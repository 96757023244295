import { State } from 'modules';

import { createSelector } from 'third-party';

export const createContractorPending = (state: State) =>
  state.contractorsList.createContractorPending;
export const updateContractorPending = (state: State) =>
  state.contractorsList.updateContractorPending;
export const deleteContractorPending = (state: State) =>
  state.contractorsList.deleteContractorPending;
export const contractorsListItems = (state: State) =>
  state.contractorsList.items;
export const contractorsListCount = (state: State) =>
  state.contractorsList.count;
export const contractorsLoaded = (state: State) =>
  state.contractorsList.contractorsLoaded;
export const getContractorByIdPending = (state: State) =>
  state.contractorsList.getContractorByIdPending;
export const contractorsListSorting = (state: State) =>
  state.contractorsList.sorting;
export const contractorsListFilters = (state: State) =>
  state.contractorsList.filters;
export const contractorsListNameFilter = (state: State) =>
  state.contractorsList.filters.name;
export const loadContractorsPending = (state: State) =>
  state.contractorsList.loadContractorsPending;
export const loadMoreContractorsPending = (state: State) =>
  state.contractorsList.loadMoreContractorsPending;
export const firstCompanyInList = createSelector(
  [contractorsLoaded, contractorsListItems],
  (contractorsLoaded, items) => {
    return contractorsLoaded && items.length > 0 ? items[0] : null;
  },
);
