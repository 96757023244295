import React, { useEffect, useMemo, useState } from 'react';

import styled from 'styled-components';

import { APIAccessLevel, APIUserType } from '@cd3p/core/types/api';
import { getFullName, getServerErrorTitle } from '@cd3p/core/utils/common';
import { emailField, phoneField } from '@cd3p/core/utils/fields';
import {
  Button,
  CircularProgress,
  Dialog,
  FormField,
  LoadingButton,
  PopoverCloseButton,
  Trans,
  Typography,
} from 'components';

import {
  FormProvider,
  useForm,
  useSelector,
  useTranslation,
} from 'third-party';

import { useApp } from 'modules/app';
import { useCache } from 'modules/cache';
import { useContractorUsersList } from 'modules/contractorUsersList';
import { NotificationSeverity, useNotifications } from 'modules/notifications';

import { appSelectors, cacheSelectors } from 'selectors';

import { CustomerField } from 'features/Fields';

import { TypeaheadOption } from 'types/app';

const Loader = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
`;

const StyledForm = styled.form`
  display: flex;
  flex-grow: 1;
  min-height: 0;
  flex-direction: column;
`;

const DialogHeader = styled(Typography)`
  color: ${props => props.theme.custom.palette.secondary500};
  margin-bottom: 0.5rem;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 2.4rem;
  padding: 1.4rem 0 2.2rem;
  justify-content: flex-end;
`;

const Divider = styled.div`
  margin: 0 -2.2rem;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
  height: 1px;
`;

const ScrollableView = styled.div`
  overflow-y: auto;
  flex-grow: 1;
  display: flex;
  flex-flow: column;
  padding: 0 0.1rem;
  > * {
    &:last-child {
      margin-bottom: 2.3rem;
    }
  }
`;

const RequiredLabel = styled(Typography)`
  font-weight: 600;
  font-size: 12px;
  color: ${props => props.theme.custom.palette.darkText};
  align-self: flex-end;
  margin: 2rem 0 0.3rem;
`;

const SendButton = styled(LoadingButton)`
  min-width: 13.4rem;
`;

const INPUT_MAX_LENGTH = 50;

enum FormFields {
  FirstName = 'firstName',
  LastName = 'lastName',
  Email = 'email',
  Phone = 'phone',
  Company = 'company',
}

type FormProps = {
  [FormFields.FirstName]: string;
  [FormFields.LastName]: string;
  [FormFields.Email]: string;
  [FormFields.Phone]: string;
  [FormFields.Company]: (TypeaheadOption & { __isNew__?: boolean }) | null;
};

const formDefaultValues = {
  [FormFields.FirstName]: '',
  [FormFields.LastName]: '',
  [FormFields.Email]: '',
  [FormFields.Phone]: '',
  [FormFields.Company]: null,
};

type Props = {
  onClose: () => void;
  onSuccess?: () => void;
  values?: FormProps | null;
  contractorId?: string | null;
};

export const ContractorForm: React.FC<Props> = ({
  onClose,
  onSuccess,
  values,
  contractorId,
}) => {
  const { t } = useTranslation();

  const { addNotification } = useNotifications();

  const { createCompany } = useCache();

  const { createUser, updateUserById } = useApp();

  const isEditMode = !!values && contractorId;

  const formMethods = useForm<FormProps>({
    defaultValues: values || formDefaultValues,
  });

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors, isValid, isSubmitted },
  } = formMethods;

  const createUserPending = useSelector(appSelectors.createUserPending);
  const createCompanyPending = useSelector(cacheSelectors.createCompanyPending);
  const updateUserByIdPending = useSelector(appSelectors.updateUserByIdPending);
  const isUpdating =
    updateUserByIdPending || createUserPending || createCompanyPending;

  const onCloseModal = () => {
    onClose?.();
    reset(formDefaultValues);
  };

  const onSubmit = async (data: FormProps) => {
    if (!isValid) {
      return;
    }

    let newCompanyResult;
    let companyOption = data[FormFields.Company];

    if (companyOption?.__isNew__) {
      newCompanyResult = await createCompany({
        name: companyOption?.label,
        companyType: APIUserType.Contractor,
      });
      if (newCompanyResult.error) {
        addNotification({
          severity: NotificationSeverity.Error,
          message:
            // @ts-ignore
            newCompanyResult.payload?.status === 409
              ? t('inviteCustomer.form.error.companyExists')
              : t('common.generalError'),
        });
        return;
      } else {
        companyOption = {
          value: newCompanyResult.payload.id,
          label: newCompanyResult.payload.name,
        };
      }
    }

    const body = {
      firstName: data[FormFields.FirstName].trim(),
      lastName: data[FormFields.LastName].trim(),
      email: data[FormFields.Email].trim(),
      phone: phoneField.apiValue(data[FormFields.Phone]),
      accessLevel: APIAccessLevel.Admin,
      userType: APIUserType.Contractor,
    };

    const result = isEditMode
      ? await updateUserById(contractorId, body)
      : await createUser(companyOption?.value, body);
    if (result.error) {
      const isUserExistError = result.payload?.status === 409;
      addNotification({
        severity: isUserExistError
          ? NotificationSeverity.Warning
          : NotificationSeverity.Error,
        message: isUserExistError
          ? t('inviteCustomer.form.error.userExists')
          : getServerErrorTitle(result) || t('common.generalError'),
      });
    } else {
      addNotification({
        message: isEditMode ? (
          <Trans i18nKey="inviteCustomer.form.successUpdate">
            {{ contractorName: getFullName(body.firstName, body.lastName) }} was
            successfully updated
          </Trans>
        ) : (
          t('inviteCustomer.form.successSubmit')
        ),
      });
      onCloseModal();
      onSuccess?.();
    }
  };

  const emailFieldRules = useMemo(() => {
    return {
      validate: {
        incorrectEmail: (value: string) =>
          emailField.validate(value) ||
          t('inviteCustomer.form.error.emailIncomplete'),
      },
    };
  }, [t]);

  const phoneFieldRules = useMemo(() => {
    return {
      validate: {
        incorrectPhone: (value: string) =>
          phoneField.validate(value) ||
          t('inviteCustomer.form.error.phoneInvalid'),
      },
    };
  }, [t]);

  return (
    <FormProvider {...formMethods}>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <PopoverCloseButton onClose={onCloseModal} isDisabled={isUpdating} />
        <DialogHeader variant="h3">
          {isEditMode
            ? t('inviteCustomer.titleEdit')
            : t('inviteCustomer.titleCreate')}
        </DialogHeader>
        <ButtonsWrapper>
          <Button
            variant="outlined"
            color="secondary"
            onClick={onCloseModal}
            disabled={isUpdating}
          >
            {t('common.cancel')}
          </Button>
          <SendButton
            type="submit"
            variant="contained"
            size="medium"
            color="primary"
            disabled={(!isValid && isSubmitted) || isUpdating}
            loading={isUpdating}
          >
            {isEditMode ? t('common.save') : t('common.send')}
          </SendButton>
        </ButtonsWrapper>
        <Divider />
        <ScrollableView>
          <RequiredLabel>
            {t('inviteCustomer.allFieldsRequiredLabel')}
          </RequiredLabel>
          <FormField
            isRequired
            isDisabled={isUpdating}
            fieldName={FormFields.FirstName}
            label={t('inviteCustomer.form.firstNameLabel')}
            fieldError={errors[FormFields.FirstName]}
            requiredErrorMessage={t('common.form.emptyFieldError')}
            control={control}
            placeholder={t('inviteCustomer.form.firstNamePlaceholder')}
            inputProps={{
              maxLength: INPUT_MAX_LENGTH,
            }}
          />
          <FormField
            isRequired
            isDisabled={isUpdating}
            fieldName={FormFields.LastName}
            fieldError={errors[FormFields.LastName]}
            requiredErrorMessage={t('common.form.emptyFieldError')}
            control={control}
            placeholder={t('inviteCustomer.form.lastNamePlaceholder')}
            inputProps={{
              maxLength: INPUT_MAX_LENGTH,
            }}
          />
          <CustomerField<FormProps>
            isCreatable
            isRequired
            isDisabled={isUpdating}
            fieldName={FormFields.Company}
            label={t('inviteCustomer.form.companyLabel')}
            placeholder={t('inviteCustomer.form.companyPlaceholder')}
          />
          <FormField
            isRequired
            isDisabled={isUpdating}
            fieldName={FormFields.Email}
            fieldError={errors[FormFields.Email]}
            label={t('inviteCustomer.form.emailLabel')}
            requiredErrorMessage={t('common.form.emptyFieldError')}
            control={control}
            placeholder={t('inviteCustomer.form.emailPlaceholder')}
            inputProps={{
              maxLength: emailField.maxLength,
            }}
            rules={emailFieldRules}
          />
          <FormField
            isRequired
            isDisabled={isUpdating}
            fieldName={FormFields.Phone}
            fieldError={errors[FormFields.Phone]}
            label={t('inviteCustomer.form.phoneLabel')}
            requiredErrorMessage={t('common.form.emptyFieldError')}
            control={control}
            placeholder={t('inviteCustomer.form.phonePlaceholder')}
            inputProps={{
              maxLength: phoneField.maxLength,
            }}
            mask={phoneField.mask}
            maskDefinitions={phoneField.maskDefinitions}
            rules={phoneFieldRules}
          />
        </ScrollableView>
      </StyledForm>
    </FormProvider>
  );
};

export const ContractorModal: React.FC<Props> = props => {
  const { contractorId } = props;
  const { t } = useTranslation();

  const [contractorData, setContractorData] = useState<FormProps | null>(null);

  const { loadContractorUserById } = useContractorUsersList();
  const { addNotification } = useNotifications();

  useEffect(() => {
    const initialLoad = async (contractorId: string) => {
      const result = await loadContractorUserById(contractorId);
      if (result.error) {
        addNotification({
          severity: NotificationSeverity.Error,
          message: t('common.generalError'),
        });
      } else {
        setContractorData({
          [FormFields.FirstName]: result.payload.firstName,
          [FormFields.LastName]: result.payload.lastName,
          [FormFields.Email]: result.payload.email,
          [FormFields.Phone]: phoneField.formValue(result.payload.phone),
          [FormFields.Company]: result.payload.companies?.[0]
            ? {
                label: result.payload.companies?.[0]?.name || '',
                value: result.payload.companies?.[0]?.id || '',
              }
            : null,
        });
      }
    };
    if (!contractorData && contractorId) {
      initialLoad(contractorId);
    }
  }, [
    contractorId,
    t,
    loadContractorUserById,
    addNotification,
    contractorData,
  ]);

  return (
    <Dialog
      open
      PaperProps={{
        style: {
          minHeight: '30rem',
          overflowY: 'visible',
          padding: '2.2rem',
          width: '42.1rem',
        },
      }}
    >
      {!contractorData && contractorId ? (
        <>
          <PopoverCloseButton onClose={props.onClose} />
          <Loader>
            <CircularProgress />
          </Loader>
        </>
      ) : (
        <ContractorForm {...props} values={contractorData} />
      )}
    </Dialog>
  );
};
