import React, { useCallback } from 'react';

import { APIServerError } from '@cd3p/core/types/common';
import { SimpleAction } from '@cd3p/core/utils/sra/types';
import { FormField, TypeaheadFormField } from 'components';
import { emit } from 'hooks/usePubSub';
import { PropsValue, SingleValue } from 'react-select';

import {
  Control,
  FieldError,
  UseFormSetValue,
  useTranslation,
} from 'third-party';

import { APP_EVENTS } from 'constants/appEvent';

import { CacheState } from 'modules/cache';

import { preparePinLocation } from 'features/OrderView/orderViewHelpers';

import { TypeaheadOption } from 'types/app';

type Props<TFormValues extends Record<string, any>> = {
  fieldName: string;
  errors: Record<string, any>;
  control: Control<TFormValues>;
  isUpdating?: boolean;
  value?: PropsValue<TypeaheadOption> | undefined;
  isDisabled?: boolean;
  label: string;
  placeholder?: string;
  shouldUpdateMapPin?: boolean;
  setValue: UseFormSetValue<TFormValues>;
  onChange?: (option: SingleValue<TypeaheadOption>) => void;
  loadAddress: (
    address: string,
  ) => Promise<APIServerError | SimpleAction<CacheState, string[]>>;
  loadAddressLocation: (address: string) => Promise<any>;
  onAddressSelect?: (option: SingleValue<TypeaheadOption>) => void;
  isRequired?: boolean;
  showError?: boolean;
  showIsRequiredMark?: boolean;
};

export const LocationField = <TFormValues extends Record<string, any>>({
  fieldName,
  errors,
  control,
  isUpdating = false,
  showIsRequiredMark,
  value,
  isDisabled,
  label,
  placeholder,
  onChange,
  shouldUpdateMapPin = false,
  loadAddress,
  loadAddressLocation,
  onAddressSelect,
  isRequired = false,
  showError = true,
  setValue,
}: Props<TFormValues>) => {
  const { t } = useTranslation();

  const updateMapPin = useCallback(
    async (address: TypeaheadOption | null) => {
      let pinLocation = null;
      if (address) {
        const result = await loadAddressLocation(address.value);
        if (!result.error) {
          pinLocation = preparePinLocation(result);
        }
      }
      emit(APP_EVENTS.UPDATE_PIN_LOCATION, pinLocation);
    },
    [loadAddressLocation],
  );

  const loadAddressOptions = async (value: string) => {
    const result = await loadAddress(value);
    let options: TypeaheadOption[] = [];
    if (!result.error) {
      options = result.payload.map(it => ({
        label: it,
        value: it,
      }));
    }
    return {
      options,
    };
  };

  const onSelectAddress = useCallback(
    (option: SingleValue<TypeaheadOption>) => {
      // @ts-ignore fix type for `option`
      setValue(fieldName, option, {
        shouldValidate: true,
        shouldDirty: true,
      });
      if (shouldUpdateMapPin) {
        updateMapPin(option);
      }
      onAddressSelect?.(option);
    },
    [setValue, fieldName, shouldUpdateMapPin, onAddressSelect, updateMapPin],
  );

  return (
    <FormField
      fieldName={fieldName}
      label={label}
      fieldError={errors[fieldName] as FieldError}
      requiredErrorMessage={t('common.form.emptyFieldError')}
      control={control}
      isUpdating={isUpdating}
      isRequired={isRequired}
      showIsRequiredMark={showIsRequiredMark}
      showError={showError}
      render={() => (
        <TypeaheadFormField
          hasError={showError && !!(errors[fieldName] as FieldError)}
          placeholder={placeholder || t('common.addAddress')}
          loadOptions={loadAddressOptions}
          onChange={(option: SingleValue<TypeaheadOption>) => {
            onSelectAddress(option);
            onChange?.(option);
          }}
          value={value}
          isDisabled={isDisabled}
        />
      )}
    />
  );
};

LocationField.displayName = 'LocationField';
