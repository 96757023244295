import React from 'react';

import { AdapterDayjs, GlobalLoader, LocalizationProvider } from 'components';
import { Notifications } from 'features';
import { useHandleUserAuthorization } from 'hooks/useHandleUserAuthorization';
import { useInitUserLocalStorage } from 'hooks/useInitUserLocalStorage';
import { useSupportOpenInstance } from 'hooks/useProviderFromUrl';
import { usePushNotifications } from 'hooks/usePushNotifications';
import { useTrackOrdersCache } from 'hooks/useTrackOrdersCache';
import { useUserNotAuthorized } from 'hooks/useUserNotAuthorized';
import { LocationChangeConfirmationProvider } from 'providers/LocationChangeConfirmationProvider';

import {
  RouterOutlet,
  createGlobalStyle,
  cssResetStyles,
  useSelector,
} from 'third-party';

import { appSelectors } from 'selectors';

const GlobalStyle = createGlobalStyle`
  ${cssResetStyles}
  :root {
    font-size: 10px;
  }

  html, body {
    height: 100%;
    overscroll-behavior: none;
  }

  body {
    font-family: 'Source Sans Pro', serif;
    background-color: ${props =>
      props.theme.custom.palette.backgroundThemeLight};
  }

  #root {
    display: flex;
    height: 100%;
  }
`;

export const AppContainer = () => {
  const isAppLoaded = useSelector(appSelectors.isAppLoaded);

  useUserNotAuthorized();

  useHandleUserAuthorization();
  usePushNotifications();

  useTrackOrdersCache();

  useInitUserLocalStorage();

  useSupportOpenInstance();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <LocationChangeConfirmationProvider>
        <GlobalStyle />
        <Notifications />
        {!isAppLoaded ? <GlobalLoader /> : <RouterOutlet />}
      </LocationChangeConfirmationProvider>
    </LocalizationProvider>
  );
};
