import React, { useMemo, useState } from 'react';

import styled from 'styled-components';

import {
  APICallBackFormStatus,
  APIRequiredAction,
  APIRequiredActionType,
  APIUserType,
} from '@cd3p/core/types/api';
import { formatDateAndTime } from '@cd3p/core/utils/common';
import { Button, Stack, SvgIcon, Typography } from 'components';

import {
  ContentLoader,
  _,
  useMatch,
  useNavigate,
  useParams,
  useSelector,
  useTranslation,
} from 'third-party';

import {
  customerProjectDetailsUrl,
  orderUrl,
  projectOrdersUrl,
} from 'constants/url';

import { useNotifications } from 'modules/notifications';
import { useRequiredActionsList } from 'modules/requiredActionsList';

import { appSelectors } from 'selectors';

import { getPrettifiedDate } from 'utils/app';
import { useCloseOrder, useUpdateOrderData } from 'utils/order';

const Wrapper = styled(Stack)<{ height?: string | number; active?: string }>`
  gap: 1.2rem;
  padding: 1.2rem 1.6rem;
  border-bottom: 1px solid ${props => props.theme.custom.palette.muted100};
  box-sizing: border-box;
  background-color: ${props =>
    props.active === 'true' ? props.theme.custom.palette.primary50 : '#fff'};
  transition: background-color 0.5s;
  &:hover {
    background-color: ${props => props.theme.custom.palette.primary50};
  }
`;

const Icon = styled.div`
  width: 3rem;
`;

const Content = styled(Stack)`
  flex-grow: 1;
`;

const Title = styled(Typography)`
  color: ${props => props.theme.custom.palette.secondary700};
`;

const Description = styled(Typography)`
  font-size: 1.4rem;
  font-weight: 400;
  color: ${props => props.theme.custom.palette.darkText};
  margin: 0.4rem 0 2rem 0;
  min-height: 0;
  flex-grow: 1;
`;

const Date = styled(Typography)`
  font-size: 1.2rem;
  font-weight: 600;
  margin-right: auto;
  color: ${props => props.theme.custom.palette.darkText};
  text-wrap: nowrap;
`;

const StyledButton = styled(Button)`
  color: ${props => props.theme.custom.palette.secondary500};
  font-size: 1rem;
  font-weight: 900;
  padding: 0;
  height: 1.8rem;
  margin-left: 1rem;
  &:focus {
    color: ${props => props.theme.custom.palette.secondary500};
  }
`;

type Props = {
  isActive?: boolean;
  item: APIRequiredAction;
};

export const RequiredActionItem: React.FC<Props> = React.memo(({ item }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id: orderId } = useParams();
  const matchOrderUrl = useMatch(orderUrl('*'));
  const userProviders = useSelector(appSelectors.userProviders);
  const companyId = useSelector(appSelectors.companyId);

  const providerName = _.find(userProviders, {
    id: item.providerId,
  })?.providerName;
  const isUserContractor =
    useSelector(appSelectors.userType) === APIUserType.Contractor;

  const { removeFromRequiredActionsList, markRequiredActionAsRead } =
    useRequiredActionsList();
  const closeOrder = useCloseOrder();
  const { updateOrderData } = useUpdateOrderData();
  const { addNotification } = useNotifications();

  const [isFirstActionDisabled] = useState(false);
  const [isSecondActionDisabled, setIsSecondActionDisabled] = useState(false);

  const {
    icon,
    title,
    description,
    firstActionLabel,
    firstActionCallback,
    secondActionLabel,
    secondActionCallback,
    onCardClick,
    isActive,
  } = useMemo<{
    icon: string;
    title: string;
    description: string;
    firstActionLabel?: string;
    firstActionCallback?: () => void;
    secondActionLabel?: string;
    secondActionCallback?: () => void;
    onCardClick?: () => void;
    isActive: boolean;
  }>(() => {
    const dateAndTime = formatDateAndTime({
      date: item.data.deliveryDate as string,
      time: item.data.deliveryTime as string,
    });

    const markAsRead = async () => {
      await markRequiredActionAsRead(item.id);
      addNotification({
        message: t('requiredActions.toast.markedAsRead'),
      });
    };

    const isOrderCardActive =
      !!matchOrderUrl && Number(item.data.id) === Number(orderId);

    const navigateToOrderUrl =
      (shouldMarkAsRead = false) =>
      () => {
        if (shouldMarkAsRead) {
          markAsRead();
        }
        navigate(orderUrl(item.data.id as string));
      };

    const navigateToProject = () => {
      navigate(projectOrdersUrl(item.data.id as string, companyId));
    };

    const navigateToCustomerProject = () => {
      navigate(
        customerProjectDetailsUrl(
          item.data.companyId as string,
          item.data.id as string,
        ),
        { state: { backUrl: location.pathname } },
      );
    };

    switch (item.notificationType) {
      case APIRequiredActionType.OrderRequested:
        return {
          isActive: isOrderCardActive,
          icon: 'action-icon-requested',
          title: t('requiredActions.orderRequested.title'),
          description: t('requiredActions.orderRequested.text', {
            companyName: item.data.company,
            projectName: item.data.project,
            dateAndTime,
          }),
          ...(isUserContractor
            ? {
                firstActionLabel: t('notification.action.markAsRead'),
                firstActionCallback: markAsRead,
                secondActionLabel: t('notification.action.viewOrder'),
                secondActionCallback: navigateToOrderUrl(true),
              }
            : {
                firstActionLabel: t(
                  'requiredActions.orderRequested.firstAction',
                ),
                firstActionCallback: navigateToOrderUrl(),
              }),
          onCardClick: navigateToOrderUrl(),
        };
      case APIRequiredActionType.OrderConfirmed:
        return {
          isActive: isOrderCardActive,
          icon: 'action-icon-confirmed',
          title: t('requiredActions.orderConfirmed.title'),
          description: t('requiredActions.orderConfirmed.text', {
            companyName: item.data.company,
            orderName: item.data.orderName,
          }),
          firstActionLabel: t('notification.action.markAsRead'),
          firstActionCallback: markAsRead,
          secondActionLabel: t('notification.action.viewOrder'),
          secondActionCallback: navigateToOrderUrl(true),
          onCardClick: navigateToOrderUrl(),
        };
      case APIRequiredActionType.ProjectCreatedByContractor:
        return {
          isActive: false,
          icon: 'action-item-project-created',
          title: t('requiredActions.projectCreated.title'),
          description: t('requiredActions.projectCreated.text', {
            companyName: item.data.company,
            projectName: item.data.name,
          }),
          firstActionLabel: t('notification.action.markAsRead'),
          secondActionLabel: t('requiredActions.projectCreated.secondAction'),
          firstActionCallback: markAsRead,
          secondActionCallback: () => {
            markAsRead();
            navigateToCustomerProject();
          },
          onCardClick: navigateToCustomerProject,
        };
      case APIRequiredActionType.ProjectCreatedByDispatcher:
        return {
          isActive: false,
          icon: 'action-item-project-created',
          title: t('requiredActions.projectCreatedContractor.title'),
          description: t('requiredActions.projectCreatedContractor.text', {
            providerName,
          }),
          firstActionLabel: t('notification.action.markAsRead'),
          secondActionLabel: t('requiredActions.projectCreated.secondAction'),
          firstActionCallback: markAsRead,
          secondActionCallback: () => {
            markAsRead();
            navigateToProject();
          },
          onCardClick: navigateToProject,
        };
      case APIRequiredActionType.OrderCancellationSubmitted:
        return {
          isActive: isOrderCardActive,
          icon: 'action-icon-cancelled',
          title: t('requiredActions.orderCancelled.title'),
          description: t('requiredActions.orderCancelled.text', {
            companyName: item.data.company,
            orderName: item.data.orderName,
          }),
          firstActionLabel: t('requiredActions.orderCancelled.firstAction'),
          firstActionCallback: navigateToOrderUrl(),
          get onCardClick() {
            return this.firstActionCallback;
          },
        };
      case APIRequiredActionType.OrderCancelled:
        return {
          isActive: isOrderCardActive,
          icon: 'action-icon-cancelled',
          title: t('requiredActions.orderCancelled.title'),
          description: t('requiredActions.orderCancelledContractor.text', {
            providerName,
            orderName: item.data.orderName,
          }),
          firstActionLabel: t('notification.action.markAsRead'),
          firstActionCallback: markAsRead,
          secondActionLabel: t('notification.action.viewOrder'),
          secondActionCallback: navigateToOrderUrl(true),
          onCardClick: isUserContractor ? navigateToOrderUrl() : markAsRead,
        };
      case APIRequiredActionType.OrderDeclined:
        return {
          isActive: isOrderCardActive,
          icon: 'action-icon-declined',
          title: t('requiredActions.orderDeclined.title'),
          description: t('requiredActions.orderDeclined.text', {
            providerName,
            orderName: item.data.orderName,
          }),
          firstActionLabel: t('notification.action.markAsRead'),
          firstActionCallback: markAsRead,
          secondActionLabel: t('notification.action.viewOrder'),
          secondActionCallback: navigateToOrderUrl(true),
          onCardClick: isUserContractor ? navigateToOrderUrl() : markAsRead,
        };
      case APIRequiredActionType.OrderCancellationConfirmed:
        return {
          isActive: isOrderCardActive,
          icon: 'action-icon-cancelled',
          title: t('requiredActions.orderCancelled.title'),
          description: t('requiredActions.orderCancelConfirm.text', {
            orderName: item.data.orderName,
          }),
          firstActionLabel: t('notification.action.markAsRead'),
          secondActionLabel: t('notification.action.viewOrder'),
          firstActionCallback: markAsRead,
          secondActionCallback: navigateToOrderUrl(true),
          onCardClick: navigateToOrderUrl(),
        };
      case APIRequiredActionType.OrderChangesRequested:
        return {
          isActive: isOrderCardActive,
          icon: 'action-icon-changes-requested',
          title: t('requiredActions.orderChangesRequested.title'),
          description: t('requiredActions.orderChangesRequested.text', {
            companyName: item.data.company,
            orderName: item.data.orderName,
            dateAndTime,
          }),
          firstActionLabel: t(
            'requiredActions.orderChangesRequested.firstAction',
          ),
          firstActionCallback: navigateToOrderUrl(),
          get onCardClick() {
            return this.firstActionCallback;
          },
        };
      case APIRequiredActionType.OrderCallBackRequested:
        return {
          isActive: isOrderCardActive,
          icon: 'action-icon-callback',
          title: t('requiredActions.orderCallback.title'),
          description: t('requiredActions.orderCallback.text', {
            orderName: item.data.orderName,
          }),
          firstActionLabel: t('requiredActions.orderCallback.secondAction'),
          firstActionCallback: async () => {
            setIsSecondActionDisabled(true);
            if (await closeOrder(Number(item.data.id))) {
              removeFromRequiredActionsList(item.id);
            }
            setIsSecondActionDisabled(false);
          },
          onCardClick: navigateToOrderUrl(),
        };
      case APIRequiredActionType.OrderCallBackSubmitted:
        return {
          isActive: isOrderCardActive,
          icon: 'action-icon-callback-submitted',
          title: t('requiredActions.orderCallbackSubmitted.title'),
          description: t('requiredActions.orderCallbackSubmitted.text', {
            orderName: item.data.orderName,
            companyName: item.data.company,
          }),
          firstActionLabel: t(
            'requiredActions.orderCallbackSubmitted.firstAction',
          ),
          firstActionCallback: navigateToOrderUrl(),
          get onCardClick() {
            return this.firstActionCallback;
          },
        };
      case APIRequiredActionType.OrderApproved:
        return {
          isActive: isOrderCardActive,
          icon: 'action-icon-confirmed',
          title: t('requiredActions.orderApproved.title'),
          description: t('requiredActions.orderApproved.text', {
            providerName,
            orderName: item.data.orderName,
            dateAndTime,
          }),
          firstActionLabel: t('notification.action.markAsRead'),
          secondActionLabel: t('notification.action.viewOrder'),
          firstActionCallback: markAsRead,
          secondActionCallback: navigateToOrderUrl(true),
          onCardClick: navigateToOrderUrl(),
        };
      case APIRequiredActionType.OrderChangesPartiallyApproved:
        return {
          isActive: isOrderCardActive,
          icon: 'action-icon-changes-approved',
          title: t('requiredActions.orderChangesPartiallyApproved.title'),
          description: t('requiredActions.orderChangesPartiallyApproved.text', {
            orderName: item.data.orderName,
            dateAndTime,
            providerName,
          }),
          firstActionLabel: t('notification.action.markAsRead'),
          secondActionLabel: t('notification.action.viewOrder'),
          firstActionCallback: markAsRead,
          secondActionCallback: navigateToOrderUrl(true),
          onCardClick: navigateToOrderUrl(),
        };
      case APIRequiredActionType.OrderChangesApproved:
        return {
          isActive: isOrderCardActive,
          icon: 'action-icon-changes-approved',
          title: t('requiredActions.orderChangesApproved.title'),
          description: t('requiredActions.orderChangesApproved.text', {
            orderName: item.data.orderName,
            dateAndTime,
            providerName,
          }),
          firstActionLabel: t('notification.action.markAsRead'),
          secondActionLabel: t('notification.action.viewOrder'),
          firstActionCallback: markAsRead,
          secondActionCallback: navigateToOrderUrl(true),
          onCardClick: navigateToOrderUrl(),
        };
      case APIRequiredActionType.OrderUpdated:
        return {
          isActive: isOrderCardActive,
          icon: 'action-icon-changes-requested',
          title: t('requiredActions.orderUpdated.title'),
          description: t('requiredActions.orderUpdated.text', {
            orderName: item.data.orderName,
            providerName,
          }),
          firstActionLabel: t('notification.action.markAsRead'),
          secondActionLabel: t('notification.action.viewOrder'),
          firstActionCallback: markAsRead,
          secondActionCallback: navigateToOrderUrl(true),
          onCardClick: navigateToOrderUrl(),
        };
      case APIRequiredActionType.OrderChangesRejected:
        return {
          isActive: isOrderCardActive,
          icon: 'action-icon-changes-rejected',
          title: t('requiredActions.orderChangesRejected.title'),
          description: t('requiredActions.orderChangesRejected.text', {
            orderName: item.data.orderName,
            providerName,
            dateAndTime,
          }),
          firstActionLabel: t('notification.action.markAsRead'),
          secondActionLabel: t('notification.action.viewOrder'),
          firstActionCallback: markAsRead,
          secondActionCallback: navigateToOrderUrl(true),
          onCardClick: navigateToOrderUrl(),
        };
      case APIRequiredActionType.OrderConfirmationNeeded:
        return {
          isActive: isOrderCardActive,
          icon: 'action-icon-order-confirmation',
          title: t('requiredActions.orderConfirmationNeeded.title'),
          description: t('requiredActions.orderConfirmationNeeded.text', {
            orderName: item.data.orderName,
          }),
          firstActionLabel: t(
            'requiredActions.orderConfirmationNeeded.firstActionLabel',
          ),
          firstActionCallback: navigateToOrderUrl(),
          get onCardClick() {
            return this.firstActionCallback;
          },
        };
      case APIRequiredActionType.OrderCallBackConfirmed:
        return {
          isActive: isOrderCardActive,
          icon: 'action-icon-callback-needed',
          title: t('requiredActions.callbackConfirmed.title'),
          description: t('requiredActions.callbackConfirmed.text'),
          firstActionLabel: t('notification.action.markAsRead'),
          secondActionLabel: t('notification.action.viewOrder'),
          firstActionCallback: markAsRead,
          secondActionCallback: navigateToOrderUrl(true),
          onCardClick: navigateToOrderUrl(),
        };
      case APIRequiredActionType.OrderNumberMissing:
        return {
          isActive: isOrderCardActive,
          icon: 'action-icon-missing-order-number',
          title: t('requiredActions.orderNumberMissing.title'),
          description: t('requiredActions.orderNumberMissing.text', {
            orderName: item.data.orderName,
          }),
          firstActionLabel: t('notification.action.markAsRead'),
          secondActionLabel: t('notification.action.addNumber'),
          firstActionCallback: markAsRead,
          secondActionCallback: navigateToOrderUrl(),
          get onCardClick() {
            return this.secondActionCallback;
          },
        };
      case APIRequiredActionType.OrderCallBackPending:
        return {
          isActive: isOrderCardActive,
          icon: 'action-icon-callback-needed',
          title: t('requiredActions.callbackNeeded.title'),
          description: t('requiredActions.callbackNeeded.text'),
          firstActionLabel: t(
            'requiredActions.callbackNeeded.firstActionLabel',
          ),
          secondActionLabel: t(
            'requiredActions.callbackNeeded.secondActionLabel',
          ),
          firstActionCallback: async () => {
            setIsSecondActionDisabled(true);
            if (
              await updateOrderData({
                orderId: Number(item.data.id),
                orderData: {
                  callBackFormStatus: APICallBackFormStatus.NoCallback,
                },
                successToastMessage: t('orderCallback.callBackClosedToast'),
              })
            ) {
              removeFromRequiredActionsList(item.id);
            }
            setIsSecondActionDisabled(false);
          },
          secondActionCallback: navigateToOrderUrl(),
          get onCardClick() {
            return this.secondActionCallback;
          },
        };
      case APIRequiredActionType.NewProjectSubscription:
        return {
          isActive: false,
          icon: 'action-icon-subscribed',
          title: t('requiredActions.subscribedToProject.title'),
          description: t('requiredActions.subscribedToProject.text', {
            projectName: item.data.name,
          }),
          firstActionLabel: t('notification.action.markAsRead'),
          secondActionLabel: t(
            'requiredActions.subscribedToProject.secondAction',
          ),
          firstActionCallback: markAsRead,
          secondActionCallback: () => {
            markAsRead();
            navigateToCustomerProject();
          },
          onCardClick: navigateToCustomerProject,
        };
      case APIRequiredActionType.NewOrderSubscription:
        return {
          isActive: isOrderCardActive,
          icon: 'action-icon-subscribed',
          title: t('requiredActions.subscribedToOrder.title'),
          description: t('requiredActions.subscribedToOrder.text', {
            orderName: item.data.orderName,
            dateAndTime,
          }),
          firstActionLabel: t('notification.action.markAsRead'),
          firstActionCallback: markAsRead,
          secondActionLabel: t('notification.action.viewOrder'),
          secondActionCallback: navigateToOrderUrl(true),
          onCardClick: navigateToOrderUrl(),
        };

      default:
        return {
          isActive: false,
          icon: '',
          title: item.notificationType,
          description: 'not yet implemented',
        };
    }
  }, [
    item.data.deliveryDate,
    item.data.deliveryTime,
    item.data.id,
    item.data.company,
    item.data.project,
    item.data.orderName,
    item.data.name,
    item.data.companyId,
    item.notificationType,
    item.id,
    matchOrderUrl,
    orderId,
    markRequiredActionAsRead,
    addNotification,
    t,
    navigate,
    isUserContractor,
    providerName,
    closeOrder,
    removeFromRequiredActionsList,
    updateOrderData,
    companyId,
  ]);

  return (
    <Wrapper
      direction="row"
      onClick={onCardClick}
      style={{ cursor: onCardClick ? 'pointer' : 'default' }}
      active={isActive.toString()}
    >
      <Icon>
        <SvgIcon height="3rem" icon={icon} width="3rem" />
      </Icon>
      <Content>
        <Title variant="subtitle2">{title}</Title>
        <Description>{description}</Description>
        <Stack direction="row" alignItems="center">
          <Date>{getPrettifiedDate(item.createdDate, t)}</Date>
          {firstActionLabel && (
            <StyledButton
              variant="text"
              disableRipple
              disabled={isFirstActionDisabled}
              onClick={(event: React.MouseEvent) => {
                event.stopPropagation();
                firstActionCallback?.();
              }}
            >
              {firstActionLabel}
            </StyledButton>
          )}
          {secondActionLabel && (
            <StyledButton
              variant="text"
              disableRipple
              disabled={isSecondActionDisabled}
              onClick={(event: React.MouseEvent) => {
                event.stopPropagation();
                secondActionCallback?.();
              }}
            >
              {secondActionLabel}
            </StyledButton>
          )}
        </Stack>
      </Content>
    </Wrapper>
  );
});

export const RequiredActionItemLoader = () => {
  return (
    <Wrapper direction="row" style={{ pointerEvents: 'none' }}>
      <ContentLoader height={100}>
        <circle cx="15" cy="15" r="15" />
        <rect x="42" y="0" width="100" height="20" />
        <rect x="42" y="30" width="250" height="15" />
        <rect x="42" y="50" width="300" height="15" />
        <rect x="42" y="70" width="210" height="15" />
      </ContentLoader>
    </Wrapper>
  );
};
