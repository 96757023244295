import React, { useCallback, useState } from 'react';

import { NotificationSeverity } from '@cd3p/core/modules/notifications';

import { useSelector, useTranslation } from 'third-party';

import { GenericCategoryItem } from 'modules/concreteCategories/commonConcreteCategories';
import { useMixTypesCategoriesList } from 'modules/concreteCategories/mixTypesCategoriesList';
import { useContractorMixTypes } from 'modules/contractorMixTypes';
import { useNotifications } from 'modules/notifications';

import {
  contractorMixTypesSelectors,
  mixTypesCategoriesSelectors,
} from 'selectors';

import { AddConcreteCategoriesPopup } from 'features/Contractors/ConcreteCategoriesPopup';
import { CommonTab } from 'features/Contractors/Tabs/CommonTab';
import { useTrackDeleteCompanyAvailability } from 'features/Contractors/Tabs/tabHelpers';

type Props = {
  contractorId: string;
  isReadOnly?: boolean;
};

export const MixTypesTab: React.FC<Props> = ({ contractorId, isReadOnly }) => {
  const { t } = useTranslation();

  const {
    loadContractorMixTypes,
    loadMoreContractorMixTypes,
    deleteContractorMixType,
    updateContractorMixTypes,
    updateContractorMixTypesSorting,
    resetContractorMixTypes,
  } = useContractorMixTypes();

  const contractorMixTypesItems = useSelector(
    contractorMixTypesSelectors.contractorMixTypesItems,
  );
  const contractorMixTypesCount = useSelector(
    contractorMixTypesSelectors.contractorMixTypesCount,
  );
  const contractorMixTypesLoaded = useSelector(
    contractorMixTypesSelectors.contractorMixTypesLoaded,
  );
  const loadContractorMixTypesPending = useSelector(
    contractorMixTypesSelectors.loadContractorMixTypesPending,
  );
  const loadMoreContractorMixTypesPending = useSelector(
    contractorMixTypesSelectors.loadMoreContractorMixTypesPending,
  );
  const sorting = useSelector(
    contractorMixTypesSelectors.contractorMixTypesSorting,
  );

  useTrackDeleteCompanyAvailability(
    contractorId,
    contractorMixTypesLoaded ? contractorMixTypesItems.length : undefined,
  );

  return (
    <CommonTab
      items={contractorMixTypesItems}
      itemsCount={contractorMixTypesCount}
      itemsLoaded={contractorMixTypesLoaded}
      loadItemsPending={loadContractorMixTypesPending}
      loadMoreItemsPending={loadMoreContractorMixTypesPending}
      sorting={sorting}
      contractorId={contractorId}
      loadItems={loadContractorMixTypes}
      loadMoreItems={loadMoreContractorMixTypes}
      deleteItem={deleteContractorMixType}
      updateItems={updateContractorMixTypes}
      updateItemsSorting={updateContractorMixTypesSorting}
      resetItems={resetContractorMixTypes}
      successDeleteMessage={t('customers.mixTypes.notification.successDelete')}
      itemNameColumnLabel={t('customers.mixTypes.table.nameColumnLabel')}
      itemCategoriesColumnLabel={t(
        'customers.mixTypes.table.categoryColumnLabel',
      )}
      deleteConfirmationTitle={t('common.areYouSure')}
      deleteConfirmationText={t(
        'customers.mixTypes.table.deleteConfirmationText',
      )}
      tableHeader={t('customers.mixTypes.header')}
      addButtonLabel={t('customers.mixTypes.addButtonText')}
      loadMoreButtonLabel={t('customers.mixTypes.table.loadMoreButtonText')}
      emptyStateText={t('customers.mixTypes.table.emptyState')}
      AssignConcreteCategoriesPopup={AssignMixTypesCategoriesPopup}
      isReadOnly={isReadOnly}
    />
  );
};

type AssignMixTypesCategoriesPopupProps = {
  companyId: string;
  isOpen: boolean;
  onClose: () => void;
  onAdd?: () => void;
};
const AssignMixTypesCategoriesPopup = ({
  companyId,
  isOpen,
  onClose,
  onAdd,
}: AssignMixTypesCategoriesPopupProps) => {
  const { t } = useTranslation();
  const {
    loadMixTypesCategories,
    loadMoreMixTypesCategories,
    updateMixTypesCategories,
    updateMixTypesCategoriesListFilters,
    resetMixTypesCategories,
  } = useMixTypesCategoriesList();
  const mixTypesListItems = useSelector(
    mixTypesCategoriesSelectors.mixTypesCategoriesListNormalized,
  );
  const canLoadMoreMixTypes = useSelector(
    mixTypesCategoriesSelectors.canLoadMoreMixTypes,
  );
  const mixTypesPending = useSelector(
    mixTypesCategoriesSelectors.mixTypesCategoriesPending,
  );
  const mixTypesLoaded = useSelector(
    mixTypesCategoriesSelectors.mixTypesCategoriesLoaded,
  );
  const { addNotification } = useNotifications();

  const [isSaving, setIsSavingSelected] = useState(false);

  const addSelectedItems = useCallback(
    async (selectedItems: GenericCategoryItem[]) => {
      setIsSavingSelected(true);
      await updateMixTypesCategories(companyId, {
        ids: Array.from(new Set(selectedItems.map(item => item.id))),
      }).then(res => {
        if (res.error) {
          return addNotification({
            severity: NotificationSeverity.Error,
            message: t('common.generalError'),
          });
        }
        addNotification({
          message: t('mixTypes.add.successNotification'),
        });
        onAdd?.();
        resetMixTypesCategories();
      });
      onClose();
      setIsSavingSelected(false);
    },
    [
      onAdd,
      companyId,
      resetMixTypesCategories,
      updateMixTypesCategories,
      onClose,
      t,
      addNotification,
    ],
  );

  const loadItems = useCallback(() => {
    loadMixTypesCategories(companyId);
  }, [companyId, loadMixTypesCategories]);
  const loadMoreItems = useCallback(() => {
    loadMoreMixTypesCategories(companyId);
  }, [companyId, loadMoreMixTypesCategories]);

  const searchItems = useCallback(
    (value: string) => {
      updateMixTypesCategoriesListFilters({ name: value });
      loadMixTypesCategories(companyId, { name: value });
    },
    [companyId, loadMixTypesCategories, updateMixTypesCategoriesListFilters],
  );

  return (
    <>
      {isOpen && (
        <AddConcreteCategoriesPopup
          onClose={() => {
            onClose();
            resetMixTypesCategories();
          }}
          addSelectedItems={addSelectedItems}
          loadItems={loadItems}
          loadMoreItems={loadMoreItems}
          searchItems={searchItems}
          items={mixTypesListItems}
          itemsLoaded={mixTypesLoaded}
          canLoadMoreItems={canLoadMoreMixTypes}
          itemsPending={mixTypesPending}
          isSaving={isSaving}
          addButtonLabel={t('mixTypes.add.addButtonLabel')}
          headerText={t('mixTypes.add.title')}
          subheaderText={t('mixTypes.add.subtext')}
          selectedListHeaderText={t('mixTypes.add.selectedListHeader')}
          availableListHeaderText={t('mixTypes.add.availableListHeader')}
          availableListEmptyText={t('mixTypes.add.emptyAvailableListText')}
          availableListEmptySearchText={t('mixTypes.add.emptySearchText')}
          selectedListEmptyText={t('mixTypes.add.emptySelectedListText')}
        />
      )}
    </>
  );
};
