export type GeoLocation = {
  lat: number;
  lng: number;
};

export type GeoCircle = {
  center: GeoLocation;
  radius: number;
};

const toRadians = (degrees: number): number => degrees * (Math.PI / 180);

export const haversineDistance = (
  lat1: number,
  lng1: number,
  lat2: number,
  lng2: number,
): number => {
  const R = 6371e3; // Earth's radius in meters
  const fi1 = toRadians(lat1);
  const fi2 = toRadians(lat2);
  const deltaFi = toRadians(lat2 - lat1);
  const deltaLambda = toRadians(lng2 - lng1);

  const a =
    Math.sin(deltaFi / 2) * Math.sin(deltaFi / 2) +
    Math.cos(fi1) *
      Math.cos(fi2) *
      Math.sin(deltaLambda / 2) *
      Math.sin(deltaLambda / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c;
};

// Find the maximum distance from the centroid to any point in the polygon
export const getPolygonMaxDistanceFromCenter = (
  polygon: GeoLocation[],
  centroid: { lat: number; lng: number },
) => {
  return Math.max(
    ...polygon.map(point =>
      haversineDistance(centroid.lat, centroid.lng, point.lat, point.lng),
    ),
  );
};

export const createBoundingCircle = (polygon: GeoLocation[]): GeoCircle => {
  if (!polygon || polygon.length === 0) {
    throw new Error('Polygon must contain at least one point.');
  }

  // Compute the centroid of the polygon
  const centroid = polygon.reduce(
    (acc, point) => {
      acc.lat += point.lat;
      acc.lng += point.lng;
      return acc;
    },
    { lat: 0, lng: 0 },
  );
  centroid.lat /= polygon.length;
  centroid.lng /= polygon.length;

  // Find the maximum distance from the centroid to any point in the polygon
  const maxDistance = getPolygonMaxDistanceFromCenter(polygon, centroid);

  // Return the circle with the centroid as center and max distance as radius
  return { center: centroid, radius: maxDistance };
};

const toDegrees = (radians: number): number => radians * (180 / Math.PI);

export const createSquareInsideCircle = (
  center: GeoLocation,
  radius: number,
): GeoLocation[] => {
  const R = 6371e3; // Earth's radius in meters
  const circleStrokeThickness = 20;
  const squareWidth = Math.cos(Math.PI / 4) * radius - circleStrokeThickness;
  const δ = squareWidth / R; // Convert radius from meters to radians

  const lat = center.lat;
  const lng = center.lng;

  // The offset in radians
  const δLat = toDegrees(δ);
  const δLng = toDegrees(δ / Math.cos(toRadians(lat)));

  // Calculate rotated square points
  const northWest = { lat: lat + δLat, lng: lng + δLng };
  const northEast = { lat: lat + δLat, lng: lng - δLng };
  const southEast = { lat: lat - δLat, lng: lng + δLng };
  const southWest = { lat: lat - δLat, lng: lng - δLng };

  return [northEast, northWest, southEast, southWest];
};
