import { useMemo } from 'react';

import {
  helpCenterBuilder,
  sendSupportRequest,
} from '@cd3p/core/modules/helpCenter';

import { bindActionCreators, useDispatch, useSelector } from 'third-party';

import { appSelectors } from 'selectors';

export const useHelpCenter = () => {
  const dispatch = useDispatch();
  const providerId = useSelector(appSelectors.providerId);

  return useMemo(
    () =>
      bindActionCreators(
        {
          sendSupportRequest: sendSupportRequest.bind(null, providerId),
        },
        dispatch,
      ),
    [dispatch, providerId],
  );
};

export const helpCenterReducer = helpCenterBuilder.getReducers();
