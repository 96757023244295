import React from 'react';

import { useSelector, useTranslation } from 'third-party';

import { useAdditiveTypesList } from 'modules/additiveTypesList';
import { useCache } from 'modules/cache';

import { additiveTypesSelectors, cacheSelectors } from 'selectors';

import { AddForm } from 'features/PlantSettings/common/AddForm';

type Props = {
  onSuccess?: () => void;
};

export const AddAdditiveTypeForm: React.FC<Props> = ({ onSuccess }) => {
  const { t } = useTranslation();
  const { createAdditiveType } = useAdditiveTypesList();
  const { loadAdditiveTypeCategories, createAdditiveTypeCategory } = useCache();

  const createAdditiveTypePending = useSelector(
    additiveTypesSelectors.createAdditiveTypePending,
  );

  const createDataCategoryPending = useSelector(
    cacheSelectors.createDataCategoryPending,
  );

  return (
    <AddForm
      createItem={createAdditiveType}
      isFormPending={createAdditiveTypePending || createDataCategoryPending}
      loadCategories={loadAdditiveTypeCategories}
      createCategory={createAdditiveTypeCategory}
      addSuccessMessage={t('additiveTypes.notification.successAdd')}
      itemFieldLabel={t('additiveTypes.nameFieldLabel')}
      itemFieldPlaceholder={t('additiveTypes.nameFieldPlaceholder')}
      categoryFieldLabel={t('additiveTypes.categoryFieldLabel')}
      categoryPlaceholder={t('additiveTypes.categoryFieldPlaceholder')}
      addButtonLabel={t('additiveTypes.addButtonLabel')}
      onSuccess={onSuccess}
    />
  );
};
