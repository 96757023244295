import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import styled from 'styled-components';

import {
  APICompany,
  APIDeliveryRateType,
  APIOrder,
  APIOrderStatus,
  APIOrderType,
  APIProject,
  APIProjectStatus,
  APIResponse,
  APISubscribedUser,
  APITicketStatuses,
  APIUser,
  APIUserType,
} from '@cd3p/core/types/api';
import { formatAPIDate, getServerErrorTitle } from '@cd3p/core/utils/common';
import { timeField } from '@cd3p/core/utils/fields';
import {
  hasAddressNotFoundError,
  prepareOrderForUpdating,
} from '@cd3p/core/utils/order';
import {
  Box,
  Button,
  CanAccess,
  CircularProgress,
  ConfirmationDialog,
  DispatcherCanAccess,
  FormCheckbox,
  FormControlLabel,
  FormField,
  FormNumberField,
  Grid,
  HorizontalDivider,
  LoadingButton,
  LocationField,
  MenuItem,
  Modal,
  MultiSelectDropdown,
  OrderStatusLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from 'components';
import { emit } from 'hooks/usePubSub';
import { useQuery } from 'hooks/useQuery';
import { LocationChangeConfirmationContext } from 'providers/LocationChangeConfirmationProvider';

import {
  OrderDetailsFormProps,
  OrderFormFields,
  getOrderDetailsFormDefaultValues,
  hasNumberDuplicationError,
  prepareAdditivesOptionsForForm,
  preparePinLocation,
  roundLocationCoordinates,
} from './orderViewHelpers';

import {
  FieldError,
  FormProvider,
  _,
  matchRoutes,
  resolvePath,
  useBeforeUnload,
  useForm,
  useLocation,
  useNavigate,
  useSelector,
  useTheme,
  useTranslation,
} from 'third-party';

import { APP_EVENTS } from 'constants/appEvent';
import { FIELD_MAX_LENGTH } from 'constants/common';
import {
  allOrdersUrl,
  orderUrl,
  projectOrderUrl,
  projectOrdersUrl,
} from 'constants/url';

import { useAllOrdersList } from 'modules/allOrdersList';
import { useCache } from 'modules/cache';
import { useContractorsList } from 'modules/contractorsList';
import { NotificationSeverity, useNotifications } from 'modules/notifications';
import { useOrder } from 'modules/order';
import { useProject } from 'modules/project';

import { appSelectors, orderSelectors } from 'selectors';

import { useLoadAllOrders } from 'features/AllOrders/useLoadAllOrders';
import { CustomerField, ProjectField } from 'features/Fields';
import { UserSearchField } from 'features/Fields/UserSearchField';
import { DateTimeForm } from 'features/OrderView/EditOrderView/DateTimeForm';
import { DeliveryRateField } from 'features/OrderView/EditOrderView/DeliveryRateField';
import { ProjectCreateEditForm } from 'features/ProjectView/ProjectCreateEditView';

import {
  LocationCoordinates,
  LocationT,
  TypeaheadDataOption,
  TypeaheadOption,
} from 'types/app';

const Wrapper = styled(Stack)`
  min-height: 0;
`;

const LoadingContainer = styled(Stack)`
  height: 100%;
  align-items: center;
  justify-content: center;
`;

const SectionTitle = styled(Typography)<{ variant: string }>`
  display: flex;
  align-items: ${props => (props.variant === 'h2' ? 'baseline' : 'center')};
  white-space: pre-wrap;
  word-break: break-word;
  color: ${props => props.theme.custom.palette.secondary500};
  padding-right: 1rem;
  margin-bottom: 0.8rem;
`;

const ProviderTitle = styled(Typography)`
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.2rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${props => props.theme.custom.palette.secondary800};
`;

const ProviderContainer = styled(Box)`
  background-color: ${props => props.theme.custom.palette.secondary50};
  padding: 12px 10px;
`;

const DescriptionText = styled(Typography)`
  font-size: 1.2rem;
  line-height: 1.6rem;
  font-weight: 600;
  margin-top: 0.4rem;
  color: ${props => props.theme.custom.palette.gray};
`;

const CoordinatesText = styled(Typography)`
  line-height: 1.6rem;
  font-weight: 600;
  margin-top: 1.2rem;
`;

const TitleWrapper = styled(Grid)`
  display: flex;
  justify-content: space-between;
`;

const ScrollableArea = styled(Grid)`
  flex-grow: 1;
  min-height: 0;
  // this padding is needed to avoid
  // menu sides cutting by overflow hidden
  padding: 0 0.2rem;
  overflow-y: auto;
`;

const ProjectModalInner = styled(Stack)`
  flex-grow: 1;
  overflow: hidden;
  border-radius: 1rem;
  border: 0.1rem solid ${props => props.theme.custom.palette.muted100};
`;

const AddProjectMenuButton = styled(Button)`
  width: 100%;
  padding: 1rem 1.6rem;
  align-items: center;
  justify-content: flex-start;
  margin-top: 1rem;
`;

const ButtonsWrapper = styled.div`
  margin-top: 1rem;
  margin-bottom: 2rem;
  display: flex;
  justify-content: flex-end;
  gap: 1.2rem;
`;

const StyledHorizontalDivider = styled(HorizontalDivider)`
  margin: 2.6rem 0 0 0;
`;

const StyledOrderStatusLabel = styled(OrderStatusLabel)`
  font-size: 1.8rem;
`;

const orderStatuses = _(Object.keys(APIOrderStatus))
  .filter(it => isNaN(Number(it)))
  .map(it => ({ value: it as APIOrderStatus, label: it }))
  .value();

const transformUserToSubscribedUserOption = (user: Partial<APIUser>) => {
  return {
    value: user.id!,
    label: `${user?.firstName} ${user?.lastName}`,
    data: {
      id: user.id!,
      firstName: user.firstName!,
      lastName: user.lastName!,
      email: user.email!,
    },
  };
};

type OrderCreateEditFormProps = {
  isEditMode?: boolean;
  orderEditData?: Partial<OrderFormProps> | null;
  setEditMode?: (value: boolean) => void;
  order?: APIOrder | null;
  manualDeliveryLocation?: LocationCoordinates | null;
  shouldConfirmUponApproval?: boolean;
};

export type OrderFormProps = OrderDetailsFormProps & {
  [OrderFormFields.OrderName]: string;
  [OrderFormFields.OrderNumber]: string;
  [OrderFormFields.Volume]: string;
  [OrderFormFields.Project]: (TypeaheadOption & { isDefault?: boolean }) | null;
  [OrderFormFields.DeliveryLocation]: TypeaheadOption | null;
  [OrderFormFields.DeliveryDate]: string | Date;
  [OrderFormFields.DeliveryTime]: string;
  [OrderFormFields.CallBack]: boolean;
  [OrderFormFields.OrderType]: APIOrderType;
  [OrderFormFields.Company]: TypeaheadOption | null;
  [OrderFormFields.Notes]: string;
  [OrderFormFields.OrderStatus]: APIOrderStatus;
  [OrderFormFields.ConfirmUponChangesApproval]: boolean;
  [OrderFormFields.DeliveryRateType]: APIDeliveryRateType;
  [OrderFormFields.SubscribedUsers]: TypeaheadDataOption<
    APISubscribedUser | APIUser
  >[];
};

export const OrderCreateEditForm: React.FC<OrderCreateEditFormProps> = ({
  isEditMode,
  order = null,
  orderEditData,
  setEditMode,
  manualDeliveryLocation,
  shouldConfirmUponApproval,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const location = useLocation();
  const { state } = location;
  const { addNotification } = useNotifications();
  const user = useSelector(appSelectors.user);
  const [showCreateProjectModal, setShowCreateProjectModal] = useState(false);
  const [company, setCompany] = useState<APICompany | null>(null);
  const [selectedLocation, setSelectedLocation] = useState<LocationT | null>(
    order
      ? {
          longitude: order.longitude,
          latitude: order.latitude,
        }
      : null,
  );
  const [shouldConfirmChangeStatus, setShouldConfirmChangeStatus] =
    useState(false);
  const [showStatusChangeConfirmation, setShowStatusChangeConfirmation] =
    useState(false);

  const isEditInDeliveringStatus =
    isEditMode && order?.orderStatus === APIOrderStatus.Delivering;

  const { loadAllOrdersList } = useLoadAllOrders();
  const { resetAllOrdersScrollPosition } = useAllOrdersList();

  const updateOrderPending = useSelector(orderSelectors.updateOrderPending);
  const isBatchSoftwareIntegrationEnabled = useSelector(
    appSelectors.isBatchSoftwareIntegrationEnabled,
  );
  const userCompanyId = useSelector(appSelectors.companyId);
  const providerName = useSelector(appSelectors.providerName);
  const isUserDispatcher = user?.userType === APIUserType.Dispatcher;
  const isUserContractor = user?.userType === APIUserType.Contractor;
  const showConfirmUponApprovalCheckbox =
    isUserContractor &&
    isEditMode &&
    order?.orderStatus === APIOrderStatus.Unconfirmed;

  const { backUrl, backUrlLabel } = useMemo(() => {
    return state?.backUrl
      ? {
          backUrl: state.backUrl,
          backUrlLabel: state.backUrlLabel || t('common.back'),
        }
      : {
          backUrl: allOrdersUrl(),
          backUrlLabel: t('order.breadcrumbs.viewAll'),
        };
  }, [state?.backUrl, state?.backUrlLabel, t]);

  const { loadProject } = useProject();
  const { loadAddress, loadAddressLocation, loadAddressByLocation } =
    useCache();
  const { projectId, companyId } = useQuery();
  const isProjectSet =
    !!projectId &&
    !!companyId &&
    typeof projectId === 'string' &&
    typeof companyId === 'string';

  const {
    continueChangeLocation,
    addLocationChangeConfirmation,
    removeLocationChangeConfirmation,
  } = useContext(LocationChangeConfirmationContext);

  const { updateOrder, createOrder, generateOrderName } = useOrder();

  const { getContractorById } = useContractorsList();

  const [confirmCancel, setConfirmCancel] = useState(false);

  const createOrderPending = useSelector(orderSelectors.createOrderPending);
  const orderTickets = useSelector(orderSelectors.orderTickets);

  const isOrderWithActiveTickets = useMemo(() => {
    return orderTickets.some(
      orderTicket =>
        ![APITicketStatuses.Completed, APITicketStatuses.Cancelled].includes(
          orderTicket.ticketStatus,
        ),
    );
  }, [orderTickets]);

  const defaultCompany = useMemo(() => {
    if (
      user.userType === APIUserType.Dispatcher ||
      !user.companies ||
      user.companies.length == 0
    ) {
      return null;
    }

    return {
      label: user.companies[0].name,
      value: user.companies[0].id,
    };
  }, [user.companies, user.userType]);

  const formDefaultValues = useMemo(
    () => ({
      [OrderFormFields.OrderName]: '',
      [OrderFormFields.OrderNumber]: '',
      [OrderFormFields.Project]: null,
      [OrderFormFields.DeliveryLocation]: null,
      [OrderFormFields.DeliveryDate]: '',
      [OrderFormFields.DeliveryTime]: '',
      [OrderFormFields.OrderType]: APIOrderType.WillCall,
      [OrderFormFields.CallBack]: false,
      [OrderFormFields.Company]: defaultCompany,
      [OrderFormFields.Notes]: '',
      [OrderFormFields.OrderStatus]: order?.orderStatus,
      [OrderFormFields.SubscribedUsers]: [],
      [OrderFormFields.DeliveryRateType]: isEditMode
        ? order?.deliveryRateType
        : APIDeliveryRateType.MinTruck,
      [OrderFormFields.ConfirmUponChangesApproval]:
        shouldConfirmUponApproval || order?.confirmUponChangesApproval,
      ...getOrderDetailsFormDefaultValues(null, []),
    }),
    [
      defaultCompany,
      isEditMode,
      order?.confirmUponChangesApproval,
      order?.deliveryRateType,
      order?.orderStatus,
      shouldConfirmUponApproval,
    ],
  );

  const formMethods = useForm<OrderFormProps>({
    mode: 'onChange',
    defaultValues: {
      ...formDefaultValues,
      ...(isEditMode ? orderEditData : {}),
    },
  });

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    setError,
    watch,
    formState: { dirtyFields: allDirtyFields, errors, isValid, isSubmitted },
  } = formMethods;

  const dirtyFields = _.omit(allDirtyFields, [
    OrderFormFields.ConfirmUponChangesApproval,
  ]);

  const isDirty = !_.isEmpty(dirtyFields);

  useBeforeUnload(isDirty, t('common.exitConfirmationWindow.text'));
  const watchedCompanyField = watch(OrderFormFields.Company);
  const watchedProjectField = watch(OrderFormFields.Project);
  const watchedDeliveryLocation = watch(OrderFormFields.DeliveryLocation);
  const watchedOrderStatus = watch(OrderFormFields.OrderStatus);

  const projectValue = watchedProjectField?.value;

  const [project, setProject] = useState<APIProject | null>(
    isEditMode &&
      (isUserDispatcher || watchedProjectField?.isDefault) &&
      order?.project.id
      ? (order.project as APIProject)
      : null,
  );

  const updateMapPinByAddress = useCallback(
    async (address: TypeaheadOption | null) => {
      let pinLocation = null;
      if (address) {
        const result = await loadAddressLocation(address.value);
        if (!result.error) {
          pinLocation = preparePinLocation(result);
          setSelectedLocation(preparePinLocation(result));
        }
      } else {
        setSelectedLocation(null);
      }
      emit(APP_EVENTS.UPDATE_PIN_LOCATION, pinLocation);
    },
    [loadAddressLocation],
  );

  useEffect(() => {
    const prefillProjectForm = async (projectId: string) => {
      if (isEditMode && (isUserDispatcher || watchedProjectField?.isDefault)) {
        if (order?.project.id) {
          setProject(order.project as APIProject);
        }
      } else {
        const result = await loadProject(projectId);
        if (!result.error) {
          const projectResponseData: APIProject = result.payload;
          setProject(projectResponseData);
          setValue(OrderFormFields.Project, {
            label: projectResponseData.name,
            value: projectResponseData.id,
          });
          if (isUserContractor && !isEditMode) {
            setValue(
              OrderFormFields.SubscribedUsers,
              _.uniqBy(
                [
                  transformUserToSubscribedUserOption(user),
                  ...projectResponseData.subscribedUsers.map(userItem => ({
                    value: userItem.id || '',
                    label: `${userItem?.firstName} ${userItem?.lastName}`,
                    data: userItem,
                  })),
                ],
                'value',
              ),
            );
          }
          setValue(OrderFormFields.DeliveryLocation, {
            label:
              projectResponseData.deliveryAddress ||
              projectResponseData.address,
            value:
              projectResponseData.deliveryAddress ||
              projectResponseData.address,
          });
          if (isProjectSet && !isEditMode && result.payload?.address) {
            const pinLocation = {
              longitude: projectResponseData.longitude,
              latitude: projectResponseData.latitude,
              address: projectResponseData.address,
              name: t('common.address'),
            };
            setSelectedLocation(pinLocation);
            emit(APP_EVENTS.UPDATE_PIN_LOCATION, pinLocation);
          }
          if (isUserDispatcher) {
            const orderNameResult = await generateOrderName(
              projectResponseData.id,
            );
            if (!orderNameResult.error) {
              setValue(OrderFormFields.OrderName, orderNameResult.payload);
            }
          }
        }
      }
    };
    if (isProjectSet || projectValue) {
      const id = isProjectSet ? projectId : projectValue!;
      prefillProjectForm(id);
    }
  }, [
    isUserDispatcher,
    loadProject,
    isProjectSet,
    projectId,
    projectValue,
    setValue,
    generateOrderName,
    isEditMode,
    order,
    watchedProjectField?.isDefault,
    t,
    isUserContractor,
    user,
  ]);

  useEffect(() => {
    if (isUserDispatcher) {
      const prefillCompanyField = async (companyId: string) => {
        const result: APIResponse<APICompany> = await getContractorById(
          companyId,
        );
        if (!result.error) {
          setCompany(result.payload);
          setValue(OrderFormFields.Company, {
            label: result.payload.name,
            value: result.payload.id,
          });
        }
      };

      if (companyId) {
        prefillCompanyField(companyId as string);
      }
    }
  }, [isUserDispatcher, companyId, getContractorById, setValue]);

  const onLocationChange = useCallback(
    (newUrl: string | Location) => {
      const url = typeof newUrl == 'string' ? newUrl : newUrl.pathname;
      const isOrderChildRoute = matchRoutes(
        [
          { path: [orderUrl(':orderId'), '*'].join('/') },
          {
            path: [
              projectOrdersUrl(':projectId', ':customerId', ':orderId'),
              '*',
            ].join('/'),
          },
        ],
        resolvePath(url, location.pathname),
      );
      if (!_.isEmpty(allDirtyFields) && !isOrderChildRoute?.length) {
        setConfirmCancel(true);
        return false;
      }
    },
    [location.pathname, allDirtyFields],
  );

  useEffect(() => {
    return addLocationChangeConfirmation(onLocationChange);
  }, [addLocationChangeConfirmation, onLocationChange]);

  const updateMapPinByCoordinates = useCallback(
    async (location?: { latitude: number; longitude: number }) => {
      if (location) {
        const roundedLocation = roundLocationCoordinates(location);
        const result = await loadAddressByLocation(
          roundedLocation.latitude,
          roundedLocation.longitude,
        );
        if (!result.error && result.payload[0]) {
          const pinLocation = {
            ...result.payload?.[0],
            ...roundedLocation,
            name: t('common.address'),
          };
          if (pinLocation?.address) {
            setValue(OrderFormFields.DeliveryLocation, {
              label: pinLocation?.address,
              value: pinLocation?.address,
            });
            setSelectedLocation(pinLocation);
          }
          emit(APP_EVENTS.UPDATE_PIN_LOCATION, pinLocation);
        }
      } else {
        setSelectedLocation(null);
      }
    },
    [loadAddressByLocation, setValue, t],
  );

  useEffect(() => {
    const projectAddress = project?.deliveryAddress || project?.address;
    const deliveryLocationValue = projectAddress
      ? { value: projectAddress, label: projectAddress }
      : null;

    if (!isProjectSet && !watchedProjectField?.isDefault) {
      setValue(OrderFormFields.DeliveryLocation, deliveryLocationValue, {
        shouldValidate: true,
      });
      updateMapPinByAddress(deliveryLocationValue);
    }
  }, [
    setValue,
    project?.address,
    isProjectSet,
    watchedProjectField?.isDefault,
    updateMapPinByAddress,
    project?.deliveryAddress,
  ]);

  useEffect(() => {
    if (manualDeliveryLocation) {
      updateMapPinByCoordinates({
        latitude: manualDeliveryLocation.lat,
        longitude: manualDeliveryLocation.lng,
      });
    }
  }, [updateMapPinByCoordinates, manualDeliveryLocation]);

  const navigateBack = useCallback(() => {
    navigate(backUrl);
  }, [backUrl, navigate]);

  const navigateOrderUrl = (orderId: number) => {
    if (isProjectSet) {
      navigate(projectOrderUrl(orderId, projectId, companyId), {
        state: { backUrl, backUrlLabel },
      });
    } else {
      navigate(orderUrl(orderId), { state: { backUrl, backUrlLabel } });
    }
  };

  const resetMapPin = useCallback(() => {
    const address =
      order?.longitude && order?.latitude
        ? {
            longitude: order?.longitude,
            latitude: order?.latitude,
            name: order?.project?.name,
            address: order?.deliveryLocation,
          }
        : order?.project;
    emit(APP_EVENTS.UPDATE_PIN_LOCATION, address);
  }, [
    order?.deliveryLocation,
    order?.latitude,
    order?.longitude,
    order?.project,
  ]);

  const onCancel = useCallback(() => {
    if (!_.isEmpty(dirtyFields)) {
      setConfirmCancel(true);
    } else {
      if (isEditMode) {
        setEditMode?.(false);
        resetMapPin();
      } else {
        navigate(backUrl);
      }
    }
  }, [backUrl, dirtyFields, isEditMode, navigate, resetMapPin, setEditMode]);

  const handleCancelEditConfirm = useCallback(() => {
    continueChangeLocation() || isEditMode
      ? setEditMode?.(false)
      : navigateBack();
    resetMapPin();
  }, [
    continueChangeLocation,
    isEditMode,
    navigateBack,
    resetMapPin,
    setEditMode,
  ]);

  const selectOptions = orderStatuses.map(option => (
    <MenuItem
      key={option.value}
      value={option.value}
      hidden={option.value === watchedOrderStatus}
    >
      <StyledOrderStatusLabel status={option.value} />
    </MenuItem>
  ));

  useEffect(() => {
    const completedOrCancelled = [
      APIOrderStatus.Completed,
      APIOrderStatus.Cancelled,
      APIOrderStatus.Declined,
    ];
    if (
      isOrderWithActiveTickets &&
      completedOrCancelled.includes(watchedOrderStatus) &&
      order?.orderStatus &&
      !completedOrCancelled.includes(order?.orderStatus)
    ) {
      setShouldConfirmChangeStatus(true);
    } else {
      setShouldConfirmChangeStatus(false);
    }
  }, [watchedOrderStatus, order?.orderStatus, isOrderWithActiveTickets]);

  const onSubmit = async (formData: Partial<OrderFormProps>) => {
    if (!isValid) {
      return;
    }
    const additives = project?.additives.filter(item =>
      formData?.additives?.includes(Number(item.id)),
    );

    const computedFormData = {
      [OrderFormFields.Project]: formData[OrderFormFields.Project]?.value,
      [OrderFormFields.Volume]: Number(formData[OrderFormFields.Volume]),
      [OrderFormFields.DeliveryLocation]:
        formData[OrderFormFields.DeliveryLocation]?.value,
      [OrderFormFields.DeliveryDate]: formatAPIDate(
        formData[OrderFormFields.DeliveryDate],
      ),
      [OrderFormFields.DeliveryTime]: timeField.apiValue(
        formData[OrderFormFields.DeliveryTime]!,
      ),
      [OrderFormFields.MixTypeId]: formData[
        OrderFormFields.MixTypeId
      ]! as number,
      [OrderFormFields.DeliveryRate]: Number(
        formData[OrderFormFields.DeliveryRate],
      ),
      [OrderFormFields.DeliveryRateType]:
        formData[OrderFormFields.DeliveryRateType],
      [OrderFormFields.CallBack]: formData[OrderFormFields.CallBack],
      [OrderFormFields.Slump]: formData.slump ? Number(formData.slump) : null,
      [OrderFormFields.Additives]: additives?.map(item => ({
        id: item.id,
        name: item.name,
      })),
      [OrderFormFields.TypeOfPour]: formData[OrderFormFields.TypeOfPour],
      [OrderFormFields.PlacementMethod]:
        formData[OrderFormFields.PlacementMethod],
      [OrderFormFields.OrderType]: formData[OrderFormFields.OrderType],
      ...(selectedLocation
        ? {
            longitude: selectedLocation.longitude,
            latitude: selectedLocation.latitude,
          }
        : {}),
    };

    const contractorComputedFormData = {
      ...computedFormData,
      orderStatus: isEditMode ? order?.orderStatus : APIOrderStatus.Requested,
      [OrderFormFields.Notes]: formData[OrderFormFields.Notes],
      ...(isEditMode ? { orderNumber: order?.orderNumber } : {}),
      [OrderFormFields.ConfirmUponChangesApproval]: !_.isNil(
        formData[OrderFormFields.ConfirmUponChangesApproval],
      )
        ? formData[OrderFormFields.ConfirmUponChangesApproval]
        : order?.confirmUponChangesApproval,
      ...(!isEditMode
        ? {
            subscribedUsersIds: formData[OrderFormFields?.SubscribedUsers]?.map(
              option => option.value,
            ),
          }
        : {}),
    };

    const dispatcherComputedFormData = {
      ...computedFormData,
      orderStatus: isEditMode
        ? formData[OrderFormFields.OrderStatus] || order?.orderStatus
        : APIOrderStatus.Unconfirmed,
      [OrderFormFields.Company]: formData[OrderFormFields.Company]?.value,
      [OrderFormFields.OrderNumber]: formData[OrderFormFields.OrderNumber],
      ...(!isEditMode
        ? { [OrderFormFields.OrderName]: formData[OrderFormFields.OrderName] }
        : {}),
    };

    let result;

    if (isEditMode) {
      result = await updateOrder(
        Number(order?.id),
        isUserDispatcher
          ? { ...prepareOrderForUpdating(order), ...dispatcherComputedFormData }
          : contractorComputedFormData,
      );
    } else {
      result = await createOrder(
        isUserDispatcher
          ? dispatcherComputedFormData
          : contractorComputedFormData,
      );
    }

    if (result.error) {
      if (hasNumberDuplicationError(result)) {
        setError(OrderFormFields.OrderNumber, {
          message: getServerErrorTitle(result) || t('common.generalError'),
        });
      }
      if (hasAddressNotFoundError(result)) {
        addNotification({
          severity: NotificationSeverity.Error,
          message: t('order.addressNotFoundToast'),
        });
      }
    } else {
      const editMessage = isUserContractor
        ? t('order.successContractorUpdatedMessage', {
            orderName: result.payload.orderName,
            providerName,
          })
        : t('order.successDispatcherUpdatedMessage', {
            orderName: result.payload.orderName,
          });
      addNotification({
        message: isEditMode
          ? editMessage
          : t('order.successCreatedMessage', {
              orderName: result.payload.orderName,
            }),
      });
      removeLocationChangeConfirmation(onLocationChange);
      if (isEditMode) {
        setEditMode?.(false);
      } else {
        resetAllOrdersScrollPosition();
        loadAllOrdersList();
        navigateOrderUrl(result.payload.id);
      }
      return result;
    }
  };
  const isSaveDisabled =
    (isSubmitted && !isValid) || createOrderPending || updateOrderPending;
  const isEditSaveDisabled = useMemo(() => {
    const isLocationPinNotChanged =
      !selectedLocation ||
      (selectedLocation &&
        order &&
        selectedLocation?.longitude === order?.longitude &&
        selectedLocation?.latitude === order?.latitude);
    return (
      (isEditMode &&
        isLocationPinNotChanged &&
        _.isEmpty(dirtyFields) &&
        !isDirty) ||
      isSaveDisabled
    );
  }, [
    dirtyFields,
    isEditMode,
    isSaveDisabled,
    order,
    selectedLocation,
    isDirty,
  ]);

  const handleProjectClear = () => {
    setProject(null);
  };

  const onProjectChange = useCallback(() => {
    setValue(
      OrderFormFields.DeliveryLocation,
      formDefaultValues[OrderFormFields.DeliveryLocation],
    );
    updateMapPinByAddress(formDefaultValues[OrderFormFields.DeliveryLocation]);
    setValue(
      OrderFormFields.MixTypeId,
      formDefaultValues[OrderFormFields.MixTypeId]!,
    );
    setValue(
      OrderFormFields.Additives,
      formDefaultValues[OrderFormFields.Additives],
    );
    setValue(
      OrderFormFields.OrderName,
      formDefaultValues[OrderFormFields.OrderName],
    );
    if (isUserContractor) {
      setValue(
        OrderFormFields.SubscribedUsers,
        formDefaultValues[OrderFormFields.SubscribedUsers],
      );
    }
  }, [formDefaultValues, isUserContractor, setValue, updateMapPinByAddress]);

  const onCustomerChange = useCallback(() => {
    setValue(
      OrderFormFields.Project,
      formDefaultValues[OrderFormFields.Project],
    );
    onProjectChange();
  }, [formDefaultValues, onProjectChange, setValue]);

  if ((projectId && !project) || (isUserDispatcher && companyId && !company)) {
    return (
      <LoadingContainer>
        <CircularProgress sx={{ margin: '5rem' }} />
      </LoadingContainer>
    );
  }

  return (
    <Wrapper>
      <TitleWrapper>
        <SectionTitle variant="h4">
          {isEditMode
            ? t('order.headerTitle.editOrder')
            : t('order.headerTitle.newOrder')}
        </SectionTitle>
        <ButtonsWrapper>
          {isEditMode ? (
            <>
              <Button
                variant="outlined"
                color="secondary"
                onClick={onCancel}
                disabled={createOrderPending || updateOrderPending}
              >
                {t('common.cancel')}
              </Button>
              <LoadingButton
                variant="contained"
                disabled={isEditSaveDisabled}
                loading={updateOrderPending}
                onClick={
                  shouldConfirmChangeStatus
                    ? () => setShowStatusChangeConfirmation(true)
                    : handleSubmit(onSubmit)
                }
                sx={{ minWidth: '8.8rem' }}
              >
                {isUserContractor
                  ? t('order.action.submitChanges')
                  : t('common.save')}
              </LoadingButton>
            </>
          ) : (
            <>
              <Button
                variant="outlined"
                color="secondary"
                onClick={onCancel}
                disabled={createOrderPending || updateOrderPending}
                sx={{ width: '8rem' }}
              >
                {t('common.cancel')}
              </Button>
              <LoadingButton
                color="primary"
                variant="contained"
                type="submit"
                loading={createOrderPending}
                disabled={isSaveDisabled}
                onClick={handleSubmit(onSubmit)}
                sx={{ width: '13rem' }}
              >
                {isUserContractor
                  ? t('common.submitOrder')
                  : t('common.createOrder')}
              </LoadingButton>
            </>
          )}
        </ButtonsWrapper>
      </TitleWrapper>
      {showConfirmUponApprovalCheckbox && (
        <FormCheckbox
          title={''}
          fieldName={OrderFormFields.ConfirmUponChangesApproval}
          control={control}
          label={t('order.header.confirmUponApproval')}
          labelStyle={{
            color: theme.custom.palette.absoluteDark,
          }}
        />
      )}
      <CanAccess allowedUserType={APIUserType.Contractor}>
        <ProviderContainer>
          <ProviderTitle>{providerName}</ProviderTitle>
        </ProviderContainer>
      </CanAccess>
      <HorizontalDivider />
      <ScrollableArea>
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DispatcherCanAccess>
              {!isEditMode && (
                <>
                  <CustomerField<OrderFormProps>
                    isRequired
                    isDisabled={
                      isEditMode ||
                      createOrderPending ||
                      updateOrderPending ||
                      !!companyId ||
                      user.userType !== APIUserType.Dispatcher
                    }
                    loadAllOptions
                    loadOnMount
                    fieldName={OrderFormFields.Company}
                    onChange={onCustomerChange}
                    onClear={handleProjectClear}
                  />
                  <ProjectField<OrderFormProps>
                    key={`project-field-${watchedCompanyField?.value}`}
                    isRequired
                    isDisabled={
                      isEditMode ||
                      createOrderPending ||
                      updateOrderPending ||
                      isProjectSet ||
                      !watchedCompanyField
                    }
                    loadAllOptions
                    loadOnMount={!!watchedCompanyField?.value}
                    companyId={watchedCompanyField?.value}
                    showError={!!watchedCompanyField?.value}
                    fieldName={OrderFormFields.Project}
                    onChange={onProjectChange}
                    onClear={handleProjectClear}
                  />
                </>
              )}
              {isEditMode && (
                <FormField
                  key={OrderFormFields.OrderStatus}
                  fieldName={OrderFormFields.OrderStatus}
                  label={t('order.sectionField.orderStatus')}
                  fieldError={errors[OrderFormFields.OrderStatus]}
                  requiredErrorMessage={t('common.form.emptyFieldError')}
                  control={control}
                  isDisabled={
                    updateOrderPending ||
                    order?.project?.projectStatus === APIProjectStatus.Archived
                  }
                  isUpdating={updateOrderPending}
                  selectOptions={selectOptions}
                  maxLength={FIELD_MAX_LENGTH}
                  isRequired
                  showError={isSubmitted}
                />
              )}
            </DispatcherCanAccess>
            {!isEditMode && (
              <CanAccess allowedUserType={APIUserType.Contractor}>
                <ProjectField<OrderFormProps>
                  // recreate field on each clear to force it to update its
                  // options
                  key={`${OrderFormFields.Project}-${!!watchedProjectField}`}
                  isRequired
                  isDisabled={
                    isEditMode ||
                    createOrderPending ||
                    updateOrderPending ||
                    isProjectSet
                  }
                  loadAllOptions
                  loadOnMount
                  companyId={userCompanyId}
                  fieldName={OrderFormFields.Project}
                  onChange={onProjectChange}
                  menuElementBefore={(props: { isLoading: boolean }) =>
                    props.isLoading ? null : (
                      <AddProjectMenuButton
                        onClick={() => setShowCreateProjectModal(true)}
                      >
                        {t('order.sectionField.addProjectButton')}
                      </AddProjectMenuButton>
                    )
                  }
                />
              </CanAccess>
            )}
            {!(isUserContractor && isEditInDeliveringStatus) && (
              <>
                <LocationField
                  label={`${t('order.sectionField.deliveryLocation')}`}
                  fieldName={OrderFormFields.DeliveryLocation}
                  errors={errors}
                  control={control}
                  isUpdating={createOrderPending || updateOrderPending}
                  value={watchedDeliveryLocation}
                  isDisabled={
                    createOrderPending ||
                    updateOrderPending ||
                    !watchedProjectField
                  }
                  showError={!!watchedProjectField?.value && isSubmitted}
                  setValue={setValue}
                  loadAddress={loadAddress}
                  loadAddressLocation={loadAddressLocation}
                  onAddressSelect={updateMapPinByAddress}
                  isRequired
                />
                {watchedDeliveryLocation?.value ? (
                  <CoordinatesText>
                    {selectedLocation?.latitude
                      ? `${
                          roundLocationCoordinates(selectedLocation).latitude
                        }:${
                          roundLocationCoordinates(selectedLocation).longitude
                        }`
                      : ''}
                  </CoordinatesText>
                ) : null}
                <DescriptionText>
                  {t('order.sectionField.deliveryDescription')}
                </DescriptionText>
              </>
            )}
            <DispatcherCanAccess>
              {!isEditMode && (
                <FormField
                  key={OrderFormFields.OrderName}
                  fieldName={OrderFormFields.OrderName}
                  label={t('order.sectionField.orderName')}
                  control={control}
                  maxLength={300}
                  isDisabled
                  isUpdating={createOrderPending || updateOrderPending}
                  isRequired
                  showError={isSubmitted}
                />
              )}
              {isBatchSoftwareIntegrationEnabled && (
                <FormField
                  key={OrderFormFields.OrderNumber}
                  fieldName={OrderFormFields.OrderNumber}
                  placeholder={t('order.sectionField.orderNumberPlaceholder')}
                  label={t('order.sectionField.orderNumber')}
                  fieldError={errors[OrderFormFields.OrderNumber]}
                  control={control}
                  maxLength={200}
                  isDisabled={createOrderPending || updateOrderPending}
                  isRequired={false}
                  showError={isSubmitted}
                />
              )}
            </DispatcherCanAccess>
            {!(isUserContractor && isEditInDeliveringStatus) && (
              <StyledHorizontalDivider />
            )}
            {(isUserDispatcher && !isEditMode) ||
            (isUserContractor &&
              (!isEditMode ||
                order?.orderStatus === APIOrderStatus.Requested)) ? (
              <FormField
                key={OrderFormFields.OrderType}
                fieldName={OrderFormFields.OrderType}
                label={t('order.sectionField.orderType')}
                fieldError={errors[OrderFormFields.OrderType]}
                control={control}
                isDisabled={createOrderPending || updateOrderPending}
                isRequired={true}
                showError={isSubmitted}
                render={({ field }) => (
                  <RadioGroup
                    value={field.value}
                    name="radio-buttons-group"
                    onChange={field.onChange}
                  >
                    <FormControlLabel
                      value={APIOrderType.WillCall}
                      control={<Radio disableRipple />}
                      disabled={createOrderPending || updateOrderPending}
                      label={t('order.sectionField.orderType.willCall')}
                    />
                    <FormControlLabel
                      value={APIOrderType.FirmOrder}
                      control={<Radio disableRipple />}
                      disabled={createOrderPending || updateOrderPending}
                      label={t('order.sectionField.orderType.firmOrder')}
                    />
                  </RadioGroup>
                )}
              />
            ) : null}
            <FormCheckbox
              title={t('order.sectionField.callbackTitle')}
              fieldName={OrderFormFields.CallBack}
              control={control}
              label={t('order.sectionField.callbackLabel')}
              isDisabled={createOrderPending || updateOrderPending}
            />
            {!(isUserContractor && isEditInDeliveringStatus) && (
              <>
                <DateTimeForm
                  isDisabled={createOrderPending || updateOrderPending}
                  isTimeRequired
                  isDateRequired
                  //CD3P-1647 Provider Web: New/Edit Allow to create/edit orders in the past
                  //acceptPastDate={false}
                  showError={isSubmitted}
                  timeFieldName={OrderFormFields.DeliveryTime}
                  dateFieldName={OrderFormFields.DeliveryDate}
                />
                <StyledHorizontalDivider />
                <DeliveryRateField
                  isRequired
                  selectFieldName={OrderFormFields.DeliveryRateType}
                  inputFieldName={OrderFormFields.DeliveryRate}
                  errors={errors}
                  control={control}
                  isDisabled={createOrderPending || updateOrderPending}
                  showError={isSubmitted}
                  label={t('order.sectionField.deliveryRate')}
                  selectValue={getValues(OrderFormFields.DeliveryRateType)}
                />
              </>
            )}
            <FormNumberField
              isRequired
              precision={2}
              control={control}
              fieldName={OrderFormFields.Volume}
              label={t('order.sectionField.orderQuantity')}
              fieldError={errors[OrderFormFields.Volume] as FieldError}
              requiredErrorMessage={t('common.form.emptyFieldError')}
              isDisabled={createOrderPending || updateOrderPending}
              placeholder={t('order.sectionField.volumeQuantityPlaceholder')}
              maxLength={10}
              rules={{
                validate: {
                  incorrectVolume: (value: string) =>
                    Number(value) >= (order?.batched || 0) ||
                    'You cannot request less than what has already been batched.',
                },
              }}
              showError={isSaveDisabled}
            />
            <FormField
              key={`mix-types-${watchedProjectField?.value}`}
              isDisabled={
                !watchedProjectField?.value ||
                createOrderPending ||
                updateOrderPending
              }
              type="select"
              fieldName={OrderFormFields.MixTypeId}
              label={t('order.sectionField.mixType')}
              fieldError={errors[OrderFormFields.MixTypeId] as FieldError}
              requiredErrorMessage={t('common.form.emptyFieldError')}
              control={control}
              placeholder={t('order.sectionField.mixTypePlaceholder')}
              maxLength={FIELD_MAX_LENGTH}
              isRequired
              showError={isSaveDisabled}
              selectOptions={(project?.mixTypes || []).map(option => (
                <MenuItem
                  key={option.id}
                  value={option.id}
                  hidden={option.id === order?.mixType?.id}
                >
                  {option.name}
                </MenuItem>
              ))}
            />
            <div style={{ marginTop: '1.6rem' }}>
              <MultiSelectDropdown
                key={`additives-types-${watchedProjectField?.value}`}
                fieldName={OrderFormFields.Additives}
                caption={t('order.sectionField.additives')}
                options={prepareAdditivesOptionsForForm(
                  project?.additives || [],
                )}
                isDisabled={
                  !watchedProjectField?.value ||
                  createOrderPending ||
                  updateOrderPending
                }
                control={control}
                placeholder={t('order.sectionField.additivesPlaceholder')}
                defaultSelected={
                  isEditMode
                    ? orderEditData?.[OrderFormFields.Additives] || []
                    : undefined
                }
              />
            </div>
            <FormNumberField
              isRequired
              precision={1}
              control={control}
              fieldName={OrderFormFields.Slump}
              label={t('order.sectionField.slump')}
              fieldError={errors[OrderFormFields.Slump] as FieldError}
              requiredErrorMessage={t('common.form.emptyFieldError')}
              isDisabled={createOrderPending || updateOrderPending}
              placeholder={t('order.sectionField.slumpPlaceholder')}
              maxLength={6}
              maxValue={9999.9}
              showError={isSaveDisabled}
            />
            <FormField
              isRequired={false}
              control={control}
              fieldName={OrderFormFields.TypeOfPour}
              label={t('order.sectionField.typeOfPour')}
              fieldError={errors[OrderFormFields.TypeOfPour] as FieldError}
              requiredErrorMessage={t('common.form.emptyFieldError')}
              isDisabled={createOrderPending || updateOrderPending}
              placeholder={t('order.sectionField.typeOfPourPlaceholder')}
              maxLength={FIELD_MAX_LENGTH}
              showError={isSaveDisabled}
            />
            <FormField
              isRequired={false}
              control={control}
              fieldName={OrderFormFields.PlacementMethod}
              label={t('order.sectionField.placementMethod')}
              fieldError={errors[OrderFormFields.PlacementMethod] as FieldError}
              requiredErrorMessage={t('common.form.emptyFieldError')}
              isDisabled={createOrderPending || updateOrderPending}
              placeholder={t('order.sectionField.placementMethodPlaceholder')}
              maxLength={FIELD_MAX_LENGTH}
              showError={isSaveDisabled}
            />
            <CanAccess allowedUserType={APIUserType.Contractor}>
              {!isEditMode && (
                <UserSearchField<OrderFormProps>
                  label={t('order.sectionField.subscribeTeamLabel')}
                  placeholder={
                    watchedProjectField?.value
                      ? t('order.sectionField.subscribeTeamPlaceholder')
                      : t('order.sectionField.subscribeTeamPlaceholderEmpty')
                  }
                  fieldName={OrderFormFields.SubscribedUsers}
                  isDisabled={
                    createOrderPending ||
                    updateOrderPending ||
                    !watchedProjectField?.value
                  }
                  menuPlacement="top"
                />
              )}
              <FormField
                key={OrderFormFields.Notes}
                fieldName={OrderFormFields.Notes}
                label={t('order.sectionField.notes')}
                placeholder={t('order.sectionField.notesPlaceholder')}
                isRequired={false}
                control={control}
                maxLength={300}
                isDisabled={createOrderPending || updateOrderPending}
                showError={isSubmitted}
                multiline
              />
            </CanAccess>
          </form>
        </FormProvider>
        <ConfirmationDialog
          onClose={() => setConfirmCancel(false)}
          open={confirmCancel}
          handleActionClick={handleCancelEditConfirm}
          description={t('common.exitConfirmationWindow.text')}
          cancelButtonTitle={t('common.continueEditing')}
          actionButtonTitle={t('common.confirmCancel')}
        />
        <ConfirmationDialog
          onClose={() => setShowStatusChangeConfirmation(false)}
          open={showStatusChangeConfirmation}
          handleActionClick={() => {
            handleSubmit(onSubmit)();
            setShowStatusChangeConfirmation(false);
          }}
          description={
            watchedOrderStatus === APIOrderStatus.Cancelled ||
            watchedOrderStatus === APIOrderStatus.Declined
              ? t('order.details.confirmChangeStatusToCancelled')
              : t('order.details.confirmChangeStatusToCompleted')
          }
          cancelButtonTitle={t('common.no.nevermind')}
          actionButtonTitle={t('common.yes.change')}
        />
      </ScrollableArea>
      <Modal
        PaperProps={{
          sx: { overflow: 'visible', width: '100%', height: '100%' },
        }}
        width="100%"
        open={showCreateProjectModal}
        headerEnabled={false}
        onClose={() => {
          setShowCreateProjectModal(false);
        }}
      >
        <ProjectModalInner>
          <ProjectCreateEditForm
            showBreadcrumbs={false}
            onClose={(project?: APIProject) => {
              if (project) {
                setValue(OrderFormFields.Project, {
                  value: project.id,
                  label: project.name,
                });
              }
              setShowCreateProjectModal(false);
            }}
          />
        </ProjectModalInner>
      </Modal>
    </Wrapper>
  );
};
