import React from 'react';

import CancelIcon from '@mui/icons-material/CancelOutlined';

import { formatDateAndTime } from '@cd3p/core/utils/common';
import { Stack, Typography } from 'components';

import { OrderContentWrapper } from '../orderViewHelpers';

import { styled, useSelector, useTheme, useTranslation } from 'third-party';

import { appSelectors } from 'selectors';

import { themeConfig } from 'styles/theme';

interface DeclinedOrderProps {
  orderName?: string;
  deliveryDate?: string;
}

const StyledOrderContentWrapper = styled(OrderContentWrapper)`
  border-color: ${props => props.theme.custom.palette.danger800};
  padding: 1.2rem;
`;

const Title = styled(Typography)`
  color: ${props => props.theme.custom.palette.danger800};
  text-transform: uppercase;
  font-size: 1.6rem;
  font-weight: 600;
  margin-left: 0.8rem;
`;

export const DeclinedOrder: React.FC<DeclinedOrderProps> = props => {
  const { orderName, deliveryDate } = props;
  const { t } = useTranslation();
  const theme = useTheme();

  const providerName = useSelector(appSelectors.providerName);

  const dateAndTime = formatDateAndTime({
    date: deliveryDate || '',
    time: deliveryDate || '',
  });

  return (
    <StyledOrderContentWrapper>
      <Stack direction={'row'} alignItems={'center'}>
        <CancelIcon
          sx={{
            fontSize: '2rem',
            color: themeConfig.custom.palette.danger800,
          }}
        />
        <Title>{t('order.details.declined.title')}</Title>
      </Stack>
      <Typography
        color={theme.custom.palette.gray}
        variant="body1"
        marginLeft="2.8rem"
        marginTop="0.4rem"
      >
        {t('order.details.declined.description', {
          orderName,
          providerName,
          dateTime: dateAndTime,
        })}
      </Typography>
    </StyledOrderContentWrapper>
  );
};
