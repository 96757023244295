import { COMMUNICATIONS_API_PATH, ORDERS_API_PATH } from './common';

export const ordersHubEndpoint = () => `${ORDERS_API_PATH}hub`;
export const communicationsHubEndpoint = () => `${COMMUNICATIONS_API_PATH}hub`;

export const ON_ORDER_UPDATE_EVENT = 'OnOrderUpdate';
export const ON_ORDER_CREATE_EVENT = 'OnOrderCreate';
export const ON_ORDER_CONFIRM_EVENT = 'OnOrderConfirm';
export const ON_ORDER_CANCELLATION_SUBMITTED_EVENT =
  'OnOrderCancellationSubmittedUpdate';

export const ON_TRUCK_LOCATION_UPDATE_EVENT = 'OnTruckLocationUpdate';

export const ON_APPLICATION_VERSION_CREATE = 'OnApplicationVersionCreate';

export const ON_TICKET_UPDATE_EVENT = 'OnTicketUpdate';
export const ON_TICKET_CREATE_EVENT = 'OnTicketCreate';
export const ON_TICKET_DELETE_EVENT = 'OnTicketDelete';

export const ON_MESSAGE_POST_EVENT = 'OnMessageSend';

export const ON_PROJECT_CREATE = 'OnProjectCreate';

export const ON_NOTIFICATION_READ = 'OnNotificationRead';
export const ON_NOTIFICATION_CREATE = 'OnNotificationCreate';
export const ON_DIALOG_UPDATE = 'OnDialogUpdate';
export const ON_DIALOG_UNREAD_COUNT_CHANGED = 'OnDialogUnreadCountChanged';
export const ON_USER_ADDED_TO_PROVIDER = 'OnUserAddedToProvider';
export const ON_USER_REMOVED_FROM_PROVIDER = 'OnUserRemovedFromProvider';
export const ON_USER_SUBSCRIBED_TO_ORDER = 'OnUserSubscribedToOrder';
export const ON_USER_UNSUBSCRIBED_FROM_ORDER = 'OnUserUnsubscribedFromOrder';
