import { TFunction } from 'i18next';

import { API_DATE_FORMAT } from '../constants/common';
import { dayjs } from '../third-party';
import { APISortOrder } from '../types/api';
import { APIServerError } from '../types/common';

export const DISPLAY_DATE_FORMAT = 'MM/DD/YYYY';
export const DISPLAY_TIME_FORMAT = 'hh:mm a';
export const formatDisplayedDate = (dateToFormat?: string | Date) => {
  return dateToFormat ? dayjs(dateToFormat).format(DISPLAY_DATE_FORMAT) : '';
};

export const formatTime = (dateToFormat?: string | Date) => {
  return dateToFormat ? dayjs(dateToFormat).format(DISPLAY_TIME_FORMAT) : '';
};

export const formatAPIDate = (dateToFormat?: string | Date) => {
  return dateToFormat ? dayjs(dateToFormat).format(API_DATE_FORMAT) : '';
};

export const formatDateAndTime = ({
  date,
  time,
}: {
  date: string;
  time: string;
}) => {
  return `${formatDisplayedDate(date)} ${convert24toAmPm(time)}`;
};

export const convert24toAmPm = (
  inputValue: string,
  options?: { withLeadingZeros: boolean },
) => {
  if (!inputValue || !/^\d{2}:\d{2}$/.test(inputValue)) {
    return '';
  }

  const [hours, minutes] = inputValue.split(':');
  let formattedHours = parseInt(hours);
  let suffix = 'AM';

  if (formattedHours >= 12) {
    formattedHours %= 12;
    suffix = 'PM';
  }

  if (formattedHours === 0) {
    formattedHours = 12;
  }
  if (options?.withLeadingZeros) {
    return `${
      ((formattedHours % 12 || 12) < 10 ? '0' : '') +
      (formattedHours % 12 || 12)
    }:${minutes} ${suffix}`;
  }

  return `${formattedHours}:${minutes} ${suffix}`;
};

export const convertAmPmto24 = (inputValue: string) => {
  const [time, modifier] = inputValue.split(' ');

  const result = time.split(':');
  let [hours] = result;
  const [, minutes] = result;

  if (hours === '12') {
    hours = '00';
  }

  if (modifier?.toUpperCase() === 'PM') {
    hours = String(parseInt(hours, 10) + 12);
  }

  return `${hours}:${minutes}`;
};

export const getFullName = (
  firstName?: string,
  lastName?: string,
  fallback = '',
) => [firstName || '', lastName || ''].join(' ').trim() || fallback;

export const getServerErrorTitle = (result?: APIServerError) =>
  result?.payload?.status === 500 ? '' : result?.payload?.body?.Title || '';

export const getSortOrder = (
  columnId: string,
  sortField: string,
  sortOrder: APISortOrder,
) => {
  // if previous sortField was the same column - change to opposite sort
  // if we sort new column - then ASC by default
  return sortField === columnId
    ? sortOrder === APISortOrder.ASC
      ? APISortOrder.DESC
      : APISortOrder.ASC
    : APISortOrder.ASC;
};

export const getApplicationVersion = (version: string) => {
  const [versionNumber, buildNumber] = version.split(' ');
  const [major, minor, patch] = versionNumber.split('.').map(Number);
  const build = buildNumber ? Number(buildNumber.slice(1, -1)) : 1;

  if (isNaN(major) || isNaN(minor) || isNaN(patch) || isNaN(build)) {
    throw new Error(`Invalid version string: ${version}`);
  }

  return [major, minor, patch, build];
};
export const getDateSeparator = (
  date: string | Date | number,
  t: TFunction,
) => {
  let dayString = '';
  let yearString = '';
  const parsedDate = dayjs(date);

  if (parsedDate.isToday()) {
    dayString = `${t('common.today')} `;
  } else if (parsedDate.isYesterday()) {
    dayString = `${t('common.yesterday')} `;
  } else if (parsedDate.isTomorrow()) {
    dayString = `${t('common.tomorrow')} `;
  }

  if (dayjs().year() !== parsedDate.year()) {
    yearString = ', ' + parsedDate.year();
  }

  return `${dayString}${parsedDate.format('dddd, MMMM D')}${yearString}`;
};

export enum DAY_OF_WEEK {
  Sunday = 'Sunday',
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
}
export const DAYS_OF_WEEK = [
  DAY_OF_WEEK.Sunday,
  DAY_OF_WEEK.Monday,
  DAY_OF_WEEK.Tuesday,
  DAY_OF_WEEK.Wednesday,
  DAY_OF_WEEK.Thursday,
  DAY_OF_WEEK.Friday,
  DAY_OF_WEEK.Saturday,
];
