import React, { createContext, useMemo } from 'react';

import {
  LocationChangeConfirmationT,
  useLocationChangeConfirmation,
} from '@cd3p/core/hooks/useLocationChangeConfirmation';

import { useNavigate } from 'third-party';

export const contextDefaultValue = {} as LocationChangeConfirmationT;

export const LocationChangeConfirmationContext =
  createContext<LocationChangeConfirmationT>(contextDefaultValue);

type Props = {
  children: React.ReactNode;
};

export const LocationChangeConfirmationProvider: React.FC<Props> = ({
  children,
}) => {
  const navigate = useNavigate();
  const contextValue = useLocationChangeConfirmation(navigate);
  return (
    <LocationChangeConfirmationContext.Provider value={contextValue}>
      {useMemo(() => {
        return <>{children}</>;
      }, [children])}
    </LocationChangeConfirmationContext.Provider>
  );
};
