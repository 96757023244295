import React, { CSSProperties, useMemo } from 'react';

import styled, { StyledComponentBase, useTheme } from 'styled-components';

import {
  APIOrderStatus,
  APISortOrder,
  APITicketStatuses,
} from '@cd3p/core/types/api';
import { TooltipProps } from '@mui/material/Tooltip/Tooltip';
import { Table, TicketETA, Typography } from 'components';
import { OrderTicket } from 'selectors/order';

import { Trans, _, useTranslation } from 'third-party';

import { TicketsListColumnId } from 'modules/common/ticketsList';

import { getTicketLabelElement } from 'features/OrderView/orderViewHelpers';

import { getTruckETA, shouldShowTicketETA, ticketStatusMap } from 'utils/order';

const CellText = styled(Typography)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const TicketNumberCell = styled(CellText)`
  font-weight: 900;
  font-size: 1.4rem;
  color: ${props => props.theme.custom.palette.muted800};
`;

const OrderNumberCell = styled(CellText)`
  font-weight: 600;
  font-size: 1.4rem;
  color: ${props => props.theme.custom.palette.muted800};
`;

const PlantNameCell = styled(CellText)`
  font-weight: 700;
  font-size: 1.4rem;
  color: ${props => props.theme.custom.palette.muted800};
`;

const TicketStatusCellWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const TicketStatusCell = styled.div`
  font-weight: 700;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

type TableComponents = 'TableHead' | 'TableCell' | 'TableBody';

type Props = {
  ticketsLoaded: boolean;
  loadTicketsPending: boolean;
  loadMoreTicketsPending: boolean;
  ticketsTotalCount: number;
  tickets: OrderTicket[];
  columnProps?: TicketsTableColumn[];
  onLoadMoreClicked: () => void;
  onRowClick: (ticket: OrderTicket) => void;
  isRowActive: (ticket: OrderTicket) => boolean;
  rowHeight?: string;
  headerHeight?: string;
  sortField?: TicketsListColumnId;
  sortOrder?: APISortOrder;
  onColumnHeaderClicked?: (columnDataId: TicketsListColumnId) => void;
  headerCellStyles?: CSSProperties;
  headerRowStyles?: CSSProperties;
  hideHeaders?: boolean;
  components?: {
    [key in TableComponents]?: StyledComponentBase<any, any>;
  };
};

export interface TicketsTableColumn {
  id: string;
  dataId?: TicketsListColumnId;
  label?: string;
  width?: number | string;
  order?: number;
  align?: 'right' | 'left';
  hidden?: boolean;
  formatter?: (
    value: any,
    ticket: OrderTicket,
  ) => {
    value: TooltipProps['title'];
    element?: TooltipProps['children'] | null;
  };
}

export const TicketsTable: React.FC<Props> = ({
  tickets,
  columnProps,
  ticketsLoaded,
  loadTicketsPending,
  loadMoreTicketsPending,
  ticketsTotalCount,
  onLoadMoreClicked,
  onRowClick,
  isRowActive,
  rowHeight = '5.7rem',
  headerHeight = '4.2rem',
  hideHeaders,
  components = {},
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const columns = useMemo(() => {
    const originColumns: TicketsTableColumn[] = [
      {
        id: 'ticketNumber',
        order: 10,
        dataId: 'ticketNumber' as TicketsListColumnId,
        label: t('ticketsTable.column.ticketNumber'),
        formatter: (value: OrderTicket['ticketNumber']) => ({
          value: value || t('common.entityNameUndefined'),
          element: (
            <TicketNumberCell>
              {value || t('common.entityNameUndefined')}
            </TicketNumberCell>
          ),
        }),
      },
      {
        id: 'ticketStatus',
        order: 20,
        dataId: 'ticketStatus' as TicketsListColumnId,
        label: t('ticketsTable.column.ticketStatus'),
        width: '11.2rem',
        formatter: (
          value: OrderTicket['ticketStatus'],
          ticket: OrderTicket,
        ) => ({
          value: ticketStatusMap[ticket.ticketStatus].label,
          element: (
            <TicketStatusCellWrapper>
              <TicketStatusCell
                style={{
                  color:
                    ticket.ticketStatus === APITicketStatuses.Completed &&
                    ticket.order.orderStatus === APIOrderStatus.Completed
                      ? theme.custom.palette.muted200
                      : ticketStatusMap[ticket.ticketStatus].color,
                }}
              >
                {getTicketLabelElement(ticket.ticketStatus)}
              </TicketStatusCell>
              {ticket &&
                shouldShowTicketETA(
                  ticket.ticketStatus,
                  ticket.timeOfArrival?.duration,
                ) && (
                  <TicketETA>
                    <Trans i18nKey="ticketsTable.eta">
                      {{
                        duration: getTruckETA(ticket.timeOfArrival?.duration),
                      }}{' '}
                      away
                    </Trans>
                  </TicketETA>
                )}
            </TicketStatusCellWrapper>
          ),
        }),
      },
      {
        id: 'plantName',
        order: 30,
        dataId: 'plant.name' as TicketsListColumnId,
        label: t('ticketsTable.column.plantName'),
        formatter: (value: OrderTicket['plant']['name']) => ({
          value: value || '',
          element: <PlantNameCell>{value}</PlantNameCell>,
        }),
      },
      {
        id: 'orderName',
        order: 40,
        dataId: 'orderName' as TicketsListColumnId,
        label: t('ticketsTable.column.orderName'),
        width: '20%',
        formatter: (value: OrderTicket['orderName']) => ({
          value: value || '',
          element: <OrderNumberCell>{value}</OrderNumberCell>,
        }),
      },
    ].map(column => {
      const additionalProps = _.find(columnProps, { id: column.id });
      return additionalProps
        ? ({ ...column, ...additionalProps } as TicketsTableColumn)
        : column;
    });

    const [, newColumns] = _.partition(columnProps, it =>
      _.find(originColumns, { id: it.id }),
    );
    const columnsResult: TicketsTableColumn[] = [
      ...originColumns,
      ...newColumns,
    ];
    return columnsResult;
  }, [columnProps, t, theme.custom.palette.muted200]);

  return (
    <Table
      hideHeaders={hideHeaders}
      columns={columns}
      items={tickets}
      itemsTotalCount={ticketsTotalCount}
      itemsLoaded={ticketsLoaded}
      loadItemsPending={loadTicketsPending}
      loadMoreItemsPending={loadMoreTicketsPending}
      onLoadMoreClicked={onLoadMoreClicked}
      onRowClick={onRowClick}
      headerRowHeight={headerHeight}
      bodyRowHeight={rowHeight}
      isRowActive={isRowActive}
      emptyText={t('ticketsTable.emptyText')}
      loadMoreButtonText={t('order.details.tickets.loadMoreButtonText')}
      components={components}
    />
  );
};
