import React, { ForwardedRef, useCallback, useEffect } from 'react';

import styled from 'styled-components';

import { Stack } from 'components';
import { InfiniteLoaderProps } from 'react-virtualized/dist/es/InfiniteLoader';

import { DialogsList } from './DialogsList';

import { VirtualizedList, useSelector } from 'third-party';

import { useDialogsList } from 'modules/dialogsList';

import { appSelectors, dialogsListSelectors } from 'selectors';

const ListWrapper = styled(Stack)`
  flex-grow: 1;
`;

export const AllDialogsTab = React.forwardRef(
  (props, listRef: ForwardedRef<VirtualizedList | null>) => {
    const { loadMoreDialogs, loadDialogs, setDialogsFilter } = useDialogsList();

    const dialogsListItems = useSelector(dialogsListSelectors.dialogsListItems);
    const companyId = useSelector(appSelectors.companyId);
    const dialogsListPredictedTotalCount = useSelector(
      dialogsListSelectors.dialogsListPredictedTotalCount,
    );
    const loadMoreDialogsPending = useSelector(
      dialogsListSelectors.loadMoreDialogsPending,
    );
    const dialogsSearchKeyword = useSelector(
      dialogsListSelectors.searchKeyword,
    );
    const loadDialogsPending = useSelector(
      dialogsListSelectors.loadDialogsPending,
    );
    const dialogsLoaded = useSelector(dialogsListSelectors.dialogsLoaded);

    const loadMore = useCallback<InfiniteLoaderProps['loadMoreRows']>(
      params => {
        // skip loading if we are already loading items or
        // it is initial loading because we load initial batch in the other place
        // to display counter
        if (
          loadDialogsPending ||
          loadMoreDialogsPending ||
          params.startIndex === 0
        ) {
          return Promise.resolve();
        }
        return loadMoreDialogs(companyId);
      },
      [loadDialogsPending, loadMoreDialogsPending, loadMoreDialogs, companyId],
    );

    useEffect(() => {
      setDialogsFilter([]);
      if (dialogsSearchKeyword) {
        loadDialogs(companyId);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <ListWrapper>
        <DialogsList
          loadDialogsPending={loadDialogsPending}
          loadMore={loadMore}
          dialogsList={dialogsListItems}
          dialogsListCount={dialogsListPredictedTotalCount}
          dialogsLoaded={dialogsLoaded}
          ref={listRef}
        />
      </ListWrapper>
    );
  },
);
