import React, { useContext } from 'react';

import { ON_MESSAGE_POST_EVENT } from '@cd3p/core/constants/hubs';
import {
  Notification,
  NotificationSeverity,
} from '@cd3p/core/modules/notifications';
import {
  APIChatMessageModel,
  APIDialogModel,
  APIUserType,
} from '@cd3p/core/types/api';
import { isMessageFromRegularUser } from '@cd3p/core/utils/chat';
import { CacheContext } from 'context/CacheContext';
import { useDialogRead } from 'hooks/useDialogRead';
import { emit } from 'hooks/usePubSub';

import {
  Trans,
  useLocation,
  useMatch,
  useNavigate,
  useParams,
  useSelector,
  useTranslation,
} from 'third-party';

import { APP_EVENTS } from 'constants/appEvent';
import { orderChatUrl, orderUrl } from 'constants/url';

import { useChat } from 'modules/chat';
import { useDialogsList } from 'modules/dialogsList';
import { useNotifications } from 'modules/notifications';
import { useOrdersCache } from 'modules/ordersCache';

import { appSelectors } from 'selectors';

import { updateDialog } from 'utils/globalCache';
import { useIsSubscribedOrder } from 'utils/order';
import { showPushNotification } from 'utils/pushNotification';
import { useOrdersSockets } from 'utils/sockets';

export const ChatHub = () => {
  const { t } = useTranslation();
  const { addNotification } = useNotifications();
  const params = useParams();
  const location = useLocation();
  const matchOrderUrl = useMatch(orderUrl('*'));

  const navigate = useNavigate();

  const { updateOrderInCache } = useOrdersCache();
  const { addUnreadOrderId, removeUnreadOrderId } = useChat();
  const { updateDialogInList } = useDialogsList();

  const isDialogRead = useDialogRead();

  const openedChatOrderId = useSelector(appSelectors.openedChatOrderId);
  const currentUser = useSelector(appSelectors.user);
  const currentUserId = useSelector(appSelectors.userId);
  const currentProviderId = useSelector(appSelectors.providerId);
  const userType = useSelector(appSelectors.userType);
  const isSubscribedOrder = useIsSubscribedOrder();
  const { syncData } = useContext(CacheContext);

  const isUserContractor = userType === APIUserType.Contractor;

  useOrdersSockets(
    () => [
      {
        method: ON_MESSAGE_POST_EVENT,
        action: (
          providerId: string,
          messageOrderId: number,
          senderId: string,
          _dialog: APIDialogModel,
          message: APIChatMessageModel,
        ) => {
          const isRegularMessage = isMessageFromRegularUser(message?.senderId);

          const isCurrentOrderChatIsOpened =
            openedChatOrderId === _dialog.orderId;
          const dialog = {
            ..._dialog,
            readCustomerIds: [
              ...(_dialog?.readCustomerIds || []),
              ...(isCurrentOrderChatIsOpened && isUserContractor
                ? [currentUserId ?? '']
                : []),
            ],
          };

          const showDispatcherChatNotification =
            userType === APIUserType.Dispatcher &&
            !isDialogRead(dialog) &&
            isRegularMessage &&
            message.senderUserType !== APIUserType.Dispatcher &&
            currentUserId !== message.senderId;

          const isRelatedUser =
            currentUser?.companies?.some(it => it.id == dialog.companyId) ||
            // Users with `Sales` accessRole don't have assigned companies,
            // so we check assigned providers in that case.
            currentUser?.providers?.some(it => it.id == dialog.providerId);

          const showContractorChatNotification =
            userType === APIUserType.Contractor &&
            senderId !== currentUserId &&
            isRelatedUser &&
            isRegularMessage &&
            openedChatOrderId !== message.orderId &&
            isSubscribedOrder(message.orderId);

          if (
            document.hidden &&
            (showDispatcherChatNotification || showContractorChatNotification)
          ) {
            showPushNotification(
              `You have a new message for an order ${dialog.orderName}`,
              closeNotification => {
                closeNotification();
                navigate(orderChatUrl(message.orderId));
              },
            );
            // don't execute the rest code if tab is not visible
            // we will do it once it becomes active
            return;
          }
          updateOrderInCache(dialog.orderId, { dialog });

          if (isDialogRead(dialog)) {
            removeUnreadOrderId(dialog.orderId);
          } else if (senderId !== currentUserId) {
            addUnreadOrderId(dialog.orderId);
          }

          const isCurrentProvider = currentProviderId === providerId;

          if (isCurrentProvider) {
            updateDialog(providerId, dialog);
            updateDialogInList(dialog);
          }

          if (isRegularMessage) {
            emit(APP_EVENTS.UPDATE_ORDER_IN_LIST, {
              id: messageOrderId,
              dialog,
            });
          }

          if (
            isSubscribedOrder(message.orderId) &&
            userType === APIUserType.Contractor
          ) {
            syncData();
          }

          const messageNotificationData: Notification = {
            providerId,
            id: `message-for-order-${message.orderId}`,
            severity: NotificationSeverity.Info,
            title: t('chat.notification.newMessageTitle'),
            onClick: closeNotification => {
              closeNotification();
              if (matchOrderUrl && Number(params.id) === message.orderId) {
                // avoid page reload if we're viewing the same order
                navigate(location, {
                  state: { isChatPanelOpened: true },
                });
              } else {
                // reload page with new order
                navigate(orderChatUrl(message.orderId));
              }
            },
            message: (
              <Trans i18nKey="chat.notification.newMessageText">
                You have a new message for an order{' '}
                {{ orderName: dialog.orderName }}
              </Trans>
            ),
          };

          if (
            showDispatcherChatNotification ||
            showContractorChatNotification
          ) {
            showPushNotification(
              `You have a new message for an order ${dialog.orderName}`,
              closeNotification => {
                closeNotification();
                navigate(orderChatUrl(message.orderId));
              },
            );
            addNotification(messageNotificationData);
          }
        },
      },
    ],
    [
      openedChatOrderId,
      isUserContractor,
      currentUserId,
      userType,
      isDialogRead,
      currentUser?.companies,
      currentUser?.providers,
      isSubscribedOrder,
      updateOrderInCache,
      currentProviderId,
      t,
      navigate,
      removeUnreadOrderId,
      addUnreadOrderId,
      updateDialogInList,
      syncData,
      matchOrderUrl,
      params.id,
      location,
      addNotification,
    ],
  );
  return null;
};
