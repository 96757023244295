import { useMemo } from 'react';

import { httpMethod } from '@cd3p/core/utils/sra/types';

import {
  bindActionCreators,
  simplifyBuilder,
  useDispatch,
  useSelector,
} from 'third-party';

import {
  availableTracksEndpoint,
  cancelTruckTrackEndpoint,
  emulateTruckTrackEndpoint,
} from 'constants/endpoints';

import { appSelectors } from 'selectors';

export interface APITrack {
  name: string;
}

export interface APITrackItem {
  truckId: string;
  delay: number;
  trackName: string;
}

export interface TruckEmulatorState {
  availableTracks: APITrack[];
  loadAvailableTracksPending: boolean;
  cancelTruckTrackingPending: boolean;
}

export const truckEmulatorState: TruckEmulatorState = {
  availableTracks: [],
  loadAvailableTracksPending: false,
  cancelTruckTrackingPending: false,
};

const builder = simplifyBuilder(truckEmulatorState, {});

const loadAvailableTracks = builder.createServerAction(
  (providerId: string) => ({
    name: 'loadAvailableTracks',
    url: availableTracksEndpoint(providerId),
    method: httpMethod.get,
    onSuccess: (state: TruckEmulatorState, payload: APITrack[]) => ({
      availableTracks: payload,
    }),
  }),
);

const emulateTruckTrack = builder.createServerAction(
  (providerId: string, trackItem: APITrackItem) => ({
    name: 'updateTruckLocation',
    url: emulateTruckTrackEndpoint(providerId),
    method: httpMethod.post,
    body: trackItem,
    // onSuccess: we don't put anything to store
  }),
);

const cancelTruckTracking = builder.createServerAction(
  (providerId: string, driverId: string) => ({
    name: 'cancelTruckTracking',
    url: cancelTruckTrackEndpoint(providerId, driverId),
    method: httpMethod.post,
  }),
);

export const useTruckEmulator = () => {
  const dispatch = useDispatch();
  const providerId = useSelector(appSelectors.providerId);

  return useMemo(
    () =>
      bindActionCreators(
        {
          loadAvailableTracks: loadAvailableTracks.bind(null, providerId),
          emulateTruckTrack: emulateTruckTrack.bind(null, providerId),
          cancelTruckTracking: cancelTruckTracking.bind(null, providerId),
        },
        dispatch,
      ),
    [dispatch, providerId],
  );
};

export const truckEmulatorReducer = builder.getReducers();
