import React, { useEffect, useState } from 'react';

import { coreChatSelectors } from '@cd3p/core/selectors';
import faviconNotification from 'images/favicon-notification.ico';
import favicon from 'images/favicon.ico';

import { Helmet, useSelector, useTranslation } from 'third-party';

import { chatSelectors, requiredActionsListSelectors } from 'selectors';

const NOTIFICATION_BLINK_TIMEOUT_MS = 2000;

export const BrowserTabMetadata = () => {
  const unreadCount = useSelector(chatSelectors.unreadMessagesCount);
  const unreadOrderIds = useSelector(coreChatSelectors.unreadOrderIds);

  const requiredActionsListCounter = useSelector(
    requiredActionsListSelectors.requiredActionsListCounter,
  );

  const [hasNotification, setHasNotification] = useState(false);

  const { t } = useTranslation();

  const hasUnreadMessages =
    unreadCount > 0 ||
    unreadOrderIds.size > 0 ||
    requiredActionsListCounter > 0;

  useEffect(() => {
    let interval = 0;
    let hash = 0;
    if (hasUnreadMessages) {
      interval = window.setInterval(() => {
        if (hash % 2 === 1) {
          setHasNotification(true);
        } else {
          setHasNotification(false);
        }
        return ++hash;
      }, NOTIFICATION_BLINK_TIMEOUT_MS);
      setHasNotification(true);
    } else {
      setHasNotification(false);
      window.clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [hasUnreadMessages, setHasNotification]);

  return (
    <Helmet defer={false}>
      <title>
        {hasNotification ? t('browser.newNotification') : t('app.name')}
      </title>
      <link
        rel="shortcut icon"
        href={hasNotification ? faviconNotification : favicon}
      />
    </Helmet>
  );
};
