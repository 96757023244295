import React, { CSSProperties } from 'react';

import styled from 'styled-components';

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Typography,
} from 'components';

import { Control, Controller, FieldError } from 'third-party';

const StyledFormHelperText = styled(FormHelperText)``;
const StyledFormControlLabel = styled(FormControlLabel)`
  color: ${props => props.theme.custom.palette.muted500};
`;

type Props = {
  title: string;
  label: string;
  labelStyle?: CSSProperties;
  control: Control<any, any>;
  fieldName: string;
  showError?: true;
  fieldError?: FieldError;
  isRequired?: boolean;
  isDisabled?: boolean;
  requiredErrorMessage?: string;
};

export const FormCheckbox: React.FC<Props> = ({
  title,
  label,
  labelStyle,
  control,
  fieldName,
  showError,
  fieldError,
  isRequired = false,
  isDisabled = false,
  requiredErrorMessage,
}) => {
  return (
    <FormControl style={{ marginTop: title ? '1.6rem' : '0.4rem' }}>
      <Grid container>
        {title && <Typography variant="subtitle2">{title}</Typography>}
        {showError && fieldError?.message && (
          <StyledFormHelperText>{fieldError?.message}</StyledFormHelperText>
        )}
      </Grid>
      <FormGroup>
        <Controller
          rules={{
            required: {
              value: isRequired,
              message: requiredErrorMessage || '',
            },
          }}
          control={control}
          name={fieldName}
          render={({ field: { value, onChange } }) => (
            <StyledFormControlLabel
              control={
                <Checkbox
                  checked={value}
                  disabled={isDisabled}
                  onChange={onChange}
                />
              }
              style={labelStyle}
              label={label}
            />
          )}
        />
      </FormGroup>
    </FormControl>
  );
};
