import { TFunction } from 'i18next';

import { APIOrder, APIProject } from '../types/api';
import { convert24toAmPm, formatDisplayedDate } from '../utils/common';
import { orderTypeField } from '../utils/fields';

export const contractorChangesRequestedFields = (
  t: TFunction,
): {
  name: keyof APIOrder;
  label: string;
  formatter?: (...args: any[]) => string;
}[] => [
  {
    name: 'project',
    label: t('orderDetails.changesRequested.fields.projectLabel'),
    formatter: (project: APIProject) => project.name || '',
  },
  {
    name: 'deliveryLocation',
    label: t('orderDetails.changesRequested.fields.deliveryLocationLabel'),
  },
  {
    name: 'deliveryDate',
    label: t('orderDetails.changesRequested.fields.deliveryDateLabel'),
    formatter: formatDisplayedDate,
  },
  {
    name: 'deliveryTime',
    label: t('orderDetails.changesRequested.fields.deliveryTimeLabel'),
    formatter: convert24toAmPm,
  },
  {
    name: 'volume',
    label: t('orderDetails.changesRequested.fields.volumeLabel'),
  },
  {
    name: 'orderType',
    label: t('orderDetails.changesRequested.fields.orderTypeLabel'),
    formatter: (item) => orderTypeField.displayValue(item, t),
  },
  {
    name: 'callBack',
    label: t('orderDetails.changesRequested.fields.callBackLabel'),
    formatter: (item) => (item ? t('common.yes') : t('common.no')),
  },
  {
    name: 'mixType',
    label: t('orderDetails.changesRequested.fields.mixTypeLabel'),
    formatter: (mixType: { name: string }) => mixType.name || '',
  },
  {
    name: 'deliveryRate',
    label: t('orderDetails.changesRequested.fields.deliveryRateLabel'),
  },
  {
    name: 'deliveryRateType',
    label: t('orderDetails.changesRequested.fields.deliveryRateLabel'),
  },
  {
    name: 'slump',
    label: t('orderDetails.changesRequested.fields.slumpLabel'),
  },
  {
    name: 'typeOfPour',
    label: t('orderDetails.changesRequested.fields.typeOfPour'),
  },
  {
    name: 'placementMethod',
    label: t('orderDetails.changesRequested.fields.placementMethod'),
  },
  {
    name: 'additives',
    label: t('orderDetails.changesRequested.fields.additivesLabel'),
    formatter: (additives: APIOrder['additives']) =>
      (additives || []).map((item) => item.name).join(', '),
  },
  {
    name: 'notes',
    label: t('orderDetails.changesRequested.fields.notesLabel'),
  },
];
