import React, { useCallback, useEffect, useMemo, useState } from 'react';

import styled from 'styled-components';

import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

import { APICompany } from '@cd3p/core/types/api';
import { Button, Popover, Stack, Typography } from 'components';
import { Outlet } from 'react-router-dom';

import {
  Navigate,
  useMatch,
  useNavigate,
  useParams,
  useSelector,
  useTranslation,
} from 'third-party';

import { contractorsUrl } from 'constants/url';

import { useContractorsList } from 'modules/contractorsList';

import { contractorsListSelectors } from 'selectors';

import { AddContractorPopup } from 'features/Contractors/AddContractorPopup';
import { ContractorDetails } from 'features/Contractors/ContractorDetails';
import { ContractorsList } from 'features/Contractors/ContractorsList';

const Wrapper = styled(Stack)`
  flex-grow: 1;
  max-height: 100%;
`;

const Header = styled(Typography)`
  color: ${props => props.theme.custom.palette.secondary500};
  margin: 2.1rem 0;
  flex-shrink: 0;
  display: inline;
`;

const ContractorsListPanel = styled(Stack)`
  width: 42rem;
  border-right: 2px solid ${props => props.theme.custom.palette.muted100};
  padding-left: 1.47rem;
  flex-shrink: 0;
`;

const AddContractorButton = styled(Button)`
  text-indent: 1rem;
  padding-left: 0;
  color: ${props => props.theme.custom.palette.secondary500};
`;

const ButtonWrapper = styled(Stack)`
  padding-left: 1.47rem;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
`;

const Separator = styled.div`
  position: relative;
  height: 0.1rem;
  background: ${props => props.theme.custom.palette.muted100};
  z-index: 2;
  flex-shrink: 0;
`;

const ContractorsCount = styled(Typography)`
  font-weight: 600;
  font-size: 1.8rem;
`;

const EmptyHeader = styled(Typography)`
  color: ${props => props.theme.custom.palette.secondary500};
  margin-bottom: 2rem;
`;

const EmptyDescription = styled(Typography)`
  font-weight: 600;
`;

const EmptyStateWrapper = styled(Stack)`
  gap: 1rem;
  max-width: 39rem;
  padding: 2.5rem 1.7rem;
  align-items: flex-start;
`;

const EmptyStateSubTitle = styled(Typography)`
  font-weight: 600;
  margin-bottom: 1.8rem;
`;

const ContractorDetailsPanel = styled(Stack)`
  padding: 4rem 3rem;
  flex-grow: 1;
`;

export const ContractorsViewWrapper = () => {
  const { resetContractorsList } = useContractorsList();

  useEffect(() => {
    return () => {
      resetContractorsList();
    };
  }, [resetContractorsList]);
  return <Outlet />;
};

export const ContractorsView = () => {
  const { t } = useTranslation();
  const { contractorId } = useParams();
  const navigate = useNavigate();
  const matchIndexRoute = useMatch(contractorsUrl());
  const { updateContractors, loadContractors, updateContractorsListFilters } =
    useContractorsList();
  const contractorsListCount = useSelector(
    contractorsListSelectors.contractorsListCount,
  );
  const firstCompanyInList = useSelector(
    contractorsListSelectors.firstCompanyInList,
  );
  const contractorsLoaded = useSelector(
    contractorsListSelectors.contractorsLoaded,
  );
  const contractorsListNameFilter = useSelector(
    contractorsListSelectors.contractorsListNameFilter,
  );
  const loadContractorsPending = useSelector(
    contractorsListSelectors.loadContractorsPending,
  );

  const [showAddContractorPopup, setShowAddContractorPopup] = useState(false);
  const [showDeactivated, setShowDeactivated] = useState(false);

  const onAddContractor = useCallback(
    (contractor: APICompany) => {
      navigate(contractorsUrl(contractor.id.toString()));
      updateContractors();
    },
    [navigate, updateContractors],
  );

  const onAddContractorButtonClicked = useCallback(() => {
    setShowAddContractorPopup(true);
  }, []);

  const addButton = useMemo(() => {
    return (
      <AddContractorButton onClick={onAddContractorButtonClicked}>
        <AddBoxOutlinedIcon />
        {t('customers.addCustomerButton')}
      </AddContractorButton>
    );
  }, [t, onAddContractorButtonClicked]);

  const emptyState = useMemo(() => {
    return (
      <EmptyStateWrapper>
        <Typography variant="h5">
          {t('customers.table.emptyStateTitleCustomers')}
        </Typography>
        <EmptyStateSubTitle variant="h6">
          {t('customers.table.emptyStateSubTitle')}
        </EmptyStateSubTitle>
        {addButton}
      </EmptyStateWrapper>
    );
  }, [addButton, t]);

  const updateVisibilityFilter = useCallback(() => {
    const visibilityFilter = !showDeactivated;
    const filtersValue = {
      name: contractorsListNameFilter,
      includeDeactivated: visibilityFilter,
    };
    setShowDeactivated(visibilityFilter);
    loadContractors(filtersValue);
    updateContractorsListFilters(filtersValue);
  }, [
    contractorsListNameFilter,
    loadContractors,
    showDeactivated,
    updateContractorsListFilters,
  ]);

  return (
    <Wrapper direction="row">
      <ContractorsListPanel>
        <Stack
          direction="row"
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Header variant="h4">{t('customers.header')}</Header>
          {showAddContractorPopup && (
            <Popover
              anchorEl={document.body}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'center',
              }}
              open
              PaperProps={{ sx: { overflow: 'visible' } }}
            >
              <AddContractorPopup
                onSuccess={onAddContractor}
                onClosePopup={() => setShowAddContractorPopup(false)}
              />
            </Popover>
          )}
        </Stack>
        <ButtonWrapper direction="row">
          <ContractorsCount>
            {t('customers.table.totalCount', {
              count: contractorsListCount || 0,
            })}
          </ContractorsCount>
          <Button onClick={updateVisibilityFilter}>
            {showDeactivated ? (
              <>
                <VisibilityOutlinedIcon sx={{ marginRight: '1rem' }} />
                {t('customers.header.hideDeactivated')}
              </>
            ) : (
              <>
                <VisibilityOffOutlinedIcon sx={{ marginRight: '1rem' }} />
                {t('customers.header.showDeactivated')}
              </>
            )}
          </Button>
        </ButtonWrapper>
        <Separator />
        <ContractorsList
          emptyState={emptyState}
          selectedContractorId={contractorId}
          onAddContractor={onAddContractorButtonClicked}
        />
      </ContractorsListPanel>
      <ContractorDetailsPanel>
        {contractorId && <ContractorDetails />}
        {!contractorsListCount &&
          !loadContractorsPending &&
          contractorsLoaded &&
          !contractorsListNameFilter && (
            <>
              <EmptyHeader variant="h3">
                {t('customers.emptyStateTitle')}
              </EmptyHeader>
              <EmptyDescription>
                {t('customers.emptyStateDescription')}
              </EmptyDescription>
            </>
          )}
      </ContractorDetailsPanel>
      {matchIndexRoute && firstCompanyInList && (
        <Navigate
          replace
          to={contractorsUrl(firstCompanyInList.id.toString())}
        />
      )}
    </Wrapper>
  );
};
