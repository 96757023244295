import { State } from 'modules';
import { ticketsMapper } from 'selectors/common/tickets';
import * as trackingSelectors from 'selectors/tracking';

import { createSelector } from 'third-party';

export const activeTicketsState = (state: State) => state.activeTicketsList;

export const activeTicketsItems = (state: State) =>
  state.activeTicketsList.tickets.items;

export const activeTickets = createSelector(
  [activeTicketsItems, trackingSelectors.trackingItems],
  ticketsMapper,
);

export const activeTicketsLoaded = (state: State) =>
  state.activeTicketsList.tickets.loaded;
export const loadActiveTicketsPending = (state: State) =>
  state.activeTicketsList.loadActiveTicketsPending;
export const loadMoreActiveTicketsPending = (state: State) =>
  state.activeTicketsList.loadMoreActiveTicketsPending;
export const activeTicketsTotalCount = (state: State) =>
  state.activeTicketsList.tickets.count;

export const activeTicketsSorting = (state: State) =>
  state.activeTicketsList.tickets.sorting;
export const activeTicketsPagination = (state: State) =>
  state.activeTicketsList.tickets.pagination;
export const activeTicketsFilters = (state: State) =>
  state.activeTicketsList.tickets.filters;
