import { State } from 'modules';

export const customerProjectsItems = (state: State) =>
  state.customerProjects.items;
export const customerProjectsCount = (state: State) =>
  state.customerProjects.count;
export const customerProjectsLoaded = (state: State) =>
  state.customerProjects.customerProjectsLoaded;
export const customerProjectsSorting = (state: State) =>
  state.customerProjects.sorting;
export const showArchivedProjects = (state: State) =>
  state.customerProjects.filters.showArchived;
export const loadCustomerProjectsPending = (state: State) =>
  state.customerProjects.loadCustomerProjectsPending;
export const loadMoreCustomerProjectsPending = (state: State) =>
  state.customerProjects.loadMoreCustomerProjectsPending;
export const searchKeyword = (state: State) =>
  state.customerProjects.searchKeyword;
