import React, { useEffect, useMemo, useState } from 'react';

import styled from 'styled-components';

import {
  APIOrderStatus,
  APIProvider,
  APIProviderSettings,
} from '@cd3p/core/types/api';
import { phoneField, textField } from '@cd3p/core/utils/fields';
import {
  CircularProgress,
  Divider,
  FormControlLabel,
  FormField,
  FormHelperText,
  FormNumberField,
  Grid,
  LocationField,
  MenuItem,
  Stack,
  Switch,
  Typography,
} from 'components';
import { useHandleApiResult } from 'hooks/useHandleApiResult';
import i18n from 'i18n';

import {
  AFTER_HOURS_NOTICE_FORM_KEY,
  AfterHoursNotice,
  AfterHoursNoticeFormT,
  parseAfterHoursNoticeFromJsonToFormData,
} from './AfterHoursNotice';

import {
  FieldError,
  FormProvider,
  _,
  useForm,
  useSelector,
  useTranslation,
} from 'third-party';

import { useAdminCustomerAccountsList } from 'modules/adminCustomerAccountsList';
import { useApp } from 'modules/app';
import { useCache } from 'modules/cache';

import { appSelectors, cacheSelectors } from 'selectors';

import { TypeaheadOption } from 'types/app';

const Wrapper = styled(Stack)`
  align-items: center;
  min-height: 0;
  overflow-y: auto;
`;

const Header = styled(Typography)`
  color: ${props => props.theme.custom.palette.secondary900};
  margin-top: 2rem;
`;

const Inner = styled(Grid)`
  flex-grow: 1;
  // this padding is needed to avoid
  // menu sides cutting by overflow hidden
  padding: 0 0.2rem 2rem;
  width: 65rem;
`;

const BackendHeader = styled(Typography)`
  font-size: 1.4rem !important;
  color: ${props => props.theme.custom.palette.bluegray600};
  margin-top: 2rem;
`;

const DividerStyled = styled(Divider)`
  margin-top: 2rem;
  border-color: ${({ theme }) => theme.custom.palette.muted100};
`;

const CompanyProfileWrapper = styled(Stack)`
  padding: 0 23.3rem 1.6rem 0;
  border-bottom: 1px solid ${props => props.theme.custom.palette.muted100};
  margin-bottom: 1.6rem;
`;

const SectionDescription = styled(Typography)`
  font-size: 1.6rem;
  font-weight: 400;
  color: ${props => props.theme.custom.palette.darkText};
  margin-top: 1rem;
`;

const NotificationsSectionTitle = styled(Typography)`
  font-size: 1.6rem;
  color: ${props => props.theme.custom.palette.darkText};
  margin-top: 2rem;
  font-weight: 900;
  text-transform: uppercase;
`;

const StyledFormField = styled(FormField)`
  max-width: 41.7rem;
`;

const HoursFormField = styled(FormNumberField)`
  max-width: 41.7rem;
  margin-left: 1.2rem;
`;

const SwitchLabel = styled(Typography)`
  font-size: 1.6rem;
  font-weight: 600;
  color: ${props => props.theme.custom.palette.darkText};
  margin-left: 1rem;
`;

const NotificationsFormWrapper = styled(Stack)`
  flex-direction: row;
`;

export const NotificationsFormError = styled(FormHelperText)`
  padding: 8rem 4rem 0 4rem;
  font-size: 1.4rem;
  line-height: 2.1rem;
  font-weight: 400;
  color: ${props => props.theme.custom.palette.error500};
  width: 20rem;
  box-sizing: border-box;
`;

const StyledForm = styled.form`
  padding-bottom: 2rem;
`;

const CHANGE_REQUEST_OPTIONS = [
  {
    value: [APIOrderStatus.Requested, APIOrderStatus.Unconfirmed].join(','),
    label: i18n.t('settings.companySettings.form.changeRequestOption1'),
  },
  {
    value: [
      APIOrderStatus.Requested,
      APIOrderStatus.Unconfirmed,
      APIOrderStatus.Confirmed,
    ].join(','),
    label: i18n.t('settings.companySettings.form.changeRequestOption2'),
  },
  // {
  //   value: [
  //     APIOrderStatus.Requested,
  //     APIOrderStatus.Unconfirmed,
  //     APIOrderStatus.Confirmed,
  //     APIOrderStatus.Delivering,
  //   ].join(','),
  //   label: i18n.t('settings.companySettings.form.changeRequestOption3'),
  // },
];

enum FormFields {
  CompanyName = 'ProviderName',
  CompanyAddress = 'ProviderAddress',
  CompanyPhone = 'ProviderPhone',
  CompanyTimeZone = 'TimeZone',
  ContractorEditingAllowedStates = 'ContractorEditingAllowedStates',
  FirstReminderHours = 'OrderConfirmationFirstReminderHours',
  EnableSecondReminder = 'OrderConfirmationSecondReminderEnabled',
  SecondReminderHours = 'OrderConfirmationSecondReminderHours',
  LocationHistoryEnabled = 'IsLocationHistoryEnabled',
  ApiDocsFeatureEnabled = 'IsApiDocsFeatureEnabled',
}

const formDefaultValues = {
  [FormFields.CompanyName]: '',
  [FormFields.CompanyAddress]: null,
  [FormFields.CompanyPhone]: '',
  [FormFields.CompanyTimeZone]: '',
  [FormFields.ContractorEditingAllowedStates]: '',
  [FormFields.FirstReminderHours]: '24',
  [FormFields.EnableSecondReminder]: false,
  [FormFields.SecondReminderHours]: '2',
  [FormFields.LocationHistoryEnabled]: false,
  [FormFields.ApiDocsFeatureEnabled]: false,
};

type FormData = {
  [FormFields.CompanyName]: string;
  [FormFields.CompanyAddress]: TypeaheadOption | null;
  [FormFields.CompanyPhone]: string;
  [FormFields.CompanyTimeZone]: string;
  [FormFields.ContractorEditingAllowedStates]: string;
  [FormFields.FirstReminderHours]: string;
  [FormFields.EnableSecondReminder]: boolean;
  [FormFields.SecondReminderHours]: string;
  [FormFields.LocationHistoryEnabled]: boolean;
  [FormFields.ApiDocsFeatureEnabled]: boolean;
};

type Props = {
  contractorId: string;
  adminEditRights?: boolean;
  data: Partial<FormData>;
  afterHoursFormData: AfterHoursNoticeFormT[];
};

export const CompanySettingsForm: React.FC<Props> = ({
  contractorId,
  adminEditRights,
  data,
  afterHoursFormData,
}) => {
  const { t } = useTranslation();

  const { loadAddress, loadAddressLocation } = useCache();
  const { updateProviderSettings } = useApp();
  const { updateCustomerAccountInList } = useAdminCustomerAccountsList();

  const handleApiResult = useHandleApiResult();

  const timezones = useSelector(cacheSelectors.timezones);

  const formMethods = useForm<FormData>({
    defaultValues: { ...formDefaultValues, ...data },
    mode: 'onChange',
  });

  const {
    trigger,
    watch,
    getValues,
    setValue,
    control,
    formState: { errors },
  } = formMethods;

  const watchedCompanyAddressField = watch(FormFields.CompanyAddress);
  const watchedCompanyTimeZoneField = watch(FormFields.CompanyTimeZone);
  const watchedContractorEditingAllowedStates = watch(
    FormFields.ContractorEditingAllowedStates,
  );
  const watchedFirstReminderHours = watch(FormFields.FirstReminderHours);
  const watchedEnableSecondReminder = watch(FormFields.EnableSecondReminder);
  const watchedSecondReminderHours = watch(FormFields.SecondReminderHours);

  const showRemindersValidationError = useMemo(() => {
    return (
      watchedEnableSecondReminder &&
      Number(watchedFirstReminderHours) <= Number(watchedSecondReminderHours)
    );
  }, [
    watchedEnableSecondReminder,
    watchedFirstReminderHours,
    watchedSecondReminderHours,
  ]);

  const timeZoneOptions = useMemo(() => {
    return timezones.map(it => (
      <MenuItem
        key={it.zoneId}
        value={it.zoneId}
        selected={it.zoneId === watchedCompanyTimeZoneField}
      >
        {`${it.offset} ${it.countryName} ${it.zoneName}`}
      </MenuItem>
    ));
  }, [timezones, watchedCompanyTimeZoneField]);

  const changeRequestOptions = useMemo(() => {
    return CHANGE_REQUEST_OPTIONS.map(it => (
      <MenuItem
        key={it.value}
        value={it.value}
        selected={it.value === watchedContractorEditingAllowedStates}
      >
        {it.label}
      </MenuItem>
    ));
  }, [watchedContractorEditingAllowedStates]);

  const autoSaveField = _.debounce(async (fieldName: FormFields) => {
    const isValidValue = async () => {
      switch (fieldName) {
        case FormFields.FirstReminderHours:
        case FormFields.EnableSecondReminder:
        case FormFields.SecondReminderHours:
          return (
            (await trigger(FormFields.EnableSecondReminder)) &&
            (await trigger(FormFields.FirstReminderHours)) &&
            (await trigger(FormFields.SecondReminderHours))
          );
        default:
          return await trigger(fieldName);
      }
    };

    const getAPIValue = () => {
      switch (fieldName) {
        case FormFields.CompanyAddress:
          return [{ key: fieldName, value: getValues(fieldName)?.value || '' }];
        case FormFields.FirstReminderHours:
        case FormFields.EnableSecondReminder:
        case FormFields.SecondReminderHours:
          return [
            {
              key: FormFields.FirstReminderHours,
              value: getValues(FormFields.FirstReminderHours),
            },
            {
              key: FormFields.SecondReminderHours,
              value: getValues(FormFields.SecondReminderHours) || '0',
            },
            {
              key: FormFields.EnableSecondReminder,
              value: getValues(FormFields.EnableSecondReminder)
                ? 'true'
                : 'false',
            },
          ];
        case FormFields.ApiDocsFeatureEnabled:
        case FormFields.LocationHistoryEnabled:
          return [
            {
              key: fieldName,
              value: getValues(fieldName) ? 'true' : 'false',
            },
          ];
        default:
          return [{ key: fieldName, value: getValues(fieldName) }];
      }
    };

    if (await isValidValue()) {
      handleApiResult(() =>
        updateProviderSettings(contractorId, getAPIValue()),
      );
      if (fieldName === FormFields.CompanyName) {
        updateCustomerAccountInList(contractorId, {
          providerName: getValues(fieldName),
        });
      }
    }
  }, 500);

  return (
    <Wrapper>
      <Inner>
        <Header variant="subtitle2">
          {t('settings.companySettings.companyProfile')}
        </Header>
        <FormProvider {...formMethods}>
          <StyledForm>
            <CompanyProfileWrapper>
              <FormField
                isRequired
                fieldName={FormFields.CompanyName}
                onBlur={() => autoSaveField(FormFields.CompanyName)}
                label={t('settings.companySettings.form.companyNameLabel')}
                fieldError={errors[FormFields.CompanyName]}
                requiredErrorMessage={t('common.form.emptyFieldError')}
                control={control}
                placeholder={t(
                  'settings.companySettings.form.companyNamePlaceholder',
                )}
                inputProps={{
                  maxLength: textField.maxLength,
                }}
              />
              <LocationField
                isRequired
                showError
                fieldName={FormFields.CompanyAddress}
                errors={errors}
                control={control}
                onChange={() => autoSaveField(FormFields.CompanyAddress)}
                value={watchedCompanyAddressField}
                setValue={setValue}
                label={t('settings.companySettings.form.companyAddressLabel')}
                placeholder={t(
                  'settings.companySettings.form.companyAddressPlaceholder',
                )}
                loadAddress={loadAddress}
                loadAddressLocation={loadAddressLocation}
              />
              <FormField
                showError
                isRequired={false}
                onBlur={() => autoSaveField(FormFields.CompanyPhone)}
                fieldName={FormFields.CompanyPhone}
                label={t('settings.companySettings.form.companyPhoneLabel')}
                placeholder={t(
                  'settings.companySettings.form.companyPhonePlaceholder',
                )}
                control={control}
                inputProps={{
                  maxLength: phoneField.maxLength,
                }}
                fieldError={errors[FormFields.CompanyPhone]}
                mask={phoneField.mask}
                maskDefinitions={phoneField.maskDefinitions}
                rules={{
                  validate: {
                    incorrectPhone: (value: string) =>
                      !value ||
                      phoneField.validate(value) ||
                      t('common.form.phone.error.invalid'),
                  },
                }}
              />
              <FormField
                isRequired
                fieldName={FormFields.CompanyTimeZone}
                onChange={() => autoSaveField(FormFields.CompanyTimeZone)}
                label={t('settings.companySettings.form.companyTimeZoneLabel')}
                fieldError={errors[FormFields.CompanyTimeZone]}
                requiredErrorMessage={t('common.form.emptyFieldError')}
                control={control}
                selectOptions={timeZoneOptions}
                placeholder={t(
                  'settings.companySettings.form.companyTimeZonePlaceholder',
                )}
              />
            </CompanyProfileWrapper>
            <Header variant="subtitle2">
              {t('settings.companySettings.changeRequestHeader')}
            </Header>
            <SectionDescription>
              {t('settings.companySettings.changeRequestDescription')}
            </SectionDescription>
            <StyledFormField
              isRequired
              fieldName={FormFields.ContractorEditingAllowedStates}
              onChange={() =>
                autoSaveField(FormFields.ContractorEditingAllowedStates)
              }
              label={t('settings.companySettings.form.changeRequestLabel')}
              fieldError={errors[FormFields.ContractorEditingAllowedStates]}
              requiredErrorMessage={t('common.form.emptyFieldError')}
              control={control}
              selectOptions={changeRequestOptions}
              placeholder={t(
                'settings.companySettings.form.changeRequestPlaceholder',
              )}
            />
            <Header style={{ marginTop: '3rem' }} variant="subtitle2">
              {t('settings.companySettings.confirmationNotificationsHeader')}
            </Header>
            <SectionDescription>
              {t(
                'settings.companySettings.confirmationNotificationsDescription',
              )}
            </SectionDescription>
            <NotificationsFormWrapper>
              <Stack flexGrow={1}>
                <NotificationsSectionTitle>
                  {t('settings.companySettings.firstReminder')}
                </NotificationsSectionTitle>

                <HoursFormField
                  isRequired
                  fieldName={FormFields.FirstReminderHours}
                  label={t(
                    'settings.companySettings.hoursBeforeOrderStartLabel',
                  )}
                  placeholder={t(
                    'settings.companySettings.hoursBeforeOrderStartPlaceholder',
                  )}
                  onBlur={() => autoSaveField(FormFields.FirstReminderHours)}
                  fieldError={errors[FormFields.FirstReminderHours]}
                  requiredErrorMessage={t('common.form.emptyFieldError')}
                  control={control}
                  rules={{
                    validate: {
                      lessThanZero: (value: string) =>
                        Number(value) > 0 ||
                        t('common.form.error.lessThanZero'),
                      incorrectField: (value: string) =>
                        !(
                          watchedEnableSecondReminder &&
                          Number(value) <= Number(watchedSecondReminderHours)
                        ) || ' ',
                    },
                    deps: [
                      FormFields.EnableSecondReminder,
                      FormFields.SecondReminderHours,
                    ],
                  }}
                  inputProps={{
                    maxLength: 3,
                  }}
                />
                <NotificationsSectionTitle>
                  {t('settings.companySettings.secondReminder')}
                </NotificationsSectionTitle>
                <FormField
                  fieldName={FormFields.EnableSecondReminder}
                  fieldError={
                    errors?.[FormFields.EnableSecondReminder] as
                      | FieldError
                      | undefined
                  }
                  control={control}
                  isRequired={false}
                  rules={{
                    deps: [
                      FormFields.FirstReminderHours,
                      FormFields.SecondReminderHours,
                    ],
                  }}
                  render={({ field }) => (
                    <FormControlLabel
                      sx={{ width: '10rem' }}
                      control={
                        <Switch
                          sx={{ marginLeft: '2.5rem' }}
                          onChange={value => {
                            field.onChange(value);
                            autoSaveField(FormFields.EnableSecondReminder);
                          }}
                          value={field.value}
                          checked={field.value}
                        />
                      }
                      label={
                        <SwitchLabel>
                          {field.value ? t('common.on') : t('common.off')}
                        </SwitchLabel>
                      }
                    />
                  )}
                />
                <HoursFormField
                  isRequired={watchedEnableSecondReminder}
                  isDisabled={!watchedEnableSecondReminder}
                  fieldName={FormFields.SecondReminderHours}
                  label={t(
                    'settings.companySettings.hoursBeforeOrderStartLabel',
                  )}
                  placeholder={t(
                    'settings.companySettings.hoursBeforeOrderStartPlaceholder',
                  )}
                  onBlur={() => autoSaveField(FormFields.SecondReminderHours)}
                  fieldError={errors[FormFields.SecondReminderHours]}
                  requiredErrorMessage={t('common.form.emptyFieldError')}
                  control={control}
                  rules={{
                    validate: {
                      lessThanZero: (value: string) =>
                        !(watchedEnableSecondReminder && Number(value) <= 0) ||
                        t('common.form.error.lessThanZero'),
                      incorrectField: (value: string) =>
                        !(
                          watchedEnableSecondReminder &&
                          Number(watchedFirstReminderHours) <= Number(value)
                        ) || ' ',
                    },
                    deps: [
                      FormFields.EnableSecondReminder,
                      FormFields.FirstReminderHours,
                    ],
                  }}
                  inputProps={{
                    maxLength: 3,
                  }}
                />
              </Stack>
              {showRemindersValidationError && (
                <NotificationsFormError>
                  {t('settings.companySettings.form.incorrectReminders')}
                </NotificationsFormError>
              )}
            </NotificationsFormWrapper>
          </StyledForm>
        </FormProvider>
        <AfterHoursNotice
          contractorId={contractorId}
          defaultData={afterHoursFormData}
        />
        {adminEditRights && (
          <>
            <DividerStyled />
            <BackendHeader variant="subtitle2">
              {t('settings.companySettings.backendHeader')}
            </BackendHeader>
            <Header style={{ marginTop: '3rem' }} variant="subtitle2">
              {t('settings.companySettings.apiDocumentationHeader')}
            </Header>
            <SectionDescription>
              {t('settings.companySettings.apiDocumentationDescription')}
            </SectionDescription>
            <NotificationsSectionTitle>
              {t('settings.companySettings.apiDocumentationLabel')}
            </NotificationsSectionTitle>
            <FormField
              fieldName={FormFields.ApiDocsFeatureEnabled}
              fieldError={
                errors?.[FormFields.ApiDocsFeatureEnabled] as
                  | FieldError
                  | undefined
              }
              control={control}
              isRequired={false}
              render={({ field }) => (
                <FormControlLabel
                  sx={{ width: '10rem' }}
                  control={
                    <Switch
                      sx={{ marginLeft: '2.5rem' }}
                      onChange={value => {
                        field.onChange(value);
                        autoSaveField(FormFields.ApiDocsFeatureEnabled);
                      }}
                      value={field.value}
                      checked={field.value}
                    />
                  }
                  label={
                    <SwitchLabel>
                      {field.value ? t('common.on') : t('common.off')}
                    </SwitchLabel>
                  }
                />
              )}
            />
            <Header style={{ marginTop: '3rem' }} variant="subtitle2">
              {t('settings.companySettings.truckLocationDataHeader')}
            </Header>
            <SectionDescription>
              {t('settings.companySettings.truckLocationDataDescription')}
            </SectionDescription>
            <NotificationsSectionTitle>
              {t('settings.companySettings.truckLocationDataLabel')}
            </NotificationsSectionTitle>
            <FormField
              fieldName={FormFields.LocationHistoryEnabled}
              fieldError={
                errors?.[FormFields.LocationHistoryEnabled] as
                  | FieldError
                  | undefined
              }
              control={control}
              isRequired={false}
              render={({ field }) => (
                <FormControlLabel
                  sx={{ width: '10rem' }}
                  control={
                    <Switch
                      sx={{ marginLeft: '2.5rem' }}
                      onChange={value => {
                        field.onChange(value);
                        autoSaveField(FormFields.LocationHistoryEnabled);
                      }}
                      value={field.value}
                      checked={field.value}
                    />
                  }
                  label={
                    <SwitchLabel>
                      {field.value ? t('common.on') : t('common.off')}
                    </SwitchLabel>
                  }
                />
              )}
            />
          </>
        )}
      </Inner>
    </Wrapper>
  );
};

type CompanySettingsTabProps = {
  adminEditRights?: boolean;
  contractorId?: string;
  onContractorLoaded?: (data: APIProvider) => void;
  onProviderSettingsLoaded?: (date: APIProviderSettings[]) => void;
};

export const CompanySettingsTab: React.FC<CompanySettingsTabProps> = ({
  adminEditRights,
  onContractorLoaded,
  onProviderSettingsLoaded,
  ...props
}) => {
  const {
    fetchProvider,
    fetchProviderSettings,
    loadProviderSettings,
    getUserInfo,
  } = useApp();
  const { loadTimezones } = useCache();

  const [formData, setFormData] = useState<Partial<FormData>>({});
  const [afterHoursFormData, setAfterHoursFormData] = useState<
    Array<AfterHoursNoticeFormT>
  >([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const userProviderId = useSelector(appSelectors.providerId);
  const contractorId = props.contractorId || userProviderId;

  const handleApiResult = useHandleApiResult();

  useEffect(() => {
    const providerDetailsPromise = new Promise(resolve => {
      handleApiResult<APIProvider>(
        () => fetchProvider(contractorId),
        ({ result }) => {
          setFormData(state => ({
            ...state,
            [FormFields.CompanyName]: result.payload.providerName || '',
            [FormFields.CompanyPhone]: result.payload.phone || '',
            [FormFields.CompanyAddress]: result.payload.officeAddress
              ? {
                  label: result.payload.officeAddress || '',
                  value: result.payload.officeAddress || '',
                }
              : null,
          }));
          onContractorLoaded?.(result.payload);
          resolve(true);
        },
      );
    });

    const providerSettingsPromise = new Promise(resolve => {
      handleApiResult<APIProviderSettings[]>(
        () => fetchProviderSettings(contractorId),
        ({ result }) => {
          setFormData(state => ({
            ...state,
            ...[
              FormFields.CompanyTimeZone,
              FormFields.ContractorEditingAllowedStates,
              FormFields.FirstReminderHours,
              FormFields.SecondReminderHours,
            ].reduce(
              (obj, fieldName) => ({
                ...obj,
                [fieldName]:
                  result.payload.find(it => it.key === fieldName)?.value ||
                  formDefaultValues[fieldName],
              }),
              {},
            ),
            ...[
              FormFields.EnableSecondReminder,
              FormFields.LocationHistoryEnabled,
              FormFields.ApiDocsFeatureEnabled,
            ].reduce(
              (obj, fieldName) => ({
                ...obj,
                [fieldName]:
                  result.payload.find(it => it.key === fieldName)?.value ===
                  'true',
              }),
              {},
            ),
          }));
          setAfterHoursFormData([
            ...parseAfterHoursNoticeFromJsonToFormData(
              result.payload.find(it => it.key === AFTER_HOURS_NOTICE_FORM_KEY)
                ?.value,
            ),
          ]);
          onProviderSettingsLoaded?.(result.payload);
          resolve(true);
        },
      );
    });

    const timezonesPromise = new Promise(resolve =>
      handleApiResult(() => loadTimezones('US'), resolve),
    );

    Promise.all([
      providerDetailsPromise,
      providerSettingsPromise,
      timezonesPromise,
    ]).then(() => setIsLoaded(true));
  }, [
    fetchProvider,
    fetchProviderSettings,
    handleApiResult,
    loadTimezones,
    contractorId,
    onContractorLoaded,
    onProviderSettingsLoaded,
  ]);

  useEffect(() => {
    return () => {
      // update redux store with the possibly
      // new data
      loadProviderSettings(contractorId);
      getUserInfo();
    };
  }, [contractorId, loadProviderSettings, getUserInfo]);

  return isLoaded ? (
    <CompanySettingsForm
      contractorId={contractorId}
      adminEditRights={adminEditRights}
      data={formData}
      afterHoursFormData={afterHoursFormData}
    />
  ) : (
    <CircularProgress />
  );
};
