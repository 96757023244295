import React from 'react';

import { GMAP_API_KEY } from 'constants/common';

type Location = {
  lat: number;
  lng: number;
};

type Props = {
  alt?: string;
  width?: number;
  lat?: number;
  long?: number;
  height?: number;
  zoom?: number;
  polygonWeight?: number;
  center: Location;
  radius?: number;
  mapType?: 'satellite' | 'hybrid' | 'roadmap';
  polygon?: Location[];
  markerLocation?: Location;
  markerIcon?: string;
};

const toRadians = (degrees: number) => degrees * (Math.PI / 180);
const toDegrees = (radians: number) => radians * (180 / Math.PI);

const getCirclePoints = (center: Location, radius: number | null) => {
  if (radius == null) {
    return [];
  }

  const circlePoints: Location[] = [];

  // Convert center coordinates to radians
  const lat_rad = toRadians(center.lat);
  const lonRad = toRadians(center.lng);
  const dist = radius / 6378137;

  // Calculate circle path point for each 5 degrees
  for (let deg = 0; deg < 360; deg += 5) {
    const rad = toRadians(deg);

    // Calculate coordinates of next circle path point
    const newLat = Math.asin(
      Math.sin(lat_rad) * Math.cos(dist) +
        Math.cos(lat_rad) * Math.sin(dist) * Math.cos(rad),
    );

    const newlng =
      lonRad +
      Math.atan2(
        Math.sin(rad) * Math.sin(dist) * Math.cos(lat_rad),
        Math.cos(dist) - Math.sin(lat_rad) * Math.sin(newLat),
      );

    // Convert new lat and lon to degrees
    const new_lat_deg = toDegrees(newLat);
    const new_lon_deg = toDegrees(newlng);

    circlePoints.push({ lat: new_lat_deg, lng: new_lon_deg });
  }

  if (circlePoints.length) {
    circlePoints.push(circlePoints[0]);
  }

  return circlePoints;
};

export const StaticMapPreview: React.FC<Props> = ({
  width = 400,
  height = 400,
  center,
  zoom = 10,
  mapType = 'roadmap',
  polygon = null,
  polygonWeight = 2,
  markerLocation = null,
  markerIcon = null,
  radius = null,
  alt = '',
}) => {
  const url = new URL('https://maps.googleapis.com/maps/api/staticmap');

  url.searchParams.append('center', `${center.lat},${center.lng}`);
  url.searchParams.append('zoom', zoom.toString());
  url.searchParams.append('size', `${width}x${height}`);
  url.searchParams.append('key', GMAP_API_KEY);
  url.searchParams.append('maptype', mapType);

  if (polygon || radius) {
    const points = polygon ? polygon : getCirclePoints(center, radius);

    const polygonLine = points.map(it => `${it.lat},${it.lng}`).join('|');
    url.searchParams.append(
      'path',
      `color:0x1E5565|fillcolor:0xAFD8D4|weight:${polygonWeight}|${polygonLine}`,
    );
  }

  if (markerLocation) {
    url.searchParams.append(
      'markers',
      `anchor:center|icon:${markerIcon}|${markerLocation.lat},${markerLocation.lng}`,
    );
  }

  return <img width={width} height={height} src={url.toString()} alt={alt} />;
};
