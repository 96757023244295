import { useMemo } from 'react';

import { httpMethod, simplifyBuilder } from '@cd3p/core';
import {
  APIProject,
  APIProjectSearchResult,
  APIProjectStatus,
  APISortOrder,
} from '@cd3p/core/types/api';
import {
  DEFAULT_PAGE_SIZE,
  getPageNumberAfterLoadMore,
  getPageSizeDependingOnItems,
} from '@cd3p/core/utils/lists';

import { _, bindActionCreators, useDispatch, useSelector } from 'third-party';

import {
  fetchProjectsListEndpoint,
  projectEndpoint,
} from 'constants/endpoints';

import { State } from 'modules/index';

import { appSelectors } from 'selectors';

export type CustomerProjectsColumnId = keyof APIProject;

export type CustomerProjectsSorting = {
  sortField: CustomerProjectsColumnId;
  sortOrder: APISortOrder;
}[];

export type CustomerProjectsState = {
  items: APIProjectSearchResult['result'];
  customerProjectsLoaded: boolean;
  loadCustomerProjectsPending: boolean;
  loadMoreCustomerProjectsPending: boolean;
  sorting: CustomerProjectsSorting;
  filters: {
    showArchived: boolean;
    name: string;
  };
  pagination: {
    pageNumber: APIProjectSearchResult['pageNumber'];
    pageSize: APIProjectSearchResult['pageSize'];
  };
  count: number;
  searchKeyword: string | null;
};

export const customerProjectsState: CustomerProjectsState = {
  items: [],
  sorting: [
    {
      sortField: 'createdDate',
      sortOrder: APISortOrder.DESC,
    },
  ],
  customerProjectsLoaded: false,
  loadMoreCustomerProjectsPending: false,
  loadCustomerProjectsPending: false,
  pagination: { pageNumber: 1, pageSize: DEFAULT_PAGE_SIZE },
  count: 0,
  filters: {
    showArchived: false,
    name: '',
  },
  searchKeyword: null,
};

const builder = simplifyBuilder(customerProjectsState, {});

export const loadCustomerProjects = builder.createServerAction(
  (providerId: string, customerId: string) => ({
    name: 'loadCustomerProjects',
    url: fetchProjectsListEndpoint(providerId),
    body: (state: State) => ({
      pageSize: state.customerProjects.pagination.pageSize,
      pageNumber: state.customerProjects.pagination.pageNumber,
      searchSortOrders: state.customerProjects.sorting,
      companyIds: [customerId],
      name: state.customerProjects.filters.name,
      // Backend sends all projects (including archived) if this flag is omitted.
      projectStatuses: state.customerProjects.filters.showArchived
        ? undefined
        : [APIProjectStatus.Active],
      keyword: state.customerProjects.searchKeyword,
    }),
    method: httpMethod.post,
    onSuccess: (
      state: CustomerProjectsState,
      payload: APIProjectSearchResult,
    ) => {
      return {
        items: payload.result || [],
        customerProjectsLoaded: true,
        pagination: {
          pageNumber: payload.pageNumber,
          pageSize: payload.pageSize,
        },
        count: payload.count,
      };
    },
  }),
);

export const loadMoreCustomerProjects = builder.createServerAction(
  (providerId: string, customerId: string) => ({
    name: 'loadMoreCustomerProjects',
    url: fetchProjectsListEndpoint(providerId),
    body: (state: State) => ({
      pageNumber: getPageNumberAfterLoadMore(
        state.customerProjects.items,
        state.customerProjects.pagination.pageSize,
      ),
      pageSize: state.customerProjects.pagination.pageSize,
      searchSortOrders: state.customerProjects.sorting,
      projectStatuses: state.customerProjects.filters.showArchived
        ? undefined
        : [APIProjectStatus.Active],
      companyIds: [customerId],
      keyword: state.customerProjects.searchKeyword,
    }),
    method: httpMethod.post,
    onSuccess: (
      state: CustomerProjectsState,
      payload: APIProjectSearchResult,
    ) => {
      return {
        items: _.uniqBy([...state.items, ...payload.result], 'id'),
      };
    },
  }),
);

export const updateCustomerProjects = builder.createServerAction(
  (providerId: string, customerId: string) => ({
    name: 'updateCustomerProjects',
    url: fetchProjectsListEndpoint(providerId),
    body: (state: State) => ({
      pageNumber: 1,
      pageSize: getPageSizeDependingOnItems(state.customerProjects.items),
      searchSortOrders: state.customerProjects.sorting,
      companyIds: [customerId],
      projectStatuses: state.customerProjects.filters.showArchived
        ? undefined
        : [APIProjectStatus.Active],
    }),
    method: httpMethod.post,
    onSuccess: (
      state: CustomerProjectsState,
      payload: APIProjectSearchResult,
    ) => {
      return {
        items: payload.result,
        count: payload.count,
      };
    },
  }),
);

export const deleteContractorProject = builder.createServerAction(
  (providerId: string, contractorId: string) => ({
    name: 'deleteContractorProject',
    url: projectEndpoint(providerId, contractorId),
    method: httpMethod.delete,
  }),
);

export const updateCustomerProjectsSorting = builder.createReduxAction(
  (newSorting: CustomerProjectsState['sorting']) => ({
    name: 'updateCustomerProjectsSorting',
    updater: () => ({
      sorting: newSorting,
    }),
  }),
);

export const updateProjectsFilter = builder.createReduxAction(
  (shouldFetchArchivedProjects: boolean, projectName?: string) => ({
    name: 'updateProjectsFilter',
    updater: () => ({
      filters: {
        showArchived: shouldFetchArchivedProjects,
        name: projectName ?? '',
      },
    }),
  }),
);

export const setSearchKeyword = builder.createReduxAction(
  (searchKeyword: string | null) => ({
    name: 'setProjectSearchKeyword',
    updater: () => ({
      searchKeyword,
    }),
  }),
);

export const resetCustomerProjects = builder.createReduxAction(() => ({
  name: 'resetCustomerProjects',
  updater: () => customerProjectsState,
}));

export const useCustomerProjects = () => {
  const dispatch = useDispatch();
  const providerId = useSelector(appSelectors.providerId);

  return useMemo(
    () =>
      bindActionCreators(
        {
          loadCustomerProjects: loadCustomerProjects.bind(null, providerId),
          loadMoreCustomerProjects: loadMoreCustomerProjects.bind(
            null,
            providerId,
          ),
          updateCustomerProjects: updateCustomerProjects.bind(null, providerId),
          updateCustomerProjectsSorting:
            updateCustomerProjectsSorting.bind(null),
          updateProjectsFilter: updateProjectsFilter.bind(null),
          deleteContractorProject: deleteContractorProject.bind(
            null,
            providerId,
          ),
          resetCustomerProjects: resetCustomerProjects.bind(null),
          setSearchKeyword: setSearchKeyword.bind(null),
        },
        dispatch,
      ),
    [dispatch, providerId],
  );
};

export const customerProjectsReducer = builder.getReducers();
