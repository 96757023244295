import React, { useCallback, useMemo, useState } from 'react';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { APIProject } from '@cd3p/core/types/api';
import { ConfirmationDialog, IconButton, Tooltip } from 'components';

import { useHandleApiResult } from './useHandleApiResult';

import { useTranslation } from 'third-party';

import { useProject } from 'modules/project';

/*
	This hooks allows to reuse project deletion functionality on
	ProjectsTab view and ProjectView to reduce code duplication.
*/
export const useProjectDeletion = () => {
  const { t } = useTranslation();
  const { deleteProject } = useProject();
  const handleApiResult = useHandleApiResult();

  const [selectedProject, setSelectedProject] = useState<null | APIProject>(
    null,
  );
  const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] =
    useState(false);
  const onDeleteProject = useCallback(async () => {
    if (!selectedProject) return;

    const item = selectedProject;
    await handleApiResult<APIProject>(
      () => deleteProject(item.id),
      ({ showBaseToast }) => {
        showBaseToast(
          t('project.deletion.deleteSuccess', {
            projectName: item.name,
          }),
        );
      },
    ).then(() => {
      setSelectedProject(null);
      setShowDeleteConfirmationDialog(false);
    });
  }, [handleApiResult, selectedProject, t, deleteProject]);

  return useMemo(
    () => ({
      DeleteProjectConfirmationDialog: ({
        onSuccess,
      }: {
        onSuccess?: () => void;
      }) => (
        <ConfirmationDialog
          onClose={() => {
            setSelectedProject(null);
            setShowDeleteConfirmationDialog(false);
          }}
          open={showDeleteConfirmationDialog}
          handleActionClick={() => {
            onDeleteProject().then(() => {
              onSuccess?.();
            });
          }}
          cancelButtonTitle={t('common.noNevermind')}
          actionButtonTitle={t('common.yes.delete')}
          description={t('project.deletion.deleteConfirmationDescription')}
        />
      ),
      DeleteProjectButton: ({ project }: { project: APIProject }) => (
        <IconButton
          onClick={e => {
            e.stopPropagation();
            setSelectedProject(project);
            setShowDeleteConfirmationDialog(true);
          }}
        >
          <Tooltip title={t('project.deletion.deleteTooltip')}>
            <DeleteOutlineIcon color="primary" />
          </Tooltip>
        </IconButton>
      ),
    }),
    [onDeleteProject, showDeleteConfirmationDialog, t],
  );
};
