import { useMemo } from 'react';

import { dialogsEndpoint } from '@cd3p/core/constants/endpoints';
import {
  APIDialogModel,
  APIDialogModelContinueSearchResult,
} from '@cd3p/core/types/api';
import { httpMethod, simplifyBuilder } from '@cd3p/core/utils/sra';

import { _, bindActionCreators, useDispatch, useSelector } from 'third-party';

import { getDialogsUnreadCountEndpoint } from 'constants/endpoints';

import { State } from 'modules/index';

import { appSelectors } from 'selectors';

import { updateDialogs } from 'utils/globalCache';

export const DIALOGS_PER_PAGE = 20;

export type DialogsListState = {
  items: APIDialogModel[];
  subscribed: APIDialogModel[];
  token: string | null;
  searchKeyword: string | null;
  dialogsLoaded: boolean;
  loadDialogsPending: boolean;
  loadMoreDialogsPending: boolean;
  isSubscribedDialogLoaded: boolean;
  dialogsUnreadCount: number;
  dialogsFilter: string[];
};

const dialogsListState: DialogsListState = {
  items: [],
  subscribed: [],
  token: null,
  dialogsLoaded: false,
  searchKeyword: null,
  loadDialogsPending: false,
  loadMoreDialogsPending: false,
  isSubscribedDialogLoaded: false,
  dialogsUnreadCount: 0,
  dialogsFilter: [],
};

const builder = simplifyBuilder(dialogsListState, {});

export const loadDialogs = builder.createServerAction(
  (providerId: string, companyId: string | null) => ({
    name: 'loadDialogs',
    url: dialogsEndpoint(providerId, companyId),
    method: httpMethod.post,
    body: (state: State) => ({
      continuationToken: '',
      keyword: state.dialogsList.searchKeyword || null,
      dialogs: state.dialogsList.dialogsFilter,
    }),
    onSuccess: (
      state: DialogsListState,
      payload: APIDialogModelContinueSearchResult,
    ) => ({
      dialogsLoaded: true,
      token: payload.token,
      items: payload.result,
    }),
  }),
);

export const loadMoreDialogs = builder.createServerAction(
  (providerId: string, companyId: string | null) => ({
    name: 'loadMoreDialogs',
    url: dialogsEndpoint(providerId, companyId),
    body: (state: State) => ({
      continuationToken: state.dialogsList.token,
      keyword: state.dialogsList.searchKeyword,
      dialogs: state.dialogsList.dialogsFilter,
    }),
    method: httpMethod.post,
    onSuccess: (
      state: DialogsListState,
      payload: APIDialogModelContinueSearchResult,
    ) => {
      if (!state.searchKeyword) {
        updateDialogs(providerId, payload.result, payload.token);
      }

      return {
        dialogsLoaded: true,
        token: payload.token,
        items: _.uniqBy([...state.items, ...(payload.result || [])], 'orderId'),
      };
    },
  }),
);

export const loadUnreadDialogsCount = builder.createServerAction(
  (providerId: string) => ({
    name: 'loadUnreadDialogsCount',
    url: getDialogsUnreadCountEndpoint(providerId),
    method: httpMethod.get,
    onSuccess: (_state: DialogsListState, payload: number) => ({
      dialogsUnreadCount: payload,
    }),
  }),
);

export const updateUnreadDialogsCount = builder.createReduxAction(
  (newUnreadCount: number) => ({
    name: 'updateUnreadDialogsCount',
    updater: () => ({
      dialogsUnreadCount: newUnreadCount,
    }),
  }),
);

export const setSearchKeyword = builder.createReduxAction(
  (searchKeyword: string | null) => ({
    name: 'setSearchKeyword',
    updater: () => ({
      token: null,
      searchKeyword,
    }),
  }),
);

export const resetDialogs = builder.createReduxAction(() => ({
  name: 'resetDialogs',
  updater: () => ({
    dialogsLoaded: false,
    token: null,
    items: [],
  }),
}));

export const ADD_TO_DIALOGS_LIST = 'addToDialogsList';
export const addToDialogsList = builder.createReduxAction(
  (dialog: APIDialogModel) => ({
    name: ADD_TO_DIALOGS_LIST,
    updater: (state: DialogsListState) => ({
      items: _.orderBy(
        _.uniqBy([dialog, ...state.items], 'orderId'),
        ['updatedOn'],
        ['desc'],
      ),
    }),
  }),
);

export const REMOVE_FROM_DIALOGS_LIST = 'removeFromDialogsList';
export const removeFromDialogsList = builder.createReduxAction(
  (orderId: number) => ({
    name: REMOVE_FROM_DIALOGS_LIST,
    updater: (state: DialogsListState) => {
      return {
        items: _.orderBy(
          _.reject(state.items, { orderId }),
          ['updatedOn'],
          ['desc'],
        ),
      };
    },
  }),
);

export const updateDialogInList = builder.createReduxAction(
  (dialog: APIDialogModel) => ({
    name: 'updateDialogInList',
    updater: (state: DialogsListState) => {
      let newItems = [...state.items];
      const foundIndex = _.findIndex(
        newItems,
        it => it.orderId === dialog?.orderId,
      );
      if (foundIndex !== -1) {
        newItems[foundIndex] = dialog;
      } else {
        newItems = [dialog, ...newItems];
      }
      return {
        items: _.orderBy(newItems, ['updatedOn'], ['desc']),
      };
    },
  }),
);

/* sync depth */
export const updateToken = builder.createReduxAction((token: string) => ({
  name: 'updateToken',
  updater: () => ({
    token: token,
  }),
}));

export const getFreshDialogs = builder.createServerAction(
  (
    providerId: string,
    companyId: string,
    token?: string,
    lastSync?: string | null,
  ) => ({
    name: 'getFreshDialogs',
    url: dialogsEndpoint(providerId, companyId),
    body: () => ({
      continuationToken: token,
      lastUpdateOn: lastSync,
    }),
    method: httpMethod.post,
  }),
);

export const getDialogsByIds = builder.createServerAction(
  (
    providerId: string,
    companyId: string,
    dialogs: string[],
    token?: string,
  ) => ({
    name: 'getDialogsByIds',
    url: dialogsEndpoint(providerId, companyId),
    body: () => ({
      continuationToken: token,
      dialogs: dialogs,
    }),
    method: httpMethod.post,
  }),
);

export const setDialogMessages = builder.createReduxAction(
  (messages: APIDialogModel[], token: string) => ({
    name: 'setDialogMessages',
    updater: () => ({
      dialogsLoaded: true,
      token: token,
      items: messages,
    }),
  }),
);

export const setSubscribedDialogs = builder.createReduxAction(
  (dialogs: APIDialogModel[]) => ({
    name: 'setSubscribedDialogs',
    updater: () => ({
      dialogsLoaded: true,
      subscribed: dialogs,
    }),
  }),
);

export const setIsSubscribedDialogLoaded = builder.createReduxAction(
  (isLoaded: boolean) => ({
    name: 'setIsSubscribedDialogLoaded',
    updater: () => ({
      isSubscribedDialogLoaded: isLoaded,
    }),
  }),
);

export const setDialogsFilter = builder.createReduxAction(
  (dialogsFilter: string[]) => ({
    name: 'setDialogsFilter',
    updater: () => ({
      dialogsFilter,
    }),
  }),
);

export const removeSubscribedDialog = builder.createReduxAction(
  (orderId: number) => ({
    name: 'removeSubscribedDialog',
    updater: state => ({
      messagesLoaded: true,
      subscribed: state.subscribed.filter(it => it.orderId != orderId),
    }),
  }),
);

export const addSubscribedDialog = builder.createReduxAction(
  (dialog: APIDialogModel) => ({
    name: 'addSubscribedDialog',
    updater: state => ({
      messagesLoaded: true,
      subscribed: [...state.subscribed, dialog].sort(
        (a, b) => b.ticks - a.ticks,
      ),
    }),
  }),
);

export const useDialogsList = () => {
  const dispatch = useDispatch();
  const providerId = useSelector(appSelectors.providerId);

  return useMemo(
    () =>
      bindActionCreators(
        {
          updateToken: updateToken.bind(null),
          getFreshDialogs: getFreshDialogs.bind(null, providerId),
          getDialogsByIds: getDialogsByIds.bind(null, providerId),
          setDialogMessages: setDialogMessages.bind(null),
          setSubscribedDialogs: setSubscribedDialogs.bind(null),
          setIsSubscribedDialogLoaded: setIsSubscribedDialogLoaded.bind(null),
          addSubscribedDialog: addSubscribedDialog.bind(null),
          removeSubscribedDialog: removeSubscribedDialog.bind(null),

          loadDialogs: loadDialogs.bind(null, providerId),
          loadMoreDialogs: loadMoreDialogs.bind(null, providerId),
          loadUnreadDialogsCount: loadUnreadDialogsCount.bind(null, providerId),
          resetDialogs: resetDialogs.bind(null),
          updateDialogInList: updateDialogInList.bind(null),
          addToDialogsList: addToDialogsList.bind(null),
          setSearchKeyword: setSearchKeyword.bind(null),
          removeFromDialogsList: removeFromDialogsList.bind(null),
          updateUnreadDialogsCount: updateUnreadDialogsCount.bind(null),
          setDialogsFilter: setDialogsFilter.bind(null),
        },
        dispatch,
      ),
    [dispatch, providerId],
  );
};

export const dialogsListReducer = builder.getReducers();
