import { useCallback } from 'react';

import { APIUserType } from '@cd3p/core/types/api';

import { useSelector } from 'third-party';

import { appSelectors } from 'selectors';

export const useUserTypeIn = () => {
  const userType = useSelector(appSelectors.userType);

  return useCallback(
    (userTypes: APIUserType | APIUserType[]) =>
      !!userType &&
      (Array.isArray(userTypes) ? userTypes : [userTypes]).includes(userType),

    [userType],
  );
};
