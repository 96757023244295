import React, { ReactNode, useRef } from 'react';

import styled from 'styled-components';

import { CSSTransition, Stack } from 'components';

export const LEFT_OVERLAY_PANEL_WIDTH = '35rem';

const AnimationStyles = styled(Stack)`
  z-index: 6;
  .css-required-actions-panel-enter {
    width: 0;
  }
  .css-required-actions-panel-enter-active {
    transition: width 500ms;
    width: ${LEFT_OVERLAY_PANEL_WIDTH};
  }
  .css-required-actions-panel-exit {
    width: ${LEFT_OVERLAY_PANEL_WIDTH};
  }
  .css-required-actions-panel-exit-active {
    transition: width 500ms;
    width: 0;
  }
`;

const Wrapper = styled(Stack)`
  width: ${LEFT_OVERLAY_PANEL_WIDTH};
  box-shadow: 10px 0 12px -10px rgba(0, 0, 0, 0.25);
  border-radius: 0.4rem;
  flex-grow: 1;
  position: relative;
  overflow: hidden;
`;

type LeftOverlayPanelContainerProps = {
  children?: ReactNode;
  isPanelOpen: boolean;
  animationDuration?: number;
};

export const LeftOverlayPanelContainer = ({
  children,
  isPanelOpen,
  animationDuration = 500,
}: LeftOverlayPanelContainerProps) => {
  const panelRef = useRef(null);
  return (
    <AnimationStyles>
      <CSSTransition
        nodeRef={panelRef}
        in={isPanelOpen}
        timeout={animationDuration}
        classNames="css-required-actions-panel"
        unmountOnExit
      >
        <Wrapper ref={panelRef}>{children}</Wrapper>
      </CSSTransition>
    </AnimationStyles>
  );
};
