import React, { useMemo } from 'react';

import styled from 'styled-components';

import { APIUserType } from '@cd3p/core/types/api';
import { CanAccess } from 'components';

import { ProjectCommonTab } from './ProjectCommonTab';

import { useSelector, useTranslation } from 'third-party';

import { useContractorAdditiveTypes } from 'modules/contractorAdditiveTypes';
import { useProject } from 'modules/project';

import { appSelectors, contractorAdditiveTypesSelectors } from 'selectors';

import { AddToListDropdown } from 'features/ProjectView/Dropdown/AddToListDropdown';

const StyledAddToListDropdown = styled(AddToListDropdown)`
  margin: 1rem 0;
`;

type Props = {
  projectId: string;
  isReadOnly?: boolean;
};

export const ProjectAdditiveTypesTab: React.FC<Props> = ({
  projectId,
  isReadOnly,
}) => {
  const { t } = useTranslation();

  const {
    fetchProjectAdditiveTypes,
    assignProjectAdditiveType,
    createProjectAdditiveType,
  } = useProject();

  const {
    loadProjectAdditiveTypes,
    loadMoreProjectAdditiveTypes,
    deleteProjectAdditiveType,
    updateProjectAdditiveTypes,
    updateContractorAdditiveTypesSorting,
    resetContractorAdditiveTypes,
  } = useContractorAdditiveTypes();

  const contractorAdditiveTypesItems = useSelector(
    contractorAdditiveTypesSelectors.contractorAdditiveTypesItems,
  );
  const contractorAdditiveTypesCount = useSelector(
    contractorAdditiveTypesSelectors.contractorAdditiveTypesCount,
  );
  const contractorAdditiveTypesLoaded = useSelector(
    contractorAdditiveTypesSelectors.contractorAdditiveTypesLoaded,
  );
  const loadProjectAdditiveTypesPending = useSelector(
    contractorAdditiveTypesSelectors.loadProjectAdditiveTypesPending,
  );
  const loadMoreProjectAdditiveTypesPending = useSelector(
    contractorAdditiveTypesSelectors.loadMoreProjectAdditiveTypesPending,
  );
  const sorting = useSelector(
    contractorAdditiveTypesSelectors.contractorAdditiveTypesSorting,
  );
  const userType = useSelector(appSelectors.userType);
  const isUserDispatcher = userType === APIUserType.Dispatcher;

  const addToListDropdown = useMemo(() => {
    return (
      <CanAccess forbiddenUserType={APIUserType.Contractor}>
        <StyledAddToListDropdown
          dropdownLabel={t('projectDetails.additiveTypes.addToListFieldLabel')}
          fieldName="name"
          loadOptions={fetchProjectAdditiveTypes.bind(null, projectId)}
          addToList={assignProjectAdditiveType.bind(null, projectId)}
          createNewOption={createProjectAdditiveType.bind(null, projectId)}
          successMessage={t('projectDetails.additiveTypes.addToListSuccess')}
          onSuccess={() => updateProjectAdditiveTypes(projectId)}
        />
      </CanAccess>
    );
  }, [
    t,
    fetchProjectAdditiveTypes,
    projectId,
    assignProjectAdditiveType,
    createProjectAdditiveType,
    updateProjectAdditiveTypes,
  ]);

  return (
    <ProjectCommonTab
      items={contractorAdditiveTypesItems}
      itemsCount={contractorAdditiveTypesCount}
      itemsLoaded={contractorAdditiveTypesLoaded}
      loadItemsPending={loadProjectAdditiveTypesPending}
      loadMoreItemsPending={loadMoreProjectAdditiveTypesPending}
      sorting={sorting}
      projectId={projectId}
      loadItems={loadProjectAdditiveTypes}
      loadMoreItems={loadMoreProjectAdditiveTypes}
      deleteItem={deleteProjectAdditiveType}
      updateItems={updateProjectAdditiveTypes}
      updateItemsSorting={updateContractorAdditiveTypesSorting}
      resetItems={resetContractorAdditiveTypes}
      successDeleteMessage={t(
        'customers.additiveTypes.notification.successDelete',
      )}
      itemNameColumnLabel={t('customers.additiveTypes.table.nameColumnLabel')}
      itemCategoriesColumnLabel={t(
        'customers.additiveTypes.table.categoryColumnLabel',
      )}
      deleteConfirmationTitle={t('common.areYouSure')}
      deleteConfirmationText={t(
        'customers.projects.additiveTypes.table.deleteConfirmationText',
      )}
      tableHeader={t('customers.additiveTypes.header')}
      loadMoreButtonLabel={t(
        'customers.additiveTypes.table.loadMoreButtonText',
      )}
      emptyStateText={
        isUserDispatcher
          ? t('customers.projects.additiveTypes.table.dispatcherEmptyState')
          : t('customers.projects.additiveTypes.table.customerEmptyState')
      }
      isReadOnly={isReadOnly}
      addToListDropdown={addToListDropdown}
    />
  );
};
