import React from 'react';

import styled from 'styled-components';

import {
  APIChangesStatus,
  APIEntityHistory,
  APIOrder,
} from '@cd3p/core/types/api';
import {
  OrderFields,
  getRequestedChangesOrderFieldsDisplayValue,
  getStringOrObject,
} from '@cd3p/core/utils/order';
import { contractorChangesRequestedFields } from '@cd3p/core/utils/orderDetails';
import { Stack, SvgIcon, Typography } from 'components';

import { useTranslation } from 'third-party';

import { OrderContentWrapper } from 'features/OrderView/orderViewHelpers';

const StyledOrderContentWrapper = styled(OrderContentWrapper)`
  border-color: ${props => props.theme.custom.palette.purple700};
`;

const Title = styled(Typography)`
  color: ${props => props.theme.custom.palette.purple700};
  margin-bottom: 0.8rem;
  font-size: 1.6rem;
  font-weight: 600;
  text-transform: uppercase;
`;

const Label = styled(Typography)`
  color: ${props => props.theme.custom.palette.gray};
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 150%;
`;

const InfoText = styled(Typography)`
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.8rem;
  margin-top: 0.4rem;
`;

const Value = styled(Label)`
  font-weight: 400;
  margin-left: 0.5rem;
`;

const RequestedChangesIcon = styled(SvgIcon)`
  margin-left: 0.5rem;
  margin-top: 0.1rem;
`;

type Props = {
  order: APIOrder;
};

export const RequestedChangesOrder: React.FC<Props> = ({ order }) => {
  const { t } = useTranslation();
  const pendingChanges = (
    (order.orderChanges as APIEntityHistory[]) || []
  ).filter(it => it.changesStatus === APIChangesStatus.Pending);
  const isConfirmUponChangesApprovalEnabled = order?.confirmUponChangesApproval;

  return (
    <StyledOrderContentWrapper>
      <Stack direction="row" gap="1rem">
        <RequestedChangesIcon
          icon="requestedChangesPending"
          width="2.2rem"
          height="2.2rem"
        />
        <Stack>
          <Title>{t('order.details.contractor.changesRequested.title')}</Title>
          <Stack>
            {contractorChangesRequestedFields(t).reduce<React.ReactNode[]>(
              (acc, field) => {
                const foundChange = pendingChanges.find(
                  it => it.fieldName === field.name,
                );
                if (
                  field.name === OrderFields.DeliveryRateType &&
                  pendingChanges.some(
                    changedItem =>
                      changedItem.fieldName === OrderFields.DeliveryRate,
                  )
                ) {
                  return acc;
                }
                if (foundChange) {
                  const fieldValue = getRequestedChangesOrderFieldsDisplayValue(
                    field.name,
                    getStringOrObject(foundChange),
                    pendingChanges,
                    order,
                  );
                  return [
                    ...acc,
                    <Stack direction="row" key={field.name}>
                      <Label>{field.label}:</Label>
                      <Value>
                        {(field.formatter
                          ? field.formatter(fieldValue)
                          : fieldValue) || t('common.none').toLowerCase()}
                      </Value>
                    </Stack>,
                  ];
                } else {
                  return acc;
                }
              },
              [],
            )}
          </Stack>
          {isConfirmUponChangesApprovalEnabled && (
            <InfoText>
              {t('order.details.requestedChanges.confirmedUponApproval')}
            </InfoText>
          )}
        </Stack>
      </Stack>
    </StyledOrderContentWrapper>
  );
};
