import React, { useEffect } from 'react';

import { InsightBlock, SvgIcon } from 'components';

import { useSelector, useTranslation } from 'third-party';

import { useAdditiveTypesList } from 'modules/additiveTypesList';

import { additiveTypesSelectors } from 'selectors';

type Props = {
  isActive?: boolean;
  onClick: () => void;
};

export const MostOrderedAdditivesWidget: React.FC<Props> = ({
  onClick,
  isActive,
}) => {
  const { t } = useTranslation();

  const { loadMostUsedAdditiveTypes } = useAdditiveTypesList();

  const mostUsedAdditiveTypesLoaded = useSelector(
    additiveTypesSelectors.mostUsedAdditiveTypesLoaded,
  );
  const mostUsedAdditiveTypesPercentage = useSelector(
    additiveTypesSelectors.mostUsedAdditiveTypesPercentage,
  );
  const mostUsedAdditiveTypesItems =
    useSelector(additiveTypesSelectors.mostUsedAdditiveTypesItems) || [];

  useEffect(() => {
    if (!mostUsedAdditiveTypesLoaded) {
      loadMostUsedAdditiveTypes();
    }
  }, [loadMostUsedAdditiveTypes, mostUsedAdditiveTypesLoaded]);

  const notSufficientData =
    mostUsedAdditiveTypesLoaded && mostUsedAdditiveTypesItems.length === 0;

  return (
    <InsightBlock
      isActive={isActive}
      isLoading={!mostUsedAdditiveTypesLoaded}
      title={t('plantSettings.insightsAdditivesTypesMostTitle')}
      description={
        notSufficientData
          ? t('plantSettings.insightsAdditivesTypesMostDefaultText')
          : t('plantSettings.insightsAdditivesTypesMostDescription', {
              count: mostUsedAdditiveTypesItems.length,
              percent: mostUsedAdditiveTypesPercentage,
            })
      }
      linkLabel={t('plantSettings.insightsViewList')}
      onClick={notSufficientData ? undefined : onClick}
      icon={
        <SvgIcon icon="insights-most-icon" height="3.6rem" width="3.6rem" />
      }
    />
  );
};
