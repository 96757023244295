import React, { useState } from 'react';

import styled from 'styled-components';

import { NotificationSeverity } from '@cd3p/core/modules/notifications';
import { APIOrder, APIOrderStatus } from '@cd3p/core/types/api';
import { formatDisplayedDate } from '@cd3p/core/utils/common';
import { prepareOrderForUpdating } from '@cd3p/core/utils/order';
import { Grid, LoadingButton, Trans, Typography } from 'components';
import { useHandleApiResult } from 'hooks/useHandleApiResult';

import { _, useNavigate, useTheme, useTranslation } from 'third-party';

import { orderUrl } from 'constants/url';

import { useNotifications } from 'modules/notifications';
import { useOrder } from 'modules/order';

import { OrderContentWrapper } from 'features/OrderView/orderViewHelpers';

import { NotificationLink } from 'styles/common';

const CancelButton = styled(LoadingButton)`
  width: 15rem;
  font-weight: 900;
`;

const ConfirmButton = styled(LoadingButton)`
  min-width: 13rem;
`;

type Props = {
  order: APIOrder;
  onSetConfirmUponApprovalMode: () => void;
};

export const UnconfirmedOrder: React.FC<Props> = ({
  order,
  onSetConfirmUponApprovalMode,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { addNotification } = useNotifications();
  const { changeOrderStatus, updateOrder } = useOrder();

  const handleApiResult = useHandleApiResult();

  const [isOrderCancelling, setIsOrderCancelling] = useState(false);
  const [isChangingOrderStatus, setIsChangingOrderStatus] = useState(false);

  const handleCancelPress = _.debounce(
    async () => {
      setIsOrderCancelling(true);
      await handleApiResult(
        async () =>
          updateOrder(order.id, {
            ...prepareOrderForUpdating(order),
            isCancellationSubmitted: true,
          }),
        ({ showBaseToast }) => {
          showBaseToast(t('order.notification.cancellationSubmitted'));
        },
      );
      setIsOrderCancelling(false);
    },
    1000,
    {
      leading: true,
      trailing: false,
    },
  );

  const handleConfirmPress = async () => {
    setIsChangingOrderStatus(true);
    const result = await changeOrderStatus(order.id, APIOrderStatus.Confirmed);
    if (!result.error) {
      addNotification({
        message: ({ closeWithCallback }) => (
          <Trans i18nKey="order.notification.confirmText" shouldUnescape>
            Thank you for confirming your Order{' '}
            <NotificationLink
              onClick={closeWithCallback(() =>
                navigate(orderUrl(order.id.toString())),
              )}
            >
              {order.orderName}
            </NotificationLink>{' '}
            scheduled for{' '}
            {{
              deliveryData: formatDisplayedDate(order.deliveryDate),
            }}
            .
          </Trans>
        ),
      });
    } else {
      addNotification({
        severity: NotificationSeverity.Error,
        message: t('common.generalError'),
      });
    }
    setIsChangingOrderStatus(false);
  };

  return (
    <OrderContentWrapper>
      <Grid item>
        <Grid item md={12}>
          <Typography variant="h5">
            {t('order.details.contractor.unconfirmed.title')}
          </Typography>
          <Typography
            color={theme.custom.palette.gray}
            variant="body1"
            marginTop="0.8rem"
          >
            {t('order.details.contractor.unconfirmed.description')}
          </Typography>
        </Grid>
        <Grid container margin="3.6rem 0 2rem" gap="1rem">
          <ConfirmButton
            color="primary"
            variant="contained"
            size="large"
            onClick={handleConfirmPress}
            disabled={isChangingOrderStatus || isOrderCancelling}
            loading={isChangingOrderStatus}
          >
            {t('common.confirm')}
          </ConfirmButton>

          <ConfirmButton
            color="primary"
            variant="contained"
            size="large"
            onClick={onSetConfirmUponApprovalMode}
            disabled={isChangingOrderStatus || isOrderCancelling}
          >
            {t('common.editAndConfirm')}
          </ConfirmButton>

          <CancelButton
            style={{ width: 'auto' }}
            color="secondary"
            variant="outlined"
            size="large"
            disabled={isChangingOrderStatus || isOrderCancelling}
            loading={isOrderCancelling}
            onClick={handleCancelPress}
          >
            {t('common.cancel')}
          </CancelButton>
        </Grid>
      </Grid>
    </OrderContentWrapper>
  );
};
