import { APISortOrder } from '@cd3p/core/types/api';
import { State } from 'modules';

import { _, createSelector } from 'third-party';

import { truckStatusesForTodayOrders } from 'utils/order';

export const trackingItems = (state: State) => state.tracking.items;
export const trackingLoaded = (state: State) => state.tracking.trackingLoaded;
export const loadTrackingPending = (state: State) =>
  state.tracking.loadTrackingPending;

export const updateTruckLocationPending = (state: State) =>
  state.tracking.updateTruckLocationPending;
export const trackingItemsValues = (state: State) =>
  _.values(state.tracking.items) ?? [];

export const sorting = (state: State) => state.tracking.sorting;

export const availableTrucks = createSelector(
  [sorting, trackingItemsValues],
  ({ sortField, sortOrder }, trucks) => {
    const filteredTrucks = _.filter(
      trucks,
      truck =>
        truckStatusesForTodayOrders.includes(truck.truckStatus) &&
        !truck.ticketId,
    );
    const filteredTrucksWithId = filteredTrucks.map(it => ({
      ...it,
      id: it.truckNumber,
    }));
    return _.orderBy(
      filteredTrucksWithId,
      [sortField],
      [sortOrder === APISortOrder.ASC ? 'asc' : 'desc'],
    );
  },
);
