import React from 'react';

import { useSelector, useTranslation } from 'third-party';

import { useCache } from 'modules/cache';
import { useMixTypesList } from 'modules/mixTypesList';

import { cacheSelectors, mixTypesSelectors } from 'selectors';

import { AddForm } from 'features/PlantSettings/common/AddForm';

type Props = {
  onSuccess?: () => void;
};

export const AddMixTypeForm: React.FC<Props> = ({ onSuccess }) => {
  const { t } = useTranslation();
  const { createMixType } = useMixTypesList();
  const { loadMixTypeCategories, createMixTypeCategory } = useCache();

  const createMixTypePending = useSelector(
    mixTypesSelectors.createMixTypePending,
  );

  const createDataCategoryPending = useSelector(
    cacheSelectors.createDataCategoryPending,
  );

  return (
    <AddForm
      createItem={createMixType}
      isFormPending={createMixTypePending || createDataCategoryPending}
      loadCategories={loadMixTypeCategories}
      createCategory={createMixTypeCategory}
      addSuccessMessage={t('mixTypes.notification.successAdd')}
      itemFieldLabel={t('mixTypes.nameFieldLabel')}
      itemFieldPlaceholder={t('mixTypes.nameFieldPlaceholder')}
      categoryFieldLabel={t('mixTypes.categoryFieldLabel')}
      categoryPlaceholder={t('mixTypes.categoryFieldPlaceholder')}
      addButtonLabel={t('mixTypes.addButtonLabel')}
      onSuccess={onSuccess}
    />
  );
};
