import React from 'react';

import { useTheme } from 'styled-components';

import { SvgIcon } from 'components';
import { motion } from 'framer-motion';

import { styled } from 'third-party';

const Wrapper = styled(motion.div)`
  align-self: stretch;
  &:hover {
    cursor: pointer;
    background: rgba(28, 25, 23, 0.1);
  }
  padding: 10px 0;
  display: flex;
  gap: 0.2rem;
  flex-direction: column;
`;

const IconWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
`;

const StyledHeader = styled(motion.h5)<{ isactive: string }>`
  color: ${props =>
    props.isactive === 'true'
      ? props.theme.custom.palette.primary900
      : props.theme.custom.palette.muted500};

  text-align: center;
  font-size: 1.1rem;
  font-family: Source Sans Pro;
  font-weight: 600;
  line-height: 150%;
`;

type Props = {
  icon: string;
  isActive: boolean;
  caption: string;
  onClick: () => void;
};

const iconVariants = {
  initial: { scale: 1 },
  enlarge: {
    scale: 1.3,
  },
};

const titleVariants = {
  initial: { scale: 1 },
  enlarge: {
    scale: 1.1,
  },
};

export const MenuNavigationItem: React.FC<Props> = ({
  caption,
  icon,
  isActive,
  onClick,
}) => {
  const theme = useTheme();
  return (
    <Wrapper
      onClick={onClick}
      initial={false}
      animate={isActive ? 'enlarge' : 'initial'}
      whileHover="enlarge"
    >
      <IconWrapper variants={iconVariants}>
        <SvgIcon
          height="2.2rem"
          icon={icon}
          width="2.2rem"
          color={
            isActive
              ? theme.custom.palette.primary900
              : theme.custom.palette.muted500
          }
        />
      </IconWrapper>
      <StyledHeader variants={titleVariants} isactive={String(isActive)}>
        {caption}
      </StyledHeader>
    </Wrapper>
  );
};
