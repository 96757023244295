import React from 'react';

import { TeamTab } from './Tabs/TeamTab';

import { styled, useSelector } from 'third-party';

import { appSelectors } from 'selectors';

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 0;
  flex-grow: 1;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
`;

export const ContractorTeamList = () => {
  const customerId = useSelector(appSelectors.companyId);
  const isAdmin = useSelector(appSelectors.isAdmin);

  return (
    <ContentWrapper>
      <TeamTab customerId={customerId} isReadOnly={!isAdmin} />
    </ContentWrapper>
  );
};
