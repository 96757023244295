import {
  APIOrdersList,
  APISearchSortOrder,
  APISortOrder,
  APITicket,
  APITicketStatuses,
  APITicketsList,
  APITicketsListParams,
} from '@cd3p/core/types/api';
import {
  DEFAULT_PAGE_SIZE,
  getPageSizeDependingOnItems,
} from '@cd3p/core/utils/lists';
import { httpMethod } from '@cd3p/core/utils/sra';
import { OrderTicket } from 'selectors/order';

import { _ } from 'third-party';

import { ticketsSearchEndpoint } from 'constants/endpoints';

import { State } from 'modules/index';

import { composeLoadTicketsRequestBody } from 'utils/ticketsList';

export type TicketsFilters = {
  ticketStatuses: APITicketStatuses[] | null;
  ticketNumber: string | null;
  plantId: string | null;
  driverId: string | null;
  orderId: number | null;
  date: {
    dateFrom: Date | null;
    dateTo: Date | null;
  };
  batchSize: number;
};

export type TicketsListColumnId = keyof OrderTicket;

export type TicketsListState = {
  tickets: {
    items: APITicket[];
    loaded: boolean;
    pagination: {
      pageNumber: APIOrdersList['pageNumber'];
      pageSize: APIOrdersList['pageSize'];
    };
    sorting: APISearchSortOrder[];
    filters: TicketsFilters;
    count: number;
  };
};

export const ticketsDefaultFilters: TicketsFilters = {
  ticketStatuses: null,
  ticketNumber: null,
  plantId: null,
  driverId: null,
  orderId: null,
  date: {
    dateFrom: null,
    dateTo: null,
  },
  batchSize: 0,
};

export const ticketsDefaultListState: TicketsListState['tickets'] = {
  items: [],
  loaded: false,
  pagination: { pageNumber: 1, pageSize: DEFAULT_PAGE_SIZE },
  sorting: [
    {
      sortField: 'createdDate',
      sortOrder: APISortOrder.DESC,
    },
  ],
  filters: ticketsDefaultFilters,
  count: 0,
};

export const loadTickets =
  <State extends TicketsListState, Payload extends APITicketsList>(
    actionName: string,
  ) =>
  (
    providerId: string,
    id: number | string | null,
    body: Partial<APITicketsListParams>,
  ) => ({
    name: actionName,
    url: ticketsSearchEndpoint(providerId),
    method: httpMethod.post,
    body: { ...body, orderId: id },
    onSuccess: (state: State, payload: Payload) => ({
      tickets: {
        ...state.tickets,
        items: payload.result,
        loaded: true,
        pagination: {
          pageNumber: payload.pageNumber,
          pageSize: payload.pageSize,
        },
        count: payload.count,
      },
    }),
  });

export const loadMoreTickets =
  <State extends TicketsListState, Payload extends APITicketsList>(
    actionName: string,
  ) =>
  (
    providerId: string,
    id: number | string | null,
    body: Partial<APITicketsListParams>,
  ) => ({
    name: actionName,
    url: ticketsSearchEndpoint(providerId),
    body: { ...body, orderId: id },
    method: httpMethod.post,
    onSuccess: (state: State, payload: Payload) => ({
      tickets: {
        ...state.tickets,
        items: [...state.tickets.items, ...payload.result],
        count: payload.count,
      },
    }),
  });

export const updateTickets =
  <ModuleState extends TicketsListState, Payload extends APITicketsList>(
    actionName: string,
  ) =>
  (
    providerId: string,
    getModuleState: (state: State) => ModuleState,
    orderId?: number | string,
  ) => ({
    name: actionName,
    url: ticketsSearchEndpoint(providerId),
    method: httpMethod.post,
    body: (state: State) => ({
      ...composeLoadTicketsRequestBody({
        pageNumber: 1,
        pageSize: getPageSizeDependingOnItems(
          getModuleState(state).tickets.items,
        ),
        sorting: getModuleState(state).tickets.sorting,
        filters: getModuleState(state).tickets.filters,
      }),
      orderId,
    }),
    onSuccess: (state: ModuleState, payload: Payload) => ({
      tickets: {
        ...state.tickets,
        items: payload.result,
        count: payload.count,
      },
    }),
  });

export const updateTicketInState =
  <State extends TicketsListState>(actionName: string) =>
  (ticketId: number, ticketData: Partial<APITicket>) => ({
    name: actionName,
    updater: (state: State) => ({
      tickets: {
        ...state.tickets,
        items: state.tickets.items.map(it =>
          it.id === ticketId ? { ...it, ...ticketData } : it,
        ),
      },
    }),
  });

export const removeTicketFromState =
  <State extends TicketsListState>(actionName: string) =>
  (ticketId: number) => ({
    name: actionName,
    updater: (state: State) => ({
      tickets: {
        ...state.tickets,
        items: _.reject(state.tickets.items, { id: ticketId }),
        count: state.tickets.count - 1,
      },
    }),
  });

export const updateTicketsFilters =
  <State extends TicketsListState>(actionName: string) =>
  (filters: Partial<TicketsFilters>) => ({
    name: actionName,
    updater: (state: State) => ({
      tickets: {
        ...state.tickets,
        filters: {
          ...state.tickets.filters,
          ...filters,
        },
      },
    }),
  });
