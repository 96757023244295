import { createOrdersRefSelector } from '@cd3p/core/utils/ordersList';
import { State } from 'modules';

import { _ } from 'third-party';

import { projectOrdersListDefaultFilters } from 'modules/projectOrdersList';

export const projectOrderListState = (state: State) => state.projectOrdersList;

export const projectOrdersListItems = createOrdersRefSelector(
  (state: State) => state.projectOrdersList.items,
);
export const projectOrdersListSorting = (state: State) =>
  state.projectOrdersList.sorting;
export const projectOrdersListFilters = (state: State) =>
  state.projectOrdersList.filters;

export const projectOrdersListSearch = (state: State) =>
  state.projectOrdersList.search;
export const projectOrdersListSearchPattern = (state: State) =>
  state.projectOrdersList.search.pattern;
export const isProjectOrdersListFiltersApplied = (state: State) =>
  !_.isEqual(state.projectOrdersList.filters, projectOrdersListDefaultFilters);
export const projectOrdersListPagination = (state: State) =>
  state.projectOrdersList.pagination;
export const projectOrdersListCount = (state: State) =>
  state.projectOrdersList.count;
export const loadProjectOrdersPending = (state: State) =>
  state.projectOrdersList.loadProjectOrdersPending;
export const projectOrdersLoaded = (state: State) =>
  state.projectOrdersList.ordersLoaded;
export const loadMoreProjectOrdersPending = (state: State) =>
  state.projectOrdersList.loadMoreProjectOrdersPending;
