import React, { useCallback, useEffect, useMemo } from 'react';

import styled from 'styled-components';

import {
  ON_NOTIFICATION_CREATE,
  ON_NOTIFICATION_READ,
} from '@cd3p/core/constants/hubs';
import {
  APIRequiredAction,
  APIRequiredActionSortOrderType,
  APIRequiredActionType,
} from '@cd3p/core/types/api';
import { AnimatedMenuIcon, IconButton, Tooltip } from 'components';
import { motion } from 'framer-motion';
import { useRequiredActionsNotifications } from 'hooks/useRequiredActionsNotifications';

import { useSelector, useTranslation } from 'third-party';

import { TOOLTIP_APPEAR_DELAY } from 'constants/common';

import { AppLeftPanel } from 'modules/app';
import { useRequiredActionsList } from 'modules/requiredActionsList';
import { useStorage } from 'modules/storage';

import { appSelectors, requiredActionsListSelectors } from 'selectors';

import { useCommunicationsSockets } from 'utils/sockets';

const StyledButton = styled(IconButton)<{ active?: string }>`
  background-color: ${props =>
    props.active === 'true'
      ? props.theme.custom.palette.primary50
      : 'transparent'};
  width: 4rem;
  height: 4rem;
`;

export const RequiredActionsButton = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation();

  const currentProviderId = useSelector(appSelectors.providerId);
  const requiredActionsListSorting = useSelector(
    requiredActionsListSelectors.requiredActionsListSorting,
  );
  const { showRequiredActionToast } = useRequiredActionsNotifications();

  const { updateUserSettingInStorage } = useStorage();

  const shouldAddRequiredAction = useCallback(
    (requiredAction: APIRequiredAction) => {
      return !(
        requiredActionsListSorting === APIRequiredActionSortOrderType.Urgency &&
        [
          APIRequiredActionType.ProjectCreatedByDispatcher,
          APIRequiredActionType.ProjectCreatedByContractor,
        ].includes(requiredAction.notificationType)
      );
    },
    [requiredActionsListSorting],
  );

  const { loadRequiredAction } = useRequiredActionsList();

  const { addToRequiredActionsList, removeFromRequiredActionsList } =
    useRequiredActionsList();

  const isRequiredActionsPanelOpen = useSelector(
    appSelectors.isRequiredActionsPanelOpen,
  );

  const requiredActionsListCounter = useSelector(
    requiredActionsListSelectors.requiredActionsListCounter,
  );

  const onRequiredPanelButtonClick = useCallback(() => {
    onClick();
    updateUserSettingInStorage({
      openedLeftPanel: isRequiredActionsPanelOpen
        ? null
        : AppLeftPanel.RequiredActions,
    });
  }, [onClick, updateUserSettingInStorage, isRequiredActionsPanelOpen]);

  useEffect(() => {
    // initial load of required actions to show unread badge
    // on the menu icon
    loadRequiredAction();
  }, [loadRequiredAction]);

  useCommunicationsSockets(
    () => [
      {
        method: ON_NOTIFICATION_CREATE,
        action: (providerId: string, requiredAction: APIRequiredAction) => {
          if (providerId !== currentProviderId) {
            return;
          }

          showRequiredActionToast(providerId, requiredAction);
          if (shouldAddRequiredAction(requiredAction)) {
            addToRequiredActionsList(
              requiredAction,
              requiredActionsListSorting,
            );
          }
        },
      },
      {
        method: ON_NOTIFICATION_READ,
        action: (providerId: string, requiredActionId: string) => {
          removeFromRequiredActionsList(requiredActionId);
        },
      },
    ],
    [
      requiredActionsListSorting,
      currentProviderId,
      showRequiredActionToast,
      shouldAddRequiredAction,
      addToRequiredActionsList,
      removeFromRequiredActionsList,
    ],
  );

  return useMemo(() => {
    return (
      <Tooltip
        enterDelay={TOOLTIP_APPEAR_DELAY}
        title={t('requiredActionsPanel.triggerButton.tooltip')}
      >
        <StyledButton
          active={String(isRequiredActionsPanelOpen)}
          onClick={onRequiredPanelButtonClick}
          // @ts-ignore
          component={motion.button}
          whileHover="enlarge"
        >
          <AnimatedMenuIcon unread={!!requiredActionsListCounter} />
        </StyledButton>
      </Tooltip>
    );
  }, [
    isRequiredActionsPanelOpen,
    onRequiredPanelButtonClick,
    requiredActionsListCounter,
    t,
  ]);
};
