import React from 'react';

import { Core } from '@cd3p/core/config';
import { APIAccessLevel, APIUserType } from '@cd3p/core/types/api';
import { useUserAccessLevelIn } from 'hooks/useUserAccessLevelIn';
import { useUserTypeIn } from 'hooks/useUserTypeIn';

export type CanAccessProps = {
  allowedUserType?: APIUserType | APIUserType[] | undefined;
  forbiddenUserType?: APIUserType | APIUserType[] | undefined;
  allowedFor?: APIAccessLevel | APIAccessLevel[];
  forbiddenFor?: APIAccessLevel | APIAccessLevel[];
  childProps?: Record<string, any>;
  children?: any;
  fallback?: React.ReactNode | React.ReactNode[] | null;
};

export const CanAccess: React.FC<CanAccessProps> = ({
  allowedUserType,
  forbiddenUserType,
  allowedFor,
  forbiddenFor,
  childProps,
  children,
  fallback,
}) => {
  const userAccessLevelIn = useUserAccessLevelIn();
  const userTypeIn = useUserTypeIn();

  if (
    (allowedUserType && userTypeIn(allowedUserType)) ||
    (forbiddenUserType && !userTypeIn(forbiddenUserType)) ||
    (allowedFor && userAccessLevelIn(allowedFor)) ||
    (forbiddenFor && !userAccessLevelIn(forbiddenFor))
  ) {
    return children;
  } else if (childProps) {
    return (
      <>
        {React.Children.map(children, child =>
          React.cloneElement(child, childProps),
        )}
      </>
    );
  } else {
    return fallback || null;
  }
};

type UserCanAccessProps = {
  children?: any;
};

export const DispatcherCanAccess: React.FC<UserCanAccessProps> = ({
  children,
}) => {
  return !Core.isAdminPortal() ? (
    <CanAccess allowedUserType={[APIUserType.Dispatcher, APIUserType.Support]}>
      {children}
    </CanAccess>
  ) : null;
};

export const SupportCanAccess: React.FC<UserCanAccessProps> = ({
  children,
}) => {
  return Core.isAdminPortal() ? (
    <CanAccess allowedUserType={APIUserType.Support}>{children}</CanAccess>
  ) : null;
};

// all users can access in general app, but not in admin
export const RegularUserCanAccess: React.FC<UserCanAccessProps> = ({
  children,
}) => {
  return !Core.isAdminPortal() ? (
    <CanAccess
      allowedUserType={[
        APIUserType.Contractor,
        APIUserType.Dispatcher,
        APIUserType.Support,
      ]}
    >
      {children}
    </CanAccess>
  ) : null;
};

export const ContractorCanAccess: React.FC<UserCanAccessProps> = ({
  children,
}) => {
  return (
    <CanAccess allowedUserType={APIUserType.Contractor}>{children}</CanAccess>
  );
};
