import { State } from 'modules';

import { createSelector } from 'third-party';

export const customerAccountsListItems = (state: State) =>
  state.adminCustomerAccountsList.items;
export const customerAccountsListCount = (state: State) =>
  state.adminCustomerAccountsList.count;
export const customerAccountsLoaded = (state: State) =>
  state.adminCustomerAccountsList.cusomerAccountsLoaded;
export const customerAccountsListSorting = (state: State) =>
  state.adminCustomerAccountsList.sorting;
export const customerAccountsListFilters = (state: State) =>
  state.adminCustomerAccountsList.filters;
export const customerAccountsListNameFilter = (state: State) =>
  state.adminCustomerAccountsList.filters.providerName;
export const loadCustomerAccountsPending = (state: State) =>
  state.adminCustomerAccountsList.loadCustomerAccountsPending;
export const loadMoreCustomerAccountsPending = (state: State) =>
  state.adminCustomerAccountsList.loadMoreCustomerAccountsPending;
export const createCustomerAccountPending = (state: State) =>
  state.adminCustomerAccountsList.createCustomerAccountPending;
export const initiateAccountPending = (state: State) =>
  state.adminCustomerAccountsList.initiateAccountPending;
export const firstAccountInList = createSelector(
  [customerAccountsLoaded, customerAccountsListItems],
  (customerAccountsLoaded, items) => {
    return customerAccountsLoaded && items.length > 0 ? items[0] : null;
  },
);
