import { useMemo } from 'react';

import { simplifyBuilder } from '@cd3p/core';
import {
  APIRequiredActionSortOrderType,
  APIUser,
  APIUserType,
} from '@cd3p/core/types/api';
import { userId as userIdSelector } from 'selectors/app';

import { _, bindActionCreators, useDispatch, useSelector } from 'third-party';

import {
  AppTableSettings,
  contractorAllOrdersHiddenColumns,
  dispatcherAllOrdersHiddenColumns,
  dispatcherTodayOrdersHiddenColumns,
} from 'constants/common';

import { AppLeftPanel } from 'modules/app';
import { OrdersFilters, ordersDefaultFilters } from 'modules/common/ordersList';

import { TableSettingsT } from 'types/app';

export type StorageUserSettings = {
  currentProviderId: string | null;
  openedLeftPanel: null | AppLeftPanel;
  [AppTableSettings.AllOrders]: TableSettingsT<OrdersFilters>;
  [AppTableSettings.TodayOrders]: TableSettingsT;
  leftPanelAnimationDuration: number;
  showTrafficLayerOnMap: boolean;
  requiredActionsListSorting: APIRequiredActionSortOrderType;
};

export const storageDefaultUserSettings = (
  user: APIUser,
): StorageUserSettings => {
  // WARNING!
  // changing the existing part of this structure will result of
  // losing that part of settings on the user device. they will need
  // to set up those settings again
  return {
    openedLeftPanel: null,
    currentProviderId: user?.providers?.[0]?.id || null,
    leftPanelAnimationDuration: 500,
    showTrafficLayerOnMap: false,
    requiredActionsListSorting: APIRequiredActionSortOrderType.Oldest,
    [AppTableSettings.AllOrders]: {
      columnsWidth: {},
      filters: ordersDefaultFilters,
      hiddenColumns:
        user.userType === APIUserType.Contractor
          ? contractorAllOrdersHiddenColumns
          : dispatcherAllOrdersHiddenColumns,
    },
    [AppTableSettings.TodayOrders]: {
      columnsWidth: {},
      filters: {},
      hiddenColumns: dispatcherTodayOrdersHiddenColumns,
    },
  };
};

export type StorageState = {
  userSettings: Record<string, StorageUserSettings>;
};

export const storageState: StorageState = {
  userSettings: {},
};

const builder = simplifyBuilder(storageState, {});

const initiateUserSettings = builder.createReduxAction((user: APIUser) => ({
  name: 'updateUserSettingInStorage',
  updater: (state: StorageState) => ({
    userSettings: {
      ...state.userSettings,
      [user.id]: {
        ...storageDefaultUserSettings(user),
        ...state.userSettings[user.id],
      },
    },
  }),
}));

const updateUserSettingInStorage = builder.createReduxAction(
  (
    userId: string,
    updater:
      | Partial<StorageUserSettings>
      | ((userSettings: StorageUserSettings) => Partial<StorageUserSettings>),
  ) => ({
    name: 'updateUserSettingInStorage',
    updater: (state: StorageState) => {
      return {
        userSettings: {
          ...state.userSettings,
          [userId]: {
            ...state.userSettings[userId],
            ...(typeof updater === 'function'
              ? updater(state.userSettings[userId])
              : updater),
          },
        },
      };
    },
  }),
);

export const toggleTableColumnVisibilityStorageUpdater =
  (storageKey: AppTableSettings) =>
  (columnId: string, visible: boolean) =>
  (userSettings: StorageUserSettings): Partial<StorageUserSettings> => ({
    [storageKey]: {
      ...userSettings[storageKey],
      hiddenColumns: _.uniq(
        visible
          ? _.without(userSettings[storageKey].hiddenColumns || [], columnId)
          : [...(userSettings[storageKey].hiddenColumns || []), columnId],
      ),
    },
  });
export const setTableColumnsWidthsStorageUpdater =
  (storageKey: AppTableSettings) =>
  (columnsWidth: Record<string, number>) =>
  (userSettings: StorageUserSettings): Partial<StorageUserSettings> => ({
    [storageKey]: {
      ...userSettings[storageKey],
      columnsWidth,
    },
  });

export const tableSettingsFiltersStorageUpdater =
  (storageKey: AppTableSettings) =>
  (newFilters: OrdersFilters) =>
  (userSettings: StorageUserSettings): Partial<StorageUserSettings> => ({
    [storageKey]: {
      ...userSettings[storageKey],
      filters: {
        ...userSettings[storageKey].filters,
        ...newFilters,
      },
    },
  });
export const tableSettingsFiltersStorageResetter =
  (storageKey: AppTableSettings) =>
  (defaultFilters: unknown) =>
  (userSettings: StorageUserSettings): Partial<StorageUserSettings> => ({
    [storageKey]: {
      ...userSettings[storageKey],
      filters: defaultFilters,
    },
  });

export const setTableSettingsColumnsWidth = Object.values(
  AppTableSettings,
).reduce((previousValue, key) => {
  return {
    ...previousValue,
    [key]: setTableColumnsWidthsStorageUpdater(key),
  };
}, {} as Record<AppTableSettings, ReturnType<typeof setTableColumnsWidthsStorageUpdater>>);

export const toggleTableSettingsColumnVisibility = Object.values(
  AppTableSettings,
).reduce((previousValue, key) => {
  return {
    ...previousValue,
    [key]: toggleTableColumnVisibilityStorageUpdater(key),
  };
}, {} as Record<AppTableSettings, ReturnType<typeof toggleTableColumnVisibilityStorageUpdater>>);

export const updateTableSettingsFilters = Object.values(
  AppTableSettings,
).reduce((previousValue, key) => {
  return {
    ...previousValue,
    [key]: tableSettingsFiltersStorageUpdater(key),
  };
}, {} as Record<AppTableSettings, ReturnType<typeof tableSettingsFiltersStorageUpdater>>);

export const resetTableSettingsFilters = Object.values(AppTableSettings).reduce(
  (previousValue, key) => {
    return {
      ...previousValue,
      [key]: tableSettingsFiltersStorageResetter(key),
    };
  },
  {} as Record<
    AppTableSettings,
    ReturnType<typeof tableSettingsFiltersStorageResetter>
  >,
);

export const useStorage = () => {
  const dispatch = useDispatch();
  const userId = useSelector(userIdSelector);

  return useMemo(
    () =>
      bindActionCreators(
        {
          initiateUserSettings: initiateUserSettings.bind(null),
          updateUserSettingInStorageByUserId:
            updateUserSettingInStorage.bind(null),
          updateUserSettingInStorage: updateUserSettingInStorage.bind(
            null,
            userId!,
          ),
        },
        dispatch,
      ),
    [dispatch, userId],
  );
};

export const storageReducer = builder.getReducers();
