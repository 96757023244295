import { useMemo } from 'react';

import { httpMethod, simplifyBuilder } from '@cd3p/core';
import {
  APIMasterMixType,
  APIMasterMixTypeModelSearchResult,
  APIMixTypeSearchFilter,
  APISortOrder,
} from '@cd3p/core/types/api';
import {
  DEFAULT_PAGE_SIZE,
  getPageNumberAfterLoadMore,
  getPageSizeDependingOnItems,
} from '@cd3p/core/utils/lists';

import { _, bindActionCreators, useDispatch, useSelector } from 'third-party';

import {
  contractorMixTypeEndpoint,
  customerMixTypesEndpoint,
  customerProjectMixTypeEndpoint,
  customerProjectMixTypesEndpoint,
} from 'constants/endpoints';

import { State } from 'modules/index';

import { appSelectors } from 'selectors';

export type ContractorMixTypesColumnId = keyof APIMasterMixType;

export type ContractorMixTypesSorting = {
  sortField: ContractorMixTypesColumnId;
  sortOrder: APISortOrder;
}[];

export type ContractorMixTypesState = {
  items: APIMasterMixTypeModelSearchResult['result'];
  mixTypesLoaded: boolean;
  loadContractorMixTypesPending: boolean;
  loadMoreContractorMixTypesPending: boolean;
  loadProjectMixTypesPending: boolean;
  loadMoreProjectMixTypesPending: boolean;
  sorting: ContractorMixTypesSorting;
  pagination: {
    pageNumber: APIMasterMixTypeModelSearchResult['pageNumber'];
    pageSize: APIMasterMixTypeModelSearchResult['pageSize'];
  };
  count: number;
};

export const contractorMixTypesState: ContractorMixTypesState = {
  items: [],
  sorting: [
    {
      sortField: 'name',
      sortOrder: APISortOrder.ASC,
    },
  ],
  mixTypesLoaded: false,
  loadContractorMixTypesPending: false,
  loadMoreContractorMixTypesPending: false,
  loadProjectMixTypesPending: false,
  loadMoreProjectMixTypesPending: false,
  pagination: { pageNumber: 1, pageSize: DEFAULT_PAGE_SIZE },
  count: 0,
};

const builder = simplifyBuilder(contractorMixTypesState, {});

export const loadContractorMixTypes = builder.createServerAction(
  (
    providerId: string,
    contractorId: string,
    body: APIMixTypeSearchFilter = {},
  ) => ({
    name: 'loadContractorMixTypes',
    url: customerMixTypesEndpoint(providerId, contractorId),
    body: (state: State) => ({
      pageSize: state.contractorMixTypes.pagination.pageSize,
      pageNumber: state.contractorMixTypes.pagination.pageNumber,
      searchSortOrders: state.contractorMixTypes.sorting,
      ...body,
    }),
    method: httpMethod.post,
    onSuccess: (
      state: ContractorMixTypesState,
      payload: APIMasterMixTypeModelSearchResult,
    ) => {
      return {
        items: payload.result || [],
        mixTypesLoaded: true,
        pagination: {
          pageNumber: payload.pageNumber,
          pageSize: payload.pageSize,
        },
        count: payload.count,
      };
    },
  }),
);

export const loadProjectMixTypes = builder.createServerAction(
  (
    providerId: string,
    projectId: string,
    body: APIMixTypeSearchFilter = {},
  ) => ({
    name: 'loadProjectMixTypes',
    url: customerProjectMixTypesEndpoint(providerId, projectId),
    body: (state: State) => ({
      pageSize: state.contractorMixTypes.pagination.pageSize,
      pageNumber: state.contractorMixTypes.pagination.pageNumber,
      searchSortOrders: state.contractorMixTypes.sorting,
      ...body,
    }),
    method: httpMethod.post,
    onSuccess: (
      state: ContractorMixTypesState,
      payload: APIMasterMixTypeModelSearchResult,
    ) => {
      return {
        items: payload.result || [],
        mixTypesLoaded: true,
        pagination: {
          pageNumber: payload.pageNumber,
          pageSize: payload.pageSize,
        },
        count: payload.count,
      };
    },
  }),
);

export const loadMoreContractorMixTypes = builder.createServerAction(
  (providerId: string, contractorId: string) => ({
    name: 'loadMoreContractorMixTypes',
    url: customerMixTypesEndpoint(providerId, contractorId),
    body: (state: State) => ({
      pageNumber: getPageNumberAfterLoadMore(
        state.contractorMixTypes.items,
        state.contractorMixTypes.pagination.pageSize,
      ),
      pageSize: state.contractorMixTypes.pagination.pageSize,
      searchSortOrders: state.contractorMixTypes.sorting,
    }),
    method: httpMethod.post,
    onSuccess: (
      state: ContractorMixTypesState,
      payload: APIMasterMixTypeModelSearchResult,
    ) => {
      return {
        items: _.uniqBy([...state.items, ...payload.result], 'id'),
      };
    },
  }),
);

export const loadMoreProjectMixTypes = builder.createServerAction(
  (providerId: string, projectId: string) => ({
    name: 'loadMoreProjectMixTypes',
    url: customerProjectMixTypesEndpoint(providerId, projectId),
    body: (state: State) => ({
      pageNumber: getPageNumberAfterLoadMore(
        state.contractorMixTypes.items,
        state.contractorMixTypes.pagination.pageSize,
      ),
      pageSize: state.contractorMixTypes.pagination.pageSize,
      searchSortOrders: state.contractorMixTypes.sorting,
    }),
    method: httpMethod.post,
    onSuccess: (
      state: ContractorMixTypesState,
      payload: APIMasterMixTypeModelSearchResult,
    ) => {
      return {
        items: [...state.items, ...payload.result],
      };
    },
  }),
);

export const updateContractorMixTypes = builder.createServerAction(
  (providerId: string, contractorId: string) => ({
    name: 'updateContractorMixTypes',
    url: customerMixTypesEndpoint(providerId, contractorId),
    body: (state: State) => ({
      pageNumber: 1,
      pageSize: getPageSizeDependingOnItems(state.contractorMixTypes.items),
      searchSortOrders: state.contractorMixTypes.sorting,
    }),
    method: httpMethod.post,
    onSuccess: (
      state: ContractorMixTypesState,
      payload: APIMasterMixTypeModelSearchResult,
    ) => {
      return {
        items: payload.result,
        count: payload.count,
      };
    },
  }),
);

export const updateProjectMixTypes = builder.createServerAction(
  (providerId: string, projectId: string) => ({
    name: 'updateProjectMixTypes',
    url: customerProjectMixTypesEndpoint(providerId, projectId),
    body: (state: State) => ({
      pageNumber: 1,
      pageSize: getPageSizeDependingOnItems(state.contractorMixTypes.items),
      searchSortOrders: state.contractorMixTypes.sorting,
    }),
    method: httpMethod.post,
    onSuccess: (
      state: ContractorMixTypesState,
      payload: APIMasterMixTypeModelSearchResult,
    ) => {
      return {
        items: payload.result,
        count: payload.count,
      };
    },
  }),
);

export const deleteContractorMixType = builder.createServerAction(
  (providerId: string, contractorId: string, mixTypeId: number) => ({
    name: 'deleteContractorMixType',
    url: contractorMixTypeEndpoint(providerId, contractorId, mixTypeId),
    method: httpMethod.delete,
  }),
);

export const deleteProjectMixType = builder.createServerAction(
  (providerId: string, projectId: string, mixTypeId: number) => ({
    name: 'deleteProjectMixType',
    url: customerProjectMixTypeEndpoint(providerId, projectId, mixTypeId),
    method: httpMethod.delete,
  }),
);

export const updateContractorMixTypesSorting = builder.createReduxAction(
  (newSorting: ContractorMixTypesState['sorting']) => ({
    name: 'updateContractorMixTypesSorting',
    updater: () => ({
      sorting: newSorting,
    }),
  }),
);

export const resetContractorMixTypes = builder.createReduxAction(() => ({
  name: 'resetContractorMixTypes',
  updater: () => contractorMixTypesState,
}));

export const useContractorMixTypes = () => {
  const dispatch = useDispatch();
  const providerId = useSelector(appSelectors.providerId);

  return useMemo(
    () =>
      bindActionCreators(
        {
          loadContractorMixTypes: loadContractorMixTypes.bind(null, providerId),
          loadMoreContractorMixTypes: loadMoreContractorMixTypes.bind(
            null,
            providerId,
          ),
          loadProjectMixTypes: loadProjectMixTypes.bind(null, providerId),
          loadMoreProjectMixTypes: loadMoreProjectMixTypes.bind(
            null,
            providerId,
          ),
          updateContractorMixTypes: updateContractorMixTypes.bind(
            null,
            providerId,
          ),
          updateProjectMixTypes: updateProjectMixTypes.bind(null, providerId),
          updateContractorMixTypesSorting:
            updateContractorMixTypesSorting.bind(null),
          deleteContractorMixType: deleteContractorMixType.bind(
            null,
            providerId,
          ),
          deleteProjectMixType: deleteProjectMixType.bind(null, providerId),
          resetContractorMixTypes: resetContractorMixTypes.bind(null),
        },
        dispatch,
      ),
    [dispatch, providerId],
  );
};

export const contractorMixTypesReducer = builder.getReducers();
