import React, { useCallback, useEffect, useMemo, useState } from 'react';

import styled from 'styled-components';

import CheckIcon from '@mui/icons-material/Check';

import {
  GoogleMap,
  Libraries,
  TrafficLayer,
  useJsApiLoader,
} from '@react-google-maps/api';
import { useMapOptions } from 'hooks/useMapOptions';
import { subscribe } from 'hooks/usePubSub';
import { useResizeDetector } from 'react-resize-detector';

import { _, classNames, useTranslation } from 'third-party';

import { APP_EVENTS } from 'constants/appEvent';
import { GMAP_API_KEY } from 'constants/common';

import { MapTypes, UserSettings } from 'components/TrackingMap/TrackingMap';
import {
  mapStylesHybrid,
  mapStylesStandard,
} from 'components/TrackingMap/trackingMapStyles';
import { useMapPlaces } from 'components/TrackingMap/useMapPlaces';
import {
  Button,
  ButtonGroup,
  CircularProgress,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
} from 'components/index';

import { fitBounds } from 'utils/map';

import { LocationT } from 'types/app';

interface LatLngLiteral {
  lat: number;
  lng: number;
}

const MapWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  // create a context for z-index
  transform: translateZ(0);
`;

const LoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.palette.background.default};
  z-index: 2;
`;

const GoogleMapWrapper = styled.div`
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const AutocenterButton = styled(Button)`
  position: absolute;
  top: 1rem;
  left: 2rem;
  z-index: 2;
  font-size: 1.2rem;
  height: 2rem;
  padding: 0 1rem;
`;

const MapTypeSwitcher = styled(ButtonGroup)`
  position: absolute;
  bottom: 2rem;
  left: 2rem;
  z-index: 2;
`;

const TrafficLayerSwitcher = styled(Stack)`
  position: absolute;
  bottom: 2rem;
  left: 25rem;
  z-index: 2;
  background-color: white;
  border-radius: 4px;
  border: 1px solid ${props => props.theme.custom.palette.secondary700};
  padding: 0.7rem;
  align-items: center;
`;

const TrafficFormControlLabel = styled(FormControlLabel)`
  margin: 0;
`;

const TrafficLayerLabel = styled(Typography)`
  font-size: 1.4rem;
  font-weight: 600;
  color: ${props => props.theme.custom.palette.secondary900};
  margin-left: 0.8rem;
`;

const MapTypeButton = styled(Button)`
  width: 10rem;
  font-size: 1.4rem;
  font-weight: 600;
  text-transform: none;

  border: 1px solid ${props => props.theme.custom.palette.secondary700};
  &:not(.selected) {
    background-color: white;
  }
  &.selected {
    &:before {
      content: '';
    }
  }
`;

const StyledCheckIcon = styled(CheckIcon)`
  font-size: 1.5rem;
  margin-right: 0.5rem;
`;

const MAP_MIN_ZOOM = 3;

const googleApiLoaderConfig = {
  id: 'google-map-script',
  libraries: ['places'] as Libraries,
  googleMapsApiKey: GMAP_API_KEY,
};

const containerStyle = {
  width: '100%',
  height: '100%',
};

export type MapProps = {
  markers?: LocationT[];
  zoom?: number;
  autoZoomCorrection?: number;
  defaultZoom?: number;
  isLoading?: boolean;
  userSettings?: UserSettings | null;
  trafficLayer?: boolean;
  onTrafficLayerChange?: (value: boolean) => void;
  center?: LatLngLiteral;
  defaultMapType?: MapTypes;
  className?: string;
  placesSearch?: boolean;
  changeUserSettings?: (settings: UserSettings | null) => void;
  closeAllTooltips?: () => void;
  onMapTypeChanged?: (type: MapTypes) => void;
  children?: React.ReactNode | React.ReactNode[];
};

export const Map: React.FC<MapProps> = ({
  markers = [],
  defaultZoom = 12,
  userSettings,
  trafficLayer = false,
  onTrafficLayerChange,
  className,
  closeAllTooltips,
  center = {
    lat: 0,
    lng: 0,
  },
  onMapTypeChanged,
  changeUserSettings,
  defaultMapType,
  placesSearch = false,
  zoom,
  autoZoomCorrection = -1,
  isLoading = false,
  children,
}) => {
  const { t } = useTranslation();

  const {
    width: mapContainerWidth,
    height: mapContainerHeight,
    ref,
  } = useResizeDetector();

  const options = useMapOptions({ defaultMapType });

  const [isAutocentering, setIsAutocentering] = useState(true);
  const [googleApi, setGoogleApi] = useState<any>(null);
  const [currentZoom, setCurrentZoom] = useState<number | null>(null);
  const [currentMapType, setCurrentMapType] = useState(options.defaultMapType);

  const { mapPlacesTypeahead, places, placesMarkers } = useMapPlaces({
    enabled: placesSearch,
  });

  const { isLoaded } = useJsApiLoader(googleApiLoaderConfig);

  const onGoogleApiLoaded = useCallback((googleApi: any) => {
    setGoogleApi(googleApi);
  }, []);

  const onGoogleApiUnloaded = useCallback(() => {
    setGoogleApi(null);
  }, []);

  const { center: calculatedCenter, zoom: calculatedZoom } = useMemo<{
    center?: LatLngLiteral;
    zoom?: number;
  }>(() => {
    if (center && !places.length && center.lat && center.lng) {
      return { center, zoom: zoom || defaultZoom };
    }

    const allMarkers = [...markers, ...places].filter(
      it =>
        _.isNumber(it.longitude) &&
        _.isNumber(it.latitude) &&
        it.longitude !== 0 &&
        it.latitude !== 0,
    );

    if (allMarkers.length === 1) {
      return {
        center: { lat: allMarkers[0].latitude, lng: allMarkers[0].longitude },
        zoom: 14,
      };
    }

    if (googleApi && allMarkers.length) {
      const bounds = new window.google.maps.LatLngBounds();

      for (let i = 0; i < allMarkers.length; i++) {
        const marker = allMarkers[i];
        const newPoint = new window.google.maps.LatLng(
          marker.latitude,
          marker.longitude,
        );
        bounds.extend(newPoint);
      }

      const newBounds = {
        ne: {
          lat: bounds.getNorthEast().lat(),
          lng: bounds.getNorthEast().lng(),
        },
        sw: {
          lat: bounds.getSouthWest().lat(),
          lng: bounds.getSouthWest().lng(),
        },
      };

      // @ts-ignore
      let { center, zoom } = fitBounds(newBounds, {
        width: mapContainerWidth || 500,
        height: mapContainerHeight || 500,
      });

      if (zoom! < MAP_MIN_ZOOM) {
        zoom = MAP_MIN_ZOOM;
        // when calculated zoom is less than the minimum, it means the map cannot zoom out
        // more to fit all markers. in this case there's a situation when it's
        // placed in the middle of nowhere showing nothing. we force map center to be
        // the first marker in this case
        center = allMarkers.length
          ? { lat: allMarkers[0].latitude, lng: allMarkers[0].longitude }
          : center;
      } else {
        // we decrease zoom by `autoZoomCorrection` to not let markers stick to the map edges
        zoom = Math.max(zoom! + autoZoomCorrection, MAP_MIN_ZOOM);
      }

      return { center, zoom };
    } else {
      return { zoom: defaultZoom };
    }
  }, [
    autoZoomCorrection,
    center,
    markers,
    places,
    googleApi,
    zoom,
    defaultZoom,
    mapContainerWidth,
    mapContainerHeight,
  ]);

  const onAutocenterClicked = useCallback(() => {
    closeAllTooltips?.();
    setCurrentZoom(null);
    changeUserSettings?.(null);
    setIsAutocentering(true);
  }, [changeUserSettings, closeAllTooltips]);

  useEffect(() => {
    return subscribe(APP_EVENTS.AUTOCENTER_MAP, onAutocenterClicked);
  }, [onAutocenterClicked]);

  const userChangedMapSettings = useCallback(() => {
    setIsAutocentering(false);
    changeUserSettings?.(null);
  }, [changeUserSettings]);

  const onDragEnd = useCallback(() => {
    closeAllTooltips?.();
    userChangedMapSettings();
  }, [closeAllTooltips, userChangedMapSettings]);

  const onDragStart = useCallback(() => {
    const mapTooltips = document.querySelectorAll<HTMLElement>(
      '[role="tooltip"][class~="map-tooltip"]',
    );
    _.forEach(
      mapTooltips,
      tooltipElement => (tooltipElement.style.display = 'none'),
    );
  }, []);

  useEffect(() => {
    if (userSettings && googleApi.map) {
      setIsAutocentering(false);
    }
  }, [googleApi?.map, userSettings]);

  const onMapScroll = _.throttle(() => {
    closeAllTooltips?.();
    googleApi && setCurrentZoom(googleApi.getZoom());
    userChangedMapSettings();
  }, 500);

  const onMapClick = useCallback(
    (event: React.PointerEvent<HTMLElement>) => {
      // if click on zoom control button
      if (
        (event?.target as Element)?.classList?.contains?.('gm-control-active')
      ) {
        closeAllTooltips?.();
        googleApi && setCurrentZoom(googleApi.getZoom());
        userChangedMapSettings();
      }
    },
    [closeAllTooltips, googleApi, userChangedMapSettings],
  );

  useEffect(() => {
    const mapElement = ref.current;
    mapElement.addEventListener('mousewheel', onMapScroll, true);
    mapElement.addEventListener('DOMMouseScroll', onMapScroll, true);
    mapElement.addEventListener('click', onMapClick);
    return () => {
      mapElement.removeEventListener('mousewheel', onMapScroll);
      mapElement.addEventListener('DOMMouseScroll', onMapScroll);
      mapElement.addEventListener('click', onMapClick);
    };
  }, [onMapClick, onMapScroll, ref]);

  const mapCenter = useMemo(() => {
    if (userSettings?.center) {
      return userSettings?.center;
    } else {
      return isAutocentering
        ? calculatedCenter || center
        : googleApi?.getCenter?.();
    }
  }, [
    calculatedCenter,
    center,
    googleApi,
    isAutocentering,
    userSettings?.center,
  ]);

  const mapZoom = useMemo(() => {
    if (userSettings?.zoom) {
      return userSettings?.zoom;
    } else {
      return isAutocentering
        ? calculatedZoom || zoom || defaultZoom
        : currentZoom || calculatedZoom;
    }
  }, [
    calculatedZoom,
    currentZoom,
    defaultZoom,
    isAutocentering,
    userSettings?.zoom,
    zoom,
  ]);

  const mapOptions = useMemo(
    () => ({
      scrollwheel: true,
      fullscreenControl: true,
      mapTypeControl: false,
      streetViewControl: false,
      styles:
        currentMapType === MapTypes.Hybrid
          ? mapStylesHybrid
          : mapStylesStandard,
      mapTypeId: currentMapType,
      minZoom: MAP_MIN_ZOOM,
    }),
    [currentMapType],
  );

  return (
    <MapWrapper ref={ref} className={className}>
      {(isLoading || !isLoaded) && (
        <LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>
      )}
      <>
        {!isAutocentering && !isLoading && (
          <AutocenterButton variant="contained" onClick={onAutocenterClicked}>
            {t('trackingMap.autocenterButtonText')}
          </AutocenterButton>
        )}
        <GoogleMapWrapper>
          {isLoaded ? (
            <GoogleMap
              mapContainerStyle={containerStyle}
              options={mapOptions}
              center={mapCenter}
              zoom={mapZoom}
              onLoad={onGoogleApiLoaded}
              onDragStart={onDragStart}
              onUnmount={onGoogleApiUnloaded}
              onDragEnd={onDragEnd}
            >
              {trafficLayer && <TrafficLayer />}
              {mapPlacesTypeahead}
              {placesMarkers}
              {children}
            </GoogleMap>
          ) : null}
        </GoogleMapWrapper>
      </>
      <MapTypeSwitcher variant="contained">
        <MapTypeButton
          onClick={() => {
            setCurrentMapType(MapTypes.Roadmap);
            onMapTypeChanged?.(MapTypes.Roadmap);
          }}
          className={classNames({
            selected: currentMapType === MapTypes.Roadmap,
          })}
        >
          {currentMapType === MapTypes.Roadmap && <StyledCheckIcon />}
          {t('trackingMap.mapType')}
        </MapTypeButton>
        <MapTypeButton
          onClick={() => {
            setCurrentMapType(MapTypes.Hybrid);
            onMapTypeChanged?.(MapTypes.Hybrid);
          }}
          className={classNames({
            selected: currentMapType === MapTypes.Hybrid,
          })}
        >
          {currentMapType === MapTypes.Hybrid && <StyledCheckIcon />}
          {t('trackingMap.satelliteType')}
        </MapTypeButton>
      </MapTypeSwitcher>
      {onTrafficLayerChange && (
        <TrafficLayerSwitcher>
          <TrafficFormControlLabel
            control={
              <Switch
                checked={trafficLayer}
                onChange={() => onTrafficLayerChange(!trafficLayer)}
              />
            }
            label={
              <TrafficLayerLabel>
                {t('trackingMap.showTraffic')}
              </TrafficLayerLabel>
            }
          />
        </TrafficLayerSwitcher>
      )}
    </MapWrapper>
  );
};
