import React, { useCallback, useMemo, useState } from 'react';

import styled, { css } from 'styled-components';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import { APIMasterAdditiveType, APIMasterMixType } from '@cd3p/core/types/api';
import { getSortOrder } from '@cd3p/core/utils/common';
import { IconButton, Stack, Table, TableCell, Typography } from 'components';

import { useSelector, useTranslation } from 'third-party';

import {
  AdditiveTypesFilters,
  AdditiveTypesListColumnId,
  AdditiveTypesSorting,
  useAdditiveTypesList,
} from 'modules/additiveTypesList';

import { additiveTypesSelectors } from 'selectors';

import { StyledTable } from 'components/Table/Table';

import { TableCellLink } from 'features/Contractors/Tabs/tabHelpers';
import { ContractorAssignmentHoverTooltip } from 'features/PlantSettings/common/ContractorAssignmentHoverTooltip';
import { ContractorAssignmentListPopup } from 'features/PlantSettings/common/ContractorAssignmentListPopup';

const TableCellText = styled(Typography)`
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 1.5;
  color: ${props => props.theme.custom.palette.gray};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const StyledTableBodyCell = styled(TableCell)<{
  height: string;
  cellstyles: string;
}>`
  position: relative;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 0;
  border-bottom: 1px solid ${props => props.theme.custom.palette.truegray300};
  height: ${props => props.height};
  padding: 0 1.6rem;
  &:not(:last-child):after {
    position: absolute;
    top: 1rem;
    right: 0;
    bottom: 0;
    border-right: 1px solid ${props => props.theme.custom.palette.truegray300};
    width: 1px;
    content: '';
  }
  ${props => props.cellstyles};
`;

const ActionButtonsCellStyles = css`
  padding: 0 0.8rem;
`;

const StyledTableColumnLabel = styled(Typography)`
  font-weight: 900;
  font-size: 2rem;
  text-transform: uppercase;
  color: ${props => props.theme.custom.palette.primary900};
`;

const StyledTableComponent = styled(StyledTable)`
  margin-bottom: 1.2rem;
`;

const tableComponents = {
  TableBodyCell: StyledTableBodyCell,
  TableColumnLabel: StyledTableColumnLabel,
  Table: StyledTableComponent,
};

type Props = {
  fetchAdditiveTypes: (
    params?: {
      searchSortOrders?: AdditiveTypesSorting;
    } & Partial<AdditiveTypesFilters>,
  ) => void;
  setAdditiveTypeIdForDelete: (itemId: number) => void;
  setAdditiveTypeIdForEdit: (itemId: number) => void;
};

export const AdditiveTypesTable: React.FC<Props> = ({
  fetchAdditiveTypes,
  setAdditiveTypeIdForDelete,
  setAdditiveTypeIdForEdit,
}) => {
  const { t } = useTranslation();

  const {
    loadMoreAdditiveTypes,
    updateAdditiveTypesListSorting,
    resetAdditiveTypes,
    loadAdditiveTypeAssignment,
    resetAdditiveTypeAssignment,
  } = useAdditiveTypesList();

  const [showAssignmentPopup, setShowAssignmentPopup] =
    useState<boolean>(false);
  const [selectedAdditiveType, setSelectedAdditiveType] =
    useState<null | APIMasterAdditiveType>(null);

  const additiveTypesListItems = useSelector(
    additiveTypesSelectors.additiveTypesListItems,
  );

  const additiveTypesListCount = useSelector(
    additiveTypesSelectors.additiveTypesListCount,
  );
  const loadMoreAdditiveTypesPending = useSelector(
    additiveTypesSelectors.loadMoreAdditiveTypesPending,
  );
  const filters = useSelector(additiveTypesSelectors.additiveTypesListFilters);
  const sorting = useSelector(additiveTypesSelectors.additiveTypesListSorting);
  const { sortField, sortOrder } = sorting[0];
  const loadAdditiveTypesPending = useSelector(
    additiveTypesSelectors.loadAdditiveTypesPending,
  );
  const additiveTypeAssignment = useSelector(
    additiveTypesSelectors.additiveTypeAssignment,
  );
  const additiveTypeAssignmentCount = useSelector(
    additiveTypesSelectors.additiveTypeAssignmentCount,
  );
  const loadAdditiveTypeAssignmentPending = useSelector(
    additiveTypesSelectors.loadAdditiveTypeAssignmentPending,
  );
  const additiveTypeAssignmentId = useSelector(
    additiveTypesSelectors.additiveTypeAssignmentId,
  );
  const additiveTypesLoaded = useSelector(
    additiveTypesSelectors.additiveTypesLoaded,
  );

  const onColumnHeaderClicked = useCallback(
    (columnId: AdditiveTypesListColumnId) => {
      const searchSortOrders = [
        {
          sortField: columnId,
          sortOrder: getSortOrder(columnId, sortField, sortOrder),
        },
      ];
      resetAdditiveTypes();
      fetchAdditiveTypes({ searchSortOrders });
      updateAdditiveTypesListSorting(searchSortOrders);
    },
    [
      sortField,
      sortOrder,
      resetAdditiveTypes,
      fetchAdditiveTypes,
      updateAdditiveTypesListSorting,
    ],
  );

  const onAssignmentElementClick = useCallback(
    (additiveType: APIMasterAdditiveType) => {
      if (additiveType?.assignment > 0) {
        setSelectedAdditiveType(additiveType);
        setShowAssignmentPopup(true);
      }
    },
    [],
  );

  const onAdditiveAssignmentPopupOpen = useCallback(() => {
    if (
      selectedAdditiveType?.id &&
      selectedAdditiveType?.id !== additiveTypeAssignmentId
    ) {
      loadAdditiveTypeAssignment(selectedAdditiveType?.id);
    }
  }, [
    selectedAdditiveType?.id,
    additiveTypeAssignmentId,
    loadAdditiveTypeAssignment,
  ]);

  const columns = useMemo(
    () => [
      {
        id: 'name',
        dataId: 'name' as const,
        label: t('additiveTypes.table.nameColumnLabel'),
        formatter: (value: APIMasterAdditiveType['name']) => ({
          value: value || '',
          element: <TableCellText>{value || ''}</TableCellText>,
        }),
      },
      {
        id: 'categories',
        dataId: 'categories' as const,
        label: t('additiveTypes.table.categoryColumnLabel'),
        sortable: false,
        formatter: (value: APIMasterAdditiveType['categories']) => {
          const displayValue = value?.map(it => it.name).join(', ');
          return {
            value: displayValue,
            element: (
              <Stack direction="row">
                <TableCellText>{displayValue}</TableCellText>
              </Stack>
            ),
          };
        },
      },
      {
        id: 'usage',
        dataId: 'usage' as const,
        width: '16%',
        label: t('additiveTypes.table.usageColumnLabel'),
        formatter: (value: APIMasterMixType['usage']) => ({
          value: value || '',
          element: (
            <TableCellLink disabled>
              {t('additiveTypes.table.usageValue', {
                count: value || 0,
              })}
            </TableCellLink>
          ),
        }),
      },
      {
        id: 'assignment',
        dataId: 'assignment' as const,
        width: '16%',
        label: t('additiveTypes.table.assignmentColumnLabel'),
        formatter: (
          value: APIMasterAdditiveType['assignment'],
          additiveType: APIMasterAdditiveType,
        ) => {
          const displayValue = t('additiveTypes.table.assignmentValue', {
            count: value || 0,
          });

          return {
            value: displayValue,
            element: (
              <span>
                <ContractorAssignmentHoverTooltip
                  assignmentCount={additiveTypeAssignmentCount}
                  assignmentData={additiveTypeAssignment}
                  onOpen={() => {
                    if (
                      !showAssignmentPopup &&
                      additiveTypeAssignmentId !== additiveType.id
                    ) {
                      loadAdditiveTypeAssignment(additiveType.id);
                    }
                  }}
                  disabled={!value}
                  isHidden={additiveTypeAssignmentId !== additiveType.id}
                >
                  <TableCellLink
                    disabled={!additiveType.assignment}
                    onClick={() => onAssignmentElementClick(additiveType)}
                  >
                    {displayValue}
                  </TableCellLink>
                </ContractorAssignmentHoverTooltip>
              </span>
            ),
          };
        },
      },
      {
        id: 'action-buttons',
        width: '11.2rem',
        cellStyles: ActionButtonsCellStyles,
        formatter: (value: string, additiveType: APIMasterAdditiveType) => {
          return {
            value: '',
            element: (
              <span>
                <IconButton
                  onClick={() => {
                    setAdditiveTypeIdForEdit(additiveType.id);
                  }}
                >
                  <EditOutlinedIcon color="primary" />
                </IconButton>
                <IconButton
                  onClick={() => {
                    setAdditiveTypeIdForDelete(additiveType.id);
                  }}
                >
                  <DeleteOutlineIcon color="primary" />
                </IconButton>
              </span>
            ),
          };
        },
      },
    ],
    [
      additiveTypeAssignment,
      additiveTypeAssignmentCount,
      additiveTypeAssignmentId,
      loadAdditiveTypeAssignment,
      onAssignmentElementClick,
      setAdditiveTypeIdForDelete,
      setAdditiveTypeIdForEdit,
      showAssignmentPopup,
      t,
    ],
  );

  return (
    <>
      {showAssignmentPopup && selectedAdditiveType && (
        <ContractorAssignmentListPopup
          title={String(selectedAdditiveType.name)}
          assignmentCount={additiveTypeAssignmentCount}
          assignmentData={additiveTypeAssignment}
          anchorElement={document.body}
          onClose={() => {
            setShowAssignmentPopup(false);
            setSelectedAdditiveType(null);
            resetAdditiveTypeAssignment();
          }}
          onOpen={onAdditiveAssignmentPopupOpen}
          isLoading={loadAdditiveTypeAssignmentPending}
        />
      )}
      <Table
        infiniteScroll
        tableMinHeight="20rem"
        components={tableComponents}
        columns={columns}
        items={additiveTypesListItems}
        itemsTotalCount={additiveTypesListCount}
        itemsLoaded={additiveTypesLoaded}
        loadItemsPending={loadAdditiveTypesPending}
        loadMoreItemsPending={loadMoreAdditiveTypesPending}
        onLoadMoreClicked={loadMoreAdditiveTypes}
        sortField={sortField}
        sortOrder={sortOrder}
        onColumnHeaderClicked={onColumnHeaderClicked}
        emptyText={
          filters?.name
            ? t('additiveTypes.table.emptySearch')
            : t('additiveTypes.table.emptyText')
        }
        loadMoreButtonText={t('additiveTypes.table.loadMoreButtonText')}
      />
    </>
  );
};
