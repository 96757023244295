import { userType } from '@cd3p/core/selectors/app';
import { APIUserType } from '@cd3p/core/types/api';
import { hasUnreadCustomerMessage } from '@cd3p/core/utils/dialogs';
import { State } from 'modules';
import { userId } from 'selectors/app';

import { createSelector } from 'third-party';

import { DIALOGS_PER_PAGE } from 'modules/dialogsList';

export const loadDialogsPending = (state: State) =>
  state.dialogsList.loadDialogsPending;
export const dialogsLoaded = (state: State) => state.dialogsList.dialogsLoaded;

export const loadMoreDialogsPending = (state: State) =>
  state.dialogsList.loadMoreDialogsPending;

export const dialogsListItems = (state: State) => state.dialogsList.items;

export const subscribedDialogsListItems = (state: State) =>
  state.dialogsList.subscribed;

export const subscribedOrdersIds = createSelector(
  subscribedDialogsListItems,
  subscribedMessages => {
    return subscribedMessages.map(it => it.orderId);
  },
);

export const dialogsListToken = (state: State) => state.dialogsList.token;

export const searchKeyword = (state: State) => state.dialogsList.searchKeyword;
export const dialogsUnreadCount = (state: State) =>
  state.dialogsList.dialogsUnreadCount;

export const hasUnreadContractorMessages = createSelector(
  [dialogsListItems, userId],
  (dialogsListItems, userId) =>
    hasUnreadCustomerMessage(dialogsListItems, userId),
);

export const hasUnreadMessages = createSelector(
  [userType, hasUnreadContractorMessages, dialogsUnreadCount],
  (userType, hasUnreadContractorMessages, dialogsUnreadCount) => {
    return userType === APIUserType.Dispatcher
      ? dialogsUnreadCount > 0
      : hasUnreadContractorMessages;
  },
);

export const dialogsListCounter = createSelector(
  [dialogsListToken, dialogsListItems],
  (token, items) => {
    return items.length + (token ? DIALOGS_PER_PAGE : 0);
  },
);

export const dialogsListPredictedTotalCount = createSelector(
  [dialogsLoaded, dialogsListToken, dialogsListItems],
  (isLoaded, token, items) => {
    // we don't know the total count in advance, we can only predict it
    // looking at the presence of continuation token
    // if token is present the list might have another `DIALOGS_PER_PAGE` amount
    // of items, if not - this is the last page and the total count equals the count we
    // already have loaded
    if (isLoaded) {
      if (token) {
        return items.length + DIALOGS_PER_PAGE;
      } else {
        return items.length;
      }
    } else {
      return DIALOGS_PER_PAGE;
    }
  },
);
