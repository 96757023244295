import React, { useCallback } from 'react';

import { ALL_ITEMS_COUNT } from '@cd3p/core/constants/common';
import { APIResponse, APITypeaheadItem } from '@cd3p/core/types/api';
import { FormField, TypeaheadFormField } from 'components';
import { FieldError } from 'react-hook-form';
import { FieldName } from 'react-hook-form/dist/types/fields';
import {
  MenuProps,
  Props as ReactSelectProps,
  SingleValue,
} from 'react-select';
import { GroupBase } from 'react-select/dist/declarations/src/types';

import { useFormContext, useTranslation } from 'third-party';

import { FIELD_MAX_LENGTH } from 'constants/common';

import { TypeaheadOption } from 'types/app';

export type CommonTypeaheadFieldProps<FormPropsT extends Record<string, any>> =
  {
    fieldName: FieldName<FormPropsT>;
    label: string;
    placeholder: string;
    notFoundText: string;
    requiredErrorMessage?: string;
    isDisabled?: boolean;
    isRequired?: boolean;
    isCreatable?: boolean;
    loadOnMount?: boolean;
    openMenuOnClick?: boolean;
    loadAllOptions?: boolean;
    showError?: boolean;
    maxLength?: number;
    onChange?: (option?: TypeaheadOption | null) => void;
    onClear?: () => void;
    menuElementBefore?: (
      props: MenuProps<TypeaheadOption, true, GroupBase<TypeaheadOption>>,
    ) => React.ReactElement | null;
    loadOptions: (
      query: string,
      body?: Record<string, any>,
    ) => Promise<APIResponse<APITypeaheadItem[]>>;
  } & ReactSelectProps;

export const CommonTypeaheadField = <FormPropsT extends Record<string, any>>({
  fieldName,
  label,
  placeholder,
  notFoundText,
  requiredErrorMessage,
  isDisabled,
  isRequired,
  isCreatable,
  loadOnMount,
  openMenuOnClick,
  loadAllOptions,
  loadOptions,
  onChange,
  onClear,
  showError = true,
  maxLength = FIELD_MAX_LENGTH,
  ...props
}: CommonTypeaheadFieldProps<FormPropsT>) => {
  const { t } = useTranslation();

  const {
    setValue,
    control,
    formState: { errors, isSubmitted },
  } = useFormContext();

  const notFoundTextCallback = useCallback(
    () => notFoundText || t('typeahead.notFound'),
    [notFoundText, t],
  );

  const loadCustomersOptions = async (value: string) => {
    const result = await loadOptions(value.trim(), {
      ...(loadAllOptions && { count: ALL_ITEMS_COUNT }),
    });
    let options: TypeaheadOption[] = [];
    if (!result.error) {
      options = result.payload.map(it => ({
        label: it.name,
        value: it.id,
      }));
    }
    return {
      options,
    };
  };

  const onSelectOption = useCallback(
    (option: SingleValue<TypeaheadOption>) => {
      // @ts-ignore fix type for `option`
      setValue(fieldName, option, {
        shouldValidate: isSubmitted,
        shouldDirty: true,
      });
      onChange?.(option);
      if (option === null) {
        onClear?.();
      }
    },
    [setValue, fieldName, isSubmitted, onChange, onClear],
  );

  return (
    <FormField
      fieldName={fieldName}
      label={label}
      fieldError={showError ? (errors[fieldName] as FieldError) : undefined}
      requiredErrorMessage={requiredErrorMessage}
      control={control}
      isDisabled={isDisabled}
      isRequired={isRequired}
      render={({ field }) => (
        // @ts-ignore
        <TypeaheadFormField
          openMenuOnClick={openMenuOnClick}
          defaultOptions={loadOnMount}
          isCreatable={isCreatable}
          hasError={showError && !!errors[fieldName]}
          placeholder={placeholder}
          noOptionsMessage={notFoundTextCallback}
          loadOptions={loadCustomersOptions}
          onChange={onSelectOption}
          isDisabled={isDisabled}
          value={field.value}
          maxLength={maxLength}
          {...props}
        />
      )}
    />
  );
};
