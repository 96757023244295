import React, { useEffect } from 'react';

import { CHAT_BOT_MESSAGE_SENDER_ID } from '@cd3p/core/constants/common';
import { log } from '@cd3p/core/utils/logger';
import { getMessaging, getToken } from '@firebase/messaging';
import { initializeApp } from 'firebase/app';
import { onMessage } from 'firebase/messaging';

import { Trans, useNavigate, useSelector, useTranslation } from 'third-party';

import { orderChatUrl } from 'constants/url';

import { useApp } from 'modules/app';
import { NotificationSeverity, useNotifications } from 'modules/notifications';
import { useStorage } from 'modules/storage';

import { appSelectors } from 'selectors';

const firebaseConfig = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_PROJECT_ID + '.firebaseapp.com',
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_PROJECT_ID + '.appspot.com',
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.FIREBASE_APP_ID,
};

const firebaseApp = firebaseConfig.apiKey
  ? initializeApp(firebaseConfig)
  : null;
export const messaging = firebaseApp ? getMessaging(firebaseApp) : null;

export const usePushNotifications = async () => {
  const userId = useSelector(appSelectors.userId);
  const { registerUserDevice } = useApp();

  usePushInformationActions();

  useEffect(() => {
    const setupPushNotifications = async () => {
      try {
        const permission = await window.Notification.requestPermission();
        if (permission === 'granted' && messaging) {
          log('Notification permission granted.');
          // Get the FCM token
          const token = await getToken(messaging);
          log('FCM Token: ' + token);

          registerUserDevice(token);
          return token;
        } else {
          log('Notification permission denied.');
        }
      } catch (err) {
        //@ts-ignore
        console.error('Error setting up notifications: ' + err);
      }
    };

    if (userId && firebaseConfig.apiKey) {
      setupPushNotifications();
    }
  }, [registerUserDevice, userId]);
};

export const usePushInformationActions = () => {
  const { t } = useTranslation();
  const { addNotification } = useNotifications();
  const currentProviderId = useSelector(appSelectors.providerId);
  const { setSelectedProvider } = useApp();
  const { updateUserSettingInStorage } = useStorage();

  const navigate = useNavigate();

  useEffect(() => {
    if (messaging == null) {
      return;
    }

    const unsubscribe = onMessage(messaging, payload => {
      const { providerId, type, orderId, dialogName, userId } =
        payload.data as { [key: string]: string };

      /***
       * Notice: we cannot relay on the fact that push notifications works in browser
       * so we will use it only for chat messages from different providers
       * this will not replace ws information fully
       **/
      if (
        type === 'chat_message' &&
        providerId != currentProviderId &&
        dialogName &&
        userId != CHAT_BOT_MESSAGE_SENDER_ID
      ) {
        addNotification({
          providerId,
          id: `message-for-order-${orderId}`,
          severity: NotificationSeverity.Info,
          title: t('chat.notification.newMessageTitle'),
          onClick: closeNotification => {
            closeNotification();

            setSelectedProvider(providerId);
            updateUserSettingInStorage({ currentProviderId: providerId });
            navigate(orderChatUrl(orderId));
          },
          message: (
            <Trans i18nKey="chat.notification.newMessageText">
              You have a new message for an order {{ orderName: dialogName }}
            </Trans>
          ),
        });
      }
    });

    return () => unsubscribe?.();
  }, [
    addNotification,
    t,
    currentProviderId,
    setSelectedProvider,
    updateUserSettingInStorage,
    navigate,
  ]);
};
