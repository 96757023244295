import { COMMUNICATIONS_API_PATH, ORDERS_API_PATH } from './common';

export const providerEndpoint = (providerId: string) =>
  `${ORDERS_API_PATH}provider/${providerId}/`;

export const currentUserEndpoint = (userId?: string) =>
  `${ORDERS_API_PATH}user${userId ? `/${userId}` : ''}`;
export const currentUserInfoEndpoint = () => `${currentUserEndpoint()}/me`;
export const userSelfEndpoint = (providerId: string) =>
  `${providerEndpoint(providerId)}user/me`;
export const userEndpoint = (providerId: string, userId?: string) =>
  `${providerEndpoint(providerId)}user/${userId ? userId : ''}`;
export const forgotPasswordEndpoint = () =>
  `${ORDERS_API_PATH}user/forgot_password`;

export const registerUserDeviceEndpoint = () =>
  `${currentUserEndpoint()}/device`;

export const userAuthEndpoint = () => `${currentUserEndpoint()}/auth`;

export const ticketEndpoint = (
  providerId: string,
  orderId?: number,
  ticketId?: number,
) =>
  `${ORDERS_API_PATH}provider/${providerId}/order/${orderId}/ticket/${ticketId}`;

export const assignedTicketEndpoint = (providerId: string, userId?: string) =>
  `${ORDERS_API_PATH}provider/${providerId}/user/${userId}/ticket`;

export const ticketsSearchEndpoint = (providerId: string) =>
  `${ORDERS_API_PATH}provider/${providerId}/ticket`;

export const getAttachmentEndpoint = (
  providerId: string,
  attachmentId?: string,
) =>
  `${COMMUNICATIONS_API_PATH}provider/${providerId}/attachment/${
    attachmentId ? `${attachmentId}/` : ''
  }`;

export const getAttachmentsEndpoint = (providerId: string) =>
  `${COMMUNICATIONS_API_PATH}provider/${providerId}/attachments/`;

export const getChatEndpoint = (providerId: string, orderId?: number) =>
  `${providerEndpoint(providerId)}dialog/${orderId ? `${orderId}/` : ''}`;

export const getMarkDialogAsReadByContractorEndpoint = (
  providerId: string,
  orderId?: number,
) => `${getChatEndpoint(providerId, orderId)}read`;

export const getMarkDialogAsReadByDispatcherEndpoint = (
  providerId: string,
  orderId: number,
) => `${getChatEndpoint(providerId, orderId)}mark-as-read`;

export const getChatMessagesEndpoint = (providerId: string, orderId: number) =>
  `${getChatEndpoint(providerId, orderId)}message/`;

export const providerSettingsEndpoint = (providerId: string) =>
  `${providerEndpoint(providerId)}setting`;

export const providerAfterHoursSettingsEndpoint = (providerId: string) =>
  `${providerEndpoint(providerId)}setting/after-hours`;

export const trucksEndpoint = (providerId: string) =>
  `${providerEndpoint(providerId)}truck`;

export const truckLocationHistoryEndpoint = (providerId: string) =>
  `${providerEndpoint(providerId)}tracking/search`;

export const generateOrderNameEndpoint = (
  providerId: string,
  projectId: string,
) => `${providerEndpoint(providerId)}project/${projectId}/generate`;

export const projectSubscriptions = (providerId: string, projectId: string) =>
  `${providerEndpoint(providerId)}project/${projectId}/subscription`;

export const orderSubscriptions = (providerId: string, orderId: number) =>
  `${providerEndpoint(providerId)}order/${orderId}/subscription`;

export const userTokenEndpoint = () => `${ORDERS_API_PATH}user/token_auth`;

export const usersListEndpoint = (providerId: string) =>
  `${providerEndpoint(providerId)}user/search`;

export const deleteUserEndpoint = (providerId: string, userId?: string) =>
  `${providerEndpoint(providerId)}user/${userId}/delete`;

export const resendUserInviteEndpoint = (providerId: string, userId?: string) =>
  `${providerEndpoint(providerId)}user/${userId}/resend_invitation`;
export const orderStatusEndpoint = (providerId: string, id: string | number) =>
  `${ORDERS_API_PATH}provider/${providerId}/order/${id}/status`;
export const orderCallbackEndpoint = (providerId: string, id: number) =>
  `${providerEndpoint(providerId)}order/${id}/callback-form`;
export const userSubscriptionEndpoint = (providerId: string) =>
  `${ORDERS_API_PATH}provider/${providerId}/order/subscription`;

export const dialogsEndpoint = (providerId: string, companyId: string | null) =>
  companyId
    ? `${ORDERS_API_PATH}provider/${providerId}/dialog/company/${companyId}`
    : `${ORDERS_API_PATH}provider/${providerId}/dialog`;
export const orderSubscriptionEndpoint = (
  providerId: string,
  orderId: string | number,
) => `${ORDERS_API_PATH}provider/${providerId}/order/${orderId}/subscription`;
export const orderEndpoint = (providerId: string, id?: number) =>
  `${providerEndpoint(providerId)}order${id ? '/' + id : ''}`;
export const orderPlantsEndpoint = (providerId: string, id: number) =>
  `${providerEndpoint(providerId)}order/${id}/plant`;

export const getHelpCenterEndpoint = (providerId: string) =>
  `${providerEndpoint(providerId)}help-center/`;

export const getHelpCenterRequestEndpoint = (providerId: string) =>
  `${getHelpCenterEndpoint(providerId)}request`;

export const OrdersApiSpec = () => `${ORDERS_API_PATH}docs`;

export const nearbyLocationsTypeaheadEndpoint = (providerId: string) =>
  `${providerEndpoint(providerId)}map/nearby_locations`;

export const mapPlacesTypeaheadEndpoint = (providerId: string) =>
  `${providerEndpoint(providerId)}map/address_query`;

export const addressLocationEndpoint = (providerId: string) =>
  `${providerEndpoint(providerId)}map/address_location`;

export const locationAddressEndpoint = (providerId: string) =>
  `${providerEndpoint(providerId)}map/location_address`;

export const timezonesEndpoint = (providerId: string) =>
  `${providerEndpoint(providerId)}setting/timezone`;

export const attachmentEndpoint = (providerId: string, attachmentId: string) =>
  `${COMMUNICATIONS_API_PATH}provider/${providerId}/attachment/${attachmentId}`;

export const updateOrderPositionEndpoint = (
  providerId: string,
  orderId: number,
) => `${providerEndpoint(providerId)}order/search/${orderId}/position`;
