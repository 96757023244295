import { updateOrderPositionEndpoint } from '@cd3p/core/constants/endpoints';
import {
  APIFullTextSearchFilter,
  APIOrder,
  APIOrderPositionElement,
  APIOrderStatus,
  APIOrdersList,
  APIOrdersListParams,
  APISearchSortOrder,
  APISortOrder,
} from '@cd3p/core/types/api';
import {
  DEFAULT_PAGE_SIZE,
  getPageNumberAfterLoadMore,
  getPageSizeDependingOnItems,
} from '@cd3p/core/utils/lists';
import { OrderFields, getOrderSortingWithDate } from '@cd3p/core/utils/order';
import { createOrdersRefList } from '@cd3p/core/utils/ordersList';
import { httpMethod } from '@cd3p/core/utils/sra';

import { _ } from 'third-party';

import { orderSearchEndpoint } from 'constants/endpoints';

import { State } from 'modules/index';

import { composeLoadOrdersRequestBody } from 'utils/ordersList';

import { TypeaheadOption } from 'types/app';

export type OrdersListColumnId = keyof APIOrder;

export type OrdersFilters = {
  onlySubscribedOrders: boolean;
  date: {
    dateFrom: Date | undefined;
    dateTo: Date | undefined;
  };
  volume: {
    ['0-100']: boolean;
    ['100-500']: boolean;
    ['500-1000']: boolean;
    ['1000-']: boolean;
  };
  orderIds: TypeaheadOption[];
  company: TypeaheadOption[];
  project: TypeaheadOption[];
  status: {
    [APIOrderStatus.Unconfirmed]: boolean;
    [APIOrderStatus.Cancelled]: boolean;
    [APIOrderStatus.Confirmed]: boolean;
    [APIOrderStatus.Delivering]: boolean;
    [APIOrderStatus.Completed]: boolean;
    [APIOrderStatus.Requested]: boolean;
    [APIOrderStatus.Declined]: boolean;
  };
};

export interface OrdersListState {
  items: ({ id: number } & Partial<APIOrder>)[];
  pagination: {
    pageNumber: APIOrdersList['pageNumber'];
    pageSize: APIOrdersList['pageSize'];
  };
  sorting: APISearchSortOrder<OrdersListColumnId>[];
  search: APIFullTextSearchFilter;
  count: number;
  ordersLoaded: boolean;
}

export const ordersDefaultFilters = {
  onlySubscribedOrders: false,
  date: {
    dateFrom: undefined,
    dateTo: undefined,
  },
  volume: {
    ['0-100']: false,
    ['100-500']: false,
    ['500-1000']: false,
    ['1000-']: false,
  },
  company: [],
  orderIds: [],
  project: [],
  status: {
    [APIOrderStatus.Unconfirmed]: true,
    [APIOrderStatus.Cancelled]: false,
    [APIOrderStatus.Confirmed]: true,
    [APIOrderStatus.Delivering]: true,
    [APIOrderStatus.Completed]: false,
    [APIOrderStatus.Requested]: true,
    [APIOrderStatus.Declined]: false,
  },
};

export const ordersDefaultListState: OrdersListState = {
  items: [],
  pagination: { pageNumber: 1, pageSize: DEFAULT_PAGE_SIZE },
  sorting: getOrderSortingWithDate({
    sortField: OrderFields.OrderStatus,
    sortOrder: APISortOrder.ASC,
  }),
  search: {
    pattern: '',
    columns: [],
  },
  count: 0,
  ordersLoaded: false,
};

export const loadOrders =
  <State extends OrdersListState, Payload extends APIOrdersList>(
    actionName: string,
  ) =>
  (providerId: string, body: APIOrdersListParams) => ({
    name: actionName,
    url: orderSearchEndpoint(providerId),
    body,
    method: httpMethod.post,
    onSuccess: (state: State, payload: Payload) => {
      return {
        items: createOrdersRefList(payload.result, ['id', 'highlights']),
        ordersLoaded: true,
        pagination: {
          pageNumber: payload.pageNumber,
          pageSize: payload.pageSize,
        },
        count: payload.count,
      };
    },
  });

export const loadMoreOrders =
  <Payload extends APIOrdersList>(actionName: string) =>
  (
    providerId: string,
    getModuleState: (state: State) => OrdersListState,
    getFilters: (state: State) => OrdersFilters,
  ) => ({
    name: actionName,
    url: orderSearchEndpoint(providerId),
    body: (state: State) =>
      composeLoadOrdersRequestBody(providerId, {
        pageNumber: getPageNumberAfterLoadMore(
          getModuleState(state).items,
          getModuleState(state).pagination.pageSize,
        ),
        pageSize: getModuleState(state).pagination.pageSize,
        sorting: getModuleState(state).sorting,
        filters: getFilters(state),
        search: getModuleState(state).search,
      }),
    method: httpMethod.post,
    onSuccess: (state: OrdersListState, payload: Payload) => {
      return {
        items: _.uniq([
          ...state.items,
          ...createOrdersRefList(payload.result, ['id', 'highlights']),
        ]),
      };
    },
  });

export const updateOrderPosition =
  <
    ModuleState extends OrdersListState,
    Payload extends APIOrderPositionElement,
  >(
    actionName: string,
  ) =>
  (
    providerId: string,
    getModuleState: (state: State) => ModuleState,
    getFilters: (state: State) => OrdersFilters,
    orderId: number,
  ) => ({
    name: actionName,
    url: updateOrderPositionEndpoint(providerId, orderId),
    body: (state: State) =>
      composeLoadOrdersRequestBody(providerId, {
        pageNumber: 1,
        pageSize: getPageSizeDependingOnItems(getModuleState(state).items),
        sorting: getModuleState(state).sorting,
        filters: getFilters(state),
        search: getModuleState(state).search,
      }),
    method: httpMethod.post,
    onSuccess: (state: ModuleState, payload: Payload) => {
      if (payload.position) {
        const [changed, rest] = _.partition(
          state.items,
          it => it.id === orderId,
        );
        const newItems =
          payload.position === -1
            ? rest
            : [
                ...rest.slice(0, payload.position - 1),
                ...(changed?.length
                  ? changed
                  : [{ id: orderId, highlights: null }]),
                ...rest.slice(payload.position - 1),
              ];
        return {
          ...state,
          items: newItems,
        };
      } else {
        return state;
      }
    },
  });

export const resetOrderList = (actionName: string) => () => ({
  name: actionName,
  updater: () => ({
    items: [],
    count: 0,
    ordersLoaded: false,
  }),
});

export const updateOrdersListSorting =
  (actionName: string) => (newSorting: OrdersListState['sorting']) => ({
    name: actionName,
    updater: () => ({
      sorting: getOrderSortingWithDate(newSorting),
    }),
  });

export const updateOrdersListFilters =
  <State extends { filters: OrdersFilters }>(actionName: string) =>
  (newFilters: OrdersFilters) => ({
    name: actionName,
    updater: (state: State) => ({
      filters: {
        ...state.filters,
        ...newFilters,
      },
    }),
  });

export const updateOrdersListSearch =
  <State extends OrdersListState>(actionName: string) =>
  (pattern: OrdersListState['search']['pattern']) => ({
    name: actionName,
    updater: (state: State) => ({
      search: {
        ...state.search,
        pattern,
      },
    }),
  });

export const resetOrdersListSearch = (actionName: string) => () => ({
  name: actionName,
  updater: () => ({
    search: ordersDefaultListState.search,
  }),
});
