import React, { useState } from 'react';

import styled, { useTheme } from 'styled-components';

import { APIAssignedDriver } from '@cd3p/core/types/api';
import { formatDisplayedDate } from '@cd3p/core/utils/common';
import {
  ConfirmationDialog,
  Dialog,
  Grid,
  LoadingButton,
  MUITable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from 'components';

import { useTranslation } from 'third-party';

import { formatDateToAMPMTime } from 'utils/order';
import { getUserFullName } from 'utils/user';

const StyledTableContainer = styled(TableContainer)`
  flex-grow: 1;
  min-height: 0;
  height: inherit;
  max-height: inherit;
`;

const StyledTableHeadRow = styled(TableRow)`
  border-bottom-color: ${props => props.theme.custom.palette.muted200};
  border-bottom-width: 1px;
  border-bottom-style: solid;
  box-shadow: 0 2px 3px -2px rgba(0, 0, 0, 0.25);
`;

const TableCellHeader = styled(TableCell)`
  border-bottom: 1px solid ${props => props.theme.custom.palette.truegray300};
  background: white;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
`;

const TableCellBody = styled(TableCell)`
  font-size: 1.6rem;
  position: relative;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 25%;
  border-bottom: 1px solid ${props => props.theme.custom.palette.truegray300};
  padding: 0.8rem 1.6rem;
  &:not(:last-child):after {
    position: absolute;
    top: 1rem;
    right: 0;
    bottom: 0;
    border-right: 1px solid ${props => props.theme.custom.palette.truegray300};
    width: 1px;
    content: '';
  }
  &:first-child {
    width: 35%;
  }
  &:last-child {
    width: 10%;
  }
`;

const StyledDeleteButton = styled(LoadingButton)`
  font-size: 1.4rem;
  text-transform: uppercase;
  font-weight: 900;
  text-decoration: underline;
  color: ${props => props.theme.custom.palette.secondary500};
  &:hover {
    background: transparent;
  }
`;

const StyledSendNotificationsButton = styled(LoadingButton)`
  height: 4rem;
  width: 17rem;
`;

const DialogControls = styled(Grid)`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
`;

const StyledTableRow = styled(TableRow)<{ hovercolor: string }>`
  &:hover {
    ${TableCellBody} {
      background-color: ${props => props.hovercolor};
    }
  }
`;

interface AssignedDriversTableProps {
  items: APIAssignedDriver[];
  deleteItem?: (id: number) => void;
  isReadOnly?: boolean;
  deletingDriverId?: number | null;
  onSendNotifications?: () => void;
  notifyPending?: boolean;
}

export const AssignedDriversTable: React.FC<AssignedDriversTableProps> = ({
  items,
  deleteItem,
  isReadOnly,
  deletingDriverId,
  onSendNotifications,
  notifyPending,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [selectedItemId, setSelectedItemId] = useState<number | null>(null);
  const [showNotificationsConfirmation, setSendNotificationsConfirmation] =
    useState(false);

  const showDeleteConfirmation = (itemId: number) => {
    setSelectedItemId(itemId);
  };

  const onItemDelete = () => {
    if (selectedItemId && deleteItem) {
      deleteItem(selectedItemId);
      setSelectedItemId(null);
    }
  };

  const handleNotificationsClick = () => {
    if (onSendNotifications) {
      onSendNotifications();
      setSendNotificationsConfirmation(false);
    }
  };

  return (
    <>
      <Dialog open={!!selectedItemId} onClose={() => setSelectedItemId(null)}>
        <Typography variant="h4" marginBottom="1rem">
          {t('order.details.confirmed.confirmationDeleteTitle')}
        </Typography>
        <Typography width="25rem" color={theme.custom.palette.gray}>
          {t('order.details.confirmed.assignedDriverDeleteDescription')}
        </Typography>
        <DialogControls>
          <LoadingButton
            variant="outlined"
            color="secondary"
            onClick={() => setSelectedItemId(null)}
          >
            {t('common.noNevermind')}
          </LoadingButton>
          <LoadingButton variant="contained" onClick={() => onItemDelete()}>
            {t('order.details.confirmed.confirmationDialogDeleteAction')}
          </LoadingButton>
        </DialogControls>
      </Dialog>

      <ConfirmationDialog
        onClose={() => setSelectedItemId(null)}
        open={!!selectedItemId}
        handleActionClick={() => onItemDelete()}
        description={t(
          'order.details.confirmed.assignedDriverDeleteDescription',
        )}
      />

      <ConfirmationDialog
        onClose={() => setSendNotificationsConfirmation(false)}
        open={showNotificationsConfirmation}
        handleActionClick={handleNotificationsClick}
        actionButtonTitle={t(
          'order.details.confirmed.assignedDriversNotificationActionButton',
        )}
        description={t(
          'order.details.confirmed.assignedDriversNotificationDescription',
        )}
      />

      <StyledTableContainer>
        <MUITable stickyHeader aria-label="sticky table">
          <TableHead>
            <StyledTableHeadRow>
              <TableCellHeader colSpan={2}>
                <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                  {isReadOnly && (
                    <Typography
                      variant="h3"
                      color={theme.custom.palette.primary900}
                      style={{ marginRight: '1rem' }}
                    >
                      {t('order.details.driverAssignmentsButton')}
                    </Typography>
                  )}
                </div>
              </TableCellHeader>

              <TableCellHeader
                align={isReadOnly ? 'center' : 'right'}
                colSpan={isReadOnly ? 1 : 2}
              >
                {!isReadOnly && (
                  <StyledSendNotificationsButton
                    size="medium"
                    color="primary"
                    variant="contained"
                    loading={notifyPending}
                    onClick={() => setSendNotificationsConfirmation(true)}
                  >
                    {t('order.details.confirmed.sendNotifications')}
                  </StyledSendNotificationsButton>
                )}
              </TableCellHeader>
            </StyledTableHeadRow>
          </TableHead>
          <TableBody>
            {items.map((item, idx) => {
              const isDeleting = deletingDriverId === item?.id;

              return (
                <StyledTableRow
                  hover
                  key={`${idx}-${item.driver?.id}`}
                  hovercolor={theme.custom.palette.primary50}
                >
                  <TableCellBody>
                    {getUserFullName(
                      item.driver,
                      t('common.entityNameUndefined'),
                    )}
                  </TableCellBody>
                  <TableCellBody>{item.plant.name}</TableCellBody>
                  <TableCellBody>
                    {formatDisplayedDate(item.assignDate)} -{' '}
                    {formatDateToAMPMTime(item.assignDate)}
                  </TableCellBody>
                  {!isReadOnly && (
                    <TableCellBody>
                      <StyledDeleteButton
                        disableRipple
                        disabled={isDeleting || notifyPending}
                        onClick={() => showDeleteConfirmation(item.id)}
                      >
                        {isDeleting
                          ? t(
                              'order.details.confirmed.deleteAssignedDriverLoading',
                            )
                          : t('order.details.confirmed.deleteAssignedDriver')}
                      </StyledDeleteButton>
                    </TableCellBody>
                  )}
                </StyledTableRow>
              );
            })}
          </TableBody>
        </MUITable>
      </StyledTableContainer>
    </>
  );
};
