import React, { CSSProperties, useCallback, useEffect, useMemo } from 'react';

import { useAfterHoursNoticeFactory } from '@cd3p/core/hooks/useAfterHoursNoticeFactory';
import { APIAfterHoursResponse } from '@cd3p/core/types/api';
import { NoticePanel } from 'components';

import { useHandleApiResult } from './useHandleApiResult';

import { useSelector, useTranslation } from 'third-party';

import { useApp } from 'modules/app';

import { appSelectors } from 'selectors';

type AfterHoursNoticeProps = {
  enabled?: boolean;
  style?: CSSProperties;
};

export const useAfterHoursNotice = ({
  enabled,
  style,
}: AfterHoursNoticeProps) => {
  const { t } = useTranslation();
  const { fetchProviderAfterHoursSettings } = useApp();
  const handleApiResult = useHandleApiResult();
  const providerId = useSelector(appSelectors.providerId);

  const renderNoticeElement = useCallback(
    (message: string) => {
      return (
        <NoticePanel
          label={t('order.details.afterHoursNotice')}
          tooltipMessage={message}
          style={style}
        />
      );
    },
    [style, t],
  );

  const {
    afterHoursNotice,
    showAfterHoursNotice,
    setAfterHoursEndsInMs,
    setAfterHoursMessage,
  } = useAfterHoursNoticeFactory({ renderNoticeElement, enabled });

  useEffect(() => {
    handleApiResult<APIAfterHoursResponse>(
      () => fetchProviderAfterHoursSettings(providerId),
      ({ result }) => {
        setAfterHoursMessage(result.payload.message || '');
        setAfterHoursEndsInMs(result.payload.startAfter || 0);
      },
    );
  }, [
    fetchProviderAfterHoursSettings,
    handleApiResult,
    providerId,
    setAfterHoursEndsInMs,
    setAfterHoursMessage,
  ]);

  return useMemo(
    () => ({
      showAfterHoursNotice,
      afterHoursNotice,
    }),
    [showAfterHoursNotice, afterHoursNotice],
  );
};
