import React, { useCallback, useMemo, useState } from 'react';

import styled from 'styled-components';

import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import {
  DAYS_OF_WEEK,
  convert24toAmPm,
  convertAmPmto24,
} from '@cd3p/core/utils/common';
import { timeField } from '@cd3p/core/utils/fields';
import { IconButton, Typography } from '@mui/material';
import {
  Button,
  ConfirmationDialog,
  FormField,
  MultiSelectDropdown,
  Stack,
  TimePicker,
} from 'components';
import { useHandleApiResult } from 'hooks/useHandleApiResult';
import { FieldError } from 'react-hook-form';

import {
  FormProvider,
  _,
  dayjs,
  useFieldArray,
  useForm,
  useSelector,
  useTranslation,
} from 'third-party';

import { useApp } from 'modules/app';

import { appSelectors } from 'selectors';

import { IOptions } from 'types/app';

const Header = styled(Typography)`
  font-weight: 900;
  text-transform: uppercase;
  margin-bottom: 0.8rem;
  color: ${props => props.theme.custom.palette.secondary900};
`;

const ContentContainer = styled(Stack)`
  flex: 1;
  margin-top: 3rem;
  padding-bottom: 1rem;
`;

const AddNoticeButton = styled(Button)`
  text-indent: 1rem;
  align-self: flex-start;
  margin-top: 1rem;
  padding-left: 0rem;
`;

const NoticeWrapper = styled(Stack)``;

const NoticeHeader = styled(Stack)`
  margin-top: 2rem;
  justify-content: space-between;
`;

const NoticeTitle = styled(Typography)`
  font-weight: 900;
  text-transform: uppercase;
  font-size: 1.6rem;
  color: ${props => props.theme.custom.palette.secondary700};
  margin-bottom: 1.4rem;
`;

const DeleteButton = styled(IconButton)`
  align-self: center;
`;

const isEndTimeAfterStartTime = (startTime: string, endTime: string) =>
  dayjs(endTime, 'HH:mm A').isAfter(dayjs(startTime, 'HH:mm A'));

const prepareAfterHoursNoticeFormToApiJson = (
  notices: Array<AfterHoursNoticeFormT>,
) => {
  return JSON.stringify(
    notices.map((item): AfterHoursNoticeAPI => {
      return {
        BusinessDays: item[AfterHoursNoticeFormFields.BusinessDays],
        StartTime: convertAmPmto24(
          item[AfterHoursNoticeFormFields.BusinessHoursStart],
        ),
        EndTime: convertAmPmto24(
          item[AfterHoursNoticeFormFields.BusinessHoursEnd],
        ),
        Message: item[AfterHoursNoticeFormFields.Message],
      };
    }),
  );
};

export const parseAfterHoursNoticeFromJsonToFormData = (
  // string should include stringifed array with objects from provider settings: Array<AfterHoursNoticeAPI>
  notices?: string | null,
): AfterHoursNoticeFormT[] => {
  if (notices) {
    const parsedNotices: AfterHoursNoticeFormT[] | null = JSON.parse(notices);
    // format time from api 24hours to am/pm on UI to match app styles
    const formatedNotices = parsedNotices?.map(notice => {
      return {
        ...notice,
        [AfterHoursNoticeFormFields.BusinessHoursStart]: convert24toAmPm(
          notice?.[AfterHoursNoticeFormFields.BusinessHoursStart],
          { withLeadingZeros: true },
        ),
        [AfterHoursNoticeFormFields.BusinessHoursEnd]: convert24toAmPm(
          notice?.[AfterHoursNoticeFormFields.BusinessHoursEnd],
          { withLeadingZeros: true },
        ),
      };
    });
    return formatedNotices || [];
  }
  return [];
};

export type AfterHoursNoticeAPI = {
  BusinessDays: number[];
  StartTime: string;
  EndTime: string;
  Message: string;
};

const daysOfWeekOptions =
  DAYS_OF_WEEK?.map(
    (day, index): IOptions => ({
      key: index,
      value: day,
    }),
  ) || [];

export const AFTER_HOURS_NOTICE_FORM_KEY = 'AfterHours';

enum AfterHoursNoticeFormFields {
  BusinessDays = 'BusinessDays',
  BusinessHoursStart = 'StartTime',
  BusinessHoursEnd = 'EndTime',
  Message = 'Message',
}

export type AfterHoursNoticeFormT = {
  [AfterHoursNoticeFormFields.BusinessDays]: number[];
  [AfterHoursNoticeFormFields.BusinessHoursStart]: string;
  [AfterHoursNoticeFormFields.BusinessHoursEnd]: string;
  [AfterHoursNoticeFormFields.Message]: string;
};

type AfterHoursNoticesFormT = {
  [AFTER_HOURS_NOTICE_FORM_KEY]: Array<AfterHoursNoticeFormT>;
};

type AfterHoursNoticeProps = {
  contractorId: string;
  defaultData: AfterHoursNoticeFormT[];
};

const formDefaultValues = {
  [AfterHoursNoticeFormFields.BusinessDays]: [],
  [AfterHoursNoticeFormFields.BusinessHoursStart]: '',
  [AfterHoursNoticeFormFields.BusinessHoursEnd]: '',
  [AfterHoursNoticeFormFields.Message]: '',
};

const checkIfNoticeFormIsEmpty = (noticeFormData?: AfterHoursNoticeFormT) => {
  if (!noticeFormData) {
    return false;
  }

  return _.some(_.values(noticeFormData), _.isEmpty);
};

export const AfterHoursNoticeForm: React.FC<AfterHoursNoticeProps> = ({
  contractorId,
  defaultData,
}) => {
  const { t } = useTranslation();
  const { updateProviderSettings } = useApp();
  const updateProviderSettingsPending = useSelector(
    appSelectors.updateProviderSettingsPending,
  );

  const handleApiResult = useHandleApiResult();

  const [selectedNoticeIndex, setSelectedNoticeIndex] = useState<number | null>(
    null,
  );
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const formMethods = useForm<AfterHoursNoticesFormT>({
    mode: 'onChange',
    defaultValues: { AfterHours: [...defaultData] },
  });

  const {
    control,
    trigger,
    watch,
    register,
    getValues,
    formState: { dirtyFields, touchedFields, errors, isValid },
  } = formMethods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: AFTER_HOURS_NOTICE_FORM_KEY,
  });

  const watchedNoticesFieldsArray = watch(AFTER_HOURS_NOTICE_FORM_KEY);

  const allSelectedDaysOfWeek = watchedNoticesFieldsArray.reduce(
    (acc: number[], nextVal: AfterHoursNoticeFormT) => {
      const selectedDays =
        nextVal[AfterHoursNoticeFormFields.BusinessDays] || [];
      return [...acc, ...selectedDays];
    },
    [],
  );

  const showAddAfterHoursNoticeButton =
    allSelectedDaysOfWeek.length < DAYS_OF_WEEK.length;
  const isAddAfterHoursNoticeDisabled = useMemo(() => {
    return (
      !isValid ||
      // check if the first added notice is empty. For some reasons if the form is empty, the first appended notice doesn't seems to invalid.
      checkIfNoticeFormIsEmpty(watchedNoticesFieldsArray[0])
    );
  }, [isValid, watchedNoticesFieldsArray]);

  const getAllValidNotices = useCallback(() => {
    return getValues(AFTER_HOURS_NOTICE_FORM_KEY).filter(
      (notice, idx) =>
        !errors?.[AFTER_HOURS_NOTICE_FORM_KEY]?.[idx] &&
        !checkIfNoticeFormIsEmpty(notice),
    );
  }, [errors, getValues]);

  const autoSaveField = useCallback(
    async (index: number) => {
      // checking if edited notice form is valid
      const isPartialFormEditingValid = async () => {
        return await trigger([
          `${AFTER_HOURS_NOTICE_FORM_KEY}.${index}.${AfterHoursNoticeFormFields.BusinessDays}`,
          `${AFTER_HOURS_NOTICE_FORM_KEY}.${index}.${AfterHoursNoticeFormFields.BusinessHoursStart}`,
          `${AFTER_HOURS_NOTICE_FORM_KEY}.${index}.${AfterHoursNoticeFormFields.BusinessHoursEnd}`,
          `${AFTER_HOURS_NOTICE_FORM_KEY}.${index}.${AfterHoursNoticeFormFields.Message}`,
        ]);
      };
      if (await isPartialFormEditingValid()) {
        const updatedValidNotices = getAllValidNotices();
        handleApiResult(() =>
          updateProviderSettings(contractorId, [
            {
              key: AFTER_HOURS_NOTICE_FORM_KEY,
              value: prepareAfterHoursNoticeFormToApiJson(updatedValidNotices),
            },
          ]),
        );
      }
    },
    [
      contractorId,
      getAllValidNotices,
      handleApiResult,
      trigger,
      updateProviderSettings,
    ],
  );

  const onAddAfterHoursNotice = useCallback(() => {
    append(formDefaultValues);
  }, [append]);

  const onDeleteAfterHoursNotice = useCallback(
    (index: number) => () => {
      setSelectedNoticeIndex(index);
      setShowDeleteConfirmation(true);
    },
    [],
  );

  const onConfirmDelete = useCallback(async () => {
    if (selectedNoticeIndex !== null) {
      handleApiResult(
        () => {
          return updateProviderSettings(contractorId, [
            {
              key: AFTER_HOURS_NOTICE_FORM_KEY,
              value: prepareAfterHoursNoticeFormToApiJson(
                getAllValidNotices().filter(
                  (it, index) => index !== selectedNoticeIndex,
                ),
              ),
            },
          ]);
        },
        ({ showBaseToast }) => {
          remove(selectedNoticeIndex);
          showBaseToast(
            t('plantSettings.afterHoursNotice.form.removeNoticeToast'),
          );
        },
      ).then(() => {
        setSelectedNoticeIndex(null);
        setShowDeleteConfirmation(false);
      });
    }
  }, [
    contractorId,
    getAllValidNotices,
    handleApiResult,
    remove,
    selectedNoticeIndex,
    t,
    updateProviderSettings,
  ]);

  return (
    <FormProvider {...formMethods}>
      <ConfirmationDialog
        onClose={() => {
          setShowDeleteConfirmation(false);
        }}
        handleActionClick={onConfirmDelete}
        open={showDeleteConfirmation}
        actionPending={updateProviderSettingsPending}
        description={t(
          'plantSettings.afterHoursNotice.removeNoticeConfirmation',
        )}
      />
      <form>
        {fields.map((field, index) => {
          const registeredBusinessDayInput = register(
            `${AFTER_HOURS_NOTICE_FORM_KEY}.${index}.${AfterHoursNoticeFormFields.BusinessDays}`,
          );
          const registeredBusinessHoursStartInput = register(
            `${AFTER_HOURS_NOTICE_FORM_KEY}.${index}.${AfterHoursNoticeFormFields.BusinessHoursStart}`,
          );
          const registeredBusinessHoursEndInput = register(
            `${AFTER_HOURS_NOTICE_FORM_KEY}.${index}.${AfterHoursNoticeFormFields.BusinessHoursEnd}`,
          );
          const registeredMessageInput = register(
            `${AFTER_HOURS_NOTICE_FORM_KEY}.${index}.${AfterHoursNoticeFormFields.Message}`,
          );

          const previouslySelectedDays = watchedNoticesFieldsArray.reduce(
            (
              acc: number[],
              nextVal: AfterHoursNoticeFormT,
              currentIndex: number,
            ) => {
              if (currentIndex !== index) {
                const selectedDays =
                  nextVal[AfterHoursNoticeFormFields.BusinessDays] || [];
                return [...acc, ...selectedDays];
              }
              return acc;
            },
            [],
          );

          const availableDaysOfWeekOptions = daysOfWeekOptions.filter(
            option => {
              return !previouslySelectedDays.includes(Number(option.key));
            },
          );

          const defaultSelectedDays = daysOfWeekOptions.reduce(
            (acc: number[], nextVal: IOptions): number[] => {
              if (
                watchedNoticesFieldsArray[index][
                  AfterHoursNoticeFormFields.BusinessDays
                ].includes(Number(nextVal.key))
              ) {
                return [...acc, Number(nextVal.key)];
              }
              return acc;
            },
            [],
          );

          const watchedStartHours =
            watchedNoticesFieldsArray[index][
              AfterHoursNoticeFormFields.BusinessHoursStart
            ] || '';
          const watchedEndHours =
            watchedNoticesFieldsArray[index][
              AfterHoursNoticeFormFields.BusinessHoursEnd
            ] || '';

          return (
            <NoticeWrapper key={field.id}>
              <NoticeHeader direction={'row'}>
                <NoticeTitle>
                  {t('plantSettings.afterHoursNotice.formTitle', {
                    index: index + 1,
                  })}
                </NoticeTitle>
                <DeleteButton onClick={onDeleteAfterHoursNotice(index)}>
                  <DeleteOutlineIcon color="primary" />
                </DeleteButton>
              </NoticeHeader>
              <MultiSelectDropdown
                key={registeredBusinessDayInput.name}
                fieldName={registeredBusinessDayInput.name}
                caption={t(
                  `plantSettings.afterHoursNotice.form.businessDaysLabel`,
                )}
                options={availableDaysOfWeekOptions}
                control={control}
                onClose={() => autoSaveField(index)}
                showError={
                  !!dirtyFields[AFTER_HOURS_NOTICE_FORM_KEY]?.[index]?.[
                    AfterHoursNoticeFormFields.BusinessDays
                  ]
                }
                fieldError={
                  errors[AFTER_HOURS_NOTICE_FORM_KEY]?.[index]?.[
                    AfterHoursNoticeFormFields.BusinessDays
                  ] as FieldError
                }
                isRequired
                placeholder={t(
                  'plantSettings.afterHoursNotice.form.businessDaysPlaceholder',
                )}
                requiredErrorMessage={t('common.form.emptyFieldError')}
                defaultSelected={defaultSelectedDays}
              />
              <FormField
                key={registeredBusinessHoursStartInput.name}
                fieldName={registeredBusinessHoursStartInput.name}
                fieldError={
                  errors[AFTER_HOURS_NOTICE_FORM_KEY]?.[index]?.[
                    AfterHoursNoticeFormFields.BusinessHoursStart
                  ]
                }
                isRequired
                label={t(
                  'plantSettings.afterHoursNotice.form.businessHoursStartLabel',
                )}
                requiredErrorMessage={t('common.form.emptyFieldError')}
                control={control}
                showError={
                  !!dirtyFields[AFTER_HOURS_NOTICE_FORM_KEY]?.[index]?.[
                    AfterHoursNoticeFormFields.BusinessHoursStart
                  ]
                }
                rules={{
                  validate: {
                    incorrectTime: (value: string) =>
                      timeField.validate(value) ||
                      t('common.form.time.error.invalid'),
                    isPastTime: (value: any) =>
                      isEndTimeAfterStartTime(value, watchedEndHours) ||
                      t('plantSettings.afterHoursNotice.startTimePastEndTime'),
                  },
                  deps: [registeredBusinessHoursEndInput.name],
                }}
                render={({ field }) => (
                  <TimePicker
                    value={field.value}
                    placeholder={t(
                      'plantSettings.afterHoursNotice.form.businessHoursStartPlaceholder',
                    )}
                    onChange={(value: string) => {
                      field.onChange(value);
                      field.onBlur();
                    }}
                    onBlur={event => {
                      autoSaveField(index);
                      registeredBusinessHoursStartInput.onBlur(event);
                    }}
                    error={
                      !!dirtyFields[AFTER_HOURS_NOTICE_FORM_KEY]?.[index]?.[
                        AfterHoursNoticeFormFields.BusinessHoursStart
                      ] &&
                      !!errors[AFTER_HOURS_NOTICE_FORM_KEY]?.[index]?.[
                        AfterHoursNoticeFormFields.BusinessHoursStart
                      ]
                    }
                  />
                )}
              />
              <FormField
                key={registeredBusinessHoursEndInput.name}
                fieldName={registeredBusinessHoursEndInput.name}
                fieldError={
                  errors[AFTER_HOURS_NOTICE_FORM_KEY]?.[index]?.[
                    AfterHoursNoticeFormFields.BusinessHoursEnd
                  ]
                }
                isRequired
                label={t(
                  'plantSettings.afterHoursNotice.form.businessHoursEndLabel',
                )}
                requiredErrorMessage={t('common.form.emptyFieldError')}
                control={control}
                showError={
                  !!dirtyFields[AFTER_HOURS_NOTICE_FORM_KEY]?.[index]?.[
                    AfterHoursNoticeFormFields.BusinessHoursEnd
                  ]
                }
                rules={{
                  validate: {
                    incorrectTime: (value: string) =>
                      timeField.validate(value) ||
                      t('common.form.time.error.invalid'),
                    isPastTime: (value: any) =>
                      isEndTimeAfterStartTime(watchedStartHours, value) ||
                      t(
                        'plantSettings.afterHoursNotice.endTimeBeforeStartTime',
                      ),
                  },
                  deps: [registeredBusinessHoursStartInput.name],
                }}
                render={({ field }) => (
                  <TimePicker
                    value={field.value}
                    onBlur={() => autoSaveField(index)}
                    onChange={(value: string) => {
                      field.onChange(value);
                      field.onBlur();
                    }}
                    placeholder={t(
                      'plantSettings.afterHoursNotice.form.businessHoursEndPlaceholder',
                    )}
                    error={
                      !!dirtyFields[AFTER_HOURS_NOTICE_FORM_KEY]?.[index]?.[
                        AfterHoursNoticeFormFields.BusinessHoursEnd
                      ] &&
                      !!errors[AFTER_HOURS_NOTICE_FORM_KEY]?.[index]?.[
                        AfterHoursNoticeFormFields.BusinessHoursEnd
                      ]
                    }
                  />
                )}
              />
              <FormField
                key={registeredMessageInput.name}
                fieldName={registeredMessageInput.name}
                label={t('plantSettings.afterHoursNotice.form.messageLabel')}
                placeholder={t(
                  'plantSettings.afterHoursNotice.form.messagePlaceholder',
                )}
                isRequired
                control={control}
                maxLength={300}
                onBlur={() => autoSaveField(index)}
                requiredErrorMessage={t('common.form.emptyFieldError')}
                fieldError={
                  errors[AFTER_HOURS_NOTICE_FORM_KEY]?.[index]?.[
                    AfterHoursNoticeFormFields.Message
                  ] as unknown as FieldError
                }
                showError={
                  (!!touchedFields[AFTER_HOURS_NOTICE_FORM_KEY]?.[index]?.[
                    AfterHoursNoticeFormFields.Message
                  ] ||
                    !!dirtyFields[AFTER_HOURS_NOTICE_FORM_KEY]?.[index]?.[
                      AfterHoursNoticeFormFields.Message
                    ]) &&
                  !!errors[AFTER_HOURS_NOTICE_FORM_KEY]?.[index]?.[
                    AfterHoursNoticeFormFields.Message
                  ]
                }
                multiline
              />
            </NoticeWrapper>
          );
        })}
      </form>
      {showAddAfterHoursNoticeButton && (
        <AddNoticeButton
          disabled={isAddAfterHoursNoticeDisabled}
          onClick={onAddAfterHoursNotice}
        >
          <AddBoxOutlinedIcon />
          {t('plantSettings.afterHoursNotice.addButton')}
        </AddNoticeButton>
      )}
    </FormProvider>
  );
};

export const AfterHoursNotice: React.FC<AfterHoursNoticeProps> = ({
  contractorId,
  defaultData,
}) => {
  const { t } = useTranslation();

  return (
    <ContentContainer>
      <Header>{t('plantSettings.afterHoursNotice.title')}</Header>
      <Typography>{t('plantSettings.afterHoursNotice.description')}</Typography>
      <AfterHoursNoticeForm
        contractorId={contractorId}
        defaultData={defaultData}
      />
    </ContentContainer>
  );
};
