import * as Sentry from '@sentry/react';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import isYesterday from 'dayjs/plugin/isYesterday';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import i18n from 'i18n';

import { dayjs } from 'third-party';

export { i18n };
export { RouterLink } from 'components/RouterLink/RouterLink';

export {
  default as styled,
  ThemeProvider,
  createGlobalStyle,
  css,
  keyframes,
  useTheme,
} from 'styled-components';
export { default as cssResetStyles } from 'styled-reset';
export { default as axios } from 'axios';
export { default as classNames } from 'classnames';
export { useSetState, useEffectOnce, useUpdateEffect } from 'react-use';
export { useBottomScrollListener } from 'react-bottom-scroll-listener';
export {
  Route,
  Routes,
  useParams,
  Outlet as RouterOutlet,
  useLocation,
  useNavigation,
  Navigate,
  useMatch,
  useResolvedPath,
  matchPath,
  createRoutesFromChildren,
  matchRoutes,
  useNavigationType,
  resolvePath,
  useSearchParams,
} from 'react-router-dom';

export {
  simplifyBuilder,
  middlewareBuilder,
  httpMethod,
} from '@cd3p/core/utils/sra';
export {
  combineReducers,
  createStore,
  applyMiddleware,
  compose,
  bindActionCreators,
} from 'redux';
export { useSelector, useDispatch } from 'react-redux';
export { createSelector } from 'reselect';
export { v4 as uuid } from 'uuid';
export { useTranslation } from 'react-i18next';
export { Trans } from 'components';
export {
  useForm,
  useFieldArray,
  useFormContext,
  Controller,
  FormProvider,
} from 'react-hook-form';
export type {
  FieldError,
  Control,
  UseFormSetValue,
  UseFieldArrayProps,
  FieldPath,
} from 'react-hook-form';

export { default as _ } from 'lodash';
export { useDropzone } from 'react-dropzone';
export { default as prettyBytes } from 'pretty-bytes';
export { useCallbackRef } from 'use-callback-ref';

export { useBeforeUnload, useInterval } from 'react-use';

export {
  default as Select,
  components as reactSelectComponents,
} from 'react-select';
export { default as AsyncSelect } from 'react-select/async';
export { default as AsyncCreatableSelect } from 'react-select/async-creatable';
export { CreatableProps } from 'react-select/creatable';
export { AsyncPaginate, wrapMenuList } from 'react-select-async-paginate';
export { withAsyncPaginate } from 'react-select-async-paginate';
export {
  AutoSizer as VirtualizedAutoSizer,
  InfiniteLoader as VirtualizedInfiniteLoader,
  List as VirtualizedList,
  CellMeasurerCache as VirtualizedCellMeasurerCache,
  CellMeasurer as VirtualizedCellMeasurer,
} from 'react-virtualized';

export type { ListRowProps } from 'react-virtualized';

export {
  default as ContentLoader,
  Facebook as FacebookLoader,
} from 'react-content-loader';

export { IMaskInput, IMask } from 'react-imask';
export { stripHtml } from 'string-strip-html';

export { default as queryString } from 'query-string';

export { default as copy } from 'copy-to-clipboard';

export { default as dayjs } from 'dayjs';

dayjs.extend(isTomorrow);
dayjs.extend(isYesterday);
dayjs.extend(isToday);
dayjs.extend(isSameOrBefore);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

export { useNavigate } from 'hooks/useNavigate';

export { Sentry };
export { BrowserTracing } from '@sentry/tracing';
export { Helmet } from 'react-helmet';
export { usePostHog, PostHogProvider } from 'posthog-js/react';
export {
  ISubscriptions,
  signalrFactory,
  closeAllSingnalrConnections,
} from '@simplify-apps/react-sockets';
