import { projectSubscriptions } from '../constants/endpoints';
import { httpMethod } from '../utils/sra';

export type CoreProjectState = {
  addProjectSubscriptionsPending: boolean;
};

export const coreProjectDefaultState: CoreProjectState = {
  addProjectSubscriptionsPending: false,
};

const addProjectSubscriptions = (
  providerId: string,
  projectId: string,
  subscribedUsersIds: string[],
) => ({
  name: 'addProjectSubscriptions',
  url: projectSubscriptions(providerId, projectId),
  body: {
    subscribedUsersIds,
  },
  method: httpMethod.put,
});

export const coreProjectMethods = {
  addProjectSubscriptions,
};
