import { dayjs } from '@cd3p/core/third-party';
import { TFunction } from 'i18next';

import { _, useSelector } from 'third-party';

import { appSelectors } from 'selectors';

export const useAllowCreateProjectsWithoutMixTypes = () => {
  const isProjectCreationByContractorEnabled = useSelector(
    appSelectors.isProjectCreationByContractorEnabled,
  );
  // https://cd-3po.atlassian.net/browse/CD3P-3039
  // plant users with access to Provider with feature flag set as False
  // shall be able to create Project for Contractor
  // who does not have Mix added yet
  return !isProjectCreationByContractorEnabled;
};
export const getPrettifiedDate = (
  date: string | Date | number,
  t: TFunction,
) => {
  const parsedDate = dayjs(date);
  let yearString = '';
  const timeString = ` ${parsedDate.format('hh:mm a')}`;

  if (parsedDate.isToday()) {
    return `${t('common.today')}${timeString}`;
  } else if (parsedDate.isYesterday()) {
    return `${t('common.yesterday')}${timeString}`;
  }

  if (dayjs().year() !== parsedDate.year()) {
    yearString = ', ' + parsedDate.year();
  }

  return `${parsedDate.format('MMM D')}${yearString} ${timeString}`;
};

export const isTitleString = (title: any) => {
  return _.isString(title) || title?.type?.name === 'Trans';
};
