import { State } from 'modules';

export const additiveTypesListItems = (state: State) =>
  state.additiveTypesList.items;
export const additiveTypesListCount = (state: State) =>
  state.additiveTypesList.count;
export const additiveTypesLoaded = (state: State) =>
  state.additiveTypesList.additiveTypesLoaded;
export const additiveTypesListSorting = (state: State) =>
  state.additiveTypesList.sorting;
export const additiveTypesListFilters = (state: State) =>
  state.additiveTypesList.filters;
export const loadAdditiveTypesPending = (state: State) =>
  state.additiveTypesList.loadAdditiveTypesPending;
export const addAdditiveTypeToMasterListPending = (state: State) =>
  state.additiveTypesList.addAdditiveTypeToMasterListPending;
export const loadMoreAdditiveTypesPending = (state: State) =>
  state.additiveTypesList.loadMoreAdditiveTypesPending;
export const createAdditiveTypePending = (state: State) =>
  state.additiveTypesList.createAdditiveTypePending;
export const updateAdditiveTypePending = (state: State) =>
  state.additiveTypesList.updateAdditiveTypePending;
export const additiveTypeAssignment = (state: State) =>
  state.additiveTypesList.additiveTypeAssignment;
export const additiveTypeAssignmentCount = (state: State) =>
  state.additiveTypesList.additiveTypeAssignment?.length || 0;
export const additiveTypeAssignmentId = (state: State) =>
  state.additiveTypesList.additiveTypeAssignmentId;
export const loadAdditiveTypeAssignmentPending = (state: State) =>
  state.additiveTypesList.loadAdditiveTypeAssignmentPending;
export const fetchAdditiveTypePending = (state: State) =>
  state.additiveTypesList.fetchAdditiveTypePending;
export const loadMostUsedAdditiveTypesPending = (state: State) =>
  state.additiveTypesList.loadMostUsedAdditiveTypesPending;
export const mostUsedAdditiveTypesItems = (state: State) =>
  state.additiveTypesList.mostUsedAdditiveTypes?.usages;
export const mostUsedAdditiveTypesPercentage = (state: State) =>
  state.additiveTypesList.mostUsedAdditiveTypes?.percentage;
export const mostUsedAdditiveTypesLoaded = (state: State) =>
  state.additiveTypesList.mostUsedAdditiveTypes !== null;
export const leastUsedAdditiveTypesItems = (state: State) =>
  state.additiveTypesList.leastUsedAdditiveTypes.items;
export const leastUsedAdditiveTypesCount = (state: State) =>
  state.additiveTypesList.leastUsedAdditiveTypes.count;
export const loadLeastUsedAdditiveTypesPending = (state: State) =>
  state.additiveTypesList.loadLeastUsedAdditiveTypesPending;
export const loadMoreLeastUsedAdditiveTypesPending = (state: State) =>
  state.additiveTypesList.loadMoreLeastUsedAdditiveTypesPending;
export const leastUsedAdditiveTypesLoaded = (state: State) =>
  state.additiveTypesList.leastUsedAdditiveTypes.additiveTypesLoaded;

export const projectAdditiveTypesItems = (state: State) =>
  state.additiveTypesList.projectsAdditiveTypesItems;
export const projectAdditiveTypesCount = (state: State) =>
  state.additiveTypesList.projectsAdditiveTypesCount;
export const loadProjectAdditiveTypesPending = (state: State) =>
  state.additiveTypesList.loadProjectsAdditiveTypesPending;
export const loadMoreProjectAdditiveTypesPending = (state: State) =>
  state.additiveTypesList.loadMoreProjectsAdditiveTypesPending;
export const projectAdditiveTypesLoaded = (state: State) =>
  state.additiveTypesList.projectsAdditiveTypesLoaded;
export const projectsAdditiveTypesSearch = (state: State) =>
  state.additiveTypesList.projectsAdditiveTypesSearch;
