import { useCallback, useEffect } from 'react';

import { NotificationSeverity } from '@cd3p/core/modules/notifications';
import { APIUser, APIUserStatus, APIUserType } from '@cd3p/core/types/api';

import {
  Sentry,
  useLocation,
  useNavigate,
  usePostHog,
  useSelector,
  useTranslation,
} from 'third-party';

import { onboardingUrl } from 'constants/url';

import { useApp } from 'modules/app';
import { useNotifications } from 'modules/notifications';
import { useStorage } from 'modules/storage';

import { appSelectors } from 'selectors';

import { isUserAuthenticated, saveUserToken, useLogOutUser } from 'utils/auth';
import { isIncompleteOnboardedUserLoggedIn } from 'utils/user';

export const useHandleUserAuthorization = () => {
  const { setIsAppLoaded, loadProviderSettings } = useApp();
  const { addNotification } = useNotifications();
  const { t } = useTranslation();
  const { getUserInfo } = useApp();
  const { initiateUserSettings } = useStorage();
  const isUserLogged = isUserAuthenticated();
  const navigate = useNavigate();
  const location = useLocation();

  const logOutUser = useLogOutUser();
  const posthog = usePostHog();

  const providerId = useSelector(appSelectors.providerId);
  const companyId = useSelector(appSelectors.companyId);

  const user = useSelector(appSelectors.user);

  const logOutDeactivatedUser = useCallback(() => {
    addNotification({
      severity: NotificationSeverity.Error,
      message: t('common.loginInactiveError'),
    });
    logOutUser();
  }, [addNotification, t, logOutUser]);

  useEffect(() => {
    if (isUserLogged && !user?.id) {
      getUserInfo().then((result: any) => {
        const user = result.payload as APIUser;
        Sentry.setUser({
          id: user.id,
          email: user.email,
        });

        if (
          !result.error &&
          result.payload.userStatus === APIUserStatus.Deactivated
        ) {
          logOutDeactivatedUser();
        }
        initiateUserSettings(user);
        saveUserToken(result.payload?.token);
        if (isIncompleteOnboardedUserLoggedIn(result.payload as APIUser)) {
          navigate(onboardingUrl());
        }
      });
    } else {
      setIsAppLoaded(true);
    }
  }, [
    getUserInfo,
    isUserLogged,
    location.pathname,
    navigate,
    logOutDeactivatedUser,
    user,
    setIsAppLoaded,
    posthog,
    initiateUserSettings,
  ]);

  useEffect(() => {
    if (providerId) {
      loadProviderSettings(providerId);

      const provider = user.providers?.find(p => p.id === providerId);
      const company = user.companies?.find(c => c.id === companyId);

      posthog?.identify(user.id, {
        email: user.email,
        name: `${user.firstName} ${user.lastName}`,
        userType: user.userType,
        providerName: provider?.providerName,
        providerId: provider?.id,
        ...(user.userType === APIUserType.Contractor && {
          companyName: company?.name,
          companyId: company?.id,
        }),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadProviderSettings, posthog, providerId, user.id]);
};
