import React from 'react';

import styled from 'styled-components';

import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';

import { Button } from 'components';

const StyledButton = styled(Button)`
  text-indent: 1rem;
`;

type Props = {
  label: string;
  onClick: () => void;
  className?: string;
  disabled?: boolean;
};

export const AddButton: React.FC<Props> = ({
  label,
  onClick,
  className,
  disabled,
}) => {
  return (
    <StyledButton onClick={onClick} className={className} disabled={disabled}>
      <AddBoxOutlinedIcon />
      {label}
    </StyledButton>
  );
};
