import styled from 'styled-components';

import { Tabs as MUITabs } from '@mui/material';

export const Tabs = styled(MUITabs)`
  display: flex;
  min-height: 4rem;
  .MuiTabs-indicator {
    background-color: ${props => props.theme.custom.palette.info900};
    height: 0.4rem;
  }
`;

export const TabsBottomLine = styled.div`
  flex-grow: 1;
  border-bottom: 1px solid ${props => props.theme.custom.palette.muted100};
`;
