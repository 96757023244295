import React, { useEffect, useMemo, useState } from 'react';

import { MenuEmptyText, MenuItem } from 'components';

export function useMenuOptions<T extends { id: string }>({
  items,
  selectedItemId,
  displayKey,
  getDisplayValue,
  emptyText,
  refreshInterval,
}: {
  items: T[];
  selectedItemId: string;
  displayKey?: keyof T;
  getDisplayValue?: (item: T) => React.ReactNode;
  emptyText?: string;
  refreshInterval?: number;
}) {
  const [count, setCount] = useState(0);
  useEffect(() => {
    if (!refreshInterval) return;

    const interval = setInterval(
      () => setCount(state => state + 1),
      refreshInterval,
    );
    return () => clearInterval(interval);
  }, [refreshInterval]);

  const menuOptions = useMemo(() => {
    return items.length ? (
      items.map((option: T) => (
        <MenuItem
          key={option.id}
          value={option.id}
          hidden={option.id === selectedItemId}
        >
          {displayKey
            ? (option[displayKey] as string) || ''
            : getDisplayValue?.(option)}
        </MenuItem>
      ))
    ) : (
      <MenuEmptyText>{emptyText}</MenuEmptyText>
    );
    // In some cases, we need to update menuOptions regularly, e.g. when we need to update online status icon
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, selectedItemId, displayKey, emptyText, getDisplayValue, count]);

  return menuOptions;
}
