import React, { useCallback, useEffect, useRef, useState } from 'react';

import styled from 'styled-components';

import CloseIcon from '@mui/icons-material/Close';

import { APIUserType } from '@cd3p/core/types/api';
import {
  CanAccess,
  DispatcherCanAccess,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Typography,
} from 'components';
import { subscribe } from 'hooks/usePubSub';
import { usePrevious } from 'react-use';

import { AllDialogsTab } from './AllDialogsTab';
import { SubscribedDialogsTab } from './SubscribedDialogsTab';

import { VirtualizedList, useSelector, useTranslation } from 'third-party';

import { APP_EVENTS } from 'constants/appEvent';

import { useDialogsList } from 'modules/dialogsList';
import { useStorage } from 'modules/storage';

import { appSelectors, dialogsListSelectors } from 'selectors';

import {
  LEFT_OVERLAY_PANEL_WIDTH,
  LeftOverlayPanelContainer,
} from 'components/LeftOverlayPanelContainer/LeftOverlayPanelContainer';

import { OutlinedSearchInput } from 'styles/common';
import { TabTitle, TabWrapper, TabsWrapper } from 'styles/theme';

const InnerContent = styled(Stack)`
  flex-grow: 1;
  min-width: ${LEFT_OVERLAY_PANEL_WIDTH};
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
`;

const Header = styled(Stack)`
  height: 5rem;
  align-items: center;
  padding: 0 1rem 0 2rem;
  border-bottom: 1px solid ${props => props.theme.custom.palette.muted100};
`;

const Title = styled(Typography)`
  color: ${props => props.theme.custom.palette.primary900};
`;

const SearchPanel = styled(Stack)`
  border-bottom: 1px solid ${props => props.theme.custom.palette.muted50};
  background: ${props => props.theme.custom.palette.backgroundTheme};
  justify-content: flex-end;
  padding: 0.8rem;
`;

const StyledOutlinedSearchInput = styled(OutlinedSearchInput)`
  width: 100%;
`;

const UnreadCount = styled(Typography)`
  font-size: 1.2rem;
  font-weight: 600;
  border-radius: 10rem;
  color: white;
  display: flex;
  padding: 0 0.6rem;
  justify-content: center;
  align-items: center;
  margin-left: 0.8rem;
  line-height: 150%;
  background: ${props => props.theme.custom.palette.danger600};
`;

const CloseButton = styled(IconButton)`
  margin-left: auto;
`;

const StyledTab = styled(Tab)`
  flex: 1;
`;

const StyledTabTitle = styled(TabTitle)`
  padding-right: 0;
`;

export const DIALOG_ITEM_HEIGHT = 85;
export const CONTRACTOR_DIALOG_ITEM_HEIGHT = 65;

enum DIALOG_TABS {
  SUBSCRIBED,
  ALL,
}

export const DialogsPanelContent = () => {
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState<DIALOG_TABS>(
    DIALOG_TABS.SUBSCRIBED,
  );

  const { updateUserSettingInStorage } = useStorage();

  const { loadDialogs, setSearchKeyword, setDialogsFilter } = useDialogsList();

  const providerId = useSelector(appSelectors.providerId);
  const companyId = useSelector(appSelectors.companyId);
  const isDialogsPanelOpen = useSelector(appSelectors.isDialogsPanelOpen);

  const dialogsSearchKeyword = useSelector(dialogsListSelectors.searchKeyword);
  const dialogsUnreadCount = useSelector(
    dialogsListSelectors.dialogsUnreadCount,
  );

  const listRef = useRef<VirtualizedList | null>(null);

  const onSearchChange = useCallback(
    (value: string | null) => {
      setSearchKeyword(value);
      if (!value) {
        setDialogsFilter([]);
      }
      loadDialogs(companyId);
      listRef.current?.scrollToRow(0);
    },
    [setSearchKeyword, loadDialogs, companyId, setDialogsFilter],
  );

  const onTabChange = useCallback(
    (value: DIALOG_TABS) => () => {
      setActiveTab(value);
    },
    [],
  );

  useEffect(() => {
    return subscribe(APP_EVENTS.CHANGE_PROVIDER, () => {
      setSearchKeyword(null);
    });
  }, [setSearchKeyword]);

  useEffect(() => {
    return () => {
      if (!isDialogsPanelOpen && dialogsSearchKeyword) {
        onSearchChange(null);
      }
    };
  }, [onSearchChange, isDialogsPanelOpen, dialogsSearchKeyword]);

  return (
    <InnerContent>
      <Header direction="row">
        <Title variant="h5">{t('dialogsPanel.title')}</Title>
        <DispatcherCanAccess>
          {!!dialogsUnreadCount && (
            <UnreadCount>{dialogsUnreadCount}</UnreadCount>
          )}
        </DispatcherCanAccess>
        <CloseButton
          onClick={() => {
            updateUserSettingInStorage({ openedLeftPanel: null });
          }}
        >
          <CloseIcon />
        </CloseButton>
      </Header>
      <SearchPanel direction="row">
        <StyledOutlinedSearchInput
          // reset search when changing provider
          key={providerId}
          isClearable
          onChange={onSearchChange}
          placeholder={t('dialogsPanel.searchPlaceholder')}
        />
      </SearchPanel>
      <CanAccess allowedUserType={APIUserType.Contractor}>
        <TabsWrapper>
          <Tabs value={activeTab}>
            <StyledTab
              label={
                <TabWrapper>
                  <StyledTabTitle>
                    {t('dialogsPanel.myOrdersTab')}
                  </StyledTabTitle>
                </TabWrapper>
              }
              onClick={onTabChange(DIALOG_TABS.SUBSCRIBED)}
            />
            <StyledTab
              label={
                <TabWrapper>
                  <StyledTabTitle>{t('dialogsPanel.allTab')}</StyledTabTitle>
                </TabWrapper>
              }
              onClick={onTabChange(DIALOG_TABS.ALL)}
            />
          </Tabs>
          {activeTab === DIALOG_TABS.SUBSCRIBED && (
            <SubscribedDialogsTab ref={listRef} />
          )}
          {activeTab === DIALOG_TABS.ALL && <AllDialogsTab />}
        </TabsWrapper>
      </CanAccess>
      <DispatcherCanAccess>
        <AllDialogsTab />
      </DispatcherCanAccess>
    </InnerContent>
  );
};

export const DialogsPanel = () => {
  const isDialogsPanelOpen = useSelector(appSelectors.isDialogsPanelOpen);
  const appOpenedLeftPanel = useSelector(appSelectors.appOpenedLeftPanel);
  const appLeftPanelAnimationDuration = useSelector(
    appSelectors.appLeftPanelAnimationDuration,
  );

  const previousAppOpenedLeftPanel = usePrevious(appOpenedLeftPanel);

  return (
    <LeftOverlayPanelContainer
      isPanelOpen={isDialogsPanelOpen}
      animationDuration={
        !!previousAppOpenedLeftPanel && !!appOpenedLeftPanel
          ? 0
          : appLeftPanelAnimationDuration
      }
    >
      <DialogsPanelContent />
    </LeftOverlayPanelContainer>
  );
};
