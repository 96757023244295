import React, { useEffect, useMemo, useState } from 'react';

import styled, { useTheme } from 'styled-components';

import {
  APIOrder,
  APISortOrder,
  APITicket,
  APITicketStatuses,
  APITrackingItem,
} from '@cd3p/core/types/api';
import {
  Button,
  CircularProgress,
  Divider,
  FormField,
  LoadingButton,
  MenuItem,
  ReadOnlyField,
  TruckNumberTag,
  Typography,
} from 'components';

import { useForm, useSelector, useTranslation } from 'third-party';

import {
  createFullUrl,
  orderAddTicketUrl,
  orderTicketsUrl,
} from 'constants/url';

import { useOrder } from 'modules/order';
import { useTodayOrdersList } from 'modules/todayOrdersList';

import {
  appSelectors,
  orderSelectors,
  todayOrdersListSelectors,
} from 'selectors';

import { getUserFullName } from 'utils/user';

const StyledAssignToTicketPopover = styled.div`
  width: 42rem;
  min-height: 33rem;
  padding: 2rem;
  box-sizing: border-box;
`;

const StyledDivider = styled(Divider)`
  border-bottom-color: ${props => props.theme.custom.palette.muted200};
  border-bottom-width: 1px;
  border-bottom-style: solid;
`;

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem;
`;

const ORDER_ID = 'orderId';

interface Props {
  selectedTruck: APITrackingItem | null;
  onClose: (shouldRefetchData?: boolean) => void;
}

export const AssignToTicketPopover: React.FC<Props> = ({
  selectedTruck,
  onClose,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const todayOrdersListItems = useSelector(
    todayOrdersListSelectors.todayOrdersListItems,
  );
  const loadOrderTicketsPending = useSelector(
    orderSelectors.loadOrderTicketsPending,
  );
  const loadMoreTodayOrdersPending = useSelector(
    todayOrdersListSelectors.loadMoreTodayOrdersPending,
  );
  const todayOrdersListCount = useSelector(
    todayOrdersListSelectors.todayOrdersListCount,
  );
  const userType = useSelector(appSelectors.userType);

  const updateTicketPending = useSelector(orderSelectors.updateTicketPending);

  const { loadOrderTickets, resetOrderTickets } = useOrder();
  const { loadMoreTodayOrders, loadOrdersForAssignDriver } =
    useTodayOrdersList();

  const [ordersToSelect, setOrdersToSelect] = useState<APIOrder[] | null>(null);

  const [availableTicket, setAvailableTicket] = useState<
    APITicket | null | undefined
  >(undefined);

  const formDefaultValues = {
    [ORDER_ID]: '',
  };

  const { control, watch, reset } = useForm({
    defaultValues: formDefaultValues,
  });

  useEffect(() => {
    return () => {
      reset();
      if (userType) {
        resetOrderTickets(userType);
      }
    };
  }, [userType, reset, resetOrderTickets]);

  const watchedSelectedOrderId = String(watch(ORDER_ID));

  useEffect(() => {
    const getAvailableTickets = async () => {
      if (watchedSelectedOrderId) {
        const selectedOrderDraftTickets = await loadOrderTickets(
          watchedSelectedOrderId,
          {
            pageNumber: 1,
            pageSize: 10,
            ticketStatuses: [APITicketStatuses.Draft],
            hasAssignedDriver: false,
            searchSortOrders: [
              { sortField: 'createdDate', sortOrder: APISortOrder.ASC },
            ],
          },
        );
        if (!selectedOrderDraftTickets.error) {
          const firstAvailableTicket =
            selectedOrderDraftTickets.payload.result?.[0];
          setAvailableTicket(firstAvailableTicket);
        }
      } else {
        setAvailableTicket(null);
      }
    };

    getAvailableTickets();
  }, [watchedSelectedOrderId, loadOrderTickets]);

  useEffect(() => {
    const asyncFunc = async () => {
      const request = await loadOrdersForAssignDriver();
      if (!request.error && request.payload?.result) {
        setOrdersToSelect(request.payload.result);
      } else {
        setOrdersToSelect([]);
      }
    };
    asyncFunc();
  }, [loadOrdersForAssignDriver]);

  const ordersOptions = useMemo(() => {
    return ordersToSelect
      ? ordersToSelect.map(order => (
          <MenuItem
            key={order.id}
            value={order.id}
            hidden={String(order.id) === watchedSelectedOrderId}
          >
            {order.orderName}
          </MenuItem>
        ))
      : [];
  }, [ordersToSelect, watchedSelectedOrderId]);

  const navigateToTicket = () => {
    const urlToRedirect = availableTicket
      ? createFullUrl(
          orderTicketsUrl(
            watchedSelectedOrderId,
            availableTicket?.id,
            selectedTruck?.truckId,
            selectedTruck?.driverId,
          ),
        )
      : createFullUrl(
          orderAddTicketUrl(
            watchedSelectedOrderId,
            selectedTruck?.truckId,
            selectedTruck?.driverId,
          ),
        );
    onClose();
    window.open(urlToRedirect, '_blank');
  };

  const selectedDriverName = getUserFullName(
    {
      firstName: selectedTruck?.driverFirstName || '',
      lastName: selectedTruck?.driverLastName || '',
    },
    t('common.entityNameUndefined'),
  );

  const isFormDisabled =
    !ordersOptions.length || updateTicketPending || loadOrderTicketsPending;

  const isOrdersToSelectLoaded = ordersToSelect !== null;

  return (
    <StyledAssignToTicketPopover>
      <Typography variant="h3" color={theme.custom.palette.primary900}>
        {t('todayOrders.availableTrucks.assignToTicketTitle')}
      </Typography>
      <StyledDivider style={{ margin: '1rem 0 1.4rem' }} />
      <ReadOnlyField
        value={selectedDriverName}
        label={t('todayOrders.availableTrucks.driverFieldLabel')}
      />
      <ReadOnlyField
        value={<TruckNumberTag value={selectedTruck?.truckNumber} />}
        label={t('order.ticket.form.truckLabel')}
      />
      <StyledDivider />
      <FormField
        isRequired
        size="small"
        label={t('todayOrders.availableTrucks.orderFieldName')}
        fieldName={ORDER_ID}
        requiredErrorMessage={t('common.form.emptyFieldError')}
        control={control}
        placeholder={
          isOrdersToSelectLoaded
            ? t('todayOrders.availableTrucks.orderFieldNamePlaceholder')
            : t('common.loading')
        }
        isDisabled={isFormDisabled || !isOrdersToSelectLoaded}
        selectOptions={ordersOptions}
        showLoadMore={todayOrdersListCount > todayOrdersListItems.length}
        loadMorePending={loadMoreTodayOrdersPending}
        handleLoadMoreClick={loadMoreTodayOrders}
      />
      {loadOrderTicketsPending && (
        <LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>
      )}
      {watchedSelectedOrderId && !loadOrderTicketsPending && (
        <div style={{ marginTop: '2rem' }}>
          <StyledDivider style={{ marginBottom: '1.4rem' }} />

          {availableTicket ? (
            <ReadOnlyField
              value={
                availableTicket.ticketNumber || t('common.entityNameUndefined')
              }
              label={t('todayOrders.availableTrucks.nextTicketTitle')}
            />
          ) : (
            <Typography
              variant="h5"
              style={{
                marginBottom: '1.4rem',
                color: theme.custom.palette.muted400,
              }}
            >
              {t('todayOrders.availableTrucks.noAvailableTicket')}
            </Typography>
          )}

          <div>
            <Button
              variant="outlined"
              color="secondary"
              style={{ marginRight: '2rem' }}
              onClick={() => onClose()}
            >
              {t('common.cancel')}
            </Button>
            {availableTicket ? (
              <LoadingButton
                variant="contained"
                onClick={navigateToTicket}
                disabled={isFormDisabled}
                loading={updateTicketPending}
              >
                {t('todayOrders.availableTrucks.addDriverAction')}
              </LoadingButton>
            ) : (
              <LoadingButton variant="contained" onClick={navigateToTicket}>
                {t('todayOrders.availableTrucks.createNewTicketAction')}
              </LoadingButton>
            )}
          </div>
        </div>
      )}
    </StyledAssignToTicketPopover>
  );
};
