import { createTransform } from 'redux-persist';

import { _ } from 'third-party';

import { StorageState } from 'modules/storage';

export const storeTransform = createTransform(
  // transform state being rehydrated
  (outboundState, key) => {
    if (key === 'storage') {
      const result = { ...(outboundState as StorageState) };

      // deserialize dates from string to Date object
      Object.keys(result.userSettings).forEach(userId => {
        const dateFrom =
          result.userSettings[userId].allOrdersTableSettings.filters?.date
            ?.dateFrom;

        _.set(
          result,
          `userSettings.${userId}.allOrdersTableSettings.filters.date.dateFrom`,
          dateFrom ? new Date(dateFrom) : undefined,
        );

        const dateTo =
          result.userSettings[userId].allOrdersTableSettings.filters?.date
            ?.dateTo;

        _.set(
          result,
          `userSettings.${userId}.allOrdersTableSettings.filters.date.dateTo`,
          dateTo ? new Date(dateTo) : undefined,
        );
      });

      return result;
    } else {
      return outboundState;
    }
  },
);
