import { APIUser, APIUserStatus } from '@cd3p/core/types/api';
import i18n from 'i18n';

export const getUserFullName = (
  user?: APIUser | { firstName: string; lastName: string },
  fallback?: string,
) =>
  user && (user.firstName || user.lastName)
    ? [user.firstName, user.lastName].join(' ')
    : fallback || i18n.t('common.entityNameUndefined');

export const getUserShortName = (
  firstName: string | null,
  lastName: string | null,
  fallback?: string | null,
): string => {
  return (
    [firstName || false, lastName ? lastName.slice(0, 1) + '.' : false]
      .filter(Boolean)
      .join(' ') ||
    fallback ||
    i18n.t('data.unknown')
  );
};

export const isIncompleteOnboardedUserLoggedIn = (user: APIUser | undefined) =>
  user?.id &&
  [
    APIUserStatus.Incomplete,
    APIUserStatus.NotActive,
    APIUserStatus.Deactivated,
  ].includes(user?.userStatus as APIUserStatus);
