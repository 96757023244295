import { useCallback } from 'react';

import { APIUserStatus } from '@cd3p/core/types/api';

import { resetGlobalCache } from './globalCache';

import { closeAllSingnalrConnections } from 'third-party';

import { ONBOARDING_STEPS, loginUrl, onboardingStepUrl } from 'constants/url';

import { useApp } from 'modules/app';

export const getUserToken = () => {
  return localStorage.getItem('token') ?? '';
};

export const saveUserToken = (token: string) => {
  localStorage.setItem('token', token);
};

export const deleteUserToken = () => {
  localStorage.removeItem('token');
};

export const isUserAuthenticated = () => {
  const token = getUserToken();

  return !isTokenExpired(token);
};

export const decodeToken = (userToken: string) => {
  try {
    const token = userToken.replace('Bearer ', '').trim();
    if (token.split('.').length !== 3 || typeof token !== 'string') {
      return null;
    }
    const payload: string = token.split('.')[1];
    const padding: string = '='.repeat((4 - (payload.length % 4)) % 4);

    const base64: string =
      payload.replace('-', '+').replace('_', '/') + padding;

    const decoded = JSON.parse(atob(base64));

    return decoded;
  } catch (error) {
    return null;
  }
};

export const isTokenExpired = (token: string) => {
  const decodedToken: any = decodeToken(token);

  if (decodedToken && decodedToken.exp) {
    const expirationDate: Date = new Date(0);
    expirationDate.setUTCSeconds(decodedToken.exp);
    return expirationDate < new Date();
  }

  return true;
};

export const getUserRedirectLink = (userStatus: APIUserStatus) => {
  // APIUserStatus.Active -> user already created, password set, info verified - regular routing should work, not custom redirects from App
  if (!userStatus || userStatus === APIUserStatus.Active) return null;

  if (userStatus === APIUserStatus.NotActive) {
    // user exists, token is valid, password is not yet set, go to step 1 - setting a password
    return onboardingStepUrl(ONBOARDING_STEPS.Step1);
  } else if (userStatus === APIUserStatus.Incomplete) {
    // password was already set, go to step 2 - info verification
    return onboardingStepUrl(ONBOARDING_STEPS.Step2);
  } else if (userStatus === APIUserStatus.Deactivated) {
    return loginUrl();
  }
};

export const useLogOutUser = () => {
  const { registerUserDevice } = useApp();

  return useCallback(
    async ({ redirect } = { redirect: true }) => {
      // close all ws connections
      await closeAllSingnalrConnections();

      //Remove user device id to stop push notifications
      await registerUserDevice('');

      deleteUserToken();
      resetGlobalCache();
      if (redirect) {
        // we need to hard reset the page upon logout
        // to clear the store from previous user data
        // and to re-initialize it again from local storage
        location.pathname = loginUrl();
      }
    },
    [registerUserDevice],
  );
};
