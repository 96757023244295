import React from 'react';

import styled from 'styled-components';

import { Stack, SvgIcon, Typography } from 'components';

const Wrapper = styled(Stack)`
  padding: 0.8rem;
  border: 1px solid ${props => props.theme.custom.palette.success800};
  border-radius: 0.2rem;
  box-sizing: border-box;
  transition: background-color 0.5s;
  flex-direction: row;
  width: fit-content;
`;

const ContentColumn = styled(Stack)`
  flex-direction: column;
`;

const Title = styled(Typography)`
  font-weight: 600;
  font-size: 1.6rem;
  text-transform: uppercase;
  margin-top: 0.2rem;
`;
const Description = styled(Typography)`
  font-weight: 400;
  font-size: 1.4rem;
  margin-top: 0.5rem;
`;

const StyledIcon = styled(SvgIcon)`
  margin-right: 0.8rem;
`;

interface Props {
  title: string;
  description: string;
  icon?: string;
  className?: string;
}

export const OrderStatusNotice: React.FC<Props> = ({
  title,
  description,
  icon,
  className,
}) => {
  return (
    <Wrapper className={className}>
      <StyledIcon
        height="3rem"
        icon={icon || 'action-icon-confirmed'}
        width="3rem"
      />
      <ContentColumn>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </ContentColumn>
    </Wrapper>
  );
};
