import React, { ReactNode, useCallback, useMemo } from 'react';

import styled, { useTheme } from 'styled-components';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import {
  APIOrderStatus,
  APIResponse,
  APITypeaheadItem,
  APIUserType,
} from '@cd3p/core/types/api';
import {
  Button,
  CanAccess,
  Checkbox,
  DateRangePicker,
  DispatcherCanAccess,
  FormControlLabel,
  IconButton,
  Typeahead,
  Typography,
} from 'components';
import i18n from 'i18n';
import { SingleValue } from 'react-select';

import {
  Controller,
  _,
  useForm,
  useSelector,
  useTranslation,
} from 'third-party';

import { AppTableSettings } from 'constants/common';

import { useCache } from 'modules/cache';
import { OrdersFilters, ordersDefaultFilters } from 'modules/common/ordersList';
import {
  resetTableSettingsFilters,
  updateTableSettingsFilters,
  useStorage,
} from 'modules/storage';

import { appSelectors, storageSelectors } from 'selectors';

import { orderStatusesMapping } from 'utils/order';
import { ordersListFiltersCount } from 'utils/ordersList';

import { TypeaheadOption } from 'types/app';

const dateSectionWidth = '25.5rem';

const ApplyButton = styled(Button)`
  text-transform: none;
`;

const Form = styled.form`
  display: flex;
  flex-flow: column;
  padding: 1.8rem;
`;

const Sections = styled.div`
  display: flex;
  flex-flow: row;
  padding-bottom: 1.6rem;
  border-bottom: 1px solid ${props => props.theme.custom.palette.muted100};
  height: 38rem;
`;

const DateSection = styled.div`
  display: flex;
  flex-flow: column;
  padding-right: 1.5rem;
  width: ${dateSectionWidth};
`;

const FilterSection = styled.div`
  display: flex;
  flex-flow: column;
  padding-left: 2.1rem;
  padding-right: 1.5rem;
  border-left: 1px solid ${props => props.theme.custom.palette.muted100};
`;

const VolumeSection = styled(FilterSection)`
  width: 14.2rem;
`;

const FooterSection = styled.div`
  display: flex;
  align-items: center;
  padding-top: 3.1rem;
  justify-content: center;
  gap: 1.7rem;
`;

const SectionTitle = styled(Typography)`
  margin-bottom: 1.5rem;
`;

const StatusText = styled(Typography)`
  font-weight: 600;
  line-height: 1.1;
  flex: 0;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
`;

const VolumeText = styled(Typography)`
  font-weight: 400;
  font-size: 1.6rem;
  color: ${props => props.theme.custom.palette.darkText};
`;

const SelectedTypeaheadOptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 100%;
  max-width: 100%;
  gap: 0.7rem;
  padding: 1.6rem 0;
  flex-grow: 1;
  min-height: 0;
`;

const SelectedTypeaheadOption = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${props => props.theme.custom.palette.muted500};
  border-radius: 1rem;
  padding: 0 0.5rem 0 0.9rem;
`;

const SelectedTypeaheadOptionLabel = styled(Typography)`
  font-weight: 600;
  font-size: 1.4rem;
  color: #000000;
  flex-grow: 1;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

type Props = {
  onReset: () => void;
  onApply: () => void;
};

const volumeOptions: {
  name: 'volume.0-100' | 'volume.100-500' | 'volume.500-1000' | 'volume.1000-';
  label: string;
}[] = [
  {
    name: 'volume.0-100',
    label: i18n.t('ordersList.filters.volume0-100'),
  },
  {
    name: 'volume.100-500',
    label: i18n.t('ordersList.filters.volume101-500'),
  },
  {
    name: 'volume.500-1000',
    label: i18n.t('ordersList.filters.volume501-1000'),
  },
  {
    name: 'volume.1000-',
    label: i18n.t('ordersList.filters.volume1001-'),
  },
];

type computedStatusNameProp =
  | `onlySubscribedOrders`
  | `status.${APIOrderStatus.Confirmed}`
  | `status.${APIOrderStatus.Unconfirmed}`
  | `status.${APIOrderStatus.Cancelled}`
  | `status.${APIOrderStatus.Delivering}`
  | `status.${APIOrderStatus.Completed}`
  | `status.${APIOrderStatus.Requested}`
  | `status.${APIOrderStatus.Declined}`
  | 'volume.0-100'
  | 'volume.100-500'
  | 'volume.500-1000'
  | 'volume.1000-';

type TypeaheadFilterName = 'company' | 'orderIds' | 'project';

export const FiltersPopup: React.FC<Props> = ({ onReset, onApply }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { updateUserSettingInStorage } = useStorage();
  const isBatchSoftwareIntegrationEnabled = useSelector(
    appSelectors.isBatchSoftwareIntegrationEnabled,
  );
  const ordersListFilters = useSelector(
    storageSelectors.tableSettingsFiltersSelector[AppTableSettings.AllOrders],
  );
  const isOrdersListFiltersApplied = useSelector(
    storageSelectors.isTableSettingsFiltersApplied[AppTableSettings.AllOrders](
      ordersDefaultFilters,
    ),
  );

  const {
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    formState: { isDirty },
  } = useForm<OrdersFilters>({
    defaultValues: ordersListFilters,
  });

  const formValues = watch();

  const onSubmit = (filters: OrdersFilters) => {
    updateUserSettingInStorage(
      updateTableSettingsFilters[AppTableSettings.AllOrders](filters),
    );
    onApply?.();
  };

  const onResetClicked = () => {
    reset(ordersDefaultFilters);
    updateUserSettingInStorage(
      resetTableSettingsFilters[AppTableSettings.AllOrders](
        ordersDefaultFilters,
      ),
    );
    onReset?.();
  };

  const isResetButtonDisabled = !isOrdersListFiltersApplied && !isDirty;
  const isApplyButtonDisabled = !isDirty;

  const applyButtonText = useMemo(() => {
    return t('ordersList.filters.apply', {
      count: ordersListFiltersCount(formValues),
    });
  }, [formValues, t]);

  const renderCheckboxControl = (
    name: computedStatusNameProp,
    label: string | ReactNode,
  ) => {
    return (
      <FormControlLabel
        key={name}
        componentsProps={{ typography: { sx: { display: 'flex' } } }}
        control={
          <Controller
            control={control}
            name={name}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <Checkbox
                inputRef={ref}
                checked={value}
                onChange={onChange}
                onBlur={onBlur}
              />
            )}
          />
        }
        label={label}
      />
    );
  };

  const onFromDateChange = useCallback(
    (dateFrom: Date | undefined) => {
      setValue('date.dateFrom', dateFrom, { shouldDirty: true });
    },
    [setValue],
  );

  const onToDateChange = useCallback(
    (dateTo: Date | undefined) => {
      setValue('date.dateTo', dateTo, { shouldDirty: true });
    },
    [setValue],
  );

  const { loadCustomers, loadOrderIds, loadProjects } = useCache();

  const loadOptions =
    (
      name: TypeaheadFilterName,
      loadFunc: (
        query: string,
        { excludedIds }: { excludedIds: any[] },
      ) => Promise<APIResponse<APITypeaheadItem[]>>,
    ) =>
    async (search: string) => {
      const excludedIds = formValues[name].map(it => it.value);
      const result: APIResponse<APITypeaheadItem[]> = await loadFunc(search, {
        excludedIds,
      });
      let options: TypeaheadOption[] = [];
      if (!result.error) {
        options = result.payload.map(it => ({
          label: it.name,
          value: it.id,
        }));
      }

      return {
        options: options,
      };
    };

  const onSelectFilter =
    (name: TypeaheadFilterName) => (option: SingleValue<TypeaheadOption>) => {
      if (option) {
        setValue(name, [...formValues[name], option], {
          shouldDirty: true,
        });
      }
    };

  const onSelectCompanyFilter = onSelectFilter('company');
  const onSelectProjectFilter = onSelectFilter('project');
  const onSelectOrderIdsFilter = onSelectFilter('orderIds');

  const onRemoveFilter = (
    name: TypeaheadFilterName,
    option: TypeaheadOption,
  ) => {
    setValue(name, _.reject(formValues[name], { value: option.value }), {
      shouldDirty: true,
    });
  };

  const renderSelectedTypeaheadOption = (
    name: TypeaheadFilterName,
    option: TypeaheadOption,
  ) => {
    return (
      <SelectedTypeaheadOption key={option.value}>
        <SelectedTypeaheadOptionLabel>
          {option.label}
        </SelectedTypeaheadOptionLabel>
        <IconButton
          sx={{ flexShrink: 0, cursor: 'pointer' }}
          onClick={() => onRemoveFilter(name, option)}
        >
          <HighlightOffIcon color={theme.custom.palette.muted400} />
        </IconButton>
      </SelectedTypeaheadOption>
    );
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Sections>
        <DateSection>
          <SectionTitle variant="subtitle2">
            {t('ordersList.filters.dateSectionTitle')}
          </SectionTitle>
          <DateRangePicker
            keepOpen
            popupWidth={dateSectionWidth}
            popupHeight="30.5rem"
            fromDate={formValues.date.dateFrom}
            toDate={formValues.date.dateTo}
            onFromChange={onFromDateChange}
            onToChange={onToDateChange}
          />
        </DateSection>
        <CanAccess allowedUserType={APIUserType.Contractor}>
          <FilterSection style={{ width: '22rem' }}>
            <SectionTitle variant="subtitle2">
              {t('ordersList.filters.myOrdersSectionTitle')}
            </SectionTitle>
            {renderCheckboxControl(
              'onlySubscribedOrders',
              t('ordersList.filters.myOrdersCheckboxLabel'),
            )}
          </FilterSection>
        </CanAccess>
        <DispatcherCanAccess>
          <FilterSection style={{ width: '22rem' }}>
            <SectionTitle variant="subtitle2">
              {t('ordersList.filters.customerSectionTitle')}
            </SectionTitle>
            <Typeahead
              hideDropdownIndicator
              placeholder={t('ordersList.filters.customerSelectPlaceholder')}
              loadOptions={loadOptions('company', loadCustomers)}
              width="100%"
              onChange={onSelectCompanyFilter}
              // don't need to store selected value in typeahead
              value={null}
            />
            <SelectedTypeaheadOptionWrapper>
              {formValues.company.map(it =>
                renderSelectedTypeaheadOption('company', it),
              )}
            </SelectedTypeaheadOptionWrapper>
          </FilterSection>
        </DispatcherCanAccess>
        <FilterSection style={{ width: '22rem' }}>
          <SectionTitle variant="subtitle2">
            {t('ordersList.filters.projectSectionTitle')}
          </SectionTitle>
          <Typeahead
            hideDropdownIndicator
            placeholder={t('ordersList.filters.projectSelectPlaceholder')}
            loadOptions={loadOptions('project', loadProjects)}
            width="100%"
            onChange={onSelectProjectFilter}
            // don't need to store selected value in typeahead
            value={null}
          />
          <SelectedTypeaheadOptionWrapper>
            {formValues.project.map(it =>
              renderSelectedTypeaheadOption('project', it),
            )}
          </SelectedTypeaheadOptionWrapper>
        </FilterSection>
        {isBatchSoftwareIntegrationEnabled && (
          <DispatcherCanAccess>
            <FilterSection style={{ width: '19rem' }}>
              <SectionTitle variant="subtitle2">
                {t('ordersList.filters.orderSectionTitle')}
              </SectionTitle>
              <Typeahead
                hideDropdownIndicator
                placeholder={t('ordersList.filters.orderSelectPlaceholder')}
                loadOptions={loadOptions('orderIds', loadOrderIds)}
                width="100%"
                onChange={onSelectOrderIdsFilter}
                // don't need to store selected value in typeahead
                value={null}
              />
              <SelectedTypeaheadOptionWrapper>
                {formValues.orderIds.map(it =>
                  renderSelectedTypeaheadOption('orderIds', it),
                )}
              </SelectedTypeaheadOptionWrapper>
            </FilterSection>
          </DispatcherCanAccess>
        )}
        <VolumeSection>
          <SectionTitle variant="subtitle2">
            {t('ordersList.filters.volumeQtyTitle')}
          </SectionTitle>
          {volumeOptions.map(it =>
            renderCheckboxControl(it.name, <VolumeText>{it.label}</VolumeText>),
          )}
        </VolumeSection>
        <FilterSection>
          <SectionTitle variant="subtitle2">
            {t('ordersList.filters.statusSectionTitle')}
          </SectionTitle>
          {Object.keys<APIOrderStatus>(orderStatusesMapping).map(
            statusValue => {
              const statusItem = orderStatusesMapping[statusValue];
              return renderCheckboxControl(
                `status.${statusValue}`,
                <StatusText color={statusItem.color}>
                  {statusItem.name}
                  {statusValue === APIOrderStatus.Unconfirmed && (
                    <ErrorIcon sx={{ fontSize: '2rem', marginLeft: '1rem' }} />
                  )}
                  {[APIOrderStatus.Requested].includes(statusValue) && (
                    <CheckCircleIcon
                      sx={{ fontSize: '2rem', marginLeft: '1rem' }}
                    />
                  )}
                  {statusValue === APIOrderStatus.Declined && (
                    <CancelIcon sx={{ fontSize: '2rem', marginLeft: '1rem' }} />
                  )}
                </StatusText>,
              );
            },
          )}
        </FilterSection>
      </Sections>
      <FooterSection>
        <Button
          variant="outlined"
          color="secondary"
          onClick={onResetClicked}
          disabled={isResetButtonDisabled}
        >
          {t('common.reset')}
        </Button>
        <ApplyButton
          variant="contained"
          type="submit"
          disabled={isApplyButtonDisabled}
        >
          {applyButtonText}
        </ApplyButton>
      </FooterSection>
    </Form>
  );
};
