import { createOrdersRefSelector } from '@cd3p/core/utils/ordersList';
import { State } from 'modules';

import { _ } from 'third-party';

import { ordersDefaultFilters } from 'modules/common/ordersList';

export const todayOrderListState = (state: State) => state.todayOrdersList;

export const todayOrdersListItems = createOrdersRefSelector(
  (state: State) => state.todayOrdersList.items,
);
export const todayOrdersListSorting = (state: State) =>
  state.todayOrdersList.sorting;
export const todayOrdersListFilters = (state: State) =>
  state.todayOrdersList.filters;
export const isTodayOrdersListFiltersApplied = (state: State) =>
  !_.isEqual(state.todayOrdersList.filters, ordersDefaultFilters);
export const todayOrdersListPagination = (state: State) =>
  state.todayOrdersList.pagination;
export const todayOrdersListCount = (state: State) =>
  state.todayOrdersList.count;
export const loadTodayOrdersPending = (state: State) =>
  state.todayOrdersList.loadTodayOrdersPending;
export const todayOrdersLoaded = (state: State) =>
  state.todayOrdersList.ordersLoaded;
export const loadMoreTodayOrdersPending = (state: State) =>
  state.todayOrdersList.loadMoreTodayOrdersPending;
