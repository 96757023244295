import React, { useCallback, useMemo, useState } from 'react';

import styled from 'styled-components';

import { GoogleMap, Polyline, useJsApiLoader } from '@react-google-maps/api';

import { GMAP_API_KEY } from 'constants/common';

import { mapStylesStandard } from 'components/TrackingMap/trackingMapStyles';

import { palette } from 'styles/theme';

type Props = {
  points: {
    lat: number;
    lng: number;
  }[];
};

const containerStyle = {
  width: '100%',
  height: '100%',
};

const GoogleMapWrapper = styled.div`
  width: 93%;
  height: 100%;
  z-index: 1;
`;

const createMapOptions = {
  scrollwheel: true,
  fullscreenControl: false,
  styles: mapStylesStandard,
};

type GoogleAPI = google.maps.Map;

export const TrackingMap: React.FC<Props> = ({ points }) => {
  const [, setGoogleApi] = useState<GoogleAPI | null>(null);
  const [center] = useState({ lat: 0, lng: 0 });

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GMAP_API_KEY,
  });

  const onGoogleApiLoaded = useCallback((map: GoogleAPI) => {
    setGoogleApi(map);
  }, []);

  const truckLines = useMemo(() => {
    return (
      <Polyline
        options={{
          path: points,
          strokeColor: palette.secondary900,
          strokeOpacity: 1.0,
          strokeWeight: 2,
        }}
      />
    );
  }, [points]);

  const mapCenter = useMemo(() => {
    if (points.length) {
      return points[0];
    }

    return center;
  }, [center, points]);

  return (
    <GoogleMapWrapper>
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={containerStyle}
          options={createMapOptions}
          center={mapCenter}
          zoom={14}
          onLoad={onGoogleApiLoaded}
        >
          {truckLines}
        </GoogleMap>
      )}
    </GoogleMapWrapper>
  );
};
