import { useCallback } from 'react';

import { CONTRACTOR_TICKET_STATUSES } from '@cd3p/core/constants/common';
import { APISortOrder } from '@cd3p/core/types/api';
import { OrderFields } from '@cd3p/core/utils/order';

import { useAllOrdersList } from 'modules/allOrdersList';
import { useOrder } from 'modules/order';
import { useProjectOrdersList } from 'modules/projectOrdersList';

// this code runs every time for contractor user
// when they refresh the page
export const useInitContractor = () => {
  const { updateOrderTicketsFilters } = useOrder();
  const { updateAllOrdersListSorting } = useAllOrdersList();
  const { updateProjectOrdersListSorting } = useProjectOrdersList();
  return useCallback(() => {
    updateOrderTicketsFilters({
      ticketStatuses: CONTRACTOR_TICKET_STATUSES,
    });
    updateAllOrdersListSorting([
      {
        sortField: OrderFields.DeliveryDate,
        sortOrder: APISortOrder.DESC,
      },
    ]);
    updateProjectOrdersListSorting([
      {
        sortField: OrderFields.DeliveryDate,
        sortOrder: APISortOrder.DESC,
      },
    ]);
  }, [
    updateAllOrdersListSorting,
    updateOrderTicketsFilters,
    updateProjectOrdersListSorting,
  ]);
};
