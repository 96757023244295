import { ReactElement, useCallback, useMemo } from 'react';

import { DefaultTheme } from 'styled-components';

import {
  APIDeliveryRateType,
  APIOrder,
  APIOrderRequest,
  APIOrderStatus,
  APITicketStatuses,
  APITruckStatuses,
  APIUserType,
} from '@cd3p/core/types/api';
import { DISPLAY_DATE_FORMAT } from '@cd3p/core/utils/common';
import {
  getOrderStatusLabel,
  prepareOrderForUpdating,
} from '@cd3p/core/utils/order';
import dayjs from 'dayjs';
import { useHandleApiResult } from 'hooks/useHandleApiResult';
import { emit } from 'hooks/usePubSub';
import i18n from 'i18n';

import { _, useSelector, useTranslation } from 'third-party';

import { APP_EVENTS } from 'constants/appEvent';

import { useOrder } from 'modules/order';

import { appSelectors, dialogsListSelectors } from 'selectors';

import { IOptions, LocationT } from 'types/app';

import { themeConfig } from 'styles/theme';

export const DISPLAY_DATE_FORMAT_WITH_DAY_OF_WEEK = 'dddd MM/DD/YYYY';

export const DISPLAY_TIME_AM_PM_FORMAT = 'hh:mm a';
export const API_DATE_FORMAT = 'YYYY-MM-DD';
export const API_TIME_FORMAT = 'HH:MM';

export const formatDateWithDayOfWeek = (dateToFormat?: string | Date) => {
  return dateToFormat
    ? dayjs(dateToFormat).format(DISPLAY_DATE_FORMAT_WITH_DAY_OF_WEEK)
    : '';
};

export const createDateFromTwoDates = (
  yearMonthDay: string, // 2022-10-20
  dateTime: Date, // Fri Nov 18 2022 05:24:00 GMT+0100 (Central European Standard Time)
) => {
  // yearMonthDay contains YYYY-MM-DD
  // dateTime contains Date object which has the needed time
  // resultDate with correct assign time needs to be sent to BE in UTC format
  // example: yearMonthDay contains order?.deliveryDate
  // dateTime contains user input hh:mm a|p in UTC format
  // TODO: order?.deliveryDate will be later in UTC format and this needs to be rewritten
  const hours = dateTime.getHours();
  const minutes = dateTime.getMinutes();
  const resultDate = new Date(yearMonthDay);
  resultDate.setHours(hours);
  resultDate.setMinutes(minutes);

  // todo: remove timezone from time
  // todo: should rewritten in better way
  const hoursDiff = resultDate.getHours() - resultDate.getTimezoneOffset() / 60;
  resultDate.setHours(hoursDiff);

  return resultDate.toISOString();
};

export const transformTime = (time?: string): Date | '' => {
  if (!time) return '';
  const dateValue = new Date();
  const [hours, minutes] = time.split(':');
  dateValue.setHours(Number(hours));
  dateValue.setMinutes(Number(minutes));
  return dateValue;
};

export const formatDateToAMPMTime = (date: Date | string) =>
  dayjs(date).format(DISPLAY_TIME_AM_PM_FORMAT);

export const formatTime = (timeToFormat?: string) => {
  // '14:23:00
  return timeToFormat ? formatDateToAMPMTime(transformTime(timeToFormat)) : '';
};

export const getLabelColor = (hasError: boolean, theme: DefaultTheme) =>
  hasError ? theme.custom.palette.error500 : theme.custom.palette.darkText;

export const orderStatusesMapping: {
  [key in APIOrderStatus]: {
    color: string;
    name: string;
  };
} = {
  [APIOrderStatus.Requested]: {
    color: themeConfig.custom.palette.statusRequested,
    name: getOrderStatusLabel(APIOrderStatus.Requested, i18n.t),
  },
  [APIOrderStatus.Unconfirmed]: {
    color: themeConfig.custom.palette.statusUnconfirmed,
    name: getOrderStatusLabel(APIOrderStatus.Unconfirmed, i18n.t),
  },
  [APIOrderStatus.Confirmed]: {
    color: themeConfig.custom.palette.statusConfirmed,
    name: getOrderStatusLabel(APIOrderStatus.Confirmed, i18n.t),
  },
  [APIOrderStatus.Delivering]: {
    color: themeConfig.custom.palette.statusDelivering,
    name: getOrderStatusLabel(APIOrderStatus.Delivering, i18n.t),
  },
  [APIOrderStatus.Completed]: {
    color: themeConfig.custom.palette.statusCompleted,
    name: getOrderStatusLabel(APIOrderStatus.Completed, i18n.t),
  },
  [APIOrderStatus.Cancelled]: {
    color: themeConfig.custom.palette.statusCanceled,
    name: getOrderStatusLabel(APIOrderStatus.Cancelled, i18n.t),
  },
  [APIOrderStatus.Declined]: {
    color: themeConfig.custom.palette.statusCanceled,
    name: getOrderStatusLabel(APIOrderStatus.Declined, i18n.t),
  },
};

export const truckColorMap: {
  [key in APITruckStatuses]: string;
} = {
  [APITruckStatuses.Unavailable]:
    themeConfig.custom.palette.truckStatusUnavailable,
  [APITruckStatuses.Ready]: themeConfig.custom.palette.truckStatusReady,
  [APITruckStatuses.Loading]: themeConfig.custom.palette.truckStatusLoading,
  [APITruckStatuses.Delivering]: themeConfig.custom.palette.statusDelivering,
  [APITruckStatuses.OnSite]: themeConfig.custom.palette.truckStatusOnsite,
  [APITruckStatuses.Returning]: themeConfig.custom.palette.truckStatusReturning,
};

export const ticketStatusMap: {
  [key in APITicketStatuses]: {
    label: string;
    color: string;
  };
} = {
  [APITicketStatuses.Draft]: {
    label: i18n.t('order.ticket.draftStatus'),
    color: themeConfig.custom.palette.warning600,
  },
  [APITicketStatuses.Loading]: {
    label: i18n.t('order.ticket.loadingStatus'),
    color: themeConfig.custom.palette.info500,
  },
  [APITicketStatuses.Delivering]: {
    label: i18n.t('order.ticket.deliveringStatus'),
    color: themeConfig.custom.palette.info700,
  },
  [APITicketStatuses.Completed]: {
    label: i18n.t('order.ticket.completeStatus'),
    color: themeConfig.custom.palette.info900,
  },
  [APITicketStatuses.Cancelled]: {
    label: i18n.t('order.ticket.cancelledStatus'),
    color: themeConfig.custom.palette.danger900,
  },
  [APITicketStatuses.OnSite]: {
    label: i18n.t('order.ticket.onSiteStatus'),
    color: themeConfig.custom.palette.purple800,
  },
  [APITicketStatuses.Pending]: {
    label: i18n.t('order.ticket.pendingStatus'),
    color: themeConfig.custom.palette.amber500,
  },
};

export type ORDER_FORM_FIELD_DATA = {
  key?: string;
  label?: string;
  value?:
    | string
    | number
    | ReactElement
    | IOptions[]
    | (string | number)[]
    | null;
  readOnlyValue?: string | number | ReactElement;
  placeholder?: string;
  isDisabled?: boolean;
  rules?: any;
  maxLength?: number;
  type?: string;
  isRequired?: boolean;
  selectOptions?: ReactElement[] | IOptions[];
};

export enum ORDER_FORMS {
  ORDER_STATUS = 'ORDER_STATUS',
  DATE_TIME = 'DATE_TIME',
  OVERVIEW = 'OVERVIEW',
  ORDER_DETAILS = 'ORDER_DETAILS',
}

export const areTicketsAvailableForOrderStatus = (order?: APIOrder | null) =>
  order &&
  [
    APIOrderStatus.Confirmed,
    APIOrderStatus.Delivering,
    APIOrderStatus.Completed,
  ].includes(order.orderStatus);

export const canAddNewTickets = (order: APIOrder) =>
  [APIOrderStatus.Confirmed, APIOrderStatus.Delivering].includes(
    order.orderStatus,
  );

// converts duration in seconds to minutes and rounds up
export const getTruckETA = (duration?: number | null) => {
  if (duration && !isNaN(Number(duration))) {
    const totalMinutes = Math.ceil(duration / 60);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return [hours && `${hours}h`, minutes && `${minutes} min`]
      .filter(Boolean)
      .join(' ');
  } else {
    return 0;
  }
};

export const shouldShowTicketETA = (
  ticketStatus: APITicketStatuses,
  duration: number | null | undefined,
) => {
  return (
    ticketStatus === APITicketStatuses.Delivering && !isNaN(Number(duration))
  );
};

export const truckStatusesToShowOnMap = [
  APITruckStatuses.Loading,
  APITruckStatuses.Delivering,
  APITruckStatuses.OnSite,
  APITruckStatuses.Returning,
];

export const truckStatusesForTodayOrders = [
  APITruckStatuses.Ready,
  APITruckStatuses.Returning,
];

export const getOrderDeliveryRateDiff = (
  deliveryRate: number,
  actualDeliveryRate: number,
) => (actualDeliveryRate - deliveryRate) * -1 ?? 0;

export const shouldShowActualDeliveryRate = (
  deliveryRate: number,
  actualDeliveryRate: number | null,
) => {
  if (!_.isNil(deliveryRate) && !_.isNil(actualDeliveryRate)) {
    const deliveryRateDiff = getOrderDeliveryRateDiff(
      deliveryRate,
      actualDeliveryRate,
    );
    return Math.abs(deliveryRateDiff) > deliveryRate * 0.25;
  }
  return false;
};

export const isOrderReadyToComplete = (order: APIOrder) =>
  order.orderStatus !== APIOrderStatus.Completed && order.pendingCompletion;

export const isCancellationSubmittedOrder = (order: APIOrder) =>
  order.isCancellationSubmitted;

export const isDateTomorrow = (dateValue?: string) =>
  !!(dateValue && dayjs(dateValue).isTomorrow());

export const isDateEqualOrGreaterToday = (
  dateValue: string,
  timeValue: string,
) => {
  const dateTime = `${dateValue} ${timeValue}`;

  const date = dayjs(
    dateTime,
    `${DISPLAY_DATE_FORMAT} ${DISPLAY_TIME_AM_PM_FORMAT}`,
  );

  return date.isAfter(dayjs());
};

export const getSiteData = (order: APIOrder): LocationT => ({
  longitude: order.longitude || 0,
  latitude: order.latitude || 0,
  name: order.project?.name,
  address: order?.deliveryLocation,
});

export const useCloseOrder = () => {
  const { t } = useTranslation();

  const { changeOrderStatus, fetchOrderById } = useOrder();

  const handleApiResult = useHandleApiResult();

  return useCallback(
    (orderId: number) => {
      return new Promise(resolve => {
        handleApiResult<APIOrder>(
          () => fetchOrderById(orderId),
          ({ result }) => {
            return handleApiResult(
              () =>
                changeOrderStatus(result.payload.id, APIOrderStatus.Completed),
              ({ showBaseToast }) => {
                emit(APP_EVENTS.CLOSE_ORDER);
                showBaseToast(
                  t('order.details.closeOrderSuccessMessage', {
                    orderName: result.payload.orderName,
                  }),
                  { id: 'close_order' },
                );
                resolve(true);
              },
              ({ showErrorToast }) => {
                showErrorToast(t('order.details.closeOrderErrorMessage'), {
                  id: 'close_order',
                });
                resolve(false);
              },
            );
          },
          ({ showErrorToast }) => {
            showErrorToast(t('order.details.closeOrderErrorMessage'), {
              id: 'close_order',
            });
            resolve(false);
          },
        );
      });
    },
    [fetchOrderById, handleApiResult, t, changeOrderStatus],
  );
};

export const useUpdateOrderData = () => {
  const { updateOrder, fetchOrderById } = useOrder();

  const handleApiResult = useHandleApiResult();

  const updateOrderData = useCallback(
    ({
      orderId,
      orderData,
      successToastMessage,
      errorToastMessage,
    }: {
      orderId: number;
      orderData: Partial<APIOrderRequest>;
      successToastMessage?: string;
      errorToastMessage?: string;
    }) => {
      return new Promise(resolve => {
        handleApiResult<APIOrder>(
          () => fetchOrderById(orderId),
          ({ result }) => {
            return handleApiResult(
              () =>
                updateOrder(result.payload.id, {
                  ...prepareOrderForUpdating(result.payload),
                  ...orderData,
                }),
              ({ showBaseToast }) => {
                if (successToastMessage) {
                  showBaseToast(successToastMessage);
                }
                resolve(true);
              },
              ({ showErrorToast }) => {
                showErrorToast(errorToastMessage);
                resolve(false);
              },
            );
          },
          ({ showErrorToast }) => {
            showErrorToast(errorToastMessage);
            resolve(false);
          },
        );
      });
    },
    [fetchOrderById, handleApiResult, updateOrder],
  );
  return { updateOrderData };
};

export const useIsSubscribedOrder = () => {
  const subscribedOrdersIds = useSelector(
    dialogsListSelectors.subscribedOrdersIds,
  );
  return useCallback(
    (orderId: number) => {
      return subscribedOrdersIds.includes(orderId);
    },
    [subscribedOrdersIds],
  );
};

export const deliveryRateTypeOptions = [
  {
    value: APIDeliveryRateType.MinTruck,
    label: i18n.t('order.sectionField.deliveryRateTypeMinPerTruck'),
  },
  {
    value: APIDeliveryRateType.CyHour,
    label: i18n.t('order.sectionField.deliveryRateTypeCYHour'),
  },
];

export const useCanEditOrder = (orderStatus: APIOrderStatus) => {
  const defaultContractorOrderEditingAllowedStates = [
    APIOrderStatus.Unconfirmed,
    APIOrderStatus.Requested,
  ];

  const userType = useSelector(appSelectors.userType);
  const contractorOrderEditingAllowedStates =
    useSelector(appSelectors.contractorOrderEditingAllowedStates) ||
    defaultContractorOrderEditingAllowedStates;

  return useMemo(() => {
    return (
      userType === APIUserType.Dispatcher ||
      (userType === APIUserType.Contractor &&
        contractorOrderEditingAllowedStates.includes(orderStatus))
    );
  }, [contractorOrderEditingAllowedStates, orderStatus, userType]);
};
