import React, { ReactNode } from 'react';

import styled from 'styled-components';

import NotificationsIcon from '@mui/icons-material/Notifications';

import { motion } from 'framer-motion';

const Wrapper = styled(motion.div)`
  position: relative;
`;

const NewAlertsBadge = styled(motion.div)<{ top: number; right: number }>`
  position: absolute;
  top: ${props => props.top};
  right: ${props => props.right};
  width: 1rem;
  height: 1rem;
  border-radius: 1rem;
  background-color: ${props => props.theme.custom.palette.notificationBadge};
`;

const StyledNotificationsIcon = styled(NotificationsIcon)`
  color: ${props => props.theme.custom.palette.secondary900};
`;

const variants = {
  enlarge: {
    scale: 1.15,
  },
};

type Props = {
  iconComponent?: ReactNode;
  unread?: boolean;
  unreadDotTopPosition?: number;
  unreadDotRightPosition?: number;
};

export const AnimatedMenuIcon: React.FC<Props> = ({
  unread,
  iconComponent,
  unreadDotTopPosition = 0,
  unreadDotRightPosition = 0,
}) => {
  return (
    <Wrapper variants={variants}>
      {iconComponent || <StyledNotificationsIcon />}
      <NewAlertsBadge
        top={unreadDotTopPosition}
        right={unreadDotRightPosition}
        initial={false}
        animate={{ scale: unread ? [0, 1.2, 1] : [1, 0] }}
      />
    </Wrapper>
  );
};
