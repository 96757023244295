import { useCallback } from 'react';

import {
  Notification,
  NotificationMessage,
  NotificationSeverity,
  addNotification as addNotificationFunc,
} from '../modules/notifications';
import { APIResponse } from '../types/api';
import { APIServerError } from '../types/common';
import { OmitFirstArg } from '../types/utils';
import { getServerErrorTitle } from '../utils/common';

export const useHandleApiResultFactory = <
  TFunc extends (key: string) => string,
  RedirectOn404Func extends (
    result: APIServerError | undefined,
    redirectUrl?: string,
    options?: any,
  ) => boolean,
>(
  t: TFunc,
  addNotification: typeof addNotificationFunc,
  redirectOn404?: RedirectOn404Func,
) => {
  const showBaseToast = useCallback(
    (message: NotificationMessage, options: Partial<Notification> = {}) => {
      addNotification({
        message: message,
        ...options,
      });
    },
    [addNotification],
  );
  const showSuccessToast = useCallback(
    (message: NotificationMessage, options: Partial<Notification> = {}) => {
      addNotification({
        severity: NotificationSeverity.Success,
        message: message,
        ...options,
      });
    },
    [addNotification],
  );

  const showWarningToast = useCallback(
    (message: NotificationMessage, options: Partial<Notification> = {}) => {
      addNotification({
        severity: NotificationSeverity.Warning,
        message: message,
        ...options,
      });
    },
    [addNotification],
  );

  const showErrorToast = useCallback(
    (
      result: APIServerError | undefined,
      message?: NotificationMessage,
      options: Partial<Notification> = {},
    ) => {
      addNotification({
        severity: NotificationSeverity.Error,
        message:
          message || getServerErrorTitle(result) || t('common.generalError'),
        ...options,
      });
    },
    [addNotification, t],
  );

  return useCallback(
    async <ResponseT>(
      apiCall: () => any,
      onSuccess?:
        | string
        | ((params: {
            result: { error?: false; payload: ResponseT };
            showSuccessToast: typeof showSuccessToast;
            showBaseToast: typeof showBaseToast;
          }) => void),
      onError?: (params: {
        result: APIServerError | undefined;
        showErrorToast: OmitFirstArg<typeof showErrorToast>;
        showWarningToast: typeof showWarningToast;
        redirectOn404: (redirectUrl?: string, options?: any) => boolean;
      }) => void,
    ) => {
      const result: APIResponse<ResponseT> = await apiCall();
      if (!result || result.error) {
        if (onError) {
          onError({
            showErrorToast: showErrorToast.bind(null, result),
            showWarningToast,
            result,
            redirectOn404: redirectOn404
              ? redirectOn404.bind(null, result)
              : () => false,
          });
        } else {
          showErrorToast(result);
        }
      } else {
        if (typeof onSuccess === 'string') {
          showSuccessToast(onSuccess);
        } else if (typeof onSuccess === 'function') {
          onSuccess({ showSuccessToast, showBaseToast, result });
        }
      }
      return result;
    },
    [
      redirectOn404,
      showBaseToast,
      showErrorToast,
      showSuccessToast,
      showWarningToast,
    ],
  );
};
