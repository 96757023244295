import dayjs from 'dayjs';
import { TFunction } from 'i18next';

import { ORDERS_API_PATH } from '../constants/common';
import { _ } from '../third-party';
import {
  APIChangesStatus,
  APIDeliveryRateType,
  APIEntityHistory,
  APIExceptionType,
  APIOrder,
  APIOrderStatus,
  APISearchSortOrder,
  APISortOrder,
  APITicket,
  APITicketStatusHistory,
  APITicketStatuses,
} from '../types/api';
import { APIServerError, OrderChangesStatus } from '../types/common';

export const DISPLAY_TIME_AM_PM_FORMAT = 'hh:mm a';

// converts duration in seconds to minutes and rounds up
export const getTruckETA = (duration?: number) => {
  if (duration && !isNaN(Number(duration))) {
    const totalMinutes = Math.ceil(duration / 60);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return [hours && `${hours}h`, minutes && `${minutes} min`]
      .filter(Boolean)
      .join(' ');
  } else {
    return 0;
  }
};

export const shouldShowTicketETA = (ticket: {
  ticketStatus: APITicketStatuses;
  timeOfArrival: { duration?: number } | null;
}) => {
  return (
    ticket.ticketStatus === APITicketStatuses.Delivering &&
    !isNaN(Number(ticket?.timeOfArrival?.duration))
  );
};

export const getDestinationCoordinates = (ticket: APITicket | APIOrder) => ({
  longitude: ticket?.longitude,
  latitude: ticket?.latitude,
});

type CreateNumberMaskProps = {
  isInteger: boolean;
  precision?: number;
  mantissa?: number;
  allowZero?: boolean;
};

export const createNumberMask = ({
  isInteger = false,
  precision = 4,
  mantissa = 2,
  allowZero = false,
}: CreateNumberMaskProps) => {
  return (value?: string) => {
    if (allowZero && value?.startsWith('0')) {
      return [/[0]/s];
    }
    const clear = value?.replace(/^0+/, '').replace(/[^0-9.,]+/g, '') || '';

    if (isInteger || !(clear.includes('.') || clear.includes(','))) {
      const intArray = clear
        .split('')
        .map(() => /\d/)
        .splice(1, precision - 1);

      return [/[1-9]/, ...intArray];
    }

    const mantissaArray = Array(mantissa).fill(/\d/);
    if (clear?.match(/^[0-9]{1,}[,.]{0,1}[0-9]{0,}/)) {
      const precisionArray = clear
        .split('.')[0]
        .split(',')[0]
        .split('')
        .map(() => /\d/)
        .splice(1, precision - 1);

      return [/[1-9]/, ...precisionArray, /[.,]/, ...mantissaArray];
    }

    // otherwise mask will be 0,00
    return [/\d/, /[.,]/, ...mantissaArray];
  };
};
export const prepareOrderForUpdating = (
  order?: APIOrder | null | Record<string, never>,
) => {
  // latitude and longitude is calculated on BE from deliveryLocation, no need to send it
  return order
    ? {
        ..._.omit(order, [
          'mixType',
          'project',
          'company',
          'latitude',
          'longitude',
        ]),
        mixTypeId: order?.mixType?.id!,
        projectId: order?.project?.id,
      }
    : {};
};

export const getOrderChangesStatus = (order: APIOrder) => {
  if (!order.orderChanges?.length) {
    return null;
  }

  switch (true) {
    case order.orderChanges.every(
      (it) => it.changesStatus === APIChangesStatus.Pending,
    ):
      return OrderChangesStatus.Pending;

    case order.orderChanges.every(
      (it) => it.changesStatus === APIChangesStatus.Applied,
    ):
      return OrderChangesStatus.Approved;

    case order.orderChanges.every(
      (it) => it.changesStatus === APIChangesStatus.Declined,
    ):
      return OrderChangesStatus.Rejected;

    default:
      return OrderChangesStatus.PartiallyApproved;
  }
};

export const getStringOrObject = (foundChange: APIEntityHistory) => {
  try {
    // checkboxes are boolean but can be received as "True" and "False"
    // TODO: it is better for BE to return normal boolean values instead of stringified booleans
    if (['True', 'False'].includes(foundChange.fieldValue as string)) {
      return foundChange.fieldValue === 'True';
    }
    // field value can be serialized json
    const obj = JSON.parse(foundChange.fieldValue as string);
    if (obj && typeof obj === 'object') {
      return obj;
    } else {
      return foundChange.fieldValue;
    }
  } catch (e) {
    return foundChange.fieldValue;
  }
};

export enum OrderFields {
  Id = 'id',
  OrderStatus = 'orderStatus',
  OrderNumber = 'orderNumber',
  Volume = 'volume',
  DeliveryDate = 'deliveryDate',
  DeliveryTime = 'deliveryTime',
  Company = 'company',
  Project = 'project',
  ProjectId = 'projectId',
  DeliveryRate = 'deliveryRate',
  DeliveryRateType = 'deliveryRateType',
  DeliveryLocation = 'deliveryLocation',
  Slump = 'slump',
  Notes = 'notes',
  Additives = 'additives',
  MixType = 'mixType',
  MixTypeId = 'mixTypeId',
  Callback = 'callBack',
  ConfirmUponChangesApproval = 'confirmUponChangesApproval',
  OrderType = 'orderType',
  TypeOfPour = 'typeOfPour',
  PlacementMethod = 'placementMethod',
  SubscribedUsers = 'subscribedUsersIds',
}

export const getOrderSortingWithDate = (
  newSorting:
    | APISearchSortOrder<keyof APIOrder>[]
    | APISearchSortOrder<keyof APIOrder>,
) => {
  const result = Array.isArray(newSorting) ? [...newSorting] : [newSorting];
  // according to https://cd-3po.atlassian.net/browse/CD3P-1736 we need to apply additional
  // sorting by Date+Time ASC whenever we sort any column in orders table
  const dateSorting = result.find(
    (it) => it.sortField === OrderFields.DeliveryDate,
  );
  if (!dateSorting) {
    result.push({
      sortField: OrderFields.DeliveryDate,
      sortOrder: APISortOrder.ASC,
    });
  }
  if (!result.find((it) => it.sortField === OrderFields.DeliveryTime)) {
    result.push({
      sortField: OrderFields.DeliveryTime,
      // if we're already sorting by date, time field should follow the same sort direction
      sortOrder: dateSorting ? dateSorting.sortOrder : APISortOrder.ASC,
    });
  }
  // Additional sorting by id to cover cases when there are multiple orders with the same DeliveryDate and DeliveryTime
  result.push({
    sortField: OrderFields.Id,
    sortOrder: APISortOrder.ASC,
  });
  return result;
};

export const getTruckDisplayName = (
  truckNumber: string,
  driverFirstName?: string | null,
  driverLastName?: string | null,
) => {
  const driverName = [
    driverFirstName,
    driverLastName && driverLastName.slice(0, 1) + '.',
  ]
    .filter(Boolean)
    .join(' ');
  return `${truckNumber}${driverName ? ` (${driverName})` : ''}`;
};

export const formatDateToAMPMTime = (date: Date | string) =>
  dayjs(date).format(DISPLAY_TIME_AM_PM_FORMAT);

// extract statuses that only used in tracked ticketStatusHistory objects
type TicketHistoryStatuses = Extract<
  APITicketStatuses,
  | APITicketStatuses.Loading
  | APITicketStatuses.Delivering
  | APITicketStatuses.Completed
  | APITicketStatuses.OnSite
>;
const getTimeStampStatus = (t: TFunction) => ({
  [APITicketStatuses.Loading]: t('order.ticket.timeStampLoadingStatus'),
  [APITicketStatuses.Delivering]: t('order.ticket.timeStampDeliveryStatus'),
  [APITicketStatuses.OnSite]: t('order.ticket.timeStampOnSiteStatus'),
  [APITicketStatuses.Completed]: t('order.ticket.timeStampCompletedStatus'),
});

export type TimeStampStep = {
  label: string;
  description?: string | null;
  isManuallyChanged?: boolean;
  skipped?: boolean;
  notExist?: boolean;
};

const getTimeStampStepsSkeleton = (t: TFunction): TimeStampStep[] => [
  {
    label: getTimeStampStatus(t)[APITicketStatuses.Loading],
    description: null,
    isManuallyChanged: false,
    skipped: false,
    notExist: false,
  },
  {
    label: getTimeStampStatus(t)[APITicketStatuses.Delivering],
    description: null,
    isManuallyChanged: false,
    skipped: false,
    notExist: false,
  },
  {
    label: getTimeStampStatus(t)[APITicketStatuses.OnSite],
    description: null,
    isManuallyChanged: false,
    skipped: false,
    notExist: false,
  },
  {
    label: getTimeStampStatus(t)[APITicketStatuses.Completed],
    description: null,
    isManuallyChanged: false,
    skipped: false,
    notExist: false,
  },
];

export const parseTicketStatusHistory = ({
  ticketStatusHistory,
  t,
}: {
  ticketStatusHistory?: APITicketStatusHistory[] | null;
  t: TFunction;
}): TimeStampStep[] => {
  // DESCRIPTION: in the code below we check ticket flow from last step to first to decide should we show the step as passed or skip it without broking the ticket flow stepper.
  // In some cases we don't have all the steps from the flow, that why we should know what is the last ticket step from the flow that was already passed.
  // If step is passed all prev not-existing steps just skipped and shown as 'passed'
  // If step is not passed all prev not-existing steps also shown as not passed until existed step will be finded
  // If step is not passed all next steps also shown as not passed
  if (ticketStatusHistory) {
    const timeStampSteps: TimeStampStep[] = [];
    [...getTimeStampStepsSkeleton(t)].reverse().forEach((step, index) => {
      const ticketStatusData = ticketStatusHistory.find(
        (ticket) =>
          getTimeStampStatus(t)[
            ticket.ticketStatus as TicketHistoryStatuses
          ] === step.label,
      );
      if (ticketStatusData) {
        const formatedDate = ticketStatusData?.timeStamp
          ? formatDateToAMPMTime(ticketStatusData?.timeStamp)
          : null;
        timeStampSteps[index] = {
          ...step,
          description: ticketStatusData?.isManualChange
            ? t('order.ticket.notRegistered')
            : formatedDate || null,
          isManuallyChanged: ticketStatusData?.isManualChange,
        };
        return;
      }
      // check skipped status step in what way it should be shown according to next steps statuses
      const isPrevItemMapped = !!timeStampSteps[index - 1];
      const isNextTimeStampStatusExist =
        isPrevItemMapped && !timeStampSteps[index - 1].notExist;
      const isNextTimeStampStatusEdited =
        isPrevItemMapped && timeStampSteps[index - 1].isManuallyChanged;
      const isNextTimeStampStatusNotExistButSkipped =
        isPrevItemMapped &&
        timeStampSteps[index - 1].notExist &&
        timeStampSteps[index - 1].skipped;
      const isStepShouldSkipped =
        isPrevItemMapped &&
        (isNextTimeStampStatusExist || isNextTimeStampStatusNotExistButSkipped);
      timeStampSteps[index] = {
        ...step,
        notExist: true,
        skipped: isStepShouldSkipped,
        isManuallyChanged: isStepShouldSkipped && isNextTimeStampStatusEdited,
        description: isStepShouldSkipped
          ? t('order.ticket.notRegistered')
          : null,
      };
      return;
    });

    return timeStampSteps.reverse();
  }
  return getTimeStampStepsSkeleton(t);
};
export const generateCoordinatesLink = (long: number, lat: number) =>
  `${ORDERS_API_PATH}map/${lat}/${long}`;

export const getModifiedOrderFieldValue = (
  fieldName: keyof APIOrder,
  order: APIOrder,
  defaultValue?: any,
) => {
  const { orderChanges } = order;
  const foundChange = ((orderChanges as APIEntityHistory[]) || []).find(
    (it) =>
      it.fieldName === fieldName &&
      it.changesStatus === APIChangesStatus.Pending,
  );
  if (foundChange) {
    return getStringOrObject(foundChange);
  }

  // field can have false value for checkboxes and it should be displayed
  return _.isNil(order[fieldName]) ? defaultValue : order[fieldName];
};

export const getRequestedChangesOrderFieldsDisplayValue = (
  fieldName: string,
  value: string,
  fieldsList: APIEntityHistory[],
  order: APIOrder | null,
) => {
  switch (fieldName) {
    case OrderFields.DeliveryRate: {
      const changedDeliveryRateTypeField = fieldsList.find(
        (item) => item.fieldName === OrderFields.DeliveryRateType,
      );
      const deliveryRateTypeValue =
        (changedDeliveryRateTypeField?.fieldValue ||
          order?.deliveryRateType) === APIDeliveryRateType.CyHour
          ? 'CY/Hour'
          : 'Min/Truck';
      return `${value} ${deliveryRateTypeValue}`;
    }
    case OrderFields.DeliveryRateType: {
      const deliveryRateTypeValue =
        value === APIDeliveryRateType.CyHour ? 'CY/Hour' : 'Min/Truck';
      return `${order?.deliveryRate} ${deliveryRateTypeValue}`;
    }
    default:
      return value;
  }
};

export const prepareUpdatedOrderData = (
  requestOrderData: Partial<APIOrder>,
  ignoreSubscription?: boolean,
) => {
  // updateOrderInState is called when we have an update from websocket event ON_ORDER_UPDATE_EVENT
  // isUserSubscribed in ON_ORDER_UPDATE_EVENT may contain wrong data for a contractor (which is correct for plants)
  // But order requests (on the contrary to websocket event ON_ORDER_UPDATE_EVENT) always return correct data
  // So we need to ignore isUserSubscribed from ON_ORDER_UPDATE_EVENT
  return ignoreSubscription
    ? _.omit(requestOrderData, ['isUserSubscribed'])
    : requestOrderData;
};

export const hasAddressNotFoundError = (error: APIServerError) => {
  return (
    error.payload.status === 404 &&
    error.payload.body?.Type === APIExceptionType.NotFoundException &&
    error.payload.body?.Title?.includes('Address was not found')
  );
};

export const HIDDEN_CY_PROGRESS_TICKET_STATUSES = [
  APITicketStatuses.Pending,
  APITicketStatuses.Draft,
  APITicketStatuses.Cancelled,
];

export const getOrderStatusLabel = (status: APIOrderStatus, t: TFunction) => {
  const statusesObject = {
    [APIOrderStatus.Requested]: t('order.status.requested'),
    [APIOrderStatus.Unconfirmed]: t('order.status.unconfirmed'),
    [APIOrderStatus.Confirmed]: t('order.status.confirmed'),
    [APIOrderStatus.Delivering]: t('order.status.delivering'),
    [APIOrderStatus.Completed]: t('order.status.completed'),
    [APIOrderStatus.Cancelled]: t('order.status.cancelled'),
    [APIOrderStatus.Declined]: t('order.status.declined'),
  };
  return statusesObject[status];
};
