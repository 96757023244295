import React from 'react';

import styled from 'styled-components';

import UndoIcon from '@mui/icons-material/Undo';

import { Message } from '@chatscope/chat-ui-kit-react';
import { IconButton } from 'components';

import { useTranslation } from 'third-party';

import {
  MessageInnerContent,
  MessageSendTime,
} from 'features/Chat/MessageBubbleStyles';

import { formatChatMessageDate } from 'utils/dialogs';

const StyledMessageSendTime = styled(MessageSendTime)``;

const StyledMessage = styled(Message)`
  overflow: visible;
  .cs-message__content,
  &.cs-message--incoming .cs-message__content {
    border: 1px dashed ${props => props.theme.custom.palette.muted600};
    background-color: #fff;
    ${StyledMessageSendTime} {
      color: ${props => props.theme.custom.palette.muted500};
    }
  }
`;

const StyledMessageInnerContent = styled(MessageInnerContent)`
  position: relative;
  background-color: #fff;
  color: ${props => props.theme.custom.palette.muted500};
`;

const StyledUndoIcon = styled(UndoIcon)`
  height: 1.5rem;
  width: 1.5rem;
`;

const UndoButton = styled(IconButton)`
  background-color: ${props => props.theme.custom.palette.muted50};
  position: absolute;
  top: 50%;
  margin-top: -1.2rem;
  padding: 0.5rem;
`;

type Props = {
  senderDisplayName: string;
  sendTime: string;
  providerName: string;
  isOwnMessage: boolean;
  onUndoClicked: () => void;
};

export const GhostMessageBubble: React.FC<Props> = ({
  senderDisplayName,
  sendTime,
  providerName,
  isOwnMessage,
  onUndoClicked,
}) => {
  const { t } = useTranslation();
  return (
    <StyledMessage
      model={{
        direction: isOwnMessage ? 'outgoing' : 'incoming',
        position: 'single',
      }}
    >
      {!isOwnMessage && (
        <Message.Header title={senderDisplayName} sender={senderDisplayName} />
      )}
      <Message.CustomContent>
        <StyledMessageInnerContent>
          <UndoButton
            onClick={onUndoClicked}
            style={isOwnMessage ? { left: '-5rem' } : { right: '-5rem' }}
          >
            <StyledUndoIcon />
          </UndoButton>
          <div>
            {t('chat.markAsRead.notice', {
              userName: senderDisplayName,
              providerName,
            })}
          </div>
          <StyledMessageSendTime>
            {formatChatMessageDate(sendTime)}
          </StyledMessageSendTime>
        </StyledMessageInnerContent>
      </Message.CustomContent>
    </StyledMessage>
  );
};
