import { useMemo } from 'react';

import { APIUserType } from '@cd3p/core/types/api';

import { useSelector } from 'third-party';

import { appSelectors } from 'selectors';

import { MapTypes } from 'components/TrackingMap/TrackingMap';

type MapOptionsProps = {
  defaultMapType?: MapTypes;
};

export const useMapOptions = ({ defaultMapType }: MapOptionsProps = {}) => {
  const userType = useSelector(appSelectors.userType);
  return useMemo(() => {
    return {
      defaultMapType:
        defaultMapType ||
        (userType === APIUserType.Dispatcher
          ? MapTypes.Roadmap
          : MapTypes.Hybrid),
    };
  }, [defaultMapType, userType]);
};
