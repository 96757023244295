import React from 'react';

import styled from 'styled-components';

import backgroundImage from 'images/background.png';

import { SvgIcon } from 'components/SvgIcon/SvgIcon';

const Wrapper = styled.div`
  background-image: url('${backgroundImage}'),
    linear-gradient(82deg, #0e424f 2.55%, #041417 93.08%);
  background-repeat: no-repeat, no-repeat;
  background-position: top right, center;
  background-size: contain, cover;
  flex-grow: 1;
  display: flex;
`;

const LeftPane = styled.div`
  display: flex;
  flex-direction: column;
  width: 72rem;
  max-width: 72rem;
  background-color: ${props => props.theme.custom.palette.backgroundTheme};
`;

const Logo = styled.div`
  padding: 3rem;
`;

type Props = {
  children?: React.ReactElement;
};

export const SplitView: React.FC<Props> = ({ children }) => {
  return (
    <Wrapper>
      <LeftPane>
        <Logo>
          <SvgIcon icon="logo" height="5.37rem" width="17.5rem" />
        </Logo>
        {children}
      </LeftPane>
    </Wrapper>
  );
};
