import React, { ForwardedRef } from 'react';

import styled from 'styled-components';

import { Typography } from '@mui/material';
import { useNavigate } from 'hooks/useNavigate';

const Link = styled(Typography)`
  cursor: pointer;
`;

type Props = {
  to: string;
  replace?: boolean;
  children: React.ReactNode;
  state?: any;
};

// we use wrapper instead of the original router `Link` component
// to pass all the navigation through our custom `useNavigate` hook
export const RouterLink = React.forwardRef(
  (props: Props, ref: ForwardedRef<HTMLDivElement>) => {
    const { to, replace, state } = props;
    const navigate = useNavigate();
    return (
      <Link
        as="div"
        ref={ref}
        {...props}
        onClick={() => navigate(to, { replace, state })}
      />
    );
  },
);
