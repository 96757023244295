import React, { useEffect, useMemo, useState } from 'react';

import WifiOffIcon from '@mui/icons-material/WifiOff';

import { ON_TRUCK_LOCATION_UPDATE_EVENT } from '@cd3p/core/constants/hubs';
import { APITrackingItem, APITruckStatuses } from '@cd3p/core/types/api';
import { getTruckDisplayName } from '@cd3p/core/utils/order';
import { LoadingButton } from '@mui/lab';
import { Container } from '@mui/material';
import {
  Button,
  FormField,
  Grid,
  MenuItem,
  MultiSelectDropdown,
  TrackingMap,
  TruckStatusLabel,
  Typography,
} from 'components';

import { _, styled, useForm, useSelector, useTranslation } from 'third-party';

import { useCache } from 'modules/cache';
import { useTracking } from 'modules/tracking';

import { cacheSelectors, trackingSelectors } from 'selectors';

import { useOrdersSockets } from 'utils/sockets';

import { LocationT } from 'types/app';

const ContainerWrapper = styled(Container)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 100%;
  padding: 0.5rem 0 0 0;
  margin: 0;
`;

const Header = styled(Typography)`
  color: ${props => props.theme.custom.palette.secondary500};
  margin-bottom: 0.3rem;
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2.3rem;
`;

const DriverEditWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-height: calc(100% - 20rem);
  overflow-y: auto;
`;

const FilterWrapper = styled(Grid)`
  padding: 1.5rem 2rem 0 2rem;
  overflow: hidden;
  height: 100%;
`;

const BoldTypography = styled.span`
  font-weight: 700;
`;

const TRUCK_STATUS = 'truckStatus';
const DELIVERY_LATITUDE = 'latitude';
const DELIVERY_LONGITUDE = 'longitude';
const PLANT_LATITUDE = 'plant_latitude';
const PLANT_LONGITUDE = 'plant_longitude';
const TRUCK_LATITUDE = 'truck_latitude';
const TRUCK_LONGITUDE = 'truck_longitude';
const PLANTS = 'plants';
const TRUCKS = 'trucks';

type FormProps = {
  [TRUCK_STATUS]: string;
  [DELIVERY_LATITUDE]: number;
  [DELIVERY_LONGITUDE]: number;
  [PLANT_LATITUDE]: number;
  [PLANT_LONGITUDE]: number;
  [TRUCK_LATITUDE]: number;
  [TRUCK_LONGITUDE]: number;
  [PLANTS]: string[] | null;
  [TRUCKS]: string[] | null;
};

const truckStatues = _(Object.keys(APITruckStatuses))
  .filter(it => isNaN(Number(it)))
  .map(it => ({ value: it as APITruckStatuses, label: it }))
  .value();

export const TrackerView = () => {
  const { loadPlants, updatePlant } = useCache();
  const [filteredPlants, setFilteredPlants] = useState<LocationT[]>([]);
  const [selectedPlant, setSelectedPlant] = useState<LocationT | null>(null);
  const [filteredTrucks, setFilteredTrucks] = useState<APITrackingItem[]>([]);
  const [selectedTruck, setSelectedTruck] = useState<APITrackingItem | null>(
    null,
  );

  const { t } = useTranslation();
  const plants = useSelector(cacheSelectors.plants);
  const loadPlantsPending = useSelector(cacheSelectors.loadPlantsPending);
  const trackingItemsValues = useSelector(
    trackingSelectors.trackingItemsValues,
  );
  const updateTruckLocationPending = useSelector(
    trackingSelectors.updateTruckLocationPending,
  );

  const { loadTracking, updateTruckLocation } = useTracking();

  const loadTrackingPending = useSelector(
    trackingSelectors.loadTrackingPending,
  );

  useEffect(() => {
    loadPlants();
    loadTracking();
  }, [loadPlants, loadTracking]);

  const onTruckClick = (truck: APITrackingItem) => {
    setValue(TRUCK_STATUS, truck.truckStatus);

    setValue(DELIVERY_LATITUDE, truck?.deliveryLocation?.latitude);
    setValue(DELIVERY_LONGITUDE, truck.deliveryLocation?.longitude);

    setValue(TRUCK_LATITUDE, truck?.currentLocation?.latitude);
    setValue(TRUCK_LONGITUDE, truck?.currentLocation?.longitude);

    setSelectedPlant(null);
    setSelectedTruck(truck);
  };

  const onPlantClick = (selected: LocationT) => {
    setValue(PLANT_LATITUDE, selected?.latitude);
    setValue(PLANT_LONGITUDE, selected.longitude);

    setSelectedTruck(null);
    setSelectedPlant(selected);
  };

  const onCloseEditClick = () => {
    setSelectedTruck(null);
    setSelectedPlant(null);
    reset();
  };

  const sites = useMemo(
    () =>
      filteredTrucks
        .filter(it => !!it.deliveryLocation)
        .map((it, idx) => ({
          name: `Site ${idx + 1}: ${it.projectName}`,
          address: `Lat: ${it.deliveryLocation?.latitude}, lng: ${it.deliveryLocation?.longitude}`,
          latitude: it.deliveryLocation?.latitude,
          longitude: it.deliveryLocation?.longitude,
        })),
    [filteredTrucks],
  );

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm<FormProps>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      [TRUCK_STATUS]: '',
      [DELIVERY_LATITUDE]: 0,
      [DELIVERY_LONGITUDE]: 0,
      [PLANT_LATITUDE]: 0,
      [PLANT_LONGITUDE]: 0,
      [TRUCK_LATITUDE]: 0,
      [TRUCK_LONGITUDE]: 0,
      [PLANTS]: [],
      [TRUCKS]: [],
    },
  });

  const watchedPlantsField = watch(PLANTS);
  const watchedTrucksField = watch(TRUCKS);

  useEffect(() => {
    setFilteredPlants(
      _.uniqBy(
        plants.filter(it => (watchedPlantsField || []).indexOf(it.id) > -1),
        'id',
      ),
    );
  }, [watchedPlantsField, plants]);

  useEffect(() => {
    setFilteredTrucks(
      trackingItemsValues?.filter(
        it => (watchedTrucksField || []).indexOf(it.truckNumber) > -1,
      ),
    );
  }, [watchedTrucksField, trackingItemsValues]);

  const onSubmit = async (formData: FormProps) => {
    if (selectedTruck) {
      const truckLocation = {
        ...selectedTruck,
        deliveryLocation: {
          latitude: formData[DELIVERY_LATITUDE],
          longitude: formData[DELIVERY_LONGITUDE],
        },
        currentLocation: {
          latitude: formData[TRUCK_LATITUDE],
          longitude: formData[TRUCK_LONGITUDE],
        },
        truckStatus: formData[TRUCK_STATUS] as APITruckStatuses,
      };
      await updateTruckLocation(truckLocation.truckId || '', truckLocation);
      setFilteredTrucks(
        filteredTrucks.map(it =>
          it.truckId === truckLocation.truckId ? truckLocation : it,
        ),
      );
      return;
    }

    if (selectedPlant && selectedPlant.id) {
      const plant = {
        ...selectedPlant,
        name: selectedPlant.name || '',
        address: selectedPlant.address || '',
        id: String(selectedPlant.id),
        latitude: Number(formData[PLANT_LATITUDE]),
        longitude: Number(formData[PLANT_LONGITUDE]),
      };

      updatePlant(plant.id, plant);
      setFilteredPlants(
        _.uniqBy(
          filteredPlants.map(it => (it.id == plant.id ? plant : it)),
          'id',
        ),
      );
      return;
    }
  };

  const selectOptions = truckStatues.map(option => (
    <MenuItem key={option.value} value={option.value}>
      <TruckStatusLabel value={option.value} />
    </MenuItem>
  ));

  const [trucksLocations, setTrucksLocations] = useState<APITrackingItem[]>([]);
  const truckIds = useMemo(
    () => filteredTrucks.map(it => it.truckNumber),
    [filteredTrucks],
  );

  useEffect(() => {
    setTrucksLocations(filteredTrucks);
  }, [filteredTrucks]);

  const onTruckPositionUpdate = (
    providerId: string,
    truckNumber: string,
    result: APITrackingItem,
  ) => {
    setFilteredTrucks(its =>
      its.map(it => (it.truckNumber == result.truckNumber ? result : it)),
    );
  };

  useOrdersSockets(
    () => [
      {
        method: ON_TRUCK_LOCATION_UPDATE_EVENT,
        action: (
          providerId: string,
          truckId: string,
          result: APITrackingItem,
        ) => {
          if (truckIds.length && !truckIds.includes(truckId)) {
            return;
          }

          if (result.truckId == selectedTruck?.truckId) {
            setSelectedTruck(result);
          }

          onTruckPositionUpdate?.(providerId, truckId, result);

          setTrucksLocations(its =>
            its.map(it =>
              it.truckId == truckId
                ? {
                    ...it,
                    ...result,
                  }
                : it,
            ),
          );
        },
      },
    ],
    [selectedTruck?.truckId, truckIds],
  );

  const selectedTruckName = useMemo(() => {
    return selectedTruck
      ? getTruckDisplayName(
          selectedTruck.truckNumber,
          selectedTruck.driverFirstName,
          selectedTruck.driverLastName,
        )
      : null;
  }, [selectedTruck]);

  return (
    <ContainerWrapper>
      <Grid container height="100%">
        <Grid item xs={9}>
          <TrackingMap
            plants={filteredPlants}
            sites={sites}
            defaultZoom={14}
            onTruckClick={onTruckClick}
            onPlantClick={onPlantClick}
            trucks={trucksLocations}
            isLoading={loadTrackingPending || loadPlantsPending}
          />
        </Grid>
        <FilterWrapper item xs={3}>
          <form style={{ marginBottom: '2rem' }}>
            <Header variant="h5" style={{ marginBottom: '1rem' }}>
              {t('trackingMap.filters')}
            </Header>
            <div style={{ marginBottom: '1.5rem' }}>
              <MultiSelectDropdown
                fieldName={PLANTS}
                caption={t('trackingMap.plants')}
                options={plants.map(it => ({
                  key: it.id || '',
                  value: it.name || '',
                }))}
                control={control}
              />
            </div>
            <div style={{ marginBottom: '1.5rem' }}>
              <MultiSelectDropdown
                fieldName={TRUCKS}
                caption={t('trackingMap.trucks')}
                options={trackingItemsValues.map(it => ({
                  key: it.truckNumber,
                  value: getTruckDisplayName(
                    it.truckNumber,
                    it.driverFirstName,
                    it.driverLastName,
                  ),
                }))}
                control={control}
              />
            </div>
          </form>

          <DriverEditWrapper>
            <div>
              {!selectedTruck &&
                !selectedPlant &&
                filteredTrucks.map((it, idx) => (
                  <div key={it.truckNumber} style={{ marginBottom: '2rem' }}>
                    <Header
                      variant="h5"
                      style={{ cursor: 'pointer' }}
                      onClick={() => onTruckClick(it)}
                    >
                      {idx + 1}.{' '}
                      {!it.isOnline && (
                        <WifiOffIcon
                          color="disabled"
                          sx={{ fontSize: '1.6rem', marginRight: '0.5rem' }}
                        />
                      )}
                      {getTruckDisplayName(
                        it.truckNumber,
                        it.driverFirstName,
                        it.driverLastName,
                      )}
                    </Header>
                    <TruckStatusLabel value={it.truckStatus} />
                    <Typography>
                      <BoldTypography>
                        {t('trackingMap.latitude')}:{' '}
                      </BoldTypography>
                      {it.currentLocation?.latitude}{' '}
                      <BoldTypography>
                        {t('trackingMap.longitude')}:{' '}
                      </BoldTypography>
                      {it.currentLocation?.longitude}
                    </Typography>
                    <Typography>
                      <BoldTypography>
                        {t('trackingMap.lastSync')}:{' '}
                      </BoldTypography>
                      {it.lastUpdate}
                    </Typography>
                    {it.timeOfArrival && (
                      <>
                        <Typography>
                          <BoldTypography>
                            {t('trackingMap.Distance')}:{' '}
                          </BoldTypography>
                          {it.timeOfArrival.distance}
                        </Typography>
                        <Typography>
                          <BoldTypography>
                            {t('trackingMap.Duration')}:{' '}
                          </BoldTypography>
                          {it.timeOfArrival.duration}
                        </Typography>
                        <Typography>
                          <BoldTypography>
                            {t('trackingMap.lastSync')}:{' '}
                          </BoldTypography>
                          {it.timeOfArrival.lastUpdated}
                        </Typography>
                      </>
                    )}
                  </div>
                ))}
            </div>

            {(selectedTruck || selectedPlant) && (
              <form onSubmit={handleSubmit(onSubmit)}>
                {selectedTruck && (
                  <div style={{ marginTop: '2rem' }}>
                    <Header variant="h5">
                      {t('trackingMap.truckInfo')}: {selectedTruckName}
                    </Header>
                    <FormField
                      key={TRUCK_STATUS}
                      fieldName={TRUCK_STATUS}
                      fieldError={errors[TRUCK_STATUS]}
                      label={t('trackingMap.truckStatus')}
                      control={control}
                      selectOptions={selectOptions}
                      maxLength={300}
                    />
                    <FormField
                      key={DELIVERY_LATITUDE}
                      fieldName={DELIVERY_LATITUDE}
                      label={t('trackingMap.deliveryLatitude')}
                      fieldError={errors[DELIVERY_LATITUDE]}
                      requiredErrorMessage={t('trackingMap.fieldIsRequired')}
                      control={control}
                    />
                    <FormField
                      key={DELIVERY_LONGITUDE}
                      fieldName={DELIVERY_LONGITUDE}
                      label={t('trackingMap.deliveryLongitude')}
                      fieldError={errors[DELIVERY_LONGITUDE]}
                      requiredErrorMessage={t('trackingMap.fieldIsRequired')}
                      control={control}
                    />
                    <FormField
                      key={TRUCK_LATITUDE}
                      fieldName={TRUCK_LATITUDE}
                      label={t('trackingMap.truckLatitude')}
                      fieldError={errors[TRUCK_LATITUDE]}
                      requiredErrorMessage={t('trackingMap.fieldIsRequired')}
                      control={control}
                    />
                    <FormField
                      key={TRUCK_LONGITUDE}
                      fieldName={TRUCK_LONGITUDE}
                      label={t('trackingMap.truckLongitude')}
                      fieldError={errors[TRUCK_LONGITUDE]}
                      requiredErrorMessage={t('trackingMap.fieldIsRequired')}
                      control={control}
                    />
                  </div>
                )}

                {selectedPlant && (
                  <>
                    <Header variant="h5">{t('trackingMap.plantInfo')}</Header>
                    <FormField
                      key={PLANT_LATITUDE}
                      fieldName={PLANT_LATITUDE}
                      label={t('trackingMap.plantLatitude')}
                      requiredErrorMessage={t('trackingMap.fieldIsRequired')}
                      control={control}
                      isRequired
                    />
                    <FormField
                      key={PLANT_LONGITUDE}
                      fieldName={PLANT_LONGITUDE}
                      label={t('trackingMap.plantLongitude')}
                      requiredErrorMessage={t('trackingMap.fieldIsRequired')}
                      control={control}
                      isRequired
                    />
                  </>
                )}

                <ActionButtons>
                  <LoadingButton
                    variant="outlined"
                    color="secondary"
                    type="submit"
                    loading={updateTruckLocationPending}
                  >
                    {t('trackingMap.updateForm')}
                  </LoadingButton>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={onCloseEditClick}
                  >
                    {t('trackingMap.closeForm')}
                  </Button>
                </ActionButtons>
              </form>
            )}
          </DriverEditWrapper>
        </FilterWrapper>
      </Grid>
    </ContainerWrapper>
  );
};
