import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import styled, { css, useTheme } from 'styled-components';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import WarningIcon from '@mui/icons-material/Warning';

import {
  APIFullTextSearchFilter,
  APIOrder,
  APIOrderRequest,
  APIOrderStatus,
  APISortOrder,
  APIUserType,
} from '@cd3p/core/types/api';
import { getSortOrder } from '@cd3p/core/utils/common';
import { tooltipClasses } from '@mui/material';
import { TooltipProps } from '@mui/material/Tooltip/Tooltip';
import { styled as muiStyled } from '@mui/material/styles';
import {
  BatchedDeliveredValue,
  Box,
  ChatIcon,
  ConfirmationDialog,
  DispatcherCanAccess,
  IconButton,
  Menu,
  MenuItem,
  OrderStatusLabel,
  Popover,
  Stack,
  SvgIcon,
  Table,
  TableCell,
  TableRow,
  Tooltip,
  Trans,
  Typography,
} from 'components';
import { SubscribeButton } from 'features';
import { useDialogRead } from 'hooks/useDialogRead';
import { UseTableSettingsT } from 'hooks/useTableSettings';
import i18n from 'i18n';
import { createPortal } from 'react-dom';

import {
  _,
  dayjs,
  useNavigate,
  useSelector,
  useTranslation,
} from 'third-party';

import {
  AppPortals,
  OrdersTableColumnIds,
  TOOLTIP_APPEAR_DELAY,
} from 'constants/common';
import { orderChatUrl, orderUrl } from 'constants/url';

import { OrdersListColumnId, OrdersListState } from 'modules/common/ordersList';
import { useNotifications } from 'modules/notifications';

import { appSelectors } from 'selectors';

import { SectionOptions, TableProps } from 'components/Table/Table';

import { AddTicketView } from 'features/OrderView/AddTicketView';
import { TableSettings } from 'features/OrdersTable/TableSettings';

import {
  canAddNewTickets,
  formatTime,
  isCancellationSubmittedOrder,
  isOrderReadyToComplete,
  orderStatusesMapping,
  useCloseOrder,
} from 'utils/order';
import { getHighlightedContent } from 'utils/ordersList';

import { TableSettingsT } from 'types/app';

const StyledChatIcon = styled(ChatIcon)`
  margin: 0;
`;

const ChatButton = styled(IconButton)`
  margin-left: 0.2rem;
  padding: 1rem;
`;

const TableWrapper = styled.div`
  flex-grow: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
`;

const StyledTableBodyCell = styled(TableCell)<{
  height: string;
  cellstyles: string;
}>`
  position: relative;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 0;
  border-bottom: 1px solid ${props => props.theme.custom.palette.truegray300};
  height: ${props => props.height};
  padding: 0 1.6rem;
  &:not(:first-child):before {
    position: absolute;
    top: 1rem;
    bottom: 0;
    left: 0;
    border-left: 1px solid ${props => props.theme.custom.palette.truegray300};
    width: 1px;
    content: '';
  }
  ${props => props.cellstyles};
`;

const StyledTableBodyRowDispatcher = styled(TableRow)<{
  item: APIOrder;
  rowhighlightcolor?: string;
}>`
  cursor: pointer;
  background-color: ${props => props.rowhighlightcolor || '#fff'};
  &:hover {
    background-color: ${props =>
      isOrderReadyToComplete(props.item)
        ? props.theme.custom.palette.warning50
        : props.theme.custom.palette.primary50};
  }
`;

const StyledTableBodyRowContractor = styled(TableRow)<{
  item: APIOrder;
  rowhighlightcolor?: string;
}>`
  cursor: pointer;
  background-color: ${props => props.rowhighlightcolor || '#fff'};
  &:hover {
    background-color: ${props => props.theme.custom.palette.primary50};
  }
`;

const TableCellText = styled(Typography)`
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 1.5;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: ${props => props.theme.custom.palette.gray};
`;

const StatusWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
  max-width: 100%;
  gap: 1.1rem;
  > p {
    flex: 0;
    line-height: 1.1;
  }
`;

const MenuCellStyles = css`
  padding: 0 0.8rem;
`;

const StyledWarningIcon = styled(WarningIcon)`
  color: ${props => props.theme.custom.palette.error600};
`;

const getMenuWrapperId = (orderId: number) => `menu-wrapper-${orderId}`;

const WarningTooltip = muiStyled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.custom.palette.warning600,
    color: 'white',
    fontSize: '1.4rem',
    padding: '0.676rem 0.907rem 0.824rem 1.343rem',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.custom.palette.warning600,
  },
}));

export interface OrdersTableColumn {
  id: string;
  dataId?: OrdersListColumnId;
  label?: string;
  width?: number | string;
  align?: 'right' | 'left';
  hidden?: boolean;
  canBeHidden?: boolean;
  fixed?: boolean;
  resizable?: boolean;
  defaultHidden?: boolean;
  formatter?: (
    value: any,
    order: APIOrder,
  ) => {
    value: TooltipProps['title'];
    element?: TooltipProps['children'];
  };
}

const orderTableComponentsDispatcher = {
  TableBodyRow: StyledTableBodyRowDispatcher,
  TableBodyCell: StyledTableBodyCell,
};

const orderTableComponentsContractor = {
  TableBodyRow: StyledTableBodyRowContractor,
  TableBodyCell: StyledTableBodyCell,
};

type Props = {
  tableSettings?: UseTableSettingsT;
  viewLabel: string;
  className?: string;
  updateSorting: (newSorting: OrdersListState['sorting']) => void;
  updateOrderInList: (
    id: number,
    body: Partial<APIOrderRequest>,
  ) => Promise<{ error?: boolean }>;
  updateOrderPosition: (orderId: number) => void;
  sortField: OrdersListColumnId;
  sortOrder: APISortOrder;
  search?: APIFullTextSearchFilter;
  orders: APIOrder[];
  ordersLoaded: boolean;
  ordersCount: number;
  isFiltersApplied?: boolean;
  loadItemsPending: boolean;
  loadMoreItemsPending: boolean;
  loadMoreOrders: () => void;
  rowHeight?: string;
  headerHeight?: string;
  emptyText?: string;
  columnProps?: OrdersTableColumn[];
  components: TableProps<APIOrder>['components'];
  orderItemUrl?: (orderId: number) => string;
  isResizable?: boolean;
  hiddenColumns?: TableSettingsT['hiddenColumns'];
  sectionOptions?: SectionOptions<APIOrder>;
  saveScrollPosition?: (scrollPosition: number) => void;
  scrollPosition?: number;
};

export const OrdersTable: React.FC<Props> = ({
  tableSettings,
  viewLabel,
  className,
  updateSorting,
  updateOrderPosition,
  sortField,
  sortOrder,
  search,
  orders,
  ordersLoaded,
  ordersCount,
  isFiltersApplied,
  loadItemsPending,
  loadMoreItemsPending,
  loadMoreOrders,
  rowHeight = '5.7rem',
  headerHeight,
  components,
  columnProps,
  emptyText: emptyTextProp,
  orderItemUrl,
  hiddenColumns,
  isResizable = false,
  sectionOptions,
  saveScrollPosition,
  scrollPosition,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const { addNotification } = useNotifications();

  const currentUserType = useSelector(appSelectors.userType);

  const isBatchSoftwareIntegrationEnabled = useSelector(
    appSelectors.isBatchSoftwareIntegrationEnabled,
  );

  const [domReady, setDomReady] = useState(false);

  const [closeOrderTooltip, setCloseOrderTooltip] = useState<null | number>(
    null,
  );

  const [
    cancellationRequestedOrderTooltip,
    setCancellationRequestedOrderTooltip,
  ] = useState<null | number>(null);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openedMenuOrder, setOpenedMenuOrder] = React.useState<null | APIOrder>(
    null,
  );
  const [orderForAddTicketPopup, setOrderForAddTicketPopup] =
    React.useState<null | APIOrder>(null);

  const [showAddTicketCloseConfirmation, setShowAddTicketCloseConfirmation] =
    useState(false);

  const closeOrder = useCloseOrder();

  useEffect(() => {
    setDomReady(true);
  }, []);

  const tableComponents = useMemo(() => {
    return {
      ...(currentUserType === APIUserType.Dispatcher
        ? orderTableComponentsDispatcher
        : orderTableComponentsContractor),
      ...components,
    };
  }, [currentUserType, components]);

  const onRowMenuClicked = useCallback(
    (event: React.MouseEvent<HTMLElement>, order: APIOrder) => {
      setOpenedMenuOrder(order);
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  const onRowMenuClose = useCallback(() => {
    setOpenedMenuOrder(null);
    setAnchorEl(null);
  }, []);

  const open = Boolean(anchorEl);

  const onColumnHeaderClicked = useCallback(
    (columnId: OrdersListColumnId) => {
      updateSorting([
        {
          sortField: columnId,
          sortOrder: getSortOrder(columnId, sortField, sortOrder),
        },
      ]);
    },
    [sortField, sortOrder, updateSorting],
  );

  const onRowMouseEnter = (order: APIOrder) => {
    isOrderReadyToComplete(order) && setCloseOrderTooltip(order.id);
    isCancellationSubmittedOrder(order) &&
      setCancellationRequestedOrderTooltip(order.id);
  };

  const onRowMouseLeave = () => {
    setCloseOrderTooltip(null);
    setCancellationRequestedOrderTooltip(null);
  };

  const onCloseOrderClicked = useCallback(async () => {
    onRowMenuClose();
    if (await closeOrder(openedMenuOrder!.id)) {
      updateOrderPosition(openedMenuOrder!.id);
    }
  }, [onRowMenuClose, closeOrder, openedMenuOrder, updateOrderPosition]);

  const getOrderItemUrl = useCallback(
    (orderId: number) => {
      return orderItemUrl ? orderItemUrl(orderId) : orderUrl(orderId);
    },
    [orderItemUrl],
  );
  const isDialogRead = useDialogRead();

  const columns = useMemo<OrdersTableColumn[]>(() => {
    return [
      {
        id: OrdersTableColumnIds.DeliveryDate,
        dataId: 'deliveryDate' as OrdersListColumnId,
        label: i18n.t('ordersList.table.dateColumnLabel'),
        minWidth: 100,
        canBeHidden: true,
        resizable: isResizable,
        formatter: (
          value: APIOrder['deliveryDate'],
          order: APIOrder,
          highlights?: string[] | null,
        ) => ({
          value: dayjs(value).format('MM/DD/YY'),
          element: (
            <TableCellText>
              {getHighlightedContent(
                dayjs(value).format('MM/DD/YY'),
                highlights,
              )}
            </TableCellText>
          ),
        }),
      },
      {
        id: OrdersTableColumnIds.DeliveryTime,
        dataId: 'deliveryTime' as OrdersListColumnId,
        label: i18n.t('ordersList.table.timeColumnLabel'),
        minWidth: 80,
        canBeHidden: true,
        resizable: isResizable,
        sortable: false,
        formatter: (
          value: APIOrder['deliveryTime'],
          order: APIOrder,
          highlights?: string[] | null,
        ) => ({
          value: formatTime(value),
          element: (
            <TableCellText style={{ textTransform: 'lowercase' }}>
              {getHighlightedContent(formatTime(value), highlights)}
            </TableCellText>
          ),
        }),
      },
      ...(currentUserType === APIUserType.Dispatcher &&
      isBatchSoftwareIntegrationEnabled
        ? [
            {
              id: OrdersTableColumnIds.OrderNumber,
              dataId: 'orderNumber' as OrdersListColumnId,
              label: i18n.t('ordersList.table.numberColumnLabel'),
              minWidth: 80,
              canBeHidden: true,
              resizable: isResizable,
              formatter: (
                value: APIOrder['orderNumber'],
                order: APIOrder,
                highlights?: string[] | null,
              ) => ({
                value: formatTime(value),
                element: (
                  <TableCellText>
                    {getHighlightedContent(value || '-', highlights)}
                  </TableCellText>
                ),
              }),
            },
          ]
        : []),
      {
        id: OrdersTableColumnIds.OrderName,
        dataId: 'orderName' as OrdersListColumnId,
        label: i18n.t('ordersList.table.orderNameLabel'),
        minWidth: 200,
        sortable: false,
        resizable: isResizable,
        formatter: (
          value: APIOrder['orderName'],
          order: APIOrder,
          highlights?: string[] | null,
        ) => {
          return {
            value: order.orderName || '',
            element: (
              <Stack direction="row" alignItems="center">
                <TableCellText>
                  {getHighlightedContent(order.orderName, highlights)}
                </TableCellText>
                {isDialogRead(order.dialog) === false && (
                  <ChatButton
                    onClick={event => {
                      event.stopPropagation();
                      navigate(orderChatUrl(order.id));
                    }}
                  >
                    <StyledChatIcon hasMessages />
                  </ChatButton>
                )}
              </Stack>
            ),
          };
        },
      },
      {
        id: OrdersTableColumnIds.Project,
        dataId: 'project.name' as OrdersListColumnId,
        label: i18n.t('ordersList.table.projectColumnLabel'),
        minWidth: 200,
        resizable: isResizable,
        canBeHidden: true,
        formatter: (
          projectName: string,
          order: APIOrder,
          highlights?: string[] | null,
        ) => ({
          value: projectName || '',
          element: (
            <TableCellText>
              {getHighlightedContent(projectName, highlights)}
            </TableCellText>
          ),
        }),
      },
      {
        id: OrdersTableColumnIds.ProjectAddress,
        dataId: 'project.address' as OrdersListColumnId,
        label: i18n.t('ordersList.table.projectAddressColumnLabel'),
        minWidth: 200,
        resizable: isResizable,
        canBeHidden: true,
        sortable: false,
        formatter: (
          projectAddress: string,
          order: APIOrder,
          highlights?: string[] | null,
        ) => ({
          value: projectAddress || '',
          element: (
            <TableCellText>
              {getHighlightedContent(projectAddress, highlights)}
            </TableCellText>
          ),
        }),
      },
      {
        id: OrdersTableColumnIds.DeliveryLocation,
        dataId: 'deliveryLocation' as OrdersListColumnId,
        label: i18n.t('ordersList.table.deliveryLocationColumnLabel'),
        minWidth: 200,
        resizable: isResizable,
        canBeHidden: true,
        sortable: false,
        formatter: (
          deliveryLocation: string,
          order: APIOrder,
          highlights?: string[] | null,
        ) => ({
          value: deliveryLocation || '',
          element: (
            <TableCellText>
              {getHighlightedContent(deliveryLocation, highlights)}
            </TableCellText>
          ),
        }),
      },
      ...(currentUserType === APIUserType.Dispatcher
        ? [
            {
              id: OrdersTableColumnIds.Company,
              dataId: 'company.name' as OrdersListColumnId,
              label: i18n.t('ordersList.table.customerColumnLabel'),
              minWidth: 200,
              resizable: isResizable,
              canBeHidden: true,
              formatter: (
                companyName: string,
                order: APIOrder,
                highlights?: string[] | null,
              ) => ({
                value: companyName || '',
                element: (
                  <TableCellText>
                    {getHighlightedContent(companyName, highlights)}
                  </TableCellText>
                ),
              }),
            },
          ]
        : []),
      {
        id: OrdersTableColumnIds.Volume,
        dataId: 'volume' as OrdersListColumnId,
        label: i18n.t('ordersList.table.volumeColumnLabel'),
        minWidth: 240,
        canBeHidden: true,
        resizable: isResizable,
        formatter: (
          value: APIOrder['volume'],
          order: APIOrder,
          highlights?: string[] | null,
        ) => ({
          value: '',
          element: (
            <BatchedDeliveredValue
              showVolumeDiff={order?.orderStatus !== APIOrderStatus.Unconfirmed}
              volume={order?.volume}
              volumeDisplayValue={getHighlightedContent(
                order?.volume.toString(),
                highlights,
              )}
              originalVolume={order?.originalVolume}
              batched={order?.batched}
              delivered={order?.delivered}
            />
          ),
        }),
      },
      {
        id: OrdersTableColumnIds.OrderStatus,
        dataId: 'orderStatus' as OrdersListColumnId,
        highlightKey: 'orderStatus',
        label: i18n.t('ordersList.table.statusColumnLabel'),
        minWidth: 200,
        canBeHidden: true,
        resizable: isResizable,
        formatter: (
          value: APIOrder['orderStatus'],
          order: APIOrder,
          highlights?: string[] | null,
        ) => ({
          value: orderStatusesMapping[value as APIOrderStatus]?.name || '',
          element: (
            <StatusWrapper>
              <DispatcherCanAccess>
                {isOrderReadyToComplete(order) && (
                  <SvgIcon
                    height="2.4rem"
                    icon="ready-to-complete"
                    width="1.5rem"
                    color={theme.custom.palette.warning600}
                  />
                )}
              </DispatcherCanAccess>
              <OrderStatusLabel
                deliveryRate={order.deliveryRate}
                actualDeliveryRate={order.actualDeliveryRate}
                status={value as APIOrder['orderStatus']}
                isHighlighted={!!highlights}
                isChangesRequested={order?.isChangesRequested}
              />
              {cancellationRequestedOrderTooltip === order.id}
              {order.isCancellationSubmitted && (
                <WarningTooltip
                  arrow
                  open={cancellationRequestedOrderTooltip === order.id}
                  placement="bottom"
                  title={t('order.cancellationSubmitted')}
                >
                  <StyledWarningIcon />
                </WarningTooltip>
              )}
            </StatusWrapper>
          ),
        }),
      },
      ...(currentUserType === APIUserType.Contractor
        ? [
            {
              id: OrdersTableColumnIds.Subscribe,
              width: 56, // px
              cellStyles: MenuCellStyles,
              fixed: true,
              formatter: (value: string, order: APIOrder) => {
                return {
                  value: '',
                  element: <SubscribeButton order={order} />,
                };
              },
            },
          ]
        : []),
      ...(currentUserType === APIUserType.Dispatcher
        ? [
            {
              id: OrdersTableColumnIds.Menu,
              width: 56, // px
              cellStyles: MenuCellStyles,
              fixed: true,
              formatter: (value: string, order: APIOrder) => {
                return {
                  value: '',
                  element: (
                    <Box id={getMenuWrapperId(order.id)}>
                      {isOrderReadyToComplete(order) && (
                        <WarningTooltip
                          arrow
                          open={closeOrderTooltip === order.id}
                          placement="bottom-end"
                          title={
                            <Trans i18nKey="ordersList.table.readyToCloseOrderTooltip">
                              This order is ready
                              <br />
                              to be closed.
                            </Trans>
                          }
                        >
                          <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-controls={
                              open ? `long-menu-for-${order.id}` : undefined
                            }
                            aria-expanded={open ? 'true' : undefined}
                            aria-haspopup="true"
                            onClick={event => {
                              event.stopPropagation();
                              onRowMenuClicked(event, order);
                            }}
                          >
                            <MoreVertIcon color="primary" />
                          </IconButton>
                        </WarningTooltip>
                      )}
                      {!isOrderReadyToComplete(order) &&
                        canAddNewTickets(order) && (
                          <Tooltip
                            placement="top-start"
                            enterDelay={TOOLTIP_APPEAR_DELAY}
                            enterNextDelay={TOOLTIP_APPEAR_DELAY}
                            title={t('ordersList.table.addTicketButton')}
                          >
                            <IconButton
                              onClick={event => {
                                event.stopPropagation();
                                setOrderForAddTicketPopup(order);
                              }}
                            >
                              <NoteAddOutlinedIcon color="primary" />
                            </IconButton>
                          </Tooltip>
                        )}
                    </Box>
                  ),
                };
              },
            },
          ]
        : []),
    ].map(column => {
      const additionalProps = _.find(columnProps, { id: column.id });
      return additionalProps ? { ...column, ...additionalProps } : column;
    });
  }, [
    isBatchSoftwareIntegrationEnabled,
    isResizable,
    currentUserType,
    isDialogRead,
    navigate,
    theme.custom.palette.warning600,
    cancellationRequestedOrderTooltip,
    t,
    closeOrderTooltip,
    open,
    onRowMenuClicked,
    columnProps,
  ]);

  const emptyText = useMemo(() => {
    if (emptyTextProp) {
      return emptyTextProp;
    } else if (!loadItemsPending) {
      if (search) {
        return t('ordersList.table.searchEmptyStateText');
      } else if (isFiltersApplied) {
        return t('ordersList.table.filterEmptyStateText');
      } else {
        return t('ordersList.table.emptyStateText');
      }
    }
  }, [emptyTextProp, isFiltersApplied, loadItemsPending, search, t]);

  const onCloseAddTicketPopover = useCallback((isDirty: boolean) => {
    if (isDirty) {
      setShowAddTicketCloseConfirmation(true);
    } else {
      setOrderForAddTicketPopup(null);
    }
  }, []);

  const onSubmitTicket = useCallback(
    (order: APIOrder) => {
      setOrderForAddTicketPopup(null);
      addNotification({
        message: (
          <Trans i18nKey="ordersList.table.onSubmitTicket">
            Ticket has been successfully created for order{' '}
            {{ orderName: order?.orderName }}.
          </Trans>
        ),
      });
    },
    [addNotification],
  );

  const onSaveDraftTicket = useCallback(
    (order: APIOrder) => {
      setOrderForAddTicketPopup(null);
      addNotification({
        message: (
          <Trans i18nKey="ordersList.table.onSubmitTicket">
            Draft ticket has been successfully created for order{' '}
            {{ orderName: order?.orderName }}.
          </Trans>
        ),
      });
    },
    [addNotification],
  );

  const rowHighlightColor = useCallback(
    (item: APIOrder) => {
      if (item.id === orderForAddTicketPopup?.id) {
        return theme.custom.palette.rowHighlight;
      }
      if (
        item.isCancellationSubmitted ||
        (currentUserType === APIUserType.Contractor &&
          item.orderStatus === APIOrderStatus.Declined)
      ) {
        return theme.custom.palette.rowCancellationSubmittedHighlight;
      }
      // DO NOT CONFUSE: item.isReadByDispatcher and item.dialog.isReadByDispatcher are not the same!
      // item.isReadByDispatcher - this is an unread order with changes
      // item.dialog.isReadByDispatcher - unread order chat
      if (!item.isReadByDispatcher) {
        return theme.custom.palette.rowUnreadHighlight;
      }
    },
    [
      orderForAddTicketPopup?.id,
      currentUserType,
      theme.custom.palette.rowHighlight,
      theme.custom.palette.rowCancellationSubmittedHighlight,
      theme.custom.palette.rowUnreadHighlight,
    ],
  );

  const portalTargetRef = useRef<null | HTMLElement>(null);

  const getTableSettingsPortalTarget = () => {
    if (portalTargetRef.current) {
      return portalTargetRef.current;
    } else {
      const portalEl = document.getElementById(
        AppPortals.OrderTableColumnVisibility,
      );
      if (portalEl) {
        return (portalTargetRef.current = portalEl);
      } else {
        return null;
      }
    }
  };

  const onTableRowClick = (order: APIOrder) => {
    navigate(getOrderItemUrl(order.id), {
      state: { backUrl: location.pathname, backUrlLabel: viewLabel },
    });
  };

  return (
    <TableWrapper className={className}>
      <Table
        {...tableSettings}
        hiddenColumns={hiddenColumns}
        infiniteScroll
        infiniteScrollOffset={500}
        tableMinHeight="10rem"
        columns={columns}
        items={orders}
        sortField={sortField}
        sortOrder={sortOrder}
        onRowClick={onTableRowClick}
        onColumnHeaderClicked={onColumnHeaderClicked}
        itemsTotalCount={ordersCount}
        itemsLoaded={ordersLoaded}
        loadItemsPending={loadItemsPending}
        rowHighlightColor={rowHighlightColor}
        loadMoreItemsPending={loadMoreItemsPending}
        onLoadMoreClicked={loadMoreOrders}
        onBodyRowMouseEnter={onRowMouseEnter}
        onBodyRowMouseLeave={onRowMouseLeave}
        headerRowHeight={headerHeight || rowHeight}
        bodyRowHeight={rowHeight}
        emptyText={emptyText}
        loadMoreButtonText={t('ordersList.table.loadMoreButtonText')}
        components={tableComponents}
        sectionOptions={sectionOptions}
        highlightSearchResults={!!search}
        onScrollEnd={saveScrollPosition}
        scrollPosition={scrollPosition}
      />
      {openedMenuOrder && (
        <Menu
          id={`long-menu-for-${openedMenuOrder.id}`}
          anchorEl={anchorEl}
          open={!!openedMenuOrder}
          onClose={onRowMenuClose}
          menuTitle={t('ordersList.table.menu.title')}
        >
          {isOrderReadyToComplete(openedMenuOrder) && (
            <MenuItem
              onClick={onCloseOrderClicked}
              icon={
                <SvgIcon
                  icon="ready-to-complete"
                  height="2.4rem"
                  width="2.4rem"
                  color="primary"
                />
              }
              text={t('ordersList.table.menu.closeOrder')}
            />
          )}
          {canAddNewTickets(openedMenuOrder) && (
            <MenuItem
              onClick={() => {
                setOrderForAddTicketPopup({ ...openedMenuOrder });
                setOpenedMenuOrder(null);
              }}
              icon={<NoteAddOutlinedIcon />}
              text={t('ordersList.table.menu.addTickets')}
            />
          )}
        </Menu>
      )}
      {orderForAddTicketPopup && (
        <Popover
          open
          anchorEl={() =>
            document.getElementById(
              getMenuWrapperId(orderForAddTicketPopup.id),
            ) as HTMLElement
          }
          PaperProps={{ sx: { overflow: 'visible' } }}
        >
          <AddTicketView
            showCloseButton
            order={orderForAddTicketPopup}
            onSubmitSuccess={onSubmitTicket}
            onSaveDraftSuccess={onSaveDraftTicket}
            onClosePopup={onCloseAddTicketPopover}
          />
        </Popover>
      )}
      <ConfirmationDialog
        onClose={() => setShowAddTicketCloseConfirmation(false)}
        open={showAddTicketCloseConfirmation}
        handleActionClick={() => {
          setOrderForAddTicketPopup(null);
          setShowAddTicketCloseConfirmation(false);
        }}
        actionButtonTitle={t('common.yes')}
        description={t('ordersList.table.addTicketDialogCloseConfirmation')}
      />
      {tableSettings?.tableName &&
        domReady &&
        getTableSettingsPortalTarget() &&
        createPortal(
          <TableSettings
            columns={columns}
            tableName={tableSettings.tableName}
          />,
          getTableSettingsPortalTarget()!,
        )}
    </TableWrapper>
  );
};
