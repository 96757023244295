import _ from 'lodash';

import {
  deleteUserEndpoint,
  resendUserInviteEndpoint,
  usersListEndpoint,
} from '../constants/endpoints';
import {
  APISearchSortOrder,
  APISortOrder,
  APIUser,
  APIUserSearchResult,
  APIUsersList,
  APIUsersListParams,
} from '../types/api';
import {
  getPageNumberAfterLoadMore,
  getPageSizeDependingOnItems,
} from '../utils/lists';
import { httpMethod } from '../utils/sra';
import { DEFAULT_USERS_LIST_PAGE_SIZE } from '../utils/user';

export type UsersListColumnId = keyof APIUser;

export interface CoreUsersListState {
  items: APIUsersList['result'];
  pagination: {
    pageNumber: APIUsersList['pageNumber'];
    pageSize: APIUsersList['pageSize'];
  };
  searchSortOrders: APISearchSortOrder<UsersListColumnId>[];
  count: number;
  loadUsersListPending: boolean;
  usersListLoaded: boolean;
  loadMoreUsersListPending: boolean;
  deleteUserPending: boolean;
  resendUserInvitePending: boolean;
  usersListErrorText: string | null;
}

export const coreUsersListState: CoreUsersListState = {
  items: [],
  pagination: { pageNumber: 1, pageSize: DEFAULT_USERS_LIST_PAGE_SIZE },
  searchSortOrders: [
    {
      sortField: 'userStatus',
      sortOrder: APISortOrder.ASC,
    },
    {
      sortField: 'lastName',
      sortOrder: APISortOrder.ASC,
    },
    {
      sortField: 'id',
      sortOrder: APISortOrder.ASC,
    },
  ],
  count: 0,
  loadUsersListPending: false,
  usersListLoaded: false,
  loadMoreUsersListPending: false,
  deleteUserPending: false,
  resendUserInvitePending: false,
  usersListErrorText: null,
};

const loadUsersList = (
  providerId: string,
  body?: Partial<APIUsersListParams>,
) => ({
  name: 'loadUsersList',
  url: usersListEndpoint(providerId),
  body: (state: { usersList: CoreUsersListState }) => {
    return {
      pageSize: state.usersList.pagination.pageSize,
      pageNumber: state.usersList.pagination.pageNumber,
      searchSortOrders: state.usersList.searchSortOrders,
      ...body,
    };
  },
  method: httpMethod.post,
  onSuccess: (_state: CoreUsersListState, payload: APIUserSearchResult) => ({
    items: payload.result,
    usersListLoaded: true,
    pagination: {
      pageNumber: payload.pageNumber,
      pageSize: payload.pageSize,
    },
    count: payload.count,
    usersLoaded: true,
  }),
});

const loadMoreUsersList = (
  providerId: string,
  body?: Partial<APIUsersListParams>,
) => ({
  name: 'loadMoreUsersList',
  url: usersListEndpoint(providerId),
  body: (state: { usersList: CoreUsersListState }) => ({
    ...body,
    pageNumber: getPageNumberAfterLoadMore(
      state.usersList.items,
      state.usersList.pagination.pageSize,
    ),
    pageSize: state.usersList.pagination.pageSize,
    searchSortOrders: state.usersList.searchSortOrders,
  }),
  method: httpMethod.post,
  onSuccess: (state: CoreUsersListState, payload: APIUserSearchResult) => ({
    items: _.uniqBy([...state.items, ...payload.result], 'id'),
  }),
});

const updateUsersList = (
  providerId: string,
  body?: Partial<APIUsersListParams>,
) => ({
  name: 'updateUsersList',
  url: usersListEndpoint(providerId),
  body: (state: { usersList: CoreUsersListState }) => ({
    ...body,
    pageNumber: 1,
    pageSize: getPageSizeDependingOnItems(state.usersList.items),
    searchSortOrders: state.usersList.searchSortOrders,
  }),
  method: httpMethod.post,
  onSuccess: (_state: CoreUsersListState, payload: APIUserSearchResult) => {
    return {
      items: payload.result,
      count: payload.count,
    };
  },
});

const deleteUser = (providerId: string, userId: string) => ({
  name: 'deleteUser',
  url: deleteUserEndpoint(providerId, userId),
  method: httpMethod.delete,
});

const resendUserInvite = (providerId: string, userId = '') => ({
  name: 'resendUserInvite',
  url: resendUserInviteEndpoint(providerId, userId),
  method: httpMethod.post,
});

const updateUsersListSorting = (
  newSorting: CoreUsersListState['searchSortOrders'],
) => ({
  name: 'updateUsersListSorting',
  updater: () => ({
    searchSortOrders: newSorting,
  }),
});

const resetUsersList = () => ({
  name: 'resetUsersList',
  updater: () => coreUsersListState,
});

const loadUsersTypeahead = (
  providerId: string,
  body?: Partial<APIUsersListParams>,
) => ({
  name: 'loadUsersTypeahead',
  url: usersListEndpoint(providerId),
  body: {
    searchSortOrders: [
      {
        sortField: 'lastName',
        sortOrder: APISortOrder.ASC,
      },
    ],
    userStatuses: ['Active'],
    ...body,
  },
  method: httpMethod.post,
});

export const coreUsersListMethods = {
  loadUsersList,
  loadMoreUsersList,
  updateUsersList,
  deleteUser,
  resendUserInvite,
  updateUsersListSorting,
  resetUsersList,
  loadUsersTypeahead,
};
