import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';

import { TimeStampStep } from '@cd3p/core/utils/order';
import {
  Step,
  StepConnector,
  StepIconProps,
  StepLabel,
  Stepper,
  stepConnectorClasses,
} from '@mui/material';
import { Typography } from 'components';

import { useTheme } from 'third-party';

const StepperStyled = styled(Stepper)`
  margin-top: 1rem;
`;

const Connector = styled(StepConnector)`
  &.${stepConnectorClasses.active} {
    & .${stepConnectorClasses.line} {
      border-color: ${({ theme }) => theme.custom.palette.primary500};
    }
  }
  &.${stepConnectorClasses.completed} {
    & .${stepConnectorClasses.line} {
      border-color: ${({ theme }) => theme.custom.palette.primary500};
    }
  }
  & .${stepConnectorClasses.line} {
    margin-top: -2.1rem;
    margin-bottom: -0.2rem;
    margin-left: -0.2rem;
    border-width: 0.2rem;
    border-color: ${({ theme }) => theme.custom.palette.muted200};
    height: 2.6rem;
  }
`;

const StyledLabel = styled(StepLabel)`
  padding: 0;
`;

const LabelText = styled(Typography)`
  font-size: 1.4rem;
  font-weight: 900;
  text-transform: uppercase;
`;

const DescriptionText = styled(Typography)`
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2.1rem;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

type Props = {
  steps: TimeStampStep[];
  className?: string;
};

type StepIconRootProps = {
  ownerState: { completed?: boolean; bordered?: boolean };
};
const StepIconRoot = styled.div<StepIconRootProps>`
  background-color: ${({ theme, ownerState }) =>
    ownerState.completed ? theme.custom.palette.primary500 : 'transparent'};
  z-index: 1;
  color: #fff;
  width: 1.8rem;
  height: 1.8rem;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  border-width: 0.2rem;
  border-style: solid;
  margin-top: -1.9rem;
  border-color: ${({ theme, ownerState }) =>
    ownerState.completed || ownerState.bordered
      ? theme.custom.palette.primary500
      : theme.custom.palette.muted200};
  box-sizing: 'border-box';
`;

const StyledDoneIcon = styled(DoneRoundedIcon)`
  width: 1.6rem;
`;

const StyledRemoveIcon = styled(RemoveRoundedIcon)`
  width: 1.6rem;
`;

function StepIcon(props: StepIconProps & { step: TimeStampStep }) {
  const { completed, className, step } = props;

  const renderIcon = () => {
    if (
      step.isManuallyChanged ||
      (completed && !step.description) ||
      step.skipped
    ) {
      return <StyledRemoveIcon color="primary" />;
    }
    if (!completed || !step.description) {
      return null;
    }
    return <StyledDoneIcon fontSize="small" />;
  };
  return (
    <StepIconRoot
      ownerState={{
        completed:
          completed &&
          !!step.description &&
          !step.isManuallyChanged &&
          !step.skipped,
        bordered: !!step.description || step.skipped,
      }}
      className={className}
    >
      {renderIcon()}
    </StepIconRoot>
  );
}

export const TimeStamps: React.FC<Props> = React.memo(
  ({ steps, className }) => {
    const [activeStep, setActiveStep] = useState(0);
    const theme = useTheme();

    useEffect(() => {
      // used some to stop looping if the condition is met
      steps.some((step, index) => {
        // if step is not passed(not-existed and not-skipped) - set it to be active step and not completed
        if (!step.description && !step.skipped && step.notExist) {
          setActiveStep(index);
          return step;
        }
        // if all steps fullfilled - set last step to be completed
        if (index === steps.length - 1) {
          setActiveStep(steps.length);
        }
      });
    }, [steps]);

    return (
      <StepperStyled
        activeStep={activeStep}
        orientation="vertical"
        connector={<Connector />}
        className={className}
      >
        {steps.map(step => (
          <Step key={step.label}>
            <StyledLabel
              StepIconComponent={props => <StepIcon {...props} step={step} />}
              optional={
                <DescriptionText variant="caption">
                  {step.description || '--'}
                </DescriptionText>
              }
            >
              <Row>
                <LabelText color={theme.custom.palette.darkText}>
                  {step.label}
                </LabelText>
              </Row>
            </StyledLabel>
          </Step>
        ))}
      </StepperStyled>
    );
  },
);
