import { TicketsListState } from 'modules/common/ticketsList';
import { TrackingState } from 'modules/tracking';

export const ticketsMapper = (
  ticketsItems: TicketsListState['tickets']['items'],
  trackingItems: TrackingState['items'],
) => {
  return ticketsItems.map(item => ({
    ...item,
    timeOfArrival:
      trackingItems?.[item.truck?.truckNumber || '']?.timeOfArrival || null,
  }));
};
