import React, { useState } from 'react';

import styled from 'styled-components';

import { DISPLAY_DATE_FORMAT } from '@cd3p/core/utils/common';
import { timeField } from '@cd3p/core/utils/fields';
import {
  DesktopDatePicker,
  FormField,
  TextField,
  TimePicker,
} from 'components';
import { FieldError } from 'react-hook-form';

import { dayjs, useFormContext, useTranslation } from 'third-party';

const StyledDatepicker = styled(DesktopDatePicker)`
  width: 100%;
`;

const popperDateSx = {
  '& .MuiPickersCalendarHeader-label': {
    fontSize: '1.6rem',
    textTransform: 'uppercase',
    fontWeight: 900,
  },
  '& .PrivatePickersYear-yearButton': {
    fontSize: '1.6rem',
    margin: '0.4rem 0',
    '&.Mui-selected': {
      color: '#ffffff',
    },
  },
  '& .MuiPickersDay-dayWithMargin': {
    fontSize: '1.6rem',
    color: '#5C6C80',
    '&[aria-selected="true"]': {
      color: '#ffffff',
    },
    '&[aria-current="date"]': {
      borderColor: '#AFD8D4',
      backgroundColor: '#AFD8D4',
    },
  },
};

interface Props {
  acceptPastDate?: boolean;
  isTimeRequired?: boolean;
  isDateRequired?: boolean;
  isDisabled?: boolean;
  showError?: boolean;
  timeFieldName: string;
  dateFieldName: string;
}

const isAfterNow = (dateValue: any, timeValue: any) =>
  !dayjs().isAfter(
    dayjs(
      `${dayjs(dateValue).format(DISPLAY_DATE_FORMAT)}T${timeValue}`,
      `${DISPLAY_DATE_FORMAT}Thh:mm A`,
    ),
  );

export const DateTimeForm = React.memo(
  ({
    acceptPastDate = true,
    showError = false,
    timeFieldName,
    dateFieldName,
    isDisabled = false,
    isTimeRequired,
    isDateRequired,
  }: Props) => {
    const { t } = useTranslation();

    const [datePickerOpen, setDatePickerOpen] = useState(false);

    const {
      watch,
      control,
      formState: { errors },
    } = useFormContext();

    const watchedDateValue = watch(dateFieldName);
    const watchedTimeValue = watch(timeFieldName);

    return (
      <>
        <FormField
          fieldName={dateFieldName}
          fieldError={errors[dateFieldName] as FieldError}
          isRequired={isDateRequired}
          label={t('order.sectionField.deliveryDate')}
          requiredErrorMessage={t('common.form.emptyFieldError')}
          control={control}
          isDisabled={isDisabled}
          showError={showError}
          rules={{
            validate: {
              incorrectDate: (value: any) =>
                dayjs(value).isValid() || t('common.form.date.error.invalid'),
              isPastDate: (value: any) => {
                return (
                  acceptPastDate ||
                  dayjs(value).isToday() ||
                  isAfterNow(value, watchedTimeValue) ||
                  t('common.form.date.error.pastDate')
                );
              },
            },
            deps: [timeFieldName],
          }}
          render={({ field }) => (
            <StyledDatepicker
              open={datePickerOpen}
              onOpen={() => setDatePickerOpen(true)}
              onClose={() => setDatePickerOpen(false)}
              inputFormat={DISPLAY_DATE_FORMAT}
              value={field.value}
              disabled={isDisabled}
              onChange={field.onChange}
              PopperProps={{
                sx: popperDateSx,
                placement: 'bottom-start',
              }}
              renderInput={params => (
                <TextField
                  size="small"
                  variant="outlined"
                  InputProps={{ style: { fontSize: 40, color: 'red' } }}
                  {...params}
                  disabled={isDisabled}
                  error={!!errors[dateFieldName] && showError}
                  onClick={() => setDatePickerOpen(true)}
                />
              )}
            />
          )}
        />
        <FormField
          fieldName={timeFieldName}
          fieldError={errors[timeFieldName] as FieldError}
          isRequired={isTimeRequired}
          label={t('order.sectionField.deliveryTime')}
          requiredErrorMessage={t('common.form.emptyFieldError')}
          control={control}
          showError={showError}
          isDisabled={isDisabled}
          rules={{
            validate: {
              incorrectTime: (value: string) =>
                timeField.validate(value) ||
                t('common.form.time.error.invalid'),
              isPastTime: (value: any) =>
                acceptPastDate ||
                isAfterNow(watchedDateValue, value) ||
                t('common.form.time.error.pastTime'),
            },
            deps: [dateFieldName],
          }}
          render={({ field }) => (
            <TimePicker
              value={field.value}
              onChange={field.onChange}
              disabled={isDisabled}
              error={showError && !!errors[timeFieldName]}
            />
          )}
        />
      </>
    );
  },
);

DateTimeForm.displayName = 'DateTimeForm';
