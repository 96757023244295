import { createOrdersRefSelector } from '@cd3p/core/utils/ordersList';
import { State } from 'modules';

export const allOrdersListState = (state: State) => state.allOrdersList;
export const allOrdersListItems = createOrdersRefSelector(
  (state: State) => state.allOrdersList.items,
);
export const allOrdersListSorting = (state: State) =>
  state.allOrdersList.sorting;
export const allOrdersListSearch = (state: State) => state.allOrdersList.search;
export const allOrdersListSearchPattern = (state: State) =>
  state.allOrdersList.search.pattern;
export const allOrdersListPagination = (state: State) =>
  state.allOrdersList.pagination;
export const allOrdersListCount = (state: State) => state.allOrdersList.count;
export const loadAllOrdersPending = (state: State) =>
  state.allOrdersList.loadAllOrdersPending;
export const allOrdersLoaded = (state: State) =>
  state.allOrdersList.ordersLoaded;
export const loadMoreAllOrdersPending = (state: State) =>
  state.allOrdersList.loadMoreAllOrdersPending;
export const allOrdersScrollPosition = (state: State) =>
  state.allOrdersList.scrollPosition;
