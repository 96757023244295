import _dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import isYesterday from 'dayjs/plugin/isYesterday';
import utc from 'dayjs/plugin/utc';

export { default as _ } from 'lodash';
export { bindActionCreators } from 'redux';
export { createSelector } from 'reselect';
export { simplifyBuilder, middlewareBuilder, httpMethod } from './utils/sra';
export { uid } from 'uid';

_dayjs.extend(isTomorrow);
_dayjs.extend(isYesterday);
_dayjs.extend(isToday);
_dayjs.extend(isSameOrBefore);
_dayjs.extend(utc);
_dayjs.extend(customParseFormat);

export const dayjs = _dayjs;
