import React, { ReactElement } from 'react';

import styled from 'styled-components';

import { Button, Stack, Typography } from 'components';

import { ContentLoader } from 'third-party';

const Wrapper = styled(Stack)<{ clickable?: string; active?: string }>`
  padding: 1.2rem 1.6rem;
  border-radius: 0.4rem;
  border: 1px solid ${props => props.theme.custom.palette.primary600};
  cursor: ${props => (props.clickable === 'true' ? 'pointer' : 'default')};
  transition: background-color 500ms;
  background-color: ${props =>
    props.active === 'true' ? props.theme.custom.palette.secondary50 : 'none'};
  &:hover {
    background-color: ${props =>
      props.clickable === 'true'
        ? props.theme.custom.palette.secondary50
        : 'inherit'};
  }
`;

const Title = styled(Typography)`
  color: ${props => props.theme.custom.palette.secondary900};
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 150%;
  text-transform: capitalize;
`;

const Description = styled(Typography)`
  color: ${props => props.theme.custom.palette.muted900};
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 150%;
  flex-grow: 1;
`;

const Link = styled(Button)`
  color: ${props => props.theme.custom.palette.secondary500};
  font-size: 1.2rem;
  font-weight: 900;
  line-height: 150%;
  padding: 0;
  min-width: 0;
  text-decoration-line: underline;
  text-transform: uppercase;
  cursor: pointer;
  margin-top: 1rem;
  align-self: flex-start;
  &:hover {
    text-decoration-line: none;
  }
`;

const IconWrapper = styled.div`
  flex-shrink: 0;
`;

type Props = {
  title: string | ReactElement;
  description: string | ReactElement;
  linkLabel: string | ReactElement;
  icon: ReactElement;
  onClick?: () => void;
  isLoading?: boolean;
  isActive?: boolean;
};

export const InsightBlock: React.FC<Props> = ({
  title,
  description,
  linkLabel,
  icon,
  onClick,
  isLoading,
  isActive,
}) => {
  return (
    <Wrapper
      clickable={Boolean(onClick && !isLoading).toString()}
      active={Boolean(isActive).toString()}
      onClick={onClick}
    >
      <Stack direction="row" gap="1.2rem" flexGrow={1}>
        <IconWrapper>{icon}</IconWrapper>
        {isLoading ? (
          <ContentLoader height={80} width={220}>
            <rect x="0" y="0" width="150" height="14" />
            <rect x="0" y="20" width="220" height="12" />
            <rect x="0" y="36" width="200" height="12" />
            <rect x="0" y="62" width="80" height="12" />
          </ContentLoader>
        ) : (
          <Stack>
            <Title>{title}</Title>
            <Description>{description}</Description>
            {onClick && <Link variant="text">{linkLabel}</Link>}
          </Stack>
        )}
      </Stack>
    </Wrapper>
  );
};
