import React, { useCallback, useEffect, useMemo, useState } from 'react';

import styled from 'styled-components';

import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';

import {
  Button,
  CircularProgress,
  Grid,
  Link,
  SvgIcon,
  Typography,
  ViewWrapper,
} from 'components';

import {
  RouterLink,
  Trans,
  _,
  prettyBytes,
  useDropzone,
  useSelector,
  useTranslation,
} from 'third-party';

import { MAX_FILE_SIZE_TO_IMPORT } from 'constants/common';
import { allOrdersUrl } from 'constants/url';

import { useImportOrders } from 'modules/importOrders';
import { NotificationSeverity, useNotifications } from 'modules/notifications';

import { importOrdersSelector } from 'selectors';

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.9rem;
`;

const Header = styled(Typography)`
  color: ${props => props.theme.custom.palette.secondary500};
  margin-right: 3rem;
`;

const Description = styled(Typography)`
  font-size: 1.8rem;
  font-weight: 400;
  color: ${props => props.theme.custom.palette.muted800};
  margin-bottom: 0.65rem;
`;

const DownloadLink = styled(Link)`
  color: ${props => props.theme.custom.palette.secondary500};
  text-transform: uppercase;
  font-weight: 900;
  font-size: 1.6rem;
  line-height: 1.5;
`;

const RequiredFieldHeader = styled(Typography)`
  margin-bottom: 2rem;
`;

const RequiredFieldList = styled.ul`
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 1.5;
  color: ${props => props.theme.custom.palette.gray};
  list-style: disc;
  padding-left: 2.5rem;
  p {
    font-weight: 300;
  }
`;

const DropZone = styled.div`
  border-width: 2px;
  border-color: ${props => props.theme.custom.palette.primary500};
  border-radius: 1rem;
  flex: 1 1 auto;
  display: flex;
  flex-flow: column;
  align-items: center;
`;

const DropZoneHeader = styled(Typography)`
  margin: 7.6rem auto 3.817rem;
  color: ${props => props.theme.custom.palette.secondary500};
`;

const DropZoneHeaderIcon = styled(CloudUploadOutlinedIcon)`
  width: 7.9rem;
  height: 5.26rem;
  color: ${props => props.theme.custom.palette.secondary500};
  margin-bottom: 3.47rem;
`;

const DropZoneOpenFileButton = styled(Link)`
  color: ${props => props.theme.custom.palette.primary900};
  font-weight: 600;
  font-size: 2.4rem;
`;

const OrderError = styled.div`
  margin-bottom: 1rem;
  break-inside: avoid-column;
  font-weight: 600;
  font-size: 1.6rem;
`;

const AlertOrderList = styled.ul`
  list-style: disc;
  padding-left: 2rem;
  font-weight: 400;
  font-size: 1.6rem;
  margin-bottom: 1.6rem;
`;

const AllOrdersLink = styled(RouterLink)`
  font-size: 1.4rem;
  color: ${props => props.theme.custom.palette.secondary900};
  &:visited {
    color: ${props => props.theme.custom.palette.secondary900};
  }
`;

const UploadButton = styled(Button)`
  margin-top: 3.1rem;
`;

const ButtonWrapper = styled(Grid)`
  display: flex;
  justify-content: center;
`;

const FileNameWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.85rem;
  max-width: 70%;
  margin-bottom: 1rem;
  > * {
    flex-shrink: 0;
  }
`;

const FileName = styled(Typography)`
  font-weight: 600;
  font-size: 2rem;
  line-height: 1.5;
  text-decoration-line: underline;
  color: ${props => props.theme.custom.palette.primary900};
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-shrink: 1;
`;

const DeleteButton = styled(Button)`
  height: 3.1rem !important;
  padding: 0.8rem;
  font-weight: 900;
  font-size: 1.4rem;
  margin-top: 1rem;
  color: ${props => props.theme.custom.palette.secondary500};
`;

const ErrorMessageTitle = styled(Typography)`
  font-weight: 900;
  font-size: 1.6rem;
  line-height: 125%;
  text-transform: uppercase;
  margin-bottom: 1rem;
  color: ${props => props.theme.custom.palette.error500};
`;

const BodyErrorMessage = styled.div`
  column-count: 2;
  column-gap: 10rem;
  > * {
    color: ${props => props.theme.custom.palette.error500};
  }
`;

const NoColumnBreakWrapper = styled.div`
  break-inside: avoid-column;
`;

export const ImportOrdersView = () => {
  const { t } = useTranslation();
  const { addNotification } = useNotifications();
  const { importOrders, clearImportResult, downloadSampleFile } =
    useImportOrders();

  const [acceptedFiles, setAcceptedFiles] = useState<File[]>([]);

  const importOrdersPending = useSelector(
    importOrdersSelector.importOrdersPending,
  );
  const importOrdersError = useSelector(importOrdersSelector.importOrdersError);
  const lastSuccessfullyImportedOrders = useSelector(
    importOrdersSelector.lastSuccessfullyImportedOrders,
  );
  const lastFailedToImportOrders = useSelector(
    importOrdersSelector.lastFailedToImportOrders,
  );
  const lastSkippedImportOrders = useSelector(
    importOrdersSelector.lastSkippedImportOrders,
  );

  useEffect(() => {
    return () => {
      clearImportResult();
    };
  }, [clearImportResult]);

  const onDrop = useCallback(
    (files: File[]) => {
      setAcceptedFiles(files);
      clearImportResult();
    },
    [clearImportResult],
  );

  const resetAcceptedFiles = () => {
    setAcceptedFiles([]);
  };

  const onUploadButtonClicked = async () => {
    const data = new FormData();
    data.append('file', acceptedFiles[0]);
    await importOrders(data);
    resetAcceptedFiles();
  };

  const onDeleteButtonClicked = () => {
    resetAcceptedFiles();
  };

  const onReUploadClicked = () => {
    setAcceptedFiles([]);
    clearImportResult();
  };

  const scrollWindowToTop = () => {
    setTimeout(() => {
      // scroll up to reveal alert messages
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }, 0);
  };

  const {
    getRootProps,
    getInputProps,
    open: openPickFileDialog,
  } = useDropzone({
    onDrop,
    multiple: false,
    noClick: true,
    noKeyboard: true,
    accept: {
      'text/csv': ['.csv'],
    },
  });

  const isFileSizeTooBig = useMemo(() => {
    const [fileToUpload] = acceptedFiles;
    return fileToUpload && fileToUpload.size > MAX_FILE_SIZE_TO_IMPORT;
  }, [acceptedFiles]);

  const notImportedOrdersCount =
    lastSkippedImportOrders.length + lastFailedToImportOrders.length;

  const { importErrorAlertTitle, importErrorAlertMessage } = useMemo(() => {
    if (notImportedOrdersCount > 0) {
      return {
        importErrorAlertTitle: (
          <Trans
            i18nKey="importOrders.importOrdersErrorAlertTitle"
            count={notImportedOrdersCount}
          >
            {{ numOfNotImportedOrdersCount: notImportedOrdersCount }} order was
            not imported
          </Trans>
        ),
        importErrorAlertMessage: (
          <Trans i18nKey="importOrders.importOrdersErrorAlertMessage">
            Please see{' '}
            <AllOrdersLink to={allOrdersUrl()}>All Orders</AllOrdersLink> page
            to review your imported orders (
            {{ numOfImportedOrders: lastSuccessfullyImportedOrders.length }}).
            For orders not imported, re-upload a new .csv file with corrected
            information.
          </Trans>
        ),
      };
    } else if (importOrdersError) {
      return {
        importErrorAlertTitle: t('importOrders.uploadFailedErrorAlertTitle'),
        importErrorAlertMessage: (
          <Trans i18nKey="importOrders.uploadFailedErrorAlertMessage">
            Something went wrong with your upload. Please check the{' '}
            <strong>required fields</strong> and file format before trying to
            upload again.
          </Trans>
        ),
      };
    } else if (isFileSizeTooBig) {
      return {
        importErrorAlertTitle: t(
          'importOrders.incorrectFileSizeErrorAlertTitle',
        ),
        importErrorAlertMessage: (
          <Trans i18nKey="importOrders.incorrectFileSizeErrorAlertMessage">
            The file you are trying to upload is too large. Files cannot be
            larger than {{ maxSize: prettyBytes(MAX_FILE_SIZE_TO_IMPORT) }}.
          </Trans>
        ),
      };
    } else {
      return {};
    }
  }, [
    notImportedOrdersCount,
    importOrdersError,
    isFileSizeTooBig,
    lastSuccessfullyImportedOrders.length,
    t,
  ]);

  useEffect(() => {
    const showAlert = !!importErrorAlertTitle || !!importErrorAlertMessage;
    if (showAlert) {
      addNotification({
        severity: NotificationSeverity.Error,
        title: importErrorAlertTitle,
        message: importErrorAlertMessage,
      });
      scrollWindowToTop();
    }
  }, [importErrorAlertTitle, importErrorAlertMessage, addNotification]);

  useEffect(() => {
    const showAlert =
      lastSuccessfullyImportedOrders.length > 0 && !notImportedOrdersCount;
    if (showAlert) {
      addNotification({
        severity: NotificationSeverity.Success,
        title: (
          <Trans i18nKey="importOrders.successAlertTitle">
            All orders imported successfully (
            {{ numOfImportedOrders: lastSuccessfullyImportedOrders.length }})
          </Trans>
        ),
        message: (
          <Trans i18nKey="importOrders.successAlertMessage">
            Please see{' '}
            <AllOrdersLink to={allOrdersUrl()}>All Orders</AllOrdersLink> page
            to review your imported orders.
          </Trans>
        ),
      });
      scrollWindowToTop();
    }
  }, [addNotification, lastSuccessfullyImportedOrders, notImportedOrdersCount]);

  const isUploadButtonDisabled =
    importOrdersPending || !acceptedFiles.length || isFileSizeTooBig;

  return (
    <ViewWrapper>
      <HeaderWrapper>
        <Header variant="h1">{t('importOrders.title')}</Header>
        {notImportedOrdersCount > 0 && (
          <Button
            color="secondary"
            variant="outlined"
            onClick={onReUploadClicked}
          >
            {t('importOrders.reUploadButtonText')}
          </Button>
        )}
      </HeaderWrapper>
      {notImportedOrdersCount ? (
        <>
          <ErrorMessageTitle>{t('importOrders.errorsTitle')}</ErrorMessageTitle>
          <BodyErrorMessage>
            {lastSkippedImportOrders.length > 0 && (
              <NoColumnBreakWrapper>
                <OrderError>
                  {t('importOrders.alreadyExistOrdersErrorMessage')}
                </OrderError>
                <AlertOrderList>
                  {_.map(lastSkippedImportOrders, (order, index) => (
                    <li key={index}>{order.model.orderNumber}</li>
                  ))}
                </AlertOrderList>
              </NoColumnBreakWrapper>
            )}
            {lastFailedToImportOrders.length > 0 &&
              _.map(lastFailedToImportOrders, (order, index) => {
                return (
                  <NoColumnBreakWrapper>
                    <OrderError key={index}>
                      <Trans i18nKey="importOrders.orderErrorTitle">
                        {t('order.sectionField.orderNumber')}
                        {{ orderNumber: order.model.orderNumber }}
                      </Trans>
                    </OrderError>
                    <AlertOrderList>
                      {_.map(order.details, (errorMessage, index) => (
                        <li key={index}>{errorMessage}</li>
                      ))}
                    </AlertOrderList>
                  </NoColumnBreakWrapper>
                );
              })}
          </BodyErrorMessage>
        </>
      ) : (
        <>
          <Description>{t('importOrders.description')}</Description>
          <DownloadLink onClick={downloadSampleFile}>
            {t('importOrders.downloadSampleLinkText')}
          </DownloadLink>
          <Grid mt={0} container spacing={4}>
            <Grid item xs={8} display="flex">
              <DropZone
                {...getRootProps()}
                style={{
                  borderStyle: acceptedFiles.length ? 'solid' : 'dashed',
                }}
              >
                <input {...getInputProps()} />
                <DropZoneHeader variant="h2">
                  {importOrdersPending
                    ? t('importOrders.dropZoneUploadingTitle')
                    : t('importOrders.dropZoneDefaultTitle')}
                </DropZoneHeader>
                {acceptedFiles.length ? (
                  importOrdersPending ? (
                    <CircularProgress
                      color="primary"
                      style={{ margin: '4rem auto' }}
                    />
                  ) : (
                    <>
                      <FileNameWrapper>
                        <SvgIcon
                          height="2.2rem"
                          icon="attachment"
                          width="2.2rem"
                        />
                        <FileName>{acceptedFiles[0].name}</FileName>
                      </FileNameWrapper>
                      <DeleteButton
                        onClick={onDeleteButtonClicked}
                        size="large"
                        color="primary"
                        variant="outlined"
                      >
                        {t('common.delete')}
                      </DeleteButton>
                    </>
                  )
                ) : (
                  <>
                    <DropZoneHeaderIcon />
                    <DropZoneOpenFileButton onClick={openPickFileDialog}>
                      {t('importOrders.dropZoneBrowseFilesButtonText')}
                    </DropZoneOpenFileButton>
                  </>
                )}
              </DropZone>
            </Grid>
            <Grid item xs={4}>
              <div>
                <RequiredFieldHeader variant="subtitle2">
                  {t('importOrders.requiredFieldsHeader')}
                </RequiredFieldHeader>
                <RequiredFieldList>
                  <li>Order #</li>
                  <li>Project Name</li>
                  <li>Delivery Location</li>
                  <li>
                    Delivery Date{' '}
                    <p>{t('importOrders.requiredFieldsFormat')}: MM/DD/YY</p>
                  </li>
                  <li>
                    {t('importOrders.fieldName.deliveryDateTime')}{' '}
                    <p>{t('importOrders.requiredFieldsFormat')}: HH:MM AM/PM</p>
                  </li>
                  <li>Volume/Qty</li>
                  <li>Mix</li>
                  <li>Delivery Rate</li>
                  <li>Slump</li>
                  <li>Customer</li>
                </RequiredFieldList>
              </div>
            </Grid>
            <ButtonWrapper item xs={8}>
              <UploadButton
                size="large"
                color="primary"
                variant="contained"
                disabled={isUploadButtonDisabled}
                onClick={onUploadButtonClicked}
              >
                {t('importOrders.uploadFileButtonText')}
              </UploadButton>
            </ButtonWrapper>
          </Grid>
        </>
      )}
    </ViewWrapper>
  );
};
