export const commonENTranslation = {
  'common.yes': 'Yes',
  'common.no': 'No',
  'common.cancel': 'Cancel',
  'common.decline': 'Decline',
  'common.confirm': 'Confirm',
  'common.editAndConfirm': 'Edit & Confirm',
  'common.edit': 'Edit',
  'common.save': 'Save',
  'common.add': 'Add',
  'common.none': 'None',
  'common.done': 'Done',
  'common.ok': 'Ok',
  'common.continue': 'Continue',
  'common.next': 'Next',
  'common.finish': 'Finish',
  'common.success': 'Success',
  'common.apply': 'Apply',
  'common.on': 'On',
  'common.off': 'Off',
  'common.generalError': 'Something went wrong. Please try again later.',
  'common.404Error':
    'Oops! It seems the page you are looking for does not exist.',
  'common.yes.delete': 'Yes, Delete',
  'common.yes.discard': 'Yes, Discard',
  'common.yes.change': 'Yes, Change',
  'common.no.cancel': 'No, Cancel',
  'common.no.continue': 'No, Continue',
  'common.submit': 'Submit',
  'common.back': 'Back',
  'common.undoCancel': 'Undo Cancel',
  'common.no.nevermind': 'No, Nevermind',
  'common.today': 'Today',
  'common.yesterday': 'Yesterday',
  'common.tomorrow': 'Tomorrow',
  'common.entityNameUndefined': 'N/A',
  'common.addAddress': 'Add Address',
  'common.address': 'Address',
  'common.areYouSure': 'Are you sure?',
  'common.providers': 'Providers',
  'common.provider': 'Provider',
  'common.version': 'version',
  'common.createOrder': 'Create Order',
  'common.newOrder': 'New Order',
  'common.cancelOrder': 'Cancel Order',
  'common.discard': 'Discard',
  'common.submitOrder': 'Submit Order',
  'common.hours_one': '1h',
  'common.hours_other': '{{count}}hrs',
  'login.signInHeader': 'Login',
  'login.emailTitle': 'Email',
  'login.emailPlaceholder': 'Enter Email Address',
  'login.passwordTitle': 'Password',
  'login.passwordPlaceholder': 'Enter Password',
  'login.signInButtonText': 'Login',
  'login.signUpHeader': 'Sign Up',
  'login.logout': 'Sign Out',
  'login.inputPlaceholder': 'Please enter a value',
  'login.lockedOutError': 'You have been locked out of your account.',
  'login.accountNotFoundError':
    'An account with such credentials is not found or you do not have access. If you forgot the password, please use forgot password link below.',
  'order.ticket.timeStampLoadingStatus': 'Loading start',
  'order.ticket.timeStampDeliveryStatus': 'Delivery start',
  'order.ticket.timeStampOnSiteStatus': 'Jobsite arrival',
  'order.ticket.timeStampCompletedStatus': 'Delivery complete',
  'order.ticket.notRegistered': 'Not Registered',

  // chat
  'chat.messageInputPlaceholder': 'Write your message...',
  'chat.concretelyBot': 'Concretely Bot',
  'chat.notification.newMessageTitle': 'New Message',
  'chat.image.loadingFailed': `Image loading failed.\nClick to retry.`,
  'chat.image.imageNotFound': `Image unavailable.`,

  // DIALOGS

  'common.dialog.discardConfirmMessage':
    'Once you discard, the changes will be lost.',
  'common.dialog.deleteUserConfirmationBodyText':
    'Once you delete this user, it cannot be undone.',

  // FORM FIELDS

  // error
  'common.form.error.emptyField': 'Please enter a value',
  'common.form.error.uploadFile': `One or more files couldn't be uploaded. Please try again later.`,
  'common.form.error.lessThanZero': `Should be positive value`,

  // first name
  'common.form.firstName.label': 'First Name',
  'common.form.firstName.placeholder': 'Enter First Name',

  // last name
  'common.form.lastName.label': 'Last Name',
  'common.form.lastName.placeholder': 'Enter Last Name',

  // phone
  'common.form.phone.label': 'Phone',
  'common.form.phone.placeholder': 'Enter Phone Number',
  'common.form.phone.error.invalid':
    'Enter phone number in this format: XXX-XXX-XXXX',

  // email
  'common.form.email.label': 'Email',
  'common.form.email.placeholder': 'Enter Email Address',
  'common.form.email.error.invalid': 'Invalid Email Address',
  'common.form.email.notFoundText': 'No email found',

  // password
  'common.form.password.label': 'Password',
  'common.form.password.placeholder': 'Enter Password',
  'common.form.password.hint':
    '{{minLength}} characters minimum, case sensitive.',

  // new password
  'common.form.newPassword.label': 'New Password',
  'common.form.newPassword.placeholder': 'Enter New Password',

  // confirm password
  'common.form.confirmPassword.label': 'Re-enter New Password',
  'common.form.confirmPassword.placeholder': 'Re-Enter New Password',

  // date
  'common.form.date.error.invalid': 'Incorrect format. Expected "mm/dd/yyyy"',
  'common.form.date.error.pastDate': "Date can't be in the past",

  // time
  'common.form.time.label': 'Time',
  'common.form.time.placeholder': 'hh:mm (a|p)m',
  'common.form.time.error.invalid': 'Incorrect format. Expected "hh:mm (a|p)m"',
  'common.form.time.error.pastTime': `Time can't be in the past`,

  // customer
  'common.form.customer.label': 'Customer',
  'common.form.customer.placeholder': 'Select Customer',
  'common.form.customer.notFoundText': 'No customer found',

  // project
  'common.form.project.label': 'Project',
  'common.form.project.placeholder': 'Select Project',
  'common.form.project.notFoundText': 'No projects found',
  'common.form.project.descriptionLabel': 'Description',
  'common.form.project.descriptionPlaceholder':
    'Enter any details about this project',

  'releaseNotes.title': 'New version available',
  'releaseNotes.update': 'Update',
  'releaseNotes.skip': 'Skip',

  // contractor common text
  'common.cancellationUndone': 'Cancellation Undone',

  'order.notification.cancellationSubmitted':
    'Cancellation submitted to provider.',

  'order.keepButtonLabel': 'Keep Order',
  'order.cancelConfirmationText':
    'You are going to cancel this order with {{providerName}}. Cancellation will take effect immediately and this action cannot be undone.',
  'order.notification.orderCancelled': `You've canceled the order.`,

  'order.subscription.success.message':
    'You will now receive notification updates on this order.',
  'order.unsubscription.success.message':
    'You will no longer receive notification updates on this order.',
  'order.addressNotFoundToast':
    'Selected address not found. Enter a nearby address and use the pin to select the exact location on the map.',

  'order.status.confirmed': 'Confirmed',
  'order.status.unconfirmed': 'Unconfirmed',
  'order.status.cancelled': 'Canceled',
  'order.status.delivering': 'Delivering',
  'order.status.completed': 'Completed',
  'order.status.requested': 'Requested',
  'order.status.declined': 'Declined',

  'orderDetails.oldMixNameInfo': `This mix name has been updated\nsince this order was placed.`,
  'orderDetails.oldAdditiveNameInfo': `This additive name has been updated\nsince this order was placed.`,

  'orderDetails.changesRequested.fields.projectLabel': 'Project',
  'orderDetails.changesRequested.fields.deliveryDateLabel': 'Delivery Date',
  'orderDetails.changesRequested.fields.deliveryTimeLabel': 'Delivery Time',
  'orderDetails.changesRequested.fields.deliveryLocationLabel': 'Address',
  'orderDetails.changesRequested.fields.deliveryLatitudeLabel': 'Latitude',
  'orderDetails.changesRequested.fields.deliveryLongitudeLabel': 'Longitude',
  'orderDetails.changesRequested.fields.volumeLabel': 'Volume (CY)',
  'orderDetails.changesRequested.fields.orderTypeLabel': 'Order Type',
  'orderDetails.changesRequested.fields.callBackLabel': 'Call Back',
  'orderDetails.changesRequested.fields.mixTypeLabel': 'Mix Type',
  'orderDetails.changesRequested.fields.deliveryRateLabel': 'Delivery Rate',
  'orderDetails.changesRequested.fields.typeOfPour': 'Type of Pour',
  'orderDetails.changesRequested.fields.placementMethod': 'Placement Method',
  'orderDetails.changesRequested.fields.slumpLabel': 'Slump',
  'orderDetails.changesRequested.fields.additivesLabel': 'Additives',
  'orderDetails.changesRequested.fields.notesLabel': 'Notes',
  'orderDetails.deliveryRateActual':
    'Actual: {{actualDeliveryRate}} {{deliveryRateTypeUnit}}',
  'order.sectionField.deliveryRateTypeMinPerTruck': 'Min/Truck',
  'order.sectionField.deliveryRateTypeCYHour': 'CY/Hour',
  'order.sectionField.deliveryRateMinPerTruckPlaceholder':
    'Enter minutes between each truck',
  'order.sectionField.deliveryRateCYHourPlaceholder':
    'Enter cubic yards per hour',
  'order.sectionField.deliveryRateMinPerTruck': 'min per truck',
  'order.sectionField.deliveryRateCyPerHour': 'CY per hour',
  'order.details.afterHoursNotice': 'After business hours.',

  'order.notification.approvedTitle': 'Order approved',
  'order.notification.approvedWithChangesTitle': 'Order approved with changes',
  'order.notification.manualUpdateTitle': 'Order Updated',
  'order.notification.manualUpdateMessage':
    'Your Order {{orderName}} has been updated by {{providerName}}.',
  'order.notification.canceledTitle': 'Order Canceled',
  'order.notification.cancelSubmittedMessage':
    'Your cancellation notice has been confirmed. Your {{orderName}} is canceled.',
  'editOrder.notificationTitle.approvalReceived': 'Changes approved',
  'editOrder.notificationTitle.partialApprovalReceived':
    'Changes partially approved',
  'editOrder.notificationTitle.approvalRejected': 'Changes rejected',
  'order.notification.declinedTitle': 'Order Declined',

  // Order Callback
  'orderCallback.noCallback': 'No Call Back',
  'orderCallback.sendCallBack': 'Send Call back',
  'orderCallback.callBackClosedToast': 'Call Back closed',
  'orderCallback.title': 'Call Back Needed?',
  'orderCallback.compactDescription':
    'Let the provider know if you need a call back order.',
  'orderCallback.description': `Do you still need your call back order? Let {{providerName}} know what you need. If you don't need to call in any more concrete, tap "No Call Back."`,
  'orderCallback.quantity.label': 'Quantity',
  'orderCallback.quantity.placeholder': 'Enter amount (in CY)',
  'orderCallback.quantity.incorrectQuantity':
    'Incorrect quantity, it should be greater then 0',
  'orderCallback.details.label': 'Additional details',
  'orderCallback.details.placeholder': 'Any other details to the provider?',
  'orderCallback.callBackSubmittedToast':
    'Call Back order submitted to provider',
  'orderCallback.submitted.title': 'Call Back Submitted',
  'orderCallback.submitted.description':
    'Your order has been sent to the provider.',
  'orderCallback.submitted.quantity': '{{quantity}} CY',
  'notification.action.markAsRead': 'Mark as Read',
  'notification.action.viewOrder': 'View Order',
  'notification.action.addNumber': 'Add Number',
  // Required Actions
  'requiredActions.orderNumberMissing.title': 'Missing Order Number',
  'requiredActions.orderNumberMissing.text':
    '{{orderName}} doesn’t have a batching software number. Add one before batching begins to sync ticket data with this order.',
  'requiredActions.callbackConfirmed.title': 'Call Back Confirmed',
  'requiredActions.callbackConfirmed.text':
    'Your call back order has been received.',
  'requiredActions.callbackNeeded.title': 'Call Back Reminder',
  'requiredActions.callbackNeeded.text':
    'Let the provider know if you need a call back order.',
  'requiredActions.orderConfirmationNeeded.title': 'Confirmation Needed',
  'requiredActions.orderConfirmationNeeded.text':
    'The order {{orderName}} awaits your confirmation.',
  'requiredActions.projectCreatedContractor.title': 'New Project Created',
  'requiredActions.projectCreatedContractor.text':
    '{{providerName}} created a new project.',
  'requiredActions.orderCallbackSubmitted.title': 'Call Back Submitted',
  'requiredActions.orderCallbackSubmitted.text':
    '{{companyName}} has issued a call back for additional concrete on {{orderName}}. Let them know it’s on the way.',

  // support
  'support.title': 'Contact Support ',
  'support.subject': 'Subject',
  'support.subject.placeholder': 'Select Subject',
  'support.description': 'How can we help you?',
  'support.description.placeholder': 'Please tell us more about the issue.',
  'support.submit': 'Submit',
  'support.send': 'Your support request has been sent.',

  // file handling
  'common.file.photoLibrary': 'Photo Library',
  'common.file.camera': 'Camera',
};
