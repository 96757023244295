import {
  currentUserInfoEndpoint,
  forgotPasswordEndpoint,
  userEndpoint,
  userTokenEndpoint,
} from '../constants/endpoints';
import { APIForgotPasswordRequest, APIUser } from '../types/api';
import { httpMethod } from '../utils/sra';
import {
  mapUserToCreateUserRequest,
  mapUserToUpdateUserRequest,
} from '../utils/user';

export type CoreUser = {
  requestForgotPasswordPending?: boolean;
  fetchCurrentUserPending?: boolean;
  fetchUserPending?: boolean;
  updateUserPasswordPending?: boolean;
  createUserPending?: boolean;
  updateUserPending?: boolean;
  validateTempTokenPending?: boolean;
};

export const coreUserState: CoreUser = {
  requestForgotPasswordPending: false,
  fetchCurrentUserPending: false,
  fetchUserPending: false,
  updateUserPasswordPending: false,
  createUserPending: false,
  updateUserPending: false,
  validateTempTokenPending: false,
};

export const CREATE_USER_ACTION_NAME = 'createUser';
export const createUser = (
  providerId: string,
  customerId: string | undefined,
  body: Partial<APIUser>,
  providerIds?: string[],
) => ({
  name: CREATE_USER_ACTION_NAME,
  url: userEndpoint(providerId),
  method: httpMethod.post,
  body: () =>
    mapUserToCreateUserRequest({
      user: body,
      providerId,
      customerId,
      providerIds,
    }),
});

export const UPDATE_USER_ACTION_NAME = 'updateUser';
export const updateUser = (
  providerId: string,
  userId: string,
  body: Partial<APIUser>,
  providerIds?: string[],
) => ({
  name: UPDATE_USER_ACTION_NAME,
  url: userEndpoint(providerId, userId),
  method: httpMethod.put,
  body: () => mapUserToUpdateUserRequest(body, providerId, providerIds),
});

const requestForgotPassword = (email: APIForgotPasswordRequest['email']) => ({
  name: 'requestForgotPassword',
  url: forgotPasswordEndpoint(),
  method: httpMethod.post,
  body: {
    email,
  },
});

const fetchCurrentUser = () => ({
  name: 'fetchCurrentUser',
  url: currentUserInfoEndpoint(),
  method: httpMethod.get,
});

const fetchUser = (providerId: string, userId: string) => ({
  name: 'fetchUser',
  url: userEndpoint(providerId, userId),
  method: httpMethod.get,
});

const updateUserPassword = (
  providerId: string,
  user: APIUser,
  password: string,
) => ({
  name: 'updateUserPassword',
  url: userEndpoint(providerId, user?.id),
  method: httpMethod.put,
  body: () => mapUserToUpdateUserRequest({ ...user, password }, providerId),
});

const validateTempToken = (token: string) => ({
  name: 'validateTempToken',
  url: userTokenEndpoint(),
  body: { token },
  method: httpMethod.post,
});

export const coreUserMethods = {
  createUser,
  updateUser,
  requestForgotPassword,
  fetchUser,
  fetchCurrentUser,
  updateUserPassword,
  validateTempToken,
};
