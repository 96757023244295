import { TFunction } from 'i18next';

import {
  CHAT_BOT_MESSAGE_SENDER_ID,
  CHAT_SYSTEM_MESSAGE_SENDER_ID,
} from '../constants/common';
import { dayjs } from '../third-party';
import { APIChatMessageModel } from '../types/api';

export const chatMessagesSortingFunc = (
  a: APIChatMessageModel,
  b: APIChatMessageModel,
) => {
  return dayjs(a.createdOn).diff(dayjs(b.createdOn));
};

export const getChatDateSeparator = (
  date: string | Date | number,
  t: TFunction,
) => {
  let dayString = '';
  let yearString = '';
  const parsedDate = dayjs(date);

  if (parsedDate.isToday()) {
    dayString = `${t('common.today')} `;
  } else if (parsedDate.isYesterday()) {
    dayString = `${t('common.yesterday')} `;
  }

  if (dayjs().year() !== parsedDate.year()) {
    yearString = ', ' + parsedDate.year();
  }

  return `${dayString}${parsedDate.format('ddd, MMM D')}${yearString}`;
};

export const isSameDate = (firstDate: string, secondDate: string) =>
  dayjs(firstDate).isSameOrBefore(secondDate, 'day');

export const isSystemMessage = (senderId: string | number) =>
  senderId === CHAT_SYSTEM_MESSAGE_SENDER_ID;

export const isMessageFromRegularUser = (senderId: string | number) =>
  ![CHAT_SYSTEM_MESSAGE_SENDER_ID, CHAT_BOT_MESSAGE_SENDER_ID].includes(
    String(senderId),
  );

export const isBotMessage = (senderId: string | number) =>
  senderId === CHAT_BOT_MESSAGE_SENDER_ID;
