import { useCallback } from 'react';

import { useHandleApiResultFactory } from '@cd3p/core/hooks/useHandleApiResultFactory';
import { Notification } from '@cd3p/core/modules/notifications';
import { APIServerError } from '@cd3p/core/types/common';
import { NavigateOptions } from 'react-router-dom';

import { useNavigate, useTranslation } from 'third-party';

import { NotificationSeverity, useNotifications } from 'modules/notifications';

export const useRedirectOn404 = () => {
  const { t } = useTranslation();
  const { addNotification } = useNotifications();
  const navigate = useNavigate();

  return useCallback(
    (
      result: APIServerError | undefined,
      redirectUrlOrCallback?: string | ((...args: any[]) => void),
      options: {
        notification?: Partial<Notification>;
        navigate?: Partial<NavigateOptions>;
      } = { notification: {}, navigate: {} },
    ) => {
      if (result?.payload?.status === 404) {
        addNotification({
          severity: NotificationSeverity.Error,
          message: t('common.404Error'),
          ...options.notification,
        });
        if (typeof redirectUrlOrCallback === 'string') {
          navigate(redirectUrlOrCallback, {
            replace: true,
            ...options.navigate,
          });
        } else if (typeof redirectUrlOrCallback === 'function') {
          redirectUrlOrCallback();
        }

        return true;
      }
      return false;
    },
    [addNotification, navigate, t],
  );
};

export const useHandleApiResult = () => {
  const { t } = useTranslation();
  const { addNotification } = useNotifications();

  const redirectOn404 = useRedirectOn404();

  return useHandleApiResultFactory(t, addNotification, redirectOn404);
};
