import { useMemo } from 'react';

import { Core } from '@cd3p/core/config';
import {
  providerEndpoint,
  userSelfEndpoint,
  userTokenEndpoint,
} from '@cd3p/core/constants/endpoints';
import {
  CoreAppState,
  coreAppMethods,
  coreAppState,
} from '@cd3p/core/modules/app';
import { APIProvider, APIUser, APIUserType } from '@cd3p/core/types/api';
import {
  mapUserToCreateUserRequest,
  mapUserToUpdateUserRequest,
} from '@cd3p/core/utils/user';
import i18next from 'i18n';

import {
  bindActionCreators,
  httpMethod,
  simplifyBuilder,
  useDispatch,
  useSelector,
} from 'third-party';

import {
  userAuthEndpoint,
  userEndpoint,
  userMeEndpoint,
} from 'constants/endpoints';

import { appSelectors } from 'selectors';

import { deleteUserToken, saveUserToken } from 'utils/auth';

export enum AppLeftPanel {
  RequiredActions = 'requiredActions',
  Dialogs = 'dialogs',
}

export type AppState = Omit<CoreAppState, 'user'> & {
  user: Partial<APIUser>;
  openedLeftPanel: null | AppLeftPanel;
  userLoginInPending: boolean;
  updateCurrentUserPending: boolean;
  validateTempTokenPending: boolean;
  userInfoPending: boolean | null;
  userLoginInErrorText: string;
  userInfoError: boolean | null;
  updateUserByIdPending: boolean;
  createUserPending: boolean;
  updateProviderPending: boolean;
};

export const appState: AppState = {
  ...coreAppState,
  user: {
    providers: [],
  },
  openedLeftPanel: null,
  userLoginInPending: false,
  updateCurrentUserPending: false,
  validateTempTokenPending: false,
  updateProviderPending: false,
  userInfoPending: null,
  userLoginInErrorText: '',
  userInfoError: false,
  updateUserByIdPending: false,
  createUserPending: false,
};

const builder = simplifyBuilder(appState, {});

export const setIsAppLoaded = builder.createReduxAction(
  (isAppLoaded: boolean) => ({
    name: 'setIsAppLoaded',
    updater: state => ({
      ...state,
      isAppLoaded: isAppLoaded,
    }),
  }),
);

const getUserInfo = builder.createServerAction(() => ({
  name: 'userInfo',
  url: userMeEndpoint(),
  method: httpMethod.get,
  onSuccess: (state: AppState, payload: APIUser) => {
    // do not allow to login user with different group type
    const { userType } = payload;
    const isAdminLogin =
      Core.isAdminPortal() && userType == APIUserType.Support;

    const isUserLogIn = !Core.isAdminPortal() && userType != APIUserType.Driver;

    if (!isAdminLogin && !isUserLogIn) {
      deleteUserToken();
      return {
        userLoginInErrorText: i18next.t('login.alert.incorrectCredentials'),
      };
    }

    return {
      user: {
        ...payload,
        providers: payload?.providers?.map((it, index) => ({
          ...it,
          isSelected: index == 0,
        })),
      },
    };
  },
}));

const userLogIn = builder.createServerAction(
  (email: string, password: string) => ({
    name: 'userLoginIn',
    url: userAuthEndpoint(),
    method: httpMethod.post,
    body: { email, password },
    onRequest: () => ({
      userLoginInErrorText: '',
    }),
    onSuccess: (state: AppState, payload: string) => {
      saveUserToken(payload);
    },
  }),
);

const validateTempToken = builder.createServerAction((token: string) => ({
  name: 'validateTempToken',
  url: userTokenEndpoint(),
  body: { token },
  method: httpMethod.post,
  onSuccess: (state: AppState, payload: string) => {
    saveUserToken(payload);
  },
}));

const createUser = builder.createServerAction(
  (
    providerId: string,
    customerId: string | undefined,
    body: Partial<APIUser>,
    providerIds?: string[],
  ) => ({
    name: 'createUser',
    url: userEndpoint(providerId),
    method: httpMethod.post,
    body: () =>
      mapUserToCreateUserRequest({
        user: body,
        providerId,
        customerId,
        providerIds,
      }),
  }),
);

const updateUserById = builder.createServerAction(
  (
    providerId: string,
    userId: string,
    body: Partial<APIUser>,
    providerIds?: string[],
  ) => ({
    name: 'updateUserById',
    url: userEndpoint(providerId, userId),
    method: httpMethod.put,
    body: () => mapUserToUpdateUserRequest(body, providerId, providerIds),
  }),
);

const updateCurrentUser = builder.createServerAction(
  (providerId: string, user: Partial<APIUser>, password?: string) => ({
    name: 'updateCurrentUser',
    url: userSelfEndpoint(providerId),
    method: httpMethod.put,
    body: () => mapUserToUpdateUserRequest({ ...user, password }, providerId),
    onSuccess: (state: AppState, payload: APIUser) => ({
      user: payload,
    }),
  }),
);

const updateProvider = builder.createServerAction(
  (providerId: string, body: Partial<APIProvider>) => ({
    name: 'updateProvider',
    url: providerEndpoint(providerId),
    method: httpMethod.put,
    body,
  }),
);

const fetchProvider = builder.createServerAction(coreAppMethods.fetchProvider);
const updateProviderSettings = builder.createServerAction(
  coreAppMethods.updateProviderSettings,
);

const loadProviderSettings = builder.createServerAction(
  coreAppMethods.loadProviderSettings,
);

const fetchProviderSettings = builder.createServerAction(
  coreAppMethods.fetchProviderSettings,
);

const fetchProviderAfterHoursSettings = builder.createServerAction(
  coreAppMethods.fetchProviderAfterHoursSettings,
);

const setOpenedChatOrderId = builder.createReduxAction(
  (openedChatOrderId: number | null) => ({
    name: 'setOpenedChatOrderId',
    updater: () => ({
      openedChatOrderId,
    }),
  }),
);

const setSelectedProvider = builder.createReduxAction((providerId: string) => ({
  name: 'setSelectedProvider',
  updater: state => ({
    ...state,
    user: {
      ...state.user,
      providers: state.user?.providers?.map(it => ({
        ...it,
        isSelected: it.id === providerId,
      })),
    },
  }),
}));

const registerUserDevice = builder.createServerAction(
  coreAppMethods.registerUserDevice,
);

export const useApp = () => {
  const dispatch = useDispatch();
  const providerId = useSelector(appSelectors.providerId);

  return useMemo(
    () =>
      bindActionCreators(
        {
          updateCurrentUser: updateCurrentUser.bind(null, providerId),
          updateUserById: updateUserById.bind(null, providerId),
          createUser: createUser.bind(null, providerId),
          updateProvider: updateProvider.bind(null, providerId),
          updateProviderSettings: updateProviderSettings.bind(null),
          // without binding TS doesn't infer type for module functions
          getUserInfo: getUserInfo.bind(null),
          userLogIn: userLogIn.bind(null),
          validateTempToken: validateTempToken.bind(null),
          fetchProvider: fetchProvider.bind(null),
          loadProviderSettings: loadProviderSettings.bind(null),
          fetchProviderSettings: fetchProviderSettings.bind(null),
          fetchProviderAfterHoursSettings:
            fetchProviderAfterHoursSettings.bind(null),
          setOpenedChatOrderId: setOpenedChatOrderId.bind(null),
          setIsAppLoaded: setIsAppLoaded.bind(null),
          setSelectedProvider: setSelectedProvider.bind(null),
          registerUserDevice: registerUserDevice.bind(null),
        },
        dispatch,
      ),
    [dispatch, providerId],
  );
};

export const appReducer = builder.getReducers();
