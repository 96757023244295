import React, { ReactNode, useRef } from 'react';

import styled from 'styled-components';

import { CSSTransition, Stack } from 'components';

export const RIGHT_PANEL_WIDTH = '37rem';

const AnimationStyles = styled(Stack)`
  z-index: 6;
  .css-right-panel-enter {
    width: 0;
  }
  .css-right-panel-enter-active {
    transition: width 500ms;
    width: ${RIGHT_PANEL_WIDTH};
  }
  .css-right-panel-exit {
    width: ${RIGHT_PANEL_WIDTH};
  }
  .css-right-panel-exit-active {
    transition: width 500ms;
    width: 0;
  }
`;

const Wrapper = styled(Stack)`
  width: ${RIGHT_PANEL_WIDTH};
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
  border-left: 1px solid ${props => props.theme.custom.palette.muted100};
  flex-grow: 1;
  position: relative;
  overflow: hidden;
`;

type RightPanelContainerProps = {
  children?: ReactNode;
  isPanelOpen: boolean;
  animationDuration?: number;
  onPanelHideCallback?: () => void;
};

export const RightPanelContainer = ({
  children,
  isPanelOpen,
  animationDuration = 500,
  onPanelHideCallback,
}: RightPanelContainerProps) => {
  const panelRef = useRef(null);
  return (
    <AnimationStyles>
      <CSSTransition
        nodeRef={panelRef}
        in={isPanelOpen}
        timeout={animationDuration}
        classNames="css-right-panel"
        unmountOnExit
        onExited={onPanelHideCallback}
      >
        <Wrapper ref={panelRef}>{children}</Wrapper>
      </CSSTransition>
    </AnimationStyles>
  );
};
