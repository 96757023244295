import { Base64 } from 'js-base64';

import { APIDialogModel, APIOrderSubscription } from '../types/api';

export type GlobalCache = {
  userSubscriptions: APIOrderSubscription[];
  dialogs: APIDialogModel[];
  dialogsSubscription: APIDialogModel[];
  dialogToken: string;
  version: string;
};

export const emptyGlobalCache: GlobalCache = {
  userSubscriptions: [],
  dialogs: [],
  dialogsSubscription: [],
  dialogToken: '',
  version: '',
};

type CacheInitOptions = {
  getGlobalCache: (providerId: string) => GlobalCache;
  setGlobalCache: (providerId: string, cache: GlobalCache) => GlobalCache;
  maxDialogLength: number;
  maxSubscriptionLength: number;
};

const config: CacheInitOptions = {
  getGlobalCache: () => {
    throw new Error('');
  },
  setGlobalCache: () => {
    throw new Error('');
  },
  maxDialogLength: 100,
  maxSubscriptionLength: 100,
};

export const initGlobalCache = ({
  getGlobalCache,
  setGlobalCache,
  maxDialogLength,
  maxSubscriptionLength,
}: CacheInitOptions) => {
  config.getGlobalCache = getGlobalCache;
  config.setGlobalCache = setGlobalCache;
  config.maxDialogLength = maxDialogLength;
  config.maxSubscriptionLength = maxSubscriptionLength;
};

/**
 * Invalidates the global cache for a specific provider.
 * This function resets the cache for the given provider ID and updates the version if provided.
 *
 * @param providerId - The ID of the provider.
 * @param newVersion - Optional. The new version to update the cache with.
 * @returns The updated global cache.
 */
export const invalidateCache = (providerId: string, newVersion: string) => {
  const cache = {
    ...emptyGlobalCache,
    version: newVersion,
  };

  return config.setGlobalCache(providerId, cache);
};

/**
 * Retrieves the last synchronization date for a specific provider from the global cache.
 * If the global cache is not provided, it will be retrieved using the provider ID.
 * Returns the last updated date of the most recent dialog in the cache, or null if the cache is empty.
 *
 * @param providerId - The ID of the provider.
 * @param globalCache - Optional. The global cache object.
 * @returns The last synchronization date or null.
 */
export const getLastSyncDate = (
  providerId: string,
  globalCache?: GlobalCache,
) => {
  const cache = globalCache ?? config.getGlobalCache(providerId);

  if (!cache.dialogs?.length) {
    return null;
  }

  const lastDialog = cache.dialogs.reduce((prev, current) =>
    prev.ticks > current.ticks ? prev : current,
  );

  return lastDialog.updatedOn;
};

/**
 * Retrieves the timestamp of the last update for the user's subscriptions in the global cache.
 * If the global cache is not provided, it will be fetched using the provider ID.
 *
 * @param providerId The ID of the provider.
 * @param globalCache The optional global cache object.
 * @returns The timestamp of the last update, or null if there are no user subscriptions.
 */
export const getLastSubscriptionUpdate = (
  providerId: string,
  globalCache?: GlobalCache,
) => {
  const cache = globalCache ?? config.getGlobalCache(providerId);

  if (!cache.userSubscriptions?.length) {
    return null;
  }

  const lastSubscription = cache.userSubscriptions.reduce((prev, current) =>
    prev.ticks > current.ticks ? prev : current,
  );

  return lastSubscription.updatedOn;
};

/**
 * Updates the user subscriptions in the global cache.
 * If a global cache is provided, it updates the subscriptions in that cache.
 * Otherwise, it retrieves the global cache for the given provider ID and updates the subscriptions in that cache.
 * The updated subscriptions are filtered, sorted, and limited to a maximum number of items.
 * Finally, it sets the updated cache in the global cache and returns the updated cache.
 *
 * @param providerId - The ID of the provider.
 * @param userSubscriptions - The user subscriptions to update.
 * @param globalCache - The optional global cache to update. If not provided, the global cache for the provider ID will be retrieved.
 * @returns The updated global cache.
 */
export const updateUserSubscriptions = (
  providerId: string,
  userSubscriptions: APIOrderSubscription[],
  globalCache?: GlobalCache,
) => {
  const cache = globalCache ?? config.getGlobalCache(providerId);
  cache.userSubscriptions = filterSubscription([
    ...userSubscriptions,
    ...cache.userSubscriptions,
  ]);

  config.setGlobalCache(providerId, cache);
  return cache;
};

/**
 * Removes a user subscription from the global cache.
 * If a `globalCache` is provided, it will be used. Otherwise, a new global cache will be retrieved using the `providerId`.
 * The user subscription with the specified `orderId` will be removed from the `userSubscriptions` and `dialogsSubscription` arrays in the cache.
 * Finally, the updated cache will be saved and returned.
 *
 * @param providerId - The ID of the provider.
 * @param userSubscriptions - The user subscriptions object to be removed.
 * @param globalCache - (Optional) The global cache object.
 * @returns The updated global cache object.
 */
export const removeUserSubscription = (
  providerId: string,
  userSubscriptions: APIOrderSubscription,
  globalCache?: GlobalCache,
) => {
  const cache = globalCache ?? config.getGlobalCache(providerId);

  cache.userSubscriptions = cache.userSubscriptions.filter(
    (it) => it.orderId !== userSubscriptions.orderId,
  );

  cache.dialogsSubscription = cache.dialogsSubscription.filter(
    (it) => it.orderId !== userSubscriptions.orderId,
  );

  config.setGlobalCache(providerId, cache);
  return cache;
};

/**
 * Adds a user subscription and dialog to the global cache.
 * If `globalCache` is provided, it uses the provided cache, otherwise it retrieves the cache for the given `providerId`.
 *
 * @param providerId - The ID of the provider.
 * @param subscription - The user subscription to add.
 * @param dialog - The dialog to add.
 * @param globalCache - The global cache to update (optional).
 * @returns The updated global cache.
 */
export const addUserSubscription = (
  providerId: string,
  subscription: APIOrderSubscription,
  dialog: APIDialogModel,
  globalCache?: GlobalCache,
) => {
  const cache = globalCache ?? config.getGlobalCache(providerId);

  cache.userSubscriptions = filterSubscription([
    ...cache.userSubscriptions,
    subscription,
  ]);

  cache.dialogsSubscription = filterDialogs([
    ...cache.dialogsSubscription,
    dialog,
  ]);

  config.setGlobalCache(providerId, cache);
  return cache;
};

/**
 * Filters and sorts an array of APIDialogModel objects based on their ticks and orderId properties.
 * Removes duplicate dialogs with the same orderId and keeps only the ones with the highest ticks value.
 * Returns a new array with a maximum length of maxDialogLength.
 *
 * @param dialogs - The array of APIDialogModel objects to filter and sort.
 * @returns The filtered and sorted array of APIDialogModel objects.
 */
export const filterDialogs = (dialogs: APIDialogModel[]) => {
  return dialogs
    .sort((a, b) => b.ticks - a.ticks)
    .filter(
      (its, index, self) =>
        index ===
        self.findIndex(
          (it) => it.orderId === its.orderId && it.ticks >= its.ticks,
        ),
    )
    .slice(0, config.maxDialogLength);
};

/**
 * Filters and sorts an array of APIOrderSubscription objects based on their ticks and orderId properties.
 * Removes duplicate subscription with the same orderId and keeps only the ones with the highest ticks value.
 * Returns a new array with a maximum length of MAX_SUBSCRIPTION_ITEMS_LENGTH.
 *
 * @param subscription - The array of APIOrderSubscription objects to filter and sort.
 * @returns The filtered and sorted array of APIOrderSubscription objects.
 */
export const filterSubscription = (subscription: APIOrderSubscription[]) => {
  const toRemove = subscription.filter((it) => it.isDeleted);

  return subscription
    .filter((it) => !toRemove.includes(it))
    .sort((a, b) => b.ticks - a.ticks)
    .filter(
      (its, index, self) =>
        index ===
        self.findIndex(
          (it) => it.orderId === its.orderId && it.ticks >= its.ticks,
        ),
    )
    .slice(0, config.maxSubscriptionLength);
};

/**
 * Updates the dialogs in the global cache for a specific provider.
 * If a global cache is provided, it updates the cache directly.
 * Otherwise, it retrieves the global cache for the provider and updates it.
 *
 * @param providerId - The ID of the provider.
 * @param dialogs - An array of dialog models to update the cache with.
 * @param token - The token associated with the dialogs.
 * @param globalCache - (Optional) The global cache object to update. If not provided, the global cache will be retrieved.
 * @returns The updated global cache object.
 */
export const updateDialogs = (
  providerId: string,
  dialogs: APIDialogModel[],
  token: string,
  globalCache?: GlobalCache,
) => {
  const cache = globalCache ?? config.getGlobalCache(providerId);

  cache.dialogs = filterDialogs([...cache.dialogs, ...dialogs]);
  cache.dialogToken = token;

  return config.setGlobalCache(providerId, cache);
};

/**
 * Updates the dialog in the global cache for a specific provider.
 * If the global cache is not provided, it will be retrieved using the providerId.
 *
 * @param providerId - The ID of the provider.
 * @param dialog - The dialog to be updated in the cache.
 * @param globalCache - The optional global cache object.
 * @returns The updated global cache.
 */
export const updateDialog = (
  providerId: string,
  dialog: APIDialogModel,
  globalCache?: GlobalCache,
) => {
  const cache = globalCache ?? config.getGlobalCache(providerId);

  cache.dialogs = filterDialogs([dialog, ...cache.dialogs]);
  const dialogSubscription = cache.dialogsSubscription.find(
    (it) => it.orderId === dialog.orderId,
  );
  if (dialogSubscription) {
    cache.dialogsSubscription = filterDialogs([
      ...cache.dialogsSubscription,
      dialog,
    ]);
  }

  return config.setGlobalCache(providerId, cache);
};

/**
 * Retrieves the order IDs of user subscriptions that do not have corresponding dialogs or dialog subscriptions in the global cache.
 * If a `globalCache` object is provided, it will be used. Otherwise, a new global cache will be created using the `providerId`.
 *
 * @param providerId - The ID of the provider.
 * @param globalCache - Optional. The global cache object.
 * @returns An array of order IDs as strings.
 */
export const getAbsentSubscriptionDialog = (
  providerId: string,
  globalCache?: GlobalCache,
) => {
  const cache = globalCache ?? config.getGlobalCache(providerId);

  return cache.userSubscriptions
    .filter(
      (it) =>
        !cache.dialogs.find((di) => di.orderId == it.orderId) &&
        !cache.dialogsSubscription.find((di) => di.orderId == it.orderId),
    )
    .map((it) => it.orderId.toString());
};

/**
 * Retrieves the existing subscription dialog(s) for a given provider ID in global cache.
 * If a global cache is provided, it will be used; otherwise, a new global cache will be created.
 *
 * @param providerId The ID of the provider.
 * @param globalCache Optional global cache object.
 * @returns An array of existing subscription dialog(s) associated with the provider ID.
 */
export const getExistSubscriptionDialog = (
  providerId: string,
  globalCache?: GlobalCache,
) => {
  const cache = globalCache ?? config.getGlobalCache(providerId);

  return cache.userSubscriptions.flatMap((sub) => {
    const dialog = cache.dialogs.find((it) => it.orderId === sub.orderId);
    return dialog
      ? [dialog]
      : cache.dialogsSubscription.filter((it) => it.orderId === sub.orderId);
  });
};

/**
 * Generates the next token based on the provided provider ID and last dialog.
 *
 * @param providerId - The ID of the provider.
 * @param lastDialog - The last dialog object.
 * @returns The next token encoded in base64.
 */
export const getNextToken = (
  providerId: string,
  companyId: string,
  globalCache?: GlobalCache,
) => {
  const cache = globalCache ?? config.getGlobalCache(providerId);

  // find the oldest dialog in the cache
  const lastDialog = cache.dialogs.reduce((prev, current) =>
    prev.ticks < current.ticks ? prev : current,
  );

  const tokenObject = {
    ...(companyId ? { cid: companyId } : { pid: providerId }),
    tid: lastDialog.orderId.toString(),
    uon: lastDialog.ticks,
  };

  // convert to base64
  return Base64.btoa(
    // we need co
    JSON.stringify(tokenObject, (_, it) =>
      typeof it === 'bigint' ? it.toString() : it,
    ),
  );
};

/**
 * Updates the dialog subscriptions in the global cache for a specific provider.
 * If a global cache is provided, it will be used; otherwise, a new global cache will be created.
 *
 * @param providerId - The ID of the provider.
 * @param dialogs - An array of dialog models to update the subscriptions with.
 * @param globalCache - (Optional) The global cache to update. If not provided, a new global cache will be created.
 * @returns The updated global cache.
 */
export const updateDialogSubscriptions = (
  providerId: string,
  dialogs: APIDialogModel[],
  globalCache?: GlobalCache,
) => {
  const cache = globalCache ?? config.getGlobalCache(providerId);

  cache.dialogsSubscription = filterDialogs(dialogs);

  return config.setGlobalCache(providerId, cache);
};

/**
 * Retrieves the dialogs from the cache for a specific provider.
 * @param providerId The ID of the provider.
 * @returns The dialogs from the cache.
 */
export const getDialogsFromCache = (providerId: string) => {
  const cache = config.getGlobalCache(providerId);
  return cache.dialogs;
};
