import { useMemo } from 'react';

import { APIImportedOrder } from '@cd3p/core/types/api';
import { httpMethod, simplifyBuilder } from '@cd3p/core/utils/sra';

import { bindActionCreators, useDispatch, useSelector } from 'third-party';

import {
  downloadImportOrdersSampleFileEndpoint,
  importOrdersEndpoint,
} from 'constants/endpoints';

import { appSelectors } from 'selectors';

export interface ImportOrdersState {
  lastImportedOrders: APIImportedOrder[];
  importOrdersPending: boolean;
  importOrdersError?: { response: Response };
}

export const importOrdersState: ImportOrdersState = {
  lastImportedOrders: [],
  importOrdersPending: false,
};

const builder = simplifyBuilder(importOrdersState, {});

const importOrders = builder.createServerAction(
  (providerId: string, data: FormData) => ({
    name: 'importOrders',
    url: importOrdersEndpoint(providerId),
    method: httpMethod.post,
    body: data,
    onRequest: () => ({
      lastImportedOrders: [],
      importOrdersError: undefined,
    }),
    onSuccess: (state: ImportOrdersState, payload: APIImportedOrder[]) => ({
      lastImportedOrders: payload,
    }),
  }),
);

const downloadSampleFile = builder.createServerAction((providerId: string) => ({
  name: 'downloadSampleFile',
  url: downloadImportOrdersSampleFileEndpoint(providerId),
  method: httpMethod.get,
  responseReader: async (response: Response) => await response.blob(),
  onSuccess: async (state: ImportOrdersState, payload: Blob) => {
    const fileURL = URL.createObjectURL(payload);
    const anchor = document.createElement('a');
    anchor.href = fileURL;
    anchor.download = 'sample';
    document.body.appendChild(anchor);
    anchor.click();
  },
}));

const clearImportResult = builder.createReduxAction(() => ({
  name: 'clearImportResult',
  updater: () => ({
    importOrdersError: undefined,
    lastImportedOrders: [],
  }),
}));

export const useImportOrders = () => {
  const dispatch = useDispatch();
  const providerId = useSelector(appSelectors.providerId);
  return useMemo(
    () =>
      bindActionCreators(
        {
          importOrders: importOrders.bind(null, providerId),
          downloadSampleFile: downloadSampleFile.bind(null, providerId),
          // without binding TS doesn't infer type for module functions
          clearImportResult: clearImportResult.bind(null),
        },
        dispatch,
      ),
    [dispatch, providerId],
  );
};

export const importOrdersReducer = builder.getReducers();
