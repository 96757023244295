import React, { useCallback, useEffect, useState } from 'react';

import styled from 'styled-components';

import CloseIcon from '@mui/icons-material/Close';

import { DispatcherCanAccess, IconButton, Stack, Typography } from 'components';
import i18n from 'i18n';

import { MostLeastUsedCommonPanel } from '../common/MostLeastUsedCommonPanel';
import { useManageAdditiveTypeItem } from '../common/useManageAdditiveTypeItem';
import { AdditiveTypesTable } from './AdditiveTypesTable';
import { LeastOrderedAdditivesWidget } from './LeastOrderedAdditivesWidget';
import { MostOrderedAdditivesWidget } from './MostOrderedAdditivesWidget';

import { useSelector, useTranslation } from 'third-party';

import { projectAdditiveTypesUrl } from 'constants/url';

import {
  AdditiveTypesFilters,
  AdditiveTypesSorting,
  useAdditiveTypesList,
} from 'modules/additiveTypesList';

import { additiveTypesSelectors } from 'selectors';

import {
  RIGHT_PANEL_WIDTH,
  RightPanelContainer,
} from 'components/RightPanelContainer/RightPanelContainer';

import { AddAdditiveTypeForm } from 'features/PlantSettings/AdditiveTypeTab/AddAdditiveTypeForm';
import { IndividualProjectAdditivesWidget } from 'features/PlantSettings/AdditiveTypeTab/IndividualProjectAdditivesWidget';
import { IndividualProjectMixesOrAdditives } from 'features/PlantSettings/common/IndividualProjectMixesOrAdditives';

import { OutlinedSearchInput } from 'styles/common';

const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
`;

const Wrapper = styled(Stack)`
  flex-grow: 1;
  min-height: 0;
  padding: 0 ${props => props.theme.custom.dimensions.viewHorizontalPadding}rem
    ${props => props.theme.custom.dimensions.viewVerticalPadding}rem;
`;

const SearchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const InsightsWrapper = styled(Stack)`
  margin-top: 1rem;
  flex-direction: row;
  gap: 1rem;
  > * {
    flex: 1;
  }
`;

const Header = styled(Typography)`
  color: ${props => props.theme.custom.palette.secondary900};
  margin-top: 3.2rem;
`;

const PanelHeader = styled(Stack)`
  min-height: 5rem;
  height: 5rem;
  align-items: center;
  padding: 0 1rem 0 2rem;
  border-bottom: 1px solid ${props => props.theme.custom.palette.muted100};
`;

const PanelInnerContent = styled(Stack)`
  flex-grow: 1;
  min-width: ${RIGHT_PANEL_WIDTH};
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
`;

const PanelTitle = styled(Typography)`
  color: ${props => props.theme.custom.palette.primary900};
`;

const StyledSearchInput = styled(OutlinedSearchInput)`
  width: 20rem;
`;

const CloseButton = styled(IconButton)`
  margin-left: auto;
`;

export const editFormTranslations = {
  submitButtonLabel: i18n.t('common.save'),
  title: i18n.t('additiveTypes.edit.title'),
  nameFieldLabel: i18n.t('additiveTypes.nameFieldLabel'),
  nameFieldPlaceholder: i18n.t('additiveTypes.nameFieldLabel'),
  categoryFieldLabel: i18n.t('additiveTypes.categoryFieldLabel'),
  categoryFieldPlaceholder: i18n.t('additiveTypes.categoryFieldLabel'),
  typeaheadPlaceholder: i18n.t('additiveTypes.categoryFieldPlaceholder'),
  successUpdateMessage: i18n.t('additiveTypes.notification.successUpdate'),
};

enum RightPanelContent {
  IndividualProjectsAdditives,
  MostUsedAdditives,
  LeastUsedAdditives,
}

export const AdditiveTypesView = () => {
  const { t } = useTranslation();
  const {
    loadAdditiveTypes,
    updateAdditiveTypes,
    updateAdditiveTypesListFilters,
    resetAdditiveTypes,
    loadMostUsedAdditiveTypes,
    loadLeastUsedAdditiveTypes,
    loadMoreLeastUsedAdditiveTypes,
    loadProjectsAdditiveTypes,
    loadMoreProjectsAdditiveTypes,
    addAdditiveTypeToMasterList,
  } = useAdditiveTypesList();

  const {
    additiveTypeItemDeleteConfirmation,
    additiveTypeItemEditPopup,
    setAdditiveTypeIdForEdit,
    setAdditiveTypeIdForDelete,
  } = useManageAdditiveTypeItem();

  const [rightPanelContent, setRightPanelContent] =
    useState<RightPanelContent | null>(null);

  const additiveTypesListItems = useSelector(
    additiveTypesSelectors.projectAdditiveTypesItems,
  );
  const additiveTypesListCount = useSelector(
    additiveTypesSelectors.projectAdditiveTypesCount,
  );
  const loadProjectAdditiveTypesPending = useSelector(
    additiveTypesSelectors.loadProjectAdditiveTypesPending,
  );
  const loadMoreAdditiveTypesPending = useSelector(
    additiveTypesSelectors.loadMoreProjectAdditiveTypesPending,
  );
  const projectAdditiveTypesLoaded = useSelector(
    additiveTypesSelectors.projectAdditiveTypesLoaded,
  );
  const addAdditiveTypeToMasterListPending = useSelector(
    additiveTypesSelectors.addAdditiveTypeToMasterListPending,
  );
  const additiveTypesLoaded = useSelector(
    additiveTypesSelectors.additiveTypesLoaded,
  );
  const sorting = useSelector(additiveTypesSelectors.additiveTypesListSorting);
  const filters = useSelector(additiveTypesSelectors.additiveTypesListFilters);
  const mostUsedAdditiveTypesItems =
    useSelector(additiveTypesSelectors.mostUsedAdditiveTypesItems) || [];

  const leastUsedAdditiveTypesItems = useSelector(
    additiveTypesSelectors.leastUsedAdditiveTypesItems,
  );
  const loadMoreLeastUsedAdditiveTypesPending = useSelector(
    additiveTypesSelectors.loadMoreLeastUsedAdditiveTypesPending,
  );
  const leastUsedAdditiveTypesCount = useSelector(
    additiveTypesSelectors.leastUsedAdditiveTypesCount,
  );

  const fetchAdditiveTypes = useCallback(
    (
      params?: {
        searchSortOrders?: AdditiveTypesSorting;
      } & Partial<AdditiveTypesFilters>,
    ) => {
      loadAdditiveTypes({
        searchSortOrders: sorting,
        ...filters,
        ...params,
      });
    },
    [loadAdditiveTypes, sorting, filters],
  );

  useEffect(() => {
    if (!additiveTypesLoaded) fetchAdditiveTypes();
  }, [fetchAdditiveTypes, additiveTypesLoaded]);

  useEffect(() => {
    return () => {
      resetAdditiveTypes();
    };
  }, [resetAdditiveTypes]);

  const onSearchChange = useCallback(
    (value: string) => {
      fetchAdditiveTypes({ name: value });
      updateAdditiveTypesListFilters({ name: value });
    },
    [fetchAdditiveTypes, updateAdditiveTypesListFilters],
  );

  const handleOpenRightPanel = useCallback(
    (panelContent: RightPanelContent) => {
      setRightPanelContent(state =>
        state === panelContent ? null : panelContent,
      );
    },
    [],
  );

  return (
    <Container>
      {additiveTypeItemDeleteConfirmation}
      {additiveTypeItemEditPopup}
      <Wrapper direction="column">
        <SearchWrapper>
          <Header variant="h5">{t('additiveTypes.header')}</Header>
          <StyledSearchInput
            placeholder={t('additiveTypes.searchPlaceholder')}
            onChange={onSearchChange}
          />
        </SearchWrapper>
        <InsightsWrapper>
          <MostOrderedAdditivesWidget
            isActive={rightPanelContent === RightPanelContent.MostUsedAdditives}
            onClick={() =>
              handleOpenRightPanel(RightPanelContent.MostUsedAdditives)
            }
          />
          <LeastOrderedAdditivesWidget
            isActive={
              rightPanelContent === RightPanelContent.LeastUsedAdditives
            }
            onClick={() =>
              handleOpenRightPanel(RightPanelContent.LeastUsedAdditives)
            }
          />
          <DispatcherCanAccess>
            <IndividualProjectAdditivesWidget
              isActive={
                rightPanelContent ===
                RightPanelContent.IndividualProjectsAdditives
              }
              onClick={() =>
                handleOpenRightPanel(
                  RightPanelContent.IndividualProjectsAdditives,
                )
              }
            />
          </DispatcherCanAccess>
        </InsightsWrapper>
        <AddAdditiveTypeForm onSuccess={updateAdditiveTypes} />
        <AdditiveTypesTable
          fetchAdditiveTypes={fetchAdditiveTypes}
          setAdditiveTypeIdForDelete={setAdditiveTypeIdForDelete}
          setAdditiveTypeIdForEdit={setAdditiveTypeIdForEdit}
        />
      </Wrapper>
      <RightPanelContainer isPanelOpen={rightPanelContent !== null}>
        <PanelInnerContent>
          <PanelHeader direction="row">
            <PanelTitle variant="h5">
              {rightPanelContent ===
                RightPanelContent.IndividualProjectsAdditives &&
                t('additiveTypes.projectSpecificPanel.title')}
              {rightPanelContent === RightPanelContent.MostUsedAdditives &&
                t('plantSettings.insightsAdditivesTypesMostTitle')}
              {rightPanelContent === RightPanelContent.LeastUsedAdditives &&
                t('plantSettings.insightsAdditivesTypesLeastTitle')}
            </PanelTitle>
            <CloseButton onClick={() => setRightPanelContent(null)}>
              <CloseIcon />
            </CloseButton>
          </PanelHeader>
          {rightPanelContent ===
            RightPanelContent.IndividualProjectsAdditives && (
            <IndividualProjectMixesOrAdditives
              items={additiveTypesListItems}
              itemUrl={projectAdditiveTypesUrl}
              dataTotalCount={additiveTypesListCount}
              updateData={loadProjectsAdditiveTypes}
              loadDataPending={loadProjectAdditiveTypesPending}
              loadMoreDataPending={loadMoreAdditiveTypesPending}
              dataLoaded={projectAdditiveTypesLoaded}
              addToMasterListPending={addAdditiveTypeToMasterListPending}
              loadMoreData={loadMoreProjectsAdditiveTypes}
              addItemToMasterList={addAdditiveTypeToMasterList}
              onAddToMasterList={updateAdditiveTypes}
              searchFieldPlaceholder={t(
                'additiveTypes.projectSpecificPanel.searchPlaceholder',
              )}
              emptyStateText={t(
                'plantSettings.insightsAdditiveTypesIndividualProjectsEmptyText',
              )}
              emptyStateTextForSearch={t(
                'additiveTypes.projectSpecificPanel.noAdditivesMatching',
              )}
            />
          )}
          {rightPanelContent === RightPanelContent.MostUsedAdditives && (
            <MostLeastUsedCommonPanel
              dataTotalCount={mostUsedAdditiveTypesItems.length}
              items={mostUsedAdditiveTypesItems}
              onEditClick={setAdditiveTypeIdForEdit}
              updateData={loadMostUsedAdditiveTypes}
            />
          )}
          {rightPanelContent === RightPanelContent.LeastUsedAdditives && (
            <MostLeastUsedCommonPanel
              items={leastUsedAdditiveTypesItems}
              onEditClick={setAdditiveTypeIdForEdit}
              onDeleteClick={setAdditiveTypeIdForDelete}
              dataTotalCount={leastUsedAdditiveTypesCount}
              updateData={loadLeastUsedAdditiveTypes}
              loadMoreData={loadMoreLeastUsedAdditiveTypes}
              loadMoreDataPending={loadMoreLeastUsedAdditiveTypesPending}
            />
          )}
        </PanelInnerContent>
      </RightPanelContainer>
    </Container>
  );
};
