import React, { useCallback, useMemo, useState } from 'react';

import styled from 'styled-components';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import {
  APIOrder,
  APIOrderStatus,
  APITicket,
  APIUserType,
} from '@cd3p/core/types/api';
import { getPageNumberAfterLoadMore } from '@cd3p/core/utils/lists';
import { HIDDEN_CY_PROGRESS_TICKET_STATUSES } from '@cd3p/core/utils/order';
import { truncateTruckNumber } from '@cd3p/core/utils/truck';
import {
  Button,
  CanAccess,
  ConfirmationDialog,
  DispatcherCanAccess,
  IconButton,
  Popover,
  Trans,
  TruckNumberTag,
  Typography,
} from 'components';
import { TicketsTable } from 'features';
import { OrderTicket } from 'selectors/order';

import {
  Route,
  Routes,
  useMatch,
  useNavigate,
  useParams,
  useResolvedPath,
  useSelector,
  useTranslation,
} from 'third-party';

import { ORDER_ADD_TICKET_PATH, PARENT_PATH } from 'constants/url';

import { TicketsListColumnId } from 'modules/common/ticketsList';
import { useNotifications } from 'modules/notifications';
import { useOrder } from 'modules/order';

import { appSelectors, orderSelectors } from 'selectors';

import { AddTicketView } from 'features/OrderView/AddTicketView';
import { TicketsTableColumn } from 'features/TicketsTable/TicketsTable';

import { canAddNewTickets } from 'utils/order';
import { composeLoadTicketsRequestBody } from 'utils/ticketsList';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 0;
`;

const StyledTitle = styled(Typography)`
  padding-top: 4rem;
  color: ${props => props.theme.custom.palette.secondary500};
`;

const EmptyText = styled(Typography)`
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 150%;
  color: ${props => props.theme.custom.palette.gray};
`;

const AddButton = styled(Button)`
  margin-top: 4rem;
  align-self: flex-start;
  font-size: 1.6rem;
  padding: 1.3rem 3.85rem;
`;

const AddButtonSecondary = styled(Button)`
  align-self: flex-end;
  font-weight: 900;
  font-size: 1.2rem;
  color: ${props => props.theme.custom.palette.secondary500};
  margin-left: auto;
`;

const TicketsTableWrapper = styled.div`
  flex-grow: 1;
  min-height: 0;
`;

const CellText = styled(Typography)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const CYProgressCell = styled(CellText)`
  font-weight: 700;
  font-size: 1.4rem;
  color: ${props => props.theme.custom.palette.muted800};
`;

const TableHeader = styled.div`
  padding-top: 2rem;
  padding-bottom: 1.6rem;
  display: flex;
  align-items: center;
`;

const LoadingText = styled(Typography)`
  padding: 1.6rem 0;
  color: ${props => props.theme.custom.palette.muted200};
`;

const TicketsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-height: 100%;
`;

const DeleteButton = styled(IconButton)`
  min-width: 0;
  color: ${props => props.theme.custom.palette.primary900};
`;

type Props = {
  order: APIOrder;
  orderStatus: APIOrderStatus;
  onAddNewTicket?: () => void;
};

enum TicketColumns {
  Truck = 'truck',
}

export const TicketsTab: React.FC<Props> = ({
  order,
  orderStatus,
  onAddNewTicket,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const { loadMoreOrderTickets, removeTicket } = useOrder();

  // :ticketId and :id params can have value 'add' in case of adding new ticket
  // that's why we need to check if it's valid number before assigning to
  // the `ticketId` variable
  const ticketId = !isNaN(Number(params['*'])) ? params['*'] : undefined;

  const matchAddTicketUrl = useMatch(
    useResolvedPath(ORDER_ADD_TICKET_PATH).pathname,
  );
  const matchViewTicketUrl = useMatch(useResolvedPath(':ticketId').pathname);

  const [removeTicketId, setRemoveTicketId] = useState<null | number>(null);

  const userType = useSelector(appSelectors.userType);
  const isUserDispatcher = userType === APIUserType.Dispatcher;
  const orderTickets = useSelector(orderSelectors.orderTickets);
  const orderTicketsLoaded = useSelector(orderSelectors.orderTicketsLoaded);
  const removeTicketPending = useSelector(orderSelectors.removeTicketPending);
  const loadOrderTicketsPending = useSelector(
    orderSelectors.loadOrderTicketsPending,
  );
  const loadMoreOrderTicketsPending = useSelector(
    orderSelectors.loadMoreOrderTicketsPending,
  );
  const orderTicketsTotalCount = useSelector(
    orderSelectors.orderTicketsTotalCount,
  );
  const sorting = useSelector(orderSelectors.orderTicketsSorting);
  const { pageSize } = useSelector(orderSelectors.orderTicketsPagination);
  const filters = useSelector(orderSelectors.orderTicketsFilters);
  const { addNotification } = useNotifications();

  const addNewTicketDisabled =
    !!matchAddTicketUrl ||
    !!matchViewTicketUrl ||
    (!!order && !canAddNewTickets(order));

  const onLoadMoreClicked = useCallback(() => {
    loadMoreOrderTickets(order.id, {
      ...composeLoadTicketsRequestBody({
        pageNumber: getPageNumberAfterLoadMore(orderTickets, pageSize),
        pageSize,
        sorting,
        filters,
      }),
      calculateTotalOnDelivery: true,
    });
  }, [
    order.id,
    loadMoreOrderTickets,
    orderTickets,
    pageSize,
    sorting,
    filters,
  ]);

  const columnProps = useMemo<TicketsTableColumn[]>(() => {
    return [
      {
        id: TicketColumns.Truck,
        dataId: TicketColumns.Truck,
        width: '8.2rem',
        order: 11,
        formatter: (value: any, ticket: OrderTicket) => ({
          value: ticket.truck?.truckNumber || t('common.entityNameUndefined'),
          element: (
            <div>
              <TruckNumberTag
                value={truncateTruckNumber(ticket.truck?.truckNumber)}
              />
            </div>
          ),
        }),
      },
      {
        id: 'cyProgress',
        order: 30,
        width: '12rem',
        dataId: 'batchSize' as TicketsListColumnId,
        label: t('ticketsTable.column.cyProgress'),
        formatter: (
          batchSize: OrderTicket['batchSize'],
          ticket: APITicket,
        ) => ({
          value: HIDDEN_CY_PROGRESS_TICKET_STATUSES.includes(
            ticket.ticketStatus,
          )
            ? ''
            : `${batchSize ? `${batchSize}cy` : '-'} in truck\n${
                ticket.totalOnDelivery ? `${ticket.totalOnDelivery}cy` : '-'
              } on delivery` || '',
          element: (
            <CYProgressCell>
              {HIDDEN_CY_PROGRESS_TICKET_STATUSES.includes(ticket.ticketStatus)
                ? ''
                : `${batchSize ? `${batchSize}cy` : '-'} | ${
                    ticket.totalOnDelivery ? `${ticket.totalOnDelivery}cy` : '-'
                  }`}
            </CYProgressCell>
          ),
        }),
      },
      {
        id: 'deleteButton',
        width: '7.2rem',
        formatter: (value: any, ticket: OrderTicket) => ({
          value,
          element: isUserDispatcher ? (
            <DeleteButton
              onClick={e => {
                e.stopPropagation();
                setRemoveTicketId(ticket.id);
              }}
            >
              <DeleteOutlineIcon />
            </DeleteButton>
          ) : null,
        }),
      },
      {
        id: 'ticketStatus' as TicketsListColumnId,
        width: '',
      },
      {
        id: 'ticketNumber' as TicketsListColumnId,
        width: '13rem',
      },
      {
        id: 'orderName' as TicketsListColumnId,
        hidden: true,
      },
      {
        id: 'plantName' as TicketsListColumnId,
        hidden: true,
      },
    ];
  }, [t, isUserDispatcher]);

  const onTicketDelete = async (ticketId?: number | null) => {
    if (ticketId) {
      await removeTicket(order.id, ticketId);
      setRemoveTicketId(null);
      addNotification({
        message: t('ticket.deleteSuccessText'),
      });
    }
  };

  const onRowClick = useCallback(
    (ticket: OrderTicket) => {
      navigate(ticket.id.toString(), { replace: true });
    },
    [navigate],
  );

  const isTableRowActive = useCallback(
    (ticket: OrderTicket) => {
      return Number(ticketId) === ticket.id;
    },
    [ticketId],
  );

  const onTicketSaved = useCallback(() => {
    if (order.id) {
      navigate(PARENT_PATH, { replace: true });
    }
  }, [navigate, order.id]);

  const onCloseTicketPopover = useCallback(() => {
    navigate(PARENT_PATH, { replace: true });
  }, [navigate]);

  const showEmptyMessage =
    orderTicketsLoaded &&
    !orderTickets.length &&
    ![
      APIOrderStatus.Completed,
      APIOrderStatus.Requested,
      APIOrderStatus.Unconfirmed,
      APIOrderStatus.Cancelled,
      APIOrderStatus.Declined,
    ].includes(orderStatus);

  const showNoOrdersEmptyMessage =
    orderTicketsLoaded &&
    !orderTickets.length &&
    [
      APIOrderStatus.Completed,
      APIOrderStatus.Requested,
      APIOrderStatus.Unconfirmed,
      APIOrderStatus.Cancelled,
      APIOrderStatus.Declined,
    ].includes(orderStatus);

  if (!orderTicketsLoaded) {
    return (
      <Wrapper>
        <LoadingText>
          {t('order.details.tickets.loadingTicketsText')}
        </LoadingText>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      {showEmptyMessage ? (
        <>
          <DispatcherCanAccess>
            <Trans i18nKey="order.details.ticketsTabEmptyStateText">
              <StyledTitle variant="h2">Tickets needed.</StyledTitle>
              <EmptyText>
                This order is ready for tickets to be added.
              </EmptyText>
            </Trans>
            <AddButton
              disabled={addNewTicketDisabled}
              variant="contained"
              onClick={onAddNewTicket}
            >
              {t('order.details.tickets.addNewTicketButtonText')}
            </AddButton>
          </DispatcherCanAccess>
          <CanAccess allowedUserType={APIUserType.Contractor}>
            <EmptyText marginTop="3rem" align="center">
              {t('order.details.ticketsTabEmptyStateTextNoTickets')}
            </EmptyText>
          </CanAccess>
        </>
      ) : showNoOrdersEmptyMessage ? (
        <EmptyText marginTop="3rem" align="center">
          {t('order.details.ticketsTabEmptyStateTextNoTickets')}
        </EmptyText>
      ) : (
        orderTicketsLoaded && (
          <TicketsWrapper>
            <TableHeader>
              <DispatcherCanAccess>
                <AddButtonSecondary
                  disabled={addNewTicketDisabled}
                  variant="outlined"
                  color="secondary"
                  onClick={onAddNewTicket}
                >
                  {t('order.details.tickets.addNewTicketSecondaryButtonText')}
                </AddButtonSecondary>
              </DispatcherCanAccess>
            </TableHeader>
            {orderTickets.length > 0 && (
              <TicketsTableWrapper>
                <TicketsTable
                  hideHeaders
                  tickets={orderTickets}
                  columnProps={columnProps}
                  ticketsLoaded={orderTicketsLoaded}
                  loadTicketsPending={loadOrderTicketsPending}
                  loadMoreTicketsPending={loadMoreOrderTicketsPending}
                  ticketsTotalCount={orderTicketsTotalCount}
                  onLoadMoreClicked={onLoadMoreClicked}
                  onRowClick={onRowClick}
                  isRowActive={isTableRowActive}
                />
              </TicketsTableWrapper>
            )}
            <ConfirmationDialog
              onClose={() => setRemoveTicketId(null)}
              open={!!removeTicketId}
              actionPending={removeTicketPending}
              handleActionClick={() => onTicketDelete(removeTicketId)}
              description={t('order.details.confirmed.deleteTicketDescription')}
            />
          </TicketsWrapper>
        )
      )}

      <Routes>
        <Route
          path=":ticketId"
          element={
            <Popover
              anchorEl={document.body}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'center',
              }}
              open
              PaperProps={{ sx: { overflow: 'visible' } }}
            >
              {order && (
                <AddTicketView
                  showCloseButton
                  order={order}
                  ticketId={ticketId}
                  onSaveDraftSuccess={onTicketSaved}
                  onSubmitSuccess={onTicketSaved}
                  onClosePopup={onCloseTicketPopover}
                  onCancelTicket={() =>
                    navigate(PARENT_PATH, { replace: true })
                  }
                />
              )}
            </Popover>
          }
        />
      </Routes>
    </Wrapper>
  );
};
