import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import styled from 'styled-components';

import {
  ON_ORDER_UPDATE_EVENT,
  ON_TRUCK_LOCATION_UPDATE_EVENT,
} from '@cd3p/core/constants/hubs';
import {
  APIOrder,
  APIOrderStatus,
  APITicket,
  APITicketStatuses,
  APITrackingItem,
  APITruckStatuses,
  APIUserType,
} from '@cd3p/core/types/api';
import { subscribeActionsAfter } from '@cd3p/core/utils/redux';
import { getSuccessActionName } from '@cd3p/core/utils/sra/reducers';
import {
  CSSTransition,
  CanAccess,
  CircularProgress,
  Container,
  DispatcherCanAccess,
  MapOverlay,
  Stack,
  TrackingMap,
} from 'components';
import { ChatThread, OrderViewLeftPanel } from 'features';
import { useAfterHoursNotice } from 'hooks/useAfterHoursNotice';
import { useDialogRead } from 'hooks/useDialogRead';
import { useHandleApiResult } from 'hooks/useHandleApiResult';
import { subscribe } from 'hooks/usePubSub';

import {
  CHAT_PANEL_WIDTH,
  ContentWrapper,
  Inner,
  LeftPanelWrapper,
  ORDER_LEFT_PANEL_WIDTH,
  OrderContainer,
} from './orderViewHelpers';

import {
  Navigate,
  _,
  useLocation,
  useMatch,
  useNavigate,
  useParams,
  useResolvedPath,
  useSelector,
  useTranslation,
} from 'third-party';

import { APP_EVENTS } from 'constants/appEvent';
import {
  ORDER_ADD_TICKET_PATH,
  PARENT_PATH,
  TICKETS_PATH,
  allOrdersUrl,
  orderUrl,
} from 'constants/url';

import { useAssignedDriversList } from 'modules/assignedDriversList';
import {
  ADD_TICKET_ACTION,
  UPDATE_TICKET_ACTION,
  useOrder,
} from 'modules/order';
import { useOrdersCache } from 'modules/ordersCache';
import { useStorage } from 'modules/storage';
import { useTracking } from 'modules/tracking';

import { appSelectors, orderSelectors, trackingSelectors } from 'selectors';

import { MapTypes } from 'components/TrackingMap/TrackingMap';

import { ContractorRightView } from 'features/OrderView/ContractorRightView/ContractorRightView';
import { DispatcherRightView } from 'features/OrderView/DispatcherRightView/DispatcherRightView';
import { OrderChat } from 'features/OrderView/OrderChat';
import { useOrderTickets } from 'features/OrderView/useOrderTickets';

import {
  getSiteData,
  isDateTomorrow,
  truckStatusesToShowOnMap,
} from 'utils/order';
import { useOrdersSockets } from 'utils/sockets';

import { LocationCoordinates, LocationT } from 'types/app';

import { themeConfig } from 'styles/theme';

const StyledContentWrapper = styled(ContentWrapper)`
  align-items: stretch;
`;
const CenterWrapper = styled(Container)`
  display: flex;
  align-items: center;
  height: 100%;
`;

const OrderChatPanel = styled.div`
  width: ${CHAT_PANEL_WIDTH}rem;
  position: absolute;
  left: ${ORDER_LEFT_PANEL_WIDTH}rem;
  top: 0;
  bottom: 0;
  z-index: ${props => props.theme.custom.zIndex.orderChat};
  display: flex;
  flex-direction: column;
  border: 1px solid ${props => props.theme.custom.palette.muted100};
`;

const StyledMapOverlay = styled(MapOverlay)`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const filterTrucks = (
  item: APITrackingItem,
  orderId: number,
  orderTickets: APITicket[],
) => {
  // hide trucks with returning statuses if its ticket not in pending mode
  if (item.truckStatus === APITruckStatuses.Returning) {
    const truckTicket = orderTickets.find(
      ticket => ticket.id === item?.ticketId,
    );
    if (truckTicket?.ticketStatus !== APITicketStatuses.Pending) {
      return false;
    }
  }
  return (
    item.currentLocation &&
    truckStatusesToShowOnMap.includes(item.truckStatus) &&
    orderId === item.orderId
  );
};

type OrderViewLayoutProps = {
  order: APIOrder;
};

export const OrderViewLayout: React.FC<OrderViewLayoutProps> = ({ order }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const orderId = order.id;
  const currentUserType = useSelector(appSelectors.userType);
  const currentProvider = useSelector(appSelectors.provider);
  const isDialogRead = useDialogRead();
  const [isEditOrderMode, setIsEditOrderMode] = useState(false);
  const [manualDeliveryLocation, setManualDeliveryLocation] =
    useState<LocationCoordinates | null>(null);
  const [shouldConfirmUponApproval, setShouldConfirmUponApproval] =
    useState(false);
  const userType = useSelector(appSelectors.userType);
  const { afterHoursNotice, showAfterHoursNotice } = useAfterHoursNotice({
    enabled: isEditOrderMode && userType === APIUserType.Contractor,
    style: {
      borderRight: `0.1rem solid ${themeConfig.custom.palette.muted100}`,
      zIndex: 4,
    },
  });

  useEffect(() => {
    if (!isEditOrderMode) {
      setManualDeliveryLocation(null);
    }
  }, [isEditOrderMode]);

  const matchAddNewTicketUrl = useMatch(
    useResolvedPath(`${TICKETS_PATH}/${ORDER_ADD_TICKET_PATH}`).pathname,
  );

  const isChatFeatureEnabled = useSelector(appSelectors.isChatFeatureEnabled);
  const isDriverAssignmentEnabled = useSelector(
    appSelectors.isDriverAssignmentEnabled,
  );

  const orderChatRef = useRef(null);
  const updateOrderPending = useSelector(orderSelectors.updateOrderPending);
  const [isChatPanelOpened, setIsChatPanelOpened] = useState(
    isChatFeatureEnabled && state?.isChatPanelOpened,
  );

  useEffect(() => {
    // close chat panel after order update
    if (!updateOrderPending) {
      setIsChatPanelOpened(false);
    }
  }, [updateOrderPending]);

  const isDeliveryDateTomorrow = isDateTomorrow(order.deliveryDate);
  const [isShowAssignDriversView, setShowAssignDriversView] =
    useState<boolean>(false);

  const { updateOrderInCache } = useOrdersCache();
  const { updateTracking } = useTracking();

  const providerName = useSelector(appSelectors.providerName);

  const orderStatus = order.orderStatus;

  useOrderTickets(orderId, orderStatus);

  useEffect(() => {
    if (orderId && state?.isChatPanelOpened) {
      setIsChatPanelOpened(state?.isChatPanelOpened);
      // remove state?.isChatPanelOpened from url to prevent
      // panel opening on page reload
      navigate(location, { state });
    }
    // ignoring navigate
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, orderId, state?.isChatPanelOpened]);

  useEffect(() => {
    if (!isDriverAssignmentEnabled) return;

    // for Confirmed orders there are 2 content screens - map and assigning drivers
    // for other order statuses no assigning drivers screen should be shown
    if (orderStatus === APIOrderStatus.Confirmed && isDeliveryDateTomorrow) {
      setShowAssignDriversView(true);
    } else {
      setShowAssignDriversView(false);
    }
  }, [
    orderStatus,
    setShowAssignDriversView,
    isDeliveryDateTomorrow,
    isDriverAssignmentEnabled,
  ]);

  useOrdersSockets(
    () => [
      {
        method: ON_ORDER_UPDATE_EVENT,
        action: (
          providerId: string,
          resultOrderId: number,
          result: APIOrder,
        ) => {
          if (resultOrderId === orderId) {
            // ignore isUserSubscribed key in this event, has some issues on back-end
            updateOrderInCache(orderId, {
              ...result,
              isUserSubscribed: order?.isUserSubscribed,
            });
          }
        },
      },
    ],
    [order?.isUserSubscribed, orderId, updateOrderInCache],
  );

  useOrdersSockets(
    () => [
      {
        method: ON_TRUCK_LOCATION_UPDATE_EVENT,
        action: (providerId, truckNumber, result: APITrackingItem) => {
          if (result.orderId === Number(orderId)) {
            updateTracking(result);
          }
        },
      },
    ],
    [orderId, updateTracking],
  );

  const handleChangeManualDeliveryLocation = useCallback(
    (coordinates: LocationCoordinates) => {
      setManualDeliveryLocation(coordinates);
    },
    [],
  );

  const handleConfirmUponApprovalMode = useCallback(() => {
    setIsEditOrderMode(true);
    setShouldConfirmUponApproval(true);
  }, []);

  useEffect(() => {
    if (!isEditOrderMode) {
      setShouldConfirmUponApproval(false);
    }
  }, [isEditOrderMode]);

  return (
    <OrderContainer>
      <Inner container>
        <Stack>
          {afterHoursNotice}
          <LeftPanelWrapper paddingTop={showAfterHoursNotice ? 1.5 : undefined}>
            <OrderViewLeftPanel
              order={order}
              isShowAssignDriversView={isShowAssignDriversView}
              setShowAssignDriversView={setShowAssignDriversView}
              onChatButtonClicked={() => setIsChatPanelOpened(true)}
              isChatButtonDisabled={isChatPanelOpened}
              isEditMode={isEditOrderMode}
              shouldConfirmUponApproval={shouldConfirmUponApproval}
              setIsEditMode={setIsEditOrderMode}
              manualDeliveryLocation={manualDeliveryLocation}
            />
          </LeftPanelWrapper>
        </Stack>

        <StyledContentWrapper>
          <BackgroundMap
            order={order}
            isEditMode={isEditOrderMode}
            changeManualDeliveryLocation={handleChangeManualDeliveryLocation}
          />
          {!isEditOrderMode && (
            <>
              <DispatcherCanAccess>
                <DispatcherRightView order={order} />
              </DispatcherCanAccess>
              <CanAccess allowedUserType={APIUserType.Contractor}>
                <ContractorRightView
                  onSetConfirmUponApprovalMode={handleConfirmUponApprovalMode}
                  order={order}
                />
              </CanAccess>
            </>
          )}
        </StyledContentWrapper>
        {isChatFeatureEnabled && (
          <CSSTransition
            nodeRef={orderChatRef}
            in={isChatPanelOpened}
            timeout={500}
            classNames="css-chat-panel"
            unmountOnExit
          >
            <OrderChatPanel ref={orderChatRef}>
              <OrderChat
                title={
                  currentUserType == APIUserType.Dispatcher
                    ? order.company.name || ''
                    : currentProvider?.providerName || ''
                }
                onClose={() => setIsChatPanelOpened(false)}
              >
                <ChatThread
                  dialog={order.dialog}
                  orderId={order.id}
                  isMarkAsReadDisabled={isDialogRead(order.dialog)}
                  providerName={providerName || ''}
                  companyName={order.company.name}
                />
              </OrderChat>
            </OrderChatPanel>
          </CSSTransition>
        )}
      </Inner>
      {/* redirect to order index url if it's not possible to create tickets in this order */}
      {matchAddNewTicketUrl &&
        orderStatus === APIOrderStatus.Completed &&
        orderId && <Navigate to={PARENT_PATH} replace state={state} />}
    </OrderContainer>
  );
};

export const OrderView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const orderId = Number(params.id);

  const order = useSelector(orderSelectors.order(orderId));
  const orderTickets = useSelector(orderSelectors.orderTickets);
  const userType = useSelector(appSelectors.userType);
  const orderTicketsTotalCount = useSelector(
    orderSelectors.orderTicketsTotalCount,
  );

  const { loadOrder, resetOrder, resetOrderTickets, fetchOrderPlants } =
    useOrder();

  const {
    loadAssignedDrivers,
    resetAssignedDrivers,
    resetAvailableToAssignDrivers,
  } = useAssignedDriversList();

  const { resetTracking, loadTracking } = useTracking();

  const handleApiResult = useHandleApiResult();

  const loadTrackingCallback = useCallback(() => {
    loadTracking({
      truckStatuses: truckStatusesToShowOnMap,
      orderId,
    });
  }, [loadTracking, orderId]);

  useEffect(() => {
    // update order to see the new status when adding first ticket
    if (order && orderTicketsTotalCount === 0) {
      return subscribeActionsAfter(
        [getSuccessActionName(ADD_TICKET_ACTION)],
        () => {
          loadOrder(orderId!);
          fetchOrderPlants(orderId);
        },
      );
    }
  }, [
    order,
    orderTicketsTotalCount,
    loadOrder,
    orderId,
    orderTickets,
    fetchOrderPlants,
  ]);

  useEffect(() => {
    if (order && orderTicketsTotalCount !== 0) {
      return subscribeActionsAfter(
        [
          getSuccessActionName(ADD_TICKET_ACTION),
          getSuccessActionName(UPDATE_TICKET_ACTION),
        ],
        () => {
          loadTrackingCallback();
          fetchOrderPlants(orderId);
        },
      );
    }
  }, [
    orderTicketsTotalCount,
    fetchOrderPlants,
    loadTrackingCallback,
    order,
    orderId,
  ]);

  useEffect(() => {
    loadTrackingCallback();
  }, [loadTrackingCallback]);

  // reset order when leave the view
  useEffect(() => {
    handleApiResult(
      () => loadOrder(orderId),
      () => {
        fetchOrderPlants(orderId);
        loadAssignedDrivers(orderId);
      },
      ({ result, showErrorToast }) => {
        if (result?.payload?.status === 404) {
          showErrorToast(t('common.wrongPage'));
        } else {
          showErrorToast();
        }
        navigate(allOrdersUrl());
      },
    );
    return () => {
      resetOrder();
      resetTracking();
      userType && resetOrderTickets(userType);
      resetAssignedDrivers();
      resetAvailableToAssignDrivers();
    };
  }, [
    userType,
    resetOrder,
    resetTracking,
    resetOrderTickets,
    resetAssignedDrivers,
    resetAvailableToAssignDrivers,
    loadOrder,
    orderId,
    fetchOrderPlants,
    loadAssignedDrivers,
    handleApiResult,
    navigate,
    t,
  ]);

  const isLoading = !order;

  return isLoading ? (
    <CenterWrapper>
      <CircularProgress sx={{ margin: '5rem auto' }} />
    </CenterWrapper>
  ) : (
    <OrderViewLayout key={order.id} order={order} />
  );
};

export const NavigateToOrderChat = () => {
  const params = useParams();
  const { state } = useLocation();
  const isChatFeatureEnabled = useSelector(appSelectors.isChatFeatureEnabled);
  return (
    <Navigate
      replace
      to={orderUrl(params.id!)}
      state={
        isChatFeatureEnabled
          ? { ...(state || null), isChatPanelOpened: true }
          : state
      }
    />
  );
};

type BackgroundMapProps = {
  order: APIOrder;
  isEditMode?: boolean;
  changeManualDeliveryLocation: (coordinates: LocationCoordinates) => void;
};
const BackgroundMap = ({
  order,
  isEditMode,
  changeManualDeliveryLocation,
}: BackgroundMapProps) => {
  const { t } = useTranslation();

  const { updateUserSettingInStorage } = useStorage();

  const allTrackingItemsValues = useSelector(
    trackingSelectors.trackingItemsValues,
  );
  const orderTickets = useSelector(orderSelectors.orderTickets);

  const orderPlants = useSelector(orderSelectors.orderPlants);

  const provider = useSelector(appSelectors.provider);
  const showTrafficLayerOnMap = useSelector(appSelectors.showTrafficLayerOnMap);

  const mapDefaultCenter = useMemo(
    () => ({
      lat: provider?.centroidLatitude || 0,
      lng: provider?.centroidLongitude || 0,
    }),
    [provider],
  );

  /*
    Populate map with markers based on order information.
  */
  const [mapSites, setMapSites] = useState<LocationT[]>([getSiteData(order)]);
  useEffect(() => {
    return subscribe(
      APP_EVENTS.UPDATE_PIN_LOCATION,
      (address: LocationT | null) => {
        setMapSites(address ? [address] : []);
      },
    );
  }, []);

  const orderId = order.id;
  const trackingItemsForOrder = useMemo(() => {
    return orderId &&
      // don't show trucks for tickets in Cancelled or Completed status
      ![APIOrderStatus.Cancelled, APIOrderStatus.Completed].includes(
        order.orderStatus,
      )
      ? allTrackingItemsValues.filter(item =>
          filterTrucks(item, orderId, orderTickets),
        )
      : [];
  }, [allTrackingItemsValues, order.orderStatus, orderId, orderTickets]);

  const onTrafficLayerChange = useCallback(
    (showTrafficLayerOnMap: boolean) => {
      updateUserSettingInStorage({
        showTrafficLayerOnMap,
      });
    },
    [updateUserSettingInStorage],
  );

  return (
    <StyledMapOverlay
      enabled={_.isEmpty(mapSites)}
      text={t('trackingMap.mapOverlay.placeholder')}
    >
      <TrackingMap
        persistentSiteTooltips
        plants={orderPlants}
        sites={mapSites}
        center={!_.isEmpty(mapSites) ? undefined : mapDefaultCenter}
        isLoading={false}
        trucks={trackingItemsForOrder}
        isSitePinDraggable={isEditMode}
        onManualDeliveryLocationChange={changeManualDeliveryLocation}
        defaultMapType={MapTypes.Hybrid}
        trafficLayer={showTrafficLayerOnMap}
        onTrafficLayerChange={onTrafficLayerChange}
      />
    </StyledMapOverlay>
  );
};
