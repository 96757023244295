import React, { useCallback, useEffect, useMemo, useState } from 'react';

import styled from 'styled-components';

import { APIMasterAdditiveType } from '@cd3p/core/types/api';
import { ConfirmationDialog, GlobalLoader, Popover } from 'components';
import { useHandleApiResult } from 'hooks/useHandleApiResult';

import { editFormTranslations } from '../AdditiveTypeTab/AdditiveTypesView';

import { useSelector, useTranslation } from 'third-party';

import { useAdditiveTypesList } from 'modules/additiveTypesList';
import { useCache } from 'modules/cache';

import { additiveTypesSelectors } from 'selectors';

import { EditFormPopup } from 'features/PlantSettings/common/EditFormPopup';

const EditLoader = styled(GlobalLoader)`
  width: 50rem;
`;

export const useManageAdditiveTypeItem = () => {
  const { t } = useTranslation();

  const {
    deleteAdditiveType,
    updateAdditiveType,
    updateAdditiveTypes,
    fetchAdditiveType,
  } = useAdditiveTypesList();

  const { loadAdditiveTypeCategories, createAdditiveTypeCategory } = useCache();

  const [additiveTypeIdForEdit, setAdditiveTypeIdForEdit] = useState<
    null | number
  >(null);
  const [additiveTypeIdForDelete, setAdditiveTypeIdForDelete] = useState<
    null | number
  >(null);
  const [additiveTypeData, setAdditiveTypeData] =
    useState<APIMasterAdditiveType | null>(null);

  const updateAdditiveTypePending = useSelector(
    additiveTypesSelectors.updateAdditiveTypePending,
  );
  const fetchAdditiveTypePending = useSelector(
    additiveTypesSelectors.fetchAdditiveTypePending,
  );

  const handleApiResult = useHandleApiResult();

  useEffect(() => {
    const additiveTypeId = additiveTypeIdForEdit || additiveTypeIdForDelete;
    if (additiveTypeId) {
      handleApiResult<APIMasterAdditiveType>(
        () => fetchAdditiveType(additiveTypeId),
        ({ result }) => {
          setAdditiveTypeData(result.payload);
        },
        ({ showErrorToast }) => {
          showErrorToast();
          setAdditiveTypeIdForDelete(null);
          setAdditiveTypeIdForEdit(null);
        },
      );
    } else {
      setAdditiveTypeData(null);
    }
  }, [
    additiveTypeIdForEdit,
    additiveTypeIdForDelete,
    handleApiResult,
    fetchAdditiveType,
  ]);

  const onDeleteAdditiveType = useCallback(async () => {
    if (additiveTypeIdForDelete) {
      handleApiResult(
        async () => {
          const result = await deleteAdditiveType(additiveTypeIdForDelete);
          setAdditiveTypeIdForDelete(null);
          return result;
        },
        ({ showBaseToast }) => {
          showBaseToast(t('additiveTypes.notification.successDelete'));
          updateAdditiveTypes();
        },
      );
    }
  }, [
    additiveTypeIdForDelete,
    deleteAdditiveType,
    handleApiResult,
    t,
    updateAdditiveTypes,
  ]);

  const additiveTypeItemDeleteConfirmation = useMemo(
    () => (
      <ConfirmationDialog
        onClose={() => {
          setAdditiveTypeIdForDelete(null);
        }}
        open={!!additiveTypeIdForDelete}
        handleActionClick={onDeleteAdditiveType}
        isLoading={fetchAdditiveTypePending || !additiveTypeData}
        description={t('additiveTypes.deleteConfirmationMessage', {
          count: additiveTypeData?.assignment || 0,
        })}
      />
    ),
    [
      additiveTypeIdForDelete,
      onDeleteAdditiveType,
      fetchAdditiveTypePending,
      additiveTypeData,
      t,
    ],
  );

  const additiveTypeItemEditPopup = useMemo(
    () =>
      additiveTypeIdForEdit && (
        <Popover
          anchorEl={document.body}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          open
          PaperProps={{ sx: { overflow: 'visible' } }}
        >
          {fetchAdditiveTypePending || !additiveTypeData ? (
            <EditLoader />
          ) : (
            <EditFormPopup
              defaultValues={additiveTypeData}
              updateData={updateAdditiveType}
              loadDataCategories={loadAdditiveTypeCategories}
              createDataCategory={createAdditiveTypeCategory}
              {...editFormTranslations}
              updatePending={updateAdditiveTypePending}
              onSuccessUpdate={updateAdditiveTypes}
              onClosePopup={() => {
                setAdditiveTypeIdForEdit(null);
              }}
            />
          )}
        </Popover>
      ),
    [
      additiveTypeData,
      additiveTypeIdForEdit,
      createAdditiveTypeCategory,
      fetchAdditiveTypePending,
      loadAdditiveTypeCategories,
      updateAdditiveType,
      updateAdditiveTypePending,
      updateAdditiveTypes,
    ],
  );

  return useMemo(
    () => ({
      additiveTypeItemDeleteConfirmation,
      additiveTypeItemEditPopup,
      setAdditiveTypeIdForEdit,
      setAdditiveTypeIdForDelete,
    }),
    [
      additiveTypeItemDeleteConfirmation,
      additiveTypeItemEditPopup,
      setAdditiveTypeIdForEdit,
      setAdditiveTypeIdForDelete,
    ],
  );
};
