import React, { useCallback, useMemo, useState } from 'react';

import styled from 'styled-components';

import { DialogMessagesAttachment } from '@cd3p/core/modules/chat';
import { ImageList, ImageListItem } from '@mui/material';
import { Typography } from 'components';
import { useSelector } from 'react-redux';
import { Lightbox } from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';

import { useChat } from 'modules/chat';

import { chatSelectors } from 'selectors';

import {
  ImageLoader,
  ImageWithFallback,
} from 'components/ImageWithFallback/ImageWithFallback';

const ligthboxStyles = {
  container: { background: 'rgba(0, 0, 0, 0.6)' },
  button: {},
  slide: { height: '90%', alignSelf: 'center' },
};

const MAX_ATTACHMENTS_SIZE = 4;

type MessageAttachmentProps = {
  attachmentIds: string[];
};

const StyledImg = styled.img`
  border-radius: 0.8rem;
  cursor: pointer;
  width: 13rem;
  height: 13rem;
  object-fit: cover;
`;

const BluredImage = styled(StyledImg)`
  filter: blur(0.5rem) brightness(80%);
`;

const PreviewWrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 0.8rem;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const ShowMoreText = styled(Typography)`
  color: ${props => props.theme.custom.palette.white};
  position: absolute;
`;

const ATTACHMENT_IMAGE_SIZE = '13rem';

export const useMessageAttachment = ({
  attachmentIds,
}: MessageAttachmentProps) => {
  const { loadMultipleDialogAttachmentsByIds } = useChat();
  const attachmentsData = useSelector(chatSelectors.dialogMessagesAttachments);
  const [openImage, setOpenImage] = useState<number | null>(null);
  const isMessageWithAttachments = !!attachmentIds?.length;
  const imageGridColumnsLength = attachmentIds?.length === 1 ? 1 : 2;

  const renderImageAttachment = useCallback(
    ({
      attachment,
      id,
      index,
    }: {
      attachment: DialogMessagesAttachment;
      id: string;
      index: number;
    }) => {
      return (
        <ImageWithFallback
          onClick={() => setOpenImage(index)}
          src={(!attachment.error && attachment?.previewUrl) || ''}
          width={ATTACHMENT_IMAGE_SIZE}
          height={ATTACHMENT_IMAGE_SIZE}
          showFallback={attachment?.error}
          onRefresh={() => {
            loadMultipleDialogAttachmentsByIds([id]);
          }}
          showRefreshFallback={
            attachment && attachment?.error && attachment?.status !== 404
          }
        />
      );
    },
    [loadMultipleDialogAttachmentsByIds],
  );

  const renderMessageAttachment = useCallback(() => {
    return (
      <>
        {isMessageWithAttachments && (
          <ImageList cols={imageGridColumnsLength}>
            {attachmentIds
              .slice(0, MAX_ATTACHMENTS_SIZE)
              .map((attachmentId, index) => {
                const attachment = attachmentsData[attachmentId];
                const showMorePreview =
                  attachmentIds.length > MAX_ATTACHMENTS_SIZE &&
                  index === MAX_ATTACHMENTS_SIZE - 1;
                const hiddenAttachmentsCount =
                  attachmentIds.length - (MAX_ATTACHMENTS_SIZE - 1);
                const key = `${
                  !attachment?.error && attachment?.id ? attachment.id : ''
                }${index}`;
                if (!attachment) {
                  return (
                    <ImageListItem key={key}>
                      <ImageLoader
                        width={ATTACHMENT_IMAGE_SIZE}
                        height={ATTACHMENT_IMAGE_SIZE}
                        key={key}
                      />
                      ;
                    </ImageListItem>
                  );
                }
                return (
                  <ImageListItem key={key}>
                    {showMorePreview ? (
                      <PreviewWrapper onClick={() => setOpenImage(index)}>
                        <BluredImage
                          draggable={false}
                          src={
                            !attachment?.error && attachment?.previewUrl
                              ? attachment.previewUrl
                              : ''
                          }
                        />
                        <ShowMoreText variant="h5">{`+${hiddenAttachmentsCount}`}</ShowMoreText>
                      </PreviewWrapper>
                    ) : (
                      renderImageAttachment({
                        attachment,
                        id: attachmentId,
                        index,
                      })
                    )}
                  </ImageListItem>
                );
              })}
          </ImageList>
        )}
        <Lightbox
          open={openImage !== null}
          close={() => setOpenImage(null)}
          index={openImage || 0}
          controller={{ closeOnBackdropClick: true }}
          carousel={{ finite: true }}
          styles={ligthboxStyles}
          slides={attachmentIds?.map(id => {
            const attachment = attachmentsData[id];
            return {
              src:
                !attachment?.error && attachment?.downloadUrl
                  ? attachment.downloadUrl
                  : '',
            };
          })}
        />
      </>
    );
  }, [
    attachmentIds,
    attachmentsData,
    imageGridColumnsLength,
    isMessageWithAttachments,
    openImage,
    renderImageAttachment,
  ]);

  return useMemo(
    () => ({
      renderMessageAttachment,
    }),
    [renderMessageAttachment],
  );
};
