import React, { ReactNode } from 'react';

import styled, { useTheme } from 'styled-components';

import { Box, Tooltip, Trans, Typography } from 'components';

import { _ } from 'third-party';

import { TOOLTIP_APPEAR_DELAY } from 'constants/common';

const Wrapper = styled.div`
  display: inline-block;
  font-weight: 700;
  font-size: 1.6rem;
  color: ${props => props.theme.custom.palette.gray};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
`;

const Value = styled(Typography)<{ color?: string }>`
  display: inline-block;
  font-weight: 700;
  font-size: 1.6rem;
  color: ${props => props.color ?? props.theme.custom.palette.gray};
`;

const VolumeDiff = styled(Typography)`
  display: inline-block;
  font-size: 1rem;
  margin-left: 0.2rem;
  sup {
    position: relative;
    top: -0.5rem;
    margin-left: 0.2rem;
    font-weight: 700;
  }
`;

type Props = {
  showVolumeDiff?: boolean;
  volume?: number;
  volumeDisplayValue?: string | ReactNode | null;
  batched?: number;
  delivered?: number;
  originalVolume?: number;
};

export const BatchedDeliveredValue: React.FC<Props> = React.forwardRef(
  (
    {
      showVolumeDiff,
      volume = 0,
      volumeDisplayValue,
      batched = 0,
      delivered = 0,
      originalVolume = 0,
    },
    ref,
  ) => {
    const theme = useTheme();
    const volumeDiff = volume - originalVolume;
    return (
      <Tooltip
        ref={ref}
        placement="top-start"
        enterDelay={TOOLTIP_APPEAR_DELAY}
        enterNextDelay={TOOLTIP_APPEAR_DELAY}
        title={
          <Box>
            <Trans i18nKey="ordersList.table.qtyBtchdDlvdTooltip">
              Quantity: {{ quantity: volume }}
              <br />
              Batched: {{ batched: batched }}
              <br />
              Delivered: {{ delivered: delivered }}
            </Trans>
          </Box>
        }
      >
        <Wrapper>
          <Value>{volumeDisplayValue || volume}</Value>
          {showVolumeDiff && !!volumeDiff && (
            <VolumeDiff>
              {volumeDiff > 0 ? '▲' : '▼'}
              <sup>
                {volumeDiff > 0 && '+'}
                {_.round(volumeDiff, 2)}
              </sup>
            </VolumeDiff>
          )}
          &nbsp;(
          <Value color={batched > 0 && theme.custom.palette.info600}>
            {batched}
          </Value>
          &nbsp;/&nbsp;
          <Value color={delivered > 0 && theme.custom.palette.success700}>
            {delivered}
          </Value>
          )
        </Wrapper>
      </Tooltip>
    );
  },
);
