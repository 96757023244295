import React from 'react';

import { createTheme } from '@mui/material';
import { CacheProvider } from 'context/CacheContext';
import { BrowserTabMetadata } from 'features';
import { persistor, store } from 'modules';
import { Provider } from 'react-redux';
import {
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { useRoutes } from 'routes';

import { PostHogProvider, ThemeProvider } from 'third-party';

import { posthogOptions } from 'constants/common';

import { themeConfig } from 'styles/theme';

const theme = createTheme(themeConfig);

export const RootContainer = () => {
  const router = createBrowserRouter(createRoutesFromElements(useRoutes()));
  return (
    <PostHogProvider apiKey={process.env.POSTHOG_KEY} options={posthogOptions}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <CacheProvider>
            <ThemeProvider theme={theme}>
              <BrowserTabMetadata />
              <RouterProvider router={router} />
            </ThemeProvider>
          </CacheProvider>
        </PersistGate>
      </Provider>
    </PostHogProvider>
  );
};
