import { useCallback, useContext } from 'react';

import { APIOrderSubscription } from '@cd3p/core/types/api';
import {
  addUserSubscription,
  removeUserSubscription,
} from '@cd3p/core/utils/cache';
import { CacheContext } from 'context/CacheContext';

import { useSelector } from 'third-party';

import { useDialogsList } from 'modules/dialogsList';
import { useOrdersCache } from 'modules/ordersCache';

import { appSelectors } from 'selectors';

export const useChangeOrderSubscription = () => {
  const { updateOrderInCache } = useOrdersCache();

  const { syncData } = useContext(CacheContext);

  const { getDialogsByIds, addSubscribedDialog, removeSubscribedDialog } =
    useDialogsList();

  const providerId = useSelector(appSelectors.providerId);
  const companyId = useSelector(appSelectors.companyId);

  return useCallback(
    async (subscription: APIOrderSubscription, shouldSubscribe: boolean) => {
      updateOrderInCache(subscription.orderId, {
        id: subscription.orderId,
        isUserSubscribed: shouldSubscribe,
      });

      // after user subscription action, we need to update the global cache.
      if (shouldSubscribe) {
        const dialog = await getDialogsByIds(companyId, [
          subscription.orderId.toString(),
        ]);

        if (dialog.payload?.result?.[0]) {
          addUserSubscription(
            providerId,
            subscription,
            dialog.payload.result[0],
          );

          addSubscribedDialog(dialog.payload.result[0]);
        }
      } else {
        removeUserSubscription(providerId, subscription);
        removeSubscribedDialog(subscription.orderId);
      }
      syncData();
    },
    [
      addSubscribedDialog,
      companyId,
      getDialogsByIds,
      providerId,
      removeSubscribedDialog,
      syncData,
      updateOrderInCache,
    ],
  );
};
