import React from 'react';

import MUISwitch, { SwitchProps } from '@mui/material/Switch';
import { styled } from '@mui/material/styles';

import { themeConfig } from 'styles/theme';

const StyledSwitch = styled(MUISwitch)(({ theme }) => ({
  width: 35,
  height: 20,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 16,
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '& + .MuiSwitch-track': {
      backgroundColor: themeConfig.custom.palette.light100,
    },
    '&.Mui-checked': {
      transform: 'translateX(15px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: themeConfig.custom.palette.primary500,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 16,
    height: 16,
    borderRadius: 8,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 20 / 2,
    opacity: 1,
    backgroundColor: 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

export const Switch = (props: SwitchProps) => {
  return <StyledSwitch {...props} />;
};
