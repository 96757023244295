import { State } from 'modules';

export const contractorUsersListItems = (state: State) =>
  state.contractorUsersList.items;
export const contractorUsersListSorting = (state: State) =>
  state.contractorUsersList.searchSortOrders;
export const contractorUsersListCount = (state: State) =>
  state.contractorUsersList.count;
export const loadContractorUsersPending = (state: State) =>
  state.contractorUsersList.loadContractorUsersPending;
export const contractorUsersListLoaded = (state: State) =>
  state.contractorUsersList.contractorUsersLoaded;
export const loadMoreContractorUsersListPending = (state: State) =>
  state.contractorUsersList.loadMoreContractorUsersPending;
export const deleteContractorUserPending = (state: State) =>
  state.contractorUsersList.deleteContractorUserPending;
export const resendInvitationPending = (state: State) =>
  state.contractorUsersList.resendInvitationPending;
