import React, { useMemo } from 'react';

import styled from 'styled-components';

import { Tab, Tabs, Typography } from 'components';
import { ContractorUsersList } from 'features';

import {
  Navigate,
  Route,
  Routes,
  useMatch,
  useNavigate,
  useSelector,
  useTranslation,
} from 'third-party';

import {
  CONTRACTOR_USERS_URL_PATH,
  DRIVERS_URL_PATH,
  PLANTS_URL_PATH,
  contractorUsersUrl,
  driversUrl,
  plantsUrl,
  userManagementUrl,
} from 'constants/url';

import { contractorUsersListSelectors } from 'selectors';

import { TabTitle, TabWrapper, TabsWrapper } from 'styles/theme';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 0;
  overflow: hidden;
  padding: ${props => props.theme.custom.dimensions.viewVerticalPadding}rem
    ${props => props.theme.custom.dimensions.viewHorizontalPadding}rem 0
    ${props => props.theme.custom.dimensions.viewHorizontalPadding}rem;
`;

const Header = styled(Typography)`
  color: ${props => props.theme.custom.palette.secondary500};
  margin-bottom: 3rem;
`;

const StyledTabs = styled(Tabs)`
  width: 33%;
`;

enum UserManagementTabs {
  Contractors,
  Drivers,
  Plants,
}

export const UserManagementView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const matchIndexUrl = useMatch(userManagementUrl());
  const matchDriversUrl = useMatch(driversUrl());
  const matchPlantsUrl = useMatch(plantsUrl());

  const currentTab = useMemo(() => {
    if (matchDriversUrl) return UserManagementTabs.Drivers;
    else if (matchPlantsUrl) return UserManagementTabs.Plants;
    return UserManagementTabs.Contractors;
  }, [matchDriversUrl, matchPlantsUrl]);

  const contractorUsersListItems = useSelector(
    contractorUsersListSelectors.contractorUsersListItems,
  );

  const tabs = useMemo(
    () => [
      {
        title: t('userManagement.customerTab'),
        showTableCounter:
          contractorUsersListItems.length > 0 &&
          currentTab === UserManagementTabs.Contractors,
        onClick: () => navigate(contractorUsersUrl(), { replace: true }),
      },
      // {
      //   title: t('userManagement.driversTab'),
      //   showTableCounter:
      //     drivers.length > 0 && currentTab === UserManagementTabs.Drivers,
      //   onClick: () => navigate(driversUrl(), { replace: true }),
      // },
      // {
      //   title: t('userManagement.plantsTab'),
      //   showTableCounter:
      //     plants.length > 0 && currentTab === UserManagementTabs.Plants,
      //   onClick: () => navigate(plantsUrl(), { replace: true }),
      // },
    ],
    [currentTab, t, navigate, contractorUsersListItems],
  );

  return (
    <Wrapper>
      <Header variant="h1">{t('userManagement.title')}</Header>
      <TabsWrapper>
        <StyledTabs value={currentTab}>
          {tabs.map(tab => {
            return (
              <Tab
                key={tab.title}
                label={
                  <TabWrapper>
                    <TabTitle>{tab.title}</TabTitle>
                  </TabWrapper>
                }
                onClick={tab.onClick}
              />
            );
          })}
        </StyledTabs>
        <Routes>
          <Route
            path={CONTRACTOR_USERS_URL_PATH}
            element={<ContractorUsersList />}
          />
          <Route
            path={DRIVERS_URL_PATH}
            element={<div>{t('userManagement.driversTab')}</div>}
          />
          <Route
            path={PLANTS_URL_PATH}
            element={<div>{t('userManagement.plantsTab')}</div>}
          />
        </Routes>
        {matchIndexUrl && <Navigate to={contractorUsersUrl()} replace />}
      </TabsWrapper>
    </Wrapper>
  );
};
