import React from 'react';

import { APIDeliveryRateType } from '@cd3p/core/types/api';
import {
  FormField,
  FormNumberField,
  MenuItem,
  Stack,
  Typography,
} from 'components';
import { PropsValue } from 'react-select';

import { Control, FieldError, styled, useTranslation } from 'third-party';

import { deliveryRateTypeOptions } from 'utils/order';

import { TypeaheadOption } from 'types/app';

const SelectFormField = styled(FormField)`
  flex: 4;
`;

const InputFormField = styled(FormNumberField)`
  flex: 10;
`;

type Props<TFormValues extends Record<string, any>> = {
  selectFieldName: string;
  inputFieldName: string;
  fieldError?: FieldError; // error object from react-hook-form
  errors: Record<string, any>;
  control: Control<TFormValues>;
  value?: PropsValue<TypeaheadOption> | undefined;
  isDisabled?: boolean;
  label?: string;
  selectPlaceholder?: string;
  isRequired?: boolean;
  showError?: boolean;
  selectValue?: APIDeliveryRateType;
};

export const DeliveryRateField = <TFormValues extends Record<string, any>>({
  selectFieldName,
  inputFieldName,
  errors,
  control,
  isDisabled,
  label,
  selectPlaceholder,
  isRequired = false,
  showError = true,
  selectValue,
}: Props<TFormValues>) => {
  const { t } = useTranslation();

  const selectOptions = deliveryRateTypeOptions.map(option => (
    <MenuItem key={option.value} value={option.value}>
      <Typography variant="body2">{option.label}</Typography>
    </MenuItem>
  ));

  const deliveryRatePlaceholder =
    selectValue === APIDeliveryRateType.CyHour
      ? t('order.sectionField.deliveryRateCYHourPlaceholder')
      : t('order.sectionField.deliveryRateMinPerTruckPlaceholder');

  return (
    <Stack>
      <Stack direction={'row'} gap={'0.5rem'}>
        <SelectFormField
          type={'select'}
          fieldName={selectFieldName}
          fieldError={errors[selectFieldName] as FieldError}
          requiredErrorMessage={t('common.form.emptyFieldError')}
          control={control}
          placeholder={selectPlaceholder}
          isDisabled={isDisabled}
          isRequired={isRequired}
          showError={showError}
          selectOptions={selectOptions}
          label={label}
        />
        <InputFormField
          precision={2}
          fieldName={inputFieldName}
          fieldError={errors[inputFieldName] as FieldError}
          requiredErrorMessage={t('common.form.emptyFieldError')}
          control={control}
          placeholder={deliveryRatePlaceholder}
          isDisabled={isDisabled}
          isRequired={isRequired}
          showError={showError}
          maxLength={10}
        />
      </Stack>
    </Stack>
  );
};

DeliveryRateField.displayName = 'LocationField';
