import { useCallback, useMemo, useState } from 'react';

import { TooltipProps } from '@mui/material/Tooltip';

import { _ } from 'third-party';

import { LocationT } from 'types/app';

const getSiteId = (site: LocationT) => [site.name, site.id].join('-');

const generateTooltipsOpenedState = (sites: LocationT[], visible: boolean) =>
  (sites || []).reduce((result, site) => {
    return {
      ...result,
      [getSiteId(site)]: visible,
    };
  }, {});

export const useSitesMarkersState = (sites: LocationT[]) => {
  const [openedTooltips, setOpenedTooltips] = useState<Record<string, boolean>>(
    generateTooltipsOpenedState(sites, true),
  );

  const getSiteTooltipProps = useCallback(
    (site: LocationT): Partial<TooltipProps> => {
      return {
        open: openedTooltips[getSiteId(site)],
        componentsProps: {
          popper: {
            className: 'persistent-tooltip map-tooltip',
            style: {
              zIndex: 0,
            },
          },
        },
      };
    },
    [openedTooltips],
  );

  const changeTooltipState = useCallback(
    (site: LocationT, visible: boolean) => {
      setOpenedTooltips(state => {
        const result = { ...state };
        result[getSiteId(site)] = visible;
        return result;
      });
    },
    [],
  );

  const closeAllTooltips = useCallback(() => {
    const isAnyOpened = _.findKey(openedTooltips, value => value);
    if (isAnyOpened) {
      setOpenedTooltips(generateTooltipsOpenedState(sites, false));
    }
  }, [openedTooltips, sites]);

  return useMemo(
    () => ({
      getSiteTooltipProps,
      changeSiteTooltipState: changeTooltipState,
      closeAllSiteTooltips: closeAllTooltips,
    }),
    [getSiteTooltipProps, changeTooltipState, closeAllTooltips],
  );
};
