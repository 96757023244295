import React, { useCallback, useMemo, useState } from 'react';

import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

import { Button, Menu } from 'components';

import { AppPortals } from 'constants/common';

export const useTableColumnVisibilityControl = () => {
  const [popupAnchorEl, setPopupAnchorEl] = useState<null | Element>();

  const closePopup = useCallback(() => {
    setPopupAnchorEl(null);
  }, []);

  const onTriggerButtonClicked = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setPopupAnchorEl(event.currentTarget);
  };

  const button = useMemo(() => {
    return (
      <Button
        variant="outlined"
        color="secondary"
        onClick={onTriggerButtonClicked}
      >
        <SettingsOutlinedIcon />
      </Button>
    );
  }, []);

  const popup = useMemo(() => {
    return (
      <Menu
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={popupAnchorEl}
        open={!!popupAnchorEl}
        onClose={closePopup}
        sx={{
          marginTop: '2rem',
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          sx: {},
        }}
      >
        <div id={AppPortals.OrderTableColumnVisibility} />
      </Menu>
    );
  }, [closePopup, popupAnchorEl]);

  return useMemo(
    () => ({
      button,
      popup,
    }),
    [button, popup],
  );
};
