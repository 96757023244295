import { useMemo } from 'react';

import { APITicketStatuses } from '@cd3p/core/types/api';

import {
  bindActionCreators,
  simplifyBuilder,
  useDispatch,
  useSelector,
} from 'third-party';

import {
  TicketsListState,
  loadMoreTickets,
  loadTickets,
  removeTicketFromState,
  ticketsDefaultListState,
  updateTicketInState,
  updateTickets,
} from 'modules/common/ticketsList';

import { activeTicketsListSelectors, appSelectors } from 'selectors';

export type ActiveTicketsListState = TicketsListState & {
  loadActiveTicketsPending: boolean;
  loadMoreActiveTicketsPending: boolean;
};

export const activeTicketsListState: ActiveTicketsListState = {
  tickets: {
    ...ticketsDefaultListState,
    filters: {
      ...ticketsDefaultListState.filters,
      // show only today's orders
      date: { dateFrom: new Date(), dateTo: new Date() },
      // show only tickets with specific status
      ticketStatuses: [
        APITicketStatuses.Loading,
        APITicketStatuses.OnSite,
        APITicketStatuses.Delivering,
        APITicketStatuses.Pending,
      ],
    },
  },
  loadActiveTicketsPending: false,
  loadMoreActiveTicketsPending: false,
};

const builder = simplifyBuilder(activeTicketsListState, {});

const loadActiveTickets = builder.createServerAction(
  loadTickets('loadActiveTickets'),
);

const loadMoreActiveTickets = builder.createServerAction(
  loadMoreTickets('loadMoreActiveTickets'),
);

const updateActiveTickets = builder.createServerAction(
  updateTickets('updateActiveTickets'),
);

const updateActiveTicketInState = builder.createReduxAction(
  updateTicketInState<ActiveTicketsListState>('updateActiveTicketInState'),
);

const removeActiveTicketFromState = builder.createReduxAction(
  removeTicketFromState<ActiveTicketsListState>('removeActiveTicketFromState'),
);

const resetActiveTickets = builder.createReduxAction(() => ({
  name: 'resetActiveTickets',
  updater: () => ({
    tickets: ticketsDefaultListState,
  }),
}));

export const useActiveTicketsList = () => {
  const dispatch = useDispatch();
  const providerId = useSelector(appSelectors.providerId);

  return useMemo(
    () =>
      bindActionCreators(
        {
          loadActiveTickets: loadActiveTickets.bind(null, providerId),
          loadMoreActiveTickets: loadMoreActiveTickets.bind(null, providerId),
          updateActiveTickets: updateActiveTickets.bind(
            null,
            providerId,
            activeTicketsListSelectors.activeTicketsState,
          ),
          // without binding TS doesn't infer type for module functions
          resetActiveTickets: resetActiveTickets.bind(null),
          updateActiveTicketInState: updateActiveTicketInState.bind(null),
          removeActiveTicketFromState: removeActiveTicketFromState.bind(null),
        },
        dispatch,
      ),
    [dispatch, providerId],
  );
};

export const activeTicketsListReducer = builder.getReducers();
