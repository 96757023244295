import React, { CSSProperties } from 'react';

import styled, { useTheme } from 'styled-components';

import InfoIconOutlined from '@mui/icons-material/InfoOutlined';

import { Stack, SvgIcon, Tooltip, Typography } from 'components';

const TOOLTIP_STYLE = {
  padding: '0.5rem 0.75rem',
  opacity: 0.9,
};

const Wrapper = styled(Stack)`
  padding: 1rem 1.4rem;
  background-color: ${props => props.theme.custom.palette.warning50};
`;

const Content = styled(Stack)`
  flex-direction: row;
`;

const ContentColumn = styled(Stack)`
  flex-direction: column;
`;

const Label = styled(Typography)`
  font-weight: 600;
  font-size: 1.4rem;
  color: ${props => props.theme.custom.palette.warning800};
`;

const StyledIcon = styled(SvgIcon)`
  min-width: 2rem;
  margin-right: 0.8rem;
`;

const StyledInfoIcon = styled(InfoIconOutlined)`
  width: 1.8rem;
  height: auto;
  margin-left: 0.5rem;
  color: ${props => props.theme.custom.palette.warning800};
`;

interface Props {
  label: string;
  tooltipMessage?: string;
  icon?: string;
  className?: string;
  style?: CSSProperties;
}

export const NoticePanel: React.FC<Props> = ({
  label,
  icon,
  tooltipMessage,
  className,
  style,
}) => {
  const theme = useTheme();

  return (
    <Wrapper className={className} style={style}>
      <Tooltip
        arrow
        placement="top"
        componentsProps={{
          tooltip: {
            style: {
              ...TOOLTIP_STYLE,
              backgroundColor: theme.custom.palette.darkText,
            },
          },
          arrow: {
            style: {
              color: theme.custom.palette.darkText,
            },
          },
        }}
        title={tooltipMessage}
      >
        <Content>
          <StyledIcon
            color={theme.custom.palette.warning800}
            icon={icon || 'clock-warning-filled'}
            height="2rem"
            width="2rem"
          />
          <ContentColumn>
            <Label>{label}</Label>
          </ContentColumn>
          <StyledInfoIcon />
        </Content>
      </Tooltip>
    </Wrapper>
  );
};
