/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export enum APIAccessLevel {
  User = 'User',
  SuperVisor = 'SuperVisor',
  Admin = 'Admin',
}

export interface APIAdditiveModel {
  /**
   * The unique identifier of the additive
   * @format int32
   */
  id?: number;
  /** The name of the additive */
  name?: string | null;
}

export interface APIAdditiveRequestModel {
  /** @format int32 */
  id?: number;
  name?: string | null;
}

export interface APIAdditiveSearchFilter {
  /**
   * The search page number, will be used for offset
   * @format int32
   * @example 0
   */
  pageNumber?: number;
  /**
   * The count of entities on the page
   * @format int32
   * @example 20
   */
  pageSize?: number;
  /** a collection of sort fields */
  searchSortOrders?: APISearchSortOrder[] | null;
  /** The search parameter that indicates the Mix Type name to search */
  name?: string | null;
  /** The search parameter for the category to search */
  categoryIds?: number[] | null;
  /** The search parameter that indicates if the additive is project specific */
  isProjectSpecific?: boolean;
}

export interface APIAdditiveUsageModel {
  /**
   * The unique identifier of the company
   * @format uuid
   */
  id?: string;
  /** The name of the company */
  name?: string | null;
}

export interface APIAfterHoursResponseModel {
  isWorkingHours?: boolean;
  message?: string | null;
  /** @format double */
  startAfter?: number;
}

export interface APIApplicationVersionModel {
  version?: string | null;
  notes?: string | null;
  isForceUpdate?: boolean;
}

export interface APIAssignedDriverModel {
  /**
   * Unique identifier for the assignment.
   * @format int32
   */
  id?: number;
  driver?: APIUserModel;
  plant?: APIPlantModel;
  /**
   * Date and timr when the driver was assigned to the plant.
   * @format date-time
   */
  assignDate?: string;
  /**
   * Date and time when the driver was notified about the assignment
   * @format date-time
   */
  notifiedDate?: string | null;
}

export interface APIAssignedDriverRequestModel {
  /** @format uuid */
  driverId?: string;
  /** @format uuid */
  plantId?: string;
  /** @format date-time */
  assignDate?: string;
}

export interface APIAutocompleteResult {
  description?: string | null;
  placeId?: string | null;
}

export interface APIAvailableAdditiveModel {
  /**
   * The unique identifier of the additive
   * @format int32
   */
  id?: number;
  /** The name of the additive */
  name?: string | null;
  /**
   * The providerId of the mix type
   * @format uuid
   */
  providerId?: string;
  /**
   * Number of projects where mix type is used
   * @format int32
   */
  usage?: number;
  /**
   * The categoryId of the mix type
   * @format int32
   */
  categoryId?: number;
  /** The category name of the mix type */
  categoryName?: string | null;
}

export interface APIAvailableAdditiveModelSearchResult {
  /**
   * The search page number, will be used for offset
   * @format int32
   * @example 0
   */
  pageNumber?: number;
  /**
   * The count of entities on the page
   * @format int32
   * @example 20
   */
  pageSize?: number;
  /**
   * The count of the searched entities in the DB
   * @format int32
   * @example 1
   */
  count?: number;
  /** The list of the searched entities */
  result?: APIAvailableAdditiveModel[] | null;
}

export interface APIAvailableAdditiveSearchFilter {
  /**
   * The search page number, will be used for offset
   * @format int32
   * @example 0
   */
  pageNumber?: number;
  /**
   * The count of entities on the page
   * @format int32
   * @example 20
   */
  pageSize?: number;
  /** a collection of sort fields */
  searchSortOrders?: APISearchSortOrder[] | null;
  /** The search parameter that indicates the Additive name to search */
  name?: string | null;
}

export interface APIAvailableMixTypeModel {
  /**
   * The unique identifier of the mix type
   * @format int32
   */
  id?: number;
  /** The name of the mix type */
  name?: string | null;
  /**
   * The providerId of the mix type
   * @format uuid
   */
  providerId?: string;
  /**
   * Number of projects where mix type is used
   * @format int32
   */
  usage?: number;
  /**
   * The categoryId of the mix type
   * @format int32
   */
  categoryId?: number;
  /** The category name of the mix type */
  categoryName?: string | null;
}

export interface APIAvailableMixTypeModelSearchResult {
  /**
   * The search page number, will be used for offset
   * @format int32
   * @example 0
   */
  pageNumber?: number;
  /**
   * The count of entities on the page
   * @format int32
   * @example 20
   */
  pageSize?: number;
  /**
   * The count of the searched entities in the DB
   * @format int32
   * @example 1
   */
  count?: number;
  /** The list of the searched entities */
  result?: APIAvailableMixTypeModel[] | null;
}

export interface APIAvailableMixTypeSearchFilter {
  /**
   * The search page number, will be used for offset
   * @format int32
   * @example 0
   */
  pageNumber?: number;
  /**
   * The count of entities on the page
   * @format int32
   * @example 20
   */
  pageSize?: number;
  /** a collection of sort fields */
  searchSortOrders?: APISearchSortOrder[] | null;
  /** The search parameter that indicates the Mix Type name to search */
  name?: string | null;
}

export enum APICallBackFormStatus {
  CallbackPending = 'CallbackPending',
  NoCallback = 'NoCallback',
  CallbackSubmitted = 'CallbackSubmitted',
  CallbackConfirmed = 'CallbackConfirmed',
  CallbackAutoResolved = 'CallbackAutoResolved',
}

export enum APIChangesStatus {
  Declined = 'Declined',
  Pending = 'Pending',
  Applied = 'Applied',
}

export interface APIChatMessageModel {
  /**
   * The unique identifier of the chat message.
   * @format uuid
   */
  id?: string;
  /**
   * The unique identifier of the order associated with this message.
   * @format int32
   */
  orderId?: number;
  /**
   * The date and time when the message was created.
   * @format date-time
   */
  createdOn?: string;
  /** The content of the chat message. */
  message?: string | null;
  /**
   * The unique identifier of the user who sent the chat message.
   * @format uuid
   */
  senderId?: string;
  /** The first name of the user who sent the chat message. */
  senderFirstName?: string | null;
  /** The last name of the user who sent the chat message. */
  senderLastName?: string | null;
  /** The user type of a sender */
  senderUserType?: string | null;
  /** The user access level */
  senderAccessLevel?: string | null;
  /**
   * The unique identifier of the attachments associated with the chat message.
   * @uniqueItems true
   */
  attachmentIds?: string[] | null;
}

export interface APIChatMessageModelContinueSearchResult {
  /**
   * The continue token for search
   * @example "1"
   */
  token?: string | null;
  /** The list of the searched entities */
  result?: APIChatMessageModel[] | null;
}

export interface APIChatMessageRequestModel {
  message?: string | null;
  /** @uniqueItems true */
  attachmentIds?: string[] | null;
}

export interface APICompanyAdditiveRequestModel {
  /** The unique identifiers of the mix types */
  ids?: number[] | null;
}

export interface APICompanyMixTypeRequestModel {
  /** The unique identifiers of the mix types */
  ids?: number[] | null;
}

export interface APICompanyModel {
  /**
   * The unique identifier for the company
   * @format uuid
   * @example "628ffc50-56c7-4b30-a752-9d9438cbc51a"
   */
  id?: string;
  /**
   * The name of the company (should be unique)
   * @minLength 1
   * @maxLength 500
   * @example "Proxet"
   */
  name: string;
  /**
   * The physical address of the company
   * @minLength 1
   * @maxLength 500
   * @example "Kyiv. Honcharna St. 2, Kyiv, Ukraine 04071"
   */
  address: string;
  /**
   * The unique identifier of the provider where the company is located.
   * @format uuid
   */
  providerId?: string;
  /**
   * Shows when company was created
   * @format date-time
   */
  createdDate?: string | null;
  companyType?: APIUserType;
  /** Shows if company is deleted */
  isDeactivated?: boolean;
  /**
   * Shows if when company was deleted
   * @format date-time
   */
  deactivatedDate?: string | null;
  /** Shows if company can be deleted */
  inUse?: boolean;
}

export interface APICompanyModelSearchResult {
  /**
   * The search page number, will be used for offset
   * @format int32
   * @example 0
   */
  pageNumber?: number;
  /**
   * The count of entities on the page
   * @format int32
   * @example 20
   */
  pageSize?: number;
  /**
   * The count of the searched entities in the DB
   * @format int32
   * @example 1
   */
  count?: number;
  /** The list of the searched entities */
  result?: APICompanyModel[] | null;
}

export interface APICompanyRequestModel {
  /**
   * The name of the company (should be unique)
   * @minLength 1
   * @example "Proxet"
   */
  name: string;
  /**
   * The physical address of the company
   * @maxLength 500
   * @example "Kyiv. Honcharna St. 2, Kyiv, Ukraine 04071"
   */
  address?: string | null;
  companyType: APIUserType;
  /** Flag if company is deactivated */
  isDeactivated?: boolean;
}

export interface APICompanySearchFilter {
  /**
   * The search page number, will be used for offset
   * @format int32
   * @example 0
   */
  pageNumber?: number;
  /**
   * The count of entities on the page
   * @format int32
   * @example 20
   */
  pageSize?: number;
  /** a collection of sort fields */
  searchSortOrders?: APISearchSortOrder[] | null;
  /**
   * The search parameter for the company name
   * @example "Company name"
   */
  name?: string | null;
  companyType?: APIUserType;
  /**
   * Include inactive companies
   * @example true
   */
  includeDeactivated?: boolean;
}

export interface APICorrectionEmailModel {
  /** Text to be send in email */
  text?: string | null;
}

export enum APIDeliveryRateType {
  MinTruck = 'MinTruck',
  CyHour = 'CyHour',
}

export interface APIDialogModel {
  /**
   * The identifier of the order associated with this dialog.
   * @format int32
   */
  orderId?: number;
  /** Order name associated with the dialog. */
  orderName?: string | null;
  orderStatus?: APIOrderStatus;
  /**
   * The date and time when the dialog was last updated.
   * @format date-time
   */
  updatedOn?: string;
  /**
   * The ticks when the dialog was last updated.
   * @format int64
   */
  ticks?: number;
  /**
   * The date and time when the dialog was created.
   * @format date-time
   */
  createdOn?: string;
  /**
   * The unique identifier of the company associated with this dialog.
   * @format uuid
   */
  companyId?: string;
  /** The name of the company. */
  companyName?: string | null;
  /**
   * The unique identifier of the provider associated with this dialog.
   * @format uuid
   */
  providerId?: string;
  /**
   * Last message senderId for the dialog
   * @format uuid
   */
  lastMessageSenderId?: string;
  /** Last message for the dialog */
  lastMessage?: string | null;
  /** Type of the user that wrote the last message. */
  lastMessageUserType?: string | null;
  /** Indicates whether the dialog has been read by the customer. */
  isReadByCustomer?: boolean;
  /**
   * Shows the user who read the dialog
   * @uniqueItems true
   */
  readCustomerIds?: string[] | null;
  /** Indicates whether the dialog has been read by the dispatcher. */
  isReadByDispatcher?: boolean;
  markAsReadBy?: APIMarkAsReadByUser;
  /** Shows dialog highlights */
  highlights?: Record<string, string[] | null>;
}

export interface APIDialogModelContinueSearchResult {
  /**
   * The continue token for search
   * @example "1"
   */
  token?: string | null;
  /** The list of the searched entities */
  result?: APIDialogModel[] | null;
}

export interface APIDialogSearchFilter {
  /** Keyword to search through dialog info */
  keyword?: string | null;
  /** Token used to page through dialog search results */
  continuationToken?: string | null;
  /** @format date-time */
  lastUpdateOn?: string | null;
  dialogs?: string[] | null;
  isFilterDefined?: boolean;
}

export interface APIDigitalFleetEvent {
  /** Indicates the general type of update.  Will be one of TRUCK, TICKET, or POSITION. */
  eventType?: string | null;
  /**
   * UTC time stamp of the event, using ISO-8601 formatting
   * @format date-time
   */
  eventTime?: string;
  /**
   * Direction truck is travelling, as reported by GPS.  In degrees.
   * @format double
   */
  heading?: number | null;
  /**
   * Current latitude of truck, as reported by GPS.
   * @format double
   */
  longitude?: number | null;
  /**
   * Current longitude of truck, as reported by GPS.
   * @format double
   */
  latitude?: number | null;
  /**
   * Unique ID for this event.  Always present
   * @format int32
   */
  messageId?: number | null;
  /** Order number/ID for ticket being updated. */
  orderNumber?: string | null;
  /**
   * Current speed truck is travelling, as reported by GPS.
   * @format double
   */
  speed?: number | null;
  /** Unit in which “Speed” field is measured.  Always “MPH” for miles per hour (but may be variable in future versions). */
  speedUom?: string | null;
  ticketEventCode?: APITicketEventCode;
  /**
   * Digital Fleet ticket ID for ticket being updated.
   * @format int32
   */
  ticketId?: number;
  /** Ticket number being updated. */
  ticketNumber?: string | null;
  ticketStatusCode?: APITicketStatusCode;
  ticketType?: APITicketType;
  /** Identifier for the statusing truck */
  truckName?: string | null;
}

export interface APIDigitalFleetEventData {
  version?: string | null;
  events?: APIDigitalFleetEvent[] | null;
}

export interface APIDriverTrackingModel {
  /**
   * The unique identifier for the user
   * @format uuid
   * @example "628ffc50-56c7-4b30-a752-9d9438cbc51a"
   */
  id?: string;
  /**
   * The first name of the user
   * @minLength 1
   * @example "Alex"
   */
  firstName: string;
  /**
   * The last name of the user
   * @minLength 1
   * @example "Stock"
   */
  lastName: string;
  /**
   * The email address of the user which will be used for login, should be unique
   * @minLength 1
   * @example "alex.stock@cd3p.app"
   */
  email: string;
  /**
   * The phone number of the user which will be for notifications
   * @minLength 1
   * @example "+18571234675"
   */
  phone: string;
  userType: APIUserType;
  userStatus?: APIUserStatus;
  accessLevel: APIAccessLevel;
  /** The list of providers for which user has access */
  providers?: APIProviderModel[] | null;
  /** The list of companies where the user belongs */
  companies?: APICompanyModel[] | null;
  /** The third party id of the user */
  thirdPartyId?: string | null;
  /** The gps tracking system id of the user */
  gpsTrackingId?: string | null;
  /** The token for authorization */
  token?: string | null;
  /** The temporary token for authorization */
  temporaryToken?: string | null;
  /** Version of cache stored on the user device */
  globalCacheVersion?: string | null;
  /** The list of user options */
  userOptions?: Record<string, string | null>;
  newApplicationVersions?: APIApplicationVersionModel[] | null;
  /** @format date-time */
  lastUpdate?: string;
}

export interface APIDriverTrackingModelSearchResult {
  /**
   * The search page number, will be used for offset
   * @format int32
   * @example 0
   */
  pageNumber?: number;
  /**
   * The count of entities on the page
   * @format int32
   * @example 20
   */
  pageSize?: number;
  /**
   * The count of the searched entities in the DB
   * @format int32
   * @example 1
   */
  count?: number;
  /** The list of the searched entities */
  result?: APIDriverTrackingModel[] | null;
}

export interface APIEmptySearchFilter {
  /**
   * The search page number, will be used for offset
   * @format int32
   * @example 0
   */
  pageNumber?: number;
  /**
   * The count of entities on the page
   * @format int32
   * @example 20
   */
  pageSize?: number;
}

export interface APIEmptySortableSearchFilter {
  /**
   * The search page number, will be used for offset
   * @format int32
   * @example 0
   */
  pageNumber?: number;
  /**
   * The count of entities on the page
   * @format int32
   * @example 20
   */
  pageSize?: number;
  /** a collection of sort fields */
  searchSortOrders?: APISearchSortOrder[] | null;
}

export interface APIEntityHistoryModel {
  /** The unique identifier of the entity */
  entityId?: string | null;
  entityType?: APIEntityType;
  /**
   * The timestamp of the change
   * @format date-time
   */
  timeStamp?: string;
  /** The name of the field that was changed */
  fieldName?: string | null;
  /** The new value of the field after the change */
  fieldValue?: string | null;
  /**
   * The id of user who made changes
   * @format uuid
   */
  createdById?: string | null;
  changesStatus?: APIChangesStatus;
}

export interface APIEntityHistoryModelSearchResult {
  /**
   * The search page number, will be used for offset
   * @format int32
   * @example 0
   */
  pageNumber?: number;
  /**
   * The count of entities on the page
   * @format int32
   * @example 20
   */
  pageSize?: number;
  /**
   * The count of the searched entities in the DB
   * @format int32
   * @example 1
   */
  count?: number;
  /** The list of the searched entities */
  result?: APIEntityHistoryModel[] | null;
}

export interface APIEntityHistorySearchFilter {
  /**
   * The search page number, will be used for offset
   * @format int32
   * @example 0
   */
  pageNumber?: number;
  /**
   * The count of entities on the page
   * @format int32
   * @example 20
   */
  pageSize?: number;
  /** The search parameter for entity id */
  entityId?: string | null;
  entityType?: APIEntityType;
}

export enum APIEntityType {
  Order = 'Order',
  Ticket = 'Ticket',
  Truck = 'Truck',
}

export interface APIForgotPasswordRequestModel {
  /** Email for the forgotten password form */
  email?: string | null;
}

export interface APIFullTextSearchFilter {
  /** The global search query for the full text search */
  pattern?: string | null;
  /** The Columns to search in the full text search */
  columns?: string[] | null;
}

export interface APIGuidTypeaheadModel {
  /**
   * The item id
   * @format uuid
   */
  id?: string;
  /**
   * The name of an item
   * @example "TestName"
   */
  name?: string | null;
}

export interface APIGuidTypeaheadRequestModel {
  /** The value pattern to search */
  query?: string | null;
  /**
   * The number of result items to return
   * @format int32
   * @example 5
   */
  count?: number;
  /** Ids to exclude from search result */
  excludedIds?: string[] | null;
}

/** Represents a help request. */
export interface APIHelpRequestModel {
  /** Gets or sets the body of the help request. */
  body?: string | null;
  /** Represents the type of help request. */
  subject?: APIHelpRequestType;
}

/** Represents the type of help request. */
export enum APIHelpRequestType {
  HelpWithApp = 'HelpWithApp',
  ReportIssue = 'ReportIssue',
  ProvideFeedback = 'ProvideFeedback',
  AccountIssue = 'AccountIssue',
  Other = 'Other',
}

export enum APIImportStatus {
  Exist = 'Exist',
  Imported = 'Imported',
  Error = 'Error',
}

export interface APIInt32TypeaheadModel {
  /**
   * The item id
   * @format int32
   */
  id?: number;
  /**
   * The name of an item
   * @example "TestName"
   */
  name?: string | null;
}

export interface APIInt32TypeaheadRequestModel {
  /** The value pattern to search */
  query?: string | null;
  /**
   * The number of result items to return
   * @format int32
   * @example 5
   */
  count?: number;
  /** Ids to exclude from search result */
  excludedIds?: number[] | null;
}

export interface APIIntervalFilter {
  /**
   * The from value search parameter (searched from greater or equal value)
   * @format float
   */
  from?: number | null;
  /**
   * The from value search parameter (searched value less or equal to)
   * @format float
   */
  to?: number | null;
}

export interface APIKeystoneRequestModel {
  tickets?: APIKeystoneTicketModel[] | null;
}

export interface APIKeystoneTicketModel {
  code?: string | null;
  order_label?: string | null;
  plant_id?: string | null;
  asset_id?: string | null;
  /** @format double */
  primary_product_quantity?: number;
  company_id?: string | null;
  company_label?: string | null;
  /** @format int32 */
  is_cancelled?: number;
  plant_label?: string | null;
  project_id?: string | null;
}

export interface APILocation {
  /**
   * The Longitude of the location
   * @format double
   * @example 50.458904
   */
  longitude?: number;
  /**
   * The Latitude of the location
   * @format double
   * @example 30.512978
   */
  latitude?: number;
}

export interface APILocationAddress {
  /**
   * The Longitude of the location
   * @format double
   * @example 50.458904
   */
  longitude?: number;
  /**
   * The Latitude of the location
   * @format double
   * @example 30.512978
   */
  latitude?: number;
  /**
   * The physical address of location
   * @example "Kyiv. Honcharna St. 2, Kyiv, Ukraine 04071"
   */
  address?: string | null;
  /**
   * The name of location
   * @example "Honcharna"
   */
  name?: string | null;
}

export interface APILocationHistoryModel {
  /**
   * The id of the truck for the location history event
   * @format uuid
   */
  truckId?: string;
  /**
   * The id of the user for the location history event
   * @format uuid
   */
  driverId?: string | null;
  /**
   * The longitude of the location history event
   * @format double
   */
  longitude?: number;
  /**
   * The latitude of the location history event
   * @format double
   */
  latitude?: number;
  /**
   * The id of the location history event
   * @format date-time
   */
  createdOn?: string;
}

export interface APILocationHistorySearchFilter {
  /**
   * The search parameter that indicates the start time of the date for start search period
   * @format time
   * @example "11:11"
   */
  timeFrom?: string;
  /**
   * The search parameter that indicates the end time of the date for start search period
   * @format time
   * @example "11:11"
   */
  timeTo?: string;
  /**
   * The search parameter that indicates the truck id for the search
   * @format uuid
   * @example "ef872317-4cd3-41a4-8d3a-2b6c744b4090"
   */
  truckId?: string;
  /**
   * The search parameter the date of the search
   * @format date
   */
  date?: string;
}

export interface APIMarkAsReadByUser {
  /** @format uuid */
  id?: string;
  name?: string | null;
  /** @format date-time */
  date?: string;
}

export interface APIMarkAsReadRequestModel {
  /** The state of mark as read. */
  markAsRead?: boolean;
}

export interface APIMasterAdditiveModel {
  /**
   * The unique identifier of the additive
   * @format int32
   */
  id?: number;
  /** The name of the additive */
  name?: string | null;
  /**
   * The providerId of the additive
   * @format uuid
   */
  providerId?: string;
  /**
   * Number of projects where additive is used
   * @format int32
   */
  assignment?: number;
  /**
   * Number of completed orders where additive was used
   * @format int32
   */
  usage?: number;
  /** Indicates that additive is project specific */
  isProjectSpecific?: boolean;
  /**
   * The ID of the project where the additive is used, only for project specific additives
   * @format uuid
   */
  projectId?: string | null;
  /**
   * The ID of the company where the additive is used, only for project specific additives
   * @format uuid
   */
  companyId?: string | null;
  /** The categories associated with the additive */
  categories?: APIMasterDataCategoryModel[] | null;
}

export interface APIMasterAdditiveModelSearchResult {
  /**
   * The search page number, will be used for offset
   * @format int32
   * @example 0
   */
  pageNumber?: number;
  /**
   * The count of entities on the page
   * @format int32
   * @example 20
   */
  pageSize?: number;
  /**
   * The count of the searched entities in the DB
   * @format int32
   * @example 1
   */
  count?: number;
  /** The list of the searched entities */
  result?: APIMasterAdditiveModel[] | null;
}

export interface APIMasterAdditiveRequestModel {
  /** The name of the additive. */
  name?: string | null;
  /** An array of category IDs associated with the additive. */
  categoryIds?: number[] | null;
  /**
   * A project for the additive, used for project specific additives
   * @format uuid
   */
  projectId?: string | null;
}

export interface APIMasterDataCategoryModel {
  /**
   * The unique identifier of the category.
   * @format int32
   */
  id?: number;
  /** The name of the category. */
  name?: string | null;
}

export interface APIMasterDataCategoryRequestModel {
  /** The name of the category. */
  name?: string | null;
}

export interface APIMasterMixTypeModel {
  /**
   * The unique identifier of the mix type
   * @format int32
   */
  id?: number;
  /** The name of the mix type */
  name?: string | null;
  /**
   * The providerId of the mix type
   * @format uuid
   */
  providerId?: string;
  /**
   * Number of projects where mix type is used
   * @format int32
   */
  assignment?: number;
  /**
   * Number of completed orders where mix type was used
   * @format int32
   */
  usage?: number;
  /** Indicates that mix type is project specific */
  isProjectSpecific?: boolean;
  /**
   * The ID of the project where the mix type is used, only for project specific mixes
   * @format uuid
   */
  projectId?: string | null;
  /**
   * The ID of the company where the mix type is used, only for project specific mixes
   * @format uuid
   */
  companyId?: string | null;
  /** Collection of mix type categories */
  categories?: APIMasterDataCategoryModel[] | null;
}

export interface APIMasterMixTypeModelSearchResult {
  /**
   * The search page number, will be used for offset
   * @format int32
   * @example 0
   */
  pageNumber?: number;
  /**
   * The count of entities on the page
   * @format int32
   * @example 20
   */
  pageSize?: number;
  /**
   * The count of the searched entities in the DB
   * @format int32
   * @example 1
   */
  count?: number;
  /** The list of the searched entities */
  result?: APIMasterMixTypeModel[] | null;
}

export interface APIMasterMixTypeRequestModel {
  /** The name of the mix type. */
  name?: string | null;
  /** An array of category IDs associated with the mix type. */
  categoryIds?: number[] | null;
  /**
   * A project for the mix type, used for project specific mixes
   * @format uuid
   */
  projectId?: string | null;
}

export interface APIMixTypeCompanyUsageModel {
  /**
   * The unique identifier of the company
   * @format uuid
   */
  id?: string;
  /** The name of the company */
  name?: string | null;
}

export interface APIMixTypeModel {
  /**
   * The unique identifier of the mix type
   * @format int32
   */
  id?: number;
  /** The name of the mix type */
  name?: string | null;
}

export interface APIMixTypeSearchFilter {
  /**
   * The search page number, will be used for offset
   * @format int32
   * @example 0
   */
  pageNumber?: number;
  /**
   * The count of entities on the page
   * @format int32
   * @example 20
   */
  pageSize?: number;
  /** a collection of sort fields */
  searchSortOrders?: APISearchSortOrder[] | null;
  /** The search parameter that indicates the Mix Type name to search */
  name?: string | null;
  /** The search parameter for the category to search */
  categoryIds?: number[] | null;
  /** The search parameter that indicates if the mix type is project specific */
  isProjectSpecific?: boolean;
}

export interface APIMostUsedModel {
  /** @format int32 */
  percentage?: number;
  usages?: APIUsageModel[] | null;
}

export interface APIMpaqDataModel {
  TicketNumber?: string | null;
  OrderID?: string | null;
  PlantID?: string | null;
  TruckID?: string | null;
  DriverID?: string | null;
  /** @format double */
  MixAmount?: number;
  /** @format date-time */
  ticketedTime?: string;
  /** @format date-time */
  loadedTime?: string;
  clientID?: string | null;
  mixID?: string | null;
  mixUnit?: string | null;
  projectID?: string | null;
  yardID?: string | null;
}

export interface APIMpaqRequestModel {
  event?: string | null;
  /** @format date-time */
  timestamp?: string;
  data?: APIMpaqDataModel;
}

export interface APIOrderAdditiveModel {
  /** @format int32 */
  id?: number;
  name?: string | null;
  originalName?: string | null;
}

export interface APIOrderBatchNumberRequestModel {
  /**
   * The unique identifier of the order in batch system
   * @example "test-order-1"
   */
  orderBatchNumber?: string | null;
}

export interface APIOrderCallbackSubmitRequestModel {
  callBackFormStatus?: APICallBackFormStatus;
  /**
   * The volume of the callback requested
   * @format float
   */
  callbackFormVolume?: number | null;
  /** Additional details for callback form */
  additionalCallbackFormDetails?: string | null;
}

export interface APIOrderChangesRequestModel {
  orderStatus?: APIOrderStatus;
  /**
   * Notes for the changes requested
   * @example "Lorem ipsum dolor sit amet"
   */
  noteToCustomer?: string | null;
  /** Shows if to confirm order upon approval of change request */
  confirmUponChangesApproval?: boolean;
  /** Summary of changes that made to the order by the contractor */
  orderChanges?: APIEntityHistoryModel[] | null;
}

export interface APIOrderImportModel {
  /**
   * The unique identifer of the order for the customer
   * @example "test-order-1"
   */
  orderNumber?: string | null;
  /**
   * The project name, where should be done delivery
   * @example "Proxet project"
   */
  projectName?: string | null;
  /**
   * The address where project is located
   * @example "Kyiv. Honcharna St. 2, Kyiv, Ukraine 04071"
   */
  deliveryLocation?: string | null;
  /**
   * The required delivery date for the order
   * @format date
   * @example "2022-11-11"
   */
  deliveryDate?: string | null;
  /**
   * The required delivery time fot the order
   * @format time
   * @example "11-11 AM"
   */
  deliveryTime?: string | null;
  /**
   * The volume of concrete that should be delivered
   * @format float
   * @example 11
   */
  volume?: number;
  /**
   * The concrete mix type for the order
   * @example "4000psi"
   */
  mixType?: string | null;
  /**
   * The delivery rate of the order
   * @format float
   * @example 10
   */
  deliveryRate?: number;
  /**
   * The elements to pouring for the order
   * @example "Sidewalk"
   */
  jobDescription?: string | null;
  /** The contractor company name */
  companyName?: string | null;
  /**
   * The slump value of the concrete in the order.
   * The slump is a measure of the consistency of the concrete mix, and is expressed in inches.
   * @format double
   * @example 6.5
   */
  slump?: number | null;
  /**
   * The selected additives for the order
   * Should be separated by ',' symbol
   * @example "Additive 1, Additive 2"
   */
  additives?: string | null;
  /**
   * Notes for the order
   * @example "Lorem ipsum dolor sit amet"
   */
  notes?: string | null;
}

export interface APIOrderImportModelImportResult {
  model?: APIOrderImportModel;
  importStatus?: APIImportStatus;
  details?: string[] | null;
}

export interface APIOrderMixTypeModel {
  /** @format int32 */
  id?: number;
  name?: string | null;
  originalName?: string | null;
}

export interface APIOrderModel {
  /**
   * The unique identifier for the order in the cd3p system
   * @format int32
   * @example 1
   */
  id?: number;
  orderStatus?: APIOrderStatus;
  orderType?: APIOrderType;
  /**
   * The unique identifier of the order for the customer
   * @example "test-order-1"
   */
  orderNumber?: string | null;
  /** The order name */
  orderName?: string | null;
  /**
   * The providerId of the order
   * @format uuid
   */
  providerId?: string;
  project?: APIProjectModel;
  mixType?: APIOrderMixTypeModel;
  /**
   * The address where project is located
   * @example "Kyiv. Honcharna St. 2, Kyiv, Ukraine 04071"
   */
  deliveryLocation?: string | null;
  /**
   * The Longitude of the project location
   * @format float
   * @example 50.458904
   */
  longitude?: number;
  /**
   * The Latitude of the project location
   * @format float
   * @example 30.512978
   */
  latitude?: number;
  /**
   * The required delivery date for the order
   * @format date
   * @example "2022-11-11"
   */
  deliveryDate?: string;
  /**
   * The required delivery time fot the order
   * @format time
   * @example "11-11 AM"
   */
  deliveryTime?: string;
  /**
   * The volume of concrete that should be delivered
   * @format float
   * @example 11
   */
  volume?: number;
  /**
   * The original volume of concrete that should be delivered
   * @format float
   * @example 11
   */
  originalVolume?: number;
  /**
   * The total of drivers/tickets concrete which are currently in Delivering/On-Site states
   * @format float
   * @example 10
   */
  batched?: number;
  /**
   * The total of drivers (trucks) concrete quantity that left the job site
   * @format float
   * @example 10
   */
  delivered?: number;
  /**
   * The delivery rate of the order
   * @format float
   * @example 10
   */
  deliveryRate?: number;
  /**
   * The Actual computed delivery rate of the order
   * @format float
   * @example 10
   */
  actualDeliveryRate?: number | null;
  deliveryRateType?: APIDeliveryRateType;
  /**
   * eThe elements to pouring for the order
   * @example "Sidewalk"
   */
  jobDescription?: string | null;
  /**
   * Shows when order was created
   * @format date-time
   */
  createdDate?: string | null;
  company?: APICompanyModel;
  /**
   * The date time which shows when dispatcher confirm order and send notification to user
   * @format date-time
   */
  notificationSentDate?: string | null;
  /**
   * The date time which shows when user either confirm or reject order
   * @format date-time
   */
  confirmationRequestDate?: string | null;
  /**
   * The total ticket count that was created for the order
   * @format int32
   */
  ticketsCount?: number;
  /** The Flag indicating that all order tickets completed and order status can be updated to completed */
  pendingCompletion?: boolean;
  /**
   * Notes for the order
   * @example "Lorem ipsum dolor sit amet"
   */
  notes?: string | null;
  /**
   * The slump value of the concrete in the order.
   * The slump is a measure of the consistency of the concrete mix, and is expressed in inches.
   * @format double
   * @example 6.5
   */
  slump?: number | null;
  /** Indicates whether there are any changes on the requested status of the order */
  hasChangesOnRequestedState?: boolean;
  /** The list of available additives selected for the order */
  additives?: APIOrderAdditiveModel[] | null;
  /** The third party id of the order */
  thirdPartyId?: string | null;
  /** Summary of changes that made to the order by the contractor */
  orderChanges?: APIEntityHistoryModel[] | null;
  /** Flag indicating that Batch Number reminder was reviewed by the user */
  isBatchNumberReminderSubmitted?: boolean;
  /** Flag indicating that changes requested on the order */
  isChangesRequested?: boolean;
  /** Shows whether this order was read by the dispatcher or not */
  isReadByDispatcher?: boolean;
  /** Will allow to close orders automatically */
  callBack?: boolean;
  callBackFormStatus?: APICallBackFormStatus;
  /**
   * The volume of the callback requested
   * @format float
   */
  callbackFormVolume?: number | null;
  /** Additional details for callback form */
  additionalCallbackFormDetails?: string | null;
  /** Shows whether user subscribed to order notifications or not */
  isUserSubscribed?: boolean;
  dialog?: APIDialogModel;
  /** Shows if order cancellation request was submitted */
  isCancellationSubmitted?: boolean;
  /** Shows if to confirm order upon approval of change request */
  confirmUponChangesApproval?: boolean;
  /** Field to describe what is being poured */
  typeOfPour?: string | null;
  /** Field to describe how concrete will be placed */
  placementMethod?: string | null;
  /** Shows order highlights */
  highlights?: Record<string, string[] | null>;
  /** The list of project subscribed users */
  subscribedUsers?: APISubscribedUserModel[] | null;
}

export interface APIOrderModelPositionElement {
  /**
   * The position of the element in the list
   * @format int32
   */
  position?: number;
  element?: APIOrderModel;
}

export interface APIOrderModelSearchResult {
  /**
   * The search page number, will be used for offset
   * @format int32
   * @example 0
   */
  pageNumber?: number;
  /**
   * The count of entities on the page
   * @format int32
   * @example 20
   */
  pageSize?: number;
  /**
   * The count of the searched entities in the DB
   * @format int32
   * @example 1
   */
  count?: number;
  /** The list of the searched entities */
  result?: APIOrderModel[] | null;
}

export interface APIOrderRequestModel {
  /**
   * The unique identifer of the order for the customer
   * @example "test-order-1"
   */
  orderNumber?: string | null;
  /** The order name */
  orderName?: string | null;
  /**
   * The required delivery date for the order
   * @format date
   * @example "2022-11-11"
   */
  deliveryDate?: string | null;
  /**
   * The address where project is located
   * @example "Kyiv. Honcharna St. 2, Kyiv, Ukraine 04071"
   */
  deliveryLocation?: string | null;
  /**
   * The Longitude of the project location
   * @format float
   * @example 50.458904
   */
  longitude?: number | null;
  /**
   * The Latitude of the project location
   * @format float
   * @example 30.512978
   */
  latitude?: number | null;
  /**
   * The required delivery time fot the order
   * @format time
   * @example "11:11"
   */
  deliveryTime?: string | null;
  /**
   * The volume of concrete that should be delivered
   * @format float
   * @example 11
   */
  volume?: number;
  /**
   * The type of concrete that should be delivered
   * @format int32
   */
  mixTypeId?: number;
  /**
   * The delivery rate of the order
   * @format float
   * @example 10
   */
  deliveryRate?: number;
  deliveryRateType?: APIDeliveryRateType;
  /**
   * The type of the pouring mode
   * @example "Direct"
   */
  pouringMode?: string | null;
  /**
   * eThe elements to pouring for the order
   * @example "Sidewalk"
   */
  jobDescription?: string | null;
  /**
   * The company project id
   * @format uuid
   */
  projectId?: string;
  orderStatus?: APIOrderStatus;
  orderType?: APIOrderType;
  /**
   * The date time which shows when dispatcher confirm order and send notification to user
   * @format date-time
   */
  notificationSentDate?: string | null;
  /**
   * The date time which shows when user either confirm or reject order
   * @format date-time
   */
  confirmationRequestDate?: string | null;
  /**
   * Notes for the order
   * @example "Lorem ipsum dolor sit amet"
   */
  notes?: string | null;
  /**
   * Notes for the changes requested
   * @example "Lorem ipsum dolor sit amet"
   */
  noteToCustomer?: string | null;
  /**
   * The slump value of the concrete in the order.
   * The slump is a measure of the consistency of the concrete mix, and is expressed in inches.
   * @format double
   * @example 6.5
   */
  slump?: number | null;
  /** The list of selected additives for the order */
  additives?: APIAdditiveRequestModel[] | null;
  /** Flag indicating that Batch Number reminder was reviewed by the user */
  isBatchNumberReminderSubmitted?: boolean;
  /** Flag indicating that changes requested on the order */
  isChangesRequested?: boolean;
  /** Will allow to close orders automatically */
  callBack?: boolean;
  callBackFormStatus?: APICallBackFormStatus;
  /**
   * The volume of the callback requested
   * @format float
   */
  callbackFormVolume?: number | null;
  /** Additional details for callback form */
  additionalCallbackFormDetails?: string | null;
  /** Shows if order update should notify contractor by showing popup notification. */
  shouldNotifyContractor?: boolean | null;
  /** Shows if order cancellation request was submitted */
  isCancellationSubmitted?: boolean | null;
  /** Shows if to confirm order upon approval of change request */
  confirmUponChangesApproval?: boolean;
  /** Field to describe what is being poured */
  typeOfPour?: string | null;
  /** Field to describe how concrete will be placed */
  placementMethod?: string | null;
  /** The unique identifiers of the users to be subscribed to order */
  subscribedUsersIds?: string[] | null;
}

export interface APIOrderSearchFilter {
  /**
   * The search page number, will be used for offset
   * @format int32
   * @example 0
   */
  pageNumber?: number;
  /**
   * The count of entities on the page
   * @format int32
   * @example 20
   */
  pageSize?: number;
  /** a collection of sort fields */
  searchSortOrders?: APISearchSortOrder[] | null;
  fullTextSearch?: APIFullTextSearchFilter;
  /**
   * The search parameter that indicates the start search period for the order
   * @format date-time
   */
  dateFrom?: string | null;
  /**
   * The search parameter that indicates the end search period for the order
   * @format date-time
   */
  dateTo?: string | null;
  /** The search parameter that indicates the end search period for the order */
  orderNumber?: string | null;
  /** The search parameter for the array of the volumes of the order */
  volume?: APIIntervalFilter[] | null;
  /** The search parameter for the order status */
  orderStatuses?: APIOrderStatus[] | null;
  /** The array of the search order ids */
  orderIds?: number[] | null;
  /** The search parameter for the ids of the company for which order was created */
  companyIds?: string[] | null;
  /** The search parameter for project ids */
  projectIds?: string[] | null;
  /** Filters orders with cancellation request submitted */
  isCancellationSubmitted?: boolean | null;
  /** Filter on orders where current user subscribed */
  isUserSubscribed?: boolean | null;
}

export enum APIOrderStatus {
  Requested = 'Requested',
  Unconfirmed = 'Unconfirmed',
  Declined = 'Declined',
  Confirmed = 'Confirmed',
  Cancelled = 'Cancelled',
  Delivering = 'Delivering',
  Completed = 'Completed',
}

export interface APIOrderSubscriptionModel {
  /** @format int32 */
  orderId?: number;
  /** @format date-time */
  createdOn?: string;
  /** @format date-time */
  updatedOn?: string;
  /** @format int64 */
  ticks?: number;
  isDeleted?: boolean;
}

export interface APIOrderSubscriptionRequestModel {
  /** The unique identifiers of the users to be subscribed to order */
  subscribedUsersIds?: string[] | null;
}

export interface APIOrderSubscriptionsFilter {
  /**
   * The search page number, will be used for offset
   * @format int32
   * @example 0
   */
  pageNumber?: number;
  /**
   * The count of entities on the page
   * @format int32
   * @example 20
   */
  pageSize?: number;
  /** a collection of sort fields */
  searchSortOrders?: APISearchSortOrder[] | null;
  /**
   * The period of time to search for subscriptions when the subscription was updated
   * @format date-time
   */
  updateOn?: string | null;
  /** Search for subscriptions by order ids */
  orderIds?: number[] | null;
  /** Flag that shows returns only active subscriptions */
  onlyActive?: boolean;
}

export enum APIOrderType {
  WillCall = 'WillCall',
  FirmOrder = 'FirmOrder',
}

export interface APIPlantModel {
  /**
   * The unique identifier for the plant
   * @format uuid
   */
  id?: string;
  /**
   * The address where plant is located
   * @example "Kyiv. Honcharna St. 2, Kyiv, Ukraine 04071"
   */
  address?: string | null;
  /**
   * The name of a plant
   * @example "Best Plant"
   */
  name?: string | null;
  /**
   * The Longitude of the plant location
   * @format float
   * @example 50.458904
   */
  longitude?: number;
  /**
   * The Latitude of the plant location
   * @format float
   * @example 30.512978
   */
  latitude?: number;
  /** The third party id of the plant */
  thirdPartyId?: string | null;
  /**
   * The radius of the plant
   * @format int32
   */
  plantRadius?: number | null;
  /** Geo fence coordinates */
  geoFenceCoordinates?: APILocation[] | null;
}

export interface APIPlantModelSearchResult {
  /**
   * The search page number, will be used for offset
   * @format int32
   * @example 0
   */
  pageNumber?: number;
  /**
   * The count of entities on the page
   * @format int32
   * @example 20
   */
  pageSize?: number;
  /**
   * The count of the searched entities in the DB
   * @format int32
   * @example 1
   */
  count?: number;
  /** The list of the searched entities */
  result?: APIPlantModel[] | null;
}

export interface APIPlantRequestModel {
  /**
   * The address where plant is located
   * @example "Kyiv. Honcharna St. 2, Kyiv, Ukraine 04071"
   */
  address?: string | null;
  /**
   * The name of a plant
   * @example "Best Plant"
   */
  name?: string | null;
  /** The third party id of the plant */
  thirdPartyId?: string | null;
  /**
   * The Longitude of the location
   * @format float
   * @example 50.458904
   */
  longitude?: number | null;
  /**
   * The Latitude of the location
   * @format float
   * @example 30.512978
   */
  latitude?: number | null;
  /**
   * The radius of the plant
   * @format int32
   */
  plantRadius?: number | null;
  /** The coordinates of the plant */
  geoFenceCoordinates?: APILocation[] | null;
}

export interface APIPlantSearchFilter {
  /**
   * The search page number, will be used for offset
   * @format int32
   * @example 0
   */
  pageNumber?: number;
  /**
   * The count of entities on the page
   * @format int32
   * @example 20
   */
  pageSize?: number;
  /** a collection of sort fields */
  searchSortOrders?: APISearchSortOrder[] | null;
  name?: string | null;
  address?: string | null;
  providerName?: string | null;
}

export interface APIPlantUpdateRequestModel {
  /**
   * The name of a plant
   * @example "Best Plant Ever"
   */
  name?: string | null;
  /** The third party id of the plant */
  thirdPartyId?: string | null;
  /**
   * The radius of the plant
   * @format int32
   */
  plantRadius?: number | null;
  /**
   * The Longitude of the location
   * @format double
   * @example 50.458904
   */
  longitude?: number | null;
  /**
   * The Latitude of the location
   * @format double
   * @example 30.512978
   */
  latitude?: number | null;
  /** Geo fence coordinates */
  geoFenceCoordinates?: APILocation[] | null;
}

export interface APIProjectAdditiveRequestModel {
  /** The unique identifiers of the additives */
  ids?: number[] | null;
}

export interface APIProjectMixTypeRequestModel {
  /** The unique identifiers of the mix types */
  ids?: number[] | null;
}

export interface APIProjectModel {
  /**
   * The unique identifier for project
   * @format uuid
   */
  id?: string;
  /** The project name, where should be done delivery */
  name?: string | null;
  projectStatus?: APIProjectStatus;
  company?: APICompanyModel;
  /** The address where the project is located */
  address?: string | null;
  /** The delivery address */
  deliveryAddress?: string | null;
  /**
   * The Longitude of the project location
   * @format float
   */
  longitude?: number;
  /**
   * The Latitude of the project location
   * @format float
   */
  latitude?: number;
  /**
   * Shows when project was created
   * @format date-time
   */
  createdDate?: string | null;
  /** Field that shows whether project is used by someone or not */
  inUse?: boolean;
  /** Field that shows whether project has orders in active statuses */
  hasActiveOrders?: boolean;
  /**
   * The total orders count that was created for the project
   * @format int32
   */
  ordersCount?: number;
  /** The list of available mix types for the project */
  mixTypes?: APIMixTypeModel[] | null;
  /** The list of available additives for the project */
  additives?: APIAdditiveModel[] | null;
  /** Shows if project is deleted */
  isDeleted?: boolean;
  /** Project description */
  description?: string | null;
  /** The list of project options */
  projectOptions?: Record<string, string | null>;
  /** The list of project subscribed users */
  subscribedUsers?: APISubscribedUserModel[] | null;
}

export interface APIProjectModelSearchResult {
  /**
   * The search page number, will be used for offset
   * @format int32
   * @example 0
   */
  pageNumber?: number;
  /**
   * The count of entities on the page
   * @format int32
   * @example 20
   */
  pageSize?: number;
  /**
   * The count of the searched entities in the DB
   * @format int32
   * @example 1
   */
  count?: number;
  /** The list of the searched entities */
  result?: APIProjectModel[] | null;
}

export interface APIProjectRequestModel {
  /** The project name, where should be done delivery */
  name?: string | null;
  /** The address where the project is located */
  address?: string | null;
  /** The delivery address */
  deliveryAddress?: string | null;
  /**
   * The longitude of the project location or delivery,
   * Required if Delivery Address provided
   * @format float
   */
  longitude?: number | null;
  /**
   * The latitude of the project location or delivery
   * Required if Delivery Address provided
   * @format float
   */
  latitude?: number | null;
  /**
   * The company id of the project
   * @format uuid
   */
  companyId?: string;
  projectStatus?: APIProjectStatus;
  /** Project description */
  description?: string | null;
  /** Flag that indicates that the project contains custom mix types */
  isCustomMixTypes?: boolean;
  /** Flag that indicates that the project contains custom additives */
  isCustomAdditives?: boolean;
  /** The unique identifiers of the users to be subscribed to project */
  subscribedUsersIds?: string[] | null;
}

export interface APIProjectSearchFilter {
  /**
   * The search page number, will be used for offset
   * @format int32
   * @example 0
   */
  pageNumber?: number;
  /**
   * The count of entities on the page
   * @format int32
   * @example 20
   */
  pageSize?: number;
  /** a collection of sort fields */
  searchSortOrders?: APISearchSortOrder[] | null;
  /** The search parameter for the name and address of the project */
  keyword?: string | null;
  /** The array of the search project ids */
  projectIds?: string[] | null;
  /** The search parameter for the ids of the company for which project was created */
  companyIds?: string[] | null;
  /** The search parameter which show what kind of project should be return by status */
  projectStatuses?: APIProjectStatus[] | null;
}

export enum APIProjectStatus {
  Active = 'Active',
  Archived = 'Archived',
}

export interface APIProjectSubscriptionRequestModel {
  /** The unique identifiers of the users */
  subscribedUsersIds?: string[] | null;
}

export interface APIProjectTypeaheadRequestModel {
  /** The value pattern to search */
  query?: string | null;
  /**
   * The number of result items to return
   * @format int32
   * @example 5
   */
  count?: number;
  /** Ids to exclude from search result */
  excludedIds?: string[] | null;
  /**
   * The search parameter for the company id
   * @format uuid
   */
  companyId?: string;
  /** Indicates that projects without mixes should be included in the results */
  includeProjectsWithoutMixes?: boolean;
}

export interface APIProviderAdminRequestModel {
  /** The email of the provider admin. */
  email?: string | null;
  /** The first name of the provider admin. */
  firstName?: string | null;
  /** The last name of the provider admin. */
  lastName?: string | null;
}

export interface APIProviderModel {
  /**
   * The unique identifier of the provider
   * @format uuid
   * @example "5fba66db-dd49-4653-9f8a-7d79e51e6e9f"
   */
  id?: string;
  /**
   * The name of the provider
   * @example "Test provider"
   */
  providerName?: string | null;
  /**
   * The address of provider's office
   * @example "Kyiv. Honcharna St. 2, Kyiv, Ukraine 04071"
   */
  officeAddress?: string | null;
  /**
   * The Longitude of the project location
   * @format float
   * @example 50.458904
   */
  longitude?: number;
  /**
   * The Latitude of the project location
   * @format float
   * @example 30.512978
   */
  latitude?: number;
  /**
   * The Latitude of the providers middle location
   * @format double
   */
  centroidLatitude?: number;
  /**
   * The Longitude of the providers middle location
   * @format double
   */
  centroidLongitude?: number;
  /**
   * The phone number of the provider company
   * @example "+18571234675"
   */
  phone?: string | null;
  /**
   * The date when the provider was created
   * @format date-time
   */
  createdOn?: string;
}

export interface APIProviderModelSearchResult {
  /**
   * The search page number, will be used for offset
   * @format int32
   * @example 0
   */
  pageNumber?: number;
  /**
   * The count of entities on the page
   * @format int32
   * @example 20
   */
  pageSize?: number;
  /**
   * The count of the searched entities in the DB
   * @format int32
   * @example 1
   */
  count?: number;
  /** The list of the searched entities */
  result?: APIProviderModel[] | null;
}

export interface APIProviderRequestModel {
  /**
   * The name of the provider
   * @example "Test provider"
   */
  providerName?: string | null;
  /**
   * The address of provider's office
   * @example "Kyiv. Honcharna St. 2, Kyiv, Ukraine 04071"
   */
  officeAddress?: string | null;
  /**
   * The phone number of the provider company
   * @example "+18571234675"
   */
  phone?: string | null;
}

export interface APIProviderSearchFilter {
  /**
   * The search page number, will be used for offset
   * @format int32
   * @example 0
   */
  pageNumber?: number;
  /**
   * The count of entities on the page
   * @format int32
   * @example 20
   */
  pageSize?: number;
  /** a collection of sort fields */
  searchSortOrders?: APISearchSortOrder[] | null;
  /**
   * The search parameter for provider name (search by start with condition)
   * @example "bostone concrete"
   */
  providerName?: string | null;
}

export interface APIProviderSettingsModel {
  /**
   * The name of a setting
   * @example "SendFromPhone"
   */
  key?: string | null;
  /**
   * Setting value
   * @example "+18574474475"
   */
  value?: string | null;
}

export interface APIProviderSettingsRequestModel {
  providerSettings?: APIProviderSettingsModel[] | null;
}

export interface APISearchSortOrder {
  /**
   * The name of a sort field
   * @default "Id"
   * @example "OrderNumber"
   */
  sortField?: string | null;
  sortOrder?: APISortOrder;
}

export enum APISortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface APISubscribedUserModel {
  /** @format uuid */
  id?: string;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
}

export interface APITemporaryTokenModel {
  token?: string | null;
}

export enum APITicketEventCode {
  TicketCreated = 'TicketCreated',
  TicketAssigned = 'TicketAssigned',
  BeginLoad = 'BeginLoad',
  ToJob = 'ToJob',
  ArriveJob = 'ArriveJob',
  BeginPour = 'BeginPour',
  StopPour = 'StopPour',
  ToYard = 'ToYard',
  ProductAdd = 'ProductAdd',
  Completed = 'Completed',
  Updated = 'Updated',
  TicketVoid = 'TicketVoid',
  LoadComplete = 'LoadComplete',
  ArrivePickup = 'ArrivePickup',
  DelayAtJob = 'DelayAtJob',
  TruckAssigned = 'TruckAssigned',
  ToPickup = 'ToPickup',
  Deactivate = 'Deactivate',
  PausePour = 'PausePour',
  ResumePour = 'ResumePour',
}

export interface APITicketModel {
  /**
   * The unique identifier for the ticket in the cd3p system
   * @format int32
   * @example 1
   */
  id?: number;
  /**
   * Ticket number
   * @example "test"
   */
  ticketNumber?: string | null;
  driver?: APIUserModel;
  truck?: APITruckModel;
  plant?: APIPlantModel;
  order?: APIOrderModel;
  company?: APICompanyModel;
  project?: APIProjectModel;
  /**
   * The Longitude of the project location
   * @format float
   */
  longitude?: number;
  /**
   * The Latitude of the project location
   * @format float
   */
  latitude?: number;
  /** The address where project is located */
  deliveryLocation?: string | null;
  /**
   * Order Id associated with ticket
   * @format int32
   */
  orderId?: number;
  /** Order Number associated with ticket */
  orderNumber?: string | null;
  /** Order Name associated with ticket */
  orderName?: string | null;
  /**
   * Q-ty of concrete
   * @format float
   */
  batchSize?: number;
  /**
   * Total on Delivery 1st ticket batch_size + … + n ticket+batcр_size
   * @format float
   */
  totalOnDelivery?: number;
  ticketStatus?: APITicketStatus;
  /**
   * Date and time when ticket was created
   * @format date-time
   */
  createdDate?: string;
  /**
   * Date and time when delivery was made
   * @format date-time
   */
  deliveryArrivalTime?: string;
  /**
   * Text with cancellation reason of a ticket
   * @example "Ordered by mistake "
   */
  cancellationReasonComment?: string | null;
  /** History of ticket status changes */
  ticketStatusHistory?: APITicketStatusHistoryModel[] | null;
}

export interface APITicketModelSearchResult {
  /**
   * The search page number, will be used for offset
   * @format int32
   * @example 0
   */
  pageNumber?: number;
  /**
   * The count of entities on the page
   * @format int32
   * @example 20
   */
  pageSize?: number;
  /**
   * The count of the searched entities in the DB
   * @format int32
   * @example 1
   */
  count?: number;
  /** The list of the searched entities */
  result?: APITicketModel[] | null;
}

export interface APITicketRequestModel {
  /**
   * Ticket number
   * @example "test"
   */
  ticketNumber?: string | null;
  /**
   * Truck id assigned to ticket
   * @format uuid
   */
  truckId?: string | null;
  /**
   * Driver id assigned to ticket
   * @format uuid
   */
  driverId?: string | null;
  /**
   * Plant id for ticket
   * @format uuid
   */
  plantId?: string | null;
  /**
   * Project id for ticket
   * @format uuid
   */
  projectId?: string | null;
  /**
   * Q-ty of concrete
   * @format float
   */
  batchSize?: number;
  ticketStatus?: APITicketStatus;
  /**
   * Text with cancellation reson of a ticket
   * @example "Ordered by mistake "
   */
  cancellationReasonComment?: string | null;
}

export interface APITicketSearchFilter {
  /**
   * The search page number, will be used for offset
   * @format int32
   * @example 0
   */
  pageNumber?: number;
  /**
   * The count of entities on the page
   * @format int32
   * @example 20
   */
  pageSize?: number;
  /** a collection of sort fields */
  searchSortOrders?: APISearchSortOrder[] | null;
  /**
   * The search parameter for the ticket number (search by contains condition)
   * @example "test"
   */
  ticketNumber?: string | null;
  ticketStatuses?: APITicketStatus[] | null;
  /**
   * The search parameter for the ticket driver id
   * @format uuid
   */
  driverId?: string | null;
  /**
   * The search parameter for the ticket truck id
   * @format uuid
   */
  truckId?: string | null;
  /**
   * The search parameter for the ticket plant
   * @format uuid
   */
  plantId?: string | null;
  /**
   * The search parameter for the ticket order
   * @format int32
   */
  orderId?: number | null;
  /**
   * The search parameter for the batch size
   * @format double
   * @example 1
   */
  batchSize?: number;
  /**
   * The search parameter that indicates the start search period for the ticket
   * @format date-time
   */
  dateFrom?: string | null;
  /**
   * The search parameter that indicates the end search period for the ticket
   * @format date-time
   */
  dateTo?: string | null;
  /** The search parameter that indicates that the driver is assigned to the ticket */
  hasAssignedDriver?: boolean | null;
  /**
   * The search parameter that indicates that total on delivery should be calculated,
   * Works only if all required statuses are in the result
   */
  calculateTotalOnDelivery?: boolean;
}

export enum APITicketStatus {
  Draft = 'Draft',
  Loading = 'Loading',
  Delivering = 'Delivering',
  OnSite = 'OnSite',
  Completed = 'Completed',
  Cancelled = 'Cancelled',
  Pending = 'Pending',
}

export enum APITicketStatusCode {
  TicketCreated = 'TicketCreated',
  Ticketed = 'Ticketed',
  Loading = 'Loading',
  ToJob = 'ToJob',
  Waiting = 'Waiting',
  Pouring = 'Pouring',
  Returning = 'Returning',
  Completed = 'Completed',
  Cancelled = 'Cancelled',
  ArrivePickup = 'ArrivePickup',
  Loaded = 'Loaded',
  Poured = 'Poured',
  TruckAssigned = 'TruckAssigned',
  ToPickup = 'ToPickup',
}

export interface APITicketStatusHistoryModel {
  ticketStatus?: APITicketStatus;
  /**
   * The timestamp when the status was changed.
   * @format date-time
   */
  timeStamp?: string;
  /** The flag indicating that the change was done manually */
  isManualChange?: boolean;
}

export enum APITicketType {
  StandardTicket = 'StandardTicket',
  QuickRouteTicket = 'QuickRouteTicket',
}

export interface APITimeOfArrivalModel {
  /** @format int32 */
  duration?: number;
  /** @format int32 */
  distance?: number;
  /** @format date-time */
  lastUpdated?: string;
  lastLocation?: APILocation;
}

export interface APITimeZonesModel {
  countryName?: string | null;
  countryCode?: string | null;
  zoneId?: string | null;
  zoneName?: string | null;
  offset?: string | null;
}

export interface APITrackEmulateModel {
  /**
   * The Id of a truck
   * @format uuid
   */
  truckId?: string;
  /**
   * Delay
   * @format int32
   */
  delay?: number;
  /** Truck Number */
  trackName?: string | null;
  /**
   * File with tracking info
   * @format binary
   */
  file?: File | null;
}

export interface APITrackModel {
  name?: string | null;
}

export interface APITruckLocationModel {
  /**
   * Truck number.
   * @example "308"
   */
  truckNumber?: string | null;
  /** GPS track device identifier associated with the truck. */
  gpsTrackDeviceId?: string | null;
  /** Unique identifier for the truck. */
  truckId?: string | null;
  /** Identifier for the driver of the truck. */
  driverId?: string | null;
  /** The first name of the driver. */
  driverFirstName?: string | null;
  /** The last name of the driver. */
  driverLastName?: string | null;
  /** GPS tracking identifier for the driver. */
  driverGpsTrackingId?: string | null;
  /**
   * Associated ticket.
   * @format int32
   */
  ticketId?: number | null;
  /**
   * Associated companyId
   * @format uuid
   */
  companyId?: string | null;
  /** Ticket number associated with the truck. */
  ticketNumber?: string | null;
  /** Project name associated with the truck. */
  projectName?: string | null;
  /** Plant name associated with the truck. */
  plantName?: string | null;
  /**
   * Unique identifier for the associated order.
   * @format int32
   */
  orderId?: number | null;
  timeOfArrival?: APITimeOfArrivalModel;
  currentLocation?: APILocation;
  plantLocation?: APILocation;
  deliveryLocation?: APILocation;
  truckStatus?: APITruckStatus;
  /** Flag indicating whether the truck is online. */
  isOnline?: boolean;
  /**
   * The timestamp of the last update for the truck's information.
   * @format date-time
   */
  lastUpdate?: string;
  /** Delivery address associated with the truck. */
  deliveryAddress?: string | null;
  /**
   * Shared with provider id.
   * @format uuid
   */
  sharedToProviderId?: string | null;
}

export interface APITruckLocationRequestModel {
  truckNumber?: string | null;
  driverFirstName?: string | null;
  driverLastName?: string | null;
  timeOfArrival?: APITimeOfArrivalModel;
  /** @format int32 */
  orderId?: number | null;
  /** @format uuid */
  truckId?: string;
  /** @format uuid */
  driverId?: string | null;
  /** @format int32 */
  ticketId?: number | null;
  plantId?: string | null;
  plantName?: string | null;
  projectName?: string | null;
  currentLocation?: APILocation;
  plantLocation?: APILocation;
  deliveryLocation?: APILocation;
  gpsTrackDeviceId?: string | null;
  /** @format uuid */
  sharedToProviderId?: string | null;
  truckStatus?: APITruckStatus;
}

export interface APITruckModel {
  /**
   * The unique identifier for the truck
   * @format uuid
   * @example "628ffc50-56c7-4b30-a752-9d9438cbc51a"
   */
  id?: string;
  /**
   * The truck number of the truck
   * @example "308"
   */
  truckNumber?: string | null;
  /**
   * Date and time of last truck update
   * @format date-time
   */
  lastUpdate?: string;
  /** Status of truck */
  isOnline?: boolean;
  driver?: APIUserModel;
  /** License Plate */
  licensePlate?: string | null;
  /** The third party id of the truck */
  thirdPartyId?: string | null;
  /** Indicates whether truck is deleted */
  isDeleted?: boolean;
  /**
   * The provider id
   * @format uuid
   */
  providerId?: string;
}

export interface APITruckModelSearchResult {
  /**
   * The search page number, will be used for offset
   * @format int32
   * @example 0
   */
  pageNumber?: number;
  /**
   * The count of entities on the page
   * @format int32
   * @example 20
   */
  pageSize?: number;
  /**
   * The count of the searched entities in the DB
   * @format int32
   * @example 1
   */
  count?: number;
  /** The list of the searched entities */
  result?: APITruckModel[] | null;
}

export interface APITruckRequestModel {
  /**
   * The truck number of the truck
   * @example "308"
   */
  truckNumber?: string | null;
  /**
   * The truck third party id
   * @example "308"
   */
  thirdPartyId?: string | null;
  /**
   * The driver id associated with the truck
   * @format uuid
   */
  driverId?: string | null;
}

export interface APITruckSearchFilter {
  /**
   * The search page number, will be used for offset
   * @format int32
   * @example 0
   */
  pageNumber?: number;
  /**
   * The count of entities on the page
   * @format int32
   * @example 20
   */
  pageSize?: number;
  /** a collection of sort fields */
  searchSortOrders?: APISearchSortOrder[] | null;
  /**
   * The search parameter for truck number (search by start with condition)
   * @example "308"
   */
  truckNumber?: string | null;
  /** The search parameters for truck ids */
  truckIds?: string[] | null;
  /** Indicates if truck is free for driver assign */
  isFreeForDriverAssign?: boolean;
  /** Indicates if truck has assigned tickets */
  hasNoTickets?: boolean;
}

export enum APITruckStatus {
  Unavailable = 'Unavailable',
  Ready = 'Ready',
  Loading = 'Loading',
  Delivering = 'Delivering',
  OnSite = 'OnSite',
  Returning = 'Returning',
}

export interface APITruckTrackingFilter {
  truckStatuses?: APITruckStatus[] | null;
  /** @format int32 */
  orderId?: number | null;
  truckId?: string[] | null;
}

export interface APIUsageModel {
  /**
   * The unique identifier of the mix type
   * @format int32
   */
  id?: number;
  /** The name of the mix type */
  name?: string | null;
  /**
   * The number of orders using the mix type
   * @format int32
   */
  usage?: number;
}

export interface APIUsageModelSearchResult {
  /**
   * The search page number, will be used for offset
   * @format int32
   * @example 0
   */
  pageNumber?: number;
  /**
   * The count of entities on the page
   * @format int32
   * @example 20
   */
  pageSize?: number;
  /**
   * The count of the searched entities in the DB
   * @format int32
   * @example 1
   */
  count?: number;
  /** The list of the searched entities */
  result?: APIUsageModel[] | null;
}

export interface APIUserAuthModel {
  email?: string | null;
  password?: string | null;
}

export interface APIUserModel {
  /**
   * The unique identifier for the user
   * @format uuid
   * @example "628ffc50-56c7-4b30-a752-9d9438cbc51a"
   */
  id?: string;
  /**
   * The first name of the user
   * @minLength 1
   * @example "Alex"
   */
  firstName: string;
  /**
   * The last name of the user
   * @minLength 1
   * @example "Stock"
   */
  lastName: string;
  /**
   * The email address of the user which will be used for login, should be unique
   * @minLength 1
   * @example "alex.stock@cd3p.app"
   */
  email: string;
  /**
   * The phone number of the user which will be for notifications
   * @minLength 1
   * @example "+18571234675"
   */
  phone: string;
  userType: APIUserType;
  userStatus?: APIUserStatus;
  accessLevel: APIAccessLevel;
  /** The list of providers for which user has access */
  providers?: APIProviderModel[] | null;
  /** The list of companies where the user belongs */
  companies?: APICompanyModel[] | null;
  /** The third party id of the user */
  thirdPartyId?: string | null;
  /** The gps tracking system id of the user */
  gpsTrackingId?: string | null;
  /** The token for authorization */
  token?: string | null;
  /** The temporary token for authorization */
  temporaryToken?: string | null;
  /** Version of cache stored on the user device */
  globalCacheVersion?: string | null;
  /** The list of user options */
  userOptions?: Record<string, string | null>;
  newApplicationVersions?: APIApplicationVersionModel[] | null;
}

export interface APIUserModelSearchResult {
  /**
   * The search page number, will be used for offset
   * @format int32
   * @example 0
   */
  pageNumber?: number;
  /**
   * The count of entities on the page
   * @format int32
   * @example 20
   */
  pageSize?: number;
  /**
   * The count of the searched entities in the DB
   * @format int32
   * @example 1
   */
  count?: number;
  /** The list of the searched entities */
  result?: APIUserModel[] | null;
}

export interface APIUserRequestModel {
  /**
   * The first name of the user
   * @example "Alex"
   */
  firstName?: string | null;
  /**
   * The last name of the user
   * @example "Stock"
   */
  lastName?: string | null;
  /**
   * The email address of the user which will be used for login, should be unique
   * @example "alex.stock@cd3p.app"
   */
  email?: string | null;
  /**
   * The phone number of the user which will be used for notifications
   * @example "+18571234675"
   */
  phone?: string | null;
  /**
   * The user's password in the system
   * @example "Qwerty123"
   */
  password?: string | null;
  accessLevel?: APIAccessLevel;
  userType?: APIUserType;
  userStatus?: APIUserStatus;
  /** The list of providers for which user has access */
  providerIds?: string[] | null;
  /** The ids of existing companies to add user to during registration */
  companyIds?: string[] | null;
  /** The user id in the third party system */
  thirdPartyId?: string | null;
  /** Indicates whether the verification of company information is required during the registration process. */
  shouldVerifyCompanyInfo?: boolean;
  /** Indicates whether the user has access to the web application */
  hasAccessToMobileApp?: boolean;
  /** Pass true if u want to ignore user exist exeption, false by default */
  shouldIgnoreUserExistException?: boolean;
}

export interface APIUserSearchFilter {
  /**
   * The search page number, will be used for offset
   * @format int32
   * @example 0
   */
  pageNumber?: number;
  /**
   * The count of entities on the page
   * @format int32
   * @example 20
   */
  pageSize?: number;
  /** a collection of sort fields */
  searchSortOrders?: APISearchSortOrder[] | null;
  /** Gets or sets a value indicating whether the search should include results from multiple providers. */
  crossProviderSearch?: boolean;
  /** The search parameter for query string, searches by first, last and email */
  query?: string | null;
  /** Ids to exclude from search result */
  excludedIds?: string[] | null;
  /**
   * The search parameter for user first name (search by start with condition)
   * @example "alex"
   */
  firstName?: string | null;
  /**
   * The search parameter for user last name (search by start with condition)
   * @example "stock"
   */
  lastName?: string | null;
  /**
   * The search parameter for user email (search by start with condition)
   * @example "alex@st"
   */
  email?: string | null;
  /**
   * The search parameter for user phone (search by start with condition)
   * @example "+123"
   */
  phone?: string | null;
  /** The search parameter for the user status */
  userStatuses?: APIUserStatus[] | null;
  /** The list of provider where searched user is located */
  providerIds?: string[] | null;
  /** The search parameter for the ids of the company for which user was created */
  companyIds?: string[] | null;
  userType?: APIUserType;
  /** Show deleted users in user search */
  includeDeleted?: boolean | null;
}

export enum APIUserStatus {
  Deactivated = 'Deactivated',
  NotActive = 'NotActive',
  Incomplete = 'Incomplete',
  Active = 'Active',
}

export enum APIUserType {
  Dispatcher = 'Dispatcher',
  Driver = 'Driver',
  Contractor = 'Contractor',
  Support = 'Support',
}
