import React from 'react';

import styled from 'styled-components';

import { APIUser, APIUserStatus } from '@cd3p/core/types/api';
import {
  emailField,
  firstNameField,
  lastNameField,
  phoneField,
  textField,
} from '@cd3p/core/utils/fields';
import { FormField, LoadingButton, LocationField } from 'components';
import { useHandleApiResult } from 'hooks/useHandleApiResult';

import {
  FieldError,
  useForm,
  useNavigate,
  useSelector,
  useTranslation,
} from 'third-party';

import { allOrdersUrl } from 'constants/url';

import { useApp } from 'modules/app';
import { useCache } from 'modules/cache';
import { NotificationSeverity, useNotifications } from 'modules/notifications';

import { appSelectors } from 'selectors';

import {
  BorderLinearProgress,
  Description,
  Title,
} from 'features/OnBoardingView/OnBoardingView';

import { TypeaheadOption } from 'types/app';

const Wrapper = styled.div`
  flex-grow: 1;
  min-height: 0;
  display: flex;
  flex-flow: column;
  align-items: center;
  overflow-y: auto;
  padding-bottom: 12.8rem;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 41.8rem;
`;

const FieldsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 1rem;
  margin-bottom: 5.4rem;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 2.2rem;
  > * {
    flex: 1 0 0;
    padding: 0;
  }
`;

enum FormFields {
  FirstName = 'firstName',
  LastName = 'lastName',
  Email = 'email',
  CompanyName = 'providerName',
  CompanyAddress = 'officeAddress',
  CompanyPhone = 'phone',
}

type FormData = {
  [FormFields.FirstName]: string;
  [FormFields.LastName]: string;
  [FormFields.Email]: string;
  [FormFields.CompanyName]: string;
  [FormFields.CompanyAddress]: TypeaheadOption;
  [FormFields.CompanyPhone]: string;
};

export const OnBoardingStep2Verify = () => {
  const { t } = useTranslation();
  const { updateCurrentUser, updateProvider } = useApp();
  const navigate = useNavigate();
  const { loadAddress, loadAddressLocation } = useCache();

  const user = useSelector(appSelectors.user);
  const providerAddress = useSelector(appSelectors.providerAddress);
  const providerName = useSelector(appSelectors.providerName);
  const providerPhone = useSelector(appSelectors.providerPhone);
  const updateCurrentUserPending = useSelector(
    appSelectors.updateCurrentUserPending,
  );
  const updateProviderPending = useSelector(appSelectors.updateProviderPending);
  const { addNotification } = useNotifications();

  const {
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors, isSubmitted, isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      [FormFields.FirstName]: user.firstName!,
      [FormFields.LastName]: user.lastName!,
      [FormFields.Email]: user.email!,
      [FormFields.CompanyName]: providerName!,
      [FormFields.CompanyAddress]: {
        label: providerAddress!,
        value: providerAddress!,
      },
      [FormFields.CompanyPhone]: phoneField.formValue(providerPhone!),
    },
  });

  const watchedAddressField = watch(FormFields.CompanyAddress);

  const handleApiResult = useHandleApiResult();

  const onSubmit = async (formData: FormData) => {
    if (!isValid) {
      return;
    }
    handleApiResult(
      () =>
        updateProvider({
          [FormFields.CompanyName]: formData[FormFields.CompanyName].trim(),
          [FormFields.CompanyAddress]:
            formData[FormFields.CompanyAddress].value,
          [FormFields.CompanyPhone]: formData[FormFields.CompanyPhone]
            ? phoneField.apiValue(formData[FormFields.CompanyPhone])
            : null,
        }),
      () => {
        handleApiResult<APIUser>(
          () =>
            updateCurrentUser({
              ...user,
              [FormFields.FirstName]: formData[FormFields.FirstName].trim(),
              [FormFields.LastName]: formData[FormFields.LastName].trim(),
              [FormFields.Email]: formData[FormFields.Email].trim(),
              userStatus: APIUserStatus.Active,
            }),
          ({ result }) => {
            if (result.payload.userStatus === APIUserStatus.Active) {
              navigate(allOrdersUrl(), { replace: true });
            } else {
              addNotification({
                severity: NotificationSeverity.Error,
                message: t('common.generalError'),
              });
            }
          },
        );
      },
    );
  };

  const isFormPending = updateCurrentUserPending || updateProviderPending;

  return (
    <Wrapper>
      <Title variant="h2">{t('onboarding.title')}</Title>
      <Description>{t('onboarding.step2.description')}</Description>
      <InnerWrapper>
        <BorderLinearProgress variant="determinate" value={75} />
        <FieldsWrapper>
          <FormField
            fieldName={FormFields.FirstName}
            fieldError={errors[FormFields.FirstName] as FieldError}
            label={t('common.form.firstName.label')}
            placeholder={t('common.form.firstName.placeholder')}
            requiredErrorMessage={t('common.form.emptyFieldError')}
            control={control}
            maxLength={firstNameField.maxLength}
            isRequired
            isDisabled={isFormPending}
          />
          <FormField
            fieldName={FormFields.LastName}
            fieldError={errors[FormFields.LastName] as FieldError}
            label={t('common.form.lastName.label')}
            placeholder={t('common.form.lastName.placeholder')}
            requiredErrorMessage={t('common.form.emptyFieldError')}
            control={control}
            maxLength={lastNameField.maxLength}
            isRequired
            isDisabled={isFormPending}
          />
          <FormField
            fieldName={FormFields.Email}
            fieldError={errors[FormFields.Email] as FieldError}
            label={t('common.form.email.label')}
            placeholder={t('common.form.email.placeholder')}
            requiredErrorMessage={t('common.form.emptyFieldError')}
            control={control}
            maxLength={emailField.maxLength}
            isRequired
            isDisabled={isFormPending}
            rules={{
              validate: {
                incorrectEmail: (value: string) =>
                  emailField.validate(value) ||
                  t('common.form.email.error.invalid'),
              },
            }}
          />
          <FormField
            fieldName={FormFields.CompanyName}
            fieldError={errors[FormFields.CompanyName] as FieldError}
            label={t('onboarding.step2.form.companyName.label')}
            placeholder={t('onboarding.step2.form.companyName.placeholder')}
            requiredErrorMessage={t('common.form.emptyFieldError')}
            control={control}
            maxLength={textField.maxLength}
            isRequired
            isDisabled={isFormPending}
          />
          <LocationField
            isRequired
            fieldName={FormFields.CompanyAddress}
            errors={errors}
            control={control}
            isUpdating={isFormPending}
            showError={isSubmitted}
            isDisabled={isFormPending}
            value={watchedAddressField}
            setValue={setValue}
            label={t('onboarding.step2.form.companyAddress.label')}
            placeholder={t('onboarding.step2.form.companyAddress.placeholder')}
            loadAddress={loadAddress}
            loadAddressLocation={loadAddressLocation}
          />
          <FormField
            fieldName={FormFields.CompanyPhone}
            fieldError={errors[FormFields.CompanyPhone] as FieldError}
            label={t('onboarding.step2.form.companyPhone.label')}
            placeholder={t('onboarding.step2.form.companyPhone.placeholder')}
            requiredErrorMessage={t('common.form.emptyFieldError')}
            control={control}
            showError={isSubmitted}
            maxLength={textField.maxLength}
            isRequired={false}
            isDisabled={isFormPending}
            inputProps={{
              maxLength: phoneField.maxLength,
            }}
            mask={phoneField.mask}
            maskDefinitions={phoneField.maskDefinitions}
            rules={{
              validate: {
                incorrectPhone: (value: string) =>
                  !value ||
                  phoneField.validate(value) ||
                  t('common.form.phone.error.invalid'),
              },
            }}
          />
        </FieldsWrapper>
        <ButtonsWrapper>
          <LoadingButton
            type="submit"
            variant="contained"
            size="medium"
            color="primary"
            loading={isFormPending}
            disabled={isFormPending}
            onClick={handleSubmit(onSubmit)}
          >
            {t('onboarding.step2.completeSetup')}
          </LoadingButton>
        </ButtonsWrapper>
      </InnerWrapper>
    </Wrapper>
  );
};
