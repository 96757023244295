import React, { useCallback, useState } from 'react';

import { NotificationSeverity } from '@cd3p/core/modules/notifications';

import { useSelector, useTranslation } from 'third-party';

import { useAdditivesCategoriesList } from 'modules/concreteCategories/additivesCategoriesList';
import { GenericCategoryItem } from 'modules/concreteCategories/commonConcreteCategories';
import { useContractorAdditiveTypes } from 'modules/contractorAdditiveTypes';
import { useNotifications } from 'modules/notifications';

import {
  additivesCategoriesSelectors,
  contractorAdditiveTypesSelectors,
} from 'selectors';

import { AddConcreteCategoriesPopup } from 'features/Contractors/ConcreteCategoriesPopup';
import { CommonTab } from 'features/Contractors/Tabs/CommonTab';
import { useTrackDeleteCompanyAvailability } from 'features/Contractors/Tabs/tabHelpers';

type Props = {
  contractorId: string;
  isReadOnly?: boolean;
};

export const AdditiveTypesTab: React.FC<Props> = ({
  contractorId,
  isReadOnly,
}) => {
  const { t } = useTranslation();

  const {
    loadContractorAdditiveTypes,
    loadMoreContractorAdditiveTypes,
    deleteContractorAdditiveType,
    updateContractorAdditiveTypes,
    updateContractorAdditiveTypesSorting,
    resetContractorAdditiveTypes,
  } = useContractorAdditiveTypes();

  const contractorAdditivesItems = useSelector(
    contractorAdditiveTypesSelectors.contractorAdditiveTypesItems,
  );
  const contractorAdditivesCount = useSelector(
    contractorAdditiveTypesSelectors.contractorAdditiveTypesCount,
  );
  const contractorAdditivesLoaded = useSelector(
    contractorAdditiveTypesSelectors.contractorAdditiveTypesLoaded,
  );
  const loadContractorAdditivesPending = useSelector(
    contractorAdditiveTypesSelectors.loadContractorAdditiveTypesPending,
  );
  const loadMoreContractorAdditivesPending = useSelector(
    contractorAdditiveTypesSelectors.loadMoreContractorAdditiveTypesPending,
  );
  const sorting = useSelector(
    contractorAdditiveTypesSelectors.contractorAdditiveTypesSorting,
  );

  useTrackDeleteCompanyAvailability(
    contractorId,
    contractorAdditivesLoaded ? contractorAdditivesItems.length : undefined,
  );

  return (
    <CommonTab
      items={contractorAdditivesItems}
      itemsCount={contractorAdditivesCount}
      itemsLoaded={contractorAdditivesLoaded}
      loadItemsPending={loadContractorAdditivesPending}
      loadMoreItemsPending={loadMoreContractorAdditivesPending}
      sorting={sorting}
      contractorId={contractorId}
      loadItems={loadContractorAdditiveTypes}
      loadMoreItems={loadMoreContractorAdditiveTypes}
      deleteItem={deleteContractorAdditiveType}
      updateItems={updateContractorAdditiveTypes}
      updateItemsSorting={updateContractorAdditiveTypesSorting}
      resetItems={resetContractorAdditiveTypes}
      successDeleteMessage={t(
        'customers.additiveTypes.notification.successDelete',
      )}
      itemNameColumnLabel={t('customers.additiveTypes.table.nameColumnLabel')}
      itemCategoriesColumnLabel={t(
        'customers.additiveTypes.table.categoryColumnLabel',
      )}
      deleteConfirmationTitle={t('common.areYouSure')}
      deleteConfirmationText={t(
        'customers.additiveTypes.table.deleteConfirmationText',
      )}
      tableHeader={t('customers.additiveTypes.header')}
      addButtonLabel={t('customers.additiveTypes.addButtonText')}
      loadMoreButtonLabel={t(
        'customers.additiveTypes.table.loadMoreButtonText',
      )}
      emptyStateText={t('customers.additiveTypes.table.emptyState')}
      AssignConcreteCategoriesPopup={AssignAdditivesCategoriesPopup}
      isReadOnly={isReadOnly}
    />
  );
};

type AssignAdditivesCategoriesPopupProps = {
  companyId: string;
  isOpen: boolean;
  onClose: () => void;
  onAdd?: () => void;
};
const AssignAdditivesCategoriesPopup = ({
  companyId,
  isOpen,
  onClose,
  onAdd,
}: AssignAdditivesCategoriesPopupProps) => {
  const { t } = useTranslation();
  const {
    loadAdditivesCategories,
    loadMoreAdditivesCategories,
    updateAdditivesCategories,
    updateAdditivesCategoriesListFilters,
    resetAdditivesCategories,
  } = useAdditivesCategoriesList();
  const additivesListItems = useSelector(
    additivesCategoriesSelectors.additivesCategoriesListNormalized,
  );
  const canLoadMoreAdditives = useSelector(
    additivesCategoriesSelectors.canLoadMoreAdditives,
  );
  const additivesPending = useSelector(
    additivesCategoriesSelectors.additivesCategoriesPending,
  );
  const additivesLoaded = useSelector(
    additivesCategoriesSelectors.additivesCategoriesLoaded,
  );
  const { addNotification } = useNotifications();

  const [isSaving, setIsSavingSelected] = useState(false);

  const addSelectedItems = useCallback(
    async (selectedItems: GenericCategoryItem[]) => {
      setIsSavingSelected(true);
      await updateAdditivesCategories(companyId, {
        ids: Array.from(new Set(selectedItems.map(item => item.id))),
      })
        .then(() => {
          addNotification({
            message: t('additiveTypes.add.successNotification'),
          });
          onAdd?.();
          resetAdditivesCategories();
        })
        .catch(() => {
          addNotification({
            severity: NotificationSeverity.Error,
            message: t('common.generalError'),
          });
        });
      onClose();
      setIsSavingSelected(false);
    },
    [
      onAdd,
      companyId,
      resetAdditivesCategories,
      updateAdditivesCategories,
      onClose,
      t,
      addNotification,
    ],
  );

  const loadItems = useCallback(() => {
    loadAdditivesCategories(companyId);
  }, [companyId, loadAdditivesCategories]);

  const loadMoreItems = useCallback(() => {
    loadMoreAdditivesCategories(companyId);
  }, [companyId, loadMoreAdditivesCategories]);

  const searchItems = useCallback(
    (value: string) => {
      updateAdditivesCategoriesListFilters({ name: value });
      loadAdditivesCategories(companyId, { name: value });
    },
    [companyId, loadAdditivesCategories, updateAdditivesCategoriesListFilters],
  );

  return (
    <>
      {isOpen && (
        <AddConcreteCategoriesPopup
          onClose={() => {
            onClose();
            resetAdditivesCategories();
          }}
          addSelectedItems={addSelectedItems}
          loadItems={loadItems}
          loadMoreItems={loadMoreItems}
          searchItems={searchItems}
          items={additivesListItems}
          itemsLoaded={additivesLoaded}
          canLoadMoreItems={canLoadMoreAdditives}
          itemsPending={additivesPending}
          isSaving={isSaving}
          addButtonLabel={t('additiveTypes.add.addButtonLabel')}
          headerText={t('additiveTypes.add.title')}
          subheaderText={t('additiveTypes.add.subtext')}
          selectedListHeaderText={t('additiveTypes.add.selectedListHeader')}
          availableListHeaderText={t('additiveTypes.add.availableListHeader')}
          availableListEmptyText={t('additiveTypes.add.emptyAvailableListText')}
          availableListEmptySearchText={t('additiveTypes.add.emptySearchText')}
          selectedListEmptyText={t('additiveTypes.add.emptySelectedListText')}
        />
      )}
    </>
  );
};
