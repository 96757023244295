// this helper was derived from https://github.com/google-map-react/google-map-react/blob/master/src/lib/index.js
// to be able to get `zoom` and `center` from `fitBounds` function which is needed but
// not produced by the library https://github.com/JustFly1984/react-google-maps-api
import { LocationCoordinates } from 'types/app';

const GOOGLE_TILE_SIZE = 256;

const log2 = Math.log2 ? Math.log2 : (x: number) => Math.log(x) / Math.LN2;

function latLng2World({ lat, lng }: LocationCoordinates) {
  const sin = Math.sin((lat * Math.PI) / 180);
  const x = lng / 360 + 0.5;
  let y = 0.5 - (0.25 * Math.log((1 + sin) / (1 - sin))) / Math.PI;

  y =
    y < 0 // eslint-disable-line
      ? 0
      : y > 1
      ? 1
      : y;
  return { x, y };
}

function world2LatLng({ x, y }: { x: number; y: number }) {
  const n = Math.PI - 2 * Math.PI * y;

  return {
    lat: (180 / Math.PI) * Math.atan(0.5 * (Math.exp(n) - Math.exp(-n))),
    lng: x * 360 - 180,
  };
}

function fitNwSe(
  nw: LocationCoordinates,
  se: LocationCoordinates,
  width: number,
  height: number,
) {
  const EPS = 0.000000001;
  const nwWorld = latLng2World(nw);
  const seWorld = latLng2World(se);
  const dx =
    nwWorld.x < seWorld.x ? seWorld.x - nwWorld.x : 1 - nwWorld.x + seWorld.x;
  const dy = seWorld.y - nwWorld.y;

  if (dx <= 0 && dy <= 0) {
    return null;
  }

  const zoomX = log2(width / GOOGLE_TILE_SIZE / Math.abs(dx));
  const zoomY = log2(height / GOOGLE_TILE_SIZE / Math.abs(dy));
  const zoom = Math.floor(EPS + Math.min(zoomX, zoomY));

  const middle = {
    x:
      nwWorld.x < seWorld.x // eslint-disable-line
        ? 0.5 * (nwWorld.x + seWorld.x)
        : nwWorld.x + seWorld.x - 1 > 0
        ? 0.5 * (nwWorld.x + seWorld.x - 1)
        : 0.5 * (1 + nwWorld.x + seWorld.x),
    y: 0.5 * (nwWorld.y + seWorld.y),
  };

  const scale = Math.pow(2, zoom);
  const halfW = width / scale / GOOGLE_TILE_SIZE / 2;
  const halfH = height / scale / GOOGLE_TILE_SIZE / 2;

  const newNW = world2LatLng({
    x: middle.x - halfW,
    y: middle.y - halfH,
  });

  const newSE = world2LatLng({
    x: middle.x + halfW,
    y: middle.y + halfH,
  });

  return {
    center: world2LatLng(middle),
    zoom,
    newBounds: {
      nw: newNW,
      se: newSE,
    },
  };
}

export function convertNeSwToNwSe({
  ne,
  sw,
}: {
  ne: LocationCoordinates;
  sw: LocationCoordinates;
}) {
  return {
    nw: {
      lat: ne.lat,
      lng: sw.lng,
    },
    se: {
      lat: sw.lat,
      lng: ne.lng,
    },
  };
}

export function convertNwSeToNeSw({
  nw,
  se,
}: {
  nw: LocationCoordinates;
  se: LocationCoordinates;
}) {
  return {
    ne: {
      lat: nw.lat,
      lng: se.lng,
    },
    sw: {
      lat: se.lat,
      lng: nw.lng,
    },
  };
}

export function fitBounds(
  {
    nw,
    se,
    ne,
    sw,
  }: {
    nw: LocationCoordinates;
    se: LocationCoordinates;
    ne: LocationCoordinates;
    sw: LocationCoordinates;
  },
  { width, height }: { width: number; height: number },
) {
  let fittedData;

  if (nw && se) {
    fittedData = fitNwSe(nw, se, width, height);
  } else {
    const calculatedNwSe = convertNeSwToNwSe({ ne, sw });
    fittedData = fitNwSe(calculatedNwSe.nw, calculatedNwSe.se, width, height);
  }

  return {
    ...fittedData!,
    newBounds: {
      ...fittedData!.newBounds,
      ...convertNwSeToNeSw(fittedData!.newBounds),
    },
  };
}

export const toRadians = (degrees: number) => degrees * (Math.PI / 180);
export const toDegrees = (radians: number) => radians * (180 / Math.PI);

export const calculateCentroid = (coordinates: LocationCoordinates[]) => {
  if (coordinates.length === 0) {
    return { lat: 0, lng: 0 };
  }

  let x = 0,
    y = 0,
    z = 0;

  for (const coordinate of coordinates) {
    const latitude = toRadians(coordinate.lat);
    const longitude = toRadians(coordinate.lng);

    x += Math.cos(latitude) * Math.cos(longitude);
    y += Math.cos(latitude) * Math.sin(longitude);
    z += Math.sin(latitude);
  }

  const total = coordinates.length;

  x /= total;
  y /= total;
  z /= total;

  const lng = toDegrees(Math.atan2(y, x));
  const hyp = Math.sqrt(x * x + y * y);
  const lat = toDegrees(Math.atan2(z, hyp));

  return { lat, lng };
};
