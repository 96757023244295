import { State } from 'modules';

import { createSelector } from 'third-party';

export const assignedDrivers = (state: State) => ({
  assignedList: state.assignedDriversList.assigned.filter(
    item => !item.notifiedDate,
  ),
  assignedAndNotifiedList: state.assignedDriversList.assigned.filter(
    item => item.notifiedDate,
  ),
});

export const areAllDriversNotified = createSelector(
  [assignedDrivers],
  assignedDrivers =>
    !assignedDrivers.assignedList.length &&
    assignedDrivers.assignedAndNotifiedList.length > 0,
);

export const hasAssignedDrivers = createSelector(
  [assignedDrivers],
  assignedDrivers => assignedDrivers.assignedList.length > 0,
);
export const availableToAssignDrivers = (state: State) =>
  state.assignedDriversList.available;
export const assignedDriversListPending = (state: State) =>
  state.assignedDriversList.loadAssignedDriversPending;
export const notifyAssignedDriversPending = (state: State) =>
  state.assignedDriversList.notifyAssignedDriversPending;
export const assignedDriversListLoaded = (state: State) =>
  state.assignedDriversList.assignedLoaded;
