import React, { CSSProperties } from 'react';

import styled from 'styled-components';

import { PopoverProps } from '@mui/material/Popover/Popover';
import {
  Button,
  LoadingButton,
  Popover,
  PopoverCloseButton,
  Stack,
  Typography,
} from 'components';

import { useTranslation } from 'third-party';

const Header = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  padding: 2.2rem 2.3rem 0;
  z-index: 1;
`;

const Title = styled(Typography)`
  line-height: 100%;
  color: ${props => props.theme.custom.palette.primary900};
  margin-bottom: 1.5rem;
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1.6rem;
  margin-bottom: 2.3rem;
`;

const Content = styled(Stack)<{ width?: string }>`
  flex-grow: 1;
  overflow: scroll;
  padding: 2.2rem 2.3rem 2.7rem;
  box-sizing: border-box;
  width: ${props => props.width || '45rem'};
`;

type Props = PopoverProps & {
  open: boolean;
  children: React.ReactElement | React.ReactElement[];
  header?: string;
  isPending?: boolean;
  isDisabled?: boolean;
  closeEnabled?: boolean;
  headerEnabled?: boolean;
  isConfirmDisabled?: boolean;
  cancelLabel?: string;
  confirmLabel?: string;
  blurBackgroundSize?: number;
  onConfirm?: () => void;
  onClose: () => void;
  width?: string;
  contentWrapperStyle?: CSSProperties;
};

export const Modal: React.FC<Props> = ({
  children,
  header,
  width,
  isPending = false,
  isDisabled = false,
  closeEnabled = true,
  headerEnabled = true,
  isConfirmDisabled = false,
  cancelLabel,
  confirmLabel,
  blurBackgroundSize = 0,
  onConfirm,
  onClose,
  open,
  contentWrapperStyle,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <Popover
      anchorEl={document.body}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      open={open}
      style={
        blurBackgroundSize
          ? { backdropFilter: `blur(${blurBackgroundSize}px)` }
          : {}
      }
      PaperProps={{ sx: { overflow: 'visible' } }}
      {...props}
    >
      {closeEnabled && <PopoverCloseButton onClose={onClose} />}
      {headerEnabled && (
        <Header>
          {header && <Title variant="h3">{header}</Title>}
          <ActionButtons>
            <Button variant="outlined" color="secondary" onClick={onClose}>
              {cancelLabel || t('common.cancel')}
            </Button>
            {confirmLabel && (
              <LoadingButton
                loading={isPending}
                variant="contained"
                color="primary"
                disabled={isDisabled || isConfirmDisabled}
                onClick={onConfirm}
              >
                {confirmLabel}
              </LoadingButton>
            )}
          </ActionButtons>
        </Header>
      )}
      <Content style={contentWrapperStyle} width={width}>
        {children}
      </Content>
    </Popover>
  );
};
