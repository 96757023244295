import React from 'react';

import styled from 'styled-components';

import { Grid, LoadingButton, Typography } from 'components';

import { useSelector, useTheme, useTranslation } from 'third-party';

import { orderSelectors } from 'selectors';

import { useCloseOrder } from 'utils/order';

const CloseButton = styled(LoadingButton)`
  min-width: 20rem;
  margin-top: 2rem;
`;

type Props = {
  orderId: number;
  orderName?: string;
};

export const CloseOrder: React.FC<Props> = ({ orderId, orderName }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const closeOrder = useCloseOrder();

  const changeOrderStatusPending = useSelector(
    orderSelectors.changeOrderStatusPending,
  );

  const onClose = () => {
    if (orderId) {
      closeOrder(orderId);
    }
  };

  return (
    <Grid item>
      <Grid item>
        <Typography variant="h5">
          {t('order.details.closeOrder.header')}
        </Typography>
        <Typography
          color={theme.custom.palette.gray}
          variant="body1"
          marginTop="0.8rem"
        >
          {t('order.details.closeOrder.text', { orderName })}
        </Typography>

        <CloseButton
          color="primary"
          variant="contained"
          size="large"
          onClick={onClose}
          disabled={changeOrderStatusPending}
          loading={changeOrderStatusPending}
        >
          {t('order.details.closeOrder.confirmButton')}
        </CloseButton>
      </Grid>
    </Grid>
  );
};
