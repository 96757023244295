import { useCallback } from 'react';

import { generateCoordinatesLink } from '@cd3p/core/utils/order';

import { copy, useTranslation } from 'third-party';

import { useNotifications } from 'modules/notifications';

export const useCopyCoordinates = () => {
  const { t } = useTranslation();
  const { addNotification } = useNotifications();

  return useCallback(
    (long: number, lat: number) => {
      copy(generateCoordinatesLink(long, lat));
      addNotification({
        id: 'copyPinLink',
        message: t('copyPinLink.successMessage'),
      });
    },
    [addNotification, t],
  );
};
