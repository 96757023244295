import React from 'react';

import { Message } from '@chatscope/chat-ui-kit-react';
import { ChatMessage } from 'selectors/chat';

import { dayjs } from 'third-party';

import { MessageInnerContent } from 'features/Chat/MessageBubbleStyles';

type Props = {
  as?: any;
  message: ChatMessage;
};

export const SystemMessageBubble: React.FC<Props> = ({ message }) => {
  return (
    <Message
      model={{
        direction: 'outgoing',
        position: 'single',
      }}
      className="system-user-message-wrapper"
    >
      <Message.CustomContent className="system-user-message">
        <MessageInnerContent>
          <div style={{ textAlign: 'center' }}>
            {message.message}
            <br /> {dayjs(message.createdOn).format('h:mma')}
          </div>
        </MessageInnerContent>
      </Message.CustomContent>
    </Message>
  );
};
