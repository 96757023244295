import React, { useCallback, useEffect, useMemo, useState } from 'react';

import styled, { useTheme } from 'styled-components';

import CreateIcon from '@mui/icons-material/Create';
import WifiOffIcon from '@mui/icons-material/WifiOff';

import { TRUCKS_UPDATE_OFFLINE_STATUS_INTERVAL } from '@cd3p/core/constants/common';
import { ON_TICKET_UPDATE_EVENT } from '@cd3p/core/constants/hubs';
import {
  APIOrder,
  APIOrderStatus,
  APIPlant,
  APITicket,
  APITicketStatuses,
  APITrackingItem,
  APITruck,
  APIUser,
  APIUserType,
} from '@cd3p/core/types/api';
import {
  formatDisplayedDate,
  getServerErrorTitle,
} from '@cd3p/core/utils/common';
import { HIDDEN_CY_PROGRESS_TICKET_STATUSES } from '@cd3p/core/utils/order';
import { parseTicketStatusHistory } from '@cd3p/core/utils/order';
import { SelectChangeEvent } from '@mui/material';
import {
  Button,
  CircularProgress,
  ConfirmationDialog,
  DispatcherCanAccess,
  Divider,
  FormField,
  FormNumberField,
  LoadingButton,
  MenuItem,
  PopoverCloseButton,
  ReadOnlyField,
  Select,
  TicketETA,
  Tooltip,
  Trans,
  TruckNumberTag,
  Typography,
} from 'components';
import { useRedirectOn404 } from 'hooks/useHandleApiResult';
import { useMenuOptions } from 'hooks/useMenuOptions';

import {
  _,
  dayjs,
  queryString,
  useForm,
  useLocation,
  useSelector,
  useTranslation,
} from 'third-party';

import { FIELD_MAX_LENGTH, TOOLTIP_APPEAR_DELAY } from 'constants/common';

import { useCache } from 'modules/cache';
import { NotificationSeverity, useNotifications } from 'modules/notifications';
import { useOrder } from 'modules/order';

import {
  appSelectors,
  cacheSelectors,
  orderSelectors,
  trackingSelectors,
} from 'selectors';

import { TimeStamps } from 'components/TimeStamps/TimeStamps';

import { CancelTicketConfirmation } from 'features/OrderView/CancelTicketConfirmation';
import { getTicketLabelElement } from 'features/OrderView/orderViewHelpers';

import { getTruckETA, shouldShowTicketETA, ticketStatusMap } from 'utils/order';
import { useOrdersSockets } from 'utils/sockets';
import { getUserFullName } from 'utils/user';

const FormWrapper = styled.form`
  position: relative;
  width: 42.1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-height: 0;
  flex-grow: 1;
`;

const Loader = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  padding: 2.2rem 2.3rem 0;
  z-index: 1;
`;

const Title = styled(Typography)`
  line-height: 100%;
  color: ${props => props.theme.custom.palette.primary900};
  margin-bottom: 1.5rem;
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2.3rem;
`;

const DateText = styled(Typography)`
  color: ${props => props.theme.custom.palette.gray};
  align-self: flex-end;
`;

const StyledDivider = styled(Divider)`
  border-color: ${props => props.theme.custom.palette.muted100};
  margin-top: 2rem;
  margin-bottom: 0.4rem;
`;

const NarrowDivider = styled(StyledDivider)`
  margin-top: 1rem;
`;

const StyledTicketETA = styled(TicketETA)`
  margin-left: 1rem;
`;

const StyledReadOnlyField = styled(ReadOnlyField)`
  display: flex;
  margin-top: 1.6rem;
  margin-bottom: 0;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 2.2rem 2.3rem 2.7rem;
  overflow-y: auto;
`;

const ContentHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const TicketStatus = styled.span`
  font-weight: 700;
  font-size: 1.6rem;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
`;

const StatusSelect = styled(Select)`
  &:before,
  &:after {
    display: none;
  }
  .MuiSelect-select {
    padding: 0;
    &:focus {
      background-color: transparent;
    }
    &.Mui-disabled {
      -webkit-text-fill-color: unset;
    }
  }
  .MuiSelect-iconStandard {
    transform: none;
  }
`;

const StyledDropdownIcon = styled(CreateIcon)`
  font-size: 2rem;
`;

type Props = {
  order: APIOrder;
  ticketId?: string | number;
  onUpdateContent?: () => void;
  onCancelTicket?: () => void;
  onSaveDraftSuccess?: (order: APIOrder) => void;
  onSubmitSuccess?: (order: APIOrder) => void;
  onClosePopup?: (isDirty: boolean) => void;
  showCloseButton?: boolean;
};

enum SaveMode {
  SubmitTicket,
  SaveDraft,
}

const TICKET_STATUSES_ORDERED = [
  APITicketStatuses.Pending,
  APITicketStatuses.Loading,
  APITicketStatuses.Delivering,
  APITicketStatuses.OnSite,
  APITicketStatuses.Completed,
  APITicketStatuses.Cancelled,
];

const canSwitchBetweenStatuses = (
  currentStatus: APITicketStatuses,
  nextStatus: APITicketStatuses,
) => {
  switch (currentStatus) {
    case APITicketStatuses.Draft:
      return [
        APITicketStatuses.Pending,
        APITicketStatuses.Loading,
        APITicketStatuses.Delivering,
        APITicketStatuses.OnSite,
        APITicketStatuses.Completed,
      ].includes(nextStatus);
    case APITicketStatuses.Pending:
      return [
        APITicketStatuses.Loading,
        APITicketStatuses.Delivering,
        APITicketStatuses.OnSite,
        APITicketStatuses.Completed,
        APITicketStatuses.Cancelled,
      ].includes(nextStatus);
    case APITicketStatuses.Loading:
      return [
        APITicketStatuses.Delivering,
        APITicketStatuses.OnSite,
        APITicketStatuses.Completed,
        APITicketStatuses.Cancelled,
      ].includes(nextStatus);
    case APITicketStatuses.Delivering:
      return [
        APITicketStatuses.OnSite,
        APITicketStatuses.Completed,
        APITicketStatuses.Cancelled,
      ].includes(nextStatus);
    case APITicketStatuses.OnSite:
      return [
        APITicketStatuses.Completed,
        APITicketStatuses.Cancelled,
      ].includes(nextStatus);
    case APITicketStatuses.Completed:
    case APITicketStatuses.Cancelled:
      return false;
    default:
      return true;
  }
};

const canEditStatus = (status: APITicketStatuses | undefined) => {
  if (!status) return false;
  return ![APITicketStatuses.Completed, APITicketStatuses.Cancelled].includes(
    status,
  );
};

const TICKET_NUMBER_FIELD = 'ticketNumber';
const TRUCK_FIELD = 'truck';
const DRIVER_FIELD = 'driver';
const PLANT_FIELD = 'plant';
const BATCH_SIZE_FIELD = 'batchSize';

const formDefaultValues = {
  [TICKET_NUMBER_FIELD]: '',
  [TRUCK_FIELD]: '',
  [DRIVER_FIELD]: '',
  [PLANT_FIELD]: '',
  [BATCH_SIZE_FIELD]: '',
};

type FormData = {
  ticketNumber: string;
  truck: string;
  driver: string;
  plant: string;
  batchSize: string;
};

export const AddTicketView: React.FC<Props> = ({
  order,
  ticketId,
  onUpdateContent,
  onSaveDraftSuccess,
  onSubmitSuccess,
  onClosePopup,
  showCloseButton,
  onCancelTicket,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { search } = useLocation();
  const { truckId } = queryString.parse(search);
  const { driverId } = queryString.parse(search);

  const {
    control,
    handleSubmit,
    setError,
    watch,
    getValues: getFormValues,
    setValue: setFormValue,
    formState: { errors, isValid, isSubmitSuccessful, isDirty },
  } = useForm<FormData>({
    defaultValues: formDefaultValues,
  });

  const { addNotification } = useNotifications();
  const { addTicket, updateTicket, loadTicket } = useOrder();
  const { loadPlants, loadDrivers, loadTrucks } = useCache();

  const redirectOn404 = useRedirectOn404();

  const trackingItemsValues = useSelector(
    trackingSelectors.trackingItemsValues,
  );
  const plants = useSelector(cacheSelectors.plants);
  const loadPlantsPending = useSelector(cacheSelectors.loadPlantsPending);

  const isTimeStampEnabled = useSelector(appSelectors.isTimeStampEnabled);
  const drivers = useSelector(cacheSelectors.drivers);
  const loadDriversPending = useSelector(cacheSelectors.loadDriversPending);
  const trucks = useSelector(cacheSelectors.trucks);
  const loadTrucksPending = useSelector(cacheSelectors.loadTrucksPending);

  const loadTicketPending = useSelector(orderSelectors.loadTicketPending);

  const addTicketPending = useSelector(orderSelectors.addTicketPending);
  const updateTicketPending = useSelector(orderSelectors.updateTicketPending);
  const userType = useSelector(appSelectors.userType);

  const [ticketData, setTicketData] = useState<null | APITicket>(null);
  const [saveMode, setSaveMode] = useState<SaveMode | null>(null);

  const availableTrucks = useMemo(() => {
    if (ticketData?.truck) {
      return [...trucks, ticketData.truck];
    }

    return trucks;
  }, [trucks, ticketData?.truck]);

  const availableDrivers = useMemo(() => {
    if (ticketData?.driver) {
      return [...drivers, ticketData.driver];
    }

    return drivers;
  }, [drivers, ticketData?.driver]);

  useEffect(() => {
    if (!ticketId || ticketData?.ticketStatus === APITicketStatuses.Draft) {
      loadPlants();
      loadDrivers();
      // loads only trucks without tickets
      loadTrucks({ hasNoTickets: true });
    }
  }, [ticketId, ticketData, loadPlants, loadDrivers, loadTrucks]);

  const [timeOfArrivalDuration, setTimeOfArrivalDuration] = useState<
    number | null | undefined
  >(0);
  const [showCancelConfirmation, setShowCancelConfirmation] = useState<
    boolean | undefined
  >(false);
  const [newTicketStatus, setNewTicketStatus] =
    useState<APITicketStatuses | null>(null);
  const [showChangeTicketStatusDialog, setShowChangeTicketStatusDialog] =
    useState<boolean>(false);
  const [changeTicketStatusPending, setChangeTicketStatusPending] =
    useState(false);

  useEffect(() => {
    const truckNumber = ticketData?.truck?.truckNumber;
    if (truckNumber) {
      const currentTrackingItem: APITrackingItem | undefined =
        trackingItemsValues.find(item => item.truckNumber === truckNumber);
      if (currentTrackingItem) {
        setTimeOfArrivalDuration(currentTrackingItem.timeOfArrival?.duration);
      }
    }
  }, [trackingItemsValues, ticketData?.truck?.truckNumber]);

  useEffect(() => {
    const updateTicketData = async () => {
      const result = await loadTicket(order.id, ticketId as string);
      if (result.error && redirectOn404(result, onClosePopup)) {
        return;
      }
      const ticketData = result.payload as APITicket;
      setTicketData(ticketData);
      onUpdateContent?.();
      if (ticketData.ticketStatus === APITicketStatuses.Draft) {
        setFormValue(TICKET_NUMBER_FIELD, ticketData.ticketNumber || '');
        setFormValue(TRUCK_FIELD, ticketData?.truck?.id || '');
        setFormValue(DRIVER_FIELD, ticketData?.driver?.id);
        setFormValue(PLANT_FIELD, ticketData?.plant?.id || '');
        setFormValue(BATCH_SIZE_FIELD, ticketData.batchSize?.toString() || '');
      }
    };
    if (ticketId) {
      updateTicketData();
    }
  }, [
    onUpdateContent,
    loadTicket,
    order.id,
    setFormValue,
    ticketId,
    redirectOn404,
    onClosePopup,
  ]);

  useOrdersSockets(
    () => [
      {
        method: ON_TICKET_UPDATE_EVENT,
        action: (providerId, orderId, ticket: APITicket) => {
          if (ticketId && Number(ticketId) === ticket.id) {
            setTicketData(ticket);
          }
        },
      },
    ],
    [ticketId],
  );

  const watchedTruckValue = watch(TRUCK_FIELD);
  const watchedDriverValue = watch(DRIVER_FIELD);
  const watchedPlantValue = watch(PLANT_FIELD);

  const driversOptions = useMenuOptions<APIUser>({
    items: availableDrivers,
    selectedItemId: watchedDriverValue,
    emptyText: t('order.ticket.form.driverEmptyText'),
    getDisplayValue: useCallback((option: APIUser) => {
      return [option.firstName, option.lastName].join(' ');
    }, []),
  });
  const plantsOptions = useMenuOptions<APIPlant>({
    items: plants,
    selectedItemId: watchedPlantValue,
    displayKey: 'name',
    emptyText: t('order.ticket.form.plantEmptyText'),
  });
  const trucksOptions = useMenuOptions<APITruck>({
    items: availableTrucks,
    selectedItemId: watchedTruckValue,
    emptyText: t('order.ticket.form.truckEmptyText'),
    refreshInterval: TRUCKS_UPDATE_OFFLINE_STATUS_INTERVAL,
    getDisplayValue: useCallback((option: APITruck) => {
      return (
        <>
          {!option.isOnline && (
            <WifiOffIcon
              color="disabled"
              sx={{
                fontSize: '1.4rem',
                marginRight: '0.8rem',
              }}
            />
          )}
          {option.truckNumber}
        </>
      );
    }, []),
  });

  useEffect(() => {
    // autofill the first plant by default if there's
    // only one plant available
    if (plants.length === 1 && !watchedPlantValue) {
      setFormValue(PLANT_FIELD, plants[0].id);
    }
  }, [plants, setFormValue, watchedPlantValue]);

  useEffect(() => {
    if (watchedTruckValue) {
      const selectedTruck = trucks.find(
        truck => truck.id === watchedTruckValue,
      );
      if (selectedTruck && selectedTruck.driver && !ticketData?.driver) {
        const assignedDriver = drivers.find(
          driver => driver.id === selectedTruck.driver.id,
        );
        if (assignedDriver) {
          setFormValue(DRIVER_FIELD, assignedDriver.id);
        }
      }
    }
  }, [watchedTruckValue, trucks, setFormValue, drivers, ticketData?.driver]);

  useEffect(() => {
    // if there is a truckId in params select that truck in the trucks list
    if (truckId && order.id && trucks?.length > 0) {
      const selectedTruck = trucks.find(truck => truck.id === truckId);
      if (selectedTruck) {
        setFormValue(TRUCK_FIELD, selectedTruck.id);
      }
    }
  }, [order.id, setFormValue, trucks, truckId]);

  useEffect(() => {
    if (driverId && order.id && drivers?.length > 0) {
      const selectedDriver = drivers.find(driver => driver.id === driverId);
      if (selectedDriver) {
        setFormValue(DRIVER_FIELD, selectedDriver.id);
      }
    }
  }, [order.id, setFormValue, drivers, driverId]);

  const onSaveDraft = async () => {
    setSaveMode(SaveMode.SaveDraft);
    let result;
    const formData = getFormValues();
    const serverData = {
      ticketNumber: formData[TICKET_NUMBER_FIELD],
      truckId: formData[TRUCK_FIELD] || null,
      driverId: formData[DRIVER_FIELD] || null,
      plantId: formData[PLANT_FIELD] || null,
      batchSize: Number(formData[BATCH_SIZE_FIELD]),
      ticketStatus: APITicketStatuses.Draft,
    };
    if (ticketId) {
      result = await updateTicket(order.id, ticketId, serverData);
    } else {
      result = await addTicket(order.id, serverData);
    }
    if (!result.error) {
      onSaveDraftSuccess?.(order);
    }
  };

  const onTicketStatusChanged = (event: SelectChangeEvent<unknown>) => {
    setNewTicketStatus(event.target.value as APITicketStatuses);
    setShowChangeTicketStatusDialog(true);
  };

  const changeTicketStatus = async () => {
    setChangeTicketStatusPending(true);
    const serverData = {
      ticketNumber: ticketData?.ticketNumber,
      driverId: ticketData?.driver?.id,
      truckId: ticketData?.truck?.id,
      plantId: ticketData?.plant.id,
      batchSize: ticketData?.batchSize,
      ticketStatus: newTicketStatus!,
    };
    const result = await updateTicket(
      ticketData?.orderId || 0,
      ticketData?.id || 0,
      serverData,
    );

    if (result.error) {
      const errorStatus = (result as { payload: { status: number } })?.payload
        ?.status;
      if (errorStatus === 400) {
        addNotification({
          severity: NotificationSeverity.Error,
          message: getServerErrorTitle(result),
        });
      } else {
        addNotification({
          severity: NotificationSeverity.Error,
          message: t('common.generalError'),
        });
      }
    } else {
      setTicketData(result.payload);
    }
    setChangeTicketStatusPending(false);
    setShowChangeTicketStatusDialog(false);
  };

  const onSubmit = async (data: FormData) => {
    setSaveMode(SaveMode.SubmitTicket);
    let result;
    const serverData = {
      ticketNumber: data[TICKET_NUMBER_FIELD],
      truckId: data[TRUCK_FIELD],
      driverId: data[DRIVER_FIELD] || null,
      plantId: data[PLANT_FIELD],
      batchSize: Number(data[BATCH_SIZE_FIELD]),
      ticketStatus: APITicketStatuses.Loading,
    };
    if (ticketId) {
      result = await updateTicket(order.id, ticketId, serverData);
    } else {
      result = await addTicket(order.id, serverData);
      if (!result.error) {
        addNotification({
          message: t('ticket.createSuccessText'),
        });
      }
    }

    if (!result.error) {
      onSubmitSuccess?.(order);
    } else {
      const errorStatus = (result as { payload: { status: number } })?.payload
        ?.status;
      if (errorStatus === 409) {
        setError(TICKET_NUMBER_FIELD, {
          type: 'custom',
          message: t('order.ticket.form.validation.duplicatedTicketNumber'),
        });
      } else if (errorStatus === 400) {
        addNotification({
          severity: NotificationSeverity.Error,
          message: getServerErrorTitle(result),
        });
      }
      return Promise.reject('error');
    }
  };

  const isFormDisabled = addTicketPending || updateTicketPending;
  const isReadOnlyMode =
    ticketId && ticketData?.ticketStatus !== APITicketStatuses.Draft;
  const isSubmitPending =
    addTicketPending && saveMode === SaveMode.SubmitTicket;
  const isSaveDraftPending =
    (addTicketPending || updateTicketPending) &&
    saveMode === SaveMode.SaveDraft;
  const showTicketCancelButton = ticketData
    ? canSwitchBetweenStatuses(
        ticketData.ticketStatus,
        APITicketStatuses.Cancelled,
      )
    : false;

  const isTodayOrder = dayjs(order.deliveryDate).isToday();
  const isStatusEditable =
    canEditStatus(ticketData?.ticketStatus) &&
    userType === APIUserType.Dispatcher;
  const ticketTimeStamps = useMemo(
    () =>
      parseTicketStatusHistory({
        ticketStatusHistory: ticketData?.ticketStatusHistory,
        t,
      }),
    [t, ticketData?.ticketStatusHistory],
  );
  const showTimeStamps =
    isTimeStampEnabled &&
    ticketData?.ticketStatus !== APITicketStatuses.Draft &&
    isReadOnlyMode;

  return (
    <>
      {showCloseButton && (
        <PopoverCloseButton onClose={() => onClosePopup?.(isDirty)} />
      )}

      <FormWrapper onSubmit={e => handleSubmit(onSubmit)(e).catch(_.noop)}>
        {loadTicketPending && (
          <Loader>
            <CircularProgress />
          </Loader>
        )}
        {ticketData && (
          <>
            <CancelTicketConfirmation
              ticket={ticketData}
              show={!!showCancelConfirmation}
              onClose={setShowCancelConfirmation}
              onConfirm={onCancelTicket}
            />
            <ConfirmationDialog
              onClose={() => setShowChangeTicketStatusDialog(false)}
              onCloseAnimationEnd={() => setNewTicketStatus(null)}
              open={showChangeTicketStatusDialog}
              handleActionClick={changeTicketStatus}
              actionPending={changeTicketStatusPending}
              description={
                newTicketStatus === APITicketStatuses.Completed
                  ? t('ticket.changeStatusToCompleteConfirmationMessage')
                  : t('ticket.changeStatusConfirmationMessage')
              }
              cancelButtonTitle={t('common.noNevermind')}
              actionButtonTitle={
                newTicketStatus === APITicketStatuses.Completed
                  ? t('ticket.changeStatusToCompleteConfirmButton')
                  : t('ticket.changeStatusConfirmButton')
              }
            />
          </>
        )}
        <Header>
          <Title variant="h3">
            {isReadOnlyMode
              ? `${t('order.ticket.title')}# ${ticketData?.ticketNumber}`
              : t('order.ticket.addTicketTitle')}
          </Title>
          {!isReadOnlyMode && (
            <ActionButtons>
              <Button
                disabled={isFormDisabled}
                variant="outlined"
                color="secondary"
                onClick={() => onClosePopup?.(isDirty)}
              >
                {t('common.cancel')}
              </Button>
              <LoadingButton
                variant="outlined"
                color="secondary"
                disabled={isFormDisabled}
                loading={isSaveDraftPending}
                onClick={onSaveDraft}
              >
                {t('order.ticket.saveDraftButtonText')}
              </LoadingButton>
              <Tooltip
                placement="top-end"
                enterDelay={TOOLTIP_APPEAR_DELAY}
                enterNextDelay={TOOLTIP_APPEAR_DELAY}
                title={isTodayOrder ? '' : t('order.ticket.notTodayOrderText')}
              >
                {/* this span is needed to be able to show tooltip on disabled button */}
                {/* which doesn't fire events */}
                <span>
                  <LoadingButton
                    variant="contained"
                    type="submit"
                    loading={isSubmitPending}
                    disabled={
                      (isSubmitSuccessful && !isValid) ||
                      isFormDisabled ||
                      !isTodayOrder
                    }
                  >
                    {t('order.ticket.addTicketButtonText')}
                  </LoadingButton>
                </span>
              </Tooltip>
            </ActionButtons>
          )}
        </Header>
        <Content>
          <ContentHeader>
            <div>
              <DispatcherCanAccess>
                {showTicketCancelButton && (
                  <LoadingButton
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={() => setShowCancelConfirmation(true)}
                  >
                    {t('order.ticket.cancelTicketButtonText')}
                  </LoadingButton>
                )}
              </DispatcherCanAccess>
            </div>
            <DateText variant="subtitle2">
              {ticketId
                ? formatDisplayedDate(ticketData?.createdDate)
                : formatDisplayedDate(new Date())}
            </DateText>
          </ContentHeader>

          {isReadOnlyMode && ticketData ? (
            <>
              <StyledReadOnlyField
                value={
                  <>
                    <StatusSelect
                      value={newTicketStatus || ticketData?.ticketStatus}
                      variant="standard"
                      IconComponent={
                        // Required to display no icon next to the dropdown
                        // `undefined` results in default down arrow icon
                        isStatusEditable ? StyledDropdownIcon : () => null
                      }
                      onChange={onTicketStatusChanged}
                      disabled={!isStatusEditable}
                    >
                      {TICKET_STATUSES_ORDERED.map(status => (
                        <MenuItem
                          disabled={
                            !canSwitchBetweenStatuses(
                              ticketData.ticketStatus,
                              status,
                            )
                          }
                          key={status}
                          value={status}
                          sx={
                            status === APITicketStatuses.Cancelled
                              ? { display: 'none' }
                              : {}
                          }
                        >
                          <TicketStatus
                            style={{
                              color:
                                status === APITicketStatuses.Completed &&
                                order?.orderStatus === APIOrderStatus.Completed
                                  ? theme.custom.palette.muted200
                                  : ticketStatusMap[status].color,
                            }}
                          >
                            {getTicketLabelElement(status)}
                          </TicketStatus>
                        </MenuItem>
                      ))}
                    </StatusSelect>
                    {ticketData &&
                      shouldShowTicketETA(
                        ticketData.ticketStatus,
                        timeOfArrivalDuration,
                      ) && (
                        <StyledTicketETA>
                          <Trans i18nKey="orderDetails.ticket.eta">
                            {{
                              duration: getTruckETA(timeOfArrivalDuration),
                            }}{' '}
                            away
                          </Trans>
                        </StyledTicketETA>
                      )}
                  </>
                }
                label={t('order.ticket.form.statusLabel')}
              />
              <StyledReadOnlyField
                value={
                  <TruckNumberTag value={ticketData?.truck?.truckNumber} />
                }
                label={t('order.ticket.form.truckLabel')}
              />
              <StyledReadOnlyField
                value={getUserFullName(ticketData?.driver)}
                label={t('order.ticket.form.driverLabelReadOnly')}
              />
              <StyledReadOnlyField
                value={ticketData?.plant?.name ?? ''}
                label={t('order.ticket.form.plantLabel')}
              />
              <StyledReadOnlyField
                value={ticketData?.batchSize.toString()}
                label={t('order.ticket.form.batchSizeLabel')}
              />
              <StyledReadOnlyField
                value={
                  HIDDEN_CY_PROGRESS_TICKET_STATUSES.includes(
                    ticketData.ticketStatus,
                  )
                    ? 'N/A'
                    : ticketData?.totalOnDelivery?.toString?.()
                }
                label={t('order.ticket.form.totalOnDeliveryLabel')}
              />
            </>
          ) : (
            <>
              <FormField
                isRequired
                isDisabled={isFormDisabled}
                fieldName={TICKET_NUMBER_FIELD}
                label={t('order.ticket.form.ticketNumberLabel')}
                fieldError={errors[TICKET_NUMBER_FIELD]}
                requiredErrorMessage={t('common.form.emptyFieldError')}
                control={control}
                placeholder={t('order.ticket.form.ticketNumberPlaceholder')}
                inputProps={{
                  maxLength: FIELD_MAX_LENGTH,
                }}
              />
              <FormField
                isRequired
                isDisabled={isFormDisabled}
                fieldName={TRUCK_FIELD}
                label={t('order.ticket.form.truckLabel')}
                fieldError={errors[TRUCK_FIELD]}
                requiredErrorMessage={t('common.form.emptyFieldError')}
                control={control}
                placeholder={t('order.ticket.form.truckPlaceholder')}
                isLoading={loadTrucksPending}
                selectOptions={trucksOptions}
              />
              <FormField
                isRequired={false}
                isDisabled={isFormDisabled || !watchedTruckValue}
                fieldName={DRIVER_FIELD}
                label={t('order.ticket.form.driverLabel')}
                fieldError={errors[DRIVER_FIELD]}
                control={control}
                placeholder={t('order.ticket.form.driverPlaceholder')}
                isLoading={loadDriversPending}
                selectOptions={driversOptions}
              />
              <FormField
                isRequired
                isDisabled={isFormDisabled}
                fieldName={PLANT_FIELD}
                label={t('order.ticket.form.plantLabel')}
                fieldError={errors[PLANT_FIELD]}
                requiredErrorMessage={t('common.form.emptyFieldError')}
                control={control}
                placeholder={t('order.ticket.form.plantPlaceholder')}
                isLoading={loadPlantsPending}
                selectOptions={plantsOptions}
              />
              <FormNumberField
                precision={2}
                isRequired
                isDisabled={isFormDisabled}
                fieldName={BATCH_SIZE_FIELD}
                label={t('order.ticket.form.batchSizeLabel')}
                fieldError={errors[BATCH_SIZE_FIELD]}
                requiredErrorMessage={t('common.form.emptyFieldError')}
                control={control}
                placeholder={t('order.ticket.form.batchSizePlaceholder')}
                rules={{
                  validate: {
                    incorrectBatchSize: (value: string) =>
                      Number(value) > 0 ||
                      t('order.ticket.form.validation.incorrectBatchSize'),
                  },
                }}
                maxLength={10}
              />
            </>
          )}
          <StyledDivider />
          {showTimeStamps && (
            <>
              <TimeStamps steps={ticketTimeStamps} />
              <NarrowDivider />
            </>
          )}
          <StyledReadOnlyField
            value={ticketId ? ticketData?.orderNumber : order.orderNumber}
            label={t('order.ticket.form.orderNumberLabel')}
          />
          <StyledReadOnlyField
            value={ticketId ? ticketData?.orderName : order.orderName}
            label={t('order.ticket.form.orderNameLabel')}
          />
          <StyledReadOnlyField
            value={
              ticketId
                ? ticketData?.project?.name || ''
                : order.project?.name || ''
            }
            label={t('order.ticket.form.projectLabel')}
          />
          <StyledReadOnlyField
            value={ticketId ? ticketData?.company?.name : order.company?.name}
            label={t('order.ticket.form.customerNameLabel')}
          />
          <StyledReadOnlyField
            value={
              ticketId ? ticketData?.deliveryLocation : order?.deliveryLocation
            }
            label={t('order.ticket.form.deliveryLocationLabel')}
          />
          {ticketData?.cancellationReasonComment && (
            <StyledReadOnlyField
              value={ticketData?.cancellationReasonComment}
              label={t('order.ticket.form.comment')}
            />
          )}
        </Content>
      </FormWrapper>
    </>
  );
};
