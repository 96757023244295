import React, { useMemo } from 'react';

import styled from 'styled-components';

import { BreadcrumbsViewAll, Stack } from 'components';

import { OrderCreateEditForm } from './OrderCreateEditForm';

import { useLocation, useTranslation } from 'third-party';

import { allOrdersUrl } from 'constants/url';

import { LocationCoordinates } from 'types/app';

type OrderViewProps = {
  manualDeliveryLocation?: LocationCoordinates | null;
};

const ScrollableContent = styled(Stack)`
  overflow-y: auto;
  padding-right: 2rem;
`;

export const OrderCreateLeftPanel: React.FC<OrderViewProps> = ({
  manualDeliveryLocation,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { state } = location;

  const { backUrl, backUrlLabel } = useMemo(() => {
    return state?.backUrl
      ? {
          backUrl: state.backUrl,
          backUrlLabel: state.backUrlLabel || t('common.back'),
        }
      : {
          backUrl: allOrdersUrl(),
          backUrlLabel: t('order.breadcrumbs.viewAll'),
        };
  }, [state?.backUrl, state?.backUrlLabel, t]);

  return (
    <ScrollableContent>
      <BreadcrumbsViewAll url={backUrl} text={backUrlLabel} />
      <OrderCreateEditForm manualDeliveryLocation={manualDeliveryLocation} />
    </ScrollableContent>
  );
};
