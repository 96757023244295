export enum LogType {
  info = 'info',
  error = 'error',
  warning = 'warning',
}

const forceLog = '#{BROWSER_LOGGING}#';
// @ts-ignore
const isLogEnabled = __DEV__ || forceLog === 'true';

export const appLog = (
  type: LogType = LogType.info,
  message?: any,
  ...optionalParams: any[]
) => {
  if (!isLogEnabled) {
    return;
  }

  switch (type) {
    case LogType.error:
      // eslint-disable-next-line no-console
      console.error('Concretely: ', message, ...optionalParams);
      break;
    case LogType.warning:
      // eslint-disable-next-line no-console
      console.warn('Concretely: ', message, ...optionalParams);
      break;
    default:
      // eslint-disable-next-line no-console
      console.log('Concretely: ', message, ...optionalParams);
  }
};

export const log = (message?: any, ...optionalParams: any[]) => {
  appLog(LogType.info, message, ...optionalParams);
};

export const error = (message?: any, ...optionalParams: any[]) => {
  appLog(LogType.error, message, ...optionalParams);
};
