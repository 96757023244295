import React from 'react';

import { Grid, Trans, Typography } from 'components';

import { useTheme, useTranslation } from 'third-party';

interface CanceledOrderProps {
  customerName?: string;
}

export const CanceledOrder: React.FC<CanceledOrderProps> = props => {
  const { customerName } = props;
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Grid item md={8}>
      <Grid item md={8}>
        <Typography variant="h5">
          {t('order.details.cancelled.title')}
        </Typography>
        <Typography
          color={theme.custom.palette.gray}
          variant="body1"
          marginTop="0.8rem"
        >
          <Trans i18nKey="order.details.canceled.description">
            <strong style={{ fontWeight: 600 }}>
              {{ customerName: customerName }}
            </strong>{' '}
            has canceled the order.
          </Trans>
        </Typography>
      </Grid>
    </Grid>
  );
};
