import { AppTableSettings } from 'constants/common';

import { AppPersistedState } from 'types/app';

export const initialMigration = (state: AppPersistedState) => {
  const newState = { ...state };

  // adding `orderNumber` column to list of hidden columns by default
  Object.keys<string>(state.storage.userSettings).forEach(userId => {
    newState.storage.userSettings?.[userId]?.[
      AppTableSettings.AllOrders
    ]?.hiddenColumns?.push('orderNumber'),
      newState.storage.userSettings?.[userId]?.[
        AppTableSettings.TodayOrders
      ]?.hiddenColumns?.push('orderNumber');
  });

  return Promise.resolve(newState);
};

export const migrations = {
  // 2: (state: AppPersistedState) => {
  //   change state here and return new version of it
  //   return state;
  // },
};
