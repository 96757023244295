import React from 'react';

import { Optional } from '@cd3p/core/types/utils';

import { useTranslation } from 'third-party';

import { FIELD_MAX_LENGTH } from 'constants/common';

import { useCache } from 'modules/cache';

import {
  CommonTypeaheadField,
  CommonTypeaheadFieldProps,
} from 'features/Fields/CommonTypeaheadField';

type Props<FormPropsT extends Record<string, any>> = Optional<
  CommonTypeaheadFieldProps<FormPropsT>,
  | 'placeholder'
  | 'label'
  | 'notFoundText'
  | 'requiredErrorMessage'
  | 'loadOptions'
>;

export const CustomerField = <FormPropsT extends Record<string, any>>({
  fieldName,
  label,
  placeholder,
  notFoundText,
  requiredErrorMessage,
  maxLength = FIELD_MAX_LENGTH,
  ...props
}: Props<FormPropsT>) => {
  const { t } = useTranslation();

  const { loadCustomers } = useCache();

  return (
    <CommonTypeaheadField
      fieldName={fieldName}
      label={label || t('common.form.customer.label')}
      requiredErrorMessage={
        requiredErrorMessage || t('common.form.emptyFieldError')
      }
      placeholder={placeholder || t('common.form.customer.placeholder')}
      notFoundText={notFoundText || t('common.form.customer.notFoundText')}
      loadOptions={loadCustomers}
      maxLength={maxLength}
      {...props}
    />
  );
};
