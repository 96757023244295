import styled from 'styled-components';

import { Tab as MUITab } from '@mui/material';

export const Tab = styled(MUITab)`
  min-height: 4rem;
  font-weight: 900;
  font-size: 1.6rem;
  color: ${props => props.theme.custom.palette.info900};
  flex-grow: 1;
  padding: 0;
  border-bottom: 1px solid transparent;
  max-width: none;
  &:not(.Mui-selected) {
    border-bottom-color: ${props => props.theme.custom.palette.muted100};
  }
  &.Mui-selected {
    background-color: ${props => props.theme.custom.palette.info50};
    color: ${props => props.theme.custom.palette.info900};
  }
`;
