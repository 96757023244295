import { useEffect } from 'react';

import {
  CHANGE_ORDER_STATUS_ACTION,
  LOAD_ORDER_ACTION,
  TOGGLE_ORDER_SUBSCRIPTION_ACTION,
  UPDATE_ORDER_ACTION,
  UPDATE_ORDER_CALLBACK_ACTION,
} from '@cd3p/core/modules/order';
import {
  APIOrder,
  APIOrderPositionElement,
  APIOrderSubscription,
  APIOrdersList,
} from '@cd3p/core/types/api';
import { subscribeActionsBefore } from '@cd3p/core/utils/redux';
import { getSuccessActionName } from '@cd3p/core/utils/sra/reducers';

import {
  LOAD_ALL_ORDERS_ACTION,
  LOAD_MORE_ORDERS_ACTION,
  UPDATE_ORDER_POSITION_IN_ALL_ORDERS_ACTION,
} from 'modules/allOrdersList';
import {
  UPDATE_ORDER_BATCH_NUMBER_ACTION,
  UPDATE_ORDER_REQUESTED_CHANGES_ACTION,
} from 'modules/order';
import { useOrdersCache } from 'modules/ordersCache';
import {
  LOAD_MORE_PROJECT_ORDERS_ACTION,
  LOAD_PROJECT_ORDERS_ACTION,
  UPDATE_ORDER_POSITION_IN_PROJECT_ORDERS_ACTION,
} from 'modules/projectOrdersList';
import {
  LOAD_MORE_TODAY_ORDERS_ACTION,
  LOAD_TODAY_ORDERS_ACTION,
  UPDATE_ORDER_POSITION_IN_TODAY_ORDERS_ACTION,
} from 'modules/todayOrdersList';

export const useTrackOrdersCache = () => {
  const { updateOrdersCache, updateOrderInCache } = useOrdersCache();

  useEffect(() => {
    return subscribeActionsBefore(
      [
        getSuccessActionName(UPDATE_ORDER_POSITION_IN_ALL_ORDERS_ACTION),
        getSuccessActionName(UPDATE_ORDER_POSITION_IN_TODAY_ORDERS_ACTION),
        getSuccessActionName(UPDATE_ORDER_POSITION_IN_PROJECT_ORDERS_ACTION),
      ],
      ({ payload }: { payload: APIOrderPositionElement }) => {
        if (payload.position !== -1) {
          updateOrderInCache(payload.element.id, payload.element);
        }
      },
    );
  }, [updateOrderInCache]);

  useEffect(() => {
    return subscribeActionsBefore(
      [
        getSuccessActionName(LOAD_ALL_ORDERS_ACTION),
        getSuccessActionName(LOAD_MORE_ORDERS_ACTION),
        getSuccessActionName(LOAD_TODAY_ORDERS_ACTION),
        getSuccessActionName(LOAD_MORE_TODAY_ORDERS_ACTION),
        getSuccessActionName(LOAD_PROJECT_ORDERS_ACTION),
        getSuccessActionName(LOAD_MORE_PROJECT_ORDERS_ACTION),
      ],
      ({ payload }: { payload: APIOrdersList }) => {
        updateOrdersCache(payload.result);
      },
    );
  }, [updateOrdersCache]);

  useEffect(() => {
    return subscribeActionsBefore(
      [
        getSuccessActionName(LOAD_ORDER_ACTION),
        getSuccessActionName(UPDATE_ORDER_ACTION),
        getSuccessActionName(CHANGE_ORDER_STATUS_ACTION),
        getSuccessActionName(UPDATE_ORDER_BATCH_NUMBER_ACTION),
        getSuccessActionName(UPDATE_ORDER_REQUESTED_CHANGES_ACTION),
        getSuccessActionName(UPDATE_ORDER_CALLBACK_ACTION),
        // not sure if we need this, maybe sometimes we need
        // to fetch order without updating it in cache 🤷‍♂️
        // getSuccessActionName(FETCH_ORDER_BY_ID_ACTION)
      ],
      ({ payload }: { payload: APIOrder }) => {
        updateOrderInCache(payload.id, payload);
      },
    );
  }, [updateOrderInCache]);

  useEffect(() => {
    return subscribeActionsBefore(
      [getSuccessActionName(TOGGLE_ORDER_SUBSCRIPTION_ACTION)],
      ({ payload }: { payload: APIOrderSubscription }) => {
        updateOrderInCache(payload.orderId, {
          isUserSubscribed: !payload.isDeleted,
        });
      },
    );
  }, [updateOrderInCache]);
};
