import {
  addressLocationEndpoint,
  locationAddressEndpoint,
  mapPlacesTypeaheadEndpoint,
  nearbyLocationsTypeaheadEndpoint,
} from '../constants/endpoints';
import { httpMethod } from '../utils/sra';

export type CoreCacheState = {
  loadAddressLocationPending: boolean;
  loadAddressByLocationPending: boolean;
  loadMapPlacesPending: boolean;
  loadNearbyLocationsPending: boolean;
};

export const coreCacheState: CoreCacheState = {
  loadAddressLocationPending: false,
  loadAddressByLocationPending: false,
  loadMapPlacesPending: false,
  loadNearbyLocationsPending: false,
};

const loadAddressLocation = (providerId: string, address: string) => ({
  name: 'loadAddressLocation',
  url: addressLocationEndpoint(providerId),
  params: {
    address,
  },
  method: httpMethod.get,
  // onSuccess: we don't put anything to store, just return result for typeahead
});

const loadAddressByLocation = (
  providerId: string,
  latitude: number,
  longitude: number,
) => ({
  name: 'loadAddressByLocation',
  url: locationAddressEndpoint(providerId),
  params: {
    latitude: latitude,
    longitude: longitude,
  },
  method: httpMethod.get,
  // onSuccess: we don't put anything to store, just return result for typeahead
});

const loadMapPlaces = (providerId: string, query: string) => ({
  name: 'loadMapPlaces',
  url: mapPlacesTypeaheadEndpoint(providerId),
  params: {
    query,
  },
  method: httpMethod.get,
  // onSuccess: we don't put anything to store, just return result for typeahead
});

const loadNearbyLocations = (providerId: string, keyword: string) => ({
  name: 'loadNearbyLocations',
  url: nearbyLocationsTypeaheadEndpoint(providerId),
  params: {
    keyword,
  },
  method: httpMethod.get,
  // onSuccess: we don't put anything to store, just return result for typeahead
});

export const coreCacheMethods = {
  loadAddressLocation,
  loadAddressByLocation,
  loadMapPlaces,
  loadNearbyLocations,
};
