import { ReactElement } from 'react';

import { MAX_PERSISTENT_NOTIFICATION_ON_SCREEN } from '../constants/common';
import { Notification } from '../modules/notifications';

// According to the requirements we limit the number of persistent
// notifications by MAX_PERSISTENT_NOTIFICATION_ON_SCREEN
// so not to fill the whole screen with notifications.
// As soon as user closes one of them we show the next on in stack
export const renderLimitedNumberOfNotifications = (
  notificationItems: Notification[],
  renderItem: (notification: Notification) => ReactElement,
) => {
  return notificationItems.reduce<{
    persistentItemsCount: number;
    displayItems: ReactElement[];
  }>(
    ({ persistentItemsCount, displayItems }, notification) => {
      const isPersistent = notification.autoHide === false;
      const shouldDisplay =
        !isPersistent ||
        (isPersistent &&
          persistentItemsCount < MAX_PERSISTENT_NOTIFICATION_ON_SCREEN);
      return {
        persistentItemsCount:
          isPersistent && shouldDisplay
            ? persistentItemsCount + 1
            : persistentItemsCount,
        displayItems: [
          ...displayItems,
          ...(shouldDisplay ? [renderItem(notification)] : []),
        ],
      };
    },
    { persistentItemsCount: 0, displayItems: [] },
  ).displayItems;
};
