export const NUMBER_REGEXP = new RegExp(/^[+-]?\d+(\.\d+)?$/);

const DIGITS_REGEX = new RegExp(/\d+/g);

export const getMaskedPhone = (phoneNumber?: string) => {
  // +1234567801 => (234)-567-8901
  // 11133334444558887777 => (455)-888-7777
  if (!phoneNumber) return '';
  const numbersOnly = (phoneNumber.match(DIGITS_REGEX) || []).join('');
  const operatorCode = numbersOnly.slice(-10, -7);
  const phoneStart = numbersOnly.slice(-7, -4);
  const phoneEnd = numbersOnly.slice(-4);
  return `(${operatorCode})-${phoneStart}-${phoneEnd}`;
};
