import { APITicketsListParams } from '@cd3p/core/types/api';

import { dayjs } from 'third-party';

import { TicketsListState } from 'modules/common/ticketsList';

import { API_DATE_FORMAT } from 'utils/order';

export const composeLoadTicketsRequestBody = ({
  pageNumber,
  pageSize,
  sorting,
  filters,
}: {
  pageNumber: TicketsListState['tickets']['pagination']['pageNumber'];
  pageSize: TicketsListState['tickets']['pagination']['pageSize'];
  sorting: TicketsListState['tickets']['sorting'];
  filters: TicketsListState['tickets']['filters'];
}): Partial<APITicketsListParams> => {
  return {
    pageNumber,
    pageSize,
    searchSortOrders: sorting,
    ...(filters.date.dateFrom
      ? { dateFrom: dayjs(filters.date.dateFrom).format(API_DATE_FORMAT) }
      : {}),
    ...(filters.date.dateTo
      ? { dateTo: dayjs(filters.date.dateTo).format(API_DATE_FORMAT) }
      : {}),
    ...(filters.ticketStatuses
      ? { ticketStatuses: filters.ticketStatuses }
      : {}),
  };
};
