import React, { useCallback, useMemo } from 'react';

import styled from 'styled-components';

import { APIDialogModel } from '@cd3p/core/types/api';
import { formatDisplayedDate, formatTime } from '@cd3p/core/utils/common';
import { parseDialogLastMessage } from '@cd3p/core/utils/dialogs';
import { DispatcherCanAccess, Stack, Typography } from 'components';
import { motion } from 'framer-motion';
import { useDialogRead } from 'hooks/useDialogRead';

import { DIALOG_ITEM_HEIGHT } from './DialogsPanel';

import {
  ContentLoader,
  dayjs,
  useLocation,
  useMatch,
  useNavigate,
  useParams,
  useTranslation,
} from 'third-party';

import { orderChatUrl, orderUrl } from 'constants/url';

import { getHighlightedContent } from 'utils/ordersList';

const Date = styled(Typography)`
  flex-shrink: 0;
  font-size: 1.4rem;
  font-weight: 600;
  margin-right: auto;
  padding-bottom: 0.3rem;
  color: ${props => props.theme.custom.palette.muted800};
  margin-left: auto;
  display: block;
`;

const Wrapper = styled(Stack)<{ height: number; active?: string }>`
  gap: 1.2rem;
  justify-content: center;
  padding: 0 1rem;
  height: ${props => props.height / 10}rem;
  border-bottom: 1px solid ${props => props.theme.custom.palette.muted100};
  box-sizing: border-box;
  background-color: ${props =>
    props.active === 'true' ? props.theme.custom.palette.primary50 : '#fff'};
  transition: background-color 0.5s;
  cursor: pointer;
  min-width: 0;
  &:hover {
    background-color: ${props => props.theme.custom.palette.primary50};
  }
`;

const Content = styled(Stack)``;

const Title = styled(Typography)`
  color: ${props => props.theme.custom.palette.secondary700};
  padding-right: 2rem;
  margin-bottom: 0.25rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
`;

const CardText = styled(Typography)`
  font-size: 1.4rem;
  font-weight: 400;
  color: ${props => props.theme.custom.palette.muted800};
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
`;

const UserName = styled(Typography)`
  flex-shrink: 0;
  font-size: 1.4rem;
  color: ${props => props.theme.custom.palette.primary900};
  font-weight: 700;
  margin-right: 0.5rem;
`;

const UnreadBadge = styled(motion.div)`
  width: 1rem;
  height: 1rem;
  min-width: 1rem;
  border-radius: 1rem;
  background-color: ${props => props.theme.custom.palette.notificationBadge};
`;

type Props = {
  isActive?: boolean;
  rowHeight: number;
  item: APIDialogModel;
};

export const DialogsItem: React.FC<Props> = React.memo(
  ({ item, rowHeight, isActive = false }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();
    const matchOrderUrl = useMatch(orderUrl('*'));
    const isDialogRead = useDialogRead();

    const onCardClick = useCallback(() => {
      if (matchOrderUrl && Number(params.id) === item.orderId) {
        // avoid page reload if we're viewing the same order
        navigate(location, {
          state: { isChatPanelOpened: true },
        });
      } else {
        navigate(orderChatUrl(item.orderId));
      }
    }, [item.orderId, location, matchOrderUrl, navigate, params.id]);

    const { userFullName, userLastMessage } = useMemo(() => {
      return parseDialogLastMessage(item.lastMessage);
    }, [item.lastMessage]);

    const dialogDateAndTime = useMemo(() => {
      if (dayjs(item.updatedOn).isToday()) {
        return formatTime(item.updatedOn);
      } else if (dayjs(item.updatedOn).isYesterday()) {
        return t('common.yesterday');
      }
      return `${formatDisplayedDate(item.updatedOn)}`;
    }, [item.updatedOn, t]);

    return (
      <Wrapper
        height={rowHeight}
        onClick={onCardClick}
        active={isActive.toString()}
      >
        <Content>
          <Stack direction="row" alignItems="center">
            <Title variant="subtitle2" title={item.orderName || ''}>
              {getHighlightedContent(
                item.orderName,
                item.highlights?.orderName,
              )}
            </Title>
            <Date>{dialogDateAndTime}</Date>
          </Stack>
          <DispatcherCanAccess>
            <CardText title={item.companyName || ''}>
              {getHighlightedContent(
                item.companyName,
                item.highlights?.companyName,
              )}
            </CardText>
          </DispatcherCanAccess>
          <Stack direction="row" alignItems="center" minWidth={0}>
            {!item.highlights?.message?.length && userFullName && (
              <UserName>{`[${userFullName}]:`}</UserName>
            )}
            <CardText title={userLastMessage}>
              {getHighlightedContent(userLastMessage, item.highlights?.message)}
            </CardText>
            <UnreadBadge
              initial={false}
              animate={{
                scale: !isDialogRead(item) ? [0, 1.2, 1] : [1, 0],
              }}
            />
          </Stack>
        </Content>
      </Wrapper>
    );
  },
);

export const DialogsItemLoader = () => {
  return (
    <Wrapper height={DIALOG_ITEM_HEIGHT} style={{ pointerEvents: 'none' }}>
      <ContentLoader>
        <rect x="0" y="10" width="170" height="20" />
        <rect x="0" y="40" width="150" height="15" />
        <rect x="0" y="60" width="300" height="15" />
      </ContentLoader>
    </Wrapper>
  );
};
