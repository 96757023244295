import React, { useEffect, useState } from 'react';

import { InsightBlock, SvgIcon } from 'components';

import { useSelector, useTranslation } from 'third-party';

import { useAdditiveTypesList } from 'modules/additiveTypesList';

import { additiveTypesSelectors } from 'selectors';

type Props = {
  isActive?: boolean;
  onClick: () => void;
};

export const IndividualProjectAdditivesWidget: React.FC<Props> = ({
  onClick,
  isActive,
}) => {
  const { t } = useTranslation();

  const [totalCount, setTotalCount] = useState(0);

  const { loadProjectsAdditiveTypes } = useAdditiveTypesList();

  const projectAdditiveTypesLoaded = useSelector(
    additiveTypesSelectors.projectAdditiveTypesLoaded,
  );
  const projectAdditiveTypesCount = useSelector(
    additiveTypesSelectors.projectAdditiveTypesCount,
  );
  const projectsAdditiveTypesSearch = useSelector(
    additiveTypesSelectors.projectsAdditiveTypesSearch,
  );

  useEffect(() => {
    if (!projectsAdditiveTypesSearch) {
      setTotalCount(projectAdditiveTypesCount);
    }
  }, [projectAdditiveTypesCount, projectsAdditiveTypesSearch]);

  useEffect(() => {
    if (!projectAdditiveTypesLoaded) {
      loadProjectsAdditiveTypes();
    }
  }, [loadProjectsAdditiveTypes, projectAdditiveTypesLoaded]);

  return (
    <InsightBlock
      isActive={isActive}
      isLoading={!projectAdditiveTypesLoaded}
      title={t('plantSettings.insightsAdditivesTypesIndividualProjectsTitle')}
      description={t(
        'plantSettings.insightsAdditivesTypesIndividualProjectsDescription',
        {
          count: totalCount,
        },
      )}
      linkLabel={t('plantSettings.insightsViewList')}
      onClick={onClick}
      icon={
        <SvgIcon icon="insights-project-icon" height="3.6rem" width="3.6rem" />
      }
    />
  );
};
