import React, { useEffect } from 'react';

import { InsightBlock, SvgIcon } from 'components';

import { useSelector, useTranslation } from 'third-party';

import { useAdditiveTypesList } from 'modules/additiveTypesList';

import { additiveTypesSelectors } from 'selectors';

type Props = {
  isActive?: boolean;
  onClick: () => void;
};

export const LeastOrderedAdditivesWidget: React.FC<Props> = ({
  onClick,
  isActive,
}) => {
  const { t } = useTranslation();

  const { loadLeastUsedAdditiveTypes } = useAdditiveTypesList();

  const leastUsedAdditiveTypesLoaded = useSelector(
    additiveTypesSelectors.leastUsedAdditiveTypesLoaded,
  );
  const loadLeastUsedAdditiveTypesPending = useSelector(
    additiveTypesSelectors.loadLeastUsedAdditiveTypesPending,
  );
  const leastUsedAdditiveTypesCount = useSelector(
    additiveTypesSelectors.leastUsedAdditiveTypesCount,
  );

  useEffect(() => {
    if (!leastUsedAdditiveTypesLoaded) {
      loadLeastUsedAdditiveTypes();
    }
  }, [loadLeastUsedAdditiveTypes, leastUsedAdditiveTypesLoaded]);

  const notSufficientData =
    leastUsedAdditiveTypesLoaded && leastUsedAdditiveTypesCount === 0;

  return (
    <InsightBlock
      isActive={isActive}
      isLoading={
        loadLeastUsedAdditiveTypesPending || !leastUsedAdditiveTypesLoaded
      }
      title={t('plantSettings.insightsAdditivesTypesLeastTitle')}
      description={
        notSufficientData
          ? t('plantSettings.insightsAdditiveTypesLeastDefaultText')
          : t('plantSettings.insightsAdditiveTypesLeastDescription', {
              count: leastUsedAdditiveTypesCount,
            })
      }
      linkLabel={t('plantSettings.insightsViewList')}
      onClick={notSufficientData ? undefined : onClick}
      icon={
        <SvgIcon icon="insights-least-icon" height="3.6rem" width="3.6rem" />
      }
    />
  );
};
