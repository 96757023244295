import React from 'react';

import styled from 'styled-components';

import { Stack, Typography } from 'components';

import { RouterLink } from 'third-party';

const Inner = styled(Stack)`
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
`;

const StyledReadOnlyField = styled(Stack)`
  margin-bottom: 1.2rem;
  font-size: 1.6rem;
  line-height: 2rem;
`;

const StyledValueLink = styled(RouterLink)`
  color: ${props => props.theme.custom.palette.gray};
  text-decoration: none;
  line-height: 1.25;
  &:hover {
    color: ${props => props.theme.custom.palette.info600};
    text-decoration: underline;
  }
`;

interface Props {
  label?: string;
  value?: string | number | React.ReactElement | null | React.ReactElement[];
  linkTo?: string;
  className?: string;
  linkState?: any;
  children?: React.ReactElement | React.ReactElement[] | null;
}

export const ReadOnlyField: React.FC<Props> = ({
  value,
  label,
  linkTo,
  className,
  linkState = null,
  children = null,
}) => {
  return (
    <StyledReadOnlyField className={className} key={label} color="#5C6C80">
      <Inner>
        {label && (
          <Typography variant="subtitle2" display="inline">
            {label}:&nbsp;&nbsp;
          </Typography>
        )}
        {linkTo ? (
          <StyledValueLink to={linkTo} state={linkState}>
            {value}
          </StyledValueLink>
        ) : (
          value
        )}
      </Inner>
      {children}
    </StyledReadOnlyField>
  );
};
