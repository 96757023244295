import { Core } from '../config';
import { APIHelpRequestType, APITicket, APITicketStatuses } from '../types/api';

export const BASE_PATH = '/';

export const APP_PATH = '/';

// http://10.0.2.2:8080/ - android
// http://localhost:8080/ - ios
export const API_PATH = (() => {
  if (Core.isLocalEnv()) {
    return `http://localhost:3000/api/`;
  }

  if (Core.isAdminPortal()) {
    return `https://${Core.config.env}-admin.concretely.co/api/`;
  }

  return `https://${Core.config.env}.concretely.co/api/`;
})();

export const ORDERS_API_PATH = `${API_PATH}orders/`;
export const COMMUNICATIONS_API_PATH = `${API_PATH}communications/`;

export const API_DATE_FORMAT = 'YYYY-MM-DD';
export const API_DATE_AND_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
export const API_TIME_FORMAT = 'HH:MM';

export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc',
}

export const CONCRETELY_SUPPORT_EMAIL = 'support@concretely.co';
export const DEFAULT_PAGE_SIZE = 10;
export const MAX_ORDER_SEARCH_SIZE = 80;

export const CONTRACTOR_TICKET_STATUSES = [
  APITicketStatuses.Loading,
  APITicketStatuses.Delivering,
  APITicketStatuses.OnSite,
  APITicketStatuses.Completed,
  APITicketStatuses.Cancelled,
];
export const ASSIGNED_TICKET_STATUSES = [
  APITicketStatuses.Loading,
  APITicketStatuses.Delivering,
  APITicketStatuses.OnSite,
  APITicketStatuses.Pending,
];
export const isTicketAssigned = (assignedTicket: APITicket) => {
  return (
    assignedTicket &&
    ASSIGNED_TICKET_STATUSES.includes(assignedTicket.ticketStatus)
  );
};

export const USER_INPUT_DEBOUNCE = 1000;

export const MAX_PERSISTENT_NOTIFICATION_ON_SCREEN = 3;

export const TRUCKS_UPDATE_OFFLINE_STATUS_INTERVAL = 1000; // 1 second
export const TRUCKS_IDLE_PERIOD_FOR_OFFLINE_STATUS = 5 * 60 * 1000; // 5 minutes

export const CHAT_MESSAGE_MAX_LENGTH = 2000;

export const ALL_ITEMS_COUNT = 9999;

export const CHAT_SYSTEM_MESSAGE_SENDER_ID =
  'f2a57c71-0ba5-40e9-bb77-9c8e76f2c702';
export const CHAT_BOT_MESSAGE_SENDER_ID =
  '96168db5-fa41-4f23-bac1-4c0476ba6819';

export const DEFAULT_TIMEZONE = 'America/New_York';

export enum Application {
  CONTRACTOR = 'Contractor',
  DRIVER = 'Driver',
  PLANT = 'Plant',
}

export const APP_IOS_MARKET_URL =
  'itms-apps://itunes.apple.com/app/concretely/id6469589439?mt=8';
export const APP_ANDROID_MARKET_URL =
  'https://play.google.com/store/apps/details?id=com.cd3p.live';

export enum UserOptions {
  HasAccessToMobileApp = 'hasAccessToMobileApp',
}

export const SupportTypes = {
  [APIHelpRequestType.HelpWithApp]: 'Looking for help using the app',
  [APIHelpRequestType.ReportIssue]: 'Report a software bug',
  [APIHelpRequestType.ProvideFeedback]: 'Provide feedback',
  [APIHelpRequestType.AccountIssue]: 'Account access/password reset issue',
  [APIHelpRequestType.Other]: 'Other account-related issue',
};
