import React, { useEffect } from 'react';

import styled from 'styled-components';

import { ON_APPLICATION_VERSION_CREATE } from '@cd3p/core/constants/hubs';
import { APIUserType } from '@cd3p/core/types/api';
import { DialogsPanel, RequiredActionsPanel } from 'features';
import { useInitContractor } from 'hooks/useInitContractor';

import { MainNavigation } from './MainNavigation';
import { ReleaseNotes } from './ReleaseNotes';

import { RouterOutlet, _, useSelector } from 'third-party';

import { useApp } from 'modules/app';

import { appSelectors } from 'selectors';

import { ChatHub } from 'features/ChatHub/ChatHub';

import { useOrdersSockets } from 'utils/sockets';

const MainViewWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
`;

const ContentWrapper = styled.div`
  flex-grow: 1;
  overflow-x: auto;
  display: flex;
  min-height: 0;
`;

const Inner = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-width: 120rem;
  min-height: 0;
`;

const hideAllTooltips = _.throttle(() => {
  const allTooltips = document.querySelectorAll<HTMLElement>(
    '[role="tooltip"]:not([class~="persistent-tooltip"])',
  );
  _.forEach(
    allTooltips,
    tooltipElement => (tooltipElement.style.display = 'none'),
  );
}, 1000);

export const MainView = () => {
  const { getUserInfo } = useApp();
  const user = useSelector(appSelectors.user);

  const initContractor = useInitContractor();

  useOrdersSockets(
    () => [
      {
        method: ON_APPLICATION_VERSION_CREATE,
        action: async () => {
          await getUserInfo();
        },
      },
    ],
    [getUserInfo],
  );

  // when we scroll any element that has tooltips inside, they're still showing
  // for some period of time while user scrolls the page and overlaps other elements.
  // this listener makes those tooltips hidden immediately before MUI removes them from the page
  useEffect(() => {
    window.document.addEventListener('scroll', hideAllTooltips, true);
    return () => {
      return window.document.removeEventListener('scroll', hideAllTooltips);
    };
  }, []);

  useEffect(() => {
    if (user.userType === APIUserType.Contractor) {
      initContractor();
    }
  }, [initContractor, user.userType]);

  return user.id ? (
    <MainViewWrapper>
      {!!user?.newApplicationVersions?.length && (
        <ReleaseNotes releaseNotes={user.newApplicationVersions} />
      )}
      <ChatHub />
      <MainNavigation />
      <DialogsPanel />
      <RequiredActionsPanel />
      <ContentWrapper>
        <Inner>
          <RouterOutlet />
        </Inner>
      </ContentWrapper>
    </MainViewWrapper>
  ) : null;
};
