import { APIDialogModel } from '../types/api';

export const parseDialogLastMessage = (lastMessage: string | null) => {
  const splitedData = lastMessage?.split(':');

  if (splitedData?.length === 1) {
    return {
      userFullName: null,
      userLastMessage: splitedData[0],
    };
  }

  return {
    userFullName: splitedData?.[0] || '',
    userLastMessage: splitedData?.[1] || '',
  };
};

export const isCustomerMessageRead = (
  dialog: APIDialogModel,
  currentUserId = '',
) => (dialog?.readCustomerIds || []).includes(currentUserId);

export const hasUnreadCustomerMessage = (
  items: APIDialogModel[],
  currentUserId = '',
) => items.some((it) => !isCustomerMessageRead(it, currentUserId));

export const hasUnreadDispatcherMessage = (items: APIDialogModel[]) =>
  items.some((it) => !it.isReadByDispatcher);
