import { useEffect, useRef } from 'react';

import { Notification } from '../modules/notifications';

export const useAutoHideNotification = (
  notification: Notification,
  onClose: () => void,
) => {
  const autoHideTimer = useRef<any>(null);

  useEffect(() => {
    clearTimeout(autoHideTimer.current);
    if (notification.autoHide && !isNaN(Number(notification.autoHide))) {
      autoHideTimer.current = setTimeout(() => {
        onClose();
      }, Number(notification.autoHide));
    }
    // ignoring onCloseCallback
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification]);
};
