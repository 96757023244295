import React, { useMemo } from 'react';

import styled from 'styled-components';

import { APIUserType } from '@cd3p/core/types/api';
import { CanAccess } from 'components';

import { ProjectCommonTab } from './ProjectCommonTab';

import { useSelector, useTranslation } from 'third-party';

import { useContractorMixTypes } from 'modules/contractorMixTypes';
import { useProject } from 'modules/project';

import { appSelectors, contractorMixTypesSelectors } from 'selectors';

import { AddToListDropdown } from 'features/ProjectView/Dropdown/AddToListDropdown';

const StyledAddToListDropdown = styled(AddToListDropdown)`
  margin: 1rem 0;
`;

type Props = {
  projectId: string;
  isReadOnly?: boolean;
};

export const ProjectMixTypesTab: React.FC<Props> = ({
  projectId,
  isReadOnly,
}) => {
  const { t } = useTranslation();

  const { fetchProjectMixTypes, assignProjectMixType, createProjectMixType } =
    useProject();

  const {
    loadProjectMixTypes,
    loadMoreProjectMixTypes,
    deleteProjectMixType,
    updateProjectMixTypes,
    updateContractorMixTypesSorting,
    resetContractorMixTypes,
  } = useContractorMixTypes();

  const contractorMixTypesItems = useSelector(
    contractorMixTypesSelectors.contractorMixTypesItems,
  );
  const contractorMixTypesCount = useSelector(
    contractorMixTypesSelectors.contractorMixTypesCount,
  );
  const contractorMixTypesLoaded = useSelector(
    contractorMixTypesSelectors.contractorMixTypesLoaded,
  );
  const loadContractorMixTypesPending = useSelector(
    contractorMixTypesSelectors.loadProjectMixTypesPending,
  );
  const loadMoreContractorMixTypesPending = useSelector(
    contractorMixTypesSelectors.loadMoreProjectMixTypesPending,
  );
  const sorting = useSelector(
    contractorMixTypesSelectors.contractorMixTypesSorting,
  );
  const userType = useSelector(appSelectors.userType);
  const isUserDispatcher = userType === APIUserType.Dispatcher;

  const addToListDropdown = useMemo(() => {
    return (
      <CanAccess forbiddenUserType={APIUserType.Contractor}>
        <StyledAddToListDropdown
          dropdownLabel={t('projectDetails.mixTypes.addToListFieldLabel')}
          fieldName="name"
          loadOptions={fetchProjectMixTypes.bind(null, projectId)}
          addToList={assignProjectMixType.bind(null, projectId)}
          createNewOption={createProjectMixType.bind(null, projectId)}
          successMessage={t('projectDetails.mixTypes.addToListSuccess')}
          onSuccess={() => updateProjectMixTypes(projectId)}
        />
      </CanAccess>
    );
  }, [
    t,
    fetchProjectMixTypes,
    projectId,
    assignProjectMixType,
    createProjectMixType,
    updateProjectMixTypes,
  ]);

  return (
    <ProjectCommonTab
      items={contractorMixTypesItems}
      itemsCount={contractorMixTypesCount}
      itemsLoaded={contractorMixTypesLoaded}
      loadItemsPending={loadContractorMixTypesPending}
      loadMoreItemsPending={loadMoreContractorMixTypesPending}
      sorting={sorting}
      projectId={projectId}
      loadItems={loadProjectMixTypes}
      loadMoreItems={loadMoreProjectMixTypes}
      deleteItem={deleteProjectMixType}
      updateItems={updateProjectMixTypes}
      updateItemsSorting={updateContractorMixTypesSorting}
      resetItems={resetContractorMixTypes}
      successDeleteMessage={t('customers.mixTypes.notification.successDelete')}
      itemNameColumnLabel={t('customers.mixTypes.table.nameColumnLabel')}
      itemCategoriesColumnLabel={t(
        'customers.mixTypes.table.categoryColumnLabel',
      )}
      deleteConfirmationTitle={t('common.areYouSure')}
      deleteConfirmationText={t(
        'customers.projects.mixTypes.table.deleteConfirmationText',
      )}
      tableHeader={t('customers.mixTypes.header')}
      loadMoreButtonLabel={t('customers.mixTypes.table.loadMoreButtonText')}
      emptyStateText={
        isUserDispatcher
          ? t('customers.projects.mixTypes.table.dispatcherEmptyState')
          : t('customers.projects.mixTypes.table.contractorEmptyState')
      }
      // can't delete last mix assigned to the project
      isReadOnly={isReadOnly || contractorMixTypesCount === 1}
      addToListDropdown={addToListDropdown}
    />
  );
};
