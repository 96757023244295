import React, { useCallback, useMemo } from 'react';

import styled from 'styled-components';

import { APIUser, APIUserType } from '@cd3p/core/types/api';
import {
  emailField,
  firstNameField,
  lastNameField,
} from '@cd3p/core/utils/fields';
import { Stack } from '@mui/material';
import { FormField, Modal, Typography } from 'components';
import { useHandleApiResult } from 'hooks/useHandleApiResult';

import {
  FormProvider,
  useForm,
  useSelector,
  useTranslation,
} from 'third-party';

import { useAdminCustomerAccountsList } from 'modules/adminCustomerAccountsList';

import { adminCustomerAccountsList } from 'selectors';

import { EmailSearchField } from 'features/Fields';

import { TypeaheadDataOption } from 'types/app';

const FormWrapper = styled.form`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-height: 0;
  flex-grow: 1;
`;

const Content = styled(Stack)`
  flex-grow: 1;
`;

enum InitiateAccountFormFields {
  FirstName = 'firstName',
  LastName = 'lastName',
  Email = 'email',
}

type InitiateAccountTypeahedEmailFormField =
  TypeaheadDataOption<APIUser> | null;

export type InitiateAccountFormT = {
  [InitiateAccountFormFields.FirstName]: string;
  [InitiateAccountFormFields.LastName]: string;
  [InitiateAccountFormFields.Email]: InitiateAccountTypeahedEmailFormField;
};

type Props = {
  onClosePopup: () => void;
  onSuccess?: (user: APIUser, formValues: InitiateAccountFormT) => void;
  customerId: string;
};

export const InitiateAccountPopup: React.FC<Props> = ({
  onClosePopup,
  onSuccess,
  customerId,
}) => {
  const { t } = useTranslation();
  const { initiateAccount } = useAdminCustomerAccountsList();
  const handleApiResult = useHandleApiResult();
  const isFormPending = useSelector(
    adminCustomerAccountsList.initiateAccountPending,
  );

  const defaultValues: InitiateAccountFormT = useMemo(() => {
    return {
      [InitiateAccountFormFields.FirstName]: '',
      [InitiateAccountFormFields.LastName]: '',
      [InitiateAccountFormFields.Email]: null,
    };
  }, []);

  const formMethods = useForm<InitiateAccountFormT>({
    mode: 'onChange',
    defaultValues: defaultValues,
  });

  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty, errors, touchedFields },
    setValue,
  } = formMethods;

  const emailFieldRules = useMemo(() => {
    return {
      validate: {
        incorrectEmail: (option: TypeaheadDataOption<APIUser> | null) => {
          const optionValue = option?.value;
          return (
            emailField.validate(optionValue || '') ||
            t('inviteCustomer.form.error.emailIncomplete')
          );
        },
      },
    };
  }, [t]);

  const onFormSubmit = useCallback(
    async (data: InitiateAccountFormT) => {
      if (!isValid) {
        return;
      }

      handleApiResult<APIUser>(
        () =>
          initiateAccount(customerId, {
            email: data[InitiateAccountFormFields.Email]?.value,
            firstName: data[InitiateAccountFormFields.FirstName],
            lastName: data[InitiateAccountFormFields.LastName],
          }),
        ({ result, showBaseToast }) => {
          showBaseToast(t('customers.support.initiateAccountSuccess'));
          onClosePopup?.();
          onSuccess?.(result.payload, data);
        },
      );
    },
    [
      customerId,
      handleApiResult,
      initiateAccount,
      isValid,
      onClosePopup,
      onSuccess,
      t,
    ],
  );

  const onEmailChange = (
    selectedOption?: TypeaheadDataOption<APIUser> | null,
  ) => {
    if (selectedOption) {
      setValue(InitiateAccountFormFields.Email, selectedOption, {
        shouldValidate: true,
      });
      if (selectedOption?.data) {
        setValue(
          InitiateAccountFormFields.FirstName,
          selectedOption?.data?.firstName || '',
        );
        setValue(
          InitiateAccountFormFields.LastName,
          selectedOption?.data?.lastName || '',
        );
      }
    }
  };

  return (
    <Modal
      open
      header={t('customers.support.initiateAccountTitle')}
      confirmLabel={t('customers.support.initiateAccountBtn')}
      onConfirm={handleSubmit(onFormSubmit)}
      isDisabled={isFormPending}
      isPending={isFormPending}
      isConfirmDisabled={!isValid || !isDirty}
      onClose={onClosePopup}
      width="50rem"
      contentWrapperStyle={{ overflow: 'visible' }}
    >
      <FormProvider {...formMethods}>
        <FormWrapper onSubmit={handleSubmit(onFormSubmit)}>
          <Content>
            <Typography>
              {t('customers.support.initiateAccountDescription')}
            </Typography>
            <FormField
              fieldName={InitiateAccountFormFields.FirstName}
              label={t('customers.users.form.firstNameLabel')}
              placeholder={t('customers.users.form.firstNamePlaceholder')}
              requiredErrorMessage={t('common.form.emptyFieldError')}
              control={control}
              maxLength={firstNameField.maxLength}
              isRequired
              isDisabled={isFormPending}
              showError={touchedFields[InitiateAccountFormFields.FirstName]}
              fieldError={errors[InitiateAccountFormFields.FirstName]}
            />
            <FormField
              fieldName={InitiateAccountFormFields.LastName}
              label={t('customers.users.form.lastNameLabel')}
              placeholder={t('customers.users.form.lastNamePlaceholder')}
              requiredErrorMessage={t('common.form.emptyFieldError')}
              control={control}
              maxLength={lastNameField.maxLength}
              isRequired
              isDisabled={isFormPending}
              showError={touchedFields[InitiateAccountFormFields.LastName]}
              fieldError={errors[InitiateAccountFormFields.LastName]}
            />
            <EmailSearchField<InitiateAccountFormT>
              isRequired
              isDisabled={isFormPending}
              placeholder={t('customers.users.form.emailPlaceholder')}
              label={t('customers.users.form.emailLabel')}
              fieldName={InitiateAccountFormFields.Email}
              onChange={onEmailChange}
              rules={emailFieldRules}
              maxLength={emailField.maxLength}
              searchUserType={APIUserType.Dispatcher}
            />
          </Content>
        </FormWrapper>
      </FormProvider>
    </Modal>
  );
};
