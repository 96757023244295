import { useMemo } from 'react';

import {
  APISearchSortOrder,
  APISortOrder,
  APIUser,
  APIUserType,
  APIUsersList,
  APIUsersListParams,
} from '@cd3p/core/types/api';
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_START } from '@cd3p/core/utils/lists';
import { httpMethod, simplifyBuilder } from '@cd3p/core/utils/sra';

import { bindActionCreators, useDispatch, useSelector } from 'third-party';

import {
  deleteUserEndpoint,
  resendInvitationEndpoint,
  userEndpoint,
  usersSearchEndpoint,
} from 'constants/endpoints';

import { appSelectors } from 'selectors';

export type ContractorUsersListColumnId = keyof APIUser;

export interface ContractorUsersListState {
  items: APIUsersList['result'];
  pagination: {
    pageNumber: APIUsersList['pageNumber'];
    pageSize: APIUsersList['pageSize'];
  };
  searchSortOrders: APISearchSortOrder<ContractorUsersListColumnId>[];
  count: number;
  contractorUsersLoaded: boolean;
  loadContractorUsersPending: boolean;
  loadMoreContractorUsersPending: boolean;
  deleteContractorUserPending: boolean;
  resendInvitationPending: boolean;
  contractorsErrorText: string | null;
}

export const contractorUsersListState: ContractorUsersListState = {
  items: [],
  pagination: {
    pageNumber: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  },
  searchSortOrders: [
    {
      sortField: 'firstName',
      sortOrder: APISortOrder.ASC,
    },
    {
      sortField: 'lastName',
      sortOrder: APISortOrder.ASC,
    },
  ],
  count: 0,
  contractorUsersLoaded: false,
  loadContractorUsersPending: false,
  loadMoreContractorUsersPending: false,
  deleteContractorUserPending: false,
  resendInvitationPending: false,
  contractorsErrorText: null,
};

const prepareContractorsFetchBody = (body: APIUsersListParams) => ({
  ...body,
  pageNumber: body?.pageNumber || DEFAULT_PAGE_START,
  pageSize: body?.pageSize || DEFAULT_PAGE_SIZE,
  userType: APIUserType.Contractor,
});

const builder = simplifyBuilder(contractorUsersListState, {});

const loadContractorUsers = builder.createServerAction(
  (providerId: string, body: APIUsersListParams) => ({
    name: 'loadContractorUsers',
    url: usersSearchEndpoint(providerId),
    body: prepareContractorsFetchBody(body),
    method: httpMethod.post,
    onSuccess: (state: ContractorUsersListState, payload: APIUsersList) => ({
      items: payload.result,
      contractorUsersLoaded: true,
      pagination: {
        pageNumber: payload.pageNumber,
        pageSize: payload.pageSize,
      },
      count: payload.count,
    }),
  }),
);

const resendInvitation = builder.createServerAction(
  (providerId: string, userId: string) => ({
    name: 'resendInvitation',
    url: resendInvitationEndpoint(providerId, userId),
    method: httpMethod.post,
  }),
);

const loadMoreContractorUsers = builder.createServerAction(
  (providerId: string, body: APIUsersListParams) => ({
    name: 'loadMoreContractorUsers',
    url: usersSearchEndpoint(providerId),
    body: prepareContractorsFetchBody(body),
    method: httpMethod.post,
    onSuccess: (state: ContractorUsersListState, payload: APIUsersList) => ({
      items: [...state.items, ...payload.result],
    }),
  }),
);

const updateContractorUsersListSorting = builder.createReduxAction(
  (searchSortOrders: ContractorUsersListState['searchSortOrders']) => ({
    name: 'updateContractorUsersListSorting',
    updater: () => ({ searchSortOrders }),
  }),
);

const loadContractorUserById = builder.createServerAction(
  (providerId: string, userId: string) => ({
    name: 'loadContractorUserById',
    url: userEndpoint(providerId, userId),
    method: httpMethod.get,
  }),
);

const deleteContractorUser = builder.createServerAction(
  (providerId: string, userId: string) => ({
    name: 'deleteContractorUser',
    url: deleteUserEndpoint(providerId, userId),
    method: httpMethod.delete,
    body: {},
  }),
);

export const useContractorUsersList = () => {
  const dispatch = useDispatch();
  const providerId = useSelector(appSelectors.providerId);

  return useMemo(
    () =>
      bindActionCreators(
        {
          loadContractorUsers: loadContractorUsers.bind(null, providerId),
          loadContractorUserById: loadContractorUserById.bind(null, providerId),
          loadMoreContractorUsers: loadMoreContractorUsers.bind(
            null,
            providerId,
          ),
          deleteContractorUser: deleteContractorUser.bind(null, providerId),
          resendInvitation: resendInvitation.bind(null, providerId),
          // without binding TS doesn't infer type for module functions
          updateContractorUsersListSorting:
            updateContractorUsersListSorting.bind(null),
        },
        dispatch,
      ),
    [dispatch, providerId],
  );
};

export const contractorUsersListReducer = builder.getReducers();
