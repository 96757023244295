import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import styled from 'styled-components';

import { APILocation } from '@cd3p/core/types/api';
import {
  Circle,
  OverlayView,
  OverlayViewF,
  Polygon,
} from '@react-google-maps/api';
import { Map, SvgIcon } from 'components';

import { Marker, MarkerIconType } from './Marker';
import { MarkerTooltip } from './TrackingMap';

import { useTranslation, useUpdateEffect } from 'third-party';

import { MapProps } from 'components/Map/Map';

import {
  GeoCircle,
  createSquareInsideCircle,
  getPolygonMaxDistanceFromCenter,
} from 'utils/geofencingMap';
import { calculateCentroid } from 'utils/map';

import { LocationCoordinates, LocationT } from 'types/app';

import { palette } from 'styles/theme';

interface LatLngLiteral {
  lat: number;
  lng: number;
}

export type UserSettings = {
  zoom?: number;
  center?: LatLngLiteral;
};

export enum GeofenceTypes {
  Polygon = 'polygon',
  Circle = 'circle',
}

export type ChangePolygonOptions = {
  circlePathOptions?: GeoCircle | null;
  polygonPathOptions?: LocationCoordinates[] | null;
  shouldDirty?: boolean;
};

type Props = MapProps & {
  geofencingType?: GeofenceTypes;
  generalPlant?: LocationT;
  onPolygonChange?: (options: ChangePolygonOptions) => void;
  onPolygonValidityChange?: (polygonError: string | null) => void;
  initialCirclePolygonRadius?: number | null;
  initialPolygonPath?: APILocation[] | null;
  initialPlantLocation?: APILocation;
  defaultMapType?: MapTypes;
};

const RemoveVertex = styled.div`
  background: ${props => props.theme.custom.palette.white};
  width: 2.4rem;
  height: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const GEOFENCE_CIRCLE_MIN_RADIUS = 100;
export const GEOFENCE_CIRCLE_MAX_RADIUS = 500;
const POLYGON_MIN_VERTEX = 3;

export enum MapTypes {
  Hybrid = 'hybrid',
  Roadmap = 'roadmap',
}

const polygonStyleOptions: google.maps.PolygonOptions = {
  strokeColor: palette.secondary800,
  strokeOpacity: 1.0,
  strokeWeight: 3.0,
  fillColor: palette.primary200,
  fillOpacity: 0.5,
  zIndex: 2,
};

const invalidPolygonStyleOptions: google.maps.PolygonOptions = {
  strokeColor: palette.error500,
  fillColor: palette.danger400,
};

const circleStyleOptions = {
  strokeColor: palette.info900,
  strokeOpacity: 0.1,
  strokeWeight: 2,
  fillColor: palette.info500,
  fillOpacity: 0.1,
  zIndex: 1,
};

const circleStyleOptionsHybrid = {
  strokeColor: palette.info900,
  strokeOpacity: 0.8,
  fillColor: palette.info900,
  fillOpacity: 0.5,
};

const getPlantCircleOptions = (
  plantPin?: LocationT | null,
  circleRadius?: number | null,
) => {
  return {
    center: {
      lat: plantPin?.latitude || 0,
      lng: plantPin?.longitude || 0,
    },
    radius: circleRadius || GEOFENCE_CIRCLE_MAX_RADIUS,
  };
};

const transformGooglePolygonPathToLocationPath = (
  polygonPath: google.maps.MVCArray<google.maps.LatLng>,
) => {
  return polygonPath.getArray().map((latLng: any) => {
    return { lat: latLng.lat(), lng: latLng.lng() };
  });
};

const PlantMarker = ({
  markerId,
  mapType,
  name,
  address,
  longitude,
  latitude,
  zIndex,
}: {
  markerId: string;
  mapType: MapTypes;
  name?: string | null;
  address?: string | null;
  latitude?: number;
  longitude?: number;
  zIndex?: number;
}) => {
  return (
    <Marker
      key={markerId}
      id={markerId}
      icon={
        mapType === MapTypes.Hybrid
          ? MarkerIconType.PlantSatellite
          : MarkerIconType.Plant
      }
      iconHover={
        mapType === MapTypes.Hybrid
          ? MarkerIconType.PlantSatelliteHover
          : MarkerIconType.PlantHover
      }
      tooltip={<MarkerTooltip name={name || ''} address={address || ''} />}
      lat={latitude}
      lng={longitude}
      width="3.8rem"
      height="3.8rem"
      zIndex={zIndex}
    />
  );
};

export const GeoFencingMap: React.FC<Props> = ({
  geofencingType,
  generalPlant,
  onPolygonChange,
  onPolygonValidityChange,
  initialPolygonPath,
  initialCirclePolygonRadius,
  initialPlantLocation,
  defaultMapType = MapTypes.Hybrid,
  ...props
}) => {
  const { t } = useTranslation();
  const [activeVertex, setActiveVertex] = useState(null);
  const [currentMapType, setCurrentMapType] = useState(defaultMapType);

  // Store Polygon path in state
  const [polygonPath, setPath] = useState<LatLngLiteral[]>([]);
  const [isPolygonInvalid, setIsPolygonInvalid] = useState(false);
  const [generalPlantCircle, setGeneralPlantCircle] =
    useState<GeoCircle | null>(null);

  const polygonRef = useRef<google.maps.Polygon | null>(null);
  const listenersRef = useRef<any>([]);
  const circleInstanceRef = useRef<google.maps.Circle | null>(null);

  const currentPolygonVertexLength =
    polygonRef?.current?.getPath().getLength() || 0;
  const isInitialGeneralPlant = useMemo(() => {
    return (
      generalPlant &&
      generalPlant.latitude === initialPlantLocation?.latitude &&
      generalPlant.longitude === initialPlantLocation?.longitude
    );
  }, [generalPlant, initialPlantLocation]);

  useEffect(() => {
    // change circle path in state if plant updated
    if (generalPlant) {
      const circleOptions = getPlantCircleOptions(
        generalPlant,
        generalPlantCircle?.radius || initialCirclePolygonRadius,
      );
      setGeneralPlantCircle(circleOptions);
    }
  }, [initialCirclePolygonRadius, generalPlant, generalPlantCircle?.radius]);

  useEffect(() => {
    // change polygon path in state if plant updated
    // check if plant is already existed with preselected polygon to render default or existed polygon path
    if (generalPlant) {
      if (isInitialGeneralPlant && initialPolygonPath?.length) {
        setPath(
          initialPolygonPath.map(item => ({
            lat: item.latitude,
            lng: item.longitude,
          })),
        );
      } else {
        const nextPath = createSquareInsideCircle(
          { lat: generalPlant.latitude, lng: generalPlant.longitude },
          GEOFENCE_CIRCLE_MAX_RADIUS,
        );
        setPath(nextPath);
        onPolygonChange?.({ polygonPathOptions: nextPath, shouldDirty: false });
      }
    }
  }, [
    initialPolygonPath,
    generalPlant,
    isInitialGeneralPlant,
    onPolygonChange,
  ]);

  useEffect(() => {
    // change circle path in state if plant updated
    if (generalPlant && polygonPath.length == 0) {
      const circleOptions = getPlantCircleOptions(
        generalPlant,
        initialCirclePolygonRadius,
      );
      setGeneralPlantCircle(circleOptions);
    }

    if (polygonPath.length > 0) {
      setGeneralPlantCircle({
        center: calculateCentroid(polygonPath),
        radius: generalPlantCircle?.radius || initialCirclePolygonRadius || 0,
      });
    }
  }, [
    initialCirclePolygonRadius,
    generalPlant,
    polygonPath,
    generalPlantCircle?.radius,
  ]);

  useEffect(() => {
    // reset active vertex on plant/geofencingType change
    setActiveVertex(null);
  }, [geofencingType, generalPlant]);

  const onEdit = useCallback(
    (event: any) => {
      if (event.vertex != null) {
        setActiveVertex(event.vertex);
      } else {
        setActiveVertex(null);
      }
      if (polygonRef.current) {
        const nextPath = transformGooglePolygonPathToLocationPath(
          polygonRef?.current.getPath(),
        );
        setPath(nextPath);
        onPolygonChange?.({ polygonPathOptions: nextPath, shouldDirty: true });
      }
    },
    [onPolygonChange],
  );

  useUpdateEffect(() => {
    // check if the polygon vertex location is in accepted zone (circle)
    let polygonError = null;
    if (
      generalPlantCircle &&
      getPolygonMaxDistanceFromCenter(polygonPath, generalPlantCircle?.center) >
        GEOFENCE_CIRCLE_MAX_RADIUS
    ) {
      polygonError = t('plantSettings.editForm.error.polygonExceedsCircle');
    }
    onPolygonValidityChange?.(polygonError);
    setIsPolygonInvalid(!!polygonError);
  }, [polygonPath, generalPlantCircle, t]);

  const onLoad = useCallback(
    (polygon: google.maps.Polygon) => {
      polygonRef.current = polygon;
      const path = polygon.getPath();

      listenersRef.current.push(
        path.addListener('set_at', onEdit),
        path.addListener('insert_at', onEdit),
        path.addListener('remove_at', onEdit),
      );
      onPolygonChange?.({
        polygonPathOptions: transformGooglePolygonPathToLocationPath(path),
        shouldDirty: false,
      });
    },
    [onEdit, onPolygonChange],
  );

  // Clean up refs
  const onUnmount = useCallback(() => {
    listenersRef.current.forEach((lis: any) => lis.remove());
    polygonRef.current = null;
  }, []);

  const onGeneralPlantCircleChange = useCallback(() => {
    if (
      !generalPlantCircle ||
      !circleInstanceRef.current?.getRadius() ||
      generalPlantCircle.radius === circleInstanceRef.current?.getRadius()
    ) {
      return;
    }
    // handle change minimum and maximum circle radius
    let nextRadius = circleInstanceRef.current?.getRadius();
    if (circleInstanceRef.current?.getRadius() > GEOFENCE_CIRCLE_MAX_RADIUS) {
      circleInstanceRef.current?.setRadius(GEOFENCE_CIRCLE_MAX_RADIUS);
      nextRadius = GEOFENCE_CIRCLE_MAX_RADIUS;
    } else if (
      circleInstanceRef.current?.getRadius() < GEOFENCE_CIRCLE_MIN_RADIUS
    ) {
      circleInstanceRef.current?.setRadius(GEOFENCE_CIRCLE_MIN_RADIUS);
      nextRadius = GEOFENCE_CIRCLE_MIN_RADIUS;
    }
    const roundedNextRadius = Math.round(nextRadius);
    setGeneralPlantCircle({
      ...generalPlantCircle,
      radius: roundedNextRadius,
    });
    if (roundedNextRadius !== generalPlantCircle.radius) {
      onPolygonChange?.({
        circlePathOptions: {
          ...generalPlantCircle,
          radius: roundedNextRadius,
        },
      });
    }
  }, [generalPlantCircle, onPolygonChange]);

  const onCircleLoad = useCallback((circle: google.maps.Circle) => {
    circleInstanceRef.current = circle;
  }, []);

  const handlePolygonVertexRemove = useCallback(() => {
    const newPath = polygonPath.filter((i, index) => index != activeVertex);
    setPath(newPath);
    setActiveVertex(null);
    onPolygonChange?.({
      polygonPathOptions: newPath,
    });
  }, [activeVertex, onPolygonChange, polygonPath]);

  const markers = useMemo(
    () => [...(generalPlant ? [generalPlant] : [])],
    [generalPlant],
  );

  const polygonStyle = useMemo(
    () => ({
      ...polygonStyleOptions,
      ...(isPolygonInvalid && invalidPolygonStyleOptions),
    }),
    [isPolygonInvalid],
  );

  const circleStyle = useMemo(
    () => ({
      ...circleStyleOptions,
      ...(currentMapType === MapTypes.Hybrid && circleStyleOptionsHybrid),
    }),
    [currentMapType],
  );

  const onCircleDragEnd = useCallback(() => {
    if (circleInstanceRef.current) {
      const center = circleInstanceRef.current.getCenter();
      if (center) {
        const newCircle = {
          center: {
            lat: center.lat(),
            lng: center.lng(),
          },
          radius: circleInstanceRef.current.getRadius(),
        };
        setGeneralPlantCircle(newCircle);
        onPolygonChange?.({
          circlePathOptions: newCircle,
        });
      }
    }
  }, [onPolygonChange]);

  return (
    <Map
      defaultMapType={currentMapType}
      markers={markers}
      onMapTypeChanged={setCurrentMapType}
      {...props}
    >
      {generalPlant && (
        <PlantMarker
          key={`${generalPlant.id}-${currentMapType}`}
          markerId={`${generalPlant.id}-${currentMapType}`}
          name={generalPlant.name}
          address={generalPlant.address}
          latitude={generalPlantCircle?.center?.lat}
          longitude={generalPlantCircle?.center?.lng}
          mapType={currentMapType}
          zIndex={2}
        />
      )}
      {generalPlant && geofencingType === GeofenceTypes.Polygon && (
        <>
          <Polygon
            editable
            draggable
            path={polygonPath}
            options={polygonStyle}
            onMouseUp={onEdit}
            onDragEnd={onEdit}
            onLoad={onLoad}
            onUnmount={onUnmount}
          />
          <Circle
            center={generalPlantCircle?.center}
            radius={GEOFENCE_CIRCLE_MAX_RADIUS}
            options={circleStyle}
          />
        </>
      )}
      {generalPlant && geofencingType === GeofenceTypes.Circle && (
        <Circle
          editable
          draggable
          center={generalPlantCircle?.center}
          onDragEnd={onCircleDragEnd}
          onLoad={onCircleLoad}
          onRadiusChanged={onGeneralPlantCircleChange}
          radius={generalPlantCircle?.radius}
          options={{
            strokeColor: palette.info900,
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: palette.info500,
            fillOpacity: 0.5,
            zIndex: 1,
          }}
        />
      )}
      {activeVertex != null &&
        currentPolygonVertexLength > POLYGON_MIN_VERTEX && (
          <OverlayViewF
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            position={polygonPath[activeVertex]}
          >
            <RemoveVertex onClick={handlePolygonVertexRemove}>
              <SvgIcon width="20px" height="20px" icon="remove-icon" />
            </RemoveVertex>
          </OverlayViewF>
        )}
    </Map>
  );
};
