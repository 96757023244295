import { useMemo } from 'react';

import { SortOrder } from '@cd3p/core/constants/common';
import {
  timezonesEndpoint,
  truckLocationHistoryEndpoint,
  trucksEndpoint,
} from '@cd3p/core/constants/endpoints';
import {
  CoreCacheState,
  coreCacheMethods,
  coreCacheState,
} from '@cd3p/core/modules/cache';
import { coreUsersListMethods } from '@cd3p/core/modules/usersList';
import {
  APICompanyRequest,
  APIGuidTypeaheadRequestModel,
  APILocationHistoryListParams,
  APIPlant,
  APIPlantsList,
  APIProjectTypeaheadRequestModel,
  APISortOrder,
  APITimeZones,
  APITruck,
  APITrucksList,
  APITypeaheadRequestModel,
  APIUser,
  APIUserType,
  APIUsersList,
} from '@cd3p/core/types/api';
import { httpMethod, simplifyBuilder } from '@cd3p/core/utils/sra';

import { bindActionCreators, useDispatch, useSelector } from 'third-party';

import { MAX_ITEMS_AMOUNT, TYPEAHEAD_OPTIONS_COUNT } from 'constants/common';
import {
  addressTypeaheadEndpoint,
  companyEndpoint,
  createDataCategoryEndpoint,
  driversEndpoint,
  getPlantUrl,
  loadPlantsEndpoint,
  plantsEndpoint,
  removePlantUrl,
  typeaheadEndpoint,
  usersSearchEndpoint,
} from 'constants/endpoints';

import { appSelectors } from 'selectors';

export type CacheState = CoreCacheState & {
  plants: APIPlant[];
  trucks: APITruck[];
  drivers: APIUser[];
  timezones: APITimeZones[];
  plantDetails: APIPlant | null;
  plantsLoaded: boolean;
  loadPlantsPending: boolean;
  updatePlantPending: boolean;
  removePlantPending: boolean;
  createPlantPending: boolean;
  loadTrucksPending: boolean;
  loadDriversPending: boolean;
  createCompanyPending: boolean;
  createDataCategoryPending: boolean;
};

export const cacheState: CacheState = {
  ...coreCacheState,
  plants: [],
  trucks: [],
  drivers: [],
  timezones: [],
  plantDetails: null,
  plantsLoaded: false,
  loadPlantsPending: false,
  updatePlantPending: false,
  removePlantPending: false,
  createPlantPending: false,
  loadTrucksPending: false,
  loadDriversPending: false,
  createCompanyPending: false,
  createDataCategoryPending: false,
};

const builder = simplifyBuilder(cacheState, {});

const createPlant = builder.createServerAction(
  (providerId: string, plant: Partial<APIPlant>) => ({
    name: 'createPlant',
    url: plantsEndpoint(providerId),
    method: httpMethod.post,
    body: plant,
  }),
);

const loadPlant = builder.createServerAction(
  (providerId: string, plantId: string) => ({
    name: 'loadPlant',
    url: getPlantUrl(providerId, plantId),
    method: httpMethod.get,
    onSuccess: (state: CacheState, payload: APIPlant) => ({
      plantDetails: payload,
    }),
  }),
);

const updatePlant = builder.createServerAction(
  (providerId: string, plantId: string, plant: Partial<APIPlant>) => ({
    name: 'updatePlant',
    url: getPlantUrl(providerId, plantId),
    method: httpMethod.put,
    body: plant,
    onSuccess: (state: CacheState, payload: APIPlant) => ({
      plants: state.plants.map(it => (it.id == payload.id ? payload : it)),
    }),
  }),
);

const removePlant = builder.createServerAction(
  (providerId: string, plantId: string) => ({
    name: 'removePlant',
    url: removePlantUrl(providerId, plantId),
    method: httpMethod.delete,
    onSuccess: (state: CacheState, payload: APIPlant) => ({
      plants: state.plants.filter(it => it.id !== payload.id),
    }),
  }),
);

const resetSelectedPlant = builder.createReduxAction(() => ({
  name: 'resetSelectedPlant',
  updater: state => ({
    ...state,
    plantDetails: null,
  }),
}));

const loadPlants = builder.createServerAction(
  (providerId: string, name = '') => ({
    name: 'loadPlants',
    url: loadPlantsEndpoint(providerId),
    body: {
      searchSortOrders: [{ sortField: 'name', sortOrder: SortOrder.ASC, name }],
      pageNumber: 1,
      pageSize: MAX_ITEMS_AMOUNT, // load all plants
    },
    method: httpMethod.post,
    onSuccess: (state: CacheState, payload: APIPlantsList) => ({
      plants: payload.result,
      plantsLoaded: true,
    }),
  }),
);

const loadTrucks = builder.createServerAction(
  (providerId: string, body = { hasNoTickets: false }) => ({
    name: 'loadTrucks',
    url: trucksEndpoint(providerId),
    body: {
      pageNumber: 1,
      pageSize: MAX_ITEMS_AMOUNT, // load all trucks
      searchSortOrders: [
        { sortField: 'truckNumber', sortOrder: SortOrder.ASC },
      ],
      ...body,
    },
    method: httpMethod.post,
    onSuccess: (state: CacheState, payload: APITrucksList) => ({
      trucks: payload.result,
    }),
  }),
);

const loadTrucksLocationHistory = builder.createServerAction(
  (providerId: string, body: APILocationHistoryListParams) => ({
    name: 'loadTrucksLocationHistory',
    url: truckLocationHistoryEndpoint(providerId),
    body: {
      pageNumber: 1,
      pageSize: MAX_ITEMS_AMOUNT,
      searchSortOrders: [
        {
          sortField: 'id',
          sortOrder: APISortOrder.DESC,
        },
      ],
      ...body,
    },
    method: httpMethod.post,
    // onSuccess: we don't put anything to store, just return result for history
  }),
);

const loadDriverUsers = builder.createServerAction((providerId: string) => ({
  name: 'loadDrivers',
  url: usersSearchEndpoint(providerId),
  body: {
    pageNumber: 1,
    userType: APIUserType.Driver,
    pageSize: MAX_ITEMS_AMOUNT, // load all drivers
  },
  method: httpMethod.post,
  onSuccess: (state: CacheState, payload: APIUsersList) => ({
    drivers: payload.result,
  }),
}));

const loadDrivers = builder.createServerAction((providerId: string) => ({
  name: 'loadDrivers',
  url: driversEndpoint(providerId),
  body: {
    pageNumber: 1,
    pageSize: MAX_ITEMS_AMOUNT, // load all drivers
  },
  method: httpMethod.post,
  onSuccess: (state: CacheState, payload: APIUsersList) => ({
    drivers: payload.result,
  }),
}));

const loadTimezones = builder.createServerAction(
  (providerId: string, countryCode?: string) => ({
    name: 'loadTimezones',
    url: timezonesEndpoint(providerId),
    method: httpMethod.get,
    params: {
      countryCode,
    },
    onSuccess: (state: CacheState, payload: APITimeZones[]) => ({
      timezones: payload,
    }),
  }),
);

const loadCustomers = builder.createServerAction(
  (
    providerId: string,
    query: string,
    body: Partial<APIGuidTypeaheadRequestModel> = {},
  ) => ({
    name: 'loadCustomers',
    url: typeaheadEndpoint(providerId, 'company'),
    body: {
      query,
      ...body,
    },
    method: httpMethod.post,
    // onSuccess: we don't put anything to store, just return result for typeahead
  }),
);

const loadUsersEmails = builder.createServerAction(
  (
    providerId: string,
    query: string,
    userType: APIUserType = APIUserType.Contractor,
  ) => ({
    name: 'loadUsersEmails',
    url: usersSearchEndpoint(providerId),
    body: {
      email: query,
      userType: userType,
      pageNumber: 1,
      pageSize: MAX_ITEMS_AMOUNT,
      crossProviderSearch: true,
    },
    method: httpMethod.post,
    // onSuccess: we don't put anything to store, just return result for typeahead
  }),
);

const createCompany = builder.createServerAction(
  (providerId: string, body: APICompanyRequest) => ({
    name: 'createCompany',
    url: companyEndpoint(providerId),
    body,
    method: httpMethod.post,
  }),
);

const loadProjects = builder.createServerAction(
  (
    providerId: string,
    query: string,
    body: Partial<APIProjectTypeaheadRequestModel> = {},
  ) => ({
    name: 'loadProjects',
    url: typeaheadEndpoint(providerId, 'project'),
    body: {
      query,
      ...body,
    },
    method: httpMethod.post,
    // onSuccess: we don't put anything to store, just return result for typeahead
  }),
);

const loadOrderIds = builder.createServerAction(
  (
    providerId: string,
    query: string,
    body: Partial<APITypeaheadRequestModel> = {},
  ) => ({
    name: 'loadOrderIds',
    url: typeaheadEndpoint(providerId, 'order'),
    body: {
      query,
      ...body,
    },
    method: httpMethod.post,
    // onSuccess: we don't put anything to store, just return result for typeahead
  }),
);

const loadAddress = builder.createServerAction(
  (providerId: string, query: string) => ({
    name: 'loadAddress',
    url: addressTypeaheadEndpoint(providerId),
    params: {
      address: query,
    },
    method: httpMethod.get,
    // onSuccess: we don't put anything to store, just return result for typeahead
  }),
);

export const createDataCategory = builder.createServerAction(
  (providerId: string, dataType: 'MixType' | 'Additive', name: string) => ({
    name: 'createDataCategory',
    url: createDataCategoryEndpoint(providerId),
    params: {
      type: dataType,
    },
    body: {
      name,
    },
    method: httpMethod.post,
  }),
);

export const loadDataCategories = builder.createServerAction(
  (
    providerId: string,
    dataType: 'MixType' | 'Additive',
    query: string,
    excludedIds: number[] = [],
  ) => ({
    name: 'loadDataCategories',
    url: typeaheadEndpoint(providerId, 'data-category'),
    params: {
      type: dataType,
    },
    body: {
      query,
      excludedIds,
      count: TYPEAHEAD_OPTIONS_COUNT,
    },
    method: httpMethod.post,
  }),
);

const loadAddressLocation = builder.createServerAction(
  coreCacheMethods.loadAddressLocation,
);

const loadAddressByLocation = builder.createServerAction(
  coreCacheMethods.loadAddressByLocation,
);

const loadUsersTypeahead = builder.createServerAction(
  coreUsersListMethods.loadUsersTypeahead,
);

const loadMapPlaces = builder.createServerAction(
  coreCacheMethods.loadMapPlaces,
);

const loadNearbyLocations = builder.createServerAction(
  coreCacheMethods.loadNearbyLocations,
);

export const useCache = () => {
  const dispatch = useDispatch();
  const providerId = useSelector(appSelectors.providerId);

  return useMemo(
    () =>
      bindActionCreators(
        {
          loadCustomers: loadCustomers.bind(null, providerId),
          loadUsersEmails: loadUsersEmails.bind(null, providerId),
          createCompany: createCompany.bind(null, providerId),
          loadProjects: loadProjects.bind(null, providerId),
          loadAddress: loadAddress.bind(null, providerId),
          loadMapPlaces: loadMapPlaces.bind(null, providerId),
          loadNearbyLocations: loadNearbyLocations.bind(null, providerId),
          loadAddressLocation: loadAddressLocation.bind(null, providerId),
          loadAddressByLocation: loadAddressByLocation.bind(null, providerId),
          loadOrderIds: loadOrderIds.bind(null, providerId),
          loadPlants: loadPlants.bind(null, providerId),
          loadTrucks: loadTrucks.bind(null, providerId),
          loadTrucksLocationHistory: loadTrucksLocationHistory.bind(
            null,
            providerId,
          ),
          loadDrivers: loadDrivers.bind(null, providerId),
          loadTimezones: loadTimezones.bind(null, providerId),
          updatePlant: updatePlant.bind(null, providerId),
          removePlant: removePlant.bind(null, providerId),
          createPlant: createPlant.bind(null, providerId),
          loadPlant: loadPlant.bind(null, providerId),
          resetSelectedPlant: resetSelectedPlant.bind(null),
          loadDriverUsers: loadDriverUsers.bind(null, providerId),
          loadMixTypeCategories: loadDataCategories.bind(
            null,
            providerId,
            'MixType',
          ),
          createMixTypeCategory: createDataCategory.bind(
            null,
            providerId,
            'MixType',
          ),
          loadAdditiveTypeCategories: loadDataCategories.bind(
            null,
            providerId,
            'Additive',
          ),
          createAdditiveTypeCategory: createDataCategory.bind(
            null,
            providerId,
            'Additive',
          ),
          loadUsersTypeahead: loadUsersTypeahead.bind(null, providerId),
        },
        dispatch,
      ),
    [dispatch, providerId],
  );
};

export const cacheReducer = builder.getReducers();
