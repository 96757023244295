import {
  getAttachmentEndpoint,
  getAttachmentsEndpoint,
  getChatEndpoint,
  getChatMessagesEndpoint,
  getMarkDialogAsReadByContractorEndpoint,
  getMarkDialogAsReadByDispatcherEndpoint,
} from '../constants/endpoints';
import {
  APIAttachment,
  APIChatMessageModel,
  APIChatMessageModelContinueSearchResult,
  APIDialogModel,
  APIDialogModelContinueSearchResult,
} from '../types/api';
import { chatMessagesSortingFunc } from '../utils/chat';
import { httpMethod, simplifyBuilder } from '../utils/sra';

export type ImageServerError = {
  error: true;
  status: number;
};

export type ServerAttachmentData = {
  error: false;
} & APIAttachment;

export type DialogMessagesAttachment = ServerAttachmentData | ImageServerError;

export interface ChatState {
  dialogsToken: string | null | undefined;
  dialogs: APIDialogModel[];
  dialogMessagesToken: string | null | undefined;
  dialogMessages: APIChatMessageModel[];
  dialogMessagesAttachments: {
    [key: string]: DialogMessagesAttachment;
  };

  currentDialog: APIDialogModel | null;
  isDialogMessagesLoaded: boolean;
  loadProviderDialogsPending: boolean;
  loadDialogMessagesPending: boolean;
  sendDialogMessagePending: boolean;
  unreadOrderIds: Set<number>;
}

export const chatState: ChatState = {
  // dialogToken and dialogMessagesToken are showing if there are more dialogs or messages to load
  dialogsToken: '',
  dialogs: [],
  dialogMessagesToken: '',
  dialogMessages: [],
  dialogMessagesAttachments: {},
  currentDialog: null,
  isDialogMessagesLoaded: false,
  loadProviderDialogsPending: false,
  loadDialogMessagesPending: false,
  sendDialogMessagePending: false,
  unreadOrderIds: new Set(),
};

export const chatBuilder = simplifyBuilder(chatState, {});

export const loadProviderDialogs = chatBuilder.createServerAction(
  (providerId: string, token?: string | null) => ({
    name: 'loadProviderDialogs',
    url: getChatEndpoint(providerId),
    method: httpMethod.get,
    params: {
      token,
    },
    onSuccess: (
      state: ChatState,
      payload: APIDialogModelContinueSearchResult,
    ) => ({
      dialogs: token?.length
        ? [...state.dialogs, ...(payload.result ?? [])]
        : payload.result ?? [],
      dialogsToken: payload.token,
    }),
  }),
);

export const LOAD_DIALOG_MESSAGES_ACTION = 'loadDialogMessages';
export const loadDialogMessages = chatBuilder.createServerAction(
  (providerId: string, orderId: number, token?: string | null) => ({
    name: LOAD_DIALOG_MESSAGES_ACTION,
    url: getChatMessagesEndpoint(providerId, orderId),
    method: httpMethod.get,
    params: {
      token,
    },
    onSuccess: (
      state: ChatState,
      payload: APIChatMessageModelContinueSearchResult,
    ) => ({
      dialogMessages: (token?.length
        ? [...state.dialogMessages, ...(payload.result ?? [])]
        : payload.result || []
      ).sort(chatMessagesSortingFunc),
      dialogMessagesToken: payload.token,
      isDialogMessagesLoaded: true,
    }),
  }),
);

export const loadDialogByOrderId = chatBuilder.createServerAction(
  (providerId: string, orderId: number) => ({
    name: 'loadDialogByOrderId',
    url: getChatEndpoint(providerId, orderId),
    method: httpMethod.get,
    onSuccess: (_state: ChatState, payload: APIDialogModel) => ({
      currentDialog: payload,
    }),
  }),
);

export const resetCurrentDialog = chatBuilder.createReduxAction(() => ({
  name: 'resetCurrentDialog',
  updater: () => ({
    currentDialog: null,
  }),
}));

export const sendDialogMessage = chatBuilder.createServerAction(
  (
    providerId: string,
    orderId: number,
    message: string,
    attachmentIds?: string[],
  ) => ({
    name: 'sendDialogMessage',
    url: getChatMessagesEndpoint(providerId, orderId),
    method: httpMethod.post,
    body: {
      message,
      attachmentIds,
    },
  }),
);

export const markDialogAsReadByContractor = chatBuilder.createServerAction(
  (providerId: string, orderId: number) => ({
    name: 'markDialogAsReadByContractor',
    url: getMarkDialogAsReadByContractorEndpoint(providerId, orderId),
    method: httpMethod.post,
  }),
);

export const markDialogAsReadByDispatcher = chatBuilder.createServerAction(
  (providerId: string, orderId: number, markAsRead = true) => ({
    name: 'markDialogAsReadByDispatcher',
    url: getMarkDialogAsReadByDispatcherEndpoint(providerId, orderId),
    body: {
      markAsRead,
    },
    method: httpMethod.post,
  }),
);

export const uploadAttachment = chatBuilder.createServerAction(
  (providerId: string, formData: FormData) => ({
    name: 'uploadAttachment',
    url: getAttachmentEndpoint(providerId),
    body: formData,
    method: httpMethod.post,
  }),
);

export const ADD_DIALOG_MESSAGE_ACTION = 'addDialogMessage';
export const addDialogMessage = chatBuilder.createReduxAction(
  (message: APIChatMessageModel) => ({
    name: ADD_DIALOG_MESSAGE_ACTION,
    updater: (state) => ({
      dialogMessages: [...state.dialogMessages, message].sort(
        chatMessagesSortingFunc,
      ),
    }),
  }),
);

export const resetDialogMessages = chatBuilder.createReduxAction(() => ({
  name: 'resetDialogMessages',
  updater: () => ({
    dialogMessages: [],
    isDialogMessagesLoaded: false,
  }),
}));

export const addUnreadOrderId = chatBuilder.createReduxAction(
  (orderId: number) => ({
    name: 'addUnreadOrderId',
    updater: (state) => ({
      unreadOrderIds: new Set([...Array.from(state.unreadOrderIds), orderId]),
    }),
  }),
);

export const removeUnreadOrderId = chatBuilder.createReduxAction(
  (orderId: number) => ({
    name: 'removeUnreadOrderId',
    updater: (state) => {
      const unreadOrderIds = new Set(state.unreadOrderIds);
      unreadOrderIds.delete(orderId);
      return {
        unreadOrderIds,
      };
    },
  }),
);

export const loadMultipleDialogAttachmentsByIds =
  chatBuilder.createServerAction(
    (providerId: string, attachmentIds: string[]) => ({
      name: 'loadMultipleDialogAttachmentsByIds',
      url: getAttachmentsEndpoint(providerId),
      method: httpMethod.post,
      body: {
        attachmentIds,
      },
      onRequest: (_state: ChatState) => ({
        dialogMessagesAttachments: {
          ..._state.dialogMessagesAttachments,
          ...attachmentIds.reduce(
            (result, id) => ({
              ...result,
              [id]: _state.dialogMessagesAttachments[id]?.error
                ? null
                : _state.dialogMessagesAttachments[id], // reset to null before downloading attachment
            }),
            {},
          ),
        },
      }),
      onSuccess: (_state: ChatState, payload: APIAttachment[]) => ({
        dialogMessagesAttachments: {
          ..._state.dialogMessagesAttachments,
          ...attachmentIds.reduce((result, id) => {
            const serverData = payload.find((it) => it.id === id);
            return {
              ...result,
              [id]: serverData
                ? { error: false, ...serverData }
                : { error: true, status: 404 },
            };
          }, {}),
        },
      }),
      onFailure: (_state: ChatState) => ({
        dialogMessagesAttachments: {
          ..._state.dialogMessagesAttachments,
          ...attachmentIds.reduce((result, id) => {
            return {
              ...result,
              [id]: { error: true },
            };
          }, {}),
        },
      }),
    }),
  );

export const updateDialogAttachmentById = chatBuilder.createReduxAction(
  (attachmentId: string, attachmentData: any) => ({
    name: 'updateDialogAttachmentById',
    updater: (_state: ChatState) => ({
      dialogMessagesAttachments: {
        ..._state.dialogMessagesAttachments,
        [attachmentId]: {
          ..._state.dialogMessagesAttachments[attachmentId],
          ...attachmentData,
        },
      },
    }),
  }),
);
