import _ from 'lodash';
import randomColor from 'randomcolor';

import {
  APIUser,
  APIUserRequest,
  APIUserStatus,
  APIUserType,
} from '../types/api';

export const DEFAULT_USERS_LIST_PAGE_SIZE = 20;

export function mapUserToCreateUserRequest({
  user,
  providerId,
  customerId,
  providerIds,
}: {
  user: Partial<APIUser>;
  providerId: string;
  customerId?: string;
  providerIds?: string[];
}): APIUserRequest {
  const userProviderIds = providerIds?.length ? providerIds : [providerId];
  return {
    ...user,
    userType: user?.userType ? user.userType : APIUserType.Contractor,
    companyIds: customerId ? [customerId] : [],
    // BE requires this parameter, otherwise user will be unassigned from company
    providerIds: user.providers?.length
      ? user.providers.map((provider) => String(provider.id))
      : userProviderIds,
  };
}

export function mapUserToUpdateUserRequest(
  user: Partial<APIUser> & {
    password?: string;
    hasAccessToMobileApp?: boolean;
    shouldVerifyCompanyInfo?: boolean;
  },
  providerId: string,
  providerIds?: string[],
): APIUserRequest {
  const userProviderIds = providerIds?.length ? providerIds : [providerId];
  return {
    ...user,
    ...(_.isUndefined(user?.shouldVerifyCompanyInfo) &&
    !_.isNil(user?.userOptions?.shouldVerifyCompanyInfo)
      ? {
          shouldVerifyCompanyInfo: JSON.parse(
            user?.userOptions?.shouldVerifyCompanyInfo || '',
          ),
        }
      : {}),
    ...(_.isUndefined(user?.hasAccessToMobileApp) &&
    !_.isNil(user?.userOptions?.hasAccessToMobileApp)
      ? {
          hasAccessToMobileApp: JSON.parse(
            user?.userOptions?.hasAccessToMobileApp || '',
          ),
        }
      : {}),
    // BE requires this parameter, otherwise user will be unassigned from company
    companyIds: (user.companies || []).map((company) => String(company.id)),
    providerIds: user.providers?.length
      ? user.providers.map((provider) => String(provider.id))
      : userProviderIds,
  };
}

export const isUserPending = (user: APIUser): boolean =>
  user.userStatus === APIUserStatus.NotActive ||
  user.userStatus === APIUserStatus.Incomplete;

export const getUserTwoSymbolsName = (
  firstName?: string,
  lastName?: string,
) => {
  return `${(firstName || '').substring(0, 1).toUpperCase()}${(lastName || '')
    .substring(0, 1)
    .toUpperCase()}`;
};

export const getColorForUserAvatar = (userId?: number | string) => {
  return randomColor({
    luminosity: 'light',
    ...(userId && { seed: userId }),
  });
};
