export enum APP_EVENTS {
  CLOSE_ORDER = 'close_order',
  UPDATE_ACTIVE_TICKETS = 'update_active_tickets',
  CLOSE_OPENED_TICKET = 'close_opened_ticket',
  UPDATE_PIN_LOCATION = 'update_pin_location',
  UPDATE_ORDER_IN_LIST = 'update_order_in_list',
  UPDATE_COMPANY = 'update_company',
  CHANGE_PROVIDER = 'change_provider',
  AUTOCENTER_MAP = 'autocenter_map',
}
