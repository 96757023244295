import { useEffect } from 'react';

import { USER_IS_NOT_AUTHORIZED_ACTION } from '@cd3p/core/constants/redux';
import { subscribeActionsBefore } from '@cd3p/core/utils/redux';

import { useLocation, useMatch, useNavigate } from 'third-party';

import {
  ONBOARDING_STEPS,
  forgotPasswordUrl,
  loginUrl,
  onboardingStepUrl,
  onboardingUrl,
  resetPasswordUrl,
} from 'constants/url';

import { isUserAuthenticated, useLogOutUser } from 'utils/auth';

export const useUserNotAuthorized = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const logOutUser = useLogOutUser();
  const isUserLogged = isUserAuthenticated();

  const matchForgotPasswordUrl = useMatch(forgotPasswordUrl());
  const matchResetPasswordUrl = useMatch(resetPasswordUrl());

  const matchOnboardingUrl = useMatch(onboardingUrl());
  const matchOnboardingStep1Url = useMatch(
    onboardingStepUrl(ONBOARDING_STEPS.Step1),
  );
  const matchOnboardingStep2Url = useMatch(
    onboardingStepUrl(ONBOARDING_STEPS.Step2),
  );

  const isOnboardingPage =
    matchOnboardingUrl || matchOnboardingStep1Url || matchOnboardingStep2Url;

  useEffect(() => {
    if (
      !isUserLogged &&
      !isOnboardingPage &&
      !matchForgotPasswordUrl &&
      !matchResetPasswordUrl
    ) {
      navigate(loginUrl(), { replace: true });
    }
  }, [
    isOnboardingPage,
    isUserLogged,
    location.pathname,
    matchForgotPasswordUrl,
    matchResetPasswordUrl,
    navigate,
  ]);

  useEffect(() => {
    return subscribeActionsBefore([USER_IS_NOT_AUTHORIZED_ACTION], () => {
      if (location.pathname !== loginUrl()) {
        logOutUser();
      }
    });
  }, [logOutUser, location.pathname]);
};
