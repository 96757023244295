import React from 'react';

import styled from 'styled-components';

import { getUserTwoSymbolsName } from '@cd3p/core/utils/user';
import { motion } from 'framer-motion';

type AvatarProps = {
  firstName?: string;
  lastName?: string;
  size?: string;
  fontSize?: string;
  backgroundColor?: string;
  className?: string;
  children?: React.ReactElement;
  onClick?: () => void;
};

const AvatarContainer = styled.div<{
  size?: string;
  bgColor?: string;
  fontSize?: string;
}>`
  width: ${props => (props.size ? props.size : '4rem')};
  height: ${props => (props.size ? props.size : '4rem')};
  min-width: ${props => (props.size ? props.size : '4rem')};
  border-radius: 50%;
  background-color: ${props =>
    props.bgColor || props.theme.custom.palette.primary500};
  color: ${props => props.theme.custom.palette.secondary900};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${props => (props.fontSize ? props.fontSize : '1.6rem')};
  font-weight: 900;
`;

const variants = {
  enlarge: {
    scale: 1.15,
  },
};

export const Avatar: React.FC<AvatarProps> = ({
  firstName = '',
  lastName = '',
  onClick,
  size,
  backgroundColor,
  className,
  children,
  fontSize,
}) => {
  return (
    <AvatarContainer
      bgColor={backgroundColor}
      className={className}
      size={size}
      onClick={onClick}
      fontSize={fontSize}
    >
      <motion.span variants={variants}>
        {children || getUserTwoSymbolsName(firstName, lastName)}
      </motion.span>
    </AvatarContainer>
  );
};
