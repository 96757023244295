import { _ } from '../third-party';
import { APIOrder } from '../types/api';
import { prepareUpdatedOrderData } from '../utils/order';

export type CoreOrdersCacheState = {
  orders: APIOrder[];
};

export const coreOrdersCacheState: CoreOrdersCacheState = {
  orders: [],
};

export const coreUpdateOrdersCache = (data: APIOrder | APIOrder[]) => ({
  name: 'updateOrdersCache',
  updater: (state: CoreOrdersCacheState) => {
    // BE doesn't return some properties in orders list, so we have to look for them
    // in existing orders so to preserve it while merging orders cache
    const updatedData = (Array.isArray(data) ? data : [data]).map((order) => {
      if (
        order.subscribedUsers === null ||
        order.project.additives === null ||
        order.project.mixTypes === null ||
        order.orderChanges === null
      ) {
        const foundOrder = state.orders.find((it) => it.id === order.id);
        return {
          ...order,
          additives:
            order.additives === null && foundOrder
              ? foundOrder.additives
              : order.additives,
          subscribedUsers:
            order.subscribedUsers === null && foundOrder
              ? foundOrder.subscribedUsers
              : order.subscribedUsers,
          project: {
            ...order.project,
            additives:
              order.project.additives === null && foundOrder
                ? foundOrder.project.additives
                : order.project.additives,
            mixTypes:
              order.project.mixTypes === null && foundOrder
                ? foundOrder.project.mixTypes
                : order.project.mixTypes,
          },
          orderChanges:
            order.orderChanges === null && foundOrder
              ? foundOrder.orderChanges
              : order.orderChanges,
        };
      }
      return order;
    });
    return {
      ...state,
      orders: _.uniqBy([...updatedData, ...state.orders], 'id'),
    };
  },
});

export const coreUpdateOrderInCache = (
  orderId: APIOrder['id'],
  data: Partial<APIOrder>,
  ignoreSubscription?: boolean,
) => ({
  name: 'updateOrderInCache',
  updater: (state: CoreOrdersCacheState) => {
    const foundOrder = state.orders.find((order) => order.id === orderId);
    const preparedOrderData = prepareUpdatedOrderData(data, ignoreSubscription);
    const updatedOrder = foundOrder
      ? { ...foundOrder, ...preparedOrderData }
      : preparedOrderData;
    if (!updatedOrder.orderName) {
      // here we check if the updated order have enough data to be displayed
      // we don't need to put in cache orders that only have `dialogs` for example
      return state;
    }
    return {
      ...state,
      orders: _.uniqBy([updatedOrder as APIOrder, ...state.orders], 'id'),
    };
  },
});

export const coreUpdateOrderUnreadMessagesInCache = (
  orderId: APIOrder['id'],
  readState: {
    isReadByDispatcher?: boolean;
    isDialogReadByCustomer?: boolean;
    customerId?: string;
  },
) => ({
  name: 'updateOrderUnreadMessagesInCache',
  updater: (state: CoreOrdersCacheState) => ({
    orders: state.orders.map((it) =>
      it.id == orderId
        ? {
            ...it,
            dialog: {
              ...it.dialog,
              ...(_.has(readState, 'isReadByDispatcher') && {
                isReadByDispatcher: readState.isReadByDispatcher,
              }),
              ...(_.has(readState, 'isDialogReadByCustomer') && {
                readCustomerIds: readState.isDialogReadByCustomer
                  ? [
                      ...(it.dialog.readCustomerIds || []),
                      readState.customerId || '',
                    ]
                  : (it.dialog.readCustomerIds || []).filter(
                      (id) => id !== readState.customerId,
                    ),
              }),
            },
          }
        : it,
    ),
  }),
});
