import { APP_EVENTS } from 'constants/appEvent';

const pubsub = new Map<string | number, Array<(...args: any[]) => any>>();

export function emit(eventType: APP_EVENTS, ...args: unknown[]) {
  if (pubsub.has(eventType)) {
    pubsub.get(eventType)?.forEach((cb: (...args: unknown[]) => void) => {
      cb(...args);
    });
  }
}

export function subscribe(eventType: APP_EVENTS, eventCallback: any) {
  pubsub.has(eventType) || pubsub.set(eventType, []);
  const callbacks = pubsub.get(eventType);
  if (callbacks && !callbacks.includes(eventCallback)) {
    callbacks.push(eventCallback);
  }

  return function unsubscribe() {
    callbacks &&
      pubsub.set(
        eventType,
        callbacks.filter(cb => cb !== eventCallback),
      );
  };
}
