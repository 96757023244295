import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

import { NotificationSeverity } from '@cd3p/core/modules/notifications';
import { APIUser } from '@cd3p/core/types/api';
import { getProviderFromUser } from '@cd3p/core/utils/app';
import {
  GlobalLoader,
  LoadingButton,
  SetUpPasswordForm,
  SplitView,
  Typography,
} from 'components';
import { useHandleApiResult } from 'hooks/useHandleApiResult';

import {
  FormProvider,
  _,
  queryString,
  useForm,
  useNavigate,
  useSelector,
  useTranslation,
} from 'third-party';

import { allOrdersUrl, loginUrl } from 'constants/url';

import { useNotifications } from 'modules/notifications';
import { useUser } from 'modules/user';

import { appSelectors, userSelectors } from 'selectors';

import {
  SetUpPasswordFormData,
  SetUpPasswordFormFields,
  setUpPasswordFormDefaultValues,
} from 'components/SetUpPasswordForm/SetUpPasswordForm';

import {
  deleteUserToken,
  isUserAuthenticated,
  saveUserToken,
  useLogOutUser,
} from 'utils/auth';

const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 8.3rem;
`;

export const Title = styled(Typography)`
  color: ${props => props.theme.custom.palette.secondary500};
  font-size: 3.6rem;
  font-weight: 900;
  text-align: center;
  margin-bottom: 3.8rem;
`;

export const Description = styled(Typography)`
  font-size: 1.8rem;
  font-weight: 400;
  padding-top: 0.8rem;
  color: ${props => props.theme.custom.palette.muted800};
`;

const Form = styled.form`
  display: flex;
  flex-flow: column;
  width: 42.8rem;
`;

const SubmitButton = styled(LoadingButton)`
  margin: 4rem 0 1rem 0;
`;

export const ResetPasswordForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { fetchCurrentUser, updateUserPassword } = useUser();

  const updateUserPasswordPending = useSelector(
    userSelectors.updateUserPasswordPending,
  );
  const fetchCurrentUserPending = useSelector(
    userSelectors.fetchCurrentUserPending,
  );

  const methods = useForm<SetUpPasswordFormData>({
    mode: 'onChange',
    defaultValues: setUpPasswordFormDefaultValues,
  });

  const {
    formState: { isValid },
    handleSubmit,
  } = methods;

  const isFormPending = updateUserPasswordPending || fetchCurrentUserPending;

  const handleApiResult = useHandleApiResult();

  const onSubmit = async (data: SetUpPasswordFormData) => {
    if (!isValid) {
      return;
    }
    const password = _.trim(data[SetUpPasswordFormFields.Password].trim());
    const result = await handleApiResult<APIUser>(fetchCurrentUser);
    if (result && !result.error) {
      const user = result.payload;
      handleApiResult(
        () =>
          updateUserPassword(
            getProviderFromUser(user)?.id as string,
            user,
            password,
          ),
        async ({ showBaseToast }) => {
          showBaseToast(t('resetPassword.successToastMessage'));
          deleteUserToken();
          navigate(loginUrl(), { replace: true });
        },
      );
    }
  };

  return (
    <Wrapper>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Title variant="h2">{t('resetPassword.title')}</Title>
          <Description>{t('resetPassword.description')}</Description>
          <SetUpPasswordForm isFormPending={isFormPending} />
          <SubmitButton
            type="submit"
            variant="contained"
            size="medium"
            color="primary"
            disabled={!isValid}
            loading={isFormPending}
          >
            {t('resetPassword.submitButton')}
          </SubmitButton>
        </Form>
      </FormProvider>
    </Wrapper>
  );
};

export const ResetPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { addNotification } = useNotifications();

  const { validateTempToken } = useUser();

  const [userToken, setUserToken] = useState<null | string>(null);

  const queryParams = queryString.parse(location.search);

  const urlToken = queryParams?.token;

  const user = useSelector(appSelectors.user);

  const validateTempTokenPending = useSelector(
    userSelectors.validateTempTokenPending,
  );

  const handleApiResult = useHandleApiResult();

  const logOutUser = useLogOutUser();

  useEffect(() => {
    if (!urlToken) {
      addNotification({
        severity: NotificationSeverity.Warning,
        message: t('resetPassword.errorToast.message'),
      });
      navigate(loginUrl(), { replace: true });
    } else {
      handleApiResult<string>(
        () => validateTempToken(urlToken as string),
        async ({ result }) => {
          await logOutUser({ redirect: false });
          saveUserToken(result.payload);
          setUserToken(result.payload);
        },
        async ({ showErrorToast, result }) => {
          setUserToken(null);
          if (result?.error && result.payload.status == 404) {
            addNotification({
              severity: NotificationSeverity.Warning,
              message: t('resetPassword.errorToast.message'),
            });
          } else {
            showErrorToast();
          }
          if (user && isUserAuthenticated()) {
            navigate(allOrdersUrl());
          } else {
            await logOutUser({ redirect: false });
            navigate(loginUrl(), { replace: true });
          }
        },
      );
    }
    // we only need to react to token changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlToken]);

  return !validateTempTokenPending && userToken ? (
    <SplitView>
      <ResetPasswordForm />
    </SplitView>
  ) : (
    <GlobalLoader />
  );
};
