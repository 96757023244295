import i18n from 'i18n';
import chatMessageSound from 'sounds/chat-message.mp3';

export type Notification = globalThis.Notification;
export type NotificationOptions = globalThis.NotificationOptions;

export const isNotificationGranted = () =>
  Notification && Notification.permission === 'granted';

export const grantPushNotificationPermissions = async () => {
  if (!isNotificationGranted()) {
    await Notification.requestPermission();
    return isNotificationGranted();
  }

  return true;
};

export const showPushNotification = (
  text: string,
  onClick?: (closeNotification: () => void) => void,
) => {
  if (!isNotificationGranted()) {
    grantPushNotificationPermissions().then(granted => {
      if (granted) {
        showPushMessage(text, onClick);
      }
    });

    return;
  }

  showPushMessage(text, onClick);
};

let showPushMessageTimeout: ReturnType<typeof setTimeout>;
let waitingPushMessagesCount = 0;

const showPushMessage = (
  text: string,
  onClick?: (closeNotification: () => void) => void,
) => {
  const show = (
    text: string,
    onClick?: (closeNotification: () => void) => void,
  ) => {
    // don't show push notifications when document is hidden
    // because firebase will do it on its end
    if (document.hidden) {
      return;
    }

    const options: NotificationOptions = {
      body: text,
      dir: 'ltr',
      icon: './icons/favicon.svg',
      badge: './icons/favicon.svg',
    };

    const notification = new Notification('Concretely', options);

    notification.onclick = () => {
      onClick?.(() => 0);
    };

    playNotificationSound();

    waitingPushMessagesCount = 0;

    return true;
  };

  clearTimeout(showPushMessageTimeout);

  waitingPushMessagesCount++;

  showPushMessageTimeout = setTimeout(() => {
    if (waitingPushMessagesCount > 1) {
      show(
        i18n.t('requiredActionsPanel.unreadNotifications', {
          count: waitingPushMessagesCount,
        }),
      );
    } else {
      show(text, onClick);
    }
  }, 100);
};

export const playNotificationSound = () => {
  // do not play sound in dev mode, it makes me crazy :)
  const sound = new Audio(chatMessageSound);
  try {
    sound.play();
  } catch (e) {
    // ignore error
  }
};
