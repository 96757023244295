import React, { useCallback, useState } from 'react';

import styled from 'styled-components';

import {
  APIAdditive,
  APIAvailableAdditive,
  APIAvailableMixType,
  APIMixType,
} from '@cd3p/core/types/api';
import { OmitFirstArg } from '@cd3p/core/types/utils';
import { SimpleAction } from '@cd3p/core/utils/sra/types';
import {
  Button,
  Checkbox,
  FormField,
  Stack,
  TypeaheadFormField,
  Typography,
} from 'components';
import { useHandleApiResult } from 'hooks/useHandleApiResult';
import { isArray } from 'lodash';
import { OptionsOrGroups, SingleValue } from 'react-select';
import { FormatOptionLabelMeta } from 'react-select/dist/declarations/src/Select';

import { _, useForm, useTranslation } from 'third-party';

import { FIELD_MAX_LENGTH } from 'constants/common';

import {
  ProjectState,
  assignProjectAdditiveType,
  assignProjectMixType,
  createProjectAdditiveType,
  createProjectMixType,
  fetchProjectAdditiveTypes,
  fetchProjectMixTypes,
} from 'modules/project';

import { AdditionalType } from 'components/Typeahead/Typeahead';

import { GroupedTypeaheadOption, TypeaheadOption } from 'types/app';

const Wrapper = styled.form`
  display: flex;
  gap: 1rem;
  align-items: flex-end;
  z-index: 5;
`;

const OptionGroup = styled(Typography)`
  font-size: 1.4rem;
  font-weight: 900;
  text-transform: uppercase;
  line-height: 2.6rem;
  color: ${props => props.theme.custom.palette.bluegray400};
`;

const Option = styled(Stack)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const OptionUsage = styled(Typography)`
  font-size: 1.2rem;
  font-weight: 400;
  color: ${props => props.theme.custom.palette.secondary500};
`;

const StyledCheckbox = styled(Checkbox)`
  padding: 0;
`;

const AddToListButton = styled(Button)`
  flex-shrink: 0;
  height: 4rem;
`;

const getGroupNestedOptionsLength = (
  groupOptions: OptionsOrGroups<TypeaheadOption, GroupedTypeaheadOption>,
) => {
  const optionsLength = groupOptions.reduce(
    (acc, groupOption) =>
      isArray(groupOption?.options) ? acc + _.size(groupOption?.options) : acc,
    0,
  );
  return optionsLength;
};

type FormData = {
  [key: string]: TypeaheadOption[] | null;
};

type Props = {
  fieldName: string;
  dropdownLabel: string;
  className?: string;
  successMessage: string;
  onSuccess?: () => void;
  createNewOption: OmitFirstArg<
    OmitFirstArg<typeof createProjectMixType | typeof createProjectAdditiveType>
  >;
  addToList: OmitFirstArg<
    OmitFirstArg<typeof assignProjectMixType | typeof assignProjectAdditiveType>
  >;
  loadOptions: OmitFirstArg<
    OmitFirstArg<typeof fetchProjectMixTypes | typeof fetchProjectAdditiveTypes>
  >;
};

export const AddToListDropdown: React.FC<Props> = ({
  fieldName,
  dropdownLabel,
  loadOptions,
  addToList,
  createNewOption,
  successMessage,
  onSuccess,
  className,
}) => {
  const { t } = useTranslation();
  const handleApiResult = useHandleApiResult();

  const [isUpdating, setIsUpdating] = useState(false);
  const [formKey, setFormKey] = useState(0);

  const defaultValues: FormData = {
    [fieldName]: null,
  };

  const {
    setValue,
    handleSubmit,
    control,
    reset,
    getValues,
    formState: { isValid },
  } = useForm<FormData>({
    defaultValues,
  });

  const getLoadOptionsResult = useCallback(
    (response: SimpleAction<ProjectState, any>) => {
      const options = response.payload.result.map(
        (it: APIAvailableAdditive | APIAvailableMixType) => ({
          label: it.name,
          value: it.id,
          categoryName: it.categoryName,
          usage: it.usage,
        }),
      );
      const groupedOptions = _.groupBy(options, 'categoryName');
      const result = Object.keys(groupedOptions).map(categoryName => ({
        label: categoryName,
        options: groupedOptions[categoryName],
      }));
      return result;
    },
    [],
  );

  const loadOptionsHandler = async (
    search: string,
    prevGroupOptions: OptionsOrGroups<TypeaheadOption, GroupedTypeaheadOption>,
    { page }: AdditionalType,
  ) => {
    const result = await loadOptions(search, page);
    let groupOptions: GroupedTypeaheadOption[] = [];
    let totalOptionsCount = 0;
    if (!result.error) {
      totalOptionsCount = result.payload.count;
      groupOptions = getLoadOptionsResult(result);
    }
    const hasMore =
      getGroupNestedOptionsLength(prevGroupOptions) +
        getGroupNestedOptionsLength(groupOptions) <
      totalOptionsCount;

    return {
      options: groupOptions,
      hasMore: hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const onSelectDropdownValue = useCallback(
    (option: SingleValue<TypeaheadOption>) => {
      // @ts-ignore fix type for `option`
      setValue(fieldName, option, {
        shouldValidate: true,
        shouldDirty: true,
      });
    },
    [fieldName, setValue],
  );

  const onCreateDropdownValue = useCallback(
    async (value: string) => {
      setIsUpdating(true);
      const result = await handleApiResult<APIMixType | APIAdditive>(() =>
        createNewOption(value.trim()),
      );
      if (result.error) {
        setIsUpdating(false);
        return;
      }
      const createdOption: TypeaheadOption = {
        label: result.payload.name || value,
        value: String(result.payload.id || value),
      };
      const currentOptions = getValues(fieldName);
      setValue(fieldName, [...(currentOptions || []), createdOption], {
        shouldValidate: true,
        shouldDirty: true,
      });
      setIsUpdating(false);
    },
    [createNewOption, fieldName, getValues, handleApiResult, setValue],
  );

  const onSubmit = async (data: FormData) => {
    if (!isValid) {
      return;
    }
    setIsUpdating(true);
    const optionIds =
      data[fieldName]?.map(option => Number(option.value)) || [];
    handleApiResult(
      async () => addToList(optionIds),
      ({ showBaseToast }) => {
        showBaseToast(successMessage);
        onSuccess?.();
        reset(defaultValues);
        // this hack is needed to make dropdown re-fetch its default
        // items after each submit
        setFormKey(key => key + 1);
        // set flag to false after form's finished resetting to
        // avoid button blinking
        setTimeout(() => setIsUpdating(false), 100);
      },
      ({ showErrorToast }) => {
        setIsUpdating(false);
        showErrorToast();
      },
    );
  };

  const formatGroupLabel = (data: GroupedTypeaheadOption) => (
    <OptionGroup>{data.label}</OptionGroup>
  );

  const formatOptionLabel = (
    data: TypeaheadOption,
    { selectValue, context }: FormatOptionLabelMeta<TypeaheadOption>,
  ) => {
    const isValueSelected = !!_.find(
      selectValue,
      it => it.value === data.value,
    );
    const isMenuOption = context === 'menu';
    return (
      <Option>
        <Stack direction="row" gap="0.5rem">
          {isMenuOption && <StyledCheckbox checked={isValueSelected} />}
          <span>{data.label}</span>
        </Stack>
        {/*
          don't display usage label for the new option
        */}
        {!data.__isNew__ && isMenuOption && (
          <OptionUsage>
            {t('projectDetails.usageLabel', {
              count: (data as TypeaheadOption & { usage: number }).usage,
            })}
          </OptionUsage>
        )}
      </Option>
    );
  };

  return (
    <Wrapper onSubmit={handleSubmit(onSubmit)} className={className}>
      <FormField
        key={formKey}
        isRequired
        fieldName={fieldName}
        label={dropdownLabel}
        control={control}
        isDisabled={isUpdating}
        maxLength={FIELD_MAX_LENGTH}
        render={({ field }) => (
          <TypeaheadFormField
            isCreatable
            openMenuOnFocus
            openMenuOnClick
            loadOptionsOnMenuOpen
            createOptionPosition="first"
            placeholder={t('projectDetails.addToListFieldPlaceholder')}
            loadOptions={loadOptionsHandler}
            onChange={onSelectDropdownValue}
            value={field.value}
            isDisabled={isUpdating}
            formatGroupLabel={formatGroupLabel}
            formatOptionLabel={formatOptionLabel}
            noOptionsMessage={() => t('typeahead.nothingFound')}
            isMulti
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            onCreate={onCreateDropdownValue}
            isPaginatable
          />
        )}
      />
      <AddToListButton
        variant="contained"
        type="submit"
        disabled={!isValid || isUpdating}
      >
        {t('projectDetails.addToListButtonLabel')}
      </AddToListButton>
    </Wrapper>
  );
};
