import React, { useCallback } from 'react';

import styled from 'styled-components';

import {
  APISubscribedUser,
  APIUser,
  APIUserSearchResult,
} from '@cd3p/core/types/api';
import { SimpleAction } from '@cd3p/core/utils/sra/types';
import { getColorForUserAvatar } from '@cd3p/core/utils/user';
import { Stack } from '@mui/material';
import { Avatar, FormField, TypeaheadFormField, Typography } from 'components';
import { FieldError, FieldName } from 'react-hook-form';
import {
  FormatOptionLabelMeta,
  GroupBase,
  MenuPlacement,
  OptionsOrGroups,
  SingleValue,
} from 'react-select';

import { _, useFormContext, useTranslation } from 'third-party';

import { FIELD_MAX_LENGTH } from 'constants/common';

import { CacheState, useCache } from 'modules/cache';

import { AdditionalType } from 'components/Typeahead/Typeahead';

import { TypeaheadDataOption } from 'types/app';

const Option = styled(Stack)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const EmailOptionText = styled(Typography)`
  font-size: 1.4rem;
  font-weight: 400;
`;

const MIN_LIST_SIZE_TO_PAGINATE = 6;
const USERS_LIST_PAGE_SIZE = 10;

export const getPageSizeDependingOnSelectedItems = (
  selectedOptionsLength: number,
) => {
  // calculating page size based on selected options amount
  // if default page size and selected amount difference is less then MIN_LIST_SIZE_TO_PAGINATE,
  // the page size will be increased to prevent empty dropdown ui in typeahead component
  // the final page size number will always be a multiple of USERS_LIST_PAGE_SIZE
  const itemsLength =
    USERS_LIST_PAGE_SIZE - selectedOptionsLength < MIN_LIST_SIZE_TO_PAGINATE
      ? selectedOptionsLength + MIN_LIST_SIZE_TO_PAGINATE
      : selectedOptionsLength;
  return (
    Math.ceil(itemsLength / USERS_LIST_PAGE_SIZE) * USERS_LIST_PAGE_SIZE ||
    USERS_LIST_PAGE_SIZE
  );
};

type Props<FormPropsT extends Record<string, any>> = {
  fieldName: FieldName<FormPropsT>;
  label: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  requiredErrorMessage?: string;
  notFoundText?: string;
  placeholder?: string;
  excludedIds?: string[];
  menuPlacement?: MenuPlacement;
  onChange?: (
    option?: TypeaheadDataOption<APISubscribedUser | APIUser>[] | null,
  ) => void;
  onClear?: () => void;
};

export const UserSearchField = <FormPropsT extends Record<string, any>>({
  fieldName,
  label,
  isDisabled,
  isRequired = false,
  requiredErrorMessage,
  notFoundText,
  placeholder,
  excludedIds = [],
  menuPlacement,
  onChange,
  onClear,
}: Props<FormPropsT>) => {
  const { t } = useTranslation();
  const { loadUsersTypeahead } = useCache();

  const {
    setValue,
    control,
    formState: { errors },
  } = useFormContext();

  const notFoundTextCallback = useCallback(
    () => notFoundText || t('typeahead.noMatchingUser'),
    [notFoundText, t],
  );

  const getLoadOptionsResult = useCallback(
    (response: SimpleAction<CacheState, APIUserSearchResult>) => {
      const options = response.payload.result.map(it => ({
        label: `${it.firstName} ${it.lastName}`,
        value: it.id,
        data: it,
      }));

      return options;
    },
    [],
  );

  const loadOptionsHandler = async (
    search: string,
    prevOptions: OptionsOrGroups<
      TypeaheadDataOption<APIUser>,
      GroupBase<TypeaheadDataOption<APIUser>>
    >,
    { page }: AdditionalType,
  ) => {
    const result = await loadUsersTypeahead({
      excludedIds: excludedIds,
      pageNumber: page,
      pageSize: USERS_LIST_PAGE_SIZE,
      query: search,
    });
    let options: TypeaheadDataOption<APIUser>[] = [];
    let totalOptionsCount = 0;
    if (!result.error) {
      totalOptionsCount = result.payload.count;
      options = getLoadOptionsResult(result);
    }

    const hasMore = [...prevOptions, ...options].length < totalOptionsCount;

    return {
      options: options,
      hasMore: hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const onSelectOption = useCallback(
    (
      option: SingleValue<TypeaheadDataOption<APISubscribedUser | APIUser>[]>,
    ) => {
      // @ts-ignore fix type for `option`
      setValue(fieldName, option, {
        shouldValidate: true,
        shouldDirty: true,
      });
      onChange?.(option);
      if (option === null) {
        onClear?.();
      }
    },
    [fieldName, onChange, onClear, setValue],
  );

  const formatOptionLabel = (
    { label, value, data }: TypeaheadDataOption<APISubscribedUser | APIUser>,
    {
      selectValue,
    }: FormatOptionLabelMeta<TypeaheadDataOption<APISubscribedUser | APIUser>>,
  ) => {
    const isValueSelected = !!_.find(selectValue, it => it.value === value);
    return (
      <Option>
        <Stack direction="row" gap="0.5rem" alignItems="center">
          {!isValueSelected && (
            <Avatar
              size="3.6rem"
              firstName={data?.firstName || ''}
              lastName={data?.lastName || ''}
              backgroundColor={getColorForUserAvatar(value)}
            />
          )}
          <Stack direction="column">
            <Typography
              fontWeight={isValueSelected ? 400 : 600}
              fontSize={isValueSelected ? 10 : 14}
              variant="body2"
            >
              {label}
            </Typography>
            {!isValueSelected && (
              <EmailOptionText>{data?.email}</EmailOptionText>
            )}
          </Stack>
        </Stack>
      </Option>
    );
  };

  return (
    <FormField
      fieldName={fieldName}
      label={label}
      fieldError={errors[fieldName] as FieldError}
      requiredErrorMessage={
        requiredErrorMessage || t('common.form.emptyFieldError')
      }
      control={control}
      isDisabled={isDisabled}
      isRequired={isRequired}
      maxLength={FIELD_MAX_LENGTH}
      render={({ field }) => (
        <TypeaheadFormField
          openMenuOnClick
          defaultOptions
          placeholder={placeholder}
          loadOptions={loadOptionsHandler}
          onChange={onSelectOption}
          value={field.value}
          isDisabled={isDisabled}
          formatOptionLabel={formatOptionLabel}
          noOptionsMessage={notFoundTextCallback}
          isMulti
          closeMenuOnSelect={false}
          menuPosition="fixed"
          menuPlacement={menuPlacement}
          isPaginatable
          cacheUniqs={[excludedIds]}
          additional={{
            page: 1,
          }}
        />
      )}
    />
  );
};
