import React from 'react';

import styled from 'styled-components';

import { Menu as MaterialMenu } from '@mui/material';
import { MenuProps } from '@mui/material/Menu/Menu';

import { Typography } from 'components/index';

type Props = { menuTitle?: string } & MenuProps;

const StyledRowMenu = styled(MaterialMenu)`
  .MuiPopover-paper {
    border-radius: 4px;
  }
`;

const MenuGroupTitle = styled(Typography)`
  padding: 1rem 2rem;
  color: ${props => props.theme.custom.palette.darkText};
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.8rem;
`;

export const Menu: React.FC<Props> = ({ children, menuTitle, ...props }) => {
  return (
    <StyledRowMenu
      MenuListProps={{
        'aria-labelledby': 'long-button',
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      elevation={2}
      {...props}
    >
      {menuTitle && <MenuGroupTitle>{menuTitle}</MenuGroupTitle>}
      {children}
    </StyledRowMenu>
  );
};
