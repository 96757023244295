import { State } from 'modules';

export const project = (state: State) => state.project.project;
export const updateProjectPending = (state: State) =>
  state.project.updateProjectPending;
export const createProjectPending = (state: State) =>
  state.project.createProjectPending;
export const loadProjectPending = (state: State) =>
  state.project.loadProjectPending;
export const projectLoaded = (state: State) => state.project.projectLoaded;
export const updateProjectErrorType = (state: State) =>
  state.project.updateProjectError?.body?.Type;
export const updateProjectErrorMessage = (state: State) =>
  state.project.updateProjectError?.body?.Title;
export const addProjectSubscriptionsPending = (state: State) =>
  state.project.addProjectSubscriptionsPending;
