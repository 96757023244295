import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import styled, { useTheme } from 'styled-components';

import { TooltipProps } from '@mui/material/Tooltip';
import {
  Marker as DefaultMarker,
  OverlayView,
  OverlayViewF,
} from '@react-google-maps/api';
import { SvgIcon, Tooltip } from 'components';

export enum MarkerIconType {
  Plant = 'plant',
  PlantHover = 'plantHover',
  PlantSatellite = 'plantSatellite',
  PlantSatelliteHover = 'plantSatelliteHover',
  Site = 'site',
  SiteHover = 'siteHover',
  SiteSatellite = 'siteSatellite',
  SiteSatelliteHover = 'siteSatelliteHover',
  Truck = 'truck',
  TruckHover = 'truckHover',
  TruckSatellite = 'truckSatellite',
  TruckSatelliteHover = 'truckSatelliteHover',
  ReturningTruck = 'returningTruck',
  ReturningTruckHover = 'returningTruckHover',
  ReturningTruckSatellite = 'returningTruckSatellite',
  ReturningTruckSatelliteHover = 'returningTruckSatelliteHover',
}

const Wrapper = styled.div<{
  markerOpacity: number;
  isOnline?: boolean;
  icon: MarkerIconType;
  isDraggable?: boolean;
}>`
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  border-radius: 100%;
  user-select: none;
  transform: translate(
    -50%,
    ${props =>
      [MarkerIconType.Site, MarkerIconType.SiteSatellite].includes(props.icon)
        ? '-100%'
        : '-50%'}
  );
  opacity: ${props => props.markerOpacity};
  cursor: ${props => (props.onClick ? 'pointer' : 'default')};
  svg {
    color: ${props =>
      !props.isOnline
        ? props.theme.custom.palette.muted500
        : props.theme.custom.palette.secondary600};
  }
  .svg-icon {
    &:nth-child(2) {
      display: none;
    }
  }
  ${props =>
    !props.isDraggable
      ? `&:hover {
    .svg-icon {
      &:nth-child(1) {
        display: none;
      }
      &:nth-child(2) {
        display: initial;
      }
    }
  }`
      : ''}
`;

const StyledLabel = styled.div<{ isOnline?: boolean }>`
  border: 2px solid
    ${props =>
      !props.isOnline
        ? props.theme.custom.palette.muted500
        : props.theme.custom.palette.secondary600};
  color: ${props =>
    !props.isOnline
      ? props.theme.custom.palette.muted800
      : props.theme.custom.palette.darkText};
  border-radius: 7px;
  position: absolute;
  background: white;
  max-width: 10rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 0.4rem;
`;

type Props = {
  id: string;
  lat?: number | null;
  lng?: number | null;
  label?: string | ReactElement;
  icon: MarkerIconType;
  iconHover?: MarkerIconType;
  tooltip?: string | ReactElement | null;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onDragEnd?: ({ lat, lng }: { lat: number; lng: number }) => void;
  isDraggable?: boolean;
  width: string;
  height: string;
  markerOpacity?: number;
  zIndex?: number;
  className?: string;
  isOnline?: boolean;
  tooltipProps?: Partial<TooltipProps>;
};

export const Marker = React.memo(
  ({
    id,
    lat,
    lng,
    onClick,
    onMouseEnter,
    onDragEnd,
    icon,
    iconHover,
    tooltip,
    label,
    width,
    height,
    markerOpacity = 1,
    isOnline,
    className,
    tooltipProps,
    isDraggable,
    zIndex,
  }: Props) => {
    const theme = useTheme();
    // needed for smooth marker dragging
    const [draggablePosition, setDraggablePosition] = useState<{
      lat: number;
      lng: number;
    } | null>(null);
    // Marker dragging and hover functionality is needed because it doesn't have its own js/css functionality
    const [isMarkerDragging, setIsMarkerDragging] = useState<boolean>(false);
    const [isMarkerHover, setIsMarkerHover] = useState<boolean>(false);

    const position = useMemo(
      () => ({
        lat: lat || 0,
        lng: lng || 0,
      }),
      [lat, lng],
    );

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
      if (!isDraggable) {
        // reset draggablePosition to initial if isDraggable = false
        setDraggablePosition(null);
      }
    }, [isDraggable]);

    const onLoad = useCallback(() => {
      // without this hack tooltip that is opened by default
      // is not positioned properly
      if (tooltipProps?.open) {
        setTimeout(() => {
          setLoaded(true);
        }, 100);
      } else {
        setLoaded(true);
      }
    }, [tooltipProps?.open]);

    const onDragMarker = useCallback((event: google.maps.MapMouseEvent) => {
      const newLat = event.latLng?.lat();
      const newLng = event.latLng?.lng();
      if (newLat && newLng) {
        setDraggablePosition({
          lat: newLat,
          lng: newLng,
        });
      }
    }, []);

    const onDragEndMarker = useCallback(
      (event: google.maps.MapMouseEvent) => {
        const newLat = event.latLng?.lat();
        const newLng = event.latLng?.lng();
        if (newLat && newLng) {
          onDragEnd?.({ lng: newLng, lat: newLat });
        }
        setIsMarkerDragging(false);
      },
      [onDragEnd],
    );

    const marker = (
      <Wrapper
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        markerOpacity={markerOpacity}
        isOnline={isOnline}
        icon={icon}
        isDraggable={isDraggable}
      >
        {!isMarkerDragging && !isMarkerHover && (
          <SvgIcon width={width} height={height} icon={icon} />
        )}
        <SvgIcon width={width} height={height} icon={iconHover || icon} />
        {label && <StyledLabel isOnline={isOnline}>{label}</StyledLabel>}
        {isDraggable && (
          <DefaultMarker
            position={draggablePosition || position}
            draggable={true}
            onDrag={onDragMarker}
            opacity={0}
            onDragStart={() => {
              setIsMarkerDragging(true);
            }}
            onDragEnd={onDragEndMarker}
            zIndex={1}
            onMouseOver={() => {
              setIsMarkerHover(true);
            }}
            onMouseOut={() => {
              setIsMarkerHover(false);
            }}
          />
        )}
      </Wrapper>
    );

    return (
      <OverlayViewF
        key={id}
        zIndex={zIndex}
        position={isDraggable ? draggablePosition || position : position}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        onLoad={onLoad}
      >
        {tooltip && loaded && !isDraggable ? (
          <Tooltip
            title={tooltip}
            {...tooltipProps}
            PopperProps={{ disablePortal: true }}
            componentsProps={{
              ...tooltipProps?.componentsProps,
              tooltip: {
                className,
                ...tooltipProps?.componentsProps?.tooltip,
                style: {
                  borderColor: theme.custom.palette.info600,
                  backgroundColor: 'white',
                  borderWidth: '2px',
                  borderStyle: 'solid',
                  color: theme.custom.palette.darkText,
                  ...tooltipProps?.componentsProps?.tooltip?.style,
                },
              },
            }}
          >
            {marker}
          </Tooltip>
        ) : (
          marker
        )}
      </OverlayViewF>
    );
  },
);
