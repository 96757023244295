import { State } from 'modules';

export const plants = (state: State) => state.cache.plants;
export const loadPlantsPending = (state: State) =>
  state.cache.loadPlantsPending;
export const plantsLoaded = (state: State) => state.cache.plantsLoaded;

export const updatePlantPending = (state: State) =>
  state.cache.updatePlantPending;
export const createPlantPending = (state: State) =>
  state.cache.createPlantPending;
export const removePlantPending = (state: State) =>
  state.cache.removePlantPending;
export const trucks = (state: State) => state.cache.trucks;
export const timezones = (state: State) => state.cache.timezones;
export const loadTrucksPending = (state: State) =>
  state.cache.loadTrucksPending;
export const drivers = (state: State) => state.cache.drivers;
export const loadDriversPending = (state: State) =>
  state.cache.loadDriversPending;
export const createCompanyPending = (state: State) =>
  state.cache.createCompanyPending;
export const createDataCategoryPending = (state: State) =>
  state.cache.createDataCategoryPending;
export const plantDetails = (state: State) => state.cache.plantDetails;
