import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import styled from 'styled-components';

import MarkChatReadOutlinedIcon from '@mui/icons-material/MarkChatReadOutlined';
import SendIcon from '@mui/icons-material/Send';

import { CHAT_MESSAGE_MAX_LENGTH } from '@cd3p/core/constants/common';
import { ON_MESSAGE_POST_EVENT } from '@cd3p/core/constants/hubs';
import {
  ADD_DIALOG_MESSAGE_ACTION,
  LOAD_DIALOG_MESSAGES_ACTION,
} from '@cd3p/core/modules/chat';
import { coreChatSelectors } from '@cd3p/core/selectors';
import {
  APIChatMessageModel,
  APIDialogModel,
  APIUserType,
} from '@cd3p/core/types/api';
import {
  getChatDateSeparator,
  isBotMessage,
  isSameDate,
  isSystemMessage,
} from '@cd3p/core/utils/chat';
import { subscribeActionsAfter } from '@cd3p/core/utils/redux';
import { getSuccessActionName } from '@cd3p/core/utils/sra/reducers';
import {
  ChatContainer,
  MainContainer,
  Message,
  MessageList,
  MessageSeparator,
} from '@chatscope/chat-ui-kit-react';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import {
  CircularProgress,
  Container,
  DispatcherCanAccess,
  IconButton,
  Tooltip,
} from 'components';
import { useAfterHoursNotice } from 'hooks/useAfterHoursNotice';
import { useHandleApiResult } from 'hooks/useHandleApiResult';

import { _, useCallbackRef, useSelector, useTranslation } from 'third-party';

import {
  CHAT_AUTOSCROLL_ACTIVATION_THRESHOLD,
  TOOLTIP_APPEAR_DELAY,
} from 'constants/common';

import { useApp } from 'modules/app';
import { useChat } from 'modules/chat';
import { useOrdersCache } from 'modules/ordersCache';

import { appSelectors, chatSelectors } from 'selectors';

import { GhostMessageBubble } from 'features/Chat/GhostMessageBubble';
import { StyledMessageBubbleWrapper } from 'features/Chat/MessageBubbleStyles';
import { SystemMessageBubble } from 'features/Chat/SystemMessageBubble';
import { UserMessageBubble } from 'features/Chat/UserMessageBubble';

import { useOrdersSockets } from 'utils/sockets';

const MessageInputForm = styled.form`
  position: relative;
  box-sizing: border-box;
  height: 5.8rem;
  padding: 0.8rem 1rem;
  border-top: 1px solid ${props => props.theme.custom.palette.muted50};
`;

const MessageInput = styled.textarea`
  color: ${props => props.theme.custom.palette.gray};
  font-weight: 600;
  font-size: 1.6rem;
  font-family: 'Source Sans Pro', serif;
  width: 100%;
  height: 100%;
  padding: 0.9rem 7rem 0.9rem 0.5rem;
  box-sizing: border-box;
  resize: none;
  border: 1px solid ${props => props.theme.custom.palette.muted100};
  border-radius: 0.4rem;
  &:focus,
  &:active {
    outline: none;
  }
`;

const SendButton = styled(IconButton)`
  position: absolute;
  top: 50%;
  right: 1rem;
  margin-top: -1.8rem;
  color: ${props => props.theme.custom.palette.primary900};
  padding: 0.6rem;
`;

const MarkAsReadButton = styled(SendButton)`
  right: 4.3rem;
`;

const CenterWrapper = styled(Container)`
  display: flex;
  align-items: center;
  height: 100%;
`;

type Props = {
  dialog?: APIDialogModel;
  orderId: number | string;
  isMarkAsReadDisabled?: boolean;
  companyName: string;
  providerName: string;
};

export const ChatThread: React.FC<Props> = ({
  dialog,
  orderId,
  isMarkAsReadDisabled,
  companyName,
  providerName,
}) => {
  const { t } = useTranslation();
  const [messageInputValue, setMessageInputValue] = useState('');

  const userType = useSelector(appSelectors.userType);

  const { afterHoursNotice } = useAfterHoursNotice({
    enabled: userType === APIUserType.Contractor,
  });

  const isLoadMoreEnabledRef = useRef(true);
  const autoScrollToBottomRef = useRef(true);
  const messageListElementRef = useRef<HTMLDivElement | null>(null);

  const onMessageListScroll = (event: any) => {
    const element = event.target;
    // if scroll to the very bottom - turn on auto-scrolling
    if (
      element.scrollHeight - element.scrollTop - element.clientHeight <=
      CHAT_AUTOSCROLL_ACTIVATION_THRESHOLD
    ) {
      autoScrollToBottomRef.current = true;
      return;
    }
    autoScrollToBottomRef.current = false;
  };

  const onMessageListScrollDebounced = _.debounce(onMessageListScroll, 500);

  const messageListRef = useCallbackRef<any>(null, messageList => {
    if (messageList) {
      messageListElementRef.current = document.querySelector(
        '.scrollbar-container',
      )!;
      if (messageListElementRef.current) {
        setTimeout(
          () =>
            messageListElementRef.current?.addEventListener?.(
              'scroll',
              onMessageListScrollDebounced,
            ),
          500,
        );
      }
    }
  });

  const {
    loadDialogMessages,
    resetDialogMessages,
    markDialogAsReadByContractor,
    sendDialogMessage,
    addDialogMessage,
    markDialogAsReadByDispatcher,
    loadMultipleDialogAttachmentsByIds,
  } = useChat();

  const { setOpenedChatOrderId } = useApp();
  const { updateOrderInCache, updateOrderUnreadMessagesInCache } =
    useOrdersCache();

  const handleApiResult = useHandleApiResult();

  const user = useSelector(appSelectors.user);
  const plantDialogMessages = useSelector(chatSelectors.plantDialogMessages);

  const dialogMessagesToken = useSelector(
    coreChatSelectors.dialogMessagesToken,
  );
  const loadDialogMessagesPending = useSelector(
    coreChatSelectors.loadDialogMessagesPending,
  );
  const sendDialogMessagePending = useSelector(
    coreChatSelectors.sendDialogMessagePending,
  );
  const isDialogMessagesLoaded = useSelector(
    coreChatSelectors.isDialogMessagesLoaded,
  );

  const scrollMessageListToBottom = useCallback(() => {
    messageListRef.current && messageListRef.current.scrollToBottom('auto');
  }, [messageListRef]);

  const markChatThreadAsReadByDispatcher = useCallback(
    (readState: boolean) => {
      updateOrderUnreadMessagesInCache(Number(orderId), {
        isReadByDispatcher: readState,
      });
      handleApiResult<APIDialogModel>(
        () => markDialogAsReadByDispatcher(Number(orderId), readState),
        ({ result, showSuccessToast }) => {
          if (!readState) {
            showSuccessToast(t('chat.markAsRead.successUnreadMessage'));
          }
          updateOrderInCache(Number(orderId), { dialog: result.payload });
          if (autoScrollToBottomRef.current) {
            setTimeout(() => {
              scrollMessageListToBottom();
            }, 100);
          }
        },
        ({ showErrorToast }) => {
          showErrorToast();
          updateOrderUnreadMessagesInCache(Number(orderId), {
            isReadByDispatcher: !readState,
          });
        },
      );
    },
    [
      updateOrderUnreadMessagesInCache,
      orderId,
      handleApiResult,
      markDialogAsReadByDispatcher,
      updateOrderInCache,
      t,
      scrollMessageListToBottom,
    ],
  );

  const onUndoMarkAsRead = useCallback(() => {
    markChatThreadAsReadByDispatcher(false);
  }, [markChatThreadAsReadByDispatcher]);

  useEffect(() => {
    // autoscroll to bottom in case `markAsReadBy` changes to
    // be able to show the ghost bubble that is appeared/dissappeared
    // at this event
    if (autoScrollToBottomRef.current) {
      setTimeout(() => {
        scrollMessageListToBottom();
      }, 100);
    }
  }, [scrollMessageListToBottom, dialog?.markAsReadBy?.date]);

  useOrdersSockets(
    () => [
      {
        method: ON_MESSAGE_POST_EVENT,
        action: (
          providerId: string,
          messageOrderId: number,
          userId: string,
          dialog: APIDialogModel,
          message: APIChatMessageModel,
        ) => {
          if (!document.hidden && messageOrderId === Number(orderId)) {
            addDialogMessage(message);
            if (userType === APIUserType.Contractor) {
              markDialogAsReadByContractor(messageOrderId);
            }
            if (message.senderId === user.id || autoScrollToBottomRef.current) {
              // add timeout to let message bubble fully been rendered
              // to be able to scroll correctly to the very bottom
              setTimeout(() => {
                scrollMessageListToBottom();
              }, 100);
            }
          }
        },
      },
    ],
    [
      addDialogMessage,
      markDialogAsReadByContractor,
      orderId,
      scrollMessageListToBottom,
      user.id,
      userType,
    ],
  );

  useEffect(() => {
    // track what chat window is now opened to be able
    // not to show toast notifications for this chat
    setOpenedChatOrderId(Number(orderId));
    return () => {
      setOpenedChatOrderId(null);
    };
  }, [setOpenedChatOrderId, orderId]);

  useEffect(() => {
    const topicId = Number(orderId);
    loadDialogMessages(topicId);
    if (userType === APIUserType.Contractor) {
      markDialogAsReadByContractor(topicId);
      updateOrderUnreadMessagesInCache(Number(orderId), {
        isDialogReadByCustomer: true,
        customerId: user.id,
      });
    }
  }, [
    user.id,
    loadDialogMessages,
    markDialogAsReadByContractor,
    orderId,
    updateOrderUnreadMessagesInCache,
    userType,
  ]);

  const onLoadMoreMessage = useCallback(async () => {
    if (
      !isLoadMoreEnabledRef.current ||
      loadDialogMessagesPending ||
      !dialogMessagesToken
    ) {
      return;
    }
    isLoadMoreEnabledRef.current = false;
    // disable auto-scroll to bottom when load more messages
    autoScrollToBottomRef.current = false;
    await loadDialogMessages(Number(orderId), dialogMessagesToken);
    // workaround to prevent double loading on load more (chat library bug)
    setTimeout(() => {
      isLoadMoreEnabledRef.current = true;
    }, 100);
  }, [
    dialogMessagesToken,
    loadDialogMessages,
    loadDialogMessagesPending,
    orderId,
  ]);

  const onSendMessageClick = () => {
    const messageToSend = messageInputValue.trim();
    if (messageToSend) {
      sendDialogMessage(Number(orderId), messageToSend);
      setMessageInputValue('');
    }
  };

  useEffect(() => {
    return subscribeActionsAfter(
      [
        getSuccessActionName(LOAD_DIALOG_MESSAGES_ACTION),
        ADD_DIALOG_MESSAGE_ACTION,
      ],
      ({ payload }) => {
        if (payload?.result || Array.isArray(payload)) {
          const messages: APIChatMessageModel[] = payload?.result
            ? _.reverse([...payload.result])
            : payload;
          const attachmentsIds = messages.reduce<string[]>(
            (result, message) => [...result, ...(message.attachmentIds || [])],
            [],
          );
          loadMultipleDialogAttachmentsByIds(attachmentsIds);
        }
      },
    );
  }, [loadMultipleDialogAttachmentsByIds]);

  useEffect(() => {
    return () => {
      resetDialogMessages();
    };
  }, [resetDialogMessages]);

  useEffect(() => {
    return () => {
      messageListElementRef.current?.removeEventListener?.(
        'scroll',
        onMessageListScrollDebounced,
      );
    };
  }, [onMessageListScrollDebounced]);

  if (!isDialogMessagesLoaded) {
    return (
      <CenterWrapper>
        <CircularProgress sx={{ margin: '5rem auto' }} />
      </CenterWrapper>
    );
  }

  return (
    <StyledMessageBubbleWrapper>
      <MainContainer responsive>
        <ChatContainer>
          <MessageList
            ref={messageListRef}
            disableOnYReachWhenNoScroll
            autoScrollToBottomOnMount
            autoScrollToBottom={false}
            loadingMore={loadDialogMessagesPending}
            onYReachStart={onLoadMoreMessage}
            onYReachEnd={() => (autoScrollToBottomRef.current = true)}
          >
            {_.chain(plantDialogMessages)
              .reduce<{ date: string; node: ReactNode }[]>(
                (result, it, index) => {
                  const previousMessage = plantDialogMessages?.[index - 1];
                  const senderCompany =
                    it.senderUserType === APIUserType.Dispatcher
                      ? providerName
                      : companyName;
                  const senderDisplayName = isBotMessage(it.senderId)
                    ? it.senderName
                    : `${it.senderName} @ ${senderCompany}`;
                  const messageHasSameDate =
                    previousMessage &&
                    isSameDate(it.createdOn, previousMessage.createdOn);
                  const isSystemUser = isSystemMessage(it.senderId);
                  const showUserName = it.senderId !== user.id && !isSystemUser;

                  return [
                    ...result,
                    ...(messageHasSameDate
                      ? []
                      : [
                          {
                            date: it.createdOn,
                            node: (
                              <MessageSeparator
                                key={`date-separator-${it.createdOn}`}
                              >
                                {getChatDateSeparator(it.createdOn, t)}
                              </MessageSeparator>
                            ),
                          },
                        ]),
                    isSystemUser
                      ? {
                          date: it.createdOn,
                          node: (
                            <SystemMessageBubble
                              as={Message}
                              key={it.id}
                              message={it}
                            />
                          ),
                        }
                      : {
                          date: it.createdOn,
                          node: (
                            <UserMessageBubble
                              key={it.id}
                              as={Message}
                              senderDisplayName={senderDisplayName}
                              isBotMessage={isBotMessage(it.senderId)}
                              showUserName={showUserName}
                              userId={user.id}
                              message={it}
                            />
                          ),
                        },
                  ];
                },
                [
                  ...(userType == APIUserType.Dispatcher &&
                  dialog?.isReadByDispatcher &&
                  dialog?.markAsReadBy?.name
                    ? [
                        {
                          date: dialog.markAsReadBy.date!,
                          node: (
                            <GhostMessageBubble
                              providerName={providerName}
                              senderDisplayName={`${dialog.markAsReadBy.name} @ ${providerName}`}
                              sendTime={dialog.markAsReadBy.date!}
                              isOwnMessage={dialog.markAsReadBy.id === user.id}
                              onUndoClicked={onUndoMarkAsRead}
                            />
                          ),
                        },
                      ]
                    : []),
                ],
              )
              .sortBy('date')
              .map(it => it.node)
              .value()}
          </MessageList>
        </ChatContainer>
      </MainContainer>
      {afterHoursNotice}
      <MessageInputForm
        onSubmit={event => {
          event.preventDefault();
          onSendMessageClick();
        }}
      >
        {/* we use our own message input because the one from the chatscope.io is hard */}
        {/* to customise and it allows user to input markup which cannot be turned off */}
        <MessageInput
          onKeyDown={event => {
            // send on Enter
            if (event.keyCode == 13) {
              event.preventDefault();
              onSendMessageClick();
            }
          }}
          placeholder={t('chat.messageInputPlaceholder')}
          value={messageInputValue}
          onChange={event => setMessageInputValue(event.target.value)}
          maxLength={CHAT_MESSAGE_MAX_LENGTH}
        />
        <DispatcherCanAccess>
          <Tooltip
            enterDelay={TOOLTIP_APPEAR_DELAY}
            enterNextDelay={TOOLTIP_APPEAR_DELAY}
            title={t('chat.markAsRead.buttonTooltip')}
          >
            <span>
              <MarkAsReadButton
                onClick={() => markChatThreadAsReadByDispatcher(true)}
                disabled={isMarkAsReadDisabled || sendDialogMessagePending}
              >
                <MarkChatReadOutlinedIcon />
              </MarkAsReadButton>
            </span>
          </Tooltip>
        </DispatcherCanAccess>
        <SendButton type="submit" disabled={messageInputValue?.trim?.() === ''}>
          <SendIcon />
        </SendButton>
      </MessageInputForm>
    </StyledMessageBubbleWrapper>
  );
};
