import React, { createContext } from 'react';

import { useGlobalCache } from 'utils/globalCache';

const CacheContext = createContext<{ syncData: () => Promise<void> }>({
  syncData: () => Promise.resolve(),
});

const CacheProvider: React.FC<{ children: any }> = ({ children }) => {
  return (
    <CacheContext.Provider value={useGlobalCache()}>
      {children}
    </CacheContext.Provider>
  );
};

export { CacheProvider, CacheContext };
