import React, { useCallback, useEffect, useState } from 'react';

import styled from 'styled-components';

import CloseIcon from '@mui/icons-material/Close';

import { DispatcherCanAccess, IconButton, Stack, Typography } from 'components';
import i18n from 'i18n';

import { AddMixTypeForm } from './AddMixTypeForm';
import { IndividualProjectMixesWidget } from './IndividualProjectMixesWidget';

import { useSelector, useTranslation } from 'third-party';

import { projectMixTypesUrl } from 'constants/url';

import {
  MixTypesFilters,
  MixTypesSorting,
  useMixTypesList,
} from 'modules/mixTypesList';

import { mixTypesSelectors } from 'selectors';

import {
  RIGHT_PANEL_WIDTH,
  RightPanelContainer,
} from 'components/RightPanelContainer/RightPanelContainer';

import { LeastOrderedMixesWidget } from 'features/PlantSettings/MixTypeTab/LeastOrderedMixesWidget';
import { MixTypesTable } from 'features/PlantSettings/MixTypeTab/MixTypesTable';
import { MostOrderedMixesWidget } from 'features/PlantSettings/MixTypeTab/MostOrderedMixesWidget';
import { IndividualProjectMixesOrAdditives } from 'features/PlantSettings/common/IndividualProjectMixesOrAdditives';
import { MostLeastUsedCommonPanel } from 'features/PlantSettings/common/MostLeastUsedCommonPanel';
import { useManageMixTypeItem } from 'features/PlantSettings/common/useManageMixTypeItem';

import { OutlinedSearchInput } from 'styles/common';

const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
`;

const CloseButton = styled(IconButton)`
  margin-left: auto;
`;

const Wrapper = styled(Stack)`
  flex-grow: 1;
  min-height: 0;
  padding: 0 ${props => props.theme.custom.dimensions.viewHorizontalPadding}rem
    ${props => props.theme.custom.dimensions.viewVerticalPadding}rem;
`;

const SearchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const InsightsWrapper = styled(Stack)`
  margin-top: 1rem;
  flex-direction: row;
  gap: 1rem;
  > * {
    flex: 1;
  }
`;

const Header = styled(Typography)`
  color: ${props => props.theme.custom.palette.secondary900};
  margin-top: 3.2rem;
`;

const PanelHeader = styled(Stack)`
  min-height: 5rem;
  height: 5rem;
  align-items: center;
  padding: 0 1rem 0 2rem;
  border-bottom: 1px solid ${props => props.theme.custom.palette.muted100};
`;

const PanelInnerContent = styled(Stack)`
  flex-grow: 1;
  min-width: ${RIGHT_PANEL_WIDTH};
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
`;

const PanelTitle = styled(Typography)`
  color: ${props => props.theme.custom.palette.primary900};
`;

const StyledSearchInput = styled(OutlinedSearchInput)`
  width: 20rem;
`;

export const editFormTranslations = {
  submitButtonLabel: i18n.t('common.save'),
  title: i18n.t('mixTypes.edit.title'),
  nameFieldLabel: i18n.t('mixTypes.nameFieldLabel'),
  nameFieldPlaceholder: i18n.t('mixTypes.nameFieldLabel'),
  categoryFieldLabel: i18n.t('mixTypes.categoryFieldLabel'),
  categoryFieldPlaceholder: i18n.t('mixTypes.categoryFieldLabel'),
  typeaheadPlaceholder: i18n.t('mixTypes.categoryFieldPlaceholder'),
  successUpdateMessage: i18n.t('mixTypes.notification.successUpdate'),
};

enum RightPanelContent {
  IndividualProjectMixes,
  MostUsedMixes,
  LeastUsedMixes,
}

export const MixTypesView = () => {
  const { t } = useTranslation();

  const {
    loadMixTypes,
    updateMixTypes,
    updateMixTypesListFilters,
    resetMixTypes,
    loadMostUsedMixTypes,
    loadLeastUsedMixTypes,
    loadMoreLeastUsedMixTypes,
    loadProjectsMixTypes,
    loadMoreProjectsMixTypes,
    addMixTypeToMasterList,
  } = useMixTypesList();

  const {
    mixTypeItemDeleteConfirmation,
    mixTypeItemEditPopup,
    setMixTypeIdForEdit,
    setMixTypeIdForDelete,
  } = useManageMixTypeItem();

  const [rightPanelContent, setRightPanelContent] =
    useState<RightPanelContent | null>(null);
  const mixTypesLoaded = useSelector(mixTypesSelectors.mixTypesLoaded);

  const sorting = useSelector(mixTypesSelectors.mixTypesListSorting);
  const filters = useSelector(mixTypesSelectors.mixTypesListFilters);
  const mostUsedMixTypesItems =
    useSelector(mixTypesSelectors.mostUsedMixTypesItems) || [];
  const projectMixTypesItems = useSelector(
    mixTypesSelectors.projectMixTypesItems,
  );
  const projectMixTypesCount = useSelector(
    mixTypesSelectors.projectMixTypesCount,
  );
  const loadProjectMixTypesPending = useSelector(
    mixTypesSelectors.loadProjectMixTypesPending,
  );
  const loadMoreMixTypesPending = useSelector(
    mixTypesSelectors.loadMoreProjectMixTypesPending,
  );
  const projectMixTypesLoaded = useSelector(
    mixTypesSelectors.projectMixTypesLoaded,
  );
  const addMixTypeToMasterListPending = useSelector(
    mixTypesSelectors.addMixTypeToMasterListPending,
  );
  const leastUsedMixTypesItems = useSelector(
    mixTypesSelectors.leastUsedMixTypesItems,
  );
  const loadMoreLeastUsedMixTypesPending = useSelector(
    mixTypesSelectors.loadMoreLeastUsedMixTypesPending,
  );
  const leastUsedMixTypesCount = useSelector(
    mixTypesSelectors.leastUsedMixTypesCount,
  );

  const fetchMixTypes = useCallback(
    (
      params?: {
        searchSortOrders?: MixTypesSorting;
      } & Partial<MixTypesFilters>,
    ) => {
      loadMixTypes({
        searchSortOrders: sorting,
        ...filters,
        ...params,
      });
    },
    [loadMixTypes, sorting, filters],
  );

  useEffect(() => {
    if (!mixTypesLoaded) fetchMixTypes();
  }, [fetchMixTypes, mixTypesLoaded]);

  useEffect(() => {
    return () => {
      resetMixTypes();
    };
  }, [resetMixTypes]);

  const onSearchChange = useCallback(
    (value: string) => {
      fetchMixTypes({ name: value });
      updateMixTypesListFilters({ name: value });
    },
    [fetchMixTypes, updateMixTypesListFilters],
  );

  const handleOpenRightPanel = useCallback(
    (panelContent: RightPanelContent) => {
      setRightPanelContent(state =>
        state === panelContent ? null : panelContent,
      );
    },
    [],
  );

  return (
    <Container>
      {mixTypeItemDeleteConfirmation}
      {mixTypeItemEditPopup}
      <Wrapper direction="column">
        <SearchWrapper>
          <Header variant="h5">{t('mixTypes.header')}</Header>
          <Stack direction={'row'} gap="1rem">
            <StyledSearchInput
              placeholder={t('mixTypes.searchPlaceholder')}
              onChange={onSearchChange}
            />
          </Stack>
        </SearchWrapper>
        <InsightsWrapper>
          <MostOrderedMixesWidget
            isActive={rightPanelContent === RightPanelContent.MostUsedMixes}
            onClick={() =>
              handleOpenRightPanel(RightPanelContent.MostUsedMixes)
            }
          />
          <LeastOrderedMixesWidget
            isActive={rightPanelContent === RightPanelContent.LeastUsedMixes}
            onClick={() =>
              handleOpenRightPanel(RightPanelContent.LeastUsedMixes)
            }
          />
          <DispatcherCanAccess>
            <IndividualProjectMixesWidget
              isActive={
                rightPanelContent === RightPanelContent.IndividualProjectMixes
              }
              onClick={() =>
                handleOpenRightPanel(RightPanelContent.IndividualProjectMixes)
              }
            />
          </DispatcherCanAccess>
        </InsightsWrapper>
        <AddMixTypeForm onSuccess={updateMixTypes} />
        <MixTypesTable
          fetchMixTypes={fetchMixTypes}
          setMixTypeIdForDelete={setMixTypeIdForDelete}
          setMixTypeIdForEdit={setMixTypeIdForEdit}
        />
      </Wrapper>
      <RightPanelContainer isPanelOpen={rightPanelContent !== null}>
        <PanelInnerContent>
          <PanelHeader direction="row">
            <PanelTitle variant="h5">
              {rightPanelContent === RightPanelContent.IndividualProjectMixes &&
                t('mixTypes.projectSpecificPanel.title')}
              {rightPanelContent === RightPanelContent.MostUsedMixes &&
                t('plantSettings.insightsMixTypesMostTitle')}
              {rightPanelContent === RightPanelContent.LeastUsedMixes &&
                t('plantSettings.insightsMixTypesLeastTitle')}
            </PanelTitle>
            <CloseButton onClick={() => setRightPanelContent(null)}>
              <CloseIcon />
            </CloseButton>
          </PanelHeader>
          {rightPanelContent === RightPanelContent.IndividualProjectMixes && (
            <IndividualProjectMixesOrAdditives
              items={projectMixTypesItems}
              itemUrl={projectMixTypesUrl}
              dataTotalCount={projectMixTypesCount}
              updateData={loadProjectsMixTypes}
              loadDataPending={loadProjectMixTypesPending}
              loadMoreDataPending={loadMoreMixTypesPending}
              dataLoaded={projectMixTypesLoaded}
              addToMasterListPending={addMixTypeToMasterListPending}
              loadMoreData={loadMoreProjectsMixTypes}
              addItemToMasterList={addMixTypeToMasterList}
              onAddToMasterList={updateMixTypes}
              searchFieldPlaceholder={t(
                'mixTypes.projectSpecificPanel.searchPlaceholder',
              )}
              emptyStateText={t(
                'plantSettings.insightsMixTypesIndividualProjectsEmptyText',
              )}
              emptyStateTextForSearch={t(
                'mixTypes.projectSpecificPanel.noMixesMatching',
              )}
            />
          )}
          {rightPanelContent === RightPanelContent.MostUsedMixes && (
            <MostLeastUsedCommonPanel
              items={mostUsedMixTypesItems}
              dataTotalCount={mostUsedMixTypesItems.length}
              onEditClick={setMixTypeIdForEdit}
              updateData={loadMostUsedMixTypes}
            />
          )}
          {rightPanelContent === RightPanelContent.LeastUsedMixes && (
            <MostLeastUsedCommonPanel
              items={leastUsedMixTypesItems}
              onEditClick={setMixTypeIdForEdit}
              onDeleteClick={setMixTypeIdForDelete}
              dataTotalCount={leastUsedMixTypesCount}
              updateData={loadLeastUsedMixTypes}
              loadMoreData={loadMoreLeastUsedMixTypes}
              loadMoreDataPending={loadMoreLeastUsedMixTypesPending}
            />
          )}
        </PanelInnerContent>
      </RightPanelContainer>
    </Container>
  );
};
