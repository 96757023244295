import { useMemo } from 'react';

import {
  APIAssignedDriver,
  APIAssignedDriverRequestParams,
  APITypeaheadItem,
} from '@cd3p/core/types/api';
import { httpMethod, simplifyBuilder } from '@cd3p/core/utils/sra';

import { bindActionCreators, useDispatch, useSelector } from 'third-party';

import {
  assignedDriverEndpoint,
  assignedDriversListEndpoint,
  availableToAssignDriversListEndpoint,
  notifyAssignedDriversEndpoint,
} from 'constants/endpoints';

import { appSelectors } from 'selectors';

export interface AssignedDriversListState {
  assigned: APIAssignedDriver[];
  available: APITypeaheadItem[];
  assignedLoaded: boolean;
  availableLoaded: boolean;
  loadAssignedDriversPending: boolean;
  notifyAssignedDriversPending: boolean;
  loadAvailableToAssignDrivers: boolean;
  loadAssignedDriversListErrorText: string | null;
  assignDriverPending: boolean;
}

export const assignedDriversListState: AssignedDriversListState = {
  assigned: [],
  available: [],
  assignedLoaded: false,
  availableLoaded: false,
  loadAssignedDriversPending: false,
  notifyAssignedDriversPending: false,
  loadAvailableToAssignDrivers: false,
  loadAssignedDriversListErrorText: null,
  assignDriverPending: false,
};

const builder = simplifyBuilder(assignedDriversListState, {});

const loadAssignedDrivers = builder.createServerAction(
  (providerId: string, orderId: number) => ({
    name: 'loadAssignedDrivers',
    url: assignedDriversListEndpoint(providerId, orderId),
    method: httpMethod.get,
    onSuccess: (
      state: AssignedDriversListState,
      payload: APIAssignedDriver[],
    ) => ({
      assigned: payload,
      assignedLoaded: true,
    }),
  }),
);

const loadAvailableToAssignDrivers = builder.createServerAction(
  (providerId: string, orderId: number) => ({
    name: 'loadAvailableToAssignDrivers',
    url: availableToAssignDriversListEndpoint(providerId, orderId),
    method: httpMethod.get,
    onSuccess: (
      state: AssignedDriversListState,
      payload: APITypeaheadItem[],
    ) => ({
      available: payload,
      availableLoaded: true,
    }),
  }),
);

const assignDriver = builder.createServerAction(
  (
    providerId: string,
    orderId: number,
    body: APIAssignedDriverRequestParams,
  ) => ({
    name: 'assignDriver',
    url: assignedDriversListEndpoint(providerId, orderId),
    method: httpMethod.post,
    body,
    onSuccess: (
      state: AssignedDriversListState,
      payload: APIAssignedDriver,
    ) => ({
      assigned: [payload, ...state.assigned],
    }),
  }),
);

const notifyAssignedDrivers = builder.createServerAction(
  (providerId: string, orderId: number) => ({
    name: 'notifyAssignedDrivers',
    url: notifyAssignedDriversEndpoint(providerId, orderId),
    method: httpMethod.post,
  }),
);

const deleteAssignedDriver = builder.createServerAction(
  (providerId: string, orderId: number, assignedDriverId: number) => ({
    name: 'assignDriver',
    url: assignedDriverEndpoint(providerId, orderId, assignedDriverId),
    method: httpMethod.delete,
    onSuccess: (state: AssignedDriversListState) => ({
      // NOTE: item.id is a number - it is an assigned driver id
      // item.driver.id is a string - it is a unique driver id
      assigned: state.assigned.filter(item => item.id !== assignedDriverId),
    }),
  }),
);

const resetAssignedDrivers = builder.createReduxAction(() => ({
  name: 'resetAssignedDrivers',
  updater: () => ({
    assigned: [],
    assignedLoaded: false,
  }),
}));

const resetAvailableToAssignDrivers = builder.createReduxAction(() => ({
  name: 'resetAvailableToAssignDrivers',
  updater: () => ({
    available: [],
    availableLoaded: false,
  }),
}));

export const useAssignedDriversList = () => {
  const dispatch = useDispatch();
  const providerId = useSelector(appSelectors.providerId);

  return useMemo(
    () =>
      bindActionCreators(
        {
          loadAssignedDrivers: loadAssignedDrivers.bind(null, providerId),
          loadAvailableToAssignDrivers: loadAvailableToAssignDrivers.bind(
            null,
            providerId,
          ),
          assignDriver: assignDriver.bind(null, providerId),
          notifyAssignedDrivers: notifyAssignedDrivers.bind(null, providerId),
          deleteAssignedDriver: deleteAssignedDriver.bind(null, providerId),
          // without binding TS doesn't infer type for module functions
          resetAssignedDrivers: resetAssignedDrivers.bind(null),
          resetAvailableToAssignDrivers:
            resetAvailableToAssignDrivers.bind(null),
        },
        dispatch,
      ),
    [dispatch, providerId],
  );
};

export const assignedDriversListReducer = builder.getReducers();
