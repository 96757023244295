import { useEffect } from 'react';

import { Core } from '@cd3p/core/config';
import { APIUserType } from '@cd3p/core/types/api';

import { useQuery } from './useQuery';

import { useNavigate, useSelector } from 'third-party';

import { allOrdersUrl } from 'constants/url';

import { useStorage } from 'modules/storage';

import { appSelectors } from 'selectors';

export const useSupportOpenInstance = () => {
  const { providerId } = useQuery();
  const userType = useSelector(appSelectors.userType);
  const { updateUserSettingInStorage } = useStorage();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      providerId &&
      typeof providerId === 'string' &&
      userType == APIUserType.Support &&
      !Core.isAdminPortal()
    ) {
      updateUserSettingInStorage({ currentProviderId: providerId });
      navigate(allOrdersUrl());
    }
  }, [navigate, providerId, updateUserSettingInStorage, userType]);
};
