import { APITrackingItem } from './api';

export type APIServerError = {
  error: true;
  payload: {
    status: number;
    body?: {
      Type?: string;
      Title?: string;
    };
  };
};

export enum OrderChangesStatus {
  Pending = 'Pending',
  Approved = 'Approved',
  PartiallyApproved = 'PartiallyApproved',
  Rejected = 'Rejected',
}

export type TrackingItem = APITrackingItem & {
  id?: string;
};
