import _ from 'lodash';

export const SUCCESS = 'SUCCESS';
export const REQUEST = 'REQUEST';
export const FAILURE = 'FAILURE';
export const CANCELED = 'CANCELED';

const pendingStateName = (storeValuePrefix: string) =>
  _.camelCase(`${storeValuePrefix} Pending`);
const cancelStateName = (storeValuePrefix: string) =>
  _.camelCase(`${storeValuePrefix} Cancelled`);
const errorStateName = (storeValuePrefix: string) =>
  _.camelCase(`${storeValuePrefix} Error`);

export const genericApiInitialState = (storeValuePrefix = '') => ({
  [pendingStateName(storeValuePrefix)]: false,
  [cancelStateName(storeValuePrefix)]: false,
  [errorStateName(storeValuePrefix)]: null,
});

export const getSuccessActionName = (actionNamePrefix: string) =>
  `${actionNamePrefix}/${SUCCESS}`;
export const getRequestActionName = (actionNamePrefix: string) =>
  `${actionNamePrefix}/${REQUEST}`;
export const getFailureActionName = (actionNamePrefix: string) =>
  `${actionNamePrefix}/${FAILURE}`;
export const getCancelActionName = (actionNamePrefix: string) =>
  `${actionNamePrefix}/${CANCELED}`;

export const genericSuccessRequestReducer =
  (storeValuePrefix = '') =>
  (state: any, action: any) => {
    return {
      ...state,
      [pendingStateName(storeValuePrefix)]: false,
      [cancelStateName(storeValuePrefix)]: false,
      ...(action.onSuccess ? action.onSuccess(state, action.payload) : {}),
    };
  };

export const genericStartRequestReducer =
  (storeValuePrefix = '') =>
  (state: any, action: any) => ({
    ...state,
    [pendingStateName(storeValuePrefix)]: true,
    [cancelStateName(storeValuePrefix)]: false,
    ...(action.onRequest ? action.onRequest(state, action.payload) : {}),
  });

export const genericFailureRequestReducer =
  (storeValuePrefix = '') =>
  (state: any, action: any) => ({
    ...state,
    [errorStateName(storeValuePrefix)]: action.payload,
    [pendingStateName(storeValuePrefix)]: false,
    ...(action.onFailure ? action.onFailure(state, action.payload) : {}),
  });

export const genericCancelRequestReducer =
  (storeValuePrefix = '') =>
  (state: any, action: any) => ({
    ...state,
    [cancelStateName(storeValuePrefix)]: true,
    [pendingStateName(storeValuePrefix)]: false,
    ...(action.onCancel ? action.onCancel(state, action.payload) : {}),
  });

export const genericServerReducer = (
  actionNamePrefix: string,
  storeValuePrefix = '',
) => ({
  [getSuccessActionName(actionNamePrefix)]: genericSuccessRequestReducer(
    storeValuePrefix || actionNamePrefix,
  ),
  [getRequestActionName(actionNamePrefix)]: genericStartRequestReducer(
    storeValuePrefix || actionNamePrefix,
  ),
  [getFailureActionName(actionNamePrefix)]: genericFailureRequestReducer(
    storeValuePrefix || actionNamePrefix,
  ),
  [getCancelActionName(actionNamePrefix)]: genericCancelRequestReducer(
    storeValuePrefix || actionNamePrefix,
  ),
});
