import React from 'react';

import styled from 'styled-components';

import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import InfoIcon from '@mui/icons-material/Info';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import {
  APIAdditive,
  APIExceptionType,
  APIMixType,
  APIOrder,
  APITicketStatuses,
} from '@cd3p/core/types/api';
import { APIServerError } from '@cd3p/core/types/common';
import { SimpleAction } from '@cd3p/core/utils/sra/types';
import { Grid, MenuItem, Stack, Tooltip } from 'components';
import { t } from 'i18next';

import { TOOLTIP_APPEAR_DELAY } from 'constants/common';

import { CacheState } from 'modules/cache';

import { ticketStatusMap } from 'utils/order';

import { LocationT } from 'types/app';

export const ORDER_LEFT_PANEL_WIDTH = 62;
export const CHAT_PANEL_WIDTH = 38;

export const OrderContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 0;
  .css-chat-panel-enter {
    left: ${ORDER_LEFT_PANEL_WIDTH - CHAT_PANEL_WIDTH}rem;
  }
  .css-chat-panel-enter-active {
    left: ${ORDER_LEFT_PANEL_WIDTH}rem;
    transition: left 500ms;
  }
  .css-chat-panel-exit {
    left: ${ORDER_LEFT_PANEL_WIDTH}rem;
  }
  .css-chat-panel-exit-active {
    left: ${ORDER_LEFT_PANEL_WIDTH - CHAT_PANEL_WIDTH}rem;
    transition: left 500ms;
  }
`;

export const Inner = styled(Grid)`
  display: flex;
  flex: 1;
  flex-wrap: nowrap;
  overflow: hidden;
  max-height: 100%;
  position: relative;
`;

export const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  flex: 1;
`;

export const LeftPanelWrapper = styled(Grid)<{ paddingTop?: number }>`
  display: flex;
  width: ${ORDER_LEFT_PANEL_WIDTH}rem;
  overflow: hidden;
  flex-direction: column;
  padding-right: 1rem;
  padding-top: ${props =>
    props.paddingTop || props.theme.custom.dimensions.viewVerticalPadding}rem;
  padding-bottom: ${props =>
    props.theme.custom.dimensions.viewVerticalPadding}rem;
  padding-left: ${props =>
    props.theme.custom.dimensions.viewHorizontalPadding}rem;
  border-right: 1px solid ${props => props.theme.custom.palette.muted100};
  z-index: ${props => props.theme.custom.zIndex.orderLeftPane};
  background: ${props => props.theme.custom.palette.backgroundThemeLight};
  flex: 1;
`;

const OriginalNameWrapper = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  word-break: keep-all;
`;

const OriginalName = styled.em`
  color: ${props => props.theme.custom.palette.truegray400};
  font-size: 1.4rem;
  font-weight: 600;
`;

const OriginalNameTooltipText = styled.span`
  white-space: pre-line;
`;

const StyledInfoIcon = styled(InfoIcon)`
  font-size: 1.6rem;
  margin-left: 0.2rem;
`;

export const getTicketLabelElement = (ticketStatus: APITicketStatuses) => {
  switch (ticketStatus) {
    case APITicketStatuses.Draft:
      return (
        <>
          {ticketStatusMap[ticketStatus].label}
          <InfoOutlinedIcon style={{ fontSize: '1.6rem' }} />
        </>
      );
    case APITicketStatuses.Completed:
      return (
        <>
          {ticketStatusMap[ticketStatus].label}
          <CheckOutlinedIcon style={{ fontSize: '1.6rem' }} />
        </>
      );
    default:
      return ticketStatusMap[ticketStatus].label;
  }
};

export const prepareAdditivesOptions = (additives?: APIAdditive[]) =>
  additives?.map(item => item.id as number) || [];

export const prepareAdditivesOptionsForForm = (additives?: APIAdditive[]) =>
  additives?.map(item => ({
    key: item.id || '',
    value: item.name || '',
  })) || [];

export enum OrderFormFields {
  Company = 'companyId',
  OrderName = 'orderName',
  OrderNumber = 'orderNumber',
  OrderStatus = 'orderStatus',
  CompanyName = 'companyName',
  Project = 'projectId',
  Volume = 'volume',
  Email = 'email',
  Phone = 'phone',
  MixTypeId = 'mixTypeId',
  DeliveryRate = 'deliveryRate',
  DeliveryRateType = 'deliveryRateType',
  Slump = 'slump',
  Additives = 'additives',
  Notes = 'notes',
  DeliveryLocation = 'deliveryLocation',
  DeliveryDate = 'deliveryDate',
  DeliveryTime = 'deliveryTime',
  CallBack = 'callBack',
  OrderType = 'orderType',
  TypeOfPour = 'typeOfPour',
  PlacementMethod = 'placementMethod',
  ConfirmUponChangesApproval = 'confirmUponChangesApproval',
  SubscribedUsers = 'subscribedUsers',
}

export const renderWithOriginalName = (
  value: string | null | undefined,
  originalValue: string | null,
  tooltipText: string,
) => {
  return originalValue !== value ? (
    <OriginalNameWrapper>
      <OriginalName>[{originalValue}]</OriginalName>&nbsp;{value}
      <Tooltip
        arrow
        sx={{ maxWidth: '30px' }}
        placement="top"
        enterDelay={TOOLTIP_APPEAR_DELAY}
        enterNextDelay={TOOLTIP_APPEAR_DELAY}
        title={<OriginalNameTooltipText>{tooltipText}</OriginalNameTooltipText>}
      >
        <StyledInfoIcon />
      </Tooltip>
    </OriginalNameWrapper>
  ) : (
    value
  );
};

export const getMixTypeFormField = (
  value?: APIMixType | null,
  availableMixTypes?: APIMixType[],
) => {
  return {
    label: t('order.sectionField.mixType'),
    value: value?.name || null,
    type: 'select',
    isRequired: true,
    placeholder: t('order.sectionField.mixTypePlaceholder'),
    selectOptions: availableMixTypes?.map(option => (
      <MenuItem
        key={option.id}
        value={option.id}
        hidden={option.id === value?.id}
      >
        {option.name}
      </MenuItem>
    )),
  };
};

const emptyString = '' as const;

export type OrderDetailsFormProps = {
  [OrderFormFields.Email]: string;
  [OrderFormFields.Volume]: string;
  [OrderFormFields.Phone]: string;
  [OrderFormFields.MixTypeId]: number | typeof emptyString;
  [OrderFormFields.DeliveryRate]: string;
  [OrderFormFields.Slump]: string;
  [OrderFormFields.TypeOfPour]: string;
  [OrderFormFields.PlacementMethod]: string;
  [OrderFormFields.Additives]: number[] | null;
};

export const getOrderDetailsFormDefaultValues = (
  order?: APIOrder | null,
  selectedAdditives?: number[],
) => ({
  [OrderFormFields.Email]: '',
  [OrderFormFields.Phone]: '',
  [OrderFormFields.Volume]: order?.volume?.toString() || '',
  [OrderFormFields.MixTypeId]: order?.mixType?.id || ('' as const),
  [OrderFormFields.DeliveryRate]: order?.deliveryRate?.toString() || '',
  [OrderFormFields.Slump]: order?.slump ? String(order?.slump) : '',
  [OrderFormFields.Additives]: selectedAdditives || [],
  [OrderFormFields.TypeOfPour]: order?.typeOfPour || '',
  [OrderFormFields.PlacementMethod]: order?.placementMethod || '',
});

export const preparePinLocation = (
  result: SimpleAction<CacheState, LocationT[]>,
) => {
  const locationInfo = result.payload?.[0];
  if (!result.error && locationInfo) {
    return {
      ...locationInfo,
      name: t('common.address'),
    };
  } else return null;
};

export const roundLocationCoordinates = (location?: {
  latitude: number;
  longitude: number;
}) => {
  return {
    latitude: Number(location?.latitude.toFixed(6)),
    longitude: Number(location?.longitude.toFixed(6)),
  };
};

export const hasNameDuplicationError = (error: APIServerError) => {
  return (
    error.payload.status === 409 &&
    error.payload.body?.Type === APIExceptionType.AlreadyExist &&
    error.payload.body?.Title?.includes('Order name')
  );
};

export const hasNumberDuplicationError = (error: APIServerError) => {
  return (
    error.payload.status === 409 &&
    error.payload.body?.Type === APIExceptionType.AlreadyExist &&
    (error.payload.body?.Title?.includes('Order #') ||
      error.payload.body?.Title?.includes('Order number'))
  );
};

export const ScrollableContentContainer = styled(Stack)`
  flex-direction: column;
  overflow-y: auto;
  padding: 2rem;
`;

export const OrderContentWrapper = styled(Stack)`
  position: relative;
  z-index: 1;
  margin-bottom: 2rem;
  padding: 2rem;
  background: ${props => props.theme.custom.palette.backgroundThemeLight};
  border: 1px solid ${props => props.theme.custom.palette.primary900};
  border-radius: 0.4rem;
`;
