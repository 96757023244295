import { useCallback } from 'react';

import { APIDialogModel, APIUserType } from '@cd3p/core/types/api';

import { useSelector } from 'third-party';

import { appSelectors } from 'selectors';

export const useDialogRead = () => {
  const userType = useSelector(appSelectors.userType);
  const userId = useSelector(appSelectors.userId);

  return useCallback(
    (dialog: APIDialogModel | null | undefined) => {
      if (userType === APIUserType.Dispatcher) {
        return dialog?.isReadByDispatcher === true;
      }
      return (dialog?.readCustomerIds || []).includes(userId || '');
    },
    [userId, userType],
  );
};
