import React, { useCallback, useEffect, useMemo, useState } from 'react';

import styled from 'styled-components';

import {
  APIAutocompleteResult,
  APILocationAddress,
} from '@cd3p/core/types/api';
import { MarkerF } from '@react-google-maps/api';
import { Stack, TypeaheadFormField } from 'components';
import { emit } from 'hooks/usePubSub';
import { SingleValue } from 'react-select';

import { useTranslation } from 'third-party';

import { APP_EVENTS } from 'constants/appEvent';

import { useCache } from 'modules/cache';

import { LocationT, TypeaheadDataOption, TypeaheadOption } from 'types/app';

type MapPlacesTypeaheadDataOption = TypeaheadDataOption<{
  placeId: null | string;
}>;

const FieldWrapper = styled(Stack)`
  margin: 2.7rem 20rem 0;
`;

const PLACES_MARKERS_DEFAULT: React.ReactElement[] = [];
const PLACES_DEFAULT: LocationT[] = [];

export const useMapPlaces = ({ enabled = true }: { enabled: boolean }) => {
  const { t } = useTranslation();

  const [places, setPlaces] = useState(PLACES_DEFAULT);
  const [placesMarkers, setPlacesMarkers] = useState(PLACES_MARKERS_DEFAULT);
  const [typeaheadValue, setTypeaheadValue] =
    useState<SingleValue<TypeaheadOption> | null>(null);

  const { loadMapPlaces, loadNearbyLocations, loadAddressLocation } =
    useCache();

  const loadMapPlacesOptions = useCallback(
    async (value: string) => {
      const result = await loadMapPlaces(value);
      let options: MapPlacesTypeaheadDataOption[] = [];
      if (!result.error) {
        options = result.payload.map((it: APIAutocompleteResult) => ({
          label: it.description,
          value: it.description,
          data: {
            placeId: it.placeId,
          },
        }));
      }
      return {
        options,
      };
    },
    [loadMapPlaces],
  );

  const loadNearbyLocationsMarkers = useCallback(
    async (option: SingleValue<MapPlacesTypeaheadDataOption>) => {
      const result = option!.data?.placeId
        ? await loadAddressLocation(option!.value)
        : await loadNearbyLocations(option!.value);
      if (!result.error) {
        setPlaces(result.payload);
        setPlacesMarkers(
          result.payload.map((it: APILocationAddress, index: number) => {
            return (
              <MarkerF
                key={`${index}-${it.name}`}
                visible
                position={{
                  lat: it.latitude,
                  lng: it.longitude,
                }}
                title={it.name}
                // @ts-ignore
                label={{
                  className: 'marker-position',
                }}
              />
            );
          }),
        );
      }
    },
    [loadAddressLocation, loadNearbyLocations],
  );

  const onTypeaheadChange = useCallback(
    (option: SingleValue<MapPlacesTypeaheadDataOption>) => {
      setTypeaheadValue(option);
      emit(APP_EVENTS.AUTOCENTER_MAP);
      if (option) {
        loadNearbyLocationsMarkers(option);
      } else {
        setPlaces(PLACES_DEFAULT);
        setPlacesMarkers(PLACES_MARKERS_DEFAULT);
      }
    },
    [loadNearbyLocationsMarkers],
  );

  useEffect(() => {
    if (!enabled) {
      setTypeaheadValue(null);
      setPlaces(PLACES_DEFAULT);
      setPlacesMarkers(PLACES_MARKERS_DEFAULT);
    }
  }, [enabled]);

  const mapPlacesTypeahead = useMemo(() => {
    return enabled ? (
      <FieldWrapper>
        <TypeaheadFormField
          searchIcon
          placeholder={t('trackingMap.searchPlacesPlaceholder')}
          onChange={onTypeaheadChange}
          loadOptions={loadMapPlacesOptions}
          value={typeaheadValue}
        />
      </FieldWrapper>
    ) : null;
  }, [enabled, loadMapPlacesOptions, onTypeaheadChange, t, typeaheadValue]);

  return useMemo(
    () => ({
      mapPlacesTypeahead,
      places,
      placesMarkers,
    }),
    [places, placesMarkers, mapPlacesTypeahead],
  );
};
