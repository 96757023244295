import React, { useCallback } from 'react';

import styled from 'styled-components';

import { Stack, Typography } from 'components';
import releases from 'docs/releases';

import { dayjs, useTranslation } from 'third-party';

import { Docs } from 'features/Docs/Docs';

import { Doc } from 'types/app';

const DateInfo = styled(Typography)`
  font-size: 1.2rem;
  font-weight: 900;
  line-height: 125%;
  text-transform: uppercase;
  color: ${props => props.theme.custom.palette.secondary700};
`;

const Title = styled(Typography)`
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 150%;
  color: ${props => props.theme.custom.palette.darkText};
`;

export const ReleaseNotes = () => {
  const { t } = useTranslation();

  const menuItemRenderer = useCallback((it: Doc) => {
    return (
      <Stack>
        <DateInfo>{dayjs(it.datePublish).format('MMMM D, YYYY')}</DateInfo>
        <Title>{it.title}</Title>
      </Stack>
    );
  }, []);

  return (
    <Docs
      selectedItem={releases?.[0]}
      menuItems={releases as Doc[]}
      pageName={t('docs.whatsNew')}
      menuItemRenderer={menuItemRenderer}
    />
  );
};
