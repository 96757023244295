import React, { ForwardedRef, ReactElement, useCallback, useMemo } from 'react';

import styled from 'styled-components';

import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';

import { IconButton, MenuItem, Paper } from 'components';
import {
  ControlProps,
  InputProps,
  MenuProps,
  MultiValue,
  OptionProps,
  SingleValue,
} from 'react-select';
import {
  AsyncPaginateProps,
  ComponentProps,
  UseAsyncPaginateParams,
  withAsyncPaginate,
} from 'react-select-async-paginate';
import Creatable from 'react-select/creatable';
import { StylesConfig } from 'react-select/dist/declarations/src/styles';
import {
  GroupBase,
  InputActionMeta,
  Options,
  OptionsOrGroups,
} from 'react-select/dist/declarations/src/types';

import {
  AsyncPaginate,
  CreatableProps,
  classNames,
  reactSelectComponents,
  useTranslation,
} from 'third-party';

import { USER_INPUT_DEBOUNCE } from 'constants/common';

import { GroupedTypeaheadOption, TypeaheadOption } from 'types/app';

type AsyncPaginateCreatableProps<
  OptionType,
  Group extends GroupBase<OptionType>,
  Additional,
  IsMulti extends boolean,
> = CreatableProps<OptionType, IsMulti, Group> &
  UseAsyncPaginateParams<OptionType, Group, Additional> &
  ComponentProps<OptionType, Group, IsMulti>;

type AsyncPaginateCreatableType = <
  OptionType,
  Group extends GroupBase<OptionType>,
  Additional,
  IsMulti extends boolean = false,
>(
  props: AsyncPaginateCreatableProps<OptionType, Group, Additional, IsMulti>,
) => ReactElement;

export type TypeaheadProps = Omit<
  AsyncPaginateProps<TypeaheadOption, GroupBase<TypeaheadOption>, any, true>,
  'onChange'
> & {
  width?: string;
  maxLength?: number;
  className?: string;
  hideDropdownIndicator?: boolean;
  hasError?: boolean;
  isCreatable?: boolean;
  isPaginatable?: boolean;
  hasMultiStyling?: boolean;
  controlBefore?: React.ReactElement;
  showPaginationLoader?: true;
  onChange?: (option: SingleValue<TypeaheadOption>) => void;
  onCreate?: (inputValue: string) => void;
};

const styles: StylesConfig<
  TypeaheadOption,
  boolean,
  GroupBase<TypeaheadOption>
> = {
  control: baseStyles => ({
    ...baseStyles,
    width: '100%',
    border: 'none',
    borderRadius: 0,
    backgroundColor: 'transparent',
    boxShadow: 'none',
  }),
  menu: baseStyles => ({
    ...baseStyles,
    border: 'none',
    backgroundColor: 'rgb(255, 255, 255)',
    color: 'rgba(0, 0, 0, 0.87)',
    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    borderRadius: '4px',
    boxShadow:
      'rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px',
  }),
  option: () => ({
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  }),
  placeholder: baseStyles => ({
    ...baseStyles,
    color: '#5C6C80',
  }),
  noOptionsMessage: baseStyles => ({
    ...baseStyles,
    fontSize: '1.4rem',
  }),
  loadingMessage: baseStyles => ({
    ...baseStyles,
    fontSize: '1.4rem',
  }),
};

const Wrapper = styled.div<{ width?: string }>`
  ${props => (props.width ? `width: ${props.width};` : '')}
`;

const ControlSearchIcon = styled(SearchIcon)`
  padding-left: 1rem;
  color: hsl(0, 0%, 50%);
`;

const ClearButton = styled(IconButton)`
  margin-right: 1rem;
`;

const StyledCloseIcon = styled(CloseIcon)`
  font-size: 1.6rem;
  color: ${props => props.theme.custom.palette.darkText};
`;

const ControlWrapper = styled.div`
  width: 100%;
  font-family: Source Sans Pro, serif;
  font-weight: 400;
  line-height: 1.4375em;
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1.6rem;
  background-color: rgba(0, 0, 0, 0.06);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  transition: background-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  &.focused {
    background-color: rgba(0, 0, 0, 0.06);
    &:after {
      transform: scaleX(1) translateX(0);
    }
  }
  &:hover {
    background-color: rgba(0, 0, 0, 0.09);
    &:not(.disabled):before {
      border-bottom: 1px solid rgba(0, 0, 0, 0.87);
    }
  }
  &:before {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    pointer-events: none;
    content: '\\00a0';
  }
  &:after {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    transform: scaleX(0);
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    border-bottom: 2px solid #31887e;
    pointer-events: none;
    content: '';
  }
`;

const OptionWrapper = styled(MenuItem)`
  color: ${props => props.theme.custom.palette.darkText};
  padding: 0;
  &:hover {
    background-color: ${props => props.theme.custom.palette.primary100};
  }
  > * {
    padding: 1rem 1.6rem;
    width: 100%;
  }
`;

const MenuWrapper = styled(Paper)`
  position: relative;
`;

const AsyncPaginateCreatable = withAsyncPaginate(
  Creatable,
) as AsyncPaginateCreatableType;

export type AdditionalType = {
  page: number;
};

const Menu: React.FC<
  MenuProps<TypeaheadOption, true, GroupBase<TypeaheadOption>>
> = ({ children, ...props }) => {
  return (
    <MenuWrapper>
      <reactSelectComponents.Menu {...props}>
        {/* @ts-ignore find out how to pass custom props into Select type */}
        {props.selectProps.menuElementBefore?.(props)}
        {children}
      </reactSelectComponents.Menu>
    </MenuWrapper>
  );
};

const Control: React.FC<
  ControlProps<TypeaheadOption, true, GroupBase<TypeaheadOption>>
> = ({ children, ...props }) => {
  return (
    <ControlWrapper
      className={classNames({
        focused: props.isFocused,
      })}
    >
      <reactSelectComponents.Control {...props}>
        {/* @ts-ignore find out how to pass custom props into Select type */}
        {props.selectProps.controlBefore}
        {children}
        {props.selectProps.inputValue && (
          <ClearButton onClick={props.clearValue}>
            <StyledCloseIcon />
          </ClearButton>
        )}
      </reactSelectComponents.Control>
    </ControlWrapper>
  );
};

const Option: React.FC<
  OptionProps<TypeaheadOption, true, GroupBase<TypeaheadOption>>
> = ({ children, ...props }) => {
  return (
    <OptionWrapper title={typeof children === 'string' ? children : ''}>
      <reactSelectComponents.Option {...props}>
        {children}
      </reactSelectComponents.Option>
    </OptionWrapper>
  );
};

const Input: React.FC<
  InputProps<TypeaheadOption, true, GroupBase<TypeaheadOption>>
> = ({ children, ...props }) => {
  return (
    <reactSelectComponents.Input
      {...props}
      // @ts-ignore find out how to pass custom props into Select type
      maxLength={props.selectProps.maxLength}
    />
  );
};

export const Typeahead = React.forwardRef(
  (
    {
      hideDropdownIndicator,
      className,
      width,
      components,
      onChange,
      onCreate,
      isCreatable,
      isPaginatable,
      isMulti,
      ...props
    }: TypeaheadProps,
    ref: ForwardedRef<any>,
  ) => {
    const { t } = useTranslation();

    const onTypeaheadChanged = useCallback(
      (options: MultiValue<TypeaheadOption>) => {
        onChange?.(
          // @ts-ignore
          options.length > 0 ? (isMulti ? options : _.last(options)) : null,
        );
      },
      [isMulti, onChange],
    );

    const onInputChange = useCallback(
      (value: string, actionMeta: InputActionMeta) => {
        // reset old value when typing the new one
        if (!isMulti && props.value && actionMeta.action === 'input-change') {
          onChange?.(null);
        }
      },
      [isMulti, onChange, props.value],
    );

    const formatCreateLabel = useCallback(
      (value: string) => `${t('typeahead.create')} "${value.trim()}"`,
      [t],
    );

    const isValidNewOption = useCallback(
      (
        inputValue: string,
        selectedOptions: Options<TypeaheadOption | GroupedTypeaheadOption>,
        options: OptionsOrGroups<
          TypeaheadOption | GroupedTypeaheadOption,
          GroupBase<TypeaheadOption | GroupedTypeaheadOption>
        >,
      ) =>
        inputValue.trim().length > 0 &&
        ![...options, ...(selectedOptions || [])].find(
          it =>
            it.label?.toLowerCase() === inputValue.trim().toLowerCase() ||
            (it.options &&
              it.options.find(
                it =>
                  it.label?.toLowerCase() === inputValue.trim().toLowerCase(),
              )),
        ),
      [],
    );

    const shouldLoadMore = useMemo(() => {
      return isPaginatable ? props.shouldLoadMore : () => false;
    }, [isPaginatable, props.shouldLoadMore]);

    const SelectComponent = isCreatable
      ? AsyncPaginateCreatable
      : AsyncPaginate;

    return (
      <Wrapper className={className} width={width}>
        <SelectComponent
          selectRef={ref}
          openMenuOnClick={false}
          controlBefore={<ControlSearchIcon />}
          components={{
            Control,
            Menu,
            Option,
            Input,
            ...(hideDropdownIndicator ? { DropdownIndicator: null } : {}),
            ...components,
          }}
          noOptionsMessage={() => t('typeahead.notFound')}
          formatCreateLabel={formatCreateLabel}
          isValidNewOption={isValidNewOption}
          additional={{
            page: 1,
          }}
          debounceTimeout={USER_INPUT_DEBOUNCE}
          // @ts-ignore TODO: find out the the right type
          {...props}
          // we use multiselect for single select typeahead because for when it's multi
          // the cursor nicely displays at the end of input field whereas when it's single
          // select it is for some reason places before the input value
          isMulti
          shouldLoadMore={shouldLoadMore}
          onChange={onTypeaheadChanged}
          onInputChange={onInputChange}
          onCreateOption={onCreate}
          styles={{
            ...styles,
            ...props.styles,
          }}
        />
      </Wrapper>
    );
  },
);
