import React, { useCallback, useEffect, useMemo, useState } from 'react';

import styled from 'styled-components';

import {
  APIProject,
  APIProjectStatus,
  APIUser,
  APIUserType,
} from '@cd3p/core/types/api';
import {
  BreadcrumbsViewAll,
  Button,
  CircularProgress,
  Grid,
  LoadingButton,
  MapOverlay,
  Stack,
  TrackingMap,
  Typography,
} from 'components';
import { useAddressToCoordinates } from 'hooks/useAddressToCoordinates';
import { useHandleApiResult } from 'hooks/useHandleApiResult';
import { emit } from 'hooks/usePubSub';
import { SingleValue } from 'react-select';

import {
  FormProvider,
  useForm,
  useLocation,
  useNavigate,
  useParams,
  useSelector,
  useTranslation,
} from 'third-party';

import { APP_EVENTS } from 'constants/appEvent';
import { allOrdersUrl, customerProjectDetailsUrl } from 'constants/url';

import { useCache } from 'modules/cache';
import { useProject } from 'modules/project';

import { appSelectors } from 'selectors';

import { MapTypes } from 'components/TrackingMap/TrackingMap';

import { roundLocationCoordinates } from 'features/OrderView/orderViewHelpers';
import { ProjectEditForm } from 'features/ProjectView/ProjectEditForm';

import {
  LocationCoordinates,
  LocationT,
  TypeaheadDataOption,
  TypeaheadOption,
} from 'types/app';

const Wrapper = styled(Stack)`
  flex-direction: row;
  flex-grow: 1;
  min-height: 0;
`;

const LeftPanel = styled(Stack)`
  flex-shrink: 0;
  width: 45rem;
  padding: 0rem;
  border-right: 2px solid ${props => props.theme.custom.palette.muted100};
`;

const HeaderWrapper = styled(Stack)`
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.theme.custom.palette.muted100};
  padding: 2.4rem 1.3rem 1.6rem;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
`;

const LeftContentWrapper = styled(Stack)`
  flex-grow: 1;
  padding: 1rem 1.3rem;
  min-height: 0;
`;

const Header = styled(Typography)`
  color: ${props => props.theme.custom.palette.secondary500};
  word-break: break-word;
  flex-grow: 1;
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1.6rem;
`;

const ScrollableArea = styled(Grid)`
  flex-grow: 1;
  min-height: 0;
  // this padding is needed to avoid
  // menu sides cutting by overflow hidden
  padding: 0 0.2rem;
  overflow-y: auto;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 0;
`;

const RightContent = styled(Stack)`
  flex-grow: 1;
`;

type Props = {
  projectId?: string;
  companyId?: string;
  defaultValues?: ProjectFormData;
  showBreadcrumbs?: boolean;
  onClose?: (project?: APIProject) => void;
};

export enum ProjectFormFields {
  ProjectName = 'name',
  ProjectAddress = 'address',
  ProjectFreeFormAddress = 'freeFormAddress',
  ProjectDeliveryLocation = 'deliveryLocation',
  ProjectDeliveryCoordinates = 'deliveryCoordinates',
  ProjectCoordinates = 'coordinates',
  ProjectDescription = 'description',
  IsCustomMixTypes = 'isCustomMixTypes',
  IsCustomAdditiveTypes = 'isCustomAdditiveTypes',
  SubscribedUsers = 'subscribedUsers',
}

export type ProjectFormData = {
  [ProjectFormFields.ProjectName]: string;
  [ProjectFormFields.ProjectAddress]: SingleValue<TypeaheadOption>;
  [ProjectFormFields.ProjectFreeFormAddress]?: string;
  [ProjectFormFields.ProjectCoordinates]?: LocationT | null;
  [ProjectFormFields.ProjectDeliveryLocation]?: string;
  [ProjectFormFields.ProjectDeliveryCoordinates]?: LocationT | null;
  [ProjectFormFields.ProjectDescription]: string;
  [ProjectFormFields.IsCustomMixTypes]: boolean;
  [ProjectFormFields.IsCustomAdditiveTypes]: boolean;
  [ProjectFormFields.SubscribedUsers]?: TypeaheadDataOption<APIUser>[];
};

const projectDefaultData = {
  [ProjectFormFields.ProjectName]: '',
  [ProjectFormFields.ProjectAddress]: null,
  [ProjectFormFields.ProjectFreeFormAddress]: '',
  [ProjectFormFields.ProjectDescription]: '',
  [ProjectFormFields.IsCustomMixTypes]: false,
  [ProjectFormFields.IsCustomAdditiveTypes]: false,
  [ProjectFormFields.SubscribedUsers]: [],
};

export const ProjectCreateEditForm: React.FC<Props> = ({
  projectId,
  companyId,
  defaultValues = projectDefaultData,
  showBreadcrumbs = true,
  onClose,
}) => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();

  const isEditMode = !!companyId && !!projectId;

  const { customerId: paramsCustomerId } = useParams();

  const { loadAddressByLocation } = useCache();

  const appCustomerId = useSelector(appSelectors.companyId);
  const provider = useSelector(appSelectors.provider);

  const customerId = paramsCustomerId || appCustomerId;

  const addressToCoordinates = useAddressToCoordinates();

  const { backUrl, backUrlLabel } = useMemo(() => {
    return state?.backUrl
      ? {
          backUrl: state.backUrl,
          backUrlLabel: state.backUrlLabel || t('common.back'),
        }
      : {
          backUrl: allOrdersUrl(),
          backUrlLabel: t('order.breadcrumbs.viewAll'),
        };
  }, [state?.backUrl, state?.backUrlLabel, t]);

  const { createProject, updateProject } = useProject();
  const userType = useSelector(appSelectors.userType);
  const handleApiResult = useHandleApiResult();

  const formMethods = useForm<ProjectFormData>({
    mode: 'onChange',
    defaultValues,
  });

  const {
    setValue,
    watch,
    handleSubmit,
    formState: { isValid, isDirty },
  } = formMethods;

  const watchedProjectAddress = watch(ProjectFormFields.ProjectAddress);
  const watchedProjectCoordinates = watch(ProjectFormFields.ProjectCoordinates);
  const watchedProjectLocation = watch(
    ProjectFormFields.ProjectDeliveryLocation,
  );
  const watchedDeliveryCoordinates = watch(
    ProjectFormFields.ProjectDeliveryCoordinates,
  );

  const hasDeliveryAddress = useMemo(() => {
    return (
      !!watchedProjectAddress?.__isNew__ ||
      (isEditMode && !!watchedProjectLocation)
    );
  }, [isEditMode, watchedProjectLocation, watchedProjectAddress]);

  const [isPending, setIsPending] = useState(false);

  const onManualDeliveryLocationChange = useCallback(
    async (option: LocationCoordinates) => {
      if (option?.lng && option?.lat) {
        const deliveryCoordinates = roundLocationCoordinates({
          longitude: option.lng,
          latitude: option.lat,
        });
        setValue(
          ProjectFormFields.ProjectDeliveryCoordinates,
          deliveryCoordinates,
          {
            shouldValidate: true,
            shouldDirty: true,
          },
        );
        const result = await loadAddressByLocation(
          deliveryCoordinates.latitude,
          deliveryCoordinates.longitude,
        );
        if (!result.error && result.payload[0]) {
          setValue(
            ProjectFormFields.ProjectDeliveryLocation,
            result.payload[0].address,
            {
              shouldValidate: true,
              shouldDirty: true,
            },
          );
        }
      }
    },
    [loadAddressByLocation, setValue],
  );

  const onSubmit = async (data: ProjectFormData) => {
    if (!isValid) {
      return;
    }
    setIsPending(true);
    await handleApiResult<APIProject>(
      () => {
        const requestData = {
          projectStatus: APIProjectStatus.Active,
          companyId: customerId,
          name: data[ProjectFormFields.ProjectName].trim(),
          address: hasDeliveryAddress
            ? data[ProjectFormFields.ProjectFreeFormAddress]?.trim()
            : data[ProjectFormFields.ProjectAddress]?.value,
          ...(hasDeliveryAddress && {
            deliveryAddress: data[ProjectFormFields.ProjectDeliveryLocation],
            latitude:
              data[ProjectFormFields.ProjectDeliveryCoordinates]?.latitude,
            longitude:
              data[ProjectFormFields.ProjectDeliveryCoordinates]?.longitude,
          }),
          description: data[ProjectFormFields.ProjectDescription]?.trim(),
          ...(userType === APIUserType.Dispatcher
            ? {
                isCustomAdditives:
                  data[ProjectFormFields.IsCustomAdditiveTypes],
                isCustomMixTypes: data[ProjectFormFields.IsCustomMixTypes],
              }
            : {}),
        };

        if (isEditMode) {
          return updateProject(projectId, companyId, {
            ...requestData,
          });
        } else {
          return createProject({
            ...requestData,
            subscribedUsersIds: data[ProjectFormFields.SubscribedUsers]!.map(
              option => option.value,
            ),
          });
        }
      },
      ({ showBaseToast, result }) => {
        const { payload } = result;
        showBaseToast(
          t(
            isEditMode
              ? 'projectDetails.successUpdate'
              : 'addProject.successCreateMessage',
            {
              projectName: payload.name,
            },
          ),
        );
        onCloseCallback(payload);
        if (!onClose) {
          if (payload.company.id && payload.id) {
            navigate(customerProjectDetailsUrl(payload.company.id, payload.id));
          }
        }
      },
    );
    setIsPending(false);
  };

  const onCloseCallback = (result?: APIProject) => {
    if (onClose) {
      onClose?.(result);
    } else {
      navigate(backUrl);
    }
  };

  useEffect(() => {
    const updateAddress = async () => {
      if (watchedProjectAddress) {
        if (!hasDeliveryAddress) {
          setValue(
            ProjectFormFields.ProjectCoordinates,
            await addressToCoordinates(watchedProjectAddress.value),
          );
        }
      } else {
        setValue(ProjectFormFields.ProjectCoordinates, null);
        setValue(ProjectFormFields.ProjectDeliveryLocation, '');
        setValue(ProjectFormFields.ProjectDeliveryCoordinates, null);
        emit(APP_EVENTS.AUTOCENTER_MAP);
      }
    };
    updateAddress();
  }, [
    hasDeliveryAddress,
    addressToCoordinates,
    setValue,
    watchedProjectAddress,
  ]);

  const mapDefaultCenter = useMemo(
    () => ({
      lat: provider?.centroidLatitude || 0,
      lng: provider?.centroidLongitude || 0,
    }),
    [provider],
  );

  const sites = useMemo(() => {
    if (watchedProjectCoordinates) {
      return [watchedProjectCoordinates];
    } else if (watchedDeliveryCoordinates?.latitude) {
      return [watchedDeliveryCoordinates];
    } else if (hasDeliveryAddress) {
      return [
        {
          latitude: mapDefaultCenter.lat,
          longitude: mapDefaultCenter.lng,
        },
      ];
    }
    return [];
  }, [
    hasDeliveryAddress,
    mapDefaultCenter.lat,
    mapDefaultCenter.lng,
    watchedDeliveryCoordinates,
    watchedProjectCoordinates,
  ]);

  return (
    <Wrapper>
      <LeftPanel>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <HeaderWrapper>
            {showBreadcrumbs && (
              <BreadcrumbsViewAll url={backUrl} text={backUrlLabel} />
            )}
            <Stack direction="row" alignSelf="stretch" alignItems="center">
              <Header variant="h4">
                {isEditMode ? t('editProject.title') : t('addProject.title')}
              </Header>
              <ActionButtons>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => onCloseCallback()}
                  disabled={isPending}
                >
                  {t('common.cancel')}
                </Button>
                <LoadingButton
                  loading={isPending}
                  variant="contained"
                  color="primary"
                  disabled={!isValid || isPending || !isDirty}
                  type="submit"
                  sx={{ minWidth: '8.8rem' }}
                >
                  {isEditMode
                    ? t('common.save')
                    : t('addProject.confirmButtonLabel')}
                </LoadingButton>
              </ActionButtons>
            </Stack>
          </HeaderWrapper>
          <LeftContentWrapper>
            <ScrollableArea>
              <FormProvider {...formMethods}>
                <ProjectEditForm
                  isEditMode={isEditMode}
                  isDisabled={isPending}
                  hasDeliveryAddress={hasDeliveryAddress}
                />
              </FormProvider>
            </ScrollableArea>
          </LeftContentWrapper>
        </Form>
      </LeftPanel>
      <RightContent>
        <MapOverlay
          enabled={!sites.length}
          text={t('addProject.emptyAddressMapText')}
        >
          <TrackingMap
            autoZoomCorrection={0}
            placesSearch={hasDeliveryAddress}
            center={sites.length ? undefined : mapDefaultCenter}
            sites={sites}
            isSitePinDraggable={hasDeliveryAddress}
            onManualDeliveryLocationChange={onManualDeliveryLocationChange}
            defaultMapType={MapTypes.Hybrid}
          />
        </MapOverlay>
      </RightContent>
    </Wrapper>
  );
};

export const ProjectCreateEditView = () => {
  const { projectId, customerId } = useParams();

  const [projectData, setProjectData] = useState<null | ProjectFormData>(null);

  const handleApiResult = useHandleApiResult();

  const addressToCoordinates = useAddressToCoordinates();

  const { loadProject } = useProject();

  useEffect(() => {
    const fetchProjectData = async (projectId: string) => {
      handleApiResult<APIProject>(
        () => loadProject(projectId),
        async ({ result }) => {
          const data = result.payload;
          setProjectData({
            [ProjectFormFields.ProjectName]: data.name,
            [ProjectFormFields.ProjectFreeFormAddress]: '',
            [ProjectFormFields.ProjectAddress]: {
              label: data.address,
              value: data.address,
            },
            ...(data.deliveryAddress
              ? {
                  [ProjectFormFields.ProjectDeliveryLocation]:
                    data.deliveryAddress,
                  [ProjectFormFields.ProjectDeliveryCoordinates]: {
                    latitude: data.latitude,
                    longitude: data.longitude,
                  },
                }
              : {
                  [ProjectFormFields.ProjectCoordinates]:
                    await addressToCoordinates(data.address),
                }),
            [ProjectFormFields.ProjectDescription]: data.description || '',
            [ProjectFormFields.IsCustomMixTypes]:
              data.projectOptions?.['containsCustomMixTypes'] === 'true' ||
              projectDefaultData[ProjectFormFields.IsCustomMixTypes],
            [ProjectFormFields.IsCustomAdditiveTypes]:
              data.projectOptions?.['containsCustomAdditives'] === 'true' ||
              projectDefaultData[ProjectFormFields.IsCustomAdditiveTypes],
          });
        },
      );
    };

    if (projectId) {
      fetchProjectData(projectId);
    }
  }, [addressToCoordinates, handleApiResult, loadProject, projectId]);

  return projectId && !projectData ? (
    <CircularProgress />
  ) : (
    <ProjectCreateEditForm
      projectId={projectId}
      companyId={customerId}
      defaultValues={
        projectId && projectData ? projectData : projectDefaultData
      }
    />
  );
};
