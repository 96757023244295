import React, {
  FC,
  KeyboardEventHandler,
  useCallback,
  useId,
  useRef,
  useState,
} from 'react';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import { Menu, MenuItem, MenuItemProps, MenuProps } from '@mui/material';

type MoreMenuItemProps = {
  button?: true;
  label: string;
  MenuProps?: Omit<MenuProps, 'open' | 'onClose' | 'anchorEl' | 'onKeyDown'>;
} & Omit<MenuItemProps, 'onKeyDown' | 'onMouseEnter' | 'onMouseLeave'>;

export const SubMenu: FC<MoreMenuItemProps> = ({
  label,
  children,
  id,
  MenuProps,
  ...other
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);

  const menuItemRef = useRef<HTMLLIElement>(null);
  const menuItemId = useId();
  const normMenuItemId = id ?? menuItemId;

  const handleItemKeyDown: KeyboardEventHandler<HTMLLIElement> = event => {
    if (
      (event.key !== 'ArrowRight' && event.key !== 'Enter') ||
      event.ctrlKey ||
      event.shiftKey ||
      event.altKey ||
      event.metaKey
    ) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    setIsOpen(true);
  };

  const handleMenuKeyDown: KeyboardEventHandler<HTMLDivElement> = event => {
    event.stopPropagation();
    if (
      (event.key !== 'ArrowLeft' && event.key !== 'Escape') ||
      event.ctrlKey ||
      event.shiftKey ||
      event.altKey ||
      event.metaKey
    ) {
      return;
    }

    event.preventDefault();
    setIsOpen(false);
  };

  return (
    <MenuItem
      {...other}
      onKeyDown={handleItemKeyDown}
      ref={menuItemRef}
      sx={[
        { justifyContent: 'space-between' },
        isOpen && { backgroundColor: 'action.hover' },
      ]}
      onMouseEnter={open}
      onMouseLeave={close}
      id={normMenuItemId}
    >
      <span>{label}</span>
      <NavigateNextIcon sx={{ fontSize: '1.2rem' }} />
      <Menu
        TransitionProps={{ onExited: () => menuItemRef.current?.focus() }}
        disableRestoreFocus
        onKeyDown={handleMenuKeyDown}
        sx={{
          pointerEvents: 'none',
          '& .MuiList-root': {
            pointerEvents: 'auto',
          },
        }}
        MenuListProps={{
          ...MenuProps?.MenuListProps,
          'aria-labelledby': normMenuItemId,
        }}
        anchorEl={menuItemRef.current}
        open={isOpen}
        onClose={close}
        anchorOrigin={
          MenuProps?.anchorOrigin ?? { vertical: 'center', horizontal: 'right' }
        }
        transformOrigin={
          MenuProps?.transformOrigin ?? {
            vertical: 'center',
            horizontal: 'left',
          }
        }
      >
        {children}
      </Menu>
    </MenuItem>
  );
};
