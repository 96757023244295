import React from 'react';

import styled from 'styled-components';

const Wrapper = styled.div`
  padding: ${props => props.theme.custom.dimensions.viewVerticalPadding}rem
    ${props => props.theme.custom.dimensions.viewHorizontalPadding}rem;
  overflow-y: auto;
`;

interface Props {
  children: React.ReactNode;
  className?: string;
}

export const ViewWrapper: React.FC<Props> = ({ className, children }) => {
  return <Wrapper className={className}>{children}</Wrapper>;
};
