import 'wdyr';

import React from 'react';

import { CaptureConsole } from '@sentry/integrations';
import 'i18n';
import ReactDOM from 'react-dom/client';

import './init.ts';

import {
  Sentry,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'third-party';

import { RootContainer } from 'features/Root/Root';

const requireAll = (r: any) => r.keys().forEach(r);
requireAll(require.context('images', true, /\.svg$/));

if (process.env['ENV'] !== 'local') {
  // add to list errors, that should be ignored
  const IGNORE_ERRORS = [
    'disconnected with error',
    'request is not allowed by the user agent',
    'timeout elapsed without receiving a message',
    'play() failed because the user',
  ];

  Sentry.init({
    dsn: 'https://bc4e018f2d09beaed75a7c3db6db3ce4@log.concretely.co/4',
    release: APPLICATION_VERSION,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    beforeSend(event, hint) {
      const error = hint.originalException as any;
      if (
        error &&
        (IGNORE_ERRORS.some(it => new RegExp(it, 'i').test(error?.message)) ||
          IGNORE_ERRORS.some(it => new RegExp(it, 'i').test(error)))
      ) {
        return null;
      }

      return event;
    },

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.05,
    environment: process.env['ENV'],
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
      new CaptureConsole({
        levels: ['error'],
      }) as any,
    ],
  });
}

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(<RootContainer />);
