import { CoreState } from '../modules';
import { createSelector } from '../third-party';
import { APIApplicationVersion } from '../types/api';
import { getProviderFromUser } from '../utils/app';

export { createSelector } from 'reselect';

export const user = (state: CoreState) => state.app.user;
export const userProviders = (state: CoreState) => state.app.user?.providers;
export const userCompanies = (state: CoreState) => state.app.user?.companies;

export const globalCacheVersion = (state: CoreState) =>
  state.app.user?.globalCacheVersion;

export const userProvidersIds = createSelector(
  userProviders,
  (providers) => providers?.map((providers) => providers.id || '') || [],
);

export const userCompaniesIds = createSelector(
  userCompanies,
  (companies) => companies?.map((companies) => companies.id || '') || [],
);

export const newApplicationVersions = (state: CoreState) =>
  state.app.user?.newApplicationVersions as APIApplicationVersion[];

export const userType = (state: CoreState) => state.app.user?.userType;
export const userOptions = (state: CoreState) => state.app.user?.userOptions;
export const provider = (state: CoreState) =>
  getProviderFromUser(state.app.user);
export const providerId = createSelector(
  provider,
  (provider) => provider?.id || '',
);
export const providerAddress = createSelector(
  provider,
  (provider) => provider?.officeAddress,
);
export const providerName = createSelector(
  provider,
  (provider) => provider?.providerName,
);
export const providerPhone = createSelector(
  provider,
  (provider) => provider?.phone,
);
export const providerTimeZone = (state: CoreState) =>
  state.app?.providerTimeZone;
export const isDriverAssignmentEnabled = (state: CoreState) =>
  state.app.isDriverAssignmentEnabled;
export const isBatchSoftwareIntegrationEnabled = (state: CoreState) =>
  state.app.isBatchSoftwareIntegrationEnabled;
export const isProviderSettingsLoaded = (state: CoreState) =>
  state.app.isProviderSettingsLoaded;
export const isChatFeatureEnabled = (state: CoreState) =>
  state.app.isChatFeatureEnabled;
export const isApiDocsFeatureEnabled = (state: CoreState) =>
  state.app.isApiDocsFeatureEnabled;
export const isProjectCreationByContractorEnabled = (state: CoreState) =>
  state.app.isProjectCreationByContractorEnabled;
export const orderConfirmationSecondReminderEnabled = (state: CoreState) =>
  state.app.orderConfirmationSecondReminderEnabled;
export const orderConfirmationFirstReminderHours = (state: CoreState) =>
  state.app.orderConfirmationFirstReminderHours;
export const orderConfirmationSecondReminderHours = (state: CoreState) =>
  state.app.orderConfirmationSecondReminderHours;
export const isTimeStampEnabled = (state: CoreState) =>
  state.app.isTimeStampEnabled;
export const openedChatOrderId = (state: CoreState) =>
  state.app.openedChatOrderId;
export const updateUserPending = (state: CoreState) =>
  state.app.updateUserPending;
export const contractorOrderEditingAllowedStates = (state: CoreState) =>
  state.app.contractorOrderEditingAllowedStates;

export const getUserInfoPending = (state: CoreState) =>
  state.app.getUserInfoPending;
export const updateProviderSettingsPending = (state: CoreState) =>
  state.app.updateProviderSettingsPending;
export const providerAdminEmail = (state: CoreState) =>
  state.app.providerAdminEmail;
export const loadProviderSettingsPending = (state: CoreState) =>
  state.app.loadProviderSettingsPending;
