import { useMemo } from 'react';

import {
  APIProject,
  APIProjectEditableParams,
  APIProjectsList,
  APIProjectsListParams,
  APISortOrder,
} from '@cd3p/core/types/api';
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_START } from '@cd3p/core/utils/lists';
import { simplifyBuilder } from '@cd3p/core/utils/sra';

import {
  bindActionCreators,
  httpMethod,
  useDispatch,
  useSelector,
} from 'third-party';

import {
  fetchProjectsListEndpoint,
  projectEndpoint,
} from 'constants/endpoints';

import { appSelectors } from 'selectors';

export type ProjectsListColumnId = keyof APIProject;

export type AllProjectsListState = {
  items: APIProjectsList['result'];
  pagination: {
    pageNumber: APIProjectsList['pageNumber'];
    pageSize: APIProjectsList['pageSize'];
  };
  sorting: {
    sortField: ProjectsListColumnId;
    sortOrder: APISortOrder;
  }[];
  filters: Record<any, any>;
  count: number;
  projectsLoaded: boolean;
  loadAllProjectsPending: boolean;
  loadMoreAllProjectsPending: boolean;
  allProjectsListErrorText: string | null;
};

export const projectsDefaultFilter = {};

export const allProjectsListState: AllProjectsListState = {
  items: [],
  pagination: { pageNumber: 1, pageSize: DEFAULT_PAGE_SIZE },
  sorting: [
    {
      sortField: 'name',
      sortOrder: APISortOrder.ASC,
    },
  ],
  filters: projectsDefaultFilter,
  count: 0,
  projectsLoaded: false,
  loadAllProjectsPending: false,
  loadMoreAllProjectsPending: false,
  allProjectsListErrorText: null,
};

const prepareFetchBody = (body: APIProjectsListParams) => ({
  ...body,
  pageNumber: body?.pageNumber || DEFAULT_PAGE_START,
  pageSize: body?.pageSize || DEFAULT_PAGE_SIZE,
});

const builder = simplifyBuilder(allProjectsListState, {});

const loadAllProjects = builder.createServerAction(
  (providerId: string, body: APIProjectsListParams) => ({
    name: 'loadAllProjects',
    url: fetchProjectsListEndpoint(providerId),
    body: prepareFetchBody(body),
    method: httpMethod.post,
    onSuccess: (state: AllProjectsListState, payload: APIProjectsList) => {
      return {
        items: payload.result,
        projectsLoaded: true,
        pagination: {
          pageNumber: payload.pageNumber,
          pageSize: payload.pageSize,
        },
        count: payload.count,
      };
    },
  }),
);

const deleteProject = builder.createServerAction(
  (providerId: string, projectId: string) => ({
    name: 'deleteProject',
    url: projectEndpoint(providerId, projectId),
    method: httpMethod.delete,
    body: {},
  }),
);

const loadMoreAllProjects = builder.createServerAction(
  (providerId: string, body: APIProjectsListParams) => ({
    name: 'loadMoreAllProjects',
    url: fetchProjectsListEndpoint(providerId),
    body: prepareFetchBody(body),
    method: httpMethod.post,
    onSuccess: (state: AllProjectsListState, payload: APIProjectsList) => {
      return {
        items: [...state.items, ...payload.result],
      };
    },
  }),
);

const updateProjectInList = builder.createServerAction(
  (
    providerId: string,
    projectId: string,
    companyId: string,
    body: Partial<APIProjectEditableParams>,
  ) => ({
    name: 'updateProject',
    url: projectEndpoint(providerId, projectId),
    method: httpMethod.post,
    body: {
      ...body,
      companyId,
    },
    onSuccess: (state: AllProjectsListState, payload: APIProject) => ({
      items: state.items?.map(it => (it.id == projectId ? payload : it)),
    }),
  }),
);

const updateAllProjectsListSorting = builder.createReduxAction(
  (newSorting: AllProjectsListState['sorting']) => ({
    name: 'updateAllProjectsListSorting',
    updater: () => ({
      sorting: newSorting,
    }),
  }),
);

const updateAllProjectsListFilters = builder.createReduxAction(
  (newFilters: AllProjectsListState['filters']) => ({
    name: 'updateAllProjectsListFilters',
    updater: state => ({
      filters: {
        ...state.filters,
        ...newFilters,
      },
    }),
  }),
);

const resetAllProjectsListFilters = builder.createReduxAction(() => ({
  name: 'resetAllProjectsListFilters',
  updater: () => ({
    filters: projectsDefaultFilter,
  }),
}));

export const useAllProjectsList = () => {
  const dispatch = useDispatch();
  const providerId = useSelector(appSelectors.providerId);

  return useMemo(
    () =>
      bindActionCreators(
        {
          loadAllProjects: loadAllProjects.bind(null, providerId),
          loadMoreAllProjects: loadMoreAllProjects.bind(null, providerId),
          deleteProject: deleteProject.bind(null, providerId),
          updateProjectInList: updateProjectInList.bind(null, providerId),
          // without binding TS doesn't infer type for module functions
          updateAllProjectsListSorting: updateAllProjectsListSorting.bind(null),
          updateAllProjectsListFilters: updateAllProjectsListFilters.bind(null),
          resetAllProjectsListFilters: resetAllProjectsListFilters.bind(null),
        },
        dispatch,
      ),
    [dispatch, providerId],
  );
};

export const allProjectsListReducer = builder.getReducers();
