import { useEffect, useMemo, useState } from 'react';

type AfterHoursNoticeProps = {
  enabled?: boolean;
  renderNoticeElement: (message: string) => React.ReactElement;
  providerId?: string;
};

export const useAfterHoursNoticeFactory = ({
  enabled,
  renderNoticeElement,
}: AfterHoursNoticeProps) => {
  const [afterHoursMessage, setAfterHoursMessage] = useState('');
  const [afterHoursEndsInMs, setAfterHoursEndsInMs] = useState(0);

  const isWorkingHours = afterHoursEndsInMs === 0;

  useEffect(() => {
    if (afterHoursEndsInMs > 0) {
      const timeoutId = setTimeout(() => {
        setAfterHoursEndsInMs(0);
      }, afterHoursEndsInMs);
      // Cleanup function to clear the timeout if the component unmounts
      return () => clearTimeout(timeoutId);
    }
    return;
  }, [afterHoursEndsInMs]);

  const afterHoursNotice = useMemo(() => {
    return !isWorkingHours && enabled && renderNoticeElement(afterHoursMessage);
  }, [afterHoursMessage, enabled, isWorkingHours, renderNoticeElement]);

  return useMemo(
    () => ({
      showAfterHoursNotice: !isWorkingHours && enabled,
      afterHoursNotice,
      setAfterHoursMessage,
      setAfterHoursEndsInMs,
    }),
    [isWorkingHours, enabled, afterHoursNotice],
  );
};
