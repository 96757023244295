import React, { useCallback } from 'react';

import styled from 'styled-components';

import { Notification } from '@cd3p/core/modules/notifications';
import { APIRequiredAction, APIRequiredActionType } from '@cd3p/core/types/api';
import {
  formatDateAndTime,
  formatDisplayedDate,
} from '@cd3p/core/utils/common';
import { Trans } from 'components';

import {
  _,
  i18n,
  useLocation,
  useNavigate,
  useSelector,
  useTranslation,
} from 'third-party';

import { orderUrl, projectOrdersUrl } from 'constants/url';

import { NotificationSeverity, useNotifications } from 'modules/notifications';

import { appSelectors } from 'selectors';

import { showPushNotification } from 'utils/pushNotification';

const NotificationLink = styled.span`
  text-decoration: none;
  font-weight: 700;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const approvalStatusColors = {
  [APIRequiredActionType.OrderChangesApproved]: NotificationSeverity.Success,
  [APIRequiredActionType.OrderChangesPartiallyApproved]:
    NotificationSeverity.Success,
  [APIRequiredActionType.OrderChangesRejected]: NotificationSeverity.Info,
};

const approvalTitle = {
  [APIRequiredActionType.OrderChangesApproved]: i18n.t(
    'editOrder.notificationTitle.approvalReceived',
  ),
  [APIRequiredActionType.OrderChangesPartiallyApproved]: i18n.t(
    'editOrder.notificationTitle.partialApprovalReceived',
  ),
  [APIRequiredActionType.OrderChangesRejected]: i18n.t(
    'editOrder.notificationTitle.approvalRejected',
  ),
};

const getApprovalNotificationMessage = ({
  notificationType,
  orderName,
  providerName,
  onOrderNameClick,
}: {
  notificationType:
    | APIRequiredActionType.OrderChangesPartiallyApproved
    | APIRequiredActionType.OrderChangesApproved
    | APIRequiredActionType.OrderChangesRejected;
  orderName: string | null;
  providerName: string;
  onOrderNameClick: () => void;
}) => {
  const linkElement = (
    <NotificationLink onClick={onOrderNameClick}>{orderName}</NotificationLink>
  );

  const approvalMessage = {
    [APIRequiredActionType.OrderChangesPartiallyApproved]: (
      <Trans i18nKey="order.notification.approvedMessage" shouldUnescape>
        Requested changes to your order {linkElement} have been partially
        approved by {{ providerName: providerName }}.
      </Trans>
    ),
    [APIRequiredActionType.OrderChangesRejected]: (
      <Trans i18nKey="order.notification.approvedMessage" shouldUnescape>
        Requested changes to your order {linkElement} have been rejected by{' '}
        {{ providerName: providerName }}.
      </Trans>
    ),
    [APIRequiredActionType.OrderChangesApproved]: (
      <Trans i18nKey="order.notification.approvedMessage" shouldUnescape>
        Requested changes to your order {linkElement} have been approved by{' '}
        {{ providerName: providerName }}.
      </Trans>
    ),
  };

  return approvalMessage[notificationType] || '';
};

const getNotificationMessage = ({
  notificationType,
  orderName,
  companyName,
  providerName,
  onClick,
}: {
  notificationType:
    | APIRequiredActionType.OrderCancellationSubmitted
    | APIRequiredActionType.OrderCallBackSubmitted
    | APIRequiredActionType.OrderApproved
    | APIRequiredActionType.OrderCancelled
    | APIRequiredActionType.OrderDeclined;
  orderName?: string | null;
  companyName?: string | null;
  providerName?: string | null;
  onClick?: () => void;
}) => {
  const linkElement = (
    <NotificationLink onClick={onClick}>{orderName}</NotificationLink>
  );

  const notificationMessages = {
    [APIRequiredActionType.OrderCallBackSubmitted]: (
      <Trans
        i18nKey="requiredActions.orderCallbackSubmitted.message"
        shouldUnescape
      >
        {{ companyName }} has issued a call back for additional concrete on{' '}
        {linkElement}.{' '}
        <NotificationLink onClick={onClick}>View Details</NotificationLink>
      </Trans>
    ),
    [APIRequiredActionType.OrderApproved]: (
      <Trans i18nKey="order.approveOrderNotification.message" shouldUnescape>
        Your Order {linkElement} has been approved by{' '}
        {{ providerName: providerName }}.
      </Trans>
    ),
    [APIRequiredActionType.OrderCancelled]: (
      <Trans i18nKey="order.notification.canceledMessage" shouldUnescape>
        Your Order {linkElement} has been canceled.
      </Trans>
    ),
    [APIRequiredActionType.OrderDeclined]: (
      <Trans i18nKey="order.notification.canceledMessage" shouldUnescape>
        {providerName} has declined your request for {linkElement}. Your order
        will not be fulfilled.
      </Trans>
    ),

    [APIRequiredActionType.OrderCancellationSubmitted]: (
      <Trans i18nKey="order.cancelOrderNotification.message" shouldUnescape>
        {{ companyName }} has canceled order {{ orderName }}. Please ensure your
        batching system is updated accordingly.
      </Trans>
    ),
  };

  return notificationMessages[notificationType] || '';
};

export const useRequiredActionsNotifications = () => {
  const { t } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();
  const { addNotification } = useNotifications();
  const userProviders = useSelector(appSelectors.userProviders);
  const companyId = useSelector(appSelectors.companyId);

  const getRequiredActionProviderName = useCallback(
    (providerId: string) => {
      return _.find(userProviders, {
        id: providerId,
      })?.providerName;
    },
    [userProviders],
  );

  const onNotificationClick = useCallback(
    (requiredAction: APIRequiredAction) => {
      navigate(orderUrl(requiredAction.data.id as string), {
        state: { backUrl: location.pathname },
      });
    },
    [location.pathname, navigate],
  );

  const navigateToNewOrder = useCallback(
    (orderId: number | string) => {
      navigate(orderUrl(orderId), { state: { backUrl: location.pathname } });
    },
    [location.pathname, navigate],
  );

  const navigateToProject = useCallback(
    (projectId: string) => {
      navigate(projectOrdersUrl(projectId, companyId));
    },
    [companyId, navigate],
  );

  const showRequiredActionToast = useCallback(
    (providerId: string, requiredAction: APIRequiredAction) => {
      const providerName = getRequiredActionProviderName(
        requiredAction?.providerId,
      );
      const notificationType = requiredAction?.notificationType;
      const { data } = requiredAction;

      let notification: Notification = {};

      if (notificationType === APIRequiredActionType.OrderCallBackSubmitted) {
        notification = {
          providerId,
          sound: 'default',
          severity: NotificationSeverity.Success,
          title: t('requiredActions.orderCallbackSubmitted.title'),
          onClick: closeNotification => {
            closeNotification();
            onNotificationClick(requiredAction);
          },
          message: ({ closeWithCallback }) =>
            getNotificationMessage({
              notificationType: APIRequiredActionType.OrderCallBackSubmitted,
              orderName: data?.orderName,
              companyName: data?.company,
              onClick: closeWithCallback(() =>
                onNotificationClick(requiredAction),
              ),
            }),
        };
      }

      if (notificationType === APIRequiredActionType.OrderCallBackConfirmed) {
        notification = {
          providerId,
          sound: 'default',
          severity: NotificationSeverity.Success,
          title: t('requiredActions.callbackConfirmed.title'),
          onClick: closeNotification => {
            closeNotification();
            onNotificationClick(requiredAction);
          },
          message: t('requiredActions.callbackConfirmed.text'),
        };
      }

      if (notificationType === APIRequiredActionType.OrderNumberMissing) {
        notification = {
          providerId,
          sound: 'default',
          severity: NotificationSeverity.Warning,
          title: t('requiredActions.orderNumberMissing.title'),
          onClick: closeNotification => {
            closeNotification();
            onNotificationClick(requiredAction);
          },
          message: t('requiredActions.orderNumberMissing.text', {
            orderName: data?.orderName,
          }),
        };
      }

      if (notificationType === APIRequiredActionType.OrderCallBackPending) {
        notification = {
          providerId,
          sound: 'default',
          severity: NotificationSeverity.Success,
          title: t('requiredActions.callbackNeeded.title'),
          onClick: closeNotification => {
            closeNotification();
            onNotificationClick(requiredAction);
          },
          message: t('requiredActions.callbackNeeded.text'),
        };
      }

      if (notificationType === APIRequiredActionType.OrderConfirmationNeeded) {
        notification = {
          providerId,
          sound: 'default',
          severity: NotificationSeverity.Success,
          title: t('requiredActions.orderConfirmationNeeded.title'),
          onClick: closeNotification => {
            closeNotification();
            onNotificationClick(requiredAction);
          },
          message: t('requiredActions.orderConfirmationNeeded.text', {
            orderName: data?.orderName,
          }),
        };
      }
      if (notificationType === APIRequiredActionType.OrderUpdated) {
        notification = {
          severity: NotificationSeverity.Info,
          sound: 'default',
          providerId,
          title: t('order.notification.manualUpdateTitle'),
          onClick: closeNotification => {
            closeNotification();
            onNotificationClick(requiredAction);
          },
          message: t('order.notification.manualUpdateMessage', {
            orderName: data?.orderName,
            providerName,
          }),
        };
      }

      if (
        notificationType ===
          APIRequiredActionType.OrderChangesPartiallyApproved ||
        notificationType === APIRequiredActionType.OrderChangesApproved ||
        notificationType === APIRequiredActionType.OrderChangesRejected
      ) {
        notification = {
          severity: approvalStatusColors[notificationType],
          title: approvalTitle[notificationType],
          sound: 'default',
          providerId,
          onClick: closeNotification => {
            closeNotification();
            onNotificationClick(requiredAction);
          },
          message: ({ closeWithCallback }) =>
            getApprovalNotificationMessage({
              notificationType,
              providerName: providerName || '',
              orderName: data?.orderName,
              onOrderNameClick: () => {
                closeWithCallback(() => {
                  onNotificationClick(requiredAction);
                })();
              },
            }),
        };
      }

      if (notificationType === APIRequiredActionType.OrderApproved) {
        notification = {
          severity: NotificationSeverity.Success,
          providerId,
          sound: 'default',
          title: t('order.notification.approvedTitle'),
          onClick: closeNotification => {
            closeNotification();
            onNotificationClick(requiredAction);
          },
          message: ({ closeWithCallback }) =>
            getNotificationMessage({
              notificationType: APIRequiredActionType.OrderApproved,
              orderName: data?.orderName,
              providerName: providerName,
              onClick: closeWithCallback(() =>
                onNotificationClick(requiredAction),
              ),
            }),
        };
      }

      if (notificationType === APIRequiredActionType.OrderCancelled) {
        notification = {
          severity: NotificationSeverity.Info,
          title: t('order.notification.canceledTitle'),
          sound: 'default',
          providerId,
          onClick: closeNotification => {
            closeNotification();
            onNotificationClick(requiredAction);
          },
          message: ({ closeWithCallback }) =>
            getNotificationMessage({
              notificationType: APIRequiredActionType.OrderCancelled,
              orderName: data?.orderName,
              onClick: closeWithCallback(() =>
                onNotificationClick(requiredAction),
              ),
            }),
        };
      }

      if (notificationType === APIRequiredActionType.OrderDeclined) {
        notification = {
          severity: NotificationSeverity.Error,
          title: t('order.notification.declinedTitle'),
          sound: 'default',
          providerId,
          onClick: closeNotification => {
            closeNotification();
            onNotificationClick(requiredAction);
          },
          message: ({ closeWithCallback }) =>
            getNotificationMessage({
              notificationType: APIRequiredActionType.OrderDeclined,
              orderName: data?.orderName,
              providerName: providerName,
              onClick: closeWithCallback(() =>
                onNotificationClick(requiredAction),
              ),
            }),
        };
      }

      if (
        notificationType === APIRequiredActionType.OrderCancellationConfirmed
      ) {
        notification = {
          severity: NotificationSeverity.Info,
          providerId,
          sound: 'default',
          title: t('order.notification.canceledTitle'),
          onClick: closeNotification => {
            closeNotification();
            onNotificationClick(requiredAction);
          },
          message: t('order.notification.cancelSubmittedMessage', {
            orderName: data?.orderName,
          }),
        };
      }

      if (
        notificationType === APIRequiredActionType.ProjectCreatedByDispatcher
      ) {
        notification = {
          severity: NotificationSeverity.Success,
          providerId,
          title: t('project.newCreated.title'),
          sound: 'default',
          onClick: closeNotification => {
            closeNotification();
            navigateToProject(data?.id as string);
          },
          message: t('requiredActions.projectCreatedContractor.text', {
            providerName,
          }),
        };
      }

      if (
        notificationType == APIRequiredActionType.ProjectCreatedByContractor
      ) {
        notification = {
          providerId,
          sound: 'default',
          severity: NotificationSeverity.Success,
          title: t('project.newCreated.title'),
          onClick: closeNotification => {
            closeNotification();
            navigate(projectOrdersUrl(data.id!, data.companyId!));
          },
          message: ({ closeWithCallback }) => (
            <Trans i18nKey="project.newCreated.message" shouldUnescape>
              {{ companyName: data.company }} has created a new project:{' '}
              {data.name}.{' '}
              <NotificationLink
                onClick={closeWithCallback(() =>
                  navigate(projectOrdersUrl(data.id!, data.companyId!)),
                )}
              >
                View Project
              </NotificationLink>
            </Trans>
          ),
        };
      }

      if (
        notificationType == APIRequiredActionType.OrderCancellationSubmitted
      ) {
        notification = {
          providerId,
          severity: NotificationSeverity.Warning,
          sound: 'default',
          title: t('order.cancelOrderNotification.title'),
          onClick: closeNotification => {
            closeNotification();
            onNotificationClick(requiredAction);
          },
          message: ({ closeWithCallback }) =>
            getNotificationMessage({
              notificationType:
                APIRequiredActionType.OrderCancellationSubmitted,
              orderName: data?.orderName,
              companyName: data?.company,
              providerName: providerName,
              onClick: closeWithCallback(() =>
                onNotificationClick(requiredAction),
              ),
            }),
        };
      }

      if (notificationType == APIRequiredActionType.OrderRequested) {
        notification = {
          providerId,
          sound: 'default',
          title: t('order.newOrderNotification.title'),
          severity: NotificationSeverity.Success,
          onClick: closeNotification => {
            closeNotification();
            navigateToNewOrder(data.id!);
          },
          message: ({ closeWithCallback }) => (
            <Trans i18nKey="order.newOrderNotification.message" shouldUnescape>
              {{ companyName: data.company }} has placed a{' '}
              <NotificationLink
                onClick={closeWithCallback(() => navigateToNewOrder(data.id!))}
              >
                new order
              </NotificationLink>{' '}
              for{' '}
              {{
                orderDate: formatDisplayedDate(data.deliveryDate!),
              }}
              . Approval/Confirmation is needed.
            </Trans>
          ),
        };
      }

      // updated user
      if (notificationType == APIRequiredActionType.OrderChangesRequested) {
        notification = {
          providerId,
          severity: NotificationSeverity.Success,
          sound: 'default',
          title: t('order.changeOrderNotification.title'),
          onClick: closeNotification => {
            closeNotification();
            navigateToNewOrder(data.id!);
          },
          message: ({ closeWithCallback }) => (
            <Trans i18nKey="order.newOrderNotification.message" shouldUnescape>
              {{ companyName: data.company }} has submitted changes to order{' '}
              <NotificationLink
                onClick={closeWithCallback(() => navigateToNewOrder(data.id!))}
              >
                {data.orderName}
              </NotificationLink>
              . Approval/Confirmation is needed
            </Trans>
          ),
        };
      }

      if (notificationType == APIRequiredActionType.OrderConfirmed) {
        notification = {
          providerId,
          severity: NotificationSeverity.Success,
          title: t('order.confirmOrderNotification.title'),
          onClick: closeNotification => {
            closeNotification();
            navigateToNewOrder(data.id!);
          },
          message: ({ closeWithCallback }) => (
            <Trans
              i18nKey="order.confirmOrderNotification.message"
              shouldUnescape
            >
              {{ companyName: data.company }} has confirmed order{' '}
              {{ orderName: data.orderName }}.{' '}
              <NotificationLink
                onClick={closeWithCallback(() => navigateToNewOrder(data.id!))}
              >
                View Details
              </NotificationLink>
              .
            </Trans>
          ),
        };
      }

      if (notificationType == APIRequiredActionType.NewProjectSubscription) {
        notification = {
          providerId,
          sound: 'default',
          severity: NotificationSeverity.Success,
          title: t('requiredActions.subscribedToProject.title'),
          onClick: closeNotification => {
            closeNotification();
            navigate(projectOrdersUrl(data.id!, data.companyId!));
          },
          message: t('requiredActions.subscribedToProject.text', {
            projectName: data.name,
          }),
        };
      }

      if (notificationType == APIRequiredActionType.NewOrderSubscription) {
        notification = {
          providerId,
          sound: 'default',
          severity: NotificationSeverity.Success,
          title: t('requiredActions.subscribedToOrder.title'),
          onClick: closeNotification => {
            closeNotification();
            onNotificationClick(requiredAction);
          },
          message: t('requiredActions.subscribedToOrder.text', {
            orderName: data.orderName,
            dateAndTime: formatDateAndTime({
              date: data.deliveryDate as string,
              time: data.deliveryTime as string,
            }),
          }),
        };
      }

      if (!_.isEmpty(notification)) {
        if (!document.hidden) {
          addNotification(notification);
        } else {
          showPushNotification(
            notification.title?.toString() || '',
            notification.onClick,
          );
        }
      }
    },
    [
      getRequiredActionProviderName,
      t,
      onNotificationClick,
      navigateToProject,
      navigate,
      navigateToNewOrder,
      addNotification,
    ],
  );
  return {
    showRequiredActionToast,
  };
};
