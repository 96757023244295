import React, { useCallback, useEffect, useMemo, useState } from 'react';

import styled from 'styled-components';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { NotificationSeverity } from '@cd3p/core/modules/notifications';
import { APICompany } from '@cd3p/core/types/api';
import { getServerErrorTitle } from '@cd3p/core/utils/common';
import {
  CircularProgress,
  ConfirmationDialog,
  EditableDetailsHeaderButton,
  IconButton,
  LoadingButton,
  ReadOnlyField,
  Stack,
  Tab,
  Tabs,
  TabsBottomLine,
  Tooltip,
  Typography,
} from 'components';
import { useHandleApiResult, useRedirectOn404 } from 'hooks/useHandleApiResult';
import { subscribe } from 'hooks/usePubSub';
import i18next from 'i18next';

import { ContractorDetailsForm } from './ContractorDetailsForm';

import {
  Navigate,
  dayjs,
  useMatch,
  useNavigate,
  useParams,
  useSelector,
  useTranslation,
} from 'third-party';

import { APP_EVENTS } from 'constants/appEvent';
import {
  ADDITIVES_PATH,
  CUSTOMER_TEAM_PATH,
  MIX_TYPES_PATH,
  PROJECTS_PATH,
  contractorsAdditiveTypesUrl,
  contractorsCustomerTeamUrl,
  contractorsMixTypesUrl,
  contractorsUrl,
  customerProjectsUrl,
} from 'constants/url';

import { useContractorsList } from 'modules/contractorsList';
import { useNotifications } from 'modules/notifications';

import { contractorsListSelectors } from 'selectors';

import { AdditiveTypesTab } from 'features/Contractors/Tabs/AdditiveTypesTab';
import { MixTypesTab } from 'features/Contractors/Tabs/MixTypesTab';
import { ProjectsTab } from 'features/Contractors/Tabs/ProjectsTab';
import { TeamTab } from 'features/Contractors/Tabs/TeamTab';

import { TabTitle, TabWrapper, TabsWrapper } from 'styles/theme';

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 3rem;
`;

const ContentWrapper = styled.div`
  width: 50rem;
  margin-bottom: 3rem;
`;

const ReadOnlyFieldsWrapper = styled.div`
  display: grid;
  align-items: start;
  width: 60rem;
  grid-template-columns: 50% 25% 25%;
  gap: 4rem;

  .details-field {
    flex-direction: column;
  }
`;

const StyledTab = styled(Tab)`
  flex-grow: initial;
  padding: 0 5rem;
`;

const LoadingContainer = styled(Stack)`
  height: 100%;
  align-items: center;
  justify-content: center;
`;

const prepareContractorDateForUI = (contractorDate?: Date | string) => {
  return contractorDate
    ? dayjs(contractorDate || '').format('MMMM D, YYYY')
    : i18next.t('common.entityNameUndefined');
};

enum ContractorDetailsTabs {
  Projects,
  MixTypes,
  Additives,
  Team,
}

export const ContractorDetails = () => {
  const { contractorId, tabId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { addNotification } = useNotifications();
  const {
    getContractorById,
    updateContractor,
    deleteContractor,
    loadContractors,
  } = useContractorsList();

  const redirectOn404 = useRedirectOn404();

  const updateContractorPending = useSelector(
    contractorsListSelectors.updateContractorPending,
  );

  const deleteContractorPending = useSelector(
    contractorsListSelectors.deleteContractorPending,
  );

  const contractorsListNameFilter = useSelector(
    contractorsListSelectors.contractorsListNameFilter,
  );

  const [selectedContractor, setSelectedContractor] =
    useState<APICompany | null>(null);

  const [isEditMode, setEditMode] = useState(false);
  const [showDeactivateConfirmation, setShowDeactivateConfirmation] =
    useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const matchIndexUrl = useMatch(contractorsUrl(contractorId));
  const currentTab = useMemo(() => {
    switch (tabId) {
      case PROJECTS_PATH:
        return ContractorDetailsTabs.Projects;
      case ADDITIVES_PATH:
        return ContractorDetailsTabs.Additives;
      case MIX_TYPES_PATH:
        return ContractorDetailsTabs.MixTypes;
      case CUSTOMER_TEAM_PATH:
        return ContractorDetailsTabs.Team;
      default:
        return null;
    }
  }, [tabId]);

  useEffect(() => {
    if (contractorId && contractorId !== selectedContractor?.id) {
      const prepareData = async () => {
        const result = await getContractorById(contractorId);
        if (!result.error) {
          setSelectedContractor(result.payload);
        } else {
          redirectOn404(result, contractorsUrl());
        }
      };
      prepareData();
    }
  }, [
    contractorId,
    getContractorById,
    addNotification,
    selectedContractor?.id,
    t,
    navigate,
    redirectOn404,
  ]);

  const createdDate = useMemo(
    () => prepareContractorDateForUI(selectedContractor?.createdDate),
    [selectedContractor?.createdDate],
  );

  const deactivatedDate = useMemo(
    () => prepareContractorDateForUI(selectedContractor?.deactivatedDate),
    [selectedContractor?.deactivatedDate],
  );

  const tabs = useMemo(
    () => [
      {
        title: t('plantSettings.projectsTab'),
        onClick: () =>
          navigate(customerProjectsUrl(contractorId), {
            replace: true,
          }),
      },
      {
        title: t('plantSettings.mixTypesTab'),
        onClick: () =>
          navigate(contractorsMixTypesUrl(contractorId), { replace: true }),
      },
      {
        title: t('plantSettings.additivesTab'),
        onClick: () =>
          navigate(contractorsAdditiveTypesUrl(contractorId), {
            replace: true,
          }),
      },
      {
        title: t('plantSettings.customerTeamTab'),
        onClick: () =>
          navigate(contractorsCustomerTeamUrl(contractorId), {
            replace: true,
          }),
      },
    ],
    [t, navigate, contractorId],
  );

  useEffect(() => {
    return subscribe(APP_EVENTS.UPDATE_COMPANY, (company: APICompany) => {
      setSelectedContractor(company);
    });
  }, []);

  const onDeactivate = async () => {
    if (selectedContractor) {
      const result = await updateContractor(contractorId!, {
        ...selectedContractor,
        isDeactivated: true,
      });
      if (!result || result.error) {
        addNotification({
          severity: NotificationSeverity.Error,
          message:
            ((result.payload.status === 400 || result.payload.status === 405) &&
              getServerErrorTitle(result)) ||
            t('common.generalError'),
        });
      } else {
        setSelectedContractor(result.payload);
        setShowDeactivateConfirmation(false);
        addNotification({
          message: t('customers.details.deactivateSuccess'),
        });
      }
    }
  };

  const handleApiResult = useHandleApiResult();

  const onReactivate = async () => {
    if (selectedContractor) {
      const body = {
        name: selectedContractor.name,
        address: selectedContractor.address,
        companyType: selectedContractor.companyType,
        isDeactivated: false,
      };
      handleApiResult<APICompany>(
        () => updateContractor(contractorId!, body),
        ({ showBaseToast, result }) => {
          setSelectedContractor(result.payload);
          showBaseToast(t('customers.details.reactivateSuccess'));
        },
      );
    }
  };

  const onConfirmDelete = useCallback(async () => {
    if (selectedContractor) {
      const result = await deleteContractor(contractorId!);
      if (!result || result.error) {
        addNotification({
          severity: NotificationSeverity.Error,
          message:
            (result.payload.status === 400 && getServerErrorTitle(result)) ||
            t('common.generalError'),
        });
      } else {
        setShowDeleteConfirmation(false);
        addNotification({
          message: t('customers.details.deleteSuccessToastText', {
            companyName: selectedContractor.name,
          }),
        });
        navigate(contractorsUrl(), { replace: true });
        loadContractors({
          name: contractorsListNameFilter,
        });
      }
    }
  }, [
    addNotification,
    contractorId,
    contractorsListNameFilter,
    deleteContractor,
    loadContractors,
    navigate,
    selectedContractor,
    t,
  ]);

  if (
    !selectedContractor ||
    !contractorId ||
    contractorId !== selectedContractor?.id
  ) {
    return (
      <LoadingContainer>
        <CircularProgress sx={{ margin: '5rem' }} />
      </LoadingContainer>
    );
  }

  return (
    <>
      <ConfirmationDialog
        onClose={() => {
          setShowDeactivateConfirmation(false);
        }}
        open={showDeactivateConfirmation}
        handleActionClick={onDeactivate}
        title={t('common.areYouSure')}
        description={t('customers.details.deactivateActionDescription')}
        actionButtonTitle={t('customers.details.deactivateActionConfirmation')}
        cancelButtonTitle={t('customers.details.deactivateActionCancel')}
        actionPending={updateContractorPending}
      />
      <HeaderWrapper>
        <Stack direction="row" alignItems="center" gap="1rem">
          <Typography variant="h3">{selectedContractor?.name}</Typography>
          {!selectedContractor?.inUse && (
            <>
              <IconButton onClick={() => setShowDeleteConfirmation(true)}>
                <Tooltip title={t('customers.details.deleteTooltip')}>
                  <DeleteOutlineIcon color="primary" />
                </Tooltip>
              </IconButton>
              <ConfirmationDialog
                onClose={() => {
                  setShowDeleteConfirmation(false);
                }}
                handleActionClick={onConfirmDelete}
                open={showDeleteConfirmation}
                actionPending={deleteContractorPending}
                description={t('customers.details.deleteConfirmationText')}
              />
            </>
          )}
        </Stack>
        {selectedContractor?.isDeactivated ? (
          <LoadingButton
            variant="outlined"
            color="secondary"
            loading={updateContractorPending}
            onClick={onReactivate}
          >
            {t('customers.details.reactivateAction')}
          </LoadingButton>
        ) : (
          <LoadingButton
            variant="outlined"
            color="secondary"
            onClick={() => setShowDeactivateConfirmation(true)}
          >
            {t('customers.details.deactivateAction')}
          </LoadingButton>
        )}
      </HeaderWrapper>
      <ContentWrapper>
        {isEditMode && selectedContractor ? (
          <ContractorDetailsForm
            selectedContractor={selectedContractor}
            setEditMode={setEditMode}
            onSuccessfulSubmit={(contractor: APICompany) =>
              setSelectedContractor(contractor)
            }
          />
        ) : (
          selectedContractor && (
            <>
              <EditableDetailsHeaderButton
                sectionTitle={t('customers.details.title')}
                handleOnClick={setEditMode}
              />
              <ReadOnlyFieldsWrapper>
                <ReadOnlyField
                  key={selectedContractor.address}
                  value={
                    selectedContractor.address ||
                    t('common.entityNameUndefined')
                  }
                  label={t('customers.details.officeAddressField')}
                  className="details-field"
                />
                <ReadOnlyField
                  key={selectedContractor.createdDate}
                  value={createdDate}
                  label={t('customers.details.activeSinceField')}
                  className="details-field"
                />
                {selectedContractor?.isDeactivated && (
                  <ReadOnlyField
                    key={selectedContractor.deactivatedDate}
                    value={deactivatedDate}
                    label={t('customers.details.deactivatedField')}
                    className="details-field"
                  />
                )}
              </ReadOnlyFieldsWrapper>
            </>
          )
        )}
      </ContentWrapper>

      <TabsWrapper>
        {currentTab !== null && (
          <Tabs value={currentTab}>
            {tabs.map(tab => {
              return (
                <StyledTab
                  key={tab.title}
                  label={
                    <TabWrapper>
                      <TabTitle>{tab.title}</TabTitle>
                    </TabWrapper>
                  }
                  onClick={tab.onClick}
                />
              );
            })}
            <TabsBottomLine />
          </Tabs>
        )}
        {contractorId && currentTab === ContractorDetailsTabs.Projects && (
          <ProjectsTab
            key={contractorId}
            customerId={contractorId}
            isReadOnly={selectedContractor?.isDeactivated}
          />
        )}
        {contractorId && currentTab === ContractorDetailsTabs.MixTypes && (
          <MixTypesTab
            key={contractorId}
            contractorId={contractorId}
            isReadOnly={selectedContractor?.isDeactivated}
          />
        )}
        {contractorId && currentTab === ContractorDetailsTabs.Additives && (
          <AdditiveTypesTab
            key={contractorId}
            contractorId={contractorId}
            isReadOnly={selectedContractor?.isDeactivated}
          />
        )}
        {contractorId && currentTab === ContractorDetailsTabs.Team && (
          <TeamTab
            key={contractorId}
            customerId={contractorId}
            isReadOnly={selectedContractor?.isDeactivated}
          />
        )}
        {matchIndexUrl && (
          <Navigate to={customerProjectsUrl(contractorId)} replace />
        )}
      </TabsWrapper>
    </>
  );
};
