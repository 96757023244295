import React, { useCallback, useEffect, useRef, useState } from 'react';

import styled from 'styled-components';

import { APIResponse, APIUserType } from '@cd3p/core/types/api';
import { multilineTextField } from '@cd3p/core/utils/fields';
import {
  Box,
  CanAccess,
  DispatcherCanAccess,
  Divider,
  FormField,
  Stack,
  TypeaheadFormField,
  Typography,
} from 'components';
import { SingleValue } from 'react-select';

import { SettingsSwitcherBlock } from './ProjectSettingsModal';

import { FieldError, useFormContext, useTranslation } from 'third-party';

import { useCache } from 'modules/cache';

import { UserSearchField } from 'features/Fields/UserSearchField';
import {
  ProjectFormData,
  ProjectFormFields,
} from 'features/ProjectView/ProjectCreateEditView';

import { TypeaheadOption } from 'types/app';

const Note = styled(Typography)`
  font-size: 1.4rem;
  font-weight: 400;
`;

export const Header = styled(Typography)`
  color: ${props => props.theme.custom.palette.secondary500};
  font-weight: 900;
  font-size: 1.6rem;
  text-transform: uppercase;
  margin-bottom: 0.4rem;
`;

const StyledDivider = styled(Divider)`
  border-color: ${props => props.theme.custom.palette.muted100};
  margin-top: 1.6rem;
  margin-bottom: 1rem;
`;

const DeliveryPin = styled(Typography)`
  color: ${props => props.theme.custom.palette.gray};
  font-size: 1.4rem;
  font-weight: 700;
  margin-top: 0.5rem;
  > small,
  em {
    display: inline-block;
    margin-top: 0.5rem;
    font-weight: 600;
    font-size: 1.2rem;
  }
  > em {
    color: ${props => props.theme.custom.palette.warning700};
  }
`;

type Props = {
  isEditMode: boolean;
  isDisabled?: boolean;
  hasDeliveryAddress?: boolean;
};

export const ProjectEditForm: React.FC<Props> = ({
  isEditMode,
  isDisabled,
  hasDeliveryAddress,
}) => {
  const { t } = useTranslation();

  const { loadAddress } = useCache();

  const [optionsCount, setOptionsCount] = useState(0);

  const {
    watch,
    setValue,
    control,
    formState: { errors },
  } = useFormContext();

  const watchedProjectAddress = watch(ProjectFormFields.ProjectAddress);

  const watchedDeliveryCoordinates = watch(
    ProjectFormFields.ProjectDeliveryCoordinates,
  );

  const addressDropdownRef = useRef<any>(null);

  useEffect(() => {
    setValue(
      ProjectFormFields.ProjectFreeFormAddress,
      hasDeliveryAddress ? watchedProjectAddress.value : '',
      {
        shouldValidate: true,
        shouldDirty: true,
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasDeliveryAddress]);

  const loadAddressOptions = async (value: string) => {
    const result: APIResponse<string[]> = await loadAddress(value);
    let options: TypeaheadOption[] = [];
    if (!result.error) {
      options = result.payload.map(it => ({
        label: it,
        value: it,
      }));
    }
    setOptionsCount(options.length);
    return {
      options,
    };
  };

  const onSelectAddress = useCallback(
    (option: SingleValue<TypeaheadOption>) => {
      setValue(ProjectFormFields.ProjectAddress, option, {
        shouldValidate: true,
        shouldDirty: true,
      });
    },
    [setValue],
  );

  const isValidNewOption = useCallback(
    (inputValue: string) => inputValue.trim().length > 0,
    [],
  );

  const onFreeFormAddressKeyUp = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (!event?.target.value) {
      setValue(ProjectFormFields.ProjectAddress, null, {
        shouldValidate: true,
        shouldDirty: true,
      });
      setTimeout(() => {
        addressDropdownRef?.current?.focus?.();
      }, 0);
    }
  };

  return (
    <>
      <DispatcherCanAccess>
        <Note>{t('addProject.addProjectNote')}</Note>
      </DispatcherCanAccess>
      <FormField
        fieldName={ProjectFormFields.ProjectName}
        label={t('addProject.nameFieldLabel')}
        fieldError={errors[ProjectFormFields.ProjectName] as FieldError}
        requiredErrorMessage={t('common.form.emptyFieldError')}
        placeholder={t('addProject.nameFieldPlaceholder')}
        control={control}
        isDisabled={isDisabled}
        maxLength={200}
        isRequired
      />
      {hasDeliveryAddress && (
        <FormField
          fieldName={ProjectFormFields.ProjectFreeFormAddress}
          label={t('addProject.addressFieldLabel')}
          fieldError={
            errors[ProjectFormFields.ProjectFreeFormAddress] as FieldError
          }
          onKeyUp={onFreeFormAddressKeyUp}
          requiredErrorMessage={t('common.form.emptyFieldError')}
          placeholder={t('addProject.addressFieldPlaceholder')}
          control={control}
          isDisabled={isDisabled}
          maxLength={200}
          isRequired
        />
      )}
      {/* hiding dropdown to allow user to edit custom address in free form input */}
      <Box
        style={{
          display: hasDeliveryAddress ? 'none' : 'block',
        }}
      >
        <FormField
          fieldName={ProjectFormFields.ProjectAddress}
          label={t('addProject.addressFieldLabel')}
          fieldError={errors[ProjectFormFields.ProjectAddress] as FieldError}
          requiredErrorMessage={t('common.form.emptyFieldError')}
          control={control}
          isDisabled={isDisabled}
          isRequired
          render={() => (
            <TypeaheadFormField
              ref={addressDropdownRef}
              isCreatable
              isClearable
              createOptionPosition="first"
              hasError={
                !!(errors[ProjectFormFields.ProjectAddress] as FieldError)
              }
              placeholder={t('addProject.addressFieldPlaceholder')}
              loadOptions={loadAddressOptions}
              onChange={onSelectAddress}
              value={watchedProjectAddress}
              isValidNewOption={isValidNewOption}
              formatCreateLabel={(inputValue: string) =>
                t(
                  optionsCount
                    ? 'addProject.createNewAddressLabelHasResults'
                    : 'addProject.createNewAddressLabelNoResults',
                  {
                    address: inputValue.trim(),
                  },
                )
              }
              isDisabled={isDisabled}
            />
          )}
        />
      </Box>
      {hasDeliveryAddress && (
        <Stack>
          <FormField
            fieldName={ProjectFormFields.ProjectDeliveryLocation}
            label={t('addProject.deliveryLocationFieldLabel')}
            fieldError={
              errors[ProjectFormFields.ProjectDeliveryLocation] as FieldError
            }
            requiredErrorMessage={t('common.form.emptyFieldError')}
            placeholder={t('addProject.deliveryLocationFieldPlaceholder')}
            control={control}
            isDisabled
            maxLength={200}
            isRequired
          />
          <DeliveryPin>
            {t('addProject.deliveryPinLabel')}:{' '}
            {watchedDeliveryCoordinates
              ? `${watchedDeliveryCoordinates.latitude}, ${watchedDeliveryCoordinates.longitude}`
              : '---'}
            <small>{t('addProject.deliveryPinTip')}</small>
            <em>{t('addProject.deliveryPinWarning')}</em>
          </DeliveryPin>
        </Stack>
      )}
      <FormField
        fieldName={ProjectFormFields.ProjectDescription}
        isDisabled={isDisabled}
        label={t('common.form.project.descriptionLabel')}
        fieldError={errors[ProjectFormFields.ProjectDescription] as FieldError}
        requiredErrorMessage={t('common.form.emptyFieldError')}
        control={control}
        placeholder={t('common.form.project.descriptionPlaceholder')}
        multiline
        isRequired={false}
        maxLength={multilineTextField.maxLength}
      />
      {!isEditMode && (
        <CanAccess allowedUserType={APIUserType.Contractor}>
          <UserSearchField<ProjectFormData>
            label={t('addProject.subscribeTeamMembers.label')}
            placeholder={t('addProject.subscribeTeamMembers.placeholder')}
            fieldName={ProjectFormFields.SubscribedUsers}
            isDisabled={isDisabled}
          />
        </CanAccess>
      )}
      <DispatcherCanAccess>
        <StyledDivider />
        <SettingsSwitcherBlock
          title={t('customers.mixTypes.header')}
          description={t('addProject.submittedMixes.description')}
          blockTitle={t('addProject.submittedMixes.fieldTitle')}
          blockDescription={t('addProject.submittedMixes.fieldDescription')}
          fieldName={ProjectFormFields.IsCustomMixTypes}
          isDisabled={isDisabled}
        />
        <SettingsSwitcherBlock
          title={t('customers.additiveTypes.header')}
          description={t('addProject.submittedAdditive.description')}
          blockTitle={t('addProject.submittedAdditive.fieldTitle')}
          blockDescription={t('addProject.submittedAdditive.fieldDescription')}
          fieldName={ProjectFormFields.IsCustomAdditiveTypes}
          isDisabled={isDisabled}
        />
      </DispatcherCanAccess>
    </>
  );
};
