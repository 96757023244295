import _ from 'lodash';

import { APIServerError } from '../../types/common';
import { genericApiInitialState, genericServerReducer } from './reducers';
import { ActionFun, ServerActionFun, SimpleAction } from './types';

export function simplifyBuilder<TInitialState, TInitialReducers>(
  initialState: TInitialState,
  initialReducers: TInitialReducers,
) {
  let state = initialState;
  let reducers = initialReducers;

  const updateState = (actionState: any) => {
    state = { ...state, ...actionState };
  };

  const updateReducers = (apiReducers: any) => {
    reducers = { ...reducers, ...apiReducers };
  };

  const createReducer =
    (initState: TInitialState, reducers: any) =>
    (state = initState, action: SimpleAction<TInitialState, any>) =>
      reducers[action.type] ? reducers[action.type](state, action) : state;

  const genericReducer =
    () => (state: TInitialState, action: SimpleAction<TInitialState, any>) => {
      return {
        ...state,
        ...(action.updater ? action.updater(state, action.payload) : {}),
      };
    };

  const getPayload = (values: any[]) => (!values.length ? {} : values);

  return {
    getState: state,
    getReducers: () => createReducer(state, reducers),
    createReduxAction: function <FuncType extends ActionFun<TInitialState>>(
      fn: FuncType,
    ): (
      ...args: Parameters<FuncType>
    ) => Promise<SimpleAction<TInitialState, Parameters<FuncType>>> {
      const actionFactory = (...args: any[]): any => {
        const model = fn(...Array.from(args));

        updateReducers({ [model.name]: genericReducer() });

        return (...args: any[]): any => {
          const data = fn(...args);

          return {
            name: model.name,
            type: model.name,
            updater: data.updater,
            payload: getPayload(args),
            toString: () => model.name,
          };
        };
      };

      return actionFactory();
    },
    createServerAction: function <
      // @ts-ignore find out how to make TS force providing
      // types for redux methods arguments without this ignore
      FuncType extends ServerActionFun<TInitialState, TPayload>,
      TPayload = any,
    >(
      fn: FuncType,
    ): (
      ...args: Parameters<FuncType>
    ) => Promise<SimpleAction<TInitialState, TPayload> | APIServerError> {
      const actionFactory = (...args: any[]) => {
        const model = fn(...Array.from(args));

        const initialStoreState = model.initialState || genericApiInitialState;

        if (_.isObjectLike(initialStoreState)) {
          updateState(initialStoreState);
        } else if (_.isFunction(initialStoreState)) {
          updateState(initialStoreState(model.name));
        } else {
          throw new Error(
            'Property state inside `createAction` method is neither object nor function!',
          );
        }
        const apiReducers = genericServerReducer(model.name);
        updateReducers(apiReducers);

        return (...args: any[]) => {
          const data = fn(...args);

          return {
            name: data.name,
            url: data.url,
            body: data.body,
            params: data.params,
            types: _.keys(apiReducers),
            onSuccess: data.onSuccess,
            onRequest: data.onRequest,
            onFailure: data.onFailure,
            onCancel: data.onCancel,
            responseReader: data.responseReader,
            payload: getPayload(args),
            method: data.method,
            toString: () => model.name,
          };
        };
      };

      return actionFactory() as any as (
        // @ts-ignore find out how to make TS force providing
        // types for redux methods arguments without this ignore
        ...args: Parameters<ServerActionFun<TInitialState, TPayload>>
      ) => Promise<SimpleAction<TInitialState, TPayload>>;
    },
  };
}
