import { Core } from '@cd3p/core/config';
import { userType } from '@cd3p/core/selectors/app';
import { APIAccessLevel, APIUser, APIUserType } from '@cd3p/core/types/api';
import { State } from 'modules';
import { createSelector } from 'reselect';
import { userSettingsSelector } from 'selectors/storage';

import { userProviders } from './app';

import { AppLeftPanel } from 'modules/app';
import { StorageUserSettings } from 'modules/storage';

export const user = (state: State) => state.app.user;

export const providerId = createSelector(
  [
    user,
    userSettingsSelector(
      (userSettingsState: StorageUserSettings) =>
        userSettingsState?.currentProviderId,
    ),
  ],
  (user, currentProviderId) => getCurrentProviderId(user, currentProviderId),
);

export const providerName = createSelector(
  userProviders,
  providerId,
  (userProviders, providerId) => {
    return (
      userProviders?.find(provider => providerId === provider?.id)
        ?.providerName || ''
    );
  },
);

export const getCurrentProviderId = (
  user: Partial<APIUser> | null,
  currentProviderId: StorageUserSettings['currentProviderId'],
) => {
  const selectedProviderId = user?.providers?.find(
    // @ts-ignore
    it => it['isSelected'],
  )?.id;

  if (currentProviderId) {
    return currentProviderId;
  }

  return selectedProviderId ?? user?.providers?.[0]?.id ?? '';
};
export const releaseNotes = (state: State) =>
  state.app.user.newApplicationVersions;
export const userId = (state: State) => state.app.user?.id;
export const userShouldVerifyCompanyInfo = (state: State) =>
  state.app.user.userOptions?.shouldVerifyCompanyInfo === 'true';
export const isAppLoaded = (state: State) => state.app.isAppLoaded;
export const userLoginPending = (state: State) => state.app.userLoginInPending;
export const appOpenedLeftPanel = userSettingsSelector(
  (userSettings: StorageUserSettings) => userSettings.openedLeftPanel,
);
export const appLeftPanelAnimationDuration = userSettingsSelector(
  (userSettings: StorageUserSettings) =>
    userSettings.leftPanelAnimationDuration,
);
export const isRequiredActionsPanelOpen = userSettingsSelector(
  (userSettings: StorageUserSettings) =>
    userSettings.openedLeftPanel === AppLeftPanel.RequiredActions,
);
export const isDialogsPanelOpen = userSettingsSelector(
  (userSettings: StorageUserSettings) =>
    userSettings.openedLeftPanel === AppLeftPanel.Dialogs,
);
export const showTrafficLayerOnMap = userSettingsSelector(
  (userSettings: StorageUserSettings) => userSettings.showTrafficLayerOnMap,
);
export const accessLevel = (state: State) => state.app.user?.accessLevel;

export const isAdmin = createSelector(accessLevel, userType => {
  return (
    userType == APIAccessLevel.Admin || userType == APIAccessLevel.SuperVisor
  );
});

export const isSupportUser = createSelector(userType, userType => {
  return Core.isAdminPortal() && userType == APIUserType.Support;
});

export const userInfoPending = (state: State) => state.app.userInfoPending;
export const createUserPending = (state: State) => state.app.createUserPending;
export const userInfoError = (state: State) => state.app.userInfoError;
export const updateCurrentUserPending = (state: State) =>
  state.app.updateCurrentUserPending;
export const updateUserByIdPending = (state: State) =>
  state.app.updateUserByIdPending;
export const validateTempTokenPending = (state: State) =>
  state.app.validateTempTokenPending;
export const isProviderSettingsLoaded = (state: State) =>
  state.app.isProviderSettingsLoaded;

export const updateProviderPending = (state: State) =>
  state.app.updateProviderPending;

export const userCompanies = (state: State) => state.app.user?.companies;

export const companyId = createSelector(
  userCompanies,
  providerId,
  (userCompanies, providerId) => {
    return (userCompanies || []).find(
      company => company.providerId === providerId,
    )?.id as string;
  },
);

export {
  userType,
  provider,
  userProviders,
  userProvidersIds,
  userCompaniesIds,
  providerAddress,
  providerPhone,
  openedChatOrderId,
  globalCacheVersion,
  providerTimeZone,
  isChatFeatureEnabled,
  isApiDocsFeatureEnabled,
  isDriverAssignmentEnabled,
  isBatchSoftwareIntegrationEnabled,
  isProjectCreationByContractorEnabled,
  isTimeStampEnabled,
  contractorOrderEditingAllowedStates,
  updateProviderSettingsPending,
  orderConfirmationFirstReminderHours,
  orderConfirmationSecondReminderEnabled,
  orderConfirmationSecondReminderHours,
  providerAdminEmail,
  loadProviderSettingsPending,
} from '@cd3p/core/selectors/app';
