import React, { useCallback, useEffect, useRef, useState } from 'react';

import styled, { useTheme } from 'styled-components';

import { APIMasterAdditiveType, APIMasterMixType } from '@cd3p/core/types/api';
import { OmitFirstArg } from '@cd3p/core/types/utils';
import { DEFAULT_PAGE_SIZE } from '@cd3p/core/utils/lists';
import { OpenInNewOutlined } from '@mui/icons-material';
import {
  Button,
  CircularProgress,
  IconButton,
  Popover,
  Stack,
  Tooltip,
  Typography,
} from 'components';
import i18n from 'i18n';

import { _, useBottomScrollListener, useTranslation } from 'third-party';

import {
  INFINITE_SCROLL_BOTTOM_OFFSET_PX,
  INFINITE_SCROLL_DEBOUNCE_TIME_MS,
  TOOLTIP_APPEAR_DELAY,
} from 'constants/common';
import { contractorsUrl, createFullUrl } from 'constants/url';

import {
  loadMoreProjectsAdditiveTypes,
  loadProjectsAdditiveTypes,
  updateAdditiveType,
} from 'modules/additiveTypesList';
import { useCache } from 'modules/cache';
import {
  loadMoreProjectsMixTypes,
  loadProjectsMixTypes,
  updateMixType,
} from 'modules/mixTypesList';

import { editFormTranslations } from 'features/PlantSettings/MixTypeTab/MixTypesView';
import { EditFormPopup } from 'features/PlantSettings/common/EditFormPopup';
import {
  ListItem,
  ListItemLabel,
  ListItemLink,
  ListWrapper,
  LoaderListItem,
  LoaderWrapper,
} from 'features/PlantSettings/common/commonHelpers';

import { OutlinedSearchInput } from 'styles/common';

const SearchPanel = styled(Stack)`
  border-bottom: 1px solid ${props => props.theme.custom.palette.muted50};
  background: ${props => props.theme.custom.palette.backgroundTheme};
  justify-content: flex-end;
  padding: 0.8rem;
`;

const StyledOutlinedSearchInput = styled(OutlinedSearchInput)`
  width: 100%;
`;

const SearchEmptyState = styled(Typography)`
  margin-top: 3rem;
  text-align: center;
  font-size: 1.6rem;
  font-weight: 600;
  color: ${props => props.theme.custom.palette.muted800};
`;

const EmptyState = styled(SearchEmptyState)`
  white-space: pre-wrap;
  padding: 0 2rem;
  text-align: left;
`;

const ViewCustomerProfileLink = styled(Button)`
  align-self: flex-start;
  margin-top: 2rem;
  padding: 0;
  margin-left: 2rem;
`;

const addToMasterListFormTranslations = ({
  name,
}: {
  name: string | null;
}) => ({
  ...editFormTranslations,
  submitButtonLabel: i18n.t('common.add'),
  title: i18n.t('plantSettings.addToMasterList.title'),
  successUpdateMessage: i18n.t('mixTypes.addToMasterList.successNotification', {
    mixName: name || '',
  }),
});

type ItemT = APIMasterMixType | APIMasterAdditiveType;

type Props = {
  items: ItemT[];
  dataTotalCount: number;
  updateData: OmitFirstArg<
    typeof loadProjectsMixTypes | typeof loadProjectsAdditiveTypes
  >;
  loadMoreData: OmitFirstArg<
    typeof loadMoreProjectsMixTypes | typeof loadMoreProjectsAdditiveTypes
  >;
  dataLoaded: boolean;
  loadDataPending: boolean;
  loadMoreDataPending: boolean;
  addToMasterListPending: boolean;
  addItemToMasterList: OmitFirstArg<
    typeof updateMixType | typeof updateAdditiveType
  >;
  onAddToMasterList: () => void;
  searchFieldPlaceholder: string;
  emptyStateText: string;
  emptyStateTextForSearch: string;
  itemUrl: (projectId: string, companyId: string) => string;
};

export const IndividualProjectMixesOrAdditives: React.FC<Props> = ({
  items,
  dataTotalCount,
  updateData,
  loadMoreData,
  dataLoaded,
  addToMasterListPending,
  loadDataPending,
  loadMoreDataPending,
  addItemToMasterList,
  onAddToMasterList,
  emptyStateText,
  searchFieldPlaceholder,
  emptyStateTextForSearch,
  itemUrl,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [searchKeyword, setSearchKeyword] = useState<string | null>(null);
  const [itemToAddToMasterList, setItemToAddToMasterList] =
    useState<ItemT | null>(null);

  const { loadMixTypeCategories, createMixTypeCategory } = useCache();

  const hasListLoadMoreButton = dataLoaded && dataTotalCount > items.length;

  // creating ref for always having the actual
  // value inside `onScrollToBottom` callback,
  const hasListLoadMoreButtonRef = useRef(hasListLoadMoreButton);
  hasListLoadMoreButtonRef.current = hasListLoadMoreButton;

  const onScrollToBottom = useCallback(() => {
    if (hasListLoadMoreButtonRef.current && !loadMoreDataPending) {
      loadMoreData({ name: searchKeyword });
    }
  }, [loadMoreDataPending, loadMoreData, searchKeyword]);

  const onScrollToBottomDebounced = _.throttle(
    onScrollToBottom,
    INFINITE_SCROLL_DEBOUNCE_TIME_MS,
  );

  const scrollRef = useBottomScrollListener<HTMLDivElement>(
    onScrollToBottomDebounced,
    {
      offset: INFINITE_SCROLL_BOTTOM_OFFSET_PX,
      triggerOnNoScroll: false,
    },
  );

  const onSearchChange = useCallback(
    (value: string | null) => {
      setSearchKeyword(value);
      updateData({ name: value });
      scrollRef.current?.scrollTo({ top: 0 });
    },
    [updateData, scrollRef],
  );

  const handleAddToMasterClick = useCallback((item: ItemT) => {
    setItemToAddToMasterList(item);
  }, []);

  const handleNavigateToProject = useCallback(
    (item: ItemT) => {
      if (item.companyId && item.projectId) {
        const urlToRedirect = createFullUrl(
          itemUrl(item.projectId, item.companyId),
        );
        window.open(urlToRedirect, '_blank');
      }
    },
    [itemUrl],
  );

  const handleAddToMasterSuccess = useCallback(() => {
    updateData({
      name: searchKeyword,
      pageNumber: 1,
      pageSize: DEFAULT_PAGE_SIZE,
    });
    onAddToMasterList?.();
    scrollRef.current?.scrollTo({ top: 0 });
  }, [updateData, scrollRef, searchKeyword, onAddToMasterList]);

  useEffect(() => {
    return () => {
      if (searchKeyword) {
        setSearchKeyword('');
        updateData();
      }
    };
  }, [searchKeyword, updateData]);

  const viewCustomerProfile = () => {
    const urlToRedirect = createFullUrl(contractorsUrl());
    window.open(urlToRedirect, '_blank');
  };

  return (
    <>
      {itemToAddToMasterList && (
        <Popover
          anchorEl={document.body}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          open
          PaperProps={{ sx: { overflow: 'visible' } }}
        >
          <EditFormPopup
            defaultValues={{ ...itemToAddToMasterList, categories: [] }}
            updateData={addItemToMasterList}
            loadDataCategories={loadMixTypeCategories}
            createDataCategory={createMixTypeCategory}
            {...addToMasterListFormTranslations(itemToAddToMasterList)}
            updatePending={addToMasterListPending}
            onSuccessUpdate={handleAddToMasterSuccess}
            onClosePopup={() => setItemToAddToMasterList(null)}
          />
        </Popover>
      )}
      {dataTotalCount === 0 && !searchKeyword && !loadDataPending ? (
        <>
          <EmptyState>{emptyStateText}</EmptyState>
          <ViewCustomerProfileLink variant="text" onClick={viewCustomerProfile}>
            {t('plantSettings.insightsIndividualProjectsCustomerProfile')}
          </ViewCustomerProfileLink>
        </>
      ) : (
        <>
          <SearchPanel direction="row">
            <StyledOutlinedSearchInput
              isClearable
              onChange={onSearchChange}
              placeholder={searchFieldPlaceholder}
            />
          </SearchPanel>
          {dataLoaded ? (
            <ListWrapper ref={scrollRef}>
              {!items.length ? (
                <SearchEmptyState variant="h6">
                  {emptyStateTextForSearch}
                </SearchEmptyState>
              ) : (
                items.map(item => (
                  <ListItem key={item?.id}>
                    <Tooltip
                      placement="top-start"
                      disableInteractive
                      enterDelay={TOOLTIP_APPEAR_DELAY}
                      title={item.name}
                    >
                      <ListItemLabel variant="body2">{item.name}</ListItemLabel>
                    </Tooltip>
                    <Stack direction={'row'}>
                      <ListItemLink
                        onClick={() => handleAddToMasterClick(item)}
                      >
                        {`+ ${t(
                          'plantSettings.projectSpecificPanel.addToMasterList',
                        )}`}
                      </ListItemLink>
                      <IconButton onClick={() => handleNavigateToProject(item)}>
                        <OpenInNewOutlined
                          style={{ width: '1.8rem', height: '1.8rem' }}
                          sx={{ color: theme.custom.palette.secondary500 }}
                        />
                      </IconButton>
                    </Stack>
                  </ListItem>
                ))
              )}
              {hasListLoadMoreButton && (
                <LoaderListItem key="loader">
                  <CircularProgress size="2rem" />
                </LoaderListItem>
              )}
            </ListWrapper>
          ) : (
            <LoaderWrapper>
              <CircularProgress size="2rem" />
            </LoaderWrapper>
          )}
        </>
      )}
    </>
  );
};
