import React from 'react';

import { AutocompleteProps } from '@mui/material';
import { MUIAutocomplete } from 'components';

type Props = AutocompleteProps<any, any, any, any>;

export const Autocomplete: React.FC<Props> = props => {
  return <MUIAutocomplete {...props} />;
};
