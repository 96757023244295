import React, { useEffect } from 'react';

import styled from 'styled-components';

import { APITicket, APITicketStatuses } from '@cd3p/core/types/api';
import { TextareaAutosize } from '@mui/base';
import { ConfirmationDialog } from 'components';
import { emit } from 'hooks/usePubSub';

import { useForm, useSelector, useTranslation } from 'third-party';

import { APP_EVENTS } from 'constants/appEvent';
import { TEXTAREA_MAXLENGTH } from 'constants/common';

import { NotificationSeverity, useNotifications } from 'modules/notifications';
import { useOrder } from 'modules/order';

import { orderSelectors } from 'selectors';

const StyledTextareaAutosize = styled(TextareaAutosize)`
  margin-top: 1rem;
  width: 100%;
  font-size: 1.6rem;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.custom.palette.muted200};
  color: ${props => props.theme.custom.palette.gray};
  border-radius: 0.4rem;
  padding: 0.8rem;
  font-family: inherit;
  &:focus {
    outline: none !important;
    border-color: ${props => props.theme.custom.palette.muted200};
  }
`;

interface Props {
  ticket: APITicket;
  show: boolean;
  onClose: (value?: boolean) => void;
  onConfirm?: () => void;
}

const COMMENT_FIELD_NAME = 'comment';

export const CancelTicketConfirmation: React.FC<Props> = ({
  ticket,
  show,
  onClose,
  onConfirm,
}) => {
  const { t } = useTranslation();

  const { updateTicket } = useOrder();

  const { register, handleSubmit, watch, reset } = useForm();

  const { addNotification } = useNotifications();

  const updateTicketPending = useSelector(orderSelectors.updateTicketPending);

  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);

  const watchedCommentField = watch(COMMENT_FIELD_NAME);

  const handleDialogClose = () => {
    reset();
    onClose();
  };
  const submitForm = async () => {
    const serverData = {
      ticketNumber: ticket.ticketNumber,
      driverId: ticket.driver?.id,
      truckId: ticket.truck?.id,
      plantId: ticket.plant.id,
      batchSize: ticket.batchSize,
      ticketStatus: APITicketStatuses.Cancelled,
      cancellationReasonComment: watchedCommentField,
    };
    const result = await updateTicket(ticket.orderId, ticket.id, serverData);

    if (result.error) {
      addNotification({
        severity: NotificationSeverity.Error,
        message: t('order.ticket.ticketCancellationError'),
      });
    }
    emit(APP_EVENTS.CLOSE_OPENED_TICKET);
    reset();
    onClose();
    onConfirm?.();
  };

  return (
    <div>
      <ConfirmationDialog
        onClose={handleDialogClose}
        open={show}
        actionPending={updateTicketPending}
        handleActionClick={submitForm}
        actionButtonTitle={t('order.ticket.cancelTicketConfirmationAction')}
        title={t('order.ticket.closeTicketConfirmationTitle')}
        description={t('order.ticket.cancelTicketConfirmationDescription')}
        actionButtonDisabled={!watchedCommentField}
      >
        <form onSubmit={handleSubmit(submitForm)}>
          <StyledTextareaAutosize
            maxRows={4}
            minRows={4}
            {...register(COMMENT_FIELD_NAME)}
            placeholder={t('order.ticket.ticketCancellationPlaceholder')}
            maxLength={TEXTAREA_MAXLENGTH}
          />
        </form>
      </ConfirmationDialog>
    </div>
  );
};
