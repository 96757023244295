import { Application } from '@cd3p/core/constants/common';
import { ChatState } from '@cd3p/core/modules/chat';
import {
  NotificationsState,
  notificationsReducer,
} from '@cd3p/core/modules/notifications';
import { CoreUsersListState } from '@cd3p/core/modules/usersList';
import { subscribeActionMiddleware } from '@cd3p/core/utils/redux';
import { createMigrate, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {
  ActiveTicketsListState,
  activeTicketsListReducer,
} from './activeTicketsList';
import {
  AdditiveTypesListState,
  additiveTypesListReducer,
} from './additiveTypesList';
import {
  CustomerAccountsListState,
  adminCustomerAccountsListReducer,
} from './adminCustomerAccountsList';
import { AllOrdersListState, allOrdersListReducer } from './allOrdersList';
import { AllProjectsListState, allProjectsListReducer } from './allProjects';
import { AppState, appReducer } from './app';
import {
  AssignedDriversListState,
  assignedDriversListReducer,
} from './assignedDriversList';
import { CacheState, cacheReducer } from './cache';
import { chatReducer } from './chat';
import {
  AdditivesCategoriesListState,
  additivesCategoriesListReducer,
} from './concreteCategories/additivesCategoriesList';
import {
  MixTypesCategoriesListState,
  mixTypesCategoriesListReducer,
} from './concreteCategories/mixTypesCategoriesList';
import {
  ContractorAdditiveTypesState,
  contractorAdditiveTypesReducer,
} from './contractorAdditiveTypes';
import {
  ContractorMixTypesState,
  contractorMixTypesReducer,
} from './contractorMixTypes';
import {
  ContractorUsersListState,
  contractorUsersListReducer,
} from './contractorUsersList';
import {
  ContractorsListState,
  contractorsListReducer,
} from './contractorsList';
import {
  CustomerProjectsState,
  customerProjectsReducer,
} from './customerProjects';
import { DialogsListState, dialogsListReducer } from './dialogsList';
import { ImportOrdersState, importOrdersReducer } from './importOrders';
import { MixTypesListState, mixTypesListReducer } from './mixTypesList';
import { OrderState, orderReducer } from './order';
import { OrdersCacheState, ordersCacheReducer } from './ordersCache';
import { ProjectState, projectReducer } from './project';
import {
  ProjectOrdersListState,
  projectOrdersListReducer,
} from './projectOrdersList';
import {
  RequiredActionsListState,
  requiredActionsListReducer,
} from './requiredActionsList';
import { StorageState, storageReducer } from './storage';
import {
  TodayOrdersListState,
  todayOrdersListReducer,
} from './todayOrdersList';
import { TrackingState, trackingReducer } from './tracking';
import { TruckEmulatorState, truckEmulatorReducer } from './truckEmulator';
import { UserState, userReducer } from './user';
import { usersListReducer } from './usersList';

import {
  Sentry,
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  middlewareBuilder,
} from 'third-party';

import {
  PERSIST_STORE_KEY,
  PERSIST_STORE_PREFIX,
  PERSIST_STORE_VERSION,
} from 'constants/common';

import { initialMigration, migrations } from 'modules/persist/migration';
import { storeTransform } from 'modules/persist/transform';

import { getUserToken } from 'utils/auth';

import { AppPersistedState } from 'types/app';

export type State = {
  app: AppState;
  importOrders: ImportOrdersState;
  order: OrderState;
  allOrdersList: AllOrdersListState;
  todayOrdersList: TodayOrdersListState;
  projectOrdersList: ProjectOrdersListState;
  assignedDriversList: AssignedDriversListState;
  activeTicketsList: ActiveTicketsListState;
  allProjects: AllProjectsListState;
  tracking: TrackingState;
  notifications: NotificationsState;
  project: ProjectState;
  cache: CacheState;
  ordersCache: OrdersCacheState;
  contractorUsersList: ContractorUsersListState;
  contractorsList: ContractorsListState;
  truckEmulator: TruckEmulatorState;
  chat: ChatState;
  mixTypesList: MixTypesListState;
  additiveTypesList: AdditiveTypesListState;
  contractorMixTypes: ContractorMixTypesState;
  contractorAdditiveTypes: ContractorAdditiveTypesState;
  customerProjects: CustomerProjectsState;
  mixTypesCategoriesList: MixTypesCategoriesListState;
  additivesCategoriesList: AdditivesCategoriesListState;
  usersList: CoreUsersListState;
  user: UserState;
  requiredActionsList: RequiredActionsListState;
  dialogsList: DialogsListState;
  adminCustomerAccountsList: CustomerAccountsListState;
  storage: StorageState;
};

// custom middleware for web requests
const middleWare = [
  subscribeActionMiddleware,
  middlewareBuilder({
    httpRequestHandler: async (url: string, options?: Partial<RequestInit>) => {
      return await fetch(url, {
        ...(options?.body instanceof FormData
          ? {
              ...options,
              headers: new Headers({
                Authorization: getUserToken(),
                ...options?.headers,
              }),
            }
          : {
              ...options,
              headers: new Headers({
                'Content-Type': 'application/json',
                'client-os': 'web',
                'App-Client': `${Application.PLANT}:${APPLICATION_VERSION}`,
                'client-app': Application.PLANT,
                accept: 'application/json',
                Authorization: getUserToken(),
                ...options?.headers,
              }),
            }),
      });
    },
  }),
];
// added support for redux-dev tool
export const composeEnhancers =
  (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

// create store for redux
export const combinedReducer = combineReducers({
  app: appReducer,
  importOrders: importOrdersReducer,
  order: orderReducer,
  allOrdersList: allOrdersListReducer,
  todayOrdersList: todayOrdersListReducer,
  projectOrdersList: projectOrdersListReducer,
  assignedDriversList: assignedDriversListReducer,
  activeTicketsList: activeTicketsListReducer,
  allProjects: allProjectsListReducer,
  tracking: trackingReducer,
  notifications: notificationsReducer,
  project: projectReducer,
  cache: cacheReducer,
  ordersCache: ordersCacheReducer,
  contractorUsersList: contractorUsersListReducer,
  contractorsList: contractorsListReducer,
  truckEmulator: truckEmulatorReducer,
  chat: chatReducer,
  mixTypesList: mixTypesListReducer,
  additiveTypesList: additiveTypesListReducer,
  contractorMixTypes: contractorMixTypesReducer,
  contractorAdditiveTypes: contractorAdditiveTypesReducer,
  customerProjects: customerProjectsReducer,
  mixTypesCategoriesList: mixTypesCategoriesListReducer,
  additivesCategoriesList: additivesCategoriesListReducer,
  usersList: usersListReducer,
  user: userReducer,
  requiredActionsList: requiredActionsListReducer,
  dialogsList: dialogsListReducer,
  adminCustomerAccountsList: adminCustomerAccountsListReducer,
  storage: storageReducer,
});

type GlobalReducerT = typeof combinedReducer;
const rootReducer: GlobalReducerT = (state, action) => {
  return combinedReducer(state, action);
};

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
});

const persistConfig = {
  key: PERSIST_STORE_KEY,
  whitelist: ['storage'],
  prefix: PERSIST_STORE_PREFIX,
  storage,
  version: PERSIST_STORE_VERSION,
  transforms: [storeTransform],
  migrate: (state: AppPersistedState, version: number) => {
    if (state) {
      return typeof state._persist.version === 'number'
        ? // @ts-ignore
          createMigrate(migrations)(state, version)
        : initialMigration(state);
    } else {
      return Promise.resolve(state);
    }
  },
};

// @ts-ignore
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(...middleWare), sentryReduxEnhancer),
);
const persistor = persistStore(store);

export { store, persistor };
