import { DEFAULT_TIMEZONE } from '../constants/common';
import {
  currentUserInfoEndpoint,
  providerAfterHoursSettingsEndpoint,
  providerEndpoint,
  providerSettingsEndpoint,
  registerUserDeviceEndpoint,
  userSelfEndpoint,
} from '../constants/endpoints';
import {
  APIApplicationVersion,
  APIOrderStatus,
  APIProviderSettings,
  APIUser,
} from '../types/api';
import { isFeatureEnabled } from '../utils/app';
import { httpMethod } from '../utils/sra';
import { mapUserToUpdateUserRequest } from '../utils/user';

export type CoreAppState = {
  user: Partial<APIUser> | null;
  isAppLoading: boolean;
  isAppLoaded: boolean;
  isChatFeatureEnabled: boolean;
  isApiDocsFeatureEnabled: boolean;
  isProviderSettingsLoaded: boolean;
  openedChatOrderId: number | null;
  providerTimeZone: string;
  isDriverAssignmentEnabled: boolean;
  isBatchSoftwareIntegrationEnabled: boolean;
  isProjectCreationByContractorEnabled: boolean;
  orderConfirmationFirstReminderHours: number;
  orderConfirmationSecondReminderHours: number;
  orderConfirmationSecondReminderEnabled: boolean;
  isTimeStampEnabled: boolean;
  updateUserPending: boolean;
  getUserInfoPending: boolean;
  contractorOrderEditingAllowedStates: APIOrderStatus[];
  updateProviderSettingsPending: boolean;
  providerAdminEmail: string | null;
  loadProviderSettingsPending: boolean;
};

export const coreAppState: CoreAppState = {
  user: null,
  isAppLoading: false,
  isAppLoaded: false,
  isChatFeatureEnabled: false,
  isApiDocsFeatureEnabled: false,
  isProviderSettingsLoaded: false,
  openedChatOrderId: null,
  providerTimeZone: DEFAULT_TIMEZONE,
  isDriverAssignmentEnabled: false,
  isBatchSoftwareIntegrationEnabled: false,
  isProjectCreationByContractorEnabled: false,
  orderConfirmationFirstReminderHours: 0,
  orderConfirmationSecondReminderHours: 0,
  orderConfirmationSecondReminderEnabled: false,
  isTimeStampEnabled: false,
  updateUserPending: false,
  getUserInfoPending: false,
  contractorOrderEditingAllowedStates: [],
  updateProviderSettingsPending: false,
  providerAdminEmail: null,
  loadProviderSettingsPending: false,
};

export const getUserInfo = () => ({
  name: 'getUserInfo',
  url: currentUserInfoEndpoint(),
  method: httpMethod.get,
  onSuccess: (_state: CoreAppState, payload: APIUser) => ({
    user: payload,
  }),
});

export const registerUserDevice = (token: string) => ({
  name: 'registerUserDevice',
  url: registerUserDeviceEndpoint(),
  method: httpMethod.post,
  params: {
    token,
  },
  // onSuccess: we don't put anything to store
});

export const userLogOut = () => ({
  name: 'userLogOut',
  updater: () => ({
    user: null,
  }),
});

export const setOpenedChatOrderId = (openedChatOrderId: number | null) => ({
  name: 'setOpenedChatOrderId',
  updater: () => ({
    openedChatOrderId,
  }),
});

export const setAppLoading = (isAppLoading: boolean) => ({
  name: 'SET_APP_LOADING',
  updater: () => ({
    isAppLoading: isAppLoading,
    isAppLoaded: true,
  }),
});

export const updateApplicationVersions = (
  applicationVersions: APIApplicationVersion[],
) => ({
  name: 'updateApplicationVersions',
  updater: (state: CoreAppState) => ({
    user: {
      ...state.user,
      newApplicationVersions: [...applicationVersions],
    },
  }),
});

export const updateUser = (
  providerId: string,
  body: Partial<APIUser> & { password?: string },
) => ({
  name: 'updateUser',
  url: userSelfEndpoint(providerId),
  method: httpMethod.put,
  body: () => mapUserToUpdateUserRequest(body, providerId),
  onSuccess: (_state: CoreAppState, payload: APIUser) => ({
    user: payload,
  }),
});

const fetchProvider = (providerId: string) => ({
  name: 'fetchProvider',
  url: providerEndpoint(providerId),
  method: httpMethod.post,
});

const fetchProviderSettings = (providerId: string) => ({
  name: 'fetchProviderSettings',
  url: providerSettingsEndpoint(providerId),
  method: httpMethod.get,
});

const fetchProviderAfterHoursSettings = (providerId: string) => ({
  name: 'fetchProviderAfterHoursSettings',
  url: providerAfterHoursSettingsEndpoint(providerId),
  method: httpMethod.get,
});

const loadProviderSettings = (providerId: string) => ({
  name: 'loadProviderSettings',
  url: providerSettingsEndpoint(providerId),
  method: httpMethod.get,
  onSuccess: (_state: any, payload: APIProviderSettings[]) => {
    const providerSettings = payload.find((item) => item?.key === 'TimeZone');
    const contractorOrderEditingAllowedStates = payload.find(
      (item) => item?.key === 'ContractorEditingAllowedStates',
    );
    const orderConfirmationFirstReminderHours = payload.find(
      (item) => item?.key === 'OrderConfirmationFirstReminderHours',
    );
    const orderConfirmationSecondReminderHours = payload.find(
      (item) => item?.key === 'OrderConfirmationSecondReminderHours',
    );
    const providerAdminEmail = payload.find(
      (item) => item?.key === 'ProviderAdminEmail',
    );
    return {
      providerTimeZone: providerSettings?.value || DEFAULT_TIMEZONE, // BE doesn't send default timezone in settings
      orderConfirmationFirstReminderHours: orderConfirmationFirstReminderHours
        ? Number(orderConfirmationFirstReminderHours.value)
        : 0,
      orderConfirmationSecondReminderHours: orderConfirmationSecondReminderHours
        ? Number(orderConfirmationSecondReminderHours.value)
        : 0,
      orderConfirmationSecondReminderEnabled: isFeatureEnabled(
        payload,
        'OrderConfirmationSecondReminderEnabled',
      ),
      isChatFeatureEnabled: isFeatureEnabled(payload, 'IsChatFeatureEnabled'),
      isApiDocsFeatureEnabled: isFeatureEnabled(
        payload,
        'IsApiDocsFeatureEnabled',
      ),
      isDriverAssignmentEnabled: isFeatureEnabled(
        payload,
        'IsDriverAssignmentEnabled',
      ),
      isBatchSoftwareIntegrationEnabled: isFeatureEnabled(
        payload,
        'IsBatchSoftwareIntegrationEnabled',
      ),
      isProjectCreationByContractorEnabled: isFeatureEnabled(
        payload,
        'IsProjectCreationByContractorEnabled',
      ),
      isTimeStampEnabled: isFeatureEnabled(payload, 'IsTimeStampEnabled'),
      isProviderSettingsLoaded: true,
      contractorOrderEditingAllowedStates:
        (contractorOrderEditingAllowedStates?.value?.split(
          ',',
        ) as APIOrderStatus[]) || [],
      providerAdminEmail: providerAdminEmail?.value || null,
    };
  },
});

const updateProviderSettings = (
  providerId: string,
  providerSettings: { key: string; value: string }[],
) => ({
  name: 'updateProviderSettings',
  url: providerSettingsEndpoint(providerId),
  method: httpMethod.put,
  body: {
    providerSettings,
  },
});

export const coreAppMethods = {
  updateApplicationVersions,
  getUserInfo,
  registerUserDevice,
  userLogOut,
  setOpenedChatOrderId,
  setAppLoading,
  updateUser,
  fetchProvider,
  loadProviderSettings,
  fetchProviderSettings,
  fetchProviderAfterHoursSettings,
  updateProviderSettings,
};
