export const createNumberRegexp = ({
  length,
  mantissa = 0,
  allowNegative = false,
  allowZero = false,
}: {
  length: number;
  mantissa?: number;
  allowNegative?: boolean;
  allowZero?: boolean;
}) => {
  const negative = allowNegative ? '-' : '';
  let decimal = '';
  if (mantissa > 0) {
    decimal = `(?:[.,][0-9]{0,${mantissa}})?`;
  }
  return new RegExp(
    `^${negative}[${allowZero ? 0 : 1}-9][0-9]{0,${length - 1}}${decimal}$`,
  );
};
export const emailRegexp = new RegExp(
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
);

// dayjs thinks this time is invalid 04:00PM, there must be a space before AM/PM - 04:00 PM
export const timeRegexp = new RegExp(
  /((1[0-2]|0?[1-9]):([0-5][0-9]) ([AaPp][Mm]))/,
);

// https://regexr.com/346hf date regexp with leap years, allowing DD/MM/YYYY, MM/DD/YYYY, DD/MM/YY formats
export const dateRegexp = new RegExp(
  /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/,
);
