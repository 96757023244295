import React, { useCallback } from 'react';

import {
  APIResponse,
  APIUser,
  APIUserType,
  APIUsersList,
} from '@cd3p/core/types/api';
import { FormField, TypeaheadFormField, Typography } from 'components';
import { FieldError, FieldName, UseControllerProps } from 'react-hook-form';
import { FormatOptionLabelMeta, SingleValue } from 'react-select';

import { styled, useFormContext, useTranslation } from 'third-party';

import { FIELD_MAX_LENGTH } from 'constants/common';

import { useCache } from 'modules/cache';

import { TypeaheadDataOption } from 'types/app';

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

type Props<FormPropsT extends Record<string, any>> = {
  fieldName: FieldName<FormPropsT>;
  label: string;
  placeholder: string;
  notFoundText?: string;
  requiredErrorMessage?: string;
  maxLength?: number;
  isDisabled?: boolean;
  isRequired?: boolean;
  rules?: UseControllerProps['rules'];
  searchUserType?: APIUserType;
  onChange?: (option?: TypeaheadDataOption<APIUser> | null) => void;
  onClear?: () => void;
};

export const EmailSearchField = <FormPropsT extends Record<string, any>>({
  fieldName,
  label,
  placeholder,
  notFoundText,
  requiredErrorMessage,
  maxLength = FIELD_MAX_LENGTH,
  isDisabled,
  isRequired,
  rules,
  searchUserType,
  onChange,
  onClear,
}: Props<FormPropsT>) => {
  const { loadUsersEmails } = useCache();

  const { t } = useTranslation();

  const {
    setValue,
    control,
    formState: { errors, isSubmitted },
  } = useFormContext();

  const notFoundTextCallback = useCallback(
    () => notFoundText || t('typeahead.notFound'),
    [notFoundText, t],
  );

  const loadCustomersOptions = async (value: string) => {
    const result: APIResponse<APIUsersList> = await loadUsersEmails(
      value.trim(),
      searchUserType,
    );
    let options: TypeaheadDataOption<APIUser>[] = [];
    if (!result.error) {
      options = result.payload.result.map(it => ({
        label: it.email,
        value: it.email,
        data: it,
      }));
    }
    return {
      options,
    };
  };

  const onSelectOption = useCallback(
    (option: SingleValue<TypeaheadDataOption<APIUser>>) => {
      // @ts-ignore fix type for `option`
      setValue(fieldName, option, {
        shouldValidate: isSubmitted,
        shouldDirty: true,
      });
      onChange?.(option);
      if (option === null) {
        onClear?.();
      }
    },
    [setValue, fieldName, isSubmitted, onChange, onClear],
  );

  const formatOptionLabel = (
    { label, data }: TypeaheadDataOption<APIUser>,
    formatOptionLabelMeta: FormatOptionLabelMeta<TypeaheadDataOption<APIUser>>,
  ) => {
    const isSelectedValue = Boolean(formatOptionLabelMeta.selectValue?.length);
    const isExistedValue = data?.id;
    return (
      <OptionWrapper>
        <Typography fontWeight={isSelectedValue ? 400 : 600}>
          {label}
        </Typography>
        {!isSelectedValue && isExistedValue && (
          <Typography>{`${data?.firstName} ${data?.lastName}`}</Typography>
        )}
      </OptionWrapper>
    );
  };

  return (
    <FormField
      fieldName={fieldName}
      label={label}
      fieldError={errors[fieldName] as FieldError}
      requiredErrorMessage={
        requiredErrorMessage || t('common.form.emptyFieldError')
      }
      control={control}
      isDisabled={isDisabled}
      isRequired={isRequired}
      rules={rules}
      render={({ field }) => (
        <TypeaheadFormField
          openMenuOnClick
          hasError={!!errors[fieldName]}
          placeholder={placeholder}
          noOptionsMessage={notFoundTextCallback}
          loadOptions={loadCustomersOptions}
          onChange={onSelectOption}
          isDisabled={isDisabled}
          isCreatable
          value={field.value}
          maxLength={maxLength}
          formatOptionLabel={formatOptionLabel}
        />
      )}
    />
  );
};
