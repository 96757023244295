import React, { ReactNode } from 'react';

import styled, { useTheme } from 'styled-components';

import { Box, Button, Grid, LoadingButton, SvgIcon, Tooltip } from 'components';

import {
  EditableDetailsHeaderButton,
  SectionTitle,
  StyledEditButton,
} from './EditableDetailsHeaderButton';

import { useTranslation } from 'third-party';

import { TOOLTIP_APPEAR_DELAY } from 'constants/common';

const EditableDetailsWrapperComponent = styled(Grid)`
  padding-top: ${props => props.theme.custom.paddings.componentPaddingSmall};
  padding-bottom: ${props => props.theme.custom.paddings.componentPaddingSmall};
`;

const StyledEditableWrapper = styled(Grid)`
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.custom.palette.primary500};
  padding: ${props => props.theme.custom.paddings.componentPaddingSmall};
  box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
`;

interface Props {
  sectionTitle: string;
  onSave: () => void;
  onCancel: () => void;
  isLoading: boolean;
  isEditMode: boolean;
  setEditMode: (value: boolean) => void;
  disableEditing?: boolean;
  className?: string;
  isSaveDisabled: boolean;
  children?: ReactNode | string;
  titleVariant?: 'h5' | 'h2';
}

const Header = styled(SectionTitle)`
  overflow: hidden;
  white-space: nowrap;
  max-width: 54%;
  text-overflow: ellipsis;
`;

const StyledGrid = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const EditableDetailsWrapper: React.FC<Props> = props => {
  const {
    sectionTitle,
    onSave,
    onCancel,
    isLoading = false,
    isEditMode = false,
    setEditMode,
    disableEditing,
    isSaveDisabled = false,
    titleVariant = 'h5',
    children,
    className,
  } = props;
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <EditableDetailsWrapperComponent className={className}>
      {isEditMode ? (
        <StyledEditableWrapper>
          <StyledGrid container>
            {!isEditMode ? (
              <StyledEditButton>
                <SectionTitle variant={titleVariant}>
                  {sectionTitle}&nbsp;
                  <SvgIcon height="1.6rem" width="1.6rem" icon="edit" />
                </SectionTitle>
              </StyledEditButton>
            ) : (
              <Tooltip
                placement="top-start"
                enterDelay={TOOLTIP_APPEAR_DELAY}
                enterNextDelay={TOOLTIP_APPEAR_DELAY}
                title={sectionTitle}
              >
                <Header variant={titleVariant}> {sectionTitle}&nbsp; </Header>
              </Tooltip>
            )}
            <Box>
              <Button variant="outlined" color="secondary" onClick={onCancel}>
                {t('order.action.cancel')}
              </Button>
              <LoadingButton
                variant="contained"
                style={{
                  marginLeft: theme.custom.paddings.componentPaddingSmall,
                }}
                disabled={isSaveDisabled}
                loading={isLoading}
                onClick={onSave}
              >
                {t('common.save')}
              </LoadingButton>
            </Box>
          </StyledGrid>
          <Grid>{children}</Grid>
        </StyledEditableWrapper>
      ) : (
        <Grid>
          <EditableDetailsHeaderButton
            titleVariant={titleVariant}
            sectionTitle={sectionTitle}
            handleOnClick={setEditMode}
            disableEditing={disableEditing}
          />
          {children}
        </Grid>
      )}
    </EditableDetailsWrapperComponent>
  );
};
