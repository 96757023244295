import React from 'react';

import styled from 'styled-components';

import EditOutlined from '@mui/icons-material/EditOutlined';

import { Box, Typography } from 'components';
import { useTranslation } from 'react-i18next';

const StyledEditIcon = styled(EditOutlined)`
  color: ${props => props.theme.custom.palette.secondary700};
  width: 1.8rem;
  height: 1.8rem;
`;

export const EditLabel = styled(Typography)`
  display: inline-block;
  color: ${props => props.theme.custom.palette.secondary700};
  padding-left: 0.6rem;
  font-weight: 900;
  font-size: 12px;
  text-transform: uppercase;
  text-decoration: underline;
`;

const StyledEditWrapper = styled(Box)`
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  &:hover ${StyledEditIcon}, &:hover ${EditLabel} {
    color: ${props => props.theme.custom.palette.primary500};
  }
`;

type Props = {
  onClick?: () => void;
  className?: string;
};

export const EditButton: React.FC<Props> = ({ onClick, className }) => {
  const { t } = useTranslation();

  return (
    <StyledEditWrapper
      className={className}
      flexDirection={'row'}
      onClick={onClick}
    >
      <StyledEditIcon />
      <EditLabel variant="body2">{t('common.edit')}</EditLabel>
    </StyledEditWrapper>
  );
};
