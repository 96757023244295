import React, { useCallback } from 'react';

import styled from 'styled-components';

import { Stack, Tooltip } from '@mui/material';
import {
  Avatar,
  Button,
  LoadingButton,
  Popover,
  PopoverCloseButton,
  Typography,
} from 'components';

import { InviteEditUserFormT } from './InviteEditUserPopup';

import { useTranslation } from 'third-party';

import { getMaskedPhone } from 'constants/regexp';

const Container = styled.div`
  position: relative;
  width: 50rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-height: 0;
  flex-grow: 1;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  padding: 2.2rem 2.3rem 0;
  z-index: 1;
`;

const Title = styled(Typography)`
  line-height: 100%;
  color: ${props => props.theme.custom.palette.primary900};
  margin-bottom: 1.5rem;
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 1.6rem;
  margin-bottom: 2.3rem;
`;

const Content = styled(Stack)`
  flex-grow: 1;
  overflow: auto;
  padding: 2.2rem 2.3rem 2.7rem;
`;

const StyledAvatarSection = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1.2rem;
  gap: 1.2rem;
`;

const UserInfoWrapper = styled.div`
  overflow: hidden;
  text-overflow: 'ellipsis';
  max-width: 25rem;
`;

const StyledName = styled(Typography)`
  padding-bottom: 0.2rem;
`;
const StyledEmail = styled(Typography)`
  padding-bottom: 0.7rem;
  text-decoration: underline;
`;

type Props = {
  onClosePopup?: () => void;
  onSubmit: () => void;
  open?: boolean;
  userData: InviteEditUserFormT<false> | null;
  isLoading?: boolean;
};

export const UserExistsPopup: React.FC<Props> = ({
  onClosePopup,
  onSubmit,
  open = false,
  userData,
  isLoading,
}) => {
  const { t } = useTranslation();

  const handleCancel = useCallback(() => {
    onClosePopup?.();
  }, [onClosePopup]);

  const userName = `${userData?.firstName} ${userData?.lastName}`;
  const userEmail = userData?.email || '';
  const userPhone = userData?.phone || '';

  return (
    <Popover
      anchorEl={document.body}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      open={open}
      componentsProps={{
        backdrop: {
          style: {
            background: 'rgba(0,0,0,0.25)',
          },
        },
      }}
      PaperProps={{ sx: { overflow: 'visible' } }}
    >
      <PopoverCloseButton onClose={() => onClosePopup?.()} />
      <Container>
        <Header>
          <Title variant="h3">{t('customers.users.existUsers.title')}</Title>
          <ActionButtons>
            <Button
              disabled={isLoading}
              variant="outlined"
              color="secondary"
              onClick={handleCancel}
            >
              {t('common.cancel')}
            </Button>
            <LoadingButton
              disabled={isLoading}
              loading={isLoading}
              variant="contained"
              type="submit"
              onClick={onSubmit}
            >
              {t('customers.users.existUsers.sendInvite')}
            </LoadingButton>
          </ActionButtons>
        </Header>
        <Content>
          <Typography variant="body2">
            {t('customers.users.existUsers.description')}
          </Typography>
          <StyledAvatarSection>
            <Avatar
              firstName={userData?.firstName}
              lastName={userData?.lastName}
            />
            <UserInfoWrapper>
              <Tooltip title={userName} placement="right-end">
                <StyledName noWrap variant="subtitle2">
                  {userName}
                </StyledName>
              </Tooltip>
              <Tooltip title={userEmail}>
                <StyledEmail noWrap variant="inherit" fontSize={13}>
                  {userEmail}
                </StyledEmail>
              </Tooltip>
              <Tooltip title={userPhone}>
                <StyledName noWrap variant="inherit" fontSize={13}>
                  {getMaskedPhone(userPhone)}
                </StyledName>
              </Tooltip>
            </UserInfoWrapper>
          </StyledAvatarSection>
        </Content>
      </Container>
    </Popover>
  );
};
