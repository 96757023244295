export const DEFAULT_PAGE_SIZE = 20;
export const DEFAULT_PAGE_START = 1;

export const LIST_MAX_PAG_SIZE = 99;

export const getPageNumberAfterLoadMore = (
  items: any[],
  pageSize: number = DEFAULT_PAGE_SIZE,
) => Math.ceil(items.length / pageSize) + 1;
export const getPageSizeDependingOnItems = (items: any[]) =>
  Math.ceil(items.length / DEFAULT_PAGE_SIZE) * DEFAULT_PAGE_SIZE;
