import React from 'react';

import styled from 'styled-components';

import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';

import { APISubscribedUser } from '@cd3p/core/types/api';
import { getColorForUserAvatar } from '@cd3p/core/utils/user';
import { Avatar, IconButton, Stack, Typography } from 'components';

const Row = styled(Stack)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.4rem 0;
  flex: 1;
`;

const NameText = styled(Typography)`
  color: ${props => props.theme.custom.palette.secondary900};
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin-left: 1rem;
`;

const StyledIconButton = styled(IconButton)`
  padding: 0.4rem;
`;

const StyledNotificationsActiveIcon = styled(NotificationsActiveIcon)`
  color: ${props => props.theme.custom.palette.primary800};
`;

type Props = {
  user: APISubscribedUser;
  onBellClick?: () => void;
  isDisabled?: boolean;
};

export const UserListItem: React.FC<Props> = React.memo(
  ({ user, onBellClick, isDisabled }) => {
    return (
      <Row>
        <Stack direction="row" alignItems="center">
          <Avatar
            size="3.2rem"
            fontSize="1.8rem"
            firstName={user?.firstName || ''}
            lastName={user?.lastName || ''}
            backgroundColor={getColorForUserAvatar(user?.id)}
          />
          <NameText>{`${user?.firstName} ${user?.lastName}`}</NameText>
        </Stack>
        <StyledIconButton disabled={isDisabled} onClick={onBellClick}>
          <StyledNotificationsActiveIcon color="primary" />
        </StyledIconButton>
      </Row>
    );
  },
);
