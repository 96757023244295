import React from 'react';

import styled from 'styled-components';

import { APIDeliveryRateType, APIOrder } from '@cd3p/core/types/api';
import { DeliveryRateIcon } from 'components';

import { Trans, _, useTranslation } from 'third-party';

import {
  getOrderDeliveryRateDiff,
  shouldShowActualDeliveryRate,
} from 'utils/order';

const DeliveryRateMin = styled.span`
  color: ${props => props.theme.custom.palette.danger400};
`;

const DeliveryRateWrapper = styled.div<{ multiline?: boolean }>`
  display: flex;
  flex-direction: column;
  line-height: 1.25;
  width: ${props => (props.multiline ? '100%' : 'auto')};
  p,
  strong {
    display: inline-flex;
    align-items: center;
  }
  strong {
    font-weight: 700;
  }
`;

export const DeliveryRateTitle = ({ order }: { order: APIOrder | null }) => {
  let actualDeliveryRateElement;
  const { t } = useTranslation();

  const deliveryRateTypeUnit =
    order?.deliveryRateType === APIDeliveryRateType.CyHour
      ? t('order.sectionField.deliveryRateCyPerHour')
      : t('order.sectionField.deliveryRateMinPerTruck');

  if (order && !_.isNil(order?.actualDeliveryRate)) {
    const deliveryRateDiff = getOrderDeliveryRateDiff(
      order.deliveryRate,
      order.actualDeliveryRate,
    );
    const showActualDeliveryRate = shouldShowActualDeliveryRate(
      order?.deliveryRate,
      order?.actualDeliveryRate,
    );

    actualDeliveryRateElement = showActualDeliveryRate ? (
      <strong>
        {t('orderDetails.deliveryRateActual', {
          actualDeliveryRate: order.actualDeliveryRate,
          deliveryRateTypeUnit,
        })}
        <DeliveryRateIcon
          deliveryRate={order.deliveryRate}
          actualDeliveryRate={order.actualDeliveryRate}
        />
        <DeliveryRateMin>
          {deliveryRateDiff > 0 ? '+' : ''}
          {deliveryRateDiff}{' '}
          {order?.deliveryRateType === APIDeliveryRateType.CyHour
            ? 'CY'
            : 'min'}
        </DeliveryRateMin>
      </strong>
    ) : null;
  }

  return (
    <DeliveryRateWrapper multiline={!!actualDeliveryRateElement}>
      <Trans i18nKey="order.sectionField.deliveryRateRequested">
        <p>
          Requested: {{ deliveryRate: order?.deliveryRate }}{' '}
          {deliveryRateTypeUnit}
        </p>
      </Trans>
      {actualDeliveryRateElement}
    </DeliveryRateWrapper>
  );
};
