import React, { useCallback, useEffect, useMemo, useState } from 'react';

import styled from 'styled-components';

import { APIMasterMixType } from '@cd3p/core/types/api';
import { ConfirmationDialog, GlobalLoader, Popover } from 'components';
import { useHandleApiResult } from 'hooks/useHandleApiResult';

import { useSelector, useTranslation } from 'third-party';

import { useCache } from 'modules/cache';
import { useMixTypesList } from 'modules/mixTypesList';

import { mixTypesSelectors } from 'selectors';

import { editFormTranslations } from 'features/PlantSettings/MixTypeTab/MixTypesView';
import { EditFormPopup } from 'features/PlantSettings/common/EditFormPopup';

const EditLoader = styled(GlobalLoader)`
  width: 50rem;
`;

export const useManageMixTypeItem = () => {
  const { t } = useTranslation();

  const { deleteMixType, updateMixType, updateMixTypes, fetchMixType } =
    useMixTypesList();

  const { loadMixTypeCategories, createMixTypeCategory } = useCache();

  const [mixTypeIdForEdit, setMixTypeIdForEdit] = useState<null | number>(null);
  const [mixTypeIdForDelete, setMixTypeIdForDelete] = useState<null | number>(
    null,
  );
  const [mixTypeData, setMixTypeData] = useState<APIMasterMixType | null>(null);

  const updateMixTypePending = useSelector(
    mixTypesSelectors.updateMixTypePending,
  );
  const fetchMixTypePending = useSelector(
    mixTypesSelectors.fetchMixTypePending,
  );

  const handleApiResult = useHandleApiResult();

  useEffect(() => {
    const mixTypeId = mixTypeIdForEdit || mixTypeIdForDelete;
    if (mixTypeId) {
      handleApiResult<APIMasterMixType>(
        () => fetchMixType(mixTypeId),
        ({ result }) => {
          setMixTypeData(result.payload);
        },
        ({ showErrorToast }) => {
          showErrorToast();
          setMixTypeIdForDelete(null);
          setMixTypeIdForEdit(null);
        },
      );
    } else {
      setMixTypeData(null);
    }
  }, [mixTypeIdForEdit, mixTypeIdForDelete, handleApiResult, fetchMixType]);

  const onDeleteMixType = useCallback(async () => {
    if (mixTypeIdForDelete) {
      handleApiResult(
        async () => {
          const result = await deleteMixType(mixTypeIdForDelete);
          setMixTypeIdForDelete(null);
          return result;
        },
        ({ showBaseToast }) => {
          showBaseToast(t('mixTypes.notification.successDelete'));
          updateMixTypes();
        },
      );
    }
  }, [deleteMixType, handleApiResult, mixTypeIdForDelete, t, updateMixTypes]);

  const mixTypeItemDeleteConfirmation = useMemo(
    () => (
      <ConfirmationDialog
        onClose={() => {
          setMixTypeIdForDelete(null);
        }}
        open={!!mixTypeIdForDelete}
        handleActionClick={onDeleteMixType}
        isLoading={fetchMixTypePending || !mixTypeData}
        description={t('mixTypes.deleteConfirmationMessage', {
          count: mixTypeData?.assignment || 0,
        })}
      />
    ),
    [mixTypeIdForDelete, onDeleteMixType, fetchMixTypePending, mixTypeData, t],
  );

  const mixTypeItemEditPopup = useMemo(
    () =>
      mixTypeIdForEdit && (
        <Popover
          anchorEl={document.body}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          open
          PaperProps={{ sx: { overflow: 'visible' } }}
        >
          {fetchMixTypePending || !mixTypeData ? (
            <EditLoader />
          ) : (
            <EditFormPopup
              defaultValues={mixTypeData}
              updateData={updateMixType}
              loadDataCategories={loadMixTypeCategories}
              createDataCategory={createMixTypeCategory}
              {...editFormTranslations}
              updatePending={updateMixTypePending}
              onSuccessUpdate={updateMixTypes}
              onClosePopup={() => {
                setMixTypeIdForEdit(null);
              }}
            />
          )}
        </Popover>
      ),
    [
      createMixTypeCategory,
      fetchMixTypePending,
      loadMixTypeCategories,
      mixTypeData,
      mixTypeIdForEdit,
      updateMixType,
      updateMixTypePending,
      updateMixTypes,
    ],
  );

  return useMemo(
    () => ({
      mixTypeItemDeleteConfirmation,
      mixTypeItemEditPopup,
      setMixTypeIdForEdit,
      setMixTypeIdForDelete,
    }),
    [
      mixTypeItemDeleteConfirmation,
      mixTypeItemEditPopup,
      setMixTypeIdForEdit,
      setMixTypeIdForDelete,
    ],
  );
};
