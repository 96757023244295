import { getHelpCenterRequestEndpoint } from '../constants/endpoints';
import { APIHelpRequest } from '../types/api';
import { httpMethod, simplifyBuilder } from '../utils/sra';

export interface HelpCenterState {}

export const helpCenterState: HelpCenterState = {};

export const helpCenterBuilder = simplifyBuilder(helpCenterState, {});

export const sendSupportRequest = helpCenterBuilder.createServerAction(
  (providerId: string, request: APIHelpRequest) => ({
    name: 'sendSupportRequest',
    url: getHelpCenterRequestEndpoint(providerId),
    method: httpMethod.post,
    body: request,
  }),
);
