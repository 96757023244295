import { useMemo } from 'react';

import { httpMethod, simplifyBuilder } from '@cd3p/core';
import {
  APIProvider,
  APIProviderModelSearchResult,
  APIProviderRequest,
  APIProviderSearchFilter,
  APISortOrder,
  APIUserRequest,
  APIUserType,
} from '@cd3p/core/types/api';
import {
  DEFAULT_PAGE_SIZE,
  getPageNumberAfterLoadMore,
  getPageSizeDependingOnItems,
} from '@cd3p/core/utils/lists';

import { _, bindActionCreators, useDispatch } from 'third-party';

import {
  addProviderEndpoint,
  initiateAdminEndpoint,
  providersListEndpoint,
} from 'constants/endpoints';

import { State } from 'modules/index';

const defaultCustomerAccountsListFilters: {
  providerName: string;
} = {
  providerName: '',
};

export type CustomerAccountsListFilters =
  typeof defaultCustomerAccountsListFilters;

export type CustomerAccountListColumnId = keyof APIProvider;

export type CustomerAccountsListSorting = {
  sortField: CustomerAccountListColumnId;
  sortOrder: APISortOrder;
}[];

export type CustomerAccountsListState = {
  items: APIProviderModelSearchResult['result'];
  cusomerAccountsLoaded: boolean;
  loadCustomerAccountsPending: boolean;
  loadMoreCustomerAccountsPending: boolean;
  getCustomerAccountByIdPending: boolean;
  sorting: CustomerAccountsListSorting;
  pagination: {
    pageNumber: APIProviderModelSearchResult['pageNumber'];
    pageSize: APIProviderModelSearchResult['pageSize'];
  };
  filters: CustomerAccountsListFilters;
  count: number;
  createCustomerAccountPending: boolean;
  initiateAccountPending: boolean;
};

export const customerAccountsListState: CustomerAccountsListState = {
  items: [],
  sorting: [
    {
      sortField: 'providerName',
      sortOrder: APISortOrder.ASC,
    },
  ],
  cusomerAccountsLoaded: false,
  loadMoreCustomerAccountsPending: false,
  loadCustomerAccountsPending: false,
  getCustomerAccountByIdPending: false,
  pagination: { pageNumber: 1, pageSize: DEFAULT_PAGE_SIZE },
  filters: defaultCustomerAccountsListFilters,
  count: 0,
  createCustomerAccountPending: false,
  initiateAccountPending: false,
};

const builder = simplifyBuilder(customerAccountsListState, {});

const loadCustomerAccounts = builder.createServerAction(
  (body: Partial<APIProviderSearchFilter> = {}) => ({
    name: 'loadCustomerAccounts',
    url: providersListEndpoint(),
    body: (state: State) => ({
      pageSize: state.adminCustomerAccountsList.pagination.pageSize,
      pageNumber: state.adminCustomerAccountsList.pagination.pageNumber,
      searchSortOrders: state.adminCustomerAccountsList.sorting,
      ...state.adminCustomerAccountsList.filters,
      ...body,
    }),
    method: httpMethod.post,
    onSuccess: (
      state: CustomerAccountsListState,
      payload: APIProviderModelSearchResult,
    ) => {
      return {
        items: payload.result || [],
        cusomerAccountsLoaded: true,
        pagination: {
          pageNumber: payload.pageNumber,
          pageSize: payload.pageSize,
        },
        count: payload.count,
      };
    },
  }),
);

const loadMoreCustomerAccounts = builder.createServerAction(() => ({
  name: 'loadMoreCustomerAccounts',
  url: providersListEndpoint(),
  body: (state: State) => ({
    companyType: APIUserType.Contractor,
    pageNumber: getPageNumberAfterLoadMore(
      state.adminCustomerAccountsList.items,
      state.adminCustomerAccountsList.pagination.pageSize,
    ),
    pageSize: state.adminCustomerAccountsList.pagination.pageSize,
    searchSortOrders: state.adminCustomerAccountsList.sorting,
    ...state.adminCustomerAccountsList.filters,
  }),
  method: httpMethod.post,
  onSuccess: (
    state: CustomerAccountsListState,
    payload: APIProviderModelSearchResult,
  ) => {
    return {
      items: _.uniqBy([...state.items, ...payload.result], 'id'),
    };
  },
}));

const updateCustomerAccountsList = builder.createServerAction(() => ({
  name: 'updateCustomerAccountsList',
  url: providersListEndpoint(),
  body: (state: State) => ({
    pageNumber: 1,
    pageSize: getPageSizeDependingOnItems(
      state.adminCustomerAccountsList.items,
    ),
    searchSortOrders: state.adminCustomerAccountsList.sorting,
    ...state.adminCustomerAccountsList.filters,
  }),
  method: httpMethod.post,
  onSuccess: (
    _state: CustomerAccountsListState,
    payload: APIProviderModelSearchResult,
  ) => {
    return {
      items: payload.result,
      count: payload.count,
    };
  },
}));

const updateCustomerAccountInList = builder.createReduxAction(
  (customerId: string, data: Partial<APIProvider>) => ({
    name: 'updateCustomerAccountInList',
    updater: state => ({
      items: state.items.reduce<CustomerAccountsListState['items']>(
        (result, it) => {
          return [
            ...result,
            it.id === customerId
              ? {
                  ...it,
                  ...data,
                }
              : it,
          ];
        },
        [],
      ),
    }),
  }),
);

const updateCustomerAccountsListFilters = builder.createReduxAction(
  (newFilters: CustomerAccountsListState['filters']) => ({
    name: 'updateCustomerAccountsListFilters',
    updater: state => ({
      filters: {
        ...state.filters,
        ...newFilters,
      },
    }),
  }),
);

const resetCustomerAccountsList = builder.createReduxAction(() => ({
  name: 'resetCustomerAccountsList',
  updater: () => customerAccountsListState,
}));

const createCustomerAccount = builder.createServerAction(
  (body: APIProviderRequest) => ({
    name: 'createCustomerAccount',
    body,
    url: addProviderEndpoint(),
    method: httpMethod.post,
  }),
);

const initiateAccount = builder.createServerAction(
  (customerId: string, body: APIUserRequest) => ({
    name: 'initiateAccount',
    body,
    url: initiateAdminEndpoint(customerId),
    method: httpMethod.post,
  }),
);

export const useAdminCustomerAccountsList = () => {
  const dispatch = useDispatch();
  return useMemo(
    () =>
      bindActionCreators(
        {
          initiateAccount: initiateAccount.bind(null),
          createCustomerAccount: createCustomerAccount.bind(null),
          loadCustomerAccounts: loadCustomerAccounts.bind(null),
          loadMoreCustomerAccounts: loadMoreCustomerAccounts.bind(null),
          updateCustomerAccountsList: updateCustomerAccountsList.bind(null),
          resetCustomerAccountsList: resetCustomerAccountsList.bind(null),
          updateCustomerAccountInList: updateCustomerAccountInList.bind(null),
          updateCustomerAccountsListFilters:
            updateCustomerAccountsListFilters.bind(null),
        },
        dispatch,
      ),
    [dispatch],
  );
};

export const adminCustomerAccountsListReducer = builder.getReducers();
