import { State } from 'modules';

export const mixTypesListItems = (state: State) => state.mixTypesList.items;
export const mixTypesListCount = (state: State) => state.mixTypesList.count;
export const mixTypesLoaded = (state: State) =>
  state.mixTypesList.mixTypesLoaded;
export const mixTypesListSorting = (state: State) => state.mixTypesList.sorting;
export const mixTypesListFilters = (state: State) => state.mixTypesList.filters;
export const loadMixTypesPending = (state: State) =>
  state.mixTypesList.loadMixTypesPending;
export const addMixTypeToMasterListPending = (state: State) =>
  state.mixTypesList.addMixTypeToMasterListPending;
export const loadMoreMixTypesPending = (state: State) =>
  state.mixTypesList.loadMoreMixTypesPending;
export const createMixTypePending = (state: State) =>
  state.mixTypesList.createMixTypePending;
export const updateMixTypePending = (state: State) =>
  state.mixTypesList.updateMixTypePending;
export const mixTypeAssignment = (state: State) =>
  state.mixTypesList.mixTypeAssignment;
export const mixTypeAssignmentCount = (state: State) =>
  state.mixTypesList.mixTypeAssignment?.length || 0;
export const mixTypeAssignmentId = (state: State) =>
  state.mixTypesList.mixTypeAssignmentId;
export const loadMixTypesAssignmentPending = (state: State) =>
  state.mixTypesList.loadMixTypesAssignmentPending;
export const mostUsedMixTypesPercentage = (state: State) =>
  state.mixTypesList.mostUsedMixTypes?.percentage;
export const mostUsedMixTypesItems = (state: State) =>
  state.mixTypesList.mostUsedMixTypes?.usages;
export const loadMostUsedMixTypesPending = (state: State) =>
  state.mixTypesList.loadMostUsedMixTypesPending;
export const mostUsedMixTypesLoaded = (state: State) =>
  state.mixTypesList.mostUsedMixTypes !== null;
export const leastUsedMixTypesItems = (state: State) =>
  state.mixTypesList.leastUsedMixTypes.items;
export const leastUsedMixTypesCount = (state: State) =>
  state.mixTypesList.leastUsedMixTypes.count;
export const loadLeastUsedMixTypesPending = (state: State) =>
  state.mixTypesList.loadLeastUsedMixTypesPending;
export const loadMoreLeastUsedMixTypesPending = (state: State) =>
  state.mixTypesList.loadMoreLeastUsedMixTypesPending;
export const leastUsedMixTypesLoaded = (state: State) =>
  state.mixTypesList.leastUsedMixTypes.mixTypesLoaded;
export const fetchMixTypePending = (state: State) =>
  state.mixTypesList.fetchMixTypePending;
export const projectMixTypesItems = (state: State) =>
  state.mixTypesList.projectsMixTypesItems;
export const projectMixTypesCount = (state: State) =>
  state.mixTypesList.projectsMixTypesCount;
export const loadProjectMixTypesPending = (state: State) =>
  state.mixTypesList.loadProjectsMixTypesPending;
export const loadMoreProjectMixTypesPending = (state: State) =>
  state.mixTypesList.loadMoreProjectsMixTypesPending;
export const projectMixTypesLoaded = (state: State) =>
  state.mixTypesList.projectsMixTypesLoaded;
export const projectsMixTypesSearch = (state: State) =>
  state.mixTypesList.projectsMixTypesSearch;
