import React, { useEffect, useState } from 'react';

import { InsightBlock, SvgIcon } from 'components';

import { useSelector, useTranslation } from 'third-party';

import { useMixTypesList } from 'modules/mixTypesList';

import { mixTypesSelectors } from 'selectors';

type Props = {
  isActive?: boolean;
  onClick: () => void;
};

export const IndividualProjectMixesWidget: React.FC<Props> = ({
  onClick,
  isActive,
}) => {
  const { t } = useTranslation();

  const [totalCount, setTotalCount] = useState(0);

  const { loadProjectsMixTypes } = useMixTypesList();

  const projectMixTypesLoaded = useSelector(
    mixTypesSelectors.projectMixTypesLoaded,
  );
  const projectMixTypesCount = useSelector(
    mixTypesSelectors.projectMixTypesCount,
  );
  const projectsMixTypesSearch = useSelector(
    mixTypesSelectors.projectsMixTypesSearch,
  );

  useEffect(() => {
    if (!projectsMixTypesSearch) {
      setTotalCount(projectMixTypesCount);
    }
  }, [projectMixTypesCount, projectsMixTypesSearch]);

  useEffect(() => {
    if (!projectMixTypesLoaded) {
      loadProjectsMixTypes();
    }
  }, [loadProjectsMixTypes, projectMixTypesLoaded]);

  return (
    <InsightBlock
      isActive={isActive}
      isLoading={!projectMixTypesLoaded}
      title={t('plantSettings.insightsMixTypesIndividualProjectsTitle')}
      description={t(
        'plantSettings.insightsMixTypesIndividualProjectsDescription',
        {
          count: totalCount,
        },
      )}
      linkLabel={t('plantSettings.insightsViewList')}
      onClick={onClick}
      icon={
        <SvgIcon icon="insights-project-icon" height="3.6rem" width="3.6rem" />
      }
    />
  );
};
