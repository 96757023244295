import React, { useCallback, useEffect, useMemo } from 'react';

import styled, { useTheme } from 'styled-components';

import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

import {
  APIProject,
  APIProjectStatus,
  APIUserType,
} from '@cd3p/core/types/api';
import { formatDisplayedDate, getSortOrder } from '@cd3p/core/utils/common';
import {
  AddButton,
  Button,
  CanAccess,
  CircularProgress,
  DispatcherCanAccess,
  Stack,
  Table,
  Tag,
} from 'components';
import { useProjectArchivation } from 'hooks/useProjectArchivation';
import { useProjectDeletion } from 'hooks/useProjectDeletion';
import { subscribe } from 'hooks/usePubSub';

import {
  useLocation,
  useNavigate,
  useSelector,
  useTranslation,
} from 'third-party';

import { APP_EVENTS } from 'constants/appEvent';
import {
  contractorAddProjectUrl,
  customerProjectAddUrl,
  customerProjectDetailsUrl,
} from 'constants/url';

import {
  CustomerProjectsColumnId,
  useCustomerProjects,
} from 'modules/customerProjects';

import { appSelectors, customerProjectsSelectors } from 'selectors';

import {
  EmptyState,
  Header,
  HeaderWrapper,
  LoadingContainer,
  TableCellText,
  Wrapper,
  tableComponents,
  useTrackDeleteCompanyAvailability,
} from 'features/Contractors/Tabs/tabHelpers';

import { OutlinedSearchInput } from 'styles/common';
import { themeConfig } from 'styles/theme';

type Props = {
  customerId: string;
  isReadOnly?: boolean;
};

const StyledSearch = styled(OutlinedSearchInput)`
  width: 30rem;
  margin-right: 2rem;
`;

const getHighlightedKeywordContent = (
  originalValue: string | null,
  keyword: string | null,
) => {
  if (!keyword || keyword.length === 0 || !originalValue) {
    return originalValue;
  }
  const keywordToFindRegex = new RegExp(`(${keyword})`, 'gi');
  const partsToHighlight = originalValue.split(keywordToFindRegex);
  const reactElements = partsToHighlight.map((text, index) => {
    if (keyword.toLowerCase() !== text.toLowerCase()) {
      return text.length > 20 && partsToHighlight[index + 1]
        ? `...${text.slice(text.length - 20, text.length)}`
        : text;
    } else {
      return (
        <em
          key={index}
          style={{ backgroundColor: themeConfig.custom.palette.searchResult }}
        >
          {text}
        </em>
      );
    }
  });
  return <>{reactElements.flat()}</>;
};

export const ProjectsTab: React.FC<Props> = ({ customerId, isReadOnly }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const {
    loadCustomerProjects,
    loadMoreCustomerProjects,
    updateCustomerProjects,
    updateCustomerProjectsSorting,
    resetCustomerProjects,
    updateProjectsFilter,
    setSearchKeyword,
  } = useCustomerProjects();
  const refetchProjects = useCallback(
    () => updateCustomerProjects(customerId),
    [customerId, updateCustomerProjects],
  );
  const { ArchiveProjectConfirmationDialog, ArchiveProjectButton } =
    useProjectArchivation();
  const { DeleteProjectConfirmationDialog, DeleteProjectButton } =
    useProjectDeletion();

  const providerId = useSelector(appSelectors.providerId);
  const customerProjects = useSelector(
    customerProjectsSelectors.customerProjectsItems,
  );
  const customerProjectsLoaded = useSelector(
    customerProjectsSelectors.customerProjectsLoaded,
  );
  const customerProjectsCount = useSelector(
    customerProjectsSelectors.customerProjectsCount,
  );
  const loadCustomerProjectsPending = useSelector(
    customerProjectsSelectors.loadCustomerProjectsPending,
  );
  const loadMoreCustomerProjectsPending = useSelector(
    customerProjectsSelectors.loadMoreCustomerProjectsPending,
  );
  const sorting = useSelector(
    customerProjectsSelectors.customerProjectsSorting,
  );
  const showArchivedProjects = useSelector(
    customerProjectsSelectors.showArchivedProjects,
  );
  const searchKeyword = useSelector(customerProjectsSelectors.searchKeyword);
  const userType = useSelector(appSelectors.userType);
  const { sortField, sortOrder } = sorting[0];

  const onColumnHeaderClicked = useCallback(
    (columnId: CustomerProjectsColumnId) => {
      const searchSortOrders = [
        {
          sortField: columnId,
          sortOrder: getSortOrder(columnId, sortField, sortOrder),
        },
      ];
      updateCustomerProjectsSorting(searchSortOrders);
      loadCustomerProjects(customerId);
    },
    [
      customerId,
      loadCustomerProjects,
      sortField,
      sortOrder,
      updateCustomerProjectsSorting,
    ],
  );

  useEffect(() => {
    return subscribe(APP_EVENTS.CHANGE_PROVIDER, () => setSearchKeyword(null));
  }, [setSearchKeyword]);

  useEffect(() => {
    if (!customerProjectsLoaded) {
      loadCustomerProjects(customerId);
    }
  }, [loadCustomerProjects, customerId, customerProjectsLoaded]);

  const onProjectSearchChange = useCallback(
    (projectName: string) => {
      setSearchKeyword(projectName);
      updateProjectsFilter(showArchivedProjects, projectName);
      loadCustomerProjects(customerId);
    },
    [
      setSearchKeyword,
      updateProjectsFilter,
      showArchivedProjects,
      loadCustomerProjects,
      customerId,
    ],
  );

  useEffect(() => {
    return () => {
      resetCustomerProjects();
    };
  }, [resetCustomerProjects]);

  const onLoadMoreClicked = useCallback(() => {
    loadMoreCustomerProjects(customerId);
  }, [loadMoreCustomerProjects, customerId]);

  const onProjectClick = useCallback(
    (project: APIProject) => {
      navigate(customerProjectDetailsUrl(customerId, project.id), {
        state: {
          backUrl: location.pathname,
          backUrlLabel:
            userType === APIUserType.Dispatcher
              ? t('projectDetails.backButtonLabel')
              : t('projectDetails.projectsBackButtonLabel'),
        },
      });
    },
    [navigate, customerId, location.pathname, userType, t],
  );

  const columns = useMemo(() => {
    return [
      {
        id: 'createdDate',
        dataId: 'createdDate' as keyof APIProject,
        label: t('customers.projects.table.dateColumnLabel'),
        width: '15rem',
        formatter: (value: APIProject['createdDate']) => {
          return {
            value: formatDisplayedDate(value) || '',
            element: (
              <TableCellText>{formatDisplayedDate(value) || ''}</TableCellText>
            ),
          };
        },
      },
      {
        id: 'name',
        dataId: 'name' as keyof APIProject,
        label: t('customers.projects.table.nameColumnLabel'),
        width: '25%',
        formatter: (value: APIProject['name'], item: APIProject) => ({
          value: value || '',
          element: (
            <Stack direction="row">
              <TableCellText mr="1rem">
                {getHighlightedKeywordContent(value, searchKeyword) || ''}
              </TableCellText>
              {item.projectStatus === APIProjectStatus.Archived && (
                <Tag
                  ml="auto"
                  value={t('project.archivation.archivedStatus')}
                />
              )}
            </Stack>
          ),
        }),
      },
      {
        id: 'address',
        dataId: 'address' as keyof APIProject,
        sortable: false,
        label: t('customers.projects.table.addressColumnLabel'),
        formatter: (value: APIProject['address']) => ({
          value: value || '',
          element: (
            <TableCellText>
              {getHighlightedKeywordContent(value, searchKeyword) || ''}
            </TableCellText>
          ),
        }),
      },
      {
        id: 'action',
        width: '7.2rem',
        formatter: (value: string, item: APIProject) => ({
          value: '',
          element: (
            <span>
              {item.inUse ? (
                <ArchiveProjectButton project={item} />
              ) : (
                <DeleteProjectButton project={item} />
              )}
            </span>
          ),
        }),
      },
    ];
  }, [ArchiveProjectButton, DeleteProjectButton, searchKeyword, t]);

  const theme = useTheme();
  const rowHighlightColor = useCallback(
    (item: APIProject) => {
      if (item.projectStatus === APIProjectStatus.Archived) {
        return theme.custom.palette.muted50;
      }
    },
    [theme],
  );

  useTrackDeleteCompanyAvailability(
    customerId,
    customerProjectsLoaded ? customerProjects.length : undefined,
  );

  return (
    <Wrapper direction="column">
      <ArchiveProjectConfirmationDialog onSuccess={refetchProjects} />
      <DeleteProjectConfirmationDialog onSuccess={refetchProjects} />
      <HeaderWrapper direction="row">
        <DispatcherCanAccess>
          <Header variant="h5">{t('customers.projects.header')}</Header>
        </DispatcherCanAccess>
        <CanAccess allowedUserType={APIUserType.Contractor}>
          <Header variant="h4">{t('customers.projects.header')}</Header>
        </CanAccess>
        <StyledSearch
          // reset search when changing provider
          key={providerId}
          isClearable
          placeholder={t('ordersList.filters.projectSelectPlaceholder')}
          onChange={onProjectSearchChange}
        />
        <Button
          onClick={() => {
            updateProjectsFilter(!showArchivedProjects);
            loadCustomerProjects(customerId);
          }}
        >
          {showArchivedProjects ? (
            <>
              <VisibilityOutlinedIcon sx={{ marginRight: '1rem' }} />
              {t('project.archivation.hideArchived')}
            </>
          ) : (
            <>
              <VisibilityOffOutlinedIcon sx={{ marginRight: '1rem' }} />
              {t('project.archivation.showArchived')}
            </>
          )}
        </Button>
        {!isReadOnly && (
          <AddButton
            onClick={() =>
              navigate(
                userType === APIUserType.Dispatcher
                  ? customerProjectAddUrl(customerId)
                  : contractorAddProjectUrl(),
                {
                  state: {
                    backUrl: location.pathname,
                    backUrlLabel: t('common.back'),
                  },
                },
              )
            }
            label={t('customers.projects.addButtonText')}
          />
        )}
      </HeaderWrapper>
      {!customerProjectsLoaded && (
        <LoadingContainer>
          <CircularProgress sx={{ margin: '5rem' }} />
        </LoadingContainer>
      )}
      {customerProjectsLoaded && customerProjectsCount > 0 && (
        <Table<APIProject>
          infiniteScroll
          tableMinHeight="20rem"
          components={tableComponents}
          columns={columns}
          rowHighlightColor={rowHighlightColor}
          items={customerProjects}
          itemsTotalCount={customerProjectsCount}
          itemsLoaded={customerProjectsLoaded}
          loadItemsPending={loadCustomerProjectsPending}
          loadMoreItemsPending={loadMoreCustomerProjectsPending}
          onLoadMoreClicked={onLoadMoreClicked}
          onRowClick={onProjectClick}
          sortField={sortField}
          sortOrder={sortOrder}
          onColumnHeaderClicked={onColumnHeaderClicked}
          loadMoreButtonText={t('customers.projects.table.loadMoreButtonText')}
        />
      )}
      {customerProjectsLoaded && customerProjectsCount === 0 && (
        <Stack direction="column" alignItems="flex-start">
          <EmptyState>
            {!searchKeyword
              ? t('customers.projects.table.emptyState')
              : t('customers.projects.table.noProjects')}
          </EmptyState>
        </Stack>
      )}
    </Wrapper>
  );
};
