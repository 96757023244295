import React, { SyntheticEvent, useState } from 'react';

import { useTheme } from 'styled-components';

import {
  Checkbox,
  FormControl,
  FormHelperText,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Typography,
} from 'components';

import {
  Control,
  Controller,
  FieldError,
  FieldPath,
  _,
  styled,
  useTranslation,
} from 'third-party';

import { getLabelColor } from 'utils/order';

import { IOptions } from 'types/app';

const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const StyledFormControl = styled(FormControl)`
  width: 100%;
`;

export const StyledFormFieldTypography = styled(Typography)`
  display: inline;
  min-height: 2.1rem;
`;

const NoOptions = styled.div`
  padding: 0.5rem 1rem;
  font-size: 1.6rem;
`;

export const StyledFormHelperText = styled(FormHelperText)`
  margin: 0 0 0 auto;
  font-size: 1.4rem;
  line-height: 2.1rem;
  font-weight: 400;
  color: ${props => props.theme.custom.palette.error500};
`;

export const StyledFormFieldLabel = styled(Stack)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  margin-bottom: 0.8rem;
`;

type IProps<TFormValues extends Record<string, any>> = {
  caption: string;
  options: IOptions[];
  isRequired?: boolean;
  requiredErrorMessage?: string;
  rules?: any;
  control: Control<TFormValues>;
  fieldName: FieldPath<TFormValues>;
  isDisabled?: boolean;
  placeholder?: string;
  defaultSelected?: (string | number)[];
  showError?: boolean;
  fieldError?: FieldError;
  onClose?: (event: SyntheticEvent<Element, Event>) => void;
};

export const MultiSelectDropdown = <TFormValues extends Record<string, any>>({
  caption,
  options,
  isRequired = false,
  requiredErrorMessage,
  rules,
  control,
  fieldName,
  isDisabled,
  defaultSelected,
  placeholder,
  showError = false,
  fieldError,
  onClose,
}: IProps<TFormValues>) => {
  const theme = useTheme();
  const [selected, setSelected] = useState<string | number[]>(
    (defaultSelected as string | number[]) || [],
  );
  const isAllSelected =
    options.length > 0 && selected.length === options.length;
  const hasOptions = options?.length > 0;

  const titleColor = getLabelColor(showError && !!fieldError?.message, theme);

  const { t } = useTranslation();

  const handleChange = (event: any, callback: any) => {
    const value = event.target.value.filter(
      (item: Record<string, any> | string | number) => !_.isObject(item),
    );
    // value holds the following: [], [1], [{Option}, 1, 2, 3, 'all'], ['all]
    // when all items are selected, 'all' item is always at the end of the array
    const areAllItemsSelected = value[value.length - 1] === 'all';
    const selectedValue = areAllItemsSelected
      ? selected.length === options.length
        ? []
        : options.map(it => it.key)
      : value;
    setSelected(selectedValue);
    callback(selectedValue);
    return;
  };

  return (
    <StyledFormControl>
      <StyledFormFieldLabel>
        <StyledFormFieldTypography variant="subtitle2" color={titleColor}>
          {caption}
          {isRequired ? '*' : ''}
        </StyledFormFieldTypography>
        {showError && fieldError?.message && (
          <StyledFormHelperText>{fieldError?.message}</StyledFormHelperText>
        )}
      </StyledFormFieldLabel>

      <Controller
        rules={{
          required: {
            value: isRequired,
            message: requiredErrorMessage || '',
          },
          ...rules,
          validate: {
            ...(isRequired
              ? {
                  errorOnSpaces: (value: string) =>
                    !!_.trim(value) || requiredErrorMessage,
                }
              : {}),
            ...(rules?.validate ? rules.validate : {}),
          },
        }}
        control={control}
        name={fieldName}
        render={props => (
          <Select
            sx={{
              '& .MuiSelect-select .notranslate::after': placeholder
                ? {
                    content: `"${placeholder}"`,
                    color: theme.custom.palette.gray,
                  }
                : {},
            }}
            labelId="multiple-select-label"
            multiple
            error={showError && !!fieldError?.message}
            style={{ height: '4rem' }}
            disabled={isDisabled || !hasOptions}
            value={props.field.value}
            onChange={selection =>
              handleChange(selection, props.field.onChange)
            }
            onClose={onClose}
            renderValue={its =>
              options
                .filter(it => its.indexOf(it.key) > -1)
                .map(it => it.value)
                .join(', ')
            }
            MenuProps={MenuProps}
          >
            {hasOptions ? (
              <MenuItem key="all" value="all" style={{ padding: 0 }}>
                <ListItemIcon>
                  <Checkbox
                    checked={isAllSelected}
                    indeterminate={
                      selected.length > 0 && selected.length < options.length
                    }
                  />
                </ListItemIcon>
                <ListItemText primary="Select All" />
              </MenuItem>
            ) : (
              <NoOptions>{t('common.selectNoOptions')}</NoOptions>
            )}
            {options.map(option => {
              return (
                <MenuItem
                  key={option.key}
                  value={option.key}
                  style={{ padding: 0 }}
                >
                  <ListItemIcon>
                    <Checkbox
                      checked={
                        (selected as [string | number]).indexOf(option.key) > -1
                      }
                    />
                  </ListItemIcon>
                  <ListItemText primary={option.value} />
                </MenuItem>
              );
            })}
          </Select>
        )}
      />
    </StyledFormControl>
  );
};
