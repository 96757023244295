import React, { useCallback, useEffect, useMemo } from 'react';

import {
  ON_ORDER_CREATE_EVENT,
  ON_ORDER_UPDATE_EVENT,
} from '@cd3p/core/constants/hubs';
import { APIOrder } from '@cd3p/core/types/api';
import { AddButton, LoadingButton, Stack, Typography } from 'components';
import { subscribe } from 'hooks/usePubSub';

import {
  styled,
  useLocation,
  useNavigate,
  useParams,
  useSelector,
  useTranslation,
} from 'third-party';

import { APP_EVENTS } from 'constants/appEvent';
import {
  OrdersTableColumnIds,
  dispatcherAllOrdersHiddenColumns,
} from 'constants/common';
import { createProjectOrderUrl } from 'constants/url';

import { useOrder } from 'modules/order';
import { useOrdersCache } from 'modules/ordersCache';
import {
  projectOrdersListState,
  useProjectOrdersList,
} from 'modules/projectOrdersList';

import { appSelectors, projectOrdersListSelectors } from 'selectors';

import { ordersTableSectionOptions } from 'components/Table/Table';

import { OrdersTable } from 'features/OrdersTable/OrdersTable';

import { composeLoadOrdersRequestBody } from 'utils/ordersList';
import { useOrdersSockets } from 'utils/sockets';

import { OutlinedSearchInput } from 'styles/common';

const TableLoadMoreButton = styled(LoadingButton)`
  margin-top: 2rem;
  height: 5.2rem;
`;

const ordersTableComponents = {
  LoadMoreButton: TableLoadMoreButton,
};

const projectOrdersColumnProps = [
  {
    id: OrdersTableColumnIds.Company,
    hidden: true,
  },
  {
    id: OrdersTableColumnIds.DeliveryTime,
    hidden: true,
  },
  {
    id: OrdersTableColumnIds.OrderNumber,
    hidden: true,
  },
];

const Title = styled(Typography)`
  line-height: 100%;
  color: ${props => props.theme.custom.palette.secondary500};
  margin-right: 1.6rem;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: flex-start;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: flex-start;
  padding-top: 2rem;
`;

const OrdersWrapperCount = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
`;

const StyledOutlinedSearchInput = styled(OutlinedSearchInput)`
  width: 40rem;
  margin-right: 1rem;
`;

export const ProjectOrdersTab = () => {
  const location = useLocation();
  const { projectId, customerId } = useParams();
  const { updateOrder } = useOrder();
  const { updateOrderInCache } = useOrdersCache();
  const {
    loadProjectOrders,
    updateProjectOrdersListSorting,
    loadMoreProjectOrders,
    updateOrderPositionInProjectOrders,
    resetProjectOrdersList,
    updateProjectOrdersListFilters,
    updateProjectOrdersSearch,
    resetProjectOrdersSearch,
  } = useProjectOrdersList();

  // reset order list when leave the view
  useEffect(() => {
    return () => {
      resetProjectOrdersList();
    };
  }, [resetProjectOrdersList]);

  const providerId = useSelector(appSelectors.providerId);
  const ordersListItems = useSelector(
    projectOrdersListSelectors.projectOrdersListItems,
  );
  const loadOrdersPending = useSelector(
    projectOrdersListSelectors.loadProjectOrdersPending,
  );
  const ordersLoaded = useSelector(
    projectOrdersListSelectors.projectOrdersLoaded,
  );
  const ordersListCount = useSelector(
    projectOrdersListSelectors.projectOrdersListCount,
  );
  const loadMoreOrdersPending = useSelector(
    projectOrdersListSelectors.loadMoreProjectOrdersPending,
  );
  const ordersListSorting = useSelector(
    projectOrdersListSelectors.projectOrdersListSorting,
  );
  const ordersListSearch = useSelector(
    projectOrdersListSelectors.projectOrdersListSearch,
  );
  const ordersListSearchPattern = useSelector(
    projectOrdersListSelectors.projectOrdersListSearchPattern,
  );
  const isOrdersListFiltersApplied = useSelector(
    projectOrdersListSelectors.isProjectOrdersListFiltersApplied,
  );
  const { sortField, sortOrder } = ordersListSorting[0];
  const { pageNumber, pageSize } = useSelector(
    projectOrdersListSelectors.projectOrdersListPagination,
  );

  const { t } = useTranslation();
  const navigate = useNavigate();

  const filters = useMemo(
    () => ({
      ...projectOrdersListState.filters,
      project: [{ value: projectId ?? '', label: '' }],
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectId],
  );

  useOrdersSockets(
    () => [
      {
        method: ON_ORDER_UPDATE_EVENT,
        action: (providerId: string, orderId: number) => {
          updateOrderPositionInProjectOrders(orderId);
        },
      },
      {
        method: ON_ORDER_CREATE_EVENT,
        action: (providerId: string, orderId: number) => {
          updateOrderPositionInProjectOrders(orderId);
        },
      },
    ],
    [updateOrderPositionInProjectOrders],
  );

  useEffect(() => {
    updateProjectOrdersListFilters(filters);
  }, [filters, updateProjectOrdersListFilters]);

  useEffect(() => {
    return subscribe(
      APP_EVENTS.UPDATE_ORDER_IN_LIST,
      (orderDetails: Partial<APIOrder> & { id: number }) => {
        updateOrderInCache(orderDetails.id, orderDetails);
      },
    );
  }, [updateOrderInCache]);

  useEffect(() => {
    return subscribe(APP_EVENTS.CHANGE_PROVIDER, resetProjectOrdersSearch);
  }, [resetProjectOrdersSearch]);

  const orderUrl = useCallback((orderId: number) => {
    return orderId.toString();
  }, []);

  const onOrdersSearch = useCallback(
    (value: string) => {
      updateProjectOrdersSearch(value.trim());
    },
    [updateProjectOrdersSearch],
  );

  useEffect(() => {
    loadProjectOrders(
      composeLoadOrdersRequestBody(providerId, {
        pageNumber,
        pageSize,
        sorting: ordersListSorting,
        filters,
        search: ordersListSearch,
      }),
    );
  }, [
    providerId,
    loadProjectOrders,
    pageNumber,
    pageSize,
    ordersListSorting,
    filters,
    ordersListSearch,
  ]);

  return (
    <>
      <HeaderWrapper>
        <OrdersWrapperCount>
          <Title variant="h5"> {t('customers.project.orders.title')}</Title>
        </OrdersWrapperCount>
        <Stack direction="row" alignItems="center">
          <StyledOutlinedSearchInput
            // reset search when changing provider
            key={providerId}
            isClearable
            defaultValue={ordersListSearchPattern}
            onChange={onOrdersSearch}
            placeholder={t('ordersList.searchInputPlaceholder')}
          />
          <AddButton
            onClick={() =>
              navigate(
                createProjectOrderUrl(projectId ?? '', customerId ?? ''),
                {
                  state: {
                    backUrl: location.pathname,
                    backUrlLabel: t('projectDetails.orders.backButtonLabel'),
                  },
                },
              )
            }
            label={t('common.newOrder')}
          />
        </Stack>
      </HeaderWrapper>
      <OrdersTable
        viewLabel={t('projectDetails.orders.backButtonLabel')}
        columnProps={projectOrdersColumnProps}
        loadMoreOrders={loadMoreProjectOrders}
        updateOrderInList={updateOrder}
        updateOrderPosition={updateOrderPositionInProjectOrders}
        updateSorting={updateProjectOrdersListSorting}
        sortField={sortField}
        sortOrder={sortOrder}
        search={ordersListSearch}
        hiddenColumns={dispatcherAllOrdersHiddenColumns}
        orders={ordersListItems}
        ordersLoaded={ordersLoaded}
        loadItemsPending={loadOrdersPending}
        emptyText={
          ordersListSearch
            ? t('ordersList.table.searchEmptyStateText')
            : t('ordersList.table.emptyStateText')
        }
        loadMoreItemsPending={loadMoreOrdersPending}
        ordersCount={ordersListCount}
        isFiltersApplied={isOrdersListFiltersApplied}
        components={ordersTableComponents}
        orderItemUrl={orderUrl}
        sectionOptions={ordersTableSectionOptions}
      />
    </>
  );
};
