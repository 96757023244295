import React, {
  ForwardedRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';

import styled from 'styled-components';

import { APIDialogModel, APIUserType } from '@cd3p/core/types/api';
import { subscribeActionsBefore } from '@cd3p/core/utils/redux';
import { Stack, Typography } from 'components';
import { motion } from 'framer-motion';
import { IndexRange } from 'react-virtualized';
import { ListRowRenderer } from 'react-virtualized/dist/es/List';

import {
  CONTRACTOR_DIALOG_ITEM_HEIGHT,
  DIALOG_ITEM_HEIGHT,
} from './DialogsPanel';

import {
  VirtualizedAutoSizer,
  VirtualizedInfiniteLoader,
  VirtualizedList,
  _,
  useMatch,
  useParams,
  useSelector,
  useTranslation,
} from 'third-party';

import { orderUrl } from 'constants/url';

import { ADD_TO_DIALOGS_LIST } from 'modules/dialogsList';

import { appSelectors, dialogsListSelectors } from 'selectors';

import {
  DialogsItem,
  DialogsItemLoader,
} from 'features/DialogsPanel/DialogsItem';

const ListWrapper = styled(Stack)`
  flex-grow: 1;
`;

const EmptyState = styled(Typography)`
  margin-top: 3rem;
  align-self: center;
  font-size: 1.6rem;
  font-weight: 600;
  color: ${props => props.theme.custom.palette.muted800};
`;

const StyledVirtualizedList = styled(VirtualizedList)`
  flex-grow: 1;
`;

const ANIMATE_N_NEXT_ITEMS = 10;
const LIST_ITEM_ANIMATION_DURATION = 1;
const listItemAnimationVariants = {
  initial: {
    opacity: 0,
    scale: 0.9,
  },
  appear: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: LIST_ITEM_ANIMATION_DURATION,
    },
  },
};

type DialogsListProps = {
  dialogsList: APIDialogModel[];
  dialogsListCount: number;
  loadDialogsPending: boolean;
  dialogsLoaded: boolean;
  loadMore: (params: IndexRange) => Promise<void>;
};

export const DialogsList = React.forwardRef(
  (
    {
      dialogsList,
      loadMore,
      loadDialogsPending,
      dialogsLoaded,
      dialogsListCount,
    }: DialogsListProps,
    listForwardedRef: ForwardedRef<VirtualizedList | null>,
  ) => {
    const { t } = useTranslation();

    const { id } = useParams();
    const matchOrderUrl = useMatch(orderUrl('*'));

    const userType = useSelector(appSelectors.userType);
    const rowHeight =
      userType === APIUserType.Contractor
        ? CONTRACTOR_DIALOG_ITEM_HEIGHT
        : DIALOG_ITEM_HEIGHT;

    const dialogsSearchKeyword = useSelector(
      dialogsListSelectors.searchKeyword,
    );

    const isRowLoaded = useCallback(
      ({ index }: { index: number }) => {
        return !!dialogsList?.[index];
      },
      [dialogsList],
    );

    const listRef = useRef<VirtualizedList | null>(null);

    useImperativeHandle(
      listForwardedRef,
      () => listRef.current as VirtualizedList,
    );

    const [newItemsIds, setNewItemsIds] = useState<Record<string, boolean>>({});

    useEffect(() => {
      return subscribeActionsBefore([ADD_TO_DIALOGS_LIST], ({ payload }) => {
        setNewItemsIds(state => ({ ...state, [payload?.[0]?.id]: true }));
        setTimeout(() => {
          setNewItemsIds(state => {
            const newState = { ...state };
            delete newState[payload?.[0]?.id];
            return newState;
          });
        }, LIST_ITEM_ANIMATION_DURATION * 1000);
      });
    }, []);

    const itemIndexesToAnimate = useMemo(() => {
      // this optimization prevents animation side effects in list
      // when a bunch of items far from newly added one slides up
      // to the top. with `itemIndexesToAnimate` we animate only N
      // siblings after the new item
      return Object.keys<string>(newItemsIds).reduce<number[]>((result, id) => {
        return [
          ...result,
          ..._.range(
            _.findIndex(dialogsList, it => String(it.orderId) === id),
            ANIMATE_N_NEXT_ITEMS,
          ),
        ];
      }, []);
    }, [newItemsIds, dialogsList]);

    const renderItem = useCallback<ListRowRenderer>(
      ({ index, key, style }) => {
        const item = dialogsList?.[index];
        return !loadDialogsPending && item ? (
          <motion.div
            variants={listItemAnimationVariants}
            layout
            layoutId={`${item.orderId}`}
            transition={{
              layout: {
                duration: itemIndexesToAnimate.includes(index) ? 0.5 : 0,
              },
            }}
            {...(newItemsIds[`${item.orderId}`] && {
              initial: 'initial',
              animate: 'appear',
            })}
            key={item.orderId}
            style={style}
          >
            <DialogsItem
              item={item}
              rowHeight={rowHeight}
              isActive={!!matchOrderUrl && Number(id) === item.orderId}
            />
          </motion.div>
        ) : (
          <div key={key} style={style}>
            <DialogsItemLoader key={key} />
          </div>
        );
      },
      [
        dialogsList,
        loadDialogsPending,
        itemIndexesToAnimate,
        newItemsIds,
        rowHeight,
        matchOrderUrl,
        id,
      ],
    );

    return (
      <ListWrapper>
        {dialogsLoaded && dialogsList.length === 0 ? (
          <EmptyState variant="h6">
            {dialogsSearchKeyword
              ? t('dialogsPanel.noSearchResult')
              : t('dialogsPanel.noMessages')}
          </EmptyState>
        ) : (
          <VirtualizedInfiniteLoader
            isRowLoaded={isRowLoaded}
            loadMoreRows={loadMore}
            rowCount={dialogsListCount}
          >
            {({ onRowsRendered, registerChild }) => (
              <VirtualizedAutoSizer>
                {({ width, height }) => (
                  <StyledVirtualizedList
                    ref={(ref: VirtualizedList) => {
                      registerChild(ref);
                      listRef.current = ref;
                    }}
                    onRowsRendered={onRowsRendered}
                    height={height}
                    rowCount={dialogsListCount}
                    rowHeight={rowHeight}
                    rowRenderer={renderItem}
                    width={width}
                  />
                )}
              </VirtualizedAutoSizer>
            )}
          </VirtualizedInfiniteLoader>
        )}
      </ListWrapper>
    );
  },
);
