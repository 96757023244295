import { APIAvailableMixTypeModel } from '@cd3p/core/types/api';
import { State } from 'modules';

import { createSelector } from 'third-party';

import { normalizeCategories } from 'modules/concreteCategories/commonConcreteCategories';

export const allMixTypesCategoriesListState = (state: State) =>
  state.mixTypesCategoriesList;
export const mixTypesCategoriesListItems = (state: State) =>
  state.mixTypesCategoriesList.items;
export const mixTypesCategoriesListNormalized = createSelector(
  [mixTypesCategoriesListItems],
  items => normalizeCategories<APIAvailableMixTypeModel>(items),
);
export const mixTypesCategoriesListCount = (state: State) =>
  state.mixTypesCategoriesList.count;
export const canLoadMoreMixTypes = (state: State) =>
  state.mixTypesCategoriesList.items.length <
  state.mixTypesCategoriesList.count;
export const mixTypesCategoriesLoaded = (state: State) =>
  state.mixTypesCategoriesList.itemsLoaded;
export const mixTypesCategoriesPending = (state: State) =>
  state.mixTypesCategoriesList.loadMixTypesCategoriesPending ||
  state.mixTypesCategoriesList.loadMoreMixTypesCategoriesPending;
export const mixTypesCategoriesListSorting = (state: State) =>
  state.mixTypesCategoriesList.searchSortOrders;
export const mixTypesCategoriesListPagination = (state: State) =>
  state.mixTypesCategoriesList.pagination;
