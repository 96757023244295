import { State } from 'modules';

export const contractorAdditiveTypesItems = (state: State) =>
  state.contractorAdditiveTypes.items;
export const contractorAdditiveTypesCount = (state: State) =>
  state.contractorAdditiveTypes.count;
export const contractorAdditiveTypesLoaded = (state: State) =>
  state.contractorAdditiveTypes.additiveTypesLoaded;
export const contractorAdditiveTypesSorting = (state: State) =>
  state.contractorAdditiveTypes.sorting;
export const loadContractorAdditiveTypesPending = (state: State) =>
  state.contractorAdditiveTypes.loadContractorAdditiveTypesPending;
export const loadMoreContractorAdditiveTypesPending = (state: State) =>
  state.contractorAdditiveTypes.loadMoreContractorAdditiveTypesPending;
export const loadProjectAdditiveTypesPending = (state: State) =>
  state.contractorAdditiveTypes.loadProjectAdditiveTypesPending;
export const loadMoreProjectAdditiveTypesPending = (state: State) =>
  state.contractorAdditiveTypes.loadMoreProjectAdditiveTypesPending;
