import { orders as ordersCacheSelector } from '@cd3p/core/selectors/ordersCache';
import { APITicket, APITrackingItem } from '@cd3p/core/types/api';
import { State } from 'modules';
import { ticketsMapper } from 'selectors/common/tickets';

import * as trackingSelectors from './tracking';

import { createSelector } from 'third-party';

export const orderId = (state: State) => state.order.orderId;

export const order = (orderId: number) =>
  createSelector([ordersCacheSelector], orderCache => {
    return orderCache.find(order => order.id === orderId);
  });

export const updateOrderPending = (state: State) =>
  state.order.updateOrderPending;
export const updateOrderCallbackPending = (state: State) =>
  state.order.updateOrderCallbackPending;
export const updateOrderBatchNumberPending = (state: State) =>
  state.order.updateOrderBatchNumberPending;
export const updateOrderRequestedChangesPending = (state: State) =>
  state.order.updateOrderRequestedChangesPending;
export const createOrderPending = (state: State) =>
  state.order.createOrderPending;
export const fetchOrderByIdPending = (state: State) =>
  state.order.fetchOrderByIdPending;
export const orderLoaded = (state: State) => state.order.orderLoaded;
export const sendOrderNotificationPending = (state: State) =>
  state.order.sendOrderNotificationPending;
export const addTicketPending = (state: State) => state.order.addTicketPending;
export const updateTicketPending = (state: State) =>
  state.order.updateTicketPending;
export const orderPlants = (state: State) => state.order.orderPlants;
export const orderPlantsLoaded = (state: State) =>
  state.order.orderPlantsLoaded;
export const addOrderSubscriptionsPending = (state: State) =>
  state.order.addOrderSubscriptionsPending;

export const orderTicketsItems = (state: State) => state.order.tickets.items;

export type OrderTicket = APITicket & Pick<APITrackingItem, 'timeOfArrival'>;

export const orderTickets = createSelector(
  [orderTicketsItems, trackingSelectors.trackingItems],
  ticketsMapper,
);

export const orderTicketsLoaded = (state: State) => state.order.tickets.loaded;
export const orderTicketsTotalCount = (state: State) =>
  state.order.tickets.count;
export const orderTicketsSorting = (state: State) =>
  state.order.tickets.sorting;
export const orderTicketsFilters = (state: State) =>
  state.order.tickets.filters;
export const orderTicketsPagination = (state: State) =>
  state.order.tickets.pagination;
export const loadOrderTicketsPending = (state: State) =>
  state.order.loadOrderTicketsPending;
export const loadMoreOrderTicketsPending = (state: State) =>
  state.order.loadMoreOrderTicketsPending;
export const loadTicketPending = (state: State) =>
  state.order.loadTicketPending;
export const removeTicketPending = (state: State) =>
  state.order.removeTicketPending;

export { changeOrderStatusPending } from '@cd3p/core/selectors/order';
