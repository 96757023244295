import React, { useCallback, useEffect, useMemo, useState } from 'react';

import styled from 'styled-components';

import {
  APIProvider,
  APIProviderSettings,
  APIUser,
} from '@cd3p/core/types/api';
import { OpenInNewOutlined } from '@mui/icons-material';
import {
  Button,
  CircularProgress,
  Stack,
  Tab,
  Tabs,
  TabsBottomLine,
  Typography,
} from 'components';

import {
  InitiateAccountFormT,
  InitiateAccountPopup,
} from './InitiateAccountPopup';

import {
  Navigate,
  useMatch,
  useNavigate,
  useParams,
  useSelector,
  useTranslation,
} from 'third-party';

import {
  COMPANY_SETTINGS_PATH,
  contractorsUrl,
  customerAccountSettingsUrl,
  openInstanceUrl,
} from 'constants/url';

import { adminCustomerAccountsList } from 'selectors';

import { CompanySettingsTab } from 'features/PlantSettings/CompanySettingsTab/CompanySettingsTab';

import { TabTitle, TabWrapper, TabsWrapper } from 'styles/theme';

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 1rem 1.2rem 1.5rem;
  flex-direction: row;
`;

const StyledTab = styled(Tab)`
  flex-grow: initial;
  padding: 0 5rem;
`;

const Title = styled(Typography)`
  font-size: 2rem;
  font-weight: 900;
  text-transform: uppercase;
  color: ${props => props.theme.custom.palette.secondary900};
`;

const LoadingContainer = styled(Stack)`
  height: 100%;
  align-items: center;
  justify-content: center;
`;

const StyledOpenInNewIcon = styled(OpenInNewOutlined)`
  font-size: 1.6rem;
  padding-right: 0.8rem;
`;

const StyledButton = styled(Button)`
  font-size: 1.2rem;
  font-weight: 700;
`;

enum CustomerAccountDetailsTabs {
  CompanySettings,
}

export const CustomerAccountDetails = () => {
  const { contractorId, tabId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showInitiateAccountPopup, setShowInitiateAccountPopup] =
    useState(false);
  const [customerAccountAdminEmail, setCustomerAccountAdminEmail] = useState<
    null | string | undefined
  >(undefined);
  const [isProviderSettingsLoading, setIsProviderSettingsLoading] =
    useState(true);

  const customerAccountsListItems = useSelector(
    adminCustomerAccountsList.customerAccountsListItems,
  );

  const selectedCustomerAccount = useMemo(
    () => customerAccountsListItems.find(item => item.id === contractorId),
    [contractorId, customerAccountsListItems],
  );

  const [customerDetails, setCustomerDetails] = useState<null | APIProvider>(
    null,
  );

  const matchIndexUrl = useMatch(contractorsUrl(contractorId));
  const currentTab = useMemo(() => {
    switch (tabId) {
      case COMPANY_SETTINGS_PATH:
        return CustomerAccountDetailsTabs.CompanySettings;
      default:
        return null;
    }
  }, [tabId]);

  const tabs = useMemo(
    () => [
      {
        title: t('settings.companySettingsTab'),
        onClick: () =>
          navigate(customerAccountSettingsUrl(contractorId), {
            replace: true,
          }),
      },
    ],
    [t, navigate, contractorId],
  );

  const onOpenInstanceClick = useCallback(() => {
    if (contractorId) {
      window.open(
        openInstanceUrl(contractorId),
        '_blank',
        'noopener,noreferrer',
      );
    }
  }, [contractorId]);

  const onCustomerSettingsLoaded = useCallback(
    (settings: APIProviderSettings[]) => {
      setIsProviderSettingsLoading(false);
      const providerAdminEmail = settings.find(
        item => item?.key === 'ProviderAdminEmail',
      );
      setCustomerAccountAdminEmail(providerAdminEmail?.value || null);
    },
    [],
  );

  const handleInitiateAccountClick = useCallback(() => {
    setShowInitiateAccountPopup(true);
  }, []);

  const handleInitiateAccountSuccess = useCallback(
    (user: APIUser, formValues: InitiateAccountFormT) => {
      if (contractorId) {
        setCustomerAccountAdminEmail(formValues.email?.value);
      }
    },
    [contractorId],
  );

  const onContractorLoaded = useCallback((data: APIProvider) => {
    setCustomerDetails(data);
  }, []);

  useEffect(() => {
    if (contractorId) {
      setIsProviderSettingsLoading(true);
    }
  }, [contractorId]);

  if (!contractorId) {
    return (
      <LoadingContainer>
        <CircularProgress sx={{ margin: '5rem' }} />
      </LoadingContainer>
    );
  }

  return (
    <>
      <HeaderWrapper>
        {showInitiateAccountPopup && (
          <InitiateAccountPopup
            onClosePopup={() => setShowInitiateAccountPopup(false)}
            onSuccess={handleInitiateAccountSuccess}
            customerId={contractorId}
          />
        )}
        <Stack direction="row" alignItems="center">
          <Title variant="h5">
            {(selectedCustomerAccount || customerDetails)?.providerName}
          </Title>
        </Stack>
        <Stack direction="row" gap="1rem">
          <StyledButton
            size="small"
            variant="outlined"
            color="secondary"
            onClick={onOpenInstanceClick}
          >
            <StyledOpenInNewIcon fontSize="small" />
            {t('customers.support.openInstance')}
          </StyledButton>
          <StyledButton
            variant="contained"
            color="primary"
            disabled={isProviderSettingsLoading || !!customerAccountAdminEmail}
            onClick={handleInitiateAccountClick}
          >
            {t('customers.support.initiateAccount')}
          </StyledButton>
        </Stack>
      </HeaderWrapper>
      <TabsWrapper>
        <Tabs value={currentTab}>
          {tabs.map(tab => {
            return (
              <StyledTab
                key={tab.title}
                label={
                  <TabWrapper>
                    <TabTitle>{tab.title}</TabTitle>
                  </TabWrapper>
                }
                onClick={tab.onClick}
              />
            );
          })}
          <TabsBottomLine />
        </Tabs>
        {contractorId &&
          currentTab === CustomerAccountDetailsTabs.CompanySettings && (
            <CompanySettingsTab
              key={contractorId}
              adminEditRights
              contractorId={contractorId}
              onContractorLoaded={onContractorLoaded}
              onProviderSettingsLoaded={onCustomerSettingsLoaded}
            />
          )}
        {matchIndexUrl && (
          <Navigate to={customerAccountSettingsUrl(contractorId)} replace />
        )}
      </TabsWrapper>
    </>
  );
};
