import React from 'react';

import styled from 'styled-components';

import { APIOrderStatus } from '@cd3p/core/types/api';
import { Grid, LoadingButton, Typography } from 'components';
import { useHandleApiResult } from 'hooks/useHandleApiResult';

import { useSelector, useTheme, useTranslation } from 'third-party';

import { useOrder } from 'modules/order';

import { orderSelectors } from 'selectors';

const ApproveButton = styled(LoadingButton)`
  min-width: 20rem;
  margin-top: 2rem;
`;

type Props = {
  orderId: number;
  customerName?: string;
};

export const CancellationSubmittedOrder: React.FC<Props> = ({
  orderId,
  customerName,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { changeOrderStatus } = useOrder();

  const updateOrderPending = useSelector(
    orderSelectors.changeOrderStatusPending,
  );

  const handleApiResult = useHandleApiResult();

  const onCancel = () => {
    if (orderId) {
      handleApiResult(
        () => changeOrderStatus(orderId, APIOrderStatus.Cancelled),
        ({ showBaseToast }) =>
          showBaseToast(
            t('order.details.cancellationSubmitted.successToastMessage'),
          ),
      );
    }
  };

  return (
    <Grid item>
      <Grid item>
        <Typography variant="h5">
          {t('order.details.cancelled.title')}
        </Typography>
        <Typography
          color={theme.custom.palette.gray}
          variant="body1"
          marginTop="0.8rem"
        >
          {t('order.details.cancellationSubmitted.message', { customerName })}
        </Typography>

        <ApproveButton
          color="primary"
          variant="contained"
          size="large"
          onClick={onCancel}
          disabled={updateOrderPending}
          loading={updateOrderPending}
        >
          {t('order.details.cancellationSubmitted.confirmButton')}
        </ApproveButton>
      </Grid>
    </Grid>
  );
};
