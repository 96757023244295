import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import styled from 'styled-components';

import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { APIRequiredActionSortOrderType } from '@cd3p/core/types/api';
import { subscribeActionsBefore } from '@cd3p/core/utils/redux';
import { SelectChangeEvent } from '@mui/material';
import { IconButton, MenuItem, Select, Stack, Typography } from 'components';
import { motion } from 'framer-motion';
import i18n from 'i18n';
import { usePrevious } from 'react-use';
import { InfiniteLoaderProps } from 'react-virtualized/dist/es/InfiniteLoader';
import { ListRowRenderer } from 'react-virtualized/dist/es/List';

import {
  VirtualizedAutoSizer,
  VirtualizedCellMeasurer,
  VirtualizedCellMeasurerCache,
  VirtualizedInfiniteLoader,
  VirtualizedList,
  _,
  useSelector,
  useTranslation,
} from 'third-party';

import {
  ADD_TO_REQUIRED_ACTIONS_LIST,
  useRequiredActionsList,
} from 'modules/requiredActionsList';
import { useStorage } from 'modules/storage';

import { appSelectors, requiredActionsListSelectors } from 'selectors';

import {
  LEFT_OVERLAY_PANEL_WIDTH,
  LeftOverlayPanelContainer,
} from 'components/LeftOverlayPanelContainer/LeftOverlayPanelContainer';

import {
  RequiredActionItem,
  RequiredActionItemLoader,
} from 'features/RequiredActionsPanel/RequiredActionItem';

const InnerContent = styled(Stack)`
  flex-grow: 1;
  min-width: ${LEFT_OVERLAY_PANEL_WIDTH};
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
`;

const Header = styled(Stack)`
  height: 5rem;
  align-items: center;
  padding: 0 1rem 0 2rem;
  border-bottom: 1px solid ${props => props.theme.custom.palette.muted100};
`;

const Title = styled(Typography)`
  flex-grow: 1;
  color: ${props => props.theme.custom.palette.primary900};
`;

const SortingPanel = styled(Stack)`
  height: 3rem;
  border-bottom: 1px solid ${props => props.theme.custom.palette.muted50};
  background: ${props => props.theme.custom.palette.backgroundTheme};
  justify-content: flex-end;
  padding: 0 0.8rem;
`;

const StyledSelect = styled(Select)`
  color: ${props => props.theme.custom.palette.secondary500};
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 900;
  .MuiSelect-select:focus {
    background-color: transparent;
  }
  &:before {
    display: none;
  }
  &:after {
    display: none;
  }
  .MuiSelect-icon {
    margin-right: 0.5rem;
    color: ${props => props.theme.custom.palette.secondary500};
    font-size: 1.8rem;
  }
`;

const ListWrapper = styled(Stack)`
  flex-grow: 1;
`;

const StyledVirtualizedList = styled(VirtualizedList)`
  flex-grow: 1;
`;

const EmptyState = styled(Typography)`
  margin-top: 3rem;
  align-self: center;
  font-size: 1.6rem;
  font-weight: 600;
  color: ${props => props.theme.custom.palette.muted800};
`;

const sortingOptions = [
  {
    value: APIRequiredActionSortOrderType.Oldest,
    label: i18n.t('requiredActionsPanel.sortingLabel.oldest'),
  },
  {
    value: APIRequiredActionSortOrderType.Newest,
    label: i18n.t('requiredActionsPanel.sortingLabel.newest'),
  },
  {
    value: APIRequiredActionSortOrderType.Urgency,
    label: i18n.t('requiredActionsPanel.sortingLabel.urgency'),
  },
];

const ANIMATE_N_NEXT_ITEMS = 10;
const LIST_ITEM_ANIMATION_DURATION = 1;
const listItemAnimationVariants = {
  initial: {
    opacity: 0,
    scale: 0.9,
  },
  appear: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: LIST_ITEM_ANIMATION_DURATION,
    },
  },
};

export const RequiredActionsPanelContent = () => {
  const { t } = useTranslation();

  const { updateUserSettingInStorage } = useStorage();

  const { loadMoreRequiredAction, loadRequiredAction } =
    useRequiredActionsList();

  const requiredActionsListSorting = useSelector(
    requiredActionsListSelectors.requiredActionsListSorting,
  );

  const requiredActionsListItems = useSelector(
    requiredActionsListSelectors.requiredActionsListItems,
  );

  const requiredActionsListPredictedTotalCount = useSelector(
    requiredActionsListSelectors.requiredActionsListPredictedTotalCount,
  );

  const loadMoreRequiredActionPending = useSelector(
    requiredActionsListSelectors.loadMoreRequiredActionPending,
  );

  const loadRequiredActionPending = useSelector(
    requiredActionsListSelectors.loadRequiredActionPending,
  );

  const requiredActionsLoaded = useSelector(
    requiredActionsListSelectors.requiredActionsLoaded,
  );

  const isRowLoaded = useCallback(
    ({ index }: { index: number }) => {
      return !!requiredActionsListItems?.[index];
    },
    [requiredActionsListItems],
  );

  const cellMeasurerCache = useMemo(
    () =>
      new VirtualizedCellMeasurerCache({
        defaultHeight: 100,
        fixedWidth: true,
      }),
    [],
  );

  const listRef = useRef<VirtualizedList | null>(null);

  useEffect(() => {
    // recompute list rows height on changing data
    cellMeasurerCache.clearAll();
    listRef.current && listRef.current.recomputeRowHeights();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requiredActionsListItems]);

  const [newItemsIds, setNewItemsIds] = useState<Record<string, boolean>>({});

  useEffect(() => {
    return subscribeActionsBefore(
      [ADD_TO_REQUIRED_ACTIONS_LIST],
      ({ payload }) => {
        setNewItemsIds(state => ({ ...state, [payload?.[0]?.id]: true }));
        setTimeout(() => {
          setNewItemsIds(state => {
            const newState = { ...state };
            delete newState[payload?.[0]?.id];
            return newState;
          });
        }, LIST_ITEM_ANIMATION_DURATION * 1000);
      },
    );
  }, []);

  const itemIndexesToAnimate = useMemo(() => {
    // this optimization prevents animation side effects in list
    // when a bunch of items far from newly added one slides up
    // to the top. with `itemIndexesToAnimate` we animate only N
    // siblings after the new item
    return Object.keys<string>(newItemsIds).reduce<number[]>((result, id) => {
      return [
        ...result,
        ..._.range(
          _.findIndex(requiredActionsListItems, it => it.id === id),
          ANIMATE_N_NEXT_ITEMS,
        ),
      ];
    }, []);
  }, [newItemsIds, requiredActionsListItems]);

  const renderItem = useCallback<ListRowRenderer>(
    ({ index, key, style, parent }) => {
      const item = requiredActionsListItems?.[index];
      return (
        <VirtualizedCellMeasurer
          key={key}
          cache={cellMeasurerCache}
          columnIndex={0}
          parent={parent}
          rowIndex={index}
        >
          {!loadRequiredActionPending && item ? (
            <motion.div
              variants={listItemAnimationVariants}
              layout
              layoutId={item.id}
              transition={{
                layout: {
                  duration: itemIndexesToAnimate.includes(index) ? 0.5 : 0,
                },
              }}
              {...(newItemsIds[item.id as string] && {
                initial: 'initial',
                animate: 'appear',
              })}
              key={item.id}
              style={style}
            >
              <RequiredActionItem item={item} />
            </motion.div>
          ) : (
            <div key={key} style={style}>
              <RequiredActionItemLoader key={key} />
            </div>
          )}
        </VirtualizedCellMeasurer>
      );
    },
    [
      requiredActionsListItems,
      cellMeasurerCache,
      loadRequiredActionPending,
      itemIndexesToAnimate,
      newItemsIds,
    ],
  );

  const loadMore = useCallback<InfiniteLoaderProps['loadMoreRows']>(
    params => {
      // skip loading if we are already loading items or
      // it is initial loading because we load initial batch in the other place
      // to display counter
      if (loadMoreRequiredActionPending || params.startIndex === 0) {
        return Promise.resolve();
      }
      return loadMoreRequiredAction();
    },
    [loadMoreRequiredActionPending, loadMoreRequiredAction],
  );

  const handleSortingChange = useCallback(
    (event: SelectChangeEvent<unknown>) => {
      listRef.current?.scrollToPosition(0);
      setTimeout(() => {
        updateUserSettingInStorage({
          requiredActionsListSorting: event.target
            .value as APIRequiredActionSortOrderType,
        });
        loadRequiredAction(
          event.target.value as APIRequiredActionSortOrderType,
        );
      }, 0);
    },
    [loadRequiredAction, updateUserSettingInStorage],
  );

  return (
    <InnerContent>
      <Header direction="row">
        <Title variant="h5">{t('requiredActionsPanel.title')}</Title>
        <IconButton
          onClick={() => {
            updateUserSettingInStorage({ openedLeftPanel: null });
          }}
        >
          <CloseIcon />
        </IconButton>
      </Header>
      <SortingPanel direction="row">
        <StyledSelect
          variant="standard"
          value={requiredActionsListSorting}
          IconComponent={KeyboardArrowDownIcon}
          onChange={handleSortingChange}
        >
          {sortingOptions.map(it => (
            <MenuItem value={it.value} key={it.value}>
              {it.label}
            </MenuItem>
          ))}
        </StyledSelect>
      </SortingPanel>
      <ListWrapper>
        {requiredActionsLoaded && requiredActionsListItems.length === 0 ? (
          <EmptyState variant="h6">
            {t('requiredActionsPanel.noActions')}
          </EmptyState>
        ) : (
          <VirtualizedInfiniteLoader
            isRowLoaded={isRowLoaded}
            loadMoreRows={loadMore}
            rowCount={requiredActionsListPredictedTotalCount}
          >
            {({ onRowsRendered, registerChild }) => (
              <VirtualizedAutoSizer>
                {({ width, height }) => (
                  <StyledVirtualizedList
                    ref={(ref: VirtualizedList) => {
                      registerChild(ref);
                      listRef.current = ref;
                    }}
                    onRowsRendered={onRowsRendered}
                    height={height}
                    rowCount={requiredActionsListPredictedTotalCount}
                    rowHeight={cellMeasurerCache.rowHeight}
                    deferredMeasurementCache={cellMeasurerCache}
                    rowRenderer={renderItem}
                    width={width}
                  />
                )}
              </VirtualizedAutoSizer>
            )}
          </VirtualizedInfiniteLoader>
        )}
      </ListWrapper>
    </InnerContent>
  );
};

export const RequiredActionsPanel = () => {
  const isRequiredActionsPanelOpen = useSelector(
    appSelectors.isRequiredActionsPanelOpen,
  );
  const appOpenedLeftPanel = useSelector(appSelectors.appOpenedLeftPanel);
  const appLeftPanelAnimationDuration = useSelector(
    appSelectors.appLeftPanelAnimationDuration,
  );

  const previousAppOpenedLeftPanel = usePrevious(appOpenedLeftPanel);

  return (
    <LeftOverlayPanelContainer
      isPanelOpen={isRequiredActionsPanelOpen}
      animationDuration={
        !!previousAppOpenedLeftPanel && !!appOpenedLeftPanel
          ? 0
          : appLeftPanelAnimationDuration
      }
    >
      <RequiredActionsPanelContent />
    </LeftOverlayPanelContainer>
  );
};
