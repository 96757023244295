import { useMemo } from 'react';

import {
  coreUsersListMethods,
  coreUsersListState,
} from '@cd3p/core/modules/usersList';
import { APIUser } from '@cd3p/core/types/api';

import {
  bindActionCreators,
  simplifyBuilder,
  useDispatch,
  useSelector,
} from 'third-party';

import { appSelectors } from 'selectors';

export type UsersListColumnId = keyof APIUser;

const builder = simplifyBuilder(coreUsersListState, {});

export const loadUsersList = builder.createServerAction(
  coreUsersListMethods.loadUsersList,
);

export const loadMoreUsersList = builder.createServerAction(
  coreUsersListMethods.loadMoreUsersList,
);

const updateUsersList = builder.createServerAction(
  coreUsersListMethods.updateUsersList,
);

const deleteUser = builder.createServerAction(coreUsersListMethods.deleteUser);

const resendUserInvite = builder.createServerAction(
  coreUsersListMethods.resendUserInvite,
);

const updateUsersListSorting = builder.createReduxAction(
  coreUsersListMethods.updateUsersListSorting,
);

const resetUsersList = builder.createReduxAction(
  coreUsersListMethods.resetUsersList,
);

export const useUsersList = () => {
  const dispatch = useDispatch();
  const providerId = useSelector(appSelectors.providerId);

  return useMemo(
    () =>
      bindActionCreators(
        {
          loadUsersList: loadUsersList.bind(null, providerId),
          loadMoreUsersList: loadMoreUsersList.bind(null, providerId),
          updateUsersList: updateUsersList.bind(null, providerId),
          deleteUser: deleteUser.bind(null, providerId),
          resendUserInvite: resendUserInvite.bind(null, providerId),
          updateUsersListSorting: updateUsersListSorting.bind(null),
          resetUsersList: resetUsersList.bind(null),
        },
        dispatch,
      ),
    [dispatch, providerId],
  );
};

export const usersListReducer = builder.getReducers();
