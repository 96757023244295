import React from 'react';

import styled from 'styled-components';

import { APITruckStatuses } from '@cd3p/core/types/api';
import { Typography } from 'components';

import { truckColorMap } from 'utils/order';

const StyledTruckStatusLabel = styled(Typography)<{ fontSize: string }>`
  font-weight: 700;
  font-size: ${props => props.fontSize};
  line-height: 1.5;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

interface Props {
  value: APITruckStatuses;
  label?: string;
  fontSize?: string;
}

export const TruckStatusLabel: React.FC<Props> = React.forwardRef(
  // suppress warning about unused argument ref because we need to forward ref
  // to prevent warning from MUI about no-ref element in tooltip and from React about
  // missing the second argument in forwardRef function
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ value, label, fontSize = '1.6rem' }, ref) => {
    return (
      <StyledTruckStatusLabel
        sx={{ color: truckColorMap[value] }}
        fontSize={fontSize}
      >
        {label || value}
      </StyledTruckStatusLabel>
    );
  },
);
