import { APIServerError } from '../types/common';
import * as SwaggerCommunicationsAPI from './swaggerCommunicationsApi';
import {
  APINotificationSortOrderType,
  APINotificationType,
  APIUrgencyType,
} from './swaggerCommunicationsApi';
import * as SwaggerOrdersAPI from './swaggerOrdersApi';
import {
  APICallBackFormStatus,
  APIChangesStatus,
  APICompanyModel,
  APIDeliveryRateType,
  APIOrderStatus,
  APIOrderType,
  APIProviderSettingsModel,
  APISortOrder,
  APITicketStatus as APITicketStatuses,
  APITruckStatus as APITruckStatuses,
  APIUserStatus,
  APIUserType,
} from './swaggerOrdersApi';
import { NonNullableObject, WithRequiredProps } from './utils';

export { APIImportStatus as APIImportedOrderStatus } from './swaggerOrdersApi';

export { APIAccessLevel } from './swaggerOrdersApi';
export { APIProjectStatus } from './swaggerOrdersApi';

export type APIResponse<PayloadT> =
  | { error?: false; payload: PayloadT }
  | APIServerError;

export type APIImportedOrderModel = SwaggerOrdersAPI.APIOrderImportModel;

export type APIUserRequest = SwaggerOrdersAPI.APIUserRequestModel;
export type APIForgotPasswordRequest =
  SwaggerOrdersAPI.APIForgotPasswordRequestModel;
export type APICompanyRequest = SwaggerOrdersAPI.APICompanyRequestModel;
export type APIProviderRequest = SwaggerOrdersAPI.APIProviderRequestModel;
export type APITruckSearchFilter = SwaggerOrdersAPI.APITruckSearchFilter;
export type APITruckModelSearchResult = Required<
  NonNullableObject<SwaggerOrdersAPI.APITruckModelSearchResult>
>;

export type APIMasterMixType = Required<SwaggerOrdersAPI.APIMasterMixTypeModel>;
export type APIMixTypeSearchFilter = SwaggerOrdersAPI.APIMixTypeSearchFilter;
export type APIUserAuthModel = SwaggerOrdersAPI.APIUserAuthModel;
export type APIMasterMixTypeModelSearchResult =
  SwaggerOrdersAPI.APIMasterMixTypeModelSearchResult & {
    result: APIMasterMixType[];
  };

export type APIMasterAdditive =
  Required<SwaggerOrdersAPI.APIMasterAdditiveModel>;
export type APIAutocompleteResult =
  Required<SwaggerOrdersAPI.APIAutocompleteResult>;
export type APIMasterAdditiveModelSearchResult =
  SwaggerOrdersAPI.APIMasterAdditiveModelSearchResult & {
    result: APIMasterAdditive[];
  };
export type APIAdditiveSearchFilter = SwaggerOrdersAPI.APIAdditiveSearchFilter;
export type APIOrderSubscription = Required<
  NonNullableObject<SwaggerOrdersAPI.APIOrderSubscriptionModel>
>;

export type APICompany = Required<
  NonNullableObject<SwaggerOrdersAPI.APICompanyModel>
>;
export type APIMasterAdditiveType =
  Required<SwaggerOrdersAPI.APIMasterAdditiveModel>;
export type APIAdditiveTypeSearchFilter =
  SwaggerOrdersAPI.APIAdditiveSearchFilter;
export type APIMasterAdditiveTypeModelSearchResult =
  SwaggerOrdersAPI.APIMasterAdditiveModelSearchResult & {
    result: APIMasterAdditiveType[];
  };
export type APIMasterAdditiveTypeRequest =
  Required<SwaggerOrdersAPI.APIMasterAdditiveRequestModel>;
export type APICompanyModelSearchResult =
  Required<SwaggerOrdersAPI.APICompanyModelSearchResult> & {
    result: Required<NonNullableObject<APICompanyModel>>[];
  };
export type APICompanySearchFilter =
  Required<SwaggerOrdersAPI.APICompanySearchFilter>;
export type APIProviderModelSearchResult = Omit<
  Required<SwaggerOrdersAPI.APIProviderModelSearchResult>,
  'result'
> & {
  result: APIProvider[];
};
export type APIProviderSearchFilter =
  Required<SwaggerOrdersAPI.APIProviderSearchFilter>;

export type APILocation = Required<SwaggerOrdersAPI.APILocation>;

export type APIMasterMixTypeRequest =
  Required<SwaggerOrdersAPI.APIMasterMixTypeRequestModel>;
export type APIEntityHistory = Required<SwaggerOrdersAPI.APIEntityHistoryModel>;
export type APIMasterDataCategory =
  Required<SwaggerOrdersAPI.APIMasterDataCategoryModel>;

export type APIAvailableMixTypeModel = Required<
  NonNullableObject<SwaggerOrdersAPI.APIAvailableMixTypeModel>
>;
export type APIAvailableMixTypeModelSearchResult = Required<
  NonNullableObject<SwaggerOrdersAPI.APIAvailableMixTypeModelSearchResult>
> & {
  result: APIAvailableMixTypeModel[];
};
export type APIAvailableMixTypeSearchFilter = Required<
  NonNullableObject<SwaggerOrdersAPI.APIAvailableMixTypeSearchFilter>
>;

export type APIAvailableAdditiveModel = Required<
  NonNullableObject<SwaggerOrdersAPI.APIAvailableAdditiveModel>
>;
export type APIAvailableAdditiveModelSearchResult = Required<
  NonNullableObject<SwaggerOrdersAPI.APIAvailableAdditiveModelSearchResult>
> & {
  result: APIAvailableAdditiveModel[];
};

export type APIApplicationVersion = Required<
  NonNullableObject<SwaggerOrdersAPI.APIApplicationVersionModel>
>;

export type APIAfterHoursResponse = Required<
  NonNullableObject<SwaggerOrdersAPI.APIAfterHoursResponseModel>
>;

export type APIAttachment = Required<
  NonNullableObject<SwaggerCommunicationsAPI.APIAttachmentModel>
>;

export type APIAvailableAdditiveSearchFilter = Required<
  NonNullableObject<SwaggerOrdersAPI.APIAvailableAdditiveSearchFilter>
>;

export type APIRequiredAction = Required<
  NonNullableObject<SwaggerCommunicationsAPI.APINotificationModel>
>;

export type APIRequiredActionsContinueSearchResult = Required<
  NonNullableObject<SwaggerCommunicationsAPI.APINotificationModelContinueSearchResult>
>;

export type APITypeaheadItem =
  Required<SwaggerOrdersAPI.APIGuidTypeaheadModel> & {
    name: string;
  };
export type APIAdditive = SwaggerOrdersAPI.APIAdditiveModel;
export type APIMixType = SwaggerOrdersAPI.APIMixTypeModel;
export type APIAvailableAdditive = SwaggerOrdersAPI.APIAvailableAdditiveModel;
export type APIAdditiveUsage = SwaggerOrdersAPI.APIAdditiveUsageModel;
export type APIAvailableMixType = SwaggerOrdersAPI.APIAvailableMixTypeModel;
export type APIMixTypeUsage = SwaggerOrdersAPI.APIMixTypeCompanyUsageModel;
export type APICompanyParams = SwaggerOrdersAPI.APICompanyRequestModel;
export type APITrackingParams = SwaggerOrdersAPI.APITruckTrackingFilter;
export type APIProjectEditableParams = SwaggerOrdersAPI.APIProjectRequestModel;
export type APITicketsListParams = SwaggerOrdersAPI.APITicketSearchFilter;
export type APIProjectsListParams =
  NonNullableObject<SwaggerOrdersAPI.APIProjectSearchFilter>;
export type APIAssignedDriverRequestParams =
  SwaggerOrdersAPI.APIAssignedDriverRequestModel;
export type APIOrderRequest = SwaggerOrdersAPI.APIOrderRequestModel;
export type APIOrderBatchNumberRequest =
  SwaggerOrdersAPI.APIOrderBatchNumberRequestModel;
export type APIOrderCallbackSubmitRequest =
  SwaggerOrdersAPI.APIOrderCallbackSubmitRequestModel;
export type APIOrderChangesRequest =
  SwaggerOrdersAPI.APIOrderChangesRequestModel;
export type APITicketParams = SwaggerOrdersAPI.APITicketRequestModel;
export type APIOrderModelSearchResult =
  SwaggerOrdersAPI.APIOrderModelSearchResult & {
    result: APIOrder[];
  };
export type APIAddTicketResponse = SwaggerOrdersAPI.APITicketSearchFilter;
export type APIOrdersListParams = SwaggerOrdersAPI.APIOrderSearchFilter;
export type APIUsersListParams = SwaggerOrdersAPI.APIUserSearchFilter;
export type APIOrderCreateParams = SwaggerOrdersAPI.APIOrderRequestModel;
export type APIProjectTypeaheadRequestModel =
  SwaggerOrdersAPI.APIProjectTypeaheadRequestModel;
export type APITypeaheadRequestModel =
  SwaggerOrdersAPI.APIInt32TypeaheadRequestModel;
export type APIGuidTypeaheadRequestModel =
  SwaggerOrdersAPI.APIGuidTypeaheadRequestModel;
export type APIProvider = Required<SwaggerOrdersAPI.APIProviderModel>;
export type APITimeZones = NonNullableObject<
  Required<SwaggerOrdersAPI.APITimeZonesModel>
>;
export type APISubscribedUser = Required<
  NonNullableObject<SwaggerOrdersAPI.APISubscribedUserModel>
>;
export type APILocationHistoryModel = SwaggerOrdersAPI.APILocationHistoryModel;
export type APILocationHistoryListParams =
  NonNullableObject<SwaggerOrdersAPI.APILocationHistorySearchFilter>;

export type APITrackingItem = WithRequiredProps<
  SwaggerOrdersAPI.APITruckLocationModel,
  'truckStatus' | 'timeOfArrival'
> & {
  currentLocation: APILocation;
  plantLocation: APILocation;
  deliveryLocation: APILocation;
  truckNumber: string;
};

export type APIImportedOrder = WithRequiredProps<
  SwaggerOrdersAPI.APIOrderImportModelImportResult,
  'model'
>;

export type APIDialogModel = Required<SwaggerOrdersAPI.APIDialogModel>;
export type APIOrderMixType = Required<SwaggerOrdersAPI.APIOrderMixTypeModel>;
export type APIOrderAdditive = Required<SwaggerOrdersAPI.APIOrderAdditiveModel>;
export type APIOrder = Omit<
  Required<
    NonNullableObject<
      SwaggerOrdersAPI.APIOrderModel & {
        dialog: APIDialogModel;
      }
    >
  >,
  'subscribedUsers' | 'mixType' | 'additives'
> & {
  subscribedUsers: APISubscribedUser[];
  mixType: APIOrderMixType;
  additives: APIOrderAdditive[];
};
export type APIChatMessageModel =
  Required<SwaggerOrdersAPI.APIChatMessageModel>;
export type APIChatMessageModelContinueSearchResult =
  Required<SwaggerOrdersAPI.APIChatMessageModelContinueSearchResult> & {
    result: APIChatMessageModel[];
  };
export type APILocationAddress = Required<
  NonNullableObject<SwaggerOrdersAPI.APILocationAddress>
>;
export type APIUsage = Required<
  NonNullableObject<SwaggerOrdersAPI.APIUsageModel>
>;
export type APIUsageSearchResult = Required<
  Required<NonNullableObject<SwaggerOrdersAPI.APIUsageModelSearchResult>>
>;

export type APIMostUsedMixTypes =
  Required<SwaggerOrdersAPI.APIMostUsedModel> & {
    usages: APIUsage[];
  };

export type APIDialogModelContinueSearchResult = Required<
  NonNullableObject<
    SwaggerOrdersAPI.APIDialogModelContinueSearchResult & {
      result: Required<APIDialogModel>[];
    }
  >
>;

export type APIDialogSearchFilter =
  Required<SwaggerOrdersAPI.APIDialogSearchFilter>;

export type APIOrderPositionElement = Omit<
  Required<SwaggerOrdersAPI.APIOrderModelPositionElement>,
  'element'
> & {
  element: APIOrder;
};

export type APIPlant = Omit<
  Required<NonNullableObject<SwaggerOrdersAPI.APIPlantModel>>,
  'geoFenceCoordinates'
> & { geoFenceCoordinates: APILocation[] };

export type APITruck = Required<
  NonNullableObject<SwaggerOrdersAPI.APITruckModel>
>;

export type APITicket = Required<
  NonNullableObject<SwaggerOrdersAPI.APITicketModel>
> & {
  driver: APIUser;
  truck: Required<APITruck>;
  order: APIOrder;
  plant: APIPlant;
};
export type APITicketStatusHistory =
  SwaggerOrdersAPI.APITicketStatusHistoryModel;

export type APIFullTextSearchFilter =
  Required<SwaggerOrdersAPI.APIFullTextSearchFilter>;

export type APIAssignedDriver = WithRequiredProps<
  SwaggerOrdersAPI.APIAssignedDriverModel,
  'id' | 'plant' | 'assignDate'
>;

export type APIProject = Omit<
  Required<NonNullableObject<SwaggerOrdersAPI.APIProjectModel>>,
  'subscribedUsers'
> & { subscribedUsers: APISubscribedUser[] };
export type APIProjectSearchResult = Required<
  NonNullableObject<SwaggerOrdersAPI.APIProjectModelSearchResult>
> & { result: APIProject[] };

export type APISearchSortOrder<FieldT = string> = {
  sortField: FieldT;
  sortOrder: APISortOrder;
};

export type APIUser = WithRequiredProps<
  Omit<SwaggerOrdersAPI.APIUserModel, 'providers'>,
  'id' | 'userStatus'
> & {
  providers: APIProvider[] | null;
  newApplicationVersions: APIApplicationVersion[] | null;
  companies: APICompany[] | null;
};
export type APIUserSearchResult = Required<
  Omit<NonNullableObject<SwaggerOrdersAPI.APIUserModelSearchResult>, 'result'>
> & { result: APIUser[] };

export type APIProviderSettings = Required<APIProviderSettingsModel>;

export enum APIExceptionType {
  AlreadyExist = 'AlreadyExistExceptions',
  NotFound = 'NotExistExceptions',
  NotValidModel = 'NotValidModelException',
  NotFoundException = 'NotFoundException',
}

export type APIGenericList<ItemType> = {
  result: ItemType[];
  pageNumber: number;
  pageSize: number;
  count: number;
};
export type APIPlantsList = APIGenericList<APIPlant>;
export type APIOrdersList = APIGenericList<APIOrder>;
export type APIUsersList = APIGenericList<APIUser>;
export type APITicketsList = APIGenericList<APITicket>;
export type APIProjectsList = APIGenericList<APIProject>;
export type APITrucksList = APIGenericList<APITruck>;

export {
  APICallBackFormStatus,
  APITruckStatuses,
  APIOrderStatus,
  APITicketStatuses,
  APIUserType,
  APIDeliveryRateType,
  APIUserStatus,
  APISortOrder,
  APIOrderType,
  APIChangesStatus,
  APINotificationType as APIRequiredActionType,
  APINotificationSortOrderType as APIRequiredActionSortOrderType,
  APIUrgencyType as APIRequiredActionUrgencyType,
};

export type APIHelpRequest = Required<SwaggerOrdersAPI.APIHelpRequestModel>;
export { APIHelpRequestType } from './swaggerOrdersApi';
