import React from 'react';

import styled, { useTheme } from 'styled-components';

import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import CircleIcon from '@mui/icons-material/Circle';

const ChatIconWrapper = styled.div`
  position: relative;
  justify-content: center;
  align-items: center;
  margin-left: 1rem;
`;

interface Props {
  hasMessages?: boolean;
  className?: string;
}

export const ChatIcon: React.FC<Props> = ({ hasMessages, className }) => {
  const theme = useTheme();

  return (
    <ChatIconWrapper className={className}>
      <ChatBubbleOutlineOutlinedIcon sx={{ fontSize: '1.8rem' }} />
      <CircleIcon
        sx={{
          fontSize: '1rem',
          fill: hasMessages ? theme.custom.palette.danger600 : 'transparent',
          position: 'absolute',
          top: '-0.2rem',
          right: '-0.2rem',
        }}
      />
    </ChatIconWrapper>
  );
};
