import { useCallback } from 'react';

import { TableSettingsFiltersSelector } from 'selectors/storage';

import { useSelector } from 'third-party';

import { AppTableSettings } from 'constants/common';

import { useAllOrdersList } from 'modules/allOrdersList';
import { OrdersFilters } from 'modules/common/ordersList';

import {
  allOrdersListSelectors,
  appSelectors,
  storageSelectors,
} from 'selectors';

import { composeLoadOrdersRequestBody } from 'utils/ordersList';

export const useLoadAllOrders = () => {
  const { loadAllOrders } = useAllOrdersList();

  const providerId = useSelector(appSelectors.providerId);

  const ordersListSorting = useSelector(
    allOrdersListSelectors.allOrdersListSorting,
  );
  const ordersListFilters = useSelector(
    storageSelectors.tableSettingsFiltersSelector[
      AppTableSettings.AllOrders
    ] as TableSettingsFiltersSelector<OrdersFilters>,
  );
  const ordersListSearch = useSelector(
    allOrdersListSelectors.allOrdersListSearch,
  );
  const { pageNumber, pageSize } = useSelector(
    allOrdersListSelectors.allOrdersListPagination,
  );

  const handleLoadAllOrders = useCallback(() => {
    loadAllOrders(
      composeLoadOrdersRequestBody(providerId, {
        pageNumber,
        pageSize,
        sorting: ordersListSorting,
        filters: ordersListFilters,
        search: ordersListSearch,
      }),
    );
  }, [
    loadAllOrders,
    ordersListFilters,
    ordersListSearch,
    ordersListSorting,
    pageNumber,
    pageSize,
    providerId,
  ]);

  return {
    loadAllOrdersList: handleLoadAllOrders,
  };
};
