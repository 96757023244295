import { useMemo } from 'react';

import {
  addDialogMessage,
  addUnreadOrderId,
  chatBuilder,
  loadDialogMessages,
  loadMultipleDialogAttachmentsByIds,
  loadProviderDialogs,
  markDialogAsReadByContractor,
  markDialogAsReadByDispatcher,
  removeUnreadOrderId,
  resetDialogMessages,
  sendDialogMessage,
} from '@cd3p/core/modules/chat';

import { bindActionCreators, useDispatch, useSelector } from 'third-party';

import { appSelectors } from 'selectors';

export const useChat = () => {
  const dispatch = useDispatch();
  const providerId = useSelector(appSelectors.providerId);

  return useMemo(
    () =>
      bindActionCreators(
        {
          loadProviderDialogs: loadProviderDialogs.bind(null, providerId),
          loadDialogMessages: loadDialogMessages.bind(null, providerId),
          sendDialogMessage: sendDialogMessage.bind(null, providerId),
          markDialogAsReadByDispatcher: markDialogAsReadByDispatcher.bind(
            null,
            providerId,
          ),
          markDialogAsReadByContractor: markDialogAsReadByContractor.bind(
            null,
            providerId,
          ),
          addDialogMessage: addDialogMessage.bind(null),
          resetDialogMessages: resetDialogMessages.bind(null),
          addUnreadOrderId: addUnreadOrderId.bind(null),
          removeUnreadOrderId: removeUnreadOrderId.bind(null),
          loadMultipleDialogAttachmentsByIds:
            loadMultipleDialogAttachmentsByIds.bind(null, providerId),
        },
        dispatch,
      ),
    [dispatch, providerId],
  );
};

export const chatReducer = chatBuilder.getReducers();
