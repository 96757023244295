import { useMemo } from 'react';

import { httpMethod, simplifyBuilder } from '@cd3p/core';
import {
  APIAdditiveSearchFilter,
  APIMasterAdditive,
  APIMasterAdditiveModelSearchResult,
  APISortOrder,
} from '@cd3p/core/types/api';
import {
  DEFAULT_PAGE_SIZE,
  getPageNumberAfterLoadMore,
  getPageSizeDependingOnItems,
} from '@cd3p/core/utils/lists';

import { _, bindActionCreators, useDispatch, useSelector } from 'third-party';

import {
  contractorAdditiveEndpoint,
  contractorAdditivesEndpoint,
  customerProjectAdditiveTypeEndpoint,
  customerProjectAdditiveTypesEndpoint,
} from 'constants/endpoints';

import { State } from 'modules/index';

import { appSelectors } from 'selectors';

export type ContractorAdditiveTypesColumnId = keyof APIMasterAdditive;

export type ContractorAdditiveTypesSorting = {
  sortField: ContractorAdditiveTypesColumnId;
  sortOrder: APISortOrder;
}[];

export type ContractorAdditiveTypesState = {
  items: APIMasterAdditiveModelSearchResult['result'];
  additiveTypesLoaded: boolean;
  loadContractorAdditiveTypesPending: boolean;
  loadMoreContractorAdditiveTypesPending: boolean;
  loadProjectAdditiveTypesPending: boolean;
  loadMoreProjectAdditiveTypesPending: boolean;
  sorting: ContractorAdditiveTypesSorting;
  pagination: {
    pageNumber: APIMasterAdditiveModelSearchResult['pageNumber'];
    pageSize: APIMasterAdditiveModelSearchResult['pageSize'];
  };
  count: number;
};

export const contractorAdditiveTypesState: ContractorAdditiveTypesState = {
  items: [],
  sorting: [
    {
      sortField: 'name',
      sortOrder: APISortOrder.ASC,
    },
  ],
  additiveTypesLoaded: false,
  loadMoreContractorAdditiveTypesPending: false,
  loadContractorAdditiveTypesPending: false,
  loadProjectAdditiveTypesPending: false,
  loadMoreProjectAdditiveTypesPending: false,
  pagination: { pageNumber: 1, pageSize: DEFAULT_PAGE_SIZE },
  count: 0,
};

const builder = simplifyBuilder(contractorAdditiveTypesState, {});

export const loadContractorAdditiveTypes = builder.createServerAction(
  (
    providerId: string,
    contractorId: string,
    body: APIAdditiveSearchFilter = {},
  ) => ({
    name: 'loadContractorAdditiveTypes',
    url: contractorAdditivesEndpoint(providerId, contractorId),
    body: (state: State) => ({
      pageSize: state.contractorAdditiveTypes.pagination.pageSize,
      pageNumber: state.contractorAdditiveTypes.pagination.pageNumber,
      searchSortOrders: state.contractorAdditiveTypes.sorting,
      ...body,
    }),
    method: httpMethod.post,
    onSuccess: (
      state: ContractorAdditiveTypesState,
      payload: APIMasterAdditiveModelSearchResult,
    ) => {
      return {
        items: payload.result || [],
        additiveTypesLoaded: true,
        pagination: {
          pageNumber: payload.pageNumber,
          pageSize: payload.pageSize,
        },
        count: payload.count,
      };
    },
  }),
);

export const loadProjectAdditiveTypes = builder.createServerAction(
  (
    providerId: string,
    projectId: string,
    body: APIAdditiveSearchFilter = {},
  ) => ({
    name: 'loadProjectAdditiveTypes',
    url: customerProjectAdditiveTypesEndpoint(providerId, projectId),
    body: (state: State) => ({
      pageSize: state.contractorAdditiveTypes.pagination.pageSize,
      pageNumber: state.contractorAdditiveTypes.pagination.pageNumber,
      searchSortOrders: state.contractorAdditiveTypes.sorting,
      ...body,
    }),
    method: httpMethod.post,
    onSuccess: (
      state: ContractorAdditiveTypesState,
      payload: APIMasterAdditiveModelSearchResult,
    ) => {
      return {
        items: payload.result || [],
        additiveTypesLoaded: true,
        pagination: {
          pageNumber: payload.pageNumber,
          pageSize: payload.pageSize,
        },
        count: payload.count,
      };
    },
  }),
);

export const loadMoreContractorAdditiveTypes = builder.createServerAction(
  (providerId: string, contractorId: string) => ({
    name: 'loadMoreContractorAdditiveTypes',
    url: contractorAdditivesEndpoint(providerId, contractorId),
    body: (state: State) => ({
      pageNumber: getPageNumberAfterLoadMore(
        state.contractorAdditiveTypes.items,
        state.contractorAdditiveTypes.pagination.pageSize,
      ),
      pageSize: state.contractorAdditiveTypes.pagination.pageSize,
      searchSortOrders: state.contractorAdditiveTypes.sorting,
    }),
    method: httpMethod.post,
    onSuccess: (
      state: ContractorAdditiveTypesState,
      payload: APIMasterAdditiveModelSearchResult,
    ) => {
      return {
        items: [...state.items, ...payload.result],
      };
    },
  }),
);

export const loadMoreProjectAdditiveTypes = builder.createServerAction(
  (providerId: string, projectId: string) => ({
    name: 'loadMoreProjectAdditiveTypes',
    url: customerProjectAdditiveTypesEndpoint(providerId, projectId),
    body: (state: State) => ({
      pageNumber: getPageNumberAfterLoadMore(
        state.contractorAdditiveTypes.items,
        state.contractorAdditiveTypes.pagination.pageSize,
      ),
      pageSize: state.contractorAdditiveTypes.pagination.pageSize,
      searchSortOrders: state.contractorAdditiveTypes.sorting,
    }),
    method: httpMethod.post,
    onSuccess: (
      state: ContractorAdditiveTypesState,
      payload: APIMasterAdditiveModelSearchResult,
    ) => {
      return {
        items: _.uniqBy([...state.items, ...payload.result], 'id'),
      };
    },
  }),
);

export const updateContractorAdditiveTypes = builder.createServerAction(
  (providerId: string, contractorId: string) => ({
    name: 'updateContractorAdditiveTypes',
    url: contractorAdditivesEndpoint(providerId, contractorId),
    body: (state: State) => ({
      pageNumber: 1,
      pageSize: getPageSizeDependingOnItems(
        state.contractorAdditiveTypes.items,
      ),
      searchSortOrders: state.contractorAdditiveTypes.sorting,
    }),
    method: httpMethod.post,
    onSuccess: (
      state: ContractorAdditiveTypesState,
      payload: APIMasterAdditiveModelSearchResult,
    ) => {
      return {
        items: payload.result,
        count: payload.count,
      };
    },
  }),
);

export const updateProjectAdditiveTypes = builder.createServerAction(
  (providerId: string, projectId: string) => ({
    name: 'updateProjectAdditiveTypes',
    url: customerProjectAdditiveTypesEndpoint(providerId, projectId),
    body: (state: State) => ({
      pageNumber: 1,
      pageSize: getPageSizeDependingOnItems(
        state.contractorAdditiveTypes.items,
      ),
      searchSortOrders: state.contractorAdditiveTypes.sorting,
    }),
    method: httpMethod.post,
    onSuccess: (
      state: ContractorAdditiveTypesState,
      payload: APIMasterAdditiveModelSearchResult,
    ) => {
      return {
        items: payload.result,
        count: payload.count,
      };
    },
  }),
);

export const deleteContractorAdditiveType = builder.createServerAction(
  (providerId: string, contractorId: string, additiveTypeId: number) => ({
    name: 'deleteContractorAdditiveType',
    url: contractorAdditiveEndpoint(providerId, contractorId, additiveTypeId),
    method: httpMethod.delete,
  }),
);

export const deleteProjectAdditiveType = builder.createServerAction(
  (providerId: string, projectId: string, additiveTypeId: number) => ({
    name: 'deleteProjectAdditiveType',
    url: customerProjectAdditiveTypeEndpoint(
      providerId,
      projectId,
      additiveTypeId,
    ),
    method: httpMethod.delete,
  }),
);

export const updateContractorAdditiveTypesSorting = builder.createReduxAction(
  (newSorting: ContractorAdditiveTypesState['sorting']) => ({
    name: 'updateContractorAdditiveTypesSorting',
    updater: () => ({
      sorting: newSorting,
    }),
  }),
);

export const resetContractorAdditiveTypes = builder.createReduxAction(() => ({
  name: 'resetContractorAdditiveTypes',
  updater: () => contractorAdditiveTypesState,
}));

export const useContractorAdditiveTypes = () => {
  const dispatch = useDispatch();
  const providerId = useSelector(appSelectors.providerId);

  return useMemo(
    () =>
      bindActionCreators(
        {
          loadContractorAdditiveTypes: loadContractorAdditiveTypes.bind(
            null,
            providerId,
          ),
          loadMoreContractorAdditiveTypes: loadMoreContractorAdditiveTypes.bind(
            null,
            providerId,
          ),
          loadProjectAdditiveTypes: loadProjectAdditiveTypes.bind(
            null,
            providerId,
          ),
          loadMoreProjectAdditiveTypes: loadMoreProjectAdditiveTypes.bind(
            null,
            providerId,
          ),
          updateContractorAdditiveTypes: updateContractorAdditiveTypes.bind(
            null,
            providerId,
          ),
          updateProjectAdditiveTypes: updateProjectAdditiveTypes.bind(
            null,
            providerId,
          ),
          updateContractorAdditiveTypesSorting:
            updateContractorAdditiveTypesSorting.bind(null),
          deleteContractorAdditiveType: deleteContractorAdditiveType.bind(
            null,
            providerId,
          ),
          deleteProjectAdditiveType: deleteProjectAdditiveType.bind(
            null,
            providerId,
          ),
          resetContractorAdditiveTypes: resetContractorAdditiveTypes.bind(null),
        },
        dispatch,
      ),
    [dispatch, providerId],
  );
};

export const contractorAdditiveTypesReducer = builder.getReducers();
