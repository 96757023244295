import { State } from 'modules';

import { _, createSelector } from 'third-party';

import { AppTableSettings } from 'constants/common';

import { StorageUserSettings } from 'modules/storage';

import { TableSettingsT } from 'types/app';

export type TableSettingsFiltersSelector<FiltersT> = (state: State) => FiltersT;

export const userSettings = (state: State) => state.storage.userSettings;

export const userSettingsSelector = <
  SelectorFunc extends (...args: any) => any,
>(
  settingsSelector: SelectorFunc,
) =>
  createSelector(
    [(state: State) => state.app.user?.id, userSettings],
    (userId = '', userSettings): ReturnType<SelectorFunc> =>
      settingsSelector(userSettings[userId]),
  );

export const tableSettingsColumnWidthsSelector = Object.values(
  AppTableSettings,
).reduce((previousValue, key) => {
  return {
    ...previousValue,
    [key]: userSettingsSelector(
      (userSettings: StorageUserSettings) => userSettings[key].columnsWidth,
    ),
  };
}, {} as Record<AppTableSettings, (state: State) => TableSettingsT['columnsWidth']>);

export const tableSettingsHiddenColumnsSelector = Object.values(
  AppTableSettings,
).reduce((previousValue, key) => {
  return {
    ...previousValue,
    [key]: userSettingsSelector(
      (userSettings: StorageUserSettings) => userSettings[key].hiddenColumns,
    ),
  };
}, {} as Record<AppTableSettings, (state: State) => TableSettingsT['hiddenColumns']>);

export const tableSettingsFiltersSelector = Object.values(
  AppTableSettings,
).reduce((previousValue, key) => {
  return {
    ...previousValue,
    [key]: userSettingsSelector(
      (userSettings: StorageUserSettings) => userSettings[key].filters,
    ),
  };
}, {} as Record<AppTableSettings, (state: State) => TableSettingsT['filters']>);

export const isTableSettingsFiltersApplied = Object.values(
  AppTableSettings,
).reduce((previousValue, key) => {
  return {
    ...previousValue,
    [key]: (defaultFilters: any) =>
      createSelector(
        [tableSettingsFiltersSelector[key]],
        currentFilters => !_.isEqual(currentFilters, defaultFilters),
      ),
  };
}, {} as Record<AppTableSettings, (defaultFilters: any) => (state: State) => boolean>);
