import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

import { APILocationHistoryModel, APITruck } from '@cd3p/core/types/api';
import { Container, Grid, Typography } from '@mui/material';
import {
  DesktopDatePicker,
  FormField,
  LoadingButton,
  TextField,
  TimePicker,
} from 'components';
import { useMenuOptions } from 'hooks/useMenuOptions';

import { TrackingMap } from './TrackingMap';

import { dayjs, useForm, useSelector, useTranslation } from 'third-party';

import { useCache } from 'modules/cache';

import { cacheSelectors } from 'selectors';

const ContainerWrapper = styled(Container)`
  width: 100%;
  height: 100%;
  min-width: 100%;
  padding: 0.5rem 0 0 0;
  margin: 0;
`;

const SettingsHeader = styled(Typography)`
  color: ${props => props.theme.custom.palette.secondary500};
  margin-bottom: 0.3rem;
`;

const SettingsWrapper = styled(Grid)`
  padding: 1.5rem 2rem 0 2rem;
  overflow: hidden;
  height: 100%;
`;

const FieldWrapper = styled.div`
  margin-bottom: 1.5rem;
`;

const TRUCK_FIELD = 'truckId';
const DATE_FIELD = 'date';
const TIME_FROM = 'timeFrom';
const TIME_TO = 'timeTo';

const formDefaultValues = {
  [TRUCK_FIELD]: '',
  [DATE_FIELD]: '',
  [TIME_FROM]: undefined,
  [TIME_TO]: undefined,
};

export interface APIHistory {
  truckId: string;
  date: string;
  timeFrom: string | undefined;
  timeTo: string | undefined;
}

const popperDateSx = {
  '& .MuiPickersCalendarHeader-label': {
    fontSize: '1.6rem',
    textTransform: 'uppercase',
    fontWeight: 900,
  },
  '& .PrivatePickersYear-yearButton': {
    fontSize: '1.6rem',
    margin: '0.4rem 0',
    '&.Mui-selected': {
      color: '#ffffff',
    },
  },
  '& .MuiPickersDay-dayWithMargin': {
    fontSize: '1.6rem',
    color: '#5C6C80',
    '&[aria-selected="true"]': {
      color: '#ffffff',
    },
    '&[aria-current="date"]': {
      borderColor: '#AFD8D4',
      backgroundColor: '#AFD8D4',
    },
  },
};

interface Coordinates {
  lat: number;
  lng: number;
}

const removeConsecutiveDuplicates = (arr: Coordinates[]) => {
  const uniqueCoordinates: Coordinates[] = [];
  for (let i = 0; i < arr.length; i++) {
    if (i === 0 || !areCoordinatesEqual(arr[i], arr[i - 1])) {
      uniqueCoordinates.push(arr[i]);
    }
  }

  return uniqueCoordinates;
};

const areCoordinatesEqual = (a: Coordinates, b: Coordinates) => {
  return a.lat === b.lat && a.lng === b.lng;
};

export const TrackingHistoryView = () => {
  const { t } = useTranslation();

  const { loadTrucks, loadTrucksLocationHistory } = useCache();

  const loadTrucksPending = useSelector(cacheSelectors.loadTrucksPending);
  const [locationHistory, setLocationHistory] = useState<any[]>([]);
  const trucks = useSelector(cacheSelectors.trucks);

  useEffect(() => {
    loadTrucks();
  }, [loadTrucks]);

  const {
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<APIHistory>({
    mode: 'onSubmit',
    defaultValues: formDefaultValues,
  });

  const watchedTruckValue = watch(TRUCK_FIELD);
  const trucksOptions = useMenuOptions<APITruck>({
    items: trucks,
    selectedItemId: watchedTruckValue,
    displayKey: 'truckNumber',
  });

  const onSubmit = async (data: APIHistory) => {
    const result = await loadTrucksLocationHistory({
      ...data,
      date: dayjs(data.date).format('YYYY-MM-DD'),
    });

    const payload = result.payload as APILocationHistoryModel[];
    const mapped =
      payload?.map(it => ({
        lat: it.latitude as number,
        lng: it.longitude as number,
      })) ?? [];

    setLocationHistory(removeConsecutiveDuplicates(mapped));
  };

  return (
    <ContainerWrapper>
      <Grid container height="100%" width="100%">
        <SettingsWrapper item xs={4}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <SettingsHeader variant="h5">
              {t('trackingEmulator.settings')}
            </SettingsHeader>
            <FieldWrapper>
              <FormField
                key={TRUCK_FIELD}
                fieldName={TRUCK_FIELD}
                fieldError={errors[TRUCK_FIELD]}
                isRequired
                label={t('trackingHistory.truck')}
                control={control}
                isLoading={loadTrucksPending}
                selectOptions={trucksOptions}
              />
            </FieldWrapper>
            <FieldWrapper>
              <FormField
                key={DATE_FIELD}
                fieldName={DATE_FIELD}
                fieldError={errors[DATE_FIELD]}
                isRequired
                label={t('trackingHistory.date')}
                control={control}
                render={({ field }) => (
                  <DesktopDatePicker
                    value={field.value}
                    PopperProps={{
                      sx: popperDateSx,
                      placement: 'bottom-start',
                    }}
                    renderInput={params => (
                      <TextField
                        size="small"
                        variant="outlined"
                        InputProps={{ style: { fontSize: 40, color: 'red' } }}
                        {...params}
                        error={!!errors[DATE_FIELD]}
                      />
                    )}
                    onChange={value => {
                      setValue(
                        DATE_FIELD,
                        dayjs(value.$d).format('YYYY-MM-DD'),
                      );
                    }}
                  />
                )}
              />
            </FieldWrapper>
            <FieldWrapper>
              <FormField
                key={TIME_FROM}
                fieldName={TIME_FROM}
                fieldError={errors[TIME_FROM]}
                isRequired
                label={t('trackingHistory.timeFrom')}
                control={control}
                render={({ field }) => (
                  <TimePicker
                    value={field.value}
                    onChange={value => {
                      setValue(TIME_FROM, value);
                    }}
                    error={!!errors[TIME_FROM]}
                    inputProps={{
                      placeholder: t('trackingHistory.timeFrom'),
                      style: { color: 'black', width: '16rem' },
                    }}
                  />
                )}
              />
            </FieldWrapper>
            <FieldWrapper>
              <FormField
                key={TIME_TO}
                fieldName={TIME_TO}
                fieldError={errors[TIME_TO]}
                isRequired
                label={t('trackingHistory.timeTo')}
                control={control}
                render={({ field }) => (
                  <TimePicker
                    value={field.value}
                    onChange={value => {
                      setValue(TIME_TO, value);
                    }}
                    error={!!errors[TIME_TO]}
                    inputProps={{
                      placeholder: t('trackingHistory.timeTo'),
                      style: { color: 'black', width: '16rem' },
                    }}
                  />
                )}
              />
            </FieldWrapper>
            <FieldWrapper>
              <LoadingButton
                variant="outlined"
                color="secondary"
                type="submit"
                loading={loadTrucksPending}
                style={{ marginRight: '1rem' }}
              >
                {t('trackingHistory.load')}
              </LoadingButton>
            </FieldWrapper>
          </form>
        </SettingsWrapper>
        <Grid item xs={8}>
          <TrackingMap points={locationHistory} />
        </Grid>
      </Grid>
    </ContainerWrapper>
  );
};
