import React from 'react';

import { OrdersApiSpec } from '@cd3p/core/constants/endpoints';
import { DispatcherCanAccess } from 'components';

import { useSelector } from 'third-party';

import { appSelectors } from 'selectors';

export const ApiDocs = () => {
  const isApiDocsFeatureEnabled = useSelector(
    appSelectors.isApiDocsFeatureEnabled,
  );

  return isApiDocsFeatureEnabled ? (
    <DispatcherCanAccess>
      <iframe
        src={OrdersApiSpec()}
        frameBorder="0"
        height="100%"
        width="100%"
      />
    </DispatcherCanAccess>
  ) : null;
};
