export default [
  {
    index: 8,
    title: '1.26.0',
    authors: 'concretely',
    position: -8,
    datePublish: '2024-09-19T00:00:00.000Z',
    tags: ['release'],
    children: [],
    file: 'docs/releases/1.26.0.mdx',
  },
  {
    index: 7,
    title: '1.25.0',
    authors: 'concretely',
    position: -7,
    datePublish: '2024-09-11T00:00:00.000Z',
    tags: ['release'],
    children: [],
    file: 'docs/releases/1.25.0.mdx',
  },
  {
    index: 6,
    title: '1.24.0',
    authors: 'concretely',
    position: -6,
    datePublish: '2024-08-22T00:00:00.000Z',
    tags: ['release'],
    children: [],
    file: 'docs/releases/1.24.0.mdx',
  },
  {
    index: 5,
    title: '1.23.0',
    authors: 'concretely',
    position: -5,
    datePublish: '2024-08-08T00:00:00.000Z',
    tags: ['release'],
    children: [],
    file: 'docs/releases/1.23.0.mdx',
  },
  {
    index: 4,
    title: '1.22.0',
    authors: 'concretely',
    position: -4,
    datePublish: '2024-07-16T00:00:00.000Z',
    tags: ['release'],
    children: [],
    file: 'docs/releases/1.22.0.mdx',
  },
  {
    index: 3,
    title: '1.21.0',
    authors: 'concretely',
    position: -3,
    datePublish: '2024-06-24T00:00:00.000Z',
    tags: ['release'],
    children: [],
    file: 'docs/releases/1.21.0.mdx',
  },
  {
    index: 2,
    title: '1.20.0',
    authors: 'concretely',
    position: -2,
    datePublish: '2024-06-04T00:00:00.000Z',
    tags: ['release'],
    children: [],
    file: 'docs/releases/1.20.0.mdx',
  },
  {
    index: 1,
    title: '1.19.0',
    authors: 'concretely',
    position: -1,
    datePublish: '2024-05-15T00:00:00.000Z',
    tags: ['release'],
    children: [],
    file: 'docs/releases/1.19.0.mdx',
  },
];
