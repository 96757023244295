import React, { useCallback, useEffect, useMemo, useState } from 'react';

import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import { NotificationSeverity } from '@cd3p/core/modules/notifications';
import {
  APIAdditiveSearchFilter,
  APIMasterAdditive,
  APIMasterMixType,
  APIMixTypeSearchFilter,
  APIUserType,
} from '@cd3p/core/types/api';
import { OmitFirstArg } from '@cd3p/core/types/utils';
import { getServerErrorTitle, getSortOrder } from '@cd3p/core/utils/common';
import {
  CanAccess,
  CircularProgress,
  ConfirmationDialog,
  IconButton,
  Table,
} from 'components';

import {
  ActionButtonsCellStyles,
  EmptyState,
  Header,
  HeaderWrapper,
  LoadingContainer,
  TableCellText,
  Wrapper,
  tableComponents,
} from './commonTabComponents';

import { useSelector, useTranslation } from 'third-party';

import {
  MixAndAdditivesTableColumnIds,
  contractorMixAndAdditivesHiddenColumns,
} from 'constants/common';

// TODO: Update respective methods used for project additives list.
import {
  ContractorAdditiveTypesColumnId,
  ContractorAdditiveTypesSorting,
  deleteContractorAdditiveType,
  loadContractorAdditiveTypes,
  loadMoreContractorAdditiveTypes,
  resetContractorAdditiveTypes,
  updateContractorAdditiveTypes,
  updateContractorAdditiveTypesSorting,
} from 'modules/contractorAdditiveTypes';
import {
  ContractorMixTypesColumnId,
  ContractorMixTypesSorting,
  deleteProjectMixType,
  loadMoreContractorMixTypes,
  loadProjectMixTypes,
  resetContractorMixTypes,
  updateContractorMixTypesSorting,
  updateProjectMixTypes,
} from 'modules/contractorMixTypes';
import { useNotifications } from 'modules/notifications';

import { appSelectors } from 'selectors';

type ItemT = APIMasterMixType | APIMasterAdditive;

type Props = {
  items: ItemT[];
  itemsCount: number;
  itemsLoaded: boolean;
  loadItemsPending: boolean;
  loadMoreItemsPending: boolean;
  sorting: ContractorMixTypesSorting | ContractorAdditiveTypesSorting;
  projectId: string;
  loadItems: OmitFirstArg<
    typeof loadContractorAdditiveTypes | typeof loadProjectMixTypes
  >;
  loadMoreItems: OmitFirstArg<
    typeof loadMoreContractorAdditiveTypes | typeof loadMoreContractorMixTypes
  >;
  deleteItem: OmitFirstArg<
    typeof deleteContractorAdditiveType | typeof deleteProjectMixType
  >;
  updateItems: OmitFirstArg<
    typeof updateContractorAdditiveTypes | typeof updateProjectMixTypes
  >;
  updateItemsSorting:
    | typeof updateContractorAdditiveTypesSorting
    | typeof updateContractorMixTypesSorting;
  resetItems:
    | typeof resetContractorAdditiveTypes
    | typeof resetContractorMixTypes;
  successDeleteMessage: string;
  itemNameColumnLabel: string;
  itemCategoriesColumnLabel: string;
  deleteConfirmationTitle: string;
  deleteConfirmationText: string;
  tableHeader: string;
  loadMoreButtonLabel: string;
  emptyStateText: string;
  isReadOnly?: boolean;
  addToListDropdown?: React.ReactElement;
};

export const ProjectCommonTab = ({
  items,
  itemsCount,
  itemsLoaded,
  loadItems,
  loadMoreItems,
  loadItemsPending,
  loadMoreItemsPending,
  sorting,
  successDeleteMessage,
  itemNameColumnLabel,
  itemCategoriesColumnLabel,
  deleteConfirmationTitle,
  deleteConfirmationText,
  tableHeader,
  loadMoreButtonLabel,
  emptyStateText,
  projectId,
  deleteItem,
  updateItems,
  updateItemsSorting,
  resetItems,
  isReadOnly,
  addToListDropdown,
}: Props) => {
  const { t } = useTranslation();

  const { addNotification } = useNotifications();

  const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] =
    useState(false);
  const [selectedItem, setSelectedItem] = useState<null | ItemT>(null);
  const userType = useSelector(appSelectors.userType);
  const isUserContractor = userType === APIUserType.Contractor;

  const { sortField, sortOrder } = sorting[0];

  const fetchItems = useCallback(
    (
      params?:
        | Partial<APIAdditiveSearchFilter>
        | Partial<APIMixTypeSearchFilter>,
    ) => {
      loadItems(projectId, params);
    },
    [projectId, loadItems],
  );

  useEffect(() => {
    if (!itemsLoaded) fetchItems();
  }, [fetchItems, itemsLoaded]);

  useEffect(() => {
    return () => {
      resetItems();
    };
  }, [resetItems]);

  const onLoadMoreClicked = useCallback(() => {
    loadMoreItems(projectId);
  }, [projectId, loadMoreItems]);

  const onColumnHeaderClicked = useCallback(
    (
      columnId: ContractorMixTypesColumnId | ContractorAdditiveTypesColumnId,
    ) => {
      const searchSortOrders = [
        {
          sortField: columnId,
          sortOrder: getSortOrder(columnId, sortField, sortOrder),
        },
      ];
      fetchItems({ searchSortOrders });
      updateItemsSorting(searchSortOrders);
    },
    [sortField, sortOrder, updateItemsSorting, fetchItems],
  );

  const onDeleteItem = useCallback(async () => {
    setShowDeleteConfirmationDialog(false);
    if (!selectedItem) return;

    const result = await deleteItem(projectId, selectedItem.id);
    setSelectedItem(null);
    if (result.error) {
      addNotification({
        severity: NotificationSeverity.Error,
        message: getServerErrorTitle(result) || t('common.generalError'),
      });
    } else {
      addNotification({
        message: successDeleteMessage,
      });
      updateItems(projectId);
    }
  }, [
    selectedItem,
    deleteItem,
    projectId,
    addNotification,
    t,
    successDeleteMessage,
    updateItems,
  ]);

  const columns = useMemo(() => {
    const readOnlyColumns = [
      {
        id: MixAndAdditivesTableColumnIds.Name,
        dataId: 'name' as const,
        label: itemNameColumnLabel,
        formatter: (value: ItemT['name']) => ({
          value: value || '',
          element: <TableCellText>{value || ''}</TableCellText>,
        }),
      },
      {
        id: MixAndAdditivesTableColumnIds.Categories,
        dataId: 'categories' as const,
        sortable: false,
        label: itemCategoriesColumnLabel,
        formatter: (value: ItemT['categories']) => {
          const displayValue = value?.map(it => it.name).join(', ');
          return {
            value: displayValue,
            element: <TableCellText>{displayValue}</TableCellText>,
          };
        },
      },
    ];

    const actionButtons = {
      id: MixAndAdditivesTableColumnIds.ActionButtons,
      width: '7.2rem',
      cellStyles: ActionButtonsCellStyles,
      formatter: (value: string, item: ItemT) => {
        return {
          value: '',
          element: (
            <span>
              <CanAccess forbiddenUserType={APIUserType.Contractor}>
                <IconButton
                  onClick={() => {
                    setSelectedItem(item);
                    setShowDeleteConfirmationDialog(true);
                  }}
                >
                  <HighlightOffIcon color="primary" />
                </IconButton>
              </CanAccess>
            </span>
          ),
        };
      },
    };

    return isReadOnly ? readOnlyColumns : [...readOnlyColumns, actionButtons];
  }, [itemCategoriesColumnLabel, itemNameColumnLabel, isReadOnly]);

  return (
    <Wrapper direction="column">
      <ConfirmationDialog
        onClose={() => {
          setSelectedItem(null);
          setShowDeleteConfirmationDialog(false);
        }}
        open={showDeleteConfirmationDialog}
        handleActionClick={onDeleteItem}
        title={deleteConfirmationTitle}
        actionButtonTitle={t('common.yes.delete')}
        cancelButtonTitle={t('common.no.cancel')}
        description={deleteConfirmationText}
      />
      <HeaderWrapper direction="row">
        <Header variant="h5">{tableHeader}</Header>
      </HeaderWrapper>
      {addToListDropdown}
      {!itemsLoaded && (
        <LoadingContainer>
          <CircularProgress sx={{ margin: '5rem' }} />
        </LoadingContainer>
      )}
      {itemsLoaded && itemsCount > 0 && (
        <Table
          infiniteScroll
          tableMinHeight="20rem"
          components={tableComponents}
          columns={columns}
          items={items}
          itemsTotalCount={itemsCount}
          itemsLoaded={itemsLoaded}
          loadItemsPending={loadItemsPending}
          loadMoreItemsPending={loadMoreItemsPending}
          onLoadMoreClicked={onLoadMoreClicked}
          sortField={sortField}
          sortOrder={sortOrder}
          onColumnHeaderClicked={onColumnHeaderClicked}
          loadMoreButtonText={loadMoreButtonLabel}
          hiddenColumns={
            isUserContractor ? contractorMixAndAdditivesHiddenColumns : []
          }
        />
      )}
      {itemsLoaded && itemsCount === 0 && (
        <EmptyState>{emptyStateText}</EmptyState>
      )}
    </Wrapper>
  );
};
