import React, { useEffect, useMemo, useState } from 'react';

import { ON_TRUCK_LOCATION_UPDATE_EVENT } from '@cd3p/core/constants/hubs';
import { APITrackingItem, APITruck } from '@cd3p/core/types/api';
import { Container, MenuItem, Typography } from '@mui/material';
import {
  FormField,
  Grid,
  LoadingButton,
  MenuEmptyText,
  TrackingMap,
  TruckStatusLabel,
} from 'components';
import { useMenuOptions } from 'hooks/useMenuOptions';

import { styled, useForm, useSelector, useTranslation } from 'third-party';

import { useCache } from 'modules/cache';
import { NotificationSeverity, useNotifications } from 'modules/notifications';
import { useTracking } from 'modules/tracking';
import { APITrackItem, useTruckEmulator } from 'modules/truckEmulator';

import {
  cacheSelectors,
  trackingSelectors,
  truckEmulatorSelectors,
} from 'selectors';

import { useOrdersSockets } from 'utils/sockets';

const ContainerWrapper = styled(Container)`
  width: 100%;
  height: 100%;
  min-width: 100%;
  padding: 0.5rem 0 0 0;
  margin: 0;
`;

const SettingsHeader = styled(Typography)`
  color: ${props => props.theme.custom.palette.secondary500};
  margin-bottom: 0.3rem;
`;

const DriverLabel = styled(Typography)`
  display: flex;
  padding-right: 0.5rem;
`;

const DriverValue = styled(Typography)`
  font-weight: 600;
  padding-left: 0.5rem;
`;

const SettingsWrapper = styled(Grid)`
  padding: 1.5rem 2rem 0 2rem;
  overflow: hidden;
  height: 100%;
`;

const TRUCK_FIELD = 'truckId';
const DELAY_FIELD = 'delay';
const TRACK_FIELD = 'trackName';

const formDefaultValues = {
  [TRUCK_FIELD]: '',
  [DELAY_FIELD]: 1,
  [TRACK_FIELD]: '',
};

export const TrackingEmulatorView = () => {
  const { t } = useTranslation();
  const { addNotification } = useNotifications();

  const { loadTracking } = useTracking();
  const { loadTrucks } = useCache();
  const { loadAvailableTracks, emulateTruckTrack, cancelTruckTracking } =
    useTruckEmulator();

  const [trucksLocations, setTrucksLocations] = useState<APITrackingItem[]>([]);

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<APITrackItem>({
    mode: 'onSubmit',
    defaultValues: formDefaultValues,
  });

  const onSubmit = async (data: APITrackItem) => {
    const result = await emulateTruckTrack(data);
    if (result?.error) {
      addNotification({
        severity: NotificationSeverity.Error,
        // @ts-ignore in case of error payload is not an empty object
        message: result.payload?.body?.Title,
      });
    }
  };

  const watchedTruckValue = watch(TRUCK_FIELD);
  const watchedTrackValue = watch(TRACK_FIELD);
  const selectedTruck = useMemo(
    () =>
      trucksLocations.find(
        it => watchedTruckValue && it.truckId === watchedTruckValue,
      ),
    [trucksLocations, watchedTruckValue],
  );

  const onCancel = async () => {
    const result = await cancelTruckTracking(watchedTruckValue);
    if (result?.error) {
      addNotification({
        severity: NotificationSeverity.Error,
        // @ts-ignore in case of error payload is not an empty object
        message: result.payload?.body?.Title,
      });
    }
  };

  const trucks = useSelector(cacheSelectors.trucks);
  const loadDriversPending = useSelector(cacheSelectors.loadDriversPending);
  const availableTracks = useSelector(truckEmulatorSelectors.availableTracks);
  const loadAvailableTracksPending = useSelector(
    truckEmulatorSelectors.loadAvailableTracksPending,
  );
  const cancelTruckTrackingPending = useSelector(
    truckEmulatorSelectors.cancelTruckTrackingPending,
  );
  const trackingItemsValues = useSelector(
    trackingSelectors.trackingItemsValues,
  );

  const trucksOptions = useMenuOptions<APITruck>({
    items: trucks,
    selectedItemId: watchedTruckValue,
    displayKey: 'truckNumber',
  });

  const trackOptions = useMemo(() => {
    return availableTracks && availableTracks.length ? (
      availableTracks.map((option: { name: string }) => (
        <MenuItem
          key={option.name}
          value={option.name}
          hidden={option.name === watchedTrackValue}
        >
          {option.name}
        </MenuItem>
      ))
    ) : (
      <MenuEmptyText>{t('order.ticket.form.plantEmptyText')}</MenuEmptyText>
    );
  }, [availableTracks, watchedTrackValue, t]);

  useEffect(() => {
    loadTrucks();
    loadAvailableTracks();
    loadTracking();
  }, [loadTrucks, loadAvailableTracks, loadTracking]);

  useEffect(() => {
    setTrucksLocations(
      trackingItemsValues?.filter(it => it.truckId === watchedTruckValue),
    );
  }, [trackingItemsValues, watchedTruckValue]);

  useOrdersSockets(
    () => [
      {
        method: ON_TRUCK_LOCATION_UPDATE_EVENT,
        action: (
          providerId: string,
          truckId: string,
          result: APITrackingItem,
        ) => {
          setTrucksLocations(its =>
            its.map(it =>
              it.truckId == truckId
                ? {
                    ...it,
                    ...result,
                  }
                : it,
            ),
          );
        },
      },
    ],
    [],
  );

  const firstTruck = trucksLocations?.[0];

  const mapCenter = useMemo(() => {
    if (firstTruck) {
      return {
        lat: firstTruck.currentLocation.latitude,
        lng: firstTruck.currentLocation.longitude,
      };
    } else {
      return undefined;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstTruck?.truckNumber]);

  return (
    <ContainerWrapper>
      <Grid container height="100%" width="100%">
        <SettingsWrapper item xs={4}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <SettingsHeader variant="h5">
              {t('trackingEmulator.settings')}
            </SettingsHeader>
            <div style={{ marginBottom: '1.5rem' }}>
              <FormField
                key={TRUCK_FIELD}
                fieldName={TRUCK_FIELD}
                fieldError={errors[TRUCK_FIELD]}
                isRequired
                label={t('trackingEmulator.truck')}
                control={control}
                isLoading={loadDriversPending}
                selectOptions={trucksOptions}
              />
            </div>
            <div style={{ marginBottom: '1.5rem' }}>
              <FormField
                key={DELAY_FIELD}
                fieldName={DELAY_FIELD}
                fieldError={errors[DELAY_FIELD]}
                isRequired
                label={t('trackingEmulator.delay')}
                control={control}
                type="number"
              />
            </div>
            <div style={{ marginBottom: '1.5rem' }}>
              <FormField
                key={TRACK_FIELD}
                fieldName={TRACK_FIELD}
                fieldError={errors[TRACK_FIELD]}
                label={t('trackingEmulator.track')}
                control={control}
                isLoading={loadAvailableTracksPending}
                selectOptions={trackOptions}
              />
            </div>
            <div>
              <LoadingButton
                variant="outlined"
                color="secondary"
                type="submit"
                style={{ marginRight: '1rem' }}
                loading={loadAvailableTracksPending}
              >
                {t('trackingEmulator.emulate')}
              </LoadingButton>
              <LoadingButton
                color="primary"
                onClick={onCancel}
                disabled={!watchedTruckValue}
                loading={cancelTruckTrackingPending}
              >
                {t('trackingEmulator.cancel')}
              </LoadingButton>
            </div>
          </form>
          {selectedTruck && (
            <Grid style={{ marginTop: '3rem' }}>
              <Grid container>
                <DriverLabel>
                  {t('trackingEmulator.label.truckStatus')}:
                </DriverLabel>
                <TruckStatusLabel value={selectedTruck.truckStatus} />
              </Grid>
              <DriverLabel>
                {t('trackingEmulator.label.projectName')}:
                <DriverValue>{selectedTruck.projectName}</DriverValue>
              </DriverLabel>
              <DriverLabel>
                {t('trackingEmulator.label.plantName')}:
                <DriverValue>{selectedTruck.plantName}</DriverValue>
              </DriverLabel>
              <DriverLabel>
                {t('trackingEmulator.label.latitude')}:
                <DriverValue>
                  {selectedTruck.currentLocation?.latitude}
                </DriverValue>
              </DriverLabel>
              <DriverLabel>
                {t('trackingEmulator.label.longitude')}:
                <DriverValue>
                  {selectedTruck.currentLocation?.longitude}
                </DriverValue>
              </DriverLabel>
              <DriverLabel>
                {t('trackingEmulator.label.ticketId')}:
                <DriverValue>
                  {selectedTruck.ticketId} / {selectedTruck.ticketNumber}
                </DriverValue>
              </DriverLabel>
              <DriverLabel>
                {t('trackingEmulator.label.driverName')}:
                <DriverValue>
                  {selectedTruck.driverFirstName} {selectedTruck.driverLastName}
                </DriverValue>
              </DriverLabel>
            </Grid>
          )}
        </SettingsWrapper>
        <Grid item xs={8}>
          <TrackingMap
            defaultZoom={14}
            center={mapCenter}
            trucks={trucksLocations}
            isLoading={false}
          />
        </Grid>
      </Grid>
    </ContainerWrapper>
  );
};
