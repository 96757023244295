import React, { useCallback, useState } from 'react';

import styled, { useTheme } from 'styled-components';

import LinkIcon from '@mui/icons-material/Link';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import WarningIcon from '@mui/icons-material/Warning';

import { dayjs } from '@cd3p/core/third-party';
import { APIOrder, APIOrderStatus, APIUserType } from '@cd3p/core/types/api';
import { formatDisplayedDate } from '@cd3p/core/utils/common';
import { generateCoordinatesLink } from '@cd3p/core/utils/order';
import {
  AssignedDriversPopover,
  BatchedDeliveredValue,
  CanAccess,
  DispatcherCanAccess,
  IconButton,
  Link,
  OrderStatusLabel,
  ReadOnlyField,
  Stack,
  SvgIcon,
  Tooltip,
  Typography,
} from 'components';
import { useCopyCoordinates } from 'hooks/useCopyCoordinates';

import { useSelector, useTranslation } from 'third-party';

import { TOOLTIP_APPEAR_DELAY } from 'constants/common';

import { useOrder } from 'modules/order';

import {
  appSelectors,
  assignedDriversListSelectors,
  orderSelectors,
} from 'selectors';

import { OrderFormFields } from 'features/OrderView/orderViewHelpers';
import { SubscribeTeamMembersModal } from 'features/TeamSubscription/SubscribeTeamMembersModal';
import { SubscribedTeamMembersList } from 'features/TeamSubscription/SubscribedTeamMembersList';

import { formatTime, isOrderReadyToComplete } from 'utils/order';

const VolumeField = styled(ReadOnlyField)`
  display: inline-flex;
`;

const DeliveryLocationField = styled(ReadOnlyField)`
  margin-bottom: 0.6rem;
`;

const StyledOrderStatusLabel = styled(OrderStatusLabel)`
  font-size: 1.8rem;
`;

const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1.1rem;
`;

const StatusLabelWrapper = styled(Stack)`
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  margin-bottom: 1rem;
`;

const DeliveryPinDescription = styled(Stack)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 2.3rem;
`;

const PinCoordinates = styled(Typography)`
  color: ${props => props.theme.custom.palette.gray};
  font-size: 1.6rem;
  font-weight: 700;
`;

const DeliveryLocationText = styled(PinCoordinates)`
  font-weight: 600;
`;

const PinDescription = styled(Typography)`
  color: ${props => props.theme.custom.palette.gray};
  font-size: 1.2rem;
  font-weight: 600;
`;

const StyledLinkIcon = styled(LinkIcon)`
  transform: rotate(-45deg);
  color: ${props => props.theme.custom.palette.primary800};
`;

const AssignedDriverWrapper = styled.div`
  margin: -1.5rem 0 1rem -0.7rem;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SubscribedUsersRow = styled(Row)`
  margin-top: 0.6rem;
`;

const StyledSvgIcon = styled(SvgIcon)`
  margin-bottom: 1rem;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 2rem;
  margin-right: 0.3rem;
`;

const StyledWarningIcon = styled(WarningIcon)`
  color: #d2362e;
  margin-left: 0.5rem;
  margin-top: 0.1rem;
`;

const StyledReadOnlyField = styled(ReadOnlyField)`
  margin-left: 2.2rem;
  margin-top: 1rem;
`;

const StyledNotificationsActiveOutlinedIcon = styled(
  NotificationsActiveOutlinedIcon,
)`
  width: 1.7rem;
  height: 1.7rem;
`;

type Props = {
  order: APIOrder;
};

export const OrderDetailsInfo: React.FC<Props> = React.memo(({ order }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const { addOrderSubscriptions, loadOrder } = useOrder();
  const { assignedAndNotifiedList } = useSelector(
    assignedDriversListSelectors.assignedDrivers,
  );
  const isBatchSoftwareIntegrationEnabled = useSelector(
    appSelectors.isBatchSoftwareIntegrationEnabled,
  );
  const addOrderSubscriptionsPending = useSelector(
    orderSelectors.addOrderSubscriptionsPending,
  );
  const orderSubscribedUsers = order.subscribedUsers;

  const copyCoordinates = useCopyCoordinates();

  const [showSubscribeTeamModal, setShowSubscribeTeamModal] = useState(false);

  const handleSubscribeTeamClick = useCallback(() => {
    setShowSubscribeTeamModal(true);
  }, []);

  const handleSubscribeSuccess = useCallback(() => {
    loadOrder(order.id);
  }, [loadOrder, order.id]);

  const handleSubscribeTeam = useCallback(
    (membersIds: string[]) => addOrderSubscriptions(order.id, membersIds),
    [addOrderSubscriptions, order.id],
  );

  const handleDeliveryCoordinatesClick = useCallback(() => {
    const urlToRedirect = generateCoordinatesLink(
      order.longitude,
      order.latitude,
    );
    window.open(urlToRedirect, '_blank');
  }, [order.latitude, order.longitude]);

  return (
    <>
      {showSubscribeTeamModal && (
        <SubscribeTeamMembersModal
          title={t('subscribeTeamMembers.dialog.title')}
          description={t('subscribeTeamMembers.dialog.orderDescription')}
          onSubscribeSuccess={handleSubscribeSuccess}
          subscribeTeamMembers={handleSubscribeTeam}
          onClosePopup={() => setShowSubscribeTeamModal(false)}
          subscribedUsers={orderSubscribedUsers}
          isLoading={addOrderSubscriptionsPending}
        />
      )}
      <StatusWrapper>
        <DispatcherCanAccess>
          {order && isOrderReadyToComplete(order) && (
            <SvgIcon
              height="2.4rem"
              icon="ready-to-complete"
              width="1.5rem"
              color={theme.custom.palette.warning600}
            />
          )}
        </DispatcherCanAccess>
        <StatusLabelWrapper>
          <StyledOrderStatusLabel
            deliveryRate={order?.deliveryRate}
            actualDeliveryRate={order?.actualDeliveryRate}
            status={order?.orderStatus as APIOrderStatus}
            isChangesRequested={order?.isChangesRequested}
          />
          {order?.isCancellationSubmitted && <StyledWarningIcon />}
        </StatusLabelWrapper>
      </StatusWrapper>
      {order?.deliveryDate && (
        <Row>
          <IconWrapper>
            <StyledSvgIcon icon="clock" width="1.6rem" height="1.6rem" />
          </IconWrapper>
          <ReadOnlyField
            key={OrderFormFields.DeliveryDate}
            value={`${formatDisplayedDate(order.deliveryDate)} ${dayjs(
              order.deliveryDate,
            ).format('dddd')} (${formatTime(order.deliveryTime)})`}
            label={t('order.details.dateAndTimeLabel')}
          />
        </Row>
      )}
      <Row>
        <IconWrapper>
          <StyledSvgIcon icon="truckIcon" width="2rem" height="1.6rem" />
        </IconWrapper>
        <VolumeField
          key={OrderFormFields.Volume}
          value={
            <BatchedDeliveredValue
              showVolumeDiff={order?.orderStatus !== APIOrderStatus.Unconfirmed}
              volume={order?.volume}
              originalVolume={order?.originalVolume}
              batched={order?.batched}
              delivered={order?.delivered}
            />
          }
          label={t('order.sectionField.volumeQuantity')}
        />
      </Row>
      <Row>
        <IconWrapper>
          <StyledSvgIcon icon="pinOutlined" width="1.6rem" height="1.6rem" />
        </IconWrapper>
        <DeliveryLocationField
          key={OrderFormFields.DeliveryLocation}
          value={''}
          label={t('order.sectionField.deliveryLocation')}
        />
      </Row>
      <DeliveryPinDescription>
        <Stack>
          <DeliveryLocationText>{order?.deliveryLocation}</DeliveryLocationText>
          <PinCoordinates>
            {t('order.sectionField.deliveryLocationCoordinates')}
            <Link
              onClick={handleDeliveryCoordinatesClick}
            >{`${order?.latitude}, ${order?.longitude}`}</Link>
          </PinCoordinates>
          <PinDescription>
            {t('order.sectionField.deliveryLocationDescription')}
          </PinDescription>
        </Stack>
        {order && (
          <Tooltip
            placement="top-start"
            disableInteractive
            enterDelay={TOOLTIP_APPEAR_DELAY}
            title={t('copyPinLink.tooltip')}
          >
            <IconButton
              onClick={() => copyCoordinates(order.longitude, order.latitude)}
            >
              <StyledLinkIcon />
            </IconButton>
          </Tooltip>
        )}
      </DeliveryPinDescription>
      {isBatchSoftwareIntegrationEnabled && (
        <DispatcherCanAccess>
          <StyledReadOnlyField
            key={OrderFormFields.OrderNumber}
            value={
              order?.orderNumber
                ? order.orderNumber
                : t('common.entityNameUndefined')
            }
            label={t('order.sectionField.orderNumber')}
          />
        </DispatcherCanAccess>
      )}
      <CanAccess allowedUserType={APIUserType.Contractor}>
        <SubscribedUsersRow>
          <IconWrapper>
            <StyledNotificationsActiveOutlinedIcon color="primary" />
          </IconWrapper>
          <SubscribedTeamMembersList
            members={orderSubscribedUsers}
            onSubscribeClick={handleSubscribeTeamClick}
          />
        </SubscribedUsersRow>
      </CanAccess>
      <AssignedDriverWrapper>
        {assignedAndNotifiedList?.length > 0 && (
          <AssignedDriversPopover items={assignedAndNotifiedList} />
        )}
      </AssignedDriverWrapper>
    </>
  );
});

OrderDetailsInfo.displayName = 'OrderDetailsInfo';
