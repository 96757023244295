import React from 'react';

import { Trans as ReactI18NextTrans } from 'react-i18next';

type ReactI18NextTransProps = React.ComponentProps<typeof ReactI18NextTrans>;

type TransProps = Omit<ReactI18NextTransProps, 'i18nKey'> & { i18nKey: string };

export const Trans: React.FC<TransProps> = ({ i18nKey, ...restProps }) => {
  // @ts-ignore we do not need to provide the default value for Trans components and TS is throwing
  // error that we do not have this key in translation file. Suppress this error for now until we
  // figure out how to overcome this problem
  return <ReactI18NextTrans i18nKey={i18nKey} {...restProps} />;
};
