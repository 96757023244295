import React, { useCallback } from 'react';

import styled from 'styled-components';

import WarningIcon from '@mui/icons-material/Warning';

import { APIOrder } from '@cd3p/core/types/api';
import { prepareOrderForUpdating } from '@cd3p/core/utils/order';
import { LoadingButton, Stack, Typography } from 'components';
import { useHandleApiResult } from 'hooks/useHandleApiResult';

import { useSelector, useTranslation } from 'third-party';

import { useOrder } from 'modules/order';

import { orderSelectors } from 'selectors';

import { OrderContentWrapper } from 'features/OrderView/orderViewHelpers';

const UndoButton = styled(LoadingButton)`
  margin-top: 2rem;
  align-self: flex-start;
`;

const StyledOrderContentWrapper = styled(OrderContentWrapper)`
  border-color: ${props => props.theme.custom.palette.error800};
`;

const StyledWarningIcon = styled(WarningIcon)`
  color: #d2362e;
`;

const Title = styled(Typography)`
  color: ${props => props.theme.custom.palette.error500};
  text-transform: uppercase;
  font-size: 1.6rem;
  font-weight: 600;
`;

const Description = styled(Typography)`
  color: ${props => props.theme.custom.palette.gray};
  margin-top: 0.8rem;
  font-weight: 600;
`;

type Props = {
  order: APIOrder;
};

export const CancellationSubmittedOrder: React.FC<Props> = ({ order }) => {
  const { t } = useTranslation();

  const { updateOrder } = useOrder();

  const updateOrderPending = useSelector(orderSelectors.updateOrderPending);

  const handleApiResult = useHandleApiResult();

  const handleUndoCancelPress = useCallback(() => {
    handleApiResult(
      async () =>
        updateOrder(order.id, {
          ...prepareOrderForUpdating(order),
          isCancellationSubmitted: false,
        }),
      ({ showBaseToast }) => {
        showBaseToast(t('common.cancellationUndone'));
      },
    );
  }, [handleApiResult, order, t, updateOrder]);

  return (
    <StyledOrderContentWrapper>
      <Stack direction="row" gap="1rem">
        <StyledWarningIcon />
        <Stack>
          <Title>
            {t('order.details.contractor.unconfirmedCanceled.title')}
          </Title>
          <Description variant="body1">
            {t('order.details.contractor.unconfirmedCanceled.description')}
          </Description>
          <UndoButton
            color="secondary"
            variant="outlined"
            size="large"
            onClick={handleUndoCancelPress}
            disabled={updateOrderPending}
            loading={updateOrderPending}
          >
            {t('common.undoCancel')}
          </UndoButton>
        </Stack>
      </Stack>
    </StyledOrderContentWrapper>
  );
};
