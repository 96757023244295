import React from 'react';

import styled from 'styled-components';

import { APIMixTypeUsage } from '@cd3p/core/types/api';
import { Grid, Tooltip, Typography } from 'components';

const StyledHoverTooltipText = styled(Typography)`
  font-weight: 400;
  font-size: 1.4rem;
  color: ${props => props.theme.custom.palette.white};
`;

const StyledHoverTooltipLink = styled(StyledHoverTooltipText)`
  text-decoration: underline;
`;

type ContractorUsageTooltipProps = {
  assignmentCount: number;
  assignmentData?: APIMixTypeUsage[] | null;
  onOpen?: () => void;
  disabled?: boolean;
  isHidden?: boolean;
  children: any;
};

const VISIBLE_ITEMS_LENGTH = 4;

export const ContractorAssignmentHoverTooltip: React.FC<
  ContractorUsageTooltipProps
> = ({
  assignmentCount = 0,
  assignmentData,
  onOpen,
  disabled,
  isHidden,
  children,
}: ContractorUsageTooltipProps) => {
  const showHasMore = assignmentCount > VISIBLE_ITEMS_LENGTH;

  return (
    <Tooltip
      id="hover-tooltip"
      title={
        <Grid container direction={'column'}>
          {(showHasMore
            ? assignmentData?.slice(0, VISIBLE_ITEMS_LENGTH)
            : assignmentData
          )?.map(item => (
            <StyledHoverTooltipText key={item.id}>
              {item.name}
            </StyledHoverTooltipText>
          ))}
          {showHasMore && (
            <StyledHoverTooltipLink>{`+ ${
              assignmentCount - VISIBLE_ITEMS_LENGTH
            } more`}</StyledHoverTooltipLink>
          )}
        </Grid>
      }
      onOpen={onOpen}
      placement="bottom"
      enterDelay={500}
      disableInteractive
      disableHoverListener={disabled}
      disableFocusListener={disabled}
      componentsProps={{
        tooltip: {
          style: {
            visibility: isHidden || !assignmentCount ? 'hidden' : 'visible',
          },
        },
      }}
      arrow
    >
      {children}
    </Tooltip>
  );
};
