import React from 'react';

import styled from 'styled-components';

import { Typography } from 'components';

import { useTranslation } from 'third-party';

const Value = styled.span`
  display: inline-block;
  border: 1px solid ${props => props.theme.custom.palette.secondary500};
  border-radius: 0.7rem;
  padding: 0 0.8rem;
  height: 2rem;
`;

type TruckNumberTagProps = {
  value?: string | null;
  className?: string;
};
export const TruckNumberTag = ({ value, className }: TruckNumberTagProps) => {
  const { t } = useTranslation();
  const displayedValue = value || t('common.entityNameUndefined');

  return (
    <Value className={className}>
      <Typography variant="subtitle2">{displayedValue}</Typography>
    </Value>
  );
};
