import { useCallback } from 'react';

import { APIAccessLevel } from '@cd3p/core/types/api';

import { useSelector } from 'third-party';

import { appSelectors } from 'selectors';

export const useUserAccessLevelIn = () => {
  const userAccessLevel = useSelector(appSelectors.accessLevel);

  return useCallback(
    (accessLevels: APIAccessLevel | APIAccessLevel[]) =>
      !!userAccessLevel &&
      (Array.isArray(accessLevels) ? accessLevels : [accessLevels]).includes(
        userAccessLevel,
      ),

    [userAccessLevel],
  );
};
