import React, { useMemo } from 'react';

import { APIProjectTypeaheadRequestModel } from '@cd3p/core/types/api';
import { Optional } from '@cd3p/core/types/utils';
import { Props as ReactSelectProps } from 'react-select';

import { useTranslation } from 'third-party';

import { FIELD_MAX_LENGTH } from 'constants/common';

import { useCache } from 'modules/cache';

import {
  CommonTypeaheadField,
  CommonTypeaheadFieldProps,
} from 'features/Fields/CommonTypeaheadField';

type Props<FormPropsT extends Record<string, any>> = {
  companyId?: string;
} & Optional<
  CommonTypeaheadFieldProps<FormPropsT>,
  | 'placeholder'
  | 'label'
  | 'notFoundText'
  | 'requiredErrorMessage'
  | 'loadOptions'
> &
  ReactSelectProps;

export const ProjectField = <FormPropsT extends Record<string, any>>({
  fieldName,
  label,
  placeholder,
  notFoundText,
  requiredErrorMessage,
  maxLength = FIELD_MAX_LENGTH,
  companyId,
  ...props
}: Props<FormPropsT>) => {
  const { t } = useTranslation();

  const { loadProjects } = useCache();

  const loadProjectsCallback = useMemo(() => {
    if (companyId) {
      return (query: string, body?: Partial<APIProjectTypeaheadRequestModel>) =>
        loadProjects(query, { ...body, companyId });
    } else {
      return loadProjects;
    }
  }, [companyId, loadProjects]);

  return (
    <CommonTypeaheadField
      fieldName={fieldName}
      label={label || t('common.form.project.label')}
      requiredErrorMessage={
        requiredErrorMessage || t('common.form.emptyFieldError')
      }
      placeholder={placeholder || t('common.form.project.placeholder')}
      notFoundText={notFoundText || t('common.form.project.notFoundText')}
      loadOptions={loadProjectsCallback}
      maxLength={maxLength}
      {...props}
    />
  );
};
