import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import styled, { useTheme } from 'styled-components';

import { useAutoHideNotification } from '@cd3p/core/hooks/useAutoHideNotification';
import { Notification } from '@cd3p/core/modules/notifications';
import { renderLimitedNumberOfNotifications } from '@cd3p/core/utils/notifications';
import { Alert, AlertTitle, CSSTransition } from 'components';

import { useSelector } from 'third-party';

import { useApp } from 'modules/app';
import { useNotifications } from 'modules/notifications';
import { useStorage } from 'modules/storage';

import { appSelectors, notificationsSelectors } from 'selectors';

import { isTitleString } from 'utils/app';
import { showPushNotification } from 'utils/pushNotification';

const ANIMATION_DURATION = 500;

const Wrapper = styled.div`
  position: absolute;
  top: ${props => props.theme.custom.dimensions.notificationPadding}rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  z-index: 1500;
  .item-enter {
    opacity: 0;
  }
  .item-enter-active {
    opacity: 1;
    transition: opacity ${ANIMATION_DURATION}ms ease-in;
  }
  .item-exit {
    opacity: 1;
  }
  .item-exit-active {
    opacity: 0;
    transition-delay: 0ms, 250ms;
    transition-duration: ${ANIMATION_DURATION}ms, 250ms;
    transition-property: opacity, max-height;
    transition-timing-function: ease-in;
    background-color: transparent;
    max-height: 0;
    overflow: hidden;
  }
`;

const StyledAlert = styled(Alert)<{
  showpointer?: string;
  background?: string;
}>`
  margin-bottom: 2.5rem;
  max-width: 80rem;
  max-height: 30rem;
  justify-self: center;
  background-color: ${props => (props.background ? props.background : 'unset')};
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
  cursor: ${props => (props.showpointer === 'true' ? 'pointer' : 'default')};
  strong {
    font-weight: bold;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

type AnimatedNotificationProps = {
  notification: Notification;
  onClose: () => void;
  onClick?: (closeNotification: () => void) => void;
};

const AnimatedNotification: React.FC<AnimatedNotificationProps> = ({
  notification,
  onClose,
  onClick,
}) => {
  const { removeNotification } = useNotifications();
  const { updateUserSettingInStorage } = useStorage();
  const { setSelectedProvider } = useApp();
  const providerId = useSelector(appSelectors.providerId);
  const theme = useTheme();

  const [visible, setVisible] = useState(false);

  const notificationColor = notification.severity
    ? notification.severity
    : theme.custom.palette.white;

  useEffect(() => {
    setVisible(true);
  }, []);

  const onCloseCallback = useCallback(() => {
    setVisible(false);
  }, []);

  const closeNotification = () => {
    removeNotification(notification.id);
  };

  const closeNotificationWithCallback =
    (callback: (notification?: Notification) => void) => () => {
      callback(notification);
      removeNotification(notification.id);
    };

  const handNotificationClick = () => {
    if (notification.providerId && providerId != notification.providerId) {
      updateUserSettingInStorage({
        currentProviderId: notification.providerId,
      });
      setSelectedProvider(notification.providerId).then(() =>
        onClick?.(closeNotification),
      );
    }

    onClick?.(closeNotification);
  };

  useAutoHideNotification(notification, onCloseCallback);

  return (
    <CSSTransition
      in={visible}
      timeout={ANIMATION_DURATION}
      classNames="item"
      onExited={() => onClose()}
      unmountOnExit
    >
      <StyledAlert
        severity={notification.severity}
        onClose={(event: SyntheticEvent) => {
          event.stopPropagation();
          onCloseCallback();
        }}
        background={notificationColor}
        onClick={handNotificationClick}
        showpointer={onClick ? 'true' : 'false'}
      >
        <div>
          {isTitleString(notification.title) ? (
            <AlertTitle style={{ fontWeight: 600, textTransform: 'uppercase' }}>
              {notification.title}
            </AlertTitle>
          ) : (
            notification.title
          )}
        </div>
        <div>
          {typeof notification.message === 'function'
            ? notification.message({
                close: closeNotification,
                closeWithCallback: closeNotificationWithCallback,
              })
            : notification.message}
        </div>
      </StyledAlert>
    </CSSTransition>
  );
};

export const Notifications = () => {
  const { removeNotification } = useNotifications();
  const notificationItems = useSelector(
    notificationsSelectors.notificationItems,
  );

  const renderedNotifications = useMemo(() => {
    return renderLimitedNumberOfNotifications(
      notificationItems,
      notification => {
        if (notification.sound && typeof notification.title === 'string') {
          showPushNotification(notification.title, notification.onClick);
        }
        return (
          <AnimatedNotification
            key={notification.id}
            notification={notification}
            onClose={() => removeNotification(notification.id)}
            onClick={notification.onClick}
          />
        );
      },
    );
  }, [notificationItems, removeNotification]);

  return <Wrapper>{renderedNotifications}</Wrapper>;
};
