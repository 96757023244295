import { useCallback, useMemo, useRef } from 'react';

// TODO: find out the right type that satisfy the `string` and `Location` at
// the same time
export type LocationT = any;

type CallbackFuncT = (url: LocationT) => boolean | void;

type RelativeRoutingType = 'route' | 'path';
type NavigateOptions = {
  replace?: boolean;
  state?: any;
  preventScrollReset?: boolean;
  relative?: RelativeRoutingType;
};

type RemoveLocationChangeConfirmationT = (callback: CallbackFuncT) => void;

export type LocationChangeConfirmationT = {
  locationChangeConfirmation: (
    url: LocationT,
    options: NavigateOptions,
  ) => boolean;

  addLocationChangeConfirmation: (callback: CallbackFuncT) => any; // TODO: find out the right type
  removeLocationChangeConfirmation: RemoveLocationChangeConfirmationT;
  continueChangeLocation: () => boolean;
};

export const useLocationChangeConfirmation = (
  navigate: (url: LocationT, options?: NavigateOptions) => void,
) => {
  // `confirmationCallbacks` and `navigateTo` are refs because we don't need re-renders
  // once they are changed
  const confirmationCallbacks = useRef<CallbackFuncT[]>([]);
  const navigateTo = useRef<null | {
    url: LocationT;
    options: NavigateOptions;
  }>(null);

  const removeLocationChangeConfirmation = useCallback(
    (callback: CallbackFuncT) => {
      confirmationCallbacks.current = confirmationCallbacks.current.reduce<
        CallbackFuncT[]
      >((arr, it) => {
        return it === callback ? arr : [...arr, it];
      }, []);
    },
    [],
  );

  const addLocationChangeConfirmation = useCallback(
    (callback: CallbackFuncT) => {
      confirmationCallbacks.current = [
        ...confirmationCallbacks.current,
        callback,
      ];
      return () => removeLocationChangeConfirmation(callback);
    },
    [removeLocationChangeConfirmation],
  );

  // this method runs through all the registered callbacks
  // and if any returns `false` saves url to redirect to
  // and stops navigation returning `false` for outside `useNavigation` wrapper
  const locationChangeConfirmation = useCallback(
    (url: string, options: NavigateOptions) => {
      let result = true;
      for (const callback of confirmationCallbacks.current) {
        if (callback(url) === false) {
          navigateTo.current = { url, options };
          result = false;
          break;
        }
      }
      return result;
    },
    [],
  );

  const continueChangeLocation = useCallback(() => {
    // clear all callbacks
    confirmationCallbacks.current = [];

    if (navigateTo.current) {
      // do navigation
      navigate(navigateTo.current.url, navigateTo.current.options);
      // clear saved url
      navigateTo.current = null;
      return true;
    } else {
      return false;
    }
    // ignoring navigate
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return useMemo<LocationChangeConfirmationT>(
    () => ({
      continueChangeLocation,
      addLocationChangeConfirmation,
      removeLocationChangeConfirmation,
      locationChangeConfirmation,
    }),
    [
      continueChangeLocation,
      addLocationChangeConfirmation,
      locationChangeConfirmation,
      removeLocationChangeConfirmation,
    ],
  );
};
