import React, { useCallback, useMemo, useState } from 'react';

import styled, { css } from 'styled-components';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import { APIMasterMixType } from '@cd3p/core/types/api';
import { getSortOrder } from '@cd3p/core/utils/common';
import { IconButton, Stack, Table, TableCell, Typography } from 'components';

import { useSelector, useTranslation } from 'third-party';

import {
  MixTypesFilters,
  MixTypesListColumnId,
  MixTypesSorting,
  useMixTypesList,
} from 'modules/mixTypesList';

import { mixTypesSelectors } from 'selectors';

import { StyledTable } from 'components/Table/Table';

import { TableCellLink } from 'features/Contractors/Tabs/tabHelpers';
import { ContractorAssignmentHoverTooltip } from 'features/PlantSettings/common/ContractorAssignmentHoverTooltip';
import { ContractorAssignmentListPopup } from 'features/PlantSettings/common/ContractorAssignmentListPopup';

const TableCellText = styled(Typography)`
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 1.5;
  color: ${props => props.theme.custom.palette.gray};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const StyledTableBodyCell = styled(TableCell)<{
  height: string;
  cellstyles: string;
}>`
  position: relative;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 0;
  border-bottom: 1px solid ${props => props.theme.custom.palette.truegray300};
  height: ${props => props.height};
  padding: 0 1.6rem;
  &:not(:last-child):after {
    position: absolute;
    top: 1rem;
    right: 0;
    bottom: 0;
    border-right: 1px solid ${props => props.theme.custom.palette.truegray300};
    width: 1px;
    content: '';
  }
  ${props => props.cellstyles};
`;

const ActionButtonsCellStyles = css`
  padding: 0 0.8rem;
`;

const StyledTableColumnLabel = styled(Typography)`
  font-weight: 900;
  font-size: 2rem;
  text-transform: uppercase;
  color: ${props => props.theme.custom.palette.primary900};
`;

const StyledTableComponent = styled(StyledTable)`
  margin-bottom: 1.2rem;
`;

const tableComponents = {
  TableBodyCell: StyledTableBodyCell,
  TableColumnLabel: StyledTableColumnLabel,
  Table: StyledTableComponent,
};

type Props = {
  fetchMixTypes: (
    params?: {
      searchSortOrders?: MixTypesSorting;
    } & Partial<MixTypesFilters>,
  ) => void;
  setMixTypeIdForDelete: (itemId: number) => void;
  setMixTypeIdForEdit: (itemId: number) => void;
};

export const MixTypesTable: React.FC<Props> = ({
  fetchMixTypes,
  setMixTypeIdForDelete,
  setMixTypeIdForEdit,
}) => {
  const { t } = useTranslation();

  const {
    loadMoreMixTypes,
    updateMixTypesListSorting,
    resetMixTypes,
    loadMixTypesAssignment,
    resetMixTypeAssignment,
  } = useMixTypesList();

  const [showAssignmentPopup, setShowAssignmentPopup] =
    useState<boolean>(false);
  const [selectedMixType, setSelectedMixType] =
    useState<null | APIMasterMixType>(null);

  const mixTypesListItems = useSelector(mixTypesSelectors.mixTypesListItems);
  const mixTypesListCount = useSelector(mixTypesSelectors.mixTypesListCount);
  const loadMoreMixTypesPending = useSelector(
    mixTypesSelectors.loadMoreMixTypesPending,
  );
  const filters = useSelector(mixTypesSelectors.mixTypesListFilters);
  const sorting = useSelector(mixTypesSelectors.mixTypesListSorting);
  const { sortField, sortOrder } = sorting[0];
  const loadMixTypesPending = useSelector(
    mixTypesSelectors.loadMixTypesPending,
  );
  const mixTypeAssignment = useSelector(mixTypesSelectors.mixTypeAssignment);
  const mixTypeAssignmentCount = useSelector(
    mixTypesSelectors.mixTypeAssignmentCount,
  );
  const loadMixTypesAssignmentPending = useSelector(
    mixTypesSelectors.loadMixTypesAssignmentPending,
  );
  const mixTypeAssignmentId = useSelector(
    mixTypesSelectors.mixTypeAssignmentId,
  );
  const mixTypesLoaded = useSelector(mixTypesSelectors.mixTypesLoaded);

  const onColumnHeaderClicked = useCallback(
    (columnId: MixTypesListColumnId) => {
      const searchSortOrders = [
        {
          sortField: columnId,
          sortOrder: getSortOrder(columnId, sortField, sortOrder),
        },
      ];
      resetMixTypes();
      fetchMixTypes({ searchSortOrders });
      updateMixTypesListSorting(searchSortOrders);
    },
    [
      sortField,
      resetMixTypes,
      sortOrder,
      updateMixTypesListSorting,
      fetchMixTypes,
    ],
  );

  const onAssignmentElementClick = useCallback((mixType: APIMasterMixType) => {
    if (mixType.assignment > 0) {
      setSelectedMixType(mixType);
      setShowAssignmentPopup(true);
    }
  }, []);

  const onMixtypeAssignmentPopupOpen = useCallback(() => {
    if (selectedMixType?.id && selectedMixType?.id !== mixTypeAssignmentId) {
      loadMixTypesAssignment(selectedMixType?.id);
    }
  }, [loadMixTypesAssignment, mixTypeAssignmentId, selectedMixType?.id]);

  const columns = useMemo(
    () => [
      {
        id: 'name',
        dataId: 'name' as const,
        label: t('mixTypes.table.nameColumnLabel'),
        formatter: (value: APIMasterMixType['name']) => ({
          value: value || '',
          element: <TableCellText>{value || ''}</TableCellText>,
        }),
      },
      {
        id: 'categories',
        dataId: 'categories' as const,
        sortable: false,
        label: t('mixTypes.table.categoryColumnLabel'),
        formatter: (value: APIMasterMixType['categories']) => {
          const displayValue = value?.map(it => it.name).join(', ');
          return {
            value: displayValue,
            element: (
              <Stack direction="row">
                <TableCellText>{displayValue}</TableCellText>
              </Stack>
            ),
          };
        },
      },
      {
        id: 'usage',
        dataId: 'usage' as const,
        width: '16%',
        label: t('mixTypes.table.usageColumnLabel'),
        formatter: (value: APIMasterMixType['usage']) => ({
          value: value || '',
          element: (
            <TableCellLink disabled>
              {t('mixTypes.table.usageValue', {
                count: value || 0,
              })}
            </TableCellLink>
          ),
        }),
      },
      {
        id: 'assignment',
        dataId: 'assignment' as const,
        width: '16%',
        label: t('mixTypes.table.assignmentColumnLabel'),
        formatter: (
          value: APIMasterMixType['assignment'],
          mixType: APIMasterMixType,
        ) => {
          const displayValue = t('mixTypes.table.assignmentValue', {
            count: value || 0,
          });
          return {
            value: displayValue,
            element: (
              <span>
                <ContractorAssignmentHoverTooltip
                  assignmentCount={mixTypeAssignmentCount}
                  assignmentData={mixTypeAssignment}
                  onOpen={() => {
                    if (
                      !showAssignmentPopup &&
                      mixTypeAssignmentId !== mixType.id
                    ) {
                      loadMixTypesAssignment(mixType.id);
                    }
                  }}
                  disabled={!value}
                  isHidden={mixTypeAssignmentId !== mixType.id}
                >
                  <TableCellLink
                    disabled={!mixType.assignment}
                    onClick={() => onAssignmentElementClick(mixType)}
                  >
                    {displayValue}
                  </TableCellLink>
                </ContractorAssignmentHoverTooltip>
              </span>
            ),
          };
        },
      },
      {
        id: 'action-buttons',
        width: '11.2rem',
        cellStyles: ActionButtonsCellStyles,
        formatter: (value: string, mixType: APIMasterMixType) => {
          return {
            value: '',
            element: (
              <span>
                <IconButton onClick={() => setMixTypeIdForEdit(mixType.id)}>
                  <EditOutlinedIcon color="primary" />
                </IconButton>

                <IconButton onClick={() => setMixTypeIdForDelete(mixType.id)}>
                  <DeleteOutlineIcon color="primary" />
                </IconButton>
              </span>
            ),
          };
        },
      },
    ],
    [
      loadMixTypesAssignment,
      mixTypeAssignment,
      mixTypeAssignmentCount,
      mixTypeAssignmentId,
      onAssignmentElementClick,
      setMixTypeIdForDelete,
      setMixTypeIdForEdit,
      showAssignmentPopup,
      t,
    ],
  );

  return (
    <>
      {showAssignmentPopup && selectedMixType && (
        <ContractorAssignmentListPopup
          title={String(selectedMixType.name)}
          assignmentCount={mixTypeAssignmentCount}
          assignmentData={mixTypeAssignment}
          anchorElement={document.body}
          onClose={() => {
            setShowAssignmentPopup(false);
            setSelectedMixType(null);
            resetMixTypeAssignment();
          }}
          onOpen={onMixtypeAssignmentPopupOpen}
          isLoading={loadMixTypesAssignmentPending}
        />
      )}
      <Table
        infiniteScroll
        tableMinHeight="20rem"
        components={tableComponents}
        columns={columns}
        items={mixTypesListItems}
        itemsTotalCount={mixTypesListCount}
        itemsLoaded={mixTypesLoaded}
        loadItemsPending={loadMixTypesPending}
        loadMoreItemsPending={loadMoreMixTypesPending}
        onLoadMoreClicked={loadMoreMixTypes}
        sortField={sortField}
        sortOrder={sortOrder}
        onColumnHeaderClicked={onColumnHeaderClicked}
        emptyText={
          filters?.name
            ? t('mixTypes.table.emptySearch')
            : t('mixTypes.table.emptyText')
        }
        loadMoreButtonText={t('mixTypes.table.loadMoreButtonText')}
      />
    </>
  );
};
