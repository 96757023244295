import { useCallback } from 'react';

import { useCache } from 'modules/cache';

import { preparePinLocation } from 'features/OrderView/orderViewHelpers';

export const useAddressToCoordinates = () => {
  const { loadAddressLocation } = useCache();

  return useCallback(
    async (address: string) => {
      if (address) {
        const result = await loadAddressLocation(address);
        if (!result.error) {
          return preparePinLocation(result);
        }
      } else {
        return null;
      }
    },
    [loadAddressLocation],
  );
};
