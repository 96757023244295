import React, { CSSProperties } from 'react';

import styled from 'styled-components';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

import { APIOrderStatus } from '@cd3p/core/types/api';
import { DeliveryRateIcon, SvgIcon, Typography } from 'components';

import {
  orderStatusesMapping,
  shouldShowActualDeliveryRate,
} from 'utils/order';

import { themeConfig } from 'styles/theme';

const StyledOrderStatusLabel = styled(Typography)`
  font-weight: 600;
  font-size: 1.6rem;
  text-transform: uppercase;
  display: flex;
  align-items: center;
`;

const RequestedChangesIcon = styled(SvgIcon)`
  margin-left: 0.5rem;
`;

interface Props {
  deliveryRate?: number;
  actualDeliveryRate?: number | null;
  status: APIOrderStatus;
  styles?: CSSProperties;
  className?: string;
  isChangesRequested?: boolean;
  isHighlighted?: boolean;
}

export const OrderStatusLabel: React.FC<Props> = React.forwardRef(
  // suppress warning about unused argument ref because we need to forward ref
  // to prevent warning from MUI about no-ref element in tooltip and from React about
  // missing the second argument in forwardRef function
  (
    {
      status = '',
      deliveryRate = 0,
      actualDeliveryRate = 0,
      styles,
      className,
      isChangesRequested,
      isHighlighted,
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ref,
  ) => {
    const showActualDeliveryRate = shouldShowActualDeliveryRate(
      deliveryRate,
      actualDeliveryRate,
    );
    const statusLabelColor =
      orderStatusesMapping[status as APIOrderStatus]?.color;
    const visibleStatus = orderStatusesMapping[status as APIOrderStatus]?.name;
    const showOrderChangesRequestedLabel =
      isChangesRequested &&
      [
        APIOrderStatus.Confirmed,
        APIOrderStatus.Unconfirmed,
        APIOrderStatus.Delivering,
      ].includes(status as APIOrderStatus);

    return (
      <StyledOrderStatusLabel
        sx={{ color: statusLabelColor, ...styles }}
        className={className}
      >
        {isHighlighted ? (
          <em
            style={{ backgroundColor: themeConfig.custom.palette.searchResult }}
          >
            {visibleStatus}
          </em>
        ) : (
          visibleStatus
        )}
        {status === APIOrderStatus.Unconfirmed && (
          <ErrorIcon sx={{ fontSize: '2rem', marginLeft: '0.8rem' }} />
        )}
        {[APIOrderStatus.Requested].includes(status as APIOrderStatus) && (
          <CheckCircleIcon sx={{ fontSize: '2rem', marginLeft: '0.8rem' }} />
        )}
        {status === APIOrderStatus.Declined && (
          <CancelIcon sx={{ fontSize: '2rem', marginLeft: '0.8rem' }} />
        )}
        {showOrderChangesRequestedLabel && (
          <RequestedChangesIcon
            icon="requestedChangesPending"
            width="2.2rem"
            height="2.2rem"
          />
        )}
        {showActualDeliveryRate && (
          <DeliveryRateIcon
            deliveryRate={deliveryRate}
            actualDeliveryRate={actualDeliveryRate}
          />
        )}
      </StyledOrderStatusLabel>
    );
  },
);
