import React, { ReactElement, useMemo } from 'react';

import {
  APICallBackFormStatus,
  APIOrder,
  APIOrderStatus,
} from '@cd3p/core/types/api';

import { ScrollableContentContainer } from '../orderViewHelpers';
import { DeclinedOrder } from './DeclinedOrder';
import { OrderCallback } from './OrderCallback';

import { CanceledOrder } from 'features/OrderView/ContractorRightView/CanceledOrder';
import { CancellationSubmittedOrder } from 'features/OrderView/ContractorRightView/CancellationSubmittedOrder';
import { RequestedChangesOrder } from 'features/OrderView/ContractorRightView/RequestedChangesOrder';
import { UnconfirmedOrder } from 'features/OrderView/ContractorRightView/UnconfirmedOrder';

type Props = {
  order: APIOrder;
  onSetConfirmUponApprovalMode: () => void;
};

export const ContractorRightView: React.FC<Props> = ({
  order,
  onSetConfirmUponApprovalMode,
}) => {
  const showCallbackAction = [
    APICallBackFormStatus.CallbackPending,
    APICallBackFormStatus.CallbackSubmitted,
  ].includes(order.callBackFormStatus);

  const content = useMemo(() => {
    const forms: ReactElement[] = [];

    switch (order.orderStatus) {
      case APIOrderStatus.Unconfirmed:
        if (order.isCancellationSubmitted) {
          forms.push(
            <CancellationSubmittedOrder
              order={order}
              key="CancellationSubmittedOrder"
            />,
          );
        } else if (order.isChangesRequested) {
          forms.push(
            <RequestedChangesOrder order={order} key="RequestedChangesOrder" />,
          );
        } else {
          forms.push(
            <UnconfirmedOrder
              key="UnconfirmedOrder"
              onSetConfirmUponApprovalMode={onSetConfirmUponApprovalMode}
              order={order}
            />,
          );
        }
        break;
      case APIOrderStatus.Confirmed:
        order.isChangesRequested &&
          forms.push(
            <RequestedChangesOrder order={order} key="RequestedChangesOrder" />,
          );
        break;
      case APIOrderStatus.Delivering:
        order.isChangesRequested &&
          forms.push(
            <RequestedChangesOrder order={order} key="RequestedChangesOrder" />,
          );
        showCallbackAction &&
          forms.push(<OrderCallback order={order} key="OrderCallback" />);
        break;
      case APIOrderStatus.Cancelled:
        forms.push(
          <CanceledOrder
            customerName={order.company.name}
            key="CanceledOrder"
          />,
        );
        break;
      case APIOrderStatus.Declined:
        forms.push(
          <DeclinedOrder
            orderName={order.orderName}
            deliveryDate={order.deliveryDate}
            key="DeclinedOrder"
          />,
        );
        break;
      default:
        break;
    }
    return forms;
  }, [onSetConfirmUponApprovalMode, order, showCallbackAction]);

  return <ScrollableContentContainer>{content}</ScrollableContentContainer>;
};
