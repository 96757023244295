import { useMemo } from 'react';

import { httpMethod, simplifyBuilder } from '@cd3p/core';
import {
  APICompany,
  APICompanyModelSearchResult,
  APICompanyParams,
  APICompanyRequest,
  APICompanySearchFilter,
  APISortOrder,
  APIUserType,
} from '@cd3p/core/types/api';
import {
  DEFAULT_PAGE_SIZE,
  getPageNumberAfterLoadMore,
  getPageSizeDependingOnItems,
} from '@cd3p/core/utils/lists';

import { _, bindActionCreators, useDispatch, useSelector } from 'third-party';

import { companyEndpoint, customersEndpoint } from 'constants/endpoints';

import { State } from 'modules/index';

import { appSelectors } from 'selectors';

const defaultContractorsListFilters: {
  name: string;
  includeDeactivated?: boolean;
} = {
  name: '',
  includeDeactivated: false,
};

export type ContractorsListFilters = typeof defaultContractorsListFilters;

export type ContractorsListColumnId = keyof APICompany;

export type ContractorsListSorting = {
  sortField: ContractorsListColumnId;
  sortOrder: APISortOrder;
}[];

export type ContractorsListState = {
  items: APICompanyModelSearchResult['result'];
  contractorsLoaded: boolean;
  loadContractorsPending: boolean;
  loadMoreContractorsPending: boolean;
  getContractorByIdPending: boolean;
  sorting: ContractorsListSorting;
  pagination: {
    pageNumber: APICompanyModelSearchResult['pageNumber'];
    pageSize: APICompanyModelSearchResult['pageSize'];
  };
  filters: ContractorsListFilters;
  count: number;
  createContractorPending: boolean;
  updateContractorPending: boolean;
  deleteContractorPending: boolean;
};

export const contractorsListState: ContractorsListState = {
  items: [],
  sorting: [
    {
      sortField: 'isDeactivated',
      sortOrder: APISortOrder.ASC,
    },
    {
      sortField: 'name',
      sortOrder: APISortOrder.ASC,
    },
  ],
  contractorsLoaded: false,
  loadMoreContractorsPending: false,
  loadContractorsPending: false,
  getContractorByIdPending: false,
  pagination: { pageNumber: 1, pageSize: DEFAULT_PAGE_SIZE },
  filters: defaultContractorsListFilters,
  count: 0,
  createContractorPending: false,
  updateContractorPending: false,
  deleteContractorPending: false,
};

const builder = simplifyBuilder(contractorsListState, {});

const loadContractors = builder.createServerAction(
  (providerId: string, body: Partial<APICompanySearchFilter> = {}) => ({
    name: 'loadContractors',
    url: customersEndpoint(providerId),
    body: (state: State) => ({
      companyType: APIUserType.Contractor,
      pageSize: state.contractorsList.pagination.pageSize,
      pageNumber: state.contractorsList.pagination.pageNumber,
      searchSortOrders: state.contractorsList.sorting,
      ...state.contractorsList.filters,
      ...body,
    }),
    method: httpMethod.post,
    onSuccess: (
      state: ContractorsListState,
      payload: APICompanyModelSearchResult,
    ) => {
      return {
        items: payload.result || [],
        contractorsLoaded: true,
        pagination: {
          pageNumber: payload.pageNumber,
          pageSize: payload.pageSize,
        },
        count: payload.count,
      };
    },
  }),
);

const loadMoreContractors = builder.createServerAction(
  (providerId: string) => ({
    name: 'loadMoreContractors',
    url: customersEndpoint(providerId),
    body: (state: State) => ({
      companyType: APIUserType.Contractor,
      pageNumber: getPageNumberAfterLoadMore(
        state.contractorsList.items,
        state.contractorsList.pagination.pageSize,
      ),
      pageSize: state.contractorsList.pagination.pageSize,
      searchSortOrders: state.contractorsList.sorting,
      ...state.contractorsList.filters,
    }),
    method: httpMethod.post,
    onSuccess: (
      state: ContractorsListState,
      payload: APICompanyModelSearchResult,
    ) => {
      return {
        items: _.uniqBy([...state.items, ...payload.result], 'id'),
      };
    },
  }),
);

const updateContractors = builder.createServerAction((providerId: string) => ({
  name: 'updateContractors',
  url: customersEndpoint(providerId),
  body: (state: State) => ({
    companyType: APIUserType.Contractor,
    pageNumber: 1,
    pageSize: getPageSizeDependingOnItems(state.contractorsList.items),
    searchSortOrders: state.contractorsList.sorting,
    ...state.contractorsList.filters,
  }),
  method: httpMethod.post,
  onSuccess: (
    state: ContractorsListState,
    payload: APICompanyModelSearchResult,
  ) => {
    return {
      items: payload.result,
      count: payload.count,
    };
  },
}));

const getContractorById = builder.createServerAction(
  (providerId: string, companyId: string) => ({
    name: 'getContractorById',
    url: companyEndpoint(providerId, companyId),
    method: httpMethod.get,
  }),
);

const loadContractorById = builder.createServerAction(
  (providerId: string, companyId: string) => ({
    name: 'loadContractorById',
    url: companyEndpoint(providerId, companyId),
    method: httpMethod.get,
    onSuccess: (state: ContractorsListState, payload: APICompany) => ({
      items: state.items.map((item: APICompany) =>
        item.id === companyId ? payload : item,
      ),
    }),
  }),
);

const updateContractor = builder.createServerAction(
  (providerId: string, companyId: string, body: APICompanyParams) => ({
    name: 'updateContractor',
    url: companyEndpoint(providerId, companyId),
    body,
    method: httpMethod.put,
    onSuccess: (state: ContractorsListState, payload: APICompany) => ({
      items: state.items.map((item: APICompany) =>
        item.id === companyId ? payload : item,
      ),
    }),
  }),
);

const deleteContractor = builder.createServerAction(
  (providerId: string, companyId: string) => ({
    name: 'deleteContractor',
    url: companyEndpoint(providerId, companyId),
    method: httpMethod.delete,
    onSuccess: (state: ContractorsListState) => ({
      items: state.items.filter((item: APICompany) => item.id !== companyId),
      count: state.count - 1,
    }),
  }),
);

const updateContractorsListSorting = builder.createReduxAction(
  (newSorting: ContractorsListState['sorting']) => ({
    name: 'updateContractorsListSorting',
    updater: () => ({
      sorting: newSorting,
    }),
  }),
);

const updateContractorsListFilters = builder.createReduxAction(
  (newFilters: ContractorsListState['filters']) => ({
    name: 'updateContractorsListFilters',
    updater: state => ({
      filters: {
        ...state.filters,
        ...newFilters,
      },
    }),
  }),
);

const resetContractorsList = builder.createReduxAction(() => ({
  name: 'resetContractorsList',
  updater: () => contractorsListState,
}));

const createContractor = builder.createServerAction(
  (providerId: string, body: APICompanyRequest) => ({
    name: 'createContractor',
    body,
    url: companyEndpoint(providerId),
    method: httpMethod.post,
  }),
);

export const useContractorsList = () => {
  const dispatch = useDispatch();
  const providerId = useSelector(appSelectors.providerId);

  return useMemo(
    () =>
      bindActionCreators(
        {
          createContractor: createContractor.bind(null, providerId),
          loadContractors: loadContractors.bind(null, providerId),
          loadMoreContractors: loadMoreContractors.bind(null, providerId),
          updateContractors: updateContractors.bind(null, providerId),
          updateContractor: updateContractor.bind(null, providerId),
          deleteContractor: deleteContractor.bind(null, providerId),
          getContractorById: getContractorById.bind(null, providerId),
          loadContractorById: loadContractorById.bind(null, providerId),
          resetContractorsList: resetContractorsList.bind(null),
          updateContractorsListSorting: updateContractorsListSorting.bind(null),
          updateContractorsListFilters: updateContractorsListFilters.bind(null),
        },
        dispatch,
      ),
    [dispatch, providerId],
  );
};

export const contractorsListReducer = builder.getReducers();
