import React, { useState } from 'react';

import { SupportTypes } from '@cd3p/core/constants/common';
import { APIHelpRequest, APIHelpRequestType } from '@cd3p/core/types/api';
import { multilineTextField } from '@cd3p/core/utils/fields';
import { FormField, MenuItem, Modal, Typography } from 'components';
import { useTranslation } from 'react-i18next';

import { FieldError, useForm } from 'third-party';

enum FormFields {
  Subject = 'subject',
  Body = 'body',
}

const emptyString = '' as const;

export type FormProps = {
  [FormFields.Subject]: APIHelpRequestType | typeof emptyString;
  [FormFields.Body]: string;
};

const defaultValues: FormProps = {
  [FormFields.Subject]: '',
  [FormFields.Body]: '',
};

type Props = {
  onClose: () => void;
  onSuccess?: (support: APIHelpRequest) => void;
};

export const SupportModal: React.FC<Props> = ({ onClose, onSuccess }) => {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormProps>({
    mode: 'onChange',
    defaultValues,
  });
  const [isPending, setIsPending] = useState(false);
  const onSubmit = async (data: FormProps) => {
    if (!isValid) {
      return;
    }
    setIsPending(true);
    onSuccess?.({
      subject: data.subject as APIHelpRequestType,
      body: data.body,
    });
    setIsPending(false);
    onClose();
  };
  return (
    <Modal
      open
      header={t('support.title')}
      confirmLabel={t('support.submit')}
      onConfirm={() => {
        handleSubmit(onSubmit)();
      }}
      isDisabled={isPending}
      isPending={isPending}
      isConfirmDisabled={!isValid}
      onClose={onClose}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormField
          type="select"
          isRequired
          selectOptions={
            // map over the keys of the SupportTypes object
            // and create a MenuItem for each key
            Object.keys(SupportTypes).map(key => (
              <MenuItem key={key} value={key}>
                <Typography variant="body1" fontWeight={600}>
                  {
                    //@ts-ignore
                    SupportTypes[key]
                  }
                </Typography>
              </MenuItem>
            ))
          }
          fieldName={FormFields.Subject}
          label={t('support.subject')}
          placeholder={t('support.subject.placeholder')}
          requiredErrorMessage={t('common.form.emptyFieldError')}
          control={control}
          maxLength={200}
        />
        <FormField
          fieldName={FormFields.Body}
          label={t('support.description')}
          fieldError={errors[FormFields.Body] as FieldError}
          requiredErrorMessage={t('common.form.emptyFieldError')}
          placeholder={t('support.description.placeholder')}
          control={control}
          multiline
          rows={6}
          maxLength={multilineTextField.maxLength}
          isDisabled={isPending}
          isRequired
        />
      </form>
    </Modal>
  );
};
