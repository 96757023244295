import React, { useState } from 'react';

import styled from 'styled-components';

import { APIMasterDataCategory } from '@cd3p/core/types/api';
import { getServerErrorTitle } from '@cd3p/core/utils/common';
import { FormField, LoadingButton, TypeaheadFormField } from 'components';

import { FieldError, useForm, useTranslation } from 'third-party';

import { NotificationSeverity, useNotifications } from 'modules/notifications';

import { getCategoryExcludedIds } from 'features/PlantSettings/common/commonHelpers';

import { TypeaheadOption } from 'types/app';

const FormWrapper = styled.form`
  display: flex;
  align-items: flex-start;
  gap: 1.6rem;
  z-index: 5;
`;

const AddButton = styled(LoadingButton)`
  white-space: nowrap;
  min-width: auto;
  height: 4rem;
  margin-top: 4.4rem;
`;

export enum AddFormFields {
  Name = 'name',
  Category = 'category',
}

export type AddForm = {
  [AddFormFields.Name]: string;
  [AddFormFields.Category]: null | TypeaheadOption[];
};
export const addFormDefaultValues = {
  [AddFormFields.Name]: '',
  [AddFormFields.Category]: null,
};

export const useSubmitAddForm = <
  CreateCategoryT extends (...args: any[]) => Promise<any>,
  SubmitT extends (...args: any[]) => Promise<any>,
  SetValueT extends (...args: any[]) => void,
>({
  isValid,
  setValue,
  createCategory,
  submit,
  onSuccess,
  successMessage,
}: {
  isValid: boolean;
  createCategory: CreateCategoryT;
  submit: SubmitT;
  onSuccess?: () => void;
  setValue: SetValueT;
  successMessage: string;
}) => {
  const { t } = useTranslation();
  const { addNotification } = useNotifications();

  return async (data: AddForm) => {
    if (!isValid) {
      return;
    }
    const categories: TypeaheadOption[] = [];
    for (const option of data[AddFormFields.Category]!) {
      let value = option.value;

      if (option.__isNew__) {
        const result = await createCategory(option.label.trim());
        if (result.error) {
          addNotification({
            severity: NotificationSeverity.Error,
            message: getServerErrorTitle(result) || t('common.generalError'),
          });
          return;
        }
        value = result.payload.id;
      }

      categories.push({
        label: option.label,
        value: option.__isNew__ ? value : option.value,
      });
    }
    const categoryIds = categories.map(it => Number(it.value));
    const result = await submit({
      name: data[AddFormFields.Name].trim(),
      categoryIds,
    });
    if (result.error) {
      addNotification({
        severity: NotificationSeverity.Error,
        message: getServerErrorTitle(result) || t('common.generalError'),
      });
      // update dropdown list to avoid creating new
      // categories again in case of error
      setValue(AddFormFields.Category, categories);
    } else {
      addNotification({
        message: successMessage,
      });
      onSuccess?.();
    }
  };
};

export const loadDataCategoryOptions =
  (loadFunc: (...args: any[]) => Promise<any>, excludedIds: number[] = []) =>
  async (value: string) => {
    const result = await loadFunc(value, excludedIds);
    let options: TypeaheadOption[] = [];
    if (!result.error) {
      options = result.payload.map((it: APIMasterDataCategory) => ({
        label: it.name,
        value: it.id.toString(),
      }));
    }
    return {
      options,
    };
  };

type Props = {
  onSuccess?: () => void;
  createItem: (...args: any[]) => Promise<any>;
  isFormPending: boolean;
  loadCategories: (...args: any[]) => Promise<any>;
  createCategory: (...args: any[]) => Promise<any>;
  addSuccessMessage: string;
  itemFieldLabel: string;
  itemFieldPlaceholder: string;
  categoryFieldLabel: string;
  categoryPlaceholder: string;
  addButtonLabel: string;
};

export const AddForm: React.FC<Props> = ({
  onSuccess,
  createItem,
  isFormPending,
  loadCategories,
  createCategory,
  addButtonLabel,
  addSuccessMessage,
  itemFieldLabel,
  itemFieldPlaceholder,
  categoryFieldLabel,
  categoryPlaceholder,
}) => {
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors, isValid },
  } = useForm<AddForm>({
    defaultValues: addFormDefaultValues,
    mode: 'onChange',
  });

  // According to requirements, we need to refetch newly created mix categories.
  // But this logic in incapsulated inside AddForm component, specifically TypeaheadFormField.
  // So by providing a key for the AddForm component, we force it to remount and refetch categories.
  const [submitKey, setSubmitKey] = useState(0);

  const onSubmit = useSubmitAddForm({
    isValid,
    setValue,
    createCategory,
    submit: createItem,
    onSuccess: () => {
      reset(addFormDefaultValues);
      onSuccess?.();
      setSubmitKey(key => key + 1);
    },
    successMessage: addSuccessMessage,
  });

  const loadCategoryOptions = loadDataCategoryOptions(
    loadCategories,
    getCategoryExcludedIds(getValues(AddFormFields.Category)),
  );

  const onCategoryChange = (selectedOption: TypeaheadOption[] | null) => {
    setValue(AddFormFields.Category, selectedOption, {
      shouldValidate: true,
    });
  };

  return (
    <FormWrapper onSubmit={handleSubmit(onSubmit)} key={submitKey}>
      <FormField
        fieldName={AddFormFields.Name}
        label={itemFieldLabel}
        placeholder={itemFieldPlaceholder}
        requiredErrorMessage={t('common.form.emptyFieldError')}
        control={control}
        maxLength={300}
        isDisabled={isFormPending}
        isRequired
      />
      <FormField
        fieldName={AddFormFields.Category}
        label={categoryFieldLabel}
        fieldError={errors[AddFormFields.Category] as FieldError}
        control={control}
        isRequired
        render={({ field }) => (
          <TypeaheadFormField
            openMenuOnFocus
            openMenuOnClick
            defaultOptions
            isCreatable
            isMulti
            placeholder={categoryPlaceholder}
            loadOptions={loadCategoryOptions}
            onChange={onCategoryChange}
            value={field.value}
            maxLength={300}
            isDisabled={isFormPending}
            noOptionsMessage={() => t('typeahead.nothingFound')}
          />
        )}
      />
      <AddButton
        type="submit"
        variant="contained"
        disabled={isFormPending || !isValid}
        loading={isFormPending}
      >
        {addButtonLabel}
      </AddButton>
    </FormWrapper>
  );
};
