import React, { useCallback, useEffect, useMemo, useState } from 'react';

import styled, { useTheme } from 'styled-components';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SendIcon from '@mui/icons-material/Send';

import {
  APIAccessLevel,
  APISortOrder,
  APIUser,
  APIUserRequest,
  APIUserStatus,
  APIUserType,
} from '@cd3p/core/types/api';
import {
  getFullName,
  getServerErrorTitle,
  getSortOrder,
} from '@cd3p/core/utils/common';
import {
  AddButton,
  CanAccess,
  CircularProgress,
  ConfirmationDialog,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Table,
  Tag,
  Typography,
} from 'components';
import { useHandleApiResult } from 'hooks/useHandleApiResult';

import { _, useSelector, useTranslation } from 'third-party';

import { getMaskedPhone } from 'constants/regexp';

import { useApp } from 'modules/app';
import { UsersListColumnId, useUsersList } from 'modules/usersList';

import { appSelectors, usersListSelectors } from 'selectors';

import { InviteEditUserPopup } from 'features/Contractors/InviteEditUserPopup';
import {
  ActionButtonsCellStyles,
  LoadingContainer,
  TableCellText,
  tableComponents,
} from 'features/Contractors/Tabs/tabHelpers';

import { getUserFullName } from 'utils/user';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 0;
  overflow: hidden;
  padding-top: ${props => props.theme.custom.dimensions.viewVerticalPadding}rem;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 ${props => props.theme.custom.dimensions.viewVerticalPadding}rem;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3rem 2.5rem;
  overflow: auto;
`;

const Header = styled(Typography)`
  color: ${props => props.theme.custom.palette.secondary500};
`;

const Subheader = styled(Typography)`
  color: ${props => props.theme.custom.palette.absoluteDark};
  margin-bottom: 2rem;
`;

const StyledTag = styled(Tag)`
  color: ${({ theme }) => theme.custom.palette.purple700};
  background-color: ${({ theme }) => theme.custom.palette.white};
  border-color: ${({ theme }) => theme.custom.palette.muted100};
  > * {
    text-transform: capitalize;
  }
`;

const TableHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  align-items: center;
`;

const TableHeader = styled(Typography)`
  color: ${({ theme }) => theme.custom.palette.secondary500};
`;

const DividerStyled = styled(Divider)`
  border-color: ${({ theme }) => theme.custom.palette.muted100};
`;

export const ProviderUsersListView = () => {
  const { t } = useTranslation();
  const [selectedUser, setSelectedUser] = useState<APIUser | null>(null);
  const [actionsMenuAnchorEl, setActionsMenuAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const [isConfirmationDialogShown, setConfirmationDialogShown] =
    useState(false);
  const [showUserEditPopup, setShowUserEditPopup] = useState(false);

  const providerName = useSelector(appSelectors.providerName);
  const accessLevel = useSelector(appSelectors.accessLevel);
  const userId = useSelector(appSelectors.userId);
  const createUserPending = useSelector(appSelectors.createUserPending);
  const updateUserByIdPending = useSelector(appSelectors.updateUserByIdPending);

  const theme = useTheme();
  const rowHighlightColor = useCallback(
    (item: APIUser) => {
      if (item.userStatus !== APIUserStatus.Active) {
        return theme.custom.palette.purple50;
      }
    },
    [theme],
  );

  const handleApiResult = useHandleApiResult();

  const { createUser, updateUserById } = useApp();
  const {
    loadUsersList,
    loadMoreUsersList,
    updateUsersListSorting,
    resetUsersList,
    deleteUser,
    updateUsersList,
    resendUserInvite,
  } = useUsersList();

  const usersListItems = useSelector(usersListSelectors.usersListItems);
  const usersListLoaded = useSelector(usersListSelectors.usersListLoaded);
  const usersListCount = useSelector(usersListSelectors.usersListCount);
  const loadUsersListPending = useSelector(
    usersListSelectors.loadUsersListPending,
  );
  const loadMoreUsersListPending = useSelector(
    usersListSelectors.loadMoreUsersListPending,
  );
  const deleteUserPending = useSelector(usersListSelectors.deleteUserPending);
  const sorting = useSelector(usersListSelectors.usersListSorting);
  const { sortField, sortOrder } = sorting[1];

  const openActionsMenu = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, user: APIUser) => {
      setSelectedUser(user);
      setActionsMenuAnchorEl(event.currentTarget);
    },
    [],
  );

  const closeActionsMenu = useCallback(() => {
    setActionsMenuAnchorEl(null);
  }, []);

  useEffect(() => {
    if (!usersListLoaded) {
      loadUsersList({ userType: APIUserType.Dispatcher });
    }
  }, [usersListLoaded, loadUsersList]);

  useEffect(() => {
    return () => {
      resetUsersList();
    };
  }, [resetUsersList]);

  const onResendInvite = useCallback(
    (user: APIUser) => {
      handleApiResult<APIUser>(
        () => resendUserInvite(user.id),
        ({ showBaseToast }) =>
          showBaseToast(t('customers.users.table.resendInviteSuccess')),
      ).then(() => {
        setSelectedUser(null);
      });
    },
    [handleApiResult, resendUserInvite, t],
  );

  const onConfirmDelete = useCallback(() => {
    if (selectedUser) {
      handleApiResult<APIUser>(
        () => deleteUser(selectedUser.id),
        ({ showBaseToast }) => {
          showBaseToast(
            t('userManagement.users.deleteSuccess', {
              userName: getFullName(
                selectedUser.firstName,
                selectedUser.lastName,
              ),
            }),
          );
          updateUsersList({ userType: APIUserType.Dispatcher });
        },
      ).then(() => {
        setSelectedUser(null);
        setConfirmationDialogShown(false);
      });
    }
  }, [deleteUser, handleApiResult, selectedUser, t, updateUsersList]);

  const actionMenuItems = useMemo(() => {
    return [
      {
        label: t('customers.users.table.actionsMenuEdit'),
        icon: () => <ModeEditOutlineOutlinedIcon />,
        onClick: () => {
          setShowUserEditPopup(true);
          closeActionsMenu();
        },
      },
      ...(selectedUser?.id !== userId
        ? [
            {
              label: t('customers.users.table.actionsMenuDelete'),
              icon: () => <DeleteIcon />,
              onClick: () => {
                setConfirmationDialogShown(true);
                closeActionsMenu();
              },
            },
          ]
        : []),
      ...(selectedUser?.userStatus === APIUserStatus.NotActive
        ? [
            {
              label: t('customers.users.table.actionsMenuResendInvite'),
              icon: () => <SendIcon />,
              onClick: () => {
                onResendInvite(selectedUser);
                closeActionsMenu();
              },
            },
          ]
        : []),
    ];
  }, [t, selectedUser, userId, closeActionsMenu, onResendInvite]);

  const columns = useMemo(() => {
    return [
      {
        id: 'lastName',
        dataId: 'lastName' as keyof APIUser,
        label: t('customers.users.table.nameColumnLabel'),
        width: '25%',
        formatter: (_: APIUser['lastName'], item: APIUser) => {
          const value = getUserFullName(item);
          return {
            value,
            element: (
              <Stack direction="row">
                {item.userStatus !== APIUserStatus.Active && (
                  <StyledTag
                    mr="1rem"
                    value={t('customers.users.table.pendingLabel')}
                  />
                )}
                <TableCellText>{value}</TableCellText>
              </Stack>
            ),
          };
        },
      },
      {
        id: 'email',
        dataId: 'email' as keyof APIUser,
        label: t('customers.users.table.emailColumnLabel'),
        width: '25%',
        formatter: (value: APIUser['email']) => ({
          value: value || '',
          element: (
            <Stack direction="row">
              <TableCellText mr="1rem">{value || ''}</TableCellText>
            </Stack>
          ),
        }),
      },
      {
        id: 'phone',
        dataId: 'phone' as keyof APIUser,
        label: t('customers.users.table.phoneColumnLabel'),
        width: '19%',
        formatter: (value: APIUser['phone']) => {
          const computedValue = getMaskedPhone(value);
          return {
            value: computedValue,
            element: (
              <TableCellText align={!computedValue ? 'center' : 'left'}>
                {computedValue || '-'}
              </TableCellText>
            ),
          };
        },
      },
      {
        id: 'accessLevel',
        dataId: 'accessLevel' as keyof APIUser,
        label: t('customers.users.table.roleColumnLabel'),
        width: '20%',
        formatter: (value: APIUser['accessLevel']) => ({
          value: value || '',
          element: <TableCellText>{value || ''}</TableCellText>,
        }),
      },
      ...(accessLevel === APIAccessLevel.Admin
        ? [
            {
              id: 'accessLevelIndicator',
              dataId: 'userOptions.hasAccessToMobileApp' as keyof APIUser,
              label: t('customers.users.table.hasAccessToMobile'),
              width: '8%',
              sortable: false,
              formatter: (value: string) => ({
                value: value,
                element: (
                  <CheckCircleIcon
                    sx={{
                      fontSize: '2rem',
                      marginLeft: '0.8rem',
                      color: value == 'true' ? 'green' : 'grey',
                    }}
                  />
                ),
              }),
            },
            {
              id: 'menu',
              width: '5%',
              cellStyles: ActionButtonsCellStyles,
              formatter: (_: APIUser['lastName'], user: APIUser) => ({
                value: 'Actions',
                element: (
                  <IconButton
                    aria-label="Actions"
                    onClick={e => openActionsMenu(e, user)}
                  >
                    <MoreVertIcon />
                  </IconButton>
                ),
              }),
            },
          ]
        : []),
    ];
  }, [openActionsMenu, t, accessLevel]);

  const onLoadMoreClicked = useCallback(() => {
    loadMoreUsersList({ userType: APIUserType.Dispatcher });
  }, [loadMoreUsersList]);

  const onColumnHeaderClicked = useCallback(
    (columnId: UsersListColumnId) => {
      const searchSortOrders = [
        {
          sortField: 'userStatus' as keyof APIUser,
          sortOrder: APISortOrder.ASC,
        },
        {
          sortField: columnId,
          sortOrder: getSortOrder(columnId, sortField, sortOrder),
        },
        {
          sortField: 'id' as keyof APIUser,
          sortOrder: APISortOrder.ASC,
        },
      ];
      updateUsersListSorting(searchSortOrders);
      loadUsersList({ userType: APIUserType.Dispatcher });
    },
    [loadUsersList, sortField, sortOrder, updateUsersListSorting],
  );

  const onUserDetailsSubmit = useCallback(
    (user: Partial<APIUserRequest>) => {
      handleApiResult<APIUser>(
        () => {
          const userModel = user as APIUser;
          if (userModel?.id) {
            return updateUserById(
              userModel.id,
              _.omit(userModel, 'providers'),
              user?.providerIds || undefined,
            );
          } else {
            return createUser(
              undefined,
              {
                ...userModel,
                userType: APIUserType.Dispatcher,
              },
              user?.providerIds || undefined,
            );
          }
        },
        ({ showBaseToast }) => {
          const userToUpdate = user as APIUser;
          const successMessage = userToUpdate?.id
            ? t('customers.users.form.editSuccess', {
                name: getUserFullName(user as APIUser),
              })
            : t('userManagement.users.form.inviteSuccess');
          showBaseToast(successMessage);
          updateUsersList({ userType: APIUserType.Dispatcher });
          setShowUserEditPopup(false);
        },
        ({ showErrorToast, showWarningToast, result }) => {
          const isUserExistError = result?.payload?.status === 409;
          if (isUserExistError) {
            showWarningToast(t('inviteCustomer.form.error.userExists'));
          } else {
            showErrorToast(
              getServerErrorTitle(result) || t('common.generalError'),
            );
          }
        },
      );
    },
    [handleApiResult, updateUserById, createUser, t, updateUsersList],
  );

  return (
    <Wrapper>
      <InviteEditUserPopup
        user={selectedUser}
        open={showUserEditPopup}
        userTypeToCreate={APIUserType.Dispatcher}
        onClosePopup={() => {
          setShowUserEditPopup(false);
        }}
        onSubmit={onUserDetailsSubmit}
        isFormPending={createUserPending || updateUserByIdPending}
      />
      <ConfirmationDialog
        onClose={() => {
          setConfirmationDialogShown(false);
          setSelectedUser(null);
        }}
        handleActionClick={onConfirmDelete}
        open={isConfirmationDialogShown}
        actionPending={deleteUserPending}
        description={t('common.dialog.deleteUserConfirmationBodyText')}
      />
      <HeaderWrapper>
        <Header variant="h4">{t('userManagement.title')}</Header>
        <Subheader variant="h3">{providerName}</Subheader>
      </HeaderWrapper>
      <DividerStyled />
      <Content>
        <TableHeaderWrapper>
          <TableHeader variant="h5">
            {t('userManagement.users.header')}
          </TableHeader>
          <CanAccess allowedFor={APIAccessLevel.Admin}>
            <AddButton
              onClick={() => {
                setShowUserEditPopup(true);
                setSelectedUser(null);
              }}
              label={t('customers.users.inviteButtonText')}
            />
          </CanAccess>
        </TableHeaderWrapper>
        {!usersListLoaded && (
          <LoadingContainer>
            <CircularProgress sx={{ margin: '5rem' }} />
          </LoadingContainer>
        )}
        {usersListLoaded && usersListCount > 0 && (
          <Table<APIUser>
            infiniteScroll
            tableMinHeight="20rem"
            components={tableComponents}
            columns={columns}
            rowHighlightColor={rowHighlightColor}
            items={usersListItems}
            itemsTotalCount={usersListCount}
            itemsLoaded={usersListLoaded}
            loadItemsPending={loadUsersListPending}
            loadMoreItemsPending={loadMoreUsersListPending}
            onLoadMoreClicked={onLoadMoreClicked}
            sortField={sortField}
            sortOrder={sortOrder}
            onColumnHeaderClicked={onColumnHeaderClicked}
            loadMoreButtonText={t('customers.users.table.loadMoreButtonText')}
          />
        )}
        <Menu
          menuTitle={t('customers.users.table.actionsMenuTitle')}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          anchorEl={actionsMenuAnchorEl}
          open={Boolean(actionsMenuAnchorEl)}
          onClose={closeActionsMenu}
        >
          {actionMenuItems.map(({ label, icon: Icon, onClick }) => (
            <MenuItem
              key={label}
              text={label}
              icon={<Icon />}
              onClick={onClick}
            />
          ))}
        </Menu>
      </Content>
    </Wrapper>
  );
};
