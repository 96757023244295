import React, {
  ReactElement,
  Suspense,
  lazy,
  useCallback,
  useMemo,
  useState,
} from 'react';

import styled from 'styled-components';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import { Typography } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { SvgIcon } from 'components';

import { components } from './Components';

import { useSearchParams, useTranslation } from 'third-party';

import { Doc } from 'types/app';

const NavigationContainer = styled.div`
  min-width: 35rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: white;
  box-shadow: 10px 0 12px -10px rgba(0, 0, 0, 0.25);
`;

const ContentContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  min-height: 0;
`;

const HelpContainer = styled.div`
  margin: 2rem;
  width: 100%;
  overflow: scroll;
  display: flex;
  justify-content: center;
`;

const EmptyStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const StyledList = styled(List)<{ component?: React.ElementType }>`
  overflow-y: auto;
  flex-grow: 1;
  padding: 0;
`;

const PageHeader = styled(Typography)`
  color: ${props => props.theme.custom.palette.secondary700};
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
  height: 2.5rem;
  padding: 2rem;
`;

const InnerContent = styled.div`
  max-width: 66.8rem;
  min-width: 66.8rem;
`;

type Props = {
  selectedItem?: Doc;
  menuItems: Doc[];
  pageName: string;
  menuItemRenderer?: (it: any) => string | ReactElement | ReactElement[];
};

export const Docs: React.FC<Props> = ({
  selectedItem,
  menuItems,
  pageName,
  menuItemRenderer,
}) => {
  const { t } = useTranslation();
  const [openItems, setItems] = React.useState<string[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const [fileToShow, setFileToShow] = useState<string>(
    searchParams.get('fileToShow') || selectedItem?.file || '',
  );

  const onListItemClick = useCallback(
    (it: Doc) => {
      setSearchParams({ fileToShow: it.file });
      setFileToShow(it.file);
      if (openItems.includes(it.file)) {
        setItems(prev => prev.filter(item => item !== it.file));
        return;
      } else {
        setItems(prev => [...prev, it.file]);
      }
    },
    [openItems, setSearchParams],
  );

  const DocComponent = useMemo(() => {
    return lazy(() => import(`../../${fileToShow}`));
  }, [fileToShow]);

  return (
    <ContentContainer>
      <NavigationContainer>
        <PageHeader variant="h4">{pageName}</PageHeader>
        <StyledList component="nav" aria-labelledby="nested-list-subheader">
          {menuItems.map((it: any, index: number) =>
            it.children.length ? (
              <>
                <ListItemButton
                  onClick={() => onListItemClick(it)}
                  selected={fileToShow === it.file}
                >
                  <ListItemText
                    primary={menuItemRenderer ? menuItemRenderer(it) : it.title}
                  />
                  {openItems.includes(it.file) ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )}
                </ListItemButton>
                <Collapse
                  in={openItems.includes(it.file)}
                  timeout="auto"
                  unmountOnExit
                >
                  {it.children.map((it: any, index: number) => (
                    <List key={index} component="div" disablePadding>
                      <ListItemButton
                        sx={{ pl: 4 }}
                        onClick={() => onListItemClick(it)}
                        selected={fileToShow === it.file}
                      >
                        <ListItemText
                          primary={
                            menuItemRenderer ? menuItemRenderer(it) : it.title
                          }
                        />
                      </ListItemButton>
                    </List>
                  ))}
                </Collapse>
              </>
            ) : (
              <ListItemButton
                key={index}
                selected={fileToShow === it.file}
                onClick={() => onListItemClick(it)}
              >
                <ListItemText
                  primary={menuItemRenderer ? menuItemRenderer(it) : it.title}
                />
              </ListItemButton>
            ),
          )}
        </StyledList>
      </NavigationContainer>
      <HelpContainer>
        <InnerContent>
          <Suspense fallback={<div>Loading...</div>}>
            {fileToShow && (
              <DocComponent key={fileToShow} components={components} />
            )}
          </Suspense>
          {!fileToShow && (
            <EmptyStateContainer>
              <Typography variant="h4" marginBottom="1rem">
                {t('docs.emptyStateText')}
              </Typography>
              <SvgIcon height="50rem" width="50rem" icon="helpcenter" />
            </EmptyStateContainer>
          )}
        </InnerContent>
      </HelpContainer>
    </ContentContainer>
  );
};
