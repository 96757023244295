import { State } from 'modules';

import { _ } from 'third-party';

import { projectsDefaultFilter } from 'modules/allProjects';

export const allProjectsListItems = (state: State) => state.allProjects.items;
export const allProjectsListSorting = (state: State) =>
  state.allProjects.sorting;
export const allProjectsListFilters = (state: State) =>
  state.allProjects.filters;
export const isAllProjectsListFiltersApplied = (state: State) =>
  !_.isEqual(state.allProjects.filters, projectsDefaultFilter);
export const allProjectsListPagination = (state: State) =>
  state.allProjects.pagination;
export const allProjectsListCount = (state: State) => state.allProjects.count;
export const loadAllProjectsPending = (state: State) =>
  state.allProjects.loadAllProjectsPending;
export const allProjectsLoaded = (state: State) =>
  state.allProjects.projectsLoaded;
export const loadMoreAllProjectsPending = (state: State) =>
  state.allProjects.loadMoreAllProjectsPending;
