import { commonENTranslation } from '@cd3p/core/i18n';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

export const resources = {
  en: {
    translation: {
      ...commonENTranslation,
      'common.delete': 'Delete',
      'common.apply': 'Apply',
      'common.reset': 'Reset',
      'common.approve': 'Approve',
      'common.loading': 'Loading ...',
      'common.loadMore': 'Load more',
      'common.send': 'Send',
      'common.form.emptyFieldError': "Field can't be empty",
      'common.loginInactiveError':
        'An account with such credentials is not found or you do not have access. If you forgot the password, please contact the support',
      'common.selectNoOptions': 'No options',
      'common.noNevermind': 'No, nevermind',
      'common.exitConfirmationWindow.text':
        'Are you sure you want to leave the page? All unsaved data will be lost.',
      'common.confirmCancel': 'Yes, cancel',
      'common.confirmDelete': 'Yes, delete',
      'common.continueEditing': 'Continue editing',
      'common.yesterday': 'Yesterday',
      'common.wrongPage':
        'Oops! It seems the page you are looking for does not exist.',

      'app.name': 'Concretely',
      'app.version': 'Version',
      'browser.newMessageNotification': 'New Message',
      'browser.newNotification': 'New Notification',

      'typeahead.notFound': 'Not Found',
      'typeahead.nothingFound': 'Nothing Found',
      'typeahead.create': 'Create',
      'typeahead.noMatchingUser': 'No matching user',

      'copyPinLink.successMessage': 'Copied to Clipboard',
      'copyPinLink.tooltip': 'Copy link to pin',

      // Login
      'login.signInHeader': 'Login',
      'login.signInButtonText': 'Login',
      'login.alert.incorrectCredentials':
        'An account with such credentials is not found or you do not have access. If you forgot the password, please contact the support',
      'login.forgotPasswordLink': 'Forgot password?',

      // Forgot Password
      'forgotPassword.title': 'Reset Password',
      'forgotPassword.description':
        'Enter your email address and we will send you a link to reset your password.',
      'forgotPassword.submitButtonLabel': 'Send Link to Email',
      'forgotPassword.successToastMessage':
        'Check your email and follow the link to reset your password',
      'forgotPassword.errorToastMessage':
        'Sorry, no users with that email address were found.',

      // Reset Password
      'resetPassword.title': 'Reset Password',
      'resetPassword.description':
        'Create a new password and re-enter to confirm your new password.',
      'resetPassword.submitButton': 'Reset Password',
      'resetPassword.successToastMessage':
        'Your password has been reset. You’re ready to login with your new password.',
      'resetPassword.errorToast.message':
        'This link is no longer valid. Please return to "Forgot Password" on the Log In screen, and a new reset link will be sent.',

      // App Bar
      'appbar.menu.userManagement': 'User management',
      'appbar.menu.whatsNews': 'What’s new',
      'appbar.menu.apiDocs': 'API docs',
      'appbar.menu.users': 'Users',
      'appbar.menu.setting': 'Settings',
      'appDrawer.menu.importOrders': 'Import orders',
      'appDrawer.menu.allOrders': 'All Orders',
      'appDrawer.menu.allProjects': 'All Projects',
      'importOrders.title': 'Import Orders',
      'importOrders.description':
        'Please upload your orders here with the following information. Only CSV fomated files will be accepted. ',
      'importOrders.downloadSampleLinkText': 'Download sample .csv file',
      'importOrders.requiredFieldsHeader': 'Required Fields',
      'importOrders.requiredFieldsFormat': 'format',
      'importOrders.uploadFileButtonText': 'Upload file',
      'importOrders.dropZoneDefaultTitle': 'File Upload',
      'importOrders.dropZoneUploadingTitle': 'Uploading ...',
      'importOrders.dropZoneBrowseFilesButtonText': 'browse files',
      'importOrders.uploadFailedErrorAlertTitle': 'Upload failed',
      'importOrders.incorrectFileSizeErrorAlertTitle': 'Incorrect filed size',
      'importOrders.errorsTitle': 'Errors with your .csv file',
      'importOrders.reUploadButtonText': 'Re-upload file',
      'importOrders.alreadyExistOrdersErrorMessage':
        'The following order IDs already exist:',
      'importOrders.fieldName.deliveryDateTime': 'Delivery date time',
      'importOrders.importOrdersErrorAlertTitle_other':
        '{{numOfNotImportedOrdersCount}} orders were not imported',

      'appDrawer.menu.dashBoard': 'Dashboard',
      'appDrawer.menu.orders': 'Orders',
      'appDrawer.menu.customer': 'Customers',
      'appDrawer.menu.providers': 'Providers',
      'appDrawer.menu.projects': 'Projects',
      'appDrawer.menu.products': 'Products',

      'order.breadcrumbs.viewAll': 'View all orders',
      'order.breadcrumbs.projectDetails': 'Project details',

      'order.breadcrumbs.edit': 'Edit mode',
      'order.headerTitle.newOrder': 'New Order',
      'order.headerTitle.editOrder': 'Edit order',
      'order.header.confirmUponApproval':
        'Confirm this order upon approval of change request',

      'order.sectionField.orderNumber': 'Batching Software Order #',
      'order.sectionField.orderNumberPlaceholder':
        'Enter Batching Software Order Number',
      'order.sectionField.orderType': 'Order Type',
      'order.sectionField.orderType.willCall': 'Will Call',
      'order.sectionField.orderType.firmOrder':
        'Firm Order (no confirmation required)',
      'order.sectionField.orderName': 'Order Name',
      'order.sectionField.orderStatus': 'Order status',

      'order.newOrderNotification.title': 'New order received',
      'order.changeOrderNotification.title': 'ORDER CHANGES REQUESTED',
      'order.cancelOrderNotification.title': 'Order Canceled',
      'order.confirmOrderNotification.title': 'ORDER CONFIRMED',

      'order.sectionTitle.deliveryDateTime': 'Delivery date & time',
      'order.sectionTitle.deliveryDateTimeSeparated': 'Delivery\ndate & time',
      'order.sectionField.deliveryDate': 'Date',
      'order.sectionTitle.deliveryDate2': 'Delivery date',
      'order.sectionTitle.deliveryTime': 'Delivery time',
      'order.sectionField.invalidDateError': 'Date is invalid',
      'order.sectionField.invalidTimeError': 'Time is invalid',
      'order.sectionField.deliveryTime': 'Time',
      'order.sectionField.deliveryDescription':
        'Delivery goes to the Pin coordinates, not address.',

      'order.sectionTitle.overview': 'Overview',
      'order.sectionField.project': 'Project',
      'order.sectionField.projectAddress': 'Project Address',
      'order.sectionField.addProjectButton': 'Add Project',
      'order.sectionField.customerName': 'Customer Name',
      'order.sectionField.providerName': 'Provider Name',
      'order.sectionField.placedBy': 'Placed By',
      'order.sectionField.deliveryLocation': 'Delivery location',
      'order.sectionField.deliveryLocationCoordinates': 'Delivery Pin: ',
      'order.sectionField.deliveryLocationDescription':
        '(Delivery goes to the Pin coordinates, not address)',
      'order.sectionField.callbackTitle': 'Call back',
      'order.sectionField.callbackPlaceholder': 'Enter Call back',
      'order.sectionField.callbackLabel': 'This order requires a call back',
      'order.sectionField.hasAccessToMobileApp': 'Mobile Access',
      'order.sectionField.hasAccessToMobileAppLabel':
        'Access Concretely via the mobile app',

      'order.sectionField.orderTypeLabel': 'Order Type',
      'order.orderType.willCall': 'Will Call',
      'order.orderType.firmOrder': 'Firm Order',

      'order.sectionTitle.orderDetails': 'Order details',
      'order.sectionField.contactInfo': 'Contact info',
      'order.sectionField.cancellationSubmittedLabel': 'Cancellation Submitted',
      'order.sectionField.changesRequestedLabel': 'Changes Requested',
      'order.sectionField.volumeQuantity': 'Qty (Btchd / Dlvrd)',
      'order.sectionField.orderQuantity': 'Quantity',
      'order.sectionField.volumeQuantityPlaceholder': 'Enter Quantity',
      'order.sectionField.mixType': 'Mix',
      'order.sectionField.mixTypePlaceholder': 'Select Mix',
      'order.sectionField.deliveryRate': 'Delivery rate',
      'order.sectionField.deliveryRatePlaceholder': 'Enter Delivery Rate',
      'order.sectionField.actualDeliveryRate':
        'Actual: {{actualDeliveryRate}} {{deliveryRateTypeUnit}}',
      'order.sectionField.deliveryRateDiff': '{{deliveryRateDiff}} min',
      'order.sectionField.slump': 'Slump',
      'order.sectionField.slumpPlaceholder': 'Enter Slump',
      'order.sectionField.additives': 'Additive(s)',
      'order.sectionField.additivesPlaceholder': 'Select Additives',
      'order.sectionField.notes': 'Notes',
      'order.sectionField.notesPlaceholder':
        'Additional details for the provider',
      'order.sectionField.emptyFieldErrorTooLong': 'Field is longer than',
      'order.sectionField.emptyFieldErrorTooLongEnd': 'characters',
      'order.sectionField.typeOfPour': 'Type of Pour',
      'order.sectionField.typeOfPourPlaceholder':
        'Describe what is being poured. Ex: exterior slab',
      'order.sectionField.placementMethod': 'Placement Method',
      'order.sectionField.placementMethodPlaceholder':
        'Describe how concrete will be placed. Ex: pump',
      'order.sectionField.subscribeTeamLabel': 'Subscribe Team Members',
      'order.sectionField.subscribeTeamPlaceholder':
        'Search user name or email address',
      'order.sectionField.subscribeTeamPlaceholderEmpty':
        'You should select a project first',

      'order.action.cancel': 'Cancel',
      'order.action.submitChanges': 'Submit changes',

      'order.successCreatedMessage':
        'Order {{ orderName }} was successfully created',
      'order.successDispatcherUpdatedMessage':
        'Order {{ orderName }} was successfully updated',
      'order.successContractorUpdatedMessage':
        'Updates to your order {{ orderName }} have been received by {{ providerName }} and submitted for approval',

      'order.errorCloseMessage':
        'Order {{ orderName }} was successfully created',

      'order.cancellationSubmitted': 'Cancellation Submitted',

      'order.details.sendUpdate': 'Send update',
      'order.details.unconfirmed.title.future':
        'Confirmation needed before the pour date',
      'order.details.unconfirmed.description.reminder':
        ' has not confirmed the order. Reminders will be sent to them 24hrs and 2hrs prior to the order time.',
      'order.details.unconfirmed.description.future':
        'The notification must be sent only before the start of the pour date.',
      'order.details.confirmed.title': 'Order confirmed, please assign drivers',
      'order.details.confirmed.actionSubtitle': 'Add driver:',
      'order.details.confirmed.assignedNotNotified': 'Not yet notified:',
      'order.details.confirmed.addButton': 'Add',
      'order.details.confirmed.deleteAssignedDriver': 'Delete',
      'order.details.confirmed.deleteAssignedDriverLoading': 'Deleting',
      'order.details.confirmed.assignDriversButton': 'Assign drivers',
      'order.details.confirmed.showMapButton': 'Show map',
      'order.details.confirmed.sendNotifications': 'Send notifications',
      'order.details.confirmed.exportAssigned': 'Export',
      'order.details.confirmed.driverLabelPlaceholder': 'Select Driver',
      'order.details.confirmed.plantLabelPlaceholder': 'Select Plant',
      'order.details.confirmed.arrivalTimeLabelPlaceholder': 'Arrival Time',
      'order.details.confirmed.confirmationDeleteTitle': 'Are you sure?',
      'order.details.confirmed.assignedDriverDeleteDescription':
        'Once you delete this assignment, it cannot be undone.',
      'order.details.confirmed.confirmationDialogDeleteAction': 'Yes, delete',
      'order.details.assignDriverErrorMessage':
        'Error while assigning a driver. Please try again later.',
      'order.details.confirmed.allDriversNotified':
        'All drivers have been notified and assigned.',
      'order.details.confirmed.viewAssignmentsButton': 'View assignments',
      'order.details.confirmed.assignedDriversNotificationDescription':
        'Notifications will be sent directly to these drivers’ mobile devices. This action cannot be undone.',
      'order.details.confirmed.assignedDriversNotificationActionButton':
        'Yes, send',
      'order.details.confirmed.deleteTicketDescription':
        'Once you delete this ticket, it cannot be undone.',
      'order.details.confirmChangeStatusToCancelled':
        'All active tickets will be canceled',
      'order.details.confirmChangeStatusToCompleted':
        'All active tickets will be completed',
      'order.details.dateAndTimeLabel': 'Delivery Start',
      'order.details.workingHours':
        'After business hours. Operations resume on {{day}} at {{time}}',

      'order.details.contractor.unconfirmed.title':
        'Confirmation Needed for This Order.',
      'order.details.contractor.unconfirmed.description':
        'Your upcoming order is not yet confirmed. Please confirm your order.',
      'order.details.contractor.unconfirmedCanceled.title':
        'Cancellation Submitted',
      'order.details.contractor.unconfirmedCanceled.description': `You've submitted a cancellation notice on this order. Provider confirmation is pending.`,
      'order.details.contractor.changesRequested.title':
        'Requested changes pending',
      'order.details.changesRequested.NoteToCustomerLabel': 'Note to Customer',
      'order.details.changesRequested.NoteToCustomerPlaceholder':
        'Any other notes regarding these changes?',
      'order.details.changesRequested.confirmationPendingTitle':
        'Confirmation Pending',
      'order.details.changesRequested.confirmationPendingDescription':
        'Customer confirms this order if these changes are approved.',

      'order.details.projectPlaceholder': 'Select Project',
      'order.details.unconfirmed.title.tomorrow':
        'Confirmation needed for this order',
      'order.details.unconfirmed.title.sent': 'Confirmation notification sent',
      'order.details.closeOrder.header': 'Check Call Back',
      'order.details.closeOrder.text':
        '{{orderName}} delivery seems to be complete. Check status and close order.',
      'order.details.closeOrder.confirmButton': 'Close Order',
      'order.details.cancellationSubmitted.confirmButton':
        'Confirm Cancellation',
      'order.details.cancellationSubmitted.title': 'Cancellation Submitted',
      'order.details.cancellationSubmitted.successToastMessage':
        'Order Canceled',
      'order.details.cancellationSubmitted.message':
        '{{customerName}} has submitted cancel this order. You must acknowledge/confirm this cancellation before the order can move to a canceled status. Please take any necessary actions outside of Concretely that result from this order being canceled.',
      'order.details.cancelled.title': 'Canceled order',
      'order.details.declined.title': 'Order declined',
      'order.details.declined.description':
        '{{providerName}} has declined your order {{orderName}} for {{dateTime}}. Your order will not be fulfilled.',
      'order.details.requested.title': 'Approval Needed for This Order.',
      'order.details.requested.message':
        'Contact customer for changes or questions about the order submitted.',
      'order.details.requested.checkboxLabel':
        'Confirm upon approval (Firm Order Only)',
      'order.details.closeOrderSuccessMessage':
        'Order {{ orderName }} was successfully closed',
      'order.details.closeOrderErrorMessage':
        'Error while closing the order. Please try again later.',
      'order.details.approveWithChangesButtonLabel': 'Approve with changes',
      'order.details.orderCallbackSubmitted.title': 'Call Back Submitted',
      'order.details.orderCallbackSubmitted.description':
        '{{companyName}} has submitted a call back on this order for an additional concrete. ',
      'order.details.orderCallbackSubmitted.confirmText':
        'Please confirm this notice to let {{companyName}} know their call back order has been received. Please take any necessary actions outside of Concretely that result from this call back order submission. ',
      'order.details.orderCallbackSubmitted.confirmCallback':
        'Confirm Call Back',
      'order.details.orderCallbackSubmitted.quantityLabel': 'Quantity',
      'order.details.orderCallbackSubmitted.quantityValue':
        '{{quantityValue}} CY',
      'order.details.orderCallbackSubmitted.aditionalDetailsLabel':
        'Additional Details',
      'order.details.orderCallbackSubmitted.toast.callbackConfirmedTitle':
        'Call Back confirmed',
      'order.details.orderCallbackSubmitted.toast.callbackConfirmedMessage':
        '{{companyName}} has issued a call back for additional concrete on {{orderName}}.',

      'order.details.requestedChanges.title': 'Requested changes',
      'order.details.requestedChanges.description':
        'Select individual changes to approve or click button to approve all changes.',
      'order.details.requestedChanges.rejectAction': 'Reject changes',
      'order.details.requestedChanges.approveAllAction': 'Approve all changes',
      'order.details.requestedChanges.approveAction':
        'Approve selected changes',
      'order.details.requestedChanges.confirmedUponApproval':
        'Order status will move to Confirmed when change request is approved by your provider.',

      'order.details.completed.title': 'This order has been completed',
      'order.details.orderDetailsTabLabel': 'Order Details',
      'order.details.driverAssignmentsButton': 'Driver assignments',
      'order.details.noTicketsTabLabel': 'Tickets',
      'order.details.ticketsTabLabel': 'Tickets ({{count}})',
      'order.details.tickets.addNewTicketButtonText': 'Add new ticket',
      'order.details.tickets.addNewTicketSecondaryButtonText': 'Add ticket',
      'order.details.ticketsTabEmptyStateTextNoTickets': 'Order has no tickets',
      'order.details.tickets.emptyText':
        'Add the first ticket to start this order',
      'order.details.tickets.loadMoreButtonText': 'View more tickets',
      'order.details.tickets.loadingTicketsText': 'Loading tickets ...',

      'order.batchingOrderNumber.title': 'Enter Batching Software Order Number',
      'order.batchingOrderNumber.subtitle':
        'Ticket automation requires that the order number in Concretely matches the order number for this order in your batching software prior to batching start. Enter batching software order number here or update it in the order details.',
      'order.batchingOrderNumber.fieldLabel': 'Batching Software Order Number',
      'order.batchingOrderNumber.fieldPlaceholder':
        'Enter order number from your batching software',
      'order.batchingOrderNumber.successMessage':
        'The order number has been updated successfully.',

      'ticket.changeStatusConfirmationMessage':
        'It will affect the ticket delivery process.',
      'ticket.changeStatusToCompleteConfirmationMessage':
        'Once you complete the ticket, it cannot be undone.',
      'ticket.changeStatusConfirmButton': 'Yes, change',
      'ticket.changeStatusToCompleteConfirmButton': 'Yes, complete',

      'order.details.trucks.loadMoreButtonText': 'View more trucks',

      'order.details.errorSendingSMS':
        'The notification was not sent. Please try again or contact the administrator.',

      'order.backButtonLabel.order': 'Order',

      'order.ticket.draftStatus': 'Needs Info',
      'order.ticket.loadingStatus': 'Loading',
      'order.ticket.deliveringStatus': 'In Transit',
      'order.ticket.completeStatus': 'Complete',
      'order.ticket.cancelledStatus': 'Canceled',
      'order.ticket.onSiteStatus': 'Unloading',
      'order.ticket.pendingStatus': 'Pending',

      'order.ticket.addTicketTitle': 'Add ticket',
      'order.ticket.title': 'Ticket',
      'order.ticket.saveDraftButtonText': 'Save draft',
      'order.ticket.addTicketButtonText': 'Submit ticket',
      'order.ticket.notTodayOrderText':
        'Ticket can be submitted only on the delivery date',
      'order.ticket.cancelTicketButtonText': 'Cancel ticket',
      'order.ticket.closeTicketConfirmationTitle': 'Are you sure?',
      'order.ticket.cancelTicketConfirmationDescription':
        'Once you cancel this ticket, it cannot be undone',
      'order.ticket.cancelTicketConfirmationAction': 'Yes, cancel',
      'order.ticket.ticketCancellationError':
        'Could not cancel the ticket. Please try again later',
      'order.ticket.ticketCancellationPlaceholder': 'Add a comment...',
      'order.ticket.form.ticketNumberLabel': 'Ticket #',
      'order.ticket.form.ticketNumberPlaceholder': 'Ticket #',
      'order.ticket.form.batchSizeLabel': 'Batch Size (CY)',
      'order.ticket.form.batchSizePlaceholder': 'Batch Size (CY)',
      'order.ticket.form.totalOnDeliveryLabel': 'Total on delivery (CY)',
      'order.ticket.form.plantLabel': 'Plant',
      'order.ticket.form.plantPlaceholder': 'Plant Name',
      'order.ticket.form.plantEmptyText': 'No plants found',
      'order.ticket.form.driverEmptyText': 'No drivers found',
      'order.ticket.form.truckLabel': 'Truck',
      'order.ticket.form.truckPlaceholder': 'Truck Number',
      'order.ticket.form.truckEmptyText': 'No trucks available',
      'order.ticket.form.driverLabel': 'Driver',
      'order.ticket.form.driverLabelReadOnly': 'Driver',
      'order.ticket.form.driverPlaceholder': 'Driver Name',
      'order.ticket.form.orderNumberLabel': 'Order #',
      'order.ticket.form.orderNameLabel': 'Order Name',
      'order.ticket.form.projectLabel': 'Project',
      'order.ticket.form.statusLabel': 'Status',
      'order.ticket.form.deliveryLocationLabel': 'Delivery Location',
      'order.ticket.form.customerNameLabel': 'Customer Name',
      'order.ticket.form.comment': 'Comment',
      'order.ticket.form.validation.duplicatedTicketNumber':
        'Ticket # already exists',
      'order.ticket.form.validation.incorrectBatchSize':
        'Batch Size can’t be 0 or lower',

      'order.edit.statusTitle': 'Status',
      'order.edit.statusDescription':
        'Please select a status option to update your order.',
      'order.edit.datesTitle': 'Start date',

      'ordersList.viewTitle': 'Orders',
      'ordersList.searchInputPlaceholder': 'Search Orders',
      'ordersList.table.addTicketButton': 'Add Ticket',
      'ordersList.table.addTicketDialogCloseConfirmation':
        'Do you want to leave with unsaved changes?',
      'ordersList.table.emptyStateText': 'No orders created yet.',
      'ordersList.table.filterEmptyStateText':
        'There are no orders for this filter criteria.',
      'ordersList.table.searchEmptyStateText': 'No orders match your search.',
      'ordersList.table.dateColumnLabel': 'Date',
      'ordersList.table.timeColumnLabel': 'Time',
      'ordersList.table.numberColumnLabel': 'Order #',
      'ordersList.table.customerColumnLabel': 'Customer',
      'ordersList.table.projectColumnLabel': 'Project',
      'ordersList.table.projectAddressColumnLabel': 'Project Address',
      'ordersList.table.deliveryLocationColumnLabel': 'Delivery Location',
      'ordersList.table.idColumnLabel': 'Order #',
      'ordersList.table.orderNameLabel': 'Order Name',
      'ordersList.table.volumeColumnLabel': 'Qty (Btchd / Dlvrd)',
      'ordersList.table.statusColumnLabel': 'Status',
      'ordersList.table.loadMoreButtonText': 'View more orders',
      'ordersList.table.menu.title': 'Actions',
      'ordersList.table.menu.editOrder': 'Edit Order',
      'ordersList.table.menu.addTickets': 'Add Tickets',
      'ordersList.table.menu.confirmOrder': 'Confirm Order',
      'ordersList.table.menu.closeOrder': 'Close Order',
      'ordersList.table.subscribeTooltip': 'Subscribe to this order',
      'ordersList.table.unsubscribeTooltip': 'Unsubscribe from this order',
      'ordersList.filters.datePlaceholder': 'Select Dates',
      'ordersList.filters.dateSectionTitle': 'Date',
      'ordersList.filters.volumeQtyTitle': 'VOLUME/QTY',
      'ordersList.filters.volume0-100': '< 100',
      'ordersList.filters.volume101-500': '101-500',
      'ordersList.filters.volume501-1000': '501-1000',
      'ordersList.filters.volume1001-': '1001 >',
      'ordersList.filters.statusSectionTitle': 'Status',
      'ordersList.filters.customerSectionTitle': 'Customer',
      'ordersList.filters.myOrdersSectionTitle': 'My Orders',
      'ordersList.filters.myOrdersCheckboxLabel': 'Show only My Orders',
      'ordersList.filters.projectSectionTitle': 'Project',
      'ordersList.filters.customerSelectPlaceholder': 'Search Customers',
      'ordersList.filters.projectSelectPlaceholder': 'Search Projects',
      'ordersList.filters.orderSectionTitle': 'Order#',
      'ordersList.filters.orderSelectPlaceholder': 'Search Orders',
      'ordersList.filters.apply_zero': 'APPLY',
      'ordersList.filters.apply_one': 'APPLY (1)',
      'ordersList.filters.apply_other': 'APPLY ({{count}})',
      'ordersList.resetFiltersButtonText': 'Reset filters',
      'ordersList.filtersButtonText_zero': 'FILTERS',
      'ordersList.filtersButtonText_one': 'FILTERS (1)',
      'ordersList.filtersButtonText_other': 'FILTERS ({{count}})',
      'ordersList.tableSettings.header': 'Column Visibility',

      'trackingEmulator.settings': 'Settings',
      'trackingEmulator.truck': 'Truck',
      'trackingEmulator.delay': 'Delay',
      'trackingEmulator.track': 'Track',
      'trackingEmulator.emulate': 'Emulate',
      'trackingEmulator.cancel': 'Cancel',
      'trackingEmulator.label.driverName': 'Driver name',
      'trackingEmulator.label.truckStatus': 'Truck status',
      'trackingEmulator.label.projectName': 'Project name',
      'trackingEmulator.label.plantName': 'Plant name',
      'trackingEmulator.label.latitude': 'Current location latitude',
      'trackingEmulator.label.longitude': 'Current location longitude',
      'trackingEmulator.label.ticketId': 'Ticket id/number',

      'trackingHistory.search': 'Search Track',
      'trackingHistory.truck': 'Truck #',
      'trackingHistory.date': 'Date',
      'trackingHistory.timeFrom': 'Time From',
      'trackingHistory.timeTo': 'Time To',
      'trackingHistory.load': 'load',

      'trackingMap.mapType': 'Map',
      'trackingMap.satelliteType': 'Satellite',
      'trackingMap.autocenterButtonText': 'Autocenter map',
      'trackingMap.showTraffic': 'Show Traffic',
      'trackingMap.searchPlacesPlaceholder': 'Search address, place, etc.',
      'trackingMap.filters': 'Filters',
      'trackingMap.plants': 'Plants',
      'trackingMap.trucks': 'Trucks',
      'trackingMap.truckInfo': 'Truck',
      'trackingMap.truckNumber': 'Truck Id',
      'trackingMap.latitude': 'lat.',
      'trackingMap.longitude': 'long.',
      'trackingMap.lastSync': 'last sync',
      'trackingMap.truckStatus': 'status',
      'trackingMap.truckOffline': 'Offline',
      'trackingMap.deliveryLatitude': 'Delivery location latitude',
      'trackingMap.deliveryLongitude': 'Delivery location longitude',
      'trackingMap.fieldIsRequired': 'This field is required',
      'trackingMap.updateForm': 'Update',
      'trackingMap.closeForm': 'Close',
      'trackingMap.plantInfo': 'Plant information',
      'trackingMap.plantLatitude': 'Plant location latitude',
      'trackingMap.plantLongitude': 'Plant location longitude',
      'trackingMap.truckLatitude': 'Truck latitude',
      'trackingMap.truckLongitude': 'Truck longitude',
      'trackingMap.Distance': 'Distance to project',
      'trackingMap.Duration': 'ETA time',
      'trackingMap.mapOverlay.placeholder':
        'Please select a project and delivery address',

      'todayOrders.title': `Today's dashboard`,
      'todayOrders.activeOrderTableTitle': 'Orders',
      'todayOrders.viewAllOrdersLink': 'View All Orders',
      'todayOrders.activeTicketsTabLabel': 'Active tickets',
      'todayOrders.availableTrucksTabLabel': 'Available trucks',
      'todayOrders.table.emptyText': 'No orders',

      'todayOrders.availableTrucks.headerTruckDriver': 'Truck & Driver',
      'todayOrders.availableTrucks.headerStatus': 'Status',
      'todayOrders.availableTrucks.headerTicket': 'Ticket',
      'todayOrders.availableTrucks.noData': 'No trucks',
      'todayOrders.availableTrucks.assignToTicketTitle': 'Assign to ticket',
      'todayOrders.availableTrucks.assignTicketButton': 'Assign to ticket',
      'todayOrders.availableTrucks.driverFieldLabel': 'Driver',
      'todayOrders.availableTrucks.nextTicketTitle': 'Next open ticket',
      'todayOrders.availableTrucks.orderFieldName': 'Order Name',
      'todayOrders.availableTrucks.orderFieldNamePlaceholder': 'Select',
      'todayOrders.availableTrucks.addDriverAction': 'Add truck',
      'todayOrders.availableTrucks.createNewTicketAction': 'Create new ticket',
      'todayOrders.availableTrucks.noAvailableTicket': 'No open tickets',

      'ticketsTable.column.ticketNumber': 'Ticket',
      'ticketsTable.column.ticketStatus': 'Status',
      'ticketsTable.column.orderName': 'Order name',
      'ticketsTable.column.plantName': 'Plant',
      'ticketsTable.column.cyProgress': 'CY Progress',
      'ticketsTable.emptyText': 'No tickets',

      'data.unknown': 'Unknown',

      // Onboarding
      'onboarding.title': `Welcome,\n let’s get started!`,
      'onboarding.step1.description.moreSteps': 'Step 1 of 2:  Create Password',
      'onboarding.step1.description': 'Create Password',
      'onboarding.step1.confirmPasswordFieldPlaceholder': 'Confirm password',
      'onboarding.step1.getStartedButton': 'Get Started',
      'onboarding.step1.passwordHelperText':
        '8 character minimum, case sensitive.',
      'onboarding.step2.description': 'Step 2 of 2:  Verify My Information',
      'onboarding.step2.form.companyName.label': 'Company Name',
      'onboarding.step2.form.companyName.placeholder': 'Enter Company Name',
      'onboarding.step2.form.companyPhone.label': 'Company Phone',
      'onboarding.step2.form.companyPhone.placeholder': 'Enter Company Phone',
      'onboarding.step2.form.companyAddress.label': 'Company Address',
      'onboarding.step2.form.companyAddress.placeholder':
        'Enter Company Address',
      'onboarding.step2.completeSetup': 'Complete Setup',

      'onboarding.expiredLink.requestInvitation': 'Request invitation',
      'onboarding.expiredTokenToastTitle': 'EXPIRED LINK',
      'onboarding.expiredTokenToastMessage': 'This link is no longer valid.',

      'userManagement.title': 'User management',
      'userManagement.customerTab': 'Customers',
      'userManagement.customerTab.contactNameColumn': 'Contact name',
      'userManagement.customerTab.emailColumn': 'Email',
      'userManagement.customerTab.phoneColumn': 'Phone',
      'userManagement.customerTab.companyNameColumn': 'Company Name',
      'userManagement.customerTab.actionsTitle': 'Actions',
      'userManagement.customerTab.editAction': 'View/Edit',
      'userManagement.customerTab.deleteAction': 'Delete',
      'userManagement.customerTab.resendInviteAction': 'Resend invite',
      'userManagement.customerTab.loadMore': 'View more customers',
      'userManagement.customerTab.removeTitle': 'Are you sure?',
      'userManagement.customerTab.removeDescription':
        ' Once you delete this customer, it cannot be undone.',
      'userManagement.customerTab.emptyText':
        'No customer have been invited yet.',
      'userManagement.customerTab.inviteCustomer': 'Invite customer',
      'userManagement.driversTab': 'Drivers',
      'userManagement.plantsTab': 'Plant',
      'userManagement.users.header': 'Team members',
      'userManagement.users.form.inviteSuccess':
        'Your user has been sent an invite to join Concretely.',
      'userManagement.users.deleteSuccess':
        '{{userName}} was successfully removed',

      // settings
      'settings.title': 'Settings',
      'settings.companySettingsTab': 'Company Settings',
      'settings.integrationsTab': 'Integrations',
      'settings.userSettingsTab': 'User Settings',
      'settings.companySettings.companyProfile': 'Company profile',
      'settings.companySettings.form.companyNameLabel': 'Company Name',
      'settings.companySettings.form.companyNamePlaceholder':
        'Enter Company Name',
      'settings.companySettings.form.companyAddressLabel': 'Address',
      'settings.companySettings.form.companyAddressPlaceholder':
        'Enter Company Address',
      'settings.companySettings.form.companyPhoneLabel': 'Phone Number',
      'settings.companySettings.form.companyPhonePlaceholder':
        'Type phone number',
      'settings.companySettings.form.companyTimeZoneLabel': 'Timezone',
      'settings.companySettings.form.companyTimeZonePlaceholder':
        'Enter Company Timezone',
      'settings.companySettings.confirmationNotificationsHeader':
        'Order Confirmation Notifications',
      'settings.companySettings.confirmationNotificationsDescription':
        'These are notifications that are sent to remind your customers to confirm their order prior to the delivery start time. By default, a first reminder is sent 24 hours prior to delivery start, and if the order is still unconfirmed 2 hours prior to delivery start a second reminder is sent. You can change those defaults here.',
      'settings.companySettings.changeRequestHeader': 'Change Request Window',
      'settings.companySettings.changeRequestDescription':
        'Your customers can edit their orders, submitting change requests with you for your approval. By default, we close the window for editing orders after the order has been confirmed. You can change that window here.',
      'settings.companySettings.form.changeRequestLabel':
        'Change Request Window',
      'settings.companySettings.form.changeRequestPlaceholder':
        'Select Change Request Window',
      'settings.companySettings.form.changeRequestOption1':
        'Allow change requests until order is confirmed',
      'settings.companySettings.form.changeRequestOption2':
        'Allow change requests until first truck is ticketed',
      'settings.companySettings.form.changeRequestOption3':
        'Allow change requests at all order stages',
      'settings.companySettings.firstReminder': 'First Reminder',
      'settings.companySettings.hoursBeforeOrderStartLabel':
        'Hours before order start',
      'settings.companySettings.hoursBeforeOrderStartPlaceholder':
        'Enter hours before order start',
      'settings.companySettings.secondReminder': 'Second Reminder',
      'settings.companySettings.form.incorrectReminders':
        'The first reminder cannot be set to a time earlier than the second reminder. Please ensure that the second reminder is later than the first reminder.',
      'settings.companySettings.apiDocumentationLabel': 'Show Documentation',
      'settings.companySettings.backendHeader': 'Concretely Back-End',
      'settings.companySettings.apiDocumentationHeader': 'API Documentation',
      'settings.companySettings.apiDocumentationDescription':
        'This setting is to turn the display of API documentation on or off in the provider’s app. By default, it is off. If turned on, the provider will be able to view this documentation by clicking on “API Documentation” in the info menu.',
      'settings.companySettings.truckLocationDataLabel':
        'Save Truck Location Data',
      'settings.companySettings.truckLocationDataHeader': 'Truck Location Data',
      'settings.companySettings.truckLocationDataDescription':
        'This setting is to track and save the truck location data in our database. It is on by default.',

      // products (former 'plant settings')
      'plantSettings.title': 'Products',
      'plantSettings.plants': 'Plants',
      'plantSettings.mixTypesTab': 'Mixes',
      'plantSettings.additivesTab': 'Additives',
      'plantSettings.projectsTab': 'Projects',
      'plantSettings.customerTeamTab': 'Customer Team',
      'plantSettings.tabPlantInfo.addPlantAction': 'Add plant',
      'plantSettings.tabPlantInfo.noData': 'No plants have been added yet.',
      'plantSettings.tabPlantInfo.address': 'Address',
      'plantSettings.tabPlantInfo.batchingSoftwareId': 'Batching software id',
      'plantSettings.editForm.createTitle': 'Add plant',
      'plantSettings.editForm.editTitle': 'Edit plant',
      'plantSettings.editForm.createAction': 'Add plant',
      'plantSettings.editForm.nameField': 'Plant name',
      'plantSettings.editForm.batchingSoftwareId': 'Batching software id',
      'plantSettings.editForm.nameFieldPlaceholder': 'Type name',
      'plantSettings.editForm.addressField': 'Address',
      'plantSettings.editForm.addressFieldPlaceholder': 'Type address',
      'plantSettings.editForm.error.polygonExceedsCircle':
        'Plant polygon zone must be within the circle',
      'plantSettings.editForm.nameFieldDuplicationError':
        'Plant name already exists',
      'plantSettings.editForm.updateSuccessfulMessage':
        'Plant was successfully updated',
      'plantSettings.editForm.createSuccessfulMessage':
        'Plant was successfully created',
      'plantSettings.editForm.removeSuccessfulMessage':
        'Plant was successfully deleted',
      'plantSettings.editForm.removeErrorMessage':
        'Plant was successfully deleted',
      'plantSettings.editForm.GeofencePolygon': 'Polygon',
      'plantSettings.editForm.GeofenceCircle': 'Circle',
      'plantSettings.editForm.plantGeofenceLabel': 'Plant Geofence',
      'plantSettings.editForm.drawGeofenceBoundaryText':
        'Draw geofence boundary using the polygon or circle boundary tool. This geofence is used to determine ticket statuses for trucks, so we recommend your boundary captures all areas that trucks will drive, park, and load at your plant’s property.',
      'plantSettings.editPlant.emptyAddressMapText':
        'Enter plant address to view on map',
      'plantSettings.mapPinAddressLabel': 'Address',
      'plantSettings.removePlant.confirmationDescription':
        'Once you delete it, it cannot be undone',
      'plantSettings.addToMasterList.title': 'Add to Master List',

      // Insights widgets
      'plantSettings.insightsMixTypesMostTitle': 'Most ordered mixes',
      'plantSettings.insightsMixTypesMostDescription_one':
        'This {{count}} mix makes up {{percent}}% of your orders',
      'plantSettings.insightsMixTypesMostDescription_other':
        'These {{count}} mixes make up {{percent}}% of your orders',
      'plantSettings.insightsMixTypesMostDefaultText':
        'We need to gather data from a minimum of 20 orders to report this insight. Encourage your customers to place orders through the system to expedite this reporting.',
      'plantSettings.insightsMixTypesLeastTitle': 'Least ordered mixes',
      'plantSettings.insightsMixTypesLeastDescription_one':
        '{{count}} mix is ordered less than once per month',
      'plantSettings.insightsMixTypesLeastDescription_other':
        '{{count}} mixes are ordered less than once per month',
      'plantSettings.insightsMixTypesLeastDefaultText':
        'We need to gather order data for a minimum of 30 days. During this time, encourage your customers to place orders through the system to contribute to the accuracy of this insight.',
      'plantSettings.insightsMixTypesIndividualProjectsTitle':
        'Individual project mixes',
      'plantSettings.insightsMixTypesIndividualProjectsDescription_one':
        '{{count}} mix is assigned to individual projects. This is not in the master list.',
      'plantSettings.insightsMixTypesIndividualProjectsDescription_other':
        '{{count}} mixes are assigned to individual projects. These are not in the master list.',
      'plantSettings.insightsMixTypesIndividualProjectsEmptyText': `0 mixes are assigned to individual projects.\n\nThese mixes are not in the master list. They can be created in the project’s mix list and will only be available to order in that project.\n\nGo to customer profiles to create projects and customize mix offerings per project.`,
      'plantSettings.insightsIndividualProjectsCustomerProfile':
        'View Customer Profiles',
      'plantSettings.insightsAdditivesTypesMostTitle': 'Most ordered additives',
      'plantSettings.insightsAdditivesTypesMostDescription_one':
        'This {{count}} additive makes up {{percent}}% of your orders',
      'plantSettings.insightsAdditivesTypesMostDescription_other':
        'These {{count}} additives make up {{percent}}% of your orders',
      'plantSettings.insightsAdditivesTypesLeastTitle':
        'Least ordered additives',
      'plantSettings.insightsAdditivesTypesIndividualProjectsTitle':
        'Individual project additives',
      'plantSettings.insightsAdditivesTypesIndividualProjectsDescription_one':
        '{{count}} additive is assigned to individual projects. This is not in the master list.',
      'plantSettings.insightsAdditivesTypesIndividualProjectsDescription_other':
        '{{count}} additives are assigned to individual projects. These are not in the master list.',
      'plantSettings.insightsViewList': 'View List',
      'plantSettings.projectSpecificPanel.addToMasterList':
        'Add to master list',
      'plantSettings.insightsUsageOrders_one': '{{count}} order placed',
      'plantSettings.insightsUsageOrders_other': '{{count}} orders placed',
      'plantSettings.insightsAdditivesTypesMostDefaultText':
        'We need to gather data from a minimum of 20 orders to report this insight. Encourage your customers to place orders through the system to expedite this reporting.',
      'plantSettings.insightsAdditiveTypesLeastDescription_one':
        '{{count}} additive is ordered less than once per month',
      'plantSettings.insightsAdditiveTypesLeastDescription_other':
        '{{count}} additives are ordered less than once per month',
      'plantSettings.insightsAdditiveTypesLeastDefaultText':
        'We need to gather order data for a minimum of 30 days. During this time, encourage your customers to place orders through the system to contribute to the accuracy of this insight.',
      'plantSettings.insightsAdditiveTypesIndividualProjectsEmptyText': `0 additives are assigned to individual projects.\n\nThese additives are not in the master list. They can be created in the project’s additive list and will only be available to order in that project.\n\nGo to customer profiles to create projects and customize additive offerings per project.`,
      'plantSettings.afterHoursNotice.title': 'After-hours Notice',
      'plantSettings.afterHoursNotice.description':
        'These are notifications that are embedded your customers app experience to inform them that your company is currently not operating, or operating after business hours, and manage their expectations regarding response times. You can customize your settings for after-hours notices in the customer’s order form and chat threads.',
      'plantSettings.afterHoursNotice.addButton': 'Add After-Hour Notice',
      'plantSettings.afterHoursNotice.formTitle':
        'After hours notice {{index}}',
      'plantSettings.afterHoursNotice.form.businessDaysLabel': 'Business days',
      'plantSettings.afterHoursNotice.form.businessDaysPlaceholder':
        'Select business days',
      'plantSettings.afterHoursNotice.form.businessHoursStartLabel':
        'Business Hour Start',
      'plantSettings.afterHoursNotice.form.businessHoursStartPlaceholder':
        'Select hours start',
      'plantSettings.afterHoursNotice.form.businessHoursEndLabel':
        'Business Hour End',
      'plantSettings.afterHoursNotice.form.businessHoursEndPlaceholder':
        'Select hours end',
      'plantSettings.afterHoursNotice.form.messageLabel': 'Message',
      'plantSettings.afterHoursNotice.form.messagePlaceholder':
        'Enter a message',
      'plantSettings.afterHoursNotice.form.removeNoticeToast':
        'The notice was successfully removed',
      'plantSettings.afterHoursNotice.removeNoticeConfirmation':
        'Once you delete it, it cannot be undone.',
      'plantSettings.afterHoursNotice.startTimePastEndTime':
        'The hour start time cannot be set to a time later than the hour end time. Please ensure that the start time is earlier than the end time.',
      'plantSettings.afterHoursNotice.endTimeBeforeStartTime':
        'The hour end time cannot be set to a time earlier than the hour start time. Please ensure that the hour end time is later than the hour start time.',

      // Invite customer
      'inviteCustomer.titleCreate': 'Invite Customer',
      'inviteCustomer.titleEdit': 'Edit Customer',
      'inviteCustomer.allFieldsRequiredLabel': 'All Fields Required',
      'inviteCustomer.form.customerNotFound': 'No customer found',
      'inviteCustomer.form.firstNameLabel': 'Contact name',
      'inviteCustomer.form.firstNamePlaceholder': 'First Name',
      'inviteCustomer.form.lastNamePlaceholder': 'Last Name',
      'inviteCustomer.form.emailLabel': 'Email',
      'inviteCustomer.form.emailPlaceholder': 'Email Address',
      'inviteCustomer.form.error.emailIncomplete': 'Email field is incomplete',
      'inviteCustomer.form.phoneLabel': 'Phone',
      'inviteCustomer.form.phonePlaceholder': 'Contact Number',
      'inviteCustomer.form.error.phoneInvalid':
        'Enter phone number in this format: XXX-XXX-XXXX',
      'inviteCustomer.form.companyLabel': 'Company',
      'inviteCustomer.form.companyPlaceholder': 'Company Name',
      'inviteCustomer.form.successSubmit':
        'This customer has been sent an invite to join Concretely',
      'inviteCustomer.resendInvitationMessage': 'Invitation was sent',
      'inviteCustomer.form.error.userExists': 'The user already exists',
      'inviteCustomer.form.error.companyExists': 'The company already exists',

      // chat
      'chat.chatButtonLabel': 'Chat',
      'chat.messageTo': 'Message To',
      'chat.loadMore': 'Load more',
      'chat.notification.newMessageTitle': 'New Message',
      'chat.markAsRead.notice':
        'Marked as read by {{userName}}. This message is only visible to members of {{providerName}}.',
      'chat.markAsRead.buttonTooltip': 'Mark chat as read',
      'chat.markAsRead.successUnreadMessage': 'Chat marked as Unread',

      // customers
      'customers.header': 'Customers',
      'customers.addCustomerButton': 'Add customer',
      'customers.header.showDeactivated': 'Show deactivated',
      'customers.header.hideDeactivated': 'Hide deactivated',
      'customers.addCustomerPopup.title': 'Add customer',
      'customers.addCustomerPopup.companyNameLabel': 'Company Name',
      'customers.addCustomerPopup.companyNamePlaceholder': 'Type name',
      'customers.addCustomerPopup.companyAddressLabel': 'Company Address',
      'customers.addCustomerPopup.companyAddressPlaceholder':
        'Type company address',
      'customers.table.emptySearch': 'No matching company',
      'customers.table.searchFiledPlaceholderCustomers': 'Search Customers',
      'customers.table.searchFiledPlaceholderProviders': 'Search Providers',
      'customers.table.totalCount_one': '{{count}} Company',
      'customers.table.totalCount_other': '{{count}} Companies',
      'customers.table.loadMoreButton': 'Load more customers',
      'customers.table.emptyStateTitleCustomers':
        'You don’t have any customer profiles.',
      'customers.table.emptyStateTitleProviders':
        'You don’t have any provider profiles.',
      'customers.table.emptyStateSubTitle': 'Add a provider to get started.',
      'customers.details.title': 'Overview',
      'customers.details.deactivatedStatus': 'Deactivated',
      'customers.details.companyNameField': 'Company Name',
      'customers.details.deleteTooltip': 'Delete Company',
      'customers.details.deleteConfirmationText':
        'Once you delete this company, it cannot be undone.',
      'customers.details.deleteSuccessToastText':
        '{{companyName}} was successfully deleted',
      'customers.details.deactivateAction': 'Deactivate customer',
      'customers.details.reactivateAction': 'Reactivate customer',
      'customers.details.deactivateActionDescription':
        'Do you intend to proceed with customer deactivation?',
      'customers.details.deactivateActionConfirmation': 'Yes, confirm',
      'customers.details.deactivateActionCancel': 'No, cancel',
      'customers.details.companyNamePlaceholder': 'Enter Company Name',
      'customers.details.officeAddressField': 'Company Address',
      'customers.details.activeSinceField': 'Active since',
      'customers.details.deactivatedField': 'Deactivated',
      'customers.details.reactivateSuccess':
        'Customer was successfully reactivated',
      'customers.details.deactivateSuccess':
        'Customer was successfully deactivated',
      'customers.emptyStateTitle': 'You don’t have any customers profiles.',
      'customers.emptyStateDescription': 'Add a customer to get started.',
      'customers.mixTypes.header': 'Mixes',
      'customers.mixTypes.addButtonText': 'Add mix',
      'customers.mixTypes.table.nameColumnLabel': 'Mix Name',
      'customers.mixTypes.table.categoryColumnLabel': 'Category',
      'customers.mixTypes.table.deleteConfirmationText':
        'Do you intend to proceed with removing the mix from the customer?',
      'customers.mixTypes.table.deleteConfirmationConfirm': 'Yes, Delete',
      'customers.mixTypes.table.deleteConfirmationCancel': 'No, Cancel',
      'customers.mixTypes.table.counter_one':
        '{{currentlyLoaded}} of {{count}} type offered',
      'customers.mixTypes.table.counter_other':
        '{{currentlyLoaded}} of {{count}} types offered',
      'customers.mixTypes.table.emptyState':
        'There are no mixes assigned to this customer profile. Add mixes from your master list to offer this contractor the ability to create their own projects with a standard set of mixes.',
      'customers.mixTypes.notification.successDelete':
        'Mix was successfully deleted',
      'customers.mixTypes.table.loadMoreButtonText': 'View More Mixes',

      'customers.additiveTypes.table.nameColumnLabel': 'Additive Name',
      'customers.additiveTypes.table.categoryColumnLabel': 'Category',
      'customers.additiveTypes.notification.successDelete':
        'Additive was successfully deleted',
      'customers.additiveTypes.table.deleteConfirmationText':
        'Do you intend to proceed with removing the additive from the customer?',
      'customers.additiveTypes.header': 'Additives',
      'customers.additiveTypes.addButtonText': 'Add additive',
      'customers.additiveTypes.table.loadMoreButtonText': 'View More Additives',
      'customers.additiveTypes.table.emptyState':
        'There are no additives assigned to this customer profile. Add additives from your master list to offer this contractor a standard set of additives on their projects.',

      'customers.projects.header': 'Projects',
      'customers.projects.searchProject': 'Search project by name',
      'customers.projects.addButtonText': 'New project',
      'customers.projects.editProject': 'Edit project',
      'customers.projects.table.emptyState':
        'This customer doesn’t have any projects yet.',
      'customers.projects.table.noProjects': 'No projects match your search',
      'customers.projects.table.loadMoreButtonText': 'View More Projects',
      'customers.projects.table.dateColumnLabel': 'Date',
      'customers.projects.table.nameColumnLabel': 'Project',
      'customers.projects.table.addressColumnLabel': 'Address',
      'customers.projects.mixTypes.table.deleteConfirmationText':
        'Do you intend to proceed with removing the mix from the customer?',
      'customers.projects.additiveTypes.table.deleteConfirmationText':
        'Do you intend to proceed with removing the additive from the customer?',
      'customers.projects.mixTypes.table.dispatcherEmptyState':
        'You don’t have any mixes assigned to this project yet. Add mixes to allow your customer to place orders for this project.',
      'customers.projects.mixTypes.table.contractorEmptyState':
        'You don’t have any mixes assigned to this project yet.',
      'customers.projects.additiveTypes.table.dispatcherEmptyState':
        'You don’t have any additives assigned to this project yet. Add additives to allow your customer to place orders for this project.',
      'customers.projects.additiveTypes.table.customerEmptyState':
        'You don’t have any additives assigned to this project yet.',
      'customers.users.header': 'Customer Team',
      'customers.users.inviteButtonText': 'Invite User',
      'customers.users.table.nameColumnLabel': 'Name',
      'customers.users.table.emailColumnLabel': 'Email',
      'customers.users.table.phoneColumnLabel': 'Phone',
      'customers.users.table.providersColumnLabel': 'Providers',
      'customers.users.table.roleColumnLabel': 'Role',
      'customers.users.table.hasAccessToMobile': 'Mobile',
      'customers.users.table.pendingLabel': 'Pending',
      'customers.users.table.emptyState':
        'You don’t have any customer users yet. Add a user to start receiving orders from this customer.',
      'customers.users.table.loadMoreButtonText': 'View More Users',
      'customers.users.table.actionsMenuTitle': 'Actions',
      'customers.users.table.actionsMenuEdit': 'Edit',
      'customers.users.table.actionsMenuDelete': 'Delete',
      'customers.users.table.actionsMenuResendInvite': 'Resend Invite',
      'customers.users.table.deleteConfirmation':
        'Deleting this user will remove their account from the customer application. This user will no longer have access to the app or be able to place orders. Their account can be restored should you invite them to use the application again.',
      'customers.users.table.deleteSuccess': 'User was successfully deleted',
      'customers.users.table.resendInviteSuccess': 'Invitation was sent',
      'customers.users.form.editUser': 'Edit User',
      'customers.users.form.inviteUser': 'Invite User',
      'customers.users.form.inviteButtonText': 'Send Invite',
      'customers.users.form.firstNameLabel': 'First Name',
      'customers.users.form.firstNamePlaceholder': 'First Name',
      'customers.users.form.lastNameLabel': 'Last Name',
      'customers.users.form.lastNamePlaceholder': 'Last Name',
      'customers.users.form.emailLabel': 'Email',
      'customers.users.form.emailPlaceholder': 'Email Address',
      'customers.users.form.phoneLabel': 'Phone',
      'customers.users.form.phonePlaceholder': 'Contact Number (XXX-XXX-XXXX)',
      'customers.users.form.roleLabel': 'Role',
      'customers.users.form.rolePlaceholder': 'Select Role',
      'customers.users.form.providersPlaceholders': 'Providers',
      'customers.users.form.roleAdminValueLabel': 'Admin',
      'customers.users.form.roleAdminValueDescription': 'Access all features',
      'customers.users.form.roleUserValueLabel': 'Standard User',
      'customers.users.form.roleUserValueDescription':
        'Restricted from user management',
      'customers.users.form.editSuccess': '{{ name }} was successfully updated',
      'customers.users.form.createSuccess':
        'This customer has been sent an invite to join Concretely',
      'customers.users.form.discardConfirmation':
        'Do you intend to proceed with the changes discarded?',
      'customers.users.form.inviteSuccessTitle': 'INVITATION SENT',
      'customers.users.existUsers.title': 'This user exists already',
      'customers.users.existUsers.description': `We found an existing user in our database with the same email
      credentials you entered. We’ve updated the credentials with the
      existing user info.`,
      'customers.users.existUsers.sendInvite': 'Ok, send invite',
      'customers.support.headerProviders': 'Provider Accounts',
      'customers.support.newAccountBtn': 'New Account',
      'customers.support.openInstance': 'OPEN INSTANCE',
      'customers.support.initiateAccount': 'INITIATE ACCOUNT',
      'customers.support.newAccountTitle': 'NEW ACCOUNT',
      'customers.support.newAccountSubmit': 'Create Account',
      'customers.support.customerAccountCreatedSuccess':
        '{{providerName}} was successfully created.',
      'customers.support.initiateAccountTitle': 'Initiate account',
      'customers.support.initiateAccountBtn': 'Send first invite',
      'customers.support.initiateAccountDescription':
        'Activate this account by sending an invite to the first user to open their account.',
      'customers.support.initiateAccountSuccess':
        'User has been sent an invite to join Concretely.',
      'customers.support.listEmptySearch': 'No matching accounts',

      // add project
      'addProject.title': 'New Project',
      'addProject.projectsBackButtonLabel': 'Back',
      'addProject.emptyAddressMapText': 'Enter project address to view on map',
      'addProject.addProjectNote':
        'By default, the mixes and additives assigned to this customers’s profile will be available for this project. These offerings can be modified once the project is created.',
      'addProject.confirmButtonLabel': 'Create project',
      'addProject.nameFieldLabel': 'Project name',
      'addProject.nameFieldPlaceholder': 'Project name',
      'addProject.addressFieldLabel': 'Project address',
      'addProject.addressFieldPlaceholder': 'Project address',
      'addProject.deliveryLocationFieldLabel': 'Delivery Location',
      'addProject.deliveryLocationFieldPlaceholder': 'Place pin to get address',
      'addProject.deliveryPinLabel': 'Delivery Pin',
      'addProject.deliveryPinTip':
        'Delivery goes to the Pin coordinates, not address. Place or move pin on map to set delivery coordinates.',
      'addProject.deliveryPinWarning':
        'Address not recognized. Set delivery location as pin on map to proceed. This can be changed later in the project or in any order in this project.',
      'addProject.createNewAddressLabelNoResults':
        'Address not found. Use "{{address}}"',
      'addProject.createNewAddressLabelHasResults': 'Use "{{address}}"',
      'addProject.successCreateMessage':
        '{{ projectName }} was successfully created',
      'addProject.submittedMixes.description':
        'By default this project mix list will contain the standard mixes that are assigned to this customer.',
      'addProject.submittedMixes.fieldTitle': 'Submitted Mixes',
      'addProject.submittedMixes.fieldDescription':
        'Start with a blank slate and set up all your project mixes individually. This will ignore any updates to the customer list.',
      'addProject.submittedAdditive.description':
        'By default this project additive list will contain the standard additives that are assigned to this customer.',
      'addProject.submittedAdditive.fieldTitle': 'Submitted Additives',
      'addProject.submittedAdditive.fieldDescription':
        'Start with a blank slate and set up all your project additives individually',
      'addProject.subscribeTeamMembers.label': 'Subscribe Team Members',
      'addProject.subscribeTeamMembers.placeholder':
        'Search user name or email address',

      // edit project
      'editProject.title': 'Edit Project',

      'projectSettings.title': 'Project Settings',
      'projectSettings.toast.saveSuccess':
        'Project settings were successfully saved',

      'project.newCreated.title': 'New project created',
      'project.archivation.showArchived': 'Show Archived',
      'project.archivation.hideArchived': 'Hide Archived',
      'project.archivation.title': 'PROJECT ARCHIVATION',
      'project.archivation.archiveTooltip': 'Archive Project',
      'project.archivation.archiveSuccess':
        '{{ projectName }} was successfully archived',
      'project.archivation.archivedStatus': 'Archived',
      'project.archivation.unarchiveTooltip': 'Unarchive Project',
      'project.archivation.unarchiveSuccess':
        '{{ projectName }} was successfully unarchived',
      'project.archivation.archiveConfirmationDescription':
        'Are you sure you want to archive this project?',
      'project.archivation.archiveConfirmationButton': 'Yes, Archive',
      'project.archivation.unarchiveConfirmationDescription':
        'Are you sure you want to unarchive this project?',
      'project.archivation.unarchiveConfirmationButton': 'Yes, Unarchive',
      'project.deletion.title': 'PROJECT DELETED',
      'project.deletion.deleteTooltip': 'Delete Project',
      'project.deletion.deleteConfirmationDescription':
        'Once you delete project, it cannot be undone.',
      'project.deletion.deleteSuccess':
        '{{ projectName }} was successfully deleted',

      // project details
      'projectDetails.mixTypesTabLabel': 'Mixes',
      'projectDetails.additiveTypesTabLabel': 'Additives',
      'projectDetails.ordersTabLabel': 'Orders',
      'projectDetails.backButtonLabel': 'Customer profile',
      'projectDetails.projectsBackButtonLabel': 'Projects',
      'projectDetails.overviewLabel': 'Overview',
      'projectDetails.nameField': 'Project Name',
      'projectDetails.nameFieldPlaceholder': 'Project Name',
      'projectDetails.customerField': 'Customer',
      'projectDetails.addressField': 'Project Address',
      'projectDetails.addressFieldPlaceholder': 'Project Address',
      'projectDetails.deliveryLocationField': 'Delivery Location',
      'projectDetails.deliveryLocationDescription':
        'Delivery goes to the Pin coordinates, not address.',
      'projectDetails.successUpdate':
        '{{projectName}} was successfully updated',
      'projectDetails.mapPinAddressLabel': 'Address',
      'projectDetails.addToListButtonLabel': 'Add to list',
      'projectDetails.addToListFieldPlaceholder':
        'Select from master list or create new',
      'projectDetails.mixTypes.addToListFieldLabel': 'Mix name (Project Only)',
      'projectDetails.usageLabel_one': 'Available to {{count}} contractor',
      'projectDetails.usageLabel_other': 'Available to {{count}} contractors',
      'projectDetails.mixTypes.addToListSuccess':
        'The mixes were successfully added',
      'projectDetails.additiveTypes.addToListFieldLabel':
        'Additive Name (Project Only)',
      'projectDetails.additiveTypes.addToListSuccess':
        'The additives were successfully added',
      'projectDetails.orders.backButtonLabel': 'Project orders',

      // mixes
      'mixTypes.header': 'Mixes Master List',
      'mixTypes.searchPlaceholder': 'Search Master List',
      'mixTypes.table.emptySearch': 'No matching mixes',
      'mixTypes.nameFieldLabel': 'Mix Name',
      'mixTypes.nameFieldPlaceholder': 'Enter name',
      'mixTypes.categoryFieldLabel': 'Category',
      'mixTypes.categoryFieldPlaceholder':
        'Select category or type to create a new category',
      'mixTypes.addButtonLabel': 'Add to master list',
      'mixTypes.notification.successAdd': 'Mix was successfully added',
      'mixTypes.notification.successDelete': 'Mix was successfully deleted',
      'mixTypes.notification.successUpdate': 'Mix was successfully updated',
      'mixTypes.table.nameColumnLabel': 'Mix Name',
      'mixTypes.table.categoryColumnLabel': 'Category',
      'mixTypes.table.assignmentColumnLabel': 'Assignment',
      'mixTypes.table.assignmentValue_one': '{{count}} customer',
      'mixTypes.table.assignmentValue_other': '{{count}} customers',
      'mixTypes.table.usageColumnLabel': 'Usage',
      'mixTypes.table.usageValue_one': '{{count}} order placed',
      'mixTypes.table.usageValue_other': '{{count}} orders placed',
      'mixTypes.table.emptyText': 'No mixes have been added yet',
      'mixTypes.table.loadMoreButtonText': 'View More Mixes',
      'mixTypes.deleteConfirmationMessage_zero':
        'This action cannot be undone.',
      'mixTypes.deleteConfirmationMessage_one':
        'Deleting this mix will remove its existing availability to {{count}} customer. This action cannot be undone.',
      'mixTypes.deleteConfirmationMessage_other':
        'Deleting this mix will remove its existing availability to {{count}} customers. This action cannot be undone.',
      'mixTypes.edit.title': 'Edit Mix',
      'mixTypes.add.title': 'Add Mix',
      'mixTypes.add.addButtonLabel': 'Add To Mixes List',
      'mixTypes.add.subtext':
        'The mixes you add to the profile will be the mixes available to this customer to order from your plant in their mobile app order form.',
      'mixTypes.add.availableListHeader': 'Available Mixes',
      'mixTypes.add.selectedListHeader': 'Selected Mixes',
      'mixTypes.add.emptySearchText': 'No mixes found',
      'mixTypes.add.emptyAvailableListText': 'Add Mix to the Master List',
      'mixTypes.add.emptySelectedListText': 'Select Mix First',
      'mixTypes.add.successNotification': 'The mixes were successfully added',
      'mixTypes.projectSpecificPanel.title': 'Project-only mixes',
      'mixTypes.projectSpecificPanel.searchPlaceholder': 'Search project mixes',
      'mixTypes.projectSpecificPanel.noMixesMatching':
        'No matching project-specific mixes',
      'mixTypes.addToMasterList.successNotification':
        '{{mixName}} was successfully added to the master list.',

      // additives
      'additiveTypes.table.nameColumnLabel': 'Additive name',
      'additiveTypes.header': 'Additives Master List',
      'additiveTypes.searchPlaceholder': 'Search Master List',
      'additiveTypes.table.emptySearch': 'No matching additives',
      'additiveTypes.table.categoryColumnLabel': 'Category',
      'additiveTypes.table.assignmentColumnLabel': 'Assignment',
      'additiveTypes.table.usageColumnLabel': 'Usage',
      'additiveTypes.table.emptyText': 'No additives have been added yet',
      'additiveTypes.table.loadMoreButtonText': 'View More Additives',
      'additiveTypes.table.assignmentValue_one': '{{count}} customer',
      'additiveTypes.table.assignmentValue_other': '{{count}} customers',
      'additiveTypes.table.usageValue_one': '{{count}} order placed',
      'additiveTypes.table.usageValue_other': '{{count}} orders placed',
      'additiveTypes.notification.successAdd':
        'Additive was successfully added',
      'additiveTypes.nameFieldLabel': 'Additive Name',
      'additiveTypes.nameFieldPlaceholder': 'Enter name',
      'additiveTypes.categoryFieldLabel': 'Category',
      'additiveTypes.categoryFieldPlaceholder':
        'Select category or type to create a new category',
      'additiveTypes.addButtonLabel': 'Add to master list',
      'additiveTypes.deleteConfirmationMessage_zero':
        'This action cannot be undone.',
      'additiveTypes.deleteConfirmationMessage_one':
        'Deleting this additive will remove its existing availability to {{count}} customer. This action cannot be undone.',
      'additiveTypes.deleteConfirmationMessage_other':
        'Deleting this additive will remove its existing availability to {{count}} customers. This action cannot be undone.',
      'additiveTypes.notification.successDelete':
        'Additive was successfully deleted',
      'additiveTypes.edit.title': 'Edit Additive',
      'additiveTypes.notification.successUpdate':
        'Additive was successfully updated',
      'additiveTypes.add.title': 'Add Additive',
      'additiveTypes.add.addButtonLabel': 'Add To Additives List',
      'additiveTypes.add.subtext':
        'The additives you add to the profile will be the additives available to this customer to order from your plant in their mobile app order form.',
      'additiveTypes.add.availableListHeader': 'Available Additives',
      'additiveTypes.add.selectedListHeader': 'Selected Additives',
      'additiveTypes.add.emptySearchText': 'No additives found',
      'additiveTypes.add.emptyAvailableListText':
        'Add Additive to the Master List',
      'additiveTypes.add.emptySelectedListText': 'Select Additive First',
      'additiveTypes.add.successNotification':
        'The additives were successfully added',
      'additiveTypes.addToMasterList.successNotification':
        '{{additiveName}} was successfully added to the master list.',
      'additiveTypes.viewProjectSpecificAdditives':
        'View project-only additives',
      'additiveTypes.projectSpecificPanel.title': 'Project-only additives',
      'additiveTypes.projectSpecificPanel.searchPlaceholder':
        'Search project additives',
      'additiveTypes.projectSpecificPanel.noAdditivesMatching':
        'No matching project-specific additives',
      'additiveTypes.projectSpecificPanel.addToMasterList':
        'Add to master list',

      // customers profile settings
      'customers.profile.lists.availableCount_one':
        'Available to {{count}} customer',
      'customers.profile.lists.availableCount_other':
        'Available to {{count}} customers',

      'customers.project.orders.title': 'Orders',

      // Required Actions Panel
      'requiredActionsPanel.triggerButton.tooltip': 'Notifications',
      'requiredActionsPanel.title': 'Notifications',
      'requiredActionsPanel.noActions': 'No Actions',
      'requiredActionsPanel.sortingLabel.oldest': 'Oldest',
      'requiredActionsPanel.sortingLabel.newest': 'Newest',
      'requiredActionsPanel.sortingLabel.urgency': 'Urgency',
      'requiredActionsPanel.unreadNotifications_one':
        'You have {{count}} new notification',
      'requiredActionsPanel.unreadNotifications_other':
        'You have {{count}} new notifications',

      // Required Actions Banner
      'requiredActionsBanner.requiredActionsTitle_one':
        'You have {{count}} required action',
      'requiredActionsBanner.requiredActionsTitle_other':
        'You have {{count}}{{suffix}} required actions',
      'requiredActionsBanner.viewAlerts': 'View Alerts',

      // Required Actions Items
      'requiredActions.orderRequested.title': 'New order requested',
      'requiredActions.orderRequested.text':
        '{{companyName}} requested a new order on {{projectName}} for {{dateAndTime}}',
      'requiredActions.orderRequested.firstAction': 'View Order to Approve',
      'requiredActions.orderCancelled.title': 'Order canceled',
      'requiredActions.orderCancelled.text':
        '{{companyName}} has requested to cancel {{orderName}}. Acknowledgement is required.',
      'requiredActions.orderCancelled.firstAction': 'View to Confirm',
      'requiredActions.orderCancelledContractor.text':
        '{{providerName}} canceled your order {{orderName}}.',
      'requiredActions.orderCancelConfirm.text':
        'Your cancellation notice has been confirmed. {{orderName}} is canceled',
      'requiredActions.orderDeclined.title': 'Order declined',
      'requiredActions.orderDeclined.text':
        '{{providerName}} has declined your request for {{orderName}}. Your order will not be fulfilled.',
      'requiredActions.projectCreated.title': 'Project created',
      'requiredActions.projectCreated.text':
        '{{companyName}} has created project {{projectName}}.',
      'requiredActions.projectCreated.secondAction': 'View Project',
      'requiredActions.orderCallbackSubmitted.firstAction': 'View Call Back',
      'requiredActions.orderChangesPartiallyApproved.title':
        'Changes Partially Approved',
      'requiredActions.orderChangesPartiallyApproved.text':
        '{{providerName}} partially approved changes requested on order {{orderName}} for {{dateAndTime}}.',
      'requiredActions.orderChangesApproved.title': 'Changes Approved',
      'requiredActions.orderChangesApproved.text':
        '{{providerName}} approved changes requested on order {{orderName}} for {{dateAndTime}}',
      'requiredActions.orderChangesRequested.title': 'Changes requested',
      'requiredActions.orderChangesRequested.text':
        '{{companyName}} requested changes on {{orderName}} for {{dateAndTime}}',
      'requiredActions.orderChangesRequested.firstAction':
        'View Order to Approve',
      'requiredActions.orderCallback.title': 'Order Ready to Close',
      'requiredActions.orderCallback.text':
        '{{orderName}} delivery seems to be complete. Check status and close order.',
      'requiredActions.orderCallback.secondAction': 'Close Order',
      'requiredActions.orderConfirmed.title': 'Order confirmed',
      'requiredActions.orderConfirmed.text':
        '{{companyName}} has confirmed order {{orderName}}.',
      'requiredActions.orderApproved.title': 'Order Approved',
      'requiredActions.orderApproved.text':
        '{{providerName}} approved order {{orderName}} for {{dateAndTime}}.',
      'requiredActions.orderUpdated.title': 'Order Updated',
      'requiredActions.orderUpdated.text':
        '{{providerName}} made updates to order {{orderName}}.',
      'requiredActions.callbackNeeded.firstActionLabel': 'No Call Back',
      'requiredActions.callbackNeeded.secondActionLabel': 'Send Call Back',
      'requiredActions.orderChangesRejected.title': 'Changes Rejected',
      'requiredActions.orderChangesRejected.text':
        '{{providerName}} rejected changes requested on order {{orderName}} for {{dateAndTime}}.',
      'requiredActions.orderConfirmationNeeded.firstActionLabel':
        'View Order to Confirm',
      'requiredActions.toast.markedAsRead': 'Notification marked as read',
      'requiredActions.subscribedToProject.title': 'You’re Subscribed',
      'requiredActions.subscribedToProject.text':
        'You’ve been subscribed to {{projectName}}.',
      'requiredActions.subscribedToProject.secondAction': 'View Project',
      'requiredActions.subscribedToOrder.title': `You're subscribed`,
      'requiredActions.subscribedToOrder.text': `You've been subscribed to {{orderName}} for {{dateAndTime}}`,

      // Dialogs Panel
      'dialogsPanel.triggerButton.tooltip': 'Messages',
      'dialogsPanel.title': 'Messages',
      'dialogsPanel.noMessages': 'No Messages',
      'dialogsPanel.noSearchResult': 'No matching message',
      'dialogsPanel.searchPlaceholder': 'Search',
      'dialogsPanel.myOrdersTab': 'My orders',
      'dialogsPanel.allTab': 'All',

      // SUBSCRIBE TEAM
      'subscribeTeamMembers.dialog.title': 'Subscribe team members',
      'subscribeTeamMembers.dialog.projectDescription':
        'Subscribe team members to all orders placed in this project so they can view those orders in their “My Orders” tab and receive updates. You can always edit these subscriptions later on this project or in any order placed on this project.',
      'subscribeTeamMembers.dialog.orderDescription':
        'Subscribe team members to this order so they can view this order in their “My Orders” tab and receive updates. You can always edit these subscriptions later in your order details.',
      'subscribeTeamMembers.successMessage':
        'The team members’ subscription list has been successfully updated.',
      'subscribeTeamMembers.inputSearchLabel': 'Search Team Members',
      'subscribeTeamMembers.inputSearchPlaceholder':
        'Search user name or email address',
      'subscribeTeamMembers.submitButton': 'Subscribe',
      'subscribeTeamMembers.emptyList': 'No team members subscribed yet',

      //ticket
      'ticket.createSuccessText': 'The ticket was successfully created',
      'ticket.deleteSuccessText': 'The ticket was successfully removed',

      // docs
      'docs.whatsNew': "What's new",
      'docs.emptyStateText': 'Select some help article to learn',
    },
  },
  uk: {
    translation: {},
  },
};

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(Backend)
  .init({
    resources,
    debug: process.env.I18N_DEBUG === 'true',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18next;
