import { useMemo } from 'react';

import { APIOrderStatus } from '@cd3p/core/types/api';
import { simplifyBuilder } from '@cd3p/core/utils/sra';

import { bindActionCreators, useDispatch, useSelector } from 'third-party';

import {
  OrdersFilters,
  OrdersListState,
  loadMoreOrders,
  loadOrders,
  ordersDefaultFilters,
  ordersDefaultListState,
  resetOrderList,
  updateOrderPosition,
  updateOrdersListFilters,
  updateOrdersListSearch,
  updateOrdersListSorting,
} from 'modules/common/ordersList';

import { appSelectors, projectOrdersListSelectors } from 'selectors';

export type ProjectOrdersListState = OrdersListState & {
  filters: OrdersFilters;
  loadProjectOrdersPending: boolean;
  loadMoreProjectOrdersPending: boolean;
  projectOrdersListErrorText: string | null;
};

export const projectOrdersListDefaultFilters = {
  ...ordersDefaultFilters,
  status: {
    [APIOrderStatus.Unconfirmed]: true,
    [APIOrderStatus.Cancelled]: true,
    [APIOrderStatus.Confirmed]: true,
    [APIOrderStatus.Delivering]: true,
    [APIOrderStatus.Completed]: true,
    [APIOrderStatus.Requested]: true,
    [APIOrderStatus.Declined]: true,
  },
};

export const projectOrdersListState: ProjectOrdersListState = {
  ...ordersDefaultListState,
  filters: projectOrdersListDefaultFilters,
  loadProjectOrdersPending: false,
  loadMoreProjectOrdersPending: false,
  projectOrdersListErrorText: null,
  search: {
    pattern: '',
    columns: ['deliveryDate', 'orderName', 'volume', 'orderStatus'],
  },
};

const builder = simplifyBuilder(projectOrdersListState, {});

export const LOAD_PROJECT_ORDERS_ACTION = 'loadProjectOrders';
const loadProjectOrders = builder.createServerAction(
  loadOrders(LOAD_PROJECT_ORDERS_ACTION),
);

export const LOAD_MORE_PROJECT_ORDERS_ACTION = 'loadMoreProjectOrders';
const loadMoreProjectOrders = builder.createServerAction(
  loadMoreOrders(LOAD_MORE_PROJECT_ORDERS_ACTION),
);

export const UPDATE_ORDER_POSITION_IN_PROJECT_ORDERS_ACTION =
  'updateOrderPositionInProjectOrders';
const updateOrderPositionInProjectOrders = builder.createServerAction(
  updateOrderPosition(UPDATE_ORDER_POSITION_IN_PROJECT_ORDERS_ACTION),
);

const updateProjectOrdersListSorting = builder.createReduxAction(
  updateOrdersListSorting('updateProjectOrdersListSorting'),
);

const updateProjectOrdersListFilters = builder.createReduxAction(
  updateOrdersListFilters('updateProjectOrdersListFilters'),
);

const resetProjectOrdersList = builder.createReduxAction(
  resetOrderList('clearAllOrdersInState'),
);

const updateProjectOrdersSearch = builder.createReduxAction(
  updateOrdersListSearch('updateProjectOrdersSearch'),
);

export const resetProjectOrdersSearch = builder.createReduxAction(() => ({
  name: 'resetProjectOrdersSearch',
  updater: () => ({
    search: projectOrdersListState.search,
  }),
}));

export const useProjectOrdersList = () => {
  const dispatch = useDispatch();
  const providerId = useSelector(appSelectors.providerId);

  return useMemo(
    () =>
      bindActionCreators(
        {
          loadProjectOrders: loadProjectOrders.bind(null, providerId),
          loadMoreProjectOrders: loadMoreProjectOrders.bind(
            null,
            providerId,
            projectOrdersListSelectors.projectOrderListState,
            projectOrdersListSelectors.projectOrdersListFilters,
          ),
          updateOrder: updateOrderPositionInProjectOrders.bind(
            null,
            providerId,
          ),
          updateOrderPositionInProjectOrders:
            updateOrderPositionInProjectOrders.bind(
              null,
              providerId,
              projectOrdersListSelectors.projectOrderListState,
              projectOrdersListSelectors.projectOrdersListFilters,
            ),
          // without binding TS doesn't infer type for module functions
          updateProjectOrdersListSorting:
            updateProjectOrdersListSorting.bind(null),
          updateProjectOrdersListFilters:
            updateProjectOrdersListFilters.bind(null),
          resetProjectOrdersList: resetProjectOrdersList.bind(null),
          updateProjectOrdersSearch: updateProjectOrdersSearch.bind(null),
          resetProjectOrdersSearch: resetProjectOrdersSearch.bind(null),
        },
        dispatch,
      ),
    [dispatch, providerId],
  );
};

export const projectOrdersListReducer = builder.getReducers();
