import React, { useEffect, useMemo, useState } from 'react';

import { APIUserType } from '@cd3p/core/types/api';
import { MapOverlay, Stack, TrackingMap } from 'components';
import { useAfterHoursNotice } from 'hooks/useAfterHoursNotice';
import { subscribe } from 'hooks/usePubSub';

import {
  ContentWrapper,
  Inner,
  LeftPanelWrapper,
  OrderContainer,
} from './orderViewHelpers';

import { _, useSelector, useTranslation } from 'third-party';

import { APP_EVENTS } from 'constants/appEvent';

import { appSelectors } from 'selectors';

import { MapTypes } from 'components/TrackingMap/TrackingMap';

import { OrderCreateLeftPanel } from 'features/OrderView/OrderCreateLeftPanel';

import { LocationCoordinates, LocationT } from 'types/app';

export const CreateOrderView = () => {
  const { t } = useTranslation();
  const [mapSites, setMapSites] = useState<LocationT[]>([]);
  const [manualDeliveryLocation, setManualDeliveryLocation] =
    useState<LocationCoordinates | null>(null);
  const userType = useSelector(appSelectors.userType);

  const { afterHoursNotice, showAfterHoursNotice } = useAfterHoursNotice({
    enabled: userType === APIUserType.Contractor,
  });

  const provider = useSelector(appSelectors.provider);

  const mapDefaultCenter = useMemo(
    () => ({
      lat: provider?.centroidLatitude || 0,
      lng: provider?.centroidLongitude || 0,
    }),
    [provider],
  );

  useEffect(() => {
    return subscribe(
      APP_EVENTS.UPDATE_PIN_LOCATION,
      (address: LocationT | null) => {
        setMapSites(address ? [address] : []);
      },
    );
  }, []);

  return (
    <OrderContainer>
      <Inner container>
        <Stack>
          {afterHoursNotice}
          <LeftPanelWrapper paddingTop={showAfterHoursNotice ? 1.5 : undefined}>
            <OrderCreateLeftPanel
              manualDeliveryLocation={manualDeliveryLocation}
            />
          </LeftPanelWrapper>
        </Stack>
        <ContentWrapper>
          <MapOverlay
            enabled={_.isEmpty(mapSites)}
            text={t('trackingMap.mapOverlay.placeholder')}
          >
            <TrackingMap
              plants={[]}
              sites={mapSites}
              center={!_.isEmpty(mapSites) ? undefined : mapDefaultCenter}
              isLoading={false}
              trucks={[]}
              isSitePinDraggable
              onManualDeliveryLocationChange={setManualDeliveryLocation}
              defaultMapType={MapTypes.Hybrid}
            />
          </MapOverlay>
        </ContentWrapper>
      </Inner>
    </OrderContainer>
  );
};
