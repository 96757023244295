import {
  COMMUNICATIONS_API_PATH,
  ORDERS_API_PATH,
} from '@cd3p/core/constants/common';
import { getChatEndpoint } from '@cd3p/core/constants/endpoints';

export const API_PATH = process.env['API_PATH'];

export const providerEndpoint = (providerId: string) =>
  `${ORDERS_API_PATH}provider/${providerId}/`;

export const userEndpoint = (providerId: string, userId?: string) =>
  `${providerEndpoint(providerId)}user${userId ? `/${userId}` : ''}`;

export const deleteUserEndpoint = (providerId: string, userId: string) =>
  `${userEndpoint(providerId, userId)}/delete`;
export const resendUserInviteEndpoint = (providerId: string, userId: string) =>
  `${userEndpoint(providerId, userId)}/resend_invitation`;
export const usersSearchEndpoint = (providerId: string) =>
  `${providerEndpoint(providerId)}user/search`;

export const usersEndpoint = (providerId: string) =>
  `${providerEndpoint(providerId)}user`;
export const userMeEndpoint = () => `${ORDERS_API_PATH}user/me`;
export const userAuthEndpoint = () => `${ORDERS_API_PATH}user/auth`;
export const resendInvitationEndpoint = (providerId: string, userId: string) =>
  `${providerEndpoint(providerId)}user/${userId}/resend_invitation`;
export const importOrdersEndpoint = (providerId: string) =>
  `${providerEndpoint(providerId)}order/import`;
export const downloadImportOrdersSampleFileEndpoint = (providerId: string) =>
  `${providerEndpoint(providerId)}order/import_example`;
export const fetchProjectsListEndpoint = (providerId: string) =>
  `${providerEndpoint(providerId)}project/search`;
export const projectEndpoint = (providerId: string, projectId?: string) =>
  `${providerEndpoint(providerId)}project${projectId ? `/${projectId}` : ''}`;
export const projectMixTypeEndpoint = (providerId: string, projectId: string) =>
  `${projectEndpoint(providerId, projectId)}/mix-type`;
export const projectAvailableMixTypesEndpoint = (
  providerId: string,
  projectId: string,
) => `${projectEndpoint(providerId, projectId)}/available-mix-type/search`;
export const projectAdditiveTypeEndpoint = (
  providerId: string,
  projectId: string,
) => `${projectEndpoint(providerId, projectId)}/additive`;
export const projectAvailableAdditiveTypesEndpoint = (
  providerId: string,
  projectId: string,
) => `${projectEndpoint(providerId, projectId)}/available-additive/search`;
export const fetchOrdersListEndpoint = (providerId: string) =>
  `${providerEndpoint(providerId)}order`;
export const orderSearchEndpoint = (providerId: string) =>
  `${providerEndpoint(providerId)}order/search`;
export const orderBatchNumberEndpoint = (providerId: string, id: number) =>
  `${providerEndpoint(providerId)}order/${id}/batch-number`;
export const orderCallbackEndpoint = (providerId: string, id: number) =>
  `${providerEndpoint(providerId)}order/${id}/callback-form`;
export const orderRequestedChangesEndpoint = (providerId: string, id: number) =>
  `${providerEndpoint(providerId)}order/${id}/changes`;
export const orderNotificationEndpoint = (providerId: string, id: number) =>
  `${providerEndpoint(providerId)}order/${id}/notification`;
export const plantsEndpoint = (providerId: string) =>
  `${providerEndpoint(providerId)}plant`;
export const loadPlantsEndpoint = (providerId: string) =>
  `${plantsEndpoint(providerId)}/search`;
export const getPlantUrl = (providerId: string, plantId: string) =>
  `${plantsEndpoint(providerId)}/${plantId}`;
export const removePlantUrl = (providerId: string, plantId: string) =>
  `${plantsEndpoint(providerId)}/${plantId}/delete`;
export const driversEndpoint = (providerId: string) =>
  `${providerEndpoint(providerId)}drivers`;
export const availableTracksEndpoint = (providerId: string) =>
  `${providerEndpoint(providerId)}available-tracks`;
export const emulateTruckTrackEndpoint = (providerId: string) =>
  `${providerEndpoint(providerId)}emulate-truck-tracking`;

export const cancelTruckTrackEndpoint = (
  providerId: string,
  driverId: string,
) =>
  `${providerEndpoint(providerId)}cancel-truck-tracking?driverId=${driverId}`;

export const typeaheadEndpoint = (
  providerId: string,
  model: 'project' | 'order' | 'company' | 'data-category',
) => `${providerEndpoint(providerId)}${model}/typeahead`;

export const createDataCategoryEndpoint = (providerId: string) =>
  `${providerEndpoint(providerId)}data-category/`;

export const addressTypeaheadEndpoint = (providerId: string) =>
  `${providerEndpoint(providerId)}map/address_typeahead`;

export const assignedDriversListEndpoint = (
  providerId: string,
  orderId: number,
) => `${providerEndpoint(providerId)}order/${orderId}/assigned-driver`;

export const availableToAssignDriversListEndpoint = (
  providerId: string,
  orderId: number,
) =>
  `${providerEndpoint(
    providerId,
  )}order/${orderId}/assigned-driver/available-drivers`;

export const assignedDriverEndpoint = (
  providerId: string,
  orderId: number,
  assignedDriverId: number,
) =>
  `${providerEndpoint(
    providerId,
  )}order/${orderId}/assigned-driver/${assignedDriverId}`;

export const notifyAssignedDriversEndpoint = (
  providerId: string,
  orderId: number,
) =>
  `${providerEndpoint(
    providerId,
  )}order/${orderId}/assigned-driver/notification`;

export const ticketEndpoint = (
  providerId: string,
  orderId: number | string,
  ticketId?: number | string,
) =>
  `${providerEndpoint(providerId)}order/${orderId}/ticket${
    ticketId ? `/${ticketId}` : ''
  }`;

export const ticketsSearchEndpoint = (providerId: string) =>
  `${providerEndpoint(providerId)}ticket`;

export const trackingEndpoint = (providerId: string) =>
  `${providerEndpoint(providerId)}tracking`;

export const trackingByIdEndpoint = (providerId: string, truckId: string) =>
  `${providerEndpoint(providerId)}tracking/${truckId}`;

export const companyEndpoint = (providerId: string, companyId?: string) =>
  `${providerEndpoint(providerId)}company${companyId ? `/${companyId}` : ''}`;

export const mixTypeEndpoint = (providerId: string, mixTypeId?: number) =>
  `${providerEndpoint(providerId)}mix-type/${mixTypeId ? `${mixTypeId}/` : ''}`;

export const loadMixTypesEndpoint = (providerId: string) =>
  `${providerEndpoint(providerId)}mix-type/search/`;

export const loadMixTypesAssignmentEndpoint = (
  providerId: string,
  mixTypeId?: number,
) => `${providerEndpoint(providerId)}mix-type/${mixTypeId}/assignment`;

export const loadMostUsedMixTypesEndpoint = (providerId: string) =>
  `${providerEndpoint(providerId)}mix-type/most-used`;

export const loadLeastUsedMixTypesEndpoint = (providerId: string) =>
  `${providerEndpoint(providerId)}mix-type/least-used`;

export const loadMixTypesCategoriesByCompanyEndpoint = (
  providerId: string,
  companyId: string,
) => `${companyEndpoint(providerId, companyId)}/available-mix-type/search/`;

export const contractorMixTypeEndpoint = (
  providerId: string,
  companyId: string,
  mixTypeId?: number,
) =>
  `${companyEndpoint(providerId, companyId)}/mix-type/${
    mixTypeId ? `${mixTypeId}/` : ''
  }`;

export const additiveTypeEndpoint = (
  providerId: string,
  additiveTypeId?: number,
) =>
  `${providerEndpoint(providerId)}additive/${
    additiveTypeId ? `${additiveTypeId}/` : ''
  }`;

export const loadAdditiveTypesEndpoint = (providerId: string) =>
  `${providerEndpoint(providerId)}additive/search/`;

export const loadAdditiveTypeAssignmentEndpoint = (
  providerId: string,
  additiveTypeId?: number,
) => `${providerEndpoint(providerId)}additive/${additiveTypeId}/assignment`;

export const loadMostUsedAdditiveTypesEndpoint = (providerId: string) =>
  `${providerEndpoint(providerId)}additive/most-used`;

export const loadLeastUsedAdditiveTypesEndpoint = (providerId: string) =>
  `${providerEndpoint(providerId)}additive/least-used`;

export const customersEndpoint = (providerId: string) =>
  `${providerEndpoint(providerId)}company/search/`;

export const customerMixTypesEndpoint = (
  providerId: string,
  companyId: string,
) => `${providerEndpoint(providerId)}company/${companyId}/mix-type/search/`;

const customerProjectEndpoint = (providerId: string, projectId: string) =>
  `${providerEndpoint(providerId)}project/${projectId}`;

export const customerProjectMixTypeEndpoint = (
  providerId: string,
  projectId: string,
  mixTypeId?: number,
) =>
  `${customerProjectEndpoint(providerId, projectId)}/mix-type/${
    mixTypeId ? `${mixTypeId}/` : ''
  }`;
export const customerProjectMixTypesEndpoint = (
  providerId: string,
  projectId: string,
) => `${providerEndpoint(providerId)}project/${projectId}/mix-type/search/`;

export const contractorAdditiveEndpoint = (
  providerId: string,
  companyId: string,
  additiveTypeId?: number,
) =>
  `${companyEndpoint(providerId, companyId)}/additive/${
    additiveTypeId ? `${additiveTypeId}/` : ''
  }`;

export const contractorAdditivesEndpoint = (
  providerId: string,
  companyId: string,
) => `${providerEndpoint(providerId)}company/${companyId}/additive/search/`;

export const customerProjectAdditiveTypeEndpoint = (
  providerId: string,
  projectId: string,
  mixTypeId?: number,
) =>
  `${customerProjectEndpoint(providerId, projectId)}/additive/${
    mixTypeId ? `${mixTypeId}/` : ''
  }`;
export const customerProjectAdditiveTypesEndpoint = (
  providerId: string,
  projectId: string,
) => `${providerEndpoint(providerId)}project/${projectId}/additive/search/`;

export const loadAdditivesCategoriesByCompanyEndpoint = (
  providerId: string,
  companyId: string,
) => `${companyEndpoint(providerId, companyId)}/available-additive/search/`;

export const requiredActionsEndpoint = (providerId: string) =>
  `${COMMUNICATIONS_API_PATH}provider/${providerId}/notifications`;

export const markRequiredActionAsReadEndpoint = (
  providerId: string,
  notificationId: string,
) =>
  `${COMMUNICATIONS_API_PATH}provider/${providerId}/notifications/${notificationId}/read`;

export const getDialogsUnreadCountEndpoint = (providerId: string) =>
  `${getChatEndpoint(providerId)}unread-count`;

export const initiateAdminEndpoint = (providerId: string) =>
  `${providerEndpoint(providerId)}initiate-admin`;
export const addProviderEndpoint = () => `${ORDERS_API_PATH}provider`;
export const providersListEndpoint = () => `${ORDERS_API_PATH}provider/search`;
