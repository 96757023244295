import React, { useCallback, useEffect, useMemo, useState } from 'react';

import styled from 'styled-components';

import {
  APIAccessLevel,
  APIUser,
  APIUserRequest,
  APIUserType,
} from '@cd3p/core/types/api';
import { emailField, phoneField } from '@cd3p/core/utils/fields';
import { Stack } from '@mui/material';
import {
  Button,
  ConfirmationDialog,
  FormCheckbox,
  FormField,
  LoadingButton,
  MenuItem,
  MultiSelectDropdown,
  Popover,
  PopoverCloseButton,
  Typography,
} from 'components';

import { UserExistsPopup } from './UserExistsPopup';

import {
  FormProvider,
  useBeforeUnload,
  useForm,
  useSelector,
  useTranslation,
} from 'third-party';

import { appSelectors } from 'selectors';

import { EmailSearchField } from 'features/Fields';

import { TypeaheadDataOption } from 'types/app';

const FormWrapper = styled.form`
  position: relative;
  width: 50rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-height: 0;
  flex-grow: 1;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  padding: 2.2rem 2.3rem 0;
  z-index: 1;
`;

const Title = styled(Typography)`
  line-height: 100%;
  color: ${props => props.theme.custom.palette.primary900};
  margin-bottom: 1.5rem;
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1.6rem;
  margin-bottom: 2.3rem;
`;

const Content = styled(Stack)`
  flex-grow: 1;
  overflow: auto;
  padding: 2.2rem 2.3rem 2.7rem;
`;

const MultiSelectDropdownStyle = styled.div`
  padding: 1.6rem 0;
`;

enum InviteEditUserFormFields {
  FirstName = 'firstName',
  LastName = 'lastName',
  Email = 'email',
  Phone = 'phone',
  Role = 'accessLevel',
  HasAccessToMobileApp = 'hasAccessToMobileApp',
  Providers = 'providerIds',
}

type InviteEditUserEmailFormField = string;
type InviteEditUserTypeaheadEmailFormField =
  TypeaheadDataOption<APIUser> | null;

const emptyString = '' as const;

export type InviteEditUserFormT<EmailFormFieldT extends boolean | undefined> = {
  [InviteEditUserFormFields.FirstName]: string;
  [InviteEditUserFormFields.LastName]: string;
  [InviteEditUserFormFields.Email]: EmailFormFieldT extends true
    ? InviteEditUserTypeaheadEmailFormField
    : InviteEditUserEmailFormField;
  [InviteEditUserFormFields.Phone]: string;
  [InviteEditUserFormFields.Role]: APIAccessLevel | typeof emptyString;
  [InviteEditUserFormFields.HasAccessToMobileApp]: boolean;
  [InviteEditUserFormFields.Providers]: string[];
};

type IsFormWithEmailTypeahedSearchType = undefined | boolean;

type Props = {
  onClosePopup?: () => void;
  userTypeToCreate: APIUserType;
  open?: boolean;
  showUserExistPopup?: boolean;
  setShowUserExistPopup?: (showUserExistPopup: boolean) => void;
  user: APIUser | null;
  onSubmit: (user: Partial<APIUserRequest>) => void;
  isFormPending?: boolean;
  isFormWithEmailTypeahedSearch?: IsFormWithEmailTypeahedSearchType;
};

export const InviteEditUserPopup: React.FC<Props> = ({
  onClosePopup,
  onSubmit,
  open = false,
  user,
  userTypeToCreate,
  isFormPending,
  isFormWithEmailTypeahedSearch,
  showUserExistPopup,
  setShowUserExistPopup,
}) => {
  const { t } = useTranslation();
  const currentUserId = useSelector(appSelectors.userId);
  const isRoleEditDisabled = isFormPending || user?.id === currentUserId;
  const currentUserProviders = useSelector(appSelectors.userProviders);
  const isContractorCreation = userTypeToCreate === APIUserType.Contractor;
  const currentUserType = useSelector(appSelectors.userType);

  const isMultiProvidersUser = useMemo(
    () => Number(currentUserProviders?.length) > 1,
    [currentUserProviders],
  );
  const isProvidersFieldVisible = !(
    currentUserType === APIUserType.Dispatcher &&
    (!isMultiProvidersUser || isContractorCreation)
  );

  const notAvailableProviders = useMemo(() => {
    return (
      user?.providers
        ?.filter(its => !currentUserProviders?.find(it => it.id == its.id))
        .map(it => it.id) || []
    );
  }, [user?.providers, currentUserProviders]);

  const userProviderOptions = useMemo(() => {
    return (
      currentUserProviders?.map(provider => ({
        key: provider.id || '',
        value: provider.providerName || '',
      })) || []
    );
  }, [currentUserProviders]);

  const defaultProviderSelected = useMemo(() => {
    return (
      user?.providers?.filter(
        its => !notAvailableProviders.find(it => it == its.id),
      ) || []
    );
  }, [notAvailableProviders, user?.providers]);

  const defaultValues: InviteEditUserFormT<
    typeof isFormWithEmailTypeahedSearch
  > = useMemo(() => {
    return {
      [InviteEditUserFormFields.FirstName]: '',
      [InviteEditUserFormFields.LastName]: '',
      [InviteEditUserFormFields.Email]: '',
      [InviteEditUserFormFields.Phone]: '',
      [InviteEditUserFormFields.Role]: '' as const,
      [InviteEditUserFormFields.HasAccessToMobileApp]: false,
      [InviteEditUserFormFields.Providers]: [],
    };
  }, []);

  const formMethods = useForm<
    InviteEditUserFormT<typeof isFormWithEmailTypeahedSearch>
  >({
    mode: 'onChange',
    defaultValues: user
      ? normalizeUserForForm(user, isFormWithEmailTypeahedSearch)
      : defaultValues,
  });

  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty, errors, touchedFields },
    reset,
    watch,
    setValue,
    getValues,
  } = formMethods;
  useBeforeUnload(isDirty, t('common.exitConfirmationWindow.text'));

  const selectedRole = watch(InviteEditUserFormFields.Role);

  const emailFieldRules = useMemo(() => {
    return {
      validate: {
        incorrectEmail: (
          option: TypeaheadDataOption<APIUser> | null | string,
        ) => {
          const optionValue =
            typeof option === 'string' ? option : option?.value;
          return (
            emailField.validate(optionValue || '') ||
            t('inviteCustomer.form.error.emailIncomplete')
          );
        },
      },
    };
  }, [t]);

  const phoneFieldRules = useMemo(() => {
    return {
      validate: {
        incorrectPhone: (value: string) =>
          !value ||
          phoneField.validate(value) ||
          t('inviteCustomer.form.error.phoneInvalid'),
      },
    };
  }, [t]);

  useEffect(() => {
    if (user) {
      reset(normalizeUserForForm(user, isFormWithEmailTypeahedSearch));
    } else {
      reset(defaultValues);
    }
  }, [reset, user, open, defaultValues, isFormWithEmailTypeahedSearch]);

  const onFormSubmit = useCallback(
    (isForceSubmit?: boolean) =>
      async (
        data: InviteEditUserFormT<typeof isFormWithEmailTypeahedSearch>,
      ) => {
        if (!isValid) {
          return;
        }

        const emailValue =
          typeof data?.email === 'string' ? data.email : data?.email?.value;

        onSubmit({
          ...user,
          ...data,
          [InviteEditUserFormFields.Role]: data[
            InviteEditUserFormFields.Role
          ] as APIAccessLevel,
          email: emailValue || '',
          phone: data.phone ? phoneField.apiValue(data.phone) : '',
          providerIds: [
            ...data.providerIds.map(it => it as string),
            ...notAvailableProviders.map(it => it),
          ],
          shouldIgnoreUserExistException: isForceSubmit,
        });
      },
    [isValid, onSubmit, user, notAvailableProviders],
  );

  const formTitle = user
    ? t('customers.users.form.editUser')
    : t('customers.users.form.inviteUser');
  const actionButtonLabel = user
    ? t('common.save')
    : t('customers.users.form.inviteButtonText');

  const [discardConfirmationOpen, setDiscardConfirmationOpen] = useState(false);

  const onEmailChange = (
    selectedOption?: TypeaheadDataOption<APIUser> | null,
  ) => {
    if (selectedOption) {
      setValue(InviteEditUserFormFields.Email, selectedOption, {
        shouldValidate: true,
      });
      if (isFormWithEmailTypeahedSearch && selectedOption?.data) {
        setValue(
          InviteEditUserFormFields.FirstName,
          selectedOption?.data?.firstName || '',
        );
        setValue(
          InviteEditUserFormFields.LastName,
          selectedOption?.data?.lastName || '',
        );
        setValue(
          InviteEditUserFormFields.Phone,
          phoneField.formValue(selectedOption?.data?.phone || ''),
          {
            shouldValidate: true,
          },
        );
      }
    }
  };

  return (
    <Popover
      anchorEl={document.body}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      open={open}
      PaperProps={{ sx: { overflow: 'visible' } }}
    >
      <ConfirmationDialog
        open={!!discardConfirmationOpen}
        actionButtonTitle={t('common.yes.discard')}
        onClose={() => {
          setDiscardConfirmationOpen(false);
          onClosePopup?.();
        }}
        handleActionClick={() => {
          onClosePopup?.();
          setDiscardConfirmationOpen(false);
        }}
        handleCancelClick={() => setDiscardConfirmationOpen(false)}
        description={t('customers.users.form.discardConfirmation')}
      />
      {isContractorCreation && (
        <UserExistsPopup
          onSubmit={handleSubmit(onFormSubmit(true))}
          onClosePopup={() => {
            setShowUserExistPopup?.(false);
          }}
          userData={getValues()}
          open={showUserExistPopup}
          isLoading={isFormPending}
        />
      )}
      <PopoverCloseButton
        onClose={() => {
          if (isDirty) {
            setDiscardConfirmationOpen(true);
          } else {
            onClosePopup?.();
          }
        }}
      />
      <FormProvider {...formMethods}>
        <FormWrapper onSubmit={handleSubmit(onFormSubmit())}>
          <Header>
            <Title variant="h3">{formTitle}</Title>
            <ActionButtons>
              <Button
                disabled={isFormPending}
                variant="outlined"
                color="secondary"
                onClick={() => {
                  if (isDirty) {
                    setDiscardConfirmationOpen(true);
                  } else {
                    onClosePopup?.();
                  }
                }}
              >
                {t('common.cancel')}
              </Button>
              <LoadingButton
                variant="contained"
                type="submit"
                loading={isFormPending}
                disabled={!isValid || !isDirty}
              >
                {actionButtonLabel}
              </LoadingButton>
            </ActionButtons>
          </Header>
          <Content>
            <FormField
              fieldName={InviteEditUserFormFields.FirstName}
              label={t('customers.users.form.firstNameLabel')}
              placeholder={t('customers.users.form.firstNamePlaceholder')}
              requiredErrorMessage={t('common.form.emptyFieldError')}
              control={control}
              maxLength={50}
              isRequired
              isDisabled={isFormPending}
              showError={touchedFields[InviteEditUserFormFields.FirstName]}
              fieldError={errors[InviteEditUserFormFields.FirstName]}
            />
            <FormField
              fieldName={InviteEditUserFormFields.LastName}
              label={t('customers.users.form.lastNameLabel')}
              placeholder={t('customers.users.form.lastNamePlaceholder')}
              requiredErrorMessage={t('common.form.emptyFieldError')}
              control={control}
              maxLength={50}
              isRequired
              isDisabled={isFormPending}
              showError={touchedFields[InviteEditUserFormFields.LastName]}
              fieldError={errors[InviteEditUserFormFields.LastName]}
            />
            {isFormWithEmailTypeahedSearch ? (
              <EmailSearchField<
                InviteEditUserFormT<typeof isFormWithEmailTypeahedSearch>
              >
                isRequired
                isDisabled={isFormPending}
                placeholder={t('customers.users.form.emailPlaceholder')}
                label={t('customers.users.form.emailLabel')}
                fieldName={InviteEditUserFormFields.Email}
                onChange={onEmailChange}
                rules={emailFieldRules}
                maxLength={emailField.maxLength}
              />
            ) : (
              <FormField
                isRequired
                type="email"
                fieldName={InviteEditUserFormFields.Email}
                label={t('customers.users.form.emailLabel')}
                placeholder={t('customers.users.form.emailPlaceholder')}
                requiredErrorMessage={t('common.form.emptyFieldError')}
                control={control}
                inputProps={{
                  maxLength: emailField.maxLength,
                }}
                rules={emailFieldRules}
                isDisabled={isFormPending}
                showError={
                  touchedFields[InviteEditUserFormFields.Email] === true
                }
                fieldError={errors[InviteEditUserFormFields.Email]}
              />
            )}
            <FormField
              fieldName={InviteEditUserFormFields.Phone}
              label={t('customers.users.form.phoneLabel')}
              placeholder={t('customers.users.form.phonePlaceholder')}
              requiredErrorMessage={t('common.form.emptyFieldError')}
              control={control}
              inputProps={{
                maxLength: phoneField.maxLength,
              }}
              showError={touchedFields[InviteEditUserFormFields.Phone]}
              fieldError={errors[InviteEditUserFormFields.Phone]}
              mask={phoneField.mask}
              maskDefinitions={phoneField.maskDefinitions}
              rules={phoneFieldRules}
              isRequired={false}
              isDisabled={isFormPending}
            />
            <FormField
              type="select"
              isRequired
              inputProps={
                //  If `renderValue` is provided, Select doesn't render the
                // placeholder, when no value is selected yet.
                selectedRole
                  ? {
                      renderValue: (value: APIAccessLevel) => {
                        switch (value) {
                          case APIAccessLevel.Admin:
                            return t(
                              'customers.users.form.roleAdminValueLabel',
                            );
                          case APIAccessLevel.User:
                            return t('customers.users.form.roleUserValueLabel');
                          default:
                            return value;
                        }
                      },
                    }
                  : {}
              }
              selectOptions={[
                <MenuItem
                  key={APIAccessLevel.Admin}
                  value={APIAccessLevel.Admin}
                >
                  <Stack>
                    <Typography variant="body1" fontWeight={600}>
                      {t('customers.users.form.roleAdminValueLabel')}
                    </Typography>
                    <Typography variant="body2">
                      {t('customers.users.form.roleAdminValueDescription')}
                    </Typography>
                  </Stack>
                </MenuItem>,
                <MenuItem key={APIAccessLevel.User} value={APIAccessLevel.User}>
                  <Stack>
                    <Typography variant="body1" fontWeight={600}>
                      {t('customers.users.form.roleUserValueLabel')}
                    </Typography>
                    <Typography variant="body2">
                      {t('customers.users.form.roleUserValueDescription')}
                    </Typography>
                  </Stack>
                </MenuItem>,
              ]}
              fieldName={InviteEditUserFormFields.Role}
              label={t('customers.users.form.roleLabel')}
              placeholder={t('customers.users.form.rolePlaceholder')}
              requiredErrorMessage={t('common.form.emptyFieldError')}
              control={control}
              maxLength={200}
              isDisabled={isRoleEditDisabled}
            />
            {userTypeToCreate == APIUserType.Dispatcher && (
              <FormCheckbox
                title={t('order.sectionField.hasAccessToMobileApp')}
                fieldName={InviteEditUserFormFields.HasAccessToMobileApp}
                control={control}
                label={t('order.sectionField.hasAccessToMobileAppLabel')}
                isDisabled={isFormPending}
              />
            )}
            {isProvidersFieldVisible && (
              <MultiSelectDropdownStyle>
                <MultiSelectDropdown
                  fieldName={InviteEditUserFormFields.Providers}
                  caption={t('customers.users.form.providersPlaceholders')}
                  options={userProviderOptions}
                  placeholder={defaultProviderSelected
                    .map(it => it.providerName)
                    .join(', ')}
                  isRequired
                  defaultSelected={defaultProviderSelected.map(it => it.id)}
                  control={control}
                />
              </MultiSelectDropdownStyle>
            )}
          </Content>
        </FormWrapper>
      </FormProvider>
    </Popover>
  );
};

const normalizeUserForForm = (
  user: APIUser,
  isFormWithEmailTypeahedSearch?: boolean,
) => ({
  ...user,
  [InviteEditUserFormFields.Email]: isFormWithEmailTypeahedSearch
    ? {
        value: user.email,
        label: user.email,
      }
    : user.email,
  [InviteEditUserFormFields.HasAccessToMobileApp]:
    user?.userOptions &&
    user.userOptions[InviteEditUserFormFields.HasAccessToMobileApp] === 'true',
  phone: phoneField.formValue(user.phone),
  [InviteEditUserFormFields.Providers]: user.providers?.map(
    provider => provider.id,
  ),
});
