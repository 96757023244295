import { useEffect } from 'react';

import styled, { css } from 'styled-components';

import { Stack, TableCell, Typography } from 'components';
import { emit } from 'hooks/usePubSub';
import { usePrevious } from 'react-use';

import { APP_EVENTS } from 'constants/appEvent';

import { useContractorsList } from 'modules/contractorsList';

export const Wrapper = styled(Stack)`
  flex-grow: 1;
  min-height: 0;
`;

export const HeaderWrapper = styled(Stack)`
  align-items: center;
  justify-content: flex-start;
  margin-top: 3.2rem;
`;

export const Header = styled(Typography)`
  color: ${props => props.theme.custom.palette.secondary500};
  margin-right: auto;
`;

export const TableCellText = styled(Typography)`
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 1.5;
  color: ${props => props.theme.custom.palette.gray};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const TableCellLink = styled(Typography)<{ disabled?: boolean }>`
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.5;
  color: ${props => props.theme.custom.palette.primary900};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline;
  &:hover {
    text-decoration: ${props => (props.disabled ? 'initial' : 'underline')};
  }
`;

export const StyledTableBodyCell = styled(TableCell)<{
  height: string;
  cellstyles: string;
}>`
  position: relative;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 0;
  border-bottom: 1px solid ${props => props.theme.custom.palette.truegray300};
  height: ${props => props.height};
  padding: 0 1.6rem;
  &:not(:last-child):after {
    position: absolute;
    top: 1rem;
    right: 0;
    bottom: 0;
    border-right: 1px solid ${props => props.theme.custom.palette.truegray300};
    width: 1px;
    content: '';
  }
  ${props => props.cellstyles};
`;

export const ActionButtonsCellStyles = css`
  padding: 0 0.8rem;
  text-overflow: unset;
  text-align: center;
`;
export const StyledTableColumnLabel = styled(Typography)`
  font-weight: 900;
  font-size: 2rem;
  text-transform: uppercase;
  color: ${props => props.theme.custom.palette.primary900};
`;

export const TableCounter = styled(Typography)`
  font-weight: 600;
  font-size: 1.6rem;
  margin-right: auto;
  margin-left: 1rem;
  height: 2.4rem;
`;

export const EmptyState = styled(Typography)`
  font-size: 1.6rem;
  font-weight: 600;
  margin-top: 3.6rem;
`;

export const LoadingContainer = styled(Stack)`
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const tableComponents = {
  TableBodyCell: StyledTableBodyCell,
  TableColumnLabel: StyledTableColumnLabel,
};

export const useTrackDeleteCompanyAvailability = (
  contractorId: string,
  currentCount: number | undefined,
) => {
  const { getContractorById } = useContractorsList();

  const previousCount = usePrevious(currentCount);

  useEffect(() => {
    const updateCompany = async () => {
      const result = await getContractorById(contractorId);
      if (!result.error) {
        emit(APP_EVENTS.UPDATE_COMPANY, result.payload);
      }
    };

    if (
      contractorId &&
      typeof previousCount === 'number' &&
      typeof currentCount === 'number' &&
      // update company only when we add first item to the table
      // or delete the last one, only in these two cases
      // the `inUse` flag of the company can be changed
      ((currentCount > 0 && previousCount === 0) ||
        (currentCount === 0 && previousCount > 0))
    ) {
      updateCompany();
    }
  }, [previousCount, currentCount, contractorId, getContractorById]);
};
