import React, { CSSProperties } from 'react';

import styled from 'styled-components';

import {
  ListItemIcon,
  MenuItem as MaterialMenuItem,
  MenuItemProps,
  Typography,
} from '@mui/material';

import { ListItemText } from 'components/index';

type Props = {
  icon?: React.ReactElement;
  text?: string;
  style?: CSSProperties;
} & MenuItemProps;

const StyledMenuItem = styled(MaterialMenuItem)<{ disabled?: boolean }>`
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: ${props => props.theme.custom.palette.primary500};
  &:hover {
    .MuiListItemText-root {
      color: ${props => props.theme.custom.palette.primary500};
    }
    .MuiListItemText-root {
      color: ${props => props.theme.custom.palette.primary500};
    }
    .MuiSvgIcon-root {
      color: ${props => props.theme.custom.palette.primary500};
    }
  }
  opacity: ${props => (props.disabled ? 0.85 : 1)};
`;

const StyledListItemText = styled(ListItemText)`
  color: ${props => props.theme.custom.palette.secondary500};
  text-decoration: underline;
  text-transform: uppercase;
  > span {
    font-weight: 900;
  }
`;

const StyledListItemIcon = styled(ListItemIcon)`
  color: ${props => props.theme.custom.palette.secondary500};
`;

export const MenuEmptyText = styled(Typography)`
  padding: 0 1rem;
  align-items: center;
  color: ${props => props.theme.custom.palette.muted800};
`;

export const MenuItem: React.FC<Props> = ({
  icon,
  text,
  disabled,
  children,
  ...props
}) => {
  return (
    <StyledMenuItem {...props} disabled={disabled}>
      {icon && <StyledListItemIcon>{icon}</StyledListItemIcon>}
      {text && <StyledListItemText>{text}</StyledListItemText>}
      {children}
    </StyledMenuItem>
  );
};
