import React from 'react';

import styled from 'styled-components';

import { APIUser, APIUserStatus, APIUserType } from '@cd3p/core/types/api';
import { LoadingButton, SetUpPasswordForm } from 'components';
import { useHandleApiResult } from 'hooks/useHandleApiResult';

import { BorderLinearProgress, Description, Title } from './OnBoardingView';

import {
  FormProvider,
  useForm,
  useNavigate,
  useSelector,
  useTranslation,
} from 'third-party';

import {
  ONBOARDING_STEPS,
  allOrdersUrl,
  onboardingStepUrl,
} from 'constants/url';

import { useApp } from 'modules/app';

import { appSelectors } from 'selectors';

import {
  SetUpPasswordFormData,
  SetUpPasswordFormFields,
  setUpPasswordFormDefaultValues,
} from 'components/SetUpPasswordForm/SetUpPasswordForm';

const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 12.8rem;
`;

const Form = styled.form`
  display: flex;
  flex-flow: column;
  width: 42.8rem;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
`;

const NextButton = styled(LoadingButton)`
  margin-top: 4rem;
  width: 100%;
`;

export const OnBoardingStep1SetPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { updateCurrentUser } = useApp();
  const user = useSelector(appSelectors.user);
  const userType = useSelector(appSelectors.userType);
  const isUserContractor = userType === APIUserType.Contractor;

  const updateCurrentUserPending = useSelector(
    appSelectors.updateCurrentUserPending,
  );
  const userShouldVerifyCompanyInfo = useSelector(
    appSelectors.userShouldVerifyCompanyInfo,
  );

  const methods = useForm<SetUpPasswordFormData>({
    mode: 'onChange',
    defaultValues: setUpPasswordFormDefaultValues,
  });

  const {
    formState: { isValid },
    handleSubmit,
  } = methods;

  const handleApiResult = useHandleApiResult();

  const onSubmit = async (formData: SetUpPasswordFormData) => {
    if (!isValid) {
      return;
    }

    if (user) {
      handleApiResult<APIUser>(
        () =>
          updateCurrentUser(
            {
              ...user,
              userStatus: isUserContractor
                ? APIUserStatus.Active
                : user.userStatus,
            },
            formData[SetUpPasswordFormFields.Password].trim(),
          ),
        ({ result }) => {
          if (result.payload.userStatus === APIUserStatus.Incomplete) {
            navigate(onboardingStepUrl(ONBOARDING_STEPS.Step2), {
              replace: true,
            });
          } else {
            navigate(allOrdersUrl(), { replace: true });
          }
        },
      );
    }
  };

  const isFormPending = updateCurrentUserPending;

  const onboardingHasMoreSteps = userShouldVerifyCompanyInfo;

  return (
    <Wrapper>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Title variant="h2">{t('onboarding.title')}</Title>
          <Description>
            {onboardingHasMoreSteps
              ? t('onboarding.step1.description.moreSteps')
              : t('onboarding.step1.description')}
          </Description>
          <InnerWrapper>
            {onboardingHasMoreSteps && (
              <BorderLinearProgress variant="determinate" value={50} />
            )}
            <SetUpPasswordForm isFormPending={isFormPending} />
            <NextButton
              type="submit"
              variant="contained"
              size="medium"
              color="primary"
              disabled={!isValid}
              loading={isFormPending}
            >
              {onboardingHasMoreSteps
                ? t('common.next')
                : t('onboarding.step1.getStartedButton')}
            </NextButton>
          </InnerWrapper>
        </Form>
      </FormProvider>
    </Wrapper>
  );
};
