import React, { useEffect } from 'react';

import { NotificationSeverity } from '@cd3p/core/modules/notifications';
import { APICompany } from '@cd3p/core/types/api';
import { getServerErrorTitle } from '@cd3p/core/utils/common';
import {
  EditableDetailsWrapper,
  FormField,
  LocationField,
  Trans,
} from 'components';

import {
  useBeforeUnload,
  useForm,
  useSelector,
  useTranslation,
} from 'third-party';

import { useCache } from 'modules/cache';
import { useContractorsList } from 'modules/contractorsList';
import { useNotifications } from 'modules/notifications';

import { contractorsListSelectors } from 'selectors';

enum COMPANY_FORM_FIELDS {
  NAME = 'name',
  ADDRESS = 'address',
}

const getAddressValue = (address?: string) =>
  address
    ? {
        label: address,
        value: address,
      }
    : undefined;

const getFormDefaultValues = (name?: string, address?: string) => ({
  [COMPANY_FORM_FIELDS.NAME]: name || '',
  [COMPANY_FORM_FIELDS.ADDRESS]: getAddressValue(address),
});

type FormProps = {
  [COMPANY_FORM_FIELDS.NAME]: string;
  [COMPANY_FORM_FIELDS.ADDRESS]:
    | {
        label: string;
        value: string;
      }
    | undefined;
};

type ContractorDetailsFormProps = {
  selectedContractor: APICompany;
  setEditMode: (value: boolean) => void;
  onSuccessfulSubmit: (contractor: APICompany) => void;
};

export const ContractorDetailsForm = ({
  selectedContractor,
  setEditMode,
  onSuccessfulSubmit,
}: ContractorDetailsFormProps) => {
  const { t } = useTranslation();
  const { addNotification } = useNotifications();
  const { loadAddress, loadAddressLocation } = useCache();
  const { updateContractor } = useContractorsList();

  const updateContractorPending = useSelector(
    contractorsListSelectors.updateContractorPending,
  );

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    setError,
    formState: { isSubmitted, isDirty, isValid, errors },
  } = useForm<FormProps>({
    mode: 'onChange',
    defaultValues: getFormDefaultValues(
      selectedContractor.name,
      selectedContractor.address,
    ),
  });

  const watchedAddressField = watch(COMPANY_FORM_FIELDS.ADDRESS);
  useBeforeUnload(isDirty, t('common.exitConfirmationWindow.text'));

  const handleCancel = () => {
    setEditMode(false);
  };

  const isSaveDisabled = (!isValid && isSubmitted) || !isDirty;

  useEffect(() => {
    if (selectedContractor) {
      setValue(COMPANY_FORM_FIELDS.NAME, selectedContractor.name);
      setValue(
        COMPANY_FORM_FIELDS.ADDRESS,
        getAddressValue(selectedContractor.address),
        { shouldValidate: true },
      );
    }
    return () => {
      reset(getFormDefaultValues());
    };
  }, [selectedContractor, setValue, reset]);

  const onSubmit = async (formFields: FormProps) => {
    if (!isValid) {
      return;
    }
    const body = {
      [COMPANY_FORM_FIELDS.NAME]: formFields[COMPANY_FORM_FIELDS.NAME].trim(),
      [COMPANY_FORM_FIELDS.ADDRESS]:
        formFields[COMPANY_FORM_FIELDS.ADDRESS]?.value,
      companyType: selectedContractor?.companyType,
      isDeactivated: selectedContractor?.isDeactivated,
    };
    const result = await updateContractor(selectedContractor?.id, body);

    if (!result || result.error) {
      const errorStatus = result.payload.status;
      if (errorStatus === 409) {
        setError(COMPANY_FORM_FIELDS.NAME, {
          type: 'custom',
          message: getServerErrorTitle(result),
        });
      } else {
        addNotification({
          severity: NotificationSeverity.Error,
          message: t('common.generalError'),
        });
      }
    } else {
      addNotification({
        message: (
          <Trans i18nKey="contractors.editSuccessful.message" shouldUnescape>
            {{ contractorName: result.payload?.name }} was successfully updated
          </Trans>
        ),
      });
      setEditMode(false);
      onSuccessfulSubmit(result.payload);
    }
  };

  return (
    <EditableDetailsWrapper
      sectionTitle={t('customers.details.title')}
      onSave={handleSubmit(onSubmit)}
      onCancel={handleCancel}
      isEditMode
      isLoading={updateContractorPending}
      isSaveDisabled={isSaveDisabled}
      setEditMode={setEditMode}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormField
          fieldName={COMPANY_FORM_FIELDS.NAME}
          label={t('customers.details.companyNameField')}
          placeholder={t('customers.details.companyNameField')}
          fieldError={errors[COMPANY_FORM_FIELDS.NAME]}
          requiredErrorMessage={t('common.form.emptyFieldError')}
          control={control}
          isUpdating={updateContractorPending}
          maxLength={200}
          isRequired
          showError={isSubmitted}
        />
        <LocationField
          fieldName={COMPANY_FORM_FIELDS.ADDRESS}
          errors={errors}
          control={control}
          isUpdating={updateContractorPending}
          showError={isSubmitted}
          value={watchedAddressField}
          setValue={setValue}
          label={t('customers.details.officeAddressField')}
          loadAddress={loadAddress}
          loadAddressLocation={loadAddressLocation}
        />
      </form>
    </EditableDetailsWrapper>
  );
};
