import { useMemo } from 'react';

import {
  CoreOrdersCacheState,
  coreOrdersCacheState,
  coreUpdateOrderInCache,
  coreUpdateOrderUnreadMessagesInCache,
  coreUpdateOrdersCache,
} from '@cd3p/core/modules/ordersCache';
import { simplifyBuilder } from '@cd3p/core/utils/sra';

import { bindActionCreators, useDispatch } from 'third-party';

export type OrdersCacheState = CoreOrdersCacheState;

const builder = simplifyBuilder(coreOrdersCacheState, {});

const updateOrdersCache = builder.createReduxAction(coreUpdateOrdersCache);

const updateOrderInCache = builder.createReduxAction(coreUpdateOrderInCache);

export const updateOrderUnreadMessagesInCache = builder.createReduxAction(
  coreUpdateOrderUnreadMessagesInCache,
);

export const useOrdersCache = () => {
  const dispatch = useDispatch();

  return useMemo(
    () =>
      bindActionCreators(
        {
          updateOrdersCache: updateOrdersCache.bind(null),
          updateOrderInCache: updateOrderInCache.bind(null),
          updateOrderUnreadMessagesInCache:
            updateOrderUnreadMessagesInCache.bind(null),
        },
        dispatch,
      ),
    [dispatch],
  );
};

export const ordersCacheReducer = builder.getReducers();
