import React from 'react';

import { Core } from '@cd3p/core/config';
import { APIAccessLevel, APIUserType } from '@cd3p/core/types/api';
import { ProtectedRoute } from 'components';
import {
  AllOrdersView,
  AllOrdersWrapper,
  ApiDocs,
  AppContainer,
  ContractorProjectsList,
  ContractorTeamList,
  ContractorsView,
  CreateOrderView,
  ForgotPassword,
  ImportOrdersView,
  LogInView,
  MainView,
  NavigateToOrderChat,
  OnBoardingStep1SetPassword,
  OnBoardingStep2Verify,
  OnBoardingView,
  OrderView,
  PlantEditView,
  ProductsView,
  ProjectCreateEditView,
  ProjectView,
  ProjectViewWrapper,
  ReleaseNotes,
  ResetPassword,
  TodayOrdersView,
  TrackerView,
  TrackingHistoryView,
  UserManagementView,
} from 'features';

import { Navigate, Route } from 'third-party';

import {
  ADD_PATH,
  EDIT_PATH,
  ONBOARDING_STEPS,
  ORDERS_PATH,
  PLANTS_PATH,
  PROJECTS_PATH,
  allOrdersUrl,
  apiDocsUrl,
  appUrl,
  contractorProjectsListUrl,
  contractorTeamListUrl,
  contractorsUrl,
  docsUrl,
  forgotPasswordUrl,
  getOnboardingStepPath,
  importOrdersUrl,
  loginUrl,
  onboardingUrl,
  orderChatUrl,
  orderUrl,
  productsUrl,
  providerUsersListUrl,
  resetPasswordUrl,
  settingsUrl,
  todayOrdersUrl,
  trackerUrl,
  trackingEmulatorUrl,
  trackingHistoryViewUrl,
  userManagementUrl,
} from 'constants/url';

import { SupportCanAccess } from 'components/CanAccess/CanAccess';

import {
  AdminCustomerAccountViewWrapper,
  AdminCustomerAccountsView,
} from 'features/AdminCustomerAccounts/AdminCustomerAccountsView';
import { ContractorsViewWrapper } from 'features/Contractors/ContractorsView';
import { SettingsView } from 'features/PlantSettings/SettingsView';
import { ProviderUsersListView } from 'features/ProviderUsersListView/ProviderUsersListView';
import { TrackingEmulatorView } from 'features/TrackingEmulator/TrackingEmulatorView';

export const useRoutes = () => {
  const isAdminPortal = Core.isAdminPortal();
  return (
    <Route path={appUrl()} element={<AppContainer />}>
      {isAdminPortal ? (
        <Route path={appUrl()} element={<MainView />}>
          <Route
            path={appUrl()}
            element={<Navigate replace to={contractorsUrl()} />}
          />
          <Route
            path={`${contractorsUrl()}/*`}
            element={<AdminCustomerAccountViewWrapper />}
          >
            <Route
              path=":contractorId?/:tabId?"
              element={<AdminCustomerAccountsView />}
            />
          </Route>
        </Route>
      ) : (
        <Route path={appUrl()} element={<MainView />}>
          <Route path={docsUrl()} element={<ReleaseNotes />} />
          <Route path={apiDocsUrl()} element={<ApiDocs />} />

          <Route
            path={appUrl()}
            element={<Navigate replace to={allOrdersUrl()} />}
          />
          <Route path={trackerUrl()} element={<TrackerView />} />
          <Route
            path={trackingEmulatorUrl()}
            element={<TrackingEmulatorView />}
          />
          <Route
            path={`plant/:plantId/${EDIT_PATH}`}
            element={<PlantEditView />}
          />
          <Route
            path={trackingHistoryViewUrl()}
            element={<TrackingHistoryView />}
          />
          <Route path={allOrdersUrl()} element={<AllOrdersWrapper />}>
            <Route index element={<AllOrdersView />} />
            <Route
              path={orderChatUrl(':id')}
              element={<NavigateToOrderChat />}
            />
            <Route path={orderUrl('add')} element={<CreateOrderView />} />
            <Route path={orderUrl(':id/*')} element={<OrderView />} />
          </Route>
          <Route
            path={`${todayOrdersUrl()}/*`}
            element={
              <ProtectedRoute
                allowedUserType={[APIUserType.Dispatcher, APIUserType.Support]}
              >
                <TodayOrdersView />
              </ProtectedRoute>
            }
          />
          <Route path={importOrdersUrl()} element={<ImportOrdersView />} />
          <Route
            path={`${settingsUrl()}/*`}
            element={
              <ProtectedRoute
                allowedUserType={[APIUserType.Dispatcher, APIUserType.Support]}
              >
                <ProtectedRoute allowedFor={APIAccessLevel.Admin}>
                  <SettingsView />
                </ProtectedRoute>
              </ProtectedRoute>
            }
          />
          <Route
            path={`${productsUrl()}/*`}
            element={
              <ProtectedRoute
                allowedUserType={[APIUserType.Dispatcher, APIUserType.Support]}
              >
                <ProductsView />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${PLANTS_PATH}/:plantId/${EDIT_PATH}`}
            element={
              <ProtectedRoute
                allowedUserType={[APIUserType.Dispatcher, APIUserType.Support]}
              >
                <PlantEditView />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${PLANTS_PATH}/${ADD_PATH}`}
            element={
              <ProtectedRoute
                allowedUserType={[APIUserType.Dispatcher, APIUserType.Support]}
              >
                <PlantEditView />
              </ProtectedRoute>
            }
          />
          <Route path={`${contractorProjectsListUrl()}`}>
            <Route
              index
              element={
                <ProtectedRoute allowedUserType={APIUserType.Contractor}>
                  <ContractorProjectsList />
                </ProtectedRoute>
              }
            />
            <Route
              path={ADD_PATH}
              element={
                <ProtectedRoute allowedUserType={APIUserType.Contractor}>
                  <ProjectCreateEditView />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path={`${contractorTeamListUrl()}`}
            element={
              <ProtectedRoute
                allowedUserType={APIUserType.Contractor}
                allowedFor={APIAccessLevel.Admin}
              >
                <ContractorTeamList />
              </ProtectedRoute>
            }
          />
          <Route
            path={`${providerUsersListUrl()}`}
            element={<ProviderUsersListView />}
          />
          <Route
            path={`${userManagementUrl()}/*`}
            element={<UserManagementView />}
          />
          <Route
            path={`${contractorsUrl()}/*`}
            element={<ContractorsViewWrapper />}
          >
            <Route
              path=":contractorId?/:tabId?"
              element={<ContractorsView />}
            />
            <Route
              path={`:customerId/${PROJECTS_PATH}/${ADD_PATH}`}
              element={<ProjectCreateEditView />}
            />
            <Route
              path={`:customerId/${PROJECTS_PATH}/:projectId/${EDIT_PATH}`}
              element={<ProjectCreateEditView />}
            />
            <Route
              path={`:customerId/${PROJECTS_PATH}/:projectId/:tabId?`}
              element={<ProjectViewWrapper />}
            >
              <Route index element={<ProjectView />} />
              <Route path={`${ORDERS_PATH}/:id/*`} element={<OrderView />} />
            </Route>
          </Route>
        </Route>
      )}

      <Route path={onboardingUrl()} element={<OnBoardingView />}>
        <Route
          path={getOnboardingStepPath(ONBOARDING_STEPS.Step1)}
          element={<OnBoardingStep1SetPassword />}
        />
        <Route
          path={getOnboardingStepPath(ONBOARDING_STEPS.Step2)}
          element={<OnBoardingStep2Verify />}
        />
      </Route>
      <Route path={loginUrl()} element={<LogInView />} />
      <Route path={forgotPasswordUrl()} element={<ForgotPassword />} />
      <Route path={resetPasswordUrl()} element={<ResetPassword />} />
      {/* main app: redirect to all orders in case of no url match */}
      {/* admin app: redirect to customer accounts in case of no url match */}
      <Route
        path="*"
        element={
          isAdminPortal ? (
            <SupportCanAccess>
              <Navigate to={contractorsUrl()} replace />
            </SupportCanAccess>
          ) : (
            <Navigate to={allOrdersUrl()} replace />
          )
        }
      />
    </Route>
  );
};
