import { SingleValue } from 'react-select';

import { TypeaheadOption } from 'types/app';

export enum FormFields {
  Name = 'name',
  Company = 'company',
  Address = 'address',
  Description = 'description',
}

export type FormProps = {
  [FormFields.Name]: string;
  [FormFields.Company]: SingleValue<TypeaheadOption>;
  [FormFields.Address]: SingleValue<TypeaheadOption>;
  [FormFields.Description]: string;
};
