import React from 'react';

import styled from 'styled-components';

import { Typography } from '@mui/material';

import { Trans } from 'third-party';

const StyledTableCounter = styled(Typography)<{ fontSize: string }>`
  color: ${props => props.theme.custom.palette.darkText};
  font-size: ${props => props.fontSize};
  font-weight: 600;
  text-transform: none;
`;

interface Props {
  loadedCount: number;
  totalCount: number;
  fontSize?: string;
}

export const TableCounter: React.FC<Props> = props => {
  const { loadedCount, totalCount, fontSize = '1.6rem' } = props;

  return (
    <StyledTableCounter fontSize={fontSize}>
      {!!loadedCount && (
        <Trans i18nKey="ordersList.countText">
          {{ loadedCount: loadedCount }} of {{ totalCount: totalCount }} shown
        </Trans>
      )}
    </StyledTableCounter>
  );
};
