import { ChatState } from '@cd3p/core/modules/chat';
import { coreChatSelectors } from '@cd3p/core/selectors';
import { APIUserType } from '@cd3p/core/types/api';
import { isBotMessage } from '@cd3p/core/utils/chat';
import i18n from 'i18n';
import { State } from 'modules';

import { createSelector } from 'third-party';

import { allOrdersListSelectors } from 'selectors';

import { formatChatMessageDate } from 'utils/dialogs';

export type ChatMessage = {
  id: string;
  createdOn: string;
  message: string;
  senderName: string;
  senderUserType: string | null;
  senderAccessLevel: string | null;
  senderId: string;
  sendTime: string;
  attachmentIds: string[];
};

export const plantDialogMessages = createSelector(
  [coreChatSelectors.dialogMessages],
  (messages: ChatState['dialogMessages']): ChatMessage[] =>
    messages.map(it => ({
      id: it.id,
      createdOn: it.createdOn,
      message: it.message ?? '',
      senderName: isBotMessage(it.senderId)
        ? i18n.t('chat.concretelyBot')
        : `${it.senderFirstName} ${it.senderLastName}`,
      senderUserType: it.senderUserType,
      senderAccessLevel: it.senderAccessLevel,
      senderId: it.senderId,
      sendTime: formatChatMessageDate(it.createdOn),
      attachmentIds: it.attachmentIds || [],
    })),
);

export const unreadMessagesCount = createSelector(
  [allOrdersListSelectors.allOrdersListItems],
  (orders: any[]): number =>
    orders
      .map(it => it.dialog)
      .filter(
        dialog =>
          dialog?.isReadByDispatcher === false &&
          dialog.lastMessageUserType !== APIUserType.Dispatcher,
      ).length,
);

export const dialogMessagesAttachments = (state: State) =>
  state.chat.dialogMessagesAttachments;
