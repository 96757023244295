import { useMemo } from 'react';

// import { APIOrder, APIOrdersList } from '@cd3p/core/types/api';
import { APIAvailableMixTypeModelSearchResult } from '@cd3p/core/types/api';
import { simplifyBuilder } from '@cd3p/core/utils/sra';

import {
  ConcreteCategoriesState,
  concreteCategoriesDefaultState,
  loadConcreteCategories,
  loadMoreConcreteCategories,
  resetConcreteCategories,
  updateConcreteCategories,
  updateConcreteCategoriesFilters,
  updateConcreteCategoriesPagination,
  updateConcreteCategoriesSorting,
} from './commonConcreteCategories';

import { bindActionCreators, useDispatch, useSelector } from 'third-party';

import {
  contractorMixTypeEndpoint,
  loadMixTypesCategoriesByCompanyEndpoint,
} from 'constants/endpoints';

import { appSelectors, mixTypesCategoriesSelectors } from 'selectors';

export type MixTypesCategoriesListState =
  ConcreteCategoriesState<APIAvailableMixTypeModelSearchResult> & {
    loadMixTypesCategoriesPending: boolean;
    loadMoreMixTypesCategoriesPending: boolean;
  };

export const mixTypesCategoriesListState: MixTypesCategoriesListState = {
  ...concreteCategoriesDefaultState,
  loadMixTypesCategoriesPending: false,
  loadMoreMixTypesCategoriesPending: false,
};

const builder = simplifyBuilder(mixTypesCategoriesListState, {});

const loadMixTypesCategories = builder.createServerAction(
  loadConcreteCategories<
    APIAvailableMixTypeModelSearchResult,
    MixTypesCategoriesListState,
    APIAvailableMixTypeModelSearchResult
  >('loadMixTypesCategories', loadMixTypesCategoriesByCompanyEndpoint),
);

const loadMoreMixTypesCategories = builder.createServerAction(
  loadMoreConcreteCategories<
    APIAvailableMixTypeModelSearchResult,
    MixTypesCategoriesListState,
    APIAvailableMixTypeModelSearchResult
  >('loadMoreMixTypesCategories', loadMixTypesCategoriesByCompanyEndpoint),
);

const updateMixTypesCategories = builder.createServerAction(
  updateConcreteCategories(
    'updateMixTypesCategories',
    contractorMixTypeEndpoint,
  ),
);

const updateMixTypesCategoriesListSorting = builder.createReduxAction(
  updateConcreteCategoriesSorting<APIAvailableMixTypeModelSearchResult>(
    'updateMixTypesCategoriesListSorting',
  ),
);

const updateMixTypesCategoriesListPagination = builder.createReduxAction(
  updateConcreteCategoriesPagination<APIAvailableMixTypeModelSearchResult>(
    'updateMixTypesCategoriesListPagination',
  ),
);

const updateMixTypesCategoriesListFilters = builder.createReduxAction(
  updateConcreteCategoriesFilters<
    APIAvailableMixTypeModelSearchResult,
    MixTypesCategoriesListState
  >('updateMixTypesCategoriesListFilters'),
);

const resetMixTypesCategories = builder.createReduxAction(
  resetConcreteCategories('resetMixTypesCategories'),
);

export const useMixTypesCategoriesList = () => {
  const dispatch = useDispatch();
  const providerId = useSelector(appSelectors.providerId);

  return useMemo(
    () =>
      bindActionCreators(
        {
          loadMixTypesCategories: loadMixTypesCategories.bind(
            null,
            providerId,
            mixTypesCategoriesSelectors.allMixTypesCategoriesListState,
          ),
          loadMoreMixTypesCategories: loadMoreMixTypesCategories.bind(
            null,
            providerId,
            mixTypesCategoriesSelectors.allMixTypesCategoriesListState,
          ),
          updateMixTypesCategories: updateMixTypesCategories.bind(
            null,
            providerId,
          ),
          updateMixTypesCategoriesListSorting:
            updateMixTypesCategoriesListSorting.bind(null),
          updateMixTypesCategoriesListPagination:
            updateMixTypesCategoriesListPagination.bind(null),
          updateMixTypesCategoriesListFilters:
            updateMixTypesCategoriesListFilters.bind(null),
          resetMixTypesCategories: resetMixTypesCategories.bind(null),
          // updateItemDetailsInMixTypesCategoriesState:
          //   updateItemDetailsInMixTypesCategoriesState.bind(null),
        },
        dispatch,
      ),
    [dispatch, providerId],
  );
};

export const mixTypesCategoriesListReducer = builder.getReducers();
