import { orders as cacheSelectorsOrders } from '../selectors/ordersCache';
import { _, createSelector } from '../third-party';
import { APIOrder } from '../types/api';

export const createOrdersRefList = (
  orders: APIOrder[],
  pickKeys: (keyof APIOrder)[] = ['id'],
) => {
  return (orders || []).map((order) => _.pick(order, pickKeys));
};
export const createOrdersRefSelector = (
  ordersSelector: (state: any) => Partial<APIOrder>[],
) =>
  createSelector(
    [ordersSelector, cacheSelectorsOrders],
    (orders, ordersCache) => {
      return orders.map((order) => ({
        ...ordersCache.find((it) => order.id === it.id)!,
        ...order,
      }));
    },
  );
