import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useTheme } from 'styled-components';

import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SendIcon from '@mui/icons-material/Send';

import {
  APIAccessLevel,
  APIProvider,
  APISortOrder,
  APIUser,
  APIUserRequest,
  APIUserStatus,
  APIUserType,
} from '@cd3p/core/types/api';
import { getServerErrorTitle, getSortOrder } from '@cd3p/core/utils/common';
import {
  AddButton,
  CanAccess,
  CircularProgress,
  ConfirmationDialog,
  DispatcherCanAccess,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Table,
  Tag,
} from 'components';
import { useHandleApiResult } from 'hooks/useHandleApiResult';

import { _, styled, useSelector, useTranslation } from 'third-party';

import { getMaskedPhone } from 'constants/regexp';

import { useApp } from 'modules/app';
import { UsersListColumnId, useUsersList } from 'modules/usersList';

import { appSelectors, usersListSelectors } from 'selectors';

import { InviteEditUserPopup } from 'features/Contractors/InviteEditUserPopup';
import {
  ActionButtonsCellStyles,
  EmptyState,
  Header,
  HeaderWrapper,
  LoadingContainer,
  TableCellText,
  Wrapper,
  tableComponents,
  useTrackDeleteCompanyAvailability,
} from 'features/Contractors/Tabs/tabHelpers';

import { getUserFullName } from 'utils/user';

const StyledTag = styled(Tag)`
  color: ${({ theme }) => theme.custom.palette.purple700};
  background-color: ${({ theme }) => theme.custom.palette.white};
  border-color: ${({ theme }) => theme.custom.palette.muted100};
  > * {
    text-transform: capitalize;
  }
`;

type Props = {
  customerId: string;
  isReadOnly?: boolean;
};

export const TeamTab: React.FC<Props> = ({ customerId, isReadOnly }) => {
  const { t } = useTranslation();

  const { createUser, updateUserById } = useApp();
  const userProvidersIds = useSelector(appSelectors.userProvidersIds);
  const userCompanyIds = useSelector(appSelectors.userCompaniesIds);
  const accessLevel = useSelector(appSelectors.accessLevel);
  const createUserPending = useSelector(appSelectors.createUserPending);
  const updateUserByIdPending = useSelector(appSelectors.updateUserByIdPending);
  const { userType, id } = useSelector(appSelectors.user);
  const crossProviderSearch = userType == APIUserType.Contractor;

  const {
    loadUsersList,
    loadMoreUsersList,
    updateUsersList,
    updateUsersListSorting,
    resendUserInvite,
    deleteUser,
    resetUsersList,
  } = useUsersList();

  const userList = useSelector(usersListSelectors.usersListItems);
  const userListLoaded = useSelector(usersListSelectors.usersListLoaded);
  const userListCount = useSelector(usersListSelectors.usersListCount);
  const loadUserListPending = useSelector(
    usersListSelectors.loadUsersListPending,
  );
  const loadMoreUserListPending = useSelector(
    usersListSelectors.loadMoreUsersListPending,
  );
  const deleteUserPending = useSelector(usersListSelectors.deleteUserPending);
  const sorting = useSelector(usersListSelectors.usersListSorting);

  const { sortField, sortOrder } = sorting[1];

  const generateUserListOptions = useCallback(() => {
    return {
      providerIds: userProvidersIds,
      companyIds: crossProviderSearch ? userCompanyIds : [customerId],
      userType: APIUserType.Contractor,
      crossProviderSearch: crossProviderSearch,
    };
  }, [customerId, userProvidersIds, userCompanyIds, crossProviderSearch]);

  const handleLoadUsersList = useCallback(() => {
    loadUsersList(generateUserListOptions());
  }, [loadUsersList, generateUserListOptions]);

  const handleLoadMoreUsersList = useCallback(() => {
    loadMoreUsersList(generateUserListOptions());
  }, [loadMoreUsersList, generateUserListOptions]);

  const handleUpdateUsersList = useCallback(() => {
    updateUsersList(generateUserListOptions());
  }, [updateUsersList, generateUserListOptions]);

  const onColumnHeaderClicked = useCallback(
    (columnId: UsersListColumnId) => {
      const searchSortOrders = [
        {
          sortField: 'userStatus' as keyof APIUser,
          sortOrder: APISortOrder.ASC,
        },
        {
          sortField: columnId,
          sortOrder: getSortOrder(columnId, sortField, sortOrder),
        },
        {
          sortField: 'id' as keyof APIUser,
          sortOrder: APISortOrder.ASC,
        },
      ];
      updateUsersListSorting(searchSortOrders);
      handleLoadUsersList();
    },
    [handleLoadUsersList, sortField, sortOrder, updateUsersListSorting],
  );

  useEffect(() => {
    if (!userListLoaded) {
      handleLoadUsersList();
    }
  }, [handleLoadUsersList, customerId, userListLoaded]);

  useEffect(() => {
    return () => {
      resetUsersList();
    };
  }, [resetUsersList]);

  const onLoadMoreClicked = useCallback(() => {
    handleLoadMoreUsersList();
  }, [handleLoadMoreUsersList]);

  const [showUserPopup, setShowUserPopup] = useState(false);
  const [showUserExistPopup, setShowUserExistPopup] = useState(false);

  const handleApiResult = useHandleApiResult();
  const onUserDetailsSubmit = useCallback(
    (user: Partial<APIUserRequest>) => {
      handleApiResult<APIUser>(
        () => {
          const userModel = user as APIUser;
          if (userModel?.id) {
            return updateUserById(
              userModel.id,
              //We omit providers since providerIds passed
              _.omit(userModel, 'providers'),
              user.providerIds || undefined,
            );
          } else {
            return createUser(
              customerId,
              userModel,
              user?.providerIds || undefined,
            );
          }
        },
        ({ showBaseToast }) => {
          const userToUpdate = user as APIUser;
          const successMessage = userToUpdate?.id
            ? t('customers.users.form.editSuccess', {
                name: getUserFullName(user as APIUser),
              })
            : t('customers.users.form.createSuccess');
          showBaseToast(successMessage);
          handleUpdateUsersList();
          setShowUserExistPopup(false);
          setShowUserPopup(false);
        },
        ({ showErrorToast, showWarningToast, result }) => {
          const isUserExistError = result?.payload?.status === 403;
          const isUserExistInAnotherProviderError =
            result?.payload?.status === 409;
          if (isUserExistError) {
            showWarningToast(t('inviteCustomer.form.error.userExists'));
          } else if (isUserExistInAnotherProviderError) {
            setShowUserExistPopup(true);
          } else {
            showErrorToast(
              getServerErrorTitle(result) || t('common.generalError'),
            );
          }
        },
      );
    },
    [
      handleApiResult,
      updateUserById,
      createUser,
      customerId,
      t,
      handleUpdateUsersList,
    ],
  );

  const [selectedUser, setSelectedUser] = useState<APIUser | null>(null);
  const [actionsMenuAnchorEl, setActionsMenuAnchorEl] =
    useState<HTMLButtonElement | null>(null);

  const openActionsMenu = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, user: APIUser) => {
      setSelectedUser(user);
      setActionsMenuAnchorEl(event.currentTarget);
    },
    [],
  );
  const closeActionsMenu = useCallback(() => {
    setActionsMenuAnchorEl(null);
  }, []);

  const columns = useMemo(() => {
    return [
      {
        id: 'lastName',
        dataId: 'lastName' as keyof APIUser,
        label: t('customers.users.table.nameColumnLabel'),
        width: '25%',
        formatter: (_: APIUser['lastName'], item: APIUser) => {
          const value = getUserFullName(item);
          return {
            value,
            element: (
              <Stack direction="row">
                {item.userStatus !== APIUserStatus.Active && (
                  <StyledTag
                    mr="1rem"
                    value={t('customers.users.table.pendingLabel')}
                  />
                )}
                <TableCellText>{value}</TableCellText>
              </Stack>
            ),
          };
        },
      },
      {
        id: 'email',
        dataId: 'email' as keyof APIUser,
        label: t('customers.users.table.emailColumnLabel'),
        width: '25%',
        formatter: (value: APIUser['email']) => ({
          value: value || '',
          element: (
            <Stack direction="row">
              <TableCellText mr="1rem">{value || ''}</TableCellText>
            </Stack>
          ),
        }),
      },
      {
        id: 'phone',
        dataId: 'phone' as keyof APIUser,
        label: t('customers.users.table.phoneColumnLabel'),
        width: '25%',
        formatter: (value: APIUser['phone']) => {
          const computedValue = getMaskedPhone(value);
          return {
            value: computedValue,
            element: <TableCellText>{computedValue}</TableCellText>,
          };
        },
      },
      ...(userType === APIUserType.Contractor
        ? [
            {
              id: 'phone',
              dataId: 'providers' as keyof APIUser,
              label: t('customers.users.table.providersColumnLabel'),
              width: '25%',
              sortable: false,
              formatter: (providers: APIProvider[] | null) => {
                const computedValue =
                  providers
                    ?.map(provider => provider.providerName)
                    .join(', ') || '';

                return {
                  value: computedValue,
                  element: <TableCellText>{computedValue}</TableCellText>,
                };
              },
            },
          ]
        : []),
      {
        id: 'role',
        dataId: 'accessLevel' as keyof APIUser,
        label: t('customers.users.table.roleColumnLabel'),
        width: '20%',
        formatter: (value: APIUser['accessLevel']) => ({
          value: value || '',
          element: <TableCellText>{value || ''}</TableCellText>,
        }),
      },
      ...(accessLevel === APIAccessLevel.Admin
        ? [
            {
              id: 'menu',
              width: '5%',
              cellStyles: ActionButtonsCellStyles,
              formatter: (_: APIUser['firstName'], user: APIUser) => ({
                value: 'Actions',
                element: (
                  <IconButton
                    aria-label="Actions"
                    onClick={e => openActionsMenu(e, user)}
                  >
                    <MoreVertIcon />
                  </IconButton>
                ),
              }),
            },
          ]
        : []),
    ];
  }, [t, userType, accessLevel, openActionsMenu]);

  const theme = useTheme();
  const rowHighlightColor = useCallback(
    (item: APIUser) => {
      if (item.userStatus !== APIUserStatus.Active) {
        return theme.custom.palette.purple50;
      }
    },
    [theme],
  );

  const canInviteNewUsers = !isReadOnly;

  const onResendInvite = useCallback(
    (user: APIUser) => {
      handleApiResult<APIUser>(
        () => {
          return resendUserInvite(user.id);
        },
        ({ showBaseToast }) => {
          showBaseToast(t('customers.users.table.resendInviteSuccess'));
        },
      ).then(() => {
        setSelectedUser(null);
      });
    },
    [handleApiResult, resendUserInvite, t],
  );
  const actionMenuItems = useMemo(() => {
    return [
      {
        label: t('customers.users.table.actionsMenuEdit'),
        icon: () => <ModeEditOutlineOutlinedIcon />,
        disabled: false,
        onClick: () => {
          setShowUserPopup(true);
          closeActionsMenu();
        },
      },
      {
        label: t('customers.users.table.actionsMenuDelete'),
        icon: () => <DeleteIcon />,
        disabled: selectedUser?.id == id,
        onClick: () => {
          setConfirmationDialogShown(true);
          closeActionsMenu();
        },
      },
      ...(selectedUser?.userStatus === APIUserStatus.NotActive
        ? [
            {
              label: t('customers.users.table.actionsMenuResendInvite'),
              icon: () => <SendIcon />,
              disabled: false,
              onClick: () => {
                onResendInvite(selectedUser);
                closeActionsMenu();
              },
            },
          ]
        : []),
    ];
  }, [t, selectedUser, id, closeActionsMenu, onResendInvite]);

  const onConfirmDelete = useCallback(() => {
    handleApiResult<APIUser>(
      () => {
        if (selectedUser) {
          return deleteUser(selectedUser.id);
        }
      },
      ({ showBaseToast }) => {
        showBaseToast(t('customers.users.table.deleteSuccess'));
        handleUpdateUsersList();
      },
    ).then(() => {
      setSelectedUser(null);
      setConfirmationDialogShown(false);
    });
  }, [deleteUser, handleApiResult, selectedUser, t, handleUpdateUsersList]);

  const [isConfirmationDialogShown, setConfirmationDialogShown] =
    useState(false);

  useTrackDeleteCompanyAvailability(
    customerId,
    userListLoaded ? userList.length : undefined,
  );

  return (
    <Wrapper direction="column">
      <InviteEditUserPopup
        user={selectedUser}
        open={showUserPopup}
        showUserExistPopup={showUserExistPopup}
        setShowUserExistPopup={setShowUserExistPopup}
        userTypeToCreate={APIUserType.Contractor}
        onClosePopup={() => {
          setShowUserPopup(false);
        }}
        onSubmit={onUserDetailsSubmit}
        isFormPending={createUserPending || updateUserByIdPending}
      />
      <ConfirmationDialog
        onClose={() => {
          setConfirmationDialogShown(false);
          setSelectedUser(null);
        }}
        handleActionClick={onConfirmDelete}
        open={isConfirmationDialogShown}
        actionPending={deleteUserPending}
        description={t('customers.users.table.deleteConfirmation')}
      />
      <HeaderWrapper direction="row">
        <DispatcherCanAccess>
          <Header variant="h5">{t('customers.users.header')}</Header>
        </DispatcherCanAccess>
        <CanAccess allowedUserType={APIUserType.Contractor}>
          <Header variant="h4">{t('userManagement.title')}</Header>
        </CanAccess>
        <CanAccess allowedFor={APIAccessLevel.Admin}>
          <AddButton
            disabled={!canInviteNewUsers}
            onClick={() => {
              setSelectedUser(null);
              setShowUserPopup(true);
            }}
            label={t('customers.users.inviteButtonText')}
          />
        </CanAccess>
      </HeaderWrapper>
      {!userListLoaded && (
        <LoadingContainer>
          <CircularProgress sx={{ margin: '5rem' }} />
        </LoadingContainer>
      )}
      {userListLoaded && userListCount > 0 && (
        <Table<APIUser>
          infiniteScroll
          tableMinHeight="20rem"
          components={tableComponents}
          columns={columns}
          rowHighlightColor={rowHighlightColor}
          items={userList}
          itemsTotalCount={userListCount}
          itemsLoaded={userListLoaded}
          loadItemsPending={loadUserListPending}
          loadMoreItemsPending={loadMoreUserListPending}
          onLoadMoreClicked={onLoadMoreClicked}
          sortField={sortField}
          sortOrder={sortOrder}
          onColumnHeaderClicked={onColumnHeaderClicked}
          loadMoreButtonText={t('customers.users.table.loadMoreButtonText')}
        />
      )}
      <Menu
        menuTitle={t('customers.users.table.actionsMenuTitle')}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={actionsMenuAnchorEl}
        open={Boolean(actionsMenuAnchorEl)}
        onClose={closeActionsMenu}
      >
        {actionMenuItems.map(({ label, icon: Icon, disabled, onClick }) => (
          <MenuItem
            key={label}
            text={label}
            disabled={disabled}
            icon={<Icon />}
            onClick={onClick}
          />
        ))}
      </Menu>
      {userListLoaded && userListCount === 0 && (
        <Stack direction="column" alignItems="flex-start">
          <EmptyState>{t('customers.users.table.emptyState')}</EmptyState>
        </Stack>
      )}
    </Wrapper>
  );
};
