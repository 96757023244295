import React, { useState } from 'react';

import styled from 'styled-components';

import CheckIcon from '@mui/icons-material/Check';

import { passwordField } from '@cd3p/core/utils/fields';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { FieldError } from 'react-hook-form';

import { useFormContext, useTranslation } from 'third-party';

import { FormField } from 'components/FormField/FormField';
import { FormHelperText, IconButton, InputAdornment } from 'components/index';

const StyledCheckIcon = styled(CheckIcon)`
  position: absolute;
  right: -4rem;
  top: 0.8rem;
  color: ${props => props.theme.custom.palette.success500};
`;

const PasswordHelperText = styled(FormHelperText)`
  margin: 0.5rem 0.2rem 0;
  color: ${props => props.theme.custom.palette.muted800};
  align-self: flex-start;
`;

export enum SetUpPasswordFormFields {
  Password = 'password',
  ConfirmPassword = 'confirmPassword',
}

export const setUpPasswordFormDefaultValues = {
  [SetUpPasswordFormFields.Password]: '',
  [SetUpPasswordFormFields.ConfirmPassword]: '',
};
export type SetUpPasswordFormData = typeof setUpPasswordFormDefaultValues;

type Props = {
  isFormPending?: boolean;
};

export const SetUpPasswordForm: React.FC<Props> = ({ isFormPending }) => {
  const { t } = useTranslation();

  const {
    watch,
    control,
    formState: { errors, dirtyFields, touchedFields },
  } = useFormContext();

  const watchedPasswordField = watch(SetUpPasswordFormFields.Password);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  return (
    <>
      <FormField
        type={showPassword ? 'text' : 'password'}
        fieldName={SetUpPasswordFormFields.Password}
        fieldError={errors[SetUpPasswordFormFields.Password] as FieldError}
        showError={touchedFields[SetUpPasswordFormFields.Password] === true}
        label={t('common.form.newPassword.label')}
        placeholder={t('common.form.newPassword.placeholder')}
        requiredErrorMessage={t('common.form.emptyFieldError')}
        control={control}
        maxLength={passwordField.maxLength}
        isRequired
        showIsRequiredMark={false}
        isDisabled={isFormPending}
        rules={{
          minLength: passwordField.minLength,
          deps: [SetUpPasswordFormFields.ConfirmPassword],
        }}
        InputProps={{
          endAdornment: (
            <>
              {dirtyFields[SetUpPasswordFormFields.Password] &&
                !errors[SetUpPasswordFormFields.Password] && (
                  <StyledCheckIcon />
                )}
              <InputAdornment position="end">
                <IconButton
                  sx={{ padding: 0 }}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            </>
          ),
        }}
      />
      <FormField
        type={showConfirmPassword ? 'text' : 'password'}
        fieldName={SetUpPasswordFormFields.ConfirmPassword}
        fieldError={
          errors[SetUpPasswordFormFields.ConfirmPassword] as FieldError
        }
        showError={
          touchedFields[SetUpPasswordFormFields.ConfirmPassword] === true
        }
        label={t('common.form.confirmPassword.label')}
        placeholder={t('common.form.confirmPassword.placeholder')}
        requiredErrorMessage={t('common.form.emptyFieldError')}
        control={control}
        maxLength={passwordField.maxLength}
        isRequired
        showIsRequiredMark={false}
        isDisabled={isFormPending}
        rules={{
          validate: {
            matchPasswordField: (value: string) =>
              !watchedPasswordField || value === watchedPasswordField,
          },
          deps: [SetUpPasswordFormFields.Password],
        }}
        InputProps={{
          endAdornment: (
            <>
              {watchedPasswordField &&
                !errors[SetUpPasswordFormFields.ConfirmPassword] && (
                  <StyledCheckIcon />
                )}
              <InputAdornment position="end">
                <IconButton
                  sx={{ padding: 0 }}
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            </>
          ),
        }}
      />
      <PasswordHelperText>
        {t('common.form.password.hint', {
          minLength: passwordField.minLength,
        })}
      </PasswordHelperText>
    </>
  );
};
