import React, { useMemo, useRef, useState } from 'react';

import styled from 'styled-components';

import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';

import { FilledInputProps } from '@mui/material';
import type { TextFieldProps } from '@mui/material/TextField';
import { IconButton, InputAdornment, TextField } from 'components';

import { _ } from 'third-party';

import { USER_INPUT_DEBOUNCE } from 'constants/common';

let debounceTimer: ReturnType<typeof _.debounce>;

const ClearButton = styled(IconButton)`
  padding: 0.5rem;
  > * {
    font-size: 2rem;
  }
`;

type SearchInputProps = {
  placeholder?: string;
  isClearable?: boolean;
  inputProps?: Partial<FilledInputProps>;
  onChange: (value: string) => void;
} & Omit<TextFieldProps, 'onChange'>;

export const SearchInput = ({
  onChange,
  placeholder = '',
  isClearable,
  inputProps,
  ...props
}: SearchInputProps) => {
  const textFieldRef = useRef<HTMLInputElement>(null);
  const [hasValue, setHasValue] = useState(!!props.defaultValue);

  const debouncedOnChange = useMemo(
    () => (debounceTimer = _.debounce(onChange, USER_INPUT_DEBOUNCE)),
    [onChange],
  );

  return (
    <TextField
      inputRef={textFieldRef}
      {...props}
      hiddenLabel
      size="small"
      variant="filled"
      placeholder={placeholder}
      InputProps={{
        disableUnderline: true,
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon color={'primary'} />
          </InputAdornment>
        ),
        ...(hasValue &&
          isClearable && {
            endAdornment: (
              <InputAdornment position="end">
                <ClearButton
                  onClick={() => {
                    debounceTimer.cancel();
                    textFieldRef.current!.value = '';
                    onChange('');
                    textFieldRef.current!.focus();
                    setHasValue(false);
                  }}
                >
                  <CloseIcon />
                </ClearButton>
              </InputAdornment>
            ),
          }),
        ...inputProps,
      }}
      onChange={e => {
        isClearable && setHasValue(e.target.value.length > 0);
        debouncedOnChange(e.target.value);
      }}
    />
  );
};
