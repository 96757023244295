import React, { useMemo } from 'react';

import styled from 'styled-components';

import { FormControlLabel, Stack, Switch, Typography } from 'components';

import { useSelector, useTranslation } from 'third-party';

import { AppTableSettings } from 'constants/common';

import {
  toggleTableSettingsColumnVisibility,
  useStorage,
} from 'modules/storage';

import { storageSelectors } from 'selectors';

import { OrdersTableColumn } from 'features/OrdersTable/OrdersTable';

const Header = styled(Typography)`
  font-size: 1.6rem;
  font-weight: 900;
  text-transform: uppercase;
`;

const Wrapper = styled.div`
  padding: 2rem 3.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Label = styled(Typography)`
  font-size: 1.6rem;
  font-weight: 400;
  margin-left: 1rem;
`;

type Props = {
  tableName: AppTableSettings;
  columns: OrdersTableColumn[];
};

export const TableSettings: React.FC<Props> = ({ columns, tableName }) => {
  const { t } = useTranslation();
  const { updateUserSettingInStorage } = useStorage();

  const allOrdersHiddenColumns = useSelector(
    storageSelectors.tableSettingsHiddenColumnsSelector[tableName],
  );

  const onColumnVisibilityChanged = (
    event: React.ChangeEvent<HTMLInputElement>,
    columnId: OrdersTableColumn['id'],
  ) => {
    updateUserSettingInStorage(
      toggleTableSettingsColumnVisibility[tableName](
        columnId,
        event.target.checked,
      ),
    );
  };

  const canBeHiddenColumns = useMemo(() => {
    return columns.filter(it => it.canBeHidden);
  }, [columns]);

  return (
    <Wrapper>
      <Header>{t('ordersList.tableSettings.header')}</Header>
      {canBeHiddenColumns.map(it => (
        <Stack key={it.id} direction="row" alignItems="center">
          <FormControlLabel
            control={
              <Switch
                checked={!(allOrdersHiddenColumns || []).includes(it.id)}
                onChange={event => onColumnVisibilityChanged(event, it.id)}
              />
            }
            label={<Label>{it.label}</Label>}
          />
        </Stack>
      ))}
    </Wrapper>
  );
};
