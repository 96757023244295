import React, { useEffect } from 'react';

import { ON_ORDER_UPDATE_EVENT } from '@cd3p/core/constants/hubs';

import { useLoadAllOrders } from './useLoadAllOrders';

import { RouterOutlet } from 'third-party';

import { useAllOrdersList } from 'modules/allOrdersList';

import { useOrdersSockets } from 'utils/sockets';

export const AllOrdersWrapper = () => {
  const { loadAllOrdersList } = useLoadAllOrders();

  const {
    resetAllOrdersSearch,
    resetAllOrdersScrollPosition,
    updateOrderPositionInAllOrders,
  } = useAllOrdersList();

  useOrdersSockets(
    () => [
      {
        method: ON_ORDER_UPDATE_EVENT,
        action: (providerId: string, orderId: number) => {
          updateOrderPositionInAllOrders(orderId);
        },
      },
    ],
    [updateOrderPositionInAllOrders],
  );

  useEffect(() => {
    resetAllOrdersScrollPosition();
    loadAllOrdersList();
  }, [resetAllOrdersScrollPosition, loadAllOrdersList]);

  useEffect(() => {
    return () => {
      resetAllOrdersSearch();
      resetAllOrdersScrollPosition();
    };
  }, [resetAllOrdersSearch, resetAllOrdersScrollPosition]);
  return <RouterOutlet />;
};
