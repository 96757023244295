import React, { useCallback, useEffect, useState } from 'react';

import styled, { useTheme } from 'styled-components';

import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import DateRangeIcon from '@mui/icons-material/DateRange';

import {
  IconButton,
  InputAdornment,
  Paper,
  Popover,
  TextField,
} from 'components';
import { DateRange, RangeKeyDict } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import { dayjs, useTranslation } from 'third-party';

const calendarWidth = '21rem';

const StyledTextField = styled(TextField)`
  input {
    padding: 0.8rem 1.6rem;
    font-size: 1.6rem;
  }
`;

const StyledInputAdornment = styled(InputAdornment)`
  margin-left: 0;
`;

const ClearButton = styled(IconButton)`
  padding: 0;
  svg {
    padding: 0.2rem;
    width: 0.6em;
    height: 0.6em;
  }
  &:disabled {
    opacity: 0;
  }
`;

const DateRangeWrapper = styled.div<{ width: string; height: string }>`
  width: ${props => props.width};
  height: ${props => props.height};
  padding: 0 1.6rem 1.6rem;
  box-sizing: border-box;
  overflow-y: hidden;
  * {
    font-family: Source Sans Pro, serif;
  }
  .rdrCalendarWrapper {
    display: flex;
    align-items: center;
    > * {
      width: ${calendarWidth};
    }
  }
  .rdrNextPrevButton,
  .rdrNextPrevButton {
    margin: 0;
    background-color: transparent;
  }
  .rdrNextButton {
    margin-right: 1rem;
  }
  .rdrPprevButton {
    margin-left: 1rem;
  }
  .rdrMonthPicker {
    color: rgba(0, 0, 0, 0.6);
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 2.4rem;
    letter-spacing: 0.1px;
  }
  .rdrWeekDays {
    padding: 0;
  }
  .rdrMonthAndYearWrapper {
    padding: 0;
    width: 25rem;
  }
  .rdrMonth {
    padding: 0;
    width: 100%;
  }
  .rdrDayEndOfWeek .rdrDayStartPreview,
  .rdrDayEndOfWeek .rdrDayInPreview,
  .rdrDayEndPreview {
    right: 1px;
  }
  .rdrDayStartOfWeek .rdrDayInPreview,
  .rdrDayStartPreview {
    left: 1px;
  }
  .rdrDayStartPreview.rdrDayEndPreview {
    top: 5px;
    right: 2px;
    bottom: 5px;
    left: 2px;
  }
  .rdrDayEndPreview,
  .rdrDayStartPreview,
  .rdrDayInPreview {
    top: 4px;
    bottom: 4px;
  }
  .rdrDayToday .rdrDayNumber ::after {
    background: ${props => props.theme.custom.palette.primary900};
    width: 8px;
    height: 1px;
  }
`;

type DateRangeCalendarProps = {
  fromDate?: Date;
  toDate?: Date;
  width: string;
  height: string;
  onFromChange: (date: Date | undefined) => void;
  onToChange: (date: Date | undefined) => void;
};

export const DateRangeCalendar: React.FC<DateRangeCalendarProps> = ({
  width,
  height,
  onFromChange,
  onToChange,
  fromDate,
  toDate,
}) => {
  const theme = useTheme();

  const maxDate = dayjs(new Date()).add(1, 'year').toDate();

  const onSelectDateRanges = useCallback(
    ({ selection }: RangeKeyDict) => {
      const startDate = dayjs(selection.startDate).isValid()
        ? dayjs(selection.startDate).toDate()
        : undefined;
      const endDate = dayjs(selection.endDate).isValid()
        ? dayjs(selection.endDate).toDate()
        : undefined;

      onFromChange(startDate);
      onToChange(endDate);
    },
    [onFromChange, onToChange],
  );

  return (
    <DateRangeWrapper width={width} height={height}>
      <DateRange
        ranges={[
          {
            // @ts-ignore fix to have the initial calendar unselected
            startDate: fromDate ?? null,
            endDate: toDate ?? new Date(''),
            key: 'selection',
          },
        ]}
        onChange={onSelectDateRanges}
        weekdayDisplayFormat="EEEEE"
        weekStartsOn={1}
        rangeColors={[theme.custom.palette.primary900]}
        maxDate={maxDate}
        showMonthAndYearPickers
        moveRangeOnFirstSelection={false}
        showDateDisplay={false}
        scroll={{ enabled: true }}
      />
    </DateRangeWrapper>
  );
};

type DateRangePickerProps = {
  label?: string;
  dateFormat?: string;
  placeholder?: string;
  fromDate?: Date;
  toDate?: Date;
  popupWidth?: string;
  popupHeight?: string;
  keepOpen?: boolean;
  onFromChange: (date: Date | undefined) => void;
  onToChange: (date: Date | undefined) => void;
};

export const DateRangePicker: React.FC<DateRangePickerProps> = ({
  label,
  dateFormat = 'MM/DD/YY',
  placeholder,
  popupWidth = '25.5rem',
  popupHeight = '32.4rem',
  fromDate,
  toDate,
  onFromChange,
  onToChange,
  keepOpen = false,
}) => {
  const { t } = useTranslation();

  const [displayCalendar, setDisplayCalendar] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const showCalendar = () => {
    setDisplayCalendar(true);
  };

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const { fromDate, toDate } = processInputValue(inputValue);

    setInputValue(inputValue);
    onFromChange(fromDate);
    onToChange(toDate);
  };

  const onPopoverClose = () => {
    setDisplayCalendar(false);
  };

  useEffect(() => {
    let inputValue = '';
    const fromDateString = fromDate ? dayjs(fromDate).format(dateFormat) : '';
    const toDateString = toDate ? dayjs(toDate).format(dateFormat) : '';

    if (fromDateString && toDateString) {
      inputValue =
        fromDateString === toDateString
          ? fromDateString
          : [fromDateString, toDateString].join(' - ');
    }
    setInputValue(inputValue);
  }, [dateFormat, fromDate, toDate]);

  const processInputValue = (value: string) => {
    const [fromDateString, toDateString] = value
      .split('-')
      .map(element => element.trim());

    const fromDate = dayjs(fromDateString, dateFormat).isValid()
      ? dayjs(fromDateString, dateFormat).toDate()
      : undefined;
    const toDate = dayjs(toDateString, dateFormat).isValid()
      ? dayjs(toDateString, dateFormat).toDate()
      : undefined;

    return { fromDate, toDate };
  };

  const onClear = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      onFromChange(undefined);
      onToChange(undefined);
    },
    [onFromChange, onToChange],
  );

  const areDatesDefined = fromDate || toDate;

  return (
    <div>
      <StyledTextField
        label={label}
        inputRef={ref => {
          setAnchorEl(ref);
        }}
        fullWidth
        value={inputValue}
        placeholder={placeholder || t('ordersList.filters.datePlaceholder')}
        variant="filled"
        size="small"
        onClick={showCalendar}
        InputProps={{
          readOnly: true,
          sx: {
            paddingRight: 0,
            height: '3.8rem',
          },
          endAdornment: (
            <StyledInputAdornment position="end">
              <ClearButton disabled={!areDatesDefined} onClick={onClear}>
                <ClearOutlinedIcon />
              </ClearButton>
              <IconButton onClick={showCalendar}>
                <DateRangeIcon />
              </IconButton>
            </StyledInputAdornment>
          ),
        }}
        onChange={onInputChange}
      />
      {keepOpen ? (
        <Paper elevation={2}>
          <DateRangeCalendar
            width={popupWidth}
            height={popupHeight}
            onFromChange={onFromChange}
            onToChange={onToChange}
            toDate={toDate}
            fromDate={fromDate}
          />
        </Paper>
      ) : (
        <Popover
          open={displayCalendar}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={onPopoverClose}
        >
          <DateRangeCalendar
            width={popupWidth}
            height={popupHeight}
            onFromChange={onFromChange}
            onToChange={onToChange}
            toDate={toDate}
            fromDate={fromDate}
          />
        </Popover>
      )}
    </div>
  );
};
