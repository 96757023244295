import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import type { Dispatch, SetStateAction } from 'react';

import styled from 'styled-components';

import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

import {
  Button,
  Checkbox,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  LoadingButton,
  Popover,
  PopoverCloseButton,
  Stack,
  Tooltip,
  Typography,
} from 'components';

import { _, useBottomScrollListener, useTranslation } from 'third-party';

import {
  INFINITE_SCROLL_BOTTOM_OFFSET_PX,
  INFINITE_SCROLL_DEBOUNCE_TIME_MS,
  TOOLTIP_APPEAR_DELAY,
} from 'constants/common';

import {
  GenericCategoryItem,
  NormalizedCategories,
} from 'modules/concreteCategories/commonConcreteCategories';

import { OutlinedSearchInput } from 'styles/common';

const StyledSearchInput = styled(OutlinedSearchInput)`
  width: 100%;
`;

const CategoryName = styled(ListSubheader)`
  position: static;
  font-size: 1.4rem;
  font-weight: 900;
  text-transform: uppercase;
  line-height: 2.6rem;
  padding: 0.8rem 1rem;
  color: ${props => props.theme.custom.palette.bluegray400};
`;

const StyledList = styled(List)`
  border: 1px solid ${props => props.theme.custom.palette.muted100};
  border-radius: 0.4rem;
  width: 100%;
  height: 40rem;
  overflow: auto;
  padding: 0;
`;
const StyledListHeader = styled(Typography)`
  font-size: 1.6rem;
  margin-bottom: 0.6rem;
`;
const AvailableListItem = styled(ListItem)<{ checked?: boolean }>`
  cursor: pointer;
  height: 3.8rem;
  background-color: ${props =>
    props.checked ? props.theme.custom.palette.secondary50 : 'transparent'};
`;

const SelectedListItem = styled(AvailableListItem)`
  padding: 0.8rem 1rem;
`;

const PlaceholderListItem = styled(SelectedListItem)`
  color: ${props => props.theme.custom.palette.bluegray400};
  pointer-events: none;
  user-select: none;
`;

const StyledListItemText = styled(ListItemText)`
  font-weight: 600;
  margin: 0;
  white-space: nowrap;

  & .MuiListItemText-primary {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const SearchPanel = styled(Stack)`
  border-bottom: 1px solid ${props => props.theme.custom.palette.muted50};
  background: ${props => props.theme.custom.palette.backgroundTheme};
  justify-content: flex-end;
  padding: 0.8rem;
`;

const AvailabilityCounter = styled.span`
  margin-left: auto;
  font-size: 1.2rem;
  font-weight: 400;
  padding: 0 1rem;
  flex-shrink: 0;
  color: ${props => props.theme.custom.palette.secondary500};
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  padding: 2.2rem 2.3rem 0;
  z-index: 1;
`;

const Title = styled(Typography)`
  line-height: 100%;
  color: ${props => props.theme.custom.palette.primary900};
  margin-bottom: 1.5rem;
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1.6rem;
  margin-bottom: 2.3rem;
`;

const Content = styled(Stack)`
  flex-grow: 1;
  padding: 2.2rem 2.3rem 2.7rem;
  width: 80rem;
  overflow-y: scroll;
`;

const Container = styled(Stack)`
  flex-direction: row;
  gap: 1rem;
  margin-top: 1rem;
`;

type AddConcreteCategoriesPopupProps = {
  loadItems: () => void;
  searchItems: (value: string) => void;
  loadMoreItems: () => void;
  onClose: () => void;
  addSelectedItems: (selectedItems: GenericCategoryItem[]) => void;
  addButtonLabel: string;
  headerText: string;
  subheaderText: string;
  selectedListHeaderText: string;
  availableListHeaderText: string;
  availableListEmptyText?: string;
  availableListEmptySearchText?: string;
  selectedListEmptyText?: string;
  items: NormalizedCategories<GenericCategoryItem>;
  canLoadMoreItems: boolean;
  itemsLoaded: boolean;
  itemsPending: boolean;
  isSaving: boolean;
};
export const AddConcreteCategoriesPopup = ({
  loadItems,
  searchItems,
  loadMoreItems,
  addSelectedItems,
  onClose,
  addButtonLabel,
  headerText,
  subheaderText,
  selectedListHeaderText,
  availableListHeaderText,
  availableListEmptyText,
  availableListEmptySearchText,
  selectedListEmptyText,
  itemsLoaded,
  itemsPending,
  isSaving,
  items,
  canLoadMoreItems,
}: AddConcreteCategoriesPopupProps) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (!itemsLoaded) {
      loadItems();
    }
  }, [itemsLoaded, loadItems]);

  const [selectedItems, setSelectedItems] = useState<GenericCategoryItem[]>([]);

  return (
    <Popover
      anchorEl={document.body}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      open
      PaperProps={{ sx: { overflow: 'visible' } }}
    >
      <PopoverCloseButton onClose={onClose} />
      <Header>
        <Title variant="h3">{headerText}</Title>
        <ActionButtons>
          <Button variant="outlined" color="secondary" onClick={onClose}>
            {t('common.cancel')}
          </Button>
          <LoadingButton
            loading={isSaving}
            variant="contained"
            color="primary"
            disabled={!selectedItems.length}
            onClick={() => {
              addSelectedItems(selectedItems);
            }}
          >
            {addButtonLabel}
          </LoadingButton>
        </ActionButtons>
      </Header>
      <Content>
        <Typography>{subheaderText}</Typography>
        <ConcreteGategoriesList
          items={items}
          canLoadMoreItems={canLoadMoreItems}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          searchItems={searchItems}
          loadMoreItems={loadMoreItems}
          itemsPending={itemsPending}
          selectedListHeader={selectedListHeaderText}
          availableListHeader={availableListHeaderText}
          availableListEmptyText={availableListEmptyText}
          availableListEmptySearchText={availableListEmptySearchText}
          selectedListEmptyText={selectedListEmptyText}
        />
      </Content>
    </Popover>
  );
};

type ConcreteGategoriesListProps<T extends GenericCategoryItem> = {
  items: NormalizedCategories<T>;
  itemsPending: boolean;
  loadMoreItems: () => void;
  searchItems: (value: string) => void;
  canLoadMoreItems: boolean;
  selectedItems: T[];
  setSelectedItems: Dispatch<SetStateAction<GenericCategoryItem[]>>;
  availableListHeader?: string;
  selectedListHeader?: string;
  availableListEmptyText?: string;
  availableListEmptySearchText?: string;
  selectedListEmptyText?: string;
};
export const ConcreteGategoriesList = ({
  items,
  canLoadMoreItems,
  itemsPending,
  loadMoreItems,
  searchItems,
  selectedItems,
  setSelectedItems,
  availableListHeader,
  selectedListHeader,
  availableListEmptyText,
  availableListEmptySearchText,
  selectedListEmptyText,
}: ConcreteGategoriesListProps<GenericCategoryItem>) => {
  const handleListItemClick = (targetItem: GenericCategoryItem) => {
    /*
      Clicking on an item of any category should either select all pairs of
      items with the same name or deselect them if they are already selected.
    */
    if (selectedItems.includes(targetItem)) {
      setSelectedItems(selectedItems.filter(item => item.id !== targetItem.id));
      return;
    }

    const allCheckedCategories = Object.values(items).reduce<
      GenericCategoryItem[]
    >((acc, category) => {
      const foundItem = category.items.find(item => item.id === targetItem.id);
      if (foundItem) {
        acc.push(foundItem);
      }
      return acc;
    }, []);

    /*
      Selected items should be sorted by category name to ease and speed up
      the rendering of the right list (where selected items are displayed).
      It also makes it easier to remove them from the list wnenever needed.
      Sometimes categories can have identical names, so we also sort them by
      category id to avoid any possible issues.
    */
    setSelectedItems(
      Array.from(new Set([...selectedItems, ...allCheckedCategories]))
        .sort((a, b) => a.categoryName.localeCompare(b.categoryName))
        .sort((a, b) =>
          a.categoryId > b.categoryId
            ? 1
            : a.categoryId < b.categoryId
            ? -1
            : 0,
        ),
    );
  };

  const onRemoveItem = useCallback(
    (item: GenericCategoryItem) => {
      setSelectedItems(items => items.filter(i => i.id !== item.id));
    },
    [setSelectedItems],
  );

  return (
    <Container direction="row" spacing="1rem">
      <ListWrapper>
        <StyledListHeader variant="h5">{availableListHeader}</StyledListHeader>
        <AvailableList
          items={items}
          itemsPending={itemsPending}
          loadMoreItems={canLoadMoreItems ? loadMoreItems : undefined}
          searchItems={searchItems}
          onClick={handleListItemClick}
          selectedItems={selectedItems}
          emptyText={availableListEmptyText}
          emptySearchText={availableListEmptySearchText}
        />
      </ListWrapper>

      <ListWrapper>
        <StyledListHeader variant="h5">{selectedListHeader}</StyledListHeader>
        <SelectedList
          selectedItems={selectedItems}
          onRemoveItem={onRemoveItem}
          emptyText={selectedListEmptyText}
        />
      </ListWrapper>
    </Container>
  );
};

const ListWrapper = styled(Stack)`
  width: 37.5rem;
`;

type AvailableListProps = {
  items: NormalizedCategories<GenericCategoryItem>;
  itemsPending: boolean;
  loadMoreItems?: () => void;
  searchItems: (value: string) => void;
  selectedItems: GenericCategoryItem[];
  onClick: (item: GenericCategoryItem) => void;
  emptyText?: string;
  emptySearchText?: string;
};
const AvailableList = ({
  items,
  itemsPending,
  loadMoreItems,
  searchItems,
  selectedItems,
  onClick,
  emptyText,
  emptySearchText,
}: AvailableListProps) => {
  const { t } = useTranslation();

  const isLoadPending = useRef(false);
  useEffect(() => {
    if (!itemsPending || !loadMoreItems) {
      isLoadPending.current = false;
      return;
    }
    isLoadPending.current = itemsPending;
  }, [itemsPending, loadMoreItems]);
  const onScrollToBottom = () => {
    if (!loadMoreItems || isLoadPending.current) return;
    isLoadPending.current = true;
    loadMoreItems();
  };

  const onScrollToBottomDebounced = _.throttle(
    onScrollToBottom,
    INFINITE_SCROLL_DEBOUNCE_TIME_MS,
  );

  const scrollRef = useBottomScrollListener<HTMLUListElement>(
    onScrollToBottomDebounced,
    {
      offset: INFINITE_SCROLL_BOTTOM_OFFSET_PX,
      triggerOnNoScroll: false,
    },
  );

  const [inputValue, setInputValue] = useState('');
  const onInputChange = useCallback(
    (value: string) => {
      searchItems(value);
      setInputValue(value);
    },
    [searchItems],
  );

  const sortedItems = useMemo(
    () => Object.entries(items).sort(sortCategoryKeysByName),
    [items],
  );

  const hasItems = sortedItems.length > 0;
  const shouldDisplayEmptyList = !itemsPending && !hasItems;

  if (shouldDisplayEmptyList && !inputValue) {
    return (
      <StyledList>
        <PlaceholderListItem key="empty">
          <StyledListItemText primary={emptyText} />
        </PlaceholderListItem>
      </StyledList>
    );
  }

  return (
    <StyledList ref={scrollRef}>
      <SearchPanel>
        <StyledSearchInput
          placeholder={t('mixTypes.searchPlaceholder')}
          onChange={onInputChange}
        />
      </SearchPanel>
      {shouldDisplayEmptyList && inputValue && (
        <PlaceholderListItem key="empty">
          <StyledListItemText primary={emptySearchText} />
        </PlaceholderListItem>
      )}
      {sortedItems.map(([id, category]) => (
        <li key={id}>
          <ul>
            <CategoryName>{category.categoryName}</CategoryName>
            {category.items.map(item => {
              const isChecked = selectedItems.includes(item);
              return (
                <AvailableListItem
                  key={item.name + item.categoryId}
                  disablePadding
                  checked={isChecked}
                  onClick={() => onClick(item)}
                >
                  <Checkbox checked={isChecked} disableRipple />
                  <Tooltip
                    placement="top-start"
                    disableInteractive
                    enterDelay={TOOLTIP_APPEAR_DELAY}
                    title={item.name}
                  >
                    <StyledListItemText primary={item.name} />
                  </Tooltip>
                  <AvailabilityCounter>
                    {t('customers.profile.lists.availableCount', {
                      count: item.usage,
                    })}
                  </AvailabilityCounter>
                </AvailableListItem>
              );
            })}
          </ul>
        </li>
      ))}
      {itemsPending && (
        <AvailableListItem key="loading">
          <CircularProgress />
        </AvailableListItem>
      )}
    </StyledList>
  );
};

type SelectedListProps = {
  selectedItems: GenericCategoryItem[];
  onRemoveItem: (item: GenericCategoryItem) => void;
  emptyText?: string;
};
const SelectedList = ({
  selectedItems,
  onRemoveItem,
  emptyText,
}: SelectedListProps) => {
  if (!selectedItems || !selectedItems.length)
    return (
      <StyledList>
        <PlaceholderListItem key="empty">
          <StyledListItemText primary={emptyText} />
        </PlaceholderListItem>
      </StyledList>
    );

  return (
    <StyledList>
      {_.uniqBy(selectedItems, 'id').reduce<React.ReactElement[]>(
        (acc, item) => {
          acc.push(
            <SelectedListItem key={item.name + item.categoryName}>
              <Tooltip
                placement="top-start"
                disableInteractive
                enterDelay={TOOLTIP_APPEAR_DELAY}
                title={item.name}
              >
                <StyledListItemText primary={item.name} />
              </Tooltip>
              <IconButton onClick={() => onRemoveItem(item)}>
                <CancelOutlinedIcon />
              </IconButton>
            </SelectedListItem>,
          );

          return acc;
        },
        [],
      )}
    </StyledList>
  );
};

function sortCategoryKeysByName(
  a: [string, { categoryName: string }],
  b: [string, { categoryName: string }],
) {
  const aCategoryName = a[1].categoryName;
  const bCategoryName = b[1].categoryName;
  return aCategoryName.localeCompare(bCategoryName);
}
