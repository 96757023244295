import React from 'react';

import styled from 'styled-components';

import { APIMasterAdditiveType, APIMasterMixType } from '@cd3p/core/types/api';
import { OmitFirstArg } from '@cd3p/core/types/utils';
import { Stack } from '@mui/material';
import {
  Button,
  FormField,
  LoadingButton,
  PopoverCloseButton,
  TypeaheadFormField,
  Typography,
} from 'components';

import { FieldError, useForm, useSelector, useTranslation } from 'third-party';

import { FIELD_MAX_LENGTH } from 'constants/common';

import { updateAdditiveType } from 'modules/additiveTypesList';
import { createDataCategory, loadDataCategories } from 'modules/cache';
import { updateMixType } from 'modules/mixTypesList';

import { cacheSelectors } from 'selectors';

import {
  AddForm,
  AddFormFields,
  loadDataCategoryOptions,
  useSubmitAddForm,
} from 'features/PlantSettings/common/AddForm';
import { getCategoryExcludedIds } from 'features/PlantSettings/common/commonHelpers';

import { TypeaheadOption } from 'types/app';

const FormWrapper = styled.form`
  position: relative;
  width: 50rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-height: 0;
  flex-grow: 1;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  padding: 2.2rem 2.3rem 0;
  z-index: 1;
`;

const Title = styled(Typography)`
  line-height: 100%;
  color: ${props => props.theme.custom.palette.primary900};
  margin-bottom: 1.5rem;
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1.6rem;
  margin-bottom: 2.3rem;
`;

const Content = styled(Stack)`
  flex-grow: 1;
  padding: 2.2rem 2.3rem 2.7rem;
`;

type Props = {
  defaultValues: APIMasterMixType | APIMasterAdditiveType;
  updatePending: boolean;
  updateData: OmitFirstArg<typeof updateMixType | typeof updateAdditiveType>;
  loadDataCategories: OmitFirstArg<OmitFirstArg<typeof loadDataCategories>>;
  createDataCategory: OmitFirstArg<OmitFirstArg<typeof createDataCategory>>;
  onClosePopup?: () => void;
  onSuccessUpdate?: () => void;
  title: string;
  submitButtonLabel: string;
  categoryFieldLabel: string;
  successUpdateMessage: string;
  nameFieldLabel: string;
  nameFieldPlaceholder: string;
  typeaheadPlaceholder: string;
};

export const EditFormPopup: React.FC<Props> = ({
  defaultValues,
  updateData,
  loadDataCategories,
  createDataCategory,
  updatePending,
  onClosePopup,
  onSuccessUpdate,
  title,
  submitButtonLabel,
  categoryFieldLabel,
  successUpdateMessage,
  nameFieldLabel,
  nameFieldPlaceholder,
  typeaheadPlaceholder,
}) => {
  const { t } = useTranslation();

  const createDataCategoryPending = useSelector(
    cacheSelectors.createDataCategoryPending,
  );

  const {
    setValue,
    getValues,
    handleSubmit,
    control,
    formState: { isValid, isDirty, errors },
  } = useForm<AddForm>({
    defaultValues: {
      [AddFormFields.Name]: defaultValues.name || '',
      [AddFormFields.Category]: (defaultValues.categories || []).map(it => ({
        label: it.name || '',
        value: it.id!.toString(),
      })),
    },
  });

  // @ts-ignore TODO: find out the correct type
  const submitFunction = updateData.bind(null, defaultValues.id);

  const onSubmit = useSubmitAddForm<
    typeof createDataCategory,
    typeof submitFunction,
    typeof setValue
  >({
    isValid,
    setValue,
    createCategory: createDataCategory,
    submit: submitFunction,
    onSuccess: () => {
      onClosePopup?.();
      onSuccessUpdate?.();
    },
    successMessage: successUpdateMessage,
  });

  const onCategoryChange = (selectedOption: TypeaheadOption[] | null) => {
    setValue(AddFormFields.Category, selectedOption, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const loadCategoryOptions = loadDataCategoryOptions(
    loadDataCategories,
    getCategoryExcludedIds(getValues(AddFormFields.Category)),
  );

  const isFormPending = updatePending || createDataCategoryPending;

  return (
    <>
      <PopoverCloseButton onClose={() => onClosePopup?.()} />
      <FormWrapper onSubmit={handleSubmit(onSubmit)}>
        <Header>
          <Title variant="h3">{title}</Title>
          <ActionButtons>
            <Button
              disabled={isFormPending}
              variant="outlined"
              color="secondary"
              onClick={() => onClosePopup?.()}
            >
              {t('common.cancel')}
            </Button>
            <LoadingButton
              variant="contained"
              type="submit"
              loading={isFormPending}
              disabled={!isValid || !isDirty}
            >
              {submitButtonLabel}
            </LoadingButton>
          </ActionButtons>
        </Header>
        <Content>
          <FormField
            fieldName={AddFormFields.Name}
            label={nameFieldLabel}
            placeholder={nameFieldPlaceholder}
            requiredErrorMessage={t('common.form.emptyFieldError')}
            control={control}
            maxLength={FIELD_MAX_LENGTH}
            isRequired
          />
          <FormField
            fieldName={AddFormFields.Category}
            label={categoryFieldLabel}
            fieldError={errors[AddFormFields.Category] as FieldError}
            control={control}
            isRequired
            render={({ field }) => (
              <TypeaheadFormField
                openMenuOnFocus
                openMenuOnClick
                defaultOptions
                isCreatable
                isMulti
                placeholder={typeaheadPlaceholder}
                loadOptions={loadCategoryOptions}
                onChange={onCategoryChange}
                value={field.value}
                maxLength={FIELD_MAX_LENGTH}
                isDisabled={isFormPending}
              />
            )}
          />
        </Content>
      </FormWrapper>
    </>
  );
};
