import { useCallback, useContext } from 'react';

import { LocationT } from '@cd3p/core/hooks/useLocationChangeConfirmation';
import { LocationChangeConfirmationContext } from 'providers/LocationChangeConfirmationProvider';
import {
  NavigateOptions,
  useNavigate as useRouterNavigate,
} from 'react-router-dom';

// wrap native `navigate` function with a wrapper to
// be able to cancel navigation in some cases
export const useNavigate = () => {
  const navigate = useRouterNavigate();

  const { locationChangeConfirmation } = useContext(
    LocationChangeConfirmationContext,
  );

  return useCallback(
    (url: LocationT, options: NavigateOptions = {}) => {
      // when location changes we call confirmation callbacks if any registered
      // if any of them returns `false` no navigation happens. we use optional chaining here
      // because `useNavigate` hook can be called inside `LocationChangeConfirmationProvider`
      // when user confirms redirection. in that case `locationChangeConfirmation` can be `undefined`
      // because of the dependencies loop (I guess), but that's fine, we don't need to call
      // `locationChangeConfirmation` again since user already confirmed redirection
      if (locationChangeConfirmation?.(url, options) === false) {
        return;
      }

      navigate(url, options);
    },
    [locationChangeConfirmation, navigate],
  );
};
