import styled from 'styled-components';

import { Button, Stack, Typography } from 'components';

import { TypeaheadOption } from 'types/app';

export const getCategoryExcludedIds = (
  selectedOptions: TypeaheadOption[] | null,
) => {
  return (selectedOptions || []).reduce<number[]>(
    (result, it) => (it.__isNew__ ? result : [...result, Number(it.value)]),
    [],
  );
};
export const ListWrapper = styled(Stack)`
  flex-grow: 1;
  padding: 0 0.8rem;
  overflow-y: scroll;
  margin-bottom: 4rem;
`;
export const ListItem = styled(Stack)`
  flex-direction: row;
  align-items: center;
  padding: 0.4rem 0.8rem;
  justify-content: space-between;
  gap: 1rem;
`;
export const ListItemLabel = styled(Typography)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex-grow: 1;
  min-width: 0;
  color: ${props => props.theme.custom.palette.bluegray600};
`;
export const ListItemUsage = styled(Typography)`
  white-space: nowrap;
  color: ${props => props.theme.custom.palette.secondary500};
`;
export const ListItemLink = styled(Button)`
  font-size: 1.2rem;
  font-weight: 900;
  line-height: 1.8rem;
  text-wrap: nowrap;
`;
export const LoaderListItem = styled(ListItem)`
  height: 4rem;
`;
export const LoaderWrapper = styled(Stack)`
  align-items: center;
  justify-content: center;
  flex: 1;
`;
