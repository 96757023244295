import { useMemo } from 'react';

import { httpMethod, simplifyBuilder } from '@cd3p/core';
import {
  APIAdditiveTypeSearchFilter,
  APIAdditiveUsage,
  APIMasterAdditiveType,
  APIMasterAdditiveTypeModelSearchResult,
  APIMasterAdditiveTypeRequest,
  APIMostUsedMixTypes,
  APISortOrder,
  APIUsage,
  APIUsageSearchResult,
} from '@cd3p/core/types/api';
import {
  DEFAULT_PAGE_SIZE,
  getPageNumberAfterLoadMore,
  getPageSizeDependingOnItems,
} from '@cd3p/core/utils/lists';

import { _, bindActionCreators, useDispatch, useSelector } from 'third-party';

import {
  additiveTypeEndpoint,
  loadAdditiveTypeAssignmentEndpoint,
  loadAdditiveTypesEndpoint,
  loadLeastUsedAdditiveTypesEndpoint,
  loadMostUsedAdditiveTypesEndpoint,
} from 'constants/endpoints';

import { State } from 'modules/index';

import { appSelectors } from 'selectors';

const defaultAdditiveTypesFilters = {
  name: '',
};

export type AdditiveTypesFilters = typeof defaultAdditiveTypesFilters;

export type AdditiveTypesSorting = {
  sortField: AdditiveTypesListColumnId;
  sortOrder: APISortOrder;
}[];

export type AdditiveTypesListColumnId = keyof APIMasterAdditiveType;

export type AdditiveTypesListState = {
  items: APIMasterAdditiveTypeModelSearchResult['result'];
  projectsAdditiveTypesItems: APIMasterAdditiveType[];
  projectsAdditiveTypesSearch: string;
  additiveTypesLoaded: boolean;
  projectsAdditiveTypesLoaded: boolean;
  createAdditiveTypePending: boolean;
  updateAdditiveTypePending: boolean;
  loadAdditiveTypesPending: boolean;
  loadMoreAdditiveTypesPending: boolean;
  loadProjectsAdditiveTypesPending: boolean;
  loadMoreProjectsAdditiveTypesPending: boolean;
  sorting: AdditiveTypesSorting;
  pagination: {
    pageNumber: APIMasterAdditiveTypeModelSearchResult['pageNumber'];
    pageSize: APIMasterAdditiveTypeModelSearchResult['pageSize'];
  };
  projectsAdditiveTypesPagination: {
    pageNumber: APIMasterAdditiveTypeModelSearchResult['pageNumber'];
    pageSize: APIMasterAdditiveTypeModelSearchResult['pageSize'];
  };
  filters: AdditiveTypesFilters;
  count: number;
  projectsAdditiveTypesCount: number;
  additiveTypeAssignment: APIAdditiveUsage[] | null;
  additiveTypeAssignmentId: number | null;
  loadAdditiveTypeAssignmentPending: boolean;
  addAdditiveTypeToMasterListPending: boolean;
  fetchAdditiveTypePending: boolean;
  mostUsedAdditiveTypes: null | APIMostUsedMixTypes;
  loadMostUsedAdditiveTypesPending: boolean;
  leastUsedAdditiveTypes: {
    count: number;
    items: APIUsage[];
    additiveTypesLoaded: boolean;
    pagination: { pageNumber: number; pageSize: number };
  };
  loadLeastUsedAdditiveTypesPending: boolean;
  loadMoreLeastUsedAdditiveTypesPending: boolean;
};

export const additiveTypesListState: AdditiveTypesListState = {
  items: [],
  projectsAdditiveTypesItems: [],
  sorting: [
    {
      sortField: 'name',
      sortOrder: APISortOrder.ASC,
    },
  ],
  additiveTypesLoaded: false,
  projectsAdditiveTypesLoaded: false,
  createAdditiveTypePending: false,
  updateAdditiveTypePending: false,
  loadMoreAdditiveTypesPending: false,
  loadAdditiveTypesPending: false,
  loadProjectsAdditiveTypesPending: false,
  loadMoreProjectsAdditiveTypesPending: false,
  pagination: { pageNumber: 1, pageSize: DEFAULT_PAGE_SIZE },
  projectsAdditiveTypesPagination: {
    pageNumber: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  },
  filters: defaultAdditiveTypesFilters,
  count: 0,
  projectsAdditiveTypesCount: 0,
  projectsAdditiveTypesSearch: '',
  additiveTypeAssignment: null,
  additiveTypeAssignmentId: null,
  loadAdditiveTypeAssignmentPending: false,
  addAdditiveTypeToMasterListPending: false,
  fetchAdditiveTypePending: false,
  mostUsedAdditiveTypes: null,
  loadMostUsedAdditiveTypesPending: false,
  leastUsedAdditiveTypes: {
    count: 0,
    items: [],
    additiveTypesLoaded: false,
    pagination: { pageNumber: 1, pageSize: DEFAULT_PAGE_SIZE },
  },
  loadLeastUsedAdditiveTypesPending: false,
  loadMoreLeastUsedAdditiveTypesPending: false,
};

const builder = simplifyBuilder(additiveTypesListState, {});

const loadAdditiveTypes = builder.createServerAction(
  (providerId: string, body: APIAdditiveTypeSearchFilter = {}) => ({
    name: 'loadAdditiveTypes',
    url: loadAdditiveTypesEndpoint(providerId),
    body: (state: State) => ({
      pageSize: state.additiveTypesList.pagination.pageSize,
      pageNumber: state.additiveTypesList.pagination.pageNumber,
      ...state.additiveTypesList.filters,
      ...body,
    }),
    method: httpMethod.post,
    onSuccess: (
      state: AdditiveTypesListState,
      payload: APIMasterAdditiveTypeModelSearchResult,
    ) => {
      return {
        items: payload.result || [],
        additiveTypesLoaded: true,
        pagination: {
          pageNumber: payload.pageNumber,
          pageSize: payload.pageSize,
        },
        count: payload.count,
      };
    },
  }),
);

const loadMoreAdditiveTypes = builder.createServerAction(
  (providerId: string) => ({
    name: 'loadMoreAdditiveTypes',
    url: loadAdditiveTypesEndpoint(providerId),
    body: (state: State) => ({
      pageNumber: getPageNumberAfterLoadMore(
        state.additiveTypesList.items,
        state.additiveTypesList.pagination.pageSize,
      ),
      pageSize: state.additiveTypesList.pagination.pageSize,
      searchSortOrders: state.additiveTypesList.sorting,
      ...state.additiveTypesList.filters,
    }),
    method: httpMethod.post,
    onSuccess: (
      state: AdditiveTypesListState,
      payload: APIMasterAdditiveTypeModelSearchResult,
    ) => {
      return {
        items: [...state.items, ...payload.result],
      };
    },
  }),
);

export const loadProjectsAdditiveTypes = builder.createServerAction(
  (providerId: string, body: APIAdditiveTypeSearchFilter = {}) => ({
    name: 'loadProjectsAdditiveTypes',
    url: loadAdditiveTypesEndpoint(providerId),
    body: (state: State) => ({
      pageSize:
        state.additiveTypesList.projectsAdditiveTypesPagination.pageSize,
      pageNumber:
        state.additiveTypesList.projectsAdditiveTypesPagination.pageNumber,
      isProjectSpecific: true,
      searchSortOrders: state.additiveTypesList.sorting,
      ...state.additiveTypesList.filters,
      ...body,
    }),
    method: httpMethod.post,
    onSuccess: (
      state: AdditiveTypesListState,
      payload: APIMasterAdditiveTypeModelSearchResult,
    ) => {
      return {
        projectsAdditiveTypesItems: payload.result || [],
        projectsAdditiveTypesPagination: {
          pageNumber: payload.pageNumber,
          pageSize: payload.pageSize,
        },
        projectsAdditiveTypesSearch: body.name || '',
        projectsAdditiveTypesCount: payload.count,
        projectsAdditiveTypesLoaded: true,
      };
    },
  }),
);

export const loadMoreProjectsAdditiveTypes = builder.createServerAction(
  (providerId: string, body: APIAdditiveTypeSearchFilter = {}) => ({
    name: 'loadMoreProjectsAdditiveTypes',
    url: loadAdditiveTypesEndpoint(providerId),
    body: (state: State) => ({
      pageNumber: getPageNumberAfterLoadMore(
        state.additiveTypesList.projectsAdditiveTypesItems,
        state.additiveTypesList.projectsAdditiveTypesPagination.pageSize,
      ),
      pageSize:
        state.additiveTypesList.projectsAdditiveTypesPagination.pageSize,
      isProjectSpecific: true,
      searchSortOrders: state.additiveTypesList.sorting,
      ...state.additiveTypesList.filters,
      ...body,
    }),
    method: httpMethod.post,
    onSuccess: (
      state: AdditiveTypesListState,
      payload: APIMasterAdditiveTypeModelSearchResult,
    ) => {
      return {
        projectsAdditiveTypesSearch: body.name || '',
        projectsAdditiveTypesItems: [
          ...state.projectsAdditiveTypesItems,
          ...payload.result,
        ],
      };
    },
  }),
);

const fetchAdditiveType = builder.createServerAction(
  (providerId: string, additiveTypeId: number) => ({
    name: 'fetchAdditiveType',
    url: additiveTypeEndpoint(providerId, additiveTypeId),
    method: httpMethod.get,
  }),
);

export const updateAdditiveType = builder.createServerAction(
  (
    providerId: string,
    additiveTypeId: number,
    body: APIMasterAdditiveType,
  ) => ({
    name: 'updateAdditiveType',
    url: additiveTypeEndpoint(providerId, additiveTypeId),
    body: body,
    method: httpMethod.put,
  }),
);

export const addAdditiveTypeToMasterList = builder.createServerAction(
  (
    providerId: string,
    additiveTypeId: number,
    body: APIMasterAdditiveType,
  ) => ({
    name: 'addAdditiveTypeToMasterList',
    url: additiveTypeEndpoint(providerId, additiveTypeId),
    body: { ...body, projectId: null },
    method: httpMethod.put,
  }),
);

const updateAdditiveTypes = builder.createServerAction(
  (providerId: string) => ({
    name: 'updateAdditiveTypes',
    url: loadAdditiveTypesEndpoint(providerId),
    body: (state: State) => ({
      pageNumber: 1,
      pageSize: getPageSizeDependingOnItems(state.additiveTypesList.items),
      searchSortOrders: state.additiveTypesList.sorting,
      ...state.additiveTypesList.filters,
    }),
    method: httpMethod.post,
    onSuccess: (
      state: AdditiveTypesListState,
      payload: APIMasterAdditiveTypeModelSearchResult,
    ) => {
      return {
        items: payload.result,
        count: payload.count,
      };
    },
  }),
);

const updateAdditiveTypesListSorting = builder.createReduxAction(
  (newSorting: AdditiveTypesListState['sorting']) => ({
    name: 'updateAdditiveTypesListSorting',
    updater: () => ({
      sorting: newSorting,
    }),
  }),
);

const updateAdditiveTypesListFilters = builder.createReduxAction(
  (newFilters: AdditiveTypesListState['filters']) => ({
    name: 'updateAdditiveTypesListFilters',
    updater: state => ({
      filters: {
        ...state.filters,
        ...newFilters,
      },
    }),
  }),
);

const loadAdditiveTypeAssignment = builder.createServerAction(
  (providerId: string, additiveTypeId: number) => ({
    name: 'loadAdditiveTypeAssignment',
    url: loadAdditiveTypeAssignmentEndpoint(providerId, additiveTypeId),
    method: httpMethod.get,
    onSuccess: (
      state: AdditiveTypesListState,
      payload: APIMasterAdditiveTypeModelSearchResult,
    ) => {
      return {
        additiveTypeAssignment: payload || [],
        additiveTypeAssignmentId: additiveTypeId,
      };
    },
  }),
);

const loadMostUsedAdditiveTypes = builder.createServerAction(
  (providerId: string) => ({
    name: 'loadMostUsedAdditiveTypes',
    url: loadMostUsedAdditiveTypesEndpoint(providerId),
    method: httpMethod.get,
    onSuccess: (
      _state: AdditiveTypesListState,
      payload: APIMostUsedMixTypes,
    ) => ({
      mostUsedAdditiveTypes: payload,
    }),
  }),
);

const loadLeastUsedAdditiveTypes = builder.createServerAction(
  (providerId: string) => ({
    name: 'loadLeastUsedAdditiveTypes',
    url: loadLeastUsedAdditiveTypesEndpoint(providerId),
    body: () => ({
      pageSize:
        additiveTypesListState.leastUsedAdditiveTypes.pagination.pageSize,
      pageNumber:
        additiveTypesListState.leastUsedAdditiveTypes.pagination.pageNumber,
    }),
    method: httpMethod.post,
    onSuccess: (
      state: AdditiveTypesListState,
      payload: APIUsageSearchResult,
    ) => {
      return {
        leastUsedAdditiveTypes: {
          ...state.leastUsedAdditiveTypes,
          items: payload.result || [],
          additiveTypesLoaded: true,
          pagination: {
            pageNumber: payload.pageNumber,
            pageSize: payload.pageSize,
          },
          count: payload.count,
        },
      };
    },
  }),
);

const loadMoreLeastUsedAdditiveTypes = builder.createServerAction(
  (providerId: string) => ({
    name: 'loadMoreLeastUsedAdditiveTypes',
    url: loadLeastUsedAdditiveTypesEndpoint(providerId),
    body: (state: State) => ({
      pageNumber: getPageNumberAfterLoadMore(
        state.additiveTypesList.leastUsedAdditiveTypes.items,
        state.additiveTypesList.leastUsedAdditiveTypes.pagination.pageSize,
      ),
      pageSize:
        state.additiveTypesList.leastUsedAdditiveTypes.pagination.pageSize,
    }),
    method: httpMethod.post,
    onSuccess: (
      state: AdditiveTypesListState,
      payload: APIUsageSearchResult,
    ) => {
      return {
        leastUsedAdditiveTypes: {
          ...state.leastUsedAdditiveTypes,
          items: _.uniqBy(
            [...state.leastUsedAdditiveTypes.items, ...payload.result],
            'id',
          ),
          count: payload.count,
        },
      };
    },
  }),
);

const resetAdditiveTypeAssignment = builder.createReduxAction(() => ({
  name: 'resetAdditiveTypeAssignment',
  updater: state => ({
    ...state,
    additiveTypeAssignment: null,
    loadAdditiveTypeAssignmentPending: false,
    additiveTypeAssignmentCount: 0,
    additiveTypeAssignmentId: null,
  }),
}));

const resetAdditiveTypes = builder.createReduxAction(() => ({
  name: 'resetAdditiveTypes',
  updater: () => additiveTypesListState,
}));

const resetProjectAdditiveTypes = builder.createReduxAction(() => ({
  name: 'resetProjectAdditiveTypes',
  updater: state => ({
    ...state,
    projectsAdditiveTypesItems: [],
    projectsAdditiveTypesPagination:
      additiveTypesListState.projectsAdditiveTypesPagination,
    projectsAdditiveTypesCount: 0,
    loadProjectsAdditiveTypesPending: false,
    loadMoreProjectsAdditiveTypesPending: false,
    projectsAdditiveTypesLoaded: false,
  }),
}));

const createAdditiveType = builder.createServerAction(
  (providerId: string, body: APIMasterAdditiveTypeRequest) => ({
    name: 'createAdditiveType',
    url: additiveTypeEndpoint(providerId),
    body,
    method: httpMethod.post,
  }),
);

export const DELETE_ADDITIVE_TYPE_ACTION = 'deleteAdditiveType';
const deleteAdditiveType = builder.createServerAction(
  (providerId: string, additiveTypeId: number) => ({
    name: DELETE_ADDITIVE_TYPE_ACTION,
    url: additiveTypeEndpoint(providerId, additiveTypeId),
    method: httpMethod.delete,
  }),
);

export const useAdditiveTypesList = () => {
  const dispatch = useDispatch();
  const providerId = useSelector(appSelectors.providerId);

  return useMemo(
    () =>
      bindActionCreators(
        {
          loadAdditiveTypes: loadAdditiveTypes.bind(null, providerId),
          loadMoreAdditiveTypes: loadMoreAdditiveTypes.bind(null, providerId),
          loadProjectsAdditiveTypes: loadProjectsAdditiveTypes.bind(
            null,
            providerId,
          ),
          loadMoreProjectsAdditiveTypes: loadMoreProjectsAdditiveTypes.bind(
            null,
            providerId,
          ),
          updateAdditiveTypes: updateAdditiveTypes.bind(null, providerId),
          createAdditiveType: createAdditiveType.bind(null, providerId),
          deleteAdditiveType: deleteAdditiveType.bind(null, providerId),
          fetchAdditiveType: fetchAdditiveType.bind(null, providerId),
          loadMostUsedAdditiveTypes: loadMostUsedAdditiveTypes.bind(
            null,
            providerId,
          ),
          loadLeastUsedAdditiveTypes: loadLeastUsedAdditiveTypes.bind(
            null,
            providerId,
          ),
          loadMoreLeastUsedAdditiveTypes: loadMoreLeastUsedAdditiveTypes.bind(
            null,
            providerId,
          ),
          updateAdditiveType: updateAdditiveType.bind(null, providerId),
          updateAdditiveTypesListSorting:
            updateAdditiveTypesListSorting.bind(null),
          updateAdditiveTypesListFilters:
            updateAdditiveTypesListFilters.bind(null),
          resetAdditiveTypes: resetAdditiveTypes.bind(null),
          resetAdditiveTypeAssignment: resetAdditiveTypeAssignment.bind(null),
          resetProjectAdditiveTypes: resetProjectAdditiveTypes.bind(null),
          loadAdditiveTypeAssignment: loadAdditiveTypeAssignment.bind(
            null,
            providerId,
          ),
          addAdditiveTypeToMasterList: addAdditiveTypeToMasterList.bind(
            null,
            providerId,
          ),
        },
        dispatch,
      ),
    [dispatch, providerId],
  );
};

export const additiveTypesListReducer = builder.getReducers();
