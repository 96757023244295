import { CoreState } from '../modules';

export const requestForgotPasswordPending = (state: CoreState) =>
  state.user.requestForgotPasswordPending;
export const fetchCurrentUserPending = (state: CoreState) =>
  state.user.fetchCurrentUserPending;
export const fetchUserPending = (state: CoreState) =>
  state.user.fetchUserPending;
export const updateUserPasswordPending = (state: CoreState) =>
  state.user.updateUserPasswordPending;
export const createUserPending = (state: CoreState) =>
  state.user.createUserPending;
export const updateUserPending = (state: CoreState) =>
  state.user.updateUserPending;
export const validateTempTokenPending = (state: CoreState) =>
  state.user.validateTempTokenPending;
