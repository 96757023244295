import React, { useState } from 'react';

import styled from 'styled-components';

import { APIAssignedDriver } from '@cd3p/core/types/api';
import {
  AssignedDriversTable,
  Button,
  Popover,
  PopoverCloseButton,
} from 'components';
import i18next from 'i18next';

const AssignedDriversTableWrapper = styled.div`
  width: 74rem;
  max-height: 80vh;
  padding-bottom: 2.6rem;
`;

const StyledAssignmentsButton = styled(Button)`
  font-size: 1.6rem;
`;

interface AssignedDriversPopoverProps {
  items: APIAssignedDriver[];
  buttonTitle?: string;
  buttonVariant?: 'text' | 'outlined' | 'contained';
  buttonColor?: 'primary' | 'secondary';
}

export const AssignedDriversPopover: React.FC<AssignedDriversPopoverProps> = ({
  items,
  buttonTitle = i18next.t('order.details.driverAssignmentsButton'),
  buttonVariant = 'text',
  buttonColor = 'primary',
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const openDriverAssignmentsPopover = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const closeDriverAssignmentsPopover = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const popoverId = open ? 'assignedNotifiedDriversPopover' : undefined;

  return (
    <div>
      <StyledAssignmentsButton
        disableRipple
        variant={buttonVariant}
        color={buttonColor}
        aria-describedby={popoverId}
        onClick={openDriverAssignmentsPopover}
      >
        {buttonTitle}
      </StyledAssignmentsButton>
      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={closeDriverAssignmentsPopover}
        anchorReference="anchorPosition"
        anchorPosition={{ top: 100, left: 500 }}
        PaperProps={{ sx: { overflow: 'visible' } }}
      >
        <PopoverCloseButton onClose={closeDriverAssignmentsPopover} />
        <AssignedDriversTableWrapper>
          <div style={{ overflow: 'hidden', maxHeight: 'inherit' }}>
            <AssignedDriversTable items={items} isReadOnly />
          </div>
        </AssignedDriversTableWrapper>
      </Popover>
    </div>
  );
};
