import React, { useCallback, useEffect } from 'react';

import styled from 'styled-components';

import { APIMixTypeUsage } from '@cd3p/core/types/api';
import {
  CircularProgress,
  Grid,
  Popover,
  PopoverCloseButton,
  Typography,
} from 'components';

import { useTranslation } from 'third-party';

import { contractorsUrl, createFullUrl } from 'constants/url';

const StyledContent = styled(Grid)`
  padding: 20px;
`;

const StyledItem = styled(Grid)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-right: 1rem;
  min-width: 5rem;
`;

const StyledText = styled(Typography)`
  display: inline;
  font-weight: 400;
  font-size: 1.6rem;
  color: ${props => props.theme.custom.palette.gray};
`;

const StyledLink = styled(StyledText)`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const Container = styled.div`
  position: relative;
  width: 50rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-height: 0;
  flex-grow: 1;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  padding: 2.2rem 2.3rem 0;
  z-index: 1;
`;

const Title = styled(Typography)`
  line-height: 100%;
  color: ${props => props.theme.custom.palette.primary900};
`;

const SubTitle = styled(Typography)`
  color: ${props => props.theme.custom.palette.gray};
  margin-bottom: 1.5rem;
  font-weight: 600;
`;

type ContractorAssignmentListProps = {
  title: string;
  assignmentCount: number;
  assignmentData: APIMixTypeUsage[] | null;
  anchorElement: Element;
  onOpen?: () => void;
  onClose?: () => void;
  isLoading?: boolean;
};

export const ContractorAssignmentListPopup: React.FC<
  ContractorAssignmentListProps
> = ({
  title,
  assignmentCount = 0,
  assignmentData,
  anchorElement,
  onOpen,
  onClose,
  isLoading,
}: ContractorAssignmentListProps) => {
  const { t } = useTranslation();

  const handleItemClick = useCallback((id?: string) => {
    if (id) {
      const urlToRedirect = createFullUrl(contractorsUrl(id.toString()));
      window.open(urlToRedirect, '_blank');
    }
  }, []);

  useEffect(() => {
    onOpen?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Popover
      anchorEl={anchorElement}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      open
      PaperProps={{ sx: { overflow: 'visible' } }}
    >
      <PopoverCloseButton onClose={() => onClose?.()} />
      <Container>
        <Header>
          <Title variant="h4">{title}</Title>
          <SubTitle>
            {!isLoading &&
              t('mixTypes.table.assignmentValue', {
                count: assignmentCount,
              })}
          </SubTitle>
        </Header>
        <StyledContent container direction={'row'}>
          {assignmentData?.map(item => (
            <StyledItem xs={4} key={item.id}>
              <StyledLink
                key={item.id}
                onClick={() => handleItemClick(item.id)}
              >
                {item.name}
              </StyledLink>
              &nbsp;
            </StyledItem>
          ))}
          {!assignmentCount && !isLoading && (
            <StyledText>{t('typeahead.notFound')}</StyledText>
          )}
          {!assignmentCount && isLoading && <CircularProgress size={'2rem'} />}
        </StyledContent>
      </Container>
    </Popover>
  );
};
