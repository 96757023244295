import {
  orderCallbackEndpoint,
  orderEndpoint,
  orderPlantsEndpoint,
  orderStatusEndpoint,
  orderSubscriptionEndpoint,
  orderSubscriptions,
  userSubscriptionEndpoint,
} from '../constants/endpoints';
import {
  APIOrder,
  APIOrderCallbackSubmitRequest,
  APIOrderCreateParams,
  APIOrderRequest,
  APIOrderStatus,
  APIOrderSubscription,
  APIPlant,
  APIProject,
} from '../types/api';
import { httpMethod } from '../utils/sra';

export type CoreOrderState = {
  orderId: APIOrder['id'] | null;
  changeOrderStatusPending: boolean;
  updateOrderCallbackPending: boolean;
  orderSubscription: APIOrderSubscription[];
  orderLoaded: boolean;
  loadOrderPending: boolean;
  orderPlants: APIPlant[];
  orderPlantsLoaded: boolean;
  addOrderSubscriptionsPending: boolean;
};

export const orderCoreState: CoreOrderState = {
  orderId: null,
  changeOrderStatusPending: false,
  updateOrderCallbackPending: false,
  orderSubscription: [],
  orderLoaded: false,
  loadOrderPending: false,
  orderPlants: [],
  orderPlantsLoaded: false,
  addOrderSubscriptionsPending: false,
};

export const LOAD_ORDER_ACTION = 'loadOrder';
const loadOrder = (providerId: string, id: number) => ({
  name: LOAD_ORDER_ACTION,
  url: orderEndpoint(providerId, id),
  method: httpMethod.get,
  onSuccess: (_state: CoreOrderState, payload: APIOrder) => ({
    orderId: payload.id,
    orderLoaded: true,
  }),
});

export const UPDATE_ORDER_ACTION = 'updateOrder';
const updateOrder = (
  providerId: string,
  id: number,
  body: { projectId?: APIProject['id'] } & Partial<APIOrderRequest>,
) => ({
  name: UPDATE_ORDER_ACTION,
  url: orderEndpoint(providerId, id),
  method: httpMethod.put,
  body,
});

export const UPDATE_ORDER_CALLBACK_ACTION = 'updateOrderCallback';
const updateOrderCallback = (
  providerId: string,
  id: number,
  body: APIOrderCallbackSubmitRequest,
) => ({
  name: 'updateOrderCallback',
  url: orderCallbackEndpoint(providerId, id),
  method: httpMethod.put,
  body,
});

const createOrder = (providerId: string, body: APIOrderCreateParams) => ({
  name: 'createOrder',
  url: orderEndpoint(providerId),
  body,
  method: httpMethod.post,
});

export const CHANGE_ORDER_STATUS_ACTION = 'changeOrderStatus';
const changeOrderStatus = (
  providerId: string,
  id: string | number,
  status: APIOrderStatus,
) => ({
  name: CHANGE_ORDER_STATUS_ACTION,
  url: orderStatusEndpoint(providerId, id),
  body: status,
  method: httpMethod.put,
});

const loadOrderPlants = (providerId: string, id: number) => {
  return {
    name: 'loadOrderPlants',
    url: orderPlantsEndpoint(providerId, id),
    method: httpMethod.get,
    onSuccess: (_state: CoreOrderState, payload: APIPlant[]) => {
      return {
        orderPlants: payload,
        orderPlantsLoaded: true,
      };
    },
    onFailure: () => ({ orderPlantsLoaded: true }),
  };
};

export const TOGGLE_ORDER_SUBSCRIPTION_ACTION = 'toggleOrderSubscription';
const toggleOrderSubscription = (
  providerId: string,
  orderId: string | number,
  shouldSubscribe: boolean,
) => ({
  name: TOGGLE_ORDER_SUBSCRIPTION_ACTION,
  url: orderSubscriptionEndpoint(providerId, orderId),
  method: shouldSubscribe ? httpMethod.post : httpMethod.delete,
});

const getUserSubscriptions = (
  providerId: string,
  lastSubscription: string | null,
) => ({
  name: 'getUserSubscriptions',
  url: userSubscriptionEndpoint(providerId),
  body: { updateOn: lastSubscription, onlyActive: true },
  onSuccess: (_state: CoreOrderState, payload: APIOrderSubscription[]) => ({
    orderSubscription: payload,
  }),
  method: httpMethod.post,
});

const addOrderSubscriptions = (
  providerId: string,
  orderId: number,
  subscribedUsersIds: string[],
) => ({
  name: 'addOrderSubscriptions',
  url: orderSubscriptions(providerId, orderId),
  body: {
    subscribedUsersIds,
  },
  method: httpMethod.put,
});

export const coreOrderMethods = {
  loadOrder,
  updateOrder,
  updateOrderCallback,
  createOrder,
  changeOrderStatus,
  loadOrderPlants,
  toggleOrderSubscription,
  getUserSubscriptions,
  addOrderSubscriptions,
};
