import React, { useEffect, useMemo } from 'react';

import styled from 'styled-components';

import { Stack, Typography } from 'components';
import { Outlet } from 'react-router-dom';

import { CustomerAccountDetails } from './CustomerAccountDetails';
import { CustomerAccountsList } from './CustomerAccountsList';

import {
  Navigate,
  useMatch,
  useParams,
  useSelector,
  useTranslation,
} from 'third-party';

import { contractorsUrl } from 'constants/url';

import { useAdminCustomerAccountsList } from 'modules/adminCustomerAccountsList';

import { adminCustomerAccountsList } from 'selectors';

const Wrapper = styled(Stack)`
  flex-grow: 1;
  max-height: 100%;
`;

const Header = styled(Typography)`
  color: ${props => props.theme.custom.palette.secondary500};
  margin: 1rem 0 1rem 2rem;
  flex-shrink: 0;
  display: inline;
`;

const ContractorsListPanel = styled(Stack)`
  width: 42rem;
  border-right: 2px solid ${props => props.theme.custom.palette.muted100};
  flex-shrink: 0;
`;

const Separator = styled.div`
  position: relative;
  height: 0.1rem;
  background: ${props => props.theme.custom.palette.muted100};
  z-index: 2;
  flex-shrink: 0;
`;

const EmptyStateWrapper = styled(Stack)`
  gap: 1rem;
  max-width: 39rem;
  padding: 2.5rem 1.7rem;
  align-items: flex-start;
`;

const EmptyStateSubTitle = styled(Typography)`
  font-weight: 600;
  margin-bottom: 1.8rem;
`;

const ContractorDetailsPanel = styled(Stack)`
  padding: 0.5rem;
  flex-grow: 1;
`;

export const AdminCustomerAccountViewWrapper = () => {
  const { resetCustomerAccountsList } = useAdminCustomerAccountsList();

  useEffect(() => {
    return () => {
      resetCustomerAccountsList();
    };
  }, [resetCustomerAccountsList]);
  return <Outlet />;
};

export const AdminCustomerAccountsView = () => {
  const { t } = useTranslation();
  const { contractorId } = useParams();
  const matchIndexRoute = useMatch(contractorsUrl());
  const firstAccountInList = useSelector(
    adminCustomerAccountsList.firstAccountInList,
  );

  const emptyState = useMemo(() => {
    return (
      <EmptyStateWrapper>
        <Typography variant="h5">
          {t('customers.table.emptyStateTitleProviders')}
        </Typography>
        <EmptyStateSubTitle variant="h6">
          {t('customers.table.emptyStateSubTitle')}
        </EmptyStateSubTitle>
      </EmptyStateWrapper>
    );
  }, [t]);

  return (
    <Wrapper direction="row">
      <ContractorsListPanel>
        <Stack
          direction="row"
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Header variant="h5">{t('customers.support.headerProviders')}</Header>
        </Stack>
        <Separator />
        <CustomerAccountsList
          emptyState={emptyState}
          selectedAccountId={contractorId}
        />
      </ContractorsListPanel>
      <ContractorDetailsPanel>
        {contractorId && <CustomerAccountDetails />}
      </ContractorDetailsPanel>
      {matchIndexRoute && firstAccountInList && (
        <Navigate
          replace
          to={contractorsUrl(firstAccountInList.id.toString())}
        />
      )}
    </Wrapper>
  );
};
