import React, { useCallback, useMemo, useState } from 'react';

import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined';

import { APIProject, APIProjectStatus } from '@cd3p/core/types/api';
import { ConfirmationDialog, IconButton, Tooltip } from 'components';

import { useHandleApiResult } from './useHandleApiResult';

import { useTranslation } from 'third-party';

import { useProject } from 'modules/project';

/*
  This hooks allows to reuse project archivation functionality on
  ProjectsTab view and ProjectView to reduce code duplication.
*/
export const useProjectArchivation = () => {
  const { t } = useTranslation();
  const { updateProject } = useProject();
  const handleApiResult = useHandleApiResult();

  const [selectedProject, setSelectedProject] = useState<null | APIProject>(
    null,
  );
  const [showArchiveConfirmationDialog, setShowArchiveConfirmationDialog] =
    useState(false);

  const onArchiveProject = useCallback(async () => {
    if (!selectedProject) return;

    const item = selectedProject;
    const shouldArchiveProject =
      item.projectStatus !== APIProjectStatus.Archived;
    const updatedProjectStatus = shouldArchiveProject
      ? APIProjectStatus.Archived
      : APIProjectStatus.Active;

    await handleApiResult<APIProject>(
      () => {
        return updateProject(item.id, item.company.id as string, {
          projectStatus: updatedProjectStatus,
          name: item.name,
          address: item.address,
          deliveryAddress: item.deliveryAddress,
          description: item.description,
          longitude: item.longitude,
          latitude: item.latitude,
        });
      },
      ({ showBaseToast }) => {
        const successMessage = shouldArchiveProject
          ? t('project.archivation.archiveSuccess', {
              projectName: item.name,
            })
          : t('project.archivation.unarchiveSuccess', {
              projectName: item.name,
            });

        showBaseToast(successMessage);
      },
    ).then(() => {
      setSelectedProject(null);
      setShowArchiveConfirmationDialog(false);
    });
  }, [handleApiResult, selectedProject, t, updateProject]);

  const confirmationDialogProps = useMemo(
    () =>
      selectedProject?.projectStatus === APIProjectStatus.Active
        ? {
            actionButtonTitle: t(
              'project.archivation.archiveConfirmationButton',
            ),
            description: t(
              'project.archivation.archiveConfirmationDescription',
            ),
          }
        : {
            actionButtonTitle: t(
              'project.archivation.unarchiveConfirmationButton',
            ),
            description: t(
              'project.archivation.unarchiveConfirmationDescription',
            ),
          },
    [selectedProject?.projectStatus, t],
  );

  return useMemo(
    () => ({
      ArchiveProjectConfirmationDialog: ({
        onSuccess,
      }: {
        onSuccess?: () => void;
      }) => (
        <ConfirmationDialog
          onClose={() => {
            setSelectedProject(null);
            setShowArchiveConfirmationDialog(false);
          }}
          open={showArchiveConfirmationDialog}
          handleActionClick={() => {
            onArchiveProject().then(() => {
              onSuccess?.();
            });
          }}
          cancelButtonTitle={t('common.noNevermind')}
          {...confirmationDialogProps}
        />
      ),
      ArchiveProjectButton: ({ project }: { project: APIProject }) => (
        <IconButton
          onClick={e => {
            e.stopPropagation();
            setSelectedProject(project);

            setShowArchiveConfirmationDialog(true);
          }}
        >
          {project.projectStatus === APIProjectStatus.Archived ? (
            <Tooltip title={t('project.archivation.unarchiveTooltip')}>
              <UnarchiveOutlinedIcon color="primary" />
            </Tooltip>
          ) : (
            <Tooltip title={t('project.archivation.archiveTooltip')}>
              <ArchiveOutlinedIcon color="primary" />
            </Tooltip>
          )}
        </IconButton>
      ),
    }),
    [
      confirmationDialogProps,
      onArchiveProject,
      showArchiveConfirmationDialog,
      t,
    ],
  );
};
