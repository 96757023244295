import React, { useState } from 'react';

import styled from 'styled-components';

import { Core } from '@cd3p/core/config';
import { APIUser, APIUserType } from '@cd3p/core/types/api';
import { emailField } from '@cd3p/core/utils/fields';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  FormField,
  IconButton,
  InputAdornment,
  LoadingButton,
  SplitView,
  Typography,
} from 'components';
import { useHandleApiResult } from 'hooks/useHandleApiResult';

import {
  Navigate,
  RouterLink,
  useForm,
  useNavigate,
  useSelector,
  useTranslation,
} from 'third-party';

import { allOrdersUrl, forgotPasswordUrl, loginUrl } from 'constants/url';

import { useApp } from 'modules/app';
import { useStorage } from 'modules/storage';

import { appSelectors } from 'selectors';

import { getUserRedirectLink } from 'utils/auth';

const Header = styled(Typography)`
  margin-bottom: 1.5rem;
  color: ${props => props.theme.custom.palette.secondary900};
  align-self: center;
`;

const LoginWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Form = styled.form`
  width: 42.8rem;
  display: flex;
  flex-direction: column;
  padding-bottom: 7.5rem;
`;

const ForgotPasswordLink = styled(RouterLink)`
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 600;
  margin: 1.2rem 0 5.4rem;
  align-self: flex-start;
  color: ${props => props.theme.custom.palette.primary900};
  &:hover {
    text-decoration: underline;
  }
`;

enum FormFields {
  Email = 'email',
  Password = 'password',
}

const loginFormDefaultValues = {
  [FormFields.Email]: '',
  [FormFields.Password]: '',
};

type LoginFormData = typeof loginFormDefaultValues;

export const LogInView: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { initiateUserSettings } = useStorage();
  const user = useSelector(appSelectors.user);
  const { userLogIn, getUserInfo, loadProviderSettings } = useApp();
  const [showPassword, setShowPassword] = useState(false);
  const [isFormPending, setIsFormPending] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitted, isValid },
  } = useForm<LoginFormData>({
    defaultValues: loginFormDefaultValues,
  });

  const handleApiResult = useHandleApiResult();

  const onSubmit = async ({ email, password }: LoginFormData) => {
    if (email && password) {
      setIsFormPending(true);
      handleApiResult<APIUser>(
        () => userLogIn(email, password),
        async ({ showSuccessToast }) => {
          const userInfoResult = await getUserInfo();
          initiateUserSettings(userInfoResult.payload);
          if (userInfoResult.error) {
            return;
          }
          const userType = userInfoResult.payload.userType;
          const isUserLogIn =
            !Core.isAdminPortal() && userType != APIUserType.Driver;

          const isAdminLogin =
            Core.isAdminPortal() && userType == APIUserType.Support;

          // alow to login either admin from admin portal or dispatcher from dispatcher portal
          if (isAdminLogin || isUserLogIn) {
            const providerId = userInfoResult.payload.providers?.[0].id;
            if (providerId) {
              loadProviderSettings(providerId);
            }
            navigate(
              getUserRedirectLink(userInfoResult.payload.userStatus) ||
                allOrdersUrl(),
            );
          } else {
            showSuccessToast(t('login.alert.incorrectCredentials'), {
              id: 'incorrectCredentials',
            });
            setIsFormPending(false);
          }
        },
        ({ showErrorToast, result }) => {
          setIsFormPending(false);
          if (result && result.payload.status === 401) {
            showErrorToast(t('login.alert.incorrectCredentials'), {
              id: 'incorrectCredentials',
            });
          } else {
            showErrorToast();
          }
        },
      );
    }
  };

  const isSubmitDisabled = (isSubmitted && !isValid) || isFormPending;

  return user?.id ? (
    <Navigate to={allOrdersUrl()} replace />
  ) : (
    <SplitView>
      <LoginWrapper>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Header variant="h2">{t('login.signInHeader')}</Header>
          <FormField
            fieldName={FormFields.Email}
            fieldError={errors[FormFields.Email]}
            label={t('common.form.email.label')}
            placeholder={t('common.form.email.placeholder')}
            requiredErrorMessage={t('common.form.emptyFieldError')}
            control={control}
            maxLength={emailField.maxLength}
            isRequired
            showIsRequiredMark={false}
            isDisabled={isFormPending}
            rules={{
              validate: {
                invalidEmail: (value: string) =>
                  emailField.validate(value) ||
                  t('common.form.email.error.invalid'),
              },
            }}
          />
          <FormField
            type={showPassword ? 'text' : 'password'}
            fieldName={FormFields.Password}
            fieldError={errors[FormFields.Password]}
            label={t('common.form.password.label')}
            placeholder={t('common.form.password.placeholder')}
            requiredErrorMessage={t('common.form.emptyFieldError')}
            control={control}
            maxLength={50}
            isRequired
            showIsRequiredMark={false}
            isDisabled={isFormPending}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    sx={{ padding: 0 }}
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <ForgotPasswordLink
            to={isFormPending ? loginUrl() : forgotPasswordUrl()}
          >
            {t('login.forgotPasswordLink')}
          </ForgotPasswordLink>
          <LoadingButton
            type="submit"
            variant="contained"
            size="medium"
            color="primary"
            disabled={isSubmitDisabled}
            loading={isFormPending}
          >
            {t('login.signInButtonText')}
          </LoadingButton>
        </Form>
      </LoginWrapper>
    </SplitView>
  );
};
