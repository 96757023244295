import React, { useCallback, useContext, useEffect } from 'react';

import styled from 'styled-components';

import MessageIcon from '@mui/icons-material/ChatBubble';

import {
  ON_DIALOG_UNREAD_COUNT_CHANGED,
  ON_DIALOG_UPDATE,
  ON_USER_SUBSCRIBED_TO_ORDER,
  ON_USER_UNSUBSCRIBED_FROM_ORDER,
} from '@cd3p/core/constants/hubs';
import {
  APIDialogModel,
  APIOrderSubscription,
  APIUserType,
} from '@cd3p/core/types/api';
import { AnimatedMenuIcon, IconButton, Tooltip } from 'components';
import { CacheContext } from 'context/CacheContext';
import { motion } from 'framer-motion';
import { useChangeOrderSubscription } from 'hooks/useChangeOrderSubscription';
import { useDialogRead } from 'hooks/useDialogRead';

import { useSelector, useTranslation } from 'third-party';

import { TOOLTIP_APPEAR_DELAY } from 'constants/common';

import { AppLeftPanel } from 'modules/app';
import { useChat } from 'modules/chat';
import { useDialogsList } from 'modules/dialogsList';
import { useOrdersCache } from 'modules/ordersCache';
import { useStorage } from 'modules/storage';

import { appSelectors, dialogsListSelectors } from 'selectors';

import { updateDialog } from 'utils/globalCache';
import { useIsSubscribedOrder } from 'utils/order';
import { useOrdersSockets } from 'utils/sockets';

const StyledButton = styled(IconButton)<{ active?: string }>`
  background-color: ${props =>
    props.active === 'true'
      ? props.theme.custom.palette.primary50
      : 'transparent'};
  width: 4rem;
  height: 4rem;
`;

const StyledMessageIcon = styled(MessageIcon)`
  width: 2rem;
  color: ${props => props.theme.custom.palette.secondary900};
`;

export const DialogsButton = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation();

  const { updateUserSettingInStorage } = useStorage();
  const { updateOrderInCache } = useOrdersCache();

  const { removeUnreadOrderId, addUnreadOrderId } = useChat();

  const {
    loadDialogs,
    updateDialogInList,
    loadUnreadDialogsCount,
    updateUnreadDialogsCount,
  } = useDialogsList();

  const userType = useSelector(appSelectors.userType);
  const isDialogRead = useDialogRead();

  const companyId = useSelector(appSelectors.companyId);
  const isDialogsPanelOpen = useSelector(appSelectors.isDialogsPanelOpen);
  const hasUnreadMessages = useSelector(dialogsListSelectors.hasUnreadMessages);
  const isSubscribedOrder = useIsSubscribedOrder();
  const { syncData } = useContext(CacheContext);

  const changeOrderSubscription = useChangeOrderSubscription();

  useEffect(() => {
    loadUnreadDialogsCount();
  }, [loadUnreadDialogsCount]);

  const onDialogsPanelButtonClick = useCallback(() => {
    onClick();
    updateUserSettingInStorage({
      openedLeftPanel: isDialogsPanelOpen ? null : AppLeftPanel.Dialogs,
    });
  }, [onClick, updateUserSettingInStorage, isDialogsPanelOpen]);

  useOrdersSockets(
    () => [
      {
        method: ON_DIALOG_UNREAD_COUNT_CHANGED,
        action: (providerId: string, unreadCount: number) => {
          // update unread dialogs count only for dispatcher
          if (userType === APIUserType.Dispatcher) {
            updateUnreadDialogsCount(unreadCount);
          }
        },
      },
      {
        method: ON_DIALOG_UPDATE,
        action: (
          providerId: string,
          orderId: number,
          userId: number,
          dialog: APIDialogModel,
        ) => {
          updateDialog(providerId, dialog);
          updateDialogInList(dialog);
          if (isSubscribedOrder(orderId)) {
            syncData();
          }
          updateOrderInCache(dialog.orderId, { dialog });
          if (isDialogRead(dialog)) {
            removeUnreadOrderId(dialog.orderId);
          } else {
            addUnreadOrderId(dialog.orderId);
          }
        },
      },
      {
        method: ON_USER_SUBSCRIBED_TO_ORDER,
        action: (providerId: string, subscription: APIOrderSubscription) => {
          changeOrderSubscription(subscription, true);
        },
      },
      {
        method: ON_USER_UNSUBSCRIBED_FROM_ORDER,
        action: (providerId: string, subscription: APIOrderSubscription) => {
          changeOrderSubscription(subscription, false);
        },
      },
    ],
    [
      changeOrderSubscription,
      addUnreadOrderId,
      isDialogRead,
      removeUnreadOrderId,
      updateDialogInList,
      updateOrderInCache,
      updateUnreadDialogsCount,
      userType,
      isSubscribedOrder,
      syncData,
    ],
  );

  useEffect(() => {
    loadDialogs(companyId);
    // no need to react to `loadDialogs` change as it causes double call
    // of useEffect when provider changes. when `providerId` is different
    // the `companyId` is also different which already invokes this useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  return (
    <Tooltip
      enterDelay={TOOLTIP_APPEAR_DELAY}
      title={t('dialogsPanel.triggerButton.tooltip')}
    >
      <StyledButton
        active={String(isDialogsPanelOpen)}
        onClick={onDialogsPanelButtonClick}
        // @ts-ignore
        component={motion.button}
        whileHover="enlarge"
      >
        <AnimatedMenuIcon
          unreadDotRightPosition={-2}
          unread={hasUnreadMessages}
          iconComponent={<StyledMessageIcon />}
        />
      </StyledButton>
    </Tooltip>
  );
};
