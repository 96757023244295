import React from 'react';

import styled from 'styled-components';

import { Box, Typography } from 'components';

const StyledHeader = styled(Typography)`
  font-weight: 900;
  line-height: 125%;
  text-transform: uppercase;
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

const StyledHeaderH1 = styled(StyledHeader)`
  color: ${props => props.theme.custom.palette.secondary700};
  font-size: 2rem;
`;

const StyledHeaderH2 = styled(StyledHeader)`
  color: ${props => props.theme.custom.palette.secondary500};
  font-size: 1.6rem;
`;

const StyledHeaderH3 = styled(StyledHeader)`
  color: ${props => props.theme.custom.palette.primary900};
  font-size: 1.3rem;
`;

type MdxProps = {
  children: React.ReactNode;
};

const h1: React.FC<MdxProps> = ({ children }) => (
  <StyledHeaderH1>{children}</StyledHeaderH1>
);

const h2: React.FC<MdxProps> = ({ children }) => (
  <StyledHeaderH2>{children}</StyledHeaderH2>
);

const h3: React.FC<MdxProps> = ({ children }) => (
  <StyledHeaderH3>{children}</StyledHeaderH3>
);

const StyledParagraph = styled(Typography)`
  padding: 1.3rem 0;
  color: ${props => props.theme.custom.palette.darkText};
  font-size: 1.4rem;
  line-height: 2.3rem;
  font-weight: 400;
  letter-spacing: -0.005em;
`;

const p: React.FC<MdxProps> = ({ children }) => (
  <StyledParagraph variant="body1">{children}</StyledParagraph>
);

const StyledUl = styled.ul`
  list-style: disc;
  padding-left: 2rem;
  font-size: 1.5rem;
  line-height: 2.3rem;
  font-weight: normal;
  letter-spacing: -0.005em;
  margin-bottom: 2rem;
`;

const ul: React.FC<MdxProps> = ({ children }) => (
  <StyledUl>{children}</StyledUl>
);

const img: React.FC<any> = props => (
  <Box
    component="img"
    margin="1rem 2rem"
    sx={{
      maxHeight: 500,
      maxWidth: 550,
    }}
    {...props}
  />
);

export const components = {
  h1: h1,
  h2: h2,
  h3: h3,
  p: p,
  ul: ul,
  img: img,
};
