import React, { useCallback, useEffect, useState } from 'react';

import styled from 'styled-components';

import { NotificationSeverity } from '@cd3p/core/modules/notifications';
import { APIUser, APIUserStatus } from '@cd3p/core/types/api';
import { linearProgressClasses } from '@mui/material/LinearProgress';
import {
  GlobalLoader,
  LinearProgress,
  SplitView,
  Typography,
} from 'components';
import { useHandleApiResult } from 'hooks/useHandleApiResult';

import {
  Navigate,
  RouterOutlet,
  queryString,
  useNavigate,
  useSelector,
  useTranslation,
} from 'third-party';

import { allOrdersUrl, loginUrl } from 'constants/url';

import { useApp } from 'modules/app';
import { useNotifications } from 'modules/notifications';

import { appSelectors } from 'selectors';

import { getUserRedirectLink, getUserToken, useLogOutUser } from 'utils/auth';
import { isIncompleteOnboardedUserLoggedIn } from 'utils/user';

export const Title = styled(Typography)`
  color: ${props => props.theme.custom.palette.secondary900};
  white-space: pre-line;
  text-align: center;
  margin-bottom: 3.8rem;
  flex-shrink: 0;
`;

export const Description = styled(Typography)`
  font-size: 1.6rem;
  font-weight: 700;
  padding-top: 0.8rem;
  color: ${props => props.theme.custom.palette.primary900};
  text-align: center;
`;

export const BorderLinearProgress = styled(LinearProgress)`
  height: 2.4rem;
  border-radius: 99.99rem;
  width: 100%;
  margin-top: 0.8rem;
  margin-bottom: 1.6rem;
  &.${linearProgressClasses.colorPrimary} {
    background-color: ${props => props.theme.custom.palette.muted100};
  }
  & .${linearProgressClasses.bar} {
    border-top-left-radius: 99.99rem;
    border-bottom-left-radius: 99.99rem;
    background-color: ${props => props.theme.custom.palette.secondary700};
  }
`;

export const OnBoardingView = () => {
  const { t } = useTranslation();
  const { addNotification } = useNotifications();
  const navigate = useNavigate();
  const { validateTempToken } = useApp();
  const { getUserInfo } = useApp();

  const queryParams = queryString.parse(location.search);

  const userHasUrlToken = !!queryParams?.token;

  const validateTempTokenPending = useSelector(
    appSelectors.validateTempTokenPending,
  );
  const user = useSelector(appSelectors.user);
  const userInfoError = useSelector(appSelectors.userInfoError);
  const userInfoPending = useSelector(appSelectors.userInfoPending);

  const [authToken, setAuthToken] = useState<null | string>(null);

  const logOutUser = useLogOutUser();
  const handleApiResult = useHandleApiResult();

  const logOutDeactivatedUser = useCallback(() => {
    addNotification({
      severity: NotificationSeverity.Error,
      message: t('common.loginInactiveError'),
    });
    logOutUser();
  }, [addNotification, t, logOutUser]);

  const redirectUserToCorrectPage = useCallback(
    (userStatus: APIUserStatus) => {
      // redirect to the correct onboarding step/page depending on user status
      const userRedirectLink = getUserRedirectLink(userStatus as APIUserStatus);
      if (userRedirectLink) {
        navigate(userRedirectLink, { replace: true });
        if (userStatus === APIUserStatus.Deactivated) {
          logOutDeactivatedUser();
        }
      }
    },
    [navigate, logOutDeactivatedUser],
  );

  useEffect(() => {
    const validate = async () => {
      const isNotLoggedInUserOnOnboardingPage =
        !user.id && !userInfoPending && !userInfoError;

      if (userHasUrlToken) {
        handleApiResult<string>(
          () => validateTempToken(queryParams.token as string),
          async ({ result }) => {
            setAuthToken(result.payload);
            if (isNotLoggedInUserOnOnboardingPage) {
              // store bearer token received from temp token
              // get user info using new bearer token and redirect to a correct page depending on user status
              const userInfo = await getUserInfo();
              if (!userInfo.error) {
                redirectUserToCorrectPage(userInfo.payload.userStatus);
              }
            } else if (user) {
              redirectUserToCorrectPage(user.userStatus as APIUserStatus);
            }
          },
          () => {
            addNotification({
              severity: NotificationSeverity.Warning,
              message: t('onboarding.expiredTokenToastMessage'),
            });
            navigate(
              isNotLoggedInUserOnOnboardingPage ? loginUrl() : allOrdersUrl(),
              { replace: true },
            );
          },
        );
      } else if (isNotLoggedInUserOnOnboardingPage) {
        // users with incorrect tokens will be redirected to expired url (in the previous if else condition) and should stay there
        // user without token/being logged in comes to the onboarding page - redirect to login page
        const userInfo = await getUserInfo();
        if (userInfo.error) {
          navigate(loginUrl(), { replace: true });
        }
      } else if (
        user.userStatus &&
        isIncompleteOnboardedUserLoggedIn(user as APIUser) &&
        !userInfoPending &&
        !userInfoError
      ) {
        // logged in incomplete user comes to onboarding page without token
        // redirect to the correct onboarding step/page depending on user status
        redirectUserToCorrectPage(user.userStatus);
      }
    };

    validate();
  }, [
    getUserInfo,
    user,
    userInfoPending,
    userInfoError,
    navigate,
    queryParams.token,
    validateTempToken,
    redirectUserToCorrectPage,
    logOutDeactivatedUser,
    userHasUrlToken,
    handleApiResult,
    addNotification,
    t,
  ]);

  const isLoading =
    validateTempTokenPending ||
    userInfoPending ||
    (userHasUrlToken && !authToken);

  if (!userHasUrlToken && !getUserToken()) {
    return <Navigate to={loginUrl()} replace />;
  }

  return isLoading ? (
    <GlobalLoader />
  ) : (
    <SplitView>
      <RouterOutlet />
    </SplitView>
  );
};
