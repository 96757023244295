import axios from 'axios';
import queryString from 'query-string';
import { AnyAction, Dispatch, MiddlewareAPI } from 'redux';

import { USER_IS_NOT_AUTHORIZED_ACTION } from '../../constants/redux';
import { BuilderOptions } from './types';

const getActionBody = (
  actionBody: (state: Record<any, any>) => void | Record<any, any>,
  getState: () => Record<any, any>,
): any => {
  return typeof actionBody === 'function' ? actionBody(getState()) : actionBody;
};

const parseJson = async (response: Response) => {
  const text = await response.text();
  try {
    return JSON.parse(text);
  } catch (err) {
    return text;
  }
};

export function middlewareBuilder(options?: BuilderOptions) {
  const simplifyReactMiddleware =
    ({ dispatch, getState }: MiddlewareAPI<any>) =>
    (next: Dispatch<AnyAction>) =>
    async (action: AnyAction) => {
      if (!action.name) {
        return next(action);
      }

      if (!action.url) {
        const simpleAction = {
          type: action.name,
          payload: action.payload,
          updater: action.updater,
        };

        dispatch(simpleAction);

        return simpleAction;
      }

      const [successType, requestType, failureType, cancelType] =
        action.types.map((type: any) =>
          typeof type === 'string' ? type : type.toString(),
        );

      dispatch({
        type: requestType,
        onRequest: action.onRequest,
      });

      let serverAction = {};
      try {
        const url = queryString.stringifyUrl({
          url: action.url,
          query: action.params ?? {},
        });

        let body = action.body;
        if (!(body instanceof FormData)) {
          body =
            action.body && action.method !== 'GET'
              ? JSON.stringify(getActionBody(action.body, getState))
              : null;
        }

        const response = options?.httpRequestHandler
          ? await options.httpRequestHandler(url, {
              method: action.method || 'GET',
              body,
              headers: action.headers,
            })
          : await fetch(url, {
              method: action.method || 'GET',
              body,
            });

        if (response.status === 401) {
          dispatch({
            type: USER_IS_NOT_AUTHORIZED_ACTION,
          });
        }

        if (response.status !== 200) {
          throw { response };
        }

        const data = action.responseReader
          ? await action.responseReader(response)
          : await parseJson(response);

        const shouldDispatch = options?.responseHandler
          ? await options.responseHandler(response, dispatch)
          : true;

        if (!shouldDispatch) {
          return null;
        }

        serverAction = {
          type: successType,
          payload: data,
          onSuccess: action.onSuccess,
        };
      } catch (error: any) {
        console.error(error);
        let body;
        try {
          body = await error?.response?.json();
        } catch (e) {
          // handle error
        }
        serverAction = {
          ...(axios.isCancel(error)
            ? {
                type: cancelType,
                onCancel: action.onCancel,
              }
            : {
                type: failureType,
                onFailure: action.onFailure,
              }),
          payload: {
            body,
            bodyUsed: error?.response?.bodyUsed,
            headers: error?.response?.headers,
            ok: error?.response?.ok,
            redirected: error?.response?.redirected,
            status: error?.response?.status,
            type: error?.response?.type,
            url: error?.response?.url,
          },
          error: true,
        };
      }
      dispatch(serverAction);

      return serverAction;
    };

  return simplifyReactMiddleware;
}
