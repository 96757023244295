import React, { useEffect, useState } from 'react';

import styled, { useTheme } from 'styled-components';

import {
  APIChangesStatus,
  APIDeliveryRateType,
  APIEntityHistory,
  APIOrder,
  APIOrderStatus,
  APIOrderType,
} from '@cd3p/core/types/api';
import { convert24toAmPm, formatDisplayedDate } from '@cd3p/core/utils/common';
import { multilineTextField, orderTypeField } from '@cd3p/core/utils/fields';
import { OrderFields, getStringOrObject } from '@cd3p/core/utils/order';
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormField,
  Grid,
  LoadingButton,
  OrderStatusNotice,
  Trans,
  Typography,
} from 'components';
import i18n from 'i18n';

import { _, useForm, useSelector, useTranslation } from 'third-party';

import { NotificationSeverity, useNotifications } from 'modules/notifications';
import { useOrder } from 'modules/order';

import { orderSelectors } from 'selectors';

const ChangesRequestedOrderWrapper = styled(Grid)`
  overflow-y: auto;
  max-width: 100%;
  flex-basis: auto;
`;

const CancelButton = styled(LoadingButton)`
  width: 15rem;
  margin-right: 1rem;
`;
const ApproveButton = styled(LoadingButton)`
  min-width: 20rem;
`;

const FormControlLabelWrapper = styled(FormControlLabel)`
  text-transform: uppercase;
  color: ${props => props.theme.custom.palette.darkText};
  margin-bottom: 1rem;

  > * {
    font-weight: 700;
    font-size: 1.6rem;
  }
`;

const FormControlLabelTitle = styled(Typography)`
  font-weight: 700;
  line-height: 1;
`;

const FormControlLabelValue = styled(Typography)`
  text-transform: none;
  color: ${props => props.theme.custom.palette.gray};
`;

const RequestedChangesWrapper = styled(Box)`
  padding: 2rem;
  margin-top: 2rem;
  background-color: #faf5ff;
  display: flex;
  flex-direction: column;
`;

const RequestedChangesHeader = styled(Typography)`
  color: ${props => props.theme.custom.palette.secondary500};
  margin-bottom: 0.8rem;
`;

const StyledFormField = styled(FormField)`
  width: 80%;
`;

const StyledOrderStatusNotice = styled(OrderStatusNotice)`
  margin-top: 1.6rem;
`;

const computeOrderStatus = (
  currentOrderStatus?: APIOrderStatus,
  confirmUponApproval = false,
  shouldNotConfirmOrder = false,
) => {
  if (currentOrderStatus === APIOrderStatus.Delivering) {
    return APIOrderStatus.Delivering;
  } else if (currentOrderStatus === APIOrderStatus.Unconfirmed) {
    return confirmUponApproval && !shouldNotConfirmOrder
      ? APIOrderStatus.Confirmed
      : APIOrderStatus.Unconfirmed;
  }
  return APIOrderStatus.Confirmed;
};
const getFieldValueFromJSONString = (item: APIEntityHistory) => {
  const result = getStringOrObject(item);
  if (result && typeof result === 'object') {
    if (_.isArray(result)) {
      return result.map(item => item.name).join(', ');
    } else {
      return result.name;
    }
  }
  return result;
};

enum HIDDEN_ORDER_FIELDS {
  ProjectId = 'projectId',
  MixTypeId = 'mixTypeId',
  Latitude = 'latitude',
  Longitude = 'longitude',
}

const computeApprovedChangesValue = (
  item: APIEntityHistory,
  approvedChanges: Record<string, boolean | undefined>,
) => {
  // BE is returning project and projectId fields, mixType and mixTypeId fields
  // on FE we are showing only project and mixType because they have correct names to display
  // whereas mixTypeId and projectId do not have names, they only have ids
  // We must send beck to BE all the items that we have received from BE with correct statuses
  const fieldName = item.fieldName as HIDDEN_ORDER_FIELDS;
  if (fieldName === HIDDEN_ORDER_FIELDS.ProjectId) {
    return approvedChanges.project
      ? APIChangesStatus.Applied
      : APIChangesStatus.Declined;
  } else if (fieldName === HIDDEN_ORDER_FIELDS.MixTypeId) {
    return approvedChanges.mixType
      ? APIChangesStatus.Applied
      : APIChangesStatus.Declined;
  } else if (
    [HIDDEN_ORDER_FIELDS.Latitude, HIDDEN_ORDER_FIELDS.Longitude].includes(
      fieldName,
    )
  ) {
    return approvedChanges.deliveryLocation
      ? APIChangesStatus.Applied
      : APIChangesStatus.Declined;
  } else {
    return approvedChanges[fieldName]
      ? APIChangesStatus.Applied
      : APIChangesStatus.Declined;
  }
};

const orderFieldsTitleMapping: Record<string, string> = {
  [OrderFields.OrderStatus]: i18n.t('order.sectionField.orderStatus'),
  [OrderFields.OrderNumber]: i18n.t('order.sectionField.orderNumber'),
  [OrderFields.Volume]: i18n.t('order.sectionField.orderQuantity'),
  [OrderFields.DeliveryDate]: i18n.t('order.sectionTitle.deliveryDate2'),
  [OrderFields.DeliveryTime]: i18n.t('order.sectionTitle.deliveryTime'),
  [OrderFields.Project]: i18n.t('order.sectionField.project'),
  [OrderFields.DeliveryRate]: i18n.t('order.sectionField.deliveryRate'),
  [OrderFields.DeliveryRateType]: i18n.t('order.sectionField.deliveryRate'),
  [OrderFields.DeliveryLocation]: i18n.t('order.sectionField.deliveryLocation'),
  [OrderFields.Notes]: i18n.t('order.sectionField.notes'),
  [OrderFields.Additives]: i18n.t('order.sectionField.additives'),
  [OrderFields.MixType]: i18n.t('order.sectionField.mixType'),
  [OrderFields.Callback]: i18n.t('order.sectionField.callbackTitle'),
  [OrderFields.OrderType]: i18n.t('order.sectionField.orderTypeLabel'),
  [OrderFields.PlacementMethod]: i18n.t('order.sectionField.placementMethod'),
  [OrderFields.TypeOfPour]: i18n.t('order.sectionField.typeOfPour'),
};

const getOrderFieldsDisplayValue = (
  fieldName: OrderFields,
  value: string,
  fieldsList: APIEntityHistory[],
  order: APIOrder | null,
) => {
  switch (fieldName) {
    case OrderFields.Callback:
      return (value ? i18n.t('common.yes') : i18n.t('common.no')).toUpperCase();
    case OrderFields.OrderType:
      return orderTypeField.displayValue(value as APIOrderType, i18n.t);
    case OrderFields.DeliveryDate:
      return formatDisplayedDate(value);
    case OrderFields.DeliveryTime:
      return convert24toAmPm(value);
    case OrderFields.DeliveryRate: {
      const deliveryRateTypeField = fieldsList.find(
        item => item.fieldName === OrderFields.DeliveryRateType,
      );
      const deliveryRateTypeValue =
        (deliveryRateTypeField?.fieldValue || order?.deliveryRateType) ===
        APIDeliveryRateType.CyHour
          ? 'CY/Hour'
          : 'Min/Truck';
      return `${value} ${deliveryRateTypeValue}`;
    }
    case OrderFields.DeliveryRateType: {
      const deliveryRateTypeValue =
        value === APIDeliveryRateType.CyHour ? 'CY/Hour' : 'Min/Truck';
      return `${order?.deliveryRate} ${deliveryRateTypeValue}`;
    }
    default:
      return value;
  }
};

const getVisibleOrderChanges = (
  allOrderChanges: APIEntityHistory[],
): APIEntityHistory[] => {
  return (
    (allOrderChanges || []).filter(
      item =>
        ![
          HIDDEN_ORDER_FIELDS.ProjectId,
          HIDDEN_ORDER_FIELDS.MixTypeId,
          HIDDEN_ORDER_FIELDS.Latitude,
          HIDDEN_ORDER_FIELDS.Longitude,
          // @ts-ignore
        ].includes(item.fieldName),
    ) || []
  );
};

const computeOrderChangesApprovedValues = (
  visibleOrderChanges: APIEntityHistory[],
) => {
  return (visibleOrderChanges || []).reduce(
    (memo, item): Record<string, boolean> => ({
      ...memo,
      [item.fieldName as string]: true,
    }),
    {},
  );
};

enum FormFields {
  NoteToCustomer = 'noteToCustomer',
}

type FormProps = {
  [FormFields.NoteToCustomer]: string;
};

const defaultValues = {
  [FormFields.NoteToCustomer]: '',
};

type Props = {
  order: APIOrder;
};

export const ChangesRequestedOrder: React.FC<Props> = ({ order }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const { addNotification } = useNotifications();
  const { updateOrderRequestedChanges } = useOrder();
  const allOrderChanges = order?.orderChanges as unknown as APIEntityHistory[];
  const visibleOrderChanges = getVisibleOrderChanges(allOrderChanges);

  const [approvedChanges, setApprovedChanges] = useState<
    Record<string, boolean | undefined>
  >(computeOrderChangesApprovedValues(visibleOrderChanges));
  useEffect(() => {
    setApprovedChanges(
      computeOrderChangesApprovedValues(
        getVisibleOrderChanges(allOrderChanges),
      ),
    );
  }, [allOrderChanges]);

  const [confirmUponApproval, setConfirmUponApproval] = useState(
    order?.orderType === APIOrderType.FirmOrder,
  );
  const [isConfirmUponApprovalTouched, setConfirmUponApprovalTouched] =
    useState(false);
  const [isApprovalInProgress, setIsApprovalInProgress] = useState(false);
  const [isRejectionInProgress, setRejectionInProgress] = useState(false);
  const updateOrderRequestedChangesPending = useSelector(
    orderSelectors.updateOrderRequestedChangesPending,
  );
  const showConfirmationPendingNotice = order?.confirmUponChangesApproval;

  const areAllChangesSelected = !Object.values(approvedChanges).includes(false);
  const areAllChangesNotSelected =
    !Object.values(approvedChanges).includes(true);

  const {
    handleSubmit,
    control,
    formState: { isSubmitted },
  } = useForm<FormProps>({
    mode: 'onChange',
    defaultValues,
  });

  useEffect(() => {
    if (!isConfirmUponApprovalTouched) {
      setConfirmUponApproval(order?.orderType === APIOrderType.FirmOrder);
    }
  }, [order?.orderType, isConfirmUponApprovalTouched]);

  const onSubmitChanges =
    (isRejectClicked?: boolean) => async (data: FormProps) => {
      const orderChanges = (allOrderChanges || []).map(item => {
        return {
          ...item,
          changesStatus: isRejectClicked
            ? APIChangesStatus.Declined
            : computeApprovedChangesValue(
                item as APIEntityHistory,
                approvedChanges,
              ),
        };
      });
      const updatedOrderStatus = computeOrderStatus(
        order?.orderStatus,
        order?.confirmUponChangesApproval || confirmUponApproval,
        isRejectClicked || !areAllChangesSelected,
      );
      const body = {
        orderStatus: updatedOrderStatus,
        orderChanges,
        noteToCustomer: data[FormFields.NoteToCustomer],
        confirmUponChangesApproval: false,
      };
      if (isRejectClicked) setRejectionInProgress(true);
      else setIsApprovalInProgress(true);

      const result = await updateOrderRequestedChanges(order?.id || 0, body);
      if (result.error || isRejectClicked) {
        addNotification({
          severity: result.error ? NotificationSeverity.Error : undefined,
          message: result.error ? (
            t('common.generalError')
          ) : (
            <Trans i18nKey="order.details.rejectNotificationText">
              Changes for order {{ orderName: order?.orderName }} have been
              rejected
            </Trans>
          ),
        });
      } else {
        addNotification({
          message: areAllChangesSelected ? (
            <Trans i18nKey="order.details.approveNotificationText">
              Changes for order {{ orderName: order?.orderName }} have been
              approved
            </Trans>
          ) : (
            <Trans i18nKey="order.details.partialApproveNotificationText">
              Changes for order {{ orderName: order?.orderName }} have been
              partially approved
            </Trans>
          ),
        });
      }
      setRejectionInProgress(false);
      setIsApprovalInProgress(false);
    };

  const onCheckboxClick = (item: APIEntityHistory, fieldName: OrderFields) => {
    // Project related fields are project, mixType, additives
    // If there are project changes, we need to check/uncheck all fields at once -  project, mixType, additives.
    // If there are only mixType or additives changes, they can be checked/unchecked independently
    // mixTypes and additives are taken from the Project
    setApprovedChanges(state => {
      const isProjectRelatedFieldEdited = [
        OrderFields.Project,
        OrderFields.MixType,
        OrderFields.Additives,
      ].includes(fieldName);

      const customChangedFields =
        isProjectRelatedFieldEdited &&
        !_.isUndefined(state[OrderFields.Project])
          ? {
              [OrderFields.Project]: !state[fieldName],
              [OrderFields.MixType]: !state[fieldName],
              [OrderFields.Additives]: !state[fieldName],
            }
          : {};
      return {
        ...state,
        [fieldName]: !state[fieldName],
        ...customChangedFields,
      };
    });
  };

  return (
    <ChangesRequestedOrderWrapper item md={10}>
      <Typography variant="h5">{t('order.details.requested.title')}</Typography>
      <Typography
        variant="body1"
        marginTop="0.8rem"
        color={theme.custom.palette.gray}
      >
        {t('order.details.requested.message')}
      </Typography>
      {showConfirmationPendingNotice && (
        <StyledOrderStatusNotice
          title={t('order.details.changesRequested.confirmationPendingTitle')}
          description={t(
            'order.details.changesRequested.confirmationPendingDescription',
          )}
        />
      )}
      <Grid
        container
        margin={showConfirmationPendingNotice ? '2rem 0 2rem' : '3.6rem 0 2rem'}
        gap="1rem"
      >
        <CancelButton
          style={{ width: 'auto' }}
          color="secondary"
          variant="outlined"
          size="large"
          disabled={updateOrderRequestedChangesPending || isApprovalInProgress}
          loading={isRejectionInProgress}
          onClick={handleSubmit(onSubmitChanges(true))}
        >
          {t('order.details.requestedChanges.rejectAction')}
        </CancelButton>

        <ApproveButton
          color="primary"
          variant="contained"
          size="large"
          onClick={handleSubmit(onSubmitChanges())}
          disabled={
            updateOrderRequestedChangesPending ||
            isRejectionInProgress ||
            areAllChangesNotSelected
          }
          loading={isApprovalInProgress}
        >
          {areAllChangesSelected
            ? t('order.details.requestedChanges.approveAllAction')
            : t('order.details.requestedChanges.approveAction')}
        </ApproveButton>
      </Grid>
      {order?.orderStatus === APIOrderStatus.Unconfirmed &&
        !showConfirmationPendingNotice && (
          <FormControlLabelWrapper
            control={
              <Checkbox
                disabled={updateOrderRequestedChangesPending}
                checked={confirmUponApproval}
                onChange={() => {
                  setConfirmUponApproval(!confirmUponApproval);
                  setConfirmUponApprovalTouched(true);
                }}
              />
            }
            label={t('order.details.requested.checkboxLabel')}
          />
        )}

      {!_.isEmpty(visibleOrderChanges) && (
        <RequestedChangesWrapper>
          <RequestedChangesHeader variant="subtitle2">
            {t('order.details.requestedChanges.title')}
          </RequestedChangesHeader>
          <Typography marginBottom="1rem">
            {t('order.details.requestedChanges.description')}
          </Typography>
          {visibleOrderChanges.map(item => {
            const fieldName = item.fieldName as OrderFields;
            if (
              fieldName === OrderFields.DeliveryRateType &&
              visibleOrderChanges.some(
                changedItem =>
                  changedItem.fieldName === OrderFields.DeliveryRate,
              )
            ) {
              return null;
            }
            const fieldNameVisible =
              orderFieldsTitleMapping[
                fieldName as keyof typeof orderFieldsTitleMapping
              ] || fieldName;
            const fieldValue = getOrderFieldsDisplayValue(
              fieldName,
              getFieldValueFromJSONString(item),
              visibleOrderChanges,
              order,
            );
            const isApproved = approvedChanges[fieldName];

            /*
                Combination of fieldName and isApproved is used as a key because
                otherwise MUI Checkbox component will not update its state
                when approvedChanges record is updated.
              */
            return (
              <FormControlLabelWrapper
                key={fieldName + isApproved}
                style={{ alignItems: 'flex-start' }}
                control={
                  <>
                    <Checkbox
                      checked={isApproved}
                      onChange={() => onCheckboxClick(item, fieldName)}
                    />
                  </>
                }
                label={
                  <Box paddingTop="1rem">
                    <FormControlLabelTitle>
                      {fieldNameVisible}
                    </FormControlLabelTitle>
                    <FormControlLabelValue>
                      {fieldValue || t('common.none').toLowerCase()}
                    </FormControlLabelValue>
                  </Box>
                }
              />
            );
          })}
          <StyledFormField
            fieldName={FormFields.NoteToCustomer}
            label={t('order.details.changesRequested.NoteToCustomerLabel')}
            placeholder={t(
              'order.details.changesRequested.NoteToCustomerPlaceholder',
            )}
            isRequired={false}
            control={control}
            maxLength={multilineTextField.maxLength}
            showError={isSubmitted}
            multiline
            rows={4}
            InputProps={{
              style: {
                background: theme.custom.palette.white,
              },
            }}
          />
        </RequestedChangesWrapper>
      )}
    </ChangesRequestedOrderWrapper>
  );
};
