import {
  APIAvailableAdditiveModelSearchResult,
  APIAvailableMixTypeModelSearchResult,
  APIAvailableMixTypeSearchFilter,
  APISearchSortOrder,
  APISortOrder,
} from '@cd3p/core/types/api';
import {
  DEFAULT_PAGE_SIZE,
  getPageNumberAfterLoadMore,
} from '@cd3p/core/utils/lists';
import { httpMethod } from '@cd3p/core/utils/sra';

import { State } from 'modules/index';

export type GenericCategoryItem = {
  categoryId: number;
  categoryName: string;
  id: number;
  name: string;
  providerId: string;
  usage: number;
};

export type NormalizedCategories<
  T extends CategoriesResponse['result'][number],
> = {
  [key: number]: {
    categoryId: number;
    categoryName: string;
    items: Array<T>;
  };
};
export type ConcreteCategoriesFilters = {
  name: string;
};

export const concreteCategoriesDefaultFilters: ConcreteCategoriesFilters = {
  name: '',
};

export type CategoriesResponse = {
  result: GenericCategoryItem[];
  pageNumber: number;
  pageSize: number;
};
export type ConcreteCategoriesState<T extends CategoriesResponse> = {
  items: T['result'];
  itemsLoaded: boolean;
  count: number;
  pagination: {
    pageNumber: T['pageNumber'];
    pageSize: T['pageSize'];
  };
  searchSortOrders: APISearchSortOrder<'categoryName' | 'name'>[];
  filters: ConcreteCategoriesFilters;
};

export const concreteCategoriesDefaultState: ConcreteCategoriesState<
  APIAvailableMixTypeModelSearchResult | APIAvailableAdditiveModelSearchResult
> = {
  items: [],
  itemsLoaded: false,
  pagination: { pageNumber: 1, pageSize: DEFAULT_PAGE_SIZE },
  searchSortOrders: [
    {
      sortField: 'categoryName',
      sortOrder: APISortOrder.ASC,
    },
    {
      sortField: 'name',
      sortOrder: APISortOrder.ASC,
    },
  ],
  filters: concreteCategoriesDefaultFilters,
  count: 0,
};

export const loadConcreteCategories =
  <
    T extends CategoriesResponse,
    ModuleState extends ConcreteCategoriesState<T>,
    Payload extends APIAvailableMixTypeModelSearchResult,
  >(
    actionName: string,
    endpointGetter: (providerId: string, companyId: string) => string,
  ) =>
  (
    providerId: string,
    getModuleState: (state: State) => ModuleState,
    companyId: string,
    body?: Partial<APIAvailableMixTypeSearchFilter>,
  ) => ({
    name: actionName,
    url: endpointGetter(providerId, companyId),
    body: (state: State) => ({
      pageNumber: 1,
      pageSize: getModuleState(state).pagination.pageSize,
      searchSortOrders: getModuleState(state).searchSortOrders,
      ...body,
    }),
    method: httpMethod.post,
    onSuccess: (state: ModuleState, payload: Payload) => {
      return {
        items: payload.result,
        itemsLoaded: true,
        pagination: {
          pageNumber: payload.pageNumber,
          pageSize: payload.pageSize,
        },
        count: payload.count,
      };
    },
  });

export const updateConcreteCategories =
  (
    actionName: string,
    endpointGetter: (providerId: string, companyId: string) => string,
  ) =>
  (providerId: string, companyId: string, body: { ids: number[] }) => ({
    name: actionName,
    url: endpointGetter(providerId, companyId),
    body,
    method: httpMethod.post,
  });

export const loadMoreConcreteCategories =
  <
    T extends CategoriesResponse,
    ModuleState extends ConcreteCategoriesState<T>,
    Payload extends APIAvailableMixTypeModelSearchResult,
  >(
    actionName: string,
    endpointGetter: (providerId: string, companyId: string) => string,
  ) =>
  (
    providerId: string,
    getModuleState: (state: State) => ModuleState,
    companyId: string,
    body?: Partial<APIAvailableMixTypeSearchFilter>,
  ) => ({
    name: actionName,
    url: endpointGetter(providerId, companyId),
    body: (state: State) => ({
      pageNumber: getPageNumberAfterLoadMore(
        getModuleState(state).items,
        getModuleState(state).pagination.pageSize,
      ),
      pageSize: getModuleState(state).pagination.pageSize,
      searchSortOrders: getModuleState(state).searchSortOrders,
      ...getModuleState(state).filters,
      ...body,
    }),
    method: httpMethod.post,
    onSuccess: (state: ModuleState, payload: Payload) => {
      const items = [...state.items, ...(payload.result || [])] as T['result'];

      return {
        items,
        pagination: {
          pageNumber: payload.pageNumber,
          pageSize: payload.pageSize,
        },
      };
    },
  });

export const updateConcreteCategoriesSorting =
  <T extends CategoriesResponse>(actionName: string) =>
  (newSorting: ConcreteCategoriesState<T>['searchSortOrders']) => ({
    name: actionName,
    updater: () => ({
      searchSortOrders: newSorting,
    }),
  });

export const updateConcreteCategoriesPagination =
  <T extends CategoriesResponse>(actionName: string) =>
  (newPagination: ConcreteCategoriesState<T>['pagination']) => ({
    name: actionName,
    updater: () => ({
      pagination: newPagination,
    }),
  });

export const updateConcreteCategoriesFilters =
  <T extends CategoriesResponse, State extends ConcreteCategoriesState<T>>(
    actionName: string,
  ) =>
  (newFilters: ConcreteCategoriesState<T>['filters']) => ({
    name: actionName,
    updater: (state: State) => ({
      filters: {
        ...state.filters,
        ...newFilters,
      },
    }),
  });

export const resetConcreteCategories = (actionName: string) => () => ({
  name: actionName,
  updater: () => concreteCategoriesDefaultState,
});

export function normalizeCategories<
  T extends CategoriesResponse['result'][number],
>(items: T[] | null): NormalizedCategories<T> {
  if (!items) return {} as NormalizedCategories<T>;

  return items.reduce<NormalizedCategories<T>>((acc, curr) => {
    const { categoryId, categoryName } = curr;
    if (categoryId && !acc[categoryId]) {
      acc[categoryId] = {
        categoryId,
        categoryName: categoryName || '',
        items: [curr],
      };
    } else {
      if (categoryId && acc[categoryId]) {
        acc[categoryId].items.push(curr);
      }
    }
    return acc;
  }, {});
}
