import truncate from 'lodash/truncate';

const MAX_LENGTH = 5;

export const truncateTruckNumber = (truckNumber?: string | null): string => {
  if (!truckNumber) {
    return '';
  }

  return truncate(truckNumber, {
    length: MAX_LENGTH,
    omission: '-',
  });
};
