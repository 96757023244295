import {
  subscribeActionAfter,
  subscribeActionBefore,
} from 'redux-subscribe-action';

import { _ } from '../third-party';

export const combineUnsubscribeActions = (
  unsubscribeArray: ((...args: any[]) => any)[],
) => {
  return () => {
    _.forEach(unsubscribeArray, (unsubscribeFunction) => {
      unsubscribeFunction();
    });
  };
};

const batchSubscribeActions = (
  reduxHelper: (...args: any[]) => any,
  actions: string[],
  callback: (...args: any[]) => any,
) => {
  const unsubscribeArray = _.map(actions, (action) =>
    reduxHelper(action, callback),
  );
  return combineUnsubscribeActions(unsubscribeArray);
};

export const subscribeActionsAfter = batchSubscribeActions.bind(
  null,
  subscribeActionAfter,
);
export const subscribeActionsBefore = batchSubscribeActions.bind(
  null,
  subscribeActionBefore,
);

export { default as subscribeActionMiddleware } from 'redux-subscribe-action';
