import { useMemo } from 'react';

import { getNotificationsBoundActions } from '@cd3p/core/modules/notifications';

import { useDispatch } from 'third-party';

export { NotificationSeverity } from '@cd3p/core/modules/notifications';

export const useNotifications = () => {
  const dispatch = useDispatch();

  return useMemo(() => {
    return getNotificationsBoundActions(dispatch);
  }, [dispatch]);
};
