import { RefObject, useCallback, useMemo } from 'react';

import { _, useSelector } from 'third-party';

import { AppTableSettings } from 'constants/common';

import { setTableSettingsColumnsWidth, useStorage } from 'modules/storage';

import { storageSelectors } from 'selectors';

import { TableColumn } from 'components/Table/Table';

import { TableSettingsT } from 'types/app';

export type UseTableSettingsT = {
  tableName: AppTableSettings;
  hiddenColumns?: TableSettingsT['hiddenColumns'];
  isFiltersApplied?: boolean;
  isResizable?: boolean;
  onColumnSizeChanged?: (
    columnId: string,
    width: string,
    tableColumns: (TableColumn<any> & { ref: RefObject<HTMLElement> })[],
  ) => void;
  autoAdjustColumnsWidth?: boolean;
  columnsWidth?: Record<string, number>;
};

export const useTableSettings = (
  tableName: AppTableSettings,
  options: {
    isResizable?: boolean;
    autoAdjustColumnsWidth?: boolean;
    defaultFilters?: Record<any, any>;
  },
) => {
  const { updateUserSettingInStorage } = useStorage();

  const columnsWidth = useSelector(
    storageSelectors.tableSettingsColumnWidthsSelector[tableName],
  );

  const hiddenColumns = useSelector(
    storageSelectors.tableSettingsHiddenColumnsSelector[tableName],
  );

  const isFiltersApplied = useSelector(
    storageSelectors.isTableSettingsFiltersApplied[tableName](
      options.defaultFilters,
    ),
  );

  const onColumnSizeChanged = useCallback(
    (
      columnId: string,
      width: string,
      allColumns: (TableColumn<any> & { ref: RefObject<HTMLElement> })[],
    ) => {
      const columnsWidth = allColumns.reduce((result, it) => {
        return {
          ...result,
          ...(it.ref.current && {
            [it.id]: it.ref.current.getBoundingClientRect().width,
          }),
        };
      }, {});
      updateUserSettingInStorage(
        setTableSettingsColumnsWidth[tableName](columnsWidth),
      );
    },
    [tableName, updateUserSettingInStorage],
  );

  return useMemo<UseTableSettingsT>(
    () => ({
      tableName,
      hiddenColumns,
      isFiltersApplied,
      ...(options.isResizable && {
        isResizable: options.isResizable,
        onColumnSizeChanged,
        autoAdjustColumnsWidth: _.isEmpty(columnsWidth),
        columnsWidth,
      }),
    }),
    [
      tableName,
      onColumnSizeChanged,
      isFiltersApplied,
      hiddenColumns,
      columnsWidth,
      options,
    ],
  );
};
