import { APIAvailableAdditiveModel } from '@cd3p/core/types/api';
import { State } from 'modules';

import { createSelector } from 'third-party';

import { normalizeCategories } from 'modules/concreteCategories/commonConcreteCategories';

export const allAdditivesCategoriesListState = (state: State) =>
  state.additivesCategoriesList;
export const additivesCategoriesListItems = (state: State) =>
  state.additivesCategoriesList.items;
export const additivesCategoriesListNormalized = createSelector(
  [additivesCategoriesListItems],
  items => normalizeCategories<APIAvailableAdditiveModel>(items),
);
export const additivesCategoriesListCount = (state: State) =>
  state.additivesCategoriesList.count;
export const canLoadMoreAdditives = (state: State) =>
  state.additivesCategoriesList.items.length <
  state.additivesCategoriesList.count;
export const additivesCategoriesLoaded = (state: State) =>
  state.additivesCategoriesList.itemsLoaded;
export const additivesCategoriesPending = (state: State) =>
  state.additivesCategoriesList.loadAdditivesCategoriesPending ||
  state.additivesCategoriesList.loadMoreAdditivesCategoriesPending;
export const additivesCategoriesListSorting = (state: State) =>
  state.additivesCategoriesList.searchSortOrders;
export const additivesCategoriesListPagination = (state: State) =>
  state.additivesCategoriesList.pagination;
