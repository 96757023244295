import React, { useCallback, useEffect, useMemo, useState } from 'react';

import styled from 'styled-components';

import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SendIcon from '@mui/icons-material/Send';

import { APIUser, APIUserStatus } from '@cd3p/core/types/api';
import { isUserAdmin } from '@cd3p/core/utils/app';
import { getSortOrder } from '@cd3p/core/utils/common';
import {
  getPageNumberAfterLoadMore,
  getPageSizeDependingOnItems,
} from '@cd3p/core/utils/lists';
import {
  Box,
  Button,
  CircularProgress,
  ConfirmationDialog,
  IconButton,
  LoadingButton,
  Menu,
  MenuItem,
  Table,
  TableCell,
  Trans,
  Typography,
  ViewWrapper,
} from 'components';

import { useSelector, useTranslation } from 'third-party';

import { getMaskedPhone } from 'constants/regexp';

import {
  ContractorUsersListColumnId,
  useContractorUsersList,
} from 'modules/contractorUsersList';
import { NotificationSeverity, useNotifications } from 'modules/notifications';

import { appSelectors, contractorUsersListSelectors } from 'selectors';

import { ContractorModal } from 'features/UserManagement/ContractorModal';

import { getUserFullName } from 'utils/user';

const canResendInvitation = (userStatus?: APIUserStatus) =>
  userStatus &&
  [APIUserStatus.NotActive, APIUserStatus.Incomplete].includes(userStatus);

const StyledViewWrapper = styled(ViewWrapper)`
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  padding: 2rem 0;
`;

const Header = styled.div`
  display: flex;
  margin-bottom: 1rem;
  align-items: center;
  padding: 0 1.6rem;
`;

const TableWrapper = styled.div`
  flex-grow: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
`;

const TableCellText = styled(Typography)`
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 1.5;
  color: ${props => props.theme.custom.palette.gray};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const TableLoadMoreButton = styled(LoadingButton)`
  margin-top: 2rem;
  height: 5.2rem;
`;

const CircularProgressWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10rem;
  flex: 1;
`;

const StyledTableBodyCell = styled(TableCell)<{
  height: string;
}>`
  position: relative;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 0;
  border-bottom: 1px solid ${props => props.theme.custom.palette.truegray300};
  height: ${props => props.height};
  padding: 0 1.6rem;

  &:not(:last-child):after {
    position: absolute;
    top: 1rem;
    right: 0;
    bottom: 0;
    border-right: 1px solid ${props => props.theme.custom.palette.truegray300};
    width: 1px;
    content: '';
  }
`;

const EmptyState = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  align-items: flex-start;
`;

const InviteContractorButton = styled(Button)`
  margin-right: 2rem;
  text-indent: 1rem;
  margin-left: auto;
`;

const tableComponents = {
  LoadMoreButton: TableLoadMoreButton,
  TableBodyCell: StyledTableBodyCell,
};

export const ContractorUsersList = () => {
  const { t } = useTranslation();
  const { addNotification } = useNotifications();
  const [isConfirmationDialogShown, setConfirmationDialogShown] =
    useState(false);

  const [isContractorModalOpened, setContractorModalOpened] = useState(false);
  const [contractorIdInModal, setContractorIdInModal] = useState<string | null>(
    null,
  );
  const [contractorInMenu, setContractorInMenu] =
    React.useState<null | APIUser>(null);

  const {
    loadContractorUsers,
    loadMoreContractorUsers,
    updateContractorUsersListSorting,
    resendInvitation,
    deleteContractorUser,
  } = useContractorUsersList();

  const user = useSelector(appSelectors.user);
  const contractorUsersListItems = useSelector(
    contractorUsersListSelectors.contractorUsersListItems,
  );
  const loadContractorUsersPending = useSelector(
    contractorUsersListSelectors.loadContractorUsersPending,
  );
  const contractorUsersListLoaded = useSelector(
    contractorUsersListSelectors.contractorUsersListLoaded,
  );
  const contractorUsersListCount = useSelector(
    contractorUsersListSelectors.contractorUsersListCount,
  );
  const loadMoreContractorUsersListPending = useSelector(
    contractorUsersListSelectors.loadMoreContractorUsersListPending,
  );
  const deleteContractorUserPending = useSelector(
    contractorUsersListSelectors.deleteContractorUserPending,
  );
  const resendInvitationPending = useSelector(
    contractorUsersListSelectors.resendInvitationPending,
  );
  const contractorUsersListSorting = useSelector(
    contractorUsersListSelectors.contractorUsersListSorting,
  );

  const { sortField, sortOrder } = contractorUsersListSorting[0];

  const isAdmin = isUserAdmin(user);

  const loadContractorsCallback = useCallback(() => {
    loadContractorUsers({
      searchSortOrders: contractorUsersListSorting,
    });
  }, [loadContractorUsers, contractorUsersListSorting]);

  useEffect(() => {
    loadContractorsCallback();
  }, [loadContractorsCallback]);

  const onLoadMoreClicked = useCallback(() => {
    loadMoreContractorUsers({
      pageNumber: getPageNumberAfterLoadMore(contractorUsersListItems),
      searchSortOrders: contractorUsersListSorting,
    });
  }, [
    loadMoreContractorUsers,
    contractorUsersListItems,
    contractorUsersListSorting,
  ]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);

  const onRowMenuClicked = useCallback(
    (event: React.MouseEvent<HTMLElement>, contractor: APIUser) => {
      setContractorInMenu(contractor);
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  const onRowMenuClose = useCallback(() => {
    setContractorInMenu(null);
    setAnchorEl(null);
  }, []);

  const hasContractors =
    contractorUsersListLoaded && contractorUsersListItems.length > 0;

  const onColumnHeaderClicked = useCallback(
    (columnId: ContractorUsersListColumnId) => {
      const computedSortOrder = getSortOrder(columnId, sortField, sortOrder);
      updateContractorUsersListSorting([
        {
          sortField: columnId,
          sortOrder: computedSortOrder,
        },
        ...(columnId === 'firstName'
          ? [
              {
                sortField: 'lastName' as const,
                sortOrder: computedSortOrder,
              },
            ]
          : []),
      ]);
    },
    [sortField, sortOrder, updateContractorUsersListSorting],
  );

  const onConfirmDeleteClick = async () => {
    if (contractorInMenu) {
      const result = await deleteContractorUser(contractorInMenu.id);
      if (result.error) {
        await addNotification({
          severity: NotificationSeverity.Error,
          message: t('common.generalError'),
        });
      } else {
        loadContractorUsers({
          pageSize: getPageSizeDependingOnItems(contractorUsersListItems),
          searchSortOrders: contractorUsersListSorting,
        });
        await addNotification({
          message: (
            <Trans i18nKey="userManagement.userDeletionSuccess">
              {{
                contactName: getUserFullName(contractorInMenu),
              }}{' '}
              was successfully removed
            </Trans>
          ),
        });
      }
      setContractorInMenu(null);
      setConfirmationDialogShown(false);
    }
  };

  const onInviteContractorSuccess = useCallback(() => {
    loadContractorUsers({
      pageSize: getPageSizeDependingOnItems(contractorUsersListItems),
      searchSortOrders: contractorUsersListSorting,
    });
  }, [
    contractorUsersListItems,
    contractorUsersListSorting,
    loadContractorUsers,
  ]);

  const columns = useMemo(() => {
    return [
      {
        id: 'name',
        dataId: 'firstName' as ContractorUsersListColumnId,
        label: t('userManagement.customerTab.contactNameColumn'),
        formatter: (value: APIUser['firstName'], data: APIUser): any => {
          const computedValue = getUserFullName(data);
          return {
            value: computedValue,
            element: <TableCellText>{computedValue}</TableCellText>,
          };
        },
      },
      {
        id: 'email',
        dataId: 'email' as ContractorUsersListColumnId,
        label: t('userManagement.customerTab.emailColumn'),
        width: '30%',
        formatter: (value: APIUser['email']) => ({
          value: value || '',
          element: <TableCellText>{value || ''}</TableCellText>,
        }),
      },
      {
        id: 'phone',
        dataId: 'phone' as ContractorUsersListColumnId,
        label: t('userManagement.customerTab.phoneColumn'),
        width: '18.2rem',
        formatter: (value: APIUser['phone']) => {
          const computedValue = getMaskedPhone(value);
          return {
            value: computedValue,
            element: <TableCellText>{computedValue}</TableCellText>,
          };
        },
      },
      {
        id: 'companyName',
        label: t('userManagement.customerTab.companyNameColumn'),
        formatter: (value: string, data: APIUser) => {
          const computedValue =
            data.companies?.map(item => item.name).join(', ') || '';
          return {
            value: computedValue,
            element: <TableCellText>{computedValue}</TableCellText>,
          };
        },
      },
      ...(isAdmin
        ? [
            {
              id: 'menu',
              width: '7.2rem',
              formatter: (value: string, contractor: APIUser) => {
                return {
                  value: '',
                  element: (
                    <Box>
                      <IconButton
                        aria-label="more"
                        id="long-button"
                        aria-controls={
                          menuOpen
                            ? `long-menu-for-${contractor.id}`
                            : undefined
                        }
                        aria-expanded={menuOpen ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={event => {
                          event.stopPropagation();
                          onRowMenuClicked(event, contractor);
                        }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </Box>
                  ),
                };
              },
            },
          ]
        : []),
    ];
  }, [menuOpen, onRowMenuClicked, isAdmin, t]);

  const onResendInviteClick = async () => {
    if (contractorInMenu) {
      const result = await resendInvitation(contractorInMenu.id);
      if (result.error) {
        await addNotification({
          severity: NotificationSeverity.Error,
          message: t('common.generalError'),
        });
      } else {
        await addNotification({
          message: t('inviteCustomer.resendInvitationMessage'),
        });
      }
      setContractorInMenu(null);
    }
  };

  if (!contractorUsersListLoaded) {
    return (
      <CircularProgressWrapper>
        <CircularProgress />
      </CircularProgressWrapper>
    );
  }

  return (
    <StyledViewWrapper>
      <Header>
        {isAdmin && contractorUsersListCount > 0 && (
          <InviteContractorButton
            onClick={() => setContractorModalOpened(true)}
          >
            <AddBoxOutlinedIcon />
            {t('userManagement.customerTab.inviteCustomer')}
          </InviteContractorButton>
        )}
      </Header>
      {hasContractors ? (
        <TableWrapper>
          <ConfirmationDialog
            onClose={() => {
              setConfirmationDialogShown(false);
              setContractorInMenu(null);
            }}
            handleActionClick={onConfirmDeleteClick}
            open={isConfirmationDialogShown}
            actionPending={deleteContractorUserPending}
            description={t('userManagement.customerTab.removeDescription')}
          />
          <Table
            columns={columns}
            items={contractorUsersListItems}
            sortField={sortField}
            sortOrder={sortOrder}
            onColumnHeaderClicked={onColumnHeaderClicked}
            itemsTotalCount={contractorUsersListCount}
            itemsLoaded={!!contractorUsersListLoaded}
            loadItemsPending={loadContractorUsersPending}
            loadMoreItemsPending={!!loadMoreContractorUsersListPending}
            onLoadMoreClicked={onLoadMoreClicked}
            emptyText={t('userManagement.customerTab.emptyText')}
            loadMoreButtonText={t('userManagement.customerTab.loadMore')}
            components={tableComponents}
          />
        </TableWrapper>
      ) : (
        !loadContractorUsersPending && (
          <EmptyState>
            <Typography variant="h3" color="darkText">
              {t('userManagement.customerTab.emptyText')}
            </Typography>
            {isAdmin && (
              <Button
                size="large"
                color="primary"
                variant="contained"
                onClick={() => setContractorModalOpened(true)}
              >
                {t('userManagement.customerTab.inviteCustomer')}
              </Button>
            )}
          </EmptyState>
        )
      )}

      {contractorInMenu && (
        <Menu
          id={`long-menu-for-${contractorInMenu.id}`}
          anchorEl={anchorEl}
          open={!!contractorInMenu && !isConfirmationDialogShown}
          onClose={onRowMenuClose}
          menuTitle={t('userManagement.customerTab.actionsTitle')}
        >
          {isAdmin && (
            <MenuItem
              icon={<DriveFileRenameOutlineOutlinedIcon />}
              text={t('userManagement.customerTab.editAction')}
              onClick={() => {
                setContractorModalOpened(true);
                setContractorIdInModal(contractorInMenu?.id || null);
                setContractorInMenu(null);
              }}
            />
          )}
          {canResendInvitation(contractorInMenu?.userStatus) && (
            <MenuItem
              disabled={resendInvitationPending}
              onClick={onResendInviteClick}
              icon={<SendIcon />}
              text={t('userManagement.customerTab.resendInviteAction')}
            />
          )}
          <MenuItem
            onClick={() => {
              setConfirmationDialogShown(true);
            }}
            icon={<DeleteForeverIcon />}
            text={t('userManagement.customerTab.deleteAction')}
          />
        </Menu>
      )}
      {isAdmin && isContractorModalOpened && (
        <ContractorModal
          contractorId={contractorIdInModal}
          onClose={() => {
            setContractorModalOpened(false);
            setContractorIdInModal(null);
          }}
          onSuccess={onInviteContractorSuccess}
        />
      )}
    </StyledViewWrapper>
  );
};
