import { useCallback, useEffect, useMemo } from 'react';

import {
  GlobalCache,
  addUserSubscription,
  emptyGlobalCache,
  getAbsentSubscriptionDialog,
  getDialogsFromCache,
  getExistSubscriptionDialog,
  getLastSubscriptionUpdate,
  getLastSyncDate,
  getNextToken,
  initGlobalCache,
  invalidateCache,
  removeUserSubscription,
  updateDialog,
  updateDialogSubscriptions,
  updateDialogs,
  updateUserSubscriptions,
} from '@cd3p/core/utils/cache';
import { error, log } from '@cd3p/core/utils/logger';

import { useSelector } from 'third-party';

import { useDialogsList } from 'modules/dialogsList';
import { useOrder } from 'modules/order';

import { appSelectors } from 'selectors';

export {
  updateDialog,
  updateDialogs,
  getNextToken,
  addUserSubscription,
  removeUserSubscription,
  getDialogsFromCache,
  getExistSubscriptionDialog,
  updateDialogSubscriptions,
  getLastSyncDate,
  getAbsentSubscriptionDialog,
  updateUserSubscriptions,
  getLastSubscriptionUpdate,
};

export const getGlobalCache = (providerId: string) => {
  const cache = localStorage.getItem('cache_' + providerId) ?? '';
  const result = cache.length
    ? (JSON.parse(cache) as GlobalCache)
    : emptyGlobalCache;

  return result;
};

const setGlobalCache = (providerId: string, cache: GlobalCache) => {
  localStorage.setItem('cache_' + providerId, JSON.stringify(cache));
  return cache;
};

const options = {
  maxSubscriptionLength: 100,
  maxDialogLength: 100,
  getGlobalCache,
  setGlobalCache,
};

initGlobalCache(options);

export const resetGlobalCache = () => {
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (key?.startsWith('cache_')) {
      localStorage.removeItem(key);
    }
  }
};

export const useGlobalCache = () => {
  const { getUserSubscriptions } = useOrder();

  const {
    updateToken,
    getFreshDialogs,
    getDialogsByIds,
    setDialogMessages,
    setSubscribedDialogs,
    setIsSubscribedDialogLoaded,
  } = useDialogsList();

  const providerId = useSelector(appSelectors.providerId);
  const userId = useSelector(appSelectors.userId);
  const companyId = useSelector(appSelectors.companyId);
  const globalCacheVersion = useSelector(appSelectors.globalCacheVersion);

  const syncData = useCallback(async () => {
    if (!userId) {
      log('user is not auth, skip sync');
      return;
    }

    let cache = getGlobalCache(providerId);

    if (globalCacheVersion && cache.version !== globalCacheVersion) {
      log(
        'Invalidating cache due to version mismatch. Expected: ' +
          globalCacheVersion +
          ', found: ' +
          cache.version,
      );

      cache = invalidateCache(providerId, globalCacheVersion);
    }

    const lastSubscription = getLastSubscriptionUpdate(providerId, cache);
    const result = await getUserSubscriptions(lastSubscription);
    const subscriptions = result.payload;

    // sync user subscriptions with global cache
    cache = updateUserSubscriptions(providerId, subscriptions, cache);
    log(
      'cache subscriptions: ' + cache.userSubscriptions.map(it => it.orderId),
    );

    // sync last dialogs
    const lastSync = getLastSyncDate(providerId, cache);
    const freshDialogs = await getFreshDialogs(companyId, '', lastSync);

    if (freshDialogs.payload.token && lastSync) {
      log('user was not in app for a long, clear old dialog cache');
      const newData = await getFreshDialogs(companyId, '', null);
      cache.dialogs = [];
      cache = updateDialogs(
        providerId,
        newData.payload.result,
        newData.payload.token,
        cache,
      );
    } else {
      log('dialog was updated in cache');
      cache = updateDialogs(
        providerId,
        freshDialogs.payload.result,
        freshDialogs.payload.token,
        cache,
      );
    }

    setDialogMessages(cache.dialogs, cache.dialogToken);

    const dialogsToDownload = getAbsentSubscriptionDialog(providerId, cache);
    const existDialogs = getExistSubscriptionDialog(providerId, cache);

    log('dialogs to download: ' + dialogsToDownload);
    log('exist dialogs: ' + existDialogs.map(it => it.orderId));

    try {
      if (lastSync) {
        const token = getNextToken(providerId, companyId, cache);
        updateToken(token);
        log('cache token: ' + token);
      }
      let fishedLoad = dialogsToDownload.length == 0;
      let token = '';
      let i = 0;

      while (!fishedLoad) {
        log('sync dialogs request:' + i);
        if (++i > 6) {
          error('sync dialogs fallen in loop');
          break;
        }
        const dialogsResult = await getDialogsByIds(
          companyId,
          dialogsToDownload,
          token,
        );

        existDialogs.push(...dialogsResult.payload.result);

        token = dialogsResult.payload.token ?? '';
        fishedLoad = !dialogsResult.payload?.token?.length;
      }

      cache = updateDialogSubscriptions(providerId, existDialogs, cache);
      setSubscribedDialogs(cache.dialogsSubscription);
    } catch (e) {
      error('sync dialogs fallen in loop' + e);
    } finally {
      setIsSubscribedDialogLoaded(true);
    }
  }, [
    userId,
    companyId,
    providerId,
    globalCacheVersion,
    getUserSubscriptions,
    getFreshDialogs,
    setDialogMessages,
    setSubscribedDialogs,
    updateToken,
    getDialogsByIds,
    setIsSubscribedDialogLoaded,
  ]);

  useEffect(() => {
    if (!userId) {
      setIsSubscribedDialogLoaded(true);
      return;
    }

    setIsSubscribedDialogLoaded(false);
    syncData();
  }, [userId, syncData, setIsSubscribedDialogLoaded]);

  return useMemo(
    () => ({
      syncData,
    }),
    [syncData],
  );
};
