import React, { ReactNode } from 'react';

import styled from 'styled-components';

import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';

import { IconButton, Typography } from 'components';

import { useTranslation } from 'third-party';

const CloseButton = styled(IconButton)`
  border: 1px solid ${props => props.theme.custom.palette.muted100};
  padding: 0;
`;

const Wrapper = styled.div`
  background-color: #fff;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 2rem 1.2rem 2rem 2.5rem;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.25);
  z-index: 2;
`;

const TitleWrapper = styled.div`
  padding-right: 1rem;
  flex-grow: 1;
  min-width: 0;
`;

const Title = styled(Typography)`
  color: ${props => props.theme.custom.palette.muted800};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const SubTitle = styled(Typography)`
  font-size: 1.2rem;
  font-weight: 700;
  color: ${props => props.theme.custom.palette.primary900};
  text-transform: uppercase;
`;

type Props = {
  onClose: () => void;
  title: string;
  children?: ReactNode;
};

export const OrderChat: React.FC<Props> = ({ onClose, title, children }) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Header>
        <TitleWrapper>
          <SubTitle>{t('chat.messageTo')}:</SubTitle>
          <Title variant="h4" title={title}>
            {title}
          </Title>
        </TitleWrapper>
        <CloseButton onClick={onClose}>
          <KeyboardArrowLeftOutlinedIcon />
        </CloseButton>
      </Header>
      {children}
    </Wrapper>
  );
};
