import { Core } from '@cd3p/core/config';
import queryString from 'query-string';

export const APP_PATH = process.env['APP_PATH'];

export const PARENT_PATH = '.';
export const ADD_PATH = 'add';
export const EDIT_PATH = 'edit';
export const PLANTS_PATH = 'locations';
export const MIX_TYPES_PATH = 'mix-types';
export const ADDITIVES_PATH = 'additives';
export const PROJECTS_PATH = 'projects';
export const CUSTOMER_TEAM_PATH = 'team';
export const ORDERS_PATH = 'orders';
export const TICKETS_PATH = 'tickets';
export const COMPANY_SETTINGS_PATH = 'company-settings';
export const INTEGRATIONS_PATH = 'integrations';
export const USER_SETTINGS_PATH = 'user-settings';

export const appUrl = () => `${APP_PATH}`;

export const createFullUrl = (localUrl: string) => {
  return `${window.location.origin}${localUrl}`;
};

export const trackerUrl = () => `${appUrl()}tracker`;
export const trackingEmulatorUrl = () => `${appUrl()}tracking-emulator`;
export const trackingHistoryViewUrl = () => `${appUrl()}tracking`;
export const allOrdersUrl = () => `${appUrl()}orders`;

export const openInstanceUrl = (providerId: string) =>
  `https://${Core.Environment()}.concretely.co/orders?providerId=${providerId}`;

export const orderUrl = (id: string | number) => `${allOrdersUrl()}/${id}`;
export const projectOrderUrl = (
  id: string | number,
  projectId: string,
  companyId: string,
) => {
  return queryString.stringifyUrl({
    url: orderUrl(id),
    query: { projectId, companyId },
  });
};

export const orderChatUrl = (id: string | number) =>
  `${allOrdersUrl()}/${id}/chat`;
export const createOrderUrl = () => `${appUrl()}orders/add`;
export const createProjectOrderUrl = (projectId: string, companyId: string) =>
  queryString.stringifyUrl({
    url: createOrderUrl(),
    query: { projectId, companyId },
  });

export const ORDER_ADD_TICKET_PATH = 'add';
export const orderTicketsRelativeUrl = (
  ticketId?: string | number,
  truckId?: string | null,
  driverId?: string | null,
) => {
  const url = `${TICKETS_PATH}${ticketId ? `/${ticketId}` : ''}`;
  return queryString.stringifyUrl(
    {
      url,
      query: { truckId, driverId },
    },
    {
      skipNull: true,
    },
  );
};

export const orderAddTicketRelativeUrl = orderTicketsRelativeUrl(
  ORDER_ADD_TICKET_PATH,
);

export const orderTicketsUrl = (
  orderId: string | number,
  ticketId?: string | number,
  truckId?: string | null,
  driverId?: string | null,
) => {
  const url = `${orderUrl(orderId)}/tickets${ticketId ? `/${ticketId}` : ''}`;
  return queryString.stringifyUrl(
    {
      url,
      query: { truckId, driverId },
    },
    {
      skipNull: true,
    },
  );
};

export const orderAddTicketUrl = (
  orderId: string | number,
  truckId?: string | null,
  driverId?: string | null,
) => {
  const url = orderTicketsUrl(orderId, 'add');
  return queryString.stringifyUrl(
    {
      url,
      query: { truckId, driverId },
    },
    {
      skipNull: true,
    },
  );
};

export const todayOrdersUrl = () => `${appUrl()}today-orders`;

export const TODAY_ORDERS_ACTIVE_TICKETS_URL_PATH = 'active-tickets';
export const todayOrdersActiveTicketsUrl = () =>
  `${todayOrdersUrl()}/${TODAY_ORDERS_ACTIVE_TICKETS_URL_PATH}`;

export const TODAY_ORDERS_AVAILABLE_TRUCKS_URL_PATH = 'available-trucks';
export const todayOrdersAvailableTrucksUrl = () =>
  `${todayOrdersUrl()}/${TODAY_ORDERS_AVAILABLE_TRUCKS_URL_PATH}`;
export const importOrdersUrl = () => `${appUrl()}import-orders`;
export const loginUrl = () => `${appUrl()}login`;
export const forgotPasswordUrl = () => `${appUrl()}forgot-password`;
export const resetPasswordUrl = () => `${appUrl()}reset-password`;
export const userManagementUrl = () => `${appUrl()}user-management`;

export const productsUrl = () => `${appUrl()}products`;
export const settingsUrl = () => `${appUrl()}settings`;

export const settingsCompanySettingsUrl = () =>
  `${settingsUrl()}/${COMPANY_SETTINGS_PATH}`;
export const settingsIntegrationsUrl = () =>
  `${settingsUrl()}/${INTEGRATIONS_PATH}`;
export const settingsUserSettingsUrl = () =>
  `${settingsUrl()}/${USER_SETTINGS_PATH}`;

export const contractorProjectsListUrl = () => `${appUrl()}projects`;
export const contractorAddProjectUrl = () => `${appUrl()}projects/${ADD_PATH}`;
export const contractorTeamListUrl = () => `${appUrl()}team`;

export const productsPlantsUrl = () => `${productsUrl()}/${PLANTS_PATH}`;
export const productsMixTypesUrl = () => `${productsUrl()}/${MIX_TYPES_PATH}`;
export const productsAdditivesUrl = () => `${productsUrl()}/${ADDITIVES_PATH}`;
export const CONTRACTOR_USERS_URL_PATH = 'contractor-users';
export const DRIVERS_URL_PATH = 'drivers';
export const PLANTS_URL_PATH = 'drivers';
export const contractorUsersUrl = () =>
  `${userManagementUrl()}/${CONTRACTOR_USERS_URL_PATH}`;
export const driversUrl = () => `${userManagementUrl()}/${DRIVERS_URL_PATH}`;
export const plantsUrl = () => `${userManagementUrl()}/${PLANTS_URL_PATH}`;

export const contractorsUrl = (contractorId?: string) =>
  `${appUrl()}customer/${contractorId ? `${contractorId}/` : ''}`;

export const contractorsMixTypesUrl = (contractorId?: string) =>
  `${contractorsUrl(contractorId)}${MIX_TYPES_PATH}`;

export const contractorsAdditiveTypesUrl = (contractorId?: string) =>
  `${contractorsUrl(contractorId)}${ADDITIVES_PATH}`;

export const contractorsCustomerTeamUrl = (contractorId?: string) =>
  `${contractorsUrl(contractorId)}${CUSTOMER_TEAM_PATH}`;

export const customerProjectsUrl = (contractorId?: string) =>
  `${contractorsUrl(contractorId)}${PROJECTS_PATH}`;
export const customerProjectDetailsUrl = (
  contractorId: string,
  projectId: string,
) => `${customerProjectsUrl(contractorId)}/${projectId}`;
export const customerProjectAddUrl = (contractorId: string) =>
  `${customerProjectsUrl(contractorId)}/${ADD_PATH}`;
export const customerProjectEditUrl = (
  contractorId: string,
  projectId: string,
) => `${customerProjectDetailsUrl(contractorId, projectId)}/${EDIT_PATH}`;
export const customerAccountSettingsUrl = (contractorId?: string) =>
  `${contractorsUrl(contractorId)}${COMPANY_SETTINGS_PATH}`;

// onboarding urls
export const onboardingUrl = () => `${appUrl()}onboarding`;

export enum ONBOARDING_STEPS {
  Step1 = 1,
  Step2 = 2,
}
export const getOnboardingStepPath = (stepNumber: ONBOARDING_STEPS) =>
  `step-${stepNumber}`;
export const onboardingStepUrl = (stepNumber: ONBOARDING_STEPS) =>
  `${onboardingUrl()}/step-${stepNumber}`;

export const projectOrdersUrl = (
  projectId: string,
  companyId: string,
  orderId?: string,
) =>
  `${customerProjectDetailsUrl(companyId, projectId)}/${ORDERS_PATH}${
    orderId ? `/${orderId}` : ''
  }`;

export const projectMixTypesUrl = (projectId: string, companyId: string) =>
  `${customerProjectDetailsUrl(companyId, projectId)}/${MIX_TYPES_PATH}`;
export const projectAdditiveTypesUrl = (projectId: string, companyId: string) =>
  `${customerProjectDetailsUrl(companyId, projectId)}/${ADDITIVES_PATH}`;

export const providerUsersListUrl = () => `${appUrl()}users`;
export const docsUrl = () => `${appUrl()}docs/`;
export const apiDocsUrl = () => `${docsUrl()}api`;

export const plantAddUrl = () => `${appUrl()}${PLANTS_PATH}/${ADD_PATH}`;
export const plantEditUrl = (plantId: string) =>
  `${appUrl()}${PLANTS_PATH}/${plantId}/edit`;
