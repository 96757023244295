/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface APIAttachmentModel {
  /** @format uuid */
  id?: string;
  fileName?: string | null;
  fileExtension?: string | null;
  previewUrl?: string | null;
  downloadUrl?: string | null;
}

export interface APIAttachmentSearchModel {
  attachmentIds?: string[] | null;
}

export interface APICommunicationEventMessage {
  from?: string | null;
  to?: string | null;
  bcc?: string[] | null;
  body?: string | null;
  isHtml?: boolean;
  subject?: string | null;
  templateName?: string | null;
  dynamicTemplateData?: Record<string, any>;
  data?: Record<string, any>;
  /** @format uuid */
  providerId?: string;
  type?: APICommunicationType;
}

export enum APICommunicationType {
  SMS = 'SMS',
  Email = 'Email',
  Push = 'Push',
}

export interface APIDocumentModel {
  /** @format uuid */
  id?: string;
  isRoot?: boolean;
  author?: string | null;
  /** @format int32 */
  position?: number;
  /** @format date-time */
  publishedDate?: string;
  title?: string | null;
  text?: string | null;
  optimizedSearchText?: string | null;
  /** @format uuid */
  sectionId?: string;
}

export interface APIDocumentRequestModel {
  /** @format uuid */
  id?: string;
  isRoot?: boolean;
  author?: string | null;
  /** @format int32 */
  position?: number;
  /** @format date-time */
  publishedDate?: string;
  title?: string | null;
  text?: string | null;
}

export interface APIDocumentSearchFilter {
  /**
   * The search page number, will be used for offset
   * @format int32
   * @example 0
   */
  pageNumber?: number;
  /**
   * The count of entities on the page
   * @format int32
   * @example 20
   */
  pageSize?: number;
  /** a collection of sort fields */
  searchSortOrders?: APISearchSortOrder[] | null;
  fullTextSearch?: APIFullTextSearchFilter;
  columns?: string[] | null;
}

export interface APIDocumentSearchResult {
  document?: APIDocumentModel;
  highlights?: Record<string, string[] | null>;
}

export interface APIFullTextSearchFilter {
  /** The global search query for the full text search */
  pattern?: string | null;
  /** The Columns to search in the full text search */
  columns?: string[] | null;
}

export interface APINotificationModel {
  id?: string | null;
  /** @format uuid */
  providerId?: string;
  /** @format date-time */
  createdDate?: string;
  /** @format date-time */
  eventDate?: string;
  notificationType?: APINotificationType;
  data?: Record<string, string | null>;
  urgencyType?: APIUrgencyType;
}

export interface APINotificationModelContinueSearchResult {
  /**
   * The continue token for search
   * @example "1"
   */
  token?: string | null;
  /** The list of the searched entities */
  result?: APINotificationModel[] | null;
}

export interface APINotificationSearchFilter {
  orderByType?: APINotificationSortOrderType;
  lastUrgencyType?: APIUrgencyType;
  continuationToken?: string | null;
}

export enum APINotificationSortOrderType {
  Oldest = 'Oldest',
  Newest = 'Newest',
  Urgency = 'Urgency',
}

export enum APINotificationType {
  OrderRequested = 'OrderRequested',
  OrderChangesRequested = 'OrderChangesRequested',
  OrderConfirmed = 'OrderConfirmed',
  OrderCancellationSubmitted = 'OrderCancellationSubmitted',
  OrderCallBackRequested = 'OrderCallBackRequested',
  ProjectCreatedByContractor = 'ProjectCreatedByContractor',
  OrderCallBackSubmitted = 'OrderCallBackSubmitted',
  OrderApproved = 'OrderApproved',
  OrderChangesApproved = 'OrderChangesApproved',
  OrderChangesPartiallyApproved = 'OrderChangesPartiallyApproved',
  OrderChangesRejected = 'OrderChangesRejected',
  OrderConfirmationNeeded = 'OrderConfirmationNeeded',
  OrderCallBackPending = 'OrderCallBackPending',
  OrderCancellationConfirmed = 'OrderCancellationConfirmed',
  OrderCancelled = 'OrderCancelled',
  OrderUpdated = 'OrderUpdated',
  OrderCallBackConfirmed = 'OrderCallBackConfirmed',
  ProjectCreatedByDispatcher = 'ProjectCreatedByDispatcher',
  OrderNumberMissing = 'OrderNumberMissing',
  NewProjectSubscription = 'NewProjectSubscription',
  NewOrderSubscription = 'NewOrderSubscription',
  OrderDeclined = 'OrderDeclined',
}

export interface APISearchSortOrder {
  /**
   * The name of a sort field
   * @default "Id"
   * @example "OrderNumber"
   */
  sortField?: string | null;
  sortOrder?: APISortOrder;
}

export enum APISortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum APIUrgencyType {
  Low = 'Low',
  Medium = 'Medium',
  High = 'High',
}
