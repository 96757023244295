import React, { useEffect } from 'react';

import { RouterOutlet } from 'third-party';

import { useProjectOrdersList } from 'modules/projectOrdersList';

export const ProjectViewWrapper = () => {
  const { resetProjectOrdersSearch } = useProjectOrdersList();
  useEffect(() => {
    return () => {
      resetProjectOrdersSearch();
    };
  }, [resetProjectOrdersSearch]);
  return <RouterOutlet />;
};
