import { TFunction } from 'i18next';

import { API_DATE_FORMAT } from '../constants/common';
import { dayjs } from '../third-party';
import { APIOrderType } from '../types/api';
import { dateRegexp, emailRegexp } from '../utils/regexp';

export const textField = {
  maxLength: 200,
};

export const multilineTextField = {
  maxLength: 1000,
};

export const firstNameField = {
  maxLength: 50,
};

export const lastNameField = {
  ...firstNameField,
};

export const passwordField = {
  minLength: 8,
  maxLength: 64,
};

export const phoneField = {
  maxLength: 15,
  formValue: (value: string) => value?.substring?.(2),
  apiValue: (value: string) => `+1${value.replace(/-/g, '')}`,
  mask: '#00-000-0000',
  maskNative: [
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
  maskDefinitions: {
    '#': /[1-9]/,
  },
  validate(value: string) {
    return value.length === this.mask.length;
  },
  validateNative(value: string) {
    return value.length === 10;
  },
};

export const emailField = {
  maxLength: 254,
  validate(value: string) {
    return emailRegexp.test(value) && value.length <= this.maxLength;
  },
};

export const dateField = {
  validate(value: string) {
    return dateRegexp.test(value);
  },
};

export const timeField = {
  maxLength: 8,
  apiValue: (value: string) => {
    if (!value) {
      return '';
    }
    const timeWithDate = dayjs(
      `${dayjs().format(API_DATE_FORMAT)}T${value.toUpperCase()}`,
      `${API_DATE_FORMAT}Thh:mm A`,
      true,
    ).toDate();
    // this does not work - dayjs is ALWAYS setting minutes as 01
    // dayjs(formFields[DELIVERY_TIME]).format(API_TIME_FORMAT),
    return `${timeWithDate.getHours()}:${timeWithDate.getMinutes()}`;
  },
  validate(value: string) {
    return dayjs(
      `${dayjs().format(API_DATE_FORMAT)}T${value.toUpperCase()}`,
      `${API_DATE_FORMAT}Thh:mm A`,
      true,
    ).isValid();
  },
};

export const orderTypeField = {
  displayValue: (value: APIOrderType, t: TFunction) => {
    return {
      [APIOrderType.WillCall]: t('order.orderType.willCall'),
      [APIOrderType.FirmOrder]: t('order.orderType.firmOrder'),
    }[value];
  },
};
