import styled from 'styled-components';

export const palette = {
  light100: '#E7E5E4',
  main: '#31887E',
  searchResult: '#ffff00',
  primary50: '#ECF6F5',
  primary100: '#CFE8E5',
  primary200: '#AFD8D4',
  primary500: '#5EB1A9',
  primary600: '#56AAA2',
  primary700: '#4CA198',
  primary800: '#42988F',
  primary900: '#31887E',
  secondary50: '#E6EEF0',
  secondary100: '#C1D5DA',
  secondary500: '#307282',
  secondary600: '#2B6A7A',
  secondary700: '#245F6F',
  secondary800: '#1E5565',
  secondary900: '#134252',
  backgroundTheme: '#FCF9F8',
  backgroundThemeLight: '#FFFFFF',
  textOnDark: '#F3F4F6',
  warning50: '#FFF7ED',
  warning600: '#EA580C',
  warning700: '#C2410C',
  warning800: '#9A3412',
  danger400: '#D9544D',
  danger600: '#CD3029',
  danger700: '#C72923',
  danger800: '#C1221D',
  danger900: '#B61612',
  muted50: '#F0F0EF',
  muted100: '#DAD8D7',
  muted200: '#C1BFBD',
  muted300: '#A8A5A2',
  muted400: '#96918E',
  muted500: '#837E7A',
  muted600: '#7B7672',
  muted700: '#706B67',
  muted800: '#66615D',
  muted900: '#534E4A',
  info50: '#F0F9FF',
  info500: '#0EA5E9',
  info700: '#0369A1',
  info900: '#0C4A6E',
  purple800: '#693082',
  amber500: '#F59E0B',
  violet700: '#6D28D9',
  purple50: '#FAF5FF',
  purple700: '#7E22CE',
  bluegray400: '#94A3B8',
  bluegray600: '#475569',
  truegray300: '#D4D4D4',
  truegray400: '#A3A3A3',
  truegray600: '#525252',
  truegray700: '#404040',
  truegray800: '#262626',
  truegray900: '#171717',
  teal600: '#0D9488',
  darkText: '#1C1917',
  absoluteDark: '#000000',
  info600: '#0284C7',
  rowHighlight: '#c9e9fd',
  rowCancellationSubmittedHighlight: '#FFE4E6',
  rowUnreadHighlight: '#faf5ff',
  error500: '#B02D26',
  error600: '#A92822',
  error800: '#971C17',
  notificationBadge: '#DC2626',
  gray: '#5C6C80',
  white: '#FFFFFF',
  success500: '#577A0E',
  success600: '#4F720C',
  success700: '#46670A',
  success800: '#3C5D08',
  disabledButtonBackground: '#E8E5E4',
  disabledButtonText: '#44403C',
  // order statuses colors
  statusCompleted: '#171717',
  statusDelivering: '#577A0E',
  statusRequested: '#9333EA',
  statusConfirmed: '#31887E',
  statusUnconfirmed: '#EA580C',
  statusCanceled: '#C72923',
  statusText: '#FFFFFF',
  // truck statuses colors
  truckStatusUnavailable: '#C72923',
  truckStatusReady: '#31887E',
  truckStatusLoading: '#0EA5E9',
  truckStatusDelivering: '#0369A1',
  truckStatusReturning: '#0C4A6E',
  truckStatusOnsite: '#693082',
};

export const dimensions = {
  appBarHeight: 6,
  notificationPadding: 1,
  appDrawerWidth: 17.3,
  viewHorizontalPadding: 3.5,
  viewVerticalPadding: 2.5,
};

export const zIndex = {
  mainNavigation: 7,
  orderLeftPane: 4,
  orderChat: 3,
  batchingOrderNumberPopup: 2,
};

export const time = {
  tooltipEnterDelay: 1000,
  tooltipEnterNextDelay: 1000,
};

export const paddings = {
  componentPaddingSmall: '1.6rem',
};

export const popperTimeSx = {
  '& .MuiClock-amButton, & .MuiClock-pmButton': {
    width: '4.2rem',
  },
  '& .MuiClockNumber-root': {
    fontSize: '1.6rem',
  },
};

export const TabsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 0;
  flex-grow: 1;
  > .MuiTabs-root {
    box-shadow: 0 12px 12px -12px rgba(0, 0, 0, 0.1);
  }
`;

export const TabWrapper = styled.span`
  display: flex;
  align-items: baseline;
`;

export const TabTitle = styled.span`
  padding-right: 1.6rem;
  line-height: 1.5;
`;

export const themeConfig = {
  custom: {
    palette,
    dimensions,
    zIndex,
    time,
    paddings,
  },
  palette: {
    primary: {
      main: palette.primary900,
      contrastText: palette.secondary900,
    },
  },
  typography: {
    fontFamily: ['Source Sans Pro', 'serif'].join(','),
    h1: {
      // 3xl
      fontSize: '4.8rem !important',
      color: palette.truegray700,
      fontWeight: 900,
      lineHeight: 1.25,
      textTransform: 'uppercase' as const,
    },
    h2: {
      // 2xl
      fontSize: ' 3.6rem !important',
      fontWeight: 900,
      lineHeight: 1.25,
      textTransform: 'uppercase' as const,
    },
    h3: {
      // xl
      fontSize: '3rem !important',
      color: palette.truegray600,
      fontWeight: 900,
      lineHeight: 1.25,
      textTransform: 'uppercase' as const,
    },
    h4: {
      // lg
      fontSize: '2.4rem !important',
      fontWeight: 900,
      lineHeight: 1.25,
      color: palette.darkText,
      textTransform: 'uppercase' as const,
    },
    h5: {
      // md
      fontSize: '2rem !important',
      color: palette.absoluteDark,
      fontWeight: 900,
      lineHeight: 1.25,
      textTransform: 'uppercase' as const,
    },
    // h6 sm
    subtitle2: {
      fontSize: '1.6rem !important',
      color: palette.absoluteDark,
      fontWeight: 900,
      lineHeight: 1.25,
      textTransform: 'uppercase' as const,
    },
  },
  ButtonUnstyled: {
    styleOverrides: {
      root: {
        background: 'none',
      },
    },
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          maxWidth: '100%',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: palette.primary900,
          fontSize: '1.6rem',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: '1.6rem',
          '& input::placeholder': {
            color: palette.gray,
            opacity: 1,
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          borderColor: palette.muted200,
          color: '#5C6C80',
          borderRadius: '0.4rem',
          padding: '0.8rem',
        },
      },
    },
    MuiClock: {
      pmButton: {
        styleOverrides: {
          width: '4.2rem',
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          color: '#5C6C80',
        },
      },
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        label: {
          fontSize: '1.6rem',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: '1.6rem',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: '2.4rem',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '1.6rem',
        },
        sizeMedium: {
          height: '3.6rem',
          fontSize: '1.4rem',
        },
        sizeLarge: {
          height: '4.6rem',
        },
        containedPrimary: {
          lineHeight: 1.25,
          fontWeight: 900,
          color: palette.secondary900,
          textTransform: 'uppercase' as const,
          backgroundColor: palette.primary500,
          boxShadow: 'none',
          '&:hover': {
            color: palette.secondary500,
            backgroundColor: palette.primary200,
            boxShadow: 'none',
          },
          '&:disabled': {
            borderColor: palette.disabledButtonBackground,
            backgroundColor: palette.disabledButtonBackground,
            color: palette.disabledButtonText,
            opacity: 0.5,
          },
        },
        outlinedSecondary: {
          lineHeight: 1.25,
          fontWeight: 900,
          textTransform: 'uppercase' as const,
          color: palette.secondary500,
          borderColor: palette.primary500,
          borderWidth: 2,
          '&:hover': {
            backgroundColor: palette.primary200,
            borderColor: palette.primary200,
            borderWidth: 2,
          },
          '&:disabled': {
            borderWidth: 2,
            borderColor: palette.disabledButtonBackground,
            backgroundColor: palette.disabledButtonBackground,
            color: palette.disabledButtonText,
            opacity: 0.5,
          },
        },
        textPrimary: {
          fontWeight: 900,
          color: palette.primary900,
          fontSize: '1.4rem',
          textTransform: 'uppercase' as const,
          textDecoration: 'underline',
          backgroundColor: 'none',
          '&:hover': {
            textDecoration: 'underline',
            backgroundColor: 'transparent',
            color: palette.primary500,
          },
          '&:focus': {
            color: palette.primary600,
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: '1.6rem',
        },
      },
    },
    MuiTooltip: {
      // TODO: find out why it's not working
      placement: 'top',
      enterDelay: time.tooltipEnterDelay,
      enterNextDelay: time.tooltipEnterNextDelay,
      styleOverrides: {
        tooltip: {
          fontSize: '1.4rem',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: '1.4rem',
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        li: {
          fontSize: '2rem',
          lineHeight: 1.25,
          fontWeight: 900,
          textTransform: 'uppercase' as const,
          color: palette.secondary600,
          '&:hover': {
            textDecoration: 'none',
          },
        },
        separator: {
          margin: '0 1.25rem 0 0',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          fontSize: '1.4rem',
        },
        standardInfo: {
          backgroundColor: palette.primary50,
        },
      },
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          fontSize: '1.6rem',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        filled: {
          borderRadius: '0.2rem',
          height: '3.2rem',
          fontSize: '1.2rem',
          fontWeight: 700,
          lineHeight: 1.5,
          textTransform: 'uppercase' as const,
          color: palette.statusText,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: palette.absoluteDark,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderWidth: '2px',
          borderStyle: 'solid',
          borderColor: palette.primary500,
          background: palette.backgroundThemeLight,
          padding: '2rem 2.5rem',
          minWidth: '27rem',
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
          borderRadius: '1rem',
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderWidth: '2px',
          borderStyle: 'solid',
          borderColor: palette.primary500,
          background: palette.backgroundThemeLight,
          borderRadius: '1rem',
          display: 'flex',
          flexDirection: 'column' as const,
          // plz set overflow directly in component, not in theme
          // dropdowns must have no overflow set
          // popovers with close button must have overflow: visible
          // example: PaperProps={{ sx: { overflow: 'visible' } }}
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        indeterminate: {
          margin: 'auto',
        },
      },
    },
  },
};
