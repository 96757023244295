import React, { useEffect } from 'react';

import { InsightBlock, SvgIcon } from 'components';

import { useSelector, useTranslation } from 'third-party';

import { useMixTypesList } from 'modules/mixTypesList';

import { mixTypesSelectors } from 'selectors';

type Props = {
  isActive?: boolean;
  onClick: () => void;
};

export const LeastOrderedMixesWidget: React.FC<Props> = ({
  onClick,
  isActive,
}) => {
  const { t } = useTranslation();

  const { loadLeastUsedMixTypes } = useMixTypesList();

  const leastUsedMixTypesLoaded = useSelector(
    mixTypesSelectors.leastUsedMixTypesLoaded,
  );
  const loadLeastUsedMixTypesPending = useSelector(
    mixTypesSelectors.loadLeastUsedMixTypesPending,
  );
  const leastUsedMixTypesCount = useSelector(
    mixTypesSelectors.leastUsedMixTypesCount,
  );

  useEffect(() => {
    if (!leastUsedMixTypesLoaded) {
      loadLeastUsedMixTypes();
    }
  }, [loadLeastUsedMixTypes, leastUsedMixTypesLoaded]);

  const notSufficientData =
    leastUsedMixTypesLoaded && leastUsedMixTypesCount === 0;

  return (
    <InsightBlock
      isActive={isActive}
      isLoading={loadLeastUsedMixTypesPending || !leastUsedMixTypesLoaded}
      title={t('plantSettings.insightsMixTypesLeastTitle')}
      description={
        notSufficientData
          ? t('plantSettings.insightsMixTypesLeastDefaultText')
          : t('plantSettings.insightsMixTypesLeastDescription', {
              count: leastUsedMixTypesCount,
            })
      }
      linkLabel={t('plantSettings.insightsViewList')}
      onClick={notSufficientData ? undefined : onClick}
      icon={
        <SvgIcon icon="insights-least-icon" height="3.6rem" width="3.6rem" />
      }
    />
  );
};
