import React, { useCallback, useEffect, useRef } from 'react';

import styled from 'styled-components';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import { APIUsage } from '@cd3p/core/types/api';
import { subscribeActionsAfter } from '@cd3p/core/utils/redux';
import { getSuccessActionName } from '@cd3p/core/utils/sra/reducers';
import { CircularProgress, IconButton, Stack, Tooltip } from 'components';

import {
  ListItem,
  ListItemLabel,
  ListItemUsage,
  ListWrapper,
  LoaderListItem,
} from './commonHelpers';

import { _, useBottomScrollListener, useTranslation } from 'third-party';

import {
  INFINITE_SCROLL_BOTTOM_OFFSET_PX,
  INFINITE_SCROLL_DEBOUNCE_TIME_MS,
  TOOLTIP_APPEAR_DELAY,
} from 'constants/common';

import { DELETE_ADDITIVE_TYPE_ACTION } from 'modules/additiveTypesList';
import { DELETE_MIX_TYPE_ACTION } from 'modules/mixTypesList';

const EditIcon = styled(EditOutlinedIcon)`
  color: ${props => props.theme.custom.palette.secondary600};
  font-size: 1.8rem;
`;

const DeleteIcon = styled(DeleteOutlineIcon)`
  color: ${props => props.theme.custom.palette.secondary600};
  font-size: 1.8rem;
`;

type Props = {
  items: APIUsage[];
  onEditClick: (id: number) => void;
  onDeleteClick?: (id: number) => void;
  dataTotalCount: number;
  updateData: () => void;
  loadMoreData?: () => void;
  loadMoreDataPending?: boolean;
};

export const MostLeastUsedCommonPanel: React.FC<Props> = ({
  items,
  onEditClick,
  onDeleteClick,
  dataTotalCount,
  updateData,
  loadMoreData,
  loadMoreDataPending,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    return subscribeActionsAfter(
      [
        getSuccessActionName(DELETE_MIX_TYPE_ACTION),
        getSuccessActionName(DELETE_ADDITIVE_TYPE_ACTION),
      ],
      ({ payload }: { payload: APIUsage }) => {
        if (items.find(it => it.id === payload.id)) {
          updateData();
        }
      },
    );
  }, [items, updateData]);

  const hasListLoadMoreButton = dataTotalCount > items.length;

  // creating ref for always having the actual
  // value inside `onScrollToBottom` callback,
  const hasListLoadMoreButtonRef = useRef(hasListLoadMoreButton);
  hasListLoadMoreButtonRef.current = hasListLoadMoreButton;

  const onScrollToBottom = useCallback(() => {
    if (
      loadMoreData &&
      hasListLoadMoreButtonRef.current &&
      !loadMoreDataPending
    ) {
      loadMoreData();
    }
  }, [loadMoreDataPending, loadMoreData]);

  const onScrollToBottomDebounced = _.throttle(
    onScrollToBottom,
    INFINITE_SCROLL_DEBOUNCE_TIME_MS,
  );

  const scrollRef = useBottomScrollListener<HTMLDivElement>(
    onScrollToBottomDebounced,
    {
      offset: INFINITE_SCROLL_BOTTOM_OFFSET_PX,
      triggerOnNoScroll: false,
    },
  );

  return (
    <ListWrapper ref={scrollRef}>
      {items.map(item => (
        <ListItem key={item?.id}>
          <Tooltip
            placement="top-start"
            disableInteractive
            enterDelay={TOOLTIP_APPEAR_DELAY}
            title={item.name}
          >
            <ListItemLabel variant="body2">{item.name}</ListItemLabel>
          </Tooltip>
          <ListItemUsage>
            {t('plantSettings.insightsUsageOrders', { count: item.usage })}
          </ListItemUsage>
          <Stack direction="row">
            <IconButton onClick={() => onEditClick(item.id)}>
              <EditIcon />
            </IconButton>
            {onDeleteClick && (
              <IconButton onClick={() => onDeleteClick(item.id)}>
                <DeleteIcon />
              </IconButton>
            )}
          </Stack>
        </ListItem>
      ))}
      {hasListLoadMoreButton && (
        <LoaderListItem key="loader">
          <CircularProgress size="2rem" />
        </LoaderListItem>
      )}
    </ListWrapper>
  );
};
