import React, { useCallback } from 'react';

import styled, { useTheme } from 'styled-components';

import { APICallBackFormStatus, APIOrder } from '@cd3p/core/types/api';
import { multilineTextField } from '@cd3p/core/utils/fields';
import {
  FormField,
  Grid,
  LoadingButton,
  ReadOnlyField,
  Typography,
} from 'components';
import { useHandleApiResult } from 'hooks/useHandleApiResult';

import { useForm, useSelector, useTranslation } from 'third-party';

import { useOrder } from 'modules/order';

import { appSelectors, orderSelectors } from 'selectors';

import { OrderContentWrapper } from 'features/OrderView/orderViewHelpers';

const CancelButton = styled(LoadingButton)`
  width: 'auto';
  margin-right: 1rem;
  font-weight: 900;
  border-width: 0.2rem;
  &:hover {
    border-width: 0.2rem;
  }
`;

const ConfirmButton = styled(LoadingButton)`
  min-width: 13rem;
`;

enum FormFields {
  Quantity = 'quantity',
  Details = 'details',
}
const formDefaultValues = {
  [FormFields.Quantity]: '',
  [FormFields.Details]: '',
};

type FormData = typeof formDefaultValues;

type Props = {
  order: APIOrder;
};

export const OrderCallback: React.FC<Props> = ({ order }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { updateOrderCallback } = useOrder();
  const handleApiResult = useHandleApiResult();

  const updateOrderCallbackPending = useSelector(
    orderSelectors.updateOrderCallbackPending,
  );
  const providerName = useSelector(appSelectors.providerName);

  const isCallbackSubmitted =
    order.callBackFormStatus === APICallBackFormStatus.CallbackSubmitted;
  const methods = useForm<FormData>({
    defaultValues: formDefaultValues,
    mode: 'onChange',
  });

  const {
    control,
    handleSubmit,
    formState: { isValid, errors },
  } = methods;

  const handleCancelCallback = useCallback(async () => {
    await handleApiResult(
      async () =>
        await updateOrderCallback(order.id, {
          callBackFormStatus: APICallBackFormStatus.NoCallback,
        }),
      ({ showBaseToast }) => {
        showBaseToast(t('orderCallback.callBackClosedToast'));
      },
    );
  }, [handleApiResult, order, t, updateOrderCallback]);

  const handleSendCallback = useCallback(
    async (formData: FormData) => {
      await handleApiResult(
        async () =>
          await updateOrderCallback(order.id, {
            callBackFormStatus: APICallBackFormStatus.CallbackSubmitted,
            callbackFormVolume: Number(formData[FormFields.Quantity]),
            additionalCallbackFormDetails: (
              formData[FormFields.Details] || ''
            ).trim(),
          }),
        ({ showBaseToast }) => {
          showBaseToast(t('orderCallback.callBackSubmittedToast'));
        },
      );
    },
    [handleApiResult, order, t, updateOrderCallback],
  );

  return (
    <OrderContentWrapper>
      <Grid container item direction={'column'} flexWrap={'nowrap'}>
        <Typography variant="h5">
          {isCallbackSubmitted
            ? t('orderCallback.submitted.title')
            : t('orderCallback.title')}
        </Typography>
        <Typography
          color={theme.custom.palette.gray}
          variant="body1"
          marginTop="0.8rem"
        >
          {isCallbackSubmitted
            ? t('orderCallback.submitted.description')
            : t('orderCallback.description', { providerName })}
        </Typography>
        <Grid
          md={6}
          direction={'column'}
          mt={isCallbackSubmitted ? '2rem' : undefined}
        >
          {isCallbackSubmitted ? (
            <ReadOnlyField
              key={FormFields.Quantity}
              value={t('orderCallback.submitted.quantity', {
                quantity: order.callbackFormVolume,
              })}
              label={t('orderCallback.quantity.label')}
            />
          ) : (
            <FormField
              fieldName={FormFields.Quantity}
              fieldError={errors[FormFields.Quantity]}
              label={t('orderCallback.quantity.label')}
              placeholder={t('orderCallback.quantity.placeholder')}
              requiredErrorMessage={t('common.form.emptyFieldError')}
              control={control}
              type="number"
              isRequired
              showIsRequiredMark={true}
              isDisabled={updateOrderCallbackPending}
            />
          )}
          {isCallbackSubmitted ? (
            order.additionalCallbackFormDetails ? (
              <ReadOnlyField
                key={FormFields.Details}
                value={order.additionalCallbackFormDetails}
                label={t('orderCallback.details.label')}
              />
            ) : null
          ) : (
            <FormField
              isDisabled={updateOrderCallbackPending}
              fieldName={FormFields.Details}
              label={t('orderCallback.details.label')}
              fieldError={errors[FormFields.Details]}
              requiredErrorMessage={t('common.form.emptyFieldError')}
              control={control}
              placeholder={t('orderCallback.details.placeholder')}
              multiline
              isRequired={false}
              maxLength={multilineTextField.maxLength}
            />
          )}
        </Grid>
        {!isCallbackSubmitted && (
          <Grid container margin={'3.6rem 0 2rem'} gap="1rem">
            <CancelButton
              color="primary"
              variant="outlined"
              size="large"
              disabled={updateOrderCallbackPending}
              loading={updateOrderCallbackPending}
              onClick={handleCancelCallback}
            >
              {t('orderCallback.noCallback')}
            </CancelButton>

            <ConfirmButton
              color="primary"
              variant="contained"
              size="large"
              onClick={handleSubmit(handleSendCallback)}
              disabled={!isValid || updateOrderCallbackPending}
              loading={updateOrderCallbackPending}
            >
              {t('orderCallback.sendCallBack')}
            </ConfirmButton>
          </Grid>
        )}
      </Grid>
    </OrderContentWrapper>
  );
};
