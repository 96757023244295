import React from 'react';

import styled from 'styled-components';

import { emailField } from '@cd3p/core/utils/fields';
import {
  Button,
  FormField,
  LoadingButton,
  SplitView,
  Typography,
} from 'components';
import { useHandleApiResult } from 'hooks/useHandleApiResult';

import { useSelector } from 'third-party';
import { FieldError, useForm, useNavigate, useTranslation } from 'third-party';

import { loginUrl } from 'constants/url';

import { useUser } from 'modules/user';

import { userSelectors } from 'selectors';

const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 8.3rem;
`;

export const Title = styled(Typography)`
  color: ${props => props.theme.custom.palette.secondary500};
  font-size: 3.6rem;
  font-weight: 900;
  text-align: center;
  margin-bottom: 3.8rem;
`;

export const Description = styled(Typography)`
  font-size: 1.8rem;
  font-weight: 400;
  padding-top: 0.8rem;
  color: ${props => props.theme.custom.palette.muted800};
`;

const Form = styled.form`
  display: flex;
  flex-flow: column;
  width: 42.8rem;
`;

const SubmitButton = styled(LoadingButton)`
  margin: 4rem 0 1rem 0;
`;

enum FormFields {
  Email = 'email',
}

type FormData = {
  [FormFields.Email]: string;
};

const formDefaultValues = {
  [FormFields.Email]: '',
};

export const ForgotPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { requestForgotPassword } = useUser();

  const requestForgotPasswordPending = useSelector(
    userSelectors.requestForgotPasswordPending,
  );

  const methods = useForm<FormData>({
    defaultValues: formDefaultValues,
  });
  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isSubmitted },
  } = methods;

  const isFormPending = requestForgotPasswordPending;

  const handleApiResult = useHandleApiResult();

  const onSubmit = (data: FormData) => {
    handleApiResult(
      () => requestForgotPassword(data[FormFields.Email].trim()),
      ({ showBaseToast }) => {
        showBaseToast(t('forgotPassword.successToastMessage'));
        navigate(loginUrl());
      },
      ({ showErrorToast }) => {
        showErrorToast('', { id: 'forgot_password_error' });
      },
    );
  };

  return (
    <SplitView>
      <Wrapper>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Title variant="h2">{t('forgotPassword.title')}</Title>
          <Description>{t('forgotPassword.description')}</Description>
          <FormField
            fieldName={FormFields.Email}
            fieldError={errors[FormFields.Email] as FieldError}
            label={t('common.form.email.label')}
            placeholder={t('common.form.email.placeholder')}
            requiredErrorMessage={t('common.form.emptyFieldError')}
            control={control}
            maxLength={emailField.maxLength}
            isRequired
            showIsRequiredMark={false}
            isDisabled={isFormPending}
            rules={{
              validate: {
                incorrectEmail: (value: string) =>
                  emailField.validate(value) ||
                  t('common.form.email.error.invalid'),
              },
            }}
          />
          <SubmitButton
            type="submit"
            variant="contained"
            size="medium"
            color="primary"
            disabled={isFormPending || (!isValid && isSubmitted)}
            loading={isFormPending}
          >
            {t('forgotPassword.submitButtonLabel')}
          </SubmitButton>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => navigate(loginUrl())}
            disabled={isFormPending}
          >
            {t('common.back')}
          </Button>
        </Form>
      </Wrapper>
    </SplitView>
  );
};
