import React, { useState } from 'react';

import NotificationAddOutlinedIcon from '@mui/icons-material/NotificationAddOutlined';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';

import { APIOrder } from '@cd3p/core/types/api';
import { IconButton, Tooltip } from 'components';
import { useChangeOrderSubscription } from 'hooks/useChangeOrderSubscription';
import { useHandleApiResult } from 'hooks/useHandleApiResult';

import { useTranslation } from 'third-party';

import { useOrder } from 'modules/order';

type Props = {
  order: APIOrder;
  onSuccess?: (shouldSubscribe: boolean) => void;
};

export const SubscribeButton: React.FC<Props> = ({ order, onSuccess }) => {
  const { t } = useTranslation();
  const { toggleOrderSubscription } = useOrder();

  const shouldSubscribe = !order?.isUserSubscribed;

  const [subscriptionPending, setSubscriptionPending] = useState(false);

  const handleApiResult = useHandleApiResult();

  const changeOrderSubscription = useChangeOrderSubscription();
  const onSubscriptionClick = async () => {
    if (subscriptionPending) return;
    handleApiResult(
      async () => {
        setSubscriptionPending(true);
        const result = await toggleOrderSubscription(order.id, shouldSubscribe);
        changeOrderSubscription(result.payload, shouldSubscribe);
        setSubscriptionPending(false);
        return result;
      },
      ({ showBaseToast }) => {
        onSuccess?.(shouldSubscribe);
        const message = shouldSubscribe
          ? t('order.subscription.success.message')
          : t('order.unsubscription.success.message');

        showBaseToast(message);
      },
    );
  };

  return (
    <Tooltip
      title={
        shouldSubscribe
          ? t('ordersList.table.subscribeTooltip')
          : t('ordersList.table.unsubscribeTooltip')
      }
    >
      <IconButton
        onClick={event => {
          event.stopPropagation();
          onSubscriptionClick();
        }}
      >
        {shouldSubscribe ? (
          <NotificationAddOutlinedIcon color="primary" />
        ) : (
          <NotificationsActiveIcon color="primary" />
        )}
      </IconButton>
    </Tooltip>
  );
};
