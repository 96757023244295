import React, { useEffect, useState } from 'react';

import styled, { useTheme } from 'styled-components';

import { APIOrder, APIOrderStatus, APIOrderType } from '@cd3p/core/types/api';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  LoadingButton,
  Trans,
  Typography,
} from 'components';

import { useSelector, useTranslation } from 'third-party';

import { NotificationSeverity, useNotifications } from 'modules/notifications';
import { useOrder } from 'modules/order';

import { orderSelectors } from 'selectors';

const CancelButton = styled(LoadingButton)`
  width: 15rem;
  margin-right: 1rem;
`;
const ApproveButton = styled(LoadingButton)`
  min-width: 20rem;
`;

const FormControlLabelStyled = styled(FormControlLabel)`
  text-transform: uppercase;
  color: ${props => props.theme.custom.palette.darkText};
  margin-top: 2rem;
  > * {
    font-weight: 700;
    font-size: 1.4rem;
  }
`;

type Props = {
  order: APIOrder;
};

export const RequestedOrder: React.FC<Props> = ({ order }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const { addNotification } = useNotifications();
  const { changeOrderStatus } = useOrder();

  const updateOrderPending = useSelector(orderSelectors.updateOrderPending);

  const [confirmUponApproval, setConfirmUponApproval] = useState(
    order?.orderType === APIOrderType.FirmOrder,
  );
  const [isConfirmUponApprovalTouched, setConfirmUponApprovalTouched] =
    useState(false);
  const [isApprovalInProgress, setIsApprovalInProgress] = useState(false);
  const [isCancelingInProgress, setIsCancelingInProgress] = useState(false);

  useEffect(() => {
    if (!isConfirmUponApprovalTouched) {
      setConfirmUponApproval(order?.orderType === APIOrderType.FirmOrder);
    }
  }, [order?.orderType, isConfirmUponApprovalTouched]);

  const onCancel = async () => {
    setIsCancelingInProgress(true);
    const result = await changeOrderStatus(
      order?.id || 0,
      APIOrderStatus.Declined,
    );
    if (result.error) {
      addNotification({
        severity: NotificationSeverity.Error,
        message: t('common.generalError'),
      });
    } else {
      addNotification({
        message: (
          <Trans i18nKey="order.details.cancelNotificationText">
            Order {{ orderName: order?.orderName }} has been declined
          </Trans>
        ),
      });
    }
    setIsCancelingInProgress(false);
  };

  const onApprove = async () => {
    setIsApprovalInProgress(true);
    const status = confirmUponApproval
      ? APIOrderStatus.Confirmed
      : APIOrderStatus.Unconfirmed;
    const result = await changeOrderStatus(order?.id || 0, status);
    if (result.error) {
      addNotification({
        severity: NotificationSeverity.Error,
        message: t('common.generalError'),
      });
    } else {
      addNotification({
        message: (
          <Trans i18nKey="order.details.approveNotificationText">
            Order {{ orderName: order?.orderName }} has been approved
          </Trans>
        ),
      });
    }
    setIsApprovalInProgress(false);
  };

  return (
    <Grid item>
      <Grid item>
        <Typography variant="h5" color={theme.custom.palette.darkText}>
          {t('order.details.requested.title')}
        </Typography>
        <Typography
          variant="body1"
          marginTop="0.8rem"
          color={theme.custom.palette.gray}
        >
          {t('order.details.requested.message')}
        </Typography>
        <Grid container marginTop="2rem" gap="1rem">
          <CancelButton
            color="secondary"
            variant="outlined"
            size="large"
            disabled={updateOrderPending}
            loading={isCancelingInProgress}
            onClick={onCancel}
          >
            {t('common.decline')}
          </CancelButton>

          <ApproveButton
            color="primary"
            variant="contained"
            size="large"
            onClick={onApprove}
            disabled={updateOrderPending}
            loading={isApprovalInProgress}
          >
            {order?.hasChangesOnRequestedState
              ? t('order.details.approveWithChangesButtonLabel')
              : t('common.approve')}
          </ApproveButton>
        </Grid>
        <FormControlLabelStyled
          control={
            <Checkbox
              disabled={updateOrderPending}
              checked={confirmUponApproval}
              onChange={() => {
                setConfirmUponApproval(!confirmUponApproval);
                setConfirmUponApprovalTouched(true);
              }}
            />
          }
          label={t('order.details.requested.checkboxLabel')}
        />
      </Grid>
    </Grid>
  );
};
