import React, { useMemo } from 'react';

import styled from 'styled-components';

import { Tab, Tabs, TabsBottomLine, Typography } from 'components';

import { AdditiveTypesView } from './AdditiveTypeTab/AdditiveTypesView';
import { MixTypesView } from './MixTypeTab/MixTypesView';

import {
  Navigate,
  Route,
  Routes,
  useMatch,
  useNavigate,
  useTranslation,
} from 'third-party';

import {
  ADDITIVES_PATH,
  MIX_TYPES_PATH,
  PLANTS_PATH,
  productsAdditivesUrl,
  productsMixTypesUrl,
  productsPlantsUrl,
  productsUrl,
} from 'constants/url';

import { PlantsView } from 'features/PlantSettings/PlantsTab/PlantsView';

import { TabTitle, TabWrapper, TabsWrapper } from 'styles/theme';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 0;
  overflow: hidden;
  padding: ${props => props.theme.custom.dimensions.viewVerticalPadding}rem 0 0;
`;

const HeaderContainer = styled.div`
  padding: 0 ${props => props.theme.custom.dimensions.viewHorizontalPadding}rem;
`;

const Header = styled(Typography)`
  color: ${props => props.theme.custom.palette.secondary500};
  margin-bottom: 2rem;
`;

const StyledTab = styled(Tab)`
  flex-grow: initial;
  padding: 0 5rem;
`;

enum PlantSettingsTabs {
  Plants = 'plants',
  MixTypes = 'mixTypes',
  Additives = 'additivesTypes',
}

export const ProductsView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const matchIndexUrl = useMatch(productsUrl());
  const matchPlantsUrl = useMatch(productsPlantsUrl());
  const matchMixTypesUrl = useMatch(productsMixTypesUrl());
  const matchAdditivesTypesUrl = useMatch(productsAdditivesUrl());

  const currentTab = useMemo(() => {
    switch (true) {
      case !!matchPlantsUrl:
        return PlantSettingsTabs.Plants;
      case !!matchAdditivesTypesUrl:
        return PlantSettingsTabs.Additives;
      case !!matchMixTypesUrl || !!matchIndexUrl:
      default:
        return PlantSettingsTabs.MixTypes;
    }
  }, [matchPlantsUrl, matchAdditivesTypesUrl, matchMixTypesUrl, matchIndexUrl]);

  const tabs = useMemo(
    () => [
      {
        title: t('plantSettings.mixTypesTab'),
        value: PlantSettingsTabs.MixTypes,
        onClick: () => navigate(productsMixTypesUrl(), { replace: true }),
      },
      {
        title: t('plantSettings.additivesTab'),
        value: PlantSettingsTabs.Additives,
        onClick: () => navigate(productsAdditivesUrl(), { replace: true }),
      },
      {
        title: t('plantSettings.plants'),
        value: PlantSettingsTabs.Plants,
        onClick: () => navigate(productsPlantsUrl(), { replace: true }),
      },
    ],
    [t, navigate],
  );

  return (
    <Wrapper>
      <HeaderContainer>
        <Header variant="h4">{t('plantSettings.title')}</Header>
      </HeaderContainer>
      <TabsWrapper>
        <Tabs value={currentTab}>
          {tabs.map(tab => {
            return (
              <StyledTab
                key={tab.title}
                value={tab.value}
                label={
                  <TabWrapper>
                    <TabTitle>{tab.title}</TabTitle>
                  </TabWrapper>
                }
                onClick={tab.onClick}
              />
            );
          })}
          <TabsBottomLine />
        </Tabs>
        <Routes>
          <Route path={MIX_TYPES_PATH} element={<MixTypesView />} />
          <Route path={ADDITIVES_PATH} element={<AdditiveTypesView />} />
          <Route path={PLANTS_PATH} element={<PlantsView />} />
        </Routes>
        {matchIndexUrl && <Navigate to={productsMixTypesUrl()} replace />}
      </TabsWrapper>
    </Wrapper>
  );
};
