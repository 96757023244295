import React, { useCallback } from 'react';

import styled from 'styled-components';

import { APICompany, APIResponse, APIUserType } from '@cd3p/core/types/api';
import { Stack } from '@mui/material';
import {
  Button,
  FormField,
  LoadingButton,
  PopoverCloseButton,
  Trans,
  TypeaheadFormField,
  Typography,
} from 'components';
import { useHandleApiResult } from 'hooks/useHandleApiResult';

import { FieldError, useForm, useSelector, useTranslation } from 'third-party';

import { useCache } from 'modules/cache';
import { useContractorsList } from 'modules/contractorsList';

import { contractorsListSelectors } from 'selectors';

import { TypeaheadOption } from 'types/app';

const FormWrapper = styled.form`
  position: relative;
  width: 50rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-height: 0;
  flex-grow: 1;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  padding: 2.2rem 2.3rem 0;
  z-index: 1;
`;

const Title = styled(Typography)`
  line-height: 100%;
  color: ${props => props.theme.custom.palette.primary900};
  margin-bottom: 1.5rem;
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1.6rem;
  margin-bottom: 2.3rem;
`;

const Content = styled(Stack)`
  flex-grow: 1;
  padding: 2.2rem 2.3rem 2.7rem;
`;

enum AddFormFields {
  Name = 'name',
  Address = 'address',
}

type AddForm = {
  [AddFormFields.Name]: string;
  [AddFormFields.Address]: TypeaheadOption | null;
};

const defaultValues: AddForm = {
  [AddFormFields.Name]: '',
  [AddFormFields.Address]: null,
};

type Props = {
  onClosePopup?: () => void;
  onSuccess?: (result: APICompany) => void;
};

export const AddContractorPopup: React.FC<Props> = ({
  onClosePopup,
  onSuccess,
}) => {
  const { t } = useTranslation();

  const { loadAddress } = useCache();

  const { createContractor } = useContractorsList();

  const isFormPending = useSelector(
    contractorsListSelectors.createContractorPending,
  );

  const {
    setValue,
    handleSubmit,
    control,
    formState: { isValid, isDirty, errors },
  } = useForm<AddForm>({
    defaultValues,
  });

  const loadAddressOptions = async (value: string) => {
    const result: APIResponse<string[]> = await loadAddress(value);
    let options: TypeaheadOption[] = [];
    if (!result.error) {
      options = result.payload.map(it => ({
        label: it,
        value: it,
      }));
    }
    return {
      options,
    };
  };

  const handleApiResult = useHandleApiResult();

  const onSubmit = async (data: AddForm) => {
    if (!isValid) {
      return;
    }
    handleApiResult<APICompany>(
      () =>
        createContractor({
          name: data[AddFormFields.Name].trim(),
          address: data[AddFormFields.Address]?.value || null,
          companyType: APIUserType.Contractor,
        }),
      ({ result, showBaseToast }) => {
        showBaseToast(
          <Trans
            i18nKey="contractors.addCustomerPopup.addSuccessMessage"
            shouldUnescape
          >
            "{{ companyName: result.payload.name }}" was successfully created.
          </Trans>,
        );
        onClosePopup?.();
        onSuccess?.(result.payload);
      },
    );
  };

  const onSelectAddress = useCallback(
    (option: TypeaheadOption | null) => {
      setValue(AddFormFields.Address, option, {
        shouldValidate: true,
        shouldDirty: true,
      });
    },
    [setValue],
  );

  return (
    <>
      <PopoverCloseButton onClose={() => onClosePopup?.()} />
      <FormWrapper onSubmit={handleSubmit(onSubmit)}>
        <Header>
          <Title variant="h3">{t('customers.addCustomerPopup.title')}</Title>
          <ActionButtons>
            <Button
              disabled={isFormPending}
              variant="outlined"
              color="secondary"
              onClick={() => onClosePopup?.()}
            >
              {t('common.cancel')}
            </Button>
            <LoadingButton
              variant="contained"
              type="submit"
              loading={isFormPending}
              disabled={!isValid || !isDirty}
            >
              {t('customers.addCustomerButton')}
            </LoadingButton>
          </ActionButtons>
        </Header>
        <Content>
          <FormField
            fieldName={AddFormFields.Name}
            label={t('customers.addCustomerPopup.companyNameLabel')}
            placeholder={t('customers.addCustomerPopup.companyNamePlaceholder')}
            requiredErrorMessage={t('common.form.emptyFieldError')}
            control={control}
            maxLength={200}
            isRequired
            isDisabled={isFormPending}
          />
          <FormField
            fieldName={AddFormFields.Address}
            label={t('customers.addCustomerPopup.companyAddressLabel')}
            fieldError={errors[AddFormFields.Address] as FieldError}
            control={control}
            isRequired={false}
            render={() => (
              <TypeaheadFormField
                placeholder={t(
                  'customers.addCustomerPopup.companyAddressPlaceholder',
                )}
                loadOptions={loadAddressOptions}
                onChange={onSelectAddress}
                isDisabled={isFormPending}
              />
            )}
          />
        </Content>
      </FormWrapper>
    </>
  );
};
