import { useMemo } from 'react';

import { simplifyBuilder } from '@cd3p/core';
import {
  CoreUser,
  coreUserMethods,
  coreUserState,
} from '@cd3p/core/modules/user';

import { bindActionCreators, useDispatch } from 'third-party';

export type UserState = CoreUser;

export const userState: UserState = {
  ...coreUserState,
};

export const userBuilder = simplifyBuilder(userState, {});

const requestForgotPassword = userBuilder.createServerAction(
  coreUserMethods.requestForgotPassword,
);
const fetchCurrentUser = userBuilder.createServerAction(
  coreUserMethods.fetchCurrentUser,
);
const updateUserPassword = userBuilder.createServerAction(
  coreUserMethods.updateUserPassword,
);
const validateTempToken = userBuilder.createServerAction(
  coreUserMethods.validateTempToken,
);

export const useUser = () => {
  const dispatch = useDispatch();

  return useMemo(
    () =>
      bindActionCreators(
        {
          requestForgotPassword: requestForgotPassword.bind(null),
          fetchCurrentUser: fetchCurrentUser.bind(null),
          updateUserPassword: updateUserPassword.bind(null),
          validateTempToken: validateTempToken.bind(null),
        },
        dispatch,
      ),
    [dispatch],
  );
};

export const userReducer = userBuilder.getReducers();
