import styled from 'styled-components';

import { TableSortLabelMUI } from 'components';

export const TableSortLabel = styled(TableSortLabelMUI)`
  font-weight: 900;
  font-size: 2rem;
  line-height: 1.25;
  text-decoration: underline;
  text-transform: uppercase;
  &.Mui-active,
  &:hover {
    color: ${props => props.theme.custom.palette.secondary900};
    .MuiTableSortLabel-icon {
      opacity: 1;
    }
  }
  color: ${props => props.theme.custom.palette.primary900};
  .MuiTableSortLabel-icon {
    fill: ${props => props.theme.custom.palette.secondary900};
    stroke: ${props => props.theme.custom.palette.secondary900};
  }
`;
