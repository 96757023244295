import React from 'react';

import styled, { useTheme } from 'styled-components';

import { APIApplicationVersion } from '@cd3p/core/types/api';
import { Modal, Typography } from 'components';

import { useTranslation } from 'third-party';

const Title = styled(Typography)`
  line-height: 100%;
  color: ${props => props.theme.custom.palette.primary900};
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
`;

type Props = {
  releaseNotes: APIApplicationVersion[];
};

export const ReleaseNotes: React.FC<Props> = ({ releaseNotes }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Modal
      open
      header={t('releaseNotes.title')}
      closeEnabled={false}
      onConfirm={() => 0}
      blurBackgroundSize={1}
      cancelLabel={t('releaseNotes.update')}
      isDisabled={false}
      isPending={false}
      onClose={() => {
        window.location.href =
          window.location.href.split('?')[0] +
          '?updated=' +
          new Date().getTime();
      }}
    >
      {releaseNotes.map(it => (
        <>
          <Title variant="h5">{it.version}</Title>
          <Typography color={theme.custom.palette.gray}>
            {it.notes.split('\n').map((line, index) => (
              <React.Fragment key={it.version + index}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </Typography>
        </>
      ))}
    </Modal>
  );
};
