import { useMemo } from 'react';

// import { APIOrder, APIOrdersList } from '@cd3p/core/types/api';
import { APIAvailableAdditiveModelSearchResult } from '@cd3p/core/types/api';
import { simplifyBuilder } from '@cd3p/core/utils/sra';

import {
  ConcreteCategoriesState,
  concreteCategoriesDefaultState,
  loadConcreteCategories,
  loadMoreConcreteCategories,
  resetConcreteCategories,
  updateConcreteCategories,
  updateConcreteCategoriesFilters,
  updateConcreteCategoriesPagination,
  updateConcreteCategoriesSorting,
} from './commonConcreteCategories';

import { bindActionCreators, useDispatch, useSelector } from 'third-party';

import {
  contractorAdditiveEndpoint,
  loadAdditivesCategoriesByCompanyEndpoint,
} from 'constants/endpoints';

import { additivesCategoriesSelectors, appSelectors } from 'selectors';

export type AdditivesCategoriesListState =
  ConcreteCategoriesState<APIAvailableAdditiveModelSearchResult> & {
    loadAdditivesCategoriesPending: boolean;
    loadMoreAdditivesCategoriesPending: boolean;
  };

export const additivesCategoriesListState: AdditivesCategoriesListState = {
  ...concreteCategoriesDefaultState,
  loadAdditivesCategoriesPending: false,
  loadMoreAdditivesCategoriesPending: false,
};

const builder = simplifyBuilder(additivesCategoriesListState, {});

const loadAdditivesCategories = builder.createServerAction(
  loadConcreteCategories<
    APIAvailableAdditiveModelSearchResult,
    AdditivesCategoriesListState,
    APIAvailableAdditiveModelSearchResult
  >('loadAdditivesCategories', loadAdditivesCategoriesByCompanyEndpoint),
);

const loadMoreAdditivesCategories = builder.createServerAction(
  loadMoreConcreteCategories<
    APIAvailableAdditiveModelSearchResult,
    AdditivesCategoriesListState,
    APIAvailableAdditiveModelSearchResult
  >('loadMoreAdditivesCategories', loadAdditivesCategoriesByCompanyEndpoint),
);

const updateAdditivesCategories = builder.createServerAction(
  updateConcreteCategories(
    'updateAdditivesCategories',
    contractorAdditiveEndpoint,
  ),
);

const updateAdditivesCategoriesListSorting = builder.createReduxAction(
  updateConcreteCategoriesSorting<APIAvailableAdditiveModelSearchResult>(
    'updateAdditivesCategoriesListSorting',
  ),
);

const updateAdditivesCategoriesListPagination = builder.createReduxAction(
  updateConcreteCategoriesPagination<APIAvailableAdditiveModelSearchResult>(
    'updateAdditivesCategoriesListPagination',
  ),
);

const updateAdditivesCategoriesListFilters = builder.createReduxAction(
  updateConcreteCategoriesFilters<
    APIAvailableAdditiveModelSearchResult,
    AdditivesCategoriesListState
  >('updateAdditivesCategoriesListFilters'),
);

const resetAdditivesCategories = builder.createReduxAction(
  resetConcreteCategories('resetAdditivesCategories'),
);

export const useAdditivesCategoriesList = () => {
  const dispatch = useDispatch();
  const providerId = useSelector(appSelectors.providerId);

  return useMemo(
    () =>
      bindActionCreators(
        {
          loadAdditivesCategories: loadAdditivesCategories.bind(
            null,
            providerId,
            additivesCategoriesSelectors.allAdditivesCategoriesListState,
          ),
          loadMoreAdditivesCategories: loadMoreAdditivesCategories.bind(
            null,
            providerId,
            additivesCategoriesSelectors.allAdditivesCategoriesListState,
          ),
          updateAdditivesCategories: updateAdditivesCategories.bind(
            null,
            providerId,
          ),
          updateAdditivesCategoriesListSorting:
            updateAdditivesCategoriesListSorting.bind(null),
          updateAdditivesCategoriesListPagination:
            updateAdditivesCategoriesListPagination.bind(null),
          updateAdditivesCategoriesListFilters:
            updateAdditivesCategoriesListFilters.bind(null),
          resetAdditivesCategories: resetAdditivesCategories.bind(null),
          // updateItemDetailsInAdditivesCategoriesState:
          //   updateItemDetailsInAdditivesCategoriesState.bind(null),
        },
        dispatch,
      ),
    [dispatch, providerId],
  );
};

export const additivesCategoriesListReducer = builder.getReducers();
