import React from 'react';

import { APIOrder, APIUserType } from '@cd3p/core/types/api';
import {
  CanAccess,
  DeliveryRateTitle,
  DispatcherCanAccess,
  HorizontalDivider,
  ReadOnlyField,
  Stack,
} from 'components';

import { renderWithOriginalName } from './orderViewHelpers';

import { styled, useSelector, useTranslation } from 'third-party';

import { contractorsUrl, customerProjectDetailsUrl } from 'constants/url';

import { appSelectors } from 'selectors';

import { shouldShowActualDeliveryRate } from 'utils/order';

type Props = {
  order: APIOrder | null;
};

const OrderInformationWrapper = styled.div`
  flex-grow: 1;
  padding: 1.6rem 0;
`;

const StyledDivider = styled(HorizontalDivider)`
  margin-bottom: 1rem;
`;

const DeliveryRateDescription = styled(Stack)`
  flex-direction: column;
  color: ${props => props.theme.custom.palette.gray};
  font-size: 1.6rem;
  margin: -0.6rem 0 0.6rem;
`;

export const OrderInformationTab: React.FC<Props> = ({ order }) => {
  const { t } = useTranslation();
  const providerName = useSelector(appSelectors.providerName);
  const showActualDeliveryRate = order
    ? shouldShowActualDeliveryRate(order.deliveryRate, order.actualDeliveryRate)
    : false;

  return (
    <OrderInformationWrapper>
      <ReadOnlyField
        value={order?.project?.name}
        label={t('order.sectionField.project')}
        linkState={
          order?.company?.id && order?.project?.id
            ? {
                backUrl: location.pathname,
                backUrlLabel: t('order.backButtonLabel.order'),
              }
            : null
        }
        linkTo={
          order?.company?.id && order?.project?.id
            ? customerProjectDetailsUrl(order.company.id, order.project.id)
            : undefined
        }
      />
      <ReadOnlyField
        value={order?.project?.address}
        label={t('order.sectionField.projectAddress')}
      />
      <CanAccess allowedUserType={APIUserType.Contractor}>
        <ReadOnlyField
          value={providerName}
          label={t('order.sectionField.providerName')}
        />
      </CanAccess>
      <DispatcherCanAccess>
        <ReadOnlyField
          value={order?.company?.name}
          label={t('order.sectionField.customerName')}
          linkTo={contractorsUrl(order?.company.id)}
        />
      </DispatcherCanAccess>
      <StyledDivider />
      <ReadOnlyField
        label={t('order.sectionField.callbackTitle')}
        value={`${order?.callBack ? t('common.yes') : t('common.no')}`}
      />
      <Stack>
        <ReadOnlyField
          label={t('order.sectionField.deliveryRate')}
          value={
            showActualDeliveryRate
              ? ''
              : <DeliveryRateTitle order={order} /> ||
                t('common.entityNameUndefined')
          }
        />
        {showActualDeliveryRate ? (
          <DeliveryRateDescription>
            <DeliveryRateTitle order={order} />
          </DeliveryRateDescription>
        ) : null}
      </Stack>
      <ReadOnlyField
        label={t('order.sectionField.orderQuantity')}
        value={order?.volume || t('common.entityNameUndefined')}
      />
      <ReadOnlyField
        label={t('order.sectionField.mixType')}
        value={
          order?.mixType
            ? renderWithOriginalName(
                order.mixType.name || null,
                order.mixType.originalName,
                t('orderDetails.oldMixNameInfo'),
              )
            : t('common.entityNameUndefined')
        }
      />
      <ReadOnlyField
        label={t('order.sectionField.additives')}
        value={
          order?.additives?.length
            ? (order.additives || []).map((item, index, array) => (
                <span key={index}>
                  {renderWithOriginalName(
                    item.name,
                    item.originalName,
                    t('orderDetails.oldAdditiveNameInfo'),
                  )}
                  {array.length - 1 === index ? '' : `,`}
                  &nbsp;
                </span>
              ))
            : t('common.entityNameUndefined')
        }
      />
      <ReadOnlyField
        label={t('order.sectionField.slump')}
        value={order?.slump || t('common.entityNameUndefined')}
      />
      <ReadOnlyField
        label={t('order.sectionField.typeOfPour')}
        value={order?.typeOfPour || t('common.entityNameUndefined')}
      />
      <ReadOnlyField
        label={t('order.sectionField.placementMethod')}
        value={order?.placementMethod || t('common.entityNameUndefined')}
      />
      <ReadOnlyField
        label={t('order.sectionField.notes')}
        value={order?.notes || t('common.entityNameUndefined')}
      />
    </OrderInformationWrapper>
  );
};
