import React, { useEffect, useMemo, useState } from 'react';

import styled from 'styled-components';

import {
  APICallBackFormStatus,
  APIOrder,
  APIOrderStatus,
} from '@cd3p/core/types/api';

import { useSelector } from 'third-party';

import { appSelectors } from 'selectors';

import { BatchingOrderNumber } from 'features/OrderView/BatchingOrderNumber';
import { CanceledOrder } from 'features/OrderView/DispatcherRightView/CanceledOrder';
import { CancellationSubmittedOrder } from 'features/OrderView/DispatcherRightView/CancellationSubmittedOrder';
import { ChangesRequestedOrder } from 'features/OrderView/DispatcherRightView/ChangesRequestedOrder';
import { CloseOrder } from 'features/OrderView/DispatcherRightView/CloseOrder';
import { OrderCallback } from 'features/OrderView/DispatcherRightView/OrderCallback';
import { RequestedOrder } from 'features/OrderView/DispatcherRightView/RequestedOrder';
import { UnconfirmedOrder } from 'features/OrderView/DispatcherRightView/UnconfirmedOrder';
import {
  OrderContentWrapper,
  ScrollableContentContainer,
} from 'features/OrderView/orderViewHelpers';

import { isOrderReadyToComplete } from 'utils/order';

const StyledBatchingOrderNumber = styled(BatchingOrderNumber)`
  width: auto;
  max-width: none;
  z-index: 1;
`;

type Props = {
  order: APIOrder;
};

const CHANGES_REQUESTED_FORM_STATUSES = [
  APIOrderStatus.Unconfirmed,
  APIOrderStatus.Confirmed,
  APIOrderStatus.Delivering,
];

// they are equal for now
const MPAQ_ORDER_NUMBER_STATUSES = CHANGES_REQUESTED_FORM_STATUSES;

export const DispatcherRightView: React.FC<Props> = ({ order }) => {
  const isBatchSoftwareIntegrationEnabled = useSelector(
    appSelectors.isBatchSoftwareIntegrationEnabled,
  );

  const [showMpaqOrderNumberReminder, setShowMpaqOrderNumberReminder] =
    useState(
      isBatchSoftwareIntegrationEnabled &&
        !order.isBatchNumberReminderSubmitted,
    );

  useEffect(() => {
    if (!isBatchSoftwareIntegrationEnabled) {
      setShowMpaqOrderNumberReminder(false);
      return;
    }

    const shouldDisplayMpaqForStatus = MPAQ_ORDER_NUMBER_STATUSES.includes(
      order.orderStatus,
    );
    const showMpaqReminder =
      shouldDisplayMpaqForStatus && !order.isBatchNumberReminderSubmitted;

    setShowMpaqOrderNumberReminder(showMpaqReminder);
  }, [order, isBatchSoftwareIntegrationEnabled]);

  const showCallbackSubmittedBlock =
    order?.callBackFormStatus === APICallBackFormStatus.CallbackSubmitted;

  const content = useMemo(() => {
    const forms = [];
    if (order.isCancellationSubmitted) {
      forms.push(
        <CancellationSubmittedOrder
          customerName={order.company.name}
          orderId={order.id}
        />,
      );
    } else {
      if (
        order.isChangesRequested &&
        CHANGES_REQUESTED_FORM_STATUSES.includes(order.orderStatus)
      ) {
        forms.push(<ChangesRequestedOrder order={order} />);
      }
      if (isOrderReadyToComplete(order)) {
        forms.push(
          <CloseOrder orderId={order.id} orderName={order.orderName} />,
        );
      }
      switch (order.orderStatus) {
        case APIOrderStatus.Unconfirmed:
          forms.push(<UnconfirmedOrder order={order} />);
          break;
        case APIOrderStatus.Requested:
          forms.push(<RequestedOrder order={order} />);
          break;
        case APIOrderStatus.Delivering:
          showCallbackSubmittedBlock &&
            forms.push(<OrderCallback order={order} />);
          break;
        case APIOrderStatus.Cancelled:
          forms.push(<CanceledOrder customerName={order.company.name} />);
          break;
        default: {
          break;
        }
      }
    }
    return forms;
  }, [order, showCallbackSubmittedBlock]);

  return (
    <ScrollableContentContainer>
      {content.map((form, index) => (
        <OrderContentWrapper key={index}>{form}</OrderContentWrapper>
      ))}
      {showMpaqOrderNumberReminder && (
        <StyledBatchingOrderNumber
          order={order}
          onClose={() => setShowMpaqOrderNumberReminder(false)}
        />
      )}
    </ScrollableContentContainer>
  );
};
