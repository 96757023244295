import React, { useCallback, useEffect, useMemo, useState } from 'react';

import styled from 'styled-components';

import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';

import { ON_ORDER_UPDATE_EVENT } from '@cd3p/core/constants/hubs';
import { APIOrder, APIUserType } from '@cd3p/core/types/api';
import {
  Button,
  CanAccess,
  DispatcherCanAccess,
  LoadingButton,
  Menu,
  RequiredActionsBanner,
  Stack,
  Typography,
  ViewWrapper,
} from 'components';
import { subscribe } from 'hooks/usePubSub';
import { useTableColumnVisibilityControl } from 'hooks/useTableColumnVisibilityControl';
import { useTableSettings } from 'hooks/useTableSettings';
import { TableSettingsFiltersSelector } from 'selectors/storage';

import {
  useLocation,
  useNavigate,
  useSelector,
  useTranslation,
} from 'third-party';

import { APP_EVENTS } from 'constants/appEvent';
import { AppTableSettings } from 'constants/common';
import { createOrderUrl } from 'constants/url';

import { useAllOrdersList } from 'modules/allOrdersList';
import { OrdersFilters, ordersDefaultFilters } from 'modules/common/ordersList';
import { useOrder } from 'modules/order';
import { useOrdersCache } from 'modules/ordersCache';
import { resetTableSettingsFilters, useStorage } from 'modules/storage';

import {
  allOrdersListSelectors,
  appSelectors,
  requiredActionsListSelectors,
  storageSelectors,
} from 'selectors';

import { ordersTableSectionOptions } from 'components/Table/Table';

import { FiltersPopup } from 'features/AllOrders/FiltersPopup';
import { OrdersTable } from 'features/OrdersTable/OrdersTable';

import { ordersListFiltersCount } from 'utils/ordersList';
import { useOrdersSockets } from 'utils/sockets';

import { OutlinedSearchInput } from 'styles/common';

const StyledViewWrapper = styled(ViewWrapper)`
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
`;

const Header = styled.div`
  display: flex;
  margin-bottom: 1rem;
  align-items: center;
`;

const ViewTitle = styled(Typography)`
  color: ${props => props.theme.custom.palette.secondary500};
  padding-right: 1.3rem;
  margin-right: auto;
`;

const ResetButton = styled(Button)`
  text-decoration: underline;
  font-weight: 900;
  font-size: 1.4rem;
  margin-right: 1.2rem;
  padding: 0 1.2rem;
  &:hover {
    text-decoration: none;
  }
`;

const FilterButton = styled(Button)`
  text-transform: none;
  margin-right: 1rem;
`;

const AddOrderButton = styled(Button)`
  margin-right: 2rem;
  text-indent: 1rem;
  align-self: flex-end;
`;

const AddOrderDarkButton = styled(Button)`
  margin-left: 1rem;
`;

const TableLoadMoreButton = styled(LoadingButton)`
  margin-top: 2rem;
  height: 5.2rem;
`;

const ordersTableComponents = {
  LoadMoreButton: TableLoadMoreButton,
};

const StyledOutlinedSearchInput = styled(OutlinedSearchInput)`
  width: 40rem;
  margin-right: 1rem;
`;

export const AllOrdersView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { updateOrder } = useOrder();
  const { updateOrderInCache } = useOrdersCache();
  const { updateUserSettingInStorage } = useStorage();
  const {
    updateAllOrdersListSorting,
    loadMoreAllOrders,
    updateOrderPositionInAllOrders,
    updateAllOrdersSearch,
    resetAllOrdersSearch,
    saveAllOrdersScrollPosition,
  } = useAllOrdersList();

  useOrdersSockets(
    () => [
      {
        method: ON_ORDER_UPDATE_EVENT,
        action: (providerId: string, orderId: string, order: APIOrder) => {
          updateOrderInCache(order.id, order);
        },
      },
    ],
    [updateOrderInCache],
  );

  const providerId = useSelector(appSelectors.providerId);

  const ordersListItems = useSelector(
    allOrdersListSelectors.allOrdersListItems,
  );
  const loadOrdersPending = useSelector(
    allOrdersListSelectors.loadAllOrdersPending,
  );
  const ordersLoaded = useSelector(allOrdersListSelectors.allOrdersLoaded);
  const allOrdersScrollPosition = useSelector(
    allOrdersListSelectors.allOrdersScrollPosition,
  );
  const ordersListCount = useSelector(
    allOrdersListSelectors.allOrdersListCount,
  );
  const loadMoreOrdersPending = useSelector(
    allOrdersListSelectors.loadMoreAllOrdersPending,
  );
  const ordersListSorting = useSelector(
    allOrdersListSelectors.allOrdersListSorting,
  );
  const ordersListFilters = useSelector(
    storageSelectors.tableSettingsFiltersSelector[
      AppTableSettings.AllOrders
    ] as TableSettingsFiltersSelector<OrdersFilters>,
  );
  const ordersListSearch = useSelector(
    allOrdersListSelectors.allOrdersListSearch,
  );
  const ordersListSearchPattern = useSelector(
    allOrdersListSelectors.allOrdersListSearchPattern,
  );
  const isOrdersListFiltersApplied = useSelector(
    storageSelectors.isTableSettingsFiltersApplied[AppTableSettings.AllOrders](
      ordersDefaultFilters,
    ),
  );
  const { sortField, sortOrder } = ordersListSorting[0];
  const requiredActionsListCounter = useSelector(
    requiredActionsListSelectors.requiredActionsListCounter,
  );

  const [filterPopupAnchorEl, setFilterPopupAnchorEl] =
    useState<null | Element>();

  const tableSettings = useTableSettings(AppTableSettings.AllOrders, {
    isResizable: true,
    defaultFilters: ordersDefaultFilters,
  });

  const tableColumnVisibility = useTableColumnVisibilityControl();

  const showResetFiltersButton = isOrdersListFiltersApplied;

  useEffect(() => {
    return subscribe(
      APP_EVENTS.UPDATE_ORDER_IN_LIST,
      (orderDetails: Partial<APIOrder> & { id: number }) => {
        updateOrderInCache(orderDetails.id, orderDetails);
      },
    );
  }, [updateOrderInCache]);

  const onFilterButtonClicked = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setFilterPopupAnchorEl(event.currentTarget);
  };

  const filterButtonText = useMemo(() => {
    return t('ordersList.filtersButtonText', {
      count: ordersListFiltersCount(ordersListFilters!),
    });
  }, [ordersListFilters, t]);

  const closeFilterPopup = useCallback(() => {
    setFilterPopupAnchorEl(null);
  }, []);

  const onCreateOrderClick = useCallback(() => {
    navigate(createOrderUrl(), {
      state: {
        backUrl: location.pathname,
        backUrlLabel: t('order.breadcrumbs.viewAll'),
      },
    });
  }, [location.pathname, navigate, t]);

  const onOrdersSearch = useCallback(
    (value: string) => {
      updateAllOrdersSearch(value.trim());
    },
    [updateAllOrdersSearch],
  );

  useEffect(() => {
    return subscribe(APP_EVENTS.CHANGE_PROVIDER, resetAllOrdersSearch);
  }, [resetAllOrdersSearch]);

  return (
    <StyledViewWrapper>
      <Header>
        <ViewTitle variant="h4">{t('ordersList.viewTitle')}</ViewTitle>
        <Stack direction="row" alignItems="center">
          <StyledOutlinedSearchInput
            // reset search when changing provider
            key={providerId}
            isClearable
            defaultValue={ordersListSearchPattern}
            onChange={onOrdersSearch}
            placeholder={t('ordersList.searchInputPlaceholder')}
          />
          {showResetFiltersButton && (
            <ResetButton
              onClick={() =>
                updateUserSettingInStorage(
                  resetTableSettingsFilters[AppTableSettings.AllOrders](
                    ordersDefaultFilters,
                  ),
                )
              }
            >
              {t('ordersList.resetFiltersButtonText')}
            </ResetButton>
          )}
          <DispatcherCanAccess>
            <AddOrderButton onClick={onCreateOrderClick}>
              <AddBoxOutlinedIcon />
              {t('common.newOrder')}
            </AddOrderButton>
          </DispatcherCanAccess>
          <FilterButton
            variant="outlined"
            color="secondary"
            type="submit"
            onClick={onFilterButtonClicked}
          >
            {filterButtonText}
          </FilterButton>
          {tableColumnVisibility.button}
          <CanAccess allowedUserType={APIUserType.Contractor}>
            <AddOrderDarkButton
              variant="contained"
              color="primary"
              onClick={onCreateOrderClick}
              startIcon={<AddBoxOutlinedIcon />}
            >
              {t('common.newOrder')}
            </AddOrderDarkButton>
          </CanAccess>
        </Stack>
      </Header>
      <RequiredActionsBanner count={requiredActionsListCounter} />
      <OrdersTable
        isResizable={tableSettings.isResizable}
        isFiltersApplied={tableSettings.isFiltersApplied}
        tableSettings={tableSettings}
        hiddenColumns={tableSettings.hiddenColumns}
        viewLabel={t('order.breadcrumbs.viewAll')}
        loadMoreOrders={loadMoreAllOrders}
        updateOrderInList={updateOrder}
        updateOrderPosition={updateOrderPositionInAllOrders}
        updateSorting={updateAllOrdersListSorting}
        sortField={sortField}
        sortOrder={sortOrder}
        search={ordersListSearch}
        orders={ordersListItems}
        ordersLoaded={ordersLoaded}
        loadItemsPending={loadOrdersPending}
        loadMoreItemsPending={loadMoreOrdersPending}
        ordersCount={ordersListCount}
        components={ordersTableComponents}
        sectionOptions={ordersTableSectionOptions}
        saveScrollPosition={saveAllOrdersScrollPosition}
        scrollPosition={allOrdersScrollPosition}
      />
      <Menu
        id="filters-popup"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={filterPopupAnchorEl}
        open={Boolean(filterPopupAnchorEl)}
        onClose={closeFilterPopup}
        sx={{
          marginTop: '2rem',
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          sx: {},
        }}
      >
        <FiltersPopup onApply={closeFilterPopup} onReset={closeFilterPopup} />
      </Menu>
      {tableColumnVisibility.popup}
    </StyledViewWrapper>
  );
};
