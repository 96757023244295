import React from 'react';

import styled from 'styled-components';

import { SystemProps } from '@mui/system';
import { Stack, Typography } from 'components';

const Value = styled(Stack)`
  display: inline-flex;
  align-items: center;
  border: 1px solid ${props => props.theme.custom.palette.secondary900};
  background: ${props => props.theme.custom.palette.muted50};
  border-radius: 0.4rem;
  padding: 0 0.8rem;
  height: 2.6rem;
`;

type TagProps = {
  value: string;
  className?: string;
} & SystemProps;
export const Tag = ({ value, className, ...systemProps }: TagProps) => {
  return (
    <Value className={className} {...systemProps}>
      <Typography variant="body1" textTransform="uppercase">
        {value}
      </Typography>
    </Value>
  );
};
