import { APIImportedOrder, APIImportedOrderStatus } from '@cd3p/core/types/api';
import { State } from 'modules';

import { _, createSelector } from 'third-party';

export const lastImportedOrders = (state: State) =>
  state.importOrders.lastImportedOrders;
export const importOrdersPending = (state: State) =>
  state.importOrders.importOrdersPending;
export const importOrdersError = (state: State) =>
  state.importOrders.importOrdersError;

export const lastSuccessfullyImportedOrders = createSelector(
  [lastImportedOrders],
  (lastImportedOrders: APIImportedOrder[]) =>
    _.filter(
      lastImportedOrders,
      it => it.importStatus === APIImportedOrderStatus.Imported,
    ),
);

export const lastSkippedImportOrders = createSelector(
  [lastImportedOrders],
  (lastImportedOrders: APIImportedOrder[]) =>
    _.filter(
      lastImportedOrders,
      it => it.importStatus === APIImportedOrderStatus.Exist,
    ),
);

export const lastFailedToImportOrders = createSelector(
  [lastImportedOrders],
  (lastImportedOrders: APIImportedOrder[]) =>
    _.filter(
      lastImportedOrders,
      it => it.importStatus === APIImportedOrderStatus.Error,
    ),
);
