import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import styled, { useTheme } from 'styled-components';

import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';

import { APIProvider } from '@cd3p/core/types/api';
import { Avatar, Button, Stack, Table, TableRow, Typography } from 'components';

import { AddCustomerAccountPopup } from './AddCustomerAccount';

import { useNavigate, useSelector, useTranslation } from 'third-party';

import { contractorsUrl } from 'constants/url';

import {
  CustomerAccountsListFilters,
  CustomerAccountsListSorting,
  useAdminCustomerAccountsList,
} from 'modules/adminCustomerAccountsList';

import { adminCustomerAccountsList } from 'selectors';

import { StyledTableBodyCell } from 'components/Table/Table';

import { OutlinedSearchInput } from 'styles/common';

const StyledTable = styled(Table)`
  flex-grow: 1;
  min-height: 0;
  border-top: 1px solid ${props => props.theme.custom.palette.truegray300};
`;

const TableCellTextWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0.8rem 0;
`;

const TableCellText = styled(Typography)`
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.5;
  padding-right: 1rem;
  color: ${props => props.theme.custom.palette.secondary900};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-left: 1.4rem;
`;

const AddButton = styled(Button)`
  font-size: 1.2rem;
  white-space: nowrap;
  min-width: 11rem;
`;

const StyledAddBoxOutlinedIcon = styled(AddBoxOutlinedIcon)`
  font-size: 1.6rem;
  padding-right: 0.4rem;
`;

const TableHeaderRow = styled(TableRow)`
  display: none;
`;

const TableBodyCell = styled(StyledTableBodyCell)`
  padding-left: 1.4rem;
  border-bottom: 0px;
`;

const SearchPanel = styled(Stack)`
  border-bottom: 1px solid ${props => props.theme.custom.palette.muted50};
  border-left: 1px solid ${props => props.theme.custom.palette.muted100};
  background: ${props => props.theme.custom.palette.backgroundTheme};
  justify-content: flex-end;
  padding: 0.8rem;
  flex-direction: row;
  gap: 1rem;
`;

const tableComponents = {
  TableHeaderRow,
  TableBodyCell,
};

type Props = {
  emptyState: ReactElement;
  selectedAccountId?: string;
};

export const CustomerAccountsList: React.FC<Props> = ({
  emptyState,
  selectedAccountId,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();

  const [showAddCustomerPopup, setShowAddCustomerPopup] = useState(false);

  const {
    loadCustomerAccounts,
    loadMoreCustomerAccounts,
    updateCustomerAccountsListFilters,
    updateCustomerAccountsList,
  } = useAdminCustomerAccountsList();

  const customerAccountsListItems = useSelector(
    adminCustomerAccountsList.customerAccountsListItems,
  );
  const customerAccountsListCount = useSelector(
    adminCustomerAccountsList.customerAccountsListCount,
  );
  const customerAccountsLoaded = useSelector(
    adminCustomerAccountsList.customerAccountsLoaded,
  );
  const loadCustomerAccountsPending = useSelector(
    adminCustomerAccountsList.loadCustomerAccountsPending,
  );
  const loadMoreCustomerAccountsPending = useSelector(
    adminCustomerAccountsList.loadMoreCustomerAccountsPending,
  );
  const customerAccountsListNameFilter = useSelector(
    adminCustomerAccountsList.customerAccountsListNameFilter,
  );

  const sorting = useSelector(
    adminCustomerAccountsList.customerAccountsListSorting,
  );

  const { sortField, sortOrder } = sorting[0];

  const loadContractorsCallback = useCallback(
    (
      params?: {
        searchSortOrders?: CustomerAccountsListSorting;
      } & CustomerAccountsListFilters,
    ) => {
      loadCustomerAccounts({
        ...params,
      });
    },
    [loadCustomerAccounts],
  );

  useEffect(() => {
    if (!customerAccountsLoaded) loadContractorsCallback();
  }, [loadContractorsCallback, customerAccountsLoaded]);

  const columns = useMemo(() => {
    return [
      {
        id: 'providerName',
        dataId: 'providerName' as const,
        formatter: (value: APIProvider['providerName']) => ({
          value: value || '',
          element: (
            <TableCellTextWrapper>
              <Avatar
                firstName={value?.split(' ')?.[0]}
                lastName={value?.split(' ')?.[1]}
                size="4.6rem"
                fontSize="2rem"
              />
              <TableCellText>{value || ''}</TableCellText>
            </TableCellTextWrapper>
          ),
        }),
      },
    ];
  }, []);

  const onRowClick = useCallback(
    (item: APIProvider) => {
      navigate(contractorsUrl(item.id.toString()));
    },
    [navigate],
  );

  const rowHighlightColor = useCallback(
    (item: APIProvider) => {
      if (item.id.toString() === selectedAccountId) {
        return theme.custom.palette.primary50;
      }
    },
    [selectedAccountId, theme.custom.palette.primary50],
  );

  const updateNameFilter = (name: string) => {
    const filtersValue = {
      providerName: name,
    };
    loadContractorsCallback(filtersValue);
    updateCustomerAccountsListFilters(filtersValue);
  };

  const handleAddCustomerClick = useCallback(() => {
    setShowAddCustomerPopup(true);
  }, []);

  const handleAddCustomerAccountSuccess = useCallback(() => {
    updateCustomerAccountsList();
  }, [updateCustomerAccountsList]);

  return customerAccountsLoaded &&
    !loadCustomerAccountsPending &&
    customerAccountsListCount === 0 &&
    !customerAccountsListNameFilter ? (
    emptyState
  ) : (
    <>
      {showAddCustomerPopup && (
        <AddCustomerAccountPopup
          onClosePopup={() => setShowAddCustomerPopup(false)}
          onSuccess={handleAddCustomerAccountSuccess}
        />
      )}
      <SearchPanel>
        <OutlinedSearchInput
          isClearable
          onChange={updateNameFilter}
          defaultValue={customerAccountsListNameFilter}
          placeholder={t('customers.table.searchFiledPlaceholderProviders')}
        />
        <AddButton
          variant="contained"
          color="primary"
          onClick={handleAddCustomerClick}
        >
          <StyledAddBoxOutlinedIcon />
          {t('customers.support.newAccountBtn')}
        </AddButton>
      </SearchPanel>
      {/* @ts-ignore TODO: find out what's wrong with types */}
      <StyledTable
        infiniteScroll
        tableMinHeight="20rem"
        headerRowHeight="6.6rem"
        bodyRowHeight="5rem"
        components={tableComponents}
        columns={columns}
        items={customerAccountsListItems}
        itemsTotalCount={customerAccountsListCount}
        itemsLoaded={customerAccountsLoaded}
        loadItemsPending={loadCustomerAccountsPending}
        loadMoreItemsPending={loadMoreCustomerAccountsPending}
        onLoadMoreClicked={loadMoreCustomerAccounts}
        sortField={sortField}
        sortOrder={sortOrder}
        onRowClick={onRowClick}
        rowHighlightColor={rowHighlightColor}
        loadMoreButtonText={t('customers.table.loadMoreButton')}
        emptyText={
          customerAccountsListNameFilter
            ? t('customers.support.listEmptySearch')
            : undefined
        }
      />
    </>
  );
};
