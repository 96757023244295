import React, { useState } from 'react';

import styled from 'styled-components';

import { APIProject } from '@cd3p/core/types/api';
import { FormField, Modal, Stack, Switch, Typography } from 'components';
import { useHandleApiResult } from 'hooks/useHandleApiResult';

import {
  FieldError,
  FormProvider,
  useForm,
  useFormContext,
  useTranslation,
} from 'third-party';

import { useProject } from 'modules/project';

export const Header = styled(Typography)`
  color: ${props => props.theme.custom.palette.secondary500};
  font-weight: 900;
  font-size: 1.6rem;
  text-transform: uppercase;
  margin-bottom: 0.4rem;
`;

const ConfigContent = styled(Stack)`
  border: 0.1rem solid ${props => props.theme.custom.palette.muted100};
  padding: 1.2rem;
  margin-top: 0.8rem;
  flex-direction: row;
  flex-wrap: no-wrap;
  margin-bottom: 1rem;
`;

const ConfigHeader = styled(Typography)`
  font-weight: 600;
`;

const SwicherFormField = styled(FormField)`
  margin-top: 0.2rem !important;
  flex-direction: column-reverse;
  width: 3.8rem;
`;

enum FormFields {
  IsCustomMixTypes = 'isCustomMixTypes',
  IsCustomAdditiveTypes = 'isCustomAdditiveTypes',
}

export type FormProps = {
  [FormFields.IsCustomMixTypes]: boolean;
  [FormFields.IsCustomAdditiveTypes]: boolean;
};

const formDefaultValues = {
  [FormFields.IsCustomMixTypes]: false,
  [FormFields.IsCustomAdditiveTypes]: false,
};

type Props = {
  projectId: string;
  companyId: string;
  project: APIProject;
  onClose: () => void;
  onSuccess?: () => void;
};

export const ProjectSettingsModal: React.FC<Props> = ({
  onClose,
  onSuccess,
  projectId,
  project,
  companyId,
}) => {
  const { t } = useTranslation();

  const { updateProject } = useProject();
  const handleApiResult = useHandleApiResult();

  const formMethods = useForm<FormProps>({
    mode: 'onChange',
    defaultValues: {
      isCustomAdditiveTypes:
        project.projectOptions?.['containsCustomAdditives'] === 'true' ||
        formDefaultValues[FormFields.IsCustomAdditiveTypes],
      isCustomMixTypes:
        project.projectOptions?.['containsCustomMixTypes'] === 'true' ||
        formDefaultValues[FormFields.IsCustomMixTypes],
    },
  });

  const {
    handleSubmit,
    formState: { isValid, isDirty },
  } = formMethods;

  const [isPending, setIsPending] = useState(false);

  const onSubmit = async (data: FormProps) => {
    if (!isValid) {
      return;
    }
    setIsPending(true);
    await handleApiResult<APIProject>(
      () =>
        updateProject(projectId, companyId, {
          isCustomAdditives: data[FormFields.IsCustomAdditiveTypes],
          isCustomMixTypes: data[FormFields.IsCustomMixTypes],
          name: project.name,
          address: project.address,
          description: project.description || '',
        }),
      ({ showBaseToast }) => {
        showBaseToast(t('projectSettings.toast.saveSuccess'));
        onClose();
        onSuccess?.();
      },
    );
    setIsPending(false);
  };

  return (
    <Modal
      open
      header={t('projectSettings.title')}
      confirmLabel={t('common.save')}
      onConfirm={() => {
        handleSubmit(onSubmit)();
      }}
      isDisabled={isPending}
      isPending={isPending}
      isConfirmDisabled={!isValid || !isDirty}
      onClose={onClose}
    >
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <SettingsSwitcherBlock
            title={t('customers.mixTypes.header')}
            description={t('addProject.submittedMixes.description')}
            blockTitle={t('addProject.submittedMixes.fieldTitle')}
            blockDescription={t('addProject.submittedMixes.fieldDescription')}
            fieldName={FormFields.IsCustomMixTypes}
            isDisabled={isPending}
          />
          <SettingsSwitcherBlock
            title={t('customers.additiveTypes.header')}
            description={t('addProject.submittedAdditive.description')}
            blockTitle={t('addProject.submittedAdditive.fieldTitle')}
            blockDescription={t(
              'addProject.submittedAdditive.fieldDescription',
            )}
            fieldName={FormFields.IsCustomAdditiveTypes}
            isDisabled={isPending}
          />
        </form>
      </FormProvider>
    </Modal>
  );
};

type SwitcherBlockProps = {
  title: string;
  description: string;
  blockTitle: string;
  blockDescription: string;
  fieldName: string;
  isDisabled?: boolean;
};

export const SettingsSwitcherBlock = ({
  title,
  description,
  blockTitle,
  blockDescription,
  isDisabled,
  fieldName,
}: SwitcherBlockProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <Header variant="h6">{title}</Header>
      <Typography variant="body2">{description}</Typography>
      <ConfigContent>
        <Stack flex={1} flexDirection={'column'}>
          <ConfigHeader variant="h6">{blockTitle}</ConfigHeader>
          <Typography variant="body2">{blockDescription}</Typography>
        </Stack>
        <SwicherFormField
          fieldName={fieldName}
          isDisabled={isDisabled}
          fieldError={errors?.[fieldName] as FieldError | undefined}
          control={control}
          isRequired={false}
          render={({ field }) => (
            <Switch
              onChange={field.onChange}
              value={field.value}
              checked={field.value}
            />
          )}
        />
      </ConfigContent>
    </>
  );
};
