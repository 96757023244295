import React from 'react';

import { API_DATE_AND_TIME_FORMAT } from '@cd3p/core/constants/common';
import {
  APIFullTextSearchFilter,
  APIOrderStatus,
  APIOrdersListParams,
} from '@cd3p/core/types/api';

import { _, dayjs } from 'third-party';

import {
  OrdersFilters,
  OrdersListState,
  ordersDefaultFilters,
} from 'modules/common/ordersList';

import { getGlobalCache } from 'utils/globalCache';

import { themeConfig } from 'styles/theme';

const booleanFilterIsApplied = (booleanFilter: {
  [propertyName: string]: boolean;
}) => _.keys(_.pickBy(booleanFilter, Boolean)).length > 0;

export const ordersListFiltersCount = (filters: OrdersFilters) => {
  const dateFilterDefined =
    filters.date.dateFrom || filters.date.dateTo ? 1 : 0;
  const statusFilterDefined = booleanFilterIsApplied(filters.status);
  const volumeFilterDefined = booleanFilterIsApplied(filters.volume);
  const companyFilterDefined = filters.company.length > 0;
  const orderIdsFilterDefined = filters.orderIds.length > 0;
  const projectIdsFilterDefined = filters.project.length > 0;
  const onlySubscribedOrdersDefined =
    filters.onlySubscribedOrders !== ordersDefaultFilters.onlySubscribedOrders;

  return (
    Number(dateFilterDefined) +
    Number(statusFilterDefined) +
    Number(volumeFilterDefined) +
    Number(companyFilterDefined) +
    Number(projectIdsFilterDefined) +
    Number(orderIdsFilterDefined) +
    Number(onlySubscribedOrdersDefined)
  );
};

export const composeLoadOrdersRequestBody = (
  providerId: string,
  {
    pageNumber,
    pageSize,
    sorting,
    filters,
    search,
  }: {
    pageNumber: OrdersListState['pagination']['pageNumber'];
    pageSize: OrdersListState['pagination']['pageSize'];
    sorting: OrdersListState['sorting'];
    filters: OrdersFilters;
    search?: APIFullTextSearchFilter;
  },
): APIOrdersListParams => {
  let dateFrom, dateTo, orderStatuses;
  const volume: APIOrdersListParams['volume'] = [];
  let companyIds: APIOrdersListParams['companyIds'] = [];
  let projectIds: APIOrdersListParams['projectIds'] = [];

  const { userSubscriptions = [] } = getGlobalCache(providerId);

  const userSubscriptionIds = userSubscriptions.map(it => it.orderId);

  let orderIds: APIOrdersListParams['orderIds'] = filters.onlySubscribedOrders
    ? userSubscriptionIds
    : [];

  if (filters.date.dateFrom) {
    dateFrom = dayjs(filters.date.dateFrom)
      .startOf('date')
      .format(API_DATE_AND_TIME_FORMAT);
    if (!filters.date.dateFrom) {
      dateTo = dateFrom;
    }
  }

  if (filters.date.dateTo) {
    dateTo = dayjs(filters.date.dateTo)
      .endOf('date')
      .format(API_DATE_AND_TIME_FORMAT);
    if (!filters.date.dateTo) {
      dateFrom = filters.date.dateTo;
    }
  }

  _.forOwn(filters.volume, (checked, range) => {
    if (checked) {
      const [from, to] = range.split('-');
      const volumeFrom = isNaN(parseInt(from)) ? null : parseInt(from);
      const volumeTo = isNaN(parseInt(to)) ? null : parseInt(to);

      if (volumeFrom || volumeTo) {
        volume.push({
          ...(volumeFrom === null ? {} : { from: volumeFrom }),
          ...(volumeTo === null ? {} : { to: volumeTo }),
        });
      }
    }
  });

  if (filters.company.length) {
    companyIds = filters.company.map(it => it.value);
  }

  if (filters.orderIds.length) {
    orderIds = [...orderIds, ...filters.orderIds.map(it => Number(it.value))];
  }

  if (filters.project.length) {
    projectIds = filters.project.map(it => it.value);
  }

  const checkedStatuses = _.pickBy(filters.status, Boolean);
  if (!_.isEmpty(checkedStatuses)) {
    orderStatuses = Object.keys<APIOrderStatus>(checkedStatuses);
  }

  return {
    pageNumber,
    pageSize,
    searchSortOrders: sorting,
    volume,
    ...(companyIds.length && { companyIds }),
    ...(orderIds.length && { orderIds }),
    ...(projectIds.length && { projectIds }),
    ...(dateFrom && { dateFrom }),
    ...(dateTo && { dateTo }),
    ...(orderStatuses && { orderStatuses }),
    ...(search?.pattern && { fullTextSearch: search }),
  };
};

export const getHighlightedContent = (
  originalValue: string | null,
  highlights?: string[] | null,
) => {
  if (!highlights || highlights.length === 0) {
    return originalValue;
  }

  const textToProcess = highlights.length > 1 ? [highlights[0]] : highlights;

  const reactElements = textToProcess.map(text => {
    const splittedText = text.split(/<em>|<\/em>/);
    const elements = splittedText.map((str, index) => {
      if (index % 2 === 0) {
        return str.length > 20
          ? `...${str.slice(str.length - 20, str.length)}`
          : str;
      } else {
        return (
          <em
            key={`${index}`}
            style={{ backgroundColor: themeConfig.custom.palette.searchResult }}
          >
            {str}
          </em>
        );
      }
    });

    return elements;
  });

  return <>{reactElements.flat()}</>;
};
