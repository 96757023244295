import { Core, Environment } from '@cd3p/core/config';

const env = process.env['ENV'] as Environment;

const isAdminPortal = () => {
  if (__DEV__ && ADMIN_PORTAL) {
    return true;
  }

  return window && window.location?.hostname?.includes('-admin.concretely.co');
};

Core.init({ env: env, isAdminPortal: isAdminPortal() });
