import React, { useCallback } from 'react';

import styled from 'styled-components';

import { APIProvider, APIResponse } from '@cd3p/core/types/api';
import { phoneField, textField } from '@cd3p/core/utils/fields';
import { FormField, Modal, TypeaheadFormField } from 'components';
import { useHandleApiResult } from 'hooks/useHandleApiResult';

import { FieldError, useForm, useSelector, useTranslation } from 'third-party';

import { useAdminCustomerAccountsList } from 'modules/adminCustomerAccountsList';
import { useCache } from 'modules/cache';

import { adminCustomerAccountsList } from 'selectors';

import { TypeaheadOption } from 'types/app';

const FormWrapper = styled.form`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-height: 0;
  flex-grow: 1;
`;

enum AddFormFields {
  Name = 'name',
  Address = 'address',
  PhoneNumber = 'phoneNumber',
}

type AddForm = {
  [AddFormFields.Name]: string;
  [AddFormFields.Address]: TypeaheadOption | null;
  [AddFormFields.PhoneNumber]: string;
};

const defaultValues: AddForm = {
  [AddFormFields.Name]: '',
  [AddFormFields.Address]: null,
  [AddFormFields.PhoneNumber]: '',
};

type Props = {
  onClosePopup: () => void;
  onSuccess?: (result: APIProvider) => void;
};

export const AddCustomerAccountPopup: React.FC<Props> = ({
  onClosePopup,
  onSuccess,
}) => {
  const { t } = useTranslation();

  const { loadAddress } = useCache();

  const { createCustomerAccount } = useAdminCustomerAccountsList();

  const isFormPending = useSelector(
    adminCustomerAccountsList.createCustomerAccountPending,
  );

  const {
    setValue,
    handleSubmit,
    control,
    formState: { isValid, isDirty, errors },
  } = useForm<AddForm>({
    defaultValues,
  });

  const loadAddressOptions = async (value: string) => {
    const result: APIResponse<string[]> = await loadAddress(value);
    let options: TypeaheadOption[] = [];
    if (!result.error) {
      options = result.payload.map(it => ({
        label: it,
        value: it,
      }));
    }
    return {
      options,
    };
  };

  const handleApiResult = useHandleApiResult();

  const onSubmit = async (data: AddForm) => {
    if (!isValid) {
      return;
    }
    handleApiResult<APIProvider>(
      () =>
        createCustomerAccount({
          providerName: data[AddFormFields.Name].trim(),
          officeAddress: data[AddFormFields.Address]?.value || null,
          phone: data[AddFormFields.PhoneNumber],
        }),
      ({ result, showBaseToast }) => {
        showBaseToast(
          t('customers.support.customerAccountCreatedSuccess', {
            providerName: result.payload.providerName,
          }),
        );
        onClosePopup?.();
        onSuccess?.(result.payload);
      },
    );
  };

  const onSelectAddress = useCallback(
    (option: TypeaheadOption | null) => {
      setValue(AddFormFields.Address, option, {
        shouldValidate: true,
        shouldDirty: true,
      });
    },
    [setValue],
  );

  return (
    <Modal
      open
      header={t('customers.support.newAccountTitle')}
      confirmLabel={t('customers.support.newAccountSubmit')}
      onConfirm={handleSubmit(onSubmit)}
      isDisabled={isFormPending}
      isPending={isFormPending}
      isConfirmDisabled={!isValid || !isDirty}
      onClose={onClosePopup}
      width="50rem"
      contentWrapperStyle={{ overflow: 'visible' }}
    >
      <FormWrapper onSubmit={handleSubmit(onSubmit)}>
        <FormField
          fieldName={AddFormFields.Name}
          label={t('customers.addCustomerPopup.companyNameLabel')}
          placeholder={t('customers.addCustomerPopup.companyNamePlaceholder')}
          requiredErrorMessage={t('common.form.emptyFieldError')}
          control={control}
          maxLength={textField.maxLength}
          isRequired
          isDisabled={isFormPending}
        />
        <FormField
          fieldName={AddFormFields.Address}
          label={t('customers.addCustomerPopup.companyAddressLabel')}
          fieldError={errors[AddFormFields.Address] as FieldError}
          requiredErrorMessage={t('common.form.emptyFieldError')}
          control={control}
          render={() => (
            <TypeaheadFormField
              placeholder={t(
                'customers.addCustomerPopup.companyAddressPlaceholder',
              )}
              loadOptions={loadAddressOptions}
              onChange={onSelectAddress}
              isDisabled={isFormPending}
            />
          )}
        />
        <FormField
          showError
          isRequired={false}
          fieldName={AddFormFields.PhoneNumber}
          label={t('settings.companySettings.form.companyPhoneLabel')}
          placeholder={t(
            'settings.companySettings.form.companyPhonePlaceholder',
          )}
          control={control}
          inputProps={{
            maxLength: phoneField.maxLength,
          }}
          fieldError={errors[AddFormFields.PhoneNumber] as FieldError}
          mask={phoneField.mask}
          maskDefinitions={phoneField.maskDefinitions}
          rules={{
            validate: {
              incorrectPhone: (value: string) =>
                !value ||
                phoneField.validate(value) ||
                t('common.form.phone.error.invalid'),
            },
          }}
        />
      </FormWrapper>
    </Modal>
  );
};
