import { useEffect } from 'react';

import { APIUser } from '@cd3p/core/types/api';

import { useSelector } from 'third-party';

import { useStorage } from 'modules/storage';

import { appSelectors } from 'selectors';

export const useInitUserLocalStorage = () => {
  const { initiateUserSettings } = useStorage();

  const user = useSelector(appSelectors.user);

  useEffect(() => {
    if (user.id) {
      initiateUserSettings(user as APIUser);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.id]);
};
