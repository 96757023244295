import React, { useCallback, useEffect, useMemo, useState } from 'react';

import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import { NotificationSeverity } from '@cd3p/core/modules/notifications';
import {
  APIAdditiveSearchFilter,
  APIMasterAdditive,
  APIMasterMixType,
  APIMixTypeSearchFilter,
} from '@cd3p/core/types/api';
import { OmitFirstArg } from '@cd3p/core/types/utils';
import { getServerErrorTitle, getSortOrder } from '@cd3p/core/utils/common';
import {
  AddButton,
  CircularProgress,
  ConfirmationDialog,
  IconButton,
  Table,
} from 'components';

import { useTranslation } from 'third-party';

import {
  ContractorAdditiveTypesColumnId,
  ContractorAdditiveTypesSorting,
  deleteContractorAdditiveType,
  loadContractorAdditiveTypes,
  loadMoreContractorAdditiveTypes,
  resetContractorAdditiveTypes,
  updateContractorAdditiveTypes,
  updateContractorAdditiveTypesSorting,
} from 'modules/contractorAdditiveTypes';
import {
  ContractorMixTypesColumnId,
  ContractorMixTypesSorting,
  deleteContractorMixType,
  loadContractorMixTypes,
  loadMoreContractorMixTypes,
  resetContractorMixTypes,
  updateContractorMixTypes,
  updateContractorMixTypesSorting,
} from 'modules/contractorMixTypes';
import { useNotifications } from 'modules/notifications';

import {
  ActionButtonsCellStyles,
  EmptyState,
  Header,
  HeaderWrapper,
  LoadingContainer,
  TableCellText,
  Wrapper,
  tableComponents,
} from 'features/Contractors/Tabs/tabHelpers';

type ItemT = APIMasterMixType | APIMasterAdditive;

type Props = {
  items: ItemT[];
  itemsCount: number;
  itemsLoaded: boolean;
  loadItemsPending: boolean;
  loadMoreItemsPending: boolean;
  sorting: ContractorMixTypesSorting | ContractorAdditiveTypesSorting;
  contractorId: string;
  loadItems: OmitFirstArg<
    typeof loadContractorAdditiveTypes | typeof loadContractorMixTypes
  >;
  loadMoreItems: OmitFirstArg<
    typeof loadMoreContractorAdditiveTypes | typeof loadMoreContractorMixTypes
  >;
  deleteItem: OmitFirstArg<
    typeof deleteContractorAdditiveType | typeof deleteContractorMixType
  >;
  updateItems: OmitFirstArg<
    typeof updateContractorAdditiveTypes | typeof updateContractorMixTypes
  >;
  updateItemsSorting:
    | typeof updateContractorAdditiveTypesSorting
    | typeof updateContractorMixTypesSorting;
  resetItems:
    | typeof resetContractorAdditiveTypes
    | typeof resetContractorMixTypes;
  successDeleteMessage: string;
  itemNameColumnLabel: string;
  itemCategoriesColumnLabel: string;
  deleteConfirmationTitle: string;
  deleteConfirmationText: string;
  tableHeader: string;
  addButtonLabel: string;
  loadMoreButtonLabel: string;
  emptyStateText: string;
  AssignConcreteCategoriesPopup?: (...args: any[]) => JSX.Element;
  isReadOnly?: boolean;
};

export const CommonTab = ({
  items,
  itemsCount,
  itemsLoaded,
  loadItemsPending,
  loadMoreItemsPending,
  sorting,
  successDeleteMessage,
  itemNameColumnLabel,
  itemCategoriesColumnLabel,
  deleteConfirmationTitle,
  deleteConfirmationText,
  tableHeader,
  addButtonLabel,
  loadMoreButtonLabel,
  emptyStateText,
  contractorId,
  loadItems,
  loadMoreItems,
  deleteItem,
  updateItems,
  updateItemsSorting,
  resetItems,
  AssignConcreteCategoriesPopup,
  isReadOnly,
}: Props) => {
  const { t } = useTranslation();

  const { addNotification } = useNotifications();

  const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] =
    useState(false);
  const [selectedItem, setSelectedItem] = useState<null | ItemT>(null);

  const [isAssignConcreteTypesPopupOpen, setIsAssignConcreteTypesPopupOpen] =
    useState(false);

  const { sortField, sortOrder } = sorting[0];

  const fetchItems = useCallback(
    (
      params?:
        | Partial<APIAdditiveSearchFilter>
        | Partial<APIMixTypeSearchFilter>,
    ) => {
      loadItems(contractorId, params);
    },
    [contractorId, loadItems],
  );

  useEffect(() => {
    if (!itemsLoaded) fetchItems();
  }, [fetchItems, itemsLoaded]);

  useEffect(() => {
    return () => {
      resetItems();
    };
  }, [resetItems]);

  const onLoadMoreClicked = useCallback(() => {
    loadMoreItems(contractorId);
  }, [contractorId, loadMoreItems]);

  const onColumnHeaderClicked = useCallback(
    (
      columnId: ContractorMixTypesColumnId | ContractorAdditiveTypesColumnId,
    ) => {
      const searchSortOrders = [
        {
          sortField: columnId,
          sortOrder: getSortOrder(columnId, sortField, sortOrder),
        },
      ];
      fetchItems({ searchSortOrders });
      updateItemsSorting(searchSortOrders);
    },
    [sortField, sortOrder, updateItemsSorting, fetchItems],
  );

  const onDeleteItem = useCallback(async () => {
    if (!selectedItem) {
      return;
    }
    setShowDeleteConfirmationDialog(false);
    const result = await deleteItem(contractorId, selectedItem.id);
    setSelectedItem(null);
    if (result.error) {
      addNotification({
        severity: NotificationSeverity.Error,
        message: getServerErrorTitle(result) || t('common.generalError'),
      });
    } else {
      addNotification({
        message: successDeleteMessage,
      });
      updateItems(contractorId);
    }
  }, [
    selectedItem,
    deleteItem,
    contractorId,
    addNotification,
    t,
    successDeleteMessage,
    updateItems,
  ]);

  const columns = useMemo(() => {
    const readOnlyColumns = [
      {
        id: 'name',
        dataId: 'name' as const,
        label: itemNameColumnLabel,
        formatter: (value: ItemT['name']) => ({
          value: value || '',
          element: <TableCellText>{value || ''}</TableCellText>,
        }),
      },
      {
        id: 'categories',
        dataId: 'categories' as const,
        sortable: false,
        label: itemCategoriesColumnLabel,
        formatter: (value: ItemT['categories']) => {
          const displayValue = value?.map(it => it.name).join(', ');
          return {
            value: displayValue,
            element: <TableCellText>{displayValue}</TableCellText>,
          };
        },
      },
    ];

    const actionButtons = {
      id: 'action-buttons',
      width: '7.2rem',
      cellStyles: ActionButtonsCellStyles,
      formatter: (value: string, item: ItemT) => {
        return {
          value: '',
          element: (
            <IconButton
              onClick={() => {
                setSelectedItem(item);
                setShowDeleteConfirmationDialog(true);
              }}
            >
              <HighlightOffIcon color="primary" />
            </IconButton>
          ),
        };
      },
    };

    return isReadOnly ? readOnlyColumns : [...readOnlyColumns, actionButtons];
  }, [itemCategoriesColumnLabel, itemNameColumnLabel, isReadOnly]);

  return (
    <Wrapper direction="column">
      <ConfirmationDialog
        onClose={() => {
          setSelectedItem(null);
          setShowDeleteConfirmationDialog(false);
        }}
        open={showDeleteConfirmationDialog}
        handleActionClick={onDeleteItem}
        title={deleteConfirmationTitle}
        actionButtonTitle={t('common.yes.delete')}
        cancelButtonTitle={t('common.no.cancel')}
        description={deleteConfirmationText}
      />
      <HeaderWrapper direction="row">
        <Header variant="h5">{tableHeader}</Header>
        {!isReadOnly && (
          <AddButton
            onClick={() => setIsAssignConcreteTypesPopupOpen(true)}
            label={addButtonLabel}
          />
        )}
      </HeaderWrapper>
      {!itemsLoaded && (
        <LoadingContainer>
          <CircularProgress sx={{ margin: '5rem' }} />
        </LoadingContainer>
      )}
      {itemsLoaded && itemsCount > 0 && (
        <Table
          infiniteScroll
          tableMinHeight="20rem"
          components={tableComponents}
          columns={columns}
          items={items}
          itemsTotalCount={itemsCount}
          itemsLoaded={itemsLoaded}
          loadItemsPending={loadItemsPending}
          loadMoreItemsPending={loadMoreItemsPending}
          onLoadMoreClicked={onLoadMoreClicked}
          sortField={sortField}
          sortOrder={sortOrder}
          onColumnHeaderClicked={onColumnHeaderClicked}
          loadMoreButtonText={loadMoreButtonLabel}
        />
      )}
      {itemsLoaded && itemsCount === 0 && (
        <EmptyState>{emptyStateText}</EmptyState>
      )}
      {contractorId && AssignConcreteCategoriesPopup && (
        <AssignConcreteCategoriesPopup
          companyId={contractorId}
          onClose={() => setIsAssignConcreteTypesPopupOpen(false)}
          onAdd={() => loadItems(contractorId)}
          isOpen={isAssignConcreteTypesPopupOpen}
        />
      )}
    </Wrapper>
  );
};
