import React from 'react';

import styled from 'styled-components';

import { ButtonBase, Typography } from 'components';

import { EditButton } from 'components/EditButton/EditButton';

export const SectionTitle = styled(Typography)<{ variant: string }>`
  display: inline-block;
  align-items: ${props => (props.variant === 'h2' ? 'baseline' : 'center')};
  white-space: pre-wrap;
  word-break: break-word;
  color: ${props => props.theme.custom.palette.secondary500};
  padding-right: 1rem;
  &:hover {
    cursor: pointer;
    color: ${props => props.theme.custom.palette.primary500};
    svg {
      color: ${props => props.theme.custom.palette.primary500};
    }
  }
`;

export const StyledEditButton = styled(ButtonBase)`
  flex: 1;
  text-align: left;
  margin-bottom: ${props => props.theme.custom.paddings.componentPaddingSmall};
  color: ${props => props.theme.custom.palette.absoluteDark};
`;

interface Props {
  sectionTitle: string;
  handleOnClick: (value: boolean) => void;
  disableEditing?: boolean;
  titleVariant?: 'h5' | 'h2';
}

export const EditableDetailsHeaderButton: React.FC<Props> = props => {
  const {
    sectionTitle,
    handleOnClick,
    disableEditing,
    titleVariant = 'h5',
  } = props;

  return (
    <StyledEditButton
      disabled={disableEditing}
      onClick={() => handleOnClick(true)}
    >
      <SectionTitle variant={titleVariant}>{sectionTitle}</SectionTitle>
      <EditButton />
    </StyledEditButton>
  );
};
