import {
  ON_TRUCK_LOCATION_UPDATE_EVENT,
  communicationsHubEndpoint,
  ordersHubEndpoint,
} from '@cd3p/core/constants/hubs';
import { log } from '@cd3p/core/utils/logger';

import { getUserToken } from './auth';

import { ISubscriptions, signalrFactory } from 'third-party';

type CacheFunction = {
  function: (...args: any) => any;
  method: string;
  args: any[];
};

const cache: CacheFunction[] = [];

let isHidden = false;
let lastActiveTime: number | null = null;
const INACTIVE_REFRESH_MINUTES = 20;

document.addEventListener('visibilitychange', function () {
  isHidden = document.hidden;
  if (isHidden) {
    lastActiveTime = Date.now();
    return;
  }

  // if the tab was inactive for a long time, we need to refresh the page
  if (
    lastActiveTime &&
    Date.now() - lastActiveTime > INACTIVE_REFRESH_MINUTES * 60 * 1000
  ) {
    cache.length = 0;
    log('site was refresh due to a long inactivity');
    window.location.reload();
    return;
  }

  // execute all cached actions
  cache.forEach(it => it.function());
  cache.length = 0;
});

function middleware(actions: ISubscriptions): ISubscriptions {
  // method: string, func: any
  return {
    method: actions.method,
    action: function (...args: any) {
      const { method, action } = actions;
      if (!isHidden) {
        return action(...args);
      }

      const event = {
        function: () => action(...args),
        method: method,
        args: args,
      };

      // since track events are fired every second, we need to prevent update of the same truck twice
      // we check this by comparing the name of the event and the second argument which is the truck id
      if (method === ON_TRUCK_LOCATION_UPDATE_EVENT) {
        const index = cache.findIndex(
          it => it.method === method && it.args[1] === args[1],
        );

        if (index !== -1) {
          log('replaced value in cache for truck: ' + args[1]);
          cache[index] = event;
          return;
        }
      }

      cache.push(event);

      return action(...args);
    },
  };
}

const signalrConfig = {
  tokenFactory: getUserToken,
  middleware: middleware,
};

export const useOrdersSockets = signalrFactory(
  signalrConfig,
  ordersHubEndpoint(),
);

export const useCommunicationsSockets = signalrFactory(
  signalrConfig,
  communicationsHubEndpoint(),
);
