import React, { useMemo } from 'react';

import styled from 'styled-components';

import { Tab, Tabs, TabsBottomLine, Typography } from 'components';

import {
  Navigate,
  Route,
  Routes,
  useMatch,
  useNavigate,
  useTranslation,
} from 'third-party';

import {
  COMPANY_SETTINGS_PATH,
  settingsCompanySettingsUrl,
  settingsIntegrationsUrl,
  settingsUrl,
  settingsUserSettingsUrl,
} from 'constants/url';

import { CompanySettingsTab } from 'features/PlantSettings/CompanySettingsTab/CompanySettingsTab';

import { TabTitle, TabWrapper, TabsWrapper } from 'styles/theme';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 0;
  overflow: hidden;
  padding: ${props => props.theme.custom.dimensions.viewVerticalPadding}rem 0 0;
`;

const HeaderContainer = styled.div`
  padding: 0 ${props => props.theme.custom.dimensions.viewHorizontalPadding}rem;
`;

const Header = styled(Typography)`
  color: ${props => props.theme.custom.palette.secondary500};
  margin-bottom: 2rem;
`;

const StyledTab = styled(Tab)`
  flex-grow: initial;
  padding: 0 5rem;
`;

enum SettingsTabs {
  CompanySettings = 'companySettings',
  Integrations = 'integrations',
  UserSettings = 'userSettings',
}

export const SettingsView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const matchIndexUrl = useMatch(settingsUrl());
  const matchIntegrationsUrl = useMatch(settingsIntegrationsUrl());
  const matchCompanySettingsUrl = useMatch(settingsCompanySettingsUrl());
  const matchUserSettingsUrl = useMatch(settingsUserSettingsUrl());

  const currentTab = useMemo(() => {
    switch (true) {
      case !!matchIntegrationsUrl:
        return SettingsTabs.Integrations;
      case !!matchUserSettingsUrl:
        return SettingsTabs.UserSettings;
      case !!matchCompanySettingsUrl || !!matchIndexUrl:
      default:
        return SettingsTabs.CompanySettings;
    }
  }, [
    matchIntegrationsUrl,
    matchUserSettingsUrl,
    matchCompanySettingsUrl,
    matchIndexUrl,
  ]);

  const tabs = useMemo(
    () => [
      {
        title: t('settings.companySettingsTab'),
        value: SettingsTabs.CompanySettings,
        onClick: () =>
          navigate(settingsCompanySettingsUrl(), { replace: true }),
      },
      // {
      //   title: t('settings.integrationsTab'),
      //   value: SettingsTabs.Integrations,
      //   onClick: () => navigate(settingsIntegrationsUrl(), { replace: true }),
      // },
      // {
      //   title: t('settings.userSettingsTab'),
      //   value: SettingsTabs.UserSettings,
      //   onClick: () => navigate(settingsUserSettingsUrl(), { replace: true }),
      // },
    ],
    [t, navigate],
  );

  return (
    <Wrapper>
      <HeaderContainer>
        <Header variant="h4">{t('settings.title')}</Header>
      </HeaderContainer>
      <TabsWrapper>
        <Tabs value={currentTab}>
          {tabs.map(tab => {
            return (
              <StyledTab
                key={tab.title}
                value={tab.value}
                label={
                  <TabWrapper>
                    <TabTitle>{tab.title}</TabTitle>
                  </TabWrapper>
                }
                onClick={tab.onClick}
              />
            );
          })}
          <TabsBottomLine />
        </Tabs>
        <Routes>
          <Route
            path={COMPANY_SETTINGS_PATH}
            element={<CompanySettingsTab />}
          />
          {/*<Route path={INTEGRATIONS_PATH} element={<AdditiveTypesView />} />*/}
          {/*<Route path={USER_SETTINGS_PATH} element={<PlantsView />} />*/}
        </Routes>
        {matchIndexUrl && (
          <Navigate to={settingsCompanySettingsUrl()} replace />
        )}
      </TabsWrapper>
    </Wrapper>
  );
};
