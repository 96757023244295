import { useMemo } from 'react';

import { httpMethod, simplifyBuilder } from '@cd3p/core';
import {
  APIMasterMixType,
  APIMasterMixTypeModelSearchResult,
  APIMasterMixTypeRequest,
  APIMixTypeSearchFilter,
  APIMixTypeUsage,
  APIMostUsedMixTypes,
  APISortOrder,
  APIUsage,
  APIUsageSearchResult,
} from '@cd3p/core/types/api';
import {
  DEFAULT_PAGE_SIZE,
  getPageNumberAfterLoadMore,
  getPageSizeDependingOnItems,
} from '@cd3p/core/utils/lists';

import { _, bindActionCreators, useDispatch, useSelector } from 'third-party';

import {
  loadLeastUsedMixTypesEndpoint,
  loadMixTypesAssignmentEndpoint,
  loadMixTypesEndpoint,
  loadMostUsedMixTypesEndpoint,
  mixTypeEndpoint,
} from 'constants/endpoints';

import { State } from 'modules/index';

import { appSelectors } from 'selectors';

export type MixTypesListColumnId = keyof APIMasterMixType;

export type MixTypesSorting = {
  sortField: MixTypesListColumnId;
  sortOrder: APISortOrder;
}[];

const defaultMixTypesFilters = {
  name: '',
};

export type MixTypesFilters = typeof defaultMixTypesFilters;

export type MixTypesListState = {
  items: APIMasterMixTypeModelSearchResult['result'];
  projectsMixTypesItems: APIMasterMixType[];
  mixTypesLoaded: boolean;
  projectsMixTypesLoaded: boolean;
  createMixTypePending: boolean;
  updateMixTypePending: boolean;
  loadMixTypesPending: boolean;
  loadMoreMixTypesPending: boolean;
  loadProjectsMixTypesPending: boolean;
  loadMoreProjectsMixTypesPending: boolean;
  sorting: MixTypesSorting;
  pagination: {
    pageNumber: APIMasterMixTypeModelSearchResult['pageNumber'];
    pageSize: APIMasterMixTypeModelSearchResult['pageSize'];
  };
  projectsMixTypesPagination: {
    pageNumber: APIMasterMixTypeModelSearchResult['pageNumber'];
    pageSize: APIMasterMixTypeModelSearchResult['pageSize'];
  };
  filters: MixTypesFilters;
  count: number;
  projectsMixTypesCount: number;
  projectsMixTypesSearch: string;
  mixTypeAssignment: APIMixTypeUsage[] | null;
  mixTypeAssignmentId: null | number;
  loadMixTypesAssignmentPending: boolean;
  addMixTypeToMasterListPending: boolean;
  mostUsedMixTypes: null | APIMostUsedMixTypes;
  loadMostUsedMixTypesPending: boolean;
  leastUsedMixTypes: {
    count: number;
    items: APIUsage[];
    mixTypesLoaded: boolean;
    pagination: { pageNumber: number; pageSize: number };
  };
  loadLeastUsedMixTypesPending: boolean;
  loadMoreLeastUsedMixTypesPending: boolean;
  fetchMixTypePending: boolean;
};

export const mixTypesListState: MixTypesListState = {
  items: [],
  projectsMixTypesItems: [],
  sorting: [
    {
      sortField: 'name',
      sortOrder: APISortOrder.ASC,
    },
  ],
  filters: defaultMixTypesFilters,
  mixTypesLoaded: false,
  projectsMixTypesLoaded: false,
  createMixTypePending: false,
  updateMixTypePending: false,
  loadMoreMixTypesPending: false,
  loadMixTypesPending: false,
  loadProjectsMixTypesPending: false,
  loadMoreProjectsMixTypesPending: false,
  addMixTypeToMasterListPending: false,
  pagination: { pageNumber: 1, pageSize: DEFAULT_PAGE_SIZE },
  projectsMixTypesPagination: {
    pageNumber: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  },
  count: 0,
  projectsMixTypesCount: 0,
  projectsMixTypesSearch: '',
  mixTypeAssignment: null,
  mixTypeAssignmentId: null,
  loadMixTypesAssignmentPending: false,
  mostUsedMixTypes: null,
  leastUsedMixTypes: {
    count: 0,
    items: [],
    mixTypesLoaded: false,
    pagination: { pageNumber: 1, pageSize: DEFAULT_PAGE_SIZE },
  },
  loadMostUsedMixTypesPending: false,
  loadLeastUsedMixTypesPending: false,
  loadMoreLeastUsedMixTypesPending: false,
  fetchMixTypePending: false,
};
const builder = simplifyBuilder(mixTypesListState, {});

const loadMixTypes = builder.createServerAction(
  (providerId: string, body: APIMixTypeSearchFilter = {}) => ({
    name: 'loadMixTypes',
    url: loadMixTypesEndpoint(providerId),
    body: (state: State) => ({
      pageSize: state.mixTypesList.pagination.pageSize,
      pageNumber: state.mixTypesList.pagination.pageNumber,
      ...state.mixTypesList.filters,
      ...body,
    }),
    method: httpMethod.post,
    onSuccess: (
      state: MixTypesListState,
      payload: APIMasterMixTypeModelSearchResult,
    ) => {
      return {
        items: payload.result || [],
        mixTypesLoaded: true,
        pagination: {
          pageNumber: payload.pageNumber,
          pageSize: payload.pageSize,
        },
        count: payload.count,
      };
    },
  }),
);

const loadMoreMixTypes = builder.createServerAction((providerId: string) => ({
  name: 'loadMoreMixTypes',
  url: loadMixTypesEndpoint(providerId),
  body: (state: State) => ({
    pageNumber: getPageNumberAfterLoadMore(
      state.mixTypesList.items,
      state.mixTypesList.pagination.pageSize,
    ),
    pageSize: state.mixTypesList.pagination.pageSize,
    searchSortOrders: state.mixTypesList.sorting,
    ...state.mixTypesList.filters,
  }),
  method: httpMethod.post,
  onSuccess: (
    state: MixTypesListState,
    payload: APIMasterMixTypeModelSearchResult,
  ) => {
    return {
      items: [...state.items, ...payload.result],
    };
  },
}));

export const loadProjectsMixTypes = builder.createServerAction(
  (providerId: string, body: APIMixTypeSearchFilter = {}) => ({
    name: 'loadProjectsMixTypes',
    url: loadMixTypesEndpoint(providerId),
    body: (state: State) => ({
      pageSize: state.mixTypesList.projectsMixTypesPagination.pageSize,
      pageNumber: state.mixTypesList.projectsMixTypesPagination.pageNumber,
      isProjectSpecific: true,
      searchSortOrders: state.mixTypesList.sorting,
      ...state.mixTypesList.filters,
      ...body,
    }),
    method: httpMethod.post,
    onSuccess: (
      state: MixTypesListState,
      payload: APIMasterMixTypeModelSearchResult,
    ) => {
      return {
        projectsMixTypesItems: payload.result || [],
        projectsMixTypesPagination: {
          pageNumber: payload.pageNumber,
          pageSize: payload.pageSize,
        },
        projectsMixTypesSearch: body.name || '',
        projectsMixTypesCount: payload.count,
        projectsMixTypesLoaded: true,
      };
    },
  }),
);

export const loadMoreProjectsMixTypes = builder.createServerAction(
  (providerId: string, body: APIMixTypeSearchFilter = {}) => ({
    name: 'loadMoreProjectsMixTypes',
    url: loadMixTypesEndpoint(providerId),
    body: (state: State) => ({
      pageNumber: getPageNumberAfterLoadMore(
        state.mixTypesList.projectsMixTypesItems,
        state.mixTypesList.projectsMixTypesPagination.pageSize,
      ),
      pageSize: state.mixTypesList.projectsMixTypesPagination.pageSize,
      isProjectSpecific: true,
      searchSortOrders: state.mixTypesList.sorting,
      ...state.mixTypesList.filters,
      ...body,
    }),
    method: httpMethod.post,
    onSuccess: (
      state: MixTypesListState,
      payload: APIMasterMixTypeModelSearchResult,
    ) => {
      return {
        projectsMixTypesItems: [
          ...state.projectsMixTypesItems,
          ...payload.result,
        ],
        projectsMixTypesSearch: body.name || '',
      };
    },
  }),
);

const updateMixTypes = builder.createServerAction((providerId: string) => ({
  name: 'updateMixTypes',
  url: loadMixTypesEndpoint(providerId),
  body: (state: State) => ({
    pageNumber: 1,
    pageSize: getPageSizeDependingOnItems(state.mixTypesList.items),
    searchSortOrders: state.mixTypesList.sorting,
    ...state.mixTypesList.filters,
  }),
  method: httpMethod.post,
  onSuccess: (
    state: MixTypesListState,
    payload: APIMasterMixTypeModelSearchResult,
  ) => {
    return {
      items: payload.result,
      count: payload.count,
    };
  },
}));

const fetchMixType = builder.createServerAction(
  (providerId: string, mixTypeId: number) => ({
    name: 'fetchMixType',
    url: mixTypeEndpoint(providerId, mixTypeId),
    method: httpMethod.get,
  }),
);

export const UPDATE_MIX_TYPE_ACTION = 'updateMixType';
export const updateMixType = builder.createServerAction(
  (providerId: string, mixTypeId: number, body: APIMasterMixType) => ({
    name: UPDATE_MIX_TYPE_ACTION,
    url: mixTypeEndpoint(providerId, mixTypeId),
    body: body,
    method: httpMethod.put,
  }),
);

export const addMixTypeToMasterList = builder.createServerAction(
  (providerId: string, mixTypeId: number, body: APIMasterMixType) => ({
    name: 'addMixTypeToMasterList',
    url: mixTypeEndpoint(providerId, mixTypeId),
    body: { ...body, projectId: null },
    method: httpMethod.put,
  }),
);

export const DELETE_MIX_TYPE_ACTION = 'deleteMixType';
const deleteMixType = builder.createServerAction(
  (providerId: string, mixTypeId: number) => ({
    name: DELETE_MIX_TYPE_ACTION,
    url: mixTypeEndpoint(providerId, mixTypeId),
    method: httpMethod.delete,
  }),
);

const updateMixTypesListSorting = builder.createReduxAction(
  (newSorting: MixTypesListState['sorting']) => ({
    name: 'updateMixTypesListSorting',
    updater: () => ({
      sorting: newSorting,
    }),
  }),
);

const updateMixTypesListFilters = builder.createReduxAction(
  (newFilters: MixTypesListState['filters']) => ({
    name: 'updateMixTypesListFilters',
    updater: state => ({
      filters: {
        ...state.filters,
        ...newFilters,
      },
    }),
  }),
);

const loadMixTypesAssignment = builder.createServerAction(
  (providerId: string, mixTypeId: number) => ({
    name: 'loadMixTypesAssignment',
    url: loadMixTypesAssignmentEndpoint(providerId, mixTypeId),
    method: httpMethod.get,
    onSuccess: (
      state: MixTypesListState,
      payload: APIMasterMixTypeModelSearchResult,
    ) => {
      return {
        mixTypeAssignment: payload || [],
        mixTypeAssignmentId: mixTypeId,
      };
    },
  }),
);

const resetMixTypeAssignment = builder.createReduxAction(() => ({
  name: 'resetMixTypeAssignment',
  updater: state => ({
    ...state,
    mixTypeAssignment: null,
    loadMixTypesPending: false,
    mixTypeAssignmentCount: 0,
    mixTypeAssignmentId: null,
  }),
}));

const loadMostUsedMixTypes = builder.createServerAction(
  (providerId: string) => ({
    name: 'loadMostUsedMixTypes',
    url: loadMostUsedMixTypesEndpoint(providerId),
    method: httpMethod.get,
    onSuccess: (_state: MixTypesListState, payload: APIMostUsedMixTypes) => ({
      mostUsedMixTypes: payload,
    }),
  }),
);

const loadLeastUsedMixTypes = builder.createServerAction(
  (providerId: string) => ({
    name: 'loadLeastUsedMixTypes',
    url: loadLeastUsedMixTypesEndpoint(providerId),
    body: () => ({
      pageSize: mixTypesListState.leastUsedMixTypes.pagination.pageSize,
      pageNumber: mixTypesListState.leastUsedMixTypes.pagination.pageNumber,
    }),
    method: httpMethod.post,
    onSuccess: (state: MixTypesListState, payload: APIUsageSearchResult) => {
      return {
        leastUsedMixTypes: {
          ...state.leastUsedMixTypes,
          items: payload.result || [],
          mixTypesLoaded: true,
          pagination: {
            pageNumber: payload.pageNumber,
            pageSize: payload.pageSize,
          },
          count: payload.count,
        },
      };
    },
  }),
);

const loadMoreLeastUsedMixTypes = builder.createServerAction(
  (providerId: string) => ({
    name: 'loadMoreLeastUsedMixTypes',
    url: loadLeastUsedMixTypesEndpoint(providerId),
    body: (state: State) => ({
      pageNumber: getPageNumberAfterLoadMore(
        state.mixTypesList.leastUsedMixTypes.items,
        state.mixTypesList.leastUsedMixTypes.pagination.pageSize,
      ),
      pageSize: state.mixTypesList.leastUsedMixTypes.pagination.pageSize,
    }),
    method: httpMethod.post,
    onSuccess: (state: MixTypesListState, payload: APIUsageSearchResult) => {
      return {
        leastUsedMixTypes: {
          ...state.leastUsedMixTypes,
          items: _.uniqBy(
            [...state.leastUsedMixTypes.items, ...payload.result],
            'id',
          ),
          count: payload.count,
        },
      };
    },
  }),
);

const resetMixTypes = builder.createReduxAction(() => ({
  name: 'resetMixTypes',
  updater: () => mixTypesListState,
}));

const resetProjectMixTypes = builder.createReduxAction(() => ({
  name: 'resetProjectMixTypes',
  updater: state => ({
    ...state,
    projectsMixTypesItems: [],
    projectsMixTypesPagination: mixTypesListState.projectsMixTypesPagination,
    projectsMixTypesCount: 0,
    loadProjectsMixTypesPending: false,
    loadMoreProjectsMixTypesPending: false,
    projectsMixTypesLoaded: false,
  }),
}));

const createMixType = builder.createServerAction(
  (providerId: string, body: APIMasterMixTypeRequest) => ({
    name: 'createMixType',
    url: mixTypeEndpoint(providerId),
    body,
    method: httpMethod.post,
  }),
);

export const useMixTypesList = () => {
  const dispatch = useDispatch();
  const providerId = useSelector(appSelectors.providerId);

  return useMemo(
    () =>
      bindActionCreators(
        {
          loadMixTypes: loadMixTypes.bind(null, providerId),
          loadMoreMixTypes: loadMoreMixTypes.bind(null, providerId),
          updateMixTypes: updateMixTypes.bind(null, providerId),
          createMixType: createMixType.bind(null, providerId),
          updateMixTypesListSorting: updateMixTypesListSorting.bind(null),
          updateMixTypesListFilters: updateMixTypesListFilters.bind(null),
          resetMixTypes: resetMixTypes.bind(null),
          resetMixTypeAssignment: resetMixTypeAssignment.bind(null),
          fetchMixType: fetchMixType.bind(null, providerId),
          updateMixType: updateMixType.bind(null, providerId),
          deleteMixType: deleteMixType.bind(null, providerId),
          loadMixTypesAssignment: loadMixTypesAssignment.bind(null, providerId),
          loadProjectsMixTypes: loadProjectsMixTypes.bind(null, providerId),
          loadMostUsedMixTypes: loadMostUsedMixTypes.bind(null, providerId),
          loadLeastUsedMixTypes: loadLeastUsedMixTypes.bind(null, providerId),
          loadMoreLeastUsedMixTypes: loadMoreLeastUsedMixTypes.bind(
            null,
            providerId,
          ),
          loadMoreProjectsMixTypes: loadMoreProjectsMixTypes.bind(
            null,
            providerId,
          ),
          resetProjectMixTypes: resetProjectMixTypes.bind(null),
          addMixTypeToMasterList: addMixTypeToMasterList.bind(null, providerId),
        },
        dispatch,
      ),
    [dispatch, providerId],
  );
};

export const mixTypesListReducer = builder.getReducers();
