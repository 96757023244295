import React, { FocusEventHandler, useCallback } from 'react';

import styled from 'styled-components';

import { TextFieldProps } from '@mui/material/TextField/TextField';
import { Autocomplete, Popper, TextField } from 'components';
import dayjs from 'dayjs';

import { useTranslation } from 'third-party';

const OPTION_LABEL_FORMAT = 'hh:mm A';

const StyledAutocomplete = styled(Autocomplete)``;

const StyledPopper = styled(Popper)`
  .MuiAutocomplete-option {
    padding-right: 1.6rem;
    padding-left: 1.6rem;
    max-height: 20rem;
    color: ${props => props.theme.custom.palette.primary900};
    font-size: 1.6rem;
    white-space: nowrap;
  }
  .MuiAutocomplete-paper {
    border-radius: 1rem;
  }
  .MuiAutocomplete-listbox {
    border-width: 2px;
    border-style: solid;
    border-color: ${props => props.theme.custom.palette.primary500};
    border-radius: 1rem;
    background: ${props => props.theme.custom.palette.backgroundThemeLight};
    max-height: 20rem;
  }
`;

type OptionType = {
  value: string;
  label: string;
};

const generateOptions = (minutesStep = 15): OptionType[] => {
  let current = dayjs().startOf('date');
  const endDate = dayjs().endOf('date');

  const result = [];

  while (current <= endDate) {
    const label = dayjs(current).format(OPTION_LABEL_FORMAT);
    result.push({
      value: label,
      label,
    });
    current = current.add(minutesStep, 'minute');
  }
  return result;
};

const options: OptionType[] = generateOptions();
const OPTIONS_DEFAULT_VALUE = dayjs()
  // 12 PM
  .startOf('date')
  .add(12, 'hours')
  .format(OPTION_LABEL_FORMAT);

const getListValueOptionToScrollTo = (time: string) => {
  const formatData = 'YYYY-MM-DD';
  const formatTime = 'hh:mm A';
  return (
    dayjs(
      `${dayjs().format(formatData)} ${time?.toUpperCase()}`,
      `${formatData} ${formatTime}`,
      true,
    )
      // scroll to the beginning of the hour
      .startOf('hour')
      .format(formatTime)
  );
};

type Props = {
  onChange: (value: string) => void;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  value: string;
  error: boolean;
  disabled?: boolean;
  inputProps?: TextFieldProps['inputProps'];
  placeholder?: string;
};

export const TimePicker: React.FC<Props> = ({
  onChange,
  onBlur,
  value,
  error,
  disabled,
  inputProps = {},
  placeholder,
  ...props
}) => {
  const { t } = useTranslation();

  const onInputChangeCallback = useCallback(
    (event: any, newValue: string) => {
      if (newValue !== value?.toUpperCase()) {
        onChange((newValue || '').toUpperCase());
      }
    },
    [value, onChange],
  );

  const selectedValue = options.find(it => it.value === value?.toUpperCase());

  return (
    <StyledAutocomplete
      freeSolo
      disableClearable
      forcePopupIcon={true}
      options={options}
      onInputChange={onInputChangeCallback}
      disabled={disabled}
      value={selectedValue || value?.toUpperCase()}
      PopperComponent={StyledPopper}
      onOpen={() => {
        setTimeout(() => {
          const optionEl = document.querySelector(
            `[data-name="${getListValueOptionToScrollTo(
              value || OPTIONS_DEFAULT_VALUE,
            )}"]`,
          );
          optionEl?.scrollIntoView();
        }, 1);
      }}
      renderOption={(props, option) => {
        return (
          <li {...props} data-name={option.label}>
            {option.label}
          </li>
        );
      }}
      renderInput={params => (
        // @ts-ignore
        <TextField
          variant="outlined"
          {...params}
          size="small"
          onBlur={onBlur}
          error={error}
          placeholder={placeholder || t('common.form.time.placeholder')}
          inputProps={{
            ...params.inputProps,
            ...inputProps,
          }}
        />
      )}
      {...props}
    />
  );
};
