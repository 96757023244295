import { useMemo } from 'react';

import {
  CoreProjectState,
  coreProjectDefaultState,
  coreProjectMethods,
} from '@cd3p/core/modules/projects';
import { APIProject, APIProjectEditableParams } from '@cd3p/core/types/api';
import { DEFAULT_PAGE_SIZE } from '@cd3p/core/utils/lists';
import { httpMethod, simplifyBuilder } from '@cd3p/core/utils/sra';

import { bindActionCreators, useDispatch, useSelector } from 'third-party';

import {
  additiveTypeEndpoint,
  mixTypeEndpoint,
  projectAdditiveTypeEndpoint,
  projectAvailableAdditiveTypesEndpoint,
  projectAvailableMixTypesEndpoint,
  projectEndpoint,
  projectMixTypeEndpoint,
} from 'constants/endpoints';

import { appSelectors } from 'selectors';

export type ProjectState = CoreProjectState & {
  project: APIProject | null;
  projectLoaded: boolean;
  loadProjectPending: boolean | null;
  projectErrorText: string | null;
  updateProjectPending: boolean;
  createProjectPending: boolean;
  updateProjectError?: Record<string, any>;
};

export const projectState: ProjectState = {
  ...coreProjectDefaultState,
  project: null,
  projectLoaded: false,
  loadProjectPending: null,
  projectErrorText: null,
  updateProjectPending: false,
  createProjectPending: false,
  updateProjectError: undefined,
};

const builder = simplifyBuilder(projectState, {});

const loadProject = builder.createServerAction(
  (providerId: string, projectId: string) => {
    return {
      name: 'loadProject',
      url: projectEndpoint(providerId, projectId),
      method: httpMethod.get,
      onSuccess: (state: ProjectState, payload: APIProject) => {
        return {
          projectLoaded: true,
          project: payload,
        };
      },
      onFailure: () => {
        return {
          projectLoaded: true,
          projectErrorText: 'Error',
        };
      },
    };
  },
);

const updateProjectInState = builder.createReduxAction(
  (project: APIProject) => ({
    name: 'updateProjectInState',
    updater: () => ({
      project,
    }),
  }),
);

const updateProject = builder.createServerAction(
  (
    providerId: string,
    projectId: string,
    companyId: string,
    body: Partial<APIProjectEditableParams>,
  ) => ({
    name: 'updateProject',
    url: projectEndpoint(providerId, projectId),
    method: httpMethod.put,
    body: {
      ...body,
      companyId,
    },
    onSuccess: (state: ProjectState, payload: APIProject) => ({
      project: payload,
    }),
  }),
);

const createProject = builder.createServerAction(
  (
    providerId: string,
    body: Partial<APIProjectEditableParams> & { companyId: string },
  ) => ({
    name: 'createProject',
    url: projectEndpoint(providerId),
    method: httpMethod.post,
    body: body,
    onSuccess: (state: ProjectState, payload: APIProject) => ({
      project: payload,
    }),
  }),
);

const deleteProject = builder.createServerAction(
  (providerId: string, projectId: string) => ({
    name: 'deleteProject',
    url: projectEndpoint(providerId, projectId),
    method: httpMethod.delete,
  }),
);

export const fetchProjectMixTypes = builder.createServerAction(
  (
    providerId: string,
    projectId: string,
    name: string,
    pageNumber?: number,
  ) => ({
    name: 'fetchProjectMixTypes',
    url: projectAvailableMixTypesEndpoint(providerId, projectId),
    method: httpMethod.post,
    body: {
      pageNumber: pageNumber || 1,
      pageSize: DEFAULT_PAGE_SIZE,
      searchSortOrders: [
        {
          sortField: 'name',
          sortOrder: 'ASC',
        },
      ],
      name,
    },
  }),
);

export const fetchProjectAdditiveTypes = builder.createServerAction(
  (
    providerId: string,
    projectId: string,
    name: string,
    pageNumber?: number,
  ) => ({
    name: 'fetchProjectAdditiveTypes',
    url: projectAvailableAdditiveTypesEndpoint(providerId, projectId),
    method: httpMethod.post,
    body: {
      pageNumber: pageNumber || 1,
      pageSize: DEFAULT_PAGE_SIZE,
      searchSortOrders: [
        {
          sortField: 'name',
          sortOrder: 'ASC',
        },
      ],
      name,
    },
  }),
);

export const assignProjectMixType = builder.createServerAction(
  (providerId: string, projectId: string, ids: number | number[]) => ({
    name: 'assignProjectMixType',
    url: projectMixTypeEndpoint(providerId, projectId),
    method: httpMethod.post,
    body: {
      ids: Array.isArray(ids) ? ids : [ids],
    },
  }),
);

export const createProjectMixType = builder.createServerAction(
  (providerId: string, projectId: string, name: string) => ({
    name: 'assignProjectMixType',
    url: mixTypeEndpoint(providerId),
    method: httpMethod.post,
    body: {
      name,
      projectId,
    },
  }),
);

export const assignProjectAdditiveType = builder.createServerAction(
  (providerId: string, projectId: string, ids: number | number[]) => ({
    name: 'assignProjectAdditiveType',
    url: projectAdditiveTypeEndpoint(providerId, projectId),
    method: httpMethod.post,
    body: {
      ids: Array.isArray(ids) ? ids : [ids],
    },
  }),
);

export const createProjectAdditiveType = builder.createServerAction(
  (providerId: string, projectId: string, name: string) => ({
    name: 'createProjectAdditiveType',
    url: additiveTypeEndpoint(providerId),
    method: httpMethod.post,
    body: {
      name,
      projectId,
    },
  }),
);

const addProjectSubscriptions = builder.createServerAction(
  coreProjectMethods.addProjectSubscriptions,
);

const resetProject = builder.createReduxAction(() => ({
  name: 'resetProject',
  updater: () => ({
    projectLoaded: false,
    project: projectState.project,
  }),
}));

export const useProject = () => {
  const dispatch = useDispatch();
  const providerId = useSelector(appSelectors.providerId);

  return useMemo(
    () =>
      bindActionCreators(
        {
          loadProject: loadProject.bind(null, providerId),
          updateProject: updateProject.bind(null, providerId),
          createProject: createProject.bind(null, providerId),
          deleteProject: deleteProject.bind(null, providerId),
          fetchProjectMixTypes: fetchProjectMixTypes.bind(null, providerId),
          assignProjectMixType: assignProjectMixType.bind(null, providerId),
          createProjectMixType: createProjectMixType.bind(null, providerId),
          fetchProjectAdditiveTypes: fetchProjectAdditiveTypes.bind(
            null,
            providerId,
          ),
          assignProjectAdditiveType: assignProjectAdditiveType.bind(
            null,
            providerId,
          ),
          createProjectAdditiveType: createProjectAdditiveType.bind(
            null,
            providerId,
          ),
          // without binding TS doesn't infer type for module functions
          addProjectSubscriptions: addProjectSubscriptions.bind(
            null,
            providerId,
          ),
          updateProjectInState: updateProjectInState.bind(null),
          resetProject: resetProject.bind(null),
        },
        dispatch,
      ),
    [dispatch, providerId],
  );
};

export const projectReducer = builder.getReducers();
