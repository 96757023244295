import { useMemo } from 'react';

import { useLocation } from 'react-router-dom';

import { queryString } from 'third-party';

export const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => queryString.parse(search), [search]);
};
