import { useMemo } from 'react';

import { APIOrderStatus } from '@cd3p/core/types/api';
import { httpMethod, simplifyBuilder } from '@cd3p/core/utils/sra';

import {
  bindActionCreators,
  dayjs,
  useDispatch,
  useSelector,
} from 'third-party';

import { orderSearchEndpoint } from 'constants/endpoints';

import {
  OrdersFilters,
  OrdersListState,
  loadMoreOrders,
  loadOrders,
  ordersDefaultFilters,
  ordersDefaultListState,
  updateOrderPosition,
  updateOrdersListSorting,
} from 'modules/common/ordersList';

import { appSelectors, todayOrdersListSelectors } from 'selectors';

import { composeLoadOrdersRequestBody } from 'utils/ordersList';

export type TodayOrdersListState = OrdersListState & {
  filters: OrdersFilters;
  loadTodayOrdersPending: boolean;
  loadMoreTodayOrdersPending: boolean;
  todayOrdersListErrorText: string | null;
};

export const todayOrdersListState: TodayOrdersListState = {
  ...ordersDefaultListState,
  filters: {
    ...ordersDefaultFilters,
    // show only today's orders
    date: {
      dateFrom: dayjs(new Date()).startOf('date').toDate(),
      dateTo: dayjs(new Date()).endOf('date').toDate(),
    },
    // all orders for today except canceled and completed
    status: {
      [APIOrderStatus.Unconfirmed]: true,
      [APIOrderStatus.Cancelled]: true,
      [APIOrderStatus.Confirmed]: true,
      [APIOrderStatus.Delivering]: true,
      [APIOrderStatus.Completed]: true,
      [APIOrderStatus.Requested]: true,
      [APIOrderStatus.Declined]: true,
    },
  },
  loadTodayOrdersPending: false,
  loadMoreTodayOrdersPending: false,
  todayOrdersListErrorText: null,
};

const builder = simplifyBuilder(todayOrdersListState, {});

export const LOAD_TODAY_ORDERS_ACTION = 'loadTodayOrders';
const loadTodayOrders = builder.createServerAction(
  loadOrders(LOAD_TODAY_ORDERS_ACTION),
);

export const LOAD_MORE_TODAY_ORDERS_ACTION = 'loadMoreTodayOrders';
const loadMoreTodayOrders = builder.createServerAction(
  loadMoreOrders(LOAD_MORE_TODAY_ORDERS_ACTION),
);

export const UPDATE_ORDER_POSITION_IN_TODAY_ORDERS_ACTION =
  'updateOrderPositionInTodayOrders';
const updateOrderPositionInTodayOrders = builder.createServerAction(
  updateOrderPosition(UPDATE_ORDER_POSITION_IN_TODAY_ORDERS_ACTION),
);

const updateTodayOrdersListSorting = builder.createReduxAction(
  updateOrdersListSorting('updateTodayOrdersListSorting'),
);

const loadOrdersForAssignDriver = builder.createServerAction(
  (providerId: string) => ({
    name: 'loadOrdersForAssignDriver',
    url: orderSearchEndpoint(providerId),
    body: () =>
      composeLoadOrdersRequestBody(providerId, {
        pageNumber: 1,
        pageSize: 0,
        filters: {
          ...todayOrdersListState.filters,
          status: {
            [APIOrderStatus.Unconfirmed]: false,
            [APIOrderStatus.Cancelled]: false,
            [APIOrderStatus.Confirmed]: true,
            [APIOrderStatus.Delivering]: true,
            [APIOrderStatus.Completed]: false,
            [APIOrderStatus.Requested]: false,
            [APIOrderStatus.Declined]: false,
          },
        },
        sorting: todayOrdersListState.sorting,
      }),
    method: httpMethod.post,
  }),
);

export const useTodayOrdersList = () => {
  const dispatch = useDispatch();
  const providerId = useSelector(appSelectors.providerId);

  return useMemo(
    () =>
      bindActionCreators(
        {
          loadTodayOrders: loadTodayOrders.bind(null, providerId),
          loadMoreTodayOrders: loadMoreTodayOrders.bind(
            null,
            providerId,
            todayOrdersListSelectors.todayOrderListState,
            todayOrdersListSelectors.todayOrdersListFilters,
          ),
          updateOrder: updateOrderPositionInTodayOrders.bind(null, providerId),
          updateOrderPositionInTodayOrders:
            updateOrderPositionInTodayOrders.bind(
              null,
              providerId,
              todayOrdersListSelectors.todayOrderListState,
              todayOrdersListSelectors.todayOrdersListFilters,
            ),
          loadOrdersForAssignDriver: loadOrdersForAssignDriver.bind(
            null,
            providerId,
          ),
          // without binding TS doesn't infer type for module functions
          updateTodayOrdersListSorting: updateTodayOrdersListSorting.bind(null),
        },
        dispatch,
      ),
    [dispatch, providerId],
  );
};

export const todayOrdersListReducer = builder.getReducers();
