import { useMemo } from 'react';

import {
  APISortOrder,
  APITrackingItem,
  APITrackingParams,
} from '@cd3p/core/types/api';
import { TrackingItem } from '@cd3p/core/types/common';
import { httpMethod, simplifyBuilder } from '@cd3p/core/utils/sra';

import { _, bindActionCreators, useDispatch, useSelector } from 'third-party';

import { trackingByIdEndpoint, trackingEndpoint } from 'constants/endpoints';

import { appSelectors } from 'selectors';

export type TrackingItemColumnId = keyof TrackingItem;

type SortingT = {
  sortField: TrackingItemColumnId;
  sortOrder: APISortOrder;
};

export interface TrackingState {
  items: { [truckId: string]: TrackingItem };
  trackingLoaded: boolean;
  loadTrackingPending: boolean;
  updateTruckLocationPending: boolean;
  sorting: SortingT;
}

export const trackingState: TrackingState = {
  items: {},
  trackingLoaded: false,
  loadTrackingPending: false,
  updateTruckLocationPending: false,
  sorting: {
    sortField: 'driverFirstName',
    sortOrder: APISortOrder.DESC,
  },
};

const builder = simplifyBuilder(trackingState, {});

const loadTracking = builder.createServerAction(
  (providerId: string, body: Partial<APITrackingParams> = {}) => ({
    name: 'loadTracking',
    url: trackingEndpoint(providerId),
    method: httpMethod.post,
    body,
    onSuccess: (state: TrackingState, payload: APITrackingItem[]) => ({
      trackingLoaded: true,
      items: payload.reduce(
        (result, it) => ({
          ...result,
          [it.truckNumber]: it,
        }),
        {},
      ),
    }),
  }),
);

const updateTruckLocation = builder.createServerAction(
  (providerId: string, truckId: string, truckLocation: APITrackingItem) => ({
    name: 'updateTruckLocation',
    url: trackingByIdEndpoint(providerId, truckId),
    method: httpMethod.put,
    body: truckLocation,
    onSuccess: (state: TrackingState, payload: APITrackingItem) => ({
      ...state,
      items: {
        ...state.items,
        [payload.truckNumber]: payload,
      },
      updateTruckLocationPending: false,
    }),
    onFailure: (state: TrackingState) => ({
      ...state,
      updateTruckLocationPending: false,
    }),
  }),
);

const updateTrackingSorting = builder.createReduxAction(
  (newSorting: SortingT) => ({
    name: 'updateTrackingSorting',
    updater: () => ({ sorting: newSorting }),
  }),
);

const updateTruckInList = builder.createReduxAction(
  (truckNumber: string, updatedTruck: Partial<TrackingItem>) => ({
    name: 'updateTrackingInList',
    updater: state => ({
      ...state,
      items: {
        ...state.items,
        [truckNumber]: {
          ...state.items[truckNumber],
          ...updatedTruck,
        },
      },
    }),
  }),
);

export const updateTracking = builder.createReduxAction(
  (trackingData: APITrackingItem | APITrackingItem[]) => ({
    name: 'updateTracking',
    updater: state => {
      const updatedData = (
        _.isArray(trackingData) ? trackingData : [trackingData]
      ).reduce(
        (result, it) => ({
          [it.truckNumber]: it,
        }),
        {},
      );
      return {
        ...state,
        items: {
          ...state.items,
          ...updatedData,
        },
      };
    },
  }),
);

const resetTracking = builder.createReduxAction(() => ({
  name: 'resetTracking',
  updater: () => trackingState,
}));

export const useTracking = () => {
  const dispatch = useDispatch();
  const providerId = useSelector(appSelectors.providerId);

  return useMemo(
    () =>
      bindActionCreators(
        {
          updateTruckLocation: updateTruckLocation.bind(null, providerId),
          loadTracking: loadTracking.bind(null, providerId),
          // without binding TS doesn't infer type for module functions
          updateTracking: updateTracking.bind(null),
          resetTracking: resetTracking.bind(null),
          updateTrackingSorting: updateTrackingSorting.bind(null),
          updateTruckInList: updateTruckInList.bind(null),
        },
        dispatch,
      ),
    [dispatch, providerId],
  );
};

export const trackingReducer = builder.getReducers();
