import { ReactElement } from 'react';

import { Dispatch } from 'redux';

import { _, bindActionCreators, simplifyBuilder, uid } from '../third-party';

const DEFAULT_AUTOHIDE_TIME = 5 * 1000; // 5s

export enum NotificationSeverity {
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
  Info = 'info',
}

export type NotificationMessage = string | ReactElement | ReactElement[];

export type Notification = {
  id?: string;
  severity?: NotificationSeverity;
  namespace?: string;
  sound?: 'default';
  autoHide?: number | false;
  title?: string | ReactElement | ReactElement[];
  onClick?: (closeNotification: () => void) => void;
  message?:
    | NotificationMessage
    | ((methods: {
        close: () => void;
        closeWithCallback: (
          callback: (notification?: Notification) => void,
        ) => () => void;
      }) => NotificationMessage);
  providerId?: string;
};

export interface NotificationsState {
  items: Notification[];
}

export const notificationsState: NotificationsState = {
  items: [],
};

const builder = simplifyBuilder(notificationsState, {});

export const ADD_NOTIFICATION_ACTION = 'addNotification';
export const addNotification = builder.createReduxAction(
  (notification: Notification) => ({
    name: ADD_NOTIFICATION_ACTION,
    updater: (state: NotificationsState) => {
      if (!notification.id || !_.find(state.items, { id: notification.id })) {
        return {
          items: [
            ...state.items,
            {
              ...notification,
              id: notification.id || uid(),
              autoHide:
                notification.autoHide === false
                  ? false
                  : notification.autoHide || DEFAULT_AUTOHIDE_TIME,
            },
          ],
        };
      } else {
        return {
          items: [
            ...state.items.reduce<Notification[]>((items, it) => {
              return [
                ...items,
                it.id === notification.id ? { ...it, ...notification } : it,
              ];
            }, []),
          ],
        };
      }
    },
  }),
);

export const removeNotification = builder.createReduxAction(
  (id: Notification['id']) => ({
    name: 'removeNotification',
    updater: (state: NotificationsState) => ({
      items: _.reject(state.items, { id }),
    }),
  }),
);

export const removeAllNotifications = builder.createReduxAction(() => ({
  name: 'removeAllNotifications',
  updater: () => ({
    items: [],
  }),
}));

export const removeNotificationsByNamespace = builder.createReduxAction(
  (namespace: Notification['namespace']) => ({
    name: 'removeNotificationsByNamespace',
    updater: (state: NotificationsState) => ({
      items: _.reject(state.items, { namespace }),
    }),
  }),
);

export const getNotificationsBoundActions = (dispatch: Dispatch<any>) => {
  return bindActionCreators(
    {
      addNotification,
      removeNotification,
      removeAllNotifications,
      removeNotificationsByNamespace,
    },
    dispatch,
  );
};

export const notificationsReducer = builder.getReducers();
