import { CoreUsersListState } from '../modules/usersList';

export const usersListItems = (state: { usersList: CoreUsersListState }) =>
  state.usersList.items;
export const usersListSorting = (state: { usersList: CoreUsersListState }) =>
  state.usersList.searchSortOrders;
export const usersListPagination = (state: { usersList: CoreUsersListState }) =>
  state.usersList.pagination;
export const usersListCount = (state: { usersList: CoreUsersListState }) =>
  state.usersList.count;
export const loadUsersListPending = (state: {
  usersList: CoreUsersListState;
}) => state.usersList.loadUsersListPending;
export const usersListLoaded = (state: { usersList: CoreUsersListState }) =>
  state.usersList.usersListLoaded;
export const loadMoreUsersListPending = (state: {
  usersList: CoreUsersListState;
}) => state.usersList.loadMoreUsersListPending;
export const deleteUserPending = (state: { usersList: CoreUsersListState }) =>
  state.usersList.deleteUserPending;
export const resendUserInvitePending = (state: {
  usersList: CoreUsersListState;
}) => state.usersList.resendUserInvitePending;
