import React, { useCallback, useEffect, useMemo } from 'react';

import CheckIcon from '@mui/icons-material/Check';

import { APILocation, APIPlant, APIResponse } from '@cd3p/core/types/api';
import { APIServerError } from '@cd3p/core/types/common';
import { getServerErrorTitle } from '@cd3p/core/utils/common';
import { ButtonGroup, Container, Stack, Typography } from '@mui/material';
import {
  BreadcrumbsViewAll,
  Button,
  CircularProgress,
  FormField,
  FormHelperText,
  GeoFencingMap,
  Grid,
  LoadingButton,
  MapOverlay,
  ReadOnlyField,
  TypeaheadFormField,
} from 'components';
import { useAddressToCoordinates } from 'hooks/useAddressToCoordinates';
import { emit } from 'hooks/usePubSub';
import { SingleValue } from 'react-select';

import {
  FieldError,
  classNames,
  styled,
  useForm,
  useLocation,
  useNavigate,
  useParams,
  useSelector,
  useTranslation,
} from 'third-party';

import { APP_EVENTS } from 'constants/appEvent';
import { productsPlantsUrl } from 'constants/url';

import { useCache } from 'modules/cache';
import { NotificationSeverity, useNotifications } from 'modules/notifications';

import { appSelectors, cacheSelectors } from 'selectors';

import {
  ChangePolygonOptions,
  GEOFENCE_CIRCLE_MAX_RADIUS,
  GeofenceTypes,
} from 'components/TrackingMap/GeoFencingMap';

import { LocationT, TypeaheadOption } from 'types/app';

const ContainerWrapper = styled(Container)`
  display: flex;
  width: 100%;
  height: 100%;
  min-width: 100%;
  padding: 0;
  margin: 0;
`;

const LeftPanel = styled(Stack)`
  flex-shrink: 0;
  width: 45rem;
  padding: 0rem;
  border-right: 0.2rem solid ${props => props.theme.custom.palette.muted100};
`;

const RightContent = styled(Stack)`
  flex-grow: 1;
`;

const HeaderWrapper = styled(Stack)`
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 0.1rem solid ${props => props.theme.custom.palette.muted100};
  padding: 2.4rem 1.3rem 1.6rem;
  box-shadow: 0rem 0.2rem 1.2rem 0rem rgba(0, 0, 0, 0.1);
`;

const Header = styled(Typography)`
  color: ${props => props.theme.custom.palette.secondary500};
  word-break: break-word;
  flex-grow: 1;
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1.6rem;
`;

const ContentWrapper = styled(Stack)`
  flex-grow: 1;
  padding: 1rem 1.3rem;
  min-height: 0;
`;

const ScrollableArea = styled(Grid)`
  flex-grow: 1;
  min-height: 0;
  padding: 0 0.2rem;
  overflow-y: auto;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 0;
`;

const StyledReadOnlyField = styled(ReadOnlyField)`
  margin-top: 1.6rem;
`;

const GeofenceTypeSwitcher = styled(ButtonGroup)`
  margin-top: 2rem;
`;

export const StyledFormHelperText = styled(FormHelperText)`
  margin: 0 0 0 auto;
  font-size: 1.4rem;
  line-height: 2.1rem;
  font-weight: 400;
  color: ${props => props.theme.custom.palette.error500};
`;

const MapTypeButton = styled(Button)`
  width: 100%;
  font-size: 1.4rem;
  font-weight: 600;
  text-transform: none;

  border: 0.1rem solid ${props => props.theme.custom.palette.secondary700};
  &:not(.selected) {
    background-color: white;
  }
  &:not(:last-of-type).Mui-disabled {
    border-color: ${props => props.theme.custom.palette.muted100};
  }
  &.selected {
    &:before {
      content: '';
    }
  }
`;

const StyledCheckIcon = styled(CheckIcon)`
  font-size: 1.5rem;
  margin-right: 0.5rem;
`;

const FIELD_MAX_LENGTH = 200;

enum PlantFormFields {
  Name = 'name',
  Address = 'address',
  ThirdPartyId = 'thirdPartyId',
  PlantGeofenceType = 'plantGeofenceType',
  PlantCoordinates = 'plantCoordinates',
  GeoFenceCoordinates = 'geoFenceCoordinates',
  PlantRadius = 'plantRadius',
}

const CUSTOM_ERROR = 'custom';

type FormData = {
  [PlantFormFields.Name]: APIPlant['name'];
  [PlantFormFields.ThirdPartyId]: APIPlant['thirdPartyId'];
  [PlantFormFields.Address]:
    | {
        label: APIPlant['address'];
        value: APIPlant['address'];
      }
    | undefined;
  [PlantFormFields.PlantGeofenceType]: GeofenceTypes | null;
  [PlantFormFields.PlantCoordinates]?: LocationT | null;
  [PlantFormFields.GeoFenceCoordinates]?: APILocation[] | null;
  [PlantFormFields.PlantRadius]?: number | null;
};

const getDefaultValues = (plant: APIPlant | null) => {
  const plantAddress = (plant as APIPlant)?.address || '';
  return {
    [PlantFormFields.Name]: (plant as APIPlant)?.name || '',
    [PlantFormFields.ThirdPartyId]: (plant as APIPlant)?.thirdPartyId || '',
    [PlantFormFields.Address]: plant?.id
      ? {
          label: plantAddress,
          value: plantAddress,
        }
      : undefined,
    [PlantFormFields.PlantGeofenceType]: plant?.plantRadius
      ? GeofenceTypes.Circle
      : GeofenceTypes.Polygon,
    [PlantFormFields.PlantCoordinates]: null,
    [PlantFormFields.GeoFenceCoordinates]: plant?.geoFenceCoordinates?.length
      ? plant?.geoFenceCoordinates
      : null,
    [PlantFormFields.PlantRadius]:
      plant?.plantRadius || GEOFENCE_CIRCLE_MAX_RADIUS,
  };
};

type Props = {
  plant: APIPlant | null;
};

export const PlantCreateEditForm: React.FC<Props> = ({ plant }) => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { addNotification } = useNotifications();
  const addressToCoordinates = useAddressToCoordinates();
  const provider = useSelector(appSelectors.provider);
  const { updatePlant, createPlant, loadAddress, resetSelectedPlant } =
    useCache();

  const mapDefaultCenter = useMemo(
    () => ({
      lat: plant?.latitude || provider?.centroidLatitude || 0,
      lng: plant?.longitude || provider?.centroidLongitude || 0,
    }),
    [
      plant?.latitude,
      plant?.longitude,
      provider?.centroidLatitude,
      provider?.centroidLongitude,
    ],
  );

  const isBatchSoftwareIntegrationEnabled = useSelector(
    appSelectors.isBatchSoftwareIntegrationEnabled,
  );
  const updatePlantPending = useSelector(cacheSelectors.updatePlantPending);
  const createPlantPending = useSelector(cacheSelectors.createPlantPending);
  const isLoading = updatePlantPending || createPlantPending;
  const isNewPlant = !plant?.id;

  const initialPlantLocation = useMemo(() => {
    return !isNewPlant && plant
      ? { latitude: plant?.latitude, longitude: plant?.longitude }
      : undefined;
  }, [isNewPlant, plant]);

  const title = isNewPlant
    ? t('plantSettings.editForm.createTitle')
    : t('plantSettings.editForm.editTitle');
  const actionTitle = isNewPlant
    ? t('plantSettings.editForm.createAction')
    : t('common.save');

  const { backUrl, backUrlLabel } = useMemo(() => {
    return state?.backUrl
      ? {
          backUrl: state.backUrl,
          backUrlLabel: state.backUrlLabel || t('common.back'),
        }
      : {
          backUrl: productsPlantsUrl(),
          backUrlLabel: t('common.back'),
        };
  }, [state?.backUrl, state?.backUrlLabel, t]);

  const formMethods = useForm<FormData>({
    mode: 'onChange',
    defaultValues: getDefaultValues(plant),
  });

  useEffect(() => {
    return () => {
      resetSelectedPlant();
    };
  }, [resetSelectedPlant]);

  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    trigger,
    setValue,
    watch,
    formState: { errors, isValid, isSubmitted, isDirty },
  } = formMethods;

  const watchedPlantAddress = watch(PlantFormFields.Address);
  const watchedGeofenceType = watch(PlantFormFields.PlantGeofenceType);
  const watchedPlantCoordinates = watch(PlantFormFields.PlantCoordinates);

  const switchGeofenceType = useCallback(
    (geofenceType: GeofenceTypes) => () => {
      setValue(PlantFormFields.PlantGeofenceType, geofenceType, {
        shouldDirty: true,
      });
    },
    [setValue],
  );

  const onSubmit = async (data: FormData) => {
    let result;
    const geofenceType = data[PlantFormFields.PlantGeofenceType];

    const submitData = {
      [PlantFormFields.Name]: (data[PlantFormFields.Name] || '').trim(),
      [PlantFormFields.ThirdPartyId]: (
        data[PlantFormFields.ThirdPartyId] || ''
      ).trim(),
      [PlantFormFields.Address]: data[PlantFormFields.Address]?.label,
      ...(geofenceType === GeofenceTypes.Circle &&
      data[PlantFormFields.PlantRadius]
        ? {
            [PlantFormFields.PlantRadius]: data[PlantFormFields.PlantRadius]!,
          }
        : {}),
      ...(geofenceType === GeofenceTypes.Polygon &&
      data[PlantFormFields.GeoFenceCoordinates]?.length
        ? {
            [PlantFormFields.GeoFenceCoordinates]:
              data[PlantFormFields.GeoFenceCoordinates]!,
          }
        : {}),
      ...(data[PlantFormFields.PlantCoordinates]
        ? {
            longitude: data[PlantFormFields.PlantCoordinates]?.longitude,
            latitude: data[PlantFormFields.PlantCoordinates]?.latitude,
          }
        : {}),
    };

    if (isNewPlant) {
      result = await createPlant(submitData);
    } else {
      result = await updatePlant(plant.id, submitData);
    }

    if (!result || result?.error) {
      const errorStatus = result.payload.status;
      if (errorStatus === 409) {
        setError(PlantFormFields.Name, {
          type: 'custom',
          message: getServerErrorTitle(result as APIServerError),
        });
      } else {
        addNotification({
          severity: NotificationSeverity.Error,
          message: t('common.generalError'),
        });
      }
      return Promise.reject('error');
    } else {
      addNotification({
        message: isNewPlant
          ? t('plantSettings.editForm.createSuccessfulMessage')
          : t('plantSettings.editForm.updateSuccessfulMessage'),
      });
      navigate(productsPlantsUrl());
    }
  };

  useEffect(() => {
    // get plant coordinates by selected address to show on map
    const updateAddress = async () => {
      if (watchedPlantAddress?.value) {
        // use plant coordinates if address is default
        if (plant?.address && watchedPlantAddress.value === plant?.address) {
          setValue(PlantFormFields.PlantCoordinates, {
            latitude: plant?.latitude,
            longitude: plant?.longitude,
            address: plant?.address,
            name: t('plantSettings.mapPinAddressLabel'),
          });
          return;
        }
        setValue(
          PlantFormFields.PlantCoordinates,
          await addressToCoordinates(watchedPlantAddress.value),
        );
      } else {
        setValue(PlantFormFields.PlantCoordinates, null);
        emit(APP_EVENTS.AUTOCENTER_MAP);
      }
    };
    updateAddress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressToCoordinates, setValue, watchedPlantAddress]);

  const loadAddressOptions = async (value: string) => {
    const result: APIResponse<string[]> = await loadAddress(value);
    let options: TypeaheadOption[] = [];
    if (!result.error) {
      options = result.payload.map(it => ({
        label: it,
        value: it,
      }));
    }
    return {
      options,
    };
  };

  const onSelectAddress = useCallback(
    (option: SingleValue<TypeaheadOption>) => {
      if (option) {
        setValue(PlantFormFields.Address, option, {
          shouldValidate: true,
          shouldDirty: true,
        });
      }
      if (!option) {
        setValue(PlantFormFields.Address, undefined);
      }
    },
    [setValue],
  );

  const onAddressPinPolygonChange = useCallback(
    ({
      circlePathOptions,
      polygonPathOptions,
      shouldDirty = true,
    }: ChangePolygonOptions) => {
      if (circlePathOptions) {
        setValue(
          PlantFormFields.PlantCoordinates,
          {
            longitude: circlePathOptions.center.lng,
            latitude: circlePathOptions.center.lat,
          },
          {
            shouldDirty,
          },
        );
        setValue(PlantFormFields.PlantRadius, circlePathOptions.radius, {
          shouldDirty,
        });
      } else if (polygonPathOptions?.length) {
        setValue(
          PlantFormFields.GeoFenceCoordinates,
          polygonPathOptions.map(path => ({
            longitude: path.lng,
            latitude: path.lat,
          })),
          { shouldDirty },
        );
      }
    },
    [setValue],
  );

  const onPolygonValidityChange = useCallback(
    (polygonError: string | null) => {
      if (polygonError) {
        setError(PlantFormFields.GeoFenceCoordinates, {
          type: CUSTOM_ERROR,
          message: polygonError,
        });
      } else {
        clearErrors(PlantFormFields.GeoFenceCoordinates);
        trigger(PlantFormFields.GeoFenceCoordinates);
      }
    },
    [clearErrors, setError, trigger],
  );

  const onBackCallback = () => {
    navigate(backUrl);
  };

  const sites = useMemo(() => {
    if (watchedPlantAddress && watchedPlantCoordinates) {
      return [watchedPlantCoordinates];
    }
    return [];
  }, [watchedPlantAddress, watchedPlantCoordinates]);

  return (
    <ContainerWrapper>
      <LeftPanel>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <HeaderWrapper>
            <BreadcrumbsViewAll url={backUrl} text={backUrlLabel} />
            <Stack direction="row" alignSelf="stretch" alignItems="center">
              <Header variant="h4">{title}</Header>
              <ActionButtons>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={onBackCallback}
                  disabled={isLoading}
                >
                  {t('common.cancel')}
                </Button>
                <LoadingButton
                  loading={isLoading}
                  variant="contained"
                  color="primary"
                  disabled={!(isValid && isDirty && !isLoading)}
                  type="submit"
                  sx={{ minWidth: '8.8rem' }}
                >
                  {actionTitle}
                </LoadingButton>
              </ActionButtons>
            </Stack>
          </HeaderWrapper>
          <ContentWrapper>
            <ScrollableArea>
              <FormField
                isRequired
                isDisabled={isLoading}
                fieldName={PlantFormFields.Name}
                label={t('plantSettings.editForm.nameField')}
                fieldError={errors[PlantFormFields.Name]}
                requiredErrorMessage={t('common.form.emptyFieldError')}
                control={control}
                placeholder={t('plantSettings.editForm.nameFieldPlaceholder')}
                inputProps={{
                  maxLength: FIELD_MAX_LENGTH,
                }}
              />
              <FormField
                isRequired
                fieldName={PlantFormFields.Address}
                label={t('plantSettings.editForm.addressField')}
                fieldError={errors[PlantFormFields.Address] as FieldError}
                requiredErrorMessage={t('common.form.emptyFieldError')}
                control={control}
                placeholder={t(
                  'plantSettings.editForm.addressFieldPlaceholder',
                )}
                inputProps={{
                  maxLength: FIELD_MAX_LENGTH,
                }}
                isUpdating={isLoading}
                render={() => (
                  <TypeaheadFormField
                    hasError={
                      isSubmitted &&
                      !!(errors[PlantFormFields.Address] as FieldError)
                    }
                    placeholder={t('common.addAddress')}
                    loadOptions={loadAddressOptions}
                    onChange={onSelectAddress}
                    value={watchedPlantAddress}
                    isDisabled={isLoading}
                  />
                )}
              />
              {isBatchSoftwareIntegrationEnabled && (
                <FormField
                  isDisabled={isLoading}
                  fieldName={PlantFormFields.ThirdPartyId}
                  label={t('plantSettings.editForm.batchingSoftwareId')}
                  control={control}
                  isRequired={false}
                  placeholder={t('plantSettings.editForm.batchingSoftwareId')}
                  inputProps={{
                    maxLength: FIELD_MAX_LENGTH,
                  }}
                />
              )}
              <StyledReadOnlyField
                key={PlantFormFields.PlantGeofenceType}
                value={t('plantSettings.editForm.drawGeofenceBoundaryText')}
                label={t('plantSettings.editForm.plantGeofenceLabel')}
              >
                <GeofenceTypeSwitcher
                  disabled={!watchedPlantAddress?.value}
                  disableElevation
                  variant="contained"
                >
                  <MapTypeButton
                    onClick={switchGeofenceType(GeofenceTypes.Polygon)}
                    className={classNames({
                      selected:
                        watchedPlantAddress?.value &&
                        watchedGeofenceType === GeofenceTypes.Polygon,
                    })}
                  >
                    {watchedPlantAddress?.value &&
                      watchedGeofenceType === GeofenceTypes.Polygon && (
                        <StyledCheckIcon />
                      )}
                    {t('plantSettings.editForm.GeofencePolygon')}
                  </MapTypeButton>
                  <MapTypeButton
                    onClick={switchGeofenceType(GeofenceTypes.Circle)}
                    className={classNames({
                      selected:
                        watchedPlantAddress?.value &&
                        watchedGeofenceType === GeofenceTypes.Circle,
                    })}
                  >
                    {watchedPlantAddress?.value &&
                      watchedGeofenceType === GeofenceTypes.Circle && (
                        <StyledCheckIcon />
                      )}
                    {t('plantSettings.editForm.GeofenceCircle')}
                  </MapTypeButton>
                </GeofenceTypeSwitcher>
              </StyledReadOnlyField>
              {Object.keys<PlantFormFields>(errors)
                .filter(errorKey => errors[errorKey]!.type === CUSTOM_ERROR)
                .map((errorKey, index) => {
                  return (
                    <StyledFormHelperText key={index}>
                      {errors[errorKey]!.message}
                    </StyledFormHelperText>
                  );
                })}
            </ScrollableArea>
          </ContentWrapper>
        </Form>
      </LeftPanel>
      <RightContent>
        <MapOverlay
          enabled={!sites.length}
          text={t('plantSettings.editPlant.emptyAddressMapText')}
        >
          <GeoFencingMap
            defaultZoom={14}
            center={sites.length ? undefined : mapDefaultCenter}
            isLoading={false}
            generalPlant={sites?.[0]}
            geofencingType={watchedGeofenceType || GeofenceTypes.Circle}
            onPolygonChange={onAddressPinPolygonChange}
            onPolygonValidityChange={onPolygonValidityChange}
            initialCirclePolygonRadius={!isNewPlant ? plant.plantRadius : null}
            initialPolygonPath={!isNewPlant ? plant.geoFenceCoordinates : null}
            initialPlantLocation={initialPlantLocation}
          />
        </MapOverlay>
      </RightContent>
    </ContainerWrapper>
  );
};

export const PlantEditView = () => {
  const { plantId } = useParams();
  const { loadPlant } = useCache();
  const plant = useSelector(cacheSelectors.plantDetails);

  useEffect(() => {
    if (plantId) {
      loadPlant(plantId);
    }
  }, [loadPlant, plantId]);

  return plantId && !plant ? (
    <CircularProgress />
  ) : (
    <PlantCreateEditForm plant={plant} />
  );
};
