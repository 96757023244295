export const CONCRETELY_SUPPORT_EMAIL = 'support@concretely.co';
export const MAX_FILE_SIZE_TO_IMPORT = 25000000; // 25mb in bytes
export const GMAP_API_KEY = process.env['GMAP_API_KEY'] ?? '';

export const PERSIST_STORE_VERSION = 1;
export const PERSIST_STORE_KEY = 'app';
export const PERSIST_STORE_PREFIX = 'persist:';

export const USER_INPUT_DEBOUNCE = 1000;
export const TOOLTIP_APPEAR_DELAY = 1000;

export const TEXTAREA_MAXLENGTH = 1000;

export const MAX_ITEMS_AMOUNT = 999999;

export const TYPEAHEAD_OPTIONS_COUNT = 5;

export const FIELD_MAX_LENGTH = 300;

export const CHAT_AUTOSCROLL_ACTIVATION_THRESHOLD = 100;

export const NEW_ITEM_ID = 'new';

export const INFINITE_SCROLL_BOTTOM_OFFSET_PX = 300;
export const INFINITE_SCROLL_DEBOUNCE_TIME_MS = 3000;

export enum AppPortals {
  OrderTableColumnVisibility = 'order-table-settings-portal',
}

export enum OrdersTableColumnIds {
  OrderName = 'orderName',
  DeliveryDate = 'deliveryDate',
  DeliveryTime = 'deliveryTime',
  OrderNumber = 'orderNumber',
  Company = 'company',
  Project = 'project',
  ProjectAddress = 'projectAddress',
  DeliveryLocation = 'deliveryLocation',
  Volume = 'volume',
  OrderStatus = 'orderStatus',
  Menu = 'menu',
  Subscribe = 'subscribe',
}

export const dispatcherTodayOrdersHiddenColumns = [
  OrdersTableColumnIds.DeliveryDate,
  OrdersTableColumnIds.DeliveryLocation,
  OrdersTableColumnIds.OrderNumber,
  OrdersTableColumnIds.Project,
  OrdersTableColumnIds.ProjectAddress,
];

export const dispatcherAllOrdersHiddenColumns = [
  OrdersTableColumnIds.DeliveryLocation,
  OrdersTableColumnIds.OrderNumber,
  OrdersTableColumnIds.Project,
  OrdersTableColumnIds.ProjectAddress,
];

export const contractorAllOrdersHiddenColumns = [
  OrdersTableColumnIds.ProjectAddress,
];

export const posthogOptions = {
  api_host: process.env.POSTHOG_HOST,
};

export enum AppTableSettings {
  // WARNING!
  // changing any of the existing value of this enum
  // will result of losing table settings which user previously saved
  // they will need to set up those settings again
  AllOrders = 'allOrdersTableSettings',
  TodayOrders = 'todayOrdersTableSettings',
}

export enum MixAndAdditivesTableColumnIds {
  Name = 'name',
  Categories = 'categories',
  ActionButtons = 'action-buttons',
}

export const contractorMixAndAdditivesHiddenColumns = [
  MixAndAdditivesTableColumnIds.Categories,
];
export const PLANT_ICON = 'https://cdn.concretely.co/common/plant.png';
