import React from 'react';

import styled from 'styled-components';

import { CircularProgress, Container } from 'components';

const CenterWrapper = styled(Container)`
  display: flex;
  align-items: center;
  height: 100%;
`;

type Props = {
  className?: string;
};

export const GlobalLoader: React.FC<Props> = ({ className }) => {
  return (
    <CenterWrapper className={className}>
      <CircularProgress sx={{ margin: '5rem auto' }} />
    </CenterWrapper>
  );
};
