import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { APIOrder, APIOrderStatus, APIUserType } from '@cd3p/core/types/api';
import { getPageNumberAfterLoadMore } from '@cd3p/core/utils/lists';
import {
  getModifiedOrderFieldValue,
  prepareOrderForUpdating,
} from '@cd3p/core/utils/order';
import {
  Breadcrumbs,
  Button,
  ButtonBase,
  ChatIcon,
  ConfirmationDialog,
  Grid,
  Stack,
  SvgIcon,
  Tab,
  Tabs,
  Typography,
} from 'components';
import { OrderCreateEditForm } from 'features';
import { useDialogRead } from 'hooks/useDialogRead';
import { useHandleApiResult } from 'hooks/useHandleApiResult';
import { LocationChangeConfirmationContext } from 'providers/LocationChangeConfirmationProvider';

import { OrderFormProps } from './OrderCreateEditForm';
import { OrderDetailsInfo } from './OrderDetailsInfo';
import { OrderInformationTab } from './OrderInformationTab';
import { TicketsTab } from './TicketsTab';
import { OrderFormFields, prepareAdditivesOptions } from './orderViewHelpers';

import {
  Route,
  RouterLink,
  RouterOutlet,
  Routes,
  _,
  styled,
  useBottomScrollListener,
  useLocation,
  useMatch,
  useNavigate,
  useParams,
  useResolvedPath,
  useSelector,
  useTranslation,
} from 'third-party';

import {
  INFINITE_SCROLL_BOTTOM_OFFSET_PX,
  INFINITE_SCROLL_DEBOUNCE_TIME_MS,
} from 'constants/common';
import {
  PARENT_PATH,
  TICKETS_PATH,
  allOrdersUrl,
  orderAddTicketRelativeUrl,
  orderTicketsRelativeUrl,
} from 'constants/url';

import { useOrder } from 'modules/order';

import { appSelectors, orderSelectors } from 'selectors';

import { EditButton } from 'components/EditButton/EditButton';

import { formatTime, isDateTomorrow, useCanEditOrder } from 'utils/order';
import { composeLoadTicketsRequestBody } from 'utils/ticketsList';

import { LocationCoordinates } from 'types/app';

const BreadcrumbsWrapper = styled(Grid)`
  justify-content: space-between;
  align-items: center;
  padding-right: 1.5rem;
`;

const BreadcrumbsLink = styled(RouterLink)`
  font-weight: 900;
  color: ${props => props.theme.custom.palette.secondary600};
  text-decoration: none;
`;

const StyledTabs = styled(Tabs)`
  margin-top: 3rem;
`;

const TabContent = styled.div<{ visible?: boolean }>`
  ${props => (!props.visible ? 'display: none' : 'display: flex')};
  flex-grow: 1;
  min-height: 0;
  flex-direction: column;
`;

const ChatButton = styled(Button)`
  margin-left: auto;
`;

const ScrollableContent = styled(Stack)`
  overflow-y: auto;
  padding-right: 1.5rem;
`;

const EditableDetailsWrapperComponent = styled(Stack)`
  min-height: 0;
`;
const EditableDetailsHeader = styled(Stack)`
  flex-direction: row;
  align-items: center;
`;

export const StyledEditButton = styled(ButtonBase)`
  flex: 1;
  text-align: left;
  margin-bottom: ${props => props.theme.custom.paddings.componentPaddingSmall};
  color: ${props => props.theme.custom.palette.absoluteDark};
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  &:hover {
    svg {
      color: ${props => props.theme.custom.palette.primary500};
    }
  }
`;
export const SectionTitle = styled(Typography)<{ variant: string }>`
  display: inline-block;
  white-space: pre-wrap;
  word-break: break-word;
  color: ${props => props.theme.custom.palette.secondary500};
  padding-right: 1rem;
  width: 100%;
  &:hover {
    color: ${props => props.theme.custom.palette.primary500};
  }
`;

const AddOrderButton = styled(Button)`
  margin-right: 1rem;
`;

const HeaderActionsRow = styled(Stack)`
  flex-direction: row;
  margin-left: auto;
`;

interface OrderViewProps {
  order: APIOrder;
  isShowAssignDriversView: boolean;
  setShowAssignDriversView: (state: boolean) => void;
  onChatButtonClicked: () => void;
  isChatButtonDisabled: boolean;
  isEditMode: boolean;
  setIsEditMode: (value: boolean) => void;
  manualDeliveryLocation?: LocationCoordinates | null;
  shouldConfirmUponApproval?: boolean;
}

enum OrderDetailsTab {
  OrderInformation,
  Tickets,
}

export const OrderViewLeftPanel: React.FC<OrderViewProps> = ({
  order,
  isShowAssignDriversView,
  setShowAssignDriversView,
  onChatButtonClicked,
  isChatButtonDisabled,
  isEditMode,
  setIsEditMode,
  manualDeliveryLocation,
  shouldConfirmUponApproval,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const { state } = useLocation();
  const orderId = Number(params.id);
  const isChatFeatureEnabled = useSelector(appSelectors.isChatFeatureEnabled);
  const isDriverAssignmentEnabled = useSelector(
    appSelectors.isDriverAssignmentEnabled,
  );
  const userType = useSelector(appSelectors.userType);
  const isUserDispatcher = userType === APIUserType.Dispatcher;

  const isRequestedOrder = order?.orderStatus === APIOrderStatus.Requested;
  const showCancelOrderButton =
    !isUserDispatcher &&
    (isRequestedOrder ||
      (order?.isChangesRequested &&
        order.orderStatus === APIOrderStatus.Unconfirmed));

  const canEditOrder =
    useCanEditOrder(order?.orderStatus as APIOrderStatus) && !isEditMode;
  const isDialogRead = useDialogRead();

  const { backUrl, backUrlLabel } = useMemo(() => {
    return state?.backUrl
      ? {
          backUrl: state.backUrl,
          backUrlLabel: state.backUrlLabel || t('common.back'),
        }
      : {
          backUrl: allOrdersUrl(),
          backUrlLabel: t('order.breadcrumbs.viewAll'),
        };
  }, [state?.backUrl, state?.backUrlLabel, t]);

  const { pageSize: orderTicketsPageSize } = useSelector(
    orderSelectors.orderTicketsPagination,
  );
  const orderTicketsSorting = useSelector(orderSelectors.orderTicketsSorting);
  const orderTicketsFilters = useSelector(orderSelectors.orderTicketsFilters);

  const orderTickets = useSelector(orderSelectors.orderTickets);
  const orderTicketsLoaded = useSelector(orderSelectors.orderTicketsLoaded);
  const loadMoreOrderTicketsPending = useSelector(
    orderSelectors.loadMoreOrderTicketsPending,
  );
  const orderTicketsTotalCount = useSelector(
    orderSelectors.orderTicketsTotalCount,
  );

  const { continueChangeLocation } = useContext(
    LocationChangeConfirmationContext,
  );

  const providerName = useSelector(appSelectors.providerName);

  const { loadMoreOrderTickets, updateOrder, changeOrderStatus } = useOrder();
  const handleApiResult = useHandleApiResult();

  const orderStatus = order?.orderStatus as APIOrderStatus;
  const matchTicketsUrl = useMatch(
    useResolvedPath(`${TICKETS_PATH}/*`).pathname,
  );

  const currentTab = matchTicketsUrl
    ? OrderDetailsTab.Tickets
    : OrderDetailsTab.OrderInformation;

  const [orderEditData, setOrderEditData] =
    useState<Partial<OrderFormProps> | null>(null);
  const [isLeaveConfirmationDialogShown, setLeaveConfirmationDialogShown] =
    useState(false);
  const [isOrderCancelling, setIsOrderCancelling] = useState(false);
  const [orderCancellingConfirmation, setOrderCancellingConfirmation] =
    useState(false);

  const onOrderInformationTabClicked = () => {
    navigate(PARENT_PATH, { replace: true });
  };

  const onTicketsTabClicked = () => {
    navigate(orderTicketsRelativeUrl(), { replace: true });
  };

  const onLoadMoreTickets = useCallback(() => {
    loadMoreOrderTickets(orderId, {
      ...composeLoadTicketsRequestBody({
        pageNumber: getPageNumberAfterLoadMore(
          orderTickets,
          orderTicketsPageSize,
        ),
        pageSize: orderTicketsPageSize,
        sorting: orderTicketsSorting,
        filters: orderTicketsFilters,
      }),
      calculateTotalOnDelivery: true,
    });
  }, [
    loadMoreOrderTickets,
    orderId,
    orderTickets,
    orderTicketsPageSize,
    orderTicketsSorting,
    orderTicketsFilters,
  ]);

  const hasOrderTicketsLoadMoreButton =
    orderTicketsLoaded && orderTicketsTotalCount > orderTickets.length;

  // creating ref for always having the actual
  // value inside `onScrollToBottom` callback,
  const hasOrderTicketsLoadMoreButtonRef = useRef(
    hasOrderTicketsLoadMoreButton,
  );
  hasOrderTicketsLoadMoreButtonRef.current = hasOrderTicketsLoadMoreButton;

  const onScrollToBottom = useCallback(() => {
    if (
      hasOrderTicketsLoadMoreButtonRef.current &&
      !loadMoreOrderTicketsPending
    ) {
      onLoadMoreTickets?.();
    }
  }, [loadMoreOrderTicketsPending, onLoadMoreTickets]);

  const onScrollToBottomDebounced = _.throttle(
    onScrollToBottom,
    INFINITE_SCROLL_DEBOUNCE_TIME_MS,
  );

  const scrollRef = useBottomScrollListener<HTMLDivElement>(
    onScrollToBottomDebounced,
    {
      offset: INFINITE_SCROLL_BOTTOM_OFFSET_PX,
      triggerOnNoScroll: false,
    },
  );

  // for Confirmed tomorrow orders we show controls to switch from map to assign drivers screen
  const showContentControls =
    order?.orderStatus === APIOrderStatus.Confirmed &&
    isDateTomorrow(order?.deliveryDate);

  const handleImmediateCancelOrder = useCallback(async () => {
    setOrderCancellingConfirmation(false);
    if (order) {
      setIsOrderCancelling(true);
      await handleApiResult(
        async () => changeOrderStatus(order.id, APIOrderStatus.Cancelled),
        ({ showBaseToast }) => {
          showBaseToast(t('order.notification.orderCancelled'));
        },
      );
      setIsOrderCancelling(false);
    }
  }, [changeOrderStatus, handleApiResult, order, t]);

  const handleOrderCancellationRequest = useCallback(async () => {
    if (order) {
      setIsOrderCancelling(true);
      await handleApiResult(
        async () =>
          updateOrder(order.id, {
            ...prepareOrderForUpdating(order),
            isCancellationSubmitted: true,
          }),
        ({ showBaseToast }) => {
          showBaseToast(t('order.notification.cancellationSubmitted'));
        },
      );
      setIsOrderCancelling(false);
    }
  }, [handleApiResult, order, t, updateOrder]);

  const parseOrderEditData = useCallback(() => {
    if (order) {
      const orderProject =
        order.project.name && order.project.id
          ? {
              label: order.project.name,
              value: order.project.id,
              isDefault: true,
            }
          : null;
      const orderCompany =
        order.company.name && order.company.id
          ? {
              label: order.company.name,
              value: order.company.id,
            }
          : null;
      const orderAdditives = prepareAdditivesOptions(order.additives);
      const deliveryLocation = order.deliveryLocation
        ? {
            label: order.deliveryLocation,
            value: order.deliveryLocation,
          }
        : null;
      setOrderEditData({
        [OrderFormFields.Project]: orderProject,
        [OrderFormFields.DeliveryLocation]: deliveryLocation,
        [OrderFormFields.OrderType]: getModifiedOrderFieldValue(
          OrderFormFields.OrderType,
          order,
        ),
        [OrderFormFields.CallBack]: getModifiedOrderFieldValue(
          OrderFormFields.CallBack,
          order,
        ),
        [OrderFormFields.DeliveryDate]: getModifiedOrderFieldValue(
          OrderFormFields.DeliveryDate,
          order,
        ),
        [OrderFormFields.DeliveryTime]: formatTime(
          getModifiedOrderFieldValue(OrderFormFields.DeliveryTime, order),
        ),
        [OrderFormFields.Volume]:
          getModifiedOrderFieldValue(
            OrderFormFields.Volume,
            order,
            '',
          )?.toString() || '',
        [OrderFormFields.DeliveryRate]:
          getModifiedOrderFieldValue(
            OrderFormFields.DeliveryRate,
            order,
            '',
          )?.toString() || '',
        [OrderFormFields.DeliveryRateType]: getModifiedOrderFieldValue(
          OrderFormFields.DeliveryRateType,
          order,
        ),
        [OrderFormFields.TypeOfPour]: getModifiedOrderFieldValue(
          OrderFormFields.TypeOfPour,
          order,
          '',
        ),
        [OrderFormFields.PlacementMethod]: getModifiedOrderFieldValue(
          OrderFormFields.PlacementMethod,
          order,
          '',
        ),
        [OrderFormFields.Slump]:
          getModifiedOrderFieldValue(
            OrderFormFields.Slump,
            order,
            '',
          )?.toString() || '',
        [OrderFormFields.Notes]: getModifiedOrderFieldValue(
          OrderFormFields.Notes,
          order,
          '',
        ),
        [OrderFormFields.MixTypeId]: order.mixType.id,
        [OrderFormFields.Additives]: orderAdditives,
        ...(isUserDispatcher
          ? {
              [OrderFormFields.Company]: orderCompany,
              [OrderFormFields.OrderNumber]: getModifiedOrderFieldValue(
                OrderFormFields.OrderNumber,
                order,
              ),
              [OrderFormFields.OrderName]: getModifiedOrderFieldValue(
                OrderFormFields.OrderName,
                order,
              ),
            }
          : {}),
      });
    }
  }, [isUserDispatcher, order]);

  useEffect(() => {
    parseOrderEditData();
  }, [parseOrderEditData]);

  return (
    <>
      <ConfirmationDialog
        onClose={() => setLeaveConfirmationDialogShown(false)}
        open={isLeaveConfirmationDialogShown}
        handleActionClick={continueChangeLocation}
        description={t('common.exitConfirmationWindow.text')}
        cancelButtonTitle={t('common.continueEditing')}
        actionButtonTitle={t('common.confirmCancel')}
      />
      <ConfirmationDialog
        onClose={() => setOrderCancellingConfirmation(false)}
        open={orderCancellingConfirmation}
        handleActionClick={handleImmediateCancelOrder}
        description={t('order.cancelConfirmationText', { providerName })}
        cancelButtonTitle={t('order.keepButtonLabel')}
        actionButtonTitle={t('common.cancelOrder')}
      />
      <BreadcrumbsWrapper container marginBottom="1rem">
        <Grid item display="flex" alignItems="center" flexGrow={1}>
          <Breadcrumbs
            aria-label="breadcrumb"
            style={{ display: 'inline-flex' }}
            separator={
              <SvgIcon height="1.2rem" width="0.74rem" icon="shevron-left" />
            }
          >
            {/*
              An empty div is needed to display a separator.
              As a default is it not displayed with just 1 child of Breadcrumbs.
            */}
            <div />
            <BreadcrumbsLink to={backUrl}>{backUrlLabel}</BreadcrumbsLink>
          </Breadcrumbs>
          <HeaderActionsRow>
            {showCancelOrderButton && (
              <AddOrderButton
                disabled={isOrderCancelling}
                onClick={
                  isRequestedOrder
                    ? () => setOrderCancellingConfirmation(true)
                    : handleOrderCancellationRequest
                }
              >
                {t('common.cancelOrder')}
              </AddOrderButton>
            )}
            {isChatFeatureEnabled && (
              <ChatButton
                variant="outlined"
                color="secondary"
                onClick={onChatButtonClicked}
                disabled={isChatButtonDisabled}
              >
                {t('chat.chatButtonLabel')}
                <ChatIcon
                  hasMessages={order?.dialog && !isDialogRead(order.dialog)}
                />
              </ChatButton>
            )}
          </HeaderActionsRow>
        </Grid>
        {showContentControls && isDriverAssignmentEnabled && (
          <Button
            disableRipple
            onClick={() => setShowAssignDriversView(!isShowAssignDriversView)}
          >
            {isShowAssignDriversView
              ? t('order.details.confirmed.showMapButton')
              : t('order.details.confirmed.assignDriversButton')}
          </Button>
        )}
      </BreadcrumbsWrapper>
      <ScrollableContent ref={scrollRef}>
        <EditableDetailsWrapperComponent>
          {isEditMode ? (
            <OrderCreateEditForm
              isEditMode
              order={order}
              orderEditData={orderEditData}
              setEditMode={setIsEditMode}
              manualDeliveryLocation={manualDeliveryLocation}
              shouldConfirmUponApproval={shouldConfirmUponApproval}
            />
          ) : (
            <>
              <EditableDetailsHeader>
                <StyledEditButton
                  disableRipple
                  disabled={!canEditOrder}
                  onClick={() => setIsEditMode(true)}
                >
                  <SectionTitle variant="h4">
                    {order?.orderName || ''}
                  </SectionTitle>
                </StyledEditButton>
                {canEditOrder && (
                  <EditButton onClick={() => setIsEditMode(true)} />
                )}
              </EditableDetailsHeader>
              <OrderDetailsInfo order={order} />
              <StyledTabs value={currentTab}>
                <Tab
                  label={t('order.details.orderDetailsTabLabel')}
                  onClick={onOrderInformationTabClicked}
                />
                <Tab
                  label={
                    orderTicketsTotalCount > 0
                      ? t('order.details.ticketsTabLabel', {
                          count: orderTicketsTotalCount,
                        })
                      : t('order.details.noTicketsTabLabel')
                  }
                  onClick={onTicketsTabClicked}
                />
              </StyledTabs>

              <TabContent visible>
                <RouterOutlet />
              </TabContent>

              <Routes>
                <Route
                  path={`${TICKETS_PATH}/*`}
                  element={
                    <TicketsTab
                      order={order}
                      orderStatus={orderStatus}
                      onAddNewTicket={() =>
                        navigate(orderAddTicketRelativeUrl, { replace: true })
                      }
                    />
                  }
                />
                <Route index element={<OrderInformationTab order={order} />} />
              </Routes>
            </>
          )}
        </EditableDetailsWrapperComponent>
      </ScrollableContent>
    </>
  );
};
