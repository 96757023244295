import React, { useCallback, useMemo, useState } from 'react';

import styled from 'styled-components';

import LinkIcon from '@mui/icons-material/Link';
import WifiOffIcon from '@mui/icons-material/WifiOff';

import { APITruckStatuses } from '@cd3p/core/types/api';
import { TrackingItem } from '@cd3p/core/types/common';
import { getTruckDisplayName } from '@cd3p/core/utils/order';
import { Circle } from '@react-google-maps/api';
import { IconButton, Map, Tooltip, Typography } from 'components';
import { useCopyCoordinates } from 'hooks/useCopyCoordinates';

import { Marker, MarkerIconType } from './Marker';

import { useTranslation } from 'third-party';

import { TOOLTIP_APPEAR_DELAY } from 'constants/common';

import { MapProps } from 'components/Map/Map';
import { PopoverCloseButton } from 'components/PopoverCloseButton/PopoverCloseButton';
import { useSitesMarkersState } from 'components/TrackingMap/useSitesMarkersState';

import { roundLocationCoordinates } from 'features/OrderView/orderViewHelpers';

import { LocationCoordinates, LocationT } from 'types/app';

import { palette } from 'styles/theme';

interface LatLngLiteral {
  lat: number;
  lng: number;
}

export type UserSettings = {
  zoom?: number;
  center?: LatLngLiteral;
};

type Props = MapProps & {
  plants?: LocationT[];
  sites?: LocationT[];
  trucks?: TrackingItem[];
  onTruckClick?: (selectedItem: TrackingItem) => void;
  onPlantClick?: (selectedItem: LocationT) => void;
  persistentSiteTooltips?: boolean;
  isSitePinDraggable?: boolean;
  onManualDeliveryLocationChange?: (coordinates: LocationCoordinates) => void;
  defaultMapType?: MapTypes;
};

const CustomizedTooltip = styled.div`
  font-size: 1.6rem;
  line-height: 125%;
  width: 20rem;
`;

const TooltipName = styled.strong`
  text-transform: uppercase;
  font-weight: 700;
`;

const MarkerLabelWrapper = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  margin: 0.2rem 0;
  max-width: 100%;
`;

const SiteCoordinates = styled(Typography)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
`;

const StyledLinkIcon = styled(LinkIcon)`
  transform: rotate(-45deg);
  color: ${props => props.theme.custom.palette.primary800};
`;

const MarkerTooltipWrapper = styled.div`
  position: relative;
`;

const SitePopoverCloseButton = styled(PopoverCloseButton)`
  right: -2rem;
  top: -2rem;
  border-color: ${props => props.theme.custom.palette.info600};
  &:hover {
    border-color: ${props => props.theme.custom.palette.info600};
  }
`;

export enum MapTypes {
  Hybrid = 'hybrid',
  Roadmap = 'roadmap',
}

export const MarkerTooltip = ({
  name,
  address,
  children,
}: {
  name?: string;
  address?: string;
  children?: React.ReactElement;
}) => (
  <CustomizedTooltip>
    <TooltipName>{name}</TooltipName>
    <br />
    {address}
    {children}
  </CustomizedTooltip>
);

export const TrackingMap: React.FC<Props> = ({
  plants = [],
  sites = [],
  trucks = [],
  onTruckClick,
  onPlantClick,
  persistentSiteTooltips,
  isSitePinDraggable,
  onManualDeliveryLocationChange,
  defaultMapType,
  ...props
}) => {
  const copyCoordinates = useCopyCoordinates();

  const { t } = useTranslation();

  const [currentMapType, setCurrentMapType] = useState(defaultMapType);

  const trucksMarkers = useMemo(() => {
    if (trucks?.length > 0) {
      return trucks.map(item => {
        const truckIcon =
          currentMapType === MapTypes.Hybrid
            ? MarkerIconType.TruckSatellite
            : MarkerIconType.Truck;
        const truckReturningIcon =
          currentMapType === MapTypes.Hybrid
            ? MarkerIconType.ReturningTruckSatellite
            : MarkerIconType.ReturningTruck;
        const truckHoverIcon =
          currentMapType === MapTypes.Hybrid
            ? MarkerIconType.TruckSatelliteHover
            : MarkerIconType.TruckHover;

        const truckHoverReturningIcon =
          currentMapType === MapTypes.Hybrid
            ? MarkerIconType.ReturningTruckSatelliteHover
            : MarkerIconType.ReturningTruckHover;

        const truckMarkerIconType =
          item.truckStatus === APITruckStatuses.Returning
            ? truckReturningIcon
            : truckIcon;

        const truckHoverMarkerIconType =
          item.truckStatus === APITruckStatuses.Returning
            ? truckHoverReturningIcon
            : truckHoverIcon;

        const labelText = getTruckDisplayName(
          item.truckNumber,
          item.driverFirstName,
          item.driverLastName,
        );

        const markerId = `truck-${item.truckNumber}-${currentMapType}`;

        return (
          <Marker
            key={markerId}
            id={markerId}
            tooltip={!item.isOnline ? t('trackingMap.truckOffline') : null}
            isOnline={item.isOnline}
            label={
              <MarkerLabelWrapper>
                {!item.isOnline && (
                  <WifiOffIcon
                    color="disabled"
                    sx={{
                      fontSize: '1.2rem',
                      marginRight: '0.3rem',
                      marginBottom: '-0.2rem',
                    }}
                  />
                )}
                {labelText}
              </MarkerLabelWrapper>
            }
            icon={truckMarkerIconType}
            iconHover={truckHoverMarkerIconType}
            lat={item.currentLocation?.latitude}
            lng={item.currentLocation?.longitude}
            onClick={() => onTruckClick?.(item)}
            width="1.6rem"
            height="1.6rem"
          />
        );
      });
    }
  }, [trucks, currentMapType, t, onTruckClick]);

  const { getSiteTooltipProps, changeSiteTooltipState, closeAllSiteTooltips } =
    useSitesMarkersState(sites);

  const sitesMarkers = useMemo(() => {
    if (sites?.length > 0) {
      return sites.map(item => {
        const tooltipProps = persistentSiteTooltips
          ? getSiteTooltipProps(item)
          : undefined;
        const markerId = `dest-${item.name}-${currentMapType}`;
        const roundedLocationCoordinates = roundLocationCoordinates(item);
        return (
          item.latitude &&
          item.longitude && (
            <Marker
              key={markerId}
              id={markerId}
              isDraggable={isSitePinDraggable}
              icon={
                currentMapType === MapTypes.Hybrid
                  ? MarkerIconType.SiteSatellite
                  : MarkerIconType.Site
              }
              iconHover={
                currentMapType === MapTypes.Hybrid
                  ? MarkerIconType.SiteSatelliteHover
                  : MarkerIconType.SiteHover
              }
              tooltip={
                <MarkerTooltipWrapper>
                  <MarkerTooltip
                    name={item.name || ''}
                    address={item.address || ''}
                  >
                    <SiteCoordinates>
                      <strong>
                        {roundedLocationCoordinates.latitude},{' '}
                        {roundedLocationCoordinates.longitude}
                      </strong>
                      <Tooltip
                        placement="top-start"
                        disableInteractive
                        enterDelay={TOOLTIP_APPEAR_DELAY}
                        title={t('copyPinLink.tooltip')}
                      >
                        <IconButton
                          onClick={() =>
                            copyCoordinates(
                              roundedLocationCoordinates.longitude,
                              roundedLocationCoordinates.latitude,
                            )
                          }
                        >
                          <StyledLinkIcon />
                        </IconButton>
                      </Tooltip>
                    </SiteCoordinates>
                  </MarkerTooltip>
                  {tooltipProps && (
                    <SitePopoverCloseButton
                      onClose={() => changeSiteTooltipState(item, false)}
                    />
                  )}
                </MarkerTooltipWrapper>
              }
              onMouseEnter={() => changeSiteTooltipState(item, true)}
              onDragEnd={onManualDeliveryLocationChange}
              lat={item.latitude}
              lng={item.longitude}
              width="3rem"
              height="3.7rem"
              tooltipProps={tooltipProps}
            />
          )
        );
      });
    }
  }, [
    sites,
    persistentSiteTooltips,
    getSiteTooltipProps,
    currentMapType,
    isSitePinDraggable,
    t,
    onManualDeliveryLocationChange,
    copyCoordinates,
    changeSiteTooltipState,
  ]);

  const plantsMarkers = useMemo(() => {
    if (plants?.length > 0) {
      return plants.map(item => {
        const markerId = `${item.id}-${currentMapType}`;
        return (
          item && (
            <Marker
              key={markerId}
              id={markerId}
              onClick={() => onPlantClick?.(item)}
              icon={
                currentMapType === MapTypes.Hybrid
                  ? MarkerIconType.PlantSatellite
                  : MarkerIconType.Plant
              }
              iconHover={
                currentMapType === MapTypes.Hybrid
                  ? MarkerIconType.PlantSatelliteHover
                  : MarkerIconType.PlantHover
              }
              tooltip={
                <MarkerTooltip
                  name={item.name || ''}
                  address={item.address || ''}
                />
              }
              lat={item.latitude}
              lng={item.longitude}
              width="3.8rem"
              height="3.8rem"
            />
          )
        );
      });
    }
  }, [plants, currentMapType, onPlantClick]);

  const plantCircles = useMemo(() => {
    return plants.map(it => (
      <Circle
        key={it.id}
        center={{
          lat: it.latitude,
          lng: it.longitude,
        }}
        radius={300}
        options={{
          strokeColor: palette.secondary900,
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: palette.primary500,
          fillOpacity: 0.5,
        }}
      />
    ));
  }, [plants]);

  const closeAllTooltips = useCallback(() => {
    closeAllSiteTooltips();
  }, [closeAllSiteTooltips]);

  const markers = useMemo(() => [...plants, ...sites], [plants, sites]);

  return (
    <Map
      defaultMapType={defaultMapType}
      markers={markers}
      closeAllTooltips={closeAllTooltips}
      onMapTypeChanged={setCurrentMapType}
      {...props}
    >
      {plantCircles}
      {plantsMarkers}
      {sitesMarkers}
      {trucksMarkers}
    </Map>
  );
};
