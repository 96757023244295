import React from 'react';

import styled from 'styled-components';

import NotificationAddOutlinedIcon from '@mui/icons-material/NotificationAddOutlined';

import { APISubscribedUser } from '@cd3p/core/types/api';
import { getColorForUserAvatar } from '@cd3p/core/utils/user';
import { Avatar, IconButton, Typography } from 'components';

import { _ } from 'third-party';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 0.4rem;
`;

const StyledNotificationAddOutlinedIcon = styled(NotificationAddOutlinedIcon)`
  width: 1.7rem;
  height: 1.7rem;
  margin-left: -0.2rem;
  color: ${props => props.theme.custom.palette.secondary900};
`;

const AddIconButton = styled(IconButton)`
  background-color: ${props => props.theme.custom.palette.primary500};
  border: 0.1rem solid ${props => props.theme.custom.palette.white};
  align-items: center;
  justify-content: center;
  margin-left: -0.4rem;
  box-sizing: content-box;
  width: 3rem;
  height: 3rem;
  padding: 0;
`;

const MemberAvatar = styled(Avatar)`
  border: 0.1rem solid ${props => props.theme.custom.palette.white};
  margin-left: -0.4rem;
`;

const HiddenAvatar = styled(MemberAvatar)`
  background-color: ${props => props.theme.custom.palette.muted300};
`;

const HiddenText = styled(Typography)`
  font-size: 1.4rem;
  font-weight: 700;
  color: ${props => props.theme.custom.palette.secondary900};
`;

type Props = {
  onSubscribeClick?: () => void;
  members: APISubscribedUser[] | null;
};

const DEFAULT_MEMBERS_LIST_LENGTH = 10;

export const SubscribedTeamMembersList: React.FC<Props> = React.memo(
  ({ onSubscribeClick, members }) => {
    const displayMembers = _.take(members, DEFAULT_MEMBERS_LIST_LENGTH);
    const hiddenMembersCount = Number(members?.length) - displayMembers.length;
    return (
      <Row>
        {displayMembers.map(user => {
          return (
            <MemberAvatar
              key={user?.id}
              size="3rem"
              firstName={user?.firstName || ''}
              lastName={user?.lastName || ''}
              backgroundColor={getColorForUserAvatar(user?.id)}
            />
          );
        })}
        {!!hiddenMembersCount && (
          <HiddenAvatar size="3rem">
            <HiddenText
              fontSize={'1rem'}
            >{`+${hiddenMembersCount}`}</HiddenText>
          </HiddenAvatar>
        )}
        {onSubscribeClick && (
          <AddIconButton
            aria-label="add"
            id="add-button"
            disableFocusRipple
            sx={{
              ':hover': {
                background: theme => theme.custom.palette.primary700,
              },
            }}
            onClick={onSubscribeClick}
          >
            <StyledNotificationAddOutlinedIcon />
          </AddIconButton>
        )}
      </Row>
    );
  },
);
