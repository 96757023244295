import React, { useCallback } from 'react';

import styled, { useTheme } from 'styled-components';

import { APICallBackFormStatus, APIOrder } from '@cd3p/core/types/api';
import { Grid, LoadingButton, Typography } from 'components';
import { useHandleApiResult } from 'hooks/useHandleApiResult';

import { useSelector, useTranslation } from 'third-party';

import { useOrder } from 'modules/order';

import { orderSelectors } from 'selectors';

const ApproveButton = styled(LoadingButton)`
  min-width: 18rem;
`;

const SubTitle = styled(Typography)`
  color: ${props => props.theme.custom.palette.darkText};
  line-height: 1.6rem;
  font-size: 1.6rem;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1.5rem;
  gap: 0.5rem;
`;

type Props = {
  order: APIOrder;
};

export const OrderCallback: React.FC<Props> = ({ order }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const { updateOrderCallback } = useOrder();

  const updateOrderCallbackPending = useSelector(
    orderSelectors.updateOrderCallbackPending,
  );
  const handleApiResult = useHandleApiResult();

  const onApprove = useCallback(() => {
    if (order) {
      handleApiResult(
        async () =>
          await updateOrderCallback(order?.id, {
            callBackFormStatus: APICallBackFormStatus.CallbackConfirmed,
          }),
        ({ showBaseToast }) => {
          showBaseToast(
            t(
              'order.details.orderCallbackSubmitted.toast.callbackConfirmedTitle',
            ),
          );
        },
      );
    }
  }, [handleApiResult, order, t, updateOrderCallback]);

  return (
    <Grid item>
      <Typography variant="h5" color={theme.custom.palette.darkText}>
        {t('order.details.orderCallbackSubmitted.title')}
      </Typography>
      <Typography
        variant="body1"
        marginTop="0.8rem"
        marginBottom={'1.2rem'}
        color={theme.custom.palette.gray}
      >
        {t('order.details.orderCallbackSubmitted.description', {
          companyName: order?.company.name,
        })}
      </Typography>
      <Row>
        <SubTitle variant="subtitle2">
          {t('order.details.orderCallbackSubmitted.quantityLabel')}:
        </SubTitle>
        <Typography
          variant="body1"
          fontWeight={600}
          color={theme.custom.palette.gray}
        >
          {t(`order.details.orderCallbackSubmitted.quantityValue`, {
            quantityValue: order?.callbackFormVolume || 0,
          })}
        </Typography>
      </Row>
      <Row>
        <SubTitle variant="subtitle2">
          {t('order.details.orderCallbackSubmitted.aditionalDetailsLabel')}:
        </SubTitle>
        <Typography
          variant="body1"
          fontWeight={600}
          color={theme.custom.palette.gray}
        >
          {order?.additionalCallbackFormDetails ||
            t('common.entityNameUndefined')}
        </Typography>
      </Row>
      <Typography variant="body1" color={theme.custom.palette.gray}>
        {t('order.details.orderCallbackSubmitted.confirmText', {
          companyName: order?.company.name,
        })}
      </Typography>
      <Grid marginTop="1.6rem">
        <ApproveButton
          color="primary"
          variant="contained"
          onClick={onApprove}
          disabled={updateOrderCallbackPending}
        >
          {t('order.details.orderCallbackSubmitted.confirmCallback')}
        </ApproveButton>
      </Grid>
    </Grid>
  );
};
