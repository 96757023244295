import React from 'react';

import styled from 'styled-components';

import CloseIcon from '@mui/icons-material/Close';

import { APIOrder } from '@cd3p/core/types/api';
import { Button, FormField, IconButton, Stack, Typography } from 'components';
import { useHandleApiResult } from 'hooks/useHandleApiResult';

import { FieldError, useForm, useSelector, useTranslation } from 'third-party';

import { useOrder } from 'modules/order';

import { orderSelectors } from 'selectors';

const Wrapper = styled(Stack)`
  position: relative;
  background-color: white;
  width: 80%;
  max-width: 81.5rem;
  border-radius: 0.4rem;
  border: 1px solid ${props => props.theme.custom.palette.primary900};
  padding: 2rem;
`;

const SubTitle = styled(Typography)`
  color: ${props => props.theme.custom.palette.gray};
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 150%;
  text-transform: none;
  margin-top: 0.8rem;
`;

const StyledFormField = styled(FormField)`
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  max-width: 41.7rem;
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 1rem;
  right: 1rem;
`;

enum FormFields {
  OrderNumber = 'orderNumber',
}

type FormProps = {
  [FormFields.OrderNumber]: string;
};

const defaultValues = {
  [FormFields.OrderNumber]: '',
};

type Props = {
  order: APIOrder;
  onClose: () => void;
  className?: string;
};

export const BatchingOrderNumber: React.FC<Props> = ({
  order,
  className,
  onClose,
}) => {
  const { t } = useTranslation();

  const { updateOrderBatchNumber } = useOrder();

  const updateOrderBatchNumberPending = useSelector(
    orderSelectors.updateOrderBatchNumberPending,
  );

  const {
    handleSubmit,
    control,
    formState: { isValid, errors },
  } = useForm<FormProps>({
    mode: 'onChange',
    defaultValues,
  });

  const handleApiResult = useHandleApiResult();

  const updateOrderCallback = ({
    data,
    successMessage,
  }: {
    data?: FormProps;
    successMessage?: string;
  } = {}) => {
    return handleApiResult(
      () =>
        updateOrderBatchNumber(order.id, {
          orderBatchNumber: data?.[FormFields.OrderNumber]?.trim() ?? '',
        }),
      ({ showBaseToast }) => {
        onClose();
        successMessage && showBaseToast(successMessage);
      },
    );
  };

  const onCloseCallback = () => {
    updateOrderCallback();
  };

  const onSubmit = async (data: FormProps) => {
    if (!isValid) {
      return;
    }
    await updateOrderCallback({
      data,
      successMessage: t('order.batchingOrderNumber.successMessage'),
    });
  };

  return (
    <Wrapper className={className}>
      <CloseButton
        disabled={updateOrderBatchNumberPending}
        onClick={onCloseCallback}
      >
        <CloseIcon />
      </CloseButton>
      <Typography variant="h5">
        {t('order.batchingOrderNumber.title')}
      </Typography>
      <SubTitle variant="subtitle2">
        {t('order.batchingOrderNumber.subtitle')}
      </SubTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledFormField
          isRequired
          isDisabled={updateOrderBatchNumberPending}
          fieldName={FormFields.OrderNumber}
          fieldError={errors[FormFields.OrderNumber] as FieldError}
          label={t('order.batchingOrderNumber.fieldLabel')}
          placeholder={t('order.batchingOrderNumber.fieldPlaceholder')}
          requiredErrorMessage={t('common.form.emptyFieldError')}
          control={control}
          maxLength={200}
        />
        <Stack direction="row" gap="2.5rem">
          <Button
            color="secondary"
            variant="outlined"
            onClick={onCloseCallback}
            disabled={updateOrderBatchNumberPending}
          >
            {t('common.cancel')}
          </Button>
          <Button
            color="primary"
            type="submit"
            variant="contained"
            disabled={updateOrderBatchNumberPending || !isValid}
          >
            {t('common.submit')}
          </Button>
        </Stack>
      </form>
    </Wrapper>
  );
};
