import styled from 'styled-components';

export const MessageSendTime = styled.div`
  align-self: flex-end;
  font-weight: 400;
  font-size: 1rem;
`;

export const StyledMessageBubbleWrapper = styled.div`
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
  > * {
    font-family: Source Sans Pro, serif;
  }
  // load more button styles
  .cs-message:first-child {
    max-width: initial;
    .cs-message__content-wrapper {
      width: 100%;
      .cs-message__content {
        background-color: transparent;
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }
  .cs-message__content-wrapper {
    margin-top: 1rem;
  }
  .cs-message-list__scroll-wrapper {
    padding: 2rem 1.2rem 1rem 1.8rem;
  }
  .cs-main-container {
    flex-grow: 1;
    border: none;
    height: auto;
  }
  .cs-message__content {
    background-color: ${props => props.theme.custom.palette.primary700};
    padding: 1.2rem 1.6rem !important;
    color: ${props => props.theme.custom.palette.statusText};
    font-weight: 600;
    font-size: 1.4rem;
    ${MessageSendTime} {
      color: ${props => props.theme.custom.palette.statusText};
    }
  }
  .cs-message__sender-name {
    margin-bottom: 0.5rem;
    color: ${props => props.theme.custom.palette.muted600};
    font-weight: 600;
    font-size: 1.2rem;
    text-transform: uppercase;
  }
  .cs-conversation-header {
    margin-bottom: 1rem;
    background-color: ${props => props.theme.custom.palette.primary50};
    font-size: 1.5rem;
  }
  .cs-conversation-header__info,
  .cs-conversation-header__user-name {
    background-color: ${props => props.theme.custom.palette.primary50};
    padding: 0.2rem;
  }
  .svg-inline--fa {
    height: 2.5rem;
  }
  .cs-button--ellipsis {
    color: ${props => props.theme.custom.palette.primary900};
  }
  .ps__thumb-y {
    background-color: ${props => props.theme.custom.palette.primary900};
  }
  .ps__rail-y:hover > .ps__thumb-y,
  .ps__rail-y:focus > .ps__thumb-y,
  .ps__rail-y.ps--clicking .ps__thumb-y {
    background-color: ${props => props.theme.custom.palette.primary900};
    width: 11px;
  }
  .cs-conversation {
    padding: 1.2rem;
    font-size: 1.5rem;
    &__name {
      padding-bottom: 0.4rem;
    }
    &:hover {
      background-color: ${props => props.theme.custom.palette.primary100};
    }
  }
  .cs-loader::before {
    border-color: ${props => props.theme.custom.palette.primary600};
  }
  .cs-loader::after {
    border-color: ${props => props.theme.custom.palette.primary100} transparent
      transparent transparent;
  }
  .cs-overlay::before,
  .cs-overlay {
    background-color: transparent;
  }
  .cs-message--incoming {
    & .cs-message__content {
      background: ${props => props.theme.custom.palette.muted100};
      color: ${props => props.theme.custom.palette.muted900};
    }
    ${MessageSendTime} {
      align-self: flex-start;
      color: ${props => props.theme.custom.palette.muted700};
    }
  }
  .cs-message--incoming.bot-message {
    & .cs-message__content {
      background: ${props => props.theme.custom.palette.secondary50};
    }
  }
  .cs-message--incoming .cs-message__content {
    align-self: flex-start;
  }
  .system-user-message-wrapper {
    justify-content: center;
    margin-right: auto;
    margin-left: auto;
    .cs-message__content-wrapper {
      // &.cs-message__content { has lower specificity and is overridden by this
      > div {
        background-color: transparent !important;
      }
      .system-user-message {
        color: ${props => props.theme.custom.palette.muted600};
      }
    }
  }
  .cs-message-separator {
    color: ${props => props.theme.custom.palette.secondary900};
    font-weight: 600;
    font-size: 1.6rem;
    &:before,
    &:after {
      background-color: transparent;
    }
  }
  .cs-message-list__loading-more {
    padding-top: 1rem;
    height: 4rem;
    .cs-loader {
      width: 2rem;
      height: 2rem;
    }
  }
`;

export const MessageInnerContent = styled.div`
  display: flex;
  flex-flow: column;
  gap: 1rem;
  line-height: normal;
`;
