import React from 'react';

import styled from 'styled-components';

import InfoIcon from '@mui/icons-material/Info';

import { Tooltip } from 'components';

import { useTheme } from 'third-party';

type Props = {
  title: string;
  icon?: React.ReactElement;
};

const StyledInfoIcon = styled(InfoIcon)`
  width: 1.6rem;
  height: auto;
  margin-left: 0.5rem;
`;

export const InfoIconTooltip: React.FC<Props> = ({ title, icon }) => {
  const theme = useTheme();
  return (
    <Tooltip
      arrow
      placement="right"
      componentsProps={{
        tooltip: {
          style: {
            padding: '0.5rem 0.75rem',
            opacity: 0.9,
            backgroundColor: theme.custom.palette.darkText,
          },
        },
        arrow: {
          style: {
            color: theme.custom.palette.darkText,
          },
        },
      }}
      title={title}
    >
      {icon || <StyledInfoIcon color="disabled" />}
    </Tooltip>
  );
};
