import React, { useCallback, useEffect, useMemo, useState } from 'react';

import styled from 'styled-components';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import {
  ON_ORDER_CREATE_EVENT,
  ON_ORDER_UPDATE_EVENT,
  ON_TRUCK_LOCATION_UPDATE_EVENT,
} from '@cd3p/core/constants/hubs';
import { APIOrder, APITrackingItem } from '@cd3p/core/types/api';
import { TrackingItem } from '@cd3p/core/types/common';
import {
  DispatcherCanAccess,
  RequiredActionsBanner,
  Tab,
  TableSortLabel,
  Tabs,
  TrackingMap,
  Typography,
} from 'components';
import { subscribe } from 'hooks/usePubSub';
import { useTableColumnVisibilityControl } from 'hooks/useTableColumnVisibilityControl';
import { useTableSettings } from 'hooks/useTableSettings';
import { OrderTicket } from 'selectors/order';

import { TrucksTab } from './TrucksTab';

import {
  Navigate,
  Route,
  RouterLink,
  Routes,
  _,
  dayjs,
  useMatch,
  useNavigate,
  useSelector,
  useTranslation,
} from 'third-party';

import { APP_EVENTS } from 'constants/appEvent';
import { AppTableSettings } from 'constants/common';
import {
  TODAY_ORDERS_ACTIVE_TICKETS_URL_PATH,
  TODAY_ORDERS_AVAILABLE_TRUCKS_URL_PATH,
  allOrdersUrl,
  todayOrdersActiveTicketsUrl,
  todayOrdersAvailableTrucksUrl,
  todayOrdersUrl,
} from 'constants/url';

import { useCache } from 'modules/cache';
import { ordersDefaultFilters } from 'modules/common/ordersList';
import { useOrder } from 'modules/order';
import { useOrdersCache } from 'modules/ordersCache';
import { useTodayOrdersList } from 'modules/todayOrdersList';
import { useTracking } from 'modules/tracking';

import {
  activeTicketsListSelectors,
  appSelectors,
  cacheSelectors,
  requiredActionsListSelectors,
  todayOrdersListSelectors,
  trackingSelectors,
} from 'selectors';

import { StyledTableColumnLabel } from 'components/Table/Table';
import { UserSettings } from 'components/TrackingMap/TrackingMap';

import {
  OrdersTable,
  OrdersTableColumn,
} from 'features/OrdersTable/OrdersTable';
import { ActiveTicketsTab } from 'features/TodayOrdersView/ActiveTicketsTab';

import { getSiteData } from 'utils/order';
import { composeLoadOrdersRequestBody } from 'utils/ordersList';
import { useOrdersSockets } from 'utils/sockets';

import { LocationT } from 'types/app';

import { TabTitle, TabWrapper } from 'styles/theme';

const StyledViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: ${props => props.theme.custom.dimensions.viewVerticalPadding}rem
    ${props => props.theme.custom.dimensions.viewHorizontalPadding}rem 0
    ${props => props.theme.custom.dimensions.viewHorizontalPadding}rem;
  overflow: hidden;
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: baseline;
`;

const SubHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const SubHeaderTitle = styled(Typography)`
  color: ${props => props.theme.custom.palette.primary900};
  padding-right: 1rem;
  margin-right: auto;
`;

const ViewAllOrdersLink = styled(RouterLink)`
  font-weight: 900;
  font-size: 1.6rem;
  color: ${props => props.theme.custom.palette.secondary600};
  text-transform: uppercase;
  text-decoration: none;
  display: flex;
  align-items: center;
  margin-left: 1rem;
`;

const Header = styled(Typography)`
  color: ${props => props.theme.custom.palette.secondary500};
  margin-right: 1rem;
`;

const TodayDate = styled(Typography)`
  color: ${props => props.theme.custom.palette.gray};
  margin-left: auto;
`;

const BasicSection = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

const TopSection = styled(BasicSection)`
  max-height: 50%;
  padding-bottom: 3rem;
`;

const BottomSection = styled(BasicSection)`
  display: flex;
  flex-direction: row;
  padding-bottom: 0;
  flex: 1 1 auto;
  overflow: hidden;
`;

const TabsWrapper = styled.div`
  max-width: 50%;
  min-width: 50%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
`;

const MapWrapper = styled.div`
  flex-grow: 1;
  margin-top: 3.9rem;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.custom.palette.muted100};
`;

const StyledTableSortLabel = styled(TableSortLabel)`
  font-weight: 900;
  font-size: 1.6rem;
`;

const StyledTableColumnLabelComponent = styled(StyledTableColumnLabel)`
  font-weight: 900;
  font-size: 1.6rem;
`;

const tableComponents = {
  TableSortLabel: StyledTableSortLabel,
  TableColumnLabel: StyledTableColumnLabelComponent,
};

const mapActiveTicketsSitesForMap = (activeTickets: OrderTicket[]) => {
  const activeTicketsSites: LocationT[] = [];
  _.forEach(activeTickets, ticket => {
    activeTicketsSites.push(getSiteData(ticket.order));
  });
  return _.uniqBy(activeTicketsSites, 'name');
};

export const TodayOrdersView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const matchAvailableTrucksUrl = useMatch(todayOrdersAvailableTrucksUrl());
  const matchActiveTicketsUrl = useMatch(todayOrdersActiveTicketsUrl());
  const matchIndexUrl = useMatch(todayOrdersUrl());
  const currentProviderId = useSelector(appSelectors.providerId);

  const [mapUserSettings, changeMapUserSettings] =
    useState<null | UserSettings>(null);

  const { updateOrder } = useOrder();
  const { updateOrderInCache } = useOrdersCache();

  const {
    loadTodayOrders,
    updateTodayOrdersListSorting,
    loadMoreTodayOrders,
    updateOrderPositionInTodayOrders,
  } = useTodayOrdersList();

  const { loadPlants } = useCache();

  const { updateTracking } = useTracking();

  const providerId = useSelector(appSelectors.providerId);

  const todayOrdersListItems = useSelector(
    todayOrdersListSelectors.todayOrdersListItems,
  );

  const availableTrucks = useSelector(trackingSelectors.availableTrucks);
  const updateTruckLocationPending = useSelector(
    trackingSelectors.updateTruckLocationPending,
  );

  const ordersLoaded = useSelector(todayOrdersListSelectors.todayOrdersLoaded);

  const plants = useSelector(cacheSelectors.plants);

  const loadOrdersPending = useSelector(
    todayOrdersListSelectors.loadTodayOrdersPending,
  );

  const loadMoreOrdersPending = useSelector(
    todayOrdersListSelectors.loadMoreTodayOrdersPending,
  );

  const ordersListCount = useSelector(
    todayOrdersListSelectors.todayOrdersListCount,
  );

  const ordersListSorting = useSelector(
    todayOrdersListSelectors.todayOrdersListSorting,
  );

  const ordersListFilters = useSelector(
    todayOrdersListSelectors.todayOrdersListFilters,
  );

  const { sortField, sortOrder } = ordersListSorting[0];

  const { pageNumber, pageSize } = useSelector(
    todayOrdersListSelectors.todayOrdersListPagination,
  );

  const activeTicketsLoaded = useSelector(
    activeTicketsListSelectors.activeTicketsLoaded,
  );

  const requiredActionsListCounter = useSelector(
    requiredActionsListSelectors.requiredActionsListCounter,
  );

  const tableSettings = useTableSettings(AppTableSettings.TodayOrders, {
    isResizable: true,
    defaultFilters: ordersDefaultFilters,
  });

  const tableColumnVisibility = useTableColumnVisibilityControl();

  const onTrucksTableRowClick = useCallback((item: TrackingItem) => {
    changeMapUserSettings({
      center: {
        lat: item.currentLocation.latitude,
        lng: item.currentLocation.longitude,
      },
      zoom: 15,
    });
  }, []);

  useEffect(() => {
    loadPlants();
  }, [loadPlants]);

  const [activeTicketsData, setActiveTicketsData] = useState<{
    activeTicketsSites: LocationT[];
    activeTicketsTrucks: TrackingItem[];
  }>({
    activeTicketsSites: [],
    activeTicketsTrucks: [],
  });

  useEffect(() => {
    return subscribe(
      APP_EVENTS.UPDATE_ACTIVE_TICKETS,
      (
        activeTickets: OrderTicket[],
        allTrackingItemsValues: TrackingItem[],
      ) => {
        const activeTicketsSites = mapActiveTicketsSitesForMap(activeTickets);
        const activeTicketsTrucks = activeTickets.reduce<TrackingItem[]>(
          (result, it) => {
            const driverTrackingData = _.find(
              allTrackingItemsValues,
              tracking => tracking.truckNumber === it.truck.truckNumber,
            );
            return [
              ...result,
              ...(driverTrackingData ? [driverTrackingData] : []),
            ];
          },
          [],
        );
        setActiveTicketsData({
          activeTicketsSites,
          activeTicketsTrucks,
        });
      },
    );
  }, []);

  const { activeTicketsSites, activeTicketsTrucks } = activeTicketsData;

  useOrdersSockets(
    () => [
      {
        method: ON_ORDER_UPDATE_EVENT,
        action: (providerId: string, orderId: number) => {
          updateOrderPositionInTodayOrders(orderId);
        },
      },
      {
        method: ON_ORDER_CREATE_EVENT,
        action: (providerId: string, orderId: number) => {
          updateOrderPositionInTodayOrders(orderId);
        },
      },
    ],
    [updateOrderPositionInTodayOrders],
  );

  useOrdersSockets(
    () => [
      {
        method: ON_TRUCK_LOCATION_UPDATE_EVENT,
        action: (providerId, truckNumber, result: APITrackingItem) => {
          if (providerId === currentProviderId) {
            updateTracking(result);
          }
        },
      },
    ],
    [currentProviderId, updateTracking],
  );

  useEffect(() => {
    return subscribe(
      APP_EVENTS.UPDATE_ORDER_IN_LIST,
      (orderDetails: Partial<APIOrder> & { id: number }) => {
        updateOrderInCache(orderDetails.id, orderDetails);
      },
    );
  }, [updateOrderInCache]);

  // const currentTab = matchAvailableTrucksUrl
  //   ? BottomTabs.AvailableTrucks
  //   : BottomTabs.ActiveTickets;

  const currentTab = useMemo(() => {
    if (matchActiveTicketsUrl) {
      return TODAY_ORDERS_ACTIVE_TICKETS_URL_PATH;
    } else if (matchAvailableTrucksUrl) {
      return TODAY_ORDERS_AVAILABLE_TRUCKS_URL_PATH;
    } else {
      return null;
    }
  }, [matchActiveTicketsUrl, matchAvailableTrucksUrl]);

  const todayOrdersColumnProps = useMemo<OrdersTableColumn[]>(() => {
    return [
      {
        id: 'deliveryDate',
        canBeHidden: false,
      },
      {
        id: 'deliveryTime',
        order: 1,
        sortable: true,
      },
      {
        id: 'orderName',
        order: 0,
      },
    ];
  }, []);

  useEffect(() => {
    loadTodayOrders(
      composeLoadOrdersRequestBody(providerId, {
        pageNumber,
        pageSize,
        sorting: ordersListSorting,
        filters: ordersListFilters,
      }),
    );
  }, [
    providerId,
    loadTodayOrders,
    pageNumber,
    pageSize,
    ordersListSorting,
    ordersListFilters,
  ]);

  return (
    <StyledViewWrapper>
      <TopSection>
        <HeaderWrapper>
          <Header variant="h4">{t('todayOrders.title')}</Header>
          <TodayDate variant="h3">{dayjs().format('MM/DD/YY')}</TodayDate>
        </HeaderWrapper>
        <SubHeaderWrapper>
          <SubHeaderTitle variant="h4">
            {t('todayOrders.activeOrderTableTitle')}
          </SubHeaderTitle>
          {tableColumnVisibility.button}
          <ViewAllOrdersLink to={allOrdersUrl()}>
            {t('todayOrders.viewAllOrdersLink')}
            <ChevronRightIcon />
          </ViewAllOrdersLink>
        </SubHeaderWrapper>
        <DispatcherCanAccess>
          <RequiredActionsBanner count={requiredActionsListCounter} />
        </DispatcherCanAccess>
        <OrdersTable
          isResizable={tableSettings.isResizable}
          isFiltersApplied={tableSettings.isFiltersApplied}
          tableSettings={tableSettings}
          hiddenColumns={tableSettings.hiddenColumns}
          viewLabel={t('todayOrders.title')}
          columnProps={todayOrdersColumnProps}
          loadMoreOrders={loadMoreTodayOrders}
          updateOrderInList={updateOrder}
          updateOrderPosition={updateOrderPositionInTodayOrders}
          updateSorting={updateTodayOrdersListSorting}
          sortField={sortField}
          sortOrder={sortOrder}
          orders={todayOrdersListItems}
          ordersLoaded={ordersLoaded}
          loadItemsPending={loadOrdersPending}
          loadMoreItemsPending={loadMoreOrdersPending}
          ordersCount={ordersListCount}
          rowHeight="5.7rem"
          headerHeight="4.3rem"
          components={tableComponents}
          emptyText={t('todayOrders.table.emptyText')}
        />
        {tableColumnVisibility.popup}
      </TopSection>
      {ordersLoaded && (
        <BottomSection>
          <TabsWrapper>
            {currentTab && (
              <Tabs value={currentTab}>
                <Tab
                  value={TODAY_ORDERS_ACTIVE_TICKETS_URL_PATH}
                  label={
                    <TabWrapper>
                      <TabTitle>
                        {t('todayOrders.activeTicketsTabLabel')}
                      </TabTitle>
                    </TabWrapper>
                  }
                  onClick={() =>
                    navigate(todayOrdersActiveTicketsUrl(), { replace: true })
                  }
                />
                <Tab
                  value={TODAY_ORDERS_AVAILABLE_TRUCKS_URL_PATH}
                  label={
                    <TabWrapper>
                      <TabTitle>
                        {t('todayOrders.availableTrucksTabLabel')}
                      </TabTitle>
                    </TabWrapper>
                  }
                  onClick={() =>
                    navigate(todayOrdersAvailableTrucksUrl(), { replace: true })
                  }
                />
              </Tabs>
            )}
            <Routes>
              <Route
                path={TODAY_ORDERS_ACTIVE_TICKETS_URL_PATH}
                element={<ActiveTicketsTab />}
              />
              <Route
                path={TODAY_ORDERS_AVAILABLE_TRUCKS_URL_PATH}
                element={<TrucksTab onRowClick={onTrucksTableRowClick} />}
              />
            </Routes>
            {(!currentTab || matchIndexUrl) && (
              <Navigate to={todayOrdersActiveTicketsUrl()} replace />
            )}
          </TabsWrapper>
          <MapWrapper>
            <Routes>
              <Route
                path={TODAY_ORDERS_ACTIVE_TICKETS_URL_PATH}
                element={
                  <TrackingMap
                    plants={plants}
                    sites={activeTicketsSites}
                    trucks={activeTicketsTrucks}
                    isLoading={!activeTicketsLoaded}
                  />
                }
              />
              <Route
                path={TODAY_ORDERS_AVAILABLE_TRUCKS_URL_PATH}
                element={
                  <TrackingMap
                    plants={plants}
                    isLoading={updateTruckLocationPending}
                    trucks={availableTrucks}
                    userSettings={mapUserSettings}
                    changeUserSettings={changeMapUserSettings}
                  />
                }
              />
            </Routes>
          </MapWrapper>
        </BottomSection>
      )}
    </StyledViewWrapper>
  );
};
