import React, { useCallback, useEffect, useMemo, useState } from 'react';

import styled, { useTheme } from 'styled-components';

import WifiOffIcon from '@mui/icons-material/WifiOff';

import {
  APISortOrder,
  APITrackingItem,
  APITruckStatuses,
} from '@cd3p/core/types/api';
import { TrackingItem } from '@cd3p/core/types/common';
import { getSortOrder } from '@cd3p/core/utils/common';
import { getTruckDisplayName } from '@cd3p/core/utils/order';
import {
  Button,
  CircularProgress,
  Popover,
  PopoverCloseButton,
  Table,
  TableCell,
  TableHead,
  TableSortLabel,
  TicketETA,
  Trans,
  TruckStatusLabel,
  Typography,
} from 'components';

import { AssignToTicketPopover } from './AssignToTicketPopover';

import { useSelector, useTranslation } from 'third-party';

import { useTracking } from 'modules/tracking';

import { appSelectors, trackingSelectors } from 'selectors';

import { getTruckETA, truckStatusesForTodayOrders } from 'utils/order';

const AvailableTrucksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 0;
  max-height: 100%;
  padding-bottom: 1rem;
  padding-top: 1rem;
`;

const StyledTableBodyCell = styled(TableCell)<{ height: string }>`
  height: ${props => props.height};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 0;
  padding: 0 1.6rem;
  position: relative;

  &:not(:last-child):after {
    position: absolute;
    top: 1rem;
    right: 0;
    bottom: 0;
    border-right: 1px solid ${props => props.theme.custom.palette.truegray300};
    width: 1px;
    content: '';
  }

  &:first-child {
    border-left: 1px solid ${props => props.theme.custom.palette.truegray300};
  }

  &:last-child {
    border-right: 1px solid ${props => props.theme.custom.palette.truegray300};
  }
`;

const CellName = styled(Typography)`
  font-weight: 600;
  font-size: 1.4rem;
  color: ${props => props.theme.custom.palette.muted800};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const StyledTicketETA = styled(TicketETA)`
  margin-left: 1rem;
  text-transform: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: inherit;
  flex: 1 1 auto;
`;

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const AssignTicketButton = styled(Button)`
  margin-left: -1rem;
  white-space: nowrap;
`;

const TicketNumber = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledTableHead = styled(TableHead)`
  box-shadow: 0 2px 3px rgb(0 0 0 / 25%);
  position: sticky;
  top: 0;
  z-index: 2;
`;

const StyledTableSortLabel = styled(TableSortLabel)`
  font-weight: 900;
  font-size: 1.6rem;
`;

const StyledTableColumnLabel = styled(Typography)`
  font-weight: 900;
  font-size: 1.6rem;
  text-transform: uppercase;
  color: ${props => props.theme.custom.palette.primary900};
`;

const tableComponents = {
  TableBodyCell: StyledTableBodyCell,
  TableHead: StyledTableHead,
  TableSortLabel: StyledTableSortLabel,
  TableColumnLabel: StyledTableColumnLabel,
};

enum TableColumns {
  TruckNumber = 'truckNumber',
  TruckStatus = 'truckStatus',
  TicketNumber = 'ticketNumber',
}

type Props = {
  onRowClick?: (item: TrackingItem) => void;
};

export const TrucksTab: React.FC<Props> = ({ onRowClick }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selectedTruck, setSelectedTruck] = useState<APITrackingItem | null>(
    null,
  );

  const { loadTracking, updateTrackingSorting } = useTracking();

  const availableTrucks = useSelector(trackingSelectors.availableTrucks);
  const providerTimeZone = useSelector(appSelectors.providerTimeZone);

  const { sortField, sortOrder } = useSelector(trackingSelectors.sorting);

  const trackingLoaded = useSelector(trackingSelectors.trackingLoaded);
  const loadTrackingPending = useSelector(
    trackingSelectors.loadTrackingPending,
  );

  const availableTrucksCount = availableTrucks.length;

  useEffect(() => {
    const loadTrackingData = async () => {
      await updateTrackingSorting({
        sortField: TableColumns.TruckStatus,
        sortOrder: APISortOrder.ASC,
      });
      loadTracking({ truckStatuses: truckStatusesForTodayOrders });
    };
    loadTrackingData();
  }, [loadTracking, updateTrackingSorting]);

  const open = Boolean(anchorEl);
  const popoverId = open ? 'assignedNotifiedDriversPopover' : undefined;

  const columns = useMemo(() => {
    return [
      {
        id: TableColumns.TruckNumber,
        dataId: TableColumns.TruckNumber as keyof (typeof availableTrucks)[0],
        label: t('todayOrders.availableTrucks.headerTruckDriver'),
        width: '25%',
        formatter: (
          value: TrackingItem['truckNumber'],
          trackingItem: TrackingItem,
        ) => {
          const driverName = getTruckDisplayName(
            trackingItem.truckNumber,
            trackingItem.driverFirstName,
            trackingItem.driverLastName,
          );

          return {
            value: driverName,
            element: (
              <CellName>
                {!trackingItem.isOnline && (
                  <WifiOffIcon
                    color="disabled"
                    sx={{ fontSize: '1.2rem', marginRight: '0.8rem' }}
                  />
                )}
                {driverName}
              </CellName>
            ),
          };
        },
      },
      {
        id: TableColumns.TruckStatus,
        dataId: TableColumns.TruckStatus as keyof (typeof availableTrucks)[0],
        label: t('todayOrders.availableTrucks.headerStatus'),
        formatter: (
          value: APITrackingItem['truckStatus'],
          trackingItem: APITrackingItem,
        ) => {
          const readyTruckStatusLabel = trackingItem.plantName
            ? `${trackingItem.truckStatus} at ${trackingItem.plantName}`
            : trackingItem.truckStatus;
          const hasReturningStatus =
            trackingItem.truckStatus === APITruckStatuses.Returning;
          // Do not show ETA for returning trucks for now until it is correctly done on BE
          // const returningTruckStatusLabel = trackingItem.timeOfArrival?.duration
          //   ? getTruckETA(trackingItem.timeOfArrival?.duration)
          //   : trackingItem.truckStatus;
          const truckStatusLabel = String(
            trackingItem.truckStatus === APITruckStatuses.Ready
              ? readyTruckStatusLabel
              : trackingItem.truckStatus,
          );
          const truckStatusTooltip = trackingItem.timeOfArrival?.duration
            ? `${trackingItem.truckStatus} - ${truckStatusLabel}`
            : trackingItem.truckStatus;
          const showTicketETA =
            !!trackingItem.timeOfArrival?.duration && !hasReturningStatus;

          return {
            value: truckStatusTooltip,
            element: (
              <div>
                <TruckStatusLabel
                  value={trackingItem.truckStatus}
                  label={truckStatusLabel}
                  fontSize="1.4rem"
                />
                {showTicketETA && (
                  <StyledTicketETA>
                    <Trans i18nKey="todayOrder.availableTruck.eta">
                      {{
                        duration: getTruckETA(
                          trackingItem.timeOfArrival?.duration,
                        ),
                      }}{' '}
                      min away
                    </Trans>
                  </StyledTicketETA>
                )}
              </div>
            ),
          };
        },
      },
      {
        id: TableColumns.TicketNumber,
        dataId: TableColumns.TicketNumber as keyof (typeof availableTrucks)[0],
        label: t('todayOrders.availableTrucks.headerTicket'),
        width: '18rem',
        sortable: false,
        formatter: (
          value: APITrackingItem['ticketNumber'],
          trackingItem: APITrackingItem,
        ) => {
          const computedTicketNumber = trackingItem.ticketNumber
            ? `#${trackingItem.ticketNumber}`
            : t('common.entityNameUndefined');
          const showButton =
            [APITruckStatuses.Ready, APITruckStatuses.Returning].includes(
              trackingItem.truckStatus,
            ) && !trackingItem.ticketId;
          return {
            value: showButton ? '' : computedTicketNumber,
            element: showButton ? (
              <AssignTicketButton
                variant="text"
                aria-describedby={popoverId}
                onClick={e => openAssignToTicketPopover(e, trackingItem)}
              >
                {t('todayOrders.availableTrucks.assignTicketButton')}
              </AssignTicketButton>
            ) : (
              <TicketNumber
                color={theme.custom.palette.muted800}
                fontSize="1.4rem"
              >
                {computedTicketNumber}
              </TicketNumber>
            ),
          };
        },
      },
    ];
    // we need `count` to update drivers offline status every second
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popoverId, t, theme.custom.palette.muted800, providerTimeZone]);

  const isRowActive = useCallback(
    (trackingItem: TrackingItem) => {
      return selectedTruck?.truckNumber === trackingItem.truckNumber;
    },
    [selectedTruck?.truckNumber],
  );

  const onColumnHeaderClicked = useCallback(
    (columnId: keyof (typeof availableTrucks)[0]) => {
      updateTrackingSorting({
        sortField: columnId,
        sortOrder: getSortOrder(columnId, sortField, sortOrder),
      });
    },
    [sortField, sortOrder, updateTrackingSorting],
  );

  const openAssignToTicketPopover = (
    event: React.MouseEvent<HTMLButtonElement>,
    truck: APITrackingItem,
  ) => {
    setSelectedTruck(truck);
    setAnchorEl(event.currentTarget);
  };

  const closeAssignToTicketPopover = (shouldRefetchData?: boolean) => {
    if (shouldRefetchData) {
      loadTracking({ truckStatuses: truckStatusesForTodayOrders });
    }
    setSelectedTruck(null);
    setAnchorEl(null);
  };

  if (!trackingLoaded) {
    return (
      <LoaderWrapper>
        <CircularProgress />
      </LoaderWrapper>
    );
  }

  return (
    <AvailableTrucksWrapper>
      <Table
        columns={columns}
        items={availableTrucks}
        itemsTotalCount={availableTrucksCount}
        itemsLoaded={trackingLoaded}
        loadItemsPending={loadTrackingPending}
        headerRowHeight="4.2rem"
        sortOrder={sortOrder}
        sortField={sortField}
        emptyText={t('todayOrders.availableTrucks.noData')}
        onColumnHeaderClicked={onColumnHeaderClicked}
        onRowClick={onRowClick}
        components={tableComponents}
        isRowActive={isRowActive}
      />
      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={() => closeAssignToTicketPopover()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        // Adjusting transition here because selected truck is reset
        // before exit transition finishes.
        transitionDuration={{ enter: 200, exit: 0 }}
        PaperProps={{ sx: { overflow: 'visible' } }}
      >
        <PopoverCloseButton onClose={() => closeAssignToTicketPopover()} />
        <AssignToTicketPopover
          selectedTruck={selectedTruck}
          onClose={closeAssignToTicketPopover}
        />
      </Popover>
    </AvailableTrucksWrapper>
  );
};
