import { State } from 'modules';

export const contractorMixTypesItems = (state: State) =>
  state.contractorMixTypes.items;
export const contractorMixTypesCount = (state: State) =>
  state.contractorMixTypes.count;
export const contractorMixTypesLoaded = (state: State) =>
  state.contractorMixTypes.mixTypesLoaded;
export const contractorMixTypesSorting = (state: State) =>
  state.contractorMixTypes.sorting;
export const loadContractorMixTypesPending = (state: State) =>
  state.contractorMixTypes.loadContractorMixTypesPending;
export const loadMoreContractorMixTypesPending = (state: State) =>
  state.contractorMixTypes.loadMoreContractorMixTypesPending;
export const loadProjectMixTypesPending = (state: State) =>
  state.contractorMixTypes.loadProjectMixTypesPending;
export const loadMoreProjectMixTypesPending = (state: State) =>
  state.contractorMixTypes.loadMoreProjectMixTypesPending;
