import React from 'react';

import styled from 'styled-components';

import { Button } from 'components';

const StyledPopoverCloseButton = styled(Button)`
  position: absolute;
  top: -1rem;
  right: -1rem;
  background-color: white;
  width: 2.9rem;
  height: 2.9rem;
  min-width: 0;
  border-radius: 2.9rem;
  border: 2px solid ${props => props.theme.custom.palette.primary500};
  padding: 0;
  z-index: 3;
  color: ${props => props.theme.custom.palette.truegray800};
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  &:hover {
    background-color: white;
  }
`;

type PopoverCloseButtonProps = {
  className?: string;
  onClose: () => void;
  isDisabled?: boolean;
};

export const PopoverCloseButton: React.FC<PopoverCloseButtonProps> = ({
  className,
  onClose,
  isDisabled,
}) => {
  return (
    <StyledPopoverCloseButton
      className={className}
      onClick={onClose}
      variant="outlined"
      color="secondary"
      disabled={isDisabled}
    >
      ✕
    </StyledPopoverCloseButton>
  );
};
