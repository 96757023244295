import { useMemo } from 'react';

import { CONTRACTOR_TICKET_STATUSES } from '@cd3p/core/constants/common';
import {
  generateOrderNameEndpoint,
  orderEndpoint,
} from '@cd3p/core/constants/endpoints';
import {
  CoreOrderState,
  coreOrderMethods,
  orderCoreState,
} from '@cd3p/core/modules/order';
import {
  APIOrder,
  APIOrderBatchNumberRequest,
  APIOrderChangesRequest,
  APITicketParams,
  APIUserType,
} from '@cd3p/core/types/api';
import { httpMethod, simplifyBuilder } from '@cd3p/core/utils/sra';

import { bindActionCreators, useDispatch, useSelector } from 'third-party';

import {
  orderBatchNumberEndpoint,
  orderNotificationEndpoint,
  orderRequestedChangesEndpoint,
  ticketEndpoint,
} from 'constants/endpoints';

import {
  TicketsListState,
  loadMoreTickets,
  loadTickets,
  ticketsDefaultListState,
  updateTicketInState,
  updateTickets,
  updateTicketsFilters,
} from 'modules/common/ticketsList';
import { State } from 'modules/index';

import { appSelectors } from 'selectors';

export type OrderState = {
  orderLoaded: boolean;
  fetchOrderByIdPending: boolean | null;
  orderErrorText: string | null;
  createOrderPending: boolean;
  updateOrderPending: boolean;
  updateOrderCallbackPending: boolean;
  updateOrderBatchNumberPending: boolean;
  updateOrderRequestedChangesPending: boolean;
  sendOrderNotificationPending: boolean;
  addTicketPending: boolean;
  updateTicketPending: boolean;
  loadOrderTicketsPending: boolean;
  loadMoreOrderTicketsPending: boolean;
  loadTicketPending: boolean;
  updateOrderError?: Record<string, any>;
  removeTicketPending: boolean;
} & TicketsListState &
  CoreOrderState;

export const orderState: OrderState = {
  ...orderCoreState,
  tickets: ticketsDefaultListState,
  fetchOrderByIdPending: null,
  orderErrorText: null,
  createOrderPending: false,
  updateOrderPending: false,
  updateOrderCallbackPending: false,
  updateOrderBatchNumberPending: false,
  updateOrderRequestedChangesPending: false,
  sendOrderNotificationPending: false,
  addTicketPending: false,
  updateTicketPending: false,
  loadOrderTicketsPending: false,
  loadMoreOrderTicketsPending: false,
  loadTicketPending: false,
  updateOrderError: undefined,
  removeTicketPending: false,
};

const builder = simplifyBuilder(orderState, {});

export const FETCH_ORDER_BY_ID_ACTION = 'fetchOrderById';
const fetchOrderById = builder.createServerAction(
  (providerId: string, id: number) => ({
    name: FETCH_ORDER_BY_ID_ACTION,
    url: orderEndpoint(providerId, id),
    method: httpMethod.get,
  }),
);

const loadOrder = builder.createServerAction(coreOrderMethods.loadOrder);

const fetchOrderPlants = builder.createServerAction(
  coreOrderMethods.loadOrderPlants,
);

const getUserSubscriptions = builder.createServerAction(
  coreOrderMethods.getUserSubscriptions,
);

const createOrder = builder.createServerAction(coreOrderMethods.createOrder);

const updateOrder = builder.createServerAction(coreOrderMethods.updateOrder);

export const UPDATE_ORDER_BATCH_NUMBER_ACTION = 'updateOrderBatchNumber';
const updateOrderBatchNumber = builder.createServerAction(
  (providerId: string, id: number, body: APIOrderBatchNumberRequest) => ({
    name: UPDATE_ORDER_BATCH_NUMBER_ACTION,
    url: orderBatchNumberEndpoint(providerId, id),
    method: httpMethod.put,
    body,
  }),
);

export const UPDATE_ORDER_REQUESTED_CHANGES_ACTION =
  'updateOrderRequestedChanges';
const updateOrderRequestedChanges = builder.createServerAction(
  (providerId: string, id: number, body: APIOrderChangesRequest) => ({
    name: UPDATE_ORDER_REQUESTED_CHANGES_ACTION,
    url: orderRequestedChangesEndpoint(providerId, id),
    method: httpMethod.put,
    body,
  }),
);

const updateOrderCallback = builder.createServerAction(
  coreOrderMethods.updateOrderCallback,
);

const changeOrderStatus = builder.createServerAction(
  coreOrderMethods.changeOrderStatus,
);

const toggleOrderSubscription = builder.createServerAction(
  coreOrderMethods.toggleOrderSubscription,
);

const resetOrder = builder.createReduxAction(() => ({
  name: 'resetOrder',
  updater: () => ({
    orderLoaded: false,
    orderId: null,
    tickets: orderState.tickets,
    orderPlants: orderState.orderPlants,
    orderPlantsLoaded: false,
  }),
}));

export const ADD_TICKET_ACTION = 'addTicket';
const addTicket = builder.createServerAction(
  (
    providerId: string,
    orderId: number | string,
    body: Partial<APITicketParams>,
  ) => {
    return {
      name: ADD_TICKET_ACTION,
      url: ticketEndpoint(providerId, orderId),
      method: httpMethod.post,
      body,
    };
  },
);

export const UPDATE_TICKET_ACTION = 'updateTicket';
const updateTicket = builder.createServerAction(
  (
    providerId: string,
    orderId: number | string,
    ticketId: number | string,
    body: Partial<APITicketParams>,
  ) => {
    return {
      name: UPDATE_TICKET_ACTION,
      url: ticketEndpoint(providerId, orderId, ticketId),
      method: httpMethod.put,
      body,
    };
  },
);

const updateOrderTicketInState = builder.createReduxAction(
  updateTicketInState<OrderState>('updateOrderTicketInState'),
);

const loadTicket = builder.createServerAction(
  (
    providerId: string,
    orderId: number | string,
    ticketId: number | string,
  ) => ({
    name: 'loadTicket',
    url: ticketEndpoint(providerId, orderId, ticketId),
    method: httpMethod.get,
  }),
);

const generateOrderName = builder.createServerAction(
  (providerId: string, projectId: string) => ({
    name: 'generateOrderName',
    url: generateOrderNameEndpoint(providerId, projectId),
    method: httpMethod.get,
  }),
);

const removeTicket = builder.createServerAction(
  (
    providerId: string,
    orderId: number | string,
    ticketId: number | string,
  ) => ({
    name: 'removeTicket',
    url: ticketEndpoint(providerId, orderId, ticketId),
    method: httpMethod.delete,
    onSuccess: (state: OrderState) => ({
      tickets: {
        ...state.tickets,
        items: state.tickets.items.filter(item => item.id !== ticketId),
        count: state.tickets.count - 1,
      },
    }),
  }),
);

const addOrderSubscriptions = builder.createServerAction(
  coreOrderMethods.addOrderSubscriptions,
);

const loadOrderTickets = builder.createServerAction(
  loadTickets('loadOrderTickets'),
);

const loadMoreOrderTickets = builder.createServerAction(
  loadMoreTickets('loadMoreOrderTickets'),
);

const updateOrderTickets = builder.createServerAction(
  updateTickets('updateOrderTickets'),
);

const updateOrderTicketsFilters = builder.createReduxAction(
  updateTicketsFilters('updateOrderTicketsFilters'),
);

const resetOrderTickets = builder.createReduxAction(
  (userType: APIUserType) => ({
    name: 'resetOrderTickets',
    updater: () => ({
      tickets:
        userType === APIUserType.Dispatcher
          ? orderState.tickets
          : {
              ...orderState.tickets,
              filters: {
                ...orderState.tickets.filters,
                ticketStatuses: CONTRACTOR_TICKET_STATUSES,
              },
            },
    }),
  }),
);

const sendOrderNotification = builder.createServerAction(
  (providerId: string, id: number) => ({
    name: 'sendOrderNotification',
    url: orderNotificationEndpoint(providerId, id),
    method: httpMethod.post,
    onSuccess: (state: OrderState, payload: APIOrder) => {
      return {
        order: payload,
      };
    },
  }),
);

export const useOrder = () => {
  const dispatch = useDispatch();
  const providerId = useSelector(appSelectors.providerId);

  return useMemo(
    () =>
      bindActionCreators(
        {
          fetchOrderById: fetchOrderById.bind(null, providerId),
          loadOrder: loadOrder.bind(null, providerId),
          createOrder: createOrder.bind(null, providerId),
          updateOrder: updateOrder.bind(null, providerId),
          updateOrderBatchNumber: updateOrderBatchNumber.bind(null, providerId),
          updateOrderCallback: updateOrderCallback.bind(null, providerId),
          updateOrderRequestedChanges: updateOrderRequestedChanges.bind(
            null,
            providerId,
          ),
          addTicket: addTicket.bind(null, providerId),
          updateTicket: updateTicket.bind(null, providerId),
          loadTicket: loadTicket.bind(null, providerId),
          loadOrderTickets: loadOrderTickets.bind(null, providerId),
          changeOrderStatus: changeOrderStatus.bind(null, providerId),
          toggleOrderSubscription: toggleOrderSubscription.bind(
            null,
            providerId,
          ),
          updateOrderTickets: updateOrderTickets.bind(
            null,
            providerId,
            (state: State) => state.order,
          ),
          getUserSubscriptions: getUserSubscriptions.bind(null, providerId),
          addOrderSubscriptions: addOrderSubscriptions.bind(null, providerId),
          loadMoreOrderTickets: loadMoreOrderTickets.bind(null, providerId),
          sendOrderNotification: sendOrderNotification.bind(null, providerId),
          fetchOrderPlants: fetchOrderPlants.bind(null, providerId),
          removeTicket: removeTicket.bind(null, providerId),
          generateOrderName: generateOrderName.bind(null, providerId),
          // without binding TS doesn't infer type for module functions
          resetOrder: resetOrder.bind(null),
          resetOrderTickets: resetOrderTickets.bind(null),
          updateOrderTicketInState: updateOrderTicketInState.bind(null),
          updateOrderTicketsFilters: updateOrderTicketsFilters.bind(null),
        },
        dispatch,
      ),
    [dispatch, providerId],
  );
};

export const orderReducer = builder.getReducers();
