import React, { useMemo } from 'react';

import styled, { useTheme } from 'styled-components';

import {
  Dialog,
  GlobalLoader,
  Grid,
  LoadingButton,
  Typography,
} from 'components';
import i18next from 'i18next';

const DialogControls = styled(Grid)`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
`;

type ConfirmationDialogProps = {
  onClose: () => void;
  open: boolean;
  handleCancelClick?: () => void;
  actionPending?: boolean;
  handleActionClick: () => void;
  title?: string;
  description?: string;
  cancelButtonTitle?: string;
  actionButtonTitle?: string;
  actionButtonDisabled?: boolean;
  isLoading?: boolean;
  children?: React.ReactElement;
  onCloseAnimationEnd?: (node: HTMLElement) => void;
};

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  title = i18next.t('order.details.confirmed.confirmationDeleteTitle'),
  description,
  cancelButtonTitle = i18next.t('common.noNevermind'),
  actionButtonTitle = i18next.t(
    'order.details.confirmed.confirmationDialogDeleteAction',
  ),
  onClose,
  open,
  handleCancelClick,
  actionPending,
  handleActionClick,
  onCloseAnimationEnd,
  actionButtonDisabled,
  children,
  isLoading,
}) => {
  const theme = useTheme();

  const transitionProps = useMemo(
    () => ({ onExited: onCloseAnimationEnd }),
    [onCloseAnimationEnd],
  );

  return (
    <Dialog open={open} onClose={onClose} TransitionProps={transitionProps}>
      {isLoading ? (
        <GlobalLoader />
      ) : (
        <>
          <Typography variant="h4" marginBottom="1rem">
            {title}
          </Typography>
          {description && (
            <Typography width="29rem" color={theme.custom.palette.gray}>
              {description}
            </Typography>
          )}
          {children}
          <DialogControls>
            <LoadingButton
              variant="outlined"
              color="secondary"
              disabled={actionPending}
              onClick={handleCancelClick || onClose}
            >
              {cancelButtonTitle}
            </LoadingButton>
            <LoadingButton
              variant="contained"
              onClick={handleActionClick}
              loading={actionPending}
              disabled={actionButtonDisabled}
            >
              {actionButtonTitle}
            </LoadingButton>
          </DialogControls>
        </>
      )}
    </Dialog>
  );
};
