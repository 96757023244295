import { ChatState } from '../modules/chat';

export const dialogs = (state: { chat: ChatState }) => state.chat.dialogs;
export const dialogMessages = (state: { chat: ChatState }) =>
  state.chat.dialogMessages;

export const currentDialog = (state: { chat: ChatState }) =>
  state.chat.currentDialog;

export const dialogMessagesToken = (state: { chat: ChatState }) =>
  state.chat.dialogMessagesToken;

export const sendDialogMessagePending = (state: { chat: ChatState }) =>
  state.chat.sendDialogMessagePending;

export const isDialogMessagesLoaded = (state: { chat: ChatState }) =>
  state.chat.isDialogMessagesLoaded;

export const loadProviderDialogsPending = (state: { chat: ChatState }) =>
  state.chat.loadProviderDialogsPending;

export const loadDialogMessagesPending = (state: { chat: ChatState }) =>
  state.chat.loadDialogMessagesPending;

export const unreadOrderIds = (state: { chat: ChatState }) =>
  state.chat.unreadOrderIds;

export const dialogMessagesAttachments = (state: { chat: ChatState }) =>
  state.chat.dialogMessagesAttachments;
