import React from 'react';

import { Message } from '@chatscope/chat-ui-kit-react';
import { ChatMessage } from 'selectors/chat';

import { useMessageAttachment } from './useMessageAttachment';

import {
  MessageInnerContent,
  MessageSendTime,
} from 'features/Chat/MessageBubbleStyles';

type Props = {
  as?: any;
  userId?: string;
  senderDisplayName: string;
  showUserName: boolean;
  message: ChatMessage;
  isBotMessage?: boolean;
};

export const UserMessageBubble: React.FC<Props> = ({
  userId,
  senderDisplayName,
  showUserName,
  message,
  isBotMessage,
}) => {
  const { renderMessageAttachment } = useMessageAttachment({
    attachmentIds: message.attachmentIds,
  });
  return (
    <Message
      model={{
        direction: message.senderId == userId ? 'outgoing' : 'incoming',
        position: 'single',
      }}
      className={isBotMessage ? 'bot-message' : ''}
    >
      {showUserName && (
        <Message.Header title={senderDisplayName} sender={senderDisplayName} />
      )}
      <Message.CustomContent>
        <MessageInnerContent>
          {renderMessageAttachment()}
          <div>{message.message}</div>
          <MessageSendTime>{message.sendTime}</MessageSendTime>
        </MessageInnerContent>
      </Message.CustomContent>
    </Message>
  );
};
