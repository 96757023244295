import React, { useEffect } from 'react';

import { InsightBlock, SvgIcon } from 'components';

import { useSelector, useTranslation } from 'third-party';

import { useMixTypesList } from 'modules/mixTypesList';

import { mixTypesSelectors } from 'selectors';

type Props = {
  isActive?: boolean;
  onClick: () => void;
};

export const MostOrderedMixesWidget: React.FC<Props> = ({
  onClick,
  isActive,
}) => {
  const { t } = useTranslation();

  const { loadMostUsedMixTypes } = useMixTypesList();

  const mostUsedMixTypesLoaded = useSelector(
    mixTypesSelectors.mostUsedMixTypesLoaded,
  );
  const mostUsedMixTypesPercentage = useSelector(
    mixTypesSelectors.mostUsedMixTypesPercentage,
  );
  const mostUsedMixTypesItems =
    useSelector(mixTypesSelectors.mostUsedMixTypesItems) || [];

  useEffect(() => {
    if (!mostUsedMixTypesLoaded) {
      loadMostUsedMixTypes();
    }
  }, [loadMostUsedMixTypes, mostUsedMixTypesLoaded]);

  const notSufficientData =
    mostUsedMixTypesLoaded && mostUsedMixTypesItems.length === 0;

  return (
    <InsightBlock
      isActive={isActive}
      isLoading={!mostUsedMixTypesLoaded}
      title={t('plantSettings.insightsMixTypesMostTitle')}
      description={
        notSufficientData
          ? t('plantSettings.insightsMixTypesMostDefaultText')
          : t('plantSettings.insightsMixTypesMostDescription', {
              count: mostUsedMixTypesItems.length,
              percent: mostUsedMixTypesPercentage,
            })
      }
      linkLabel={t('plantSettings.insightsViewList')}
      onClick={notSufficientData ? undefined : onClick}
      icon={
        <SvgIcon icon="insights-most-icon" height="3.6rem" width="3.6rem" />
      }
    />
  );
};
