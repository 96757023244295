import React from 'react';

import styled from 'styled-components';

import { SvgIcon } from 'components';

import { getOrderDeliveryRateDiff } from 'utils/order';

const StyledDeliveryRateIcon = styled(SvgIcon)`
  color: ${props => props.theme.custom.palette.danger400};
  margin: 0 1rem;
`;

interface Props {
  deliveryRate?: number;
  actualDeliveryRate?: number | null;
}

export const DeliveryRateIcon: React.FC<Props> = ({
  deliveryRate = 0,
  actualDeliveryRate = 0,
}) => {
  const deliveryRateDiff = getOrderDeliveryRateDiff(
    deliveryRate,
    actualDeliveryRate as number,
  );

  return (
    <StyledDeliveryRateIcon
      icon={deliveryRateDiff > 0 ? 'arrow-up' : 'arrow-down'}
      height="1.8rem"
      width="1.8rem"
    />
  );
};
