import React, { useEffect, useState } from 'react';

import styled, { useTheme } from 'styled-components';

import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';

import { NotificationSeverity } from '@cd3p/core/modules/notifications';
import { APIPlant } from '@cd3p/core/types/api';
import { getServerErrorTitle } from '@cd3p/core/utils/common';
import { DeleteOutlined, ModeEditOutlined } from '@mui/icons-material';
import {
  Button,
  CircularProgress,
  ConfirmationDialog,
  Container,
  IconButton,
  Stack,
  StaticMapPreview,
  Tooltip,
  Trans,
  Typography,
} from 'components';

import { useNavigate, useSelector, useTranslation } from 'third-party';

import { PLANT_ICON, TOOLTIP_APPEAR_DELAY } from 'constants/common';
import { plantAddUrl, plantEditUrl } from 'constants/url';

import { useCache } from 'modules/cache';
import { useNotifications } from 'modules/notifications';

import { appSelectors, cacheSelectors } from 'selectors';

const PlantInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 0;
  overflow: hidden;
  padding: 3rem 4rem 0 4rem;
`;

const HeaderWrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
`;

const Header = styled(Typography)`
  color: ${props => props.theme.custom.palette.secondary900};
`;

const CenterWrapper = styled(Container)`
  display: flex;
  align-items: center;
  height: 100%;
`;

const PlantInfoBoxWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem 3rem;
  overflow: auto;
  padding-bottom: 3rem;
`;

const PlantInfoBox = styled.div<{ background: string }>`
  display: flex;
  flex: 0 0 31%;
  flex-direction: column;
  align-items: stretch;
  border: 1px solid ${props => props.theme.custom.palette.muted100};
  border-radius: 0.4rem;
  padding: 1.5rem 0.5rem 1.5rem 1.5rem;
  min-width: 35rem;
  box-sizing: border-box;
  background-color: ${props => props.background};
`;

const PlantMapPreviewWrapper = styled.div`
  display: flex;
  color: ${props => props.theme.custom.palette.statusText};
  font-size: 3rem;
  justify-content: center;
  align-items: center;
  font-weight: 900;
  height: 11.9rem;
  border-radius: 0.4rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
  overflow: hidden;
`;

const PlantDetailsWrapper = styled.div`
  padding-right: 1rem;
  flex-grow: 1;
  min-width: 0;
`;

const PlantName = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  margin-bottom: 1rem;
`;

const PlantDetailsRow = styled.div`
  font-size: 1.4rem;
  line-height: 1.6;
`;

const PlantControls = styled.div`
  flex-shrink: 0;
`;

const PlantDetailsLabel = styled(Typography)`
  font-size: 1.4rem !important;
  line-height: 1.6 !important;
  display: inline;
`;

export const PlantsView = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { loadPlants, removePlant } = useCache();
  const { addNotification } = useNotifications();
  const navigate = useNavigate();

  const plants = useSelector(cacheSelectors.plants);
  const plantsLoaded = useSelector(cacheSelectors.plantsLoaded);
  const removePlantPending = useSelector(cacheSelectors.removePlantPending);
  const isBatchSoftwareIntegrationEnabled = useSelector(
    appSelectors.isBatchSoftwareIntegrationEnabled,
  );

  const [removeConfirmationDialogId, setRemoveConfirmationDialogId] = useState<
    null | string
  >(null);

  useEffect(() => {
    loadPlants();
  }, [loadPlants]);

  const handleAddPlantClick = (plant: APIPlant | null) => {
    if (plant) {
      navigate(plantEditUrl(plant.id));
    } else {
      navigate(plantAddUrl());
    }
  };

  const handleDeleteConfirmation = async () => {
    setRemoveConfirmationDialogId(null);
    const result = await removePlant(removeConfirmationDialogId as string);
    if (result.error) {
      const errorStatus = result.payload.status;
      addNotification({
        severity: NotificationSeverity.Error,
        message:
          errorStatus === 400
            ? getServerErrorTitle(result)
            : t('common.generalError'),
      });
    } else {
      addNotification({
        message: t('plantSettings.editForm.removeSuccessfulMessage'),
      });
    }
  };

  const plantsLength = plants.length > 0 ? `(${plants.length})` : '';

  const getPlantZoomLevel = (plant: APIPlant) => {
    return plant.plantRadius && plant.plantRadius <= 100 ? 16 : 14;
  };

  return (
    <PlantInfoWrapper>
      <ConfirmationDialog
        onClose={() => setRemoveConfirmationDialogId(null)}
        open={!!removeConfirmationDialogId}
        actionPending={removePlantPending}
        handleActionClick={handleDeleteConfirmation}
        description={t('plantSettings.removePlant.confirmationDescription')}
        cancelButtonTitle={t('common.noNevermind')}
        actionButtonTitle={t('common.confirmDelete')}
      />
      <HeaderWrapper>
        <Header variant="h5">
          <Trans i18nKey="plantSettings.tabPlantInfo.header">
            Plant locations {{ plantsLength: plantsLength }}
          </Trans>
        </Header>
        <Button onClick={() => handleAddPlantClick(null)}>
          <AddBoxOutlinedIcon sx={{ marginRight: '1rem' }} />
          {t('plantSettings.tabPlantInfo.addPlantAction')}
        </Button>
      </HeaderWrapper>

      {!plantsLoaded ? (
        <CenterWrapper>
          <CircularProgress sx={{ margin: '5rem auto' }} />
        </CenterWrapper>
      ) : (
        <PlantInfoBoxWrapper>
          {plants.map(plant => (
            <PlantInfoBox
              key={plant.id}
              background={
                removeConfirmationDialogId === plant.id
                  ? theme.custom.palette.info50
                  : 'transparent'
              }
            >
              <PlantMapPreviewWrapper>
                <StaticMapPreview
                  width={348}
                  height={120}
                  center={{ lat: plant.latitude, lng: plant.longitude }}
                  zoom={getPlantZoomLevel(plant)}
                  polygonWeight={5}
                  mapType={'hybrid'}
                  markerIcon={PLANT_ICON}
                  markerLocation={{ lat: plant.latitude, lng: plant.longitude }}
                  {...(plant.geoFenceCoordinates?.length
                    ? {
                        polygon: [
                          ...plant.geoFenceCoordinates.map(it => ({
                            lat: it.latitude,
                            lng: it.longitude,
                          })),
                          // the first and the last points of polygon
                          // should be equal
                          {
                            lat: plant.geoFenceCoordinates[0].latitude,
                            lng: plant.geoFenceCoordinates[0].longitude,
                          },
                        ],
                      }
                    : { radius: plant.plantRadius })}
                />
              </PlantMapPreviewWrapper>
              <Stack direction="row">
                <PlantDetailsWrapper>
                  <Tooltip
                    placement="top-start"
                    enterDelay={TOOLTIP_APPEAR_DELAY}
                    enterNextDelay={TOOLTIP_APPEAR_DELAY}
                    title={plant.name}
                  >
                    <PlantName variant="subtitle2">{plant.name}</PlantName>
                  </Tooltip>
                  <PlantDetailsRow>
                    {' '}
                    <PlantDetailsLabel variant="subtitle2">
                      {t('plantSettings.tabPlantInfo.address')}:&nbsp;
                    </PlantDetailsLabel>
                    {plant.address}
                  </PlantDetailsRow>
                  {isBatchSoftwareIntegrationEnabled && (
                    <PlantDetailsRow>
                      <PlantDetailsLabel variant="subtitle2">
                        {t('plantSettings.tabPlantInfo.batchingSoftwareId')}
                        :&nbsp;
                      </PlantDetailsLabel>
                      {plant.thirdPartyId || t('common.entityNameUndefined')}
                    </PlantDetailsRow>
                  )}
                </PlantDetailsWrapper>
                <PlantControls>
                  <IconButton onClick={() => handleAddPlantClick(plant)}>
                    <ModeEditOutlined
                      sx={{ color: theme.custom.palette.secondary500 }}
                    />
                  </IconButton>
                  <IconButton
                    onClick={() => setRemoveConfirmationDialogId(plant.id)}
                  >
                    <DeleteOutlined
                      sx={{ color: theme.custom.palette.secondary500 }}
                    />
                  </IconButton>
                </PlantControls>
              </Stack>
            </PlantInfoBox>
          ))}
          {!plants.length && (
            <Typography>{t('plantSettings.tabPlantInfo.noData')}</Typography>
          )}
        </PlantInfoBoxWrapper>
      )}
    </PlantInfoWrapper>
  );
};
