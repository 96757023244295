import React from 'react';

import styled from 'styled-components';

import { Breadcrumbs, Grid, SvgIcon } from 'components';

import { RouterLink } from 'third-party';

const BreadcrumbsWrapper = styled(Grid)`
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;

const BreadcrumbsLink = styled(RouterLink)`
  font-weight: 900;
  color: ${props => props.theme.custom.palette.secondary600};
  text-decoration: none;
`;

interface Props {
  url: string;
  text: string;
}

export const BreadcrumbsViewAll: React.FC<Props> = ({ url, text }) => {
  return (
    <BreadcrumbsWrapper item>
      <Breadcrumbs
        aria-label="breadcrumb"
        style={{ display: 'inline-flex' }}
        separator={
          <SvgIcon height="1.2rem" width="0.74rem" icon="shevron-left" />
        }
      >
        {/*
          An empty div is needed to display a separator.
          As a default is it not displayed with just 1 child of Breadcrumbs.
        */}
        <div />
        <BreadcrumbsLink to={url}>{text}</BreadcrumbsLink>
      </Breadcrumbs>
    </BreadcrumbsWrapper>
  );
};
