import React, { useCallback } from 'react';

import styled from 'styled-components';

import {
  AnimatedMenuIcon,
  Alert as MaterialAlert,
  Typography,
} from 'components';
import { AnimatePresence, motion } from 'framer-motion';

import { useSelector, useTranslation } from 'third-party';

import { AppLeftPanel } from 'modules/app';
import { useStorage } from 'modules/storage';

import { appSelectors } from 'selectors';

const CONTAINER_HEIGHT = '5rem';

const Wrapper = styled(motion.div)`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  max-height: ${CONTAINER_HEIGHT};
  overflow: hidden;
  cursor: pointer;
  .control-arrow:not(:hover) {
    color: ${props => props.theme.custom.palette.muted200};
    &:before {
      border-right-color: ${props => props.theme.custom.palette.muted200};
      border-left-color: ${props => props.theme.custom.palette.muted200};
    }
  }
`;

const StyledAlert = styled(MaterialAlert)<{ showpointer?: string }>`
  box-shadow: 0 1px 1.41px rgba(0, 0, 0, 0.2);
  padding-left: 3rem;
  padding-right: 3rem;
  display: flex;
  flex-direction: row;
  max-height: ${CONTAINER_HEIGHT};
  flex-grow: 1;
  strong {
    font-weight: bold;
  }
  &:last-child {
    margin-bottom: 0;
  }
  .MuiAlert-icon {
    margin-right: 2rem;
  }
  .MuiAlert-message {
    flex-grow: 1;
    align-items: flex-start;
  }
`;

const Title = styled(Typography)`
  text-align: left;
  font-size: 1.6rem;
  font-weight: 400;
  margin-left: 1.2rem;
`;

const Link = styled(Typography)`
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 700;
  text-decoration: underline;
  text-transform: uppercase;
  color: ${props => props.theme.custom.palette.secondary500};
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
`;

const LeftColumn = styled.div`
  display: flex;
  gap: 10;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

type Props = {
  count?: number;
};

const MAX_ALERT_COUNT = 20;

export const RequiredActionsBannerComponent: React.FC<Props> = ({ count }) => {
  const { t } = useTranslation();

  const { updateUserSettingInStorage } = useStorage();

  const isRequiredActionsPanelOpen = useSelector(
    appSelectors.isRequiredActionsPanelOpen,
  );

  const handleRequiredActionViewClick = useCallback(() => {
    if (!isRequiredActionsPanelOpen) {
      updateUserSettingInStorage({
        openedLeftPanel: AppLeftPanel.RequiredActions,
      });
    }
  }, [isRequiredActionsPanelOpen, updateUserSettingInStorage]);

  const actionsCount = Math.min(MAX_ALERT_COUNT, Number(count));
  const actionsCountSuffix = Number(count) > MAX_ALERT_COUNT ? '+' : '';

  return (
    <Wrapper
      initial={false}
      animate={{ opacity: 1, height: CONTAINER_HEIGHT }}
      exit={{ opacity: 0, height: 0 }}
      transition={{
        duration: 1,
      }}
      onClick={handleRequiredActionViewClick}
    >
      <StyledAlert icon={false} severity={'info'}>
        <Content>
          <LeftColumn>
            <AnimatedMenuIcon unread />
            <Title>
              {t('requiredActionsBanner.requiredActionsTitle', {
                count: actionsCount,
                suffix: actionsCountSuffix,
              })}
            </Title>
          </LeftColumn>
          <Link onClick={handleRequiredActionViewClick}>
            {t('requiredActionsBanner.viewAlerts')}
          </Link>
        </Content>
      </StyledAlert>
    </Wrapper>
  );
};

export const RequiredActionsBanner = (props: Props) => {
  return (
    <AnimatePresence>
      {!!props.count && <RequiredActionsBannerComponent {...props} />}
    </AnimatePresence>
  );
};
