import { useMemo } from 'react';

import { simplifyBuilder } from '@cd3p/core/utils/sra';
import { TableSettingsFiltersSelector } from 'selectors/storage';

import { bindActionCreators, useDispatch, useSelector } from 'third-party';

import { AppTableSettings } from 'constants/common';

import {
  OrdersFilters,
  OrdersListState,
  loadMoreOrders,
  loadOrders,
  ordersDefaultListState,
  resetOrderList,
  updateOrderPosition,
  updateOrdersListSearch,
  updateOrdersListSorting,
} from 'modules/common/ordersList';

import {
  allOrdersListSelectors,
  appSelectors,
  storageSelectors,
} from 'selectors';

export type AllOrdersListState = OrdersListState & {
  loadAllOrdersPending: boolean;
  loadMoreAllOrdersPending: boolean;
  allOrdersListErrorText: string | null;
  scrollPosition: number | undefined;
};

export const allOrdersListState: AllOrdersListState = {
  ...ordersDefaultListState,
  loadAllOrdersPending: false,
  loadMoreAllOrdersPending: false,
  allOrdersListErrorText: null,
  scrollPosition: undefined,
  search: {
    pattern: '',
    columns: [
      'deliveryDate',
      'deliveryTime',
      'orderName',
      'orderNumber',
      'company.name',
      'project.name',
      'project.address',
      'deliveryLocation',
      'volume',
      'orderStatus',
    ],
  },
};

const builder = simplifyBuilder(allOrdersListState, {});

export const LOAD_ALL_ORDERS_ACTION = 'loadAllOrders';
const loadAllOrders = builder.createServerAction(
  loadOrders(LOAD_ALL_ORDERS_ACTION),
);

export const LOAD_MORE_ORDERS_ACTION = 'loadMoreAllOrders';
const loadMoreAllOrders = builder.createServerAction(
  loadMoreOrders(LOAD_MORE_ORDERS_ACTION),
);

export const UPDATE_ORDER_POSITION_IN_ALL_ORDERS_ACTION =
  'updateOrderPositionInAllOrders';
const updateOrderPositionInAllOrders = builder.createServerAction(
  updateOrderPosition(UPDATE_ORDER_POSITION_IN_ALL_ORDERS_ACTION),
);

const updateAllOrdersListSorting = builder.createReduxAction(
  updateOrdersListSorting('updateAllOrdersListSorting'),
);

const updateAllOrdersSearch = builder.createReduxAction(
  updateOrdersListSearch('updateAllOrdersSearch'),
);

export const resetAllOrdersSearch = builder.createReduxAction(() => ({
  name: 'resetAllOrdersSearch',
  updater: () => ({
    search: allOrdersListState.search,
  }),
}));

const resetAllOrdersList = builder.createReduxAction(
  resetOrderList('clearAllOrdersInState'),
);

export const saveAllOrdersScrollPosition = builder.createReduxAction(
  (scrollPosition: number) => ({
    name: 'saveAllOrdersScrollPosition',
    updater: () => ({
      scrollPosition,
    }),
  }),
);

export const resetAllOrdersScrollPosition = builder.createReduxAction(() => ({
  name: 'resetAllOrdersScrollPosition',
  updater: () => ({
    scrollPosition: 0,
  }),
}));

export const useAllOrdersList = () => {
  const dispatch = useDispatch();
  const providerId = useSelector(appSelectors.providerId);

  return useMemo(
    () =>
      bindActionCreators(
        {
          loadAllOrders: loadAllOrders.bind(null, providerId),
          loadMoreAllOrders: loadMoreAllOrders.bind(
            null,
            providerId,
            allOrdersListSelectors.allOrdersListState,
            storageSelectors.tableSettingsFiltersSelector[
              AppTableSettings.AllOrders
            ] as TableSettingsFiltersSelector<OrdersFilters>,
          ),
          updateOrderPositionInAllOrders: updateOrderPositionInAllOrders.bind(
            null,
            providerId,
            allOrdersListSelectors.allOrdersListState,
            storageSelectors.tableSettingsFiltersSelector[
              AppTableSettings.AllOrders
            ] as TableSettingsFiltersSelector<OrdersFilters>,
          ),
          // without binding TS doesn't infer type for module functions
          updateAllOrdersListSorting: updateAllOrdersListSorting.bind(null),
          updateAllOrdersSearch: updateAllOrdersSearch.bind(null),
          resetAllOrdersList: resetAllOrdersList.bind(null),
          resetAllOrdersSearch: resetAllOrdersSearch.bind(null),
          saveAllOrdersScrollPosition: saveAllOrdersScrollPosition.bind(null),
          resetAllOrdersScrollPosition: resetAllOrdersScrollPosition.bind(null),
        },
        dispatch,
      ),
    [dispatch, providerId],
  );
};

export const allOrdersListReducer = builder.getReducers();
