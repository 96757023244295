import React, { useCallback, useMemo } from 'react';

import styled from 'styled-components';

import NotificationAddOutlinedIcon from '@mui/icons-material/NotificationAddOutlined';

import { APISubscribedUser, APIUser } from '@cd3p/core/types/api';
import { Stack } from '@mui/material';
import {
  HorizontalDivider,
  LoadingButton,
  Popover,
  PopoverCloseButton,
  Typography,
} from 'components';
import { useHandleApiResult } from 'hooks/useHandleApiResult';

import { UserListItem } from './UserListItem';

import { FormProvider, useForm, useTranslation } from 'third-party';

import { UserSearchField } from 'features/Fields/UserSearchField';

import { TypeaheadDataOption } from 'types/app';

const FormWrapper = styled.div`
  position: relative;
  width: 55rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-height: 23rem;
  flex-grow: 1;
`;

const SearchWrapper = styled.form`
  display: flex;
  gap: 1rem;
  align-items: center;
  z-index: 5;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  padding: 2.2rem 2.3rem 0;
  z-index: 1;
`;

const Title = styled(Typography)`
  line-height: 100%;
  color: ${props => props.theme.custom.palette.primary900};
  margin-bottom: 1.5rem;
`;

const Description = styled(Typography)`
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: 600;
  color: ${props => props.theme.custom.palette.muted900};
`;

const Content = styled(Stack)`
  flex-grow: 1;
  overflow: visible;
  padding: 2.2rem 2.3rem 0rem;
`;

const SubmitButton = styled(LoadingButton)`
  padding: 0 2.4rem;
  font-size: 1.2rem;
  margin-top: 4.5rem;
`;

const NoItemsText = styled(Typography)`
  color: ${props => props.theme.custom.palette.muted200};
  text-align: center;
  padding-top: 3rem;
  padding-bottom: 1.4rem;
`;

const StyledHorizontalDivider = styled(HorizontalDivider)`
  margin: 1.2rem 0 0;
  border-bottom-color: ${props => props.theme.custom.palette.muted100};
`;

const ListContent = styled(Stack)`
  overflow: scroll;
  padding-bottom: 2.3rem;
  padding-top: 0.6rem;
  max-height: 20rem;
`;

enum SubscribeTeamFields {
  'Search' = 'search',
}

type FormData = {
  [key: string]: TypeaheadDataOption<APIUser>[] | null;
};

type Props = {
  title: string;
  description: string;
  onClosePopup?: () => void;
  onSubscribeSuccess: () => void;
  subscribeTeamMembers: (memberIds: string[]) => void;
  subscribedUsers: APISubscribedUser[] | null;
  isLoading?: boolean;
};

export const SubscribeTeamMembersModal: React.FC<Props> = ({
  title = '',
  description,
  onClosePopup,
  subscribeTeamMembers,
  subscribedUsers,
  onSubscribeSuccess,
  isLoading,
}) => {
  const { t } = useTranslation();
  const subscribedUsersIds = useMemo(
    () => subscribedUsers?.map(user => user.id) || [],
    [subscribedUsers],
  );

  const handleApiResult = useHandleApiResult();

  const defaultValues: FormData = useMemo(
    () => ({
      [SubscribeTeamFields.Search]: null,
    }),
    [],
  );

  const formMethods = useForm<FormData>({
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isValid, isDirty },
  } = formMethods;

  const onSubmit = useCallback(
    async (data: FormData) => {
      if (!isValid) {
        return;
      }
      const optionIds =
        data[SubscribeTeamFields.Search]?.map(option =>
          String(option.data?.id),
        ) || [];
      handleApiResult(
        async () => subscribeTeamMembers([...optionIds, ...subscribedUsersIds]),
        ({ showBaseToast }) => {
          showBaseToast(t('subscribeTeamMembers.successMessage'));
          onSubscribeSuccess?.();
          reset();
        },
        ({ showErrorToast }) => {
          showErrorToast();
        },
      );
    },
    [
      subscribedUsersIds,
      handleApiResult,
      isValid,
      onSubscribeSuccess,
      reset,
      subscribeTeamMembers,
      t,
    ],
  );

  const handleUnsubscribeUser = useCallback(
    (userId: string) => {
      const optionIds = subscribedUsersIds?.filter(
        memberId => memberId !== userId,
      );
      handleApiResult(
        async () => subscribeTeamMembers(optionIds),
        ({ showBaseToast }) => {
          showBaseToast(t('subscribeTeamMembers.successMessage'));
          onSubscribeSuccess?.();
          reset();
        },
        ({ showErrorToast }) => {
          showErrorToast();
        },
      );
    },
    [
      subscribedUsersIds,
      handleApiResult,
      onSubscribeSuccess,
      reset,
      subscribeTeamMembers,
      t,
    ],
  );

  return (
    <Popover
      anchorEl={document.body}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      open
      PaperProps={{ sx: { overflow: 'visible' } }}
    >
      <PopoverCloseButton
        onClose={() => {
          onClosePopup?.();
        }}
      />
      <FormProvider {...formMethods}>
        <FormWrapper>
          <Header>
            <Title variant="h5">{title}</Title>
          </Header>
          <Content>
            <Description>{description}</Description>
            <SearchWrapper>
              <UserSearchField<FormData>
                fieldName={SubscribeTeamFields.Search}
                label={t('subscribeTeamMembers.inputSearchLabel')}
                isDisabled={isLoading}
                placeholder={t('subscribeTeamMembers.inputSearchPlaceholder')}
                excludedIds={subscribedUsersIds}
              />
              <SubmitButton
                startIcon={<NotificationAddOutlinedIcon />}
                variant="contained"
                type="submit"
                loading={isLoading}
                disabled={!isDirty}
                onClick={handleSubmit(onSubmit)}
              >
                {t('subscribeTeamMembers.submitButton')}
              </SubmitButton>
            </SearchWrapper>
            <StyledHorizontalDivider />
            <ListContent>
              {subscribedUsers?.length ? (
                subscribedUsers.map(user => (
                  <UserListItem
                    key={user.id}
                    user={user}
                    onBellClick={() => handleUnsubscribeUser(user.id)}
                    isDisabled={isLoading}
                  />
                ))
              ) : (
                <NoItemsText variant="body2">
                  {t('subscribeTeamMembers.emptyList')}
                </NoItemsText>
              )}
            </ListContent>
          </Content>
        </FormWrapper>
      </FormProvider>
    </Popover>
  );
};
