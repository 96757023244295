import React from 'react';

import { Navigate } from 'third-party';

import { allOrdersUrl } from 'constants/url';

import { CanAccess, CanAccessProps } from 'components/CanAccess/CanAccess';

export const ProtectedRoute = (props: CanAccessProps) => {
  return (
    <CanAccess fallback={<Navigate to={allOrdersUrl()} replace />} {...props} />
  );
};
