import { useCallback, useEffect } from 'react';

import {
  ON_TICKET_CREATE_EVENT,
  ON_TICKET_DELETE_EVENT,
  ON_TICKET_UPDATE_EVENT,
} from '@cd3p/core/constants/hubs';
import { APIOrderStatus, APITicket } from '@cd3p/core/types/api';
import { subscribe } from 'hooks/usePubSub';

import { useSelector } from 'third-party';

import { APP_EVENTS } from 'constants/appEvent';

import { useOrder } from 'modules/order';
import { useTracking } from 'modules/tracking';

import { orderSelectors } from 'selectors';

import { truckStatusesToShowOnMap } from 'utils/order';
import { useOrdersSockets } from 'utils/sockets';
import { composeLoadTicketsRequestBody } from 'utils/ticketsList';

export const useOrderTickets = (
  orderId: number,
  orderStatus: APIOrderStatus,
) => {
  const { loadOrderTickets } = useOrder();

  const { fetchOrderPlants } = useOrder();

  const { loadTracking } = useTracking();

  const sorting = useSelector(orderSelectors.orderTicketsSorting);
  const { pageNumber, pageSize } = useSelector(
    orderSelectors.orderTicketsPagination,
  );
  const filters = useSelector(orderSelectors.orderTicketsFilters);
  const loadOrderTicketsPending = useSelector(
    orderSelectors.loadOrderTicketsPending,
  );

  const loadTickets = useCallback(() => {
    loadOrderTickets(orderId, {
      ...composeLoadTicketsRequestBody({
        pageNumber,
        pageSize,
        sorting,
        filters,
      }),
      calculateTotalOnDelivery: true,
    });
  }, [loadOrderTickets, orderId, pageNumber, pageSize, sorting, filters]);

  useEffect(() => {
    return subscribe(APP_EVENTS.CLOSE_ORDER, loadTickets);
  }, [loadTickets]);

  useOrdersSockets(
    () => [
      {
        method: ON_TICKET_UPDATE_EVENT,
        action: (providerId, ticketId, ticket: APITicket) => {
          if (ticket.order.id === orderId) {
            loadTickets();
            loadTracking({
              truckStatuses: truckStatusesToShowOnMap,
              orderId,
            });
          }
        },
      },
      {
        method: ON_TICKET_DELETE_EVENT,
        action: () => {
          if (!loadOrderTicketsPending) {
            loadTickets();
            fetchOrderPlants(orderId);
            loadTracking({
              truckStatuses: truckStatusesToShowOnMap,
              orderId,
            });
          }
        },
      },
      {
        method: ON_TICKET_CREATE_EVENT,
        action: async (providerId, ticketId, ticket: APITicket) => {
          if (!loadOrderTicketsPending && ticket.orderId === orderId) {
            await loadTickets();
            fetchOrderPlants(orderId);
            loadTracking({
              truckStatuses: truckStatusesToShowOnMap,
              orderId,
            });
          }
        },
      },
    ],
    [
      fetchOrderPlants,
      loadOrderTicketsPending,
      loadTickets,
      loadTracking,
      orderId,
    ],
  );

  useEffect(() => {
    if (orderStatus) {
      loadTickets();
    }
  }, [loadTickets, orderStatus]);
};
