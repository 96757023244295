import React, { useMemo } from 'react';

import { APIOrder } from '@cd3p/core/types/api';
import { formatDisplayedDate } from '@cd3p/core/utils/common';
import { Grid, Trans, Typography } from 'components';

import { dayjs, useSelector, useTheme, useTranslation } from 'third-party';

import { appSelectors } from 'selectors';

import { formatTime, isDateEqualOrGreaterToday } from 'utils/order';

const getUnconfirmedOrderTitle = ({
  sentDate,
  isEqualOrGreaterToday,
}: {
  sentDate?: string;
  isEqualOrGreaterToday: boolean;
}) => {
  if (sentDate) {
    return 'order.details.unconfirmed.title.sent';
  } else if (!sentDate && isEqualOrGreaterToday) {
    return 'order.details.unconfirmed.title.tomorrow';
  } else {
    return 'order.details.unconfirmed.title.future';
  }
};

type GetUnconfirmedDescriptionProps = {
  order: APIOrder | null;
  customerName: string;
};

const useGetUnconfirmedDescription = (
  props: GetUnconfirmedDescriptionProps,
) => {
  const { t } = useTranslation();

  const { order, customerName } = props;

  const timezone = useSelector(appSelectors.providerTimeZone);
  const orderFirstReminderHours = useSelector(
    appSelectors.orderConfirmationFirstReminderHours,
  );
  const orderSecondReminderHours = useSelector(
    appSelectors.orderConfirmationSecondReminderHours,
  );
  const orderSecondReminderEnabled = useSelector(
    appSelectors.orderConfirmationSecondReminderEnabled,
  );

  const firstReminderText = t('common.hours', {
    count: orderFirstReminderHours,
  });
  const secondReminderText = t('common.hours', {
    count: orderSecondReminderHours,
  });

  return useMemo(() => {
    if (!order) return '';

    const reminderSentDate = order?.notificationSentDate;
    const { deliveryDate, deliveryTime } = order;

    // Delivery date and time are saved without specifying timezone,
    // but provider's timezone is implied. The second argument in .tz() call is set to true
    // to indicate that the time is local to specified timezone and should not be converted.
    const orderDeliveryDate = `${deliveryDate} ${deliveryTime}`;
    const deliveryDateObject = dayjs(orderDeliveryDate).tz(timezone, true);
    const sentDateObject = dayjs(reminderSentDate).tz(timezone, true);

    if (reminderSentDate) {
      const hoursDiff = deliveryDateObject.diff(sentDateObject, 'hour');
      const reminderDate = sentDateObject.format('MM/DD hh:mm');

      if (orderSecondReminderEnabled && hoursDiff > orderSecondReminderHours) {
        return (
          <Trans i18nKey="order.details.unconfirmed.reminder.firstSentSecondToGo">
            <strong style={{ fontWeight: 600 }}>{{ customerName }} </strong>
            has not confirmed the order. The reminder was sent at{' '}
            {{ reminderDate }}. Additional reminder will be sent to them{' '}
            {{ secondReminder: secondReminderText }} prior to the order time.
          </Trans>
        );
      } else {
        return (
          <Trans i18nKey="order.details.unconfirmed.reminder.firstSent">
            <strong style={{ fontWeight: 600 }}>{{ customerName }} </strong>
            has not confirmed the order. The reminder was sent at{' '}
            {{ reminderDate }}.
          </Trans>
        );
      }
    } else {
      // When calculating hours difference between delivery date and current time,
      // we should take into account provider's current time.
      const hoursDiff = deliveryDateObject.diff(
        dayjs().tz(timezone),
        'hour',
        true,
      );

      if (hoursDiff > orderFirstReminderHours) {
        return orderSecondReminderEnabled ? (
          <Trans i18nKey="order.details.unconfirmed.reminder.firstAndSecondToGo">
            <strong style={{ fontWeight: 600 }}>{{ customerName }}</strong> has
            not confirmed the order. Reminders will be sent to them{' '}
            {{ firstReminder: firstReminderText }} and{' '}
            {{ secondReminder: secondReminderText }} prior to the order time.
          </Trans>
        ) : (
          <Trans i18nKey="order.details.unconfirmed.reminder.firstToGo">
            <strong style={{ fontWeight: 600 }}>{{ customerName }}</strong> has
            not confirmed the order. Reminder will be sent to them{' '}
            {{ firstReminder: firstReminderText }} prior to the order time.
          </Trans>
        );
      } else {
        return orderSecondReminderEnabled &&
          hoursDiff > orderSecondReminderHours ? (
          <Trans i18nKey="order.details.unconfirmed.reminder.secondToGo">
            <strong style={{ fontWeight: 600 }}>{{ customerName }} </strong>
            has not confirmed the order. The reminder will be sent to them{' '}
            {{ secondReminder: secondReminderText }} prior to the order time.
          </Trans>
        ) : (
          <Trans i18nKey="order.details.unconfirmed.reminder.noneLeft">
            <strong style={{ fontWeight: 600 }}>{{ customerName }} </strong>
            has not confirmed the order.
          </Trans>
        );
      }
    }
  }, [
    customerName,
    firstReminderText,
    order,
    orderFirstReminderHours,
    orderSecondReminderEnabled,
    orderSecondReminderHours,
    secondReminderText,
    timezone,
  ]);
};

type Props = {
  order: APIOrder;
};

export const UnconfirmedOrder: React.FC<Props> = ({ order }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const deliveryDate = formatDisplayedDate(order?.deliveryDate);
  const deliveryTime = formatTime(order?.deliveryTime);

  const isEqualOrGreaterToday = isDateEqualOrGreaterToday(
    deliveryDate,
    deliveryTime,
  );

  const customerName = order?.company?.name as string;

  const title = getUnconfirmedOrderTitle({
    sentDate: order?.notificationSentDate || '',
    isEqualOrGreaterToday,
  });

  const description = useGetUnconfirmedDescription({
    order,
    customerName,
  });

  return (
    <Grid item>
      <Grid item md={12}>
        <Typography variant="h5">{t(title)}</Typography>
        <Typography
          color={theme.custom.palette.gray}
          variant="body1"
          marginTop="0.8rem"
        >
          {description}
        </Typography>
      </Grid>
    </Grid>
  );
};
