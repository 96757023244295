import React, { ForwardedRef, useCallback, useEffect, useMemo } from 'react';

import styled from 'styled-components';

import { Stack } from 'components';
import { InfiniteLoaderProps } from 'react-virtualized/dist/es/InfiniteLoader';

import { DialogsList } from './DialogsList';

import { VirtualizedList, useSelector } from 'third-party';

import { useDialogsList } from 'modules/dialogsList';

import { appSelectors, dialogsListSelectors } from 'selectors';

const ListWrapper = styled(Stack)`
  flex-grow: 1;
`;

export const SubscribedDialogsTab = React.forwardRef(
  (props, listRef: ForwardedRef<VirtualizedList | null>) => {
    const { loadMoreDialogs, loadDialogs, setDialogsFilter } = useDialogsList();

    const dialogsListItems = useSelector(dialogsListSelectors.dialogsListItems);
    const companyId = useSelector(appSelectors.companyId);
    const dialogsListPredictedTotalCount = useSelector(
      dialogsListSelectors.dialogsListPredictedTotalCount,
    );
    const dialogsSearchKeyword = useSelector(
      dialogsListSelectors.searchKeyword,
    );

    const loadMoreDialogsPending = useSelector(
      dialogsListSelectors.loadMoreDialogsPending,
    );

    const subscribedDialogsListItems = useSelector(
      dialogsListSelectors.subscribedDialogsListItems,
    );

    const dialogs = useMemo(() => {
      return dialogsSearchKeyword
        ? dialogsListItems
        : subscribedDialogsListItems;
    }, [dialogsListItems, dialogsSearchKeyword, subscribedDialogsListItems]);

    const dialogsFilter = useMemo(() => {
      return subscribedDialogsListItems.map(it => it.orderId.toString());
    }, [subscribedDialogsListItems]);

    const loadDialogsPending = useSelector(
      dialogsListSelectors.loadDialogsPending,
    );
    const dialogsLoaded = useSelector(dialogsListSelectors.dialogsLoaded);

    const loadMore = useCallback<InfiniteLoaderProps['loadMoreRows']>(
      params => {
        // skip loading if we are already loading items or
        // it is initial loading because we load initial batch in the other place
        // to display counter
        if (
          !dialogsSearchKeyword ||
          loadDialogsPending ||
          loadMoreDialogsPending ||
          params.startIndex === 0 ||
          dialogsListPredictedTotalCount <= dialogsListItems.length
        ) {
          return Promise.resolve();
        }
        return loadMoreDialogs(companyId);
      },
      [
        dialogsSearchKeyword,
        loadDialogsPending,
        loadMoreDialogsPending,
        dialogsListPredictedTotalCount,
        dialogsListItems.length,
        loadMoreDialogs,
        companyId,
      ],
    );

    useEffect(() => {
      setDialogsFilter(dialogsFilter);
      if (dialogsSearchKeyword) {
        loadDialogs(companyId);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <ListWrapper>
        <DialogsList
          loadDialogsPending={loadDialogsPending}
          loadMore={loadMore}
          dialogsList={dialogs}
          dialogsListCount={
            dialogsSearchKeyword
              ? dialogsListPredictedTotalCount
              : subscribedDialogsListItems.length
          }
          dialogsLoaded={dialogsLoaded}
          ref={listRef}
        />
      </ListWrapper>
    );
  },
);
