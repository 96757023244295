import React, { ReactElement, useCallback, useEffect, useMemo } from 'react';

import styled, { useTheme } from 'styled-components';

import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';

import { APICompany } from '@cd3p/core/types/api';
import { Button, Stack, Table, TableRow, Typography } from 'components';

import { useNavigate, useSelector, useTranslation } from 'third-party';

import { contractorsUrl } from 'constants/url';

import {
  ContractorsListFilters,
  ContractorsListSorting,
  useContractorsList,
} from 'modules/contractorsList';

import { contractorsListSelectors } from 'selectors';

import { StyledTableBodyCell } from 'components/Table/Table';

import { OutlinedSearchInput } from 'styles/common';

const StyledTable = styled(Table)`
  flex-grow: 1;
  min-height: 0;
  border-top: 1px solid ${props => props.theme.custom.palette.truegray300};
`;

const TableCellTextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TableCellText = styled(Typography)`
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.5;
  padding-right: 1rem;
  color: ${props => props.theme.custom.palette.secondary900};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const DeactivatedLabel = styled(Typography)`
  font-size: 1.4rem !important;
  text-transform: uppercase;
  color: ${props => props.theme.custom.palette.secondary500};
`;

const TableHeaderRow = styled(TableRow)`
  display: none;
`;

const TableBodyCell = styled(StyledTableBodyCell)`
  border-left: 1px solid ${props => props.theme.custom.palette.truegray300};
`;

const SearchPanel = styled(Stack)`
  flex-direction: row;
  border-bottom: 1px solid ${props => props.theme.custom.palette.muted50};
  border-left: 1px solid ${props => props.theme.custom.palette.muted100};
  background: ${props => props.theme.custom.palette.backgroundTheme};
  justify-content: flex-end;
  padding: 0.8rem;
`;

const AddButton = styled(Button)`
  font-size: 1.2rem;
  white-space: nowrap;
  min-width: 14rem;
  margin-left: 1rem;
`;

const StyledAddBoxOutlinedIcon = styled(AddBoxOutlinedIcon)`
  font-size: 1.6rem;
  padding-right: 0.4rem;
`;

const tableComponents = {
  TableHeaderRow,
  TableBodyCell,
};

type Props = {
  emptyState: ReactElement;
  selectedContractorId?: string;
  onAddContractor: () => void;
};

export const ContractorsList: React.FC<Props> = ({
  emptyState,
  selectedContractorId,
  onAddContractor,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();

  const { loadContractors, loadMoreContractors, updateContractorsListFilters } =
    useContractorsList();

  const contractorsListItems = useSelector(
    contractorsListSelectors.contractorsListItems,
  );
  const contractorsListCount = useSelector(
    contractorsListSelectors.contractorsListCount,
  );
  const contractorsLoaded = useSelector(
    contractorsListSelectors.contractorsLoaded,
  );
  const loadContractorsPending = useSelector(
    contractorsListSelectors.loadContractorsPending,
  );
  const loadMoreContractorsPending = useSelector(
    contractorsListSelectors.loadMoreContractorsPending,
  );
  const contractorsListNameFilter = useSelector(
    contractorsListSelectors.contractorsListNameFilter,
  );

  const sorting = useSelector(contractorsListSelectors.contractorsListSorting);

  const { sortField, sortOrder } = sorting[0];

  const loadContractorsCallback = useCallback(
    (
      params?: {
        searchSortOrders?: ContractorsListSorting;
      } & ContractorsListFilters,
    ) => {
      loadContractors({
        ...params,
      });
    },
    [loadContractors],
  );

  useEffect(() => {
    if (!contractorsLoaded) loadContractorsCallback();
  }, [loadContractorsCallback, contractorsLoaded]);

  const columns = useMemo(() => {
    return [
      {
        id: 'name',
        dataId: 'name' as const,
        formatter: (value: APICompany['name'], item: APICompany) => ({
          value: value || '',
          element: (
            <TableCellTextWrapper>
              <TableCellText>{value || ''}</TableCellText>
              {item.isDeactivated && (
                <DeactivatedLabel variant="h5">
                  {t('customers.details.deactivatedStatus')}
                </DeactivatedLabel>
              )}
            </TableCellTextWrapper>
          ),
        }),
      },
    ];
  }, [t]);

  const onRowClick = useCallback((item: APICompany) => {
    navigate(contractorsUrl(item.id.toString()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const rowHighlightColor = useCallback(
    (item: APICompany) => {
      if (item.id.toString() === selectedContractorId) {
        return theme.custom.palette.primary50;
      }
      if (item.isDeactivated) {
        return theme.custom.palette.muted50;
      }
    },
    [
      selectedContractorId,
      theme.custom.palette.primary50,
      theme.custom.palette.muted50,
    ],
  );

  const updateNameFilter = (name: string) => {
    const filtersValue = {
      name,
    };
    loadContractorsCallback(filtersValue);
    updateContractorsListFilters(filtersValue);
  };

  return contractorsLoaded &&
    !loadContractorsPending &&
    contractorsListCount === 0 &&
    !contractorsListNameFilter ? (
    emptyState
  ) : (
    <>
      <SearchPanel>
        <OutlinedSearchInput
          isClearable
          onChange={updateNameFilter}
          defaultValue={contractorsListNameFilter}
          placeholder={t('customers.table.searchFiledPlaceholderCustomers')}
        />
        <AddButton
          variant="contained"
          color="primary"
          onClick={onAddContractor}
        >
          <StyledAddBoxOutlinedIcon />
          {t('customers.addCustomerButton')}
        </AddButton>
      </SearchPanel>
      {/* @ts-ignore TODO: find out what's wrong with types */}
      <StyledTable
        infiniteScroll
        tableMinHeight="20rem"
        headerRowHeight="6.6rem"
        bodyRowHeight="5rem"
        components={tableComponents}
        columns={columns}
        items={contractorsListItems}
        itemsTotalCount={contractorsListCount}
        itemsLoaded={contractorsLoaded}
        loadItemsPending={loadContractorsPending}
        loadMoreItemsPending={loadMoreContractorsPending}
        onLoadMoreClicked={loadMoreContractors}
        sortField={sortField}
        sortOrder={sortOrder}
        onRowClick={onRowClick}
        rowHighlightColor={rowHighlightColor}
        loadMoreButtonText={t('customers.table.loadMoreButton')}
        emptyText={
          contractorsListNameFilter
            ? t('customers.table.emptySearch')
            : undefined
        }
      />
    </>
  );
};
