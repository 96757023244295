import React from 'react';

import styled from 'styled-components';

import { Typography } from 'components';

const Wrapper = styled.div`
  display: flex;
  flex-grow: 1;
  position: relative;
`;

const DefaultText = styled(Typography)`
  position: absolute;
  top: 4rem;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  color: ${props => props.theme.custom.palette.primary900};
  text-align: center;
  font-size: 1.6rem;
  font-weight: 900;
  line-height: 125%;
  text-transform: uppercase;
  z-index: 3;
  padding: 1.2rem 1.6rem;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.5;
  background: #000;
  z-index: 2;
`;

type Props = {
  text?: string;
  children: React.ReactNode;
  enabled?: boolean;
  className?: string;
};

export const MapOverlay: React.FC<Props> = ({
  text,
  children,
  enabled,
  className,
}) => {
  return (
    <Wrapper className={className}>
      {enabled && text && <DefaultText>{text}</DefaultText>}
      {enabled && <Overlay />}
      {children}
    </Wrapper>
  );
};
