import React, { useCallback, useEffect, useRef, useState } from 'react';

import styled from 'styled-components';

import {
  ON_TICKET_CREATE_EVENT,
  ON_TICKET_UPDATE_EVENT,
} from '@cd3p/core/constants/hubs';
import { getPageNumberAfterLoadMore } from '@cd3p/core/utils/lists';
import {
  CircularProgress,
  Popover,
  TableBody,
  TableCell,
  TableHead,
  Typography,
} from 'components';
import { emit, subscribe } from 'hooks/usePubSub';
import { OrderTicket } from 'selectors/order';

import { useSelector } from 'third-party';

import { APP_EVENTS } from 'constants/appEvent';

import { useActiveTicketsList } from 'modules/activeTicketsList';
import { useTracking } from 'modules/tracking';

import { activeTicketsListSelectors, trackingSelectors } from 'selectors';

import { AddTicketView } from 'features/OrderView/AddTicketView';
import { TicketsTable } from 'features/TicketsTable/TicketsTable';

import { useOrdersSockets } from 'utils/sockets';
import { composeLoadTicketsRequestBody } from 'utils/ticketsList';

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const Wrapper = styled.div`
  flex-grow: 1;
  min-height: 0;
  max-height: 100%;
  padding-bottom: 1rem;
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
`;

const TicketsTableWrapper = styled.div`
  flex-grow: 1;
  min-height: 0;
  overflow-x: auto;
`;

const TicketsTableCell = styled(TableCell)<{ height: string }>`
  height: ${props => props.height};
  padding: 0 1.6rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 0;
  position: relative;
  &:after {
    position: absolute;
    top: 50%;
    right: 0;
    bottom: 1rem;
    margin-top: -1rem;
    border-right: 1px solid #d9d9d9;
    width: 1px;
    height: 2rem;
    content: '';
  }
  &:last-child {
    border-right: 1px solid ${props => props.theme.custom.palette.muted100};
    &:after {
      display: none;
    }
  }
  &:first-child {
    border-left: 1px solid ${props => props.theme.custom.palette.muted100};
  }
`;

const TicketsTableHead = styled(TableHead)`
  box-shadow: 0 2px 3px rgb(0 0 0 / 25%);
  position: sticky;
  top: 0;
  z-index: 2;
`;

const TicketsTableBody = styled(TableBody)`
  flex-grow: 1;
  overflow-y: auto;
  border-left: 1px solid ${props => props.theme.custom.palette.muted100};
  border-right: 1px solid ${props => props.theme.custom.palette.muted100};
`;

const StyledTableColumnLabel = styled(Typography)`
  font-weight: 900;
  font-size: 1.6rem;
  text-transform: uppercase;
  color: ${props => props.theme.custom.palette.primary900};
`;

const ticketTableComponents = {
  TableBodyCell: TicketsTableCell,
  TableHead: TicketsTableHead,
  TableBody: TicketsTableBody,
  TableColumnLabel: StyledTableColumnLabel,
};

const columnProps = [
  {
    id: 'ticketNumber',
    width: '12rem',
  },
];

export const ActiveTicketsTab = () => {
  const [popupTicket, setPopupTicket] = useState<OrderTicket | null>(null);

  const { loadActiveTickets, loadMoreActiveTickets, updateActiveTickets } =
    useActiveTicketsList();

  const { loadTracking } = useTracking();

  const activeTickets = useSelector(activeTicketsListSelectors.activeTickets);
  const activeTicketsLoaded = useSelector(
    activeTicketsListSelectors.activeTicketsLoaded,
  );
  const loadActiveTicketsPending = useSelector(
    activeTicketsListSelectors.loadActiveTicketsPending,
  );
  const loadMoreActiveTicketsPending = useSelector(
    activeTicketsListSelectors.loadMoreActiveTicketsPending,
  );
  const activeTicketsTotalCount = useSelector(
    activeTicketsListSelectors.activeTicketsTotalCount,
  );

  const sorting = useSelector(activeTicketsListSelectors.activeTicketsSorting);
  const { pageNumber, pageSize } = useSelector(
    activeTicketsListSelectors.activeTicketsPagination,
  );

  const filters = useSelector(activeTicketsListSelectors.activeTicketsFilters);

  const allTrackingItemsValues = useSelector(
    trackingSelectors.trackingItemsValues,
  );

  useEffect(() => {
    emit(
      APP_EVENTS.UPDATE_ACTIVE_TICKETS,
      activeTickets,
      allTrackingItemsValues,
    );
  }, [allTrackingItemsValues, activeTickets]);

  const ticketsTableRef = useRef(null);

  useEffect(() => {
    loadTracking();
  }, [loadTracking]);

  const loadTickets = useCallback(() => {
    loadActiveTickets(
      null,
      composeLoadTicketsRequestBody({
        pageNumber,
        pageSize,
        sorting,
        filters,
      }),
    );
  }, [loadActiveTickets, pageNumber, pageSize, sorting, filters]);

  useEffect(() => {
    loadTickets();
  }, [loadTickets]);

  useOrdersSockets(
    () => [
      {
        method: ON_TICKET_CREATE_EVENT,
        action: () => {
          updateActiveTickets();
        },
      },
      {
        method: ON_TICKET_UPDATE_EVENT,
        action: () => {
          updateActiveTickets();
        },
      },
    ],
    [updateActiveTickets],
  );

  const onLoadMoreClicked = useCallback(() => {
    loadMoreActiveTickets(
      null,
      composeLoadTicketsRequestBody({
        pageNumber: getPageNumberAfterLoadMore(activeTickets, pageSize),
        pageSize,
        sorting,
        filters,
      }),
    );
  }, [loadMoreActiveTickets, activeTickets, pageSize, sorting, filters]);

  const onTableRowClicked = useCallback((ticket: OrderTicket) => {
    setPopupTicket(ticket);
  }, []);

  const isTableRowActive = useCallback(
    (ticket: OrderTicket) => {
      return ticket.id === popupTicket?.id;
    },
    [popupTicket],
  );

  const onCloseViewTicketPopup = useCallback(() => {
    setPopupTicket(null);
  }, []);

  useEffect(() => {
    // Close ticket after cancellation
    return subscribe(APP_EVENTS.CLOSE_OPENED_TICKET, () => {
      onCloseViewTicketPopup();
    });
  }, [onCloseViewTicketPopup]);

  if (!activeTicketsLoaded) {
    return (
      <LoaderWrapper>
        <CircularProgress />
      </LoaderWrapper>
    );
  }

  return (
    <Wrapper>
      <TicketsTableWrapper ref={ticketsTableRef}>
        <TicketsTable
          tickets={activeTickets}
          columnProps={columnProps}
          ticketsLoaded={activeTicketsLoaded}
          loadTicketsPending={loadActiveTicketsPending}
          loadMoreTicketsPending={loadMoreActiveTicketsPending}
          ticketsTotalCount={activeTicketsTotalCount}
          onLoadMoreClicked={onLoadMoreClicked}
          onRowClick={onTableRowClicked}
          isRowActive={isTableRowActive}
          components={ticketTableComponents}
        />
        {popupTicket && ticketsTableRef && (
          <Popover
            open
            anchorEl={ticketsTableRef.current}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={onCloseViewTicketPopup}
            PaperProps={{ sx: { overflow: 'visible' } }}
          >
            <AddTicketView
              showCloseButton
              onClosePopup={onCloseViewTicketPopup}
              ticketId={popupTicket.id}
              order={popupTicket.order}
            />
          </Popover>
        )}
      </TicketsTableWrapper>
    </Wrapper>
  );
};
