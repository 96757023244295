export const enum Environment {
  local = 'local',
  dev = 'dev',
  qa = 'qa',
  uat = 'uat',
  live = 'app',
}

interface ICoreConfig {
  env: Environment;
  isAdminPortal?: boolean;
}

export class Core {
  private static _config: ICoreConfig;

  public static init(config: ICoreConfig) {
    // for mobile rewrite live to app
    if (config.env.toString() == 'live') {
      config.env = Environment.live;
    }
    Core._config = config;
  }

  public static isLocalEnv() {
    return Core.config.env == Environment.local;
  }

  public static Environment() {
    return Core.config.env;
  }

  public static isAdminPortal() {
    return Core.config.isAdminPortal === true;
  }

  public static isLive() {
    return Core.config.env == Environment.live;
  }

  public static get config() {
    if (Core._config == null) {
      // eslint-disable-next-line no-console
      console.log('config was not set, using default');
      Core._config = { env: Environment.local, isAdminPortal: false };
    }

    return Core._config;
  }
}
