import React from 'react';

import { ProjectsTab } from './Tabs/ProjectsTab';

import { styled, useSelector } from 'third-party';

import { appSelectors } from 'selectors';

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 0;
  flex-grow: 1;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
`;

export const ContractorProjectsList = () => {
  const customerId = useSelector(appSelectors.companyId);

  return (
    <ContentWrapper>
      <ProjectsTab customerId={customerId} />
    </ContentWrapper>
  );
};
