import React, { useCallback, useEffect, useMemo, useState } from 'react';

import styled from 'styled-components';

import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

import {
  APIProject,
  APIProjectStatus,
  APIUserType,
} from '@cd3p/core/types/api';
import {
  BreadcrumbsViewAll,
  CanAccess,
  CircularProgress,
  DispatcherCanAccess,
  EditableDetailsHeaderButton,
  IconButton,
  ReadOnlyField,
  Stack,
  Tab,
  Tabs,
  TabsBottomLine,
  Tag,
  Tooltip,
  TrackingMap,
  Typography,
} from 'components';
import { useHandleApiResult } from 'hooks/useHandleApiResult';
import { useProjectArchivation } from 'hooks/useProjectArchivation';
import { useProjectDeletion } from 'hooks/useProjectDeletion';
import { subscribe } from 'hooks/usePubSub';

import { ProjectSettingsModal } from './ProjectSettingsModal';
import { ProjectAdditiveTypesTab } from './Tabs/ProjectAdditiveTypesTab';
import { ProjectMixTypesTab } from './Tabs/ProjectMixTypesTab';

import {
  Navigate,
  _,
  useLocation,
  useMatch,
  useNavigate,
  useParams,
  useSelector,
  useTranslation,
} from 'third-party';

import { APP_EVENTS } from 'constants/appEvent';
import {
  ADDITIVES_PATH,
  MIX_TYPES_PATH,
  ORDERS_PATH,
  contractorProjectsListUrl,
  customerProjectDetailsUrl,
  customerProjectEditUrl,
  customerProjectsUrl,
} from 'constants/url';

import { useContractorAdditiveTypes } from 'modules/contractorAdditiveTypes';
import { useContractorMixTypes } from 'modules/contractorMixTypes';
import { useProject } from 'modules/project';

import { appSelectors, projectSelectors } from 'selectors';

import { ProjectOrdersTab } from 'features/ProjectView/Tabs/ProjectOrdersTab';
import { FormFields } from 'features/ProjectView/projectHelpers';
import { SubscribeTeamMembersModal } from 'features/TeamSubscription/SubscribeTeamMembersModal';
import { SubscribedTeamMembersList } from 'features/TeamSubscription/SubscribedTeamMembersList';

import { LocationT } from 'types/app';

import { TabTitle, TabWrapper, TabsWrapper } from 'styles/theme';

enum ProjectDetailsTabs {
  Orders,
  MixTypes,
  AdditiveTypes,
}

const LoadingContainer = styled(Stack)`
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled(Stack)`
  flex-grow: 1;
  height: 100%;
`;

const LeftPanel = styled(Stack)`
  flex-shrink: 0;
  width: 45rem;
  padding: 3rem 0rem 3rem 2rem;
  border-right: 2px solid ${props => props.theme.custom.palette.muted100};
`;

const LeftPaneContent = styled(Stack)`
  overflow-y: auto;
  padding-right: 2rem;
  height: 100%;
`;

const RightPanel = styled(Stack)`
  padding: 2rem 3rem 1rem 2rem;
  flex-grow: 1;
`;

const StyledTab = styled(Tab)`
  flex-grow: initial;
  padding: 0 5rem;
`;

const StyledReadOnlyField = styled(ReadOnlyField)`
  margin-bottom: 1.6rem;
`;

const HeaderWrapper = styled(Stack)`
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.theme.custom.palette.muted100};
  padding: 1rem 0;
`;

const Header = styled(Typography)`
  color: ${props => props.theme.custom.palette.secondary500};
  word-break: break-word;
`;

const TrackingMapWrapper = styled.div`
  min-height: 41rem;
`;

const ActionsWrapper = styled(Stack)`
  flex-direction: row;
`;

const Overview = styled(Stack)`
  margin: 2rem 0 3rem;
  align-items: flex-start;
`;

const CoordinatesDetails = styled(Typography)`
  margin: 0.5rem 0;
  font-size: 1.6rem;
  font-weight: 600;
  color: ${props => props.theme.custom.palette.darkText};
`;

const CoordinatesDescription = styled(Typography)`
  font-size: 1.2rem;
  font-weight: 600;
  color: ${props => props.theme.custom.palette.gray};
`;

export const getFormDefaultValues = (project: APIProject) => {
  return {
    [FormFields.Name]: project.name,
    [FormFields.Company]: {
      label: project.company.name,
      value: project.company.id,
    },
    [FormFields.Address]: {
      label: project.address,
      value: project.address,
    },
    [FormFields.Description]: project.description,
  };
};

type ProjectViewDetailsProps = {
  backUrl: string;
  backUrlLabel: string;
  customerId: string;
  project: APIProject;
};

export const ProjectViewDetails: React.FC<ProjectViewDetailsProps> = ({
  customerId,
  project,
  backUrl,
  backUrlLabel,
}) => {
  const { t } = useTranslation();
  const { tabId } = useParams();
  const navigate = useNavigate();

  const { addProjectSubscriptions, loadProject } = useProject();
  const { ArchiveProjectConfirmationDialog, ArchiveProjectButton } =
    useProjectArchivation();
  const { DeleteProjectConfirmationDialog, DeleteProjectButton } =
    useProjectDeletion();
  const { loadProjectMixTypes } = useContractorMixTypes();
  const { loadProjectAdditiveTypes } = useContractorAdditiveTypes();

  const userType = useSelector(appSelectors.userType);
  const currentProviderName = useSelector(appSelectors.providerName);
  const addProjectSubscriptionsPending = useSelector(
    projectSelectors.addProjectSubscriptionsPending,
  );
  const projectSubscribedUsers = project?.subscribedUsers;

  const [mapPin, setMapPin] = useState<LocationT | null>(project);
  const [showProjectSettings, setShowProjectSettings] = useState(false);
  const [showSubscribeTeamModal, setShowSubscribeTeamModal] = useState(false);

  const matchIndexUrl = useMatch(
    customerProjectDetailsUrl(customerId, project.id),
  );

  const mixTypesUrl = `${customerProjectDetailsUrl(
    customerId,
    project.id,
  )}/${MIX_TYPES_PATH}`;

  const additiveTypesUrl = `${customerProjectDetailsUrl(
    customerId,
    project.id,
  )}/${ADDITIVES_PATH}`;

  const ordersUrl = `${customerProjectDetailsUrl(
    customerId,
    project.id,
  )}/${ORDERS_PATH}`;

  useEffect(() => {
    return subscribe(
      APP_EVENTS.UPDATE_PIN_LOCATION,
      (address: LocationT | null) => {
        setMapPin(address);
      },
    );
  }, []);

  const tabs = useMemo(
    () => [
      {
        title: t('projectDetails.ordersTabLabel'),
        onClick: () =>
          navigate(ordersUrl, {
            replace: true,
          }),
      },
      {
        title: t('projectDetails.mixTypesTabLabel'),
        onClick: () => navigate(mixTypesUrl, { replace: true }),
      },
      {
        title: t('projectDetails.additiveTypesTabLabel'),
        onClick: () =>
          navigate(additiveTypesUrl, {
            replace: true,
          }),
      },
    ],
    [t, navigate, mixTypesUrl, additiveTypesUrl, ordersUrl],
  );

  const currentTab = useMemo(() => {
    switch (tabId) {
      case MIX_TYPES_PATH:
        return ProjectDetailsTabs.MixTypes;
      case ADDITIVES_PATH:
        return ProjectDetailsTabs.AdditiveTypes;
      case ORDERS_PATH:
      default:
        return ProjectDetailsTabs.Orders;
    }
  }, [tabId]);

  const handleSettingsUpdateSuccess = useCallback(() => {
    if (currentTab === ProjectDetailsTabs.MixTypes) {
      loadProjectMixTypes(project.id);
    } else if (currentTab === ProjectDetailsTabs.AdditiveTypes) {
      loadProjectAdditiveTypes(project.id);
    }
  }, [currentTab, loadProjectAdditiveTypes, loadProjectMixTypes, project.id]);

  const handleSubscribeTeamClick = useCallback(() => {
    setShowSubscribeTeamModal(true);
  }, []);

  const handleSubscribeTeamSuccess = useCallback(() => {
    loadProject(project.id);
  }, [loadProject, project.id]);

  const handleSubscribeTeam = useCallback(
    (membersIds: string[]) => addProjectSubscriptions(project.id, membersIds),
    [addProjectSubscriptions, project.id],
  );

  const mapSites = useMemo<LocationT[]>(() => {
    return mapPin?.longitude && mapPin?.latitude ? [mapPin] : [];
  }, [mapPin]);

  return (
    <Wrapper direction="row">
      <LeftPanel>
        {showSubscribeTeamModal && (
          <SubscribeTeamMembersModal
            title={t('subscribeTeamMembers.dialog.title')}
            description={t('subscribeTeamMembers.dialog.projectDescription')}
            onSubscribeSuccess={handleSubscribeTeamSuccess}
            subscribeTeamMembers={handleSubscribeTeam}
            onClosePopup={() => setShowSubscribeTeamModal(false)}
            subscribedUsers={projectSubscribedUsers}
            isLoading={addProjectSubscriptionsPending}
          />
        )}
        <ArchiveProjectConfirmationDialog />
        <DeleteProjectConfirmationDialog
          onSuccess={() => {
            navigate(
              userType === APIUserType.Dispatcher
                ? customerProjectsUrl(customerId)
                : contractorProjectsListUrl(),
              { replace: true },
            );
          }}
        />
        {showProjectSettings && (
          <ProjectSettingsModal
            onClose={() => setShowProjectSettings(false)}
            onSuccess={handleSettingsUpdateSuccess}
            projectId={project.id}
            project={project}
            companyId={customerId}
          />
        )}
        <BreadcrumbsViewAll url={backUrl} text={backUrlLabel} />
        <LeftPaneContent>
          <HeaderWrapper direction="row">
            <Header variant="h3">{project.name}</Header>
            {project.projectStatus === APIProjectStatus.Archived && (
              <Tag
                margin="0.6rem 0.6rem 0 auto"
                value={t('project.archivation.archivedStatus')}
              />
            )}
            <ActionsWrapper>
              <DispatcherCanAccess>
                <IconButton
                  onClick={() => {
                    setShowProjectSettings(true);
                  }}
                >
                  <Tooltip title={t('projectSettings.title')}>
                    <SettingsOutlinedIcon color="primary" />
                  </Tooltip>
                </IconButton>
              </DispatcherCanAccess>
              {project.inUse ? (
                <ArchiveProjectButton project={project} />
              ) : (
                <DeleteProjectButton project={project} />
              )}
            </ActionsWrapper>
          </HeaderWrapper>
          <Overview>
            <EditableDetailsHeaderButton
              titleVariant="h5"
              sectionTitle={t('projectDetails.overviewLabel')}
              handleOnClick={() =>
                navigate(customerProjectEditUrl(customerId, project.id), {
                  state: {
                    backUrl: location.pathname,
                    backUrlLabel: t('common.back'),
                  },
                })
              }
            />

            <StyledReadOnlyField
              value={project.name || t('common.entityNameUndefined')}
              label={t('projectDetails.nameField')}
            />
            <StyledReadOnlyField
              value={project.address || t('common.entityNameUndefined')}
              label={t('projectDetails.addressField')}
            />
            {project.deliveryAddress && (
              <StyledReadOnlyField
                value={
                  project.deliveryAddress || t('common.entityNameUndefined')
                }
                label={t('projectDetails.deliveryLocationField')}
              >
                <Stack>
                  <CoordinatesDetails>
                    {project.latitude}, {project.longitude}
                  </CoordinatesDetails>
                  <CoordinatesDescription>
                    {t('projectDetails.deliveryLocationDescription')}
                  </CoordinatesDescription>
                </Stack>
              </StyledReadOnlyField>
            )}
            <DispatcherCanAccess>
              <StyledReadOnlyField
                value={project.company.name || t('common.entityNameUndefined')}
                label={t('projectDetails.customerField')}
              />
            </DispatcherCanAccess>
            <CanAccess allowedUserType={APIUserType.Contractor}>
              <StyledReadOnlyField
                value={currentProviderName || t('common.entityNameUndefined')}
                label={t('common.provider')}
              />
            </CanAccess>
            <StyledReadOnlyField
              value={project.description || t('common.entityNameUndefined')}
              label={t('common.form.project.descriptionLabel')}
            />
            <CanAccess allowedUserType={APIUserType.Contractor}>
              <SubscribedTeamMembersList
                members={projectSubscribedUsers}
                onSubscribeClick={handleSubscribeTeamClick}
              />
            </CanAccess>
          </Overview>
          {!_.isEmpty(mapSites) && (
            <TrackingMapWrapper>
              <TrackingMap sites={mapSites} />
            </TrackingMapWrapper>
          )}
        </LeftPaneContent>
      </LeftPanel>
      <RightPanel>
        <TabsWrapper>
          <Tabs value={currentTab}>
            {tabs.map(tab => {
              return (
                <StyledTab
                  key={tab.title}
                  label={
                    <TabWrapper>
                      <TabTitle>{tab.title}</TabTitle>
                    </TabWrapper>
                  }
                  onClick={tab.onClick}
                />
              );
            })}
            <TabsBottomLine />
          </Tabs>
          {currentTab === ProjectDetailsTabs.Orders && <ProjectOrdersTab />}
          {currentTab === ProjectDetailsTabs.MixTypes && (
            <ProjectMixTypesTab key={project.id} projectId={project.id} />
          )}
          {currentTab === ProjectDetailsTabs.AdditiveTypes && (
            <ProjectAdditiveTypesTab key={project.id} projectId={project.id} />
          )}
          {matchIndexUrl && <Navigate to={ordersUrl} replace />}
        </TabsWrapper>
      </RightPanel>
    </Wrapper>
  );
};

export const ProjectView = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const { projectId, customerId } = useParams();

  const { loadProject, resetProject } = useProject();

  const project = useSelector(projectSelectors.project);
  const projectLoaded = useSelector(projectSelectors.projectLoaded);
  const userType = useSelector(appSelectors.userType);

  const { backUrl, backUrlLabel } = useMemo(() => {
    return state?.backUrl
      ? {
          backUrl: state.backUrl,
          backUrlLabel: state.backUrlLabel || t('common.back'),
        }
      : {
          backUrl:
            userType === APIUserType.Dispatcher
              ? customerProjectsUrl(customerId)
              : contractorProjectsListUrl(),
          backUrlLabel:
            userType === APIUserType.Dispatcher
              ? t('projectDetails.backButtonLabel')
              : t('projectDetails.projectsBackButtonLabel'),
        };
    // read state only for the first render of this view
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleApiResult = useHandleApiResult();

  useEffect(() => {
    if (projectId) {
      handleApiResult(
        () => loadProject(projectId),
        _.noop,
        ({ redirectOn404 }) => redirectOn404(customerProjectsUrl(customerId)),
      );
    }

    return () => {
      resetProject();
    };
  }, [customerId, handleApiResult, loadProject, projectId, resetProject]);

  return projectLoaded && project ? (
    <ProjectViewDetails
      project={project}
      customerId={customerId!}
      backUrl={backUrl}
      backUrlLabel={backUrlLabel}
    />
  ) : (
    <LoadingContainer>
      <CircularProgress />
    </LoadingContainer>
  );
};
