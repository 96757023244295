import { useMemo } from 'react';

import {
  APIRequiredAction,
  APIRequiredActionSortOrderType,
  APIRequiredActionUrgencyType,
  APIRequiredActionsContinueSearchResult,
} from '@cd3p/core/types/api';
import { httpMethod, simplifyBuilder } from '@cd3p/core/utils/sra';

import { _, bindActionCreators, useDispatch, useSelector } from 'third-party';

import {
  markRequiredActionAsReadEndpoint,
  requiredActionsEndpoint,
} from 'constants/endpoints';

import { State } from 'modules/index';

import { appSelectors, requiredActionsListSelectors } from 'selectors';

export const REQUIRED_ACTIONS_PER_PAGE = 20;

export type RequiredActionsListState = {
  items: APIRequiredAction[];
  token: string | null;
  requiredActionsLoaded: boolean;
  loadRequiredActionPending: boolean;
  loadMoreRequiredActionPending: boolean;
  markRequiredActionAsReadPending: boolean;
};

const requiredActionsListState: RequiredActionsListState = {
  items: [],
  token: null,
  requiredActionsLoaded: false,
  loadRequiredActionPending: false,
  loadMoreRequiredActionPending: false,
  markRequiredActionAsReadPending: false,
};

const builder = simplifyBuilder(requiredActionsListState, {});

export const loadRequiredAction = builder.createServerAction(
  (providerId: string, sorting?: APIRequiredActionSortOrderType) => ({
    name: 'loadRequiredAction',
    url: requiredActionsEndpoint(providerId),
    method: httpMethod.post,
    body: (state: State) => ({
      orderByType:
        sorting ||
        requiredActionsListSelectors.requiredActionsListSorting(state),
      continuationToken: '',
    }),
    onSuccess: (
      state: RequiredActionsListState,
      payload: APIRequiredActionsContinueSearchResult,
    ) => ({
      requiredActionsLoaded: true,
      token: payload.token,
      items: payload.result,
    }),
  }),
);

export const markRequiredActionAsRead = builder.createServerAction(
  (providerId: string, requiredActionId: string) => ({
    name: 'markRequiredActionAsRead',
    url: markRequiredActionAsReadEndpoint(providerId, requiredActionId),
    method: httpMethod.post,
  }),
);

export const loadMoreRequiredAction = builder.createServerAction(
  (providerId: string) => ({
    name: 'loadMoreRequiredAction',
    url: requiredActionsEndpoint(providerId),
    body: (state: State) => {
      const sorting =
        requiredActionsListSelectors.requiredActionsListSorting(state);
      return {
        orderByType: sorting,
        continuationToken: state.requiredActionsList.token,
        ...(sorting === APIRequiredActionSortOrderType.Urgency && {
          lastUrgencyType:
            state.requiredActionsList.items[
              state.requiredActionsList.items.length - 1
            ]?.urgencyType,
        }),
      };
    },
    method: httpMethod.post,
    onSuccess: (
      state: RequiredActionsListState,
      payload: APIRequiredActionsContinueSearchResult,
    ) => ({
      requiredActionsLoaded: true,
      token: payload.token,
      items: _.uniqBy([...state.items, ...payload.result], 'id'),
    }),
  }),
);

export const resetRequiredActions = builder.createReduxAction(() => ({
  name: 'resetRequiredActions',
  updater: () => ({
    requiredActionsLoaded: false,
    token: null,
    items: [],
  }),
}));

export const ADD_TO_REQUIRED_ACTIONS_LIST = 'addToRequiredActionsList';
export const addToRequiredActionsList = builder.createReduxAction(
  (
    requiredAction: APIRequiredAction,
    sorting: APIRequiredActionSortOrderType,
  ) => ({
    name: ADD_TO_REQUIRED_ACTIONS_LIST,
    updater: (state: RequiredActionsListState) => {
      let newItems = state.items;
      if (sorting === APIRequiredActionSortOrderType.Newest) {
        newItems = [requiredAction, ...state.items];
      } else if (sorting === APIRequiredActionSortOrderType.Oldest) {
        // put new item at the end if we already loaded all items
        if (!state.token) {
          newItems = [...state.items, requiredAction];
        }
      } else if (sorting === APIRequiredActionSortOrderType.Urgency) {
        const urgencyGroups = _.groupBy(state.items, 'urgencyType');
        urgencyGroups[requiredAction.urgencyType] = [
          ...(urgencyGroups[requiredAction.urgencyType]
            ? urgencyGroups[requiredAction.urgencyType]
            : []),
          requiredAction,
        ];
        const amendedItems = _.union(
          _.orderBy(
            urgencyGroups[APIRequiredActionUrgencyType.High] || [],
            ['eventDate'],
            ['desc'],
          ),
          _.orderBy(
            urgencyGroups[APIRequiredActionUrgencyType.Medium] || [],
            ['eventDate'],
            ['asc'],
          ),
          _.orderBy(
            urgencyGroups[APIRequiredActionUrgencyType.Low] || [],
            ['eventDate'],
            ['asc'],
          ),
        );
        // if after inserting item and correct sorting it happens to be
        // the last item on the page, and we still have continuation token,
        // no need to insert it, it will be loaded on the following pages
        if (
          !(
            amendedItems[amendedItems.length - 1]?.id === requiredAction.id &&
            state.token
          )
        ) {
          newItems = amendedItems;
        }
      }
      return {
        items: _.uniqBy(newItems, 'id'),
      };
    },
  }),
);

export const REMOVE_FROM_REQUIRED_ACTIONS_LIST =
  'removeFromRequiredActionsList';
export const removeFromRequiredActionsList = builder.createReduxAction(
  (requiredActionId: string) => ({
    name: REMOVE_FROM_REQUIRED_ACTIONS_LIST,
    updater: (state: RequiredActionsListState) => {
      return {
        items: _.reject(state.items, { id: requiredActionId }),
      };
    },
  }),
);

export const useRequiredActionsList = () => {
  const dispatch = useDispatch();
  const providerId = useSelector(appSelectors.providerId);

  return useMemo(
    () =>
      bindActionCreators(
        {
          loadRequiredAction: loadRequiredAction.bind(null, providerId),
          loadMoreRequiredAction: loadMoreRequiredAction.bind(null, providerId),
          markRequiredActionAsRead: markRequiredActionAsRead.bind(
            null,
            providerId,
          ),
          resetRequiredActions: resetRequiredActions.bind(null),
          addToRequiredActionsList: addToRequiredActionsList.bind(null),
          removeFromRequiredActionsList:
            removeFromRequiredActionsList.bind(null),
        },
        dispatch,
      ),
    [dispatch, providerId],
  );
};

export const requiredActionsListReducer = builder.getReducers();
