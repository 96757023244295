import { State } from 'modules';
import { userSettingsSelector } from 'selectors/storage';

import { createSelector } from 'third-party';

import { REQUIRED_ACTIONS_PER_PAGE } from 'modules/requiredActionsList';
import { StorageUserSettings } from 'modules/storage';

export const loadRequiredActionPending = (state: State) =>
  state.requiredActionsList.loadRequiredActionPending;
export const requiredActionsLoaded = (state: State) =>
  state.requiredActionsList.requiredActionsLoaded;

export const loadMoreRequiredActionPending = (state: State) =>
  state.requiredActionsList.loadMoreRequiredActionPending;

export const requiredActionsListSorting = userSettingsSelector(
  (userSettings: StorageUserSettings) =>
    userSettings.requiredActionsListSorting,
);

export const requiredActionsListItems = (state: State) =>
  state.requiredActionsList.items;

export const requiredActionsListToken = (state: State) =>
  state.requiredActionsList.token;

export const requiredActionsListCounter = createSelector(
  [requiredActionsListToken, requiredActionsListItems],
  (token, items) => {
    return items.length + (token ? REQUIRED_ACTIONS_PER_PAGE : 0);
  },
);

export const requiredActionsListPredictedTotalCount = createSelector(
  [requiredActionsLoaded, requiredActionsListToken, requiredActionsListItems],
  (isLoaded, token, items) => {
    // we don't know the total count in advance, we can only predict it
    // looking at the presence of continuation token
    // if token is present the list might have another `REQUIRED_ACTIONS_PER_PAGE` amount
    // of items, if not - this is the last page and the total count equals the count we
    // already have loaded
    if (isLoaded) {
      if (token) {
        return items.length + REQUIRED_ACTIONS_PER_PAGE;
      } else {
        return items.length;
      }
    } else {
      return REQUIRED_ACTIONS_PER_PAGE;
    }
  },
);
