import React from 'react';

import { classNames } from 'third-party';

interface Props {
  height: string;
  icon: string;
  className?: string;
  width: string;
  color?: string;
}

export const SvgIcon: React.FC<Props> = props => {
  const { height = 16, icon = '', className, width = 16, color } = props;

  return (
    <svg
      className={classNames('svg-icon', className, icon)}
      style={{ width, height, color }}
    >
      <use {...props} xlinkHref={`#${icon}`} />
    </svg>
  );
};
