import styled from 'styled-components';

import { SearchInput } from 'components';

export const NotificationLink = styled.span`
  text-decoration: none;
  font-weight: 700;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const OutlinedSearchInput = styled(SearchInput)`
  border: 1px solid ${props => props.theme.custom.palette.muted100};
  border-radius: 4px;
  width: 100%;
  .MuiFilledInput-root {
    border-radius: 4px;
    background: ${props => props.theme.custom.palette.white};
    height: 3.2rem;
  }
  input {
    &::placeholder {
      opacity: 1;
      color: ${props => props.theme.custom.palette.muted400};
    }

    &::-ms-input-placeholder {
      color: ${props => props.theme.custom.palette.muted400};
    }
  }
`;
