import React, { useCallback, useState } from 'react';

import styled, { css } from 'styled-components';

import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import Code from '@mui/icons-material/Code';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

import { userProviders } from '@cd3p/core/selectors/app';
import {
  APIAccessLevel,
  APIHelpRequest,
  APIUserType,
} from '@cd3p/core/types/api';
import {
  AnimatedMenuIcon,
  Avatar,
  CanAccess,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  SvgIcon,
  Tooltip,
  Typography,
} from 'components';
import { motion } from 'framer-motion';
import { useHandleApiResult } from 'hooks/useHandleApiResult';

import { MenuNavigationItem } from './MenuNavigationItem';
import { SupportModal } from './SupportModal';

import {
  useMatch,
  useNavigate,
  useSelector,
  useTranslation,
} from 'third-party';

import { TOOLTIP_APPEAR_DELAY } from 'constants/common';
import {
  allOrdersUrl,
  apiDocsUrl,
  contractorProjectsListUrl,
  contractorTeamListUrl,
  contractorsUrl,
  docsUrl,
  productsUrl,
  providerUsersListUrl,
  settingsUrl,
  todayOrdersUrl,
} from 'constants/url';

import { useApp } from 'modules/app';
import { useHelpCenter } from 'modules/helpCenter';
import { useStorage } from 'modules/storage';

import { appSelectors } from 'selectors';

import {
  DispatcherCanAccess,
  RegularUserCanAccess,
  SupportCanAccess,
} from 'components/CanAccess/CanAccess';

import { DialogsButton } from 'features/DialogsPanel/DialogsButton';
import { RequiredActionsButton } from 'features/RequiredActionsPanel/RequiredActionsButton';

import { useLogOutUser } from 'utils/auth';

const MenuIconStyle = css`
  color: ${props => props.theme.custom.palette.secondary800};
  width: 2.2rem;
  height: 2.2rem;
  margin-right: 0.4rem;
`;

const StyledSettingsOutlinedIcon = styled(SettingsOutlinedIcon)`
  ${MenuIconStyle}
`;

const StyledAccountCircleOutlinedIcon = styled(AccountCircleOutlinedIcon)`
  ${MenuIconStyle}
`;

const StyledExitToAppOutlinedIcon = styled(ExitToAppOutlinedIcon)`
  ${MenuIconStyle}
`;

const StyledContactSupportIcon = styled(ContactSupportIcon)`
  ${MenuIconStyle}
`;

const StyledCodeIcon = styled(Code)`
  ${MenuIconStyle}
`;

const StyledFiberNewIcon = styled(FiberNewIcon)`
  ${MenuIconStyle}
`;

const ProviderIcon = styled(SvgIcon)`
  ${MenuIconStyle}
`;

const StyledMenuItem = styled(MenuItem)<{
  $isSelected?: boolean;
  $isProviderItem?: boolean;
}>`
  text-transform: capitalize;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 2.1rem;
  padding-left: 1rem;
  padding-top: ${props => (props.$isProviderItem ? 0.8 : 0.4)}rem;
  padding-bottom: ${props => (props.$isProviderItem ? 0.8 : 0.4)}rem;
  color: ${props => props.theme.custom.palette.secondary800};

  ${props =>
    props.$isProviderItem &&
    css`
      border-radius: 0.4rem;
      margin: 0.4rem;
    `};

  ${props =>
    props.$isSelected
      ? 'background-color:' + props.theme.custom.palette.primary200
      : ''};

  ${props =>
    props.$isSelected
      ? 'color:' + props.theme.custom.palette.secondary900
      : ''};

  &:hover {
    background-color: ${props => props.theme.custom.palette.primary500};
    color: ${props => props.theme.custom.palette.secondary900};
    ${StyledSettingsOutlinedIcon}, ${StyledAccountCircleOutlinedIcon}, ${StyledExitToAppOutlinedIcon}, ${StyledContactSupportIcon}, ${StyledFiberNewIcon} {
      color: ${props => props.theme.custom.palette.secondary800};
    }
  }
`;

const MenuDivider = styled(Divider)`
  margin: 0 !important;
  border-color: ${props => props.theme.custom.palette.muted200};
`;

const NavigationWrapper = styled.div`
  display: flex;
  width: 70px;
  padding-top: 5px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  background: ${props => props.theme.custom.palette.themeBackground};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  z-index: ${props => props.theme.custom.zIndex.mainNavigation};
`;

const StyledLogo = styled(SvgIcon)`
  margin: 1rem 1rem 1.5rem 1rem;
`;

const BottomButtonsWrapper = styled.div`
  margin-top: auto;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.2rem;
`;

const LogoWrapper = styled.div`
  padding-top: 1.5rem;
  &:hover {
    cursor: pointer;
  }
`;

const StyledMenu = styled(Menu)`
  .MuiPopover-paper {
    margin-left: 5rem;
    min-width: 18rem;
    max-width: 40rem;
  }
  .MuiMenu-list {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

const StyledAvatarSection = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid ${props => props.theme.custom.palette.muted200};
  padding: 1.2rem;
  gap: 1.2rem;
`;

const UserInfoWrapper = styled.div`
  overflow: hidden;
  text-overflow: 'ellipsis';
  max-width: 25rem;
`;

const StyledName = styled(Typography)`
  padding-bottom: 3px;
`;

const VersionWrapper = styled.div`
  margin: 0.25rem 1.5rem 0.5rem 1.4rem;
  color: ${props => props.theme.custom.palette.muted500};
`;

const VersionText = styled(Typography)`
  font-size: 1.2rem;
`;

const AvatarButton = styled(IconButton)`
  padding: 0;
` as typeof IconButton;

const StyledInfoButton = styled(IconButton)<{ active?: string }>`
  background-color: ${props =>
    props.active === 'true'
      ? props.theme.custom.palette.primary50
      : 'transparent'};
  width: 4rem;
  height: 4rem;
`;

const StyledInfoOutlinedIcon = styled(InfoOutlinedIcon)`
  width: 3rem;
  color: ${props => props.theme.custom.palette.secondary900};
`;

const SupportPanelBottomButtons = () => {
  const { t } = useTranslation();

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  const logOutUser = useLogOutUser();
  const isMenuOpen = Boolean(menuAnchorEl);

  const { firstName, lastName, email } = useSelector(appSelectors.user);
  const userName = ` ${firstName} ${lastName}`;

  const onProfileButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const onSignOutClick = async () => {
    handleMenuClose();
    await logOutUser();
  };

  return (
    <BottomButtonsWrapper>
      <Tooltip enterDelay={TOOLTIP_APPEAR_DELAY} title={userName}>
        <AvatarButton
          onClick={onProfileButtonClick}
          component={motion.button}
          whileHover="enlarge"
        >
          <Avatar firstName={firstName} lastName={lastName} />
        </AvatarButton>
      </Tooltip>
      <StyledMenu
        id="basic-menu"
        anchorEl={menuAnchorEl}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <StyledAvatarSection>
          <Avatar firstName={firstName} lastName={lastName} />
          <UserInfoWrapper>
            <Tooltip title={userName} placement="right-end">
              <StyledName
                textTransform={'capitalize'}
                noWrap
                variant="subtitle2"
              >
                {userName}
              </StyledName>
            </Tooltip>
            <Tooltip title={email}>
              <StyledName noWrap variant="inherit" fontSize={13}>
                {email}
              </StyledName>
            </Tooltip>
          </UserInfoWrapper>
        </StyledAvatarSection>
        <StyledMenuItem onClick={onSignOutClick}>
          <StyledExitToAppOutlinedIcon />
          {t('login.logout')}
        </StyledMenuItem>
        <MenuDivider />
        <VersionWrapper>
          <VersionText>
            {t('app.version')} {APPLICATION_VERSION}
          </VersionText>
        </VersionWrapper>
      </StyledMenu>
    </BottomButtonsWrapper>
  );
};

const PanelBottomButtons = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [infoMenuAnchorEl, setInfoMenuAnchorEl] = useState<null | HTMLElement>(
    null,
  );

  const { sendSupportRequest } = useHelpCenter();
  const handleApiResult = useHandleApiResult();

  const { firstName, lastName, email } = useSelector(appSelectors.user);
  const userName = ` ${firstName} ${lastName}`;

  const [showSupportModal, setShowSupportModal] = useState(false);

  const onSupportModalClose = (support: APIHelpRequest) => {
    handleApiResult(
      () => sendSupportRequest(support),
      ({ showBaseToast }) => {
        showBaseToast(t('support.send'));
      },
    );
  };

  const logOutUser = useLogOutUser();

  const providers = useSelector(userProviders);
  const providerId = useSelector(appSelectors.providerId);

  const { setSelectedProvider } = useApp();
  const { updateUserSettingInStorage } = useStorage();
  const userType = useSelector(appSelectors.userType);

  const isApiDocsFeatureEnabled = useSelector(
    appSelectors.isApiDocsFeatureEnabled,
  );
  const isMenuOpen = Boolean(menuAnchorEl);
  const isInfoMenuOpen = Boolean(infoMenuAnchorEl);
  const onProfileButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const onInfoButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setInfoMenuAnchorEl(event.currentTarget);
  };

  const onSignOutClick = async () => {
    handleMenuClose();
    await logOutUser();
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleInfoMenuClose = () => {
    setInfoMenuAnchorEl(null);
  };

  const onHelpCenterClick = () => {
    handleInfoMenuClose();
    navigate(docsUrl());
  };

  const onProviderClick = (providerId: string) => {
    setSelectedProvider(providerId);
    updateUserSettingInStorage({ currentProviderId: providerId });
    navigate(allOrdersUrl());
    handleMenuClose();
  };

  const onUsersClick = () => {
    handleMenuClose();

    navigate(
      userType === APIUserType.Dispatcher || userType === APIUserType.Support
        ? providerUsersListUrl()
        : contractorTeamListUrl(),
    );
  };

  const onSupportClick = () => {
    handleInfoMenuClose();
    setShowSupportModal(true);
  };

  const onSettingsClick = () => {
    navigate(settingsUrl());
    handleMenuClose();
  };

  const onApiDocsClick = () => {
    navigate(apiDocsUrl());
    handleInfoMenuClose();
  };

  const handleRequiredPanelButtonClick = useCallback(() => {
    updateUserSettingInStorage({ openedLeftPanel: null });
    handleMenuClose();
  }, [updateUserSettingInStorage]);

  const handleDialogsPanelButtonClick = useCallback(() => {
    updateUserSettingInStorage({ openedLeftPanel: null });
    handleMenuClose();
  }, [updateUserSettingInStorage]);
  return (
    <BottomButtonsWrapper>
      <DialogsButton onClick={handleDialogsPanelButtonClick} />
      <RequiredActionsButton onClick={handleRequiredPanelButtonClick} />
      <StyledInfoButton
        active={String(isInfoMenuOpen)}
        onClick={onInfoButtonClick}
        // @ts-ignore
        component={motion.button}
        whileHover="enlarge"
      >
        <AnimatedMenuIcon iconComponent={<StyledInfoOutlinedIcon />} />
      </StyledInfoButton>
      <Tooltip enterDelay={TOOLTIP_APPEAR_DELAY} title={userName}>
        <AvatarButton
          onClick={onProfileButtonClick}
          component={motion.button}
          whileHover="enlarge"
        >
          <Avatar firstName={firstName} lastName={lastName} />
        </AvatarButton>
      </Tooltip>
      <StyledMenu
        id="basic-menu"
        anchorEl={menuAnchorEl}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <StyledAvatarSection>
          <Avatar firstName={firstName} lastName={lastName} />
          <UserInfoWrapper>
            <Tooltip title={userName} placement="right-end">
              <StyledName
                textTransform={'capitalize'}
                noWrap
                variant="subtitle2"
              >
                {userName}
              </StyledName>
            </Tooltip>
            <Tooltip title={email}>
              <StyledName noWrap variant="inherit" fontSize={13}>
                {email}
              </StyledName>
            </Tooltip>
          </UserInfoWrapper>
        </StyledAvatarSection>
        {providers && providers?.length > 1 && (
          <CanAccess
            allowedUserType={[APIUserType.Dispatcher, APIUserType.Contractor]}
          >
            {providers?.map(it => (
              <StyledMenuItem
                $isProviderItem
                key={it.id}
                $isSelected={it.id === providerId}
                onClick={() => onProviderClick(it.id as string)}
              >
                <ProviderIcon icon="provider-icon" width="2rem" height="2rem" />
                {it.providerName}
              </StyledMenuItem>
            ))}
            <MenuDivider />
          </CanAccess>
        )}
        <DispatcherCanAccess>
          <CanAccess allowedFor={APIAccessLevel.Admin}>
            <StyledMenuItem onClick={onSettingsClick}>
              <StyledSettingsOutlinedIcon />
              {t('appbar.menu.setting')}
            </StyledMenuItem>
          </CanAccess>
        </DispatcherCanAccess>
        <CanAccess allowedFor={APIAccessLevel.Admin}>
          <StyledMenuItem onClick={onUsersClick}>
            <StyledAccountCircleOutlinedIcon />
            {t('appbar.menu.users')}
          </StyledMenuItem>
        </CanAccess>
        <MenuDivider />
        <StyledMenuItem onClick={onSignOutClick}>
          <StyledExitToAppOutlinedIcon />
          {t('login.logout')}
        </StyledMenuItem>
        <MenuDivider />
        <VersionWrapper>
          <VersionText>
            {t('app.version')} {APPLICATION_VERSION}
          </VersionText>
        </VersionWrapper>
      </StyledMenu>
      <StyledMenu
        id="info-menu"
        anchorEl={infoMenuAnchorEl}
        open={isInfoMenuOpen}
        onClose={handleInfoMenuClose}
      >
        <StyledMenuItem onClick={onHelpCenterClick}>
          <StyledFiberNewIcon />
          {t('appbar.menu.whatsNews')}
        </StyledMenuItem>
        <StyledMenuItem onClick={onSupportClick}>
          <StyledContactSupportIcon />
          {t('support.title')}
        </StyledMenuItem>
        {isApiDocsFeatureEnabled && (
          <StyledMenuItem onClick={onApiDocsClick}>
            <StyledCodeIcon />
            {t('appbar.menu.apiDocs')}
          </StyledMenuItem>
        )}
        <MenuDivider />
        <VersionWrapper>
          {t('app.version')} : {APPLICATION_VERSION}
        </VersionWrapper>
      </StyledMenu>
      {showSupportModal && (
        <SupportModal
          onClose={() => setShowSupportModal(false)}
          onSuccess={onSupportModalClose}
        />
      )}
    </BottomButtonsWrapper>
  );
};

export const MainNavigationContent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <SupportCanAccess>
        <MenuNavigationItem
          caption={t('appDrawer.menu.providers')}
          icon="nav-customer"
          isActive={!!useMatch([contractorsUrl(), '*'].join('/'))}
          onClick={() => navigate(contractorsUrl())}
        />
        <SupportPanelBottomButtons />
      </SupportCanAccess>
      <RegularUserCanAccess>
        <DispatcherCanAccess>
          <MenuNavigationItem
            caption={t('appDrawer.menu.dashBoard')}
            icon="nav-dashboard"
            isActive={!!useMatch([todayOrdersUrl(), '*'].join('/'))}
            onClick={() => navigate(todayOrdersUrl())}
          />
        </DispatcherCanAccess>
        <MenuNavigationItem
          caption={t('appDrawer.menu.orders')}
          icon="nav-truck"
          isActive={!!useMatch([allOrdersUrl(), '*'].join('/'))}
          onClick={() => navigate(allOrdersUrl())}
        />
        <DispatcherCanAccess>
          <MenuNavigationItem
            caption={t('appDrawer.menu.customer')}
            icon="nav-customer"
            isActive={!!useMatch([contractorsUrl(), '*'].join('/'))}
            onClick={() => navigate(contractorsUrl())}
          />
        </DispatcherCanAccess>

        <CanAccess allowedUserType={APIUserType.Contractor}>
          <MenuNavigationItem
            caption={t('appDrawer.menu.projects')}
            icon="nav-projects"
            isActive={!!useMatch([contractorProjectsListUrl(), '*'].join('/'))}
            onClick={() => navigate(contractorProjectsListUrl())}
          />
        </CanAccess>
        <DispatcherCanAccess>
          <MenuNavigationItem
            caption={t('appDrawer.menu.products')}
            icon="nav-products"
            isActive={!!useMatch([productsUrl(), '*'].join('/'))}
            onClick={() => navigate(productsUrl())}
          />
        </DispatcherCanAccess>
        <PanelBottomButtons />
      </RegularUserCanAccess>
    </>
  );
};

export const MainNavigation = () => {
  const navigate = useNavigate();
  return (
    <NavigationWrapper>
      <LogoWrapper onClick={() => navigate(allOrdersUrl())}>
        <StyledLogo height="5rem" width="5rem" icon="logo-small" />
      </LogoWrapper>
      <MainNavigationContent />
    </NavigationWrapper>
  );
};
