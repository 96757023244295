import React, { useCallback, useState } from 'react';

import styled from 'styled-components';

import BrokenImage from '@mui/icons-material/BrokenImage';
import ReloadIcon from '@mui/icons-material/Refresh';

import { IconButton, Typography } from 'components';
import { useTranslation } from 'react-i18next';

import { ContentLoader } from 'third-party';

import { themeConfig } from 'styles/theme';

type FallbackImageDimensions = {
  width: string;
  height: string;
};

const FailedImageWrapper = styled.div<FallbackImageDimensions>`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.custom.palette.backgroundTheme};
  border-radius: 0.8rem;
  position: relative;
  justify-content: center;
  align-items: center;
  width: ${props => props.width};
  height: ${props => props.height};
`;

const StyledImg = styled.img`
  border-radius: 0.8rem;
  cursor: pointer;
  width: 13rem;
  height: 13rem;
  object-fit: cover;
`;

const Container = styled.div`
  cursor: pointer;
  position: relative;
`;

const StyledRefreshButton = styled(IconButton)`
  z-index: 1;
  right: 0;
`;

const FallbackText = styled(Typography)`
  font-size: 1.2rem;
  margin-top: 1rem;
  text-align: center;
`;

const AttachmentLoaderWrapper = styled.div<FallbackImageDimensions>`
  display: flex;
  background: white;
  border-radius: 0.8rem;
  align-items: center;
  justify-content: center;
  width: ${props => props.width};
  height: ${props => props.height};
`;

export const ImageLoader = ({
  width,
  height,
  className,
}: {
  width: string;
  height: string;
  className?: string;
}) => {
  return (
    <AttachmentLoaderWrapper
      className={className}
      width={width}
      height={height}
    >
      <ContentLoader viewBox="-0.5 0.5 42 42" width={100} height={100}>
        <path d="M35.79,31.5c-0.05-0.04-5.181-7.971-6.72-7.971c-1.51,0-4.391,3.851-4.391,3.851c-2.24-2.31-7.2-8.87-8.661-8.87  c-1.509,0-7.449,8.12-10.789,12.99H35.79z M26.811,14.5c0,2.04,1.649,3.69,3.689,3.69s3.689-1.65,3.689-3.69s-1.649-3.69-3.689-3.69  S26.811,12.46,26.811,14.5z M0.5,7.5v27c0,2.52,0.51,3,3,3h34c2.471,0,3-0.46,3-3v-27c0-2.46-0.471-3-3-3h-34  C1.02,4.5,0.5,4.93,0.5,7.5z M3.5,7.5h34v27h-34V7.5z" />
      </ContentLoader>
    </AttachmentLoaderWrapper>
  );
};

const StyledImageLoader = styled(ImageLoader)`
  position: absolute;
`;

type Props = {
  width: string;
  height: string;
  onClick: () => void;
  className?: string;
  src: HTMLImageElement['src'];
  onRefresh?: () => void;
  showRefreshFallback?: boolean;
  showFallback?: boolean;
};

export const ImageWithFallback: React.FC<Props> = ({
  width,
  height,
  src,
  className,
  showRefreshFallback,
  showFallback,
  onClick,
  onRefresh,
}) => {
  const [isFailed, setIsFailed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const onError = useCallback(() => {
    if (!isFailed) {
      setIsFailed(true);
    }
    if (isLoading) {
      setIsLoading(false);
    }
  }, [isFailed, isLoading]);
  const handleRefresh = useCallback(() => {
    if (isFailed) {
      setIsFailed(false);
    }
    if (onRefresh && !isLoading) {
      setIsLoading(true);
      onRefresh?.();
    }
  }, [isFailed, isLoading, onRefresh]);
  return (
    <Container
      onClick={
        !isFailed && !showFallback && !showRefreshFallback ? onClick : undefined
      }
      className={className}
    >
      {isLoading && <StyledImageLoader width={width} height={height} />}
      {isFailed || showFallback || showRefreshFallback ? (
        <FallbackImage
          showRefreshButton={isFailed || showRefreshFallback}
          onRefresh={handleRefresh}
          width={width}
          height={height}
        />
      ) : (
        <StyledImg
          width={width}
          height={height}
          onClick={onClick}
          src={src}
          onError={onError}
          onLoad={() => setIsLoading(false)}
        />
      )}
    </Container>
  );
};

export const FallbackImage = ({
  showRefreshButton,
  onRefresh,
  width,
  height,
}: {
  showRefreshButton?: boolean;
  onRefresh?: () => void;
  width: string;
  height: string;
}) => {
  const { t } = useTranslation();
  return (
    <FailedImageWrapper width={width} height={height}>
      {showRefreshButton ? (
        <StyledRefreshButton onClick={onRefresh}>
          <ReloadIcon sx={{ color: themeConfig.custom.palette.darkText }} />
        </StyledRefreshButton>
      ) : (
        <BrokenImage sx={{ color: themeConfig.custom.palette.darkText }} />
      )}
      <FallbackText color={themeConfig.custom.palette.darkText}>
        {showRefreshButton
          ? t('chat.image.loadingFailed')
          : t('chat.image.imageNotFound')}
      </FallbackText>
    </FailedImageWrapper>
  );
};
