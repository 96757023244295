import {
  APIAccessLevel,
  APIProvider,
  APIProviderSettings,
  APIUser,
} from '../types/api';

export const isUserAdmin = (user: Partial<APIUser>) =>
  user.accessLevel == APIAccessLevel.Admin ||
  user.accessLevel == APIAccessLevel.SuperVisor;

export const isFeatureEnabled = (
  response: APIProviderSettings[],
  featureName: string,
) => {
  const foundFeatureSetting = response.find(
    (item) => item?.key === featureName,
  );
  return foundFeatureSetting?.value === 'true';
};

export const getProviderFromUser = (
  user?: APIUser | Partial<APIUser> | null,
): APIProvider | undefined => {
  const firstProvider = user?.providers?.[0];

  const selectedProvider = user?.providers?.find(
    // @ts-ignore
    (it) => it['isSelected'],
  );
  return selectedProvider || firstProvider;
};
